import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { TextObjectActions } from './text-object.action';

const adapter = createEntityAdapter<RR.TextObject>();

export interface TextObjectState extends EntityState<RR.TextObject> {}

const initialState = adapter.getInitialState();

export const textObjectReducer = createReducer(
  initialState,
  on(TextObjectActions.upsertMany, (state, { textObjects }) => adapter.upsertMany(textObjects, state)),
  on(TextObjectActions.upsertOne, (state, { textObject }) => adapter.upsertOne(textObject, state)),
  on(
    TextObjectActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
