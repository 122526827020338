import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { AttributeOptionActions } from '../attribute-option/attribute-option.action';
import { AttributeSetActions, AttributeSetBatchActions } from './attribute-set.action';
import { AttributeSetHttpService } from './attribute-set.service';
@Injectable()
export class AttributeSetEffect {
  constructor(
    private store: Store<AppState>,
    private service: AttributeSetHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map(({ attribute_sets, attribute_options }) => {
        return AttributeSetBatchActions.findAllSuccessBatchAction({
          actions: {
            attributeSetFindAllSuccessAction: AttributeSetActions.findAllSuccess({
              attributeSets: attribute_sets,
            }),
            attributeOptionFindAllSuccessAction: AttributeOptionActions.findAllSuccess({
              attributeOptions: attribute_options,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(attributeSet: Partial<RR.AttributeSet>) {
    return this.service.create(attributeSet).pipe(
      map((attributeSet: RR.AttributeSet) => AttributeSetActions.createSuccess({ attributeSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(attributeSetId: number, changes: Partial<RR.AttributeSet>) {
    return this.service.update(attributeSetId, changes).pipe(
      map((attributeSet: RR.AttributeSet) => AttributeSetActions.updateSuccess({ attributeSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  numericSort(attributeSetId: number) {
    return this.service.numericSort(attributeSetId).pipe(
      map((attributeSet: RR.AttributeSet) => AttributeSetActions.updateSuccess({ attributeSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  alphatecticSort(attributeSetId: number) {
    return this.service.alphabeticSort(attributeSetId).pipe(
      map((attributeSet: RR.AttributeSet) => AttributeSetActions.updateSuccess({ attributeSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
