import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { PatientQuestionnairesModalComponent } from 'app/modules/registration/modals/patient-questionnaires-modal/patient-questionnaires-modal.component';
import { CheckReportModalComponent } from 'app/modules/report/components/preview/check-report-modal/check-report-modal.component';
import { FinalPreviewModalComponent } from 'app/modules/report/components/preview/final-preview-modal/final-preview-modal.component';
import { SignatureModalComponent } from 'app/modules/report/components/preview/signature-modal/signature-modal.component';
import { SendReportModalComponent } from 'app/modules/report/components/send-report/send-events-modal/send-report-modal.component';
import { NextReportModalComponent } from 'app/shared/modals/next-report-modal/next-report-modal.component';
import { NotesModalComponent } from 'app/shared/modals/notes-modal/notes-modal.component';
import { AppState } from 'app/store';
import { PrefillSearchActions, PrefillSearchType } from 'app/store/prefill/prefill-search';
import { fromCurrentReport, fromReport } from 'app/store/report/report';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromSession } from 'app/store/session';
import { fromTemplate } from 'app/store/template/template';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, skipWhile, switchMap, take } from 'rxjs/operators';

import { WorklistLinkComponent } from '../../../core/toolbar-navbar/components/worklist-link/worklist-link.component';
import { DuplicatePatientWarningComponent } from '../../../shared/components/duplicate-patient-warning/duplicate-patient-warning.component';
import { ReportHeadlineComponent } from '../../../shared/components/report-headline/report-headline.component';
import { ReportNotesButtonComponent } from '../../../shared/components/report-notes-button/report-notes-button.component';
import { SamePatientReportsComponent } from '../../../shared/components/same-patient-reports/same-patient-reports.component';
import { TemplateHeadlineComponent } from '../../../shared/components/template-headline/template-headline.component';
import { AutoFocusDirective } from '../../../shared/directives/auto-focus.directive';
import { DemographicsModalComponent } from '../../../shared/modals/demographics-modal/demographics-modal.component';
import { StoreSelectPipe } from '../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { DocumentAttachmentButtonComponent } from '../../registration/components/document-attachment-button/document-attachment-button.component';
import { SignatureComponent } from '../../report/components/preview/signature/signature.component';
import { AddOtherImagingButtonComponent } from '../add-other-imaging-button/add-other-imaging-button.component';
import { DicomSrModalComponent } from '../dicom-sr-modal/dicom-sr-modal.component';
import { ImgsimParametersModalComponent } from '../imgsim-parameters-modal/imgsim-parameters-modal.component';

@Component({
  templateUrl: './quick-nav-modal.component.html',
  styleUrls: ['./quick-nav-modal.component.css'],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    ReportHeadlineComponent,
    SamePatientReportsComponent,
    SharedModule,
    WorklistLinkComponent,
    TemplateHeadlineComponent,
    DuplicatePatientWarningComponent,
    DocumentAttachmentButtonComponent,
    AutoFocusDirective,
    forwardRef(() => AddOtherImagingButtonComponent),
    ReportNotesButtonComponent,
    StoreSelectPipe,
    SignatureComponent,
    CommonModule,
  ],
})
export class QuickNavModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('imgSim', { static: false }) imgSim: ElementRef;
  @ViewChild('backToPrefill', { static: false }) backToPrefill: ElementRef;
  @ViewChild('viewAndExport', { static: false }) viewAndExport: ElementRef;
  @Input() topic_id: number;
  @Input() report_id: number;
  report: RR.Report;
  subscription: Subscription = new Subscription();
  topic$: Observable<RR.Topic>;
  imgsimParametersAvailable$: Observable<boolean>;
  topic: RR.Topic;

  relatedReports: RR.Report[] = [];

  // When open the editor, check to show report changed warning if the report has already completed
  showReportChangedWarning = false;

  activeTopics: RR.Topic[];

  templateSelectorFn = fromTemplate.selectTemplate;

  constructor(
    public activeModal: NgbActiveModal,
    private editorService: EditorService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private reportService: ReportService,
  ) {}

  ngOnInit() {
    // Check to show changed warning message at the beginning
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report_id))
        .pipe(filterDefined(), take(1))
        .subscribe((report) => {
          if (report.send_to_voyager_time) {
            this.showReportChangedWarning = true;
          }
        }),
    );

    this.subscription.add(
      this.store
        .select(fromCurrentReport.selectReport)
        .pipe(filterDefined())
        .subscribe((r) => {
          this.report = r;
        }),
    );

    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());

    this.subscription.add(
      this.topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    this.subscription.add(
      this.store.select(fromReport.selectTopics(this.report_id)).subscribe((topics) => {
        this.activeTopics = topics;
      }),
    );

    this.subscription.add(
      this.store
        .select(fromReport.selectRelatedReportsLoaded(this.report.id))
        .pipe(
          skipWhile((loaded) => !loaded),
          switchMap(() => this.store.select(fromReport.selectRelatedReports(this.report_id))),
        )
        .subscribe((relatedReports) => {
          this.relatedReports = relatedReports;
        }),
    );

    this.imgsimParametersAvailable$ = this.store.select(fromSession.selectRRConfig).pipe(
      filterDefined(),
      take(1),
      filter((rrConfig) => rrConfig.IMAGE_SIMILARITY),
      switchMap(() => this.reportService.checkImgsimPrefill(this.report_id)),
      map((available) => available.available),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.topic$.pipe(distinctUntilChanged()).subscribe((topic) => {
        if (topic.title_option_id) {
          if (topic.signature_text) {
            this.focusOnElement(this.viewAndExport);
          } else {
            this.focusOnElement(this.backToPrefill);
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, report_id: number, topic_id: number) {
    const modalRef = modalService.open(QuickNavModalComponent, {
      size: 'lg',
      windowClass: 'quick-nav-modal',
      scrollable: true,
    });
    modalRef.componentInstance.topic_id = topic_id;
    modalRef.componentInstance.report_id = report_id;
    return modalRef;
  }

  // TODO: move the loading state in Store

  isDoctorEditing() {
    return this.reportService.isDoctorEditing();
  }

  sign() {
    this.activeModal.dismiss();
    SignatureModalComponent.open({
      modal: this.modalService,
      report_id: this.report_id,
      topic_id: this.topic_id,
      signature_role: 'DOCTOR',
    });
  }

  openDicomSR() {
    this.activeModal.dismiss();
    DicomSrModalComponent.open(this.modalService, this.topic);
  }

  openImgsimParameters() {
    this.activeModal.dismiss();
    ImgsimParametersModalComponent.open(this.modalService, this.report);
  }

  openSendReport() {
    this.activeModal.dismiss();
    SendReportModalComponent.open(this.modalService, this.report);
  }

  openCheckReport() {
    this.activeModal.dismiss();
    CheckReportModalComponent.open(this.modalService, this.report.id, this.topic.id);
  }

  openFinalReport() {
    this.activeModal.dismiss();
    FinalPreviewModalComponent.open(this.modalService, {
      topic_id: this.topic.id,
      report_id: this.report.id,
      mode: 'final',
    });
  }

  openNextReportModal() {
    this.activeModal.dismiss();
    NextReportModalComponent.open({ modalService: this.modalService, reportId: this.report_id });
  }

  toggleSidebar() {
    this.activeModal.dismiss();
    return this.editorService.toggleSidebar();
  }

  openNotes() {
    this.activeModal.dismiss();
    NotesModalComponent.open({ modalService: this.modalService, report: this.report, voiceNotes: true });
  }

  setPrefillType(searchType: PrefillSearchType) {
    this.activeModal.dismiss();
    this.store.dispatch(PrefillSearchActions.updateSearchForm({ searchForm: { searchType: searchType } }));
    this.editorService.togglePrefill(true);
  }

  openPatientInformation() {
    this.activeModal.dismiss();
    DemographicsModalComponent.open(this.modalService, this.report, this.topic, 'EDITOR');
  }

  openPrefill() {
    this.activeModal.dismiss();
    this.editorService.togglePrefill(true);
  }

  openPatientQuestionnaireModal() {
    this.activeModal.dismiss();
    PatientQuestionnairesModalComponent.open(this.modalService, this.report.id);
  }

  setTopicOpen(topic_id: number) {
    if (topic_id === -1) {
      this.editorService.toggleTemplateChooser(true);
    } else {
      this.editorService.setTopicOpen(topic_id);
    }
  }

  focusOnElement(element: ElementRef) {
    requestAnimationFrame(() => {
      element.nativeElement.focus();
    });
  }
}
