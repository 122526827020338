import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TOOLTIPS } from 'app/app.constants';
import { ReportService } from 'app/core/services/report.service';
import { CheckReportModalComponent } from 'app/modules/report/components/preview/check-report-modal/check-report-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: '[rr-check-report]',
  templateUrl: 'check-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CheckReportComponent implements OnInit {
  @Input() topic: RR.Topic;
  @Input() focus = false;
  report$: Observable<RR.Report>;
  @HostBinding('attr.title') title = TOOLTIPS.COPY_TO_CLIPBOARD;

  constructor(
    private reportService: ReportService,
    private modalService: NgbModal,
    private el: ElementRef,
  ) {}

  @HostListener('click')
  openWarningModal() {
    CheckReportModalComponent.open(this.modalService, this.topic.report_id, this.topic.id);
  }

  ngOnInit() {
    this.report$ = this.reportService.getReport(this.topic.report_id);
    // Check report shortcut: Shift + C

    if (this.focus) {
      requestAnimationFrame(() => {
        this.el.nativeElement.focus();
      });
    }
  }
}
