import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { AppState } from 'app/store';
import { fromCurrentTemplate } from 'app/store/template/template';
import { Observable } from 'rxjs';

import { IndexSectionComponent } from './index-section.component';

@Component({
  standalone: true,
  imports: [CommonModule, IndexSectionComponent],
  selector: 'rr-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class IndexComponent implements OnInit {
  sections$: Observable<RR.Section[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.sections$ = this.store.select(fromCurrentTemplate.selectSections);
  }
}
