<div class="modal-header">
  <div>
    <h4 class="modal-title">Final Report Text</h4>
    <rr-report-headline [reportId]="reportId"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <textarea readonly class="form-control" [(ngModel)]="text"></textarea>
</div>
