import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface StatementSetCount {
  id: number;
  name: string;
  count_history: number;
  count_findings: number;
  count_comment: number;
  count_technique: number;
  count_statement: number;
  count_impression_recommendations: number;
}

@Injectable()
export class StatementSetHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.StatementSet[]> {
    return this.http.get<RR.StatementSet[]>('/api/statement_set');
  }

  findById(id: number) {
    return this.http.get<RR.StatementSet>(`/api/statement_set/${id}`);
  }

  create(statementSet: Partial<RR.StatementSet>): Observable<{ statementSet: RR.StatementSet }> {
    return this.http.post<{ statementSet: RR.StatementSet }>('/api/statement_set', statementSet);
  }

  update(
    statementSetId: number,
    changes: Partial<RR.StatementSet>,
  ): Observable<{ statementSet: RR.StatementSet; statements: RR.Statement[] }> {
    return this.http.put<{ statementSet: RR.StatementSet; statements: RR.Statement[] }>(
      `/api/statement_set/${statementSetId}`,
      changes,
    );
  }

  delete(statementSetId: number): Observable<void> {
    return this.http.delete<void>(`/api/statement_set/${statementSetId}`);
  }

  findInTemplate(templateId: number): Observable<RR.StatementSet[]> {
    return this.http.get<RR.StatementSet[]>(`/api/template/${templateId}/statement_set`);
  }

  counts(searchParams: HttpParams) {
    return this.http.get<{ data: StatementSetCount[] }>('/api/statement_set/counts', {
      params: searchParams,
    });
  }
}
