<!-- we don't bother with the `.showing` class to animate it -->
<div class="offcanvas offcanvas-start" [class.show]="open">
  <div class="offcanvas-header">
    <button type="button" class="btn-close ms-0" aria-label="Close" (click)="close()"></button>
    <h5 class="offcanvas-title ms-3">Navigation</h5>
  </div>
  <div class="offcanvas-body">
    <ul class="navbar-nav flex-column gap-1 pe-3">
      <li class="nav-item">
        <!-- TODO(nav): group called "Reports" containing Worklist, Edit a Template, ?Presets, ?Report Feedback, ?Walk-in ...etc -->
        <a rr-nav-link [routerLink]="'/start'">Worklist</a>
      </li>
      <li class="nav-item">
        <a rr-nav-link [routerLink]="'/invoices'"> Invoices </a>
      </li>

      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <!-- unsure why we need `w-100 text-start` simply because it isn't `<a>` -->
        <button ngbDropdownToggle class="nav-link w-100 text-start">Bookings</button>
        <div ngbDropdownMenu>
          <a *ngIf="routerHistoryService.lastBookingListUrl$ | async as url" rr-nav-link [rrDynamicLink]="url">
            Booking List
          </a>
          <a rr-nav-link [routerLink]="'/registration/booking'"> Create Booking </a>
          <a rr-nav-link [routerLink]="'/registration/report'">
            <!-- TODO(nav): should this really be under Bookings? -->
            Register walk-in Patient
          </a>
        </div>
      </li>

      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <button ngbDropdownToggle class="nav-link w-100 text-start">Communications</button>
        <div ngbDropdownMenu>
          <a rr-nav-link [routerLink]="'/registration/booking-enquiries'">
            <span class="d-inline-flex align-items-center me-1">
              Web Booking:&nbsp;<rr-booking-enquiry-badge></rr-booking-enquiry-badge>
            </span>
          </a>
          <a rr-nav-link [routerLink]="'/registration/referral-enquiries'">
            <span class="d-inline-flex align-items-center me-1">
              E-Referral:&nbsp;<rr-referral-enquiry-badge></rr-referral-enquiry-badge>
            </span>
          </a>
          <a rr-nav-link [routerLink]="'/followup-tasks'">
            Followup Tasks <rr-followup-task-badge></rr-followup-task-badge>
          </a>
        </div>
      </li>

      <!-- RR Codes -->
      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <button ngbDropdownToggle class="nav-link w-100 text-start">Codes Admin</button>
        <div ngbDropdownMenu>
          <a rr-nav-link [routerLink]="'/codes/booking-codes'">Booking Codes</a>
          <a rr-nav-link [routerLink]="'/codes/scan-codes'">Scan Codes</a>
          <a rr-nav-link [routerLink]="'/codes/billing-items'">Billing Items</a>
          <a rr-nav-link [routerLink]="'/codes/billing-groups'">Billing Groups</a>
          <a rr-nav-link [routerLink]="'/codes/referrer-types'">Referrer Types</a>
        </div>
      </li>
      <!-- Admin -->
      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <button ngbDropdownToggle class="nav-link w-100 text-start">Admin</button>
        <div ngbDropdownMenu>
          <a rr-nav-link [routerLink]="'/admin/users'">Users</a>
          <a rr-nav-link [routerLink]="'/admin/company-roles'">Company Roles</a>
          <a rr-nav-link [routerLink]="'/admin/templates'">Templates</a>
          <a rr-nav-link [routerLink]="'/admin/machines'">Machines</a>
          <a rr-nav-link [routerLink]="'/admin/config'">Configuration</a>
          <a rr-nav-link [routerLink]="'/admin/notices'">Notices</a>
          <!-- TODO(nav): finish the rest... -->
        </div>
      </li>
      <!-- Alerts dropdown -->
      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <button ngbDropdownToggle class="nav-link w-100 text-start">Notifications</button>
        <div ngbDropdownMenu>
          <button class="nav-link" (click)="openNoticeModal()" rrTooltip="OPEN_NOTICES" rrTrack="Notices - Navigation">
            Notices
            <i class="fas fa-comment-dots"></i>
          </button>

          <button class="nav-link" (click)="openNotificationModal()" rrTrack="Notifications - Navigation">
            Notifications
            <i class="fas fa-bell"></i>
          </button>
        </div>
      </li>
      <!-- Data Management -->
      <li class="nav-item" ngbDropdown [display]="'static'" [open]="true" [autoClose]="false">
        <button ngbDropdownToggle class="nav-link w-100 text-start">Data Management</button>
        <div ngbDropdownMenu>
          <a rr-nav-link [routerLink]="'/entities/referrers'"> Referrers </a>
          <a rr-nav-link [routerLink]="'/entities/referrer-notes'"> Referrer Notes </a>
          <a rr-nav-link [routerLink]="'/entities/patients'"> Patients </a>
          <a rr-nav-link [routerLink]="'/entities/patient-notes'"> Patient Notes </a>
          <a rr-nav-link [routerLink]="'/entities/institutions'"> Institutions </a>
        </div>
      </li>
    </ul>
  </div>
</div>

<!-- TODO(nav):
* Check Feedback
* Radreport feedback issues
-->
