import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { PatientAgeSexPipe } from 'app/shared/pipes/patient-age-sex-pipe';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { fromReport } from 'app/store/report/report';
import { Observable, of, Subscription, switchMap } from 'rxjs';

import { PatientInfoComponent } from '../patient-info/patient-info.component';
import { PatientNameComponent } from '../patient-name/patient-name.component';
import { ReferrerNameComponent } from '../referrer-name/referrer-name.component';
import { ReportAccessionNumberComponent } from '../report-accession-number/report-accession-number.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    PatientAgeSexPipe,
    PatientNameComponent,
    LetDirective,
    PatientInfoComponent,
    ReferrerNameComponent,
    ReportAccessionNumberComponent,
  ],
  selector: 'rr-report-headline',
  templateUrl: './report-headline.component.html',
  styleUrls: ['./report-headline.component.css'],
})
export class ReportHeadlineComponent implements OnInit {
  @Input() reportId: number;
  @Input() showReferrer = true;
  subscription = new Subscription();
  report$: Observable<RR.Report>;
  patient$: Observable<RR.Patient>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId)).pipe(filterDefined());
    this.patient$ = this.report$.pipe(
      switchMap((report) =>
        report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
      ),
      filterDefined(),
    );
  }

  // TODO: add the rest of the features and use this component in more places.
  // copyText(toCopy: any) {
  //   this.clipboard.copy(toCopy);
  //   this.messageService.add({
  //     type: 'success',
  //     title: 'Success',
  //     message: 'Copied accession number to clipboard',
  //     timeout: 2000,
  //   });
  // }
}
