<h5 *ngIf="title" class="mt-4">Add an image similarity parameter rule</h5>
<div class="form-group input-group no-shrink">
  <input
    class="form-control"
    type="text"
    #searchInputText
    placeholder="Search parameters"
    [formControl]="parameterControl"
    [ngbTypeahead]="searchParameters"
    [resultTemplate]="rt"
    (focus)="focus$.next(searchInputText.value)"
    [inputFormatter]="formatter"
    (selectItem)="selectParameter($event.item)"
    [popupClass]="'parameter-dropdown'"
  />
  <button *ngIf="parameterControl.value" (click)="clearParam()" class="input-group-text btn btn-secondary px-2">
    <span class="fa fa-times"></span>
  </button>
</div>
<ng-template #rt let-p="result" let-t="term">
  <div *ngIf="!parameters || parameters.length === 0">No Parameters available</div>
  <div *ngIf="p.name">
    <ngb-highlight class="me-1" [result]="p.name" [term]="t"></ngb-highlight>
  </div>
</ng-template>
