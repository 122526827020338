<div class="d-flex flex-column w-100 rounded bg-light mt-2 p-2">
  <div class="d-flex align-items-center mb-1">
    <h3 class="mb-0">{{ type === 'referrer' ? 'Referrer' : 'Patient' }} Notes</h3>
    <div *ngIf="minimalView" class="ms-auto">
      <ng-content></ng-content>
    </div>
  </div>
  <div
    *ngIf="createMode && !minimalView"
    class="d-flex bg-white p-3 rounded"
    permission
    [permission-only]="['data_management']"
    [permission-hidden]="true"
  >
    <textarea
      class="form-control border-secondary w-50 me-4"
      placeholder="Write a new note..."
      [formControl]="note"
      autocomplete="off"
      rrAutoSize
    ></textarea>
    <div class="me-2">
      <rr-note-category (onCategorySelect)="setCategory($event)" [type]="type"></rr-note-category>
    </div>
    <div class="d-flex ms-auto">
      <button [disabled]="!note.getRawValue().length || !category" class="btn btn-primary" (click)="createNote()">
        Create Note
      </button>
    </div>
  </div>

  <div class="mt-2">
    <ul class="list-group">
      <ng-container *ngIf="referrerNotes.length > 0">
        <li class="list-group-item" *ngFor="let note of referrerNotes">
          <rr-note [referrerNote]="note"></rr-note>
        </li>
      </ng-container>
      <ng-container *ngIf="patientNotes.length > 0">
        <li class="list-group-item" *ngFor="let note of patientNotes">
          <rr-note [patientNote]="note"></rr-note>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
