import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for BillingItem
 *
 */
export const BillingItemActions = {
  findAllSuccess: createAction('[BillingItem] Find All Success', props<{ billingItems: RR.BillingItem[] }>()),

  findManySuccess: createAction('[BillingItem] Find Many Success', props<{ billingItems: RR.BillingItem[] }>()),

  findSuccess: createAction('[BillingItem] Find Success', props<{ billingItem: RR.BillingItem }>()),

  createSuccess: createAction('[BillingItem] Create Success', props<{ billingItem: RR.BillingItem }>()),

  updateSuccess: createAction('[BillingItem] Update Success', props<{ billingItem: RR.BillingItem }>()),

  deleteSuccess: createAction('[BillingItem] Delete Success', props<{ billingItemId: number }>()),
};
