import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../../app.state';
import { ReportStatusActions } from './report-status.action';
import { ReportStatusHttpService } from './report-status.service';

@Injectable()
export class ReportStatusEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReportStatusHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((reportStatuses) => ReportStatusActions.findAllSuccess({ reportStatuses })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
