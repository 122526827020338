import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { BillingItemActions } from '../billing-item/billing-item.action';

/**
 * Ngrx Actions for BillingItem
 *
 */

const findAllSuccess = createAction(
  '[BillingItemReferrerType] Find All Success',
  props<{ billingItemsReferrerType: RR.ReferrerTypeBillingItem[] }>(),
);

const updateSuccess = createAction(
  '[BillingItemReferrerType] Update Success',
  props<{ billingItemReferrerType: RR.ReferrerTypeBillingItem }>(),
);

const updateBillingItemReferrerTypeSuccessBatch = createBatchAction(
  '[BillingItemReferrerType] Update Billing Item Referrer Type Success Batch',
  props<{
    actions: {
      updateBillingItemReferrerTypeSuccess: ReturnType<typeof updateSuccess>;
      updateBillingItemSuccess: ReturnType<typeof BillingItemActions.updateSuccess>;
    };
  }>(),
);

export const BillingItemReferrerTypeActions = {
  findAllSuccess,
  updateSuccess,
  updateBillingItemReferrerTypeSuccessBatch,
};
