import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { IPermissionMap } from 'app/core/services/permission.store';
import { AppState } from 'app/store';
import { SessionActions, SessionEffect } from 'app/store/session';
import { Observable, of as observableOf } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

import { SelectorService } from '../services/selector.service';

enum ErrorTypes {
  NOT_LOGGED_IN,
  ROUTE_PERMISSION_DENIED,
}

/**
 * If the user is authenticated, then proceeds to the route.
 * Otherwise redirects to `data.permissions.redirectTo` defined in in the Route (default redirect: /login)
 */
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private sessionEffect: SessionEffect,
    private selectorService: SelectorService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // @ts-expect-error strictNullChecks
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const data: { permissions: IPermissionMap } = route.data || <any>route.firstChild.data;
    return this.selectorService.selectLoadedCurrentUser().pipe(
      switchMap((user) => {
        // use switchMap because this.session.authorise returns an Observable<boolean>
        if (user == null) {
          return observableOf(ErrorTypes.NOT_LOGGED_IN);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (data == null) {
          return observableOf(null); // no errors
        } else {
          return this.sessionEffect.authorise(data.permissions).pipe(
            take(1),
            map((granted) => {
              if (granted) {
                return null; // no errors
              } else {
                return ErrorTypes.ROUTE_PERMISSION_DENIED;
              }
            }),
          );
        }
      }),
      map((errors: ErrorTypes | null) => {
        if (errors !== null) {
          // Store the attempted URL for redirecting
          this.store.dispatch(SessionActions.setGoalRoute({ goalRoute: state.url })); // TODO: old code didn't replace if goalUserState was already defined
          if (errors === ErrorTypes.NOT_LOGGED_IN) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
            if (data.permissions && data.permissions.redirectTo) {
              this.router.navigate([data.permissions.redirectTo], {
                replaceUrl: true,
              });
            } else {
              this.router.navigate(['/login'], { replaceUrl: true });
            }
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          } else if (errors === ErrorTypes.ROUTE_PERMISSION_DENIED) {
            // after user logs in and tries to direct to route (such as /admin), don't redirect back to /login
            this.selectorService
              .selectLoadedCurrentUser()
              .pipe(take(1))
              .subscribe(() => {
                this.router.navigate(['/start'], { replaceUrl: true });
              });
          }
        }
        return true;
      }),
      first(),
    ); // ensure the observable completes
  }
}

/**
 * Redirects to start if the user is logged in
 */
@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.selectorService.selectLoadedCurrentUser().pipe(
      map((user) => {
        if (user != null) {
          this.store.dispatch(SessionActions.setGoalRoute({ goalRoute: state.url }));
          this.router.navigate(['/start'], { replaceUrl: true });
          return false;
        } else {
          return true;
        }
      }),
      first(),
    ); // ensure the observable completes
  }
}
