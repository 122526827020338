import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { PatientQuestionAnswerEffect } from 'app/store/questionnaire/patient-question-answer';
import { Subscription } from 'rxjs';

@Component({
  selector: '[rr-questionnaire]',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class QuestionnaireComponent implements OnDestroy {
  @Input() question: RR.PatientQuestion;
  @Input() answer: RR.PatientQuestionAnswer | undefined;
  @Input() viewonly: boolean;
  @Input() report_id: number;

  subscription = new Subscription();

  constructor(private patientQuestionAnswerEffect: PatientQuestionAnswerEffect) {}

  onAnswerChanged(patient_question_id: number, answer: string) {
    if (this.answer) {
      // Not submit the same answer
      if (this.answer.answer === answer) return;
      // Update patient answer
      this.subscription.add(this.patientQuestionAnswerEffect.update(this.answer.id, { answer }).subscribe());
    } else {
      // Create new patient answer
      this.subscription.add(
        this.patientQuestionAnswerEffect.create(this.report_id, { patient_question_id, answer }).subscribe(),
      );
    }
  }

  removeAnswer(id: number) {
    this.subscription.add(this.patientQuestionAnswerEffect.delete(id).subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
