<ng-container *ngIf="subsection_choice">
  <p
    (click)="focusSubsection()"
    role="button"
    *ngIf="subsection_choice.subsection_name != null && !subsection_choice.hide_heading"
    class="h4 item-hover-parent subsection-heading px-1"
    rrTooltip="NAVIGATE"
  >
    {{ subsection_choice.subsection_name
    }}<button
      (click)="removeSubsection(); $event.stopPropagation()"
      *ngIf="mode === 'editor'"
      class="item-hover btn btn-light btn-sm"
      role="button"
      rrTooltip="REMOVE_SENTENCES"
    >
      <span class="fa fa-trash"></span> Remove all
    </button>
  </p>
  <div class="region" *ngFor="let region_choice of region_choices$ | async">
    <div
      role="button"
      *ngIf="subsection_choice && subsection_choice.type === 'region'"
      (click)="jumpToRegion(region_choice.region_id)"
      class="h4 region-heading item-hover-parent"
      rrTooltip="NAVIGATE_MIDDLE_PANE"
    >
      {{ region_choice?.region_name }}
      <div class="btn-group" *ngIf="mode === 'editor'">
        <button
          (click)="removeRegion(region_choice.id); $event.stopPropagation()"
          class="item-hover btn btn-light btn-sm"
          role="button"
          rrTooltip="REMOVE_REGION"
        >
          <span class="fa fa-trash"></span> Remove
        </button>
        <button
          class="item-hover btn btn-light btn-sm"
          role="button"
          (click)="onlyThisRegion(region_choice); $event.stopPropagation()"
          *ngIf="hasRegions | async"
          rrTooltip="ONLY_THIS_REGION"
        >
          <span class="fa fa-plus-circle"></span> Only
        </button>
      </div>
    </div>
    <rr-element-preview
      *ngFor="let element_choice_id of region_choice?.element_choices"
      [element_choice_id]="element_choice_id"
      [region_choice]="region_choice"
      [subsection_choice]="subsection_choice"
      [section_choice]="section_choice"
      [topic]="topic"
      [mode]="mode"
    >
    </rr-element-preview>
  </div>
</ng-container>
