import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Report Access Event
 *
 */
export const ReportAccessEventActions = {
  findManySuccess: createAction(
    '[ReportAccessEvent] Find Many Success',
    props<{ accessEvents: RR.ReportAccessEvent[] }>(),
  ),

  createSuccess: createAction('[ReportAccessEvent] Create Success', props<{ accessEvent: RR.ReportAccessEvent }>()),

  updateSuccess: createAction('[ReportAccessEvent] Update Success', props<{ accessEvent: RR.ReportAccessEvent }>()),
};
