<div class="modal-header">
  <h5 class="modal-title">Prefill Tutorial</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div>
    <h2><strong>Prefill Color Code:</strong></h2>
    <ul>
      <li>
        <div>
          <span><strong>Green highlight statements:</strong></span>
          <span class="ms-1"
            >Statements that appear in the current report and are suggested by selected Prefill reports</span
          >
        </div>
        <div class="mt-1 mb-2 px-2 list-group-item-success">
          There is a recent fracture of the distal styloid process of the radius.
        </div>
      </li>
      <li>
        <div>
          <span><strong>Blue highlight statements:</strong></span>
          <span class="ms-1"
            >Statements that appear in the current report but are not suggested by selected Prefill reports</span
          >
          <div class="mt-1 mb-2 px-2 list-group-item-info">
            There is a recent fracture of the distal styloid process of the radius.
          </div>
        </div>
      </li>
      <li>
        <div>
          <span><strong>Green groups:</strong></span>
          <span class="ms-1">Searched groups</span>
          <div class="mt-1 mb-2 px-2 text-success">
            <strong>SOFT TISSUE PLANES NAD</strong>
          </div>
        </div>
      </li>
      <li>
        <div>
          <span><strong>Red statements under searched groups:</strong></span>
          <span class="ms-1"
            >There are no suggested statements from Prefill reports for searched groups. Therefore, template statements
            under those groups are displayed.</span
          >
          <div class="mt-1 px-2 text-success">
            <strong>SKELETAL STRUCTURES NORMAL</strong>
          </div>
          <ul>
            <li class="text-danger">No bone or joint abnormality is detected on the right and left.</li>
            <li class="text-danger">The visualised skeletal structures of the hand appear normal for age.</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
