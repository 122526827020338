import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { JustificationActions } from './justification.action';

export type JustificationState = EntityState<RR.Justification>;

const adapter = createEntityAdapter<RR.Justification>();

const initialState = adapter.getInitialState();

export const justificationReducer = createReducer(
  initialState,
  // TODO(justification): check this works
  on(JustificationActions.findManySuccess, (state: JustificationState, { justifications }) =>
    adapter.upsertMany(justifications, state),
  ),
  on(JustificationActions.createSuccess, (state: JustificationState, { justification }) =>
    adapter.addOne(justification, state),
  ),
  on(JustificationActions.deleteSuccess, (state: JustificationState, { justificationId }) =>
    adapter.removeOne(justificationId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
