<div class="container p-0 m-1">
  <ng-container *ngIf="!institution_id">
    <div class="row">
      <div class="col-8">
        <form>
          <div class="form-group mb-2 input-group no-shrink">
            <div class="input-group-text">
              <span class="fa fa-search"></span>
            </div>
            <!-- Form wrapper needed for autocomplete off -->
            <input
              class="form-control"
              type="text"
              placeholder="Search by institution name, contact person, phone, email..."
              [formControl]="searchText"
              [popupClass]="'select-institution-dropdown'"
              [ngbTypeahead]="suggestInstitutions"
              [resultTemplate]="rt"
              (focus)="focus$.next(searchText.value)"
              (selectItem)="selectInstitution($event.item)"
            />
          </div>
        </form>
      </div>
      <div class="col">
        <button class="btn btn-outline-primary ms-auto" (click)="createNewInstitution()">+ New Institution</button>
      </div>
    </div>
  </ng-container>
  <rr-institution-form-view [institution]="institution">
    <ng-container buttons>
      <button type="button" class="btn btn-outline-primary" (click)="editInstitution()">Edit Institution</button>
      <button class="btn btn-danger mt-2" (click)="removeInstitution()">Remove Institution</button>
    </ng-container>
  </rr-institution-form-view>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div *ngIf="r.name">
    <ngb-highlight class="me-1" [result]="r.name" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.type">
    <!-- TODO(noImplicitAny): this ng-template is untyped -->
    <span>Type: {{ $any(institutionTypeMap)[r.type] }} </span>
  </div>
  <div *ngIf="r.contact_person">
    <span>Contact person: </span>
    <ngb-highlight [result]="r.contact_person" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.phone || r.email">
    <span>Phone/Email: </span>
    <ngb-highlight [result]="r.phone || r.email || 'N/A'" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.suburb">
    <span>Suburb: </span>
    <ngb-highlight [result]="r.suburb ?? '' + r.postcode ?? ''" [term]="t"></ngb-highlight>
  </div>
</ng-template>
