<ng-container *ngIf="filterByRole">
  <rr-company-role-selector mode="multiple" (onSelect)="onRoleChanged($event)"></rr-company-role-selector>
</ng-container>

<div ngbDropdown [autoClose]="'outside'" (openChange)="onUserDropdown($event)" container="body">
  <button class="btn btn-outline-dark w-100 text-normal" type="button" ngbDropdownToggle>
    <ng-container *ngIf="checkedUsersList.length">
      <span *ngFor="let user of checkedUsersList; let last = last">
        <rr-user-headline [user]="user"></rr-user-headline>
        <ng-container *ngIf="!last">, </ng-container>
      </span>
    </ng-container>
    <ng-container *ngIf="!checkedUsersList.length">
      {{ buttonLabel }}
    </ng-container>
  </button>

  <div ngbDropdownMenu class="user-list">
    <input type="text" class="form-control my-1" placeholder="Search by name" #searchInput />
    <div class="list-group list-group-flush">
      <div class="list-group-item list-group-item-action" *ngFor="let user of users; let i = index">
        <div class="form-check">
          <input
            id="{{ instanceId }}-user-selector-{{ i }}"
            class="form-check-input"
            [type]="mode === 'multiple' ? 'checkbox' : 'radio'"
            (change)="onChange(user)"
            [checked]="checkedUsers[user.id]"
          />
          <label for="{{ instanceId }}-user-selector-{{ i }}" class="form-check-label"
            ><rr-user-headline [user]="user"></rr-user-headline
          ></label>
        </div>
      </div>
    </div>
  </div>
</div>
