import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { Observable, Subscription, switchMap } from 'rxjs';

import { ReportStatusModalComponent } from '../report-status-modal/report-status-modal.component';

@Component({
  selector: 'rr-report-status-badge',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './report-status-badge.component.html',
  styleUrls: ['./report-status-badge.component.css'],
})
export class ReportStatusBadgeComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() report: RR.Report;
  report$: Observable<RR.Report>;
  @Input() btnClass = 'btn-sm';

  subscription = new Subscription();
  reportStatus: RR.ReportStatus | undefined;

  constructor(
    private store: Store<AppState>,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.report$
        .pipe(switchMap((report) => this.store.select(fromReportStatus.selectReportStatus(report.status_id))))
        .subscribe((reportStatus) => {
          this.reportStatus = reportStatus;
        }),
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClick() {
    ReportStatusModalComponent.open({
      modal: this.modal,
      report: this.report,
    });
  }
}
