import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { UserActions } from '../../user/user/user.action';

const findManySuccess = createAction('[AuditEvent] Find Many Success', props<{ auditEvents: RR.AuditEvent[] }>());
const createSuccess = createAction('[AuditEvent] Create Success', props<{ auditEvent: RR.AuditEvent }>());
const findSuccessBatch = createBatchAction(
  '[AuditEvent] Find Success Batch',
  props<{
    actions: {
      findAuditEvent: ReturnType<typeof findManySuccess>;
      findUser: ReturnType<typeof UserActions.findAllSuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for Audit Event
 *
 */
export const AuditEventActions = {
  findManySuccess,
  createSuccess,
  findSuccessBatch,
};
