import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { StatementCoincidenceActions } from './statement-coincidence.action';

export interface StatementCoincidenceState extends EntityState<RR.StatementCoincidence> {}

const adapter = createEntityAdapter<RR.StatementCoincidence>();
const initialState = adapter.getInitialState();

export const statementCoincidenceReducer = createReducer(
  initialState,
  on(StatementCoincidenceActions.addOne, (state, { statementCoincidence }) =>
    adapter.addOne(statementCoincidence, state),
  ),
  on(StatementCoincidenceActions.addMany, (state, { statementCoincidences }) =>
    adapter.addMany(statementCoincidences, state),
  ),
  on(
    StatementCoincidenceActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
