import { Pipe, PipeTransform } from '@angular/core';
import { convertSecondsToHMS } from 'app/app.utils';

@Pipe({
  standalone: true,
  name: 'convertSecondsToHMS',
})
export class ConvertSecondsToHMSPipe implements PipeTransform {
  transform(seconds: number) {
    if (!seconds) {
      return false;
    }
    const time = convertSecondsToHMS(seconds);

    return `${time[0]}h ${time[1]}m ${time[2]}s`;
  }
}
