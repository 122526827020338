<div class="d-flex me-1">
  <div ngbDropdown dropdownClass="voice-recognition-dropdown" [autoClose]="false">
    <button role="button" class="btn btn-outline-secondary" ngbDropdownToggle rrTooltip="VOICE_TERMS">
      <i class="fas fa-comment"></i>
      <span *ngIf="voiceResults.length > 0" class="mx-1 badge bg-light text-dark">{{ voiceResults.length }}</span>
    </button>
    <div ngbDropdownMenu>
      <button
        *ngIf="voiceResults.length > 0"
        class="btn btn-sm btn-outline-primary ms-2 p-1 px-2"
        (click)="clearAllVoice()"
      >
        Clear All
      </button>
      <h6 rrTooltip="SEARCH_CLIPBOARD" class="p-2 pb-0 mb-0"><u>SEARCH CLIPBOARD (PERSISTED)</u></h6>
      <ng-container *ngFor="let result of voiceResults">
        <div *ngIf="result.note_type === 'search_clipboard'">
          <div (click)="copyToSearch(result.term)" ngbDropdownItem class="d-flex p-1 px-2">
            <span>
              {{ result.term }}
            </span>
            <button role="button" class="btn btn-sm ms-auto" (click)="$event.stopPropagation(); deleteResult(result)">
              <span class="fa fa-trash-alt text-danger"></span>
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf="voiceResults.length < 1" ngbDropdownItem>No terms</div>

      <h6 rrTooltip="VOICE_TERMS_LIST" class="p-2 pb-0 mb-0 mt-3">
        <u>GENERAL VOICE NOTES</u>
      </h6>
      <ng-container *ngFor="let result of voiceResults">
        <div *ngIf="result.note_type === 'general'">
          <div (click)="copyToSearch(result.term)" ngbDropdownItem class="d-flex p-1 px-2">
            <span>
              {{ result.term }}
            </span>
            <button role="button" class="btn btn-sm ms-auto" (click)="$event.stopPropagation(); deleteResult(result)">
              <span class="fa fa-trash-alt text-danger"></span>
            </button>
          </div>
        </div>
      </ng-container>

      <rr-structured-voice-terms [parent]="parent" [voiceResults]="voiceResults"></rr-structured-voice-terms>
      <div *ngIf="voiceResults.length < 1" ngbDropdownItem>No terms</div>
    </div>
  </div>
  <button
    rrTooltip="ROTATE_VOICE_CLIPBOARD"
    *ngIf="voiceResults.length >= 1 && parent !== 'SOABS'"
    class="btn btn-sm btn-outline-secondary ms-1"
    (click)="nextTerm()"
  >
    <i class="fas fa-angle-double-down"></i>
  </button>
</div>
