import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { expiredMedicare } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { atLeastOneRequired, forbiddenDOB } from 'app/shared/utils/form-validation.utils';
import { getEmailValidatorRegex } from 'app/shared/utils/shared.utils';
import { MedipassValidationResponse, PatientDuplicate, PatientEffect, PatientHttpService } from 'app/store/patient';
import { ReferralEnquirySearchBody } from 'app/store/referral-enquiry';
import { ReferralEnquiryEffect } from 'app/store/referral-enquiry/referral-enquiry.effect';
import { formatISO } from 'date-fns';
import { combineLatest, forkJoin, Observer, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import {
  capitaliseFirstLetter,
  TitleCaseInputComponent,
} from '../../../../../shared/components/title-case-input/title-case-input.component';
import { PatientSuggestionsComponent } from '../../patient-suggestions/patient-suggestions.component';
import { PatientFormParent } from '../patient-form.component';
import { PatientPopoverComponent } from '../patient-popover/patient-popover.component';
import { PhoneFaxInputComponent } from '../phone-fax-input/phone-fax-input.component';

@Component({
  standalone: true,
  selector: 'rr-patient-form-edit',
  templateUrl: './patient-form-edit.component.html',
  styleUrls: ['./patient-form-edit.component.css'],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    PhoneFaxInputComponent,
    PatientPopoverComponent,
    TitleCaseInputComponent,
    PatientSuggestionsComponent,
  ],
})
export class PatientFormEditComponent implements OnInit, OnDestroy {
  // Popover to remind users to click save changes in kiosk mode
  @ViewChild('savePopover') popover: NgbPopover | undefined;
  @Input() viewMode: 'search' | 'view' | 'edit' | 'create';

  @Input() patient: RR.Patient | undefined;
  @Input() bookingPatient?: RR.Booking['booking_patient'];
  @Input() searchFormPatientDetails?: Partial<RR.Patient>;
  @Input() parent: PatientFormParent;

  @Input() selfRegistration: boolean;

  @Output() onChange: EventEmitter<{ patient: RR.Patient; created: boolean }> = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @Output() onExpiredMedicare = new EventEmitter();

  similarPatients: PatientDuplicate | undefined;
  similarPatientsChecked: { [patientId: number]: boolean } = {};
  similarReferralPatients: RR.ReferralEnquiry[] = [];

  disablePatientInputFields: { [key: string]: boolean } = {
    patient_first_name: true,
    patient_last_name: true,
  };

  // If changing form validation, make sure to update the separate patient object validation in patient-form and patient-listing-detail
  form = new FormGroup(
    {
      patient_first_name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      patient_last_name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      patient_dob: new FormControl('', { validators: [Validators.required, forbiddenDOB()], nonNullable: true }),
      patient_sex: new FormControl<RR.Sex | undefined>(undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),
      pronouns: new FormControl('', { nonNullable: true }),

      medicare_number: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      medicare_reference_number: new FormControl<number | undefined>(undefined, {
        validators: [Validators.pattern('^[0-9]*$')],
        nonNullable: true,
      }),
      medicare_expiry_month: new FormControl<number | undefined>(undefined, {
        validators: [Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(12)],
        nonNullable: true,
      }),
      medicare_expiry_year: new FormControl<number | undefined>(undefined, {
        validators: [Validators.pattern('^[0-9]{2}$'), Validators.min(1), Validators.max(99)],
        nonNullable: true,
      }),
      veteran_file_number: new FormControl('', { validators: Validators.pattern('^[a-zA-Z0-9]*$'), nonNullable: true }),

      phone_home: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      phone_work: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      phone_mobile: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      email: new FormControl('', { validators: Validators.pattern(getEmailValidatorRegex()), nonNullable: true }),

      address: new FormControl('', { nonNullable: true }),
      city: new FormControl('', { nonNullable: true }),
      state: new FormControl('', { nonNullable: true }),
      zip: new FormControl('', { validators: Validators.pattern('[0-9]{4}'), nonNullable: true }),

      note: new FormControl('', { nonNullable: true }),
      medipass_last_validated: new FormControl('', { nonNullable: true }),
      medipass_validation_response: new FormControl<RR.Patient['medipass_validation_response'] | undefined>(undefined, {
        nonNullable: true,
      }),
    },
    Validators.compose([
      atLeastOneRequired(
        Validators.required,
        ['phone_home', 'phone_work', 'phone_mobile', 'email'],
        'phoneOrEmailRequired',
      ),
    ]),
  );

  expiredMedicare = false;
  formValid = true;

  subscription: Subscription = new Subscription();

  constructor(
    private patientService: PatientHttpService,
    private patientEffect: PatientEffect,
    private messageService: MessageService,
    private referralEnquiryEffect: ReferralEnquiryEffect,
  ) {}

  ngOnInit(): void {
    if (this.viewMode === 'edit' && this.parent === 'REGISTRATION') {
      this.form.controls.patient_dob.disable();
      this.form.controls.patient_sex.disable();
    }

    if (this.selfRegistration) {
      this.form.controls['patient_first_name'].disable();
      this.form.controls['patient_last_name'].disable();
      this.form.controls['medicare_number'].disable();
      this.form.controls['veteran_file_number'].disable();
    }

    // Initialise the form from the patient, booking, or fields typed into the search form.
    this.formValidation();
    if (this.patient) {
      // Patch with patient data
      this.patchPatientForm(this.patient);
    } else if (this.bookingPatient && this.searchFormPatientDetails) {
      // Patch with booking and patient search form data
      this.patchPatientForm({
        patient_first_name: capitaliseFirstLetter(this.searchFormPatientDetails.patient_first_name || undefined),
        patient_last_name: capitaliseFirstLetter(this.searchFormPatientDetails.patient_last_name || undefined),
        patient_sex: this.bookingPatient.gender,
        email: this.bookingPatient.email,
        medicare_number: this.searchFormPatientDetails.medicare_number,
        phone_mobile: this.searchFormPatientDetails.phone_mobile, // assumed to be mobile
        patient_dob: this.searchFormPatientDetails.patient_dob,
      });
    } else if (this.searchFormPatientDetails) {
      this.patchPatientForm({
        ...this.searchFormPatientDetails,
        patient_first_name: capitaliseFirstLetter(this.searchFormPatientDetails.patient_first_name || undefined),
        patient_last_name: capitaliseFirstLetter(this.searchFormPatientDetails.patient_last_name || undefined),
      });
    }

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          filter(() => !this.patient && this.parent === 'REGISTRATION'),
          switchMap(() => {
            const p = this.getPatientDataFromFormInput();
            const searchBody: ReferralEnquirySearchBody = {
              limit: 20,
              offset: 0,
              all: false,
              patient_name: p.patient_first_name === null ? undefined : p.patient_first_name,
              patient_medicare_number: p.medicare_number === null ? undefined : p.medicare_number,
              patient_mobile: p.phone_mobile === null ? undefined : p.phone_mobile,
            };
            const searchPatientDuplicate = {
              patient_first_name: p.patient_first_name,
              patient_last_name: p.patient_last_name,
              medicare_number: p.medicare_number,
              medicare_reference_number: p.medicare_reference_number,
            };

            return forkJoin({
              patientDuplicateResponse: this.patientService
                .findDuplicatePatient(searchPatientDuplicate)
                .pipe(map((result: PatientDuplicate) => result)),
              referralSearchResult: this.referralEnquiryEffect
                .search(searchBody)
                .pipe(map((response) => response.actions.searchReferralSuccess.enquiries)),
            });
          }),
        )
        .subscribe(({ patientDuplicateResponse, referralSearchResult }) => {
          this.similarPatients = patientDuplicateResponse;
          this.similarReferralPatients = referralSearchResult.filter((enquiry) => enquiry.status === 'UNRESOLVED');
        }),
    );

    // Show save changes reminder in kiosk mode
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        if (this.selfRegistration && this.form.dirty && !this.popover?.isOpen()) {
          this.popover?.open();
        }
      }),
    );

    this.subscription.add(
      combineLatest([
        this.form.statusChanges.pipe(startWith(this.form.status)),
        this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
      ])
        .pipe(delay(0))
        .subscribe(([status, _]) => {
          this.expiredMedicare = expiredMedicare(
            this.form.controls.medicare_expiry_month.value,
            this.form.controls.medicare_expiry_year.value,
          );

          if (status === 'VALID') {
            this.formValid = true;
          } else if (status === 'INVALID') {
            this.formValid = false;
          }
        }),
    );
  }

  /**
   * Update form value with patient data
   * @param patient
   */
  patchPatientForm(patient: Partial<RR.Patient>) {
    this.form.patchValue({
      patient_first_name: patient.patient_first_name || '',
      patient_last_name: patient.patient_last_name || '',
      patient_dob: patient.patient_dob || '',
      patient_sex: patient.patient_sex || undefined,
      pronouns: patient.pronouns || '',
      medicare_number: patient.medicare_number || '',
      medicare_reference_number: patient.medicare_reference_number || undefined,
      medicare_expiry_month: patient.medicare_expiry_month || undefined,
      medicare_expiry_year: patient.medicare_expiry_year || undefined,
      veteran_file_number: patient.veteran_file_number || '',
      phone_home: patient.phone_home || '',
      phone_work: patient.phone_work || '',
      phone_mobile: patient.phone_mobile || '',
      email: patient.email || '',
      address: patient.address || '',
      city: patient.city || '',
      state: patient.state || '',
      zip: patient.zip || '',
      note: patient.note || '',
      medipass_last_validated: patient.medipass_last_validated || '',
      medipass_validation_response: patient.medipass_validation_response || undefined,
    });
  }

  getPatientDataFromFormInput(): Partial<RR.Patient> {
    return {
      patient_first_name: this.form.controls.patient_first_name.value,
      patient_last_name: this.form.controls.patient_last_name.value,
      patient_dob: this.form.controls.patient_dob.value
        ? formatISO(new Date(this.form.controls.patient_dob.value), { representation: 'date' })
        : undefined,
      patient_sex: this.form.controls.patient_sex.value,
      pronouns: this.form.controls.pronouns.value,
      medicare_number: this.form.controls.medicare_number.value || undefined,
      medicare_reference_number: this.form.controls.medicare_reference_number.value || undefined,
      medicare_expiry_month: this.form.controls.medicare_expiry_month.value || undefined,
      medicare_expiry_year: this.form.controls.medicare_expiry_year.value || undefined,
      veteran_file_number: this.form.controls.veteran_file_number.value,
      phone_home: this.form.controls.phone_home.value,
      phone_work: this.form.controls.phone_work.value,
      phone_mobile: this.form.controls.phone_mobile.value,
      email: this.form.controls.email.value,
      address: this.form.controls.address.value,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      zip: this.form.controls.zip.value,
      note: this.form.controls.note.value,
      medipass_validation_response: this.form.controls.medipass_validation_response.value,
      medipass_last_validated: this.form.controls.medipass_last_validated.value || undefined,
    };
  }

  emitPatient(patient: RR.Patient, created = false) {
    this.viewMode = 'view';
    this.form.markAsPristine();
    this.onChange.emit({ patient, created });
  }

  submitPatient() {
    if (
      this.similarPatients &&
      this.similarPatients.patients.length > 0 &&
      !this.similarPatients.patients.every((p) => this.similarPatientsChecked[p.id])
    ) {
      // One of the similar patients is not checked
      return;
    }

    this.formValidation();
    if (!this.form.valid) {
      this.messageService.add({
        title: 'Error',
        message: 'Some fields are still invalid',
        type: 'danger',
      });
      return;
    }

    // Close save changes reminder in kiosk mode
    if (this.selfRegistration && this.popover?.isOpen()) {
      this.popover.close();
    }

    if (this.patient) {
      // Merge data from patient input and form input then submitting
      const p = { ...this.patient, ...this.getPatientDataFromFormInput() };
      // Update patient
      this.patientEffect
        .update(this.patient.id, p)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe({
          next: (action) => {
            this.emitPatient(action.patient);
          },
          error: (error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              this.messageService.httpErrorMessage(error, { title: 'Update patient failed' });
              this.setValidationErrors(error);
            }
          },
        });
    } else {
      // Create new patient
      const p = { ...this.getPatientDataFromFormInput() };
      this.patientEffect
        .create(p)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe({
          next: (action) => {
            this.emitPatient(action.patient, true);
          },
          error: (error: unknown) => {
            if (error instanceof HttpErrorResponse) {
              this.messageService.httpErrorMessage(error, { title: 'Create patient failed' });
              this.setValidationErrors(error);
            }
          },
        });
    }
  }

  setValidationErrors(error: HttpErrorResponse) {
    const errorBody: { json?: { [fieldName: string]: string[] } } = error.error;
    const json = errorBody.json;
    if (json) {
      Object.keys(json).forEach((prop) => {
        const formControl = this.form.get(prop);
        if (formControl) {
          formControl.setErrors({
            serverError: json[prop],
          });
        }
      });
    }
  }

  formValidation() {
    this.form.markAllAsTouched();
  }

  validateWithMedicare() {
    const p = { ...this.patient, ...this.getPatientDataFromFormInput() };

    const observer: Partial<Observer<MedipassValidationResponse>> = {
      next: (action) => {
        if ('patientVerified' in action.json) {
          if (action.json.patientVerified) {
            this.messageService.add({
              title: 'Success',
              message: action.json.patient.message,
              type: 'success',
            });
          } else {
            this.messageService.add({
              title: 'Validate patient failed',
              message: action.json.patient.message,
              type: 'danger',
            });
          }
        } else {
          this.messageService.add({
            title: 'Validate patient failed',
            message: action.json.errors[0],
            type: 'danger',
          });
        }
      },
    };

    if (this.patient) {
      this.subscription.add(
        this.patientEffect
          .validate(this.patient.id, p)
          .pipe(tap(observer))
          .subscribe((response) => {
            this.form.patchValue({ medipass_last_validated: response.patient.medipass_last_validated || undefined });
          }),
      );
    } else {
      this.subscription.add(
        this.patientService
          .validateMedicare(p)
          .pipe(tap(observer))
          .subscribe((response) => {
            this.form.patchValue({
              medipass_last_validated: response.patient.medipass_last_validated || undefined,
              medipass_validation_response: response.json,
            });
          }),
      );
    }
  }

  cancel() {
    this.onCancel.emit();
  }

  remove() {
    this.cancel();
    this.onRemove.emit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
