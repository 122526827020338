import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { Observable } from 'rxjs';

import { ReportHeadlineComponent } from '../../../../shared/components/report-headline/report-headline.component';
import { RegistrationComponent } from '../../components/registration/registration.component';

@Component({
  selector: 'rr-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ReportHeadlineComponent, RegistrationComponent],
})
export class RegistrationModalComponent implements OnInit {
  @Input() reportId: number;

  report$: Observable<RR.Report | undefined>;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));
  }

  static open(modalService: NgbModal, reportId: number) {
    const modalRef = modalService.open(RegistrationModalComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'registration-modal',
    });
    modalRef.componentInstance.reportId = reportId;

    return modalRef;
  }
}
