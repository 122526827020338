import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ReferrerContactMethod>();
const selectFeature = (state: AppState) => state.referrer_contact_method;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectReferrerContactMethod = (id: number) =>
  createSelector(
    selectEntities,
    (referrer_contact_methods: Dictionary<RR.ReferrerContactMethod>) => referrer_contact_methods[id],
  );

export const fromReferrerContactMethod = {
  selectIds,
  selectEntities,
  selectAll,
  selectReferrerContactMethod,
};
