import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type ExactMatchTopicPayload = {
  age_metric: number;
  rarity_metric: number;
  recency_metric: number;
  title_metric: number;
  topicId: number;
};

@Injectable()
export class ExactMatchTopicHttpService {
  constructor(private http: HttpClient) {}

  findAll(data: ExactMatchTopicPayload): Observable<RR.ExactMatchTopic[]> {
    const { age_metric, rarity_metric, recency_metric, title_metric, topicId } = data;
    return this.http.post<RR.ExactMatchTopic[]>(`/api/topic/${topicId}/search/exact_match`, {
      age_metric,
      rarity_metric,
      recency_metric,
      title_metric,
    });
  }
}
