import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { SectionActions } from '../section/section.action';
import { TemplateActions } from '../template/template.action';

/**
 * Methods of interacting with the Subsections
 *
 * This describes the actions required to interface with the subsections, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the subsections related to a specific template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of subsections returned, while the
 * `getTemplateSuccess` being a subset of all subsections will only update the
 * values without deleting them all first.
 */

export const SubsectionActions = createActionGroup({
  source: 'Subsection',
  events: {
    addMany: props<{ subsections: RR.Subsection[] }>(),
    addOne: props<{ subsection: RR.Subsection }>(),
    upsertOne: props<{ subsection: RR.Subsection }>(),
    upsertMany: props<{ subsections: RR.Subsection[] }>(),
    removeOne: props<{ subsectionId: number }>(),
    reset: emptyProps(),
  },
});

export const SubsectionBatchActions = {
  delete: createBatchAction(
    '[Subsection] Delete Success Batch',
    props<{
      actions: {
        templateUpdateSuccessAction: ReturnType<typeof TemplateActions.updateSuccess>;
        subsectionDeleteSuccessAction: ReturnType<typeof SubsectionActions.removeOne>;
      };
    }>(),
  ),
  create: createBatchAction(
    '[Subsection] Create Success Batch',
    props<{
      actions: {
        sectionUpdateSuccessAction: ReturnType<typeof SectionActions.upsertOne>;
        subsectionCreateSuccessAction: ReturnType<typeof SubsectionActions.addOne>;
      };
    }>(),
  ),
};
