import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { PathologyActions } from '../../pathology/pathology.action';
import { PatientActions } from '../../patient/patient.action';
import { BodyPartActions } from '../../template/body-part/body-part.action';
import { PresetTitleActions, PresetTitleBatchActions } from '../preset-title/preset-title.action';
import { fromPresetTitle } from '../preset-title/preset-title.selector';
import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { ReportActions } from '../report/report.action';
import { PresetBatchActions } from './preset.action';
import {
  CreatePreset,
  PresetHttpService,
  PresetPayload,
  PresetSearchAllBody,
  PresetSearchBody,
  UsePreset,
} from './preset.service';

@Injectable()
export class PresetEffect {
  constructor(
    private store: Store<AppState>,
    private service: PresetHttpService,
    private message: MessageService,
  ) {}

  search(
    body: PresetSearchBody,
    params: {
      from: number;
      size: number;
    },
  ) {
    return this.service.search(body, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response) => {
        return PresetBatchActions.searchPresetSuccess({
          response,
          actions: {
            findAllReportSuccess: ReportActions.upsertMany({ reports: response.reports }),
            findAllPresetTitleSuccess: PresetTitleActions.findAllSuccess({ preset_titles: response.preset_titles }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  searchAll(body: PresetSearchAllBody) {
    return this.service.searchAll(body).pipe(
      this.message.handleHttpErrorPipe,
      map((response) => {
        return PresetBatchActions.searchAllPresetSuccess({
          response,
          actions: {
            findAllReportSuccess: ReportActions.upsertMany({ reports: response.reports }),
            findAllPresetTitleSuccess: PresetTitleActions.findAllSuccess({ preset_titles: response.preset_titles }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((response) => {
        return PresetBatchActions.findAllPresetSuccess({
          response,
          actions: {
            findAllReportSuccess: ReportActions.upsertMany({ reports: response.reports }),
            findAllPresetTitleSuccess: PresetTitleActions.findAllSuccess({ preset_titles: response.preset_titles }),
            findBodyPart: BodyPartActions.findManySuccess({ body_parts: response.body_parts }),
            findPathology: PathologyActions.findManySuccess({ pathologies: response.pathologies }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(data: CreatePreset) {
    return this.service.createPreset(data).pipe(
      this.message.handleHttpErrorPipe,
      map(({ report, patient }) => {
        return PresetBatchActions.createPresetSuccess({
          actions: {
            createReportSuccess: ReportActions.createSuccess({
              report,
            }),
            createPatientSuccess: PatientActions.createSuccess({
              patient,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(presetTitleId: number, changes: PresetPayload) {
    return this.service.updatePresetTitle(presetTitleId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map(({ report, preset_title }) => {
        return PresetBatchActions.updatePresetSuccess({
          actions: {
            updateReportSuccess: ReportActions.upsertOne({
              report,
            }),
            updatePresetTitleSuccess: PresetTitleActions.updateSuccess({
              preset_title,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findPresetTitle(presetTitleId: number) {
    return this.store.select(fromPresetTitle.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findPresetTitle(presetTitleId)),
      this.message.handleHttpErrorPipe,
      map((response) => {
        return PresetTitleBatchActions.findSuccess({
          actions: {
            presetTitle: PresetTitleActions.findSuccess({ preset_title: response.preset_title }),
            bodyPart: BodyPartActions.findManySuccess({ body_parts: [response.body_part] }),
            pathology: PathologyActions.findManySuccess({ pathologies: [response.pathology] }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  usePreset(data: UsePreset) {
    return this.service.usePreset(data).pipe(
      this.message.handleHttpErrorPipe,
      map((response) => mapChangeTrackerToSuccessActions(response)),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
