import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';

@Injectable()
export class SubsectionChoiceHttpService {
  constructor(private http: HttpClient) {}

  create(subsection_id: number, topic_id: number) {
    return this.http.post<ChangeTrackerResponse>('/api/subsection_choice', { subsection_id, topic_id });
  }

  update(subsectionChoiceId: number, changes: Partial<RR.SubsectionChoice>) {
    return this.http.put<ChangeTrackerResponse>(`/api/subsection_choice/${subsectionChoiceId}`, changes);
  }

  delete(subsectionChoiceId: number) {
    return this.http.delete<ChangeTrackerResponse>(`/api/subsection_choice/${subsectionChoiceId}`);
  }
}
