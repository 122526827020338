import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Represents the data returned by the tag suggestions GET API.
 *
 * This data is used within the APIs:
 *
 * - GET /analytics/topic/<topic_id>/suggested
 *
 * @param tags: An array of TagSuggestion objects corresponding to each suggested tag.
 * @param is_ml: Whether the probabilities were generated from a ML model.
 *
 */
export interface TagSuggestionsInfo {
  tags: RR.TagSuggestion[];
  is_ml: boolean;
}

@Injectable()
export class TagHttpService {
  constructor(private http: HttpClient) {}

  getSuggested(topicId: number, section?: RR.TagSearchSection): Observable<TagSuggestionsInfo> {
    // @ts-expect-error strictNullChecks
    const params = new HttpParams({ fromObject: { section } });
    return this.http.get<TagSuggestionsInfo>(`/api/ml/topic/${topicId}/tag/suggested`, { params });
  }

  getTemplateStructure(topicId: number, section: RR.TagSearchSection): Observable<RR.StructuredTagSubsection[]> {
    return this.http.get<RR.StructuredTagSubsection[]>(`/api/topic/${topicId}/section/${section}`);
  }

  getExclusiveTags(topicId: number, prefillSuggestedTags: number[]) {
    return this.http.post<{ exclusive_tags: number[] }>(`/api/ml/topic/${topicId}/tag/exclusive`, {
      prefill_suggested_tags: prefillSuggestedTags,
    });
  }
}
