import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { ElementActions } from './element.action';

/**
 * inherits these properties:
 * 1. ids: string[] | number[];
 *  ids are rarely used but they are sometimes useful for defining how the entities should be ordered.
 *
 * 2. entities: Dictionary<T>;
 *  This is a key value store. It maps each Element id to an Element entity.
 */
export interface ElementState extends EntityState<RR.Element> {}

const adapter = createEntityAdapter<RR.Element>();
const initialState = adapter.getInitialState();

export const elementReducer = createReducer(
  initialState,
  // The entity adapater provides a way to immutably update entities in the store.
  // source for these functions:
  //  https://github.com/ngrx/platform/blob/83bb8a9e4c6492470e5a13e0a93949f0a1acbe79/modules/entity/src/unsorted_state_adapter.ts
  // immutability is handled separately here:
  //  https://github.com/ngrx/platform/blob/83bb8a9e4c6492470e5a13e0a93949f0a1acbe79/modules/entity/src/state_adapter.ts#L24
  on(ElementActions.addMany, (state, { elements }) => adapter.addMany(elements, state)),
  on(ElementActions.addOne, (state, { element }) => adapter.addOne(element, state)),
  on(ElementActions.upsertOne, (state, { element }) => adapter.upsertOne(element, state)),
  on(ElementActions.removeOne, (state, { elementId }) => adapter.removeOne(elementId, state)),
  // Set loaded back to false.
  on(ElementActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
