import { createActionGroup, props } from '@ngrx/store';

/**
 * Ngrx Actions for TextObjectChoice
 *
 */
export const TextObjectChoiceActions = createActionGroup({
  source: 'Text Object Choice',
  events: {
    upsertMany: props<{ textObjectChoices: RR.TextObjectChoice[] }>(),
    addMany: props<{ textObjectChoices: RR.TextObjectChoice[] }>(),
    removeMany: props<{ textObjectChoiceIds: number[] }>(),
  },
});
