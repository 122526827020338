import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { TopicActions } from '../topic/topic.action';
import { JustificationActions } from './justification.action';
import { JustificationHttpService, JustificationPayload } from './justification.service';

@Injectable()
export class JustificationEffect {
  constructor(
    private store: Store<AppState>,
    private service: JustificationHttpService,
    private message: MessageService,
  ) {}

  create(topicId: number, data: JustificationPayload) {
    return this.service.create(topicId, data).pipe(
      this.message.handleHttpErrorPipe,
      map(({ justification, topic }) =>
        JustificationActions.createSuccessBatch({
          actions: {
            justificationCreateSuccess: JustificationActions.createSuccess({ justification }),
            topicUpdateSuccess: TopicActions.upsertOne({ topic }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(justificationId: number) {
    return this.service.delete(justificationId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topic }) =>
        JustificationActions.deleteSuccessBatch({
          actions: {
            justificationDeleteSuccess: JustificationActions.deleteSuccess({ justificationId }),
            topicUpdateSuccess: TopicActions.upsertOne({ topic }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
