import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { FeedbackActions } from '../feedback/feedback.action';
import { FeedbackCommentActions, FeedbackCommentBatchActions } from './feedback-comment.action';
import {
  CreateFeedbackComment,
  CreateFeedbackCommentResponse,
  FeedbackCommentHttpService,
} from './feedback-comment.service';

@Injectable()
export class FeedbackCommentEffect {
  constructor(
    private store: Store<AppState>,
    private service: FeedbackCommentHttpService,
  ) {}

  findAll(params?: HttpParams) {
    return this.service.findAll(params).pipe(
      map((feedbackComments: RR.FeedbackComment[]) => FeedbackCommentActions.findAllSuccess({ feedbackComments })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(feedbackComment: CreateFeedbackComment) {
    return this.service.create(feedbackComment).pipe(
      map((data: CreateFeedbackCommentResponse) =>
        FeedbackCommentBatchActions.createFeedbackCommentSuccess({
          actions: {
            updateFeedbackSuccess: FeedbackActions.updateSuccess({ feedback: data.feedback }),
            createFeedbackCommentSuccess: FeedbackCommentActions.createSuccess({
              feedbackComment: data.feedback_comment,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(feedbackCommentId: number, changes: Partial<RR.FeedbackComment>) {
    return this.service.update(feedbackCommentId, changes).pipe(
      map((feedbackComment) => FeedbackCommentActions.updateSuccess({ feedbackComment })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
