import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';

export type PresetSearchBody = {
  topic_id: number;
  search: string;
};

export type PresetSearchAllBody = {
  body_part_id: number;
  pathology_id: number;
};

export type PresetSearchResponse = {
  reports: RR.Report[];
  statement_counts: { [reportId: number]: number };
  preset_titles: RR.PresetTitle[];
  body_parts: RR.BodyPart[];
  pathologies: RR.Pathology[];
};

export type PresetPayload = {
  preset_notes: string;
  body_part_id: number;
  pathology_id: number;
};

export type CreatePreset = {
  report: PresetPayload;
  patient: Pick<RR.Patient, 'patient_dob' | 'patient_sex'>;
};

export type UsePreset = {
  from_report_id: number;
  to_topic_id: number;
};

@Injectable()
export class PresetHttpService {
  constructor(private http: HttpClient) {}

  search(
    body: PresetSearchBody,
    params: {
      from: number;
      size: number;
    },
  ) {
    return this.http.post<PresetSearchResponse>('/api/preset/search', body, {
      params,
    });
  }

  // Search all similar presets
  searchAll(body: PresetSearchAllBody) {
    return this.http.post<PresetSearchResponse>('/api/preset/search_all', body);
  }

  findAll(): Observable<PresetSearchResponse> {
    return this.http.get<PresetSearchResponse>('/api/presets');
  }

  createPreset(data: CreatePreset) {
    return this.http.post<{ report: RR.Report; patient: RR.Patient }>('/api/preset', data);
  }

  updatePresetTitle(presetTitleId: number, changes: PresetPayload) {
    return this.http.put<{ report: RR.Report; preset_title: RR.PresetTitle }>(
      `/api/preset_title/${presetTitleId}`,
      changes,
    );
  }

  findPresetTitle(presetTitleId: number) {
    return this.http.get<{
      preset_title: RR.PresetTitle;
      body_part: RR.BodyPart;
      pathology: RR.Pathology;
    }>(`/api/preset_title/${presetTitleId}`);
  }

  usePreset(data: UsePreset) {
    return this.http.post<ChangeTrackerResponse>('/api/preset/use', data);
  }
}
