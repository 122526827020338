import { createEntityAdapter } from '@ngrx/entity';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Justification>();

const selectFeature = (state: AppState) => state.justification;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

export const fromJustifications = {
  selectIds,
  selectEntities,
  selectAll,
};
