import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { InvoiceActions } from '../invoice/invoice.action';
import { PatientActions } from '../patient/patient.action';
import { AuditEventActions } from '../report/audit-event/audit-event.action';
import { ReportActions } from '../report/report/report.action';
import { SendEventActions } from '../report/send-event/send-event.action';
import { UserActions } from '../user/user/user.action';
import { BookingActions, BookingBatchActions } from './booking.action';
import {
  SameDayBookingResponse,
  BookingHttpService,
  FetchSlotParams,
  GetBookingResponse,
  PartialBooking,
  SendBookingConfirmSMSResponse,
  CreateBookingBody,
} from './booking.service';

@Injectable()
export class BookingEffect {
  constructor(
    private store: Store<AppState>,
    private service: BookingHttpService,
    private message: MessageService,
  ) {}

  find(bookingId: number) {
    return this.service.find(bookingId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: GetBookingResponse) =>
        BookingBatchActions.findSuccess({
          actions: {
            findBookingSuccess: BookingActions.findSuccess({ booking: data.booking }),
            findManySendEventsSuccess: SendEventActions.findManySuccess({ sendEvents: data.send_events }),
            reportFindManySuccess: ReportActions.upsertMany({ reports: data.reports }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(booking: CreateBookingBody, skipValidations?: string[]) {
    return this.service.create(booking, skipValidations).pipe(
      // this.message.handleHttpErrorPipe,
      map((booking: RR.Booking) => BookingActions.createSuccess({ booking })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(id: number, changes: PartialBooking) {
    return this.service.update(id, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((booking: RR.Booking) => BookingActions.updateSuccess({ booking })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAvailableSlots(slotData: FetchSlotParams) {
    return this.service.findAvailableSlots(slotData).pipe(
      this.message.handleHttpErrorPipe,
      map(({ slots, users }) =>
        BookingBatchActions.findSlotsSuccess({
          slots,
          actions: {
            userFindManySuccess: UserActions.upsertMany({ users }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: any) {
    return this.service.search(queryBody).pipe(
      this.message.handleHttpErrorPipe,
      map((response) =>
        BookingBatchActions.searchSuccess({
          count: response.count,
          actions: {
            bookingFindManySuccess: BookingActions.findManySuccess({
              bookings: response.bookings,
            }),
            findManySendEventsSuccess: SendEventActions.findManySuccess({ sendEvents: response.send_events }),
            auditEventFindManySuccess: AuditEventActions.findManySuccess({ auditEvents: response.audit_events }),
            userFindManySuccess: UserActions.upsertMany({ users: response.users }),
            reportFindManySuccess: ReportActions.upsertMany({ reports: response.reports }),
            patientFindManySuccess: PatientActions.findManySuccess({ patients: response.patients }),
            findInvoiceSuccess: InvoiceActions.findManySuccess({ invoices: response.invoices }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(id: number, body: { password: string; cancelled_notes: string }) {
    return this.service.delete(id, body).pipe(
      this.message.handleHttpErrorPipe,
      map((booking: RR.Booking) => BookingActions.updateSuccess({ booking })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  updateBookingPatient(bookingId: number, bookingPatientId: number, changes: Partial<Omit<RR.BookingPatient, 'id'>>) {
    return this.service.updateBookingPatient(bookingPatientId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((bookingPatient: RR.BookingPatient) =>
        BookingActions.updateBookingPatientSuccess({ bookingId, bookingPatient }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * Search all patient's same day bookings from a booking
   * @param bookingId
   * @returns
   */
  searchPatientSameDayBookings(bookingId: number) {
    return this.service.searchPatientSameDayBookings(bookingId).pipe(
      this.message.handleHttpErrorPipe,
      map((response: SameDayBookingResponse) =>
        BookingBatchActions.searchSameDaySuccess({
          actions: {
            bookingFindManySuccess: BookingActions.findManySuccess({
              bookings: response.bookings,
            }),
            reportFindManySuccess: ReportActions.upsertMany({ reports: response.reports }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  sendBookingConfirmationSMS(bookingId: number, sendTo: string, message: string) {
    return this.service.sendBookingConfirmationSMS(bookingId, sendTo, message).pipe(
      this.message.handleHttpErrorPipe,
      map((data: SendBookingConfirmSMSResponse) =>
        BookingBatchActions.sendConfirmationSMSSuccess({
          actions: {
            updateBookingSuccess: BookingActions.updateSuccess({ booking: data.booking }),
            createSendEventSuccess: SendEventActions.sendSuccess({ sendEvents: [data.send_event] }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  sendBookingPreparationSMS(bookingId: number, sendTo: string, message: string) {
    return this.service.sendBookingPreparationSMS(bookingId, sendTo, message).pipe(
      this.message.handleHttpErrorPipe,
      map((data: SendBookingConfirmSMSResponse) =>
        BookingBatchActions.sendPreparationSMSSuccess({
          actions: {
            updateBookingSuccess: BookingActions.updateSuccess({ booking: data.booking }),
            createSendEventSuccess: SendEventActions.sendSuccess({ sendEvents: [data.send_event] }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  restoreCancelledBooking(bookingId: number) {
    return this.service.restoreCancelledBooking(bookingId).pipe(
      this.message.handleHttpErrorPipe,
      map((booking: RR.Booking) => BookingActions.findSuccess({ booking })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
