import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { NumberInArrayPipe } from 'app/shared/pipes/number-in-array-pipe';
import { AppState } from 'app/store';
import { fromInvoice, InvoiceEffect } from 'app/store/invoice';
import { ReportEffect } from 'app/store/report/report';
import { of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { InvoiceFormMetadataComponent } from '../../components/invoice-form/invoice-form-metadata/invoice-form-metadata.component';

@Component({
  standalone: true,
  imports: [CommonModule, InvoiceFormMetadataComponent, NumberInArrayPipe],
  selector: 'rr-invoice-link-report-modal',
  templateUrl: './invoice-link-report-modal.component.html',
  styleUrls: ['./invoice-link-report-modal.component.css'],
})
export class InvoiceLinkReportModalComponent implements OnInit, OnDestroy {
  invoice_id: number;
  isSearching = true;
  filteredReportIds: number[] = [];
  invoice: RR.Invoice | undefined;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private reportEffect: ReportEffect,
    private store: Store<AppState>,
    private invoiceEffect: InvoiceEffect,
  ) {}

  ngOnInit(): void {
    const invoice$ = this.store.select(fromInvoice.selectInvoice(this.invoice_id));

    this.subscription.add(
      invoice$.subscribe((invoice) => {
        this.invoice = invoice;
      }),
    );

    this.subscription.add(
      invoice$
        .pipe(
          filterDefined(),
          take(1),
          map((invoice) => invoice.patient_id),
          switchMap((patient_id) => (patient_id ? this.reportEffect.patientSearch(patient_id) : of(undefined))),
        )
        .subscribe((action) => {
          if (action !== undefined) {
            this.isSearching = false;
            this.filteredReportIds = action.actions.findReportSuccess.reports
              .map((report) => report.id)
              .filter((id) => !this.invoice?.reports.includes(id));
          }
        }),
    );
  }

  linkReport(reportId: number) {
    this.subscription.add(this.invoiceEffect.linkReport(this.invoice_id, reportId).subscribe());
  }

  // @ts-expect-error noImplicitAny
  unlinkReport(reportId) {
    this.subscription.add(this.invoiceEffect.unlinkReport(this.invoice_id, reportId).subscribe());
  }

  static open(modal: NgbModal, invoice_id: number) {
    const modalRef = modal.open(InvoiceLinkReportModalComponent, {
      size: 'lg',
      centered: true,
      scrollable: true,
    });

    const component: InvoiceLinkReportModalComponent = modalRef.componentInstance;
    component.invoice_id = invoice_id;

    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
