import '../slatement-react/slate-types.d.ts';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbDropdown, NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { createPopper, Instance } from '@popperjs/core';
import { ATTRIBUTE_MATCH_DROPDOWN, SLATE_EDITOR_ID } from 'app/app.constants';
import { BindObservable, filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { TemplateService } from 'app/core/services/template.service';
import {
  AttributeMatch,
  DenormalisedAttribute,
  findLongestMatches,
} from 'app/modules/editor/slatement/slatement-attributes/bruteForceAttributeSearch';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromCurrentReport } from 'app/store/report/report';
import { fromAttributeOption } from 'app/store/template/attribute-option/attribute-option.selector';
import { fromAttributeSet } from 'app/store/template/attribute-set/attribute-set.selector';
import { AttributeGroup } from 'app/store/template/default-attribute';
import { StatementEffect, fromStatement } from 'app/store/template/statement';
import { fromTextObject } from 'app/store/template/text-object';
import { KeyboardEventHandler } from 'react';
import { Observable, Subject, Subscription, auditTime, combineLatest, filter, switchMap, take } from 'rxjs';
import { BaseRange, Descendant, Editor, Range, Transforms, Element, Text } from 'slate';
import { ReactEditor } from 'slate-react';

import { EditType } from '../../statement-edit/statement-edit.component';
import { StatementNumberModalComponent } from '../../statement-edit/statement-number-modal/statement-number-modal.component';
import {
  SameTextAttributeResult,
  SwitchAttributeComponent,
} from '../../statement/switch-attribute/switch-attribute.component';
import { AttributeMatchComponent } from '../slatement-attributes/attribute-match/attribute-match.component';
import { SlatementMountComponent } from '../slatement-mount/slatement-mount.component';
import {
  findAttributeSetNameById,
  findPathOfNode,
  fixSpacingAround,
  getDenormalisedAttributes,
  getTextBeforeCursorUntilVoid,
  getTextFromSelection,
  moveCursorAfter,
  rangeBeforeCursorIsStarStar,
  slateValueToTextObjects,
  textObjectsToEditorValue,
} from '../slatement-react/helpers';
import {
  AttributeTextObjectElement,
  DateTextObjectElement,
  NumberTextObjectElement,
  ParagraphElement,
} from '../slatement-react/slate-types';

@Component({
  selector: 'rr-slatement-angular',
  standalone: true,
  imports: [
    CommonModule,
    SlatementMountComponent,
    AttributeMatchComponent,
    SwitchAttributeComponent,
    StoreSelectPipe,
    NgbDropdownModule,
    SharedModule,
  ],
  templateUrl: './slatement-angular.component.html',
  styleUrls: ['./slatement-angular.component.css'],
})
export class SlatementAngularComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() editType: EditType = 'editStatement';
  editType$: Observable<EditType>;
  @ViewChild('attrDropdown') attrDropdown: NgbDropdown | undefined;
  @BindObservable() @Input() statementId: number | undefined;
  statementId$: Observable<number | undefined>;
  initialValue: Descendant[] | undefined;
  @Input() templateId: number;
  @Input() region: RR.Region | null;
  @Input() devMode = false;
  @Input() attributeGroup: AttributeGroup | undefined;
  @Output() slateOnChange = new EventEmitter<Descendant[]>();
  @Output() slateOnEditor = new EventEmitter<Editor>();
  @Output() slateOnClickAttribute = new EventEmitter();

  // Value of the slate editor, emitted from the React component.
  slateValue: Descendant[] | undefined;
  slateValue$ = new Subject<Descendant[]>();
  editor: Editor | undefined;

  subscription = new Subscription();
  matches: AttributeMatch[] = [];
  starStarRange: BaseRange | undefined;
  attributeSetMatch: AttributeMatch | undefined;
  denormalisedAttributes: DenormalisedAttribute[] | undefined;
  shortlists: { [key: number]: number[] | undefined };
  // TODO: show a visual indicator that the search is happening
  searchStatus: 'will-search' | 'searched' = 'searched';

  shouldDisplayRegionSuggestion = false;

  formattedRegionMatch: { regionSyntax: string; regionKey: string }[] = [];

  reportId: number | undefined = undefined;

  popperInstance: Instance | null;
  ATTRIBUTE_MATCH_DROPDOWN = ATTRIBUTE_MATCH_DROPDOWN;

  constructor(
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private statementEffect: StatementEffect,
    private messageService: MessageService,
    private templateService: TemplateService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.slateValue$.pipe(auditTime(100)).subscribe(() => {
        // Can run this more often than below, because it's just a simple check.
        this.assertEditorDefined();
        this.starStarRange = rangeBeforeCursorIsStarStar(this.editor);
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      this.slateValue$.subscribe(() => {
        // When we insert an attribute quickly after typing it inserts the previous match. If there are extra
        // characters, we need to delete those too. For example:
        // We found a match for "upper".
        // The `matchingText` is "u". The "p" was typed later.
        // History of up| chest pain.
        //            ^     `matchIndex` 12
        //              ^   `offset` 14
        // We use this `searchStatus` in the e2es to wait for the search to complete (`cy.get('.dropdown.searched')`)
        this.searchStatus = 'will-search';
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      this.slateValue$.pipe(auditTime(500)).subscribe((_value) => {
        this.searchAttributeBeforeCursor();
        this.searchStatus = 'searched';
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      getDenormalisedAttributes(
        this.store.select(fromAttributeSet.selectAll),
        this.store.select(fromAttributeOption.selectEntities),
      ).subscribe((denormalisedAttributes) => {
        this.denormalisedAttributes = denormalisedAttributes;
      }),
    );

    this.subscription.add(
      this.templateService.getAttributeShortlists().subscribe((shortlists) => {
        this.shortlists = shortlists;
      }),
    );

    this.subscription.add(
      this.editType$
        .pipe(
          filter(() => this.isTypeEdit()),
          switchMap(() => this.statementId$),
        )
        .subscribe(() => {
          this.initialiseFromStatement();
        }),
    );
    this.subscription.add(
      this.editType$.pipe(filter(() => this.isTypeCreate())).subscribe(() => {
        const paragraph: ParagraphElement = {
          // Add a `paragraph` container because `Text` nodes cannot be inserted as `Editor.children`
          type: 'paragraph',
          children: [{ text: '' }],
        };
        this.initialValue = [paragraph];
      }),
    );

    this.subscription.add(
      this.store
        .select(fromCurrentReport.selectReport)
        .pipe(filterDefined())
        .subscribe((report) => {
          this.reportId = report.id;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Called by StatementEditComponent to reset the text.
  public initialiseFromStatement() {
    this.assertIsTypeEdit();
    this.subscription.add(
      combineLatest([
        this.store.select(fromStatement.selectStatement(this.statementId)),
        this.store.select(fromStatement.selectTextObjects(this.statementId)),
      ])
        .pipe(take(1))
        .subscribe(([statement, textObjects]) => {
          if (statement === undefined) {
            return;
          }
          const children = textObjectsToEditorValue(textObjects);
          if (children.length === 0) {
            // Paragraphs cannot be empty. If there are no text objects, add an empty text node.
            children.push({ text: '' });
          }
          const paragraph: ParagraphElement = {
            // Add a `paragraph` container because `Text` nodes cannot be inserted as `Editor.children`
            type: 'paragraph',
            children,
          };
          this.initialValue = [paragraph];
        }),
    );
  }

  /**
   * Used to narrow the type of SlatementAngularComponent to one that has an editor.
   */
  assertEditorDefined(): asserts this is SlatementAngularComponent & { editor: Editor } {
    if (!this.editor) {
      throw new Error('editor is undefined');
    }
  }

  isTypeEdit(): this is SlatementAngularComponent & { statementId: number } {
    const types = ['editStatement', 'editNotepadStatement'];
    return types.includes(this.editType);
  }

  assertIsTypeEdit(): asserts this is SlatementAngularComponent & { statementId: number } {
    if (!this.isTypeEdit()) {
      throw new Error('This method can only be called when type is editStatement');
    }
  }

  isTypeCreate(): this is SlatementAngularComponent & { statementId: undefined } {
    const types: EditType[] = [
      'createStatement',
      'createNotepadStatement',
      'insertStatement',
      'insertNotepadStatement',
      'createElement',
    ];
    return types.includes(this.editType);
  }

  searchAttributeBeforeCursor(): void {
    this.assertEditorDefined();

    const { selection } = this.editor;

    if (!selection) return;

    const textBeforeCursorUntilVoid = getTextBeforeCursorUntilVoid(this.editor);
    const textFromSelection = getTextFromSelection(this.editor);

    const textToSearch = Range.isCollapsed(selection) ? textBeforeCursorUntilVoid : textFromSelection;

    // Reset matches and attributeSetMatch
    this.matches = [];
    this.attributeSetMatch = undefined;
    this.formattedRegionMatch = [];

    this.shouldDisplayRegionSuggestion = false;

    if (!this.denormalisedAttributes || textToSearch.length === 0) return;

    // Match double square brackets [[
    const doubleSquareBracketRegex = /\[\[$/;
    const doubleSquareBracketMatch = textToSearch.match(doubleSquareBracketRegex);

    // Match a # followed by an attribute set id. e.g. #123
    const attributeSetNumberRegex = /#(\d+)$/;
    const attributeSetNumberMatch = textToSearch.match(attributeSetNumberRegex);
    if (attributeSetNumberMatch !== null) {
      // Since it matched this format, don't do the standardard attribute search
      const typedId = parseInt(attributeSetNumberMatch[1]);
      if (!isNaN(typedId)) {
        const attributeSet = findAttributeSetNameById({
          typeId: typedId,
          attributes: this.denormalisedAttributes,
        });
        if (attributeSet) {
          this.attributeSetMatch = {
            attributeOption: attributeSet.attributeOption,
            attributeSetId: attributeSet.attributeSet.id,
            length: attributeSetNumberMatch[1].length + 1, // +1 for the #
          };
        }
      }
    } else if (doubleSquareBracketMatch !== null && this.region) {
      const { disc_name, name, next_name, space_name } = this.region;
      const regionDetails: Pick<RR.Region, 'disc_name' | 'name' | 'next_name' | 'space_name'> = {
        disc_name,
        name,
        next_name,
        space_name,
      };
      this.formattedRegionMatch = Object.keys(regionDetails).map((regionKey) => {
        const regionSyntax = `[[${regionKey}]]`;
        return {
          regionSyntax: `${regionDetails[regionKey]} - ${regionSyntax}`,
          regionKey: regionSyntax,
        };
      });

      // added this region search so the dropdown do not overlap
      this.shouldDisplayRegionSuggestion = true;
    } else {
      // For matching attributes
      const matches = findLongestMatches({
        text: textToSearch,
        attributes: this.denormalisedAttributes,
      });
      this.matches = matches;
    }

    if (this.attrDropdown && (this.matches.length > 0 || this.attributeSetMatch)) this.attrDropdown.open();
  }

  // Uses an anonymous function because have to bind `this` because otherwise it will refer to the React component
  // eslint-disable-next-line no-restricted-syntax
  slateOnChangeCallback = (value: Descendant[]) => {
    this.slateOnChange.emit(value);
    this.slateValue$.next(value);
    this.initialisePopper();
  };

  // Uses an anonymous function because have to bind `this` because otherwise it will refer to the React component
  // eslint-disable-next-line no-restricted-syntax
  slateOnEditorCallback = (editor: Editor) => {
    this.slateOnEditor.emit(editor);
    this.editor = editor;
  };

  // eslint-disable-next-line no-restricted-syntax
  slateOnKeyDownCallback: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Tab') {
      // Press Tab to insert number attribute

      this.assertEditorDefined();
      const starStarRange = rangeBeforeCursorIsStarStar(this.editor);
      if (starStarRange) {
        this.insertNumberAttribute(starStarRange);
        event.preventDefault(); // Don't move the focus out of the textbox
      }

      // Press Tab to insert first matching attribute
      if (this.matches.length > 0) {
        this.insertAttributeMatch(this.matches[0]);
        event.preventDefault(); // Don't move the focus out of the textbox
      }

      if (this.attributeSetMatch) {
        this.insertAttributeMatch(this.attributeSetMatch);
        event.preventDefault();
      }

      if (this.formattedRegionMatch.length > 0) {
        this.insertRegionText(this.formattedRegionMatch[0].regionKey);
        event.preventDefault();
      }
    }
  };

  focusedAttributeTextElement: AttributeTextObjectElement | undefined;

  // eslint-disable-next-line no-restricted-syntax
  slateOnClickAttributeCallback = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    element: AttributeTextObjectElement,
  ) => {
    this.focusedAttributeTextElement = element;
  };

  // eslint-disable-next-line no-restricted-syntax
  slateOnClickAttributeNumberCallback = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    element: NumberTextObjectElement,
  ) => {
    event.stopPropagation();
    this.openStatementNumberModal(element);
  };

  // eslint-disable-next-line no-restricted-syntax
  slateOnClickAttributeDateCallback = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    element: DateTextObjectElement,
  ) => {
    return element;
  };

  selectTextObject = fromTextObject.selectTextObject;

  switchAttributeSet(event: SameTextAttributeResult, attributeTextObjectElement: AttributeTextObjectElement) {
    this.assertEditorDefined();
    const editor = this.editor;
    if (!editor.selection) {
      throw new Error('editor.selection is undefined');
    }

    const newNode: AttributeTextObjectElement = {
      type: 'rr-attribute',
      children: [{ text: '' }],
      text_object_id: undefined,
      attribute_set_id: event.attributeSetId,
      order: this.shortlists[event.attributeSetId] || [],
      default_option_id: event.sameTextAttribute.id,
    };

    const path = findPathOfNode(editor, attributeTextObjectElement);
    if (!path) {
      throw new Error('Could not find path of node');
    }

    Transforms.setNodes(editor, newNode, { at: path });
    ReactEditor.focus(editor);
    // Transforms.move(editor); // put the cursor after the inserted node
  }

  slateValueToTextObjects() {
    if (!this.slateValue) {
      throw new Error('slateValue is undefined');
    }
    return slateValueToTextObjects(this.slateValue);
  }

  /**
   * @deprecated dev only
   */
  save() {
    this.assertIsTypeEdit();
    const textObjects = this.slateValueToTextObjects();
    this.subscription.add(
      this.statementEffect.patchTextObjects(this.statementId, textObjects).subscribe(() => {
        this.messageService.add({
          title: 'Success',
          type: 'success',
          message: 'Edit statement successfully.',
        });
      }),
    );
  }

  insertAttributeMatch(match: AttributeMatch) {
    this.assertEditorDefined();
    const editor = this.editor;
    if (!editor.selection) {
      throw new Error('editor.selection is undefined');
    }

    const node: AttributeTextObjectElement = {
      type: 'rr-attribute',
      children: [{ text: '' }],

      text_object_id: undefined,
      attribute_set_id: match.attributeSetId,
      order: this.shortlists[match.attributeSetId] || [],
      default_option_id: match.attributeOption.id,
    };

    // Replace the entire word if the cursor is positioned anywhere within it.
    // This includes scenarios where the cursor is at the beginning or end of the word.
    // Exclude punctuation if the cursor is between a word and any adjacent punctuation mark.

    const { anchor } = editor.selection;

    // Get the node at the current selection
    const [nodeAtPath] = Editor.node(editor, anchor.path);

    if (!Text.isText(nodeAtPath)) return;

    const text = nodeAtPath.text;
    const anchorOffset = anchor.offset;

    // Find the start and end positions of the word
    const startPosition = text.lastIndexOf(' ', anchorOffset - 1) + 1;

    let endPosition = text.indexOf(' ', anchorOffset);
    if (endPosition === -1) {
      endPosition = text.length;
    }

    // Check punctuation at the end of the word
    const punctuationRegex = /[.,;?!:]+$/;
    const word = text.substring(startPosition, endPosition);
    const adjustedEndPosition = word.search(punctuationRegex);
    if (adjustedEndPosition !== -1) {
      endPosition = startPosition + adjustedEndPosition;
    }

    // Create the range for the word
    const start = { path: anchor.path, offset: startPosition };
    const end = { path: anchor.path, offset: endPosition };
    const range = { anchor: start, focus: end };

    Transforms.delete(editor, { at: range });
    Transforms.insertNodes(editor, node);

    fixSpacingAround(editor, node);
    moveCursorAfter(editor, node);
  }

  insertNumberAttribute(range: BaseRange) {
    this.assertEditorDefined();
    const editor = this.editor;
    const node: NumberTextObjectElement = {
      type: 'rr-number',
      children: [{ text: '' }],
      upper: null,
      lower: null,
      formula: null,
    };
    Transforms.delete(editor, {
      at: range,
    });
    Transforms.insertNodes(editor, node);

    fixSpacingAround(editor, node);
    moveCursorAfter(editor, node);
  }

  insertRegionText(region: string) {
    this.assertEditorDefined();
    const editor = this.editor;

    const { selection } = editor;
    if (!selection) return;

    const newSelection = {
      anchor: {
        path: selection.anchor.path,
        offset: selection.anchor.offset - 2,
      },
    };

    // New selection without the initial [[
    Transforms.setSelection(editor, newSelection);

    // Insert the selected region choice
    Transforms.insertText(editor, region);
    Transforms.move(editor);
    // cursor focus
    ReactEditor.focus(editor);
  }

  onMouseDownAttributeMatch(_event: MouseEvent, match: AttributeMatch) {
    this.insertAttributeMatch(match);
  }

  targetIsAttributeTextObjectElement(target: EventTarget | null): boolean {
    if (!(target instanceof HTMLElement)) {
      return false;
    }
    if (!target.closest('[data-slate-editor="true"]')) {
      // `ReactEditor.toSlateNode` fails if passed a random element from the DOM. This avoids that.
      return false;
    }
    this.assertEditorDefined();
    // Make sure Node is imported, otherwise typescript will get Node confused with other types of Node.
    const slateNode = ReactEditor.toSlateNode(this.editor, target);
    return Element.isElement(slateNode) && slateNode.type === 'rr-attribute';
  }

  openStatementNumberModal(element: NumberTextObjectElement) {
    const modalRef = StatementNumberModalComponent.open(this.modal, {
      report_id: this.reportId,
      openFrom: 'slateEditor',
      slateNumberElement: element,
    });
    const component: StatementNumberModalComponent = modalRef.componentInstance;
    this.subscription.add(
      component.formOutput.subscribe((formValues: NumberTextObjectElement) => {
        // This is called when the modal closes
        this.updateNumberAttributeBounds(formValues, element);
      }),
    );
  }

  updateNumberAttributeBounds(formValues: NumberTextObjectElement, element: NumberTextObjectElement) {
    this.assertEditorDefined();
    const editor = this.editor;
    if (!editor.selection) {
      throw new Error('editor.selection is undefined');
    }

    const node: NumberTextObjectElement = {
      type: 'rr-number',
      children: [{ text: '' }],
      upper: formValues.upper,
      lower: formValues.lower,
      formula: formValues.formula,
    };

    const path = findPathOfNode(editor, element);
    if (!path) {
      throw new Error('Could not find path of node');
    }

    Transforms.setNodes(editor, node, { at: path });
    ReactEditor.focus(editor);
  }

  initialisePopper() {
    const slateEditor = document.getElementById(SLATE_EDITOR_ID);

    if (!(slateEditor instanceof HTMLElement)) {
      return;
    }
    const dropdown = document.getElementById(ATTRIBUTE_MATCH_DROPDOWN);
    if (!(dropdown instanceof HTMLElement)) {
      return;
    }

    // Create a virtual reference element based on the cursor position
    const generateGetBoundingClientRect = () => {
      const domSelection = window.getSelection();

      // TODO: does returning this work?
      if (!domSelection || !domSelection.rangeCount) return slateEditor.getBoundingClientRect();

      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      return {
        width: rect.width,
        height: rect.height,
        top: rect.top + window.scrollY,
        right: rect.right + window.scrollX,
        bottom: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        toJSON: () => {
          // Added to satisfy typescript
          console.error('toJSON should not be called');
        },
      };
    };

    const virtualElement = {
      getBoundingClientRect: generateGetBoundingClientRect,
    };

    const modalBodyEl = document.querySelector('.modal-body');

    this.popperInstance = createPopper(virtualElement, dropdown, {
      placement: 'bottom-start', // position below the cursor
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: modalBodyEl,
          },
        },
        {
          name: 'computeStyles',
          options: {
            adaptive: true,
            gpuAcceleration: false,
          },
        },
      ],
    });
  }

  forceUpdateDropdownPosition() {
    // update the popper instance
    if (this.popperInstance) {
      this.popperInstance.update();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDropdownOpenChange(open: boolean) {
    requestAnimationFrame(() => {
      this.forceUpdateDropdownPosition();
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    // We manually handle the closing of the dropdown here, because relying on dropdown `openChange` events has race
    // conditions: e.g. another attribute gets clicked, sets the value, then after that the dropdown closes and resets
    // it back to undefined.
    if (!this.targetIsAttributeTextObjectElement(event.target)) {
      this.focusedAttributeTextElement = undefined;
    }
  }
}
