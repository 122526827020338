<div #dropdown="ngbDropdown" ngbDropdown (openChange)="openChange($event, dropdown)" class="mt-2">
  <button class="btn btn-sm btn-outline-dark" ngbDropdownToggle type="button">Add a DICOM SR rule</button>

  <!-- max-height so that it switches to above if there's no room at bottom -->
  <div ngbDropdownMenu class="scrollable-dropdown" style="max-width: 40em; max-height: 20em">
    <div class="mx-2">
      <input #searchInput type="text" class="form-control" placeholder="Search measurement" />
    </div>
    <button
      ngbDropdownItem
      *ngFor="let matchingMeasurement of filteredMeasurements"
      (click)="onChoose.emit(matchingMeasurement)"
      type="button"
    >
      <!-- modal closes without type="button" -->
      <div *ngIf="matchingMeasurement.measurement_rule != null">
        <span
          *ngIf="matchingMeasurement.measurement_rule_type === 'CUSTOM'"
          class="badge bg-primary me-1"
          rrTooltip="RULE_CUSTOM"
          >Custom</span
        >
        <span
          *ngIf="matchingMeasurement.measurement_rule_type === 'CUSTOM_GENERATED'"
          class="badge bg-secondary me-1"
          rrTooltip="RULE_CUSTOM_GENERATED"
          >Custom/Generated</span
        >
        <span
          *ngIf="matchingMeasurement.measurement_rule_type === 'SIMPLE'"
          class="badge bg-secondary me-1"
          rrTooltip="RULE_GENERATED"
          >Generated</span
        >
        <rr-measurement-rule [measurement_rule]="matchingMeasurement.measurement_rule"></rr-measurement-rule>
        {{ matchingMeasurement.measurement.numeric_value }}
      </div>
    </button>

    <div *ngIf="!measurements || measurements.length === 0">No DICOM SR available</div>
  </div>
</div>
