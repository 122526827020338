import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StatementParameterConditionGroup } from '../statement-parameter-condition-group/statement-parameter-condition-group.service';

export interface StatementParameterCondition {
  id: number | null;
  parameter_id: number;
  value: string;
  operator: string;
  disabled: boolean;
}

@Injectable()
export class StatementParameterConditionHttpService {
  constructor(private http: HttpClient) {}

  update(statement_parameter_condition: StatementParameterCondition, id: number) {
    return this.http.put<StatementParameterConditionGroup | undefined>(
      `/api/statement_parameter_condition/${id}`,
      statement_parameter_condition,
    );
  }

  create(groupId: number, statement_parameter_condition: Partial<StatementParameterCondition>) {
    return this.http.post<StatementParameterConditionGroup | undefined>(
      `/api/statement_parameter_condition/${groupId}`,
      statement_parameter_condition,
    );
  }

  delete(id: number) {
    return this.http.delete<StatementParameterConditionGroup | undefined>(`/api/statement_parameter_condition/${id}`);
  }
}
