import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Notice
 *
 */
export const SexSpecificWordActions = {
  findAllSuccess: createAction(
    '[SexSpecificWord] Find All Success',
    props<{ sex_specific_words: RR.SexSpecificWord[] }>(),
  ),
  createSuccess: createAction('[SexSpecificWord] Create Success', props<{ sex_specific_word: RR.SexSpecificWord }>()),
  deleteSuccess: createAction('[SexSpecificWord] Delete Success', props<{ sex_specific_word_id: number }>()),
};
