<div class="modal-header">
  <h5 class="modal-title">Feedback Help (H): Compare your draft report with the final report</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body d-flex flex-row">
  <div class="col-2">
    <rr-feedback-recipients
      [selectedUserId]="selectedUserId"
      [reportId]="report_id"
      [parent]="'SELF_CHECK_FEEDBACK'"
      (onUserSelected)="onSelectedUserChanged($event)"
    ></rr-feedback-recipients>
  </div>

  <div class="col-10 d-flex flex-column flex-grow-1 overflow-auto">
    <rr-report-text-diff *ngIf="selectedUserId" [userId]="selectedUserId" [reportId]="report_id"></rr-report-text-diff>
  </div>
</div>

<div class="modal-footer d-flex justify-content-end">
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
