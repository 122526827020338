import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { RegionActions } from 'app/store/template/region';
import { StatementActions } from 'app/store/template/statement';

/**
 * Describe all possible actions that can take place with a TagChoice.
 * TagChoices are selected tags in prefill search for a topic
 *
 */

export const TagChoiceActions = {
  // Reset the state of the store back to its initial state
  reset: createAction('[TagChoice] Reset'),

  loadTagChoices: createAction('[TagChoice] Load Divider Choices'),
  loadTagChoicesSuccess: createAction('[TagChoice] Load Divider Choices Success', props<{ tags: RR.TagChoice[] }>()),

  addTagChoiceSuccess: createAction('[TagChoice] Add Divider Choice Success', props<{ tag: RR.TagChoice }>()),

  deleteTagChoiceSuccess: createAction('[TagChoice] Delete Divider Choice Success', props<{ id: string }>()),

  autoSelectTagChoicesSuccess: createAction(
    '[TagChoice] Auto Select Divider Choices Success',
    props<{ tags: RR.TagChoice[] }>(),
  ),

  copyTagChoicesSuccess: createAction('[TagChoice] Copy Divider Choices Success', props<{ tags: RR.TagChoice[] }>()),

  toggleLoading: createAction('[TagChoice] Toggle Loading'),
};

const findTemplateDataSuccess = createBatchAction(
  '[TagChoice] Find Template Data Success Batch',
  props<{
    actions: {
      findStatementsSuccess: ReturnType<typeof StatementActions.addMany>;
      findRegionsSuccess: ReturnType<typeof RegionActions.addMany>;
    };
  }>(),
);

export const TagChoiceBatchActions = {
  findTemplateDataSuccess,
  loadSuccess: createBatchAction(
    '[TagChoice] Load Divider Choices Success Batch',
    props<{
      actions: {
        loadSuccess: ReturnType<typeof TagChoiceActions.loadTagChoicesSuccess>;
        findTemplateDataSuccess: ReturnType<typeof findTemplateDataSuccess>;
      };
    }>(),
  ),
  addSuccess: createBatchAction(
    '[TagChoice] Add Divider Choice Success Batch',
    props<{
      actions: {
        addSuccess: ReturnType<typeof TagChoiceActions.addTagChoiceSuccess>;
        findTemplateDataSuccess: ReturnType<typeof findTemplateDataSuccess>;
      };
    }>(),
  ),
  autoSelectSuccess: createBatchAction(
    '[TagChoice] Auto Select Divider Choices Success Batch',
    props<{
      actions: {
        autoSelectSuccess: ReturnType<typeof TagChoiceActions.autoSelectTagChoicesSuccess>;
        findTemplateDataSuccess: ReturnType<typeof findTemplateDataSuccess>;
      };
    }>(),
  ),
  copySuccess: createBatchAction(
    '[TagChoice] Copy Divider Choices Success Batch',
    props<{
      actions: {
        copySuccess: ReturnType<typeof TagChoiceActions.copyTagChoicesSuccess>;
        findTemplateDataSuccess: ReturnType<typeof findTemplateDataSuccess>;
      };
    }>(),
  ),
};
