import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PaymentActions } from './payment.action';

export type PaymentState = EntityState<RR.Payment>;

const adapter = createEntityAdapter<RR.Payment>();

const initialState = adapter.getInitialState();

export const paymentReducer = createReducer(
  initialState,
  on(PaymentActions.findManySuccess, (state: PaymentState, { payments }) => adapter.upsertMany(payments, state)),
  on(PaymentActions.updateSuccess, (state: PaymentState, { payment }) => adapter.upsertOne(payment, state)),
  on(PaymentActions.createManySuccess, (state: PaymentState, { payments }) => adapter.addMany(payments, state)),
  on(PaymentActions.deleteManySuccess, (state: PaymentState, { paymentIds }) => adapter.removeMany(paymentIds, state)),

  on(EditorActions.open, EditorActions.close, () => initialState),
);
