import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromSite } from 'app/store/site';
import { skipWhile, startWith, Subscription, switchMap, take } from 'rxjs';

@Component({
  selector: 'rr-site-selector',
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule],
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.css'],
})
export class SiteSelectorComponent implements OnInit, OnDestroy {
  @Input() formArray: FormControl<number[]>;
  any = new FormControl(true, { nonNullable: true });
  sites: RR.Site[] | undefined;
  lastAnyValue: boolean | undefined = undefined;

  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.lastAnyValue = this.any.value;
    this.subscription.add(
      this.any.valueChanges.subscribe(() => {
        this.enforceAny();
        this.lastAnyValue = this.any.value;
      }),
    );
    this.subscription.add(
      this.formArray.valueChanges.pipe(startWith(this.formArray.value)).subscribe(() => {
        this.enforceAny();
        this.lastAnyValue = this.any.value;
      }),
    );

    this.subscription.add(
      this.store
        .select(fromSite.selectLoaded)
        .pipe(
          skipWhile((loaded) => !loaded),
          switchMap(() => this.store.select(fromSite.selectActiveSites)),
          take(1),
        )
        .subscribe((sites) => {
          if (!this.sites || !this.sites.length) {
            this.sites = sites;
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * When any becomes checked, the named checkboxes are unchecked
   * When a named checkbox becomes checked, any is unchecked
   */
  enforceAny() {
    if (this.lastAnyValue !== undefined && this.formArray.value.length) {
      const ANY_WAS_JUST_CHECKED = !this.lastAnyValue && this.any.value;
      const ANY_STAYED_CHECKED = this.lastAnyValue && this.any.value;
      if (ANY_WAS_JUST_CHECKED) {
        requestAnimationFrame(() => {
          this.formArray.setValue([]);
        });
      } else if (ANY_STAYED_CHECKED) {
        requestAnimationFrame(() => {
          this.any.patchValue(false);
        });
      }
    }
  }

  onCheck(site: RR.Site, event: Event) {
    if (event.target && event.target instanceof HTMLInputElement) {
      if (event.target.checked) {
        if (!this.formArray.value.includes(site.id)) {
          this.formArray.setValue([...this.formArray.value, site.id]);
        }
      } else {
        this.formArray.setValue(this.formArray.value.filter((siteId) => siteId !== site.id));
      }
    } else {
      console.error('Unexpected event', event);
    }
  }
}
