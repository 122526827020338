import { PortalModule } from '@angular/cdk/portal';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { VersionUpdateService } from 'app/core/services/version-update.service';
import { interval, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

import { SelectorService } from './core/services/selector.service';
import { ScreenshotService } from './core/toolbar-navbar/components/issue/screenshot.service';
import { ToastListComponent } from './core/toolbar-navbar/components/toast-list/toast-list.component';
import { NavbarComponent } from './core/toolbar-navbar/components/toolbar-navbar/toolbar-navbar.component';
import { EditorComponent } from './modules/editor/editor.component';
import { NavigationComponent } from './shared/components/navigation/navigation.component';
import { SharedModule } from './shared/shared.module';
import { AppState, selectorsToClear } from './store';
import { EditorActions } from './store/editor';
import { SessionEffect } from './store/session';
import { TemplateEffect } from './store/template/template';
import { CompanyRoleEffect } from './store/user/company-role';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [SharedModule, PortalModule, NavbarComponent, ToastListComponent, NavigationComponent],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  showToolbar = true;
  rrConfig: rrConfigType;

  constructor(
    _trace: Sentry.TraceService,
    private versionService: VersionUpdateService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private templateEffect: TemplateEffect,
    private sessionEffect: SessionEffect,
    private companyRoleEffect: CompanyRoleEffect,
    private selectorService: SelectorService,
    private modalService: NgbModal,
    public screenshotService: ScreenshotService,
  ) {}

  // the router-outlet (deactivate) can have any component type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeactivate(component: any) {
    if (component instanceof EditorComponent) {
      // EditorComponent has been destroyed and the route has changed.
      this.templateEffect.resetAll();
      this.store.dispatch(EditorActions.close());
      // Call this when closing the editor to prevent the cache growing indefinitely
      for (const selector of selectorsToClear) {
        selector.release();
      }
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.sessionEffect
        .fetchConfig()
        .pipe(
          tap((rrConfig) => {
            this.rrConfig = rrConfig.config;
          }),
          switchMap(() => this.companyRoleEffect.findAll()),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((user) => this.setUserContext(user)),
    );

    this.zone.runOutsideAngular(() => {
      this.subscription.add(
        interval(1000 * 60 * 30).subscribe(() => {
          this.zone.run(() => {
            this.versionService.checkVersionUpdate();
          });
        }),
      );
    });

    this.subscription.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((_event) => {
        const data = this.route.root.firstChild && this.route.root.firstChild.snapshot.data;
        if (data && 'showToolbar' in data) {
          this.showToolbar = data.showToolbar;
        } else {
          this.showToolbar = true;
        }
      }),
    );

    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          // Close all open modals
          this.modalService.dismissAll();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setUserContext(user: RR.User | undefined) {
    /* Register user session context for error reports */
    if (user) {
      Sentry.setUser({
        id: user.id.toString(),
        email: user.email || undefined,
        name: user.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }
}
