import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromTextObject } from 'app/store/template/text-object/text-object.selector';
import { Observable } from 'rxjs';

import { DateTypeComponent } from '../../date-type/date-type.component';
import { AttributePlaceholderComponent } from '../attribute-placeholder.component';
import { LiteralPlaceholderComponent } from '../literal-placeholder.component';

@Component({
  standalone: true,
  imports: [CommonModule, AttributePlaceholderComponent, LiteralPlaceholderComponent, DateTypeComponent],
  selector: 'rr-text-object-render',
  templateUrl: './text-object-render.component.html',
  styleUrls: ['./text-object-render.component.css'],
})
export class TextObjectRenderComponent implements OnInit {
  @Input() textObjectId: number;
  textObject$: Observable<RR.TextObject | undefined>;
  @Input() template_id: number;
  @Input() region: RR.Region | undefined;
  @Input() proposed?: RR.ProposedStatement['proposed'];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.textObject$ = this.store.select(fromTextObject.selectTextObject(this.textObjectId));
  }
}
