import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { updateSuccess as invoiceUpdateSuccess } from '../invoice/invoice.action';

const findManySuccess = createAction('[Payment] Find Many Success', props<{ payments: RR.Payment[] }>());
const updateSuccess = createAction('[Payment] Update Success', props<{ payment: RR.Payment }>());
const createManySuccess = createAction('[Payment] Create Success', props<{ payments: RR.Payment[] }>());
const deleteManySuccess = createAction('[Payment] Delete Success', props<{ paymentIds: number[] }>());

const createManySuccessBatch = createBatchAction(
  '[Payment] Create Success Batch',
  props<{
    actions: {
      createPaymentsSuccess: ReturnType<typeof createManySuccess>;
      updateInvoiceSuccess: ReturnType<typeof invoiceUpdateSuccess>;
    };
  }>(),
);
const deleteManySuccessBatch = createBatchAction(
  '[Payment] Delete Success Batch',
  props<{
    actions: {
      deletePaymentSuccess: ReturnType<typeof deleteManySuccess>;
      updateInvoiceSuccess: ReturnType<typeof invoiceUpdateSuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for Payment
 *
 */
export const PaymentActions = {
  findManySuccess,
  updateSuccess,
  createManySuccess,
  deleteManySuccess,
  createManySuccessBatch,
  deleteManySuccessBatch,
};
