import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rr-prefill-tutorial',
  templateUrl: './prefill-tutorial.component.html',
  styleUrls: ['./prefill-tutorial.component.scss'],
  standalone: true,
})
export class PrefillTutorialComponent {
  constructor(public activeModal: NgbActiveModal) {}

  static open(modal: NgbModal) {
    const modalRef = modal.open(PrefillTutorialComponent, {
      size: 'lg',
      scrollable: true,
    });
    return modalRef;
  }
}
