<table *ngIf="report.type === 'preset'" class="preset-table table table-sm">
  <tbody>
    <tr>
      <td>Preset</td>
      <td class="text-truncate">
        <rr-preset-title [presetTitleId]="report.preset_title_id"></rr-preset-title>
      </td>
      <td>
        <!-- if the button colour is wrong, make sure there's enough levels that the bootstrap css rule doesn't match -->
        <button
          permission
          [permission-only]="['preset_manage']"
          rrTooltip="UPDATE_PRESET"
          class="btn btn-sm btn-outline-primary"
          (click)="openPresetModal()"
        >
          Update Preset
        </button>
      </td>
    </tr>
    <tr *ngIf="presetFromReport">
      <td>From Report</td>
      <td colspan="2">
        <rr-preset-from-report
          *ngIf="presetFromReport"
          [presetFromReport]="presetFromReport"
          (onClick)="openPrefill(presetFromReport)"
        ></rr-preset-from-report>
      </td>
    </tr>
    <tr>
      <td>Notes</td>
      <td colspan="2" style="word-break: break-all">
        {{ report.preset_notes }}
      </td>
    </tr>
    <tr>
      <td colspan="3">
        <div class="alert alert-danger" role="alert">
          <span class="text-danger w-100 d-block"
            >No need to save the Preset. After adding the statements, just close this page.</span
          >
        </div>
      </td>
    </tr>
  </tbody>
</table>
