import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rr-voyager-status-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './voyager-status-badge.component.html',
  styleUrls: ['./voyager-status-badge.component.css'],
})
export class VoyagerStatusBadgeComponent implements OnInit {
  @Input() report: RR.Report;

  constructor() {}

  ngOnInit(): void {}
}
