import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const adapter = createEntityAdapter<RR.TagChoice>();

export interface TagChoiceState extends EntityState<RR.TagChoice> {
  loaded: boolean;
  loading: boolean;
}

export const initialState: TagChoiceState = adapter.getInitialState({
  loaded: false,
  loading: false,
});
