import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { fromAttributeOption } from 'app/store/template/attribute-option';
import { fromAttributeSet } from 'app/store/template/attribute-set';
import { fromDefaultAttribute } from 'app/store/template/default-attribute';
import { Observable, Subscription, combineLatest, switchMap } from 'rxjs';

export type SameTextAttributeResult = {
  attributeSetId: number;
  sameTextAttribute: RR.AttributeOption;
};

@Component({
  selector: 'rr-switch-attribute',
  standalone: true,
  imports: [CommonModule, StoreSelectPipe, NgbDropdownModule],
  templateUrl: './switch-attribute.component.html',
  styleUrls: ['./switch-attribute.component.css'],
})
export class SwitchAttributeComponent implements OnInit, OnDestroy {
  @ViewChild('attrReplaceDropdown', { static: true }) attrReplaceDropdown: NgbDropdown;

  // Type 1: With an Existing TextObject
  @BindObservable() @Input() textObject: RR.TextObject | undefined;
  textObject$: Observable<RR.TextObject | undefined>;

  // Type 2: New AttributeTextObjectElement does not have a TextObject yet
  @Input() defaultAttributeId: number | undefined;

  @Input() templateId: number;
  @Input() regionId: number | null;
  results: SameTextAttributeResult[] = [];

  @Output() onSwitchAttributeSet = new EventEmitter<SameTextAttributeResult>();

  subscription = new Subscription();
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    let defaultAttribute$: Observable<RR.AttributeOption | undefined>;
    if (this.textObject !== undefined) {
      // Type 1: With an Existing TextObject
      defaultAttribute$ = this.textObject$.pipe(
        switchMap((textObject) => {
          if (textObject === undefined) {
            throw new Error('textObject is undefined');
          }
          return this.store.select(
            fromDefaultAttribute.selectOptionByChoice(this.templateId, textObject.id, this.regionId),
          );
        }),
      );
    } else if (this.defaultAttributeId) {
      // Type 2: AttributeTextObjectElement
      defaultAttribute$ = this.store.select(fromAttributeOption.selectAttributeOption(this.defaultAttributeId));
    } else {
      throw new Error('textObject and defaultAttributeId are both undefined');
    }
    const attributeOptions$ = this.store.select(fromAttributeOption.selectEntities);
    const attributeSets$ = this.store.select(fromAttributeSet.selectAll);

    this.subscription.add(
      combineLatest([defaultAttribute$, attributeSets$, attributeOptions$]).subscribe(
        ([defaultAttribute, attributeSets, attributeOptionsEntities]) => {
          if (defaultAttribute === undefined) {
            return;
          }
          this.results = attributeSets
            .map((attributeSet) => {
              const sameTextAttribute = attributeSet.attribute_option_ids
                .filter((attributeId) => attributeId !== defaultAttribute.id)
                .map((id) => attributeOptionsEntities[id])
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .find((attributeOption) => attributeOption!.text === defaultAttribute!.text);
              return { attributeSetId: attributeSet.id, sameTextAttribute };
            })
            .filter((obj): obj is SameTextAttributeResult => obj.sameTextAttribute !== undefined);

          this.attrReplaceDropdown.open();
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectAttributeSet = fromAttributeSet.selectAttributeSet;

  switchAttributeSet(result: SameTextAttributeResult): void {
    this.onSwitchAttributeSet.emit(result);
  }
}
