interface Filter {
  text: string;
}

interface SectionTags {
  suggestedTags: RR.TagSuggestion[];
  isMLSuggested: boolean;
  isSuggestedLoading: boolean;
  isSuggestedLoaded: boolean;
  templateStructure: RR.StructuredTagSubsection[];
  isStructureLoading: boolean;
  isStructureLoaded: boolean;
}

export interface TagState {
  sectionTags: { [section: string]: SectionTags };
  filter: Filter;
  // Whether to automatically update the predicted values of the ML model
  autoUpdate: boolean;
}

const initialSectionTags: SectionTags = {
  suggestedTags: [],
  isMLSuggested: false,
  isSuggestedLoading: false,
  isSuggestedLoaded: false,
  templateStructure: [],
  isStructureLoading: false,
  isStructureLoaded: false,
};
export const initialState: TagState = {
  sectionTags: {
    history: initialSectionTags,
    technique: initialSectionTags,
    findings: initialSectionTags,
    comment: initialSectionTags,
    impression_recommendations: initialSectionTags,
  },
  filter: { text: '' },
  autoUpdate: false,
};
