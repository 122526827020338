<form [formGroup]="form">
  <div class="form-group mb-2">
    <rr-report-status-selector
      [value]="form.controls.reportStatuses.value"
      (onChange)="onChangeReportStatuses($event)"
    ></rr-report-status-selector>
  </div>
</form>

<ng-container *ngIf="nextReports && nextReports.length > 0; else noReports">
  <table class="rr-report-list table table-sm table-striped table-hover mt-1 w-100">
    <thead>
      <tr>
        <th [style.width.%]="10">The next report</th>
        <th [style.width.%]="20">Accession No</th>
        <th [style.width.%]="30">Patient</th>
        <th [style.width.%]="40">Voyager Study Notes</th>
      </tr>
    </thead>
    <tbody>
      <!-- Next Report -->
      <ng-container *ngFor="let nextReport of nextReports">
        <ng-container *ngIf="nextReport.reportId | storeSelectPipe: selectReportSelectorFn | async as report">
          <tr rr-next-report-row [report]="report" [nextReportLabel]="nextReport.label"></tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<ng-template #noReports>
  <p>No reports found.</p>
</ng-template>
