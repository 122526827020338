<div class="d-flex">
  <!--Search form-->
  <div class="d-flex search-form border max-height">
    <form class="p-3 d-flex flex-column w-100" [formGroup]="searchForm">
      <!-- <label> Found <b>0</b> invoices </label> -->
      <h4>Invoices: {{ invoiceCount }}</h4>
      <button class="btn btn-sm btn-outline-primary mb-2" (click)="clearFilters()">Reset to Today's invoices</button>
      <button class="btn btn-sm btn-outline-primary mb-2" (click)="generateDaysheet()">Generate Daysheet</button>
      <button class="btn btn-sm btn-outline-primary mb-2" (click)="generateWeeklyCashReport()">
        Generate Weekly Cash Report
      </button>
      <div class="form-group mb-2 d-flex flex-column">
        <div>
          <!-- <label>Invoice no: </label> -->
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span><span class="fa fa-search"></span></span>
            </div>
            <input
              formControlName="invoiceNo"
              class="form-control"
              type="text"
              placeholder="Search by invoice number"
              rrAutoFocus
            />
          </div>
        </div>
      </div>

      <div class="form-group mb-2 d-flex flex-column mb-3">
        <div>
          <!-- <label>Accession no: </label> -->
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span><span class="fa fa-search"></span></span>
            </div>
            <input
              formControlName="accessionNumber"
              class="form-control"
              type="text"
              placeholder="Search by accession number"
            />
          </div>
        </div>
      </div>

      <div class="form-group mb-2 d-flex flex-column mb-3">
        <div>
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span class="fa fa-search"></span>
            </div>
            <ng-container *ngIf="(institutionObject$ | async) === undefined">
              <input
                #institutionSearch
                class="form-control"
                type="text"
                placeholder="Search by institution"
                formControlName="institution"
                [ngbTypeahead]="suggestInstitutions"
                [resultTemplate]="rt"
                container="body"
                (focus)="focus$.next(searchForm.controls.institution.value)"
                (selectItem)="onSelectInstitution($event)"
              />
              <button class="btn btn-outline-secondary" type="button" (click)="clearField('institution')">
                <i class="fas fa-times"></i>
              </button>
            </ng-container>

            <ng-container *ngIf="institutionObject$ | async as institutionObject">
              <!-- Real Institution object. Show a dummy readonly input so the layout looks similar -->
              <input class="form-control" readonly [value]="institutionObject.name" (click)="clearInstitution()" />
              <button class="btn btn-outline-secondary" type="button" (click)="clearInstitution()">
                <i class="fas fa-times"></i>
              </button>
            </ng-container>
          </div>
        </div>
        <button
          title="Download .csv of outstanding invoices for institution."
          *ngIf="institutionObject$ | async as institutionObject"
          class="btn btn-sm btn-outline-secondary mt-2"
          (click)="downloadCSV(institutionObject)"
        >
          <span>Download outstanding </span>
          <i class="fas fa-download"></i>
        </button>
      </div>

      <div class="form-group mb-2 d-flex flex-column mb-3">
        <div>
          <!-- <label>Patient name: </label> -->
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span><span class="fa fa-search"></span></span>
            </div>
            <input
              formControlName="patientName"
              class="form-control"
              type="text"
              placeholder="Search by patient name"
            />
            <button class="btn btn-outline-secondary" type="button" (click)="clearField('patientName')">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group mb-2 d-flex flex-column mb-3">
        <div>
          <!-- <label>Patient number: </label> -->
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span class="fa fa-search"></span>
            </div>
            <input
              formControlName="patientNumber"
              class="form-control"
              type="text"
              placeholder="Search by patient number"
            />
            <button class="btn btn-outline-secondary" type="button" (click)="clearField('patientNumber')">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group mb-2 d-flex flex-column mb-3">
        <div>
          <!-- <label>Referrer name: </label> -->
          <div class="input-group no-shrink">
            <div class="input-group-text">
              <span><span class="fa fa-search"></span></span>
            </div>
            <input
              formControlName="referrerName"
              class="form-control"
              type="text"
              placeholder="Search by referrer name"
            />
          </div>
        </div>
      </div>

      <rr-date-picker
        [label]="'Invoice date:'"
        [start]="searchForm.controls.fromDate.value"
        [end]="searchForm.controls.toDate.value"
        (onDatesChanged)="queryDatesChanged($event)"
      ></rr-date-picker>

      <div class="form-group mb-2 d-flex flex-column">
        <div>
          <label class="form-label">Funder type: </label>
          <div>
            <select class="form-select" formControlName="funder">
              <option value="any">Any</option>
              <option value="medicare">Medicare</option>
              <option value="dva">DVA</option>
              <option value="bupa">BUPA or No medicare</option>
              <option value="private_patient">Private Fee (Medicare card holder)</option>
              <option value="other">Third party, CTP, work cover, or other</option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group mb-2 d-flex flex-column">
        <div>
          <label class="form-label">Status: </label>
          <div>
            <select class="form-select" formControlName="status">
              <option value="any" title="All invoices">Any</option>
              <option value="outstanding" title="All invoices that are outstanding">Outstanding (All)</option>
              <option value="paid" title="All invoices that are paid">Paid (All)</option>
              <option value="rejected" title="Bulk-bill invoices that have been rejected by medicare">
                Rejected (Bulk-bill)
              </option>
              <option value="pending" title="Bulk-bill invoices that are pending medicare approval">
                Pending (Bulk-bill)
              </option>
              <option value="cancelled">Cancelled (Bulk-bill)</option>
              <option value="completed">Completed (Bulk-bill)</option>
              <option value="submitted">Submitted (Bulk-bill)</option>
              <option value="under-review">Under review (Bulk-bill)</option>
              <option value="approved">Approved (Bulk-bill)</option>
              <option value="accepted">Accepted (Bulk-bill)</option>
              <option value="errored">Errored (Bulk-bill)</option>
            </select>
          </div>
        </div>
      </div>

      <div class="d-flex mx-2 justify-content-between">
        <div class="form-group mb-2">
          <label class="form-label">Site:</label>
          <rr-site-selector [formArray]="searchForm.controls.sites"></rr-site-selector>
        </div>

        <div class="form-group mb-2">
          <div class="mb-2">
            <label class="form-label">
              <strong>Only:</strong>
            </label>
            <div class="form-check">
              <input
                id="partially-complete"
                type="checkbox"
                class="form-check-input"
                formControlName="partially_complete"
              />
              <label
                title="Invoices with 'one or more' items rejected by medicare"
                for="partially-complete"
                class="form-check-label"
                >Partially Complete</label
              >
            </div>
            <div class="form-check">
              <input id="unpaid" type="checkbox" class="form-check-input" formControlName="unpaid" />
              <label title="Invoices that were created but not paid/completed" for="unpaid" class="form-check-label">
                Unpaid Invoices
              </label>
            </div>
            <div class="form-check">
              <input id="unbalanced" type="checkbox" class="form-check-input" formControlName="unbalanced" />
              <label
                title="Invoices with an 'amount due' greater or less than 'amount expected'"
                for="unbalanced"
                class="form-check-label"
                >Unbalanced</label
              >
            </div>
          </div>
          <div>
            <label class="form-label">Include:</label>
            <div class="form-check">
              <input id="draft-invoice" type="checkbox" class="form-check-input" formControlName="draft" />
              <label title="Include draft invoices" for="draft-invoice" class="form-check-label">Draft</label>
            </div>
            <div class="form-check">
              <input id="deleted-invoice" type="checkbox" class="form-check-input" formControlName="deleted" />
              <label title="Include deleted invoices" for="deleted-invoice" class="form-check-label">Deleted</label>
            </div>
            <div class="form-check">
              <input
                id="completed-invoice"
                type="checkbox"
                class="form-check-input"
                formControlName="no_further_action_required"
              />
              <label
                title="Include invoices that previously had rejected 'invoice item(s)' but have since been re-invoiced or marked complete"
                for="completed-invoice"
                class="form-check-label"
                >Completed invoices</label
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <!--Invoice table-->
  <div class="d-flex flex-column mx-3 max-height w-100">
    <rr-invoice-table [invoices$]="invoices$" (onSearchByPatient)="searchByPatient($event)"></rr-invoice-table>
    <div class="d-flex justify-content-center mb-4">
      <ngb-pagination
        [collectionSize]="invoiceCount"
        [maxSize]="3"
        [rotate]="true"
        [pageSize]="PAGE_SIZE"
        [ellipses]="false"
        [page]="searchForm.controls.page.value"
        (pageChange)="onPageChange($event)"
        [disabled]="invoiceCount <= PAGE_SIZE"
      >
      </ngb-pagination>
    </div>
  </div>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div *ngIf="r.name">
    <ngb-highlight class="me-1" [result]="r.name" [term]="t"></ngb-highlight>
  </div>
</ng-template>
