import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICategorisedAttribute } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { CategoryStatementComboActions } from './category-statement-combo.action';
import { CategoryStatementComboHttpService } from './category-statement-combo.service';

@Injectable()
export class CategoryStatementComboEffect {
  constructor(
    private store: Store<AppState>,
    private service: CategoryStatementComboHttpService,
  ) {}

  findAll(params?: HttpParams) {
    // @ts-expect-error strictNullChecks
    return this.service.findAll(params).pipe(
      map((categories: RR.CategoryStatementCombo[]) => CategoryStatementComboActions.findAllSuccess({ categories })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addAttributeCombo(
    categoryStatementCombo: Partial<RR.CategoryStatementCombo & { attributes: ICategorisedAttribute[] }>,
  ) {
    return this.service.addAttributeCombo(categoryStatementCombo).pipe(
      map((categoryStatementCombo: RR.CategoryStatementCombo) =>
        CategoryStatementComboActions.addAttributeComboSuccess({ categoryStatementCombo }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteAttributeCombo(
    categoryStatementCombo: Partial<RR.CategoryStatementCombo & { attributes: ICategorisedAttribute[] }>,
  ) {
    return this.service.deleteAttributeCombo(categoryStatementCombo).pipe(
      map((response) => {
        if ('deleted' in response) {
          return CategoryStatementComboActions.deleteCategoryStatementComboSuccess({
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            categoryStatementComboId: (response['deleted'] as RR.CategoryStatementCombo)?.id,
          });
        } else {
          return CategoryStatementComboActions.updateCategoryStatementComboSuccess({
            // @ts-expect-error noImplicitAny
            categoryStatementCombo: response['updated'],
          });
        }
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
