<div class="sidebar" #sidebar [@openClose]="isOpen ? 'open' : 'closed'">
  <div class="m-1">
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="toggleSidebar()"
      [rrTrack]="'Open Sidebar - In Sidebar'"
    >
      <span>(!) Open</span>
    </button>
  </div>
  <div class="mb-1 mx-1">
    <button
      role="button"
      class="btn btn-sm mb-1"
      (click)="openFavourites()"
      rrTrack
      rrTooltip="NAME_FAVOURITE"
      [ngClass]="favourite ? 'btn-success' : 'btn-light'"
    >
      <span>{{ favourite ? '(A) Update Favourites' : '(A) Add Favourites' }}</span>
    </button>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="togglePrefill()"
      rrTrack
      rrTooltip="SIDE_PREFILL_TOGGLE"
    >
      <span>(B) Back to Prefill</span>
    </button>

    <ng-container *ngIf="report$ | async as report">
      <button
        role="button"
        class="btn btn-sm mb-1"
        (click)="openCheckReport()"
        [class.btn-light]="!report || !report.send_to_voyager_time || !report.text_modified"
        [class.btn-success]="
          report &&
          report.send_to_voyager_time &&
          report.text_modified &&
          report.send_to_voyager_time > report.text_modified
        "
        [class.btn-warning]="
          report &&
          report.send_to_voyager_time &&
          report.text_modified &&
          report.send_to_voyager_time < report.text_modified
        "
        rrTrack
        rrTooltip="SIDE_CHECK_REPORT"
      >
        (C) Check Report
      </button>
    </ng-container>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="openPatientInformation()"
      rrTrack
      rrTooltip="PATIENT_INFO"
      data-cy="patient-demographics"
    >
      <span>(D) Demographics</span>
    </button>
    <button role="button" class="btn btn-sm btn-light mb-1" (click)="backToEditor()" rrTrack rrTooltip="BACK_TO_EDITOR">
      <span>(E) Back to Editor</span>
    </button>
  </div>
  <div class="mb-1 mx-1">
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="setPrefillType('PRESET'); togglePrefill()"
      rrTrack
      rrTooltip="PREFILL_PRESET"
    >
      <span>(F) Prefill Presets</span>
    </button>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="openSendFeedback()"
      rrTrack
      rrTooltip="SEND_FEEDBACK"
    >
      <span>(G) Give Feedback</span>
    </button>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="openSelfCheckFeedback()"
      rrTrack
      rrTooltip="SELF_CHECK_FEEDBACK"
    >
      <span>(H) Feedback Help</span>
    </button>
    <button
      role="button"
      class="btn btn-sm mb-1"
      (click)="openReportTitle()"
      [ngClass]="topic && topic.title_option_text ? 'btn-light' : 'btn-warning flash'"
      [disabled]="topic && topic.title_option_text"
      rrTrack
      rrTooltip="ADD_TITLE"
    >
      <span>(I) Indicate Report Title</span>
    </button>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="toggleFindingTagsAndPrefill('findings')"
      rrTrack
      rrTooltip="FINDING_TAG"
      #togglePrefillButton
    >
      <span>(J) Jump to Groups</span>
    </button>
    <button
      rr-add-other-imaging-button
      class="mb-1"
      [topic]="topic"
      [report]="report"
      [btnSm]="true"
      [btnLight]="true"
      rrTrack
      rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
    ></button>
  </div>
  <div class="mb-1 mx-1">
    <!-- TODO: find a way to check we have DICOM SR, then somewhere along workflow auto open -->
    <button
      role="button"
      class="btn btn-sm mb-1"
      (click)="openDicomSR()"
      [ngClass]="'report.has_dicom_sr' ? 'btn-warning' : 'btn-light'"
      rrTrack
      rrTooltip="SHOW_DICOM_SR_TABLE"
    >
      <span>(L) Dicom SR Length</span>
    </button>
    <button
      role="button"
      class="btn btn-sm mb-1 btn-light"
      (click)="openImgsimParameters()"
      rrTrack
      rrTooltip="SHOW_IMG_SIMILARITY_PARAMETERS"
    >
      <span>(^) Image Similarity Parameters</span>
    </button>
    <button
      [id]="SIDEBAR_INVOICE_ITEMS_ID"
      role="button"
      class="btn btn-sm mb-1"
      [ngClass]="(hasInvoiceItems$ | async) ? 'btn-success' : 'btn-light'"
      rrTrack
      rrTooltip="CHOOSE_INVOICE_ITEMS"
      (click)="openInvoiceItems()"
    >
      <span>(M) Invoices</span>
    </button>

    <button
      [id]="SIDEBAR_NOTES_ID"
      role="button"
      class="btn btn-sm mb-1 btn-light"
      (click)="openNotes()"
      rrTrack
      rrTooltip="FLAG"
      data-cy="report-notes"
    >
      (N) Notes
      <span *ngIf="hasNotes">
        <span *ngIf="hasNotes['urgentNotes']" class="badge bg-danger">{{ hasNotes.urgentNotes }}</span>
        <span *ngIf="hasNotes['todos']" class="badge bg-info">{{ hasNotes.todos }}</span>
        <span *ngIf="hasNotes['studyNotes']" class="badge bg-warning">{{ hasNotes.studyNotes }}</span>
        <span *ngIf="hasNotes['voiceNotes']" class="badge bg-success">{{ hasNotes.voiceNotes }}</span>
        <span *ngIf="hasNotes['minorNotes']" class="badge bg-secondary">{{ hasNotes.minorNotes }}</span>
      </span>
    </button>

    <button role="button" class="btn btn-sm btn-light mb-1" (click)="openTutorials()" rrTrack rrTooltip="SIDE_TUTORIAL">
      (O) Open Tutorials
    </button>
  </div>
  <div class="mb-1 mx-1">
    <button
      role="button"
      (click)="prefillSentences('PREDICTION')"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="SIDE_PREFILL_PREDICTION"
    >
      <span>(P) Prefill Prediction</span>
    </button>
    <button
      role="button"
      (click)="openRegistrationModal()"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="OPEN_REGISTRATION_MODAL"
    >
      <span>(Q) Registration</span>
    </button>

    <ng-container *ngIf="sendStatus$ | async as status">
      <button
        role="button"
        class="btn btn-sm mb-1"
        [ngClass]="{
          'btn-light': status === 'EMPTY',
          'btn-success': status === 'RESOLVED',
          'btn-warning': status === 'UNRESOLVED'
        }"
        (click)="openSendReport()"
        rrTrack
        rrTooltip="DISTRIBUTE_REPORT"
      >
        <span>(R) Distribute Report</span>
      </button>
    </ng-container>
    <button
      role="button"
      class="btn btn-sm mb-1"
      [ngClass]="topic && topic.signature_text ? 'btn-success' : 'btn-light'"
      (click)="openSignature(companyRole)"
      rrTrack
      rrTooltip="ADD_SIGNATURE"
      [disabled]="!companyRole"
    >
      <ng-container *ngIf="!topic?.signature_text">(S) Sign</ng-container>
      <ng-container *ngIf="topic?.signature_text"> (S) Signed</ng-container>
    </button>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="prefillSentences('TITLE')"
      rrTrack
      rrTooltip="SIDE_PREFILL_TITLE"
    >
      <span>(T) Prefill Title</span>
    </button>
    <rr-urgent-button
      [reportId]="report.id"
      [btnSize]="'btn-sm'"
      [btnColour]="'btn-light'"
      [btnClassName]="'mb-1 text-start text-nowrap w-100'"
    >
      <span>{{ report.urgent ? '(U) Undo Urgent' : '(U) Mark As Urgent' }}</span>
    </rr-urgent-button>
    <rr-super-urgent-button
      [reportId]="report.id"
      [btnSize]="'btn-sm'"
      [btnColour]="'btn-light'"
      [btnClassName]="'mb-1 text-start text-nowrap w-100'"
    >
      <span>{{ report.super_urgent ? '(*) Undo Super Urgent' : '(*) Mark As Super Urgent' }}</span>
    </rr-super-urgent-button>
  </div>
  <div class="mb-1 mx-1">
    <div class="mb-1">
      <rr-open-voyager-button [report_id]="report.id" btnClass="btn-light"></rr-open-voyager-button>
    </div>
    <rr-zero-fp-forms class="d-block mb-1" [report]="report" rrTrack rrTooltip="SIDE_WEB_VIEWER_FORMS" [sidebar]="true">
      Web Viewer Forms <span class="small fa fa-external-link-alt"></span>
    </rr-zero-fp-forms>
    <rr-zero-fp-images
      class="d-block mb-1"
      [report]="report"
      rrTrack
      rrTooltip="OPEN_IMAGE"
      [id]="SIDEBAR_WEB_VIEWER_ID"
      [sidebar]="true"
    >
      (W) Web Viewer Images <span class="small fa fa-external-link-alt"></span>
    </rr-zero-fp-images>
    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="openFinalReport()"
      rrTrack
      rrTooltip="COPY_TO_CLIPBOARD"
    >
      (X) View and Export
    </button>

    <ng-container *ngIf="{ report: report$ | async, patient: patient$ | async } as data">
      <button
        [id]="SIDEBAR_REGISTRATION_QUESTIONS_ID"
        class="btn btn-sm mb-1"
        [ngClass]="
          (data.report && data.report.patient_answer_ids.length > 0) ||
          (data.patient && data.patient.patient_answer_ids.length > 0)
            ? 'btn-success'
            : 'btn-light'
        "
        (click)="openPatientQuestionnaireModal()"
        rrTrack
        rrTooltip="OPEN_PATIENT_QUESTIONNAIRE"
      >
        (Y) Registration Questions
      </button>
    </ng-container>

    <button
      role="button"
      class="btn btn-sm btn-light mb-1"
      (click)="toggleAudit()"
      rrTrack
      rrTooltip="DETAILED_EDIT_HISTORY"
    >
      <span>(Z) Undo List</span>
    </button>
    <button role="button" (click)="openNavModal()" class="btn btn-sm btn-light mb-1" rrTrack rrTooltip="NAV_MODAL">
      <span>($) Navigator</span>
    </button>
    <button
      role="button"
      (click)="openDividerSentences()"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="OPEN_GROUP_MODAL"
    >
      <span>(>) Statement Groups</span>
    </button>
    <button
      role="button"
      (click)="setElementFilterType('LOGISTIC_REGRESSION')"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="SHORTEN_STATEMENT_LIST_WITH_LOGISTIC_REGRESSION"
    >
      <span>(%) Toggle LRA</span>
    </button>
  </div>

  <div class="mb-1 mx-1">
    <rr-document-attachment-button
      [bookingId]="report.booking_id"
      [size]="'sm'"
      [btnClassName]="'btn-light'"
      [dismissActiveModal]="true"
      [sidebar]="true"
    ></rr-document-attachment-button>
    <button role="button" class="btn btn-sm btn-light my-1" (click)="clearSearch()" rrTrack rrTooltip="CLEAR_TEXT">
      <span>(<i class="fas fa-backspace"></i>) Clear Search</span>
    </button>
    <button role="button" class="btn btn-sm btn-light my-1" (click)="nextTerm()">
      <span>Next Voice Term</span>
    </button>
  </div>
  <div class="mb-1 mx-1">
    <button
      *ngIf="isListening"
      role="button"
      class="btn btn-sm btn-danger flash"
      (click)="toggleVoiceRecognition()"
      rrTooltip="STOP_LISTENING"
    >
      <span>(&#64;) Stop Listening</span>
    </button>
    <button
      *ngIf="!isListening"
      role="button"
      class="btn btn-sm btn-light"
      (click)="toggleVoiceRecognition()"
      rrTooltip="START_LISTENING"
    >
      <span>(&#64;) Start Listening</span>
    </button>
  </div>
  <div class="mx-1">
    <button role="button" class="btn btn-sm btn-light mb-1" rrTrack rrTooltip="SIDE_BACK_WORKLIST">
      <rr-worklist-link>(Esc) Escape to Worklist</rr-worklist-link>
    </button>
    <button
      role="button"
      (click)="openShortcuts()"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="SIDE_OPEN_SHORTCUTS"
    >
      <span>(?) Keyboard Shortcuts</span>
    </button>
    <button
      role="button"
      (click)="openNextReportModal()"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="NEXT_REPORT_MODAL"
    >
      <span>(/) Next Case</span>
    </button>
    <button
      role="button"
      (click)="showImgSimJobStatus()"
      class="btn btn-sm btn-light mb-1"
      rrTrack
      rrTooltip="IMG_SIM_JOB_STATUS"
    >
      <span>Image Similarity Job Status</span>
    </button>
  </div>
</div>
