import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BookingRateActions } from './booking-rate.action';

export type BookingRateState = EntityState<RR.BookingRate>;

const adapter = createEntityAdapter<RR.BookingRate>();

const initialState = adapter.getInitialState();

export const bookingRateReducer = createReducer(
  initialState,
  on(BookingRateActions.upsertMany, (state: BookingRateState, { bookingRates }) =>
    adapter.upsertMany(bookingRates, state),
  ),
  on(BookingRateActions.create, (state: BookingRateState, { bookingRate }) => adapter.addOne(bookingRate, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
