import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { firstValueFrom } from 'rxjs';

/**
 * integrity: makes sure that the CDN serves the expected file. If the integrity hash is different, the resource will be blocked because the CDN might have been hacked.
 *
 * Example generate integrity hash
 *  curl https://unpkg.com/@medipass/partner-sdk@1.9.0/umd/@medipass/partner-sdk.min.js > /tmp/partner-sdk.min.js
 *  cat /tmp/partner-sdk.min.js | openssl dgst -sha256 -binary | openssl enc -base64 -A
 */
async function loadScript({ src, integrity }: { src: string; integrity: string }) {
  return new Promise((resolve, reject) => {
    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.integrity = integrity;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}

@Injectable({
  providedIn: 'root',
})
export class MedipassService {
  medipassSDK: MedipassTransactionSDKType;
  _loadingPromise: Promise<MedipassTransactionSDKType> | undefined;

  constructor(private store: Store<AppState>) {}

  async sdk() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.medipassSDK) {
      // If the sdk is loaded, return it.
      return this.medipassSDK;
    } else if (!this._loadingPromise) {
      // Otherwise, if there isn't already a loading promise - make one
      this._loadingPromise = this._sdk();
    }
    // Wait for the loading promise
    return this._loadingPromise
      .then((medipassSDK) => {
        // Cache the result. Next time the function is called it will simply return this.
        this.medipassSDK = medipassSDK;
        return medipassSDK;
      })
      .finally(() => {
        // We're done loading. Regardless of success/failure.
        // In the failure case, the next time this function is called. It will try again.
        this._loadingPromise = undefined;
      });
  }

  async _sdk() {
    await loadScript({
      src: 'https://unpkg.com/@medipass/partner-sdk@1.9.0/umd/@medipass/partner-sdk.min.js',
      integrity: 'sha256-j1EFav69UL2LevMeRibVqCRTXCXaqp2OFsycHGtrvdI=',
    });
    // @ts-expect-error noImplicitAny
    const sdk: MedipassTransactionSDKType = window['MedipassTransactionSDK'];
    const rrConfig = await firstValueFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined()));
    if (rrConfig.MEDIPASS_ENV && rrConfig.MEDIPASS_API_KEY && rrConfig.MEDIPASS_APP_ID) {
      sdk.setConfig({
        env: rrConfig.MEDIPASS_ENV,
        apiKey: rrConfig.MEDIPASS_API_KEY,
        appId: rrConfig.MEDIPASS_APP_ID,
        appVersion: rrConfig.version,
      });
    }
    return sdk;
  }
}
