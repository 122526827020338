import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { DueDateModalComponent } from 'app/shared/modals/due-date-modal/due-date-modal.component';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule, NgbModule, ReactiveFormsModule, AutoFocusDirective],
  selector: 'rr-due-date',
  templateUrl: 'due-date.component.html',
  styleUrls: ['./due-date.component.scss'],
})
export class DueDateComponent {
  @Input() parent: 'EDITOR' | 'WORKLIST' = 'EDITOR';
  @Input() report: RR.Report;

  constructor(private modal: NgbModal) {}

  openDateTimeEditor() {
    DueDateModalComponent.open({ modalService: this.modal, parent: this.parent, report: this.report });
  }
}
