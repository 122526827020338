import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { BookingActions } from 'app/store/booking';
import { PatientActions } from 'app/store/patient/patient.action';
import { ReferrerActions } from 'app/store/referrer';
import { map, tap } from 'rxjs/operators';

import { ReportActions } from '../report/report.action';
import { TodoActions } from '../todo/todo.action';
import { UrgentNoteActions } from '../urgent-note/urgent-note.action';
import { VoiceNoteActions } from '../voice-note/voice-note.action';
import { FollowupTaskActions } from './followup-task.action';
import {
  FollowupTaskHttpService,
  FollowupTaskPayload,
  FollowupTaskSearchHttpResponse,
  FollowupTaskSearchPayload,
} from './followup-task.service';

@Injectable()
export class FollowupTaskEffect {
  constructor(
    private store: Store<AppState>,
    private service: FollowupTaskHttpService,
    private message: MessageService,
  ) {}

  sendFollowupSMS(data: FollowupTaskPayload) {
    return this.service.sendFollowupSMS(data).pipe(
      this.message.handleHttpErrorPipe,
      map((followupTask: RR.FollowupTask) => FollowupTaskActions.createSuccess({ followupTask })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(id: number, changes: Partial<RR.FollowupTask>) {
    return this.service.update(id, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((followupTask: RR.FollowupTask) => FollowupTaskActions.updateSuccess({ followupTask })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(payload: FollowupTaskSearchPayload, params: HttpParams) {
    return this.service.search(payload, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response: FollowupTaskSearchHttpResponse) =>
        FollowupTaskActions.searchSuccessBatch({
          count: response.count,
          followupTaskIds: response.followup_tasks.map((t) => t.id),
          actions: {
            findManyFollowupTasksSuccess: FollowupTaskActions.findManySuccess({
              followupTasks: response.followup_tasks,
            }),
            findManyReportsSuccess: ReportActions.upsertMany({ reports: response.reports }),
            findManyBookingsSuccess: BookingActions.findManySuccess({ bookings: response.bookings }),
            findManyReferrersSuccess: ReferrerActions.findManySuccess({ referrers: response.referrers }),
            findManyPatientsSuccess: PatientActions.findManySuccess({ patients: response.patients }),
            findManyTodosSuccess: TodoActions.findManySuccess({ todos: response.todos }),
            findManyUrgentNotesSuccess: UrgentNoteActions.findManySuccess({ urgentNotes: response.urgent_notes }),
            findManyVoiceNotesSuccess: VoiceNoteActions.findManySuccess({ voice_notes: response.voice_notes }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
