import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { IPermissionMap, PermissionStore } from 'app/core/services/permission.store';
import { SelectorService } from 'app/core/services/selector.service';
import { SocketService } from 'app/core/services/socket.service';
import { Observable, of, combineLatest, zip } from 'rxjs';
import { catchError, finalize, map, share, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { fromCompanyRole } from '../user/company-role/company-role.selector';
import { UserActions } from '../user/user/user.action';
import { fromUser } from '../user/user/user.selector';
import { SessionActions, SessionBatchActions } from './session.action';
import { fromSession } from './session.selector';
import { SessionHttpService } from './session.service';

@Injectable()
export class SessionEffect {
  constructor(
    private service: SessionHttpService,
    private store: Store<AppState>,
    private socketService: SocketService,
    private gaService: GoogleAnalyticsService,
    private router: Router,
    private selectorService: SelectorService,
  ) {}

  fetchConfig() {
    const obs$ = this.service.fetchConfig().pipe(
      map((rrConfig: rrConfigType) => {
        window['rrConfig'] = rrConfig;

        if (rrConfig.sentryDSN) {
          Sentry.init({
            dsn: rrConfig.sentryDSN,
            environment: rrConfig.sentryEnvironment,
            release: rrConfig.version,
            integrations(integrations) {
              // This integration was sending an additional sentry error for failed HTTP requests.
              // "Non-Error exception captured with keys: error, headers, message, name, ok"
              // https://github.com/getsentry/sentry-javascript/issues/2169#issuecomment-525722319
              return integrations
                .filter((integration) => integration.name !== 'TryCatch')
                .concat(Sentry.browserTracingIntegration());
            },
            tracesSampleRate: 0.01,
            // debug: true,
            ignoreErrors: [
              // After releasing and the user navigates to a lazy loaded route, the following error is thrown:
              //   `Error: Uncaught (in promise): ChunkLoadError: Loading chunk 592 failed.`
              'ChunkLoadError',
            ],
          });
        }

        this.socketService.createWebSocket();
        this.gaService.initialise();

        if (rrConfig.user) {
          return SessionBatchActions.fetchConfigSuccess({
            config: rrConfig,
            actions: {
              userFindSuccess: UserActions.findByIdSuccess({
                user: rrConfig.user,
              }),
            },
          });
        } else {
          return SessionBatchActions.fetchConfigSuccess({
            config: rrConfig,
            actions: {},
          });
        }
      }),
      tap((action) => this.store.dispatch(action)),
      share(),
    );
    return obs$;
  }

  updateConfig(changes: Partial<ConfigTemplate>) {
    const obs$ = this.service.updateConfig(changes).pipe(
      map((configuration) => SessionActions.updateConfigSuccess({ configuration })),
      tap((action) => this.store.dispatch(action)),
      share(),
    );

    return obs$;
  }

  submitInitials(initials: string) {
    const obs$ = this.service.submitInitials(initials).pipe(
      map((user) => SessionActions.setKioskUser({ userId: user.id })),
      tap((action) => this.store.dispatch(action)),
      share(),
    );
    return obs$;
  }

  login(username: string, password: string) {
    return this.service.login(username, password).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.store.dispatch(SessionActions.loginFailed());
        }
        throw error;
      }),
      map((user) => {
        return SessionBatchActions.loginSuccess({
          user,
          actions: {
            userFindSuccess: UserActions.findByIdSuccess({
              user,
            }),
          },
        });
      }),
      tap(() => {
        this.store
          .select(fromSession.selectGoalRoute)
          .pipe(take(1))
          .subscribe((goalRoute) => {
            if (goalRoute) {
              // Parse into a UrlTree so that query params can be added to the exiting ones.
              const urlTree = this.router.parseUrl(goalRoute);
              urlTree.queryParams['fromLogin'] = true;
              this.router.navigateByUrl(urlTree);
            } else {
              this.router.navigate(['start']);
            }
          });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  doLogout() {
    return this.service.logout().pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // Swallow 401 errors, as they are expected if logout gets called again after logging out
          return of(null); // We need to return something so that the rest of the logout code executes
        }
        throw err;
      }),
      tap(() => {
        this.store.dispatch(SessionActions.setGoalRoute({ goalRoute: this.router.routerState.snapshot.url }));
        // impure: localStorage assign should be middleware if we ever have a broader need for it
        localStorage.removeItem('kiosk');
        this.store.dispatch(SessionActions.logoutSuccess());
        this.router.navigate(['/login']);
      }),
    );
  }

  /**
   * Authorise a given user against permissions. If user is null, authorise current logged in user
   */
  authorise(permission: IPermissionMap, user?: RR.User): Observable<boolean> {
    if (user) {
      return this.getUserPermissions(user).pipe(map((permissions) => permissions.authoriseByPermissionMap(permission)));
    } else {
      return this.selectorService.selectLoadedCurrentUser().pipe(
        switchMap((currentUser) =>
          // @ts-expect-error strictNullChecks
          this.getUserPermissions(currentUser).pipe(
            map((permissions) => permissions.authoriseByPermissionMap(permission)),
          ),
        ),
      );
    }
  }

  getUserPermissions(user: RR.User) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!user) return of(new PermissionStore());
    const companyRole$ = this.store.select(fromCompanyRole.selectLoaded).pipe(
      // This makes the observable below block until company roles are loaded
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromUser.selectCompanyRoles(user.id))),
      switchMap((companyRoles) => {
        // Select the Permissions for each CompanyRole
        const permissions$ = companyRoles.map((companyRole) => {
          return this.store.select(fromCompanyRole.selectPermissions(companyRole.id));
        });
        if (permissions$.length === 0) {
          // combineLatest() never emits if the array is empty
          return of({
            permissions: [],
          });
        }
        return combineLatest(permissions$).pipe(map((permissions) => ({ permissions })));
      }),
    );
    return companyRole$.pipe(
      map(({ permissions }) => {
        const permissionStore = new PermissionStore();
        // Flatten the Permission[][]
        permissions.flat().forEach((permission) => {
          permissionStore.definePermission(permission.value, true);
        });
        return permissionStore;
      }),
    );
  }

  isLoggingOut = false;

  logout() {
    if (this.isLoggingOut) {
      // Let existing logout() request complete
      return;
    }
    this.isLoggingOut = true;
    this.beforeLogout()
      .pipe(
        take(1),
        finalize(() => {
          this.isLoggingOut = false;
        }),
      )
      .subscribe();
  }

  beforeLogout() {
    return this.store.select(fromSession.selectBeforeLogoutListeners).pipe(
      take(1),
      tap(() => {
        // Unregister the listeners straight away. Because if doBeforeLogout() makes http requests that 401,
        // logout() will be called again and doBeforeLogout() will run multiple times.
        this.store.dispatch(SessionActions.unregisterAllBeforeLogoutListeners());
      }),
      switchMap((beforeLogoutListeners) => {
        if (beforeLogoutListeners.length === 0) {
          return of(null);
        } else {
          // A race condition if we add another listener while these observables are pending
          return zip(...beforeLogoutListeners.map((doBeforeLogout) => doBeforeLogout()));
        }
      }),
      switchMap(() => {
        return this.doLogout();
      }),
    );
  }
}
