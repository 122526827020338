import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LandmarkLabelHttpService {
  constructor(private http: HttpClient) {}

  findInTemplate(templateId: number): Observable<RR.LandmarkLabel[]> {
    return this.http.get<RR.LandmarkLabel[]>(`/api/template/${templateId}/landmark_label`);
  }

  find(id: string): Observable<RR.LandmarkLabel> {
    return this.http.get<RR.LandmarkLabel>(`/api/landmark_label/${id}`);
  }

  create(landmark: Partial<RR.LandmarkLabel>): Observable<RR.LandmarkLabel> {
    return this.http.post<RR.LandmarkLabel>('/api/landmark_label', landmark);
  }

  update(id: string, changes: Partial<RR.LandmarkLabel>): Observable<RR.LandmarkLabel> {
    return this.http.put<RR.LandmarkLabel>(`/api/landmark_label/${id}`, changes);
  }
}
