import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserNotificationsActions } from './notifications.action';

export interface UserNotificationsState extends EntityState<RR.UserNotification> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.UserNotification>();

const initialState = adapter.getInitialState({
  loaded: false,
});

export const userNotificationsReducer = createReducer(
  initialState,
  on(UserNotificationsActions.createSuccess, (state: UserNotificationsState, { notification }) =>
    adapter.addOne(notification, state),
  ),
  on(UserNotificationsActions.findAllSuccess, (state: UserNotificationsState, { notifications }) => ({
    ...adapter.setAll(notifications, state),
    loaded: true,
  })),
  on(UserNotificationsActions.readSuccess, (state: UserNotificationsState, { notification }) =>
    adapter.updateOne({ id: notification.id, changes: notification }, state),
  ),
);
