import { createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store';
import { reduceTextArrayToText } from 'app/core/services/report.service';
import { IElement } from 'app/core/services/template.service';
import { environment } from 'environments/environment';

import { fromPatient } from './patient/patient.selector';
import { fromElementChoice } from './report/element-choice/element-choice.selector';
import { fromRegionChoice } from './report/region-choice/region-choice.selector';
import { fromReport } from './report/report/report.selector';
import { fromSectionChoice } from './report/section-choice/section-choice.selector';
import { fromStatementChoice } from './report/statement-choice/statement-choice.selector';
import { fromSubsectionChoice } from './report/subsection-choice/subsection-choice.selector';
import { fromTextObjectChoice } from './report/text-object-choice/text-object-choice.selector';
import { fromTopic } from './report/topic/topic.selector';
import { fromElement } from './template/element/element.selector';
import { fromRegionSet } from './template/region-set/region-set.selector';
import { fromSection } from './template/section';
import { fromStatementCoincidence } from './template/statement-coincidence/statement-coincidence.selector';
import { fromStatementSet } from './template/statement-set/statement-set.selector';
import { fromSubsection } from './template/subsection/subsection.selector';
import { fromTemplate } from './template/template/template.selector';

interface CacheConfig {
  // maxSize?: number;
  // strategy?: CacheStrategy;
  keySelector?: KeySelector;
}

interface SelectorFactoryByParams<State, Props extends any[], Result> {
  (...props: Props): MemoizedSelector<State, Result>;
}

interface SelectorFactoryWithParam<State, Props extends any[], Result>
  extends SelectorFactoryByParams<State, Props, Result> {
  release(): void;
}

type KeySelector = (...args: any[]) => any;

/**
 * The ideally originally came from this pull request: https://github.com/ngrx/platform/pull/2119/files
 */

export const defaultKeySelector = (...props: any) => {
  if (!environment.production && props.length > 1) {
    console.warn(`defaultKeySelector was passed multiple props (${props.length})`);
  }
  return props[0];
};

export function createKeySelector(cacheConfig: CacheConfig): KeySelector {
  if (!cacheConfig.keySelector) return defaultKeySelector;
  if (typeof cacheConfig.keySelector === 'function') {
    return cacheConfig.keySelector;
  }
  throw new Error('keySelector must be a function');
}

export const selectorsToClear: SelectorFactoryWithParam<any, any, any>[] = [];

export function createSelectorFactoryWithCache<State, Props extends any[], Result>(
  selectorFactory: SelectorFactoryByParams<State, Props, Result>,
  cacheConfig: CacheConfig = {},
): SelectorFactoryWithParam<State, Props, Result> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const selectorsCache = new Map<String, MemoizedSelector<State, Result>>();
  const keySelector = createKeySelector(cacheConfig);
  let selector: MemoizedSelector<State, Result> | undefined;

  function CachedSelectorFactory(...props: Props): MemoizedSelector<State, Result> {
    const cacheKey = keySelector(...props);
    selector = selectorsCache.get(cacheKey);
    if (selector === undefined) {
      selector = selectorFactory(...props);
      selectorsCache.set(cacheKey, selector);
    }
    return selector;
  }

  const fn = Object.assign(CachedSelectorFactory, {
    release: () => {
      selectorsCache.forEach((cachedSelector) => {
        // TODO: might not need to do this. GC will see objects are unreachable?
        cachedSelector.release();
      });
      selectorsCache.clear();
    },
  });

  selectorsToClear.push(fn);
  return fn;
}

const selectTextObjectChoicesByChoiceId = createSelectorFactoryWithCache((statement_choice_id: number) =>
  createSelector(
    fromStatementChoice.selectStatementChoice(statement_choice_id),
    fromTextObjectChoice.selectEntities,
    (choice, text_object_choices) => {
      if (!choice) return [];
      return (choice.text_object_choices as number[]).map((i) => text_object_choices[i]).filter((sco) => !!sco);
    },
  ),
);

const selectChoicesWithCtx = createSelectorFactoryWithCache((topic_id: number) =>
  createSelector(
    fromStatementChoice.selectEntities,
    fromElementChoice.selectEntities,
    fromRegionChoice.selectEntities,
    fromSubsectionChoice.selectEntities,
    fromSectionChoice.selectEntities,
    fromTopic.selectTopic(topic_id),
    (statement_choices, element_choices, region_choices, subsection_choices, section_choices, topic) => {
      if (!topic) {
        // Added when refactoring to createSelector, not sure if we can filter differently
        return [];
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const id_or_empty = <T>(a: T[]) => a || ([] as T[]);
      const nested = id_or_empty(topic.section_choices).map((section_id) => {
        const section_choice = section_choices[section_id];
        return id_or_empty(section_choice?.subsection_choices || []).map((subsection_id) => {
          const subsection_choice = subsection_choices[subsection_id];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return id_or_empty(subsection_choice!.region_choices).map((region_id) => {
            const region_choice = region_choices[region_id];
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return id_or_empty(region_choice!.element_choices).map((element_id) => {
              const element_choice = element_choices[element_id];
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              return id_or_empty(element_choice!.statement_choices).map((statement_id) => {
                const statement_choice = statement_choices[statement_id];
                return {
                  statement_choice,
                  element_choice,
                  region_choice,
                  subsection_choice,
                  section_choice,
                  topic,
                };
              });
            });
          });
        });
      });
      return nested.flat().flat().flat().flat();
    },
  ),
);

const selectProofreadableTopic = createSelectorFactoryWithCache(
  (report_id: number, topic_id: number) =>
    createSelector(
      fromStatementCoincidence.selectEntities,
      fromTextObjectChoice.selectEntities,
      fromReport.selectReport(report_id),
      fromTopic.selectTopic(topic_id),
      selectChoicesWithCtx(topic_id),
      fromPatient.selectEntities,
      (statement_coincidence_entities, choice_objects, report, topic, choices, patients) => {
        if (!choices.length || !report) {
          return null;
        }
        return {
          ...topic,
          // @ts-expect-error strictNullChecks
          patient: patients[report.patient_id],
          statement_coincidences: Object.values(statement_coincidence_entities),
          choices: choices
            // @ts-expect-error strictFunctionTypes
            .map((c: RR.ExtendedCtx) => {
              // @ts-expect-error strictNullChecks
              const text_object_choices = (c.statement_choice.text_object_choices as number[])
                .map((id) => choice_objects[id])
                .filter((sco) => !!sco);
              // @ts-expect-error strictNullChecks
              c.text = reduceTextArrayToText(text_object_choices);
              // @ts-expect-error strictNullChecks
              c.text_object_choices = text_object_choices;
              return c;
            }),
        };
      },
    ),
  {
    keySelector: (...props) => JSON.stringify(props),
  },
);

const selectSubsectionChoicesInSectionChoice = createSelectorFactoryWithCache((section_choice_id: number) =>
  createSelector(
    fromSubsectionChoice.selectEntities,
    fromSectionChoice.selectSectionChoice(section_choice_id),
    (subsection_choice_entities, section_choice) => {
      // @ts-expect-error strictNullChecks
      return section_choice.subsection_choices.map((id) => subsection_choice_entities[id]).filter((c) => !!c);
    },
  ),
);

const selectSectionClones = createSelectorFactoryWithCache(
  (topic_id: number, section_choice_id: number) =>
    createSelector(
      selectChoicesWithCtx(topic_id),
      fromSectionChoice.selectSectionChoice(section_choice_id),
      (choices, section_choice) => {
        return choices.filter((ctx) => {
          const c = ctx.statement_choice;
          // @ts-expect-error strictNullChecks
          return !!c.clones && c.clones.includes(section_choice.type);
        });
      },
    ),
  {
    keySelector: (...props) => JSON.stringify(props),
  },
);

const selectShowHeadingPreview = createSelectorFactoryWithCache(
  (topic_id: number, section_choice_id: number) =>
    createSelector(
      selectSubsectionChoicesInSectionChoice(section_choice_id),
      selectSectionClones(topic_id, section_choice_id),
      (subsection_choices, clone_ctxs) => {
        // @ts-expect-error strictNullChecks
        const activeSubsections = subsection_choices.filter((c) => c.type === 'element');
        const activeRegions = subsection_choices
          // @ts-expect-error strictNullChecks
          .filter((c) => c.type === 'region')
          // @ts-expect-error strictNullChecks
          .filter((c) => c.region_choices.length > 0);
        return activeSubsections.length > 0 || activeRegions.length > 0 || clone_ctxs.length > 0;
      },
    ),
  {
    keySelector: (...props) => JSON.stringify(props),
  },
);

/**
 * Gets all the elements in a template in the order they appear
 */
const selectElementsByTemplateId = createSelectorFactoryWithCache((template_id: number) =>
  createSelector(
    fromElement.selectEntities,
    fromStatementSet.selectEntities,
    fromSubsection.selectEntities,
    fromRegionSet.selectEntities,
    fromSection.selectEntities,
    fromTemplate.selectTemplate(template_id),
    (element_entities, statement_sets, subsection_entities, region_set_entities, section_entities, template) => {
      const sections = (template?.section_ids || [])
        .map((section_id) => section_entities[section_id])
        .filter((s): s is RR.Section => !!s);
      return sections
        .map((section) => {
          const subsections = section.subsection_ids
            .map((subsection_id) => subsection_entities[subsection_id])
            .filter((s): s is RR.Subsection => !!s)
            .map((subsection) => ({
              subsection,
              section,
            }));
          return subsections;
        })
        .flat()
        .map(({ subsection, section }) => {
          return subsection.elements
            .map((elementId) => element_entities[elementId])
            .filter((element): element is RR.Element => !!element)
            .map((element) => ({
              element,
              subsection,
              section,
            }));
        })
        .flat()
        .map(({ element, subsection, section }) => {
          if (!statement_sets[element.statement_set_id]) return null;
          return {
            section,
            element,
            subsection,
            statement_set: statement_sets[element.statement_set_id],
            region_set: subsection.region_set_id ? region_set_entities[subsection.region_set_id] : null,
          };
        })
        .flat()
        .filter((obj): obj is IElement => !!obj);
    },
  ),
);

export const fromAppSelector = {
  selectTextObjectChoicesByChoiceId,
  selectProofreadableTopic,
  selectShowHeadingPreview,
  selectElementsByTemplateId,
  selectSectionClones,
  selectChoicesWithCtx,
};
