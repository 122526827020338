import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ScanCodePatientQuestionsResponse } from '../patient-question/patient-question.service';

export type ReorderingQuestionsInSetResponse = {
  patient_question_set: RR.PatientQuestionSet;
  patient_questions: RR.PatientQuestion[];
};
@Injectable()
export class PatientQuestionSetHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.PatientQuestionSet[]> {
    return this.http.get<RR.PatientQuestionSet[]>('/api/patient_question_set');
  }

  create(patientQuestionSet: Partial<RR.PatientQuestionSet>): Observable<RR.PatientQuestionSet> {
    return this.http.post<RR.PatientQuestionSet>('/api/patient_question_set', patientQuestionSet);
  }

  update(patientQuestionSetId: number, changes: Partial<RR.PatientQuestionSet>): Observable<RR.PatientQuestionSet> {
    return this.http.put<RR.PatientQuestionSet>(`/api/patient_question_set/${patientQuestionSetId}`, changes);
  }

  delete(patientQuestionSetId: number) {
    return this.http.delete(`/api/patient_question_set/${patientQuestionSetId}`);
  }

  /**
   * Remove all questions in question set from scan code
   * @param scanCodeId
   * @param questionSetId
   * @returns
   */
  removeAllFromScanCode(scanCodeId: number, questionSetId: number): Observable<ScanCodePatientQuestionsResponse> {
    return this.http.delete<ScanCodePatientQuestionsResponse>(
      `/api/scan_code/${scanCodeId}/patient_question_set/${questionSetId}`,
    );
  }

  /**
   * Add all questions in question set to scan code
   * @param scanCodeId
   * @param patientQuestionSetId
   * @returns
   */
  addAllToScanCode(scanCodeId: number, patientQuestionSetId: number) {
    return this.http.post<ScanCodePatientQuestionsResponse>(
      `/api/scan_code/${scanCodeId}/patient_question_set/${patientQuestionSetId}`,
      {},
    );
  }

  /**
   * Change position of the question set up or down 1 step
   * @param patientQuestionSetId
   * @param changes
   * @returns
   */
  move(patientQuestionSetId: number, step: number): Observable<RR.PatientQuestionSet[]> {
    return this.http.put<RR.PatientQuestionSet[]>(`/api/patient_question_set/${patientQuestionSetId}/move`, { step });
  }

  reorderQuestionsInSet(
    patientQuestionSetId: number,
    changes: Partial<RR.PatientQuestionSet>,
  ): Observable<ReorderingQuestionsInSetResponse> {
    return this.http.put<ReorderingQuestionsInSetResponse>(
      `/api/patient_question_set/${patientQuestionSetId}/reorder`,
      changes,
    );
  }
}
