import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { StatementCoincidenceActions } from './statement-coincidence.action';
import { StatementCoincidenceHttpService } from './statement-coincidence.service';

@Injectable()
export class StatementCoincidenceEffect {
  constructor(
    private store: Store<AppState>,
    private service: StatementCoincidenceHttpService,
  ) {}

  create(statementCoincidence: Partial<RR.StatementCoincidence>) {
    return this.service.create(statementCoincidence).pipe(
      map((statementCoincidenceCreated) =>
        StatementCoincidenceActions.addOne({ statementCoincidence: statementCoincidenceCreated }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((statementCoincidences: RR.StatementCoincidence[]) =>
        StatementCoincidenceActions.addMany({ statementCoincidences }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
