import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-patient-popover',
  templateUrl: './patient-popover.component.html',
  styleUrls: ['./patient-popover.component.css'],
})
export class PatientPopoverComponent {
  @Input() patient: RR.Patient;
}
