import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TopicEffect } from 'app/store/report/topic';
import { Subscription } from 'rxjs';

import { PriorReportMode, PriorStudyLinksComponent } from './prior-study-links/prior-study-links.component';

@Component({
  standalone: true,
  imports: [CommonModule, PriorStudyLinksComponent, NgbModule],
  selector: 'rr-add-other-imaging',
  templateUrl: './add-other-imaging.component.html',
  styleUrls: ['./add-other-imaging.component.css'],
})
export class AddOtherImagingComponent implements OnDestroy {
  @Input() report: RR.Report;
  @Input() topic: RR.Topic;
  @Input() defaultMode: PriorReportMode = 'all';
  @Input() selectable = true;

  selectedTopicIds: number[] = [];
  isAddingPriorReports = false;

  subscription = new Subscription();
  constructor(
    public activeModal: NgbActiveModal,
    protected router: Router,
    protected el: ElementRef,
    private topicEffect: TopicEffect,
  ) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clearOtherImaging() {
    this.subscription.add(this.topicEffect.clearOtherImaging(this.topic.id).subscribe());
  }
}
