import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.DocumentationLink>();
const selectFeature = (state: AppState) => state.documentation_link;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoadingStatus = createSelector(selectFeature, (documentation_link) => documentation_link.loadingStatus);

export const fromDocumentationLink = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoadingStatus,
};
