import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for App Setting
 *
 */
export const SettingActions = {
  toggleKiosk: createAction('[Setting] Toggle Kiosk'),
  setUserClockedOn: createAction('[Setting] User Clock On', props<{ value: boolean }>()),
};
