import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { StatementActions } from '../statement/statement.action';

/**
 * Methods of interacting with the StatementSets
 *
 * This describes the actions required to interface with the statement sets, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the statement sets related to a specific template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of statement sets returned, while the
 * `getTemplateSuccess` being a subset of all statement sets will only update the
 * values without deleting them all first.
 */
export const StatementSetActions = createActionGroup({
  source: 'StatementSet',
  events: {
    addMany: props<{ statementSets: RR.StatementSet[] }>(),
    addOne: props<{ statementSet: RR.StatementSet }>(),
    upsertOne: props<{ statementSet: RR.StatementSet }>(),
    upsertMany: props<{ statementSets: RR.StatementSet[] }>(),
    removeOne: props<{ statementSetId: number }>(),
    reset: emptyProps(),
  },
});

export const StatementSetBatchActions = {
  createSuccessBatchAction: createBatchAction(
    '[StatementSet] Create Success Batch',
    props<{
      actions: {
        statementSetCreateSuccessAction: ReturnType<typeof StatementSetActions.addOne>;
      };
    }>(),
  ),

  updateSuccessBatchAction: createBatchAction(
    '[StatementSet] Update Success Batch',
    props<{
      actions: {
        statementSetUpdateSuccessAction: ReturnType<typeof StatementSetActions.upsertOne>;
        statementUpdateManySuccessAction: ReturnType<typeof StatementActions.upsertMany>;
      };
    }>(),
  ),
};
