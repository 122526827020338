import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { Observable, Subscription, switchMap } from 'rxjs';

import { PatientDOBInfoComponent } from '../patient-dob-info/patient-dob-info.component';

@Component({
  standalone: true,
  imports: [CommonModule, LetDirective, PatientDOBInfoComponent],
  selector: 'rr-patient-name',
  templateUrl: './patient-name.component.html',
  styleUrls: ['./patient-name.component.css'],
})
export class PatientNameComponent implements OnInit, OnDestroy {
  @Input() bookingList = false;
  @Input() @BindObservable() patientId: number;
  patientId$: Observable<number>;

  patient$: Observable<RR.Patient | undefined>;
  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.patient$ = this.patientId$.pipe(switchMap((id) => this.store.select(fromPatient.selectPatient(id))));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
