import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.PresetTitle>();
const selectFeature = (state: AppState) => state.preset_title;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectPresetTitle = (id: number) =>
  createSelector(selectEntities, (preset_titles: Dictionary<RR.PresetTitle>) => preset_titles[id]);

const selectLoaded = createSelector(selectFeature, (preset_title) => preset_title.loaded);

export const fromPresetTitle = {
  selectIds,
  selectEntities,
  selectAll,
  selectPresetTitle,
  selectLoaded,
};
