import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EditorActions } from 'app/store/editor/editor.action';

import { ReportActions, ReportBatchActions } from './report.action';

export interface ReportState extends EntityState<RR.Report> {
  /**
   * Tracks if related reports were loaded separately from the Report entity
   */
  relatedReportsLoaded: { [id: number]: boolean };
  reportsLoaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.Report>();
const initialState = adapter.getInitialState({
  relatedReportsLoaded: {},
  reportsLoaded: {},
});

export const reportReducer = createReducer(
  initialState,
  // Used upsertOne because on the worklist the reports are already in the store. addOne does not update it with the new
  // `text` field and FinalPreviewModalComponent showed nothing.
  on(ReportBatchActions.findReportSuccess, (state, action) => {
    const report = action.actions.findReportSuccess.report;
    return {
      ...state,
      reportsLoaded: { ...state.reportsLoaded, [report.id]: true },
    };
  }),
  on(ReportActions.findTextSuccess, (state, { report }) => adapter.upsertOne(report, state)),
  on(ReportActions.loadedRelatedReports, (state, { report_id }) => {
    return {
      ...state,
      relatedReportsLoaded: { ...state.relatedReportsLoaded, [report_id]: true },
    };
  }),

  // Find all is being used for search function in the worklist. It's different from findManySuccess which find a report
  // and its related reports
  on(ReportActions.addMany, (state, { reports }) => adapter.addMany(reports, state)),
  on(ReportActions.upsertOne, (state, { report }) => adapter.upsertOne(report, state)),
  on(ReportActions.upsertMany, (state, { reports }) => adapter.upsertMany(reports, state)),
  on(ReportActions.updateOne, (state, { update }) => adapter.updateOne(update, state)),
  on(ReportActions.removeMany, (state, { reportIds }) => adapter.removeMany(reportIds, state)),
  on(ReportActions.mergeSuccess, (state, { request, report }) => {
    const deletedReportId = report.id === request.left_report_id ? request.right_report_id : request.left_report_id;
    return { ...adapter.upsertOne(report, state), ...adapter.removeOne(deletedReportId, state) };
  }),
  on(EditorActions.open, EditorActions.close, () => initialState),
);
