import { Store } from '@ngrx/store';
import { AppState } from 'app/store/';

import { useInjector } from '../slatement-mount/ng-react';

export function useStore() {
  const injector = useInjector();
  const store: Store<AppState> = injector.get(Store);
  return store;
}
