<div class="d-flex align-items-center mb-2">
  <button class="btn btn-outline-primary me-2" (click)="createNewInvoice()">
    <i class="fas fa-plus"></i> New Invoice
  </button>
</div>
<div class="d-flex mx-auto justify-content-center">
  <h5 class="text-center m-0" *ngIf="!(invoices$ | async)?.length">No invoices for this report</h5>
</div>

<rr-invoice-table
  *ngIf="!!(invoices$ | async)?.length"
  [invoices$]="invoices$"
  [report_id]="reportId"
></rr-invoice-table>
