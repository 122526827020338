import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { DefaultAttributeActions } from './default-attribute.action';

export interface DefaultAttributeState extends EntityState<RR.DefaultAttribute> {
  statementSetStateLoadingState: {
    [templateId: string]:
      | {
          [statementSetId: string]: undefined | 'loading' | 'loaded';
        }
      | undefined;
  };
}

const adapter = createEntityAdapter<RR.DefaultAttribute>();
const initialState: DefaultAttributeState = adapter.getInitialState({
  // additional entity state properties
  statementSetStateLoadingState: {},
});

export const defaultAttributeReducer = createReducer(
  initialState,
  on(DefaultAttributeActions.findAllSuccess, (state, { defaultAttributes }) =>
    adapter.upsertMany(defaultAttributes, state),
  ),
  on(DefaultAttributeActions.createSuccess, (state, { defaultAttribute }) => adapter.addOne(defaultAttribute, state)),
  on(DefaultAttributeActions.createManySuccess, (state, { defaultAttributes }) =>
    adapter.addMany(defaultAttributes, state),
  ),
  on(DefaultAttributeActions.updateSuccess, (state, { defaultAttribute }) =>
    adapter.upsertOne(defaultAttribute, state),
  ),
  on(DefaultAttributeActions.deleteSuccess, (state, { defaultAttributeId }) =>
    adapter.removeOne(defaultAttributeId, state),
  ),
  on(DefaultAttributeActions.findInTemplateSuccess, (state, { defaultAttributes }) =>
    adapter.upsertMany(defaultAttributes, state),
  ),
  on(DefaultAttributeActions.findInStatementSet, (state, { templateId, statementSetId }) => ({
    ...state,
    statementSetStateLoadingState: {
      [templateId]: {
        ...state.statementSetStateLoadingState[templateId],
        [statementSetId]: 'loading',
      },
    },
  })),
  on(DefaultAttributeActions.findInStatementSetSuccess, (state, { templateId, statementSetId, defaultAttributes }) => ({
    ...adapter.upsertMany(defaultAttributes, state),
    loadedStatementSet: {
      [templateId]: {
        ...state.statementSetStateLoadingState[templateId],
        [statementSetId]: 'loaded',
      },
    },
  })),
  on(DefaultAttributeActions.findInStatementSetFailed, (state, { templateId, statementSetId }) => ({
    ...state,
    statementSetStateLoadingState: {
      [templateId]: {
        ...state.statementSetStateLoadingState[templateId],
        [statementSetId]: undefined,
      },
    },
  })),
  on(DefaultAttributeActions.updateManySuccess, (state, { defaultAttributes }) =>
    adapter.upsertMany(defaultAttributes, state),
  ),
  on(DefaultAttributeActions.findManySuccess, (state, { defaultAttributes }) =>
    adapter.upsertMany(defaultAttributes, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
