import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for CategoryStatementCombo
 *
 */
export const CategoryStatementComboActions = {
  findAllSuccess: createAction(
    '[CategoryStatementCombo] Find All Success',
    props<{ categories: RR.CategoryStatementCombo[] }>(),
  ),

  addAttributeComboSuccess: createAction(
    '[CategoryStatementCombo] Add Attribute Combo Success',
    props<{ categoryStatementCombo: RR.CategoryStatementCombo }>(),
  ),

  deleteCategoryStatementComboSuccess: createAction(
    '[CategoryStatementCombo] Delete Success',
    props<{ categoryStatementComboId: number }>(),
  ),
  updateCategoryStatementComboSuccess: createAction(
    '[CategoryStatementCombo] Update Success',
    props<{ categoryStatementCombo: RR.CategoryStatementCombo }>(),
  ),
};
