import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TempNote } from '../voice-notes.component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-temp-voice-note',
  templateUrl: './temp-voice-note.component.html',
  styleUrls: ['./temp-voice-note.component.css'],
})
export class TempVoiceNoteComponent {
  @Input() note: TempNote;
  @Output() onSelectTempNote = new EventEmitter();
  @Output() onDeleteTempNote = new EventEmitter();

  selectNote() {
    this.onSelectTempNote.emit(this.note);
  }

  deleteNote() {
    this.onDeleteTempNote.emit(this.note);
  }
}
