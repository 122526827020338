import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

import { ReportAccessionNumberComponent } from '../../report-accession-number/report-accession-number.component';

@Component({
  selector: 'rr-preset-from-report',
  standalone: true,
  imports: [SharedModule, ReportAccessionNumberComponent],
  templateUrl: './preset-from-report.component.html',
  styleUrl: './preset-from-report.component.css',
})
export class PresetFromReportComponent {
  @Input() presetFromReport: RR.Report;
  @Output() onClick = new EventEmitter<MouseEvent>();
}
