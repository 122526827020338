import { createAction, props } from '@ngrx/store';

import { PercentileBandRule } from './percentile-band-rule.service';

/**
 * Ngrx Actions for Percentile Band Rules
 *
 */

export const PercentileBandRuleActions = {
  searchSuccess: createAction(
    '[PercentileBandRule] Search Success',
    props<{ percentile_band_rules: PercentileBandRule[] }>(),
  ),
  createSuccess: createAction(
    '[PercentileBandRule] Create Success',
    props<{ percentile_band_rule: PercentileBandRule }>(),
  ),
  deleteSuccess: createAction('[PercentileBandRule] Delete Success', props<{ percentile_band_rule_id: number }>()),
  updateSuccess: createAction(
    '[PercentileBandRule] Update Success',
    props<{ percentile_band_rule: PercentileBandRule }>(),
  ),
};
