import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbPopover,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { trackById } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { ICategorisedAttribute, TemplateService } from 'app/core/services/template.service';
import {
  TutorialModalComponent,
  TutorialType,
} from 'app/modules/tutorial/modals/tutorial-modal/tutorial-modal.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { AppState } from 'app/store';
import { fromCategory } from 'app/store/category';
import { CategoryStatementComboEffect, fromCategoryStatementCombo } from 'app/store/category-statement-combo';
import { fromStatement } from 'app/store/template/statement';
import { fromStatementSet } from 'app/store/template/statement-set';
import { fromTextObject } from 'app/store/template/text-object/text-object.selector';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { SharedModule } from '../../../../shared/shared.module';
import { StatementComponent } from '../../statement/statement.component';

@Component({
  selector: 'rr-categorise-sentence-modal',
  templateUrl: './categorise-sentence-modal.component.html',
  styleUrls: ['./categorise-sentence-modal.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    CommonModule,
    StatementComponent,
    NgbPopover,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
  ],
})
export class CategoriseSentenceModalComponent implements OnInit, OnDestroy {
  @ViewChild('popover', { static: false }) popover: NgbPopover;
  @ViewChild('otherCategoriesBtn', { static: false }) otherCategoriesBtn: ElementRef;

  @Input() report_id: number;
  @Input() topic_id: number;
  topic$: Observable<RR.Topic>;
  @Input() statement_set_id: number;
  @Input() statement_id: number;
  @Input() attributes: ICategorisedAttribute[] = [];
  @Input() region_id: number;

  trackBy = trackById;
  subscription: Subscription;
  attributeSetOptions: (RR.AttributeOption & { frequency: number })[] = [];
  loadingAttributeSet = false;

  topCategories: { category_id: number; count: number }[] = [];
  templateCategories: RR.Category[] = [];

  // If statement is a divider, show sentences under that divider
  dividerSentences: RR.Statement[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private templateService: TemplateService,
    private messageService: MessageService,
    private store: Store<AppState>,
    private editorService: EditorService,
    private reportService: ReportService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private categoryStatementComboEffect: CategoryStatementComboEffect,
  ) {}

  ngOnInit(): void {
    this.subscription = new Subscription();

    this.subscription.add(
      this.reportService
        .getTopic(this.topic_id)
        .pipe(switchMap((topic) => this.templateService.getTemplateCategories(topic.template_id)))
        .subscribe((categories) => {
          // @ts-expect-error strictNullChecks
          this.templateCategories = categories;
        }),
    );
    // Get top 20 categories
    this.reportService
      .getTopic(this.topic_id)
      .pipe(
        map((topic) => topic.template_id),
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((template_id) => {
        this.http
          .get<any[]>(`/api/get_top_categories?template_id=${template_id}`)
          .pipe(take(1))
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2 */
          .subscribe((data) => {
            this.topCategories = data;
          });
      });

    // Get sentences under divider if the statement is a divider
    this.getDividerSentences();
    this.topic$ = this.reportService.getTopic(this.topic_id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getStatement() {
    return this.store
      .select(fromStatement.selectStatement(this.statement_id))
      .pipe(filter((s): s is RR.Statement => !!s));
  }

  getTextObjects() {
    return this.store.select(fromStatement.selectTextObjects(this.statement_id));
  }

  getDividerSentences() {
    // Get sentences under dividers
    this.getStatement()
      .pipe(
        switchMap((s) => {
          if (!s.is_divider) {
            return of([]);
          }
          return this.store.select(fromStatement.selectEntities).pipe(
            map((statement_entities) =>
              Object.values(statement_entities)
                // @ts-expect-error strictNullChecks
                .filter((statement) => statement.divider_id === s.id)
                // @ts-expect-error strictNullChecks
                .sort((a, b) => a.position - b.position),
            ),
          );
        }),
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((statements) => {
        // @ts-expect-error strictNullChecks
        this.dividerSentences = statements;
      });
  }

  getLiteral(text: string) {
    if (this.region_id) {
      return this.templateService
        .getRegion(this.region_id)
        .pipe(map((region) => this.templateService.replaceStatementTextTemplateWithRegion(text, region)));
    } else {
      return of(text);
    }
  }

  getSelectedAttributeText(text_object_id: number) {
    const option = this.attributes.find((attr) => attr.text_object_id === text_object_id);
    if (!option) return of('');
    return undefined;
    // return (
    //   this.templateService
    //     .getAttributeOption(option.attribute_option_id)
    //     // @ts-expect-error strictNullChecks
    //     .pipe(map((attr_option) => attr_option.text))
    // );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOpenDropdown(open: boolean, attribute_set_id: number, text_object_id: number) {
    if (open) {
      this.attributeSetOptions = [];
      this.loadingAttributeSet = true;
      // this.templateService
      //   .getTopAttributes(text_object_id)
      //   .pipe(withLatestFrom(this.templateService.getAttributeSetOptions(attribute_set_id)))
      //   .pipe(
      //     finalize(() => {
      //       this.loadingAttributeSet = false;
      //       this.cd.markForCheck();
      //     }),
      //   )
      //   .pipe(take(1))
      //   // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      //   .subscribe(([frequency_list, options]) => {
      //     this.attributeSetOptions = options
      //       .map((option) => {
      //         const freq = frequency_list.find(
      //           (f: { attribute_option_id: number; frequency: number }) => f.attribute_option_id === option.id,
      //         );
      //         return { ...option, frequency: freq ? freq.frequency : 0 };
      //       })
      //       .sort((a, b) => b.frequency - a.frequency);
      //     this.cd.markForCheck();
      //   });
    }
  }

  selectAttribute(text_object_id: number, attribute_option_id: number) {
    const attr = this.attributes.find((a) => a.text_object_id === text_object_id);
    if (attr) {
      attr.attribute_option_id = attribute_option_id;
      this.cd.markForCheck();
    }
  }

  getSentenceCategories() {
    return this.templateService
      .getSentenceCategories(this.statement_id, this.attributes)
      .pipe(map((categories) => categories.filter((c): c is RR.Category => !!c)));
  }

  /**
   * Remember last selected categories to assign to the sentence
   */
  getLastSelectedCategories(top20 = false) {
    return this.editorService.getMostRecentCategories(top20).pipe(
      switchMap((ids: number[]) => {
        if (ids.length === 0) return of(ids);
        return this.getSentenceCategories().pipe(
          map((categories) => {
            // Don't show it if the sentence was already classified under that category
            return ids.filter(
              (id) => !categories.some((c) => c.id === id) && this.templateCategories.some((c) => c.id === id),
            );
          }),
        );
      }),
    );
  }

  showTopCategories() {
    if (this.popover.isOpen() || this.topCategories.length === 0) {
      return;
    }
    this.popover.open();
  }

  categoriseSentenceFromTop20(category_id: number) {
    this.editorService.saveMostRecentCategory(category_id, true);
    this.categoriseSentence(category_id);
  }

  getCategoryName(id: number) {
    return this.store.select(fromCategory.selectCategory(id)).pipe(map((c) => (c ? c.text : '')));
  }

  // TODO: refactor it, many nested subscribe
  categoriseSentence(category_id: number) {
    // Check if category already assiged for this sentence
    this.getSentenceCategories()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((cats) => {
        if (cats.some((cat) => cat.id === category_id)) {
          this.messageService.add({
            message: 'This statement has already classified under selected category',
            title: 'Error',
            type: 'danger',
            timeout: 5000,
          });
          return;
        }
        // Check if there is any blank attribute option
        this.hasBlankAttributes()
          .pipe(take(1))
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2 */
          .subscribe((b) => {
            if (!b) {
              this.subscription.add(
                this.categoryStatementComboEffect
                  .addAttributeCombo({
                    category_id,
                    statement_id: this.statement_id,
                    attributes: this.attributes,
                  })
                  // eslint-disable-next-line rxjs/no-nested-subscribe
                  .subscribe(),
              );
            } else {
              // Show confirm dialog
              const modalRef = ConfirmMessageModalComponent.open({
                modalService: this.modalService,
                header: 'Confirm',
                message: 'This statement has one or more empty attributes. Do you want to continue categorising it?',
                btnConfirmText: 'Yes',
              });
              modalRef.result.then(
                () => {
                  this.subscription.add(
                    this.categoryStatementComboEffect
                      .addAttributeCombo({
                        category_id,
                        statement_id: this.statement_id,
                        attributes: this.attributes,
                      })
                      // eslint-disable-next-line rxjs/no-nested-subscribe
                      .subscribe(),
                  );
                },
                () => {
                  /* do nothing */
                },
              );
            }
          });
      });
  }

  hasBlankAttributes() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.attributes || this.attributes.length === 0) return of(false);
    return of(false);
    // return zip(
    //   ...this.attributes.map((attr) => this.templateService.getAttributeOption(attr.attribute_option_id)),
    // ).pipe(
    //   // @ts-expect-error strictNullChecks
    //   map((options: AttributeOption[]) => {
    //     return options.some((o) => !o.text);
    //   }),
    // );
  }

  removeSentenceCategory(category_id: number) {
    this.subscription.add(
      this.categoryStatementComboEffect
        .deleteAttributeCombo({
          category_id,
          statement_id: this.statement_id,
          attributes: this.attributes,
        })
        .subscribe(),
    );
  }

  openVideoModal(type: TutorialType) {
    TutorialModalComponent.open(this.modalService, type);
  }

  closeTopCategoriesPopover() {
    requestAnimationFrame(() => {
      this.otherCategoriesBtn.nativeElement.focus();
    });
  }

  /**
   * Get next uncategorised divider in the same element
   * Only return category without attribute
   */
  getNextUncategorisedDivider() {
    if (!this.statement_set_id) return of(null);
    return combineLatest([
      this.getStatement(),
      this.store.select(fromStatement.selectTextObjects(this.statement_id)),
      this.store
        .select(fromStatementSet.selectStatements(this.statement_set_id))
        .pipe(
          map((statements) =>
            statements.filter((statement) => statement.is_divider && this.statement_id !== statement.id),
          ),
        ),
      this.store
        .select(fromCategoryStatementCombo.selectEntities)
        .pipe(map((combo_entities) => Object.values(combo_entities))),
    ]).pipe(
      map(([statement, textObjects, dividers, catCombos]) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!dividers || dividers.length === 0) return null;
        const uncategorisedDividers = dividers.filter(
          (d) =>
            // @ts-expect-error strictNullChecks
            !textObjects.some((tobj) => tobj.type === 'set') && !catCombos.some((combo) => combo.statement_id === d.id),
        );
        if (uncategorisedDividers.length === 0) return null;
        const next = uncategorisedDividers.find((d) => d.position > statement.position);
        return next || uncategorisedDividers[0];
      }),
    );
  }

  getNextDivider() {
    if (!this.statement_set_id) return of(null);
    return combineLatest([
      this.getStatement(),
      this.store.select(fromTextObject.selectEntities),
      this.store
        .select(fromStatementSet.selectStatements(this.statement_set_id))
        .pipe(
          map((statements) =>
            statements.filter((statement) => statement.is_divider && this.statement_id !== statement.id),
          ),
        ),
    ]).pipe(
      map(([statement, textObjectEntities, dividers]) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!dividers || dividers.length === 0) return null;
        const nextDividers = dividers.filter((d) => {
          return !d.text_objects.map((id) => textObjectEntities[id]).some((tobj) => tobj?.type === 'set');
        });
        if (nextDividers.length === 0) return null;
        const next = nextDividers.find((d) => d.position > statement.position);
        return next || nextDividers[0];
      }),
    );
  }

  nextDivider(id: number) {
    this.statement_id = id;
    this.attributes = [];
    this.getDividerSentences();
    this.cd.detectChanges();
  }

  static open(
    editorService: EditorService | null,
    modalService: NgbModal,
    report_id: number,
    topic_id: number,
    statement_set_id: number | null,
    region_id: number | null,
    statement_id: number,
    attributes: ICategorisedAttribute[],
  ) {
    const modalRef = modalService.open(CategoriseSentenceModalComponent, {
      size: 'xl',
      scrollable: true,
      centered: true,
    });
    modalRef.componentInstance.report_id = report_id;
    modalRef.componentInstance.topic_id = topic_id;
    modalRef.componentInstance.statement_set_id = statement_set_id;
    modalRef.componentInstance.region_id = region_id;
    modalRef.componentInstance.statement_id = statement_id;
    modalRef.componentInstance.attributes = attributes;

    modalRef.result.then(
      () => {
        if (editorService) editorService.publishFocus({ statement_id });
      },
      () => {
        if (editorService) editorService.publishFocus({ statement_id });
      },
    );
    return modalRef;
  }
}
