import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromAttributeOption } from '../attribute-option/attribute-option.selector';

const adapter = createEntityAdapter<RR.AttributeSet>();
const selectFeature = (state: AppState) => state.attribute_set;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = createSelector(selectFeature, ({ loaded }) => loaded);

const selectAttributeSet = (attributeSetId: number) =>
  createSelector(selectEntities, (attributeSet: Dictionary<RR.AttributeSet>) => attributeSet[attributeSetId]);

const selectAttributeOptions = (attributeSetId: number) => {
  return createSelector(
    selectAttributeSet(attributeSetId),
    fromAttributeOption.selectEntities,
    (attributeSet, attributeOptions: Dictionary<RR.AttributeOption>) => {
      if (!attributeSet) {
        return [];
      }
      const attributeOptionList = attributeSet.attribute_option_ids
        .map((attributeOptionId) => attributeOptions[attributeOptionId])
        .filter((a): a is RR.AttributeOption => !!a);

      return attributeOptionList;
    },
  );
};

export const fromAttributeSet = {
  selectLoaded,
  selectIds,
  selectEntities,
  selectAttributeOptions,
  selectAll,
  selectAttributeSet,
};
