import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-flag-note-modal',
  templateUrl: './flag-note-modal.component.html',
  styleUrls: ['./flag-note-modal.component.css'],
  standalone: true,
  imports: [FormsModule],
})
export class FlagNoteModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('noteInput') noteInput: ElementRef;
  note = '';

  subscription = new Subscription();

  constructor(public activeModal: NgbActiveModal) {}

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      this.noteInput.nativeElement.focus();
    });
  }

  addTodo() {
    this.activeModal.close(this.note.trim());
  }

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(FlagNoteModalComponent, { size: 'lg' });
    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
