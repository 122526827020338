import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BindObservable, filterDefined } from 'app/app.utils';
import { institutionTypeMap } from 'app/modules/invoice/invoice.constants';
import { SharedModule } from 'app/shared/shared.module';
import { getEmailValidatorRegex } from 'app/shared/utils/shared.utils';
import { InstitutionEffect } from 'app/store/institution';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  selector: 'rr-institution-form-edit',
  templateUrl: './institution-form-edit.component.html',
  styleUrls: ['./institution-form-edit.component.css'],
})
export class InstitutionFormEditComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() institution?: RR.Institution;
  institution$: Observable<RR.Institution | undefined>;

  @Output() onChange: EventEmitter<{ institution: RR.Institution; created: boolean }> = new EventEmitter();

  subscription = new Subscription();
  institutionTypeMap = institutionTypeMap;
  institutionTypes: RR.InstitutionType[] = Object.keys(institutionTypeMap);

  form = new FormGroup({
    name: new FormControl('', { validators: [Validators.required], nonNullable: true }),
    type: new FormControl<RR.InstitutionType>('third_party', { validators: [Validators.required], nonNullable: true }),
    address: new FormControl('', { nonNullable: true }),
    suburb: new FormControl('', { nonNullable: true }),
    state: new FormControl('', { nonNullable: true }),
    postcode: new FormControl('', { nonNullable: true }),
    contact_person: new FormControl('', { nonNullable: true }),
    phone: new FormControl('', { validators: [Validators.pattern('[0-9]{10}')], nonNullable: true }),
    email: new FormControl('', {
      validators: [Validators.email, Validators.pattern(getEmailValidatorRegex())],
      nonNullable: true,
    }),
    notes: new FormControl('', { nonNullable: true }),
  });

  constructor(private institutionEffect: InstitutionEffect) {}

  get name() {
    return this.form.controls.name as FormControl;
  }

  get type() {
    return this.form.controls.type as FormControl;
  }

  get phone() {
    return this.form.controls.phone as FormControl;
  }

  get email() {
    return this.form.controls.email as FormControl;
  }

  ngOnInit(): void {
    this.subscription.add(
      this.institution$.pipe(filterDefined()).subscribe((i) => {
        this.form.patchValue({
          name: i.name,
          type: i.type,
          address: i.address ?? '',
          suburb: i.suburb ?? '',
          state: i.state ?? '',
          postcode: i.postcode ?? '',
          contact_person: i.contact_person ?? '',
          phone: i.phone ?? '',
          email: i.email ?? '',
          notes: i.notes ?? '',
        });
      }),
    );
  }

  emitInstitution(institution: RR.Institution, created = false) {
    this.form.markAsPristine();
    this.onChange.emit({ institution, created });
  }

  submit() {
    if (!this.form.valid) return;
    const data = this.getFormData();
    if (!this.institution) {
      this.institutionEffect
        .create(data)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe((action) => {
          this.emitInstitution(action.institution, true);
        });
    } else {
      this.institutionEffect
        .update(this.institution.id, data)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe((action) => {
          this.emitInstitution(action.institution);
        });
    }
  }

  getFormData() {
    return {
      name: this.form.controls.name.value,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      type: this.form.controls.type.value ?? '',
      address: this.form.controls.address.value,
      suburb: this.form.controls.suburb.value,
      state: this.form.controls.state.value,
      postcode: this.form.controls.postcode.value,
      contact_person: this.form.controls.contact_person.value,
      phone: this.form.controls.phone.value,
      email: this.form.controls.email.value,
      notes: this.form.controls.notes.value,
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
