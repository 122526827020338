<ng-container *ngIf="topic$ | async as topic">
  <rr-top-statement-button-group
    class="w-100 d-flex flex-wrap"
    *ngIf="show_label"
    [parent]="parent"
    [element]="element"
    [topic]="topic"
    [report]="report"
    [element_choice]="element_choice"
    (topStatements)="topStatementListChanged($event)"
    (onScroll)="onScrollClicked($event)"
  ></rr-top-statement-button-group>
  <!-- btn-group is display: flex. The .btn children have flex-grow: 1. So we must override to flex-grow-0 to keep the
  buttons small -->
  <div class="btn-group d-flex w-100 element-name bg-primary" role="group">
    <button
      permission
      [permission-only]="['template_manage']"
      role="button"
      tabindex="-1"
      (click)="showElementUsageModal()"
      class="btn btn-primary btn-sm flex-grow-0"
      rrTooltip="SHOW_ELEMENT_USAGES"
      *ngIf="show_label"
    >
      <span class="fa fa-list-alt"></span>
    </button>
    <button
      permission
      [permission-only]="['template_manage']"
      role="button"
      tabindex="-1"
      (click)="removeElement()"
      class="btn btn-danger btn-sm flex-grow-0"
      rrTooltip="REMOVE_ELEMENT_FROM_TEMPLATE"
      *ngIf="show_label"
    >
      <span class="fa fa-trash"></span>
    </button>
    <button
      class="btn btn-primary fw-bold flex-grow-1 p-1 d-flex align-items-center justify-content-center overflow-hidden"
      [title]="statement_set.name"
      *ngIf="statement_set && statement_set.name && show_label"
      (click)="toggleLabel()"
      role="button"
      data-cy="element-button"
    >
      <div class="text-truncate">{{ statement_set.name }}</div>
    </button>
    <input
      #statementSetNameInput
      class="form-control form-control-sm"
      *ngIf="(statement_set && !statement_set.name) || !show_label"
      permission
      [permission-only]="['template_manage']"
      placeholder="enter set name here"
      [(ngModel)]="statement_set_name"
      (keydown.enter)="setStatementSetName()"
      (keydown.escape)="show_label = true"
      (blur)="show_label = true"
    />
  </div>
  <div class="d-flex px-2 bg-primary align-items-center text-white w-100 py-1 mb-0" *ngIf="is_in_sc_mode$ | async">
    <span class="fas fa-layer-group"></span>
    <span class="ms-2"
      ><strong
        >Categorised groups: {{ numberOfCategorisedDividers | async }}/{{
          getNumberOfStatements('DIVIDER') | async
        }}</strong
      >
    </span>

    <span class="ms-3"
      ><strong
        >Categorised statements: {{ numberOfCategorisedStatements | async }}/{{
          getNumberOfStatements('STATEMENT') | async
        }}</strong
      >
    </span>
  </div>
  <div class="btn-group w-100" role="group" *ngIf="!creatingSpecific2" permission>
    <button
      *ngIf="parent === 'EDITOR'"
      role="button"
      class="btn btn-info text-center py-0 px-1 justify-content-center"
      [class.d-flex]="!creatingSpecific2"
      [hidden]="creatingSpecific2"
      (click)="creatingSpecific2 = true"
      tabindex="-1"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="SEARCH_CURRENT_ELEMENT"
    >
      <p class="text-center top-btn-text fw-bold" style="margin-bottom: 0">
        <i class="fas fa-search-plus"></i> {{ NEW_REPORT_SPECIFIC }}
      </p>
    </button>

    <button
      role="button"
      class="btn btn-info text-center py-0 px-1 justify-content-center d-flex"
      *ngIf="element.type === 'element'"
      (click)="addGlobalSentence()"
      tabindex="-1"
      permission
      [permission-only]="['report_manage']"
    >
      <p class="text-center top-btn-text fw-bold" style="margin-bottom: 0">
        <i class="fas fa-file-alt"></i> Global statements
      </p>
    </button>

    <ng-container *ngIf="incompleteMandatoryStatements$ | async as incompleteMandatoryStatements">
      <button
        class="btn btn-info text-center py-0 justify-content-center d-flex"
        *ngIf="incompleteMandatoryStatements && incompleteMandatoryStatements.length > 0 && parent === 'EDITOR'"
        (click)="mandatoryStatementService.openMandatoryStatementModal(incompleteMandatoryStatements)"
      >
        <div class="text-center top-btn-text fw-bold">
          <i class="fas fa-exclamation"></i> Pending Statements
          <span class="badge rounded-pill bg-danger me-1">{{ incompleteMandatoryStatements.length }} </span>
        </div>
      </button>
    </ng-container>

    <button
      rrTooltip="CREATE_CATEGORY"
      *ngIf="parent === 'EDITOR'"
      role="button"
      class="btn btn-info text-center py-0 px-1 justify-content-center d-flex"
      (click)="openCategoryModal()"
      tabindex="-1"
      permission
      [permission-only]="['template_manage']"
    >
      <p class="text-center top-btn-text fw-bold" style="margin-bottom: 0">
        <i class="fas fa-folder-plus"></i> New category
      </p>
    </button>

    <button
      role="button"
      class="btn btn-info text-center py-0 px-1 justify-content-center d-flex"
      (click)="openEditor(element.type === 'element' ? 'createStatement' : 'createNotepadStatement')"
      tabindex="-1"
      permission
      [permission-only]="['superuser']"
      rrTooltip="CREATING_STATEMENT"
    >
      <p class="text-center top-btn-text fw-bold" style="margin-bottom: 0">
        <i class="fas fa-plus"></i> {{ NEW_TEMPLATE_STATEMENT }}
      </p>
    </button>
  </div>

  <div *ngIf="creatingSpecific2" class="px-0 py-1 list-group-item-info">
    <rr-statement-edit
      *ngIf="creatingSpecific2"
      (onSubmitBlue)="createSpecificStatement($event)"
      (onCancel)="creatingSpecific2 = false"
      [region]="region"
      [topic]="topic"
      [statementSetId]="element.statement_set_id"
      [editType]="'createBlueStatement'"
    >
    </rr-statement-edit>
  </div>

  <div
    id="{{ ELEMENT_SCROLL_ID }}-{{ instanceId }}"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
    class="scrollable px-1"
    [ngClass]="{ 'rr-element-list-highlight': highlight | async }"
  >
    <div class="divider-group list-group">
      <rr-divider
        class="list-group-item list-group-item-action text-start w-100 px-0"
        *ngFor="let statement of dividers | async"
        [element]="element"
        [statement]="statement"
        [region]="region"
        [topic]="topic"
        [subsection]="subsection"
        [parent]="parent"
      >
      </rr-divider>
    </div>
    <!-- This div is for marking a spot to scroll to -->
    <div [id]="STATEMENT_LIST_ID"></div>
    <div class="statement-group list-group" cdkDropList (cdkDropListDropped)="drop($event)">
      <rr-statement
        cdkDrag
        class="list-group-item list-group-item-action text-start w-100 p-0 text-break"
        tabindex="-1"
        *ngFor="let statement of filterStatements; let i = index; trackBy: trackById"
        [element]="element"
        [createInElement]="element.type === 'element' ? element : createInElement"
        [element_choice]="element_choice"
        [statement]="statement"
        [region]="region"
        [topic]="topic"
        [template_id]="topic.template_id"
        [subsection]="subsection"
        [section]="section"
        (onEdit)="editingStatement = $event"
        [filterType]="filterType"
        [frequency]="getStatementFrequency(statement.id)"
        (onClickDivider)="expandDivider($event)"
        [ngbPopover]="statement.tooltip"
        triggers="mouseenter:mouseleave"
        [disablePopover]="!statement.tooltip"
        container="body"
        [parent]="parent"
        [context]="'MAIN_EDITOR'"
        [statementGroupIds]="statementGroupIds"
        [report]="report"
      >
      </rr-statement>
    </div>
    <a
      rr-report-specific-choice
      *ngFor="let choice of legacyChoices | async"
      class="list-group-item list-group-item-action list-group-item-info w-100 px-0"
      [choice]="choice"
      [topic]="topic"
      [element]="element"
      [region]="region"
      [subsection]="subsection"
      [legacy]="true"
      [parent]="parent"
    >
    </a>
    <a
      rr-report-specific-choice
      tabindex="-1"
      *ngFor="let choice of reportSpecificChoices"
      class="list-group-item list-group-item-action list-group-item-info w-100 px-0"
      [choice]="choice"
      [topic]="topic"
      [element]="element"
      [region]="region"
      [subsection]="subsection"
      [parent]="parent"
    >
    </a>
    <!-- No list-group-item class on this div because it changes the z-index
      and choices from the next element appear above the statement suggestions -->
    <rr-category
      class="list-group-item list-group-item-action text-start w-100 px-0"
      *ngFor="let category of categories | async"
      [category]="category"
    >
    </rr-category>
  </div>
</ng-container>
