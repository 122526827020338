import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromFeedbackComment } from 'app/store/report/feedback-comment';
import { fromUser } from 'app/store/user/user';
import { map, Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'rr-feedback-headline',
  templateUrl: './feedback-headline.component.html',
  styleUrls: ['./feedback-headline.component.css'],
})
export class FeedbackHeadlineComponent implements OnInit {
  @Input() feedback: RR.Feedback;
  @Input() currentUser: RR.User;

  uncheckedComments$: Observable<boolean>;

  userSelectorFn = fromUser.selectUser;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.uncheckedComments$ = this.store
      .select(fromFeedbackComment.selectInFeedback(this.feedback.id))
      .pipe(
        map((comments) => !!comments.find((comment) => !comment.checked && comment.user_id !== this.currentUser.id)),
      );
  }
}
