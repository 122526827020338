import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromBillingGroup } from '../billing-group/billing-group.selector';
import { fromBillingItem } from '../billing-item/billing-item.selector';

const adapter = createEntityAdapter<RR.ScanCode>();
const selectFeature = (state: AppState) => state.scan_code;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectScanCode = (id: number) => createSelector(selectEntities, (scanCodes) => scanCodes[id]);
const selectLoaded = createSelector(selectFeature, (scan_code) => scan_code.loaded);
const selectActiveScanCodes = createSelector(selectAll, (codes) => codes.filter((c) => !c.deleted));
// Select all unique billing items in scan code
const selectBillingItems = (scanCodeId: number) =>
  createSelector(
    fromBillingGroup.selectInScanCode(scanCodeId),
    fromBillingItem.selectEntities,
    (billing_groups: RR.BillingGroup[], billing_items: Dictionary<RR.BillingItem>) => {
      return [
        ...new Set(
          billing_groups
            .map((g) => g.billing_items)
            .flat()
            .map((item_id) => billing_items[item_id])
            .filter((item): item is RR.BillingItem => !!item),
        ),
      ];
    },
  );

export const fromScanCode = {
  selectIds,
  selectEntities,
  selectAll,
  selectScanCode,
  selectLoaded,
  selectActiveScanCodes,
  selectBillingItems,
};
