import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UserSettingHttpService {
  constructor(private http: HttpClient) {}

  findAll(params?: HttpParams): Observable<RR.UserSetting[]> {
    if (params) {
      return this.http.get<RR.UserSetting[]>('/api/user_setting', { params });
    }
    return this.http.get<RR.UserSetting[]>('/api/user_setting');
  }

  update(userSettingId: number, changes: Partial<RR.UserSetting>): Observable<RR.UserSetting> {
    return this.http.put<RR.UserSetting>(`/api/user_setting/${userSettingId}`, changes);
  }
}
