import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { ToastActions } from 'app/store/toast/toast.action';
import { Toast } from 'app/store/toast/toast.reducer';
import { fromToast } from 'app/store/toast/toast.selector';
import { interval as observableInterval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'rr-toast-list',
  templateUrl: './toast-list.component.html',
  styleUrls: ['./toast-list.component.css'],
  standalone: true,
  imports: [CommonModule, ToastComponent],
})
export class ToastListComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  toasts: Toast[];
  countdownSubscriptions: Subscription[] = [];
  subscription = new Subscription();

  constructor(
    private selectorService: SelectorService,
    private store: Store<AppState>,
    private action$: Actions,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((user) => {
        this.isAuthenticated = !!user;
      }),
    );

    this.subscription.add(
      this.store.select(fromToast.selectToasts).subscribe((toasts) => {
        this.toasts = toasts;
      }),
    );

    this.subscription.add(
      this.action$.pipe(ofType(ToastActions.add)).subscribe((action) => {
        this.setSubscriptionTimer(action.toast);
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  remove(toast: Toast) {
    this.store.dispatch(ToastActions.remove({ toast }));
  }

  mouseover() {
    this.countdownSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  mouseout() {
    this.toasts.forEach((toast) => {
      this.setSubscriptionTimer(toast);
    });
  }

  setSubscriptionTimer(toast: Toast) {
    if (toast.timeout !== null) {
      this.countdownSubscriptions.push(
        observableInterval(toast.timeout)
          .pipe(take(1))
          // eslint-disable-next-line rxjs-angular/prefer-composition
          .subscribe(() => {
            this.remove(toast);
          }),
      );
    }
  }
}
