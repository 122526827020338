import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'db4Link',
})
export class DB4LinkPipe implements PipeTransform {
  transform(reportId: number | undefined) {
    if (!reportId) {
      return null;
    }

    return new URL(`/api/form/db4/${reportId}`, location.origin);
  }
}
