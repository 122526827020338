<div class="container-fluid d-flex flex-nowrap align-items-center justify-content-end">
  <ng-container *ngIf="currentUser$ | async as user">
    <div class="d-flex flex-nowrap" *ngIf="isEditorPage">
      <div class="form-check form-switch d-flex align-items-center p-0 me-2" rrTooltip="TOGGLE_VOICE_MODE">
        <input
          type="checkbox"
          class="form-check-input"
          id="voiceMode"
          [checked]="voiceMode"
          (change)="toggleVoiceMode()"
        />
        <label class="form-check-label ms-1" for="voiceMode" role="button"><small>Voice</small></label>
      </div>
      <!-- <div class="form-check form-switch d-flex align-items-center p-0" rrTooltip="S_C_MODE">
        <input type="checkbox" class="form-check-input" id="scMode" (change)="toggleSCMode()" />
        <label class="form-check-label ms-1" for="scMode" role="button">s/c</label>
      </div> -->
    </div>

    <div *ngIf="!isEditorPage">
      <a
        rrTooltip="MAKE_NEW_BOOKING"
        class="nav-link d-flex align-items-center px-2"
        routerLink="/registration/booking"
      >
        <i class="fas fa-calendar-check"></i>
        <span class="ms-1 d-none d-xl-block">Create Booking</span>
      </a>
    </div>

    <div>
      <a
        class="nav-link d-flex align-items-center px-2"
        rrTooltip="BOOKING_LIST"
        *ngIf="routerHistoryService.lastBookingListUrl$ | async as url"
        [rrDynamicLink]="url"
      >
        <i class="fas fa-list"></i>
        <span class="ms-1 d-none d-xl-block">Booking List</span>
      </a>
    </div>

    <div *ngIf="!isEditorPage">
      <a
        class="nav-link d-flex align-items-center px-2"
        rrTooltip="REGISTER_WALKIN_PATIENT"
        routerLink="/registration/report"
      >
        <i class="fas fa-walking"></i>
        <span class="ms-1 d-none d-xl-block">Register walk-in Patient</span>
      </a>
    </div>

    <div permission *ngIf="!isEditorPage">
      <a class="nav-link d-flex align-items-center px-2" routerLink="/tasks">
        <span class="fas fa-draw-polygon"></span>
        <span class="ms-1 d-none d-xl-block" rrTooltip="NAVBAR_BTN_ANNOTATION_TASKS">Annotation tasks</span>
      </a>
    </div>

    <div>
      <rr-worklist-link></rr-worklist-link>
    </div>
  </ng-container>

  <div class="d-flex align-items-center">
    <a style="font-size: 12px" class="btn btn-dark btn-sm d-none d-xl-block fw-bold" href="/changelog/"
      ><ng-container *ngIf="version$ | async as version">v{{ version }}</ng-container></a
    >
  </div>
  <div class="d-flex flex-nowrap align-items-center">
    <form rr-issue class="d-flex mb-0 ms-1"></form>
    <rr-shortcuts class="d-flex flex-nowrap mb-0 ms-1"></rr-shortcuts>
    <rr-session-dropdown></rr-session-dropdown>
  </div>
</div>
