<div class="d-flex mb-1" *ngIf="mode === 'editor' && topic">
  <rr-title-edit-dropdown class="w-100" [topic]="topic"></rr-title-edit-dropdown>
  <button
    *ngIf="topic.title_option_id"
    class="ms-1 btn btn-sm btn-info py-0"
    (click)="importTitleTopicsToPrefill()"
    rrTooltip="FIVE_LATEST_TOPICS"
    rrTrack
  >
    <i class="fas fa-file-import fa-sm"></i>
  </button>
  <div ngbDropdown class="d-flex">
    <button class="btn btn-info ms-1 py-0" rrTooltip="DROPDOWN_TOOLBOX_BTN" rrTrack ngbDropdownToggle>
      <i class="fas fa-toolbox fa-sm"></i>
    </button>
    <div ngbDropdownMenu class="dropdown-defaults">
      <div *ngIf="!processingDefaults && topic.title_option_id">
        <button
          ngbDropdownItem
          type="button"
          class="btn text-normal dropdown-item"
          rrTooltip="PREFILL_TITLE_SENTENCES"
          rrTrack
          (click)="prefillSentences('TITLE')"
        >
          <span class="fa fa-file-import text-primary"></span> Prefill Title Statements
        </button>

        <button
          ngbDropdownItem
          (click)="prefillSentences('PREDICTION')"
          class="btn text-normal dropdown-item"
          type="button"
          rrTooltip="PREFILL_PREDICTION_SENTENCES"
          rrTrack
        >
          <span class="fa fa-file-import me-1 text-primary"></span>Prefill Prediction Statements
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mode === 'editor' && !!topic">
  <div *ngIf="processingDefaults" class="d-flex justify-content-center align-items-center">
    <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
    <span class="ms-2">{{ processingMessage }}</span>
  </div>
  <div *ngIf="!processingDefaults && processingMessage" class="alert alert-warning py-1" role="alert">
    <span>{{ processingMessage }}</span>
    <button role="button" class="btn-close" (click)="processingMessage = ''"></button>
  </div>
</div>

<ngb-alert *ngIf="mode === 'editor' && (underlinePrefilled | async)?.underline" type="dark" [dismissible]="false">
  <span class="fa fa-info-circle"></span> Prefilled statements are underlined
  <button
    type="button"
    class="btn btn-sm btn-outline-danger"
    title="Remove all underlined statements"
    (click)="deleteUnderlined()"
  >
    <span class="fa fa-trash"></span> Undo
  </button>
  <button
    type="button"
    class="btn btn-sm btn-outline-dark ms-1"
    title="Stop underlining prefilled statements"
    (click)="stopUnderline()"
  >
    Stop underlining
  </button>
</ngb-alert>
<div *ngIf="!topicLoaded">Loading...</div>
<div
  [id]="PREVIEW_SCROLL_ID"
  class="preview px-1"
  [class.card]="mode === 'editor'"
  [class.preview-worklist]="mode === 'worklist'"
  *ngIf="!!topic && topicLoaded"
>
  <div *ngIf="mode === 'prefill' || mode === 'final' || mode === 'worklist'">
    <ng-container *ngIf="report$ | async as report">
      <ng-container *ngIf="patient$ | async as patient">
        <strong
          >{{ patient?.patient_first_name }} {{ patient?.patient_last_name }}
          {{ report | patientShortInfo: patient }}</strong
        >
      </ng-container>
      <div *ngIf="topic.copied">Was copied: {{ topic.copied | date: 'MMM d, y, H:mm' }}</div>
      <hr *ngIf="report.patient_id || topic.copied" />
    </ng-container>
  </div>

  <div *ngIf="mode === 'editor'" class="d-flex flex-column">
    <rr-report-accession-number *ngIf="report$ | async as report" [report]="report"></rr-report-accession-number>
  </div>
  <p
    class="h1 text-center fw-bold"
    *ngIf="mode === 'prefill' || mode === 'final' || mode === 'worklist'"
    role="button"
    (click)="jumpToTitle(topic)"
  >
    {{ topic.title_option_text }}
  </p>
  <hr class="my-3" *ngIf="(keyfindings$ | async)?.length || topic.title_option_text" />
  <p class="h2 fw-bold px-0 mb-3" *ngIf="(keyfindings$ | async)?.length">KEY FINDINGS</p>
  <!-- eslint-disable @angular-eslint/template/no-any -->
  <rr-choice-preview
    *ngFor="let ctx of keyfindings$ | async; let $last = last; let $first = first"
    [isClone]="true"
    [choice]="$any(ctx.statement_choice)"
    [region_choice]="$any(ctx.region_choice)"
    [element_choice]="$any(ctx.element_choice)"
    [section_choice]="$any(ctx.section_choice)"
    [subsection_choice]="$any(ctx.subsection_choice)"
    [first]="$first"
    [last]="$last"
    [mode]="mode"
    [template_id]="topic.template_id"
    [attr.disabled]="mode === 'worklist' ? true : null"
  ></rr-choice-preview>
  <!-- eslint-enable @angular-eslint/template/no-any -->
  <hr class="my-3" *ngIf="(keyfindings$ | async)?.length" />
  <div *ngFor="let section_choice_id of topic.section_choices">
    <rr-heading-preview
      [topic]="topic"
      [section_choice_id]="section_choice_id"
      [mode]="mode"
      [attr.disabled]="mode === 'worklist' ? true : null"
    ></rr-heading-preview>
    <rr-section-preview
      [topic]="topic"
      [section_choice_id]="section_choice_id"
      [mode]="mode"
      [attr.disabled]="mode === 'worklist' ? true : null"
    ></rr-section-preview>
    <hr class="my-3" />
  </div>
  <div class="d-flex">
    <div class="d-flex flex-column flex-grow-1 me-2">
      <!--Junior signature-->
      <rr-signature [topic]="topic" [mode]="mode" [signature_role]="'JUNIOR_TECHNICIAN'" class="mb-1"></rr-signature>
      <!--Senior signature-->
      <rr-signature [topic]="topic" [mode]="mode" [signature_role]="'TECHNICIAN'" class="mb-1"></rr-signature>

      <div class="hstack mb-1 gap-1">
        <rr-report-status-badge
          class="flex-grow-1 flex-basis-0"
          *ngIf="report"
          [report]="report"
          [btnClass]="''"
        ></rr-report-status-badge>
        <button
          class="btn btn-outline-primary flex-grow-1 flex-basis-0"
          *ngIf="report && reportStatusReviewed && reportStatus && reportStatus.value === 'reported_by_technician'"
          (click)="proceedToReportStatusReviewed(report, reportStatusReviewed)"
        >
          <i class="fas fa-check-double"></i>
          Proceed to <strong>{{ reportStatusReviewed.name }}</strong>
        </button>
      </div>

      <!--Radiology Registrar signature-->
      <rr-signature [topic]="topic" [mode]="mode" [signature_role]="'RADIOLOGY_REGISTRAR'" class="mb-1"> </rr-signature>
      <!--Doctor signature-->
      <rr-signature [topic]="topic" [mode]="mode" [signature_role]="'DOCTOR'" class="w-100"></rr-signature>
      <button
        rr-check-report
        *ngIf="mode === 'editor' || mode === 'prefill' || mode === 'final'"
        class="btn btn-outline-dark ms-auto"
        role="button"
        [topic]="topic"
        rrTrack
        [trackAction]="'Check Report - Bottom'"
      ></button>
    </div>
  </div>
</div>
