import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { TagComponent } from 'app/modules/editor/prefill/prefill-tag/tag/tag.component';
import Fuse from 'fuse.js';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, TagComponent, NgbModule],
  selector: 'rr-voice-note-tags',
  templateUrl: './voice-note-tags.component.html',
  styleUrls: ['./voice-note-tags.component.css'],
})
export class VoiceNoteTagsComponent implements OnInit, OnDestroy {
  @ViewChildren(TagComponent) tagComponents: QueryList<TagComponent>;
  @Input() type: 'STRUCTURED' | 'GENERAL';
  @Input() @BindObservable() tags: RR.TagSuggestion[];
  tags$: Observable<RR.TagSuggestion[]>;
  @Input() topicId: number;
  @Input() topicTags: RR.Divider[];
  @Input() @BindObservable() filterTerm: string;
  filterTerm$: Observable<string>;

  @Output() onClearFilter = new EventEmitter();
  filteredTags: RR.TagSuggestion[] | undefined;

  tagsLoading: boolean;

  subscription: Subscription = new Subscription();

  constructor(private editorService: EditorService) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([this.tags$, this.filterTerm$]).subscribe(([tags, term]) => {
        this.tagsLoading = true;
        if (term) {
          // This looks inside the text of the tag, region, and subsection to look for a match
          const searchOptions: Fuse.IFuseOptions<RR.TagSuggestion> = {
            keys: ['tag_text', 'region_text', 'subsection_text'],
            // Ignore where in the string the text appears.
            // Mainly added this to find tags with "===" suffixes (a search hack Roger is using).
            ignoreLocation: true,
          };
          const fuse = new Fuse(tags, searchOptions);
          const results = fuse.search(term);
          this.filteredTags = results.map((r) => r.item);
        } else {
          this.filteredTags = tags;
        }
        this.tagsLoading = false;
      }),
    );

    this.subscription.add(
      this.editorService.selectTopChoice$.subscribe((type) => {
        if (type === this.type) {
          this.selectTopChoice();
        }
      }),
    );
  }

  selectAll() {
    this.tagComponents.forEach((component) => component.selectTagFromSearch());
  }

  selectTopChoice() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.tagComponents.first) {
      this.tagComponents.first.selectTagFromSearch();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clearFilterTerm() {
    this.onClearFilter.emit();
  }
}
