// Copied from - https://github.com/ngneat/hotkeys/blob/6efe8372149ea8728c39449c1a0eb862f30ea91b/projects/ngneat/hotkeys/src/lib/hotkeys.service.ts

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

type AllowInElement = 'INPUT' | 'TEXTAREA' | 'SELECT' | 'CONTENTEDITABLE';
type Platform = 'apple' | 'pc';
type HotkeyCallback = (event: KeyboardEvent, keys: string, target: HTMLElement | undefined) => void;

export type Hotkey = Partial<Options> & { keys: string };

interface Options {
  group: string | undefined;
  element: HTMLElement;
  trigger: 'keydown' | 'keyup';
  allowIn: AllowInElement[];
  description: string | undefined;
  showInHelpMenu: boolean;
  preventDefault: boolean;
}

export interface HotkeyGroup {
  group: string | undefined;
  hotkeys: { keys: string; description: string | undefined }[];
}

@Injectable()
export class HotkeysService {
  private readonly hotkeys = new Map<string, Hotkey>();
  private readonly dispose = new Subject<string>();
  private readonly defaults: Options = {
    trigger: 'keydown',
    allowIn: [],
    element: this.document.documentElement,
    group: undefined,
    description: undefined,
    showInHelpMenu: true,
    preventDefault: true,
  };
  private callbacks: HotkeyCallback[] = [];

  hostPlatform(): Platform {
    const appleDevices = ['Mac', 'iPhone', 'iPad', 'iPhone'];
    return appleDevices.some((d) => navigator.platform.includes(d)) ? 'apple' : 'pc';
  }

  normalizeKeys(keys: string, platform: Platform): string {
    const transformMap = {
      up: 'ArrowUp',
      down: 'ArrowDown',
      left: 'ArrowLeft',
      right: 'ArrowRight',
    };

    function transform(key: string): string {
      if (platform === 'pc' && key === 'meta') {
        key = 'control';
      }

      if (key in transformMap) {
        // @ts-expect-error noImplicitAny
        key = transformMap[key];
      }

      return key;
    }

    return keys.toLowerCase().split('.').map(transform).join('.');
  }

  constructor(
    private eventManager: EventManager,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  getHotkeys(): Hotkey[] {
    return Array.from(this.hotkeys.values()).map((h) => ({ ...h }));
  }

  getShortcuts(): HotkeyGroup[] {
    const hotkeys = Array.from(this.hotkeys.values());
    const groups: HotkeyGroup[] = [];

    for (const hotkey of hotkeys) {
      if (!hotkey.showInHelpMenu) {
        continue;
      }

      let group = groups.find((g) => g.group === hotkey.group);
      if (!group) {
        group = { group: hotkey.group, hotkeys: [] };
        groups.push(group);
      }

      const normalizedKeys = this.normalizeKeys(hotkey.keys, this.hostPlatform());
      group.hotkeys.push({ keys: normalizedKeys, description: hotkey.description });
    }

    return groups;
  }

  addShortcut(options: Hotkey): Observable<KeyboardEvent | undefined> {
    const mergedOptions = { ...this.defaults, ...options };
    const normalizedKeys = this.normalizeKeys(mergedOptions.keys, this.hostPlatform());
    if (this.hotkeys.has(normalizedKeys)) {
      console.error('Duplicated shortcut');
      return of(undefined);
    }

    this.hotkeys.set(normalizedKeys, mergedOptions);
    const event = `${mergedOptions.trigger}.${normalizedKeys}`;

    return new Observable((observer) => {
      const handler = (e: KeyboardEvent) => {
        const hotkey = this.hotkeys.get(normalizedKeys);
        const skipShortcutTrigger = this.targetIsExcluded(hotkey?.allowIn || []);

        if (skipShortcutTrigger) {
          return;
        }

        if (mergedOptions.preventDefault) {
          e.preventDefault();
        }

        this.callbacks.forEach((cb) => cb(e, normalizedKeys, hotkey?.element));
        observer.next(e);
      };
      const dispose = this.eventManager.addEventListener(mergedOptions.element, event, handler);

      return () => {
        this.hotkeys.delete(normalizedKeys);
        dispose();
      };
      // @ts-expect-error strictFunctionTypes
    }).pipe(takeUntil<KeyboardEvent>(this.dispose.pipe(filter((v) => v === normalizedKeys))));
  }

  coerceArray(params: any | any[]) {
    return Array.isArray(params) ? params : [params];
  }

  removeShortcuts(hotkeys: string | string[]): void {
    const coercedHotkeys = this.coerceArray(hotkeys).map((hotkey) => this.normalizeKeys(hotkey, this.hostPlatform()));
    coercedHotkeys.forEach((hotkey) => {
      this.hotkeys.delete(hotkey);
      this.dispose.next(hotkey);
    });
  }

  private targetIsExcluded(allowIn?: AllowInElement[]) {
    const activeElement = this.document.activeElement;
    const elementName = activeElement?.nodeName;
    const elementIsContentEditable = (activeElement as HTMLElement).isContentEditable;
    let isExcluded = elementName
      ? ['INPUT', 'SELECT', 'TEXTAREA'].includes(elementName) || elementIsContentEditable
      : false;

    if (isExcluded && allowIn?.length) {
      for (const t of allowIn) {
        if (activeElement?.nodeName === t || (t === 'CONTENTEDITABLE' && elementIsContentEditable)) {
          isExcluded = false;
          break;
        }
      }
    }

    return isExcluded;
  }
}
