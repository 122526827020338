import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { FeedbackActions } from '../feedback/feedback.action';

/**
 * Ngrx Actions for FeedbackComment
 *
 */
export const FeedbackCommentActions = {
  findAllSuccess: createAction(
    '[FeedbackComment] Find All Success',
    props<{ feedbackComments: RR.FeedbackComment[] }>(),
  ),

  findManySuccess: createAction(
    '[FeedbackComment] Find Many Success',
    props<{ feedbackComments: RR.FeedbackComment[] }>(),
  ),

  updateManySuccess: createAction(
    '[FeedbackComment] Update Many Success',
    props<{ feedbackComments: RR.FeedbackComment[] }>(),
  ),

  createSuccess: createAction('[FeedbackComment] Create Success', props<{ feedbackComment: RR.FeedbackComment }>()),

  updateSuccess: createAction('[FeedbackComment] Update Success', props<{ feedbackComment: RR.FeedbackComment }>()),
};

export const FeedbackCommentBatchActions = {
  createFeedbackCommentSuccess: createBatchAction(
    '[FeedbackComment] Create Success Batch',
    props<{
      actions: {
        updateFeedbackSuccess: ReturnType<typeof FeedbackActions.updateSuccess>;
        createFeedbackCommentSuccess: ReturnType<typeof FeedbackCommentActions.createSuccess>;
      };
    }>(),
  ),
};
