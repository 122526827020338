import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientNoteSearchFilterForm } from 'app/modules/entity-listing/components/patient-note-listing/patient-note-listing-search/patient-note-listing-search.component';
import { map, Observable } from 'rxjs';

import { PatientSearchResponse } from '../patient/patient.service';

export type CreatePatientNoteResponse = {
  patient: RR.Patient;
  patient_note: RR.PatientNote;
};

export type DeletePatientNoteResponse = {
  patient: RR.Patient;
};

@Injectable()
export class PatientNoteHttpService {
  constructor(private http: HttpClient) {}

  create(patientNote: Partial<RR.PatientNote>): Observable<CreatePatientNoteResponse> {
    return this.http.post<CreatePatientNoteResponse>('/api/patient_note', patientNote);
  }

  delete(patientNoteId: number): Observable<DeletePatientNoteResponse> {
    return this.http.delete<DeletePatientNoteResponse>(`/api/patient_note/${patientNoteId}`);
  }

  update(patientNoteId: number, changes: Partial<RR.PatientNote>): Observable<RR.PatientNote> {
    return this.http.put<RR.PatientNote>(`/api/patient_note/${patientNoteId}`, changes);
  }

  searchPatientNotes(queryBody: PatientNoteSearchFilterForm, params: HttpParams): Observable<PatientSearchResponse> {
    return this.http
      .post<PatientSearchResponse>('/api/patient_note/search', queryBody, {
        params,
      })
      .pipe(
        map((response: PatientSearchResponse) => {
          return {
            count: response.count,
            patients: response.patients,
            patient_notes: response.patient_notes,
            patient_note_categories: response.patient_note_categories,
          };
        }),
      );
  }
}
