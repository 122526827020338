import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Region>();

export const selectFeature = (state: AppState) => state.region;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

/**
 * Select a single region element given the Id
 *
 * This converts the regionId into the corresponding region.
 *
 * @params: regionId: The identifier of the region which is set within the database.
 */
const selectRegion = (regionId: number) =>
  createSelector(selectEntities, (regions: Dictionary<RR.Region>) => regions[regionId]);

export const fromRegion = {
  selectIds,
  selectEntities,
  selectAll,
  selectRegion,
};
