<div>
  <div>
    <strong
      >{{ referrer.title ? referrer.title : '' }}
      {{ referrer.physician_given_name + ' ' + referrer.physician_family_name }}</strong
    >
  </div>
  <div *ngIf="referrer.service">Service: {{ referrer.service }}</div>
  <div *ngIf="referrer.medicare_provider_number">Provider number: {{ referrer.medicare_provider_number }}</div>
  <div *ngIf="referrer.phone_work">Work Phone: {{ referrer.phone_work }}</div>
  <div *ngIf="referrer.phone_mobile">Mobile Phone: {{ referrer.phone_mobile }}</div>
  <div *ngIf="referrer.fax">Fax: {{ referrer.fax }}</div>
  <div *ngIf="referrer.email">Email: {{ referrer.email }}</div>
  <div *ngIf="referrer.address">Address: {{ referrer.address }}</div>
  <div *ngIf="referrer.city">City: {{ referrer.city }}</div>
  <div *ngIf="referrer.state">State: {{ referrer.state }}</div>
  <div *ngIf="referrer.zip">Postcode: {{ referrer.zip }}</div>
</div>
