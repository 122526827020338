import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReferrer } from 'app/store/referrer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-software-preferences-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './software-preferences-text.component.html',
  styleUrls: ['./software-preferences-text.component.css'],
})
export class SoftwarePreferencesTextComponent implements OnInit, OnDestroy {
  @Input() referrerId: number;

  softwarePrefs: RR.SoftwarePreference[];
  subscription: Subscription = new Subscription();
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromReferrer.selectSoftwarePreferences(this.referrerId)).subscribe((prefs) => {
        this.softwarePrefs = prefs;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
