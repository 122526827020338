import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const selectRouter = (s: AppState) => {
  return s.router;
};

// Note: getSelectors from @ngrx/router-store don't work because of CustomRouteSerializer
export const selectRouteParams = createSelector(selectRouter, (router) => {
  return router.state.params;
});

export const selectRouteParam = (param: string) =>
  createSelector(selectRouteParams, (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
    return params && params[param];
  });

export const selectTopicId = createSelector(selectRouteParam('topic_id'), (topic_id) =>
  topic_id != null ? Number(topic_id) : null,
);

export const selectReportId = createSelector(selectRouteParam('report_id'), (report_id) =>
  report_id != null ? Number(report_id) : null,
);

export const fromRouter = {
  selectRouteParams,
  selectRouteParam,
  selectTopicId,
  selectReportId,
};
