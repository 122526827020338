import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { TextObjectChoiceHttpService } from './text-object-choice.service';

@Injectable()
export class TextObjectChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: TextObjectChoiceHttpService,
  ) {}

  update(textObjectChoiceId: number, changes: Partial<RR.TextObjectChoice>) {
    return this.service.update(textObjectChoiceId, changes).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
