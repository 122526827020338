import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferrerSearchFilterForm } from 'app/modules/entity-listing/components/referrer-listing/referrer-listing-search/referrer-listing-search.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type ESReferrerSearchResult = {
  referrers: RR.Referrer[];
};

export type ReferrerResponse = {
  referrer: RR.Referrer;
  referrer_contact_methods: RR.ReferrerContactMethod[];
};

export type ReferrerGetResponse = {
  referrer: RR.Referrer;
  referrer_contact_methods: RR.ReferrerContactMethod[];
  referrer_notes: RR.ReferrerNote[];
  referrer_note_categories: RR.NoteCategory[];
};

export type ReferrerSearchResponse = {
  count: number;
  referrers: RR.Referrer[];
  referrer_contact_methods: RR.ReferrerContactMethod[];
  referrer_notes: RR.ReferrerNote[];
  referrer_note_categories: RR.NoteCategory[];
};

export type ReferrerManyResponse = {
  referrers: RR.Referrer[];
  referrer_contact_methods: RR.ReferrerContactMethod[];
  referrer_notes: RR.ReferrerNote[];
  referrer_note_categories: RR.NoteCategory[];
};

export interface ESReferrerBody extends Partial<RR.Referrer> {
  query?: string;
}

@Injectable()
export class ReferrerHttpService {
  constructor(private http: HttpClient) {}

  searchReferrers(
    queryBody: ReferrerSearchFilterForm,
    withDeleted: boolean,
    onlyDeleted: boolean,
    params: HttpParams,
  ): Observable<ReferrerSearchResponse> {
    params = params.set('with_deleted', String(withDeleted)).set('only_deleted', String(onlyDeleted));
    return this.http
      .post<ReferrerSearchResponse>('/api/referrer/search', queryBody, {
        params,
      })
      .pipe(
        map((response: ReferrerSearchResponse) => {
          return {
            count: response.count,
            referrers: response.referrers,
            referrer_contact_methods: response.referrer_contact_methods,
            referrer_notes: response.referrer_notes,
            referrer_note_categories: response.referrer_note_categories,
          };
        }),
      );
  }

  findById(referrerId: number): Observable<ReferrerGetResponse> {
    return this.http.get<ReferrerGetResponse>(`/api/referrer/${referrerId}`);
  }

  create(referrer: Partial<RR.Referrer>): Observable<ReferrerResponse> {
    return this.http.post<ReferrerResponse>('/api/referrer', referrer);
  }

  update(referrerId: number, changes: Partial<RR.Referrer>): Observable<ReferrerResponse> {
    return this.http.put<ReferrerResponse>(`/api/referrer/${referrerId}`, changes);
  }

  delete(referrerId: number) {
    return this.http.delete(`/api/referrer/${referrerId}`);
  }

  elasticSearch(body: ESReferrerBody) {
    return this.http.post<ESReferrerSearchResult>('/api/referrer/elasticsearch', body);
  }

  restoreDeletedReferrer(referrerId: number) {
    return this.http.post<RR.Referrer>(`/api/restore_referrer/${referrerId}`, {});
  }
}
