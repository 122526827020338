import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PercentileBand {
  id: number;
  m: number;
  b: number;
  percentile: number;
  percentile_band_group_id: number;
}

export interface PercentileBandBatchCreate {
  text: string;
}

@Injectable()
export class PercentileBandHttpService {
  constructor(private http: HttpClient) {}

  createPercentileBand(
    percentileBandGroupId: number,
    percentileBand: Partial<PercentileBand>,
  ): Observable<PercentileBand> {
    return this.http.post<PercentileBand>(
      `/api/percentile_band_group/${percentileBandGroupId}/percentile_band`,
      percentileBand,
    );
  }

  updatePercentileBand(percentileBandId: number, changes: Partial<PercentileBand>): Observable<PercentileBand> {
    return this.http.put<PercentileBand>(`/api/percentile_band/${percentileBandId}`, changes);
  }

  deletePercentileBand(percentileBandId: number): Observable<void> {
    return this.http.delete<void>(`/api/percentile_band/${percentileBandId}`);
  }

  createPercentileBandBatch(
    percentileBandGroupId: number,
    data: PercentileBandBatchCreate,
  ): Observable<PercentileBand[]> {
    return this.http.post<PercentileBand[]>(
      `/api/percentile_band_group/${percentileBandGroupId}/percentile_band_batch_create`,
      data,
    );
  }
}
