import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const selectFeature = (state: AppState) => state.setting;

const selectKiosk = createSelector(selectFeature, ({ kiosk }) => kiosk);
const selectUserClockedOn = createSelector(selectFeature, ({ userClockedOn }) => userClockedOn);

export const fromSetting = {
  selectKiosk,
  selectUserClockedOn,
};
