import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { Observable } from 'rxjs';

import { ReportHeadlineComponent } from '../report-headline/report-headline.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReportHeadlineComponent],
  selector: 'rr-booking-headline',
  templateUrl: './booking-headline.component.html',
  styleUrls: ['./booking-headline.component.css'],
})
export class BookingHeadlineComponent implements OnInit {
  @Input() bookingId: number;

  booking$: Observable<RR.Booking>;
  reports$: Observable<RR.Report[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.booking$ = this.store.select(fromBooking.selectBooking(this.bookingId)).pipe(filterDefined());
    this.reports$ = this.store.select(fromBooking.selectReports(this.bookingId));
  }
}
