<div class="modal-header">
  <h5 class="modal-title" *ngIf="subsection">Edit Landmark for Subsection: {{ subsection.name || 'NO NAME' }}</h5>
  <h5 class="modal-title" *ngIf="region">Edit Landmark for Region: {{ region.name || 'NO NAME' }}</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form class="d-flex align-items-center justify-content-center">
    <label> Landmark </label>
    <input
      ngbAutoFocus
      name="landmark"
      [(ngModel)]="landmarkName"
      class="form-control w-50 ms-2"
      type="text"
      placeholder="Enter the landmark label"
    />
    <button class="ms-2 btn btn-success" type="submit" (click)="saveLandmark()">Save</button>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-light ms-auto" role="button" (click)="activeModal.dismiss()">Close</button>
</div>
