import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategorisedAttribute } from 'app/core/services/template.service';
import { Observable } from 'rxjs';

@Injectable()
export class CategoryStatementComboHttpService {
  constructor(private http: HttpClient) {}

  findAll(params: HttpParams): Observable<RR.CategoryStatementCombo[]> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (params) {
      return this.http.get<RR.CategoryStatementCombo[]>('/api/category_statement_combo', { params });
    }
    return this.http.get<RR.CategoryStatementCombo[]>('/api/category_statement_combo');
  }

  addAttributeCombo(
    categoryStatementCombo: Partial<RR.CategoryStatementCombo & { attributes: ICategorisedAttribute[] }>,
  ): Observable<RR.CategoryStatementCombo> {
    return this.http.post<RR.CategoryStatementCombo>('/api/category_attribute_combo', categoryStatementCombo);
  }

  deleteAttributeCombo(
    categoryStatementCombo: Partial<RR.CategoryStatementCombo & { attributes: ICategorisedAttribute[] }>,
  ) {
    return this.http.post('/api/remove_category_attribute_combo', categoryStatementCombo);
  }
}
