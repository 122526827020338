import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.state';
import { ChoiceErrorActions } from './choice-error.action';

@Injectable()
export class ChoiceErrorEffect {
  constructor(private store: Store<AppState>) {}

  setChoiceErrors(errors: RR.ProofreadChoiceError[]) {
    this.store.dispatch(ChoiceErrorActions.setChoiceError({ errors }));
  }
}
