import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { SubsectionActions } from './subsection.action';

export interface SubsectionState extends EntityState<RR.Subsection> {}

const adapter = createEntityAdapter<RR.Subsection>();
const initialState = adapter.getInitialState();

export const subsectionReducer = createReducer(
  initialState,
  on(SubsectionActions.addMany, (state, { subsections }) => adapter.addMany(subsections, state)),
  on(SubsectionActions.addOne, (state, { subsection }) => adapter.addOne(subsection, state)),
  on(SubsectionActions.upsertOne, (state, { subsection }) => adapter.upsertOne(subsection, state)),
  on(SubsectionActions.upsertMany, (state, { subsections }) => adapter.upsertMany(subsections, state)),
  on(SubsectionActions.removeOne, (state, { subsectionId }) => adapter.removeOne(subsectionId, state)),
  on(
    SubsectionActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
