<div class="col">
  <div class="text-break" [innerHTML]="notification.text"></div>
  <div class="small">
    {{ notification.when | date: 'dd/MM/yyyy' }}
  </div>
</div>
<div class="d-flex gap-1">
  <div class="badge bg-primary align-self-center" *ngIf="notification.read">Read</div>
  <button class="btn btn-success" *ngIf="notification.type === 'pathology'" (click)="openReport(notification)">
    View Report
  </button>
  <button class="btn btn-primary" *ngIf="!notification.read" (click)="read(notification.id)">Mark as Read</button>
</div>
