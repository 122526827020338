<div class="modal-header">
  <h5 class="modal-title">Delete {{ headingName }}</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <ng-container *ngIf="headingName !== 'Regions'">
    Are you sure you want to delete <b>{{ headingName }}</b
    >?
  </ng-container>

  <ng-container *ngIf="headingName === 'Regions'">
    Are you sure you want to delete other <b>{{ headingName }}</b> and only save this region?
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-danger" (click)="activeModal.close()" ngbAutoFocus>Delete</button>
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
