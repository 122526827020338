<ng-template #todoAuthors>
  <ul>
    <li *ngFor="let audit_event of audit_events$ | async">
      <div>
        {{ audit_event.type }} at {{ audit_event.when | date: 'MMM d, y, H:mm' }} by
        <span *ngIf="audit_event.user_id | storeSelectPipe: selectUserSelectorFn | async as user">{{ user.name }}</span>
      </div>
    </li>
  </ul>
</ng-template>

<div
  [ngbPopover]="todoAuthors"
  [disablePopover]="todo.audit_events.length === 0"
  triggers="mouseenter:mouseleave"
  popoverTitle="History"
  class="d-flex align-items-center"
>
  <div class="form-check">
    <input
      id="todo-resolved-{{ todo.id }}"
      value="any"
      type="checkbox"
      [checked]="todo.resolved"
      class="me-2 form-check-input"
      (change)="resolveTodo(todo.id, !todo.resolved)"
    />
    <label for="todo-resolved-{{ todo.id }}" class="form-check-label">
      <span style="white-space: pre-line" *ngIf="!todo.resolved" [ngClass]="{ 'font-italic': !todo.todo }">
        <rr-note-text [note]="todo.todo || 'Noted'"></rr-note-text>
      </span>
      <span style="white-space: pre-line">
        <s *ngIf="todo.resolved" [ngClass]="{ 'font-italic': !todo.todo }">
          <rr-note-text [note]="todo.todo || 'Noted'"></rr-note-text>
        </s>
      </span>
    </label>
  </div>
  <button
    role="button"
    class="btn ms-auto"
    (click)="deleteTodo(todo.id)"
    [ngClass]="{ 'pb-0': todo.statement_choice_id }"
  >
    <span class="fa fa-trash-alt text-danger"></span>
  </button>
</div>
<div *ngIf="todo.statement_choice_id && (getStatementChoiceText(todo.statement_choice_id) | async)">
  <span role="button" class="clickable" (click)="jumpToSentence(todo.statement_choice_id)"
    ><i class="fa fa-notes-medical me-1 text-danger"></i>
    {{ getStatementChoiceText(todo.statement_choice_id) | async }}</span
  >
</div>
