<ng-container>
  <div class="col-3 pe-2">
    <input
      class="form-control"
      type="date"
      #serviceDateInput
      [value]="invoiceItem.service_date"
      (blur)="editItemServiceDate(serviceDateInput.value)"
      (keydown.enter)="serviceDateInput.blur()"
      [disabled]="!draft"
    />
  </div>
  <div class="col-1 p-0">
    <td>{{ invoiceItem.item_code }}</td>
  </div>
  <div class="col-2">
    <input
      class="form-control"
      type="number"
      #amountExpectedInput
      [value]="invoiceItem.amount_expected"
      (blur)="editItemAmount(amountExpectedInput.value, 'amount_expected')"
      (keydown.enter)="amountExpectedInput.blur()"
    />
  </div>
  <div *ngIf="!draft" class="col-1">${{ invoiceItem.amount_paid || 0 }}</div>

  <div *ngIf="draft" class="col-2">
    <button type="button" class="btn btn-sm btn-danger" (click)="removeInvoiceItem()">Remove</button>
  </div>
</ng-container>
