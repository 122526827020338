import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allFormLink',
  standalone: true,
})
export class AllFormLinkPipe implements PipeTransform {
  transform(reportId: number | undefined) {
    if (!reportId) {
      return null;
    }

    return new URL(`/api/form/all/${reportId}`, location.origin);
  }
}
