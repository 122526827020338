import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ReferrerContactMethodActions } from './referrer-contact-method.action';

export type ReferrerContactMethodState = EntityState<RR.ReferrerContactMethod>;

const adapter = createEntityAdapter<RR.ReferrerContactMethod>();
const initialState = adapter.getInitialState();

export const referrerContactMethodReducer = createReducer(
  initialState,
  on(ReferrerContactMethodActions.findManySuccess, (state: ReferrerContactMethodState, { referrer_contact_methods }) =>
    adapter.upsertMany(referrer_contact_methods, state),
  ),
  on(ReferrerContactMethodActions.createSuccess, (state: ReferrerContactMethodState, { referrer_contact_method }) =>
    adapter.addOne(referrer_contact_method, state),
  ),
  on(ReferrerContactMethodActions.deleteSuccess, (state: ReferrerContactMethodState, { referrer_contact_method_id }) =>
    adapter.removeOne(referrer_contact_method_id, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
