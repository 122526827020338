import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';

@Injectable()
export class TextObjectChoiceHttpService {
  constructor(private http: HttpClient) {}

  update(textObjectChoiceId: number, changes: Partial<RR.TextObjectChoice>) {
    return this.http.put<ChangeTrackerResponse>(`/api/text_object_choice/${textObjectChoiceId}`, changes);
  }
}
