<ng-container
  *ngIf="{
  defaultAttribute: defaultAttribute$ | async,
  attributeSet: attributeSet$ | async
} as obj"
>
  <span
    *ngIf="obj.defaultAttribute; else noAttributeTemplate"
    class="rr-statement-attribute-placeholder"
    title="Attribute Set: {{ obj.attributeSet?.name }}"
    >{{obj.defaultAttribute.text}}</span
  >
</ng-container>

<!-- Where there is no default attribute, we instead place a non-breaking
space. This is also the placeholder prior to the default attribute being set,
that is when the value hasn't yet been retrieved from the database -->
<ng-template #noAttributeTemplate>
  <span class="rr-statement-attribute-placeholder">&nbsp;</span>
</ng-template>
