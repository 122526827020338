import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rr-landmark-label-headline',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './landmark-label-headline.component.html',
  styleUrls: ['./landmark-label-headline.component.css'],
})
export class LandmarkLabelHeadlineComponent implements OnInit {
  @Input() landmarkLabel: RR.LandmarkLabel;
  constructor() {}

  ngOnInit(): void {}
}
