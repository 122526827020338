<div class="small text-secondary px-2">
  Searching in
  <span *ngIf="lastClickedBreadcrumb.type === 'section'">
    Section:
    <ng-container *ngIf="lastClickedBreadcrumb.section_id | storeSelectPipe: selectSection | async as section">
      {{ section.title }}
    </ng-container>
  </span>
  <span *ngIf="lastClickedBreadcrumb.type === 'subsection'">
    Subsection:
    <ng-container
      *ngIf="lastClickedBreadcrumb.subsection_id | storeSelectPipe: selectSubsection | async as subsection"
      >{{ subsection.name }}</ng-container
    >
  </span>
  <span *ngIf="lastClickedBreadcrumb.type === 'element'">
    Element:
    <ng-container
      *ngIf="lastClickedBreadcrumb.element_id | storeSelectPipe: selectStatementSet | async as statementSet"
      >{{ statementSet.name }}</ng-container
    >
  </span>
</div>
