import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { VoiceNoteActions } from './voice-note.action';

export type VoiceNoteState = EntityState<RR.VoiceNote>;

const adapter = createEntityAdapter<RR.VoiceNote>();

const initialState = adapter.getInitialState();

export const voiceNoteReducer = createReducer(
  initialState,
  on(VoiceNoteActions.findManySuccess, (state: VoiceNoteState, { voice_notes }) =>
    adapter.upsertMany(voice_notes, state),
  ),
  on(VoiceNoteActions.createSuccess, (state: VoiceNoteState, { voiceNote }) => adapter.addOne(voiceNote, state)),
  on(VoiceNoteActions.updateSuccess, (state: VoiceNoteState, { voiceNote }) => adapter.upsertOne(voiceNote, state)),
  on(VoiceNoteActions.deleteSuccess, (state: VoiceNoteState, { voiceNoteId }) => adapter.removeOne(voiceNoteId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
