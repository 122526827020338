<label class="form-label">Report Status</label>
<div
  ngbDropdown
  cdkListbox
  cdkListboxMultiple
  rrTooltip="FILTER_BY_REPORT_STATUS"
  [cdkListboxValue]="value"
  (cdkListboxValueChange)="_onChange($event)"
  [autoClose]="'outside'"
>
  <button ngbDropdownToggle class="btn btn-outline-primary">{{ buttonText }}</button>
  <div ngbDropdownMenu>
    <button
      ngbDropdownItem
      class="dropdown-item"
      *ngFor="let reportStatus of reportStatuses; trackById"
      [cdkOption]="reportStatus"
      [class.active]="valueMap[reportStatus.id]"
    >
      {{ reportStatus.name }}
    </button>
  </div>
</div>
