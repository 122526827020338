import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { TitleCaseInputComponent } from 'app/shared/components/title-case-input/title-case-input.component';
import { SharedModule } from 'app/shared/shared.module';
import { atLeastOneRequired } from 'app/shared/utils/form-validation.utils';
import { getEmailValidatorRegex, referrerTypes } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { ESReferrerSearchResult, fromReferrer, ReferrerEffect, ReferrerHttpService } from 'app/store/referrer';
import { ReferrerContactMethodEffect } from 'app/store/referrer-contact-method';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';

import { PhoneFaxInputComponent } from '../../patient-form/phone-fax-input/phone-fax-input.component';
import { ReferrerPopoverComponent } from '../referrer-popover/referrer-popover.component';
import { SoftwarePreferencesComponent } from '../software-preferences/software-preferences.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    PhoneFaxInputComponent,
    ReferrerPopoverComponent,
    ReferrerNameComponent,
    TitleCaseInputComponent,
    SharedModule,
    SoftwarePreferencesComponent,
  ],
  selector: 'rr-referrer-form-edit',
  templateUrl: './referrer-form-edit.component.html',
  styleUrls: ['./referrer-form-edit.component.css'],
})
export class ReferrerFormEditComponent implements OnInit, OnDestroy {
  @ViewChild('suggestionPopover') suggestionPopover: NgbPopover;
  @Input() viewMode: 'search' | 'view' | 'edit' | 'create' = 'view';
  @Input() @BindObservable() referrer: RR.Referrer | undefined;
  referrer$: Observable<RR.Referrer | undefined>;
  @Input() parent: 'REPORT' | 'REFERRER' | 'BOOKING';

  @Output() onChange: EventEmitter<{ referrer: RR.Referrer; created: boolean }> = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onStatusChange = new EventEmitter();

  referrerContactMethods: RR.ReferrerContactMethod[] = [];
  similarReferrers: RR.Referrer[] = [];
  subscription: Subscription = new Subscription();

  referrerTypes = referrerTypes;

  form = new FormGroup(
    {
      physician_given_name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      physician_family_name: new FormControl('', { validators: Validators.required, nonNullable: true }),
      service: new FormControl('', { nonNullable: true }),
      title: new FormControl('', { nonNullable: true }),
      medicare_provider_number: new FormControl<string | undefined>('', {
        validators: [Validators.required, Validators.pattern('[a-zA-Z0-9]{8}')],
        nonNullable: true,
      }),
      referrer_type: new FormControl<RR.ReferrerType | undefined>(undefined, {
        validators: Validators.required,
        nonNullable: true,
      }),

      phone_work: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      phone_mobile: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      fax: new FormControl('', { validators: Validators.pattern('[0-9]{10}'), nonNullable: true }),
      email: new FormControl('', { validators: Validators.pattern(getEmailValidatorRegex()), nonNullable: true }),
      preferred_contacts: new FormControl<string[]>([], { nonNullable: true }),

      address: new FormControl('', { nonNullable: true }),
      city: new FormControl('', { nonNullable: true }),
      state: new FormControl('', { nonNullable: true }),
      zip: new FormControl('', { validators: Validators.pattern('[0-9]{4}'), nonNullable: true }),

      collater_notes: new FormControl('', { nonNullable: true }),
    },
    Validators.compose([
      atLeastOneRequired(Validators.required, ['phone_work', 'phone_mobile', 'fax', 'email'], 'phoneOrEmailRequired'),
    ]),
  );

  constructor(
    private referrerService: ReferrerHttpService,
    private referrerEffect: ReferrerEffect,
    private referrerContactMethodEffect: ReferrerContactMethodEffect,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      // Distinct until change to prevent patching form when referrer is updated
      this.referrer$.pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id)).subscribe((referrer) => {
        if (referrer) {
          this.patchReferrerForm(referrer);
          this.formValidation();
        }
      }),
    );
    this.subscription.add(
      this.referrer$
        .pipe(
          filterDefined(),
          switchMap((r) => this.store.select(fromReferrer.selectPreferredContacts(r.id))),
        )
        .subscribe((contacts) => {
          this.referrerContactMethods = contacts;
          this.patchContactMethodForForm(this.referrerContactMethods);
        }),
    );

    // Letters should be uppercase in provider numbers, this forces them to uppercase.
    this.subscription.add(
      this.form.controls.medicare_provider_number.valueChanges.subscribe(() => {
        const uppercaseValue = this.form.controls.medicare_provider_number.value?.toUpperCase();
        if (uppercaseValue && this.form.controls.medicare_provider_number.value !== uppercaseValue) {
          this.form.controls.medicare_provider_number.patchValue(uppercaseValue, { emitEvent: false });
        }
      }),
    );

    // Search for existing referrer on form value changes
    this.subscription.add(
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          filter(() => {
            // Disable searching existing referrer on updating referrer details
            return !(this.referrer && this.parent === 'REFERRER');
          }),
          switchMap(() => {
            const r = this.getReferrerDataFromFormInput();
            return this.referrerService.elasticSearch(r).pipe(this.messageService.handleHttpErrorPipe);
          }),
        )
        .subscribe((result: ESReferrerSearchResult) => {
          this.similarReferrers = result.referrers;
        }),
    );

    this.subscription.add(
      // Use delay (rxjs setTimeout) to prevent NG0100 error.
      // TODO: If possible recompose to avoid using this workaround.
      this.form.statusChanges.pipe(delay(0)).subscribe((status) => {
        this.onStatusChange.emit(status);
      }),
    );
  }

  patchReferrerForm(referrer: Partial<RR.Referrer>) {
    this.form.patchValue({
      physician_given_name: referrer.physician_given_name || '',
      physician_family_name: referrer.physician_family_name || '',
      service: referrer.service || '',
      title: referrer.title || '',
      medicare_provider_number: referrer.medicare_provider_number || '',
      referrer_type: referrer.referrer_type || undefined,

      phone_work: referrer.phone_work || '',
      phone_mobile: referrer.phone_mobile || '',
      fax: referrer.fax || '',
      email: referrer.email || '',

      address: referrer.address || '',
      city: referrer.city || '',
      state: referrer.state || '',
      zip: referrer.zip || '',
      collater_notes: referrer.collater_notes || '',
    });
  }

  patchContactMethodForForm(contactMethods: RR.ReferrerContactMethod[]) {
    this.form.controls.preferred_contacts.patchValue(contactMethods.map((c) => c.type));
  }

  formValidation() {
    this.form.markAllAsTouched();
  }

  getReferrerDataFromFormInput(): Partial<RR.Referrer> {
    return {
      physician_given_name: this.form.controls.physician_given_name.value,
      physician_family_name: this.form.controls.physician_family_name.value,
      service: this.form.controls.service.value,
      title: this.form.controls.title.value,
      medicare_provider_number: this.form.controls.medicare_provider_number.value,
      referrer_type: this.form.controls.referrer_type.value || null,
      phone_work: this.form.controls.phone_work.value,
      phone_mobile: this.form.controls.phone_mobile.value,
      email: this.form.controls.email.value,
      fax: this.form.controls.fax.value,
      // preferred_contact: this.form.controls.preferred_contact.value,
      address: this.form.controls.address.value,
      city: this.form.controls.city.value,
      state: this.form.controls.state.value,
      zip: this.form.controls.zip.value,
      collater_notes: this.form.controls.collater_notes.value,
    };
  }

  emitReferrer(referrer: RR.Referrer, created = false) {
    this.viewMode = 'view';
    this.form.markAsPristine();
    this.onChange.emit({ referrer, created });
  }

  submitReferrer() {
    this.formValidation();
    if (!this.form.valid) {
      this.messageService.add({
        title: 'Error',
        message: 'Some fields are still invalid',
        type: 'danger',
      });
      return;
    }

    if (this.referrer) {
      // Merge data from referrer input and form input then submitting
      const ref = { ...this.referrer, ...this.getReferrerDataFromFormInput() };
      // Update referrer
      this.subscription.add(
        this.referrerEffect
          .update(ref.id, ref)
          .pipe(take(1))
          .subscribe({
            next: (action) => {
              this.emitReferrer(action.actions.updateReferrerSuccess.referrer);
            },
            error: (error: unknown) => {
              if (error instanceof HttpErrorResponse) {
                this.messageService.httpErrorMessage(error, { title: 'Update referrer failed' });
                this.setValidationErrors(error);
              }
            },
          }),
      );
    } else {
      // Create new referrer
      const ref = {
        ...this.getReferrerDataFromFormInput(),
        contact_methods: this.form.controls.preferred_contacts.value,
      };

      this.subscription.add(
        this.referrerEffect
          .create(ref)
          .pipe(take(1))
          .subscribe({
            next: (action) => {
              this.emitReferrer(action.actions.createReferrerSuccess.referrer, true);
            },
            error: (error: unknown) => {
              if (error instanceof HttpErrorResponse) {
                this.messageService.httpErrorMessage(error, { title: 'Create referrer failed' });
                this.setValidationErrors(error);
              }
            },
          }),
      );
    }
  }

  setValidationErrors(error: HttpErrorResponse) {
    const errorBody: { json?: { [fieldName: string]: string[] } } = error.error;
    const json = errorBody.json;
    if (json) {
      Object.keys(json).forEach((prop) => {
        const formControl = this.form.get(prop);
        if (formControl) {
          formControl.setErrors({
            serverError: json[prop],
          });
        }
      });
    }
  }

  cancel() {
    if (this.referrer) {
      this.patchReferrerForm(this.referrer);
      this.patchContactMethodForForm(this.referrerContactMethods);
    }
    this.onCancel.emit();
    this.form.markAsPristine();
  }

  onPreferredContactCheck(contact_method: 'SMS' | 'EMAIL' | 'FAX' | 'MEDICAL_OBJECTS', event: Event) {
    if (event.target && event.target instanceof HTMLInputElement) {
      if (!this.referrer) {
        // Create new referrer mode
        if (event.target.checked) {
          this.form.controls.preferred_contacts.setValue([
            ...this.form.controls.preferred_contacts.value,
            contact_method,
          ]);
        } else {
          this.form.controls.preferred_contacts.setValue(
            this.form.controls.preferred_contacts.value.filter((c) => c !== contact_method),
          );
        }
      } else {
        // Edit referrer mode, call api directly to add/edit contact method
        const preferredContactMethod = this.referrerContactMethods.find((m) => m.type === contact_method);

        if (event.target.checked) {
          if (preferredContactMethod) return;
          this.subscription.add(
            this.referrerContactMethodEffect
              .create({ type: contact_method, referrer_id: this.referrer.id })
              .subscribe(() => {
                this.messageService.add({
                  title: 'Success',
                  message: 'Add referrer preferred contact successfully',
                  type: 'success',
                });
              }),
          );
        } else {
          if (!preferredContactMethod) return;
          this.subscription.add(
            this.referrerContactMethodEffect.delete(preferredContactMethod.id).subscribe(() => {
              this.messageService.add({
                title: 'Success',
                message: 'Remove referrer preferred contact successfully',
                type: 'success',
              });
            }),
          );
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
