import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { PREVIEW_SCROLL_ID } from 'app/app.constants';
import { BindObservable, filterDefined, scrollIntoView } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { getReportSections, ICategorisedAttribute, TemplateService } from 'app/core/services/template.service';
import { CategoriseSentenceModalComponent } from 'app/modules/editor/category/categorise-sentence-modal/categorise-sentence-modal.component';
import { DividerStatementsModalComponent } from 'app/modules/editor/divider/divider-statements-modal/divider-statements-modal.component';
import { NotepadModalComponent } from 'app/modules/editor/notepad/notepad-modal.component';
import { ChoicePreviewService } from 'app/modules/report/components/preview/choice-preview/choice-preview.service';
import { AppState } from 'app/store';
import { EditorState } from 'app/store/editor';
import { PatientNoteEffect } from 'app/store/patient-note';
import { ReportHttpService, fromCurrentReport } from 'app/store/report/report';
import { fromTodo, TodoEffect } from 'app/store/report/todo';
import { fromElement } from 'app/store/template/element';
import { fromSection } from 'app/store/template/section';
import { fromStatement } from 'app/store/template/statement';
import { fromEvent, Observable, of, Subscription, zip } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { LinkifyDirective } from '../../../../../shared/directives/linkify/linkify.directive';
import { TooltipDirective } from '../../../../../shared/directives/tooltip.directive';
import { DateTypeComponent } from '../../../../editor/date-type/date-type.component';
import { ChoiceConcatenation, CompositeChoice } from '../choice-concatenation.service';
import { FlagNoteModalComponent } from './flag-note-modal/flag-note-modal.component';
import { TodoTooltipComponent } from './todo-tooltip/todo-tooltip.component';

const REPORT_SECTIONS = new Set(getReportSections().map((s) => s.name));

@Component({
  selector: 'rr-choice-preview',
  templateUrl: 'choice-preview.component.html',
  styleUrls: ['choice-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TooltipDirective,
    LetDirective,
    NgbPopover,
    TodoTooltipComponent,
    LinkifyDirective,
    NgbTooltip,
    DateTypeComponent,
  ],
})
@LifecycleLogger
export class ChoicePreviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @BindObservable() @Input() choice: RR.StatementChoice | CompositeChoice;
  choice$: Observable<RR.StatementChoice | CompositeChoice>;
  @Input() region_choice: RR.RegionChoice;
  @Input() subsection_choice: RR.SubsectionChoice;
  @Input() section_choice: RR.SectionChoice;
  @Input() element_choice: RR.ElementChoice;
  @Input() subsection: RR.Subsection;
  @Input() first: boolean;
  @Input() last: boolean;
  @Input() isClone = false; // is being displayed outside of its Statement's section
  @Input() mode: PreviewMode = 'editor';
  @Input() template_id: number;
  @HostBinding('class.text-danger') error = false;
  @ViewChild('p', { static: false }) popover: NgbPopover | undefined;
  @ViewChild('todoTooltip', { static: false }) todoTooltip: NgbTooltip | undefined;

  pendingClose: number;
  choice_id: number; // necessary template-side because Angular hijacks 'in' syntax
  pendingOpen: number;
  reason: string;
  highlight: Observable<boolean>;
  underlinePrefilled$: Observable<EditorState['underlinePrefilled']>;
  subscription: Subscription = new Subscription();
  displayText: string;

  text_object_choices: RR.TextObjectChoice[];
  statement_attributes: RR.TextObjectChoice[] = [];
  templateLoaded = false;
  element: RR.Element | undefined;
  reportId: number;
  // Unresolved todos indicate that a choice is flagged or not
  unresolvedTodos: RR.Todo[] = [];
  isSentenceCategorised: Observable<boolean>;
  sentenceCategoriesTooltip: Observable<string>;
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();
  shouldHide = false;

  // If the choice has been cloned to keyfindings, comment, or I&R, it cannot be cloned to the other section.
  // The choice need to be uncloned first before cloning to other section to avoid report overcomplication
  hasClone = false;
  cloneMap: { [section: string]: boolean } = {};
  report: RR.Report | undefined;
  todosForFlag: RR.Todo[];

  section$: Observable<RR.Section>;
  section: RR.Section;

  constructor(
    private choiceConcatenation: ChoiceConcatenation,
    private reportService: ReportService,
    private choicePreviewService: ChoicePreviewService,
    private zone: NgZone,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private templateService: TemplateService,
    private modal: NgbModal,
    private store: Store<AppState>,
    private todoEffect: TodoEffect,
    private reportHttpService: ReportHttpService,
    private messageService: MessageService,
    private patientNoteEffect: PatientNoteEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.choice$.subscribe(() => {
        if ('component_choices' in this.choice) {
          this.displayText = this.choiceConcatenation.concatenatedDisplayText(this.choice);
        }
      }),
    );

    this.subscription.add(
      this.store.select(fromCurrentReport.selectReport).subscribe((report) => {
        this.report = report;
      }),
    );

    this.subscription.add(
      this.choice$.subscribe(() => {
        this.shouldHide = Boolean(
          !this.isClone &&
            this.choice.clones &&
            this.choice.clones.some((section) => REPORT_SECTIONS.has(section as RR.TemplateSection)),
        );

        this.hasClone = !!this.choice.clones && this.choice.clones.length > 0;
        this.cloneMap = {};
        if (this.choice.clones) {
          this.cloneMap = this.choice.clones.reduce((map: { [section: string]: boolean }, curr: RR.ReportSection) => {
            map[curr] = true;
            return map;
          }, {});
        }
      }),
    );
    this.subscription.add(
      this.choicePreviewService.openPopoverEvents().subscribe((choiceComponent) => {
        if (this !== choiceComponent) {
          clearTimeout(this.pendingClose);
          this.closeNow();
        }
      }),
    );
    this.zone.runOutsideAngular(() => {
      this.subscription.add(
        fromEvent(document, 'click').subscribe(() => {
          this.closeNow();
        }),
      );
    });
    this.highlight = this.editorService.getHighlight(
      this.element_choice.element_id,
      this.section_choice.topic_id,
      this.statement_id,
      this.region_id,
    );
    this.underlinePrefilled$ = this.editorService.getUnderlinePrefilled();
    if ('id' in this.choice) {
      this.subscription.add(
        this.reportService.getChoiceText(this.choice.id).subscribe((displayText) => {
          this.displayText = displayText;
          this.cd.markForCheck();
        }),
      );
      this.subscription.add(
        this.reportService.getChoiceError(this.choice.id).subscribe((choice_error) => {
          this.error = !!choice_error;
          this.reason = choice_error;
          this.cd.markForCheck();
        }),
      );

      this.subscription.add(
        this.reportService.getTextObjectChoices(this.choice.id).subscribe((text_object_choices) => {
          // @ts-expect-error strictNullChecks
          this.text_object_choices = text_object_choices;
          // @ts-expect-error strictNullChecks
          this.statement_attributes = text_object_choices.filter((o) => o.type === 'set');
        }),
      );
    }

    if (this.template_id) {
      this.subscription.add(
        this.templateService.isTemplateLoaded(this.template_id).subscribe((val) => {
          this.templateLoaded = val;
        }),
      );
    } else {
      this.templateLoaded = true;
    }

    // @ts-expect-error strictNullChecks
    this.choice_id = 'id' in this.choice ? this.choice.id : undefined;

    // Get element
    this.subscription.add(
      this.templateService.getElement(this.element_choice.element_id).subscribe((element) => {
        this.element = element;
      }),
    );
    // Get reportId for this choice
    this.reportService
      .getChoiceParents(<RR.StatementChoice>this.choice)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((ctx) => {
        // @ts-expect-error strictNullChecks
        this.reportId = ctx.topic.report_id;

        // Get unresolved todos of this choice when it is flagged
        this.subscription.add(
          // eslint-disable-next-line rxjs/no-nested-subscribe -- 2
          this.store.select(fromTodo.selectInReport(this.reportId)).subscribe((todos) => {
            this.unresolvedTodos = todos.filter(
              (todo) => todo.statement_choice_id === (<RR.StatementChoice>this.choice).id && !todo.resolved,
            );
          }),
        );
      });

    if (this.choice.statement_id !== null) {
      /**
       * Check if sentence was categorised by using combo of statement and attributes
       */
      this.isSentenceCategorised = this.templateService.checkSentenceCategories(
        this.choice.statement_id,
        this.getCategoryAttributesFromStatementAttributes(),
      );

      this.sentenceCategoriesTooltip = this.templateService.getSentenceCategoriesTooltip(
        this.choice.statement_id,
        this.getCategoryAttributesFromStatementAttributes(),
      );
    }

    this.section$ = this.store.select(fromSection.selectSection(this.section_choice.section_id)).pipe(filterDefined());

    this.subscription.add(
      this.section$.subscribe((section) => {
        this.section = section;
      }),
    );
  }

  ngAfterViewInit() {
    if (!this.isClone) {
      this.subscription.add(
        this.editorService
          .getScrollEvents(
            this.element_choice.element_id,
            this.section_choice.topic_id,
            this.statement_id,
            this.region_id,
          )
          .subscribe(() => {
            setTimeout(() => {
              scrollIntoView({
                element: this.el.nativeElement,
                parent: document.querySelector('#' + PREVIEW_SCROLL_ID),
              });
            });
          }),
      );
    }

    this.subscription.add(
      this.store
        .select(fromTodo.selectInReport(this.reportId))
        .pipe(map((todos) => todos.filter((todo) => todo.statement_choice_id === this.choice_id)))
        .subscribe((todos) => {
          this.todosForFlag = todos;
          this.cd.markForCheck();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.closeNow();
  }

  @HostBinding('class.composite')
  get isComposite(): boolean {
    return 'component_choices' in this.choice && this.choice.component_choices instanceof Array;
  }

  get region_id() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.region_choice && this.region_choice.region_id ? this.region_choice.region_id : undefined;
  }

  get statement_id() {
    if ('statement_id' in this.choice && typeof this.choice.statement_id === 'number') {
      return this.choice.statement_id;
    } else {
      return undefined;
    }
  }

  get excluded() {
    if (!('excluded' in this.choice)) return false;
    return this.choice.excluded;
  }

  open() {
    if (this.isComposite || this.mode === 'prefill' || !this.popover) return;
    this.choicePreviewService.openPopover(this);
    if (!this.popover.isOpen()) {
      this.popover.open();
    }
    const popoverElement = this.popover['_windowRef'].location.nativeElement;
    this.subscription.add(
      fromEvent(popoverElement, 'mouseenter').subscribe(() => {
        clearTimeout(this.pendingClose);
      }),
    );
    this.subscription.add(
      fromEvent(popoverElement, 'mouseleave').subscribe(() => {
        this.close();
      }),
    );
  }

  close() {
    clearTimeout(this.pendingOpen);
    clearTimeout(this.pendingClose);
    this.pendingClose = window.setTimeout(() => {
      this.closeNow();
    }, 500);
  }

  closeNow() {
    clearTimeout(this.pendingOpen);
    if (this.popover && this.popover.isOpen()) {
      this.popover.close();
    }
  }

  // @ts-expect-error noImplicitAny
  @HostListener('mouseenter', ['$event']) onMouseEnter(event) {
    // event.buttons=1 when dragging (primary mouse button is down)
    if (this.mode === 'prefill' || this.mode === 'worklist' || event.buttons === 1) return;
    if (!this.isClone) {
      this.editorService.publishHighlight(
        {
          element_id: this.element_choice.element_id,
          topic_id: this.section_choice.topic_id,
          statement_id: this.statement_id,
          region_id: this.region_id,
          source: 'CHOICE_PREVIEW',
        },
        { highlight: true, scroll: true },
      );
    }
    clearTimeout(this.pendingClose);
    this.pendingOpen = this.choicePreviewService.pendingOpen = window.setTimeout(() => {
      this.open();
    }, 300);
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.mode === 'prefill') return;
    if (!this.isClone) {
      this.editorService.publishHighlight(
        {
          element_id: this.element_choice.element_id,
          topic_id: this.section_choice.topic_id,
          statement_id: this.statement_id,
          region_id: this.region_id,
          source: 'CHOICE_PREVIEW',
        },
        { highlight: false, scroll: false },
      );
    }
    this.close();
  }

  // @ts-expect-error noImplicitAny
  @HostListener('click', ['$event']) focusChoice(event) {
    document.body.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
    document.body.dispatchEvent(new MouseEvent('mouseup', { bubbles: true }));

    if (this.mode === 'prefill') return;
    if (!this.templateLoaded) return;

    if (!event.target.closest('[data-no-bubble-choice]')) {
      if (this.element) {
        this.subscription.add(
          this.checkNotepadSentence()
            .pipe(take(1))
            .subscribe((val) => {
              if (!val) {
                this.publishFocusChoice();
              } else {
                this.openNotepadSentence();
              }
            }),
        );
      }
    }
  }

  openNotepadSentence() {
    zip(
      this.reportService.getTopic(this.section_choice.topic_id),
      this.templateService.getSubsection(this.subsection_choice.subsection_id),
      this.region_id ? this.templateService.getRegion(this.region_id) : of(null),
    )
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe(([topic, sub, region]) => {
        requestAnimationFrame(() => {
          NotepadModalComponent.open({
            modal: this.modal,
            template_id: this.template_id,
            topic,
            // @ts-expect-error strictNullChecks
            element: this.element,
            // @ts-expect-error strictNullChecks
            subsection: sub,
            section: this.section,
            // @ts-expect-error strictNullChecks
            region,
            focusedChoiceId: 'id' in this.choice ? this.choice.id : undefined,
          });
        });
      });
  }

  generateAndAddSentence() {
    const generateAndAdd$ = this.reportHttpService.findStatementChoiceText(this.choice_id).pipe(
      switchMap((response) => this.addPatientNote(response.sentence_text)),
      take(1),
    );

    this.subscription.add(
      generateAndAdd$.subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: 'Add statement to patient notes successfully',
            type: 'success',
          });
        },
      }),
    );
  }

  addPatientNote(sentence: string): Observable<RR.PatientNote> {
    const note: Partial<RR.PatientNote> = {
      patient_id: this.report?.patient_id ?? undefined,
      note: sentence,
      category_id: 1,
      report_id: this.report?.id,
    };

    return this.patientNoteEffect
      .create(note)
      .pipe(map((action) => action.actions.createPatientNoteSuccess.patient_note));
  }

  publishFocusChoice() {
    const choice_id = 'id' in this.choice ? this.choice.id : null;
    if (choice_id) {
      this.editorService.publishFocusChoice(choice_id);
    } else {
      this.editorService.publishFocus({
        statement_id: this.statement_id,
        element_id: this.element_choice.element_id,
      });
    }
  }

  checkNotepadSentence() {
    if (!this.statement_id) return of(false);
    return this.store
      .select(fromElement.selectGlobalStatementSets)
      .pipe(map((statementSets) => !!statementSets.find((ss) => ss.statements.includes(this.statement_id as number))));
  }

  toggleExcluded() {
    if (this.mode === 'prefill' || !('id' in this.choice)) return;
    this.subscription.add(
      this.reportService
        .updateStatementChoice(this.choice, { statement_choice: { excluded: !this.choice.excluded } })
        .subscribe(),
    );
  }

  toggleFlagged() {
    if (this.mode === 'prefill') return;
    this.closeNow();
    if ('id' in this.choice) {
      if (this.unresolvedTodos.length > 0) {
        // Mark all todos of this choice as resolved
        this.unresolvedTodos.forEach((todo) => {
          this.subscription.add(this.todoEffect.update(todo.id, { resolved: true }).subscribe());
        });
      } else {
        const modalRef = FlagNoteModalComponent.open(this.modal);

        const statementChoiceId = (<RR.StatementChoice>this.choice).id;
        modalRef.result.then(
          (note) => {
            this.subscription.add(
              this.todoEffect
                .create({
                  report_id: this.reportId,
                  todo: note,
                  statement_choice_id: statementChoiceId,
                })
                .subscribe(),
            );
          },
          () => {
            /* modal was X'd out of */
          },
        );
      }
    }
  }

  remove() {
    // TODO: remove composite history options
    if ('id' in this.choice) {
      // Temporarily save recently deleted sentence
      this.editorService.saveClipboardSentence(this.displayText);
      this.reportService.removeStatementChoice(this.choice);
    }
  }

  selectAttribute(obj: RR.TextObjectChoice, event: MouseEvent) {
    // Check if click on notepad sentence's attribute
    this.checkNotepadSentence()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((val) => {
        if (val) {
          // Open notepad modal
          this.openNotepadSentence();
        } else {
          this.editorService.publishFocusTextObjectChoice(obj.id);
          // Stop propagation so that the attribute/number dropdown doesn't close when their focus event is handled so quick
          event.stopPropagation();
        }
      });
  }

  categoriseSentence() {
    this.openSentenceCategoryModal();
  }

  getCategoryAttributesFromStatementAttributes(): ICategorisedAttribute[] {
    return this.statement_attributes.map((choice_attr) => {
      if (choice_attr.type === 'set') {
        return {
          attribute_option_id: choice_attr.attribute_option_id,
          text_object_id: choice_attr.text_object_id,
        };
      } else {
        return {
          attribute_option_id: 0,
          text_object_id: choice_attr.text_object_id,
        };
      }
    });
  }

  openSentenceCategoryModal() {
    if (!this.choice.statement_id) throw new Error('statement_id is undefined');
    return CategoriseSentenceModalComponent.open(
      null,
      this.modal,
      this.reportId,
      this.section_choice.topic_id,
      this.element ? this.element.statement_set_id : null,
      this.region_id || null,
      this.choice.statement_id,
      this.getCategoryAttributesFromStatementAttributes(),
    );
  }

  openDividerStatementsModal() {
    const statement_id = this.choice.statement_id;
    if (!statement_id) throw new Error('statement_id is undefined');
    let statement: RR.Statement | undefined;
    this.subscription.add(
      this.store
        .select(fromStatement.selectStatement(statement_id))
        .pipe(take(1))
        .subscribe((_statement) => {
          statement = _statement;
        }),
    );
    if (!statement || !statement.divider_id) {
      return;
    }
    DividerStatementsModalComponent.open({
      modalService: this.modal,
      divider_id: statement.divider_id,
      topic_id: this.section_choice.topic_id,
      parent: 'PREFILL_TAG_MODAL',
    });
  }

  toggleClone(newSection: RR.ReportSection) {
    if (this.mode === 'prefill' || 'component_choices' in this.choice) return;

    this.closeNow();

    const clones = this.choice.clones || [];

    if (clones.length === 0) {
      // If the choice is not in any section, add it to the new section
      this.subscription.add(this.reportService.addClone(this.choice, newSection).pipe(take(1)).subscribe());
    } else if (clones.includes(newSection)) {
      // If the choice is in the new section, remove it from there
      this.subscription.add(this.reportService.removeClone(this.choice, newSection).pipe(take(1)).subscribe());
    } else {
      // If the choice is in any section, remove it from there and add to the new section
      this.subscription.add(
        // This does it in one API call
        this.reportService.updateStatementChoice(this.choice, { clones: [newSection] }).subscribe(),
      );
    }
  }
}
