import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum Operator {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  CONTAINS = 'CONTAINS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
}

export interface PercentileBandRule {
  id: number;
  imgsim_parameter_id: number;
  value: number;
  operator: Operator;
}

@Injectable()
export class PercentileBandRuleHttpService {
  constructor(private http: HttpClient) {}

  createPercentileBandRule(percentileBandRule: Partial<PercentileBandRule>): Observable<PercentileBandRule> {
    return this.http.post<PercentileBandRule>(`/api/percentile_band_rule`, percentileBandRule);
  }

  updatePercentileBandRule(
    percentileBandRuleId: number,
    changes: Partial<PercentileBandRule>,
  ): Observable<PercentileBandRule> {
    return this.http.put<PercentileBandRule>(`/api/percentile_band_rule/${percentileBandRuleId}`, changes);
  }

  deletePercentileBandRule(percentileBandRuleId: number): Observable<void> {
    return this.http.delete<void>(`/api/percentile_band_rule/${percentileBandRuleId}`);
  }

  getAllPercentileBandRules(): Observable<PercentileBandRule[]> {
    return this.http.get<PercentileBandRule[]>(`/api/percentile_band_rules`);
  }
}
