import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-billing-item',
  templateUrl: './billing-item.component.html',
  styleUrls: ['./billing-item.component.css'],
})
export class BillingItemComponent {
  @Input() billing_item: RR.BillingItem;

  @Input() displayNotes = true;
  @Input() displayFees = true;
}
