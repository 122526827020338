import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { updateSuccess as invoiceUpdateSuccess } from '../invoice/invoice.action';

const findManySuccess = createAction('[InvoiceItem] Find Many Success', props<{ invoiceItems: RR.InvoiceItem[] }>());
const createManySuccess = createAction('[InvoiceItem] Create Success', props<{ invoiceItems: RR.InvoiceItem[] }>());
const updateSuccess = createAction('[InvoiceItem] Update Success', props<{ invoiceItem: RR.InvoiceItem }>());
const deleteSuccess = createAction('[InvoiceItem] Delete Success', props<{ invoiceItemId: number }>());

const createSuccessBatch = createBatchAction(
  '[InvoiceItem] Create Success Batch',
  props<{
    actions: {
      createInvoiceItemsSuccess: ReturnType<typeof createManySuccess>;
      updateInvoiceSuccess: ReturnType<typeof invoiceUpdateSuccess>;
    };
  }>(),
);

const deleteSuccessBatch = createBatchAction(
  '[InvoiceItem] Delete Success Batch',
  props<{
    actions: {
      deleteInvoiceItemSuccess: ReturnType<typeof deleteSuccess>;
      updateInvoiceSuccess: ReturnType<typeof invoiceUpdateSuccess>;
    };
  }>(),
);
/**
 * Ngrx Actions for InvoiceItem
 *
 */
export const InvoiceItemActions = {
  findManySuccess,
  createManySuccess,
  updateSuccess,
  deleteSuccess,
  createSuccessBatch,
  deleteSuccessBatch,
};
