import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Label>();
const selectFeature = (state: AppState) => state.label;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectLabel = (id: number) => createSelector(selectEntities, (labels: Dictionary<RR.Label>) => labels[id]);

export const fromLabel = {
  selectIds,
  selectEntities,
  selectAll,
  selectLabel,
};
