import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { COMMAND_ACTIONS, DELIMITER, TRIGGER_VOICE_STOP_CURRENT, ARE_YOU_LISTENING_TO_ME } from 'app/app.constants';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { SelectorService } from 'app/core/services/selector.service';
import {
  CommandOutput,
  ListeningType,
  VoiceInputTerm,
  VoiceRecognitionService,
} from 'app/core/services/voice-recognition.service';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { fromCurrentReport, fromReport } from 'app/store/report/report';
import { fromUserSetting, UserSettingEffect } from 'app/store/user/user-setting';
import { distinctUntilChanged, filter, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule],
  selector: 'rr-voice-recognition',
  templateUrl: './voice-recognition.component.html',
  styleUrls: ['./voice-recognition.component.css'],
})
export class VoiceRecognitionComponent implements OnInit, OnDestroy {
  @Input() source: string;
  @Input() reportId?: number | undefined;
  @Input() excludeVoiceTerms = false;
  @Input() speechSynthesis = true;
  subscription = new Subscription();
  isListening: ListeningType;
  globalSearchTerm: string;
  voiceControls = { DELIMITER, COMMAND_ACTIONS, TRIGGER_VOICE_STOP_CURRENT, ARE_YOU_LISTENING_TO_ME };
  voiceMode$: Observable<boolean>;
  report$: Observable<RR.Report | undefined>;
  user$: Observable<RR.User | undefined>;

  constructor(
    private voiceService: VoiceRecognitionService,
    private store: Store<AppState>,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private selectorService: SelectorService,
    private userSettingEffect: UserSettingEffect,
  ) {}

  ngOnInit(): void {
    if (this.reportId) {
      this.report$ = this.store.select(fromReport.selectReport(this.reportId));
    } else {
      this.report$ = this.store.select(fromCurrentReport.selectReport);
    }

    this.user$ = this.selectorService.selectLoadedCurrentUser();

    this.voiceMode$ = this.user$.pipe(
      filterDefined(),
      switchMap((user) =>
        this.store
          .select(fromUserSetting.selectUserSetting(user.id))
          .pipe(map((userSetting) => userSetting?.voice_recognition_features || false)),
      ),
    );

    this.subscription.add(
      this.user$
        .pipe(
          filterDefined(),
          take(1),
          switchMap((user) =>
            this.store.select(fromUserSetting.selectUserSettingLoaded(user.id)).pipe(
              map((loaded) => ({
                loaded,
                user,
              })),
              switchMap(({ loaded, user }) => {
                if (!loaded) {
                  this.userSettingEffect.findByUser(user.id);
                }
                return of(null);
              }),
            ),
          ),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.editorService.globalSearchTerm$.subscribe((response) => {
        this.globalSearchTerm = response?.term ?? '';
      }),
    );

    this.subscription.add(
      this.voiceService.voiceTermSubject$
        .pipe(filter((voiceInput: VoiceInputTerm) => voiceInput.source === this.source))
        .subscribe((voiceInput) => {
          if (this.speechSynthesis) this.voiceService.startSpeechSynth(voiceInput.term);
          // Exclude voice terms from being dispatched twice when voice.directive is doing so.
          if (!this.excludeVoiceTerms) {
            this.store.dispatch(EditorActions.addVoiceSearchResult({ term: voiceInput.term, note_type: 'general' }));
            if (this.globalSearchTerm) {
              this.editorService.clearGlobalSearch();
            }
            this.copyToSearch(voiceInput.term);
          }
        }),
    );

    this.subscription.add(
      this.voiceService.isListening$.pipe(distinctUntilChanged()).subscribe((listening) => {
        this.isListening = listening;
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      this.voiceService.commandSubject$
        .pipe(filter((command: CommandOutput) => command.destination === 'VOICE_RECOGNITION'))
        .subscribe(({ command }) => {
          if (command === COMMAND_ACTIONS.stopListening) {
            this.voiceService.stopListening();
          }
          this.cd.detectChanges();
        }),
    );
  }

  startListening(): void {
    this.voiceService.startListening(this.source);
  }

  stopListening() {
    this.voiceService.stopListening();
  }

  copyToSearch(term: string) {
    this.editorService.globalSearchTerm$.next({ source: 'TAG', term });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
