import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { VoyagerUrlService } from 'app/core/services/voyager-url.service';
import { ReportStatusSelectorComponent } from 'app/shared/components/report-status-selector/report-status-selector.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { Subscription, map, startWith, switchMap } from 'rxjs';

import { OpenVoyagerButtonComponent } from '../../../components/open-voyager-button/open-voyager-button.component';
import { PatientInfoComponent } from '../../../components/patient-info/patient-info.component';
import { ReferrerNameComponent } from '../../../components/referrer-name/referrer-name.component';
import { StoreSelectPipe } from '../../../pipes/store-select.pipe';
import { NextReportRowComponent } from '../next-report-row/next-report-row.component';

export type NextReportLabel = 'Next' | 'Same Referrer' | 'Same Scan Type' | 'XR' | 'MR' | 'CT' | 'US';

export interface NextReport {
  reportId: number;
  label: NextReportLabel;
}

@Component({
  selector: 'rr-next-report',
  standalone: true,
  templateUrl: './next-report.component.html',
  styleUrl: './next-report.component.css',
  imports: [
    CommonModule,
    StoreSelectPipe,
    PatientInfoComponent,
    SharedModule,
    OpenVoyagerButtonComponent,
    ReferrerNameComponent,
    AutoFocusDirective,
    NextReportRowComponent,
    ReportStatusSelectorComponent,
  ],
})
export class NextReportComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  nextReports: NextReport[];
  form = new FormGroup({
    reportStatuses: new FormControl<RR.ReportStatus[]>([], { nonNullable: true }),
  });

  subscription = new Subscription();

  constructor(
    private reportEffect: ReportEffect,
    public voyagerUrlService: VoyagerUrlService,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const reportStatuses$ = this.store.select(fromReportStatus.selectAll);

    this.subscription.add(
      reportStatuses$.pipe(filterDefined()).subscribe((reportStatuses) => {
        const defaultValueSet: Set<RR.ReportStatusType> = new Set([
          'scanned',
          'reported_by_technician',
          'reported_by_registrar',
        ]);
        const defaultReportStatuses = reportStatuses.filter((rs) => {
          return defaultValueSet.has(rs.value);
        });
        this.form.controls.reportStatuses.setValue(defaultReportStatuses);
      }),
    );

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          startWith(this.form.getRawValue()),
          map(() => this.form.getRawValue()),
          switchMap((value) => {
            return this.reportEffect.nextReport({
              report_id: this.reportId,
              report_statuses: value.reportStatuses.map((rs) => rs.id),
            });
          }),
        )
        .subscribe({
          next: (action) => {
            this.nextReports = [
              { reportId: action.next_report_id, label: 'Next' },
              { reportId: action.next_report_same_referrer_id, label: 'Same Referrer' },
              { reportId: action.next_report_same_scan_code_id, label: 'Same Scan Type' },
              { reportId: action.next_report_xr_id, label: 'XR' },
              { reportId: action.next_report_mr_id, label: 'MR' },
              { reportId: action.next_report_ct_id, label: 'CT' },
              { reportId: action.next_report_us_id, label: 'US' },
            ];
          },
        }),
    );
  }

  selectReportSelectorFn = fromReport.selectReport;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onChangeReportStatuses(selectedReportStatuses: RR.ReportStatus[]) {
    this.form.controls.reportStatuses.setValue(selectedReportStatuses);
  }
}
