import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { FavouriteHeadlineComponent } from 'app/shared/components/favourite-headline/favourite-headline.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { fromFavourite } from 'app/store/report/favourite';
import { fromReport } from 'app/store/report/report';
import { Observable, of, Subscription, switchMap } from 'rxjs';

import { RefTopicsContext } from '../ref-topics.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, FavouriteHeadlineComponent, StoreSelectPipe, ReportAccessionNumberComponent],
  selector: 'rr-ref-topic',
  templateUrl: './ref-topic.component.html',
  styleUrls: ['./ref-topic.component.css'],
})
export class RefTopicComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic | undefined;
  @Input() context: RefTopicsContext = 'FAVOURITES';
  @Output() onSelectFavourite = new EventEmitter<RR.Favourite>();
  topic$: Observable<RR.Topic | undefined>;

  favourite: RR.Favourite | undefined;
  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) => {
            if (topic) {
              return this.store.select(fromFavourite.selectById(topic.favourite_id));
            }
            return of(undefined);
          }),
        )
        .subscribe((favourite) => {
          this.favourite = favourite;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectReportSelectorFn = fromReport.selectReport;
}
