import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { SexSpecificWordActions } from './sex-specific-word.action';

export interface SexSpecificWordState extends EntityState<RR.SexSpecificWord> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.SexSpecificWord>();

const initialState = adapter.getInitialState({ loaded: false });

export const SexSpecificWordReducer = createReducer(
  initialState,
  on(SexSpecificWordActions.findAllSuccess, (state: SexSpecificWordState, { sex_specific_words }) => ({
    ...adapter.setAll(sex_specific_words, state),
    loaded: true,
  })),
  on(SexSpecificWordActions.createSuccess, (state: SexSpecificWordState, { sex_specific_word }) =>
    adapter.addOne(sex_specific_word, state),
  ),
  on(SexSpecificWordActions.deleteSuccess, (state: SexSpecificWordState, { sex_specific_word_id }) =>
    adapter.removeOne(sex_specific_word_id, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
