import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs';

import { ReportActions } from '../report/report.action';
import { ElementChoiceActions, ElementChoiceBatchActions } from './element-choice.action';
import { ElementChoiceHttpService, ElementChoiceUpdate } from './element-choice.service';

@Injectable()
export class ElementChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: ElementChoiceHttpService,
  ) {}

  update(elementChoiceId: number, changes: ElementChoiceUpdate) {
    return this.service.update(elementChoiceId, changes).pipe(
      map((response) =>
        ElementChoiceBatchActions.updateSuccess({
          actions: {
            elementChoiceUpdateSuccess: ElementChoiceActions.upsertOne({ elementChoice: response.element_choice }),
            reportUpdateSuccess: ReportActions.upsertOne({ report: response.report }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
