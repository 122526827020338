import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { RegionActions } from './region.action';

const adapter = createEntityAdapter<RR.Region>();

export interface RegionState extends EntityState<RR.Region> {}

const initialState = adapter.getInitialState();

export const regionReducer = createReducer(
  initialState,
  on(RegionActions.addMany, (state, { regions }) => adapter.addMany(regions, state)),
  on(RegionActions.addOne, (state, { region }) => adapter.addOne(region, state)),
  on(RegionActions.upsertOne, (state, { region }) => adapter.upsertOne(region, state)),
  on(RegionActions.removeOne, (state, { regionId }) => adapter.removeOne(regionId, state)),
  on(RegionActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
