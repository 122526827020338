import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TextObjectHttpService {
  constructor(private http: HttpClient) {}

  update(textObjectId: number, changes: Partial<RR.TextObject>) {
    return this.http.put<{
      statement: RR.Statement;
      textObject: RR.TextObject;
    }>(`/api/text_object/${textObjectId}`, changes);
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number) {
    return this.http.get<RR.TextObject[]>(`/api/template/${templateId}/text_object`);
  }
}
