<div class="d-flex flex-column max-height">
  <div>
    <div class="alert alert-info mb-1 py-1" *ngIf="referrer; else noReferrer">
      <strong>Referrer: </strong>
      <rr-referrer-name [referrerId]="referrer.id" [referrerType]="true" [editReferrer]="true"></rr-referrer-name>,
      {{ referrer.medicare_provider_number }}
      {{ referrer.service }}
      {{ referrer.city }}
    </div>
    <ng-template #noReferrer>
      <div class="alert alert-info mb-1 py-1">
        <strong>No Referrer</strong>
      </div>
    </ng-template>

    <div *ngIf="provider" class="list-group mb-2">
      <div class="list-group-item d-flex align-items-center">
        <span><strong>To: </strong> <rr-provider [provider]="provider"></rr-provider></span>
        <button
          class="btn btn-outline-danger ms-auto text-nowrap"
          title="Remove provider"
          (click)="unchooseProvider(provider)"
        >
          Remove Provider
        </button>
      </div>
    </div>

    <div *ngIf="ccProviders.length" class="list-group mb-1">
      <div *ngFor="let cc of ccProviders" class="list-group-item d-flex align-items-center">
        <span><strong>CC </strong><rr-provider [provider]="cc"></rr-provider></span>
        <button class="btn btn-outline-dark ms-auto" (click)="unchooseProvider(cc)">
          <span class="fa fa-minus-circle"></span>
        </button>
      </div>
    </div>

    <textarea readonly class="form-control modal-textarea mb-1" [formControl]="reportText"></textarea>
  </div>

  <div class="d-flex align-items-center mb-1">
    <h1 class="mb-0">To CC: Search Medical Objects Provider Directory</h1>
    <div class="ms-auto">
      <button (click)="openProofRead()" class="btn btn-outline-secondary me-2">Proof Read</button>
      <span
        *ngIf="sendMessage"
        class="badge me-2"
        [ngClass]="sendMessage.type === 'success' ? 'bg-success' : 'bg-danger'"
        >{{ sendMessage.message }}</span
      >
      <button #sendMOBtn class="btn btn-success" (click)="checkThenSend()" [disabled]="!provider">Send</button>
    </div>
  </div>
  <div>
    <div class="d-flex flex-row mb-3">
      <input type="text" #searchByName class="form-control" placeholder="Surname [space] First Name" />
      <input type="text" #searchByProviderId class="ms-1 form-control" placeholder="Medicare Provider Number" />
    </div>
    <div *ngIf="!providerSearchResult && !isLoading">No search results</div>
    <!-- This loading indicator only works sometimes -->
    <div *ngIf="!providerSearchResult && isLoading">Loading...</div>
  </div>

  <div class="d-flex flex-column overflow-auto">
    <table class="table table-hover" *ngIf="providerSearchResult">
      <tr>
        <th>Provider ID</th>
        <th>Family name</th>
        <th>Given name</th>
        <th>Job title</th>
        <th>Practice</th>
        <th>City</th>
        <th></th>
      </tr>
      <ng-container *ngFor="let providerResult of providerSearchResult.list">
        <tr>
          <td>{{ providerResult.staffIDList[0].idNumber }}</td>
          <td>{{ providerResult.staffName.familyName }}</td>
          <td>{{ providerResult.staffName.givenName }}</td>
          <td>{{ providerResult.jobTitle }}</td>
          <td>{{ providerResult.address[0].otherDesignation }}</td>
          <td>{{ providerResult.address[0].city }}</td>
          <td>
            <button
              *ngIf="(!provider && !isProviderCC(providerResult)) || isProviderTo(providerResult)"
              class="btn btn-outline-primary"
              (click)="chooseProvider(providerResult)"
              [disabled]="isProviderTo(providerResult)"
            >
              Choose
            </button>
            <button
              *ngIf="(provider && !isProviderTo(providerResult)) || isProviderCC(providerResult)"
              class="btn btn-outline-primary"
              (click)="chooseProvider(providerResult)"
              [disabled]="isProviderCC(providerResult)"
            >
              CC
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
