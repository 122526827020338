<form class="d-flex flex-column">
  <div class="form-group d-flex flex-column">
    <div>
      <label *ngIf="label">{{ label }} </label>
      <div class="input-group">
        <input
          #dateInput
          [readonly]="true"
          class="form-control"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="2"
          [dayTemplate]="dayTemplate"
          [footerTemplate]="footerTemplate"
          outsideDays="hidden"
          [startDate]="fromDate!"
          container="body"
          (click)="datepicker.toggle()"
        />
        <button class="btn btn-outline-secondary fa fa-calendar" (click)="datepicker.toggle()" type="button"></button>
      </div>
    </div>
  </div>
</form>

<ng-template #dayTemplate let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <hr class="my-0" />
  <div class="d-flex flex-wrap footer">
    <button
      *ngFor="let option of options"
      class="btn btn-primary btn-sm mx-2 my-1 float-start"
      (click)="onOptionChanged(option.id); datepicker.close()"
    >
      {{ option.value }}
    </button>
    <button class="btn btn-secondary btn-sm mx-2 my-1" (click)="datepicker.close()">Close</button>
  </div>
</ng-template>
