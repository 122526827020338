import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor';
import { PathologyActions } from './pathology.action';

export type PathologyState = EntityState<RR.Pathology>;

const adapter = createEntityAdapter<RR.Pathology>();
const initialState = adapter.getInitialState();

export const PathologyReducer = createReducer(
  initialState,
  on(PathologyActions.findAllSuccess, (state: PathologyState, { pathologies }) => adapter.setAll(pathologies, state)),
  on(PathologyActions.findManySuccess, (state: PathologyState, { pathologies }) =>
    adapter.upsertMany(pathologies, state),
  ),
  on(PathologyActions.createSuccess, (state: PathologyState, { pathology }) => adapter.addOne(pathology, state)),
  on(PathologyActions.deleteSuccess, (state: PathologyState, { pathology_id }) =>
    adapter.removeOne(pathology_id.toString(), state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
