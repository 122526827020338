import { HttpResponseBase } from '@angular/common/http';
import * as Sentry from '@sentry/angular';

// Base class already has @Injectable()
export class SentryErrorHandler extends Sentry.SentryErrorHandler {
  handleError(error: unknown): void {
    try {
      if (error instanceof HttpResponseBase) {
        console.warn('Skipped sending HttpResponseBase error to Sentry', error);
      } else {
        super.handleError(error);
      }
    } catch (loggingError) {
      console.group('Error when trying to send to Sentry');
      console.error(loggingError);
      console.groupEnd();
    }
  }
}
