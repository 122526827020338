import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { StatementActions, StatementBatchActions } from '../statement/statement.action';
import { StatementSetActions } from './statement-set.action';

export interface StatementSetState extends EntityState<RR.StatementSet> {
  statementSetLoadingState: { [statementSetId: string]: 'loading' | 'loaded' | undefined };
}

const adapter = createEntityAdapter<RR.StatementSet>();

const initialState: StatementSetState = adapter.getInitialState({
  loadedInTemplate: {},
  statementSetLoadingState: {},
});

export const statementSetReducer = createReducer(
  initialState,
  on(StatementSetActions.addMany, (state, { statementSets }) => adapter.addMany(statementSets, state)),
  on(StatementSetActions.addOne, (state, { statementSet }) => adapter.addOne(statementSet, state)),
  on(StatementSetActions.upsertOne, (state, { statementSet }) => adapter.upsertOne(statementSet, state)),
  on(StatementSetActions.upsertMany, (state, { statementSets }) => adapter.upsertMany(statementSets, state)),
  on(StatementSetActions.removeOne, (state, { statementSetId }) => adapter.removeOne(statementSetId, state)),
  on(StatementActions.findInStatementSet, (state, { statementSetId }) => {
    return {
      ...state,
      statementSetLoadingState: {
        ...state.statementSetLoadingState,
        [statementSetId]: 'loading',
      },
    };
  }),
  on(StatementActions.findInStatementSetFailed, (state, { statementSetId }) => {
    return {
      ...state,
      statementSetLoadingState: {
        ...state.statementSetLoadingState,
        [statementSetId]: undefined,
      },
    };
  }),
  on(StatementBatchActions.findInStatementSetSuccess, (state, action) => {
    return {
      ...state,
      statementSetLoadingState: {
        ...state.statementSetLoadingState,
        [action.statementSetId]: 'loaded',
      },
    };
  }),
  on(
    StatementSetActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
