import { Injectable } from '@angular/core';
import { concatenateStrings } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

declare type TextChoice = RR.StatementChoice & { text: string };
export interface CompositeChoice extends Omit<RR.StatementChoice, 'id' | 'text_object_choices' | 'excluded'> {
  component_choices: TextChoice[];
  format: {
    format: string;
    location: string;
  };
}

@Injectable()
export class ChoiceConcatenation {
  constructor(private reportService: ReportService) {}

  private formats = [
    // location === 'split' first
    { text: 'the history of...noted', location: 'split' },
    { text: 'the prior report dated...was reviewed', location: 'split' },
    { text: 'images from the study dated...were reviewed', location: 'split' },
    // then location === 'end'
    // TODO: not doing end right now, gotta go through db and remove start-of-option capitals first
    // { text: 'for investigation',  location: 'end'},
    // then location === 'start
    { text: 'history of', location: 'start' },
    { text: 'family history of', location: 'start' },
    { text: 'suspected', location: 'start' },
  ];

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  generateCompositeChoice = (format: string, location: string, choices: TextChoice[]) => {
    const pseudo_choice: CompositeChoice = {
      // TODO (flagged)
      // flagged: choices.map(choice => choice.flagged).some(flag => flag),
      statement_id: choices[0].statement_id,
      element_choice_id: choices[0].element_choice_id,
      // metadata-type-stuff for attribute recompute
      component_choices: choices,
      format: { format, location },
      source: choices[0].source,
    };

    return pseudo_choice;
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  concatenateChoices = (choices: RR.StatementChoice[]): Observable<(RR.StatementChoice | CompositeChoice)[]> => {
    // TODO: choice text array may allow for smarter and/or less convoluted concatenation code

    // Save the original Choice order because concatenation will change it
    const choiceToIndex = choices.reduce((prev, curr, index) => {
      // @ts-expect-error noImplicitAny
      prev[curr.id] = index;
      return prev;
    }, {});

    function getChoiceIndex(choice: RR.StatementChoice | CompositeChoice) {
      if ('component_choices' in choice) {
        // @ts-expect-error noImplicitAny
        return choiceToIndex[choice.component_choices[0].id];
      } else {
        // @ts-expect-error noImplicitAny
        return choiceToIndex[choice.id];
      }
    }

    return zip(
      ...choices.map((c) =>
        this.reportService.getChoiceText(c.id).pipe(
          map((text) => {
            return { ...c, text };
          }),
        ),
      ),
    ).pipe(
      map((raw) => {
        // assume no histories require concatenation and delete if they do -- easier than inverse
        const return_array: (RR.StatementChoice | CompositeChoice)[] = raw;
        const do_not_return: (RR.StatementChoice | CompositeChoice)[] = [];
        const concatenatees: { [k: string]: TextChoice[] } = {};

        // put all options to be concatenated into the appropriate bucket-thing
        this.formats.forEach((format) => {
          const format_string = format.text;
          raw.forEach((choice) => {
            if (choice.excluded) return;

            let histr = choice.text;
            if (histr.slice(-1) === '.') {
              histr = histr.slice(0, -1);
            }
            if (format.location === 'start') {
              if (histr.slice(0, format_string.length).toLowerCase() === format_string) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (concatenatees[format_string] == null) {
                  concatenatees[format_string] = [choice];
                } else {
                  concatenatees[format_string].push(choice);
                }

                do_not_return.push(choice);
              }
              // } else if (format.location === 'end') {
              //  if (histr.slice(-format_string.length).toLowerCase() === format_string) {
              //    if (concatenatees[format_string] == null) {
              //      concatenatees[format_string] = [choice];
              //    } else {
              //      concatenatees[format_string].push(choice);
              //    }
              //
              //    do_not_return.push(choice);
              //  }
            } else if (format.location === 'split') {
              const ellipsindex = format_string.indexOf('...');
              const start = format_string.slice(0, ellipsindex);
              const end = format_string.slice(ellipsindex + 3);
              if (
                histr.slice(0, start.length).toLowerCase() === start &&
                histr.slice(-end.length).toLowerCase() === end
              ) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (concatenatees[format_string] == null) {
                  concatenatees[format_string] = [choice];
                } else {
                  concatenatees[format_string].push(choice);
                }

                do_not_return.push(choice);
              }
            }
          });
        });

        do_not_return.forEach((choice) => return_array.splice(return_array.indexOf(choice), 1));

        // go through `contenatees` buckets and generate mock choices for each
        Object.entries(concatenatees).forEach(([format_string, bucket_choices]) => {
          if (bucket_choices.length === 1) {
            return_array.push(bucket_choices[0]);
          } else {
            // @ts-expect-error strictNullChecks
            const location = this.formats.find((format) => format.text === format_string).location;
            return_array.push(this.generateCompositeChoice(format_string, location, bucket_choices));
          }
        });

        return return_array.sort((a, b) => {
          return getChoiceIndex(a) - getChoiceIndex(b);
        });
      }),
    );
  };

  concatenatedDisplayText(composite_choice: CompositeChoice) {
    const format = composite_choice.format.format;
    const choice_texts = composite_choice.component_choices.map((c) => c.text);

    let body_text: string;
    let return_value: string;
    switch (composite_choice.format.location) {
      case 'start':
        body_text = concatenateStrings(
          ...choice_texts.map((text) => {
            return text.slice(format.length + 1, text.slice(-1) === '.' ? -1 : undefined);
          }),
        );
        return_value = `${format} ${body_text}.`;
        break;
      case 'end':
        // TODO -- not currently used in app
        break;
      case 'split':
        const ellipsindex = format.indexOf('...');
        const start = format.slice(0, ellipsindex);
        const end = format.slice(ellipsindex + 3);
        let display_start = start;
        let display_end = end;
        // some formats make more sense when key words are pluralised
        if (start === 'the prior report dated') {
          display_start = 'the prior reports dated';
        } else if (start === 'images from the study dated') {
          display_start = 'images from the studies dated';
        }
        // again, just massaging known problem sentences into "correct" natural language
        if (start === 'the prior report dated' && end === 'was reviewed') {
          display_end = 'were reviewed';
        }
        body_text = concatenateStrings(
          ...choice_texts.map((text) => {
            let body = text.slice(start.length, -(end.length + (text.slice(-1) === '.' ? 2 : 1))).trim();
            if (end === 'noted' && body.slice(-3) === ' is') {
              body = body.slice(0, -3);
            }
            return body;
          }),
        );
        return_value = `${display_start} ${body_text} ${display_end}.`;
        break;
    }
    // @ts-expect-error strictNullChecks
    return return_value.charAt(0).toUpperCase() + return_value.slice(1);
  }
}
