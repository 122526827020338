import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FavouriteHttpService {
  constructor(private http: HttpClient) {}

  // Legacy code below this line

  /**
   * @deprecated
   */
  findFavouriteById(id: number) {
    return this.http.get<RR.Favourite>(`/api/favourites/${id}`, {
      // Exclude=topic was a quickfix. to prevent implicit topic deletion alongside favourite deletion
      params: new HttpParams().append('exclude', 'topic'),
    });
  }

  /**
   * @deprecated
   */
  create(topicId: number, favourite: Partial<RR.Favourite>): Observable<RR.Favourite & { topic: Partial<RR.Topic> }> {
    return this.http.post<RR.Favourite & { topic: Partial<RR.Topic> }>(`/api/topic/${topicId}/favourites`, favourite);
  }

  /**
   * @deprecated
   */
  update(favouriteId: number, changes: Partial<RR.Favourite>): Observable<RR.Favourite> {
    return this.http.put<RR.Favourite>(`/api/favourites/${favouriteId}`, changes);
  }

  /**
   * @deprecated
   */
  delete(favouriteId: number) {
    return this.http.delete(`/api/favourites/${favouriteId}`);
  }
}
