import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type GithubIssueCommentPayload = Pick<RR.GithubIssueComment, 'comment'>;

export type GithubIssueFindResponse = {
  issues: RR.GithubIssue[];
  comments: RR.GithubIssueComment[];
  count: number;
};

export type GithubIssueCommentCreateResponse = {
  comment: RR.GithubIssueComment;
  issue: RR.GithubIssue;
};

@Injectable({
  providedIn: 'root',
})
export class GithubIssueService {
  constructor(private http: HttpClient) {}

  create(comment: GithubIssueCommentPayload, githubIssueId: number) {
    return this.http.post<GithubIssueCommentCreateResponse>(`/api/github_issue/${githubIssueId}/comment`, comment);
  }

  findAll(status: string, sort: string, offset: number, limit: number) {
    return this.http.post<GithubIssueFindResponse>(`/api/github_issues`, {
      status: status,
      sort: sort,
      offset: offset,
      limit: limit,
    });
  }

  findById(githubIssueId: number) {
    return this.http.get<{
      issue: RR.GithubIssue;
      comments: RR.GithubIssueComment[];
    }>(`/api/github_issue/${githubIssueId}`);
  }
}
