import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TemplateData } from '../report/topic/topic.service';

export type ProposedDataset = {
  proposed_statements: RR.ProposedStatement[];
};

export interface ScheduledImgsimJobResponse {
  message: string;
  task_id: string;
}

export interface Parameter {
  id: number;
  name: string;
  value: string;
  unit: string;
  calculation_method: 'RR' | 'DNN' | 'HU' | null;
  upper_percentile: number;
  lower_percentile: number;
}
export interface ParametersTable {
  images: string[] | undefined;
  parameters: Parameter[] | undefined;
}

export type ProposedResponse = {
  title: string;
  datasets: ProposedDataset[];
  images: string[] | undefined;
  parameters: Parameter[] | undefined;
} & TemplateData;

export type ImgSimJobStatus = {
  id: number;
  template_id: number;
  status: string;
  attempts: number;
  created_at: string;
};

export type SegmentedImageCheck = {
  available: boolean;
};

export type ImgFeaturesJobPayload = {
  accession_number: string;
  template_id: number;
  series_number: number | null;
};

@Injectable()
export class ImgsimParamsHttpService {
  constructor(private http: HttpClient) {}

  propose(topicId: number) {
    return this.http.get<ProposedResponse[]>(`/api/parameters/${topicId}`);
  }

  getImgSimJobStatus(accession: string) {
    return this.http.get<ImgSimJobStatus[]>(`api/debug/report/${accession}/imgsim_jobs`);
  }

  sendImgSimExtractionJob(accession: string, templateId: number) {
    const body = {
      accession_no: accession,
      template_id: templateId,
    };
    return this.http.post(`/api/report/add_imgsim_job`, body);
  }

  getSegmentedImages(accession: string) {
    return this.http.get<SegmentedImageCheck>(`api/report/${accession}/segmented_images`);
  }

  getAllImgSimParams() {
    return this.http.get<RR.ImgsimParameter[]>(`/api/parameter_list`);
  }

  getReportImgSimParams(reportId: number) {
    return this.http.get<RR.ImgsimParameter[]>(`/api/parameter_list/${reportId}`);
  }

  rerunImgFeaturesJob(accession: string, templateId: number, seriesNumber: number | null) {
    const data: ImgFeaturesJobPayload = {
      accession_number: accession,
      template_id: templateId,
      series_number: seriesNumber,
    };

    return this.http.post<ScheduledImgsimJobResponse>(`/api/debug/rerun_imgsim_job`, data);
  }

  rerunImgSegmentationJob(accession: string, templateId: number) {
    const data = {
      accession_number: accession,
      template_id: templateId,
    };
    return this.http.post<ScheduledImgsimJobResponse>(`/api/debug/rerun_imgsim_segmentation`, data);
  }

  updateImgsimParameterName(parameterId: number, data: { name: string }) {
    return this.http.put<Parameter>(`/api/parameter/${parameterId}`, data);
  }
}
