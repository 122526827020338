import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  funderMap,
  invoiceItemStatusMap,
  invoiceStatusMap,
  medipassClaimStatusMap,
  medipassTransactionStatusMap,
} from 'app/modules/invoice/invoice.constants';
import { CreateInvoiceModalComponent } from 'app/modules/invoice/modals/create-invoice-modal/create-invoice-modal.component';
import { Db4FormInvoiceComponent } from 'app/modules/registration/components/db4-form-invoice/db4-form-invoice.component';
import { RegistrationLinkComponent } from 'app/shared/components/registration-link/registration-link.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { BillingItemEffect } from 'app/store/billing-item/billing-item.effect';
import { fromInstitution } from 'app/store/institution';
import { InvoiceEffect } from 'app/store/invoice';
import { fromInvoiceItem } from 'app/store/invoice-item';
import { fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewInvoiceModalComponent } from '../../modals/view-invoice-modal/view-invoice-modal.component';
import { InvoiceComponent } from '../../pages/invoice/invoice.component';
import { InvoiceMedipassComponent } from '../invoice-medipass/invoice-medipass.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    StoreSelectPipe,
    RouterModule,
    // For SelectPipe
    SharedModule,
    InvoiceMedipassComponent,
    RegistrationLinkComponent,
    Db4FormInvoiceComponent,
    ReportAccessionNumberComponent,
  ],
  selector: 'rr-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.css'],
})
export class InvoiceTableComponent implements OnInit, OnDestroy {
  @Input() invoices$: Observable<RR.Invoice[]>;
  @Input() report_id: number | undefined;
  @Input() bookingId: number | undefined;
  @Output() onSearchByPatient: EventEmitter<Partial<RR.Patient>> = new EventEmitter();

  showDeletedInvoices = false;
  parent: 'INVOICE' | 'OTHER';
  funders = funderMap;
  status = invoiceStatusMap;
  itemStatus = invoiceItemStatusMap;
  medipassTransactionStatus = medipassTransactionStatusMap;
  medipassClaimStatus = medipassClaimStatusMap;

  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private invoiceEffect: InvoiceEffect,
    private modal: NgbModal,
    private billingItemEffect: BillingItemEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    if (this.route.component === InvoiceComponent) {
      this.parent = 'INVOICE';
    } else {
      this.parent = 'OTHER';
    }

    // Find all billing item effect for editing invoice items in invoice table
    this.subscription.add(this.billingItemEffect.findAll().subscribe());
  }

  invoiceItemSelectorFn = fromInvoiceItem.selectInvoiceItem;

  reportSelectorFn = fromReport.selectReport;

  institutionSelectorFn = fromInstitution.selectInstitution;

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  checkInvoiceItemStatusFn = (ids: number[], medipassStatus: string): Observable<boolean> => {
    return this.store
      .select(fromInvoiceItem.selectInvoiceItems(ids))
      .pipe(map((invoiceItems) => !!invoiceItems.find((item) => item?.medipass_status === medipassStatus)));
  };

  toggleDeletedInvoices() {
    this.showDeletedInvoices = !this.showDeletedInvoices;
  }

  viewInvoice(id: number) {
    ViewInvoiceModalComponent.open(this.modal, id);
  }

  deleteInvoice(invoice: RR.Invoice) {
    if (invoice.is_draft) {
      this.subscription.add(this.invoiceEffect.delete(invoice.id).subscribe());
    } else {
      const modalRef = ConfirmMessageModalComponent.open({
        modalService: this.modal,
        header: 'Confirm',
        message: `Enter reason to delete invoice ${invoice.id}:`,
        btnConfirmText: 'Yes',
        textInput: true,
      });
      modalRef.result.then(
        (input) => {
          const reason = { reason: input };
          this.subscription.add(this.invoiceEffect.delete(invoice.id, reason).subscribe());
        },
        () => {
          /* do nothing */
        },
      );
    }
  }

  promoteInvoice(id: number) {
    this.subscription.add(this.invoiceEffect.update(id, { is_draft: false }).subscribe());
  }

  editInvoice(invoice: RR.Invoice) {
    const reportId = this.report_id || invoice.reports[0];
    const bookingId = this.bookingId || invoice.bookings[0];
    CreateInvoiceModalComponent.open(this.modal, reportId, bookingId, invoice.id);
  }

  searchByPatient(patient: Partial<RR.Patient>) {
    this.onSearchByPatient.emit(patient);
  }

  createNewInvoice(invoice: RR.Invoice) {
    const reportId = this.report_id || invoice.reports[0];
    const bookingId = this.bookingId || invoice.bookings[0];
    CreateInvoiceModalComponent.open(this.modal, reportId, bookingId, invoice.id, true);
  }

  markComplete(invoice: RR.Invoice) {
    this.subscription.add(
      this.invoiceEffect
        .update(invoice.id, { no_further_action_required: !invoice.no_further_action_required })
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
