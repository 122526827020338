import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PercentileBandRuleActions } from './percentile-band-rule.action';
import { PercentileBandRuleHttpService } from './percentile-band-rule.service';
import { PercentileBandRule } from './percentile-band-rule.service';

@Injectable()
export class PercentileBandRuleEffect {
  constructor(
    private store: Store<AppState>,
    private service: PercentileBandRuleHttpService,
    private message: MessageService,
  ) {}

  create(percentileBandRule: Partial<PercentileBandRule>) {
    return this.service.createPercentileBandRule(percentileBandRule).pipe(
      this.message.handleHttpErrorPipe,
      map((data: PercentileBandRule) => PercentileBandRuleActions.createSuccess({ percentile_band_rule: data })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(percentileBandRuleId: number) {
    return this.service.deletePercentileBandRule(percentileBandRuleId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => PercentileBandRuleActions.deleteSuccess({ percentile_band_rule_id: percentileBandRuleId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(percentileBandRuleId: number, changes: Partial<PercentileBandRule>) {
    return this.service.updatePercentileBandRule(percentileBandRuleId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((percentile_band_rule) => PercentileBandRuleActions.updateSuccess({ percentile_band_rule })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  getAll() {
    return this.service.getAllPercentileBandRules().pipe(
      this.message.handleHttpErrorPipe,
      map((data: PercentileBandRule[]) => PercentileBandRuleActions.searchSuccess({ percentile_band_rules: data })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
