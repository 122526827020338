import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { StatementActions } from '../statement/statement.action';
import { StatementSetActions, StatementSetBatchActions } from './statement-set.action';
import { StatementSetHttpService } from './statement-set.service';

@Injectable()
export class StatementSetEffect {
  constructor(
    private store: Store<AppState>,
    private service: StatementSetHttpService,
    private message: MessageService,
  ) {}

  /**
   * @deprecated use `findInTemplate` instead
   */
  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((statementSets: RR.StatementSet[]) => StatementSetActions.addMany({ statementSets })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findById(statementSetId: number) {
    return this.service.findById(statementSetId).pipe(
      this.message.handleHttpErrorPipe,
      map((statementSet: RR.StatementSet) => StatementSetActions.addOne({ statementSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(statementSet: Partial<RR.StatementSet>) {
    return this.service.create(statementSet).pipe(
      this.message.handleHttpErrorPipe,
      map(({ statementSet }) =>
        StatementSetBatchActions.createSuccessBatchAction({
          actions: {
            statementSetCreateSuccessAction: StatementSetActions.addOne({ statementSet }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(statementSetId: number, changes: Partial<RR.StatementSet>) {
    return this.service.update(statementSetId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map(({ statementSet, statements }) =>
        StatementSetBatchActions.updateSuccessBatchAction({
          actions: {
            statementSetUpdateSuccessAction: StatementSetActions.upsertOne({ statementSet }),
            statementUpdateManySuccessAction: StatementActions.upsertMany({ statements }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated unused
   */
  delete(statementSetId: number) {
    return this.service.delete(statementSetId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => StatementSetActions.removeOne({ statementSetId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      this.message.handleHttpErrorPipe,
      map((statementSets: RR.StatementSet[]) => StatementSetActions.addMany({ statementSets })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
