<span>
  <ng-container *ngIf="showModality">
    {{ bookingCode.modality }} - {{ bookingCode.name }} ({{ bookingCode.code }})
  </ng-container>
  <ng-container *ngIf="!showModality"> {{ bookingCode.code }} – {{ bookingCode.name }} </ng-container>

  <ng-container *ngIf="!hideIcon">
    <i *ngIf="bookingCode.doctor_onsite" title="Doctor required" class="fas fa-user-md text-danger me-1"></i>
    <i *ngIf="bookingCode.doctor_required" title="Injection required" class="fas fa-syringe text-danger me-1"></i>
    <i
      *ngIf="!bookingCode.doctor_required && bookingCode.injection"
      title="Might include injection"
      class="fas fa-syringe text-primary"
    ></i>
  </ng-container>
</span>
