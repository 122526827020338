<div>
  <div class="modal-header">
    <div>
      <h5 class="modal-title">Registration Questions (Y)</h5>
      <rr-report-headline [reportId]="report_id"></rr-report-headline>
    </div>
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body scroll">
    <rr-patient-questionnaires [report_id]="report_id" [viewonly]="true"></rr-patient-questionnaires>
  </div>
</div>
