<!--Bottom 3/3-->
<div class="m-3 d-flex flex-column justify-content-around">
  <div *ngIf="selectable" class="mb-2">
    <div>
      <h5 class="m-0"><strong>Prior Reports:</strong></h5>
      <p *ngIf="topic.other_imaging" [innerText]="topic.other_imaging"></p>
      <p *ngIf="!topic.other_imaging">No history added</p>
    </div>
    <button (click)="clearOtherImaging()" class="btn btn-sm btn-info">Clear Prior Reports</button>
  </div>

  <!--Related Topics-->
  <rr-prior-study-links
    [report]="report"
    [topic]="topic"
    [selectedTopicIds]="selectedTopicIds"
    [selectable]="selectable"
    [defaultMode]="defaultMode"
    (onSelectedTopicsChange)="selectedTopicIds = $event"
  ></rr-prior-study-links>
</div>
