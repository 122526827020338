import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { PercentileBandGroup } from './percentile-band-group.service';

const adapter = createEntityAdapter<PercentileBandGroup>();
const selectFeature = (state: AppState) => state.percentile_band_group;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectPercentileBandGroup = (id: number) =>
  createSelector(
    selectEntities,
    (percentile_band_groups: Dictionary<PercentileBandGroup>) => percentile_band_groups[id],
  );

export const fromPercentileBandGroup = {
  selectIds,
  selectEntities,
  selectAll,
  selectPercentileBandGroup,
};
