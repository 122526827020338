import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { TemplateService } from 'app/core/services/template.service';
import { CategoryEffect } from 'app/store/category/category.effect';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'rr-category-edit-modal',
  templateUrl: './category-edit-modal.component.html',
  styleUrls: ['./category-edit-modal.component.css'],
  standalone: true,
  imports: [SharedModule, FormsModule, AutoFocusDirective],
})
export class CategoryEditModalComponent implements OnInit, OnDestroy {
  @Input() category: RR.Category | null;
  @Input() statement_set_id: number;
  @Input() clone: boolean;

  categoryText: string;
  categoryTooltip: string;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
    private messageService: MessageService,
    private categoryEffect: CategoryEffect,
  ) {}

  ngOnInit() {
    this.categoryText = this.category ? this.category.text : '';
    // @ts-expect-error strictNullChecks
    this.categoryTooltip = this.category ? this.category.tooltip : '';
  }

  submitCategory() {
    const text = this.categoryText.trim().toUpperCase();
    if (!text) return;

    this.subscription.add(
      this.templateService
        .getCategories(this.statement_set_id)
        .pipe(
          take(1),
          switchMap((categories) => {
            // @ts-expect-error strictNullChecks
            const existingCat = categories.find((c) => c.text === text);
            if (!!existingCat && (!this.category || this.category.id !== existingCat.id || this.clone)) {
              this.messageService.add({
                message: 'Category already existed.',
                title: 'Error',
                type: 'danger',
                timeout: 5000,
              });
              return EMPTY; // Return an empty observable
            }

            if (!this.category || this.clone) {
              // Create
              return this.categoryEffect
                .create({ text, tooltip: this.categoryTooltip, statement_set_id: this.statement_set_id })
                .pipe(take(1));
            } else {
              // Update
              return this.categoryEffect
                .update(this.category.id, { text, tooltip: this.categoryTooltip })
                .pipe(take(1));
            }
          }),
        )
        .subscribe(() => this.activeModal.dismiss()),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, category: RR.Category | null, statement_set_id: number, clone?: boolean) {
    const modalRef = modalService.open(CategoryEditModalComponent, {
      size: 'lg',
      scrollable: true,
      centered: true,
    });
    modalRef.componentInstance.category = category;
    modalRef.componentInstance.statement_set_id = statement_set_id;
    modalRef.componentInstance.clone = clone;
    return modalRef;
  }
}
