import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { NoteCounter, ReportService } from 'app/core/services/report.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { NotesModalComponent } from 'app/shared/modals/notes-modal/notes-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { fromTodo } from 'app/store/report/todo';
import { fromUrgentNote } from 'app/store/report/urgent-note';
import { Observable, Subscription, map } from 'rxjs';

import { NoteTextComponent } from '../note-text/note-text.component';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, AutoFocusDirective, NoteTextComponent],
  selector: 'rr-report-notes-button',
  templateUrl: './report-notes-button.component.html',
  styleUrls: ['./report-notes-button.component.scss'],
})
export class ReportNotesButtonComponent implements OnInit, OnDestroy {
  @Input() report_id: number;
  @Input() topicId?: number;
  @Input() flash = true;
  @Input() btnClass = 'btn-outline-info';
  // Expand to full width
  @Input() expand = false;
  // Button size small by default
  @Input() small = true;
  @Input() autoFocus = false;
  @Input() inlineNotesPreview = false;

  subscription: Subscription = new Subscription();
  hasNotes: NoteCounter;
  report: RR.Report;
  todos$: Observable<RR.Todo[]>;
  urgentNotes$: Observable<RR.UrgentNotes[]>;

  todosSlice$: Observable<RR.Todo[]>;

  constructor(
    private store: Store<AppState>,
    private reportService: ReportService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.reportService.hasNotes(this.report_id).subscribe((val) => {
        this.hasNotes = val;
      }),
    );
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report_id))
        .pipe(filterDefined())
        .subscribe((r) => {
          this.report = r;
        }),
    );
    this.todos$ = this.store.select(fromTodo.selectInReport(this.report_id));
    this.urgentNotes$ = this.store.select(fromUrgentNote.selectInReport(this.report_id));

    this.todosSlice$ = this.todos$.pipe(map((todos) => todos.slice(0, 3)));
  }

  openNotesModal() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.report) {
      NotesModalComponent.open({ modalService: this.modal, report: this.report, topicId: this.topicId });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
