import { Component, Input } from '@angular/core';
import { NoContentDirective } from 'app/shared/directives/no-content.directive';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'rr-zero-fp-forms',
  standalone: true,
  imports: [SharedModule, NoContentDirective],
  templateUrl: './zero-fp-forms.component.html',
  styleUrls: ['./zero-fp-forms.component.css'],
})
export class ZeroFPFormsComponent {
  @Input() report: RR.Report;
  @Input() sidebar = false;
}
