import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { NgbAlertToast, Toast, VersionUpdateToast } from 'app/store/toast/toast.reducer';
import { filter, Observable, Subscription } from 'rxjs';

import { VersionUpdateToastComponent } from '../version-update-toast/version-update-toast.component';

@Component({
  selector: 'rr-toast',
  templateUrl: './toast.component.html',
  standalone: true,
  imports: [CommonModule, NgbAlert, VersionUpdateToastComponent],
})
export class ToastComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() toast: Toast;
  toast$: Observable<Toast>;
  versionUpdateToast?: VersionUpdateToast;
  alertToast?: NgbAlertToast;

  @Output() remove = new EventEmitter();

  subscription = new Subscription();

  ngOnInit(): void {
    const versionUpdateToast$ = this.toast$.pipe(
      filter((toast): toast is VersionUpdateToast => toast.type === 'VersionUpdateToastComponent'),
    );
    const alertToast$ = this.toast$.pipe(filter((toast): toast is NgbAlertToast => 'title' in toast));

    this.subscription.add(
      versionUpdateToast$.subscribe((toast) => {
        this.versionUpdateToast = toast;
      }),
    );
    this.subscription.add(
      alertToast$.subscribe((toast) => {
        this.alertToast = toast;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
