<div
  ngbDropdown
  (openChange)="onDropdownChange($event)"
  dropdownClass="dropdown-width"
  class="d-inline-block body-part"
>
  <ng-container *ngIf="bodyParts$ | async as bodyParts">
    <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
      Body Parts
      <div *ngIf="template" class="badge bg-primary">{{ template.body_parts.length }}</div>
      <div *ngIf="!template" class="badge bg-primary">{{ bodyParts.length }}</div>
    </button>
    <div class="w-100 p-2" ngbDropdownMenu>
      <div class="mb-2">
        <div permission [permission-only]="['template_manage']" class="input-group mb-2">
          <input
            #textInput
            rrVoice
            type="text"
            class="form-control"
            [formControl]="form.controls.search"
            placeholder="Add or search for body part..."
          />
          <button (click)="createBodyPart()" [disabled]="!form.controls.search.value" class="btn btn-outline-success">
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <cdk-virtual-scroll-viewport itemSize="10" class="dropdown-viewport" minBufferPx="600" maxBufferPx="900">
          <ul class="list-group">
            <li *cdkVirtualFor="let bodyPart of bodyParts; let i = index" class="list-group-item">
              <div class="form-check d-flex align-items-center justify-content-between">
                <div>
                  <input
                    class="form-check-input"
                    [type]="template ? 'checkbox' : 'radio'"
                    [id]="'bodyPart' + i"
                    name="bodyPart"
                    [checked]="
                      template ? (selectedBodyParts | includes: bodyPart.id) : bodyPart.id === selectedBodyPartId
                    "
                    (change)="updateTemplate(bodyPart, $event); $event.stopPropagation()"
                  />
                  <!-- in [checked] we have to compare ids because the effect updates the store each time -->
                  <label class="form-check-label" [for]="'bodyPart' + i">
                    {{ bodyPart.name }}
                  </label>
                </div>

                <div>
                  <span role="button" (click)="deleteBodyPart(bodyPart.id)">
                    <i class="fas fa-trash"></i>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </ng-container>
</div>
