import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type UserNotificationPayload = {
  text: string;
  recipient_id: number;
};

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private http: HttpClient) {}

  findAll() {
    return this.http.get<RR.UserNotification[]>(`/api/user_notification`);
  }

  create(notification: UserNotificationPayload) {
    return this.http.post<RR.UserNotification>(`/api/user_notification`, notification);
  }

  read(notificationId: number) {
    return this.http.post<RR.UserNotification>(`/api/user_notification/${notificationId}/read`, null);
  }
}
