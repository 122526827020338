import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type CreateTagSearchTerm = Pick<RR.TagSearchTerm, 'text'>;

@Injectable()
export class TagSearchTermHttpService {
  constructor(private http: HttpClient) {}

  findAll(templateId: number): Observable<RR.TagSearchTerm[]> {
    return this.http.get<RR.TagSearchTerm[]>(`/api/template/${templateId}/tag_search_term`);
  }

  createInTemplate(templateId: number, tagSearchTerm: CreateTagSearchTerm) {
    return this.http.post<{
      tag_search_term: RR.TagSearchTerm;
      template: RR.Template;
    }>(`/api/template/${templateId}/tag_search_term`, tagSearchTerm);
  }

  createInSubsection(subsectionId: number, tagSearchTerm: CreateTagSearchTerm) {
    return this.http.post<{
      tag_search_term: RR.TagSearchTerm;
      subsection: RR.Subsection;
    }>(`/api/subsection/${subsectionId}/tag_search_term`, tagSearchTerm);
  }

  /**
   * @param tagSearchTermId
   * @param changes
   * @returns tagSearchTerm
   */
  update(tagSearchTermId: number, changes: Partial<RR.TagSearchTerm>): Observable<RR.TagSearchTerm> {
    return this.http.put<RR.TagSearchTerm>(`/api/tag_search_term/${tagSearchTermId}`, changes);
  }

  delete(tagSearchTermId: number) {
    return this.http.delete(`/api/tag_search_term/${tagSearchTermId}`);
  }
}
