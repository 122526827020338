import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SectionChoiceActions } from './section-choice.action';

export type SectionChoiceState = EntityState<RR.SectionChoice>;

const adapter = createEntityAdapter<RR.SectionChoice>();

const initialState = adapter.getInitialState();

export const sectionChoiceReducer = createReducer(
  initialState,
  on(SectionChoiceActions.addMany, (state, { sectionChoices }) => adapter.addMany(sectionChoices, state)),
  on(SectionChoiceActions.upsertMany, (state, { sectionChoices }) => adapter.upsertMany(sectionChoices, state)),
  on(SectionChoiceActions.removeMany, (state, { sectionChoiceIds }) => adapter.removeMany(sectionChoiceIds, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
