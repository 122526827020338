import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbDropdownAnchor,
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbNavLinkBase,
  NgbNavContent,
  NgbNavOutlet,
} from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BindObservable, constructTagId, filterDefined, fixMaxHeight } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import {
  ESStatementSearch,
  ESStatementSearchHit,
  ESStatementSearchResponse,
  getSectionAttr,
  TemplateService,
} from 'app/core/services/template.service';
import { GLOBAL_SEARCH_VOICE_GROUP } from 'app/core/services/voice-recognition.service';
import { AppState } from 'app/store';
import { fromTagChoice, TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { fromStatementSet } from 'app/store/template/statement-set';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { VoiceRecognitionTextComponent } from '../../../../shared/components/voice-recognition/voice-recognition-text/voice-recognition-text.component';
import { VoiceRecognitionComponent } from '../../../../shared/components/voice-recognition/voice-recognition/voice-recognition.component';
import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { VoiceDirective } from '../../../../shared/directives/voice.directive';
import { SelectPipe } from '../../../../shared/pipes/select-pipe';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';

export type SectionFilterString = RR.TemplateSection | 'all';

@Component({
  selector: 'rr-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    VoiceDirective,
    TooltipDirective,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    VoiceRecognitionTextComponent,
    VoiceRecognitionComponent,
    NgbDropdownAnchor,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbNavLinkBase,
    NgbNavContent,
    ReactiveFormsModule,
    LetDirective,
    NgbNavOutlet,
    StoreSelectPipe,
    SelectPipe,
  ],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() openTopic: RR.Topic;
  openTopic$: Observable<RR.Topic>;
  // This strange method exists because of the ngbTypeahead API
  @ViewChild('globalSearchDropdown') globalSearchDropdown: NgbDropdown;
  @ViewChild('globalSearchInput') globalSearchInput: ElementRef;
  tabActiveId = 'tags-tab';
  statementResultHits: ESStatementSearchHit[] = [];
  statementCount: ESStatementSearch['aggregations'] | undefined;
  dividerResultHits: ESStatementSearchHit[] = [];
  dividerCount: ESStatementSearch['aggregations'] | undefined;
  statementSetResult: ESStatementSearchResponse['statement_set'] | undefined;
  searchSection = new FormControl<SectionFilterString>('all', { nonNullable: true });
  activeSection$ = new BehaviorSubject<SectionFilterString>(this.searchSection.value);
  searchText = '';
  savedGlobalSearches: string[];
  subscription = new Subscription();
  loading$ = new BehaviorSubject<boolean>(false);
  GLOBAL_SEARCH_VOICE_GROUP = GLOBAL_SEARCH_VOICE_GROUP;

  constructor(
    private templateService: TemplateService,
    private editorService: EditorService,
    private hotkeysService: HotkeysService,
    private cd: ChangeDetectorRef,
    private tagChoiceEffect: TagChoiceEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    const open_topic$ = this.openTopic$.pipe(
      filter((t) => !!t),
      distinctUntilChanged((a, b) => a.id === b.id),
    );
    this.subscription = open_topic$
      .pipe(
        map((t) => t.id),
        switchMap((topic_id) => this.editorService.getGlobalSearch(topic_id)),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((s) => {
        this.searchText = s;
      });

    this.subscription.add(
      open_topic$
        .pipe(
          switchMap((openTopic) => {
            return this.templateService.searchForSimilarStatement({
              text$: this.editorService.getGlobalSearch(openTopic.id),
              topic: openTopic,
              activeSection$: this.activeSection$.pipe(distinctUntilChanged()),
              statement_set_id: undefined,
              _loading$: this.loading$,
            });
          }),
        )
        .subscribe((result) => {
          if (!result) return;
          const { textHasChanged, response } = result;
          this.statementResultHits = response.statement.hits.hits;
          this.statementSetResult = response.statement_set;
          this.statementCount = response.statement.aggregations;
          this.dividerResultHits = response.divider.hits.hits;
          this.dividerCount = response.divider.aggregations;
          if (textHasChanged) {
            /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
            this.editorService.globalSearchTerm$.pipe(take(1)).subscribe((gs) => {
              if (gs && gs.source === 'GLOBAL') {
                this.openGlobalSearchDropdown();
              }
            });
          }
          this.cd.markForCheck();
        }),
    );

    // Listen to global search text from other components(prefill, tag search)
    this.subscription.add(
      this.editorService.globalSearchTerm$
        .pipe(
          debounceTime(200),
          filterDefined(),
          filter((e) => e.source !== 'GLOBAL'),
        )
        .subscribe((globalSearch) => {
          this.searchText = globalSearch.term;
          this.editorService.doGlobalSearch(this.openTopic.id, this.searchText);
        }),
    );

    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: '/' }).subscribe(() => {
        this.globalSearchInput.nativeElement.focus();
      }),
    );

    this.subscription.add(
      this.editorService.clearGlobalSearch$.subscribe((event) => {
        if (event) {
          this.clear();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.searchText = event.detail.term;
  }

  chooseAndFocusStatement(search_option: obj_literal) {
    if (search_option._source.region || search_option._source.is_divider) {
      this.focusStatementOnly(search_option);
      return;
    }

    this.editorService.publishFocus({ element_id: search_option._source.element_id });
    // @ts-expect-error noImplicitAny
    const attribute_option_objects = search_option._source.attribute_option_objects.map((object) => {
      return {
        attribute_option_id: object.attribute_option_id,
        text: object.text,
      };
    });
    const data = {
      topic_id: this.openTopic.id,
      element_id: search_option._source.element_id,
      statement_id: search_option._source.statement_id,
      region_id: undefined,
      attribute_option_objects,
    };

    this.editorService.chooseAndFocusStatement(data);
  }

  focusStatementOnly(search_option: obj_literal) {
    this.editorService.publishFocus({
      statement_id: search_option._source.statement_id,
      element_id: search_option._source.element_id,
    });
  }

  clear() {
    this.globalSearchDropdown.close();
    // Check clear works when dropdown is expanded before removing setTimeout
    this.editorService.saveGlobalSearch(this.openTopic.id, this.searchText);
    this.searchText = '';
    this.showSearchHistory();
    this.editorService.globalSearchTerm$.next({ source: 'GLOBAL', term: this.searchText });
  }

  showSearchHistory() {
    this.subscription.add(
      this.editorService.getSavedGlobalSearches(this.openTopic.id).subscribe((response) => {
        this.savedGlobalSearches = response;
      }),
    );
  }

  chooseHistory(search: string) {
    this.searchText = search;
    this.editorService.doGlobalSearch(this.openTopic.id, search);
    this.editorService.globalSearchTerm$.next({ source: 'GLOBAL', term: search });
  }

  clickSearchBox() {
    this.openGlobalSearchDropdown();
  }

  openGlobalSearchDropdown() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.globalSearchDropdown) return;
    this.globalSearchDropdown.open();
  }

  openChange(open: any) {
    if (open) {
      requestAnimationFrame(() => {
        // @ts-expect-error strictNullChecks
        fixMaxHeight(document.querySelector('.tab-content'));
      });
    }
  }

  onTextChange() {
    this.editorService.doGlobalSearch(this.openTopic.id, this.searchText);
    this.editorService.globalSearchTerm$.next({ source: 'GLOBAL', term: this.searchText });
  }

  gotoStatementSet(statementSet: any) {
    this.editorService.publishFocus({ element_id: statementSet._source.element_id });
  }

  clickSectionTab(section: SectionFilterString) {
    this.activeSection$.next(section);
  }

  getSectionTitle(name: string) {
    return getSectionAttr(name, 'title');
  }

  jumpToTag($event: any, divider: any) {
    $event.stopPropagation();
    this.editorService.publishFocus({
      statement_id: divider._source.statement_id,
      element_id: divider._source.element_id,
    });
    this.globalSearchDropdown.close();
  }

  onClickTag($event: any, divider: ESStatementSearchHit, isSelected: boolean) {
    $event.stopPropagation();
    if (!isSelected) {
      this.subscription.add(
        this.tagChoiceEffect
          .addTagChoice(this.openTopic.id, {
            tag_id: divider._source.statement_id,
            section: divider._source.section,
            subsection_id: divider._source.subsection_id,
            region_id: null,
          })
          .subscribe(),
      );
    } else {
      this.subscription.add(
        this.tagChoiceEffect
          .deleteTagChoice(
            this.openTopic.id,
            constructTagId(divider._source.statement_id, divider._source.subsection_id, null),
          )
          .subscribe(),
      );
    }
  }

  selectStatementSet = fromStatementSet.selectStatementSet;

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  tagSelectedSelectorFn = (divider: ESStatementSearchHit) => {
    // region_id is always null for tag selected from global search
    const tagId = constructTagId(divider._source.statement_id, divider._source.subsection_id, null);
    return this.store.select(fromTagChoice.isSelected(tagId));
  };
}
