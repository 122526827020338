import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { FollowupTaskActions } from './followup-task.action';

export type FollowupTaskState = EntityState<RR.FollowupTask>;

const adapter = createEntityAdapter<RR.FollowupTask>();

const initialState = adapter.getInitialState();

export const followupTaskReducer = createReducer(
  initialState,
  on(FollowupTaskActions.findManySuccess, (state: FollowupTaskState, { followupTasks }) =>
    adapter.upsertMany(followupTasks, state),
  ),
  on(FollowupTaskActions.createSuccess, (state: FollowupTaskState, { followupTask }) =>
    adapter.addOne(followupTask, state),
  ),
  on(FollowupTaskActions.updateSuccess, (state: FollowupTaskState, { followupTask }) =>
    adapter.upsertOne(followupTask, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
