import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.KeywordAbbreviation>();
const selectFeature = (state: AppState) => state.keyword_abbreviation;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = createSelector(selectFeature, (kwAbbr) => kwAbbr.loaded);
const selectKeywordAbbreviation = (id: number) => createSelector(selectEntities, (entities) => entities[id]);
export const fromKeywordAbbreviation = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
  selectKeywordAbbreviation,
};
