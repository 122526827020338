import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { EditNoteModalComponent } from 'app/shared/modals/notes-modal/edit-note-modal/edit-note-modal.component';
import { IncludesPipe } from 'app/shared/pipes/includes.pipe';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { PatientNoteCategoryEffect, fromPatientNoteCategory } from 'app/store/patient-note-category';
import { fromReferrerNoteCategory, ReferrerNoteCategoryEffect } from 'app/store/referrer-note-category';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
} from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, StoreSelectPipe, ReactiveFormsModule, NgbModule, EditNoteModalComponent, IncludesPipe],
  selector: 'rr-note-category',
  templateUrl: './note-category.component.html',
  styleUrls: ['./note-category.component.css'],
})
export class NoteCategoryComponent implements OnInit, OnDestroy {
  @Input() type: 'referrer' | 'patient' = 'referrer';
  @Output() onCategorySelect = new EventEmitter<RR.NoteCategory>();
  title = new FormControl<string>('', { nonNullable: true });
  focus$ = new Subject<string>();
  referrerNoteCategories$: Observable<RR.NoteCategory[]>;
  referrerNoteCategoriesTitles: string[];
  patientNoteCategories$: Observable<RR.NoteCategory[]>;
  patientNoteCategoriesTitles: string[];
  selectedCategory: RR.NoteCategory | undefined = undefined;

  subscription = new Subscription();

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  formatter = (c: RR.NoteCategory) => c.title;

  constructor(
    private store: Store<AppState>,
    private referrerNoteCategoryEffect: ReferrerNoteCategoryEffect,
    private patientNoteCategoryEffect: PatientNoteCategoryEffect,
    private messageService: MessageService,
  ) {
    this.referrerNoteCategories$ = this.store.select(fromReferrerNoteCategory.selectAll);
    this.patientNoteCategories$ = this.store.select(fromPatientNoteCategory.selectAll);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  searchCategories = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term === '') {
          return this.type === 'referrer' ? this.referrerNoteCategories$ : this.patientNoteCategories$;
        } else {
          return (this.type === 'referrer' ? this.referrerNoteCategories$ : this.patientNoteCategories$).pipe(
            map((categories) =>
              categories.filter((category) => category.title.toLowerCase().indexOf(term.toLowerCase()) > -1),
            ),
          );
        }
      }),
    );
  };

  ngOnInit(): void {
    if (this.type === 'referrer') {
      this.subscription.add(
        this.referrerNoteCategories$.subscribe((categories) => {
          this.referrerNoteCategoriesTitles = categories.map((c) => c.title);
        }),
      );
    } else {
      this.subscription.add(
        this.patientNoteCategories$.subscribe((categories) => {
          this.patientNoteCategoriesTitles = categories.map((c) => c.title);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectCategory(category: RR.NoteCategory) {
    this.selectedCategory = category;
    this.onCategorySelect.emit(category);
  }

  createNoteCategory() {
    const title = this.title.getRawValue();
    if (this.type === 'referrer') {
      this.subscription.add(
        this.referrerNoteCategoryEffect
          .create({ title })
          .pipe(take(1))
          .subscribe({
            next: (action) => {
              this.selectCategory(action.referrer_note_category);
              this.messageService.add({
                title: 'Success',
                message: 'Create Note Category',
                type: 'success',
              });
            },
          }),
      );
    } else {
      this.subscription.add(
        this.patientNoteCategoryEffect
          .create({ title })
          .pipe(take(1))
          .subscribe({
            next: (action) => {
              this.selectCategory(action.patient_note_category);
              this.messageService.add({
                title: 'Success',
                message: 'Create Note Category',
                type: 'success',
              });
            },
          }),
      );
    }
  }

  clearCategory() {
    this.title.reset();
    this.selectedCategory = undefined;
    this.onCategorySelect.emit(undefined);
  }
}
