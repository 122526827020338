import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Site
 *
 */
export const SiteActions = {
  findAllSuccess: createAction('[Site] Find All Success', props<{ sites: RR.Site[] }>()),

  upsertMany: createAction('[Site] Update Many Success', props<{ sites: RR.Site[] }>()),

  upsertOne: createAction('[Site] Update Success', props<{ site: RR.Site }>()),
};
