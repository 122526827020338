import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VoyagerUrlService } from 'app/core/services/voyager-url.service';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';

import { DueDateComponent } from '../../../components/datetime/due-date.component';
import { PatientInfoComponent } from '../../../components/patient-info/patient-info.component';
import { ReportNotesButtonComponent } from '../../../components/report-notes-button/report-notes-button.component';
import { ReportScanCodeComponent } from '../../../components/report-scan-code/report-scan-code.component';
import { ReportSiteComponent } from '../../../components/report-site/report-site.component';
import { AutoFocusDirective } from '../../../directives/auto-focus.directive';

@Component({
  selector: '[rr-next-report-row]',
  standalone: true,
  templateUrl: './next-report-row.component.html',
  styleUrl: './next-report-row.component.css',
  imports: [
    CommonModule,
    RouterModule,
    AutoFocusDirective,
    PatientInfoComponent,
    ReportScanCodeComponent,
    ReportSiteComponent,
    DueDateComponent,
    ReportNotesButtonComponent,
    ReportAccessionNumberComponent,
  ],
})
export class NextReportRowComponent {
  @Input() report: RR.Report;
  @Input() nextReportLabel: string;

  constructor(public voyagerUrlService: VoyagerUrlService) {}
}
