<div class="ms-1">
  <div
    #dropdownFrom="ngbDropdown"
    ngbDropdown
    placement="bottom-right"
    class="btn-group"
    (click)="$event.stopPropagation()"
  >
    <button ngbDropdownToggle class="btn btn-sm btn-light border">
      <span>Copy from</span>
    </button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" (click)="copyFromRegion('ALL_REGIONS'); dropdownFrom.close()">All Regions</button>
      <ng-container *ngFor="let r of regions">
        <button
          *ngIf="r.name !== region.name"
          class="dropdown-item"
          (click)="copyFromRegion(r.name); dropdownFrom.close()"
        >
          {{ r.name }}
        </button>
      </ng-container>
    </div>
  </div>

  <div
    #dropdownTo="ngbDropdown"
    ngbDropdown
    placement="bottom-right"
    class="btn-group ms-1"
    (click)="$event.stopPropagation()"
  >
    <button ngbDropdownToggle class="btn btn-sm btn-light border">
      <span>Copy to</span>
    </button>
    <div ngbDropdownMenu>
      <button class="dropdown-item" (click)="copyToRegion('ALL_REGIONS'); dropdownTo.close()">All Regions</button>
      <ng-container *ngFor="let r of regions">
        <button *ngIf="r.name !== region.name" class="dropdown-item" (click)="copyToRegion(r.name); dropdownTo.close()">
          {{ r.name }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
