import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavigationComponent } from 'app/shared/components/navigation/navigation.component';
import { NavigationService } from 'app/shared/components/navigation/navigation.service';
import { SharedModule } from 'app/shared/shared.module';

import { NavbarButtonsComponent } from '../navbar-buttons/navbar-buttons.component';

@Component({
  selector: 'rr-navbar',
  templateUrl: './toolbar-navbar.component.html',
  styleUrls: ['../editor-navbar/editor-navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, RouterLink, NavbarButtonsComponent, NavigationComponent],
})
export class NavbarComponent {
  rrConfig = rrConfig;

  constructor(public navigationService: NavigationService) {}

  toggleSideNavigation() {
    this.navigationService.open();
  }
}
