<div class="modal-header">
  <div>
    <h4 class="modal-title mb-1">(K) Key Findings/Other Imaging</h4>
    <div *ngIf="selectable">Do you want to add prior relevant studies to <strong>Other Imaging</strong>?</div>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="topic && report">
  <rr-add-other-imaging
    [report]="report"
    [topic]="topic"
    [defaultMode]="defaultMode"
    [selectable]="selectable"
  ></rr-add-other-imaging>
</div>
<div class="modal-footer d-flex">
  <button rrAutoFocus role="button" class="btn btn-secondary ms-auto" (click)="activeModal.dismiss()">
    <i class="fa fa-times"></i> Close
  </button>
</div>
