<div class="modal-header">
  <h4 class="modal-title">Next Most Urgent Case</h4>
  <button class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <rr-next-report [reportId]="reportId" *ngIf="loaded$ | async"></rr-next-report>

  <div *ngIf="(loaded$ | async) === false" class="d-flex justify-content-center loading mt-2">
    <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
  </div>
</div>
