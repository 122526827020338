import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromTemplate } from 'app/store/template/template';
import { of, Subscription } from 'rxjs';
import { finalize, mergeMap, switchMap, take } from 'rxjs/operators';

import { TemplateHeadlineComponent } from '../../../shared/components/template-headline/template-headline.component';
import { SharedModule } from '../../../shared/shared.module';
import { PrefillService } from '../prefill/prefill.service';
import { TopicDeleteModalComponent } from '../topic/topic-delete-modal.component';

@Component({
  selector: 'rr-topic-button',
  templateUrl: './topic-button.component.html',
  styleUrls: ['./topic-button.component.css'],
  standalone: true,
  imports: [CdkDrag, SharedModule, TemplateHeadlineComponent, CommonModule],
})
export class TopicButtonComponent implements OnInit, OnDestroy {
  @Input() topic: RR.Topic;
  openTopic: RR.Topic;
  template: RR.Template | undefined;

  // Copy history and techniques to another study
  copyingHistoryTechnique = false;

  subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private editorService: EditorService,
    private prefillService: PrefillService,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromCurrentTopic.selectTopic).subscribe((openTopic) => {
        // @ts-expect-error strictNullChecks
        this.openTopic = openTopic;
      }),
    );

    this.subscription.add(
      this.store.select(fromTemplate.selectTemplate(this.topic.template_id)).subscribe((t) => {
        this.template = t;
      }),
    );
  }

  promptRemove() {
    const modalRef = this.modalService.open(TopicDeleteModalComponent);
    const componentInstance: TopicDeleteModalComponent = modalRef.componentInstance;
    componentInstance.topic = this.topic;
    modalRef.result.then(
      () => this.removeStudy(this.topic.id),
      () => {
        /* dismissed */
      },
    );
  }

  removeStudy(topic_id: number) {
    this.reportService
      .removeTopic(topic_id)
      .pipe(
        mergeMap(() => this.reportService.getActiveTopics(this.topic.report_id)),
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((topics) => {
        if (topics.length) {
          this.setTopicOpen(topics[0].id);
        } else {
          this.router.navigate(['/report', this.topic.report_id, 'choose']);
        }
      });
  }

  setTopicOpen(topic_id: number) {
    if (topic_id === -1) {
      this.editorService.togglePrefill(false);
      this.editorService.toggleTemplateChooser(true);
    } else {
      this.editorService.setTopicOpen(topic_id);
    }
  }

  openPrefill() {
    this.prefillService.importTopicToPrefill(this.openTopic.id, this.topic.id.toString(), true);
  }

  copyAllChoices() {
    if (this.copyingHistoryTechnique) return;
    this.copyingHistoryTechnique = true;
    this.subscription.add(
      this.reportService
        .getVisibleFlatChoices(this.topic.id)
        .pipe(
          take(1),
          switchMap((choices) => {
            const filteredChoices = choices.filter((c): c is RR.StatementChoice => c != null);
            if (filteredChoices.length === 0) {
              return of(null);
            }
            return this.prefillService.copyChoices(
              this.openTopic.id,
              filteredChoices.map((c) => c.id),
              true,
            );
          }),
          finalize(() => {
            this.copyingHistoryTechnique = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
