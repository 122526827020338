import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'hasErrors',
})
export class HasErrorsPipe implements PipeTransform {
  transform(errorObj: { [key: string]: boolean } | undefined): boolean {
    if (!errorObj) {
      return false;
    }
    return Object.values(errorObj).some((error) => error);
  }
}
