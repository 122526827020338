import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromUser } from '../user/user/user.selector';

const adapter = createEntityAdapter<RR.BookingCode>();
const selectFeature = (state: AppState) => state.booking_code;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectBookingCode = (id: number) => createSelector(selectEntities, (bookingCodes) => bookingCodes[id]);
const selectLoaded = createSelector(selectFeature, (booking_code) => booking_code.loaded);
const selectActiveBookingCodes = createSelector(selectAll, (codes) => codes.filter((c) => !c.deleted));
// Select users who can perform the scan linked to booking code
const selectAssignees = (id: number) =>
  createSelector(
    selectBookingCode(id),
    fromUser.selectEntities,
    (bookingCode, users) => bookingCode?.users.map((uid) => users[uid]) || [],
  );

const selectBookingCodesFromUser = (user_id: number) =>
  createSelector(
    fromUser.selectUser(user_id),
    selectEntities,
    (user, bookingCodes) => user?.booking_codes.map((bc) => bookingCodes[bc]) || [],
  );
export const fromBookingCode = {
  selectIds,
  selectEntities,
  selectAll,
  selectBookingCode,
  selectLoaded,
  selectActiveBookingCodes,
  selectAssignees,
  selectBookingCodesFromUser,
};
