<ng-container *ngIf="preset$ | async as presets">
  <ng-container *ngIf="presets.reports.length > 0; else noPresets">
    <div class="d-flex align-items-center justify-content-between p-1">
      <small class="ms-1">{{ presets.reports.length }} results </small>
      <button
        rrTooltip="PRESET_TOP6"
        class="btn btn-sm btn-outline-dark"
        role="button"
        (click)="selectTopics()"
        [class.active]="selectedType === 'TOP6_PRESETS'"
      >
        Top 6 Presets
      </button>
    </div>

    <ng-container *ngFor="let report of presets.reports">
      <ng-container *ngIf="report.preset_title_id | storeSelectPipe: selectPresetTitle | async as presetTitle">
        <rr-prefill-row-preset
          [report]="report"
          [presetTitle]="presetTitle"
          [openTopicId]="openTopicId"
          [count]="preset.statement_counts[report.id]"
        >
        </rr-prefill-row-preset>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noPresets>
    <span class="ms-1">No results found</span>
  </ng-template>
</ng-container>
