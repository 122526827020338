import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService, FocusChoice } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { StatementEffect } from 'app/store/template/statement';
import { filter, Subscription } from 'rxjs';

import { LinkifyDirective } from '../../../shared/directives/linkify/linkify.directive';
import { SharedModule } from '../../../shared/shared.module';
import { EditType } from '../statement-edit/statement-edit.component';
import { StatementEditModalComponent } from '../statement/statement-edit-modal.component';

@Component({
  selector: 'rr-report-specific-choice,[rr-report-specific-choice]',
  templateUrl: './report-specific-choice.component.html',
  styleUrls: ['./report-specific-choice.component.css'],
  standalone: true,
  imports: [LinkifyDirective, CommonModule, SharedModule],
})
@LifecycleLogger
export class ReportSpecificChoiceComponent implements OnDestroy, OnInit {
  @Input() choice: RR.StatementChoice;
  @Input() element: RR.Element;
  @Input() topic: RR.Topic;
  @Input() region: RR.Region | undefined;
  @Input() subsection: RR.Subsection;
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD' = 'EDITOR';
  @Input() @HostBinding('class.list-group-item-legacy') legacy = false;
  @HostBinding('attr.title') get title() {
    return this.legacy ? 'This option is not present in future reports' : undefined;
  }

  @HostListener('mouseenter')
  onmouseenter() {
    this.hover = true;
  }
  @HostListener('mouseleave')
  onmouseleave() {
    this.hover = false;
  }
  hover = false;
  text: string;
  subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private editorService: EditorService,
    private statementEffect: StatementEffect,
    private modalService: NgbModal,
    protected el: ElementRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.reportService.getChoiceText(this.choice.id).subscribe((text) => {
        this.text = text;
      }),
    );

    this.subscription.add(
      this.editorService.focusEvents.pipe(filter((e): e is FocusChoice => e?.type === 'FocusChoice')).subscribe((e) => {
        if (e.statementChoiceId === this.choice.id) {
          this.el.nativeElement.focus();
          this.el.nativeElement.scrollIntoView({ block: 'center' });
          e.doneCallback();
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openEditor(editType: EditType) {
    // Temporarily save sentence to clipboard for sentence substitution later
    this.editorService.saveClipboardSentence(this.text);

    const { componentInstance } = StatementEditModalComponent.open(this.modalService);
    componentInstance.region = this.region;
    componentInstance.topic = this.topic;
    componentInstance.subsection = this.subsection;
    componentInstance.element = this.element;
    componentInstance.choice = this.choice;
    componentInstance.editType = editType;
    componentInstance.source = this.parent;
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  removeChoice = async () => {
    // Temporarily save recently deleted sentence
    this.editorService.saveClipboardSentence(this.text);
    this.reportService.removeStatementChoice(this.choice);
  };

  approveChoice() {
    this.subscription.add(
      this.statementEffect.approveChoice(this.choice.id, this.text).subscribe((action) => {
        // Focus it so doesn't get filtered in the Element
        this.editorService.publishFocus({
          statement_id: action.actions.statementCreateSuccessAction.statement.id,
          statement_choice_id: this.choice.id,
          target: this.parent,
        });
      }),
    );
  }
}
