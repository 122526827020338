import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store/app.state';
import { fromCurrentTopic, fromTopicChoices } from 'app/store/report/topic';
import { fromSubsection, SubsectionEffect } from 'app/store/template/subsection';
import { Observable, of, Subscription } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { IndexElementComponent } from './index-element.component';
import { IndexRegionComponent } from './index-region.component';

@Component({
  standalone: true,
  imports: [CommonModule, DragDropModule, IndexRegionComponent, IndexElementComponent],
  selector: 'rr-index-subsection',
  templateUrl: './index-subsection.component.html',
  styles: [
    `
      rr-index-element:first-of-type {
        margin-top: 0.5rem;
      }
      rr-index-element {
        padding-bottom: 0.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class IndexSubsectionComponent implements OnInit, OnDestroy {
  // We have a Subsection component for each subsection, this input determines
  // the subsection within the list of all subsections.
  @Input() section: RR.Section;
  @Input() subsection: RR.Subsection;

  @Input() @BindObservable() section_choice: RR.SectionChoice;
  section_choice$: Observable<RR.SectionChoice>;
  subsection_choice: RR.SubsectionChoice;
  regions: RR.Region[];

  // The current topic we are working with
  topic$: Observable<RR.Topic | undefined>;
  topicLoadedTopic$: Observable<RR.Topic>;
  // The elements within the subsection that has been chosen
  elements$: Observable<RR.Element[]>;
  active: boolean;

  trackBy = trackById;
  subscription = new Subscription();

  constructor(
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private subsectionEffect: SubsectionEffect,
    private selectorService: SelectorService,
  ) {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic);
    this.topicLoadedTopic$ = this.selectorService.selectCurrentTopicIfLoaded().pipe(filterDefined());
  }

  ngOnInit() {
    // The subsection is only defined through the input on initialisation,
    // hence we need to perform this assignment upon initialisation.
    this.elements$ = this.topic$.pipe(
      filterDefined(),
      switchMap((_) => this.store.select(fromSubsection.selectElements(this.subsection.id))),
    );

    const subsectionChoice$ = this.topicLoadedTopic$.pipe(
      switchMap((topic) =>
        this.store.select(fromTopicChoices.selectSubsectionChoices(topic.id, this.section.id, this.subsection.id)),
      ),
      map((subsectionChoices) => subsectionChoices[0]),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    this.subscription.add(
      subsectionChoice$.subscribe((subsection_choice) => {
        this.subsection_choice = subsection_choice;
        this.active = !!subsection_choice;
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      subsectionChoice$
        .pipe(
          switchMap((subsectionChoice) => {
            if (subsectionChoice && this.subsection.region_set_id != null) {
              return this.store.select(fromSubsection.selectRegions(subsectionChoice.subsection_id));
            } else {
              return of([]);
            }
          }),
        )
        .subscribe((regions) => {
          this.regions = regions;
          this.cd.detectChanges();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  drop(event: CdkDragDrop<RR.Subsection>) {
    if (event.previousContainer.data !== event.container.data) {
      // TODO: needs an API that doesn't have a `deleteFlag`
      console.error('Dropping in a different subsection is disabled');
      // Dropped in a different subsection
      const elements = [...event.previousContainer.data.elements];
      const otherElements = [...event.container.data.elements];
      transferArrayItem(elements, otherElements, event.previousIndex, event.currentIndex);
      this.subscription.add(
        this.subsectionEffect
          .updateMany([
            {
              // Removes the element from the previous subsection
              id: event.previousContainer.data.id,
              elements,
            },
            {
              // Adds the element to the other subsection
              id: event.container.data.id,
              elements: otherElements,
            },
          ])
          .subscribe(),
      );
    } else {
      // Reorder within the same subsection
      const newElements = [...event.container.data.elements];
      moveItemInArray(newElements, event.previousIndex, event.currentIndex);
      this.subscription.add(
        this.subsectionEffect
          .update(this.subsection.id, {
            elements: newElements,
          })
          .subscribe(),
      );
    }
  }

  onClick() {
    this.editorService.publishFocus({ subsection_id: this.subsection.id });
  }
}
