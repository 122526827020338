import { Pipe, PipeTransform } from '@angular/core';
import { getPatientShortInfo } from 'app/app.utils';

/**
 * @deprecated use PatientNameComponent instead
 */
@Pipe({
  standalone: true,
  name: 'patientShortInfo',
})
export class PatientShortInfoPipe implements PipeTransform {
  transform(report: RR.Report | undefined, patient: RR.Patient | undefined) {
    if (!patient || !report) {
      return false;
    }

    return getPatientShortInfo(report, patient);
  }
}
