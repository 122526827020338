import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { BodyPartActions } from './body-part.action';
import { BodyPartHttpService } from './body-part.service';

@Injectable()
export class BodyPartEffect {
  constructor(
    private store: Store<AppState>,
    private service: BodyPartHttpService,
    private message: MessageService,
  ) {}

  create(bodyPart: Partial<RR.BodyPart>) {
    return this.service.create(bodyPart).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.BodyPart) =>
        BodyPartActions.createSuccess({
          body_part: data,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(bodyPartId: number) {
    return this.service.delete(bodyPartId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.BodyPart) =>
        BodyPartActions.deleteSuccess({
          body_part_id: data.id,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(search: string) {
    return this.service.search(search).pipe(
      this.message.handleHttpErrorPipe,
      map((body_parts) => BodyPartActions.findAllSuccess({ body_parts })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(bodyPartId: number, changes: Partial<RR.BodyPart>) {
    return this.service.update(bodyPartId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((body_part) => BodyPartActions.updateSuccess({ body_part })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((body_parts) => BodyPartActions.findManySuccess({ body_parts })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
