import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SexSpecificWordHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.SexSpecificWord[]> {
    return this.http.get<RR.SexSpecificWord[]>('/api/sex_specific_word');
  }

  create(data: Partial<RR.SexSpecificWord>): Observable<RR.SexSpecificWord> {
    return this.http.post<RR.SexSpecificWord>('/api/sex_specific_word', data);
  }

  delete(sexSpecificWordId: number) {
    return this.http.delete<RR.SexSpecificWord>(`/api/sex_specific_word/${sexSpecificWordId}`);
  }
}
