<div class="mb-4">
  <div
    class="alert py-1 mb-2"
    [class.alert-success]="noError && (!allErrors || !allErrors.length)"
    [class.alert-warning]="!noError || (allErrors && allErrors.length)"
  >
    <h3 class="mb-0">
      {{ getTemplateName() | async }}
      <strong class="text-danger" *ngIf="getPositionOfTopic() | async as pos">{{ pos }}</strong>
    </h3>
  </div>
  <div *ngIf="noError && (!allErrors || !allErrors.length)" class="text-success">
    <span class="fa fa-lg fa-check-double"></span> There is no error in this study
  </div>
  <ng-template #loading
    ><span class="fa fa-lg fa-exclamation-triangle text-warning"> </span>
    <strong>Topic is loading...</strong></ng-template
  >
  <div class="errors" *ngIf="topicLoaded$ | async; else loading">
    <ng-container *ngIf="shouldAddOtherImaging$ | async as shouldAddOtherImaging">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      <span> There are <strong>prior procedures</strong> but they haven't been added to other imaging.</span>
    </ng-container>

    <div *ngIf="!(hasNecessaryKeyFindings$ | async)">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      There are <strong>no key findings</strong>.
    </div>

    <ng-container *ngIf="{ numberOfKeyFindings: numberOfKeyFindings$ | async } as data">
      <div *ngIf="data.numberOfKeyFindings !== null && data.numberOfKeyFindings > 5">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        There are <strong>too many key findings</strong> (more than 5).
      </div>
    </ng-container>

    <ng-container *ngIf="comments$ | async as comments">
      <div *ngIf="comments.length === 0" class="mb-1">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span role="button" class="clickable" (click)="jumpToSection('comment')">
          There are <strong>no comments</strong>. (click to jump)
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="impressionRecommendations$ | async as impressionRecommendations">
      <div *ngIf="impressionRecommendations.length === 0" class="mb-1">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span role="button" class="clickable" (click)="jumpToSection('impression_recommendations')">
          There are <strong>no impression and recommendations</strong>. (click to jump)
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="techniques$ | async as techniques">
      <div *ngIf="techniques.length === 0" class="mb-1">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span role="button" class="clickable" (click)="jumpToSection('technique')">
          There are <strong>no techniques</strong>. (click to jump)
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="unfilled$ | async as unfilled">
      <div *ngIf="unfilled.length > 0">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span *ngIf="unfilled.length > 1"> Numbers are empty</span>
        <span *ngIf="unfilled.length === 1"> Number is empty</span>
        (click to jump):
        <ul>
          <li
            *ngFor="let choice of unfilled"
            [innerHTML]="getChoiceText(choice.id) | async"
            role="button"
            class="clickable"
            (click)="publishChoiceFocus(choice)"
          ></li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="invalidNumberChoices$ | async as invalidNumberChoices">
      <div *ngIf="invalidNumberChoices.length > 0">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span *ngIf="invalidNumberChoices.length > 1"> Numbers are out of recommended range</span>
        <span *ngIf="invalidNumberChoices.length === 1"> Number is out of recommended range</span>
        (click to jump):
        <ul>
          <li
            *ngFor="let choice of invalidNumberChoices$ | async"
            [innerHTML]="getChoiceText(choice.id) | async"
            role="button"
            class="clickable"
            (click)="publishChoiceFocus(choice)"
          ></li>
        </ul>
      </div>
    </ng-container>

    <div *ngIf="!(topicHasTitle$ | async)">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      Missing title (click to jump):
      <ul>
        <li role="button" (click)="jumpToTitle()" class="clickable">
          {{ getTemplateName() | async }}
        </li>
      </ul>
    </div>
    <ng-container *ngIf="unresolvedTodos$ | async as todos">
      <div *ngIf="todos.length > 0">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span *ngIf="todos.length > 1"> The study has some unresolved notes</span>
        <span *ngIf="todos.length === 1"> The study has an unresolved note</span>
        (click to open):
        <ul>
          <li
            *ngFor="let todo of todos"
            [innerHTML]="todo.todo"
            role="button"
            (click)="openNotesModal()"
            class="clickable"
          ></li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="flaggedChoices$ | async as flaggedChoices">
      <div *ngIf="flaggedChoices.length > 0">
        <hr />
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span *ngIf="flaggedChoices.length > 1"> Statements are noted</span>
        <span *ngIf="flaggedChoices.length === 1"> Statement is noted</span>
        (click to jump):
        <ul>
          <li
            *ngFor="let choice of flaggedChoices$ | async"
            [innerHTML]="getChoiceText(choice.id) | async"
            role="button"
            (click)="publishChoiceFocus(choice)"
            class="clickable"
          ></li>
        </ul>
      </div>
    </ng-container>

    <div *ngIf="!!allErrors?.length">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      These statements may have errors (click to jump):
      <h6 *ngIf="!!errorComments.length">Comments</h6>
      <ul>
        <li
          class="clickable"
          *ngFor="let choice_obj of errorComments"
          role="button"
          (click)="publishChoiceFocus(choice_obj.choice)"
        >
          <span
            *ngIf="!!choice_obj.statement_ids && choice_obj.statement_ids.size === 2"
            class="fas fa-snowflake"
            (click)="dismissError(choice_obj.statement_ids); $event.stopPropagation()"
          ></span>
          <span [innerHTML]="getChoiceText(choice_obj.choice.id) | async"></span> | <i>{{ choice_obj.reason }}</i>
        </li>
      </ul>
      <h6 *ngIf="!!errorImpression.length">Impression</h6>
      <ul>
        <li
          class="clickable"
          *ngFor="let choice_obj of errorImpression"
          role="button"
          (click)="publishChoiceFocus(choice_obj.choice)"
        >
          <span
            *ngIf="!!choice_obj.statement_ids && choice_obj.statement_ids.size === 2"
            class="fas fa-snowflake"
            (click)="dismissError(choice_obj.statement_ids); $event.stopPropagation()"
          ></span>
          <span [innerHTML]="getChoiceText(choice_obj.choice.id) | async"></span> | <i>{{ choice_obj.reason }}</i>
        </li>
      </ul>
      <h6 *ngIf="!!errorHistory.length">History</h6>
      <ul>
        <li
          class="clickable"
          *ngFor="let choice_obj of errorHistory"
          role="button"
          (click)="publishChoiceFocus(choice_obj.choice)"
        >
          <span
            *ngIf="!!choice_obj.statement_ids && choice_obj.statement_ids.size === 2"
            class="fas fa-snowflake"
            (click)="dismissError(choice_obj.statement_ids); $event.stopPropagation()"
          ></span>
          <span [innerHTML]="getChoiceText(choice_obj.choice.id) | async"></span> | <i>{{ choice_obj.reason }}</i>
        </li>
      </ul>
      <h6 *ngIf="!!errorTechnique.length">Technique</h6>
      <ul>
        <li
          class="clickable"
          *ngFor="let choice_obj of errorTechnique"
          role="button"
          (click)="publishChoiceFocus(choice_obj.choice)"
        >
          <span
            *ngIf="!!choice_obj.statement_ids && choice_obj.statement_ids.size === 2"
            class="fas fa-snowflake"
            (click)="dismissError(choice_obj.statement_ids); $event.stopPropagation()"
          ></span>
          <span [innerHTML]="getChoiceText(choice_obj.choice.id) | async"></span> | <i>{{ choice_obj.reason }}</i>
        </li>
      </ul>
      <h6 *ngIf="!!errorFindings.length">Findings</h6>
      <ul>
        <li
          class="clickable"
          *ngFor="let choice_obj of errorFindings"
          role="button"
          (click)="publishChoiceFocus(choice_obj.choice)"
        >
          <span
            *ngIf="!!choice_obj.statement_ids && choice_obj.statement_ids.size === 2"
            class="fas fa-snowflake"
            (click)="dismissError(choice_obj.statement_ids); $event.stopPropagation()"
          ></span>
          <span [innerHTML]="getChoiceText(choice_obj.choice.id) | async"></span> | <i>{{ choice_obj.reason }}</i>
        </li>
      </ul>
    </div>

    <!--Check technician signature-->
    <div *ngIf="!(technicianSignatureText$ | async)" class="mb-1">
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      <hr />
      There is
      <strong>no {{ (templateModality$ | async) === 'US' ? 'sonographer' : 'radiographer' }} signature</strong>.
      <button
        *ngIf="checkTechnicianSignaturePermission() | async"
        rrTooltip="ADD_SIGNATURE"
        role="button"
        class="btn btn-secondary btn-sm"
        (click)="openSignature('TECHNICIAN')"
      >
        <span class="fa fa-file-signature"></span> Add
        {{ (templateModality$ | async) === 'US' ? 'sonographer' : 'radiographer' }} signature
      </button>
    </div>

    <div *ngIf="!(signatureText$ | async)">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span> There is
      <strong>no doctor signature</strong>.
      <button
        ngbAutoFocus
        *ngIf="isDoctorEditing() | async"
        rrTooltip="ADD_SIGNATURE"
        role="button"
        class="btn btn-secondary btn-sm"
        (click)="openSignature('DOCTOR')"
      >
        <span class="fa fa-file-signature"></span> Add doctor signature
      </button>
    </div>

    <div *ngIf="(signatureText$ | async) && !(currentUserHasSigned$ | async) && (isDoctorEditing() | async)">
      <hr />
      <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
      Signature does not have your name
      <button
        rrTooltip="ADD_SIGNATURE"
        role="button"
        class="btn btn-outline-danger btn-sm"
        (click)="openSignature('DOCTOR')"
      >
        <span class="fa fa-file-signature"></span> Sign
      </button>
    </div>
  </div>
  <rr-add-other-imaging [topic]="topic" [report]="report"></rr-add-other-imaging>
</div>
