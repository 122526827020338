import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { COMMAND_ACTIONS } from 'app/app.constants';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { SelectorService } from 'app/core/services/selector.service';
import { CommandOutput, VoiceInputTerm, VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { StructuredNoteLengthPipe } from 'app/shared/pipes/structured-note-pipe';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { TagActions } from 'app/store/prefill/tag';
import { fromCurrentReport } from 'app/store/report/report';
import { fromVoiceNote, VoiceNoteEffect } from 'app/store/report/voice-note';
import { fromUserSetting } from 'app/store/user/user-setting';
import { groupBy } from 'lodash-es';
import { combineLatest, delay, filter, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { TagCopyComponent } from '../tag-copy/tag-copy.component';
import { TagComponent } from '../tag/tag.component';
export interface GroupedTags {
  [subsection: string]: {
    [region: string]: RR.TagSuggestion[];
  };
}

type StructureCurrentTagMap = {
  [idx: number]: boolean;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    StructuredNoteLengthPipe,
    TagComponent,
    TagCopyComponent,
  ],
  selector: 'rr-prefill-tag-structure',
  templateUrl: './tag-structure.component.html',
  styleUrls: ['./tag-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagStructureComponent implements OnInit, OnDestroy {
  @Input() topicId: number;
  @Input() @BindObservable() structure: RR.StructuredTagSubsection[];
  structure$: Observable<RR.StructuredTagSubsection[]>;

  @Input() @BindObservable() topicTags: RR.Divider[];
  topicTags$: Observable<RR.Divider[]>;
  /**
   * Transform the input array to a grouped representation.
   *
   * The input to this structure is a list of TagSuggestions, however we want
   * to be able to display this information grouped by the Subsection and
   * Region that the tags belong to. This can be done as part of the input, by
   * assigning the result to the groupedTags property when we are done.
   *
   */
  @Input() @BindObservable() tags: RR.TagSuggestion[];
  tags$: Observable<RR.TagSuggestion[]>;

  @Input() section: RR.TagSearchSection;

  // View of the tags grouped by the subsections and regions they belong to.
  // This provides a quick method of accessing tags within specific subsections
  // or regions.
  groupedTags$: Observable<GroupedTags>;
  trackBy = trackById;
  selectedSubsection: string;
  regions: RR.StructuredTagRegion[];
  subsectionName: string;
  allRegionsActive: string[];
  subsectionIx = new FormControl<number>(0, { nonNullable: true });

  subscription = new Subscription();

  // A map to indicate whether structure's subsection has topic tags or not
  structureTopicTagMap$: Observable<StructureCurrentTagMap>;
  voiceMode: boolean;
  report$: Observable<RR.Report | undefined>;
  generalNotes: RR.VoiceNote[];
  structuredNotes: RR.StructuredNote[] = [];
  voiceMode$: Observable<boolean>;

  constructor(
    private voiceService: VoiceRecognitionService,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private editorService: EditorService,
    private selectorService: SelectorService,
    private voiceNoteEffect: VoiceNoteEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.structure$.pipe(filterDefined()).subscribe((structure) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        this.subsectionName = structure[this.subsectionIx.value].name ?? 'NO NAME';
        this.regions = structure[this.subsectionIx.value].regions;
        this.allRegionsActive = this.regions.map((_r, i) => `region-${i}`);
      }),
    );

    this.groupedTags$ = this.tags$.pipe(
      map((tags) => {
        const groupedTags: GroupedTags = {};
        for (const [key, value] of Object.entries(groupBy(tags, 'subsection_text'))) {
          groupedTags[key] = groupBy(value, 'region_text');
        }
        return groupedTags;
      }),
    );

    this.structureTopicTagMap$ = combineLatest([this.structure$, this.groupedTags$, this.topicTags$]).pipe(
      map(([structure, groupedTags, topicTags]) => {
        /* eslint-disable @typescript-eslint/no-unnecessary-condition */
        const structureTopicTagMap = {};
        // Construct structure topic tags map
        structure?.forEach((subsection, idx) => {
          const subsectionTags = groupedTags[subsection.name] || [];
          const regions = subsection.regions?.length ? subsection.regions : [{ name: 'null' }];
          // @ts-expect-error noImplicitAny
          structureTopicTagMap[idx] = !!regions?.find(
            (r) =>
              !!subsectionTags[r.name]?.find(
                (tag) => !!topicTags?.find((topicTag) => topicTag.statement_id === tag.tag_id),
              ),
          );
        });
        return structureTopicTagMap;
        /* eslint-enable @typescript-eslint/no-unnecessary-condition */
      }),
    );

    this.report$ = this.store.select(fromCurrentReport.selectReport);

    this.subscription.add(
      combineLatest([
        this.report$,
        this.voiceService.voiceTermSubject$.pipe(
          filter((voiceInput: VoiceInputTerm) => voiceInput.source === 'TAG_STRUCTURE'),
        ),
      ])
        .pipe(
          switchMap(([report, voiceInput]) => {
            this.store.dispatch(
              EditorActions.addVoiceSearchResult({
                term: voiceInput.term,
                note_type: 'structured',
                subsection: this.subsectionName,
              }),
            );
            if (!this.editorService.globalSearchTerm$.getValue()?.term) {
              this.copyToSearch(voiceInput.term);
            }
            if (report) {
              return this.voiceNoteEffect.create({
                report_id: report.id,
                text: voiceInput.term,
                note_type: 'structured',
                subsection: this.subsectionName,
              });
            }
            return of(null);
          }),
        )
        .subscribe(() => {
          this.cd.detectChanges();
        }),
    );

    this.subscription.add(
      this.report$
        // @ts-expect-error strictNullChecks
        .pipe(switchMap((report) => this.store.select(fromVoiceNote.selectInReport(report.id))))
        .subscribe((voiceNotes) => {
          this.generalNotes = voiceNotes.filter((note) => note.note_type === 'general');
          this.structuredNotes = voiceNotes
            .filter((note) => note.note_type === 'structured')
            .reduce<RR.StructuredNote[]>((acc, curr) => {
              const subsectionName = curr.subsection ?? 'NO NAME';
              if (acc.some((note) => note.subsection === subsectionName)) {
                acc.find((note) => note.subsection === subsectionName)?.notes.push(curr);
              } else {
                acc.push({ subsection: subsectionName, notes: [curr] });
              }
              return acc;
            }, []);
          this.cd.detectChanges();
        }),
    );

    this.subscription.add(
      this.voiceService.commandSubject$
        .pipe(filter((command: CommandOutput) => command.destination === 'TAG_STRUCTURE'))
        .subscribe(({ command }) => {
          if (command === COMMAND_ACTIONS.nextSubsection) {
            this.nextSubsection();
          }
          if (command === COMMAND_ACTIONS.previousSubsection) {
            this.previousSubsection();
          }
          this.cd.detectChanges();
        }),
    );

    this.voiceMode$ = this.selectorService.selectLoadedCurrentUser().pipe(
      filterDefined(),
      switchMap((user) =>
        this.store
          .select(fromUserSetting.selectUserSetting(user.id))
          .pipe(map((userSetting) => userSetting?.voice_recognition_features || false)),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  previousSubsection() {
    if (this.subsectionIx.value > 0) {
      this.voiceService.startSpeechSynth(this.structure[this.subsectionIx.value - 1].name);
      this.subsectionIx.setValue(this.subsectionIx.value - 1);
      this.tagsUpdate();
    }
  }

  nextSubsection() {
    if (this.subsectionIx.value < this.structure.length - 1) {
      this.voiceService.startSpeechSynth(this.structure[this.subsectionIx.value + 1].name);
      this.subsectionIx.setValue(this.subsectionIx.value + 1);
      this.tagsUpdate();
    }
  }

  copyToSearch(term: string) {
    this.editorService.globalSearchTerm$.next({ source: 'TAG', term });
  }

  dictateSpeech() {
    this.subscription.add(
      this.voiceMode$.pipe(delay(150), take(1)).subscribe((voiceMode) => {
        if (voiceMode) {
          this.filterBySubsectionVoiceNote(this.subsectionName);
          this.voiceService.startListening('TAG_STRUCTURE');
          this.voiceService.startSpeechSynth(this.subsectionName);
        }
      }),
    );
  }

  filterBySubsectionVoiceNote(subsectionName: string) {
    const subsectionNote = this.structuredNotes.find((result) => result.subsection === subsectionName);
    const searchTerm = this.editorService.globalSearchTerm$.value?.term;

    if (subsectionNote) {
      const startingPos = subsectionNote.notes.findIndex((result) => searchTerm === result.text);
      let nextTerm = subsectionNote.notes[0].text;
      if (startingPos >= 0 && startingPos < subsectionNote.notes.length - 1) {
        nextTerm = subsectionNote.notes[startingPos + 1].text;
      }
      if (nextTerm && !this.editorService.globalSearchTerm$.getValue()?.term) {
        this.store.dispatch(TagActions.search({ text: nextTerm }));
        this.editorService.globalSearchTerm$.next({ source: 'TAG', term: nextTerm });
      }
    }
  }

  onSubsectionChange() {
    this.tagsUpdate();
  }

  tagsUpdate() {
    /**
     * Name of the currently selected subsection.
     *
     * Provide an easy method of extracting the name of the subsection currently
     * selected within the form.
     *
     */
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    this.subsectionName = this.structure[this.subsectionIx.value].name ?? 'NO NAME';
    /**
     * The current regions within the form selection.
     *
     * This gets the names of the regions within the current subsection selected
     * within the form. The ordering of the regions is the same as within the
     * template. Providing a way to list the regions in the template ordering.
     *
     */
    this.regions = this.structure[this.subsectionIx.value].regions;
  }
}
