import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NoContentDirective } from 'app/shared/directives/no-content.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-urgent-button',
  standalone: true,
  imports: [SharedModule, NoContentDirective],
  templateUrl: './urgent-button.component.html',
  styleUrls: ['./urgent-button.component.css'],
})
export class UrgentButtonComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() reportId: number;
  @Input() btnClassName = '';
  @Input() btnSize: '' | 'btn-sm' | 'btn-lg' = 'btn-sm';
  @Input() btnColour = 'btn-primary';
  report$: Observable<RR.Report | undefined>;
  subscription = new Subscription();

  constructor(
    public reportEffect: ReportEffect,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  onClick(report: RR.Report) {
    this.subscription.add(this.reportEffect.update(report.id, { urgent: !report.urgent }).subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
