import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromRegionSet, RegionSetEffect } from 'app/store/template/region-set';
import { SubsectionEffect } from 'app/store/template/subsection';
import { of, Subscription, Observable } from 'rxjs';

import { EditRegionSetModalComponent } from './edit-region-set-modal.component';

@Component({
  selector: 'rr-region-set-modal',
  templateUrl: './region-set-modal.component.html',
  styleUrls: ['./region-set-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class RegionSetModalComponent implements OnInit, OnDestroy {
  @Input() subsection: RR.Subsection;
  @Input() topic: RR.Topic;

  regionSets$: Observable<RR.RegionSet[]>;
  selectedRegionSet: RR.RegionSet;
  subscription: Subscription = new Subscription();
  // Add New Region Set
  subsectionName = '';

  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    private store: Store<AppState>,
    private regionSetEffect: RegionSetEffect,
    private subsectionEffect: SubsectionEffect,
  ) {}

  ngOnInit() {
    // Fetch all region set if they are haven't been fetched
    this.subscription.add(this.regionSetEffect.findAll().subscribe());
    this.regionSets$ = this.store.select(fromRegionSet.selectAll);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getRegions(): Observable<RR.Region[]> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.selectedRegionSet) return of([]);
    // @ts-expect-error strictNullChecks
    return this.store.select(fromRegionSet.selectRegions(this.selectedRegionSet.id));
  }

  okClicked() {
    // Create new subsection with selected region set
    const newSubsection: Partial<RR.Subsection> = {
      name: this.getSubsectionName(),
      region_set_id: this.selectedRegionSet.id,
    };
    // TODO(section): create region is broken anyway, not fixing
    this.subscription.add(
      // @ts-expect-error strictNullChecks
      this.subsectionEffect.splitSubsection(this.section, this.subsection, null, newSubsection).subscribe(),
    );
    this.activeModal.dismiss();
  }

  cancelClicked() {
    this.activeModal.dismiss();
  }

  getSubsectionName() {
    if (this.subsectionName) return this.subsectionName.toUpperCase();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.selectedRegionSet ? this.selectedRegionSet.name.toUpperCase() : 'NO NAME';
  }

  manageRegionSet() {
    this.modal.open(EditRegionSetModalComponent, {
      size: 'lg',
      keyboard: false,
    });
  }
}
