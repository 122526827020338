import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromStatementSet } from '../statement-set/statement-set.selector';
import { fromSubsection } from '../subsection/subsection.selector';

const adapter = createEntityAdapter<RR.Element>();

const selectFeature = (state: AppState) => state.element;

// The @ngrx/entity adapter provides a number of selectors for us
const {
  // Easiest way to see what these do is to look at the source:
  // https://github.com/ngrx/platform/blob/83bb8a9e4c6492470e5a13e0a93949f0a1acbe79/modules/entity/src/state_selectors.ts#L12
  selectIds, // state.ids
  selectEntities, // state.entities
  selectAll, // ids.map((id) => entities[id])
  // selectTotal, // ids.length
} = adapter.getSelectors(selectFeature);

/**
 * Read about createSelector [here](https://ngrx.io/guide/store/selectors).
 *
 * These functions are memoized so it won't run the projection function if the same input have been seen before. This
 * has performance benefits, but in this case the computation is trivial.
 *
 * So it could be rewritten it without createSelector like this
 * ```ts
 * const selectLoaded = (state: AppState) => selectFeature(state).loaded
 * ```
 *
 * Fully expanded:
 * ```ts
 * const selectLoaded = (state: AppState) => state.element.loaded
 * ```
 */

const selectElement = (elementId: number) =>
  createSelector(selectEntities, (elements: Dictionary<RR.Element>) => elements[elementId]);

const selectStatementSet = (elementId: number) =>
  createSelector(
    selectElement(elementId),
    fromStatementSet.selectEntities,
    (element: RR.Element | undefined, statementSets: Dictionary<RR.StatementSet>) =>
      (element?.statement_set_id && statementSets[element.statement_set_id]) || undefined,
  );

const selectSubsection = (elementId: number) =>
  createSelector(
    selectElement(elementId),
    fromSubsection.selectEntities,
    (element: RR.Element | undefined, subsections: Dictionary<RR.Subsection>) =>
      (element?.subsection_id && subsections[element.subsection_id]) || undefined,
  );

const selectGlobalElements = createSelector(selectAll, (elements) => elements.filter((e) => e.type === 'notepad'));

/**
 * Select global statement sets
 */
const selectGlobalStatementSets = createSelector(
  selectGlobalElements,
  fromStatementSet.selectEntities,
  (elements, statementSetEntities) =>
    elements.map((e) => statementSetEntities[e.statement_set_id]).filter((ss): ss is RR.StatementSet => !!ss),
);

export const fromElement = {
  selectIds,
  selectEntities,
  selectAll,
  selectElement,
  selectStatementSet,
  selectSubsection,
  selectGlobalElements,
  selectGlobalStatementSets,
};
