import { createAction, props } from '@ngrx/store';

/**
 * Methods of interacting with the RegionSet
 *
 * This describes the actions required to interface with the region sets, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the region sets related to a specific template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of region sets returned, while the
 * `getTemplateSuccess` being a subset of all region sets will only update the
 * values without deleting them all first.
 */
export const RegionSetActions = {
  findAllSuccess: createAction('[RegionSet] Find All Success', props<{ regionSets: RR.RegionSet[] }>()),

  createSuccess: createAction('[RegionSet] Create Success', props<{ regionSet: RR.RegionSet }>()),

  updateSuccess: createAction('[RegionSet] Update Success', props<{ regionSet: RR.RegionSet }>()),

  deleteSuccess: createAction('[RegionSet] Delete Success', props<{ regionSetId: number }>()),

  findInTemplateSuccess: createAction(
    '[RegionSet] Find In Template Success',
    props<{ templateId: number; regionSets: RR.RegionSet[] }>(),
  ),

  createRegionSetRegionsSuccess: createAction('[RegionSet] Create Region Set and Regions Success'),

  reset: createAction('[RegionSet] Reset'),
};
