<ng-container *ngIf="loading">
  <div class="d-flex justify-content-center align-items-center h-100">
    <i class="fa fa-spinner fa-spin fa-2x text-secondary"></i>
  </div>
</ng-container>
<div *ngIf="!loading && suggestedStatements" class="d-flex flex-column scroll-y">
  <ng-container *ngFor="let suggestedStatement of suggestedStatements">
    <div class="list-group d-flex flex-column">
      <div *ngIf="suggestedStatement.statement">
        <rr-recommended-statement [statement]="suggestedStatement.statement" [topic]="topic"></rr-recommended-statement>
      </div>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="!loading && !suggestedStatements">
  <div class="d-flex justify-content-center align-items-center h-100 text-secondary">
    Report has no recommended statements
  </div>
</ng-container>
