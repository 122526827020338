import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { PatientNoteSearchFilterForm } from 'app/modules/entity-listing/components/patient-note-listing/patient-note-listing-search/patient-note-listing-search.component';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PatientNoteCategoryActions } from '../patient-note-category/patient-note-category.action';
import { PatientActions } from '../patient/patient.action';
import { PatientSearchResponse } from '../patient/patient.service';
import { PatientNoteActions, PatientNoteBatchActions } from './patient-note.action';
import { CreatePatientNoteResponse, PatientNoteHttpService } from './patient-note.service';

@Injectable()
export class PatientNoteEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientNoteHttpService,
    private message: MessageService,
  ) {}

  create(patientNote: Partial<RR.PatientNote>) {
    return this.service.create(patientNote).pipe(
      this.message.handleHttpErrorPipe,
      map((data: CreatePatientNoteResponse) =>
        PatientNoteBatchActions.createSuccess({
          actions: {
            createPatientNoteSuccess: PatientNoteActions.createSuccess({
              patient_note: data.patient_note,
            }),
            updatePatientSuccess: PatientActions.updateSuccess({ patient: data.patient }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(patientNoteId: number) {
    return this.service.delete(patientNoteId).pipe(
      this.message.handleHttpErrorPipe,
      map((data) =>
        PatientNoteBatchActions.deleteSuccess({
          actions: {
            deletePatientNoteSuccess: PatientNoteActions.deleteSuccess({
              patient_note_id: patientNoteId,
            }),
            updatePatientSuccess: PatientActions.updateSuccess({ patient: data.patient }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(patientNoteId: number, changes: Partial<RR.PatientNote>) {
    return this.service.update(patientNoteId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((patient_note) => PatientNoteActions.updateSuccess({ patient_note })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: PatientNoteSearchFilterForm, params: HttpParams) {
    return this.service.searchPatientNotes(queryBody, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response: PatientSearchResponse) =>
        PatientNoteBatchActions.searchSuccess({
          actions: {
            searchPatientNoteSuccess: PatientNoteActions.searchSuccess({
              searchResult: response,
            }),
            findManyPatientSuccess: PatientActions.findManySuccess({ patients: response.patients }),
            findManyPatientNoteCategorySuccess: PatientNoteCategoryActions.findManySuccess({
              patient_note_categories: response.patient_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
