<ng-container *ngIf="relatedReports && relatedReports.length > 0">
  <table class="rr-report-list table table-sm table-striped table-hover mt-1 w-100">
    <thead>
      <tr>
        <th [style.width.%]="20">Accession No.</th>
        <th [style.width.%]="20">Study type</th>
        <th [style.width.%]="20">Report title</th>
        <th [style.width.%]="20">Signed Sent</th>
        <th [style.width.%]="20">Actions</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let report of relatedReports">
        <tr
          rr-related-report-row
          [report]="report.report"
          [editorExpandedState]="editorExpandedState"
          [selectable]="selectable"
        ></tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
