import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { TagActions } from './tag.action';
import { TagHttpService, TagSuggestionsInfo } from './tag.service';

@Injectable()
export class TagEffect {
  constructor(
    private store: Store<AppState>,
    private service: TagHttpService,
  ) {}

  loadSuggested(topicId: number, section: RR.TagSearchSection) {
    this.store.dispatch(TagActions.toggleSuggestedLoading({ section }));

    return this.service.getSuggested(topicId, section).pipe(
      map((tagSuggestionInfo: TagSuggestionsInfo) =>
        TagActions.loadSuggestedSuccess({
          section,
          suggestedTags: tagSuggestionInfo.tags,
          isMLSuggested: tagSuggestionInfo.is_ml,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  loadStructure(topicId: number, section: RR.TagSearchSection) {
    this.store.dispatch(TagActions.toggleStructureLoading({ section }));
    return this.service.getTemplateStructure(topicId, section).pipe(
      map((structure: RR.StructuredTagSubsection[]) => TagActions.loadStructureSuccess({ section, structure })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
