<div class="modal-header">
  <h5 class="modal-title">Warning: No Title Selected</h5>
</div>
<div class="modal-body">
  <h4>The report title is missing. Would you like to skip selecting a title?</h4>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="close()">Skip Selecting Title</button>
</div>
