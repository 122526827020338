<div ngbDropdown dropdownClass="dropdown-width" class="d-inline-block">
  <button rrTooltip="SOFTWARE_PREFERENCES" type="button" class="btn btn-outline-primary" ngbDropdownToggle>
    Software Preferences
    <div class="badge bg-primary">
      {{ referrer.software_preference_ids.length }}
    </div>
  </button>
  <div class="w-100 p-2" ngbDropdownMenu>
    <div class="mb-2">
      <ul class="list-group">
        <li *ngFor="let softwarePrefForm of softwarePreferencesForm.controls; let i = index" class="list-group-item">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [id]="'softwarePref' + i"
              [formControl]="softwarePrefForm.controls.selected"
              (change)="updateReferrer()"
            />
            <label class="form-check-label" [for]="'softwarePref' + i">
              {{ softwarePrefForm.controls.softwarePref.value.software_name }}
            </label>
          </div>
        </li>
      </ul>
    </div>

    <div permission [permission-only]="['data_management']" class="input-group">
      <input type="text" class="form-control" [formControl]="softwareName" placeholder="Add software name..." />
      <button (click)="createPref()" class="btn btn-outline-success">
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>
</div>
