import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AbbreviationDirective } from 'app/shared/directives/abbreviation/abbreviation.directive';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { AppState } from 'app/store';
import { fromTag, TagActions } from 'app/store/prefill/tag';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AbbreviationDirective, VoiceDirective, AutoFocusDirective],
  selector: 'rr-keyword-search',
  templateUrl: './keyword-search.component.html',
  styleUrls: ['./keyword-search.component.css'],
})
export class KeywordSearchComponent implements OnInit, OnDestroy {
  @Input() templateId: number;
  searchText = new FormControl('', { nonNullable: true });

  subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private editorService: EditorService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromTag.selectFilterText).subscribe((text) => this.searchText.setValue(text)),
    );

    // Initialise searchText with global search value.
    this.subscription.add(
      this.editorService.globalSearchTerm$.subscribe((response) => {
        this.searchText.setValue(response?.term ?? '');
      }),
    );

    /**
     * Perform a global search on the Tags.
     *
     * This will perform a fuzzy filtering of all the tags, whether in the
     * Structured or list view. This searches all the text within the tag
     * including region, and subsection.
     */
    this.subscription.add(
      this.searchText.valueChanges.pipe(debounceTime(200), distinctUntilChanged()).subscribe((text) => {
        this.store.dispatch(TagActions.search({ text }));
        this.editorService.globalSearchTerm$.next({ source: 'TAG', term: text });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clearField() {
    this.searchText.setValue('');
  }
}
