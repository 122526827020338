import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../../batching.action';
import { TopicActions } from '../topic/topic.action';

const findManySuccess = createAction(
  '[Justification] Find Many Success',
  props<{ justifications: RR.Justification[] }>(),
);
const createSuccess = createAction('[Justification] Create Success', props<{ justification: RR.Justification }>());
const deleteSuccess = createAction('[Justification] Delete Success', props<{ justificationId: number }>());

const createSuccessBatch = createBatchAction(
  '[Justification] Create Success Batch',
  props<{
    actions: {
      justificationCreateSuccess: ReturnType<typeof createSuccess>;
      topicUpdateSuccess: ReturnType<typeof TopicActions.upsertOne>;
    };
  }>(),
);

const deleteSuccessBatch = createBatchAction(
  '[Justification] Delete Success Batch',
  props<{
    actions: {
      justificationDeleteSuccess: ReturnType<typeof deleteSuccess>;
      topicUpdateSuccess: ReturnType<typeof TopicActions.upsertOne>;
    };
  }>(),
);

export const JustificationActions = {
  findManySuccess,
  createSuccess,
  createSuccessBatch,
  deleteSuccess,
  deleteSuccessBatch,
};
