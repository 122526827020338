<div class="modal-header">
  <h5 class="modal-title">Statement number</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<form [formGroup]="form" (ngSubmit)="close()">
  <div class="modal-body">
    <div>
      <label class="form-label">
        Lower
        <span class="ms-1" *ngIf="text_object?.auto_lower">(Recommended lower: {{ text_object?.auto_lower }})</span>
        <input
          ngbAutoFocus
          formControlName="lower"
          type="number"
          class="form-control"
          [class.is-invalid]="form.errors?.bounds && (form.touched || form.dirty)"
        />
      </label>
    </div>
    <div>
      <label
        >Upper
        <span class="ms-1" *ngIf="text_object?.auto_upper">(Recommended upper: {{ text_object?.auto_upper }})</span>
        <input
          formControlName="upper"
          type="number"
          class="form-control"
          [class.is-invalid]="form.errors?.bounds && (form.touched || form.dirty)"
        />
      </label>
    </div>

    <div>
      <label
        >Formula
        <input formControlName="formula" type="text" class="form-control" />
      </label>
    </div>
    <div *ngIf="form.errors?.bounds && (form.touched || form.dirty)" class="alert alert-danger mt-1">
      <em>Lower</em> bound should be less than <em>upper</em> bound.
    </div>
    <div *ngIf="openNumberModalSource === 'statementEditor'">
      <div>
        <label>
          Round to nearest whole number:
          <input formControlName="round_to_nearest" type="checkbox" />
        </label>
      </div>

      <div>
        <label>
          Exclude statement if value is out of bounds:
          <input formControlName="exclude_if_outside_range" type="checkbox" />
        </label>
      </div>

      <div>
        <label>
          Include statement only if value is out of bounds:
          <input formControlName="include_if_outside_range" type="checkbox" />
        </label>
      </div>
    </div>

    <div class="modal-footer border-top-0">
      <button class="btn btn-success" type="submit">Save</button>
      <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
    </div>

    <ng-container *ngIf="report_id">
      <hr />

      <div>
        <h4>Dicom SR</h4>
        <label>
          Linked to:
          <div *ngIf="measurementRuleSTOs.length > 0">
            <div *ngFor="let mr_sto of measurementRuleSTOs">
              <rr-measurement-rule [measurement_rule]="mr_sto.measurement_rule"></rr-measurement-rule>
              <span (click)="removeLinkedMeasurementRules(mr_sto)" role="button" class="ms-2"
                ><i class="text-danger fas fa-trash-alt"></i
              ></span>
            </div>
          </div>
          <div *ngIf="measurementRuleSTOs.length === 0">None</div>
        </label>
      </div>

      <div *ngIf="!measurementsSet && (measurementsLoading$ | async)">Loading...</div>

      <rr-measurement-dropdown
        [measurements]="measurements"
        (onChoose)="chooseMeasurement($event)"
        *ngFor="let measurements of measurementsSet"
      ></rr-measurement-dropdown>

      <hr />

      <div>
        <h4>Image Similarity Parameters</h4>
        <label>
          Linked to:
          <div *ngIf="parameterTextObject">
            <span>{{ parameterTextObject.imgsim_parameter.name }}</span>
            <span (click)="removeParameter(parameterTextObject)" role="button" class="ms-2"
              ><i class="text-danger fas fa-trash-alt"></i
            ></span>
          </div>
          <div *ngIf="!parameterTextObject">None</div>
        </label>
      </div>

      <div *ngIf="!parameters">Loading...</div>

      <rr-parameter-dropdown [parameters]="parameters" (onChoose)="chooseParameter($event)"></rr-parameter-dropdown>
    </ng-container>
  </div>
</form>
