<div class="d-flex flex-column w-100" [class.text-danger]="billing_item.deleted">
  <p class="mb-1">
    <strong>{{ billing_item.item_code }}</strong
    ><ng-container *ngIf="displayNotes"> - {{ billing_item.notes }}</ng-container>
    <strong *ngIf="billing_item.deleted">(DELETED)</strong>
  </p>
  <div class="d-flex" *ngIf="displayFees">
    <table class="table">
      <thead>
        <tr>
          <th [style.width.%]="20">Medicare (100%)</th>
          <th [style.width.%]="30">Private Fee (Medicare card holder)</th>
          <th [style.width.%]="10">DVA</th>
          <th [style.width.%]="10">BUPA</th>
          <th [style.width.%]="30">Third party, CTP, work cover</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <ng-container *ngIf="billing_item.medicare_fee > 0">${{ billing_item.medicare_fee }}</ng-container>
            <ng-container *ngIf="billing_item.medicare_fee <= 0">Not eligible</ng-container>
          </td>
          <td>${{ billing_item.private_fee }}</td>
          <td>${{ billing_item.dva_fee }}</td>
          <td>${{ billing_item.bupa_fee }}</td>
          <td>${{ billing_item.other_fee }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
