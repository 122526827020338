<!-- 
  .modal is important because bootstrap defines variables that .modal-header etc use
  .show makes the modal display. Normally this property is controlled by ng-bootstrap
-->
<div class="modal screenshot-modal shadow rounded bg-white" [class.show]="show" [style.width]="width + 'px'">
  <div
    class="modal-header cursor-move"
    cdkDrag
    cdkDragHandle
    cdkDragRootElement=".screenshot-modal"
    [cdkDragFreeDragPosition]="dragPosition"
  >
    <h5 class="modal-title">Submit an Issue with RadReport</h5>
    <button role="button" class="btn-close" (click)="dismiss()"></button>
  </div>
  <div class="modal-body">
    <p class="mb-1">
      For <strong>Voyager</strong> issues: visit their
      <a href="https://www.intellirad.com.au/contact-us/">Website</a> or
      <a href="https://voyagerpacs.atlassian.net/servicedesk/customer/portal/1">Service Desk</a> for support.
    </p>
    <p class="mb-1">
      For <strong>IT, Network, or Maintenance</strong> issues: raise a Cool Networks ticket by clicking
      <img src="/assets/cn-favicon.png" width="23" class="align-top" /> in the Windows taskbar.
    </p>
    <p class="mb-3">For issues with <strong>wording of Templates</strong>: contact your supervisor.</p>
    <form class="bootstrap-validate" [formGroup]="issueForm" autocomplete="off">
      <div class="form-group mb-2">
        <input
          rrAutoFocus
          class="form-control"
          type="text"
          [placeholder]="PLACEHOLDER1"
          [title]="PLACEHOLDER1"
          (keydown.enter)="onEnter1($event)"
          [formControl]="issueForm.controls.title"
          required
          maxlength="255"
        />
      </div>
      <div class="form-group mb-2">
        <textarea
          #textInput2
          class="form-control"
          [placeholder]="PLACEHOLDER2"
          [title]="PLACEHOLDER2"
          [formControl]="issueForm.controls.description"
          rrAutoSize
        ></textarea>
      </div>
    </form>
    <button class="btn btn-primary mb-2" (click)="onClickScreenshot()">
      <i class="fas fa-camera"></i> Capture Screenshot
    </button>
    <img
      *ngIf="screenshotBlobUrl"
      class="screenshot-thumbnail d-block border shadow-sm mb-1"
      [src]="screenshotBlobUrl"
    />
    <div class="mt-3">
      <a
        routerLink="/issues"
        role="button"
        class="btn btn-info"
        (click)="dismiss()"
        rrTooltip="GITHUB_ISSUES_OPEN_PAGE"
      >
        <i class="fas fa-tasks"></i> Open Radreport Issues
      </a>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-success"
      role="button"
      (click)="onSubmit()"
      [disabled]="issueForm.invalid || loading || loadingImage"
    >
      Submit issue
      <div
        class="spinner-border spinner-border-sm"
        [class.d-none]="!(loading || loadingImage)"
        [class.d-inline-block]="loading || loadingImage"
      ></div>
    </button>
    <button class="btn btn-light" role="button" (click)="dismiss()">Cancel</button>
  </div>
</div>
