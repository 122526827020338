import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ExactMatchTopicActions } from './exact-match-topic.action';

export interface ExactMatchTopicState extends EntityState<RR.ExactMatchTopic> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.ExactMatchTopic>({
  selectId: (exactMatchTopic: RR.ExactMatchTopic) => exactMatchTopic.topic_id,
});

const initialState = adapter.getInitialState({ loaded: false });

export const ExactMatchTopicReducer = createReducer(
  initialState,
  on(ExactMatchTopicActions.findAllSuccess, (state, { exact_matches_topic }) =>
    adapter.setAll(exact_matches_topic, { ...state, loaded: true }),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
