import { Pipe, PipeTransform } from '@angular/core';
import { getPatientAgeAndSex } from 'app/app.utils';

@Pipe({
  standalone: true,
  name: 'patientAgeSex',
})
export class PatientAgeSexPipe implements PipeTransform {
  transform(patient: RR.Patient | undefined) {
    if (!patient) {
      return false;
    }

    return getPatientAgeAndSex(patient);
  }
}
