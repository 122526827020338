import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'includes',
})
export class IncludesPipe implements PipeTransform {
  /**
   * [1, 2, 3] | includes:1
   */
  transform<T>(array: T[], value: T): boolean {
    return array.includes(value);
  }
}
