<div class="modal-header">
  <h5 class="modal-title">Notifications</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body modal-body-notification">
  <h2>Unread</h2>
  <ng-container *ngIf="unreadNotifications$ | async as unreadNotifications">
    <div class="list-group mb-1" *ngIf="unreadNotifications.length > 0">
      <!-- div[rr-user-notification] is because the extra dom level interferes with the list-group css -->
      <div rr-user-notification *ngFor="let notification of unreadNotifications" [notification]="notification"></div>
    </div>
  </ng-container>

  <h2>Read</h2>
  <ng-container *ngIf="notifications$ | async as notifications">
    <div class="list-group" *ngIf="notifications.length > 0">
      <div rr-user-notification *ngFor="let notification of notifications" [notification]="notification"></div>
    </div>
  </ng-container>
</div>
