<div class="modal-header">
  <h5 class="modal-title">Keyboard Shortcuts (?)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="mb-2">
    <h3><strong>Radreport Shortcuts</strong></h3>
    <div class="row">
      <div class="col-md-6">Clear global search: <kbd>Shift</kbd> + <kbd>Backspace</kbd></div>
      <div class="col-md-6">Toggle LRA in 'Edit Report': <kbd>Shift</kbd> + <kbd>%</kbd></div>
      <div class="col-md-6">Attach Document to Report: <kbd>Shift</kbd> + <kbd>+</kbd></div>
      <div class="col-md-6">Add to Favourites: <kbd>Shift</kbd> + <kbd>A</kbd></div>
      <div class="col-md-6">Check report: <kbd>Shift</kbd> + <kbd>C</kbd></div>
      <div class="col-md-6">Back to Prefill: <kbd>Shift</kbd> + <kbd>B</kbd></div>
      <div class="col-md-6">Give Feedback: <kbd>Shift</kbd> + <kbd>G</kbd></div>
      <div class="col-md-6">Back to Editor: <kbd>Shift</kbd> + <kbd>E</kbd></div>
      <div class="col-md-6">Toggle Sidebar: <kbd>Shift</kbd> + <kbd>!</kbd></div>
      <div class="col-md-6">Keyboard Shortcuts: <kbd>Shift</kbd> + <kbd>?</kbd></div>
      <div class="col-md-6">Escape to Worklist: <kbd>Shift</kbd> + <kbd>Esc</kbd></div>
      <div class="col-md-6">Billing Items: <kbd>Shift</kbd> + <kbd>M</kbd></div>
      <div class="col-md-6">Indicate Report Title: <kbd>Shift</kbd> + <kbd>I</kbd></div>
      <div class="col-md-6">Key findings/Other Imaging: <kbd>Shift</kbd> + <kbd>K</kbd></div>
      <div class="col-md-6">Prefill Title: <kbd>Shift</kbd> + <kbd>T</kbd></div>
      <div class="col-md-6">Prefill Favourites : <kbd>Shift</kbd> + <kbd>F</kbd></div>
      <div class="col-md-6">Prefill Prediction: <kbd>Shift</kbd> + <kbd>P</kbd></div>
      <div class="col-md-6">Jump to Groups: <kbd>Shift</kbd> + <kbd>J</kbd></div>
      <div class="col-md-6">Demographics: <kbd>Shift</kbd> + <kbd>D</kbd></div>
      <div class="col-md-6">Feedback Help: <kbd>Shift</kbd> + <kbd>H</kbd></div>
      <div class="col-md-6">View and Export: <kbd>Shift</kbd> + <kbd>X</kbd></div>
      <div class="col-md-6">Notes: <kbd>Shift</kbd> + <kbd>N</kbd></div>
      <div class="col-md-6">Mark as Urgent: + <kbd>Shift</kbd> + <kbd>U</kbd></div>
      <div class="col-md-6">Mark as Super Urgent: + <kbd>Shift</kbd> + <kbd>*</kbd></div>
      <div class="col-md-6">Undo List: <kbd>Shift</kbd> + <kbd>Z</kbd></div>
      <div class="col-md-6">Dicom SR Length: <kbd>Shift</kbd> + <kbd>L</kbd></div>
      <div class="col-md-6">Signature: <kbd>Shift</kbd> + <kbd>S</kbd></div>
      <div class="col-md-6">Distribute Report: <kbd>Shift</kbd> + <kbd>R</kbd></div>
      <div class="col-md-6">Open Tutorials: <kbd>Shift</kbd> + <kbd>O</kbd></div>
      <div class="col-md-6">Open Web Viewer Forms: <kbd>Shift</kbd> + <kbd>V</kbd></div>
      <div class="col-md-6">Open Web Viewer Images: <kbd>Shift</kbd> + <kbd>W</kbd></div>
      <div class="col-md-6">Open Navigator Modal: <kbd>Shift</kbd> + <kbd>$</kbd></div>
      <br />
      <br />
      <br />
      <div class="col-md-6">Start global search: <kbd>/</kbd></div>
      <div class="col-md-6">
        Jump to previous/next section: <kbd>Alt(Mac: Option)</kbd> + <kbd>←</kbd> / <kbd>→</kbd>
      </div>
      <div class="col-md-6 pe-0">Jump to previous/next subsection: <kbd>Shift</kbd> + <kbd>←</kbd> / <kbd>→</kbd></div>
      <div class="col-md-6">Jump to previous/next element: <kbd>←</kbd> / <kbd>→</kbd></div>
      <div class="col-md-6">Close & select top 6 in groups modal : <kbd>SPACE</kbd></div>
      <br />
      <br />
    </div>
  </div>
  <div class="mb-2">
    <h3><strong>Text Editing Shortcuts</strong></h3>
    <div class="row">
      <div class="col-md-6">Copy the selected text: <kbd>Ctrl</kbd> + <kbd>C</kbd></div>
      <div class="col-md-6">Cut (remove) the selected text: <kbd>Ctrl</kbd> + <kbd>X</kbd></div>
      <div class="col-md-6">Paste contents: <kbd>Ctrl</kbd> + <kbd>V</kbd></div>
      <div class="col-md-6">Open up a search window: <kbd>Ctrl</kbd> + <kbd>F</kbd> / <kbd>F3</kbd></div>
      <div class="col-md-6">Undo the last action performed: <kbd>Ctrl</kbd> + <kbd>Z</kbd></div>
    </div>
  </div>
</div>
