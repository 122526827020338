import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { PatientDOBInfoPipe } from 'app/shared/pipes/patient-dob-info-pipe';
import { PatientDOBPipe } from 'app/shared/pipes/patient-dob-pipe';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { Observable, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, PatientDOBInfoPipe, PatientDOBPipe, LetDirective],
  selector: 'rr-patient-dob-info',
  templateUrl: 'patient-dob-info.component.html',
})
export class PatientDOBInfoComponent implements OnInit {
  @Input() @BindObservable() patientId: number;
  patientId$: Observable<number>;

  patient$: Observable<RR.Patient | undefined>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.patient$ = this.patientId$.pipe(switchMap((id) => this.store.select(fromPatient.selectPatient(id))));
  }
}
