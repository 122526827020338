import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ProviderNumberActions } from './provider-number.action';

export interface ProviderNumberState extends EntityState<RR.ProviderNumber> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.ProviderNumber>();
const initialState = adapter.getInitialState({ loaded: false });

export const providerNumberReducer = createReducer(
  initialState,
  on(ProviderNumberActions.findAllSuccess, (state: ProviderNumberState, { providerNumbers }) => ({
    ...adapter.setAll(providerNumbers, state),
    loaded: true,
  })),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
