import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, NgbTypeaheadModule, ReactiveFormsModule],
  selector: 'rr-parameter-dropdown',
  templateUrl: './parameter-dropdown.component.html',
})
export class ParameterDropdownComponent {
  @Input() @BindObservable() parameters: RR.ImgsimParameter[];
  parameters$: Observable<RR.ImgsimParameter[]>;
  @Input() title = true;

  @Output() onChoose = new EventEmitter<RR.ImgsimParameter>();
  parameterControl = new FormControl<string>('', { nonNullable: true });
  focus$ = new Subject<string>();

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  formatter = (p: RR.ImgsimParameter) => p.parameter;

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  searchParameters = (text$: Observable<string>) => {
    return merge(text$, this.focus$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) => {
        if (term === '') {
          return this.parameters$;
        } else {
          return this.parameters$.pipe(
            map((params) => params.filter((param) => param.name.toLowerCase().indexOf(term.toLowerCase()) > -1)),
          );
        }
      }),
    );
  };

  selectParameter(param: RR.ImgsimParameter) {
    this.onChoose.emit(param);
  }

  clearParam() {
    this.parameterControl.setValue('');
  }
}
