import { createAction, props } from '@ngrx/store';

import { StatementParameterCondition } from './statement-parameter-condition.service';
/**
 * Ngrx Actions for StatementParameterCondition
 *
 */

export const StatementParameterConditionActions = {
  createSuccess: createAction(
    '[StatementParameterCondition] Create Success',
    props<{ statement_parameter_condition: StatementParameterCondition }>(),
  ),
  deleteSuccess: createAction(
    '[StatementParameterCondition] Delete Success',
    props<{ statement_parameter_condition_id: number }>(),
  ),
  updateSuccess: createAction(
    '[StatementParameterCondition] Update Success',
    props<{ statement_parameter_condition: StatementParameterCondition }>(),
  ),
  findSuccess: createAction(
    '[StatementParameterCondition] Find Success',
    props<{ statement_parameter_condition: StatementParameterCondition }>(),
  ),
};
