import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { SoftwarePreferenceActions } from './software-preferences.action';

export type SoftwarePreferenceState = EntityState<RR.SoftwarePreference>;

const adapter = createEntityAdapter<RR.SoftwarePreference>();
const initialState = adapter.getInitialState();

export const SoftwarePreferenceReducer = createReducer(
  initialState,
  on(SoftwarePreferenceActions.getAllSuccess, (state: SoftwarePreferenceState, { software_prefs }) =>
    adapter.upsertMany(software_prefs, state),
  ),
  on(SoftwarePreferenceActions.createSuccess, (state: SoftwarePreferenceState, { software_pref }) =>
    adapter.addOne(software_pref, state),
  ),
  on(SoftwarePreferenceActions.deleteSuccess, (state: SoftwarePreferenceState, { software_pref_id }) =>
    adapter.removeOne(software_pref_id.toString(), state),
  ),
  on(SoftwarePreferenceActions.updateSuccess, (state: SoftwarePreferenceState, { software_pref }) =>
    adapter.upsertOne(software_pref, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
