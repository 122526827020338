<span
  class="d-inline-block"
  [ngbPopover]="popoverContent"
  popoverTitle="{{ event.when | date: 'MMM d, yyyy, h:mm a' }}"
  #popover="ngbPopover"
  triggers="manual"
  placement="top"
  container="body"
  (mouseenter)="open(event)"
  (mouseleave)="close()"
>
  {{ last ? event.user_name : event.user_name + ',&nbsp;' }}
</span>
<ng-template #popoverContent let-from="from_date" let-counts="counts" let-time="audit_time">
  <ng-container *ngIf="counts; else noCounts">
    <div class="text-success" *ngIf="counts.CREATE_CHOICE != null">{{ counts.CREATE_CHOICE }} additions</div>
    <div class="text-danger" *ngIf="counts.DELETE_CHOICE != null">{{ counts.DELETE_CHOICE }} deletions</div>
  </ng-container>
  <ng-template #noCounts>No changes</ng-template>
  <div>
    <i class="fa fa-hourglass-half me-1 text-info"></i>
    <span>{{ event.duration | convertSecondsToHMS }} (Audit time: {{ time ? time : 0 }} min)</span>
  </div>
</ng-template>
