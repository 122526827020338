import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BillingGroupActions } from './billing-group.action';

export interface BillingGroupState extends EntityState<RR.BillingGroup> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.BillingGroup>();

const initialState = adapter.getInitialState({ loaded: false });

export const billingGroupReducer = createReducer(
  initialState,
  on(BillingGroupActions.findAllSuccess, (state: BillingGroupState, { billingGroups }) => ({
    ...adapter.setAll(billingGroups, state),
    loaded: true,
  })),
  on(BillingGroupActions.findManySuccess, (state: BillingGroupState, { billingGroups }) =>
    adapter.upsertMany(billingGroups, state),
  ),
  on(BillingGroupActions.findSuccess, (state: BillingGroupState, { billingGroup }) =>
    adapter.upsertOne(billingGroup, state),
  ),
  on(BillingGroupActions.createSuccess, (state: BillingGroupState, { billingGroup }) =>
    adapter.addOne(billingGroup, state),
  ),
  on(BillingGroupActions.updateSuccess, (state: BillingGroupState, { billingGroup }) =>
    adapter.upsertOne(billingGroup, state),
  ),
  on(BillingGroupActions.deleteSuccess, (state: BillingGroupState, { billingGroupId }) =>
    adapter.removeOne(billingGroupId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
