<div *ngIf="(relatedReportsLoaded$ | async) && relatedReports">
  <div class="d-flex mb-2">
    <span><strong>Related Studies: </strong> </span>
    <div class="form-check ms-2">
      <input
        rrTooltip="SEARCH_ALL_STUDIES"
        id="{{ instanceId }}-all_studies"
        class="form-check-input"
        type="radio"
        name="related-studies-filter-{{ instanceId }}"
        [formControl]="mode"
        value="all"
      />
      <label for="{{ instanceId }}-all_studies" class="form-check-label"> All Studies </label>
    </div>

    <div class="form-check ms-2">
      <input
        rrTooltip="SEARCH_RELATED_STUDIES"
        id="{{ instanceId }}-related_studies"
        class="form-check-input"
        type="radio"
        name="related-studies-filter-{{ instanceId }}"
        [formControl]="mode"
        value="related"
      />
      <label for="{{ instanceId }}-related_studies" class="form-check-label"> Related Studies </label>
    </div>

    <div class="form-check ms-2">
      <input
        id="{{ instanceId }}-same_day_studies"
        class="form-check-input"
        type="radio"
        name="related-studies-filter-{{ instanceId }}"
        [formControl]="mode"
        value="sameDay"
        [attr.disabled]="relatedReports['sameDay'].length === 0 || null"
      />
      <!-- `|| null` above otherwise it renders as `disabled="false"`. This still disables the input. Because the string
    'false' is truthy. -->
      <label for="{{ instanceId }}-same_day_studies" class="form-check-label">
        Studies on <span>{{ report.created | date: 'MMM d, y' }}</span>
      </label>
    </div>

    <div class="form-check ms-2">
      <input
        rrTooltip="SEARCH_UNREPORTED_STUDIES"
        id="{{ instanceId }}-unreported_studies"
        class="form-check-input"
        type="radio"
        name="related-studies-filter-{{ instanceId }}"
        [formControl]="mode"
        value="unreported"
        [attr.disabled]="relatedReports['unreported'].length === 0 || null"
      />
      <label for="{{ instanceId }}-unreported_studies" class="form-check-label"> Unreported Studies </label>
    </div>
  </div>

  <ng-container
    *ngIf="mode.value && relatedReports[mode.value] && relatedReports[mode.value].length > 0; else noRelatedReports"
  >
    <rr-related-report
      [relatedReports]="relatedReports[mode.value]"
      [selectable]="selectable"
      [editorExpandedState]="editorExpandedState"
    ></rr-related-report>
  </ng-container>

  <ng-template #noRelatedReports>
    <div *ngIf="mode.value === 'all'">There are no other studies for this patient.</div>
    <div *ngIf="mode.value === 'related'">There are no related studies for this patient.</div>
  </ng-template>
</div>

<div *ngIf="(relatedReportsLoaded$ | async) === false">Loading...</div>
