import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { KeywordAbbreviationActions } from './keyword-abbr.action';

export interface KeywordAbbreviationState extends EntityState<RR.KeywordAbbreviation> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.KeywordAbbreviation>();

const initialState = adapter.getInitialState({ loaded: false });

export const keywordAbbreviationReducer = createReducer(
  initialState,
  on(KeywordAbbreviationActions.findAllSuccess, (state: KeywordAbbreviationState, { keywordAbbreviations }) => ({
    ...adapter.setAll(keywordAbbreviations, state),
    loaded: true,
  })),
  on(KeywordAbbreviationActions.createSuccess, (state: KeywordAbbreviationState, { keywordAbbreviation }) =>
    adapter.addOne(keywordAbbreviation, state),
  ),
  on(KeywordAbbreviationActions.updateSuccess, (state: KeywordAbbreviationState, { keywordAbbreviation }) =>
    adapter.upsertOne(keywordAbbreviation, state),
  ),
  on(KeywordAbbreviationActions.deleteSuccess, (state: KeywordAbbreviationState, { keywordAbbreviationId }) =>
    adapter.removeOne(keywordAbbreviationId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
