<div class="modal-header">
  <h4 class="modal-title">Subsection Edit</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <nav *ngIf="selectedSection && selectedSubsection">
    <ol class="breadcrumb">
      <li class="breadcrumb-item text-primary">Section: {{ selectedSection.title }}</li>
      <li class="breadcrumb-item text-primary">
        Subsection: {{ selectedSubsection.name ? selectedSubsection.name : 'NO NAME' }}
      </li>
    </ol>
  </nav>
  <div class="rounded btn-group-vertical mb-3">
    <button
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      (click)="toggleEdit('HIDE_SECTION_HEADING'); $event.stopPropagation()"
      rrTooltip="SET_DEFAULT_SUBSECTION_HEADINGS"
    >
      <i class="fas fa-eye-slash"></i> Hide Subsection Headings
    </button>

    <button
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      (click)="toggleEdit('INSERT'); $event.stopPropagation()"
      rrTooltip="INSERT_SUBSECTION"
    >
      <i class="fas fa-plus"></i> Insert Subsection
    </button>

    <button
      *ngIf="selectedSubsection"
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      (click)="toggleModeRename(selectedSubsection); $event.stopPropagation()"
      rrTooltip="EDIT_SUBSECTION"
    >
      <i class="fas fa-pen"></i> Rename Subsection
    </button>

    <button
      *ngIf="selectedSubsection"
      role="button"
      permission
      [permission-only]="['template_manage']"
      class="btn btn-primary"
      rrTooltip="DELETE_SUBSECTION"
      (click)="deleteSubsection(selectedSubsection); $event.stopPropagation()"
    >
      <i class="fas fa-trash"></i> Delete Subsection
    </button>

    <button
      *ngIf="selectedSubsection"
      role="button"
      permission
      [permission-only]="['template_manage']"
      class="btn btn-primary"
      [class.active]="selectedSubsection.default"
      rrTooltip="SUBSECTION_ON_OFF"
      (click)="toggleDefault(selectedSubsection); $event.stopPropagation()"
    >
      <i class="fas fa-star"></i> Set Subsection as Default
    </button>

    <!--
      Create a Region in Subsection
      ngbTooltip doesn't display when the disabled attribute is used.
      The disabled button has a class but no click handler.
    -->
    <button
      role="button"
      class="btn btn-primary disabled"
      *ngIf="selectedSection?.type !== 'findings'"
      permission
      [permission-only]="['template_manage']"
      ngbTooltip="Regions only supported in the Findings section"
    >
      <i class="fas fa-plus"></i> Create a Region in Subsection
    </button>
    <button
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      *ngIf="topic && selectedSection && selectedSubsection && selectedSection.type === 'findings'"
      (click)="addSubsectionWithRegionSet(topic, selectedSubsection); $event.stopPropagation()"
    >
      <i class="fas fa-plus"></i> Create a Region in Subsection
    </button>

    <button
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      (click)="toggleEdit('CREATE_ELEMENT'); $event.stopPropagation()"
      rrTooltip="INSERT_NEW_OPTION"
      data-cy="create-element"
    >
      <i class="fas fa-plus"></i> Create Element
    </button>

    <button
      *ngIf="topic && selectedSection && selectedSubsection"
      role="button"
      class="btn btn-primary"
      permission
      [permission-only]="['template_manage']"
      (click)="addFromLibrary(topic, selectedSection, selectedSubsection); $event.stopPropagation()"
      rrTooltip="ADD_ELEMENT"
    >
      <i class="fas fa-file-import"></i> Import Element
    </button>

    <button
      *ngIf="selectedSubsection"
      permission
      [permission-only]="['set_configuration']"
      (click)="
        editLandmark({ subsection_id: selectedSubsection.id, landmark_label_id: selectedSubsection.landmark_label_id })
      "
      class="btn btn-primary"
      rrTooltip="EDIT_LANDMARK"
    >
      <span class="fas fa-map-marker-alt"></span> Landmark Label
      <ng-container *ngIf="selectedSubsection.landmark_label_id">
        (<rr-landmark-label-headline
          *ngIf="selectedSubsection.landmark_label_id | storeSelectPipe: selectLandmarkLabel | async as landmarkLabel"
          [landmarkLabel]="landmarkLabel"
        ></rr-landmark-label-headline
        >)
      </ng-container>
    </button>
  </div>

  <div class="mt-1" *ngIf="mode !== 'NONE' && mode !== 'HIDE_SECTION_HEADING'">
    <rr-statement-edit
      *ngIf="mode === 'CREATE_ELEMENT' && selectedSubsection && topic"
      (onSubmit)="createStatementSet($event, selectedSubsection)"
      (onCancel)="mode = 'NONE'"
      [editType]="'createElement'"
    >
    </rr-statement-edit>

    <!-- Subsection form -->
    <form *ngIf="mode === 'RENAME' && selectedSubsection">
      <input [(ngModel)]="inputText" name="inputText" type="text" rrAutoFocus class="form-control textarea" />
      <div class="d-flex btn-group">
        <button class="btn btn-sm btn-secondary" role="button" type="button" (click)="mode = 'NONE'">Cancel</button>
        <button class="btn btn-sm btn-primary" role="button" (click)="onClickRename(selectedSubsection)">Rename</button>
      </div>
    </form>
    <form *ngIf="mode === 'INSERT' && selectedSection && selectedSubsection && selectedElement">
      <input [(ngModel)]="inputText" name="inputText" type="text" rrAutoFocus class="form-control textarea" />
      <div class="d-flex btn-group">
        <button class="btn btn-sm btn-secondary" role="button" type="button" (click)="mode = 'NONE'">Cancel</button>
        <button
          class="btn btn-sm btn-primary"
          role="button"
          (click)="onClickInsert(selectedSection, selectedSubsection, selectedElement)"
        >
          Create Subsection
        </button>
      </div>
    </form>
  </div>
  <div class="mt-1" *ngIf="mode === 'HIDE_SECTION_HEADING' && topic && selectedSection">
    <ul class="list-group">
      <li
        class="list-group-item"
        [class.text-muted]="subsection.default_hide_heading"
        *ngFor="let subsection of dropdownSubsections"
      >
        <span
          role="button"
          *ngIf="subsection.default_hide_heading"
          (click)="showOrHideHeading(topic, selectedSection, subsection)"
          rrTooltip="SHOW_SUBSECTION_HEADING"
          ><i class="fas fa-eye-slash"></i
        ></span>
        <span
          role="button"
          *ngIf="!subsection.default_hide_heading"
          (click)="showOrHideHeading(topic, selectedSection, subsection)"
          rrTooltip="HIDE_SUBSECTION_HEADING"
          ><i class="fas fa-eye"></i
        ></span>
        {{ subsection.name ? subsection.name : 'NO NAME' }}
      </li>
    </ul>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close()">Close</button>
</div>
