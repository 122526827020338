import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromPatientNote } from '../patient-note/patient-note.selector';
import { fromPatientQuestionAnswer } from '../questionnaire/patient-question-answer/patient-question-answer.selector';

const adapter = createEntityAdapter<RR.Patient>();
const selectFeature = (state: AppState) => state.patient;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectPatient = (id: number) =>
  createSelector(selectEntities, (patients: Dictionary<RR.Patient>) => patients[id]);

const selectPatientAnswers = (patientId: number) =>
  createSelector(
    selectPatient(patientId),
    fromPatientQuestionAnswer.selectEntities,
    (patient: RR.Patient | undefined, answers: Dictionary<RR.PatientQuestionAnswer>) =>
      (patient?.patient_answer_ids || []).map((answerId) => answers[answerId]),
  );

const selectPatientNotes = (patient_id: number) =>
  createSelector(
    selectPatient(patient_id),
    fromPatientNote.selectEntities,
    (patient, patient_notes) =>
      patient?.patient_note_ids.map((id) => patient_notes[id]).filter((note): note is RR.PatientNote => !!note) || [],
  );

const selectPatientNotesByPatientIds = (ids: number[]) =>
  createSelector(fromPatientNote.selectAll, (patient_notes: RR.PatientNote[]) =>
    patient_notes.filter((note) => ids.includes(note.patient_id)),
  );

export const fromPatient = {
  selectIds,
  selectEntities,
  selectAll,
  selectPatient,
  selectPatientAnswers,
  selectPatientNotes,
  selectPatientNotesByPatientIds,
};
