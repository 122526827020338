import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PatientNoteActions } from './patient-note.action';

export interface PatientNoteState extends EntityState<RR.PatientNote> {
  count: number;
}

const adapter = createEntityAdapter<RR.PatientNote>();
const initialState = adapter.getInitialState({
  count: 0,
});

export const patientNoteReducer = createReducer(
  initialState,
  on(PatientNoteActions.searchSuccess, (state: PatientNoteState, searchResult) => {
    const { patient_notes, count } = searchResult.searchResult;
    return {
      ...adapter.upsertMany(patient_notes, state),
      count,
    };
  }),
  on(PatientNoteActions.findManySuccess, (state: PatientNoteState, { patient_notes }) =>
    adapter.upsertMany(patient_notes, state),
  ),
  on(PatientNoteActions.createSuccess, (state: PatientNoteState, { patient_note }) =>
    adapter.addOne(patient_note, state),
  ),
  on(PatientNoteActions.deleteSuccess, (state: PatientNoteState, { patient_note_id }) =>
    adapter.removeOne(patient_note_id, state),
  ),
  on(PatientNoteActions.updateSuccess, (state: PatientNoteState, { patient_note }) =>
    adapter.upsertOne(patient_note, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
