<td>
  <span
    class="fa mx-2"
    [ngClass]="question.parent_question_id ? 'fa-angle-double-right' : 'fa-angle-right'"
    [class.ms-4]="question.parent_question_id"
    *ngIf="question.parent_question_id || question.children_questions?.length"
  ></span
  >{{ question.text }}
</td>
<td class="ms-auto">
  <div class="d-flex flex-nowrap" *ngIf="!viewonly">
    <div class="d-flex" *ngIf="question.question_type === 'yes_no_na'">
      <div class="form-check ms-0">
        <input
          id="yes-{{ question.id }}"
          name="answer-{{ question.id }}"
          class="form-check-input"
          type="radio"
          [checked]="answer?.answer === 'Yes'"
          (click)="onAnswerChanged(question.id, 'Yes')"
          value="Yes"
        />
        <label for="yes-{{ question.id }}" class="form-check-label"> Yes </label>
      </div>

      <div class="form-check ms-3">
        <input
          id="no-{{ question.id }}"
          name="answer-{{ question.id }}"
          class="form-check-input"
          type="radio"
          [checked]="answer?.answer === 'No'"
          (click)="onAnswerChanged(question.id, 'No')"
          value="No"
        />
        <label for="no-{{ question.id }}" class="form-check-label"> No </label>
      </div>

      <div class="form-check ms-3">
        <input
          id="na-{{ question.id }}"
          name="answer-{{ question.id }}"
          class="form-check-input"
          type="radio"
          [checked]="answer?.answer === 'NA'"
          (click)="onAnswerChanged(question.id, 'NA')"
          value="NA"
        />
        <label for="na-{{ question.id }}" class="form-check-label"> NA </label>
      </div>
    </div>

    <div class="d-flex" *ngIf="question.question_type !== 'yes_no_na'">
      <input
        type="text"
        [value]="answer?.answer"
        #answerInput
        (change)="onAnswerChanged(question.id, answerInput.value)"
      />
    </div>

    <div class="ms-3" *ngIf="!!answer">
      <button class="btn btn-danger btn-sm" title="Remove the answer" (click)="removeAnswer(answer.id)">
        <span class="fa fa-times"></span>
      </button>
    </div>
  </div>

  <div *ngIf="viewonly">
    {{ answer?.answer }}
  </div>
</td>
