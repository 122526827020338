import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReferrerNoteCategoryActions } from './referrer-note-category.action';
import { ReferrerNoteCategoryHttpService } from './referrer-note-category.service';

@Injectable()
export class ReferrerNoteCategoryEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReferrerNoteCategoryHttpService,
  ) {}

  create(referrerNoteCategory: Partial<RR.NoteCategory>) {
    return this.service.create(referrerNoteCategory).pipe(
      map((referrer_note_category: RR.NoteCategory) =>
        ReferrerNoteCategoryActions.createSuccess({
          referrer_note_category,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(referrerNoteCategoryId: number) {
    return this.service.delete(referrerNoteCategoryId).pipe(
      map(() => ReferrerNoteCategoryActions.delete({ referrer_note_category_id: referrerNoteCategoryId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
