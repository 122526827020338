import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type TopAttributes = {
  attributes: { attribute_option_id: number; frequency: number }[];
  unfiltered_count: number;
};

@Injectable()
export class AttributeOptionHttpService {
  constructor(private http: HttpClient) {}

  create(attributeSet: Partial<RR.AttributeOption>) {
    return this.http.post<{
      attribute_option: RR.AttributeOption;
      attribute_set: RR.AttributeSet;
    }>('/api/attribute_option', attributeSet);
  }

  update(attributeOptionId: number, changes: Partial<RR.AttributeOption>) {
    return this.http.put<{ attribute_option: RR.AttributeOption; attribute_set: RR.AttributeSet }>(
      `/api/attribute_option/${attributeOptionId}`,
      changes,
    );
  }

  delete(attributeOptionId: number) {
    return this.http.delete<{ attribute_set: RR.AttributeSet }>(`/api/attribute_option/${attributeOptionId}`);
  }

  // Get top attribute options in that attribute list for the chosen statement
  getTopAttributes({
    text_object_id,
    limit,
    statement_choice_id,
    filtering,
  }: {
    text_object_id: number;
    limit?: number;
    statement_choice_id?: number;
    filtering?: boolean;
  }) {
    let params = new HttpParams().set('text_object_id', text_object_id.toString());
    if (limit) params = params.set('limit', limit.toString());
    if (statement_choice_id) params = params.set('statement_choice_id', statement_choice_id.toString());
    // filtering: load_default=True in backend
    if (filtering !== undefined) params = params.set('filtering', filtering.toString());
    return this.http.get<TopAttributes>('/api/get_top_attributes', {
      params,
    });
  }

  findAttributeStatementUsage(attributeOptionId: number) {
    return this.http.get<{
      statements: RR.Statement[];
      text_objects: RR.TextObject[];
    }>(`/api/attribute_option/${attributeOptionId}/statements`);
  }
}
