import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { of } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';

import { RegionEffect } from '../region/region.effect';
import { RegionSetActions } from './region-set.action';
import { RegionSetHttpService } from './region-set.service';

@Injectable()
export class RegionSetEffect {
  constructor(
    private store: Store<AppState>,
    private service: RegionSetHttpService,
    private regionEffect: RegionEffect,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((regionSets: RR.RegionSet[]) => RegionSetActions.findAllSuccess({ regionSets })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(regionSet: Partial<RR.RegionSet>) {
    return this.service.create(regionSet).pipe(
      map((regionSetCreated: RR.RegionSet) => RegionSetActions.createSuccess({ regionSet: regionSetCreated })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(regionSetId: number, changes: Partial<RR.RegionSet>) {
    return this.service.update(regionSetId, changes).pipe(
      map((regionSet: RR.RegionSet) => RegionSetActions.updateSuccess({ regionSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(regionSetId: number) {
    return this.service.delete(regionSetId).pipe(
      map(() => RegionSetActions.deleteSuccess({ regionSetId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((regionSets: RR.RegionSet[]) => RegionSetActions.findInTemplateSuccess({ templateId, regionSets })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createRegionSetRegions(regionSet: Partial<RR.RegionSet>, regions: Partial<RR.Region>[]) {
    this.create(regionSet).pipe(
      map((action) => ({ regionSet: action.regionSet, regions })),
      switchMap(({ regionSet, regions }) =>
        regions.map((region) => this.regionEffect.create({ ...region, region_set_id: regionSet.id })),
      ),
      map(() => of(RegionSetActions.createRegionSetRegionsSuccess())),
      share(),
    );
  }
}
