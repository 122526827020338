import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { DocumentationLinkActions } from './documentation-link.action';

export interface DocumentationLinkState extends EntityState<RR.DocumentationLink> {
  loadingStatus: 'loading' | 'loaded' | undefined;
}

const adapter = createEntityAdapter<RR.DocumentationLink>();

const initialState: DocumentationLinkState = adapter.getInitialState({ loadingStatus: undefined });

export const documentationLinkReducer = createReducer(
  initialState,
  on(DocumentationLinkActions.findAll, (state) => {
    return {
      ...state,
      loadingStatus: 'loading' as const,
    };
  }),
  on(DocumentationLinkActions.findAllSuccess, (state, { docs }) => {
    const newState = adapter.setAll(docs, state);
    return {
      ...newState,
      loadingStatus: 'loaded' as const,
    };
  }),
  on(DocumentationLinkActions.createSuccess, (state: DocumentationLinkState, { doc }) => adapter.addOne(doc, state)),
  on(DocumentationLinkActions.updateSuccess, (state: DocumentationLinkState, { doc }) =>
    adapter.updateOne({ id: doc.id, changes: doc }, state),
  ),
  on(DocumentationLinkActions.deleteSuccess, (state: DocumentationLinkState, { docId }) =>
    adapter.removeOne(docId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
