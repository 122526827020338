import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PatientActions } from './patient.action';

export interface PatientState extends EntityState<RR.Patient> {
  count: number;
}

const adapter = createEntityAdapter<RR.Patient>();

const initialState = adapter.getInitialState({
  count: 0,
});

export const patientReducer = createReducer(
  initialState,
  on(PatientActions.searchSuccess, (state: PatientState, searchResult) => {
    const { patients, count } = searchResult.searchResult;
    return {
      ...adapter.upsertMany(patients, state),
      count,
    };
  }),
  // Using upsert for findAll and find success because patients may already populated by finding reports
  on(PatientActions.findManySuccess, (state: PatientState, { patients }) => adapter.upsertMany(patients, state)),
  on(PatientActions.findSuccess, (state: PatientState, { patient }) => adapter.upsertOne(patient, state)),
  on(PatientActions.createSuccess, (state: PatientState, { patient }) => adapter.addOne(patient, state)),
  on(PatientActions.updateSuccess, (state: PatientState, { patient }) => adapter.upsertOne(patient, state)),
  on(PatientActions.deleteSuccess, (state: PatientState, { patient }) =>
    adapter.upsertOne({ ...patient, deleted: true }, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
