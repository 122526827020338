import { createActionGroup, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { BookingActions } from 'app/store/booking/booking.action';

export const DocumentActions = createActionGroup({
  source: 'Document',
  events: {
    upsertOne: props<{ document: RR.Document }>(),
    upsertMany: props<{ documents: RR.Document[] }>(),
    addOne: props<{ document: RR.Document }>(),
    removeOne: props<{ documentId: string }>(),
    findInBookingSuccess: props<{ booking_id: number; documents: RR.Document[] }>(),
  },
});

export const DocumentBatchActions = {
  createSuccessBatch: createBatchAction(
    '[Document] Create Success Batch',
    props<{
      actions: {
        createDocumentSuccess: ReturnType<typeof DocumentActions.addOne>;
        updateBookingSuccess: ReturnType<typeof BookingActions.updateSuccess>;
      };
    }>(),
  ),
  deleteSuccessBatch: createBatchAction(
    '[Document] Delete Success Batch',
    props<{
      actions: {
        deleteDocumentSuccess: ReturnType<typeof DocumentActions.removeOne>;
        updateBookingSuccess: ReturnType<typeof BookingActions.updateSuccess>;
      };
    }>(),
  ),
};
