import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { fromCurrentReport } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { ESTemplate, fromCurrentTemplate, fromTemplate, TemplateEffect } from 'app/store/template/template';
import { iif, Observable, of, Subscription } from 'rxjs';
import { filter, finalize, switchMap } from 'rxjs/operators';

import { ScanCodeHeadlineComponent } from '../../../shared/components/scan-code-headline/scan-code-headline.component';
import { TemplateHeadlineComponent } from '../../../shared/components/template-headline/template-headline.component';
import { TemplatePickerComponent } from './template-picker/template-picker.component';

@Component({
  selector: 'rr-template-chooser',
  templateUrl: 'template-chooser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TemplateHeadlineComponent, ScanCodeHeadlineComponent, TemplatePickerComponent, CommonModule],
})
@LifecycleLogger
export class TemplateChooserComponent implements OnInit, OnDestroy {
  report: RR.Report;
  template$: Observable<RR.Template | undefined>;

  subscription = new Subscription();
  saving = false;

  // Report's scan code
  scanCode$: Observable<RR.ScanCode | undefined>;
  scanCodeTemplate$: Observable<RR.Template | undefined>;

  constructor(
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    private editorService: EditorService,
    private templateEffect: TemplateEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.template$ = this.store.select(fromCurrentTemplate.selectTemplate);

    const report$ = this.store.select(fromCurrentReport.selectReport).pipe(filterDefined());

    this.subscription.add(
      report$.subscribe((report) => {
        this.report = report;
        this.cd.markForCheck();
      }),
    );

    this.scanCode$ = report$.pipe(
      filter((report): report is RR.Report & { scan_code_id: number } => !!report.scan_code_id),
      switchMap((report) => this.store.select(fromScanCode.selectScanCode(report.scan_code_id))),
    );

    this.subscription.add(
      this.scanCode$
        .pipe(
          switchMap((scanCode) => (scanCode?.template_id ? this.templateEffect.find(scanCode.template_id) : of(null))),
        )
        .subscribe(),
    );

    this.scanCodeTemplate$ = this.scanCode$.pipe(
      switchMap((sc) =>
        // @ts-expect-error strictNullChecks
        iif(() => !!sc?.template_id, this.store.select(fromTemplate.selectTemplate(sc.template_id)), of(undefined)),
      ),
    );
  }

  onESTemplateSelected(template: ESTemplate) {
    this.save(Number(template._id));
  }

  save(template_id: number) {
    this.saving = true;
    this.reportService
      .createTopic(this.report.id, template_id)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.cd.detectChanges();
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: (action) => {
          const topic = action.actions.createTopicSuccess.topic;
          this.setTopicOpen(topic.id);
        },
      });
  }

  setTopicOpen(topicId: number) {
    this.editorService.setTopicOpen(topicId);
  }

  back() {
    this.editorService.toggleTemplateChooser(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
