import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromTagChoice, TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { combineLatest, map, Observable, skipWhile, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'rr-auto-select-groups-button',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './auto-select-groups-button.component.html',
  styleUrl: './auto-select-groups-button.component.css',
})
export class AutoSelectGroupsButtonComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() sectionType: RR.TagSearchSection;
  sectionType$: Observable<RR.TagSearchSection>;
  @Input() topicId: number;
  @Input() parent: 'PREFILL' | 'FINDING_GROUPS';
  tagChoices$: Observable<RR.TagChoice[]>;
  filteredTagChoices$: Observable<RR.TagChoice[]>;
  subscription = new Subscription();

  form = new FormGroup({
    threshold: new FormControl(50, { nonNullable: true }),
    min: new FormControl(5, { nonNullable: true }),
    excludeNad: new FormControl(false, { nonNullable: true }),
    placeholderNad: new FormControl(true, { nonNullable: true }),
  });

  constructor(
    private tagChoiceEffect: TagChoiceEffect,
    private messageService: MessageService,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.tagChoices$ = this.store.select(fromTagChoice.selectLoaded).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromTagChoice.selectAll)),
    );

    // Filter the tagChoice based on sectionType
    this.filteredTagChoices$ = combineLatest([this.tagChoices$, this.sectionType$]).pipe(
      map(([choices, sectionType]) => choices.filter((choice) => choice.section === sectionType)),
    );
  }

  /**
   * Use the Machine learning algorithm to automatically select tags.
   *
   * @params: threshold Uses the value of the threshold in the form element.
   *
   * This will fail if there are already tags selected by the ML algorithm.
   * This can be reset be deleting the tags.
   *
   */

  onAutoSelect(close = false): void {
    const form = this.form.getRawValue();
    const threshold = form.threshold / 100;

    if (threshold <= 0 || threshold >= 1) {
      this.messageService.add({
        title: 'Error',
        message: 'Threshold must be > 0 and < 100',
        type: 'danger',
        timeout: 5000,
      });
      return;
    }

    this.subscription.add(
      this.tagChoiceEffect
        .autoSelect(this.topicId, {
          threshold,
          section: this.sectionType,
          min_tags: form.min,
          no_nad: form.excludeNad,
          nad_placeholder: form.placeholderNad,
        })
        .subscribe({
          next: () => {
            if (close) {
              this.modalService.dismissAll();
            }
          },
        }),
    );
  }

  /**
   * Delete tags suggested by the Machine Learning Algorithm.
   *
   * This only removes the tags suggested by the machine learning algorithm,
   * leaving the manually set tags alone.
   */

  onAutoSelectDelete(): void {
    this.subscription.add(this.tagChoiceEffect.autoSelectDelete(this.topicId).subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
