import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { ICategorisedAttribute, TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { fromStatement } from 'app/store/template/statement';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { LinkifyDirective } from '../../../shared/directives/linkify/linkify.directive';
import { SharedModule } from '../../../shared/shared.module';
import { CategoriseSentenceModalComponent } from '../category/categorise-sentence-modal/categorise-sentence-modal.component';
import { StatementTextRenderComponent } from '../statement-edit/statement-text-render/statement-text-render.component';

@Component({
  selector: 'rr-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.css'],
  standalone: true,
  imports: [StatementTextRenderComponent, LinkifyDirective, SharedModule, CommonModule],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class DividerComponent implements OnInit {
  @Input() element: RR.Element;
  @Input() statement: RR.Statement;
  @Input() topic: RR.Topic;
  @Input() region: RR.Region | undefined;
  @Input() subsection: RR.Subsection;
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD';

  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();
  statementPreviewText$: Observable<string>;
  isSentenceCategorised$: Observable<boolean>;

  constructor(
    private templateService: TemplateService,
    private editorService: EditorService,
    private modal: NgbModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    // Check if sentence was categorised by using combo of statement and attributes
    this.isSentenceCategorised$ = this.getStatementDefaultAttributes().pipe(
      switchMap((a) => this.templateService.checkSentenceCategories(this.statement.id, a)),
    );
  }

  get region_id() {
    return this.region ? this.region.id : null;
  }

  gotoStatement() {
    this.editorService.publishFocus({
      statement_id: this.statement.id,
      element_id: this.element.id,
      region_id: this.region_id || undefined,
      target: this.parent,
    });
  }

  openSentenceCategoryModal() {
    this.getStatementDefaultAttributes()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((a) => {
        CategoriseSentenceModalComponent.open(
          null,
          this.modal,
          this.topic.report_id,
          this.topic.id,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          this.element ? this.element.statement_set_id : null,
          this.region_id,
          this.statement.id,
          a,
        );
      });
  }

  getStatementDefaultAttributes(): Observable<ICategorisedAttribute[]> {
    return this.store.select(fromStatement.selectTextObjects(this.statement.id)).pipe(
      map((textObjects) => {
        // TODO(tda): this is duplicated in divider.component
        return textObjects
          .filter((obj) => obj.type === 'set')
          .map((obj) => {
            let default_attribute: RR.DefaultAttribute | undefined;
            this.templateService
              .getDefaultAttribute(this.topic.template_id, obj.id, this.region_id)
              .pipe(take(1))
              // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
              .subscribe((tda) => {
                default_attribute = tda;
              });
            return {
              attribute_option_id: default_attribute?.default_option_id,
              text_object_id: obj.id,
            } as ICategorisedAttribute;
          });
      }),
    );
  }
}
