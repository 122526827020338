import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { INDEX_SCROLL_ID } from 'app/app.constants';
import { filterDefined, scrollIntoView } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromStatementSet } from 'app/store/template/statement-set';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, LetDirective],
  selector: '[rr-index-element]',
  templateUrl: './index-element.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./index-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class IndexElementComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() element: RR.Element;

  @Input() region: RR.Region | undefined;
  @Input() subsection_choice: RR.SubsectionChoice;
  highlight: Observable<boolean>;
  statementSet$: Observable<RR.StatementSet | undefined>;

  topic$: Observable<RR.Topic>;

  subscription = new Subscription();
  prefill: Observable<boolean>;
  numberOfCategorisedDividers: Observable<number>;
  numberOfCategorisedStatements: Observable<number>;
  scMode$: Observable<boolean>;

  constructor(
    private editorService: EditorService,
    private templateService: TemplateService,
    private el: ElementRef,
    private store: Store<AppState>,
  ) {
    this.prefill = editorService.prefill;
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.scMode$ = this.editorService.toggleSCModeEventListener();
  }

  ngOnInit() {
    this.statementSet$ = this.store.select(fromStatementSet.selectStatementSet(this.element.statement_set_id));

    this.numberOfCategorisedDividers = this.getNumberOfCategorisedStatements('DIVIDER');
    this.numberOfCategorisedStatements = this.getNumberOfCategorisedStatements('STATEMENT');

    this.subscription.add(
      this.topic$.subscribe(
        (topic: RR.Topic) =>
          (this.highlight = this.editorService.getHighlight(this.element.id, topic.id, undefined, this.region_id)),
      ),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) =>
            this.editorService.getScrollEvents(this.element.id, topic.id, undefined, this.region_id, ['INDEX_SEARCH']),
          ),
        )
        .subscribe((event) => {
          if (event.item.source === 'CHOICE_PREVIEW') {
            // Only center index element if highlight source is choice preview.
            // Otherwise index element will jump around when breadcrumb changed, ie: click on the index element
            this.el.nativeElement.scrollIntoView({ block: 'center' });
          } else {
            scrollIntoView({
              element: this.el.nativeElement,
              parent: document.querySelector(`#${INDEX_SCROLL_ID}`),
              topOffset: 50,
            });
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get region_id() {
    // Region is undefined in IndexSubsectionComponent but defined in IndexRegionComponent
    return this.region ? this.region.id : undefined;
  }

  @HostListener('click')
  focusElement() {
    this.editorService.publishFocus({
      element_id: this.element.id,
      region_id: this.region_id,
    });
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.topic$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((topic: RR.Topic) =>
        this.editorService.publishHighlight(
          { element_id: this.element.id, topic_id: topic.id, region_id: this.region_id, source: 'INDEX_SEARCH' },
          { highlight: true, scroll: true },
        ),
      );
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.topic$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((topic: RR.Topic) =>
        this.editorService.publishHighlight(
          { element_id: this.element.id, topic_id: topic.id, region_id: this.region_id, source: 'INDEX_SEARCH' },
          { highlight: false, scroll: false },
        ),
      );
  }

  getNumberOfStatements(type: 'DIVIDER' | 'STATEMENT') {
    return this.templateService.getNumberOfStatements(this.element.statement_set_id, type);
  }

  getNumberOfCategorisedStatements(type: 'DIVIDER' | 'STATEMENT') {
    return this.templateService.getNumberOfCategorisedStatements(this.element.statement_set_id, type);
  }
}
