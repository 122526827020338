import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const StatementCoincidenceActions = createActionGroup({
  source: 'StatementCoincidence',
  events: {
    addOne: props<{ statementCoincidence: RR.StatementCoincidence }>(),
    addMany: props<{ statementCoincidences: RR.StatementCoincidence[] }>(),
    reset: emptyProps(),
  },
});
