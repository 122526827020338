<div>
  <div [hidden]="!editMode && choices.length > 0">
    <!-- Clicks on this div are tracked in the click handler -->
    <div
      class="w-100 d-flex px-1 position-relative content newline"
      role="button"
      draggable="true"
      (click)="onClick($event)"
      (mouseenter)="onmouseenter()"
      (mouseleave)="onmouseleave()"
    >
      <div class="d-flex">
        <input *ngIf="editMode && type === 'normal'" type="checkbox" [checked]="isSelected" />
        <rr-statement-text-render
          [statement_id]="statement.id"
          [region]="!isNotepad ? region : undefined"
          [template_id]="template_id"
          [proposed]="proposed?.proposed"
        ></rr-statement-text-render>
        <span [linkify]="statementPreviewText"></span>
        <!-- Button 442 always visible for dividers -->
        <div class="ms-3">
          <button
            *ngIf="statement.is_divider"
            class="btn btn-sm btn-info"
            (click)="openStatementUsageAnalytics()"
            permission
            [permission-only]="['report_manage']"
            rrTooltip="FIND_PREVIOUS_REPORT_BY_STATEMENT"
          >
            <i class="fa fa-file-medical"></i>
          </button>
        </div>
      </div>
      <div class="d-flex align-items-center ms-auto">
        <span class="fa fa-asterisk ms-1 text-danger" *ngIf="statement.default_key_finding"></span>
        <span class="fa fa-comment ms-1 text-danger" *ngIf="statement.default_comment"></span>
        <span class="fas fa-italic ms-1 text-danger" *ngIf="statement.default_impression_recommendation"></span>
        <span
          *ngIf="filterType === 'LOGISTIC_REGRESSION'"
          class="fw-bold text-dark ms-2 badge bg-light border text-dark hide-if-divider"
        >
          {{ frequency | number: '1.0-2' }}%
        </span>
        <span
          *ngIf="filterType !== 'LOGISTIC_REGRESSION' && filterType !== 'NONE'"
          class="fw-bold text-dark ms-2 badge bg-light border text-dark hide-if-divider"
        >
          {{ frequency }}
        </span>
        <ng-container *ngIf="correlationScore">
          <span
            *ngFor="let score of correlationScore"
            class="fw-bold text-dark ms-2 badge bg-light border text-dark hide-if-divider"
            [ngbTooltip]="score.name"
          >
            {{ score.value }}
          </span>
        </ng-container>
      </div>
      <div
        class="item-btns-right btn-group"
        *ngIf="hover && (choices.length === 0 || editMode) && (type === 'normal' || type === 'divider')"
        data-no-bubble-statement
      >
        <button
          *ngIf="!editMode && !statement.is_divider"
          class="btn btn-sm btn-info"
          (click)="openStatementUsageAnalytics()"
          permission
          [permission-only]="['report_manage']"
          rrTooltip="FIND_PREVIOUS_REPORT_BY_STATEMENT"
        >
          <i class="fa fa-file-medical"></i>
        </button>

        <button
          role="button"
          *ngIf="!editMode && (type === 'normal' || type === 'divider')"
          (click)="openEditor(isNotepad ? 'editNotepadStatement' : 'editStatement')"
          class="btn btn-sm btn-info"
          data-cy="edit"
          rrTrack
          [rrTooltip]="isNotepad ? 'EDIT_NOTEPAD_SENTENCE' : 'EDIT'"
          permission
          [permission-only]="['template_manage']"
        >
          <span class="fa fa-edit"></span>
        </button>

        <button
          role="button"
          *ngIf="!editMode && type === 'normal'"
          (click)="openEditor(isNotepad ? 'insertNotepadStatement' : 'insertStatement')"
          class="btn btn-sm btn-info"
          rrTrack
          [rrTooltip]="isNotepad ? 'INSERT_NOTEPAD_SENTENCE' : 'INSERT_STATEMENT_BEFORE'"
          permission
          [permission-only]="['template_manage']"
        >
          <span class="fa fa-arrow-up"></span>
        </button>

        <button
          role="button"
          *ngIf="!editMode && (section.type === 'findings' || section.type === 'technique')"
          (click)="toggleClone('key_finding')"
          class="btn btn-sm btn-info hide-if-divider"
          [ngClass]="asteriskStyle"
          rrTrack
          rrTooltip="KEY_FINDING"
          permission
          [permission-only]="['report_manage']"
        >
          <span class="fa fa-asterisk"></span>
        </button>
        <button
          role="button"
          *ngIf="!editMode && (section.type === 'findings' || section.type === 'impression_recommendations')"
          (click)="toggleClone('comment')"
          class="btn btn-sm btn-info hide-if-divider"
          [ngClass]="asteriskStyle"
          rrTrack
          rrTooltip="COMMENT"
          permission
          [permission-only]="['report_manage']"
        >
          <span class="fa fa-comment"></span>
        </button>
        <!--clone impression recommendations-->
        <button
          role="button"
          *ngIf="!editMode && (section.type === 'findings' || section.type === 'comment')"
          (click)="toggleClone('impression_recommendations')"
          class="btn btn-sm btn-info hide-if-divider"
          [ngClass]="asteriskStyle"
          rrTrack
          rrTooltip="CLONE_IMPRESSION_RECOMMENDATIONS"
          permission
          [permission-only]="['report_manage']"
        >
          <span class="fa fa-italic"></span>
        </button>

        <button
          role="button"
          *ngIf="!editMode && type === 'normal' && mandatoryStatement && !justification && topic"
          class="btn btn-sm btn-danger"
          rrTooltip="JUSTIFY_MANDATORY_STATEMENT"
          (click)="mandatoryStatementService.openJustificationModal(topic, mandatoryStatement)"
        >
          <span class="fas fa-exclamation"></span>
        </button>

        <button
          role="button"
          *ngIf="!editMode && type === 'normal' && justification"
          class="btn btn-sm btn-danger"
          rrTooltip="DELETE_MANDATORY_STATEMENT_JUSTIFICATION"
          (click)="mandatoryStatementService.deleteJustification(justification)"
        >
          <span class="fas fa-eraser"></span>
        </button>

        <button
          *ngIf="!editMode && type === 'normal'"
          rrTooltip="GLOBAL_STATEMENT_SEARCH"
          class="btn btn-sm btn-info"
          (click)="copyToGlobalSearch()"
        >
          <i class="fa fa-search"></i>
        </button>

        <button
          *ngIf="!editMode && type === 'normal'"
          class="btn btn-sm btn-info"
          (click)="searchCorrelatedStatements()"
          rrTooltip="CORRELATED_SENTENCE_SEARCH"
        >
          <i class="fas fa-lightbulb"></i>
        </button>
        <button
          role="button"
          *ngIf="editMode && type === 'normal' && !isNotepad"
          (click)="openEditMode(false)"
          class="btn btn-sm btn-danger"
          rrTrack
          rrTooltip="OPEN_EDIT_MODE"
          permission
          [permission-only]="['template_manage']"
        >
          <span class="fas fa-tasks"></span>
        </button>
        <!--icon for copying notepad sentence to template-->
        <button
          role="button"
          *ngIf="!editMode && type === 'normal' && isNotepad"
          (click)="copyToTemplate()"
          class="btn btn-sm btn-info disabled pe-auto"
          rrTrack
          rrTooltip="COPY_STATEMENT_TO_TEMPLATE"
          permission
          [permission-only]="['template_manage']"
          ngbTooltip="Not working"
        >
          <span class="fa fa-copy"></span>
        </button>
        <button
          role="button"
          *ngIf="!editMode && choicesCount < 3 && choicesCount > 0"
          data-cy="single-select"
          (click)="singleStatementSelect()"
          class="btn btn-sm btn-danger hide-if-divider"
          permission
          [permission-only]="['report_manage']"
          rrTrack
          rrTooltip="CHOOSE_ONLY_THIS_STATEMENT"
        >
          <span class="fa fa-plus-circle"></span>
        </button>

        <button
          role="button"
          *ngIf="editMode && isSelected && type === 'normal' && !isNotepad"
          (click)="openMoveMulti()"
          class="btn btn-sm btn-info"
          rrTrack
          rrTooltip="MOVE_MULTI"
          permission
          [permission-only]="['template_manage']"
        >
          <span class="fas fa-arrows-alt"></span>
        </button>
        <!--Categorise sentences-->
        <button
          role="button"
          *ngIf="!editMode && (type === 'normal' || type === 'divider') && (is_in_sc_mode$ | async)"
          (click)="categoriseStatement()"
          [ngClass]="(isSentenceCategorised | async) ? 'btn-success' : 'btn-info'"
          class="btn btn-sm"
          rrTrack
          trackAction="Categorise statement"
          [title]="sentenceCategoriesTooltip | async"
        >
          <span class="fas fa-layer-group"></span>
        </button>
      </div>
    </div>
  </div>

  <div class="w-100" data-no-bubble-statement *ngIf="topic && element">
    <div *ngFor="let choice of choices; trackBy: choiceTracking; let last = last">
      <rr-report-choice
        [choice]="choice"
        [statement]="statement"
        [topic]="topic"
        [element]="element"
        [createInElement]="createInElement"
        [element_choice]="element_choice"
        [section]="section"
        [subsection]="subsection"
        [region]="region"
        [choicesCount]="choicesCount"
        [can_focus_child]="can_focus_child"
        [last]="last"
        tabindex="0"
        class="list-group-item list-group-item-action active px-1 py-0 rounded-0 d-flex justify-content-between"
        [statementFrequency]="frequency"
        [filterType]="filterType"
        [proposed]="proposed"
      >
      </rr-report-choice>
    </div>
  </div>
</div>
