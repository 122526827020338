import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { StatementChoiceHttpService } from './statement-choice.service';
import {
  ChoiceCreationObject,
  RegionChoiceCopyPayload,
  StatementChoiceCreatePayload,
  UpdateChoicePayload,
} from './statement-choice.type';

@Injectable()
export class StatementChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: StatementChoiceHttpService,
  ) {}

  create(statementChoice: StatementChoiceCreatePayload) {
    return this.service.create(statementChoice).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(statementChoiceId: number, changes: UpdateChoicePayload) {
    return this.service.update(statementChoiceId, changes).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(statementChoiceId: number) {
    return this.service.delete(statementChoiceId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteMany(statementChoiceIds: number[]) {
    return this.service.deleteMany(statementChoiceIds).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createMany(topicId: number, statements: ChoiceCreationObject[]) {
    return this.service.createManyChoices(topicId, statements).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  copyRegionChoices(payload: RegionChoiceCopyPayload) {
    return this.service.copyRegionChoices(payload).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createPredictionChoices(topicId: number, type: 'TITLE' | 'PREDICTION') {
    return this.service.createPredictionChoices(topicId, type).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
