import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ReportAccessEvent>();
const selectFeature = (state: AppState) => state.access_event;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectAccessEventsInReport = (reportId: number) =>
  createSelector(selectAll, (events) => events.filter((e) => e.report_id === reportId));

export const fromReportAccessEvent = {
  selectIds,
  selectEntities,
  selectAll,
  selectAccessEventsInReport,
};
