import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromKeywordAbbreviation, KeywordAbbreviationEffect } from 'app/store/keyword-abbr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { EditKeywordAbbreviationModalComponent } from '../../modals/edit-keyword-abbreviation-modal/edit-keyword-abbreviation-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, EditKeywordAbbreviationModalComponent],
  selector: 'rr-abbreviations',
  templateUrl: './abbreviations.component.html',
})
export class AbbreviationsComponent implements OnInit, OnDestroy {
  allAbbreviations: RR.KeywordAbbreviation[];
  abbreviations: RR.KeywordAbbreviation[];

  searchForm = new FormGroup({
    abbreviation: new FormControl('', { nonNullable: true }),
    keyword: new FormControl('', { nonNullable: true }),
  });

  LIMIT = 10;

  totalResults = 0;
  shownResults = 0;
  subscription = new Subscription();

  constructor(
    private keywordAbbrEffect: KeywordAbbreviationEffect,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.subscription.add(this.keywordAbbrEffect.findAll().subscribe());
    this.subscription.add(
      this.store.select(fromKeywordAbbreviation.selectAll).subscribe((abbreviations) => {
        this.allAbbreviations = abbreviations;
        this.search();
      }),
    );

    this.subscription.add(
      this.searchForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
        this.search();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search(showAll = false) {
    const { abbreviation, keyword } = this.searchForm.value;
    let filtered = this.allAbbreviations;

    if (abbreviation !== '' && abbreviation !== undefined) {
      filtered = filtered.filter((abbr) => {
        return abbr.abbreviation.toLowerCase().includes(abbreviation.toLowerCase());
      });
    }

    if (keyword !== '' && keyword !== undefined) {
      filtered = filtered.filter((abbr) => {
        return abbr.keyword.toLowerCase().includes(keyword.toLowerCase());
      });
    }

    this.totalResults = filtered.length;

    if (showAll) {
      this.shownResults = filtered.length;
      this.abbreviations = filtered;
    } else {
      this.shownResults = filtered.slice(0, this.LIMIT).length;
      this.abbreviations = filtered.slice(0, this.LIMIT);
    }
  }

  deleteKeywordAbbr(id: number) {
    this.subscription.add(this.keywordAbbrEffect.delete(id).subscribe());
  }

  openEditKeywordAbbrModal(id?: number) {
    EditKeywordAbbreviationModalComponent.open(this.modalService, id);
  }
}
