import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BillingItemReferrerTypeActions } from './billing-item-referrer-type.action';

export interface BillingItemReferrerTypeState extends EntityState<RR.ReferrerTypeBillingItem> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.ReferrerTypeBillingItem>({
  selectId: (referrerTypeBillingItem: RR.ReferrerTypeBillingItem) => referrerTypeBillingItem.referrer_type,
});

const initialState = adapter.getInitialState({ loaded: false });

export const billingItemReferrerTypeReducer = createReducer(
  initialState,
  on(BillingItemReferrerTypeActions.findAllSuccess, (state, { billingItemsReferrerType }) => {
    return adapter.setAll(billingItemsReferrerType, { ...state, loaded: true });
  }),
  on(BillingItemReferrerTypeActions.updateSuccess, (state: BillingItemReferrerTypeState, { billingItemReferrerType }) =>
    adapter.upsertOne(billingItemReferrerType, state),
  ),

  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
