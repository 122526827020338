import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { UserActions } from 'app/store/user/user';
import { map, tap } from 'rxjs/operators';

import { AuditEventActions } from './audit-event.action';
import { AuditEventHttpService } from './audit-event.service';

@Injectable()
export class AuditEventEffect {
  constructor(
    private store: Store<AppState>,
    private service: AuditEventHttpService,
  ) {}

  create(auditEvent: Partial<RR.AuditEvent>) {
    return this.service.create(auditEvent).pipe(
      map((auditEvent: RR.AuditEvent) => AuditEventActions.createSuccess({ auditEvent })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createRegistrationEvent(auditEvent: Partial<RR.AuditEvent>) {
    return this.service.createRegistration(auditEvent).pipe(
      map((auditEvent: RR.AuditEvent) => AuditEventActions.createSuccess({ auditEvent })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAll(topicId: number) {
    return this.service.findAll(topicId).pipe(
      map(({ audit_events, users }) => {
        return AuditEventActions.findSuccessBatch({
          actions: {
            findAuditEvent: AuditEventActions.findManySuccess({ auditEvents: audit_events }),
            findUser: UserActions.findAllSuccess({ users }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
