<form
  [formGroup]="form"
  class="bootstrap-validate"
  permission
  [permission-only]="['data_management']"
  [permission-hidden]="true"
>
  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Institution Name <span class="text-danger fa fa-asterisk fa-xs"></span></label>
      <input formControlName="name" class="form-control" />
      <span class="invalid-feedback" *ngIf="name.errors?.required">Institution name is required</span>
    </div>

    <div class="form-group mb-2 col">
      <label class="control-label">Type <span class="text-danger fa fa-asterisk fa-xs"></span></label>
      <select class="form-select" formControlName="type">
        <option *ngFor="let institutionType of institutionTypes" [value]="institutionType">
          {{ institutionTypeMap[institutionType] }}
        </option>
      </select>
      <span class="invalid-feedback" *ngIf="type.errors?.required">Type is required</span>
    </div>
  </div>
  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Address</label>
      <input formControlName="address" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-2 col col-sm-6">
      <label class="control-label">Suburb</label>
      <input formControlName="suburb" class="form-control" />
    </div>
    <div class="form-group mb-2 col col-sm-3">
      <label class="control-label">State</label>
      <input formControlName="state" class="form-control" />
    </div>
    <div class="form-group mb-2 col col-sm-3">
      <label class="control-label">Post code</label>
      <input formControlName="postcode" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Contact person</label>
      <input formControlName="contact_person" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Phone</label>
      <input formControlName="phone" class="form-control" />
      <span class="invalid-feedback" *ngIf="phone.errors?.pattern"> Please enter 10 digit phone number </span>
    </div>
    <div class="form-group mb-2 col">
      <label class="control-label">Email</label>
      <input formControlName="email" class="form-control" />
      <span class="invalid-feedback" *ngIf="email.errors?.pattern"> Email is invalid </span>
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Notes</label>
      <textarea formControlName="notes" class="form-control"> </textarea>
    </div>
  </div>
</form>
