import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ReportMergeConflictActions } from './report-merge-conflict.action';

export interface ReportMergeConflictState extends EntityState<RR.EntityMergeConflict<RR.Report>> {
  selections: RR.MergeConflictSelections<RR.Report>;
}

const adapter = createEntityAdapter<RR.EntityMergeConflict<RR.Report>>({
  selectId(reportMergeConflict) {
    // Store these entities by field_name.
    return reportMergeConflict.field_name;
  },
});

const initialState = adapter.getInitialState({
  selections: {},
});

function selectAllSide(state: ReportMergeConflictState, fieldNames: string[], side: RR.MergeConflictSide) {
  const selections = fieldNames.reduce((selections, fieldName) => {
    // @ts-expect-error noImplicitAny
    selections[fieldName] = side;
    return selections;
  }, {});
  return {
    ...state,
    selections: selections,
  };
}

export const reportMergeConflictReducer = createReducer(
  initialState,
  on(
    ReportMergeConflictActions.selectConflictSide,
    (state: ReportMergeConflictState, { reportMergeConflict: conflict, side }) => ({
      ...state,
      selections: { ...state.selections, [conflict.field_name]: side },
    }),
  ),
  on(ReportMergeConflictActions.selectAllConflictSide, (state: ReportMergeConflictState, { fieldNames, side }) => {
    return selectAllSide(state, fieldNames || Object.keys(state.selections), side);
  }),

  on(ReportMergeConflictActions.findAllSuccess, (state: ReportMergeConflictState, { reportMergeConflicts }) =>
    adapter.setAll(reportMergeConflicts, state),
  ),
);
