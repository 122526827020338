import { Component, Input } from '@angular/core';
import { NoContentDirective } from 'app/shared/directives/no-content.directive';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'rr-registration-link',
  standalone: true,
  imports: [SharedModule, NoContentDirective],
  templateUrl: './registration-link.component.html',
  styleUrls: ['./registration-link.component.css'],
})
export class RegistrationLinkComponent {
  @Input() btnClass: string | undefined = 'btn btn-sm';
  @Input() btnStatus = true;
  @Input() newTab = false;
  @Input() report: RR.Report;
}
