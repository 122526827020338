<div ngbTooltip="temporary note" class="d-flex align-items-center w-100" (click)="selectNote()">
  <span>
    {{ note.text }}
  </span>
  <div class="d-flex ms-auto flex-nowrap">
    <button role="button" class="btn btn-sm py-0 px-1" (click)="$event.stopPropagation(); deleteNote()">
      <span class="fa fa-trash-alt text-danger"></span>
    </button>
  </div>
</div>
