<div class="modal-header">
  <h5 class="modal-title">Link Medipass Transaction to Invoice</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form [formGroup]="form" class="bootstrap-validate">
    <div class="d-flex">
      <input placeholder="Transaction id" class="form-control mb-0 me-2" type="text" formControlName="transactionId" />
      <button type="submit" class="btn btn-primary text-nowrap" (click)="linkMedipassTransaction()">
        Link Transaction
      </button>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-light ms-2" role="button" (click)="activeModal.dismiss()">Close</button>
</div>
