import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { PermissionActions } from './permission.action';

export type PermissionState = EntityState<RR.Permission>;

const adapter = createEntityAdapter<RR.Permission>();

const initialState = adapter.getInitialState();

export const permissionReducer = createReducer(
  initialState,
  on(PermissionActions.findAllSuccess, (state: PermissionState, { permissions }) =>
    adapter.upsertMany(permissions, state),
  ),
  // Don't clear Permissions data on open/close editor because because they are required
);
