import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromRouter } from '../router/router.selector';
import { fromElement } from '../template/element/element.selector';
import { fromRegionSet } from '../template/region-set/region-set.selector';
import { fromRegion } from '../template/region/region.selector';
import { fromSection } from '../template/section/section.selector';
import { fromStatementSet } from '../template/statement-set/statement-set.selector';
import { fromSubsection } from '../template/subsection/subsection.selector';
import { TopicState, Breadcrumb, EditorState } from './editor.state';

const selectFeature = (state: AppState) => state.editor;

// @ts-expect-error strictNullChecks
const selectCurrentTopicState = createSelector(
  selectFeature,
  fromRouter.selectTopicId,
  (editor: EditorState, topicId: number) => editor.topics[topicId],
);

const selectLastReportAccessEvent = createSelector(
  selectFeature,
  (editor: EditorState) => editor.lastReportAccessEvent,
);

const selectIsPrefillOpen = createSelector(selectFeature, (editor: EditorState) => editor.prefill);

const selectIsTemplateChooserOpen = createSelector(selectFeature, (editor: EditorState) => editor.templateChooser);

const selectUnderlinePrefilled = createSelector(selectFeature, (editor: EditorState) => editor.underlinePrefilled);

const selectScheduleSendToVoyagerTask = createSelector(
  selectFeature,
  (editor: EditorState) => editor.scheduleSendToVoyager,
);

const selectMostRecentCategories = createSelector(selectFeature, (editor: EditorState) => editor.recentCategories);
const selectMostRecentTop20Categories = createSelector(
  selectFeature,
  (editor: EditorState) => editor.recentTop20Categories,
);

const selectClipboard = createSelector(selectFeature, (editor: EditorState) => editor.clipboard);
const selectIsSidebarOpen = createSelector(selectFeature, (editor: EditorState) => editor.sidebarOpen);
const selectVoiceSearchResults = createSelector(selectFeature, (editor: EditorState) => editor.voiceResults);
const selectElementFilterType = createSelector(selectFeature, (editor: EditorState) => editor.elementFilterType);
const selectEditMode = createSelector(selectFeature, (editor: EditorState) => editor.editMode);
const selectSelectedStatements = createSelector(selectFeature, (editor: EditorState) => editor.selectedStatements);
const selectEditorTopic = (topic_id: number) =>
  createSelector(selectFeature, (editor: EditorState) => editor.topics[topic_id]);

export const fromEditor = {
  selectFeature,
  selectCurrentTopicState,
  selectLastReportAccessEvent,
  selectIsPrefillOpen,
  selectIsTemplateChooserOpen,
  selectUnderlinePrefilled,
  selectScheduleSendToVoyagerTask,
  selectMostRecentCategories,
  selectMostRecentTop20Categories,
  selectClipboard,
  selectIsSidebarOpen,
  selectVoiceSearchResults,
  selectElementFilterType,
  selectEditMode,
  selectSelectedStatements,
  selectEditorTopic,
};

const selectCurrentBreadcrumb = createSelector(
  fromEditor.selectCurrentTopicState,
  (topicState?: TopicState) => topicState?.breadcrumb,
);

const selectCurrentSection = createSelector(
  selectCurrentBreadcrumb,
  fromSection.selectEntities,
  (breadcrumb: Breadcrumb | undefined, sections: Dictionary<RR.Section>) =>
    (breadcrumb?.section_id && sections[breadcrumb.section_id]) || undefined,
);

/**
 * The subsections within the section of the current breadcrumb
 *
 * This provides the tools to change between subsections of the current
 * breadcrumb.
 */
const selectSubsectionOptions = createSelector(
  selectCurrentSection,
  fromSubsection.selectEntities,
  (section: RR.Section | undefined, subsections: Dictionary<RR.Subsection>) =>
    section?.subsection_ids.map((subsectionId) => subsections[subsectionId]).filter((s): s is RR.Subsection => !!s) ||
    [],
);

const selectCurrentSubsection = createSelector(
  selectCurrentBreadcrumb,
  fromSubsection.selectEntities,
  (breadcrumb: Breadcrumb | undefined, subsections: Dictionary<RR.Subsection>) =>
    (breadcrumb?.subsection_id && subsections[breadcrumb.subsection_id]) || undefined,
);

const selectLastClickedBreadcrumb = createSelector(
  fromEditor.selectCurrentTopicState,
  (topicState?: TopicState) => topicState?.lastClickedBreadcrumb,
);

/**
 * Elements within the currently selected subsection
 *
 * This provides the list of elements that are contained within the currently
 * selected subsection.
 */
const selectElementOptions = createSelector(
  selectCurrentSubsection,
  fromElement.selectEntities,
  (subsection: RR.Subsection | undefined, elements: Dictionary<RR.Element>) =>
    subsection?.elements.map((elementId) => elements[elementId]).filter((e): e is RR.Element => !!e),
);

/**
 * Statement Sets within the currently selected subsection
 *
 * This provides the list of statement sets that are contained within the
 * currently selected subsection.
 *
 * TODO(template-structure): This should be removed and the elements used
 * instead. This is implemented to provide compatibility with the current
 * method of doing things.
 */
const selectStatementSetOptions = createSelector(
  selectElementOptions,
  fromStatementSet.selectEntities,
  (elements: RR.Element[] | undefined, statementSets: Dictionary<RR.StatementSet>) =>
    elements?.map((element) => statementSets[element.statement_set_id]),
);

/**
 * Regions within the currently selected subsection
 *
 * This provides the list of regions that are contained within the currently
 * selected subsection.
 */
const selectRegionOptions = createSelector(
  selectCurrentSubsection,
  fromRegionSet.selectEntities,
  fromRegion.selectEntities,
  (subsection: RR.Subsection | undefined, regionSets: Dictionary<RR.RegionSet>, regions: Dictionary<RR.Region>) =>
    (subsection?.region_set_id &&
      regionSets[subsection.region_set_id]?.region_ids.map((regionId) => regions[regionId])) ||
    undefined,
);

const selectCurrentElement = createSelector(
  selectCurrentBreadcrumb,
  fromElement.selectEntities,
  (breadcrumb: Breadcrumb | undefined, elements: Dictionary<RR.Element>) =>
    (breadcrumb?.element_id && elements[breadcrumb.element_id]) || undefined,
);

const selectCurrentRegion = createSelector(
  selectCurrentBreadcrumb,
  fromRegion.selectEntities,
  (breadcrumb: Breadcrumb | undefined, regions: Dictionary<RR.Region>) =>
    (breadcrumb?.region_id && regions[breadcrumb.region_id]) || undefined,
);

export const fromBreadcrumb = {
  selectCurrent: selectCurrentBreadcrumb,
  selectCurrentSection,
  selectSubsectionOptions,
  selectCurrentSubsection,
  selectElementOptions,
  selectRegionOptions,
  selectStatementSetOptions,
  selectCurrentElement,
  selectCurrentRegion,
  selectLastClickedBreadcrumb,
};
