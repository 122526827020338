import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { ModalTimerService } from 'app/core/services/modal-timer.service';
import { SelectorService } from 'app/core/services/selector.service';
import { ModalAuthComponent } from 'app/shared/modals/modal-auth/modal-auth.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { FeedbackHttpService } from 'app/store/report/feedback';
import { fromSetting } from 'app/store/setting/setting.selector';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { UserFeedbackListComponent } from '../../components/user-feedback-list/user-feedback-list.component';

@Component({
  standalone: true,
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  imports: [CommonModule, SharedModule, UserFeedbackListComponent, ModalAuthComponent],
})
export class FeedbackModalComponent implements OnInit, OnDestroy {
  @Input() noNewFeedbacks: number;

  kioskState: boolean;
  isAuthorised = false;
  reportId: number | undefined;
  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private selectorService: SelectorService,
    private feedbackHttpService: FeedbackHttpService,
    private modalTimerService: ModalTimerService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store.select(fromSetting.selectKiosk).subscribe((b) => {
        this.kioskState = b;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAuthenticated() {
    this.isAuthorised = true;
  }

  static open(modalService: NgbModal, noNewFeedbacks: number, reportId: number | undefined) {
    const modalRef = modalService.open(FeedbackModalComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: false,
      scrollable: true,
      windowClass: 'feedback-modal',
    });
    const componentInstance: FeedbackModalComponent = modalRef.componentInstance;
    componentInstance.noNewFeedbacks = noNewFeedbacks;
    componentInstance.reportId = reportId;
    componentInstance.modalTimerService.setLastOpenedTime('feedbackModal', new Date());
    return modalRef;
  }

  closeModal() {
    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(
          filterDefined(),
          take(1),
          switchMap((user) => this.feedbackHttpService.getFeedbackStats({ initials: user.initials })),
        )
        .subscribe((ret) => {
          this.activeModal.close(ret.feedback_count);
        }),
    );
  }
}
