import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { ProofReadReportComponent } from 'app/shared/components/proof-read-report/proof-read-report.component';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { stripPunctuation } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { ReportEffect } from 'app/store/report/report';
import { fromSendEvent } from 'app/store/report/send-event';
import { SendEventEffect } from 'app/store/report/send-event/send-event.effect';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { auditTime, startWith, switchMap, take, tap } from 'rxjs/operators';

import { ProviderComponent } from './provider.component';

interface ProviderSearchResult {
  list: RR.Provider[];
}

@Component({
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, ProviderComponent, ReferrerNameComponent],
  selector: 'rr-provider-lookup',
  templateUrl: './provider-lookup.component.html',
  styleUrls: ['./provider-lookup.component.scss'],
})
export class ProviderLookupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() report: RR.Report;
  @Input() referrer?: RR.Referrer;
  providerSearchResult: ProviderSearchResult | null;
  practiceProviders: any;
  subscription = new Subscription();
  isLoading = false;
  @ViewChild('searchByName', { static: true }) searchByNameInput: ElementRef<HTMLInputElement>;
  @ViewChild('searchByProviderId', { static: true }) searchByProviderIdInput: ElementRef<HTMLInputElement>;
  @ViewChild('sendMOBtn') sendMOBtn: ElementRef<HTMLButtonElement>;

  provider: RR.Provider | undefined;
  ccProviders: RR.Provider[] = [];
  sendEvents: RR.SendEvent[];
  reportText = new FormControl<string | undefined>(undefined, { nonNullable: true });
  sendMessage: { message: string; type: 'error' | 'success' };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private sendEventEffect: SendEventEffect,
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private modalService: NgbModal,
    private reportService: ReportService,
  ) {}

  searchByName(name: string) {
    let last: string;
    let given: string[];
    const commaIndex = name.indexOf(',');
    if (commaIndex !== -1) {
      last = name.slice(0, commaIndex);
      given = [name.slice(commaIndex + 1)];
    } else {
      [last, ...given] = name.split(' ');
    }
    const base = '/api/mo';
    // const base = 'https://adelaidemri.mocloud.com.au';
    return this.http
      .get<ProviderSearchResult>(
        `${base}/rest/provider/lookup/byname?FAMILYNAME=${last}&GIVENNAME=${given.join(' ')}`,
        {
          responseType: 'json',
        },
      )
      .pipe(this.messageService.handleHttpErrorPipe);
  }

  searchByProvider(text: string) {
    text = stripPunctuation(text);
    const base = '/api/mo';
    return this.http
      .get<ProviderSearchResult>(`${base}/rest/provider/lookup/byid?ID=${text}`, {
        responseType: 'json',
      })
      .pipe(this.messageService.handleHttpErrorPipe);
  }

  chooseProvider(provider: RR.Provider) {
    if (this.provider) {
      this.ccProviders.push(provider);
    } else {
      this.provider = provider;
    }
    this.focusSendButton();
  }

  focusSendButton() {
    requestAnimationFrame(() => {
      this.sendMOBtn.nativeElement.focus();
    });
  }

  unchooseProvider(provider: RR.Provider) {
    if (this.provider === provider) {
      this.provider = undefined;
    }
    if (this.ccProviders.includes(provider)) {
      this.ccProviders.splice(this.ccProviders.indexOf(provider), 1);
    }
  }

  isProviderCC(provider: RR.Provider) {
    return this.ccProviders.some((p) => p.staffIDList[0].idNumber === provider.staffIDList[0].idNumber);
  }

  isProviderTo(provider: RR.Provider) {
    return this.provider?.staffIDList[0].idNumber === provider.staffIDList[0].idNumber;
  }

  checkThenSend() {
    const hasSentToMedicalObjects = this.sendEvents.some((mo) => mo.communication_type === 'MEDICAL_OBJECTS');

    if (hasSentToMedicalObjects) {
      const modalRef = ConfirmMessageModalComponent.open({
        modalService: this.modalService,
        header: 'Confirm',
        message: `A message has already been sent via Medical Objects. Would you like to send it again?`,
        btnConfirmText: 'Yes',
      });
      modalRef.result.then(
        () => {
          this.send();
        },
        () => {
          /* do nothing */
        },
      );
    } else {
      this.send();
    }
  }

  send() {
    if (this.provider === undefined) {
      throw new TypeError('provider is undefined');
    }
    this.sendEventEffect
      .sendMedicalObject({
        provider: this.provider,
        cc: this.ccProviders,
        report_id: this.report.id,
      })
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: 'Check delivery status in Medical-Objects Explorer Online https://adelaidemri.mocloud.com.au',
            type: 'success',
          });
          this.sendMessage = { message: 'Successfully sent', type: 'success' };
        },
        error: (error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            this.messageService.httpErrorMessage(error, { title: 'Send report medical object failed!' });
            const message = this.messageService.getErrorMessage(error);
            this.sendMessage = {
              message: `Failed to send: ${message}`,
              type: 'error',
            };
          }
        },
      });
  }

  ngOnInit() {
    this.subscription.add(
      this.reportService.reportText$.subscribe((text: string | undefined) => {
        this.reportText.patchValue(text); // Assign the received text to a component property
      }),
    );
    this.subscription.add(
      this.reportEffect
        .findReportText(this.report.id)
        .pipe(take(1))
        .subscribe((action) => {
          const initalText = action.report.text;
          this.reportService.setReportText(initalText);
        }),
    );

    this.subscription.add(
      merge(
        fromEvent(this.searchByNameInput.nativeElement, 'input'),
        fromEvent(this.searchByProviderIdInput.nativeElement, 'input'),
      )
        .pipe(auditTime(300))
        .pipe(
          tap(() => {
            this.isLoading = true;
          }),
          startWith(null),
          switchMap((event: any) => {
            if (event && event.target === this.searchByNameInput.nativeElement) {
              return this.searchByName(event.target.value);
            } else if (event && event.target === this.searchByProviderIdInput.nativeElement) {
              return this.searchByProvider(event.target.value);
            } else {
              return of(null);
            }
          }),
        )
        .subscribe((result) => {
          this.providerSearchResult = result;
          this.isLoading = false;
          if (this.providerSearchResult) {
            this.providerSearchResult.list.forEach((r) => {
              // Sort medicare providers to the top (AUSHICPR). Prefer those over the "Medical-Objects" namespaceID
              // https://kb.medical-objects.com.au/display/PUB/Provider+Identifiers
              // "When a Provider Number and an MO Routing ID are available for the same recipient always use the Provider Number as first preference. MO Routing ID's are only to be used when a provider number is not available."
              r.staffIDList.sort((a, b) => {
                return (
                  Number(b.assigningAuthority.namespaceID === 'AUSHICPR') -
                  Number(a.assigningAuthority.namespaceID === 'AUSHICPR')
                );
              });
            });

            // Auto select the provider if there is only one search result and only auto select it when it is null
            if (this.providerSearchResult.list.length === 1 && !this.provider) {
              this.provider = this.providerSearchResult.list[0];
              this.focusSendButton();
            }
          }
        }),
    );

    this.subscription.add(
      this.store.select(fromSendEvent.selectSendEventsInReport(this.report.id)).subscribe((events) => {
        this.sendEvents = events;
      }),
    );
  }

  openProofRead() {
    ProofReadReportComponent.open(this.modalService, this.report.id);
  }

  ngAfterViewInit() {
    this.searchByProviderIdInput.nativeElement.value = this.referrer?.medicare_provider_number ?? '';
    this.searchByProviderIdInput.nativeElement.dispatchEvent(new Event('input'));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
