<div *ngIf="sameDayReports.length > 0" class="mx-auto">
  <h5>
    <span class="fa fa-lg fa-exclamation-triangle text-warning"> </span> This patient has other registrations made on
    the same day.
  </h5>

  <table class="table table-striped">
    <thead>
      <tr>
        <th [style.width.%]="20">Accession No.</th>
        <th [style.width.%]="60">Scan Code</th>
        <th [style.width.%]="20">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of sameDayReports">
        <td>
          <a class="me-2" [routerLink]="['/report', report.id, 'edit']"
            ><rr-report-accession-number [report]="report"></rr-report-accession-number
          ></a>
        </td>
        <td>
          <ng-container *ngIf="report.scan_code_id">
            <rr-scan-code-headline
              *ngIf="report.scan_code_id | storeSelectPipe: selectScanCode | async as scanCode"
              [scanCode]="scanCode"
              [showNotes]="true"
            ></rr-scan-code-headline>
          </ng-container>
        </td>
        <td><rr-registration-link [report]="report">Registration (Q)</rr-registration-link></td>
      </tr>
    </tbody>
  </table>
</div>
