import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  ELEMENT_SCROLL_ID,
  ELEMENT_SELECTOR,
  NEW_REPORT_SPECIFIC,
  NEW_TEMPLATE_STATEMENT,
  NOTEPAD_SCROLL_ID,
  STATEMENT_LIST_ID,
} from 'app/app.constants';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService, LoadElement } from 'app/core/services/editor.service';
import { MandatoryStatementService } from 'app/core/services/mandatory-statement.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { ElementFilterType } from 'app/store/editor';
import { fromCurrentTopic, fromTopicChoices } from 'app/store/report/topic';
import { SessionEffect } from 'app/store/session';
import { fromElement } from 'app/store/template/element';
import { fromStatementSet, StatementSetEffect } from 'app/store/template/statement-set';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import { CategoryEditModalComponent } from '../category/category-edit-modal/category-edit-modal.component';
import { CategoryComponent } from '../category/category.component';
import { DividerComponent } from '../divider/divider.component';
import { NotepadModalComponent } from '../notepad/notepad-modal.component';
import { PrefillService } from '../prefill/prefill.service';
import { ReportSpecificChoiceComponent } from '../report-specific-choice/report-specific-choice.component';
import { EditType, StatementEditComponent } from '../statement-edit/statement-edit.component';
import { StatementEditModalComponent } from '../statement/statement-edit-modal.component';
import { StatementComponent } from '../statement/statement.component';
import { TopStatementButtonGroupComponent } from '../top-statement-button-group/top-statement-button-group.component';
import { ElementDeleteModalComponent } from './element-delete-modal.component';
import { ElementUsageModalComponent } from './element-usage-modal.component';

let nextId = 0;

@Component({
  selector: ELEMENT_SELECTOR,
  templateUrl: './element.component.html',
  styleUrls: ['./element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TopStatementButtonGroupComponent,
    SharedModule,
    FormsModule,
    StatementEditComponent,
    DividerComponent,
    CdkDropList,
    CdkDrag,
    StatementComponent,
    NgbPopover,
    ReportSpecificChoiceComponent,
    CategoryComponent,
  ],
})
@LifecycleLogger
export class ElementComponent implements OnInit, OnDestroy {
  STATEMENT_LIST_ID = STATEMENT_LIST_ID;
  filterStatements: RR.Statement[] = []; // Filter statements after applying the threshold
  @ViewChild(TopStatementButtonGroupComponent) filterStatementComponent: TopStatementButtonGroupComponent | undefined;
  @ViewChild('statementSetNameInput') statementSetNameInput: ElementRef<HTMLInputElement>;
  ELEMENT_SCROLL_ID = ELEMENT_SCROLL_ID;
  @Input() @BindObservable() element: RR.Element;
  element$: Observable<RR.Element>;
  @Input() @BindObservable() region: RR.Region | undefined;
  region$: Observable<RR.Region | undefined>;
  @Input() @BindObservable() subsection: RR.Subsection;
  subsection$: Observable<RR.Subsection>;
  @Input() @BindObservable() section: RR.Section;
  section$: Observable<RR.Section>;
  @Input() createInElement: RR.Element; // selected element for notepad
  @Input() report: RR.Report;

  // Element component can be used in editor, prefill divider modal and notepad modal
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD';
  // TODO(store): Extract the topic from the router store
  topic$: Observable<RR.Topic>;
  element_choice: RR.ElementChoice;
  statement_set: RR.StatementSet;
  statement_set_name = '';
  show_label = true;
  creatingSpecific2 = false;
  highlight: Observable<boolean>;
  subscription: Subscription = new Subscription();
  editingStatement = false;
  legacyChoices: Observable<RR.StatementChoice[]>;
  reportSpecificChoices: RR.StatementChoice[];
  dividers: Observable<RR.Statement[]>;
  categories: Observable<RR.Category[]>;
  trackById = trackById;
  currentUser: RR.User;
  NEW_TEMPLATE_STATEMENT = NEW_TEMPLATE_STATEMENT;
  NEW_REPORT_SPECIFIC = NEW_REPORT_SPECIFIC;
  numberOfCategorisedDividers: Observable<number>;
  numberOfCategorisedStatements: Observable<number>;
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();
  filterType: ElementFilterType;
  loadElementEvent?: LoadElement;
  loaded = false;
  statementGroupIds: number[] = [];
  incompleteMandatoryStatements$: Observable<RR.MandatoryStatement[] | undefined>;

  /**
   * Element component can be used in Editor, Prefill Divider modal or Notepad modal
   * Keep instance id for controlling scrolling behaviour triggered by top-statement-button-group
   */
  instanceId = `${nextId++}`;

  constructor(
    private templateService: TemplateService,
    private sessionEffect: SessionEffect,
    private reportService: ReportService,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private messageService: MessageService,
    private statementSetEffect: StatementSetEffect,
    private selectorService: SelectorService,
    private prefillService: PrefillService,
    protected mandatoryStatementService: MandatoryStatementService,
  ) {}

  topStatementListChanged(topStatements: RR.Statement[]) {
    this.filterStatements = topStatements;
    this.filterStatementsGroup(this.filterStatements);
    this.cd.detectChanges();
  }

  getStatementFrequency(statement_id: number) {
    if (!this.filterStatementComponent) return 0;
    return this.filterStatementComponent.getStatementFrequency(statement_id);
  }

  expandDivider(divider_id: number) {
    if (!this.filterStatementComponent) return;
    this.filterStatementComponent.expandDivider(divider_id);
  }

  ngOnInit() {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());

    this.subscription.add(
      this.element$
        .pipe(
          switchMap((element) =>
            this.store
              .select(fromStatementSet.selectLoadedInStatementSet(element.statement_set_id))
              .pipe(map((loaded) => ({ loaded, element }))),
          ),
        )
        .subscribe(({ loaded, element }) => {
          this.loaded = loaded;
          if (loaded && this.loadElementEvent?.elementId === element.id) {
            this.loadElementEvent.doneCallback();
            this.loadElementEvent = undefined;
          }
        }),
    );

    this.loadElementEvent = undefined;
    this.subscription.add(
      combineLatest([this.element$, this.topic$])
        .pipe(
          map(([element, topic]) => ({
            template_id: topic.template_id,
            statement_set_id: element.statement_set_id,
          })),
          distinctUntilChanged(
            (prev, curr) => prev.template_id === curr.template_id && prev.statement_set_id === curr.statement_set_id,
          ),
          switchMap(({ template_id, statement_set_id }) =>
            this.templateService
              .loadStatementSet(statement_set_id, template_id)
              // Load prefill topics forcing the search
              .pipe(tap(() => this.prefillService.manualSearchSubject.next(null))),
          ),
        )
        .subscribe(),
    );

    this.numberOfCategorisedDividers = this.getNumberOfCategorisedStatements('DIVIDER');
    this.numberOfCategorisedStatements = this.getNumberOfCategorisedStatements('STATEMENT');

    this.highlight = combineLatest([this.topic$, this.region$, this.element$]).pipe(
      switchMap(([topic, region, element]) => {
        return this.editorService.getHighlight(element.id, topic.id, undefined, region ? region.id : undefined);
      }),
    );

    this.dividers = this.element$.pipe(
      switchMap((element) => this.templateService.getDividers(element.statement_set_id)),
    );

    // @ts-expect-error strictNullChecks
    this.categories = this.element$.pipe(
      switchMap((element) => this.templateService.getCategories(element.statement_set_id)),
    );

    const statementSet$ = this.element$.pipe(
      switchMap((element) => this.store.select(fromElement.selectStatementSet(element.id))),
    );
    this.subscription.add(
      statementSet$.subscribe((statementSet) => {
        // @ts-expect-error statement_set
        this.statement_set = statementSet;
      }),
    );

    // Made a function because the other observables derived from this were not receiving values.
    const getElementChoice$ = () => {
      return this.selectorService.selectCurrentTopicIfLoaded().pipe(
        filterDefined(),
        switchMap((topic) => combineLatest([of(topic), this.section$, this.subsection$, this.region$, this.element$])),
        switchMap(([topic, section, subsection, region, element]) => {
          return this.store
            .select(
              fromTopicChoices.selectElementChoices(
                topic.id,
                section.id,
                subsection.id,
                region ? region.id : null,
                element.type === 'element' ? element.id : this.createInElement.id,
              ),
            )
            .pipe(map((elementChoices) => elementChoices[0]));
        }),
      );
    };

    this.subscription.add(
      getElementChoice$().subscribe((element_choice) => {
        this.element_choice = element_choice;
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      getElementChoice$()
        .pipe(
          switchMap((element_choice) => {
            return !element_choice ? of([]) : this.reportService.getReportSpecificChoices(element_choice.id);
          }),
        )
        .subscribe((specificChoices) => {
          this.reportSpecificChoices = specificChoices;
        }),
    );

    this.legacyChoices = getElementChoice$().pipe(
      switchMap((element_choice) =>
        !element_choice ? of([]) : this.reportService.getLegacyChoices(element_choice.id),
      ),
    );

    this.subscription.add(
      this.editorService.elementFilterType().subscribe((type) => {
        this.filterType = type;
      }),
    );

    this.subscription.add(
      this.editorService.focusEvents.pipe(filter((e): e is LoadElement => e?.type === 'LoadElement')).subscribe((e) => {
        if (this.element.id === e.elementId && this.loaded) {
          e.doneCallback();
          this.loadElementEvent = undefined;
        } else {
          this.loadElementEvent = e;
        }
      }),
    );

    this.subscription.add(
      this.editorService.toggleBlueStatementSearch$.subscribe(() => {
        this.creatingSpecific2 = true;
      }),
    );

    this.incompleteMandatoryStatements$ = this.topic$.pipe(
      switchMap((topic) => this.reportService.getIncompleteMandatoryStatements(topic)),
    );
  }

  get region_id() {
    return this.region ? this.region.id : undefined;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  setStatementSetName = () => {
    this.subscription.add(
      this.statementSetEffect.update(this.element.statement_set_id, { name: this.statement_set_name }).subscribe(() => {
        this.show_label = true;
      }),
    );
  };

  // @ts-expect-error noImplicitReturns
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  toggleLabel = () => {
    if (!this.show_label) {
      return (this.show_label = true);
    }

    this.sessionEffect
      .authorise({ only: ['template_manage'] })
      .pipe(
        take(1),
        filter((a) => a),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe(() => {
        this.show_label = false;
        this.statement_set_name = this.statement_set.name;
        setTimeout(() => {
          this.statementSetNameInput.nativeElement.focus();
          this.statementSetNameInput.nativeElement.select();
        });
      });
  };

  /// /
  // Statement editing (template)
  /// /

  createSpecificStatement(newStatementText: string) {
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.topic$.pipe(take(1)).subscribe((topic) =>
      this.reportService
        // @ts-expect-error strictNullChecks
        .createSpecificChoice(topic.id, this.element.id, this.region != null ? this.region.id : null, newStatementText)
        /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
        .subscribe(() => {
          const choice = this.reportSpecificChoices[this.reportSpecificChoices.length - 1];
          this.editorService.publishFocusChoice(choice.id);
        }),
    );

    this.creatingSpecific2 = false;
  }

  drop(event: CdkDragDrop<number[]>) {
    if (this.filterType !== 'NONE') {
      this.messageService.add({
        title: 'Hint',
        type: 'info',
        message: 'Dragging statements is only supported if the element filter set to "ALL"',
      });
    } else {
      const newStatements = [...this.statement_set.statements];
      const movedStatementId = newStatements[event.previousIndex];
      moveItemInArray(newStatements, event.previousIndex, event.currentIndex);
      this.subscription.add(
        this.statementSetEffect
          .update(this.statement_set.id, { statements: newStatements })
          .pipe(take(1))
          .subscribe(() => {
            this.editorService.publishFocus({ statement_id: movedStatementId, target: this.parent });
          }),
      );
    }
  }

  /// /
  // Focus and hover highlighting
  /// /
  onMouseEnter() {
    this.topic$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((topic) =>
        this.editorService.publishHighlight(
          { element_id: this.element.id, topic_id: topic.id, region_id: this.region_id, source: 'ELEMENT' },
          { highlight: true, scroll: false },
        ),
      );
  }

  onMouseLeave() {
    this.topic$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((topic) =>
        this.editorService.publishHighlight(
          { element_id: this.element.id, topic_id: topic.id, region_id: this.region_id, source: 'ELEMENT' },
          { highlight: false, scroll: false },
        ),
      );
  }

  async removeElement() {
    ElementDeleteModalComponent.open(this.modalService, this.element);
  }

  showElementUsageModal() {
    ElementUsageModalComponent.open(this.modalService, { statement_set: this.statement_set });
  }

  openEditor(editType: EditType) {
    const { componentInstance } = StatementEditModalComponent.open(this.modalService, { scrollable: false });
    componentInstance.region = this.region;
    this.subscription.add(
      this.topic$.pipe(take(1)).subscribe((topic) => {
        componentInstance.topic = topic;
      }),
    );

    componentInstance.subsection = this.subsection;
    componentInstance.element = this.element;
    componentInstance.editType = editType;
    componentInstance.source = this.parent;
  }

  addGlobalSentence() {
    this.topic$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((topic) =>
        NotepadModalComponent.open({
          modal: this.modalService,
          template_id: topic.template_id,
          topic,
          element: this.element,
          subsection: this.subsection,
          section: this.section,
          region: this.region,
        }),
      );
  }

  openCategoryModal() {
    CategoryEditModalComponent.open(this.modalService, null, this.element.statement_set_id);
  }

  getNumberOfStatements(type: 'DIVIDER' | 'STATEMENT') {
    return this.templateService.getNumberOfStatements(this.element.statement_set_id, type);
  }

  getNumberOfCategorisedStatements(type: 'DIVIDER' | 'STATEMENT') {
    return this.templateService.getNumberOfCategorisedStatements(this.element.statement_set_id, type);
  }

  onScrollClicked(type: 'HOME' | 'END') {
    requestAnimationFrame(() => {
      const element_id =
        this.element.type === 'notepad' ? NOTEPAD_SCROLL_ID : `${this.ELEMENT_SCROLL_ID}-${this.instanceId}`;
      const elementScroll: HTMLElement | null = document.querySelector(`#${element_id}`);
      if (elementScroll) {
        elementScroll.scrollTop = type === 'HOME' ? 0 : elementScroll.scrollHeight;
      }
    });
  }

  filterStatementsGroup(filterStatements: RR.Statement[]) {
    filterStatements.map((statement) => {
      if (statement.is_divider) {
        this.statementGroupIds = [...this.statementGroupIds, statement.id];
      }
    });
  }
}
