<td>
  <div class="d-flex flex-column gap-2">
    <a
      class="btn btn-outline-primary btn-sm"
      [routerLink]="['/report', report.id, 'edit']"
      (click)="voyagerUrlService.openVoyager(report.accession_number)"
      >Open Report</a
    >
    <a
      class="btn btn-sm btn-link border"
      [routerLink]="['/report', report.id, 'edit']"
      target="_blank"
      (click)="voyagerUrlService.openReportAndVoyager(report)"
    >
      <!-- href still present for a11y, even though there's a click handler -->
      <rr-report-accession-number [report]="report" [shortText]="true"></rr-report-accession-number>
      <span class="small fa fa-external-link-alt"></span>
    </a>
    <rr-zero-fp-images [report]="report"></rr-zero-fp-images>
    <rr-report-date [reportId]="report.id"></rr-report-date>
    <rr-document-attachment-button
      [bookingId]="report.booking_id"
      [size]="'sm'"
      [btnClassName]="'btn-info'"
      [dismissActiveModal]="false"
      [showText]="false"
    ></rr-document-attachment-button>
  </div>
</td>
<td>
  <div *ngFor="let topic_id of report.topic_ids">
    <rr-worklist-topic-column
      data-no-bubble
      [topic_id]="topic_id"
      [columnType]="'STUDY_TYPE'"
      [showSearchByStudyType]="false"
      [showFinalPreviewBtn]="false"
    >
    </rr-worklist-topic-column>
  </div>
</td>
<td>
  <div *ngFor="let topic_id of report.topic_ids">
    <rr-worklist-topic-column
      data-no-bubble
      [topic_id]="topic_id"
      [columnType]="'REPORT_TITLE'"
      [showSearchByReportTitle]="false"
    >
    </rr-worklist-topic-column>
  </div>
</td>
<td>
  <rr-voyager-status-badge [report]="report"></rr-voyager-status-badge>
  <rr-report-sign-badge [reportId]="report.id"></rr-report-sign-badge>
</td>
<td>
  <div class="mb-2">
    <rr-send-report-button
      [report_id]="report.id"
      [btnStyle]="'btn-primary'"
      permission
      [permission-only]="['report_distribute']"
    ></rr-send-report-button>
  </div>
  <button (click)="openProofRead()" class="btn btn-sm btn-outline-primary text-nowrap mb-2" rrTooltip="PROOF_READ">
    View Report
  </button>
  <ng-container *ngFor="let topic_id of report.topic_ids">
    <rr-related-topic
      [topic_id]="topic_id"
      [report]="report"
      [editorExpandedState]="editorExpandedState"
      [selectable]="selectable"
    ></rr-related-topic>
  </ng-container>
  <div class="mt-2">
    <button class="btn btn-info btn-sm" (click)="openNotesModal()" rrTooltip="OPEN_NOTES">Notes</button>
  </div>
</td>
