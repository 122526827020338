import { createAction, props } from '@ngrx/store';

export const DocumentationLinkActions = {
  findAll: createAction('[DocumentationLink] Find All'),

  findAllSuccess: createAction('[DocumentationLink] Find All Success', props<{ docs: RR.DocumentationLink[] }>()),

  createSuccess: createAction('[DocumentationLink] Create Success', props<{ doc: RR.DocumentationLink }>()),

  updateSuccess: createAction('[DocumentationLink] Update Success', props<{ doc: RR.DocumentationLink }>()),

  deleteSuccess: createAction('[DocumentationLink] Delete Success', props<{ docId: number }>()),
};
