import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgbDropdown, NgbModal, NgbPopover, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService, FocusChoice, FocusTextObjectChoice } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { ICategorisedAttribute, TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { PrefillSearchActions } from 'app/store/prefill/prefill-search';
import { fromElementChoice } from 'app/store/report/element-choice';
import { fromRegionSet } from 'app/store/template/region-set';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import { CategoriseSentenceModalComponent } from '../category/categorise-sentence-modal/categorise-sentence-modal.component';
import { DateTypeComponent } from '../date-type/date-type.component';
import { StatementChoiceUsageAnalyticsModalComponent } from '../modals/statement-choice-usage-analytics-modal/statement-choice-usage-analytics-modal.component';
import { StatementAttributeComponent } from '../statement-edit/statement-attribute/statement-attribute.component';
import { StatementDateComponent } from '../statement-edit/statement-date/statement-date.component';
import { EditType } from '../statement-edit/statement-edit.component';
import { StatementNumberComponent } from '../statement-edit/statement-number/statement-number.component';
import { StatementEditModalComponent } from '../statement/statement-edit-modal.component';

type AttributeOptionObject = {
  attribute_option_id: number;
  text: string;
};

type OtherRegionCheck = {
  id: number;
  name: string;
  isCheckedDefault: boolean;
  isCheckedNonDefault: boolean;
  disabled: boolean;
};

@Component({
  selector: 'rr-report-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdown,
    NgbDropdownToggle,
    SharedModule,
    DateTypeComponent,
    NgbDropdownMenu,
    StatementNumberComponent,
    StatementAttributeComponent,
    StatementDateComponent,
    NgbPopover,
  ],
})
@LifecycleLogger
export class ChoiceComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('dropdown') dropdownQueryList: QueryList<NgbDropdown>;
  @ViewChild('popoverSearch') popoverSearch: NgbPopover;
  @Input() @BindObservable() choice: RR.FullStatementChoice;
  choice$: Observable<RR.StatementChoice>;
  /**
   * statement is set to null in prefill-copy.component
   */
  @Input() statement: RR.Statement | null;
  @Input() topic: RR.Topic;
  @Input() element: RR.Element;
  @BindObservable() @Input() section: RR.Section;
  section$: Observable<RR.Section>;
  @BindObservable() @Input() subsection: RR.Subsection;
  subsection$: Observable<RR.Subsection>;
  @Input() element_choice: RR.ElementChoice | null;
  @Input() region: RR.Region | undefined;
  // TODO(unused)?
  @Input() can_focus_child = false;
  @Input() last = false;
  @Input() choicesCount: number;
  @Input() parentComponent: 'PREFILL_COPY' | 'STATEMENT' = 'STATEMENT';
  @Input() statementFrequency?: number | undefined;
  @Input() filterType: 'NONE' | 'AGE_FREQUENCY' | 'FREQUENCY' | 'LOGISTIC_REGRESSION' = 'NONE';
  @Input() proposed?: RR.ProposedStatement;
  @Input() createInElement: RR.Element;
  text_object_choices: RR.TextObjectChoice[];
  subscription = new Subscription();
  choicePreview: string;
  regions$: Observable<RR.Region[]>;
  statement_attributes: RR.TextObjectChoice[] = [];
  isSentenceCategorised: Observable<boolean>;
  sentenceCategoriesTooltip: Observable<string>;
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();
  currentDropdown: NgbDropdown | null;

  otherRegions$: Observable<OtherRegionCheck[]>;

  isFindingsOrTechnique = false;
  isFindingsOrImpressionRecommendations = false;
  isFindingsOrComment = false;

  @HostBinding('class.list-group-item-legacy') get legacy() {
    return this.statement?.legacy;
  }
  @HostBinding('class.list-group-item-success') get success() {
    return !this.statement?.legacy;
  }

  // If the choice has been cloned to keyfindings, comment, or I&R, it cannot be cloned to the other section.
  // The choice need to be uncloned first before cloning to other section to avoid report overcomplication
  hasClone = false;
  cloneMap: { [section: string]: boolean } = {};

  constructor(
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private editorService: EditorService,
    private templateService: TemplateService,
    private el: ElementRef,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {}

  get region_id() {
    return this.region ? this.region.id : undefined;
  }

  ngOnInit() {
    this.subscription.add(
      this.section$.subscribe((_section) => {
        const section = _section.type;
        this.isFindingsOrTechnique = section === 'findings' || section === 'technique';
        this.isFindingsOrImpressionRecommendations = section === 'findings' || section === 'impression_recommendations';
        this.isFindingsOrComment = section === 'findings' || section === 'comment';
      }),
    );

    this.subscription.add(
      this.reportService.getTextObjectChoices(this.choice.id).subscribe((text_object_choices) => {
        this.text_object_choices = text_object_choices.filter(
          (choice): choice is RR.TextObjectChoice => choice !== undefined,
        );

        this.statement_attributes = text_object_choices.filter(
          (attribute): attribute is RR.TextObjectChoice => attribute !== undefined && attribute.type === 'set',
        );
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      this.choice$.subscribe(() => {
        this.hasClone = !!this.choice.clones && this.choice.clones.length > 0;
        this.cloneMap = {};
        if (this.choice.clones) {
          this.cloneMap = this.choice.clones.reduce((map: { [section: string]: boolean }, curr: RR.ReportSection) => {
            map[curr] = true;
            return map;
          }, {});
        }
        this.cd.detectChanges();
      }),
    );

    if (this.parentComponent === 'STATEMENT') {
      if (this.region && this.region.region_set_id) {
        this.regions$ = this.store.select(fromRegionSet.selectRegions(this.region.region_set_id)).pipe(filterDefined());

        this.otherRegions$ = this.regions$.pipe(
          switchMap((regions) => {
            const otherRegionsObservables = regions.map((region) =>
              this.checkSelectedRegionStatement(region.id).pipe(
                map((regionChecked) => ({
                  id: region.id,
                  name: region.name,
                  isCheckedDefault: regionChecked.isDefault,
                  isCheckedNonDefault: regionChecked.noDefaults,
                  disabled: regionChecked.checked,
                })),
              ),
            );
            return combineLatest(otherRegionsObservables);
          }),
        );
      }

      this.subscription.add(
        this.editorService.focusEvents
          .pipe(filter((e): e is FocusChoice => e?.type === 'FocusChoice'))
          .subscribe((e) => {
            if (e.statementChoiceId === this.choice.id) {
              // Always show active choice in the middle of element
              this.el.nativeElement.focus();
              this.el.nativeElement.scrollIntoView({ block: 'center' });
              e.doneCallback();
            }
          }),
      );

      this.subscription.add(
        this.editorService.focusEvents
          .pipe(filter((e): e is FocusTextObjectChoice => e?.type === 'FocusTextObjectChoice'))
          .subscribe((e) => {
            const dropdown = this.dropdownQueryList.find((d) => {
              return Number((d['_nativeElement'] as HTMLElement).dataset.id) === e.textObjectChoiceId;
            });
            if (dropdown) {
              const nativeElement: HTMLElement = dropdown['_nativeElement'];

              requestAnimationFrame(() => {
                if (
                  nativeElement.dataset.type === 'set' ||
                  nativeElement.dataset.type === 'number' ||
                  nativeElement.dataset.type === 'date'
                ) {
                  this.toggleDropdown(dropdown);
                }
              });
            }
            e.doneCallback();
          }),
      );
    }

    /**
     * Check if sentence was categorised by using combo of statement and attributes
     */
    this.isSentenceCategorised = this.templateService.checkSentenceCategories(
      this.choice.statement_id,
      this.getCategoryAttributesFromStatementAttributes(),
    );

    this.sentenceCategoriesTooltip = this.templateService.getSentenceCategoriesTooltip(
      this.choice.statement_id,
      this.getCategoryAttributesFromStatementAttributes(),
    );
  }

  ngAfterViewInit() {
    this.openNumberAttribute();
  }

  openNumberAttribute() {
    if (this.can_focus_child) {
      const dropdown = this.dropdownQueryList.find((d) => {
        return (d['_nativeElement'] as HTMLElement).dataset.type === 'number';
      });
      if (dropdown) {
        requestAnimationFrame(() => {
          dropdown.open();
        });
      }
    }
  }

  onkeydown(e: KeyboardEvent) {
    // This iterates over the dropdowns directly, instead of relying on the 'this.currentDropdown' state.
    // It also supports navigating in reverse using 'Shift + Tab'.
    if (e.key === 'Tab') {
      const dropdowns = this.dropdownQueryList.toArray();
      const currentOpen = dropdowns.find((d) => d.isOpen());

      if (currentOpen) {
        const offset = e.shiftKey ? -1 : 1;
        const index = dropdowns.indexOf(currentOpen);
        const nextDropdown = dropdowns[index + offset];

        // If next dropdown doesn't exist do nothing
        if ((index === 0 && e.shiftKey) || (index === dropdowns.length - 1 && !e.shiftKey)) {
          e.preventDefault();
          return;
        }
        currentOpen.close();
        nextDropdown.open();
      }
      e.preventDefault();
    }
  }

  onOpenChange(dropdown: NgbDropdown, open: boolean) {
    if (open) {
      // Making sure that the previous dropdown is closed to avoid race condition
      if (this.currentDropdown && this.currentDropdown !== dropdown) {
        this.currentDropdown.close();
      }

      this.currentDropdown = dropdown;
    }
  }

  onEnter(dropdown: NgbDropdown, targetType?: 'number' | 'set' | 'date') {
    // Pressing enter jumps to the next attribute
    dropdown.close();
    const dropdowns = this.dropdownQueryList.toArray();
    const index = dropdowns.indexOf(dropdown);

    // Find next dropdown of the specified type
    let nextDropdown;
    if (targetType) {
      nextDropdown = dropdowns.slice(index + 1).find((d) => {
        return (d['_nativeElement'] as HTMLElement).dataset.type === targetType;
      });
    }

    // just move to the next dropdown.
    if (!nextDropdown) {
      nextDropdown = dropdowns.slice(index + 1)[0];
    }

    // Open the next dropdown.
    if (nextDropdown) {
      nextDropdown.open();
    }
  }

  toggleDropdown(dropdown: NgbDropdown) {
    if (dropdown.isOpen()) {
      dropdown.close();
    } else {
      dropdown.open();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleClone(newSection: RR.ReportSection) {
    const clones = this.choice.clones || [];

    if (clones.length === 0) {
      // If the choice is not in any section, add it to the new section
      this.subscription.add(this.reportService.addClone(this.choice, newSection).pipe(take(1)).subscribe());
    } else if (clones.includes(newSection)) {
      // If the choice is in the new section, remove it from there
      this.subscription.add(this.reportService.removeClone(this.choice, newSection).pipe(take(1)).subscribe());
    } else {
      // If the choice is in any section, remove it from there and add to the new section
      this.subscription.add(
        // This does it in one API call
        this.reportService.updateStatementChoice(this.choice, { clones: [newSection] }).subscribe(),
      );
    }
  }

  trackBy = trackById;

  repeatChoice() {
    if (this.statement) {
      this.subscription.add(
        this.reportService
          .chooseStatement({
            topic_id: this.topic.id,
            element_id: this.element.type === 'notepad' ? this.createInElement.id : this.element.id,

            statement_id: this.statement.id,
            region_id: this.region_id,
          })
          .subscribe(),
      );
    }
  }

  singleStatementSelect() {
    if (this.statement && this.element_choice) {
      this.subscription.add(
        this.store
          .select(fromElementChoice.selectStatementChoices(this.element_choice.id))
          .pipe(
            switchMap((choices) => {
              return this.reportService.removeStatementChoices(
                choices.filter((choice) => choice.id !== this.choice.id && choice.statement_id !== null),
              );
            }),
          )
          .subscribe(),
      );
    }
  }

  removeChoice() {
    this.subscription.add(
      this.reportService
        .getChoiceText(this.choice.id)
        .pipe(take(1))
        .subscribe((text) => {
          // Temporarily save recently deleted sentence
          this.editorService.saveClipboardSentence(text);
        }),
    );

    this.reportService.removeStatementChoice(this.choice);
  }

  addRegionStatement({ region_id, copyDefaultAttributes }: { region_id: number; copyDefaultAttributes: boolean }) {
    const attribute_option_objects: AttributeOptionObject[] = [];
    if (!copyDefaultAttributes) {
      this.text_object_choices.map((object) => {
        if (object.type === 'set' && typeof object.attribute_option_id === 'number') {
          attribute_option_objects.push({ attribute_option_id: object.attribute_option_id, text: object.text });
        }
      });
    }
    if (this.statement) {
      this.subscription.add(
        this.reportService
          .chooseStatement({
            topic_id: this.topic.id,
            element_id: this.element.id,
            statement_id: this.statement.id,
            region_id,

            attribute_option_objects,
          })
          .subscribe(),
      );
    }
  }

  checkSelectedRegionStatement(region_id: number) {
    return this.reportService.checkSelectedRegionStatement({ choice: this.choice, region_id, element: this.element });
  }

  openEditor(editType: EditType) {
    const { componentInstance } = StatementEditModalComponent.open(this.modalService, { scrollable: false });

    componentInstance.editType = editType;
    componentInstance.region = this.region;
    componentInstance.topic = this.topic;
    componentInstance.subsection = this.subsection;
    componentInstance.element = this.element;
    if (this.statement) componentInstance.statement_id = this.statement.id;
  }

  categoriseSentence() {
    this.openSentenceCategoryModal();
  }

  getCategoryAttributesFromStatementAttributes(): ICategorisedAttribute[] {
    return this.statement_attributes.map((choice_attr) => {
      if (choice_attr.type === 'set') {
        return {
          attribute_option_id: choice_attr.attribute_option_id,
          text_object_id: choice_attr.text_object_id,
        };
      } else {
        return {
          attribute_option_id: 0,
          text_object_id: choice_attr.text_object_id,
        };
      }
    });
  }

  openSentenceCategoryModal() {
    return CategoriseSentenceModalComponent.open(
      null,
      this.modalService,
      this.topic.report_id,
      this.topic.id,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.element ? this.element.statement_set_id : null,
      this.region_id || null,
      this.choice.statement_id,
      this.getCategoryAttributesFromStatementAttributes(),
    );
  }

  copyToFullTextSearch() {
    this.store.dispatch(
      PrefillSearchActions.updateSearchForm({
        searchForm: { searchType: 'FULL_TEXT', fullText: this.text_object_choices.map((o) => o.text).join('') },
      }),
    );
    this.store.dispatch(EditorActions.togglePrefill({ prefill: true }));
  }

  copyToGlobalSearch() {
    this.editorService.globalSearchTerm$.next({
      source: 'PREFILL',
      term: this.text_object_choices.map((o) => o.text).join(''),
    });
  }

  searchAndOpenSuggestionsPanel() {
    this.editorService.searchAndToggleCorrelatedStatement({
      statementId: this.choice.statement_id,
      templateId: this.topic.template_id,
      region: this.region,
      source: 'BUTTON',
    });
  }

  toggleExcluded() {
    this.subscription.add(
      this.reportService
        .updateStatementChoice(this.choice, { statement_choice: { excluded: !this.choice.excluded } })
        .subscribe(),
    );
  }

  openStatementUsageAnalytics() {
    StatementChoiceUsageAnalyticsModalComponent.open(this.modalService, this.choice.id, this.topic, 'choice');
  }
}
