import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromSession, SessionEffect } from 'app/store/session';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SelectorService } from '../services/selector.service';

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
  currentUser: RR.User | undefined;
  kioskUser: RR.User | undefined;

  constructor(
    private sessionEffect: SessionEffect,
    private selectorService: SelectorService,
    private store: Store<AppState>,
  ) {
    this.store.select(fromSession.selectKioskUser).subscribe((kioskUser) => {
      this.kioskUser = kioskUser;
    });
    this.selectorService.selectLoadedCurrentUser().subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.kioskUser) {
      // Otherwise, the server reads the current_user from the Flask session
      req = req.clone({
        setHeaders: {
          'RadReport-User': String(this.kioskUser.id),
        },
      });
    }
    return next.handle(req).pipe(
      catchError((err: unknown) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          req.url !== '/api/auth/login' &&
          req.url !== '/api/auth/logout' &&
          req.url !== rrConfig.TIMESHEETS_URL + '/api/radreport/open_shift2' &&
          req.url !== '/api/initials'
        ) {
          if (this.currentUser) {
            this.sessionEffect.logout();
          }
        }
        throw err;
      }),
    );
  }
}
