import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryService implements OnDestroy {
  private subscription = new Subscription();

  lastWorklistUrl$ = new BehaviorSubject<string>('/start');
  lastBookingListUrl$ = new BehaviorSubject<string>('/registration/bookings');

  constructor(private router: Router) {
    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (event.url.startsWith('/start')) {
            this.lastWorklistUrl$.next(event.url);
          }
          if (event.url.startsWith('/registration/bookings')) {
            this.lastBookingListUrl$.next(event.url);
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
