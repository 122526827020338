import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  selector: 'rr-confirm-message-modal',
  templateUrl: './confirm-message-modal.component.html',
})
export class ConfirmMessageModalComponent implements OnInit {
  header: string;
  message: string;

  btnConfirmText?: string;
  secondBtnOptionText?: string;
  textInput?: boolean;
  placeholder?: string;
  centered?: boolean;
  password?: boolean;

  form = new FormGroup({
    messageInputField: new FormControl('', { nonNullable: true }),
    password: new FormControl('', { nonNullable: true }),
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.password) {
      this.form.controls.password.setValidators([Validators.required]);
    }
    if (this.textInput) {
      this.form.controls.messageInputField.setValidators([Validators.required]);
    }
  }

  acceptSecondOption(): void {
    // Close the dialog, return false
    this.activeModal.close(false);
  }

  accept() {
    if (this.textInput && !this.password) {
      this.formValidation();
      if (this.form.valid) {
        // Close the dialog, return textInput value
        const promptResponse = this.form.controls.messageInputField.value;
        this.activeModal.close(promptResponse);
      }
    } else if (this.textInput && this.password) {
      this.formValidation();
      if (this.form.valid) {
        const promptResponse = {
          reason: this.form.controls.messageInputField.value,
          password: this.form.controls.password.value,
        };
        this.activeModal.close(promptResponse);
      }
    } else {
      // Close the dialog, return true
      this.activeModal.close(true);
    }
  }

  formValidation() {
    this.form.markAllAsTouched();
  }

  static open({
    modalService,
    header,
    message,
    btnConfirmText,
    secondBtnOptionText,
    textInput,
    placeholder,
    centered,
    password,
  }: {
    modalService: NgbModal;
    header: string;
    message: string;

    btnConfirmText?: string;
    secondBtnOptionText?: string;
    /**
     * For text input in the confirm modal, ie: enter reason to delete invoice
     */
    textInput?: boolean;
    placeholder?: string;
    centered?: boolean;
    password?: boolean;
  }) {
    const modalRef = modalService.open(ConfirmMessageModalComponent, {
      size: 'lg',
      centered: centered || false,
      windowClass: 'confirm-message-modal',
    });
    const self: ConfirmMessageModalComponent = modalRef.componentInstance;
    self.header = header;
    self.message = message;
    self.btnConfirmText = btnConfirmText;
    self.secondBtnOptionText = secondBtnOptionText;
    self.textInput = textInput;
    self.placeholder = placeholder;
    self.centered = centered;
    self.password = password;

    return modalRef;
  }
}
