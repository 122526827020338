import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { Observable, Subscription } from 'rxjs';

import { SlateEditor, SlateEditorProps } from '../slatement-react/slatement-react';
import { NgReact } from './ng-react';

@Component({
  selector: 'rr-slatement-mount',
  standalone: true,
  imports: [CommonModule],
  template: ``, // This component is rendered by React, so no template is needed.
})
export class SlatementMountComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() props: SlateEditorProps;
  props$: Observable<SlateEditorProps>;

  subscription = new Subscription();

  private ngReact = inject(NgReact);
  private root = this.ngReact.createRoot(inject(ElementRef).nativeElement);

  ngOnInit(): void {
    this.subscription.add(
      this.props$.subscribe((props) => {
        // Pass in the new props to the React component.
        this.ngReact.render(this.root, SlateEditor, props);
      }),
    );
  }

  ngOnDestroy(): void {
    this.root.unmount();
    this.subscription.unsubscribe();
  }
}
