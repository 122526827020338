import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { GithubIssueCommentActions } from './github-issue-comment.action';

export type GithubIssueCommentState = EntityState<RR.GithubIssueComment>;

const adapter = createEntityAdapter<RR.GithubIssueComment>();

const initialState: GithubIssueCommentState = adapter.getInitialState();

export const githubIssueCommentReducer = createReducer(
  initialState,
  on(GithubIssueCommentActions.findAllSuccess, (state: GithubIssueCommentState, { comments }) =>
    adapter.upsertMany(comments, state),
  ),
  on(GithubIssueCommentActions.createSuccess, (state: GithubIssueCommentState, { comment }) =>
    adapter.addOne(comment, state),
  ),
);
