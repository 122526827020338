import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { MachineActions } from './machine.action';
import { MachineHttpService } from './machine.service';

@Injectable()
export class MachineEffect {
  constructor(
    private store: Store<AppState>,
    private service: MachineHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((machines: RR.Machine[]) => MachineActions.findAllSuccess({ machines })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(machine: Partial<RR.Machine>) {
    return this.service.create(machine).pipe(
      map((machine: RR.Machine) => MachineActions.createSuccess({ machine })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(machineId: number, changes: Partial<RR.Machine> & { unavailables?: Partial<RR.MachineUnavailable>[] }) {
    return this.service.update(machineId, changes).pipe(
      map((machine) => MachineActions.updateSuccess({ machine })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(machineId: number) {
    return this.service.delete(machineId).pipe(
      map(() => MachineActions.deleteSuccess({ machineId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  updateUnavailableMachine(unavailableId: number, notes: string, machineId: number) {
    return this.service.updateUnavailableMachine(unavailableId, notes).pipe(
      map((unavailable) => MachineActions.updateUnavailableSuccess({ machineId, unavailable })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
