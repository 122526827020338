import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for ScanCode
 *
 */
export const ScanCodeActions = {
  findAllSuccess: createAction('[ScanCode] Find All Success', props<{ scanCodes: RR.ScanCode[] }>()),

  findManySuccess: createAction('[ScanCode] Find Many Success', props<{ scanCodes: RR.ScanCode[] }>()),

  findSuccess: createAction('[ScanCode] Find Success', props<{ scanCode: RR.ScanCode }>()),

  createSuccess: createAction('[ScanCode] Create Success', props<{ scanCode: RR.ScanCode }>()),

  updateSuccess: createAction('[ScanCode] Update Success', props<{ scanCode: RR.ScanCode }>()),

  deleteSuccess: createAction('[ScanCode] Delete Success', props<{ scanCodeId: number }>()),
};
