import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { UserSettingActions } from './user-setting.action';

export type UserSettingState = EntityState<RR.UserSetting>;

const adapter = createEntityAdapter<RR.UserSetting>();

const initialState = adapter.getInitialState();

export const userSettingReducer = createReducer(
  initialState,
  on(UserSettingActions.findAllSuccess, (state: UserSettingState, { userSettings }) =>
    adapter.setAll(userSettings, state),
  ),
  on(UserSettingActions.updateSuccess, (state: UserSettingState, { userSetting }) =>
    adapter.upsertOne(userSetting, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
