import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { PatientDuplicate, PatientHttpService } from 'app/store/patient';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { Observable, Subscription, of, switchMap } from 'rxjs';

import { PatientPopoverComponent } from '../../../modules/registration/components/patient-form/patient-popover/patient-popover.component';

@Component({
  selector: 'rr-duplicate-patient-warning',
  standalone: true,
  templateUrl: './duplicate-patient-warning.component.html',
  styleUrls: ['./duplicate-patient-warning.component.css'],
  imports: [CommonModule, PatientPopoverComponent, SharedModule],
})
export class DuplicatePatientWarningComponent implements OnInit, OnDestroy {
  @Input() reportId: number;

  similarPatients: PatientDuplicate | undefined;
  selectedPatientId: number | null;

  report$: Observable<RR.Report>;

  subscription = new Subscription();

  constructor(
    private patientService: PatientHttpService,
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private modal: NgbModal,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.duplicatePatient(this.reportId).subscribe((similarPatients) => {
        if (similarPatients) {
          this.similarPatients = similarPatients;
        }
      }),
    );
  }

  duplicatePatient(reportId: number) {
    this.report$ = this.store.select(fromReport.selectReport(reportId)).pipe(filterDefined());

    return this.report$.pipe(
      switchMap((report) => {
        if (report.patient_id) {
          return this.patientService.findDuplicatePatientById(report.patient_id);
        }
        return of(null);
      }),
    );
  }

  onChangePatient(patientId: number) {
    this.selectedPatientId = patientId;
  }

  updateReport() {
    const modalRef = ConfirmMessageModalComponent.open({
      modalService: this.modal,
      header: 'Confirm',
      message: `Are you sure you want to update this report with the selected patient's information?`,
      btnConfirmText: 'Yes',
    });

    modalRef.result.then(
      () => {
        this.subscription.add(
          this.reportEffect.update(this.reportId, { patient_id: this.selectedPatientId }).subscribe({
            error: () => {
              this.messageService.add({
                message: `Error updating report. Please try again later.`,
                title: 'Error',
                type: 'danger',
                timeout: 5000,
              });
            },
          }),
        );
      },
      () => {
        // do nothing
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
