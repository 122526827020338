<div class="d-flex justify-content-end mb-2">
  <ng-container *ngIf="labels$ | async as labels">
    <button
      type="button"
      [ngClass]="{ 'w-100': viewMode === 'filter' }"
      class="btn btn-outline-primary"
      [ngbPopover]="popoverLabelContent"
      popoverTitle="Label as:"
      #popoverLabel="ngbPopover"
      (click)="popoverLabel.toggle()"
      container="body"
      triggers="manual"
      [autoClose]="'outside'"
      [placement]="'bottom'"
      [rrTooltip]="reportIds && reportIds.length === 0 ? 'CREATE_REPORT_LABEL' : 'ADD_REPORT_LABEL'"
    >
      <!-- Show "Create Label" title when 'reportIds' exist and the view mode is not set to 'filter' -->
      <ng-container *ngIf="reportIds && reportIds.length === 0; else otherConditions"> Create Label </ng-container>
      <ng-template #otherConditions>
        <!-- Show "Select Label" when the view mode is 'Filter' and no label has been chosen; otherwise, display the selected labels -->
        <ng-container
          *ngIf="selectedLabelIds && selectedLabelIds.length > 0 && viewMode === 'filter'; else labelContent"
        >
          <span *ngFor="let label of labels; let i = index">
            <ng-container *ngIf="selectedLabelIds | includes: label.id">
              {{ label.name }}<span *ngIf="i < selectedLabelIds.length - 1">, </span>
            </ng-container>
          </span>
        </ng-container>
        <ng-template #labelContent>
          <ng-container *ngIf="!reportIds && viewMode === 'filter'; else justLabels"> Select Label </ng-container>
          <!-- Display labels only when a report has been selected -->
          <ng-template #justLabels> Labels </ng-template>
        </ng-template>
      </ng-template>
      <div class="badge bg-primary ms-1">{{ labels.length }}</div>
    </button>

    <ng-template #popoverLabelContent>
      <rr-labels
        [selectedLabelIds]="selectedLabelIds"
        [labels]="labels"
        [reportIds]="reportIds"
        [popoverLabel]="popoverLabel"
        [viewMode]="viewMode"
        (onSelect)="onSelectLabelIds($event)"
      ></rr-labels>
    </ng-template>
  </ng-container>
</div>
