<div>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ !!keyword_abbr_id ? 'Edit Keyword Abbreviation' : 'Create New Keyword Abbreviation' }}
    </h5>
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
    <form [formGroup]="form" class="bootstrap-validate">
      <div class="row">
        <div class="form-group mb-2 col">
          <label class="control-label">Abbreviation <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
          <input formControlName="abbreviation" class="form-control fw-bold" rrAutoFocus />

          <span class="invalid-feedback" *ngIf="abbreviation.touched && abbreviation.errors?.required">
            Abbreviation is required
          </span>
        </div>
      </div>
      <div class="row">
        <div class="form-group mb-2 col">
          <label class="control-label">Keyword <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
          <input formControlName="keyword" class="form-control fw-bold" />
          <span class="invalid-feedback" *ngIf="keyword.touched && keyword.errors?.required">
            Keyword is required
          </span>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button class="btn btn-primary" [disabled]="form.invalid" (click)="submit()">
          {{ !!keyword_abbr_id ? 'Save Changes' : 'Submit' }}
        </button>
      </div>
    </form>
  </div>
</div>
