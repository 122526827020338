import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ElementCreateBody {
  element: Partial<RR.Element>;
  index?: number;
}

@Injectable()
export class ElementHttpService {
  constructor(private http: HttpClient) {}

  create(data: ElementCreateBody): Observable<{ element: RR.Element; subsection: RR.Subsection }> {
    return this.http.post<{ element: RR.Element; subsection: RR.Subsection }>('/api/element', data);
  }

  update(elementId: number, changes: Partial<RR.Element>): Observable<RR.Element> {
    return this.http.put<RR.Element>(`/api/element/${elementId}`, changes);
  }

  delete(elementId: number, password: string) {
    return this.http.post<RR.Subsection>(`/api/element/${elementId}/delete`, { password });
  }

  findInTemplate(templateId: number): Observable<RR.Element[]> {
    return this.http.get<RR.Element[]>(`/api/template/${templateId}/element`);
  }

  fillElement(elementId: number) {
    return this.http.post<RR.DefaultAttribute[]>(`/api/default_attribute/fill_element/${elementId}`, {});
  }
}
