import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createHttpParams, HttpParamsObject } from 'app/store';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';
import { AddedTopic } from '../report/report.service';

interface ReferenceTopicResponse {
  topics: RR.Topic[];
  reports: RR.Report[];
  favourites: RR.Favourite[];
}

export type TemplateData = {
  sections: RR.Section[];
  subsections: RR.Subsection[];
  regions: RR.Region[];
  elements: RR.Element[];
  statement_sets: RR.StatementSet[];
  statements: RR.Statement[];
  dividers: RR.Statement[];
  text_objects: RR.TextObject[];
  statement_coincidences: RR.StatementCoincidence[];
};

@Injectable()
export class TopicHttpService {
  constructor(private http: HttpClient) {}

  find(topicId: number, params: HttpParamsObject) {
    return this.http.get<
      {
        topic: RR.Topic;
        template: RR.Template;
        signature: RR.Signature;
        favourite: RR.Favourite;
        justifications: RR.Justification[];
        section_choices: RR.SectionChoice[];
        subsection_choices: RR.SubsectionChoice[];
        region_choices: RR.RegionChoice[];
        element_choices: RR.ElementChoice[];
        statement_choices: RR.StatementChoice[];
        text_object_choices: RR.TextObjectChoice[];
      } & TemplateData
    >(`/api/topic/${topicId}`, { params: createHttpParams(params) });
  }

  findMultipleTopics(topicIds: number[]) {
    return this.http.get<
      {
        topics: RR.Topic[];
        signatures: RR.Signature[];
        favourites: RR.Favourite[];
        section_choices: RR.SectionChoice[];
        subsection_choices: RR.SubsectionChoice[];
        region_choices: RR.RegionChoice[];
        element_choices: RR.ElementChoice[];
        statement_choices: RR.StatementChoice[];
        text_object_choices: RR.TextObjectChoice[];
      } & TemplateData
    >('/api/topic', { params: { selected_topic_ids: topicIds } });
  }

  create(topic: Partial<RR.Topic>) {
    return this.http.post<{
      topic: RR.Topic;
      report: RR.Report;
    }>('/api/topic', topic);
  }

  update(topicId: number, changes: Partial<RR.Topic>) {
    return this.http.put<{
      topic: RR.Topic;
      report: RR.Report;
    }>(`/api/topic/${topicId}`, changes);
  }

  autoChooseSignature(topicId: number) {
    return this.http.put<RR.Topic>(`/api/topic/${topicId}/auto_choose_signature`, {});
  }

  copyOtherImaging(topicId: number, fromTopicId: number, includeKeyFindings: boolean) {
    return this.http.post<{ topic: RR.Topic; added_topics: AddedTopic[] }>('/api/topic/copy_other_imaging', {
      topic_id: topicId,
      from_topic_id: fromTopicId,
      include_key_findings: includeKeyFindings,
    });
  }

  clearOtherImaging(topicId: number) {
    return this.http.delete<{ topic: RR.Topic; added_topics: AddedTopic[] }>(
      `/api/topic/${topicId}/clear_other_imaging`,
    );
  }

  deleteOtherImaging(topicId: number, otherImagingTopicId: number) {
    return this.http.delete<{ topic: RR.Topic; added_topics: AddedTopic[] }>(
      `/api/topic/${topicId}/delete_other_imaging/${otherImagingTopicId}`,
    );
  }

  copyTopic(topicId: number) {
    return this.http.post<RR.Topic>(`/api/topic/${topicId}/copied`, {});
  }

  copyChoices(topicId: number, choiceIds: number[], keepNumbers: boolean) {
    return this.http.post<ChangeTrackerResponse>('/api/copy_choices_to_topic', {
      to_topic_id: topicId,
      from_choices: choiceIds,
      keep_numbers: keepNumbers,
    });
  }

  refTopics(topicId: number) {
    return this.http.get<ReferenceTopicResponse>(`/api/topic/${topicId}/ref_topics`);
  }
}
