import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type PatientQuestionCreationResponse = {
  patient_question_set: RR.PatientQuestionSet;
  patient_question: RR.PatientQuestion;
  scan_codes: RR.ScanCode[];
};

export type ScanCodePatientQuestionsResponse = {
  scan_code: RR.ScanCode;
  patient_questions: RR.PatientQuestion[];
};

export type PatientQuestionDeleteResponse = {
  scan_codes: RR.ScanCode[];
  patient_question_set: RR.PatientQuestionSet;
};

export type NestingPatientQuestionResponse = {
  parent_patient_question: RR.PatientQuestion;
  patient_question: RR.PatientQuestion;
};

@Injectable()
export class PatientQuestionHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.PatientQuestion[]> {
    return this.http.get<RR.PatientQuestion[]>('api/patient_question');
  }

  createInSet(
    patientQuestionSetId: number,
    patientQuestion: Partial<RR.PatientQuestion>,
    allScanCodes: boolean,
  ): Observable<PatientQuestionCreationResponse> {
    return this.http.post<PatientQuestionCreationResponse>(
      `/api/patient_question_set/${patientQuestionSetId}/patient_question?all_scan_codes=${allScanCodes}`,
      patientQuestion,
    );
  }

  update(patientQuestionId: number, changes: Partial<RR.PatientQuestion>): Observable<RR.PatientQuestion> {
    return this.http.put<RR.PatientQuestion>(`/api/patient_question/${patientQuestionId}`, changes);
  }

  delete(patientQuestionId: number): Observable<PatientQuestionDeleteResponse> {
    return this.http.delete<PatientQuestionDeleteResponse>(`/api/patient_question/${patientQuestionId}`);
  }

  /**
   * Add an existing question to scan code
   * @param scanCodeId
   * @param patientQuestionId
   * @returns
   */
  addToScanCode(scanCodeId: number, patientQuestionId: number): Observable<ScanCodePatientQuestionsResponse> {
    return this.http.post<ScanCodePatientQuestionsResponse>(
      `/api/scan_code/${scanCodeId}/patient_question/${patientQuestionId}`,
      {},
    );
  }

  /**
   * Remove an existing question from scan code
   * @param scanCodeId
   * @param patientQuestionId
   * @returns
   */
  removeFromScanCode(scanCodeId: number, patientQuestionId: number): Observable<ScanCodePatientQuestionsResponse> {
    return this.http.delete<ScanCodePatientQuestionsResponse>(
      `/api/scan_code/${scanCodeId}/patient_question/${patientQuestionId}`,
    );
  }

  nestOrUnnest(parent_question_id: number, patient_question_id: number): Observable<NestingPatientQuestionResponse> {
    return this.http.put<NestingPatientQuestionResponse>(
      `/api/patient_question/${parent_question_id}/nest/${patient_question_id}`,
      {},
    );
  }
}
