<a
  *ngIf="statement$ | async as statement"
  rr-statement
  type="preview"
  class="list-group-item list-group-item-action text-start w-100 p-0 text-break"
  tabindex="-1"
  [statement]="statement"
  [region]="region"
  [topic]="topic"
  [template_id]="template_id"
>
</a>
