import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbTooltip, NgbDropdownButtonItem, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { fromCurrentReport } from 'app/store/report/report';
import { fromTextObject } from 'app/store/template/text-object';
import { Observable, Subscription, map } from 'rxjs';

import { VoiceDirective } from '../../../../shared/directives/voice.directive';
import { SharedModule } from '../../../../shared/shared.module';
import { StatementNumberModalComponent } from '../statement-number-modal/statement-number-modal.component';

@Component({
  selector: 'rr-statement-number',
  styleUrls: ['./statement-number.component.css'],
  templateUrl: './statement-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    VoiceDirective,
    ReactiveFormsModule,
    NgbTooltip,
    NgbDropdownButtonItem,
    NgbDropdownItem,
  ],
})
@LifecycleLogger
export class StatementNumberComponent implements OnDestroy, OnInit, AfterViewInit {
  @BindObservable() @Input() choice_object: RR.TextObjectChoiceNumber;
  choice_object$: Observable<RR.TextObjectChoiceNumber>;
  @Input() choice: RR.StatementChoice;
  @Input() @BindObservable() statement: RR.Statement | null;
  statement$: Observable<RR.Statement | null>;
  @Input() topic: RR.Topic;
  @Input() proposed_measurements: RR.MeasurementValue[] | undefined;
  @ViewChild('tooltip', { static: true }) tooltip: NgbTooltip;
  @ViewChild('input', { static: true }) input: ElementRef;
  @Output() enter = new EventEmitter<void>();
  @Output() tabNumbers = new EventEmitter<KeyboardEvent>();

  equivalentTextObject: RR.TextObjectNumber | undefined;
  reportId: number | undefined = undefined;

  // Number inputs (in the html) become null when backspaced to empty, nonNullable not applicable here - refactors required
  numberInput = new FormControl<string | null>('');

  subscription = new Subscription();

  lower: number | null;
  upper: number | null;

  isSubmitCalledDueToKeyPress = false;
  tooltipText: string | null;

  constructor(
    private reportService: ReportService,
    private modal: NgbModal,
    private store: Store<AppState>,
  ) {}

  getRelatedTextObject(): Observable<RR.TextObjectNumber | undefined> {
    return this.store.select(fromTextObject.selectTextObject(this.choice_object.text_object_id)).pipe(
      map((textObject) => {
        if (textObject && textObject.type !== 'number') {
          throw new Error('TextObject is not of type number');
        }
        return textObject;
      }),
    );
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.numberInput.setValue(event.detail.term);
    this.submitAttributeNumber();
    this.tooltip.close();
    this.enter.emit();
  }

  ngOnInit() {
    this.subscription.add(
      this.getRelatedTextObject().subscribe((textObject) => {
        this.equivalentTextObject = textObject;

        if (this.equivalentTextObject) {
          const { upper, lower, auto_lower, auto_upper } = this.equivalentTextObject;
          this.lower = lower ?? auto_lower ?? null;
          this.upper = upper ?? auto_upper ?? null;
          this.tooltipText = this.getTooltipText();
        }
      }),
    );

    this.subscription.add(
      this.choice_object$.subscribe(() => {
        this.numberInput.setValue(this.choice_object.text);
      }),
    );

    this.subscription.add(
      this.store
        .select(fromCurrentReport.selectReport)
        .pipe(filterDefined())
        .subscribe((report) => {
          this.reportId = report.id;
        }),
    );
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      this.input.nativeElement.focus();
    });
  }

  onFocus() {
    this.updateTooltip();
  }

  clickDropdownItem(measurement: RR.MeasurementValue) {
    this.numberInput.setValue(measurement.numeric_value);
    this.submitAttributeNumber();
  }

  getTooltipText() {
    const lowerText = this.lower ?? '*';
    const upperText = this.upper ?? '*';
    const value = `${lowerText}–${upperText}`;
    return value !== '*–*' ? value : null;
  }

  updateTooltip() {
    if (!this.isWithinRange(this.numberInput.value)) {
      this.tooltip.open();
    } else {
      this.tooltip.close();
    }
  }

  openStatementNumberModal() {
    if (this.equivalentTextObject === undefined) return;

    const { lower, upper, formula, round_to_nearest, exclude_if_outside_range, include_if_outside_range } =
      this.equivalentTextObject;

    const text_object = {
      ...this.equivalentTextObject,
      lower,
      upper,
      formula,
      round_to_nearest,
      exclude_if_outside_range,
      include_if_outside_range,
    };

    StatementNumberModalComponent.open(this.modal, {
      text_object,
      report_id: this.reportId,
      openFrom: 'statementEditor',
    });
  }

  // TODO: refactor backend and form-control to number/float only
  // Current types: string | number | null
  isWithinRange(value: string | number | null): boolean {
    if (!this.equivalentTextObject || value === null) return true;

    const lower = this.lower || -Number.MAX_VALUE;
    const upper = this.upper || Number.MAX_VALUE;

    return typeof value === 'number' ? value >= lower && value <= upper : false;
  }

  onBlur() {
    if (!this.isSubmitCalledDueToKeyPress) {
      this.submitAttributeNumber();
      this.tooltip.close();
    }
    this.isSubmitCalledDueToKeyPress = false;
  }

  submitAttributeNumber() {
    const inputValue = this.numberInput.value;
    const value = inputValue !== null ? String(inputValue) : '';

    if (value !== String(this.choice_object.text)) {
      const data: { text: string; formula?: string } = {
        text: value,
      };

      if (this.choice_object.formula) {
        data.formula = this.choice_object.formula;
      }

      // TODO: The component is being destroyed before the effect completes and the request gets cancelled.
      // eslint-disable-next-line rxjs-angular/prefer-composition
      this.reportService.editTextObject(this.choice_object.id, data).subscribe();
    }
  }

  handleKeyPress(event: KeyboardEvent) {
    // Handling keyboard events for Enter and Space keys
    if (['Enter', ' '].includes(event.key)) {
      this.isSubmitCalledDueToKeyPress = true;

      // Override default Enter key behavior to prevent form submission by the browser
      if (event.key === 'Enter') {
        event.preventDefault();
      }

      this.submitAttributeNumber();
      this.updateTooltip();
      this.enter.emit();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent) {
    this.tabNumbers.emit(event);
  }
}
