import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { InvoiceActions } from '../invoice/invoice.action';
import { InvoiceItemActions } from './invoice-item.action';
import { InvoiceItemCreationHttpResponse, InvoiceItemHttpService } from './invoice-item.service';

@Injectable()
export class InvoiceItemEffect {
  constructor(
    private store: Store<AppState>,
    private service: InvoiceItemHttpService,
  ) {}

  create(invoice_id: number, invoiceItems: Partial<RR.InvoiceItem>[]) {
    return this.service.create(invoice_id, invoiceItems).pipe(
      map((data: InvoiceItemCreationHttpResponse) => {
        return InvoiceItemActions.createSuccessBatch({
          actions: {
            createInvoiceItemsSuccess: InvoiceItemActions.createManySuccess({ invoiceItems: data.invoice_items }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({
              invoice: data.invoice,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(invoiceItemId: number, changes: Partial<RR.InvoiceItem>) {
    return this.service.update(invoiceItemId, changes).pipe(
      map((invoiceItem: RR.InvoiceItem) => InvoiceItemActions.updateSuccess({ invoiceItem })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(invoiceItemId: number) {
    // Soft delete the invoiceItem
    return this.service.delete(invoiceItemId).pipe(
      map((invoice: RR.Invoice) =>
        InvoiceItemActions.deleteSuccessBatch({
          actions: {
            deleteInvoiceItemSuccess: InvoiceItemActions.deleteSuccess({ invoiceItemId }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({ invoice }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
