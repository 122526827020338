import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.LandmarkLabel>();
const selectFeature = (state: AppState) => state.landmark_label;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectEntities } = adapter.getSelectors(selectFeature);

const selectLandmarkLabel = (id: string) => createSelector(selectEntities, (entities) => entities[id]);
export const fromLandmarkLabel = {
  selectLandmarkLabel,
};
