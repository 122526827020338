<a
  #openButton
  *ngIf="voyagerLaunchURL"
  class="btn btn-sm text-start text-nowrap {{ btnClass }}"
  [class.w-100]="expand"
  [href]="voyagerLaunchURL"
  data-no-bubble
  rrTooltip="OPEN_VOYAGER_REPORT"
>
  (V) Open in Voyager
</a>
