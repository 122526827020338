<button
  class="btn btn-sm btn-info"
  [ngbPopover]="popoverContent"
  popoverTitle="Audit Summary"
  #popover="ngbPopover"
  triggers="manual"
  placement="left"
  container="body"
  (click)="open($event)"
  rrTooltip="WORKLIST_AUDIT_EVENT_SUMMARY"
>
  <i class="fas fa-user-edit"></i>
</button>

<ng-template #popoverContent>
  <div *ngIf="summary_audit_events.length === 0">
    <span>No changes</span>
  </div>
  <div *ngIf="summary_audit_events.length > 0">
    <div class="d-flex flex-column">
      <div class="d-flex flex-column mt-2" *ngFor="let event of summary_audit_events">
        <span
          ><i class="fa fa-user me-1"></i> <strong>{{ event.user_name }}</strong>
          {{ event.from_date | date: 'MMM d, y, H:mm' }}</span
        >
        <!--Performance-->
        <div class="mt-2 ms-4">
          <i class="fa fa-plus-circle me-1 text-success"></i>
          <span class="me-2">{{ event.performance.CREATE_PCT | number: '1.0-2' }}%</span>
          <i class="fa fa-minus-circle me-1 text-danger"></i>
          <span class="me-2">{{ event.performance.DELETE_PCT | number: '1.0-2' }}%</span>
          <i class="fa fa-edit me-1 text-info"></i>
          <span>{{ event.performance.OVERALL_PCT | number: '1.0-2' }}%</span>
        </div>
      </div>
      <div class="mt-1">
        <div>
          <span class="fa fa-hourglass-half me-1"></span><strong>Total audit time: </strong> {{ auditTime }} mins
        </div>
        <div>
          <span class="fa fa-hourglass-half me-1"></span><strong>Report interaction time: </strong>
          {{ getTimeString() }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
