import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-favourite-headline',
  templateUrl: './favourite-headline.component.html',
  styleUrls: ['./favourite-headline.component.css'],
})
export class FavouriteHeadlineComponent implements OnInit, OnDestroy {
  @Input() favourite: RR.Favourite;

  subscription = new Subscription();

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
