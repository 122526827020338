import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateActions } from '../template/template/template.action';

const findAllSuccess = createAction(
  '[TagSearchTerm] Find All Success',
  props<{ tagSearchTerms: RR.TagSearchTerm[] }>(),
);
const createSuccess = createAction('[TagSearchTerm] Create Success', props<{ tagSearchTerm: RR.TagSearchTerm }>());
const updateSuccess = createAction('[TagSearchTerm] Update Success', props<{ tagSearchTerm: RR.TagSearchTerm }>());
const deleteSuccess = createAction('[TagSearchTerm] Delete Success', props<{ tagSearchTermId: number }>());

const createInTemplateSuccess = createBatchAction(
  '[TagSearchTerm] Create In Template Success Batch',
  props<{
    actions: {
      findTemplateSuccess: ReturnType<typeof TemplateActions.updateSuccess>;
      createTagSearchItemSuccess: ReturnType<typeof createSuccess>;
    };
  }>(),
);

const createInSubsectionSuccess = createBatchAction(
  '[TagSearchTerm] Create In Subsection Success Batch',
  props<{
    actions: {
      findSubsectionSuccess: ReturnType<typeof SubsectionActions.upsertOne>;
      createTagSearchItemSuccess: ReturnType<typeof createSuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for TagSearchTerm
 *
 */
export const TagSearchTermActions = {
  findAllSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  createInTemplateSuccess,
  createInSubsectionSuccess,
};
