import { Injectable } from '@angular/core';

export interface IPermissionMap {
  only?: string[];
  redirectTo?: string;
}

@Injectable()
export class PermissionStore {
  permissionStore: Map<string, boolean> = new Map();

  definePermission(permissionName: string, held: boolean) {
    this.permissionStore.set(permissionName, held);
  }

  /**
   * Treats roles and permissions the same.
   * Extend if we develop different needs.
   */
  authoriseByPermissionMap(map: IPermissionMap): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (map == null) return true;
    if (map.only && !map.only.some((permission) => this.permissionStore.get(permission))) {
      // Missing all of the permissions it should have.
      return false;
    }
    return true;
  }
}
