import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { FavouriteActions } from './favourite.action';

export type FavouriteState = EntityState<RR.Favourite>;

const adapter = createEntityAdapter<RR.Favourite>();

const initialState = adapter.getInitialState();

export const favouriteReducer = createReducer(
  initialState,
  on(FavouriteActions.findSuccess, (state: FavouriteState, { favourite }) => adapter.upsertOne(favourite, state)),
  on(FavouriteActions.findManySuccess, (state: FavouriteState, { favourites }) =>
    adapter.upsertMany(favourites, state),
  ),
  on(FavouriteActions.createSuccess, (state: FavouriteState, { favourite }) => adapter.addOne(favourite, state)),
  on(FavouriteActions.updateSuccess, (state: FavouriteState, { favourite }) => adapter.upsertOne(favourite, state)),
  on(FavouriteActions.updateManySuccess, (state: FavouriteState, { favourites }) =>
    adapter.upsertMany(favourites, state),
  ),
  on(FavouriteActions.deleteSuccess, (state: FavouriteState, { favouriteId }) => adapter.removeOne(favouriteId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
