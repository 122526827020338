import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { trackById } from 'app/app.utils';
import { ESStatementSearchResponse } from 'app/core/services/template.service';

import { TagComponent } from '../tag/tag.component';

@Component({
  standalone: true,
  imports: [CommonModule, TagComponent],
  selector: 'rr-prefill-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {
  @Input() topicId: number;
  @Input() tags: RR.TagSuggestion[];
  @Input() dividers: RR.Divider[];
  @Input() globalSearchTags: ESStatementSearchResponse;
  @Input() hideScore: boolean;
  @Input() topicTags: RR.Divider[];

  trackBy = trackById;
}
