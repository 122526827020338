import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, constructTagIdFromDivider } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ESStatementSearchHit } from 'app/core/services/template.service';
import { DividerStatementsModalComponent } from 'app/modules/editor/divider/divider-statements-modal/divider-statements-modal.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store/app.state';
import { fromTag, TagEffect } from 'app/store/prefill/tag';
import { fromTagChoice, TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { fromSession } from 'app/store/session';
import { fromStatement } from 'app/store/template/statement';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

/**
 * Represents a view of a single Tag
 *
 * The TagComponent class represents a view of a single tag. It handles the
 * code to present and style a single tag, and also handles the updating of the
 * values.
 *
 * @param tag: This is the tag that we are displaying.
 * @param topicId: The topic in which this tag was used.
 */
@Component({
  standalone: true,
  imports: [CommonModule, NgbModule, StoreSelectPipe, SharedModule],
  selector: 'rr-prefill-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit, OnDestroy {
  // 3 different Tag schemas handled by this component
  @Input() tag: RR.TagSuggestion | undefined;
  @Input() divider: RR.Divider | undefined; // current report tags
  @Input() globalSearchTag: ESStatementSearchHit | undefined;
  @Input() tagChoice: RR.TagChoice | undefined;
  @Input() filterTerm$?: Observable<string>;

  // TODO: Move the topicId to be a selector on the Router Store
  @Input() topicId: number;
  @Input() showRegionText = true;
  @Input() hideScore: boolean;
  @Input() @BindObservable() topicTags: RR.Divider[];
  topicTags$: Observable<RR.Divider[]>;
  // The State of the tag determined based on the values in the store.
  selected$: Observable<boolean>;
  // Whether to update
  autoUpdate$: Observable<boolean>;
  // Tag is already in current report (not selected but there are sentences under this tag in current report)
  isInCurrentReport$: Observable<boolean>;

  subscription = new Subscription();
  rrConfig: rrConfigType | undefined;

  selectStatement = fromStatement.selectStatement;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private tagEffect: TagEffect,
    private tagChoiceEffect: TagChoiceEffect,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromSession.selectRRConfig).subscribe((rrConfig) => {
        this.rrConfig = rrConfig;
      }),
    );
    // We need to do this on Initialisation rather than construction, since we
    // don't have a value for the @Input tag upon construction.
    if (this.tag) {
      this.selected$ = this.store.select(fromTagChoice.isSelected(this.tag.id));
      this.isInCurrentReport$ = this.topicTags$.pipe(
        switchMap((tags) =>
          of(
            tags.some(
              (topicTag) =>
                this.tag !== undefined &&
                topicTag.section === this.tag.section &&
                topicTag.subsection_id === this.tag.subsection_id &&
                ((!topicTag.region_id && !this.tag.region_id) || topicTag.region_id === this.tag.region_id) &&
                topicTag.statement_id === this.tag.tag_id,
            ),
          ),
        ),
      );
    }
    if (this.divider) {
      this.selected$ = this.store.select(fromTagChoice.isSelected(constructTagIdFromDivider(this.divider)));
    }
    this.autoUpdate$ = this.store.select(fromTag.selectAutoUpdate);
  }

  /**
   * Handle all the logic associated with a click event.
   *
   * This is the function called when a tag is clicked. The tag can be in one
   * of two different states. Selected and deSelected. The click action allows
   * us to toggle between the two. Because the determination of selection is
   * a selector, we need to take the latest value to determine whether we want
   * to perform the select or deselect action to toggle the state.
   *
   */
  onClick(): void {
    if (this.tagChoice) {
      this.openDividerStatementsModal();
    } else if (this.tag || this.divider) {
      this.subscription.add(
        combineLatest([this.autoUpdate$, this.selected$])
          .pipe(
            take(1),
            switchMap(([update, selected]) => {
              if (this.tag) {
                if (selected) {
                  this.subscription.add(this.tagChoiceEffect.deleteTagChoice(this.topicId, this.tag.id).subscribe());
                } else {
                  this.subscription.add(
                    this.tagChoiceEffect
                      .addTagChoice(this.topicId, {
                        tag_id: this.tag.tag_id,
                        section: this.tag.section,
                        subsection_id: this.tag.subsection_id,
                        region_id: this.tag.region_id,
                      })
                      .subscribe(),
                  );
                }
                if (update && this.rrConfig?.ANALYTICS) {
                  this.subscription.add(
                    this.tagEffect
                      .loadSuggested(this.topicId, this.tag.section)
                      .pipe(this.messageService.handleHttpErrorPipe)
                      .subscribe(),
                  );
                }
              } else if (this.divider) {
                if (selected) {
                  this.subscription.add(
                    this.tagChoiceEffect
                      .deleteTagChoice(this.topicId, constructTagIdFromDivider(this.divider))
                      .subscribe(),
                  );
                } else {
                  this.subscription.add(
                    this.tagChoiceEffect
                      .addTagChoice(this.topicId, {
                        tag_id: this.divider.statement_id,
                        section: this.divider.section,
                        subsection_id: this.divider.subsection_id,
                        region_id: this.divider.region_id,
                      })
                      .subscribe(),
                  );
                }
                if (update && this.rrConfig?.ANALYTICS) {
                  this.subscription.add(
                    this.tagEffect
                      .loadSuggested(this.topicId, this.divider.section)
                      .pipe(this.messageService.handleHttpErrorPipe)
                      .subscribe(),
                  );
                }
              }

              return of(null);
            }),
          )
          .subscribe(),
      );
    }
  }

  selectTagFromSearch() {
    if (this.filterTerm$) {
      this.subscription.add(
        this.filterTerm$.pipe(take(1)).subscribe((filterTerm) => {
          if (this.tag?.tag_text.toLowerCase().includes(filterTerm.toLowerCase())) {
            this.onClick();
          }
        }),
      );
    }
  }

  openDividerStatementsModal() {
    if (this.tag) {
      DividerStatementsModalComponent.open({
        modalService: this.modalService,
        topic_id: this.topicId,
        divider_id: this.tag.tag_id,
        region_id: this.tag.region_id,
        parent: 'PREFILL_TAG_MODAL',
      });
    } else if (this.divider) {
      DividerStatementsModalComponent.open({
        modalService: this.modalService,
        topic_id: this.topicId,
        divider_id: this.divider.statement_id,
        region_id: this.divider.region_id,
        parent: 'PREFILL_TAG_MODAL',
      });
    } else if (this.tagChoice) {
      DividerStatementsModalComponent.open({
        modalService: this.modalService,
        topic_id: this.topicId,
        divider_id: this.tagChoice.tag_id,
        region_id: this.tagChoice.region_id,
        parent: 'PREFILL_TAG_MODAL',
      });
    }
  }

  onTagRemoved(tagChoice: RR.TagChoice) {
    this.subscription.add(
      this.tagChoiceEffect.deleteTagChoice(this.topicId, tagChoice.id).subscribe({
        next: () => {
          this.messageService.add({
            type: 'success',
            message: 'Tag removed successfully, changes in prefill require refresh to see.',
            title: 'Success',
          });
        },
        error: (err: unknown) => {
          this.messageService.httpErrorMessage(err);
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
