import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { RegionChoiceActions } from './region-choice.action';

export type RegionChoiceState = EntityState<RR.RegionChoice>;

const adapter = createEntityAdapter<RR.RegionChoice>();

const initialState = adapter.getInitialState();

export const regionChoiceReducer = createReducer(
  initialState,
  on(RegionChoiceActions.addMany, (state, { regionChoices }) => adapter.addMany(regionChoices, state)),
  on(RegionChoiceActions.upsertMany, (state, { regionChoices }) => adapter.upsertMany(regionChoices, state)),
  on(RegionChoiceActions.removeMany, (state, { regionChoiceIds }) => adapter.removeMany(regionChoiceIds, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
