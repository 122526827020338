import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PercentileBandRuleActions } from '../percentile-band-rule/percentile-band-rule.action';
import { PercentileBandActions } from '../percentile-band/percentile-band.action';
import { PercentileBandGroupActions, PercentileBandGroupBatchActions } from './percentile-band-group.action';
import { PercentileBandGroup, PercentileBandGroupHttpService } from './percentile-band-group.service';

@Injectable()
export class PercentileBandGroupEffect {
  constructor(
    private store: Store<AppState>,
    private service: PercentileBandGroupHttpService,
  ) {}

  create(percentileBandGroup: Partial<PercentileBandGroup>) {
    return this.service.createPercentileBandGroup(percentileBandGroup).pipe(
      map((percentile_band_group) => PercentileBandGroupActions.createSuccess({ percentile_band_group })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(percentileBandGroupId: number) {
    return this.service.deletePercentileBandGroup(percentileBandGroupId).pipe(
      map(() => PercentileBandGroupActions.deleteSuccess({ percentile_band_group_id: percentileBandGroupId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(percentileBandGroupId: number, changes: Partial<PercentileBandGroup>) {
    return this.service.updatePercentileBandGroup(percentileBandGroupId, changes).pipe(
      map((percentile_band_group) => PercentileBandGroupActions.updateSuccess({ percentile_band_group })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search() {
    return this.service.getPercentileBands().pipe(
      map((response) =>
        PercentileBandGroupBatchActions.searchSuccess({
          actions: {
            searchPercentileBandGroupSuccess: PercentileBandGroupActions.searchSuccess({
              percentile_band_groups: response.percentile_band_groups,
            }),
            searchPercentileBandRuleSuccess: PercentileBandRuleActions.searchSuccess({
              percentile_band_rules: response.percentile_band_rules,
            }),
            searchPercentileBandSuccess: PercentileBandActions.searchSuccess({
              percentile_bands: response.percentile_bands,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
