import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MachineHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Machine[]> {
    return this.http.get<RR.Machine[]>('/api/machine');
  }

  create(machine: Partial<RR.Machine>): Observable<RR.Machine> {
    return this.http.post<RR.Machine>('/api/machine', machine);
  }

  update(machineId: number, changes: Partial<RR.Machine>): Observable<RR.Machine> {
    return this.http.put<RR.Machine>(`/api/machine/${machineId}`, changes);
  }

  delete(machineId: number) {
    return this.http.delete(`/api/machine/${machineId}`);
  }

  updateUnavailableMachine(unavailableId: number, notes: string): Observable<RR.MachineUnavailable> {
    return this.http.put<RR.MachineUnavailable>(`/api/machine_unavailable/${unavailableId}`, { notes });
  }
}
