import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { PatientNoteState } from './patient-note.reducer';

const adapter = createEntityAdapter<RR.PatientNote>();
const selectFeature = (state: AppState) => state.patient_note;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectPatientNote = (id: number) =>
  createSelector(selectEntities, (patient_notes: Dictionary<RR.PatientNote>) => patient_notes[id]);

const selectCount = createSelector(selectFeature, (state: PatientNoteState) => state.count);

export const fromPatientNote = {
  selectIds,
  selectEntities,
  selectAll,
  selectPatientNote,
  selectCount,
};
