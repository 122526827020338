import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { SectionChoiceEffect } from 'app/store/report/section-choice';
import { fromSection } from 'app/store/template/section';
import { of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../../shared/directives/tooltip.directive';
import { HeadingPreviewDeleteModalComponent } from './heading-preview-delete-modal.component';

@Component({
  selector: 'rr-heading-preview',
  templateUrl: './heading-preview.component.html',
  styleUrls: ['./heading-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipDirective],
})
@LifecycleLogger
export class SectionHeadingPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('otherImagingInput') otherImagingInput: ElementRef;
  @Input() topic: RR.Topic;
  @Input() section_choice_id: number;
  section_choice: RR.SectionChoice;
  @Input() mode: PreviewMode;
  show = of(false);
  subscriptions = new Subscription();
  // For editing other imaging in History section
  editingOtherImaging = false;

  constructor(
    private store: Store<AppState>,
    private reportService: ReportService,
    private editorService: EditorService,
    private renderer: Renderer2,
    private el: ElementRef,
    private modal: NgbModal,
    private cd: ChangeDetectorRef,
    private sectionChoiceEffect: SectionChoiceEffect,
  ) {}

  ngOnInit() {
    const section_choice$ = this.reportService.getSectionChoice(this.section_choice_id);

    this.subscriptions.add(
      section_choice$.subscribe((section_choice) => {
        // @ts-expect-error strictNullChecks
        this.section_choice = section_choice;
        this.renderer.setAttribute(this.el.nativeElement, 'name', this.section_choice.type);
      }),
    );

    this.show = this.reportService.getShowHeadingPreview(this.topic.id, this.section_choice_id);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  jumpToSection() {
    // TODO: Would it be better to whitelist over blacklisting? On balance of probability, do you think future PreviewModes
    // are more or less likely to be an exception to this rule? Does the PreviewMode paradigm need to be rethought entirely?
    if (this.mode === 'editor' || this.mode === 'final') {
      this.subscriptions.add(
        this.store.select(fromSection.selectSection(this.section_choice.section_id)).subscribe((section) => {
          if (!section) {
            throw new Error(`Section not found`);
          }
          this.editorService.jumpToSection(section, this.topic.id);
        }),
      );
    }
  }

  showOtherImaging() {
    return this.section_choice.type === 'history' && this.topic.other_imaging;
  }

  removeOtherImaging() {
    this.subscriptions.add(this.reportService.setOtherImaging(this.topic.id, null).subscribe());
  }

  editOtherImaging(edit: boolean) {
    this.editingOtherImaging = edit;
  }

  saveOtherImaging() {
    this.subscriptions.add(
      this.reportService
        .setOtherImaging(this.topic.id, this.otherImagingInput.nativeElement.value)
        .pipe(take(1))
        .subscribe(() => {
          this.editingOtherImaging = false;
          this.cd.markForCheck();
        }),
    );
  }

  async removeSection() {
    const modalRef = HeadingPreviewDeleteModalComponent.open(this.modal);
    modalRef.componentInstance.headingName = 'Section';
    try {
      await modalRef.result;
      this.subscriptions.add(this.sectionChoiceEffect.delete(this.section_choice_id).subscribe());
    } catch {
      // do nothing on dismiss
    }
  }
}
