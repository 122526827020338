import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BillingItemHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.BillingItem[]> {
    return this.http.get<RR.BillingItem[]>('/api/billing_item');
  }

  create(billingItem: Partial<RR.BillingItem>): Observable<RR.BillingItem> {
    return this.http.post<RR.BillingItem>('/api/billing_item', billingItem);
  }

  update(billingItemId: number, changes: Partial<RR.BillingItem>): Observable<RR.BillingItem> {
    return this.http.put<RR.BillingItem>(`/api/billing_item/${billingItemId}`, changes);
  }

  delete(billingItemId: number) {
    return this.http.delete<RR.BillingItem>(`/api/billing_item/${billingItemId}`);
  }
}
