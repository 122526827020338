<div class="modal-header">
  <h5 class="modal-title">Delete Element</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  Are you sure you want to delete element from the template?
  <div class="text-primary mb-3">
    <span class="fa fa-stream"></span>
    <strong *ngIf="statement_set$ | async as statementSet">
      {{ statementSet.name }}
    </strong>
  </div>
  <form (submit)="removeElement(password.value)">
    <div class="form-group mb-2">
      <div>Admin password:</div>
      <input
        #password
        name="admin-password"
        rrAutoFocus
        type="password"
        class="form-control"
        placeholder="Please enter your password"
      />
    </div>
  </form>
  <div class="mt-3">
    <span class="fa fa-exclamation-triangle text-warning me-1"></span> Note: This element is still available in the
    library, and can be reimported again.
  </div>
</div>

<div class="modal-footer">
  <button ngbAutoFocus class="btn btn-danger" (click)="removeElement(password.value)">Delete</button>
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
