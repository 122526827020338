<span *ngIf="statement$ | async as statement">
  <rr-text-object-render
    *ngFor="let textObjectId of statement.text_objects"
    [textObjectId]="textObjectId"
    [template_id]="template_id"
    [proposed]="proposed"
    [region]="region"
  >
  </rr-text-object-render>
</span>
