import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReferrer } from 'app/store/referrer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-referrer-preferred-contacts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './referrer-preferred-contacts.component.html',
  styleUrls: ['./referrer-preferred-contacts.component.css'],
})
export class ReferrerPreferredContactsComponent implements OnInit, OnDestroy {
  @Input() referrer_id: number;

  contactMethods: RR.ReferrerContactMethod[];
  subscription: Subscription = new Subscription();
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromReferrer.selectPreferredContacts(this.referrer_id)).subscribe((contacts) => {
        this.contactMethods = contacts;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
