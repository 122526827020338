import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgbDropdown, NgbModal, NgbDropdownToggle, NgbTooltip, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SelectorService } from 'app/core/services/selector.service';
import { InitialsModalComponent } from 'app/shared/modals/initials-modal/initials-modal.component';
import { AppState } from 'app/store';
import { fromSession, SessionEffect } from 'app/store/session';
import { SettingEffect } from 'app/store/setting/setting.effect';
import { fromSetting } from 'app/store/setting/setting.selector';
import { Observable } from 'rxjs';

import { PermissionDirective } from '../../../../shared/directives/permission.directive';
import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';

@Component({
  selector: 'rr-session-dropdown',
  templateUrl: './session-dropdown.component.html',
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbTooltip,
    NgbDropdownMenu,
    TooltipDirective,
    PermissionDirective,
    RouterLinkActive,
    RouterLink,
  ],
})
export class SessionDropdownComponent implements OnInit {
  @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;

  currentUser$: Observable<RR.User | undefined>;
  kioskUser$: Observable<RR.User | undefined>;
  kioskState$: Observable<boolean>;

  constructor(
    private sessionEffect: SessionEffect,
    private store: Store<AppState>,
    private settingEffect: SettingEffect,
    private selectorService: SelectorService,
    private modal: NgbModal,
  ) {}

  logout() {
    this.sessionEffect.logout();
  }

  switchUser() {
    InitialsModalComponent.open({ modal: this.modal, reportId: undefined });
  }

  ngOnInit() {
    this.kioskState$ = this.store.select(fromSetting.selectKiosk);
    this.currentUser$ = this.selectorService.selectLoadedCurrentUser();
    this.kioskUser$ = this.store.select(fromSession.selectKioskUser);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  toggleKiosk = () => {
    this.settingEffect.toggleKiosk();
  };
}
