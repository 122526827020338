import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RadReportVersionError } from 'app/core/interceptors/app-version-interceptor';

import { MessageService } from './message.service';

@Injectable()
export class VersionUpdateService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
  ) {}

  /**
   * Version is checked on every API request header, see src/app/core/interceptors/app-version-interceptor.ts
   *
   * This API is polled so that updates are found even if the client isn't sending requests.
   */
  checkVersionUpdate() {
    this.http
      .get<{
        version: string;
      }>('/api/version')
      .subscribe();
  }

  notifyUpdate(error: RadReportVersionError) {
    console.info(error);
    const seenRRVersion = localStorage.getItem('seenRRVersion');
    if (seenRRVersion === error.version) {
      // Prevent refresh infinite loop. Currently, the process to release only
      // frontend assets does not update the server version. Also, if there's
      // some other problem with this, it shouldn't lock users out.
      return;
    }
    this.messageService.add({
      type: 'VersionUpdateToastComponent',
      id: 'version-update',
      data: error,
    });
  }

  reload() {
    window.location.reload();
  }
}
