import { createReducer, on } from '@ngrx/store';

import { SessionActions, SessionBatchActions } from './session.action';
import { BeforeLogoutListenerType } from './session.types';

export type SessionState = {
  currentUserId: number | undefined;
  kioskUserId: number | undefined;
  /**
   * false means waiting for the user to load.
   * true user has been loaded. If we are logged out, loaded would still change to true but no user would be found.
   */
  loaded: boolean;
  goalRoute: string | undefined;
  // Show feedback notification to user after they logged in
  userFeedbackNotification: boolean;
  rrConfig: rrConfigType | undefined;
  // Actions run on before logout, ie: submit time spent on report for Report time tracker
  beforeLogoutListeners: BeforeLogoutListenerType[];
};

const initialState: SessionState = {
  currentUserId: undefined,
  kioskUserId: undefined,
  loaded: false,
  goalRoute: undefined,
  userFeedbackNotification: false,
  rrConfig: undefined,
  beforeLogoutListeners: [],
};

export const sessionReducer = createReducer(
  initialState,
  on(SessionActions.setKioskUser, (state, { userId }) => {
    return {
      ...state,
      kioskUserId: userId,
    };
  }),
  on(SessionBatchActions.fetchConfigSuccess, (state: SessionState, action) => {
    return {
      ...state,
      // We've 'loaded' the user and found none. This is important otherwise NoAuthGuard will freeze waiting for a user.
      loaded: true,
      currentUserId: action.config.user?.id,
      rrConfig: action.config,
    };
  }),
  on(SessionActions.updateConfigSuccess, (state: SessionState, { configuration }) => {
    if (state.rrConfig === undefined) {
      throw new Error('rrConfig is undefined');
    }
    return { ...state, rrConfig: { ...state.rrConfig, configuration } };
  }),

  on(SessionBatchActions.loginSuccess, (state: SessionState, { user }) => {
    return {
      ...state,
      loaded: true,
      currentUserId: user.id,
      goalRoute: undefined,
      userFeedbackNotification: false,
    };
  }),
  on(SessionActions.logoutSuccess, (state: SessionState) => {
    return {
      ...state,
      // loaded: false, // Can't do this because `loaded: false` makes the auth-guards block before they can redirect.
      currentUserId: undefined,
      userFeedbackNotification: false,
    };
  }),
  on(SessionActions.notifiedUserFeedbacks, (state: SessionState) => {
    return { ...state, userFeedbackNotification: true };
  }),
  on(SessionActions.setGoalRoute, (state: SessionState, { goalRoute }) => {
    return { ...state, goalRoute };
  }),
  on(SessionActions.registerBeforeLogoutListener, (state: SessionState, { listener }) => {
    return { ...state, beforeLogoutListeners: [...state.beforeLogoutListeners, listener] };
  }),
  on(SessionActions.unregisterBeforeLogoutListener, (state: SessionState, { listener }) => {
    return { ...state, beforeLogoutListeners: [...state.beforeLogoutListeners.filter((l) => l !== listener)] };
  }),
  on(SessionActions.unregisterAllBeforeLogoutListeners, (state: SessionState) => {
    return { ...state, beforeLogoutListeners: [] };
  }),
  on(SessionActions.reset, (state) => {
    return {
      ...initialState,
      // don't reset those below
      goalRoute: state.goalRoute,
      rrConfig: state.rrConfig,
    };
  }),
);
