import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { PresetSearchResponse } from 'app/store/report/preset';
import { fromPresetTitle } from 'app/store/report/preset-title';
import { Observable } from 'rxjs';

@Component({
  selector: 'rr-similar-preset-warning',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './similar-preset-warning.component.html',
  styleUrl: './similar-preset-warning.component.css',
})
export class SimilarPresetWarningComponent {
  @BindObservable() @Input() presets: PresetSearchResponse | undefined;
  presets$: Observable<PresetSearchResponse | undefined>;

  constructor() {}

  selectPresetTitle = fromPresetTitle.selectPresetTitle;
}
