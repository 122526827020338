<!--Doctor signature-->
<ng-container *ngIf="mode === 'editor'">
  <button
    role="button"
    class="content btn btn-outline-success text-start item-hover-parent w-100"
    *ngIf="signatureText"
    [disabled]="!(checkSignaturePermission() | async)"
    (click)="open()"
  >
    <span [innerText]="signatureText"></span>
    <div class="item-btns-right item-hover">
      <button
        class="btn btn-light btn-sm"
        role="button"
        rrTooltip="REMOVE_SIGNATURE"
        (click)="remove(); $event.stopPropagation()"
        [disabled]="!(checkSignaturePermission() | async)"
      >
        <span class="fa fa-trash"></span> Remove
      </button>
    </div>
  </button>
  <button
    role="button"
    *ngIf="!signatureText"
    class="btn btn-outline-dark w-100"
    rrTooltip="ADD_SIGNATURE"
    (click)="open()"
    [disabled]="!(checkSignaturePermission() | async)"
    [rrAutoFocus]="autoFocus"
  >
    <span class="fa fa-plus-circle"></span> {{ addBtnText }}
  </button>
</ng-container>
<div class="w-100" *ngIf="mode !== 'editor' && signatureText" [innerText]="signatureText"></div>
