import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { BookingCodeLocatorActions } from './booking-code-locator.action';
import { BookingCodeLocatorHttpService } from './booking-code-locator.service';

@Injectable()
export class BookingCodeLocatorEffect {
  constructor(
    private store: Store<AppState>,
    private service: BookingCodeLocatorHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((bookingCodeLocators: RR.BookingCodeLocator[]) =>
        BookingCodeLocatorActions.findAllSuccess({ bookingCodeLocators }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(bookingCodeLocator: Partial<RR.BookingCodeLocator>) {
    return this.service.create(bookingCodeLocator).pipe(
      map((bookingCodeLocator: RR.BookingCodeLocator) =>
        BookingCodeLocatorActions.createSuccess({ bookingCodeLocator }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(bookingCodeLocatorId: number, changes: Partial<RR.BookingCodeLocator>) {
    return this.service.update(bookingCodeLocatorId, changes).pipe(
      map((bookingCodeLocator) => BookingCodeLocatorActions.updateSuccess({ bookingCodeLocator })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(bookingCodeLocatorId: number) {
    return this.service.delete(bookingCodeLocatorId).pipe(
      map(() => BookingCodeLocatorActions.deleteSuccess({ bookingCodeLocatorId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
