import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { BillingItemLinkPipe } from '../../pipes/billing-item-link-pipe';

@Component({
  standalone: true,
  imports: [CommonModule, BillingItemLinkPipe],
  selector: 'rr-billing-item-link',
  templateUrl: './billing-item-link.component.html',
  styleUrls: ['./billing-item-link.component.css'],
})
export class BillingItemLinkComponent {
  @Input() item_code: string | undefined;
}
