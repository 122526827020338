import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromBooking } from 'app/store/booking';
import { fromReferralEnquiry } from 'app/store/referral-enquiry';

const adapter = createEntityAdapter<RR.SendEvent>();
const selectFeature = (state: AppState) => state.send_event;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectSendEventsInReport = (reportId: number) =>
  createSelector(selectAll, (events) => events.filter((e) => e.report_id === reportId));

const selectSendEventsByTypeInReport = (reportId: number, type: 'SMS' | 'EMAIL' | 'FAX') =>
  createSelector(selectAll, (events) =>
    events.filter((e) => e.report_id === reportId && e.communication_type === type),
  );

const selectSendEventsInBooking = (bookingId: number) =>
  createSelector(
    fromBooking.selectBooking(bookingId),
    selectEntities,
    (booking, events) =>
      booking?.send_events.map((eventId) => events[eventId]).filter((event): event is RR.SendEvent => !!event) || [],
  );

const selectSendEventsInEnquiry = (enquiryId: number) =>
  createSelector(fromReferralEnquiry.selectReferralEnquiry(enquiryId), selectEntities, (enquiry, events) =>
    enquiry?.send_events
      ? enquiry.send_events.map((eventId) => events[eventId]).filter((event): event is RR.SendEvent => !!event)
      : [],
  );

export const fromSendEvent = {
  selectIds,
  selectEntities,
  selectAll,
  selectSendEventsInReport,
  selectSendEventsByTypeInReport,
  selectSendEventsInBooking,
  selectSendEventsInEnquiry,
};
