<div class="modal-header">
  <h5 class="modal-title">Link Report(s)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body pt-0 mt-2">
  <ng-container *ngIf="isSearching">
    <div class="d-flex justify-content-center loading">
      <i class="fa fa-spinner fa-spin fa-2x text-secondary"></i>
    </div>
  </ng-container>

  <ng-container *ngIf="filteredReportIds.length > 0; else noReports">
    <ng-container *ngFor="let reportId of filteredReportIds">
      <div class="d-flex mb-4 align-items-center">
        <div class="w-100 me-2">
          <rr-invoice-form-metadata [reportId]="reportId" [linkReports]="true"></rr-invoice-form-metadata>
        </div>
        <button
          *ngIf="!(invoice?.reports | numberInArray: reportId)"
          (click)="linkReport(reportId)"
          role="button"
          class="btn btn-outline-primary"
        >
          <span class="fas fa-plus"></span>
        </button>
        <button
          *ngIf="invoice?.reports | numberInArray: reportId"
          (click)="unlinkReport(reportId)"
          role="button"
          class="btn btn-outline-danger"
        >
          <span class="fas fa-minus"></span>
        </button>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noReports>
    <h3>No Reports...</h3>
  </ng-template>
</div>

<div class="modal-footer d-flex justify-content-between">
  <button class="btn btn-light ms-2" role="button" (click)="activeModal.dismiss()">Close</button>
</div>
