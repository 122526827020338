import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromRegion } from '../region/region.selector';

const adapter = createEntityAdapter<RR.RegionSet>();
const selectFeature = (state: AppState) => state.region_set;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

/**
 * Select a single region set given the Id
 *
 * This converts the regionSetId into the corresponding RegionSet.
 *
 * @params: regionSetId: The identifier of the region set which is set within the database.
 */
const selectRegionSet = (regionSetId: number) =>
  createSelector(selectEntities, (regionSets: Dictionary<RR.RegionSet>) => regionSets[regionSetId]);

/**
 * Select the regions contained within a given regionSet
 *
 * @params: regionSetId: The identifier of the region set which is set within the database.
 */
const selectRegions = (regionSetId: number) =>
  createSelector(
    selectEntities,
    fromRegion.selectEntities,
    (regionSets: Dictionary<RR.RegionSet>, regions: Dictionary<RR.Region>) => {
      return regionSets[regionSetId]?.region_ids
        .map((regionId) => regions[regionId])
        .filter((r): r is RR.Region => !!r);
    },
  );

export const fromRegionSet = {
  selectIds,
  selectEntities,
  selectAll,
  selectRegionSet,
  selectRegions,
};
