import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NoContentDirective } from 'app/shared/directives/no-content.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-super-urgent-button',
  standalone: true,
  imports: [CommonModule, SharedModule, NoContentDirective],
  templateUrl: './super-urgent-button.component.html',
  styleUrl: './super-urgent-button.component.css',
})
export class SuperUrgentButtonComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() btnClassName = '';
  @Input() btnSize: '' | 'btn-sm' | 'btn-lg' = 'btn-sm';
  @Input() btnColour = 'btn-primary';
  report$: Observable<RR.Report | undefined>;
  subscription = new Subscription();

  constructor(
    public reportEffect: ReportEffect,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));

    this.subscription.add(
      this.report$.subscribe(() => {
        this.cdr.detectChanges();
      }),
    );
  }

  onClick(report: RR.Report) {
    // toggle the super_urgent status
    const newSuperUrgentStatus = !report.super_urgent;
    this.subscription.add(this.reportEffect.markSuperUrgent(report.id, newSuperUrgentStatus).subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
