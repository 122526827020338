import { createAction, props } from '@ngrx/store';

export const InstitutionActions = {
  findSuccess: createAction('[Institution] Find Success', props<{ institution: RR.Institution }>()),

  searchSuccess: createAction(
    '[Institution] Search Success',
    props<{ count: number; institutions: RR.Institution[] }>(),
  ),

  findManySuccess: createAction('[Institution] Find Many Success', props<{ institutions: RR.Institution[] }>()),

  createSuccess: createAction('[Institution] Create Success', props<{ institution: RR.Institution }>()),

  updateSuccess: createAction('[Institution] Update Success', props<{ institution: RR.Institution }>()),

  deleteSuccess: createAction('[Institution] Delete Success', props<{ institution: RR.Institution }>()),
};
