import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';
import { FocusService } from 'app/core/services/focus.service';
import { SocketService } from 'app/core/services/socket.service';
import { AdminService } from 'app/modules/admin/services/admin.service';
import { PriorStudyLinksService } from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.service.component';
import { PrefillService } from 'app/modules/editor/prefill/prefill.service';
import { NavigationService } from 'app/shared/components/navigation/navigation.service';
import { RRStoreModule } from 'app/store/store.module';

import { AuthGuard, NoAuthGuard } from './guards/auth-guard.service';
import { Http401Interceptor } from './interceptors/app-interceptor';
import { AppVersionInterceptor } from './interceptors/app-version-interceptor';
import { SentryErrorHandler } from './loggers/sentry.logger';
import { EditorService } from './services/editor.service';
import { HotkeysService } from './services/hotkeys.service';
import { MessageService } from './services/message.service';
import { ReportService } from './services/report.service';
import { RouterHistoryService } from './services/router-history.service';
import { SelectorService } from './services/selector.service';
import { TemplateService } from './services/template.service';
import { VersionUpdateService } from './services/version-update.service';
import { VoiceRecognitionService } from './services/voice-recognition.service';

// ReportService is magic import that breaks everything if you move it to a later line

@NgModule({
  imports: [RRStoreModule, CommonModule, HttpClientModule, NgbModule],
  declarations: [],
  providers: [
    VersionUpdateService,
    MessageService,
    ReportService,
    TemplateService,
    EditorService,
    FocusService,
    SocketService,
    SelectorService,
    HotkeysService,
    VoiceRecognitionService,
    RouterHistoryService,
    AdminService,
    PriorStudyLinksService,
    PrefillService,
    AuthGuard,
    NoAuthGuard,
    NavigationService,
    { provide: ErrorHandler, useValue: new SentryErrorHandler() },
    {
      // To start a new trace when Angular navigates between pages
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: LOCALE_ID, useValue: 'en-AU' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http401Interceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        HotkeysService,
        // SessionService,
        // {
        //   provide: SESSION_CONFIG,
        //   useValue: config
        // }
      ],
    };
  }
}
