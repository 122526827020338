import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { TagActions } from './tag.action';
import { initialState } from './tag.state';

export const tagReducer = createReducer(
  initialState,

  // Reset back to the initial state
  on(TagActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),

  on(TagActions.toggleSuggestedLoading, (state, { section }) => {
    const sectionTags = { ...state.sectionTags };
    sectionTags[section] = {
      ...sectionTags[section],
      isMLSuggested: false,
      isSuggestedLoading: true,
      isSuggestedLoaded: false,
    };
    return {
      ...state,
      sectionTags: sectionTags,
    };
  }),

  on(TagActions.toggleStructureLoading, (state, { section }) => {
    const sectionTags = { ...state.sectionTags };
    sectionTags[section] = { ...sectionTags[section], isStructureLoading: true, isStructureLoaded: false };
    return {
      ...state,
      sectionTags: sectionTags,
    };
  }),

  on(TagActions.loadSuggestedSuccess, (state, { section, suggestedTags: tags, isMLSuggested }) => {
    const sectionTags = { ...state.sectionTags };
    sectionTags[section] = {
      ...sectionTags[section],
      suggestedTags: tags,
      isMLSuggested,
      isSuggestedLoading: false,
      isSuggestedLoaded: true,
    };
    return {
      ...state,
      sectionTags: sectionTags,
    };
  }),

  on(TagActions.loadStructureSuccess, (state, { section, structure }) => {
    const sectionTags = { ...state.sectionTags };
    sectionTags[section] = {
      ...sectionTags[section],
      isStructureLoading: false,
      isStructureLoaded: true,
      templateStructure: structure,
    };

    return {
      ...state,
      sectionTags: sectionTags,
    };
  }),

  on(TagActions.search, (state, { text: text }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        text,
      },
    };
  }),

  on(TagActions.toggleAutoUpdate, (state, { auto }) => ({
    ...state,
    autoUpdate: auto !== undefined ? auto : !state.autoUpdate,
  })),
);
