import { CommonModule } from '@angular/common';
import { Component, OnInit, ElementRef } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { TrackDirective } from 'app/shared/directives/track.directive';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: '[rr-nav-link]',
  standalone: true,
  imports: [CommonModule, SharedModule],
  template: `<ng-content></ng-content>`,
  host: {
    class: 'nav-link',
  },
  hostDirectives: [
    {
      directive: RouterLinkActive,
    },
    {
      directive: TrackDirective,
    },
  ],
})
export class NavLinkComponent implements OnInit {
  constructor(
    private routerLinkActive: RouterLinkActive,
    private trackDirective: TrackDirective,
    private el: ElementRef<HTMLLinkElement>,
  ) {}

  ngOnInit(): void {
    this.routerLinkActive.routerLinkActive = 'active';

    const textContent = this.el.nativeElement.textContent;
    if (textContent === null) {
      throw new Error('textContent is null');
    }
    const text = textContent.trim();
    this.trackDirective.rrTrack = `${text} - Navigation`;

    // if parent has class 'dropdown-menu', then remove the 'nav-link' class and add 'dropdown-item' class
    const dropdownParent = this.el.nativeElement.closest('.dropdown-menu');
    if (dropdownParent) {
      this.el.nativeElement.classList.remove('nav-link');
      this.el.nativeElement.classList.add('dropdown-item');
    }
  }
}
