import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ReferralEnquiry>();
const selectFeature = (state: AppState) => state.referral_enquiry;

const { selectEntities } = adapter.getSelectors(selectFeature);

const selectReferralEnquiry = (id: number) =>
  createSelector(selectEntities, (enquiries: Dictionary<RR.ReferralEnquiry>) => enquiries[id]);

export const fromReferralEnquiry = {
  selectReferralEnquiry,
};
