import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type BookingCodeAndScanCodeResponse = {
  booking_code: RR.BookingCode;
  scan_code: RR.ScanCode;
};

export type BookingCodeAndSiteResponse = {
  booking_code: RR.BookingCode;
  site: RR.Site;
};

export type BookingCodeAndRegistrationFormResponse = {
  booking_code: RR.BookingCode;
  registration_form: RR.RegistrationForm;
};

export type BookingCodeAndUserResponse = {
  booking_code: RR.BookingCode;
  user: RR.User;
};

@Injectable()
export class BookingCodeHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.BookingCode[]> {
    return this.http.get<RR.BookingCode[]>('/api/booking_code');
  }

  create(bookingCode: Partial<RR.BookingCode>): Observable<RR.BookingCode> {
    return this.http.post<RR.BookingCode>('/api/booking_code', bookingCode);
  }

  update(bookingCodeId: number, changes: Partial<RR.BookingCode>): Observable<RR.BookingCode> {
    return this.http.put<RR.BookingCode>(`/api/booking_code/${bookingCodeId}`, changes);
  }

  delete(bookingCodeId: number) {
    return this.http.delete<RR.BookingCode>(`/api/booking_code/${bookingCodeId}`);
  }

  addScanCode(bookingCodeId: number, scanCodeId: number): Observable<BookingCodeAndScanCodeResponse> {
    return this.http.post<BookingCodeAndScanCodeResponse>(
      `/api/booking_code/${bookingCodeId}/scan_code/${scanCodeId}`,
      {},
    );
  }

  removeScanCode(bookingCodeId: number): Observable<BookingCodeAndScanCodeResponse> {
    return this.http.delete<BookingCodeAndScanCodeResponse>(`/api/booking_code/${bookingCodeId}/scan_code`);
  }

  addSite(bookingCodeId: number, siteId: number): Observable<BookingCodeAndSiteResponse> {
    return this.http.post<BookingCodeAndSiteResponse>(`/api/booking_code/${bookingCodeId}/site/${siteId}`, {});
  }

  removeSite(bookingCodeId: number, siteId: number): Observable<BookingCodeAndSiteResponse> {
    return this.http.delete<BookingCodeAndSiteResponse>(`/api/booking_code/${bookingCodeId}/site/${siteId}`);
  }

  addUser(bookingCodeId: number, userId: number): Observable<BookingCodeAndUserResponse> {
    return this.http.post<BookingCodeAndUserResponse>(`/api/booking_code/${bookingCodeId}/user/${userId}`, {});
  }

  removeUser(bookingCodeId: number, userId: number): Observable<BookingCodeAndUserResponse> {
    return this.http.delete<BookingCodeAndUserResponse>(`/api/booking_code/${bookingCodeId}/user/${userId}`);
  }

  addRegistrationForm(bookingCodeId: number, formId: number): Observable<BookingCodeAndRegistrationFormResponse> {
    return this.http.post<BookingCodeAndRegistrationFormResponse>(
      `/api/booking_code/${bookingCodeId}/registration_form/${formId}`,
      {},
    );
  }

  removeRegistrationForm(bookingCodeId: number, formId: number): Observable<BookingCodeAndRegistrationFormResponse> {
    return this.http.delete<BookingCodeAndRegistrationFormResponse>(
      `/api/booking_code/${bookingCodeId}/registration_form/${formId}`,
    );
  }
}
