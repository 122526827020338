import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { ImgsimParamsHttpService } from 'app/store/imgsim-parameters';
import { fromCurrentReport } from 'app/store/report/report';
import {
  StatementParameterCondition,
  StatementParameterConditionEffect,
} from 'app/store/statement-parameter-condition';
import {
  StatementParameterConditionGroup,
  StatementParameterConditionGroupEffect,
} from 'app/store/statement-parameter-condition-group';
import { Observable, Subscription, filter, of, switchMap } from 'rxjs';

import { ParameterDropdownComponent } from '../../../../shared/components/parameter-dropdown/parameter-dropdown.component';
import { ParameterNamePipe } from '../../../../shared/pipes/parameter-name-pipe';
import { StatementTextRenderComponent } from '../statement-text-render/statement-text-render.component';

declare type StatementParameterConditionOperator =
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'GREATER_THAN'
  | 'GREATER_THAN_OR_EQUAL_TO'
  | 'LESS_THAN'
  | 'LESS_THAN_OR_EQUAL_TO';
// | 'CONTAINS'
// | 'NOT_CONTAINS'
// | 'STARTS_WITH'
// | 'ENDS_WITH';

declare type StatementParameterConditionAction = 'INCLUDE' | 'EXCLUDE';

export const parameterOperatorTypeMap: Record<StatementParameterConditionOperator, string> = {
  EQUALS: 'Equals',
  NOT_EQUALS: 'Not Equals',
  GREATER_THAN: 'Greater Than',
  GREATER_THAN_OR_EQUAL_TO: 'Greater Than or Equal To',
  LESS_THAN: 'Less Than',
  LESS_THAN_OR_EQUAL_TO: 'Less Than or Equal To',
  // CONTAINS: 'Contains',
  // NOT_CONTAINS: 'Not Contains',
  // STARTS_WITH: 'Starts With',
  // ENDS_WITH: 'Ends With',
};

export const parameterActionTypeMap: Record<StatementParameterConditionAction, string> = {
  INCLUDE: 'Include',
  EXCLUDE: 'Exclude',
};

@Component({
  selector: 'rr-statement-conditions-modal',
  templateUrl: './statement-conditions-modal.html',
  styleUrls: ['./statement-conditions-modal.css'],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    StatementTextRenderComponent,
    FormsModule,
    ReactiveFormsModule,
    ParameterDropdownComponent,
    ParameterNamePipe,
    CommonModule,
  ],
})
export class StatementConditionsModalComponent implements OnInit, OnDestroy {
  @Input() statementId: number;
  @Input() templateId: number;
  @Input() region: RR.Region | undefined;
  @Input() proposed: RR.ProposedStatement['proposed'] | undefined;

  subscription = new Subscription();
  data: StatementParameterConditionGroup | undefined = undefined;
  parameters: RR.ImgsimParameter[] | undefined = undefined;
  hasNewCondition = false;

  conditionForm = new FormGroup({
    action: new FormControl<string | undefined>(undefined, { validators: Validators.required, nonNullable: true }),
    hide_if_invalid: new FormControl(false, { nonNullable: true }),
    conditions: new FormArray([
      new FormGroup({
        id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        parameter_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        selectedParameter: new FormControl<RR.ImgsimParameter | undefined>(undefined, { nonNullable: true }),
        disabled: new FormControl(false, { nonNullable: true }),
        operator: new FormControl<string | undefined>(undefined, {
          validators: Validators.required,
          nonNullable: true,
        }),
        value: new FormControl('', { validators: Validators.required, nonNullable: true }),
      }),
    ]),
  });

  parameterOperatorTypeMap = parameterOperatorTypeMap;
  parameterActionTypeMap = parameterActionTypeMap;

  constructor(
    public activeModal: NgbActiveModal,
    private statementParameterConditionGroupEffect: StatementParameterConditionGroupEffect,
    private statementParameterConditionEffect: StatementParameterConditionEffect,
    private imgsimParamsHttpService: ImgsimParamsHttpService,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.statementParameterConditionGroupEffect.find(this.statementId).subscribe((data) => {
        if (data) {
          this.data = data;
          const conditionsFormArray = this.conditionForm.controls.conditions;
          conditionsFormArray.clear();
          this.data.statement_parameter_conditions.forEach((conditionData) => {
            conditionsFormArray.push(this.conditionFormGroup(conditionData));
          });
          this.conditionForm.patchValue({
            action: this.data.action,
            hide_if_invalid: this.data.hide_if_invalid,
          });
        }
      }),
    );

    this.subscription.add(
      this.store
        .select(fromCurrentReport.selectReportId)
        .pipe(
          switchMap((reportId) =>
            reportId ? this.imgsimParamsHttpService.getReportImgSimParams(reportId) : of(undefined),
          ),
        )
        .subscribe((data) => {
          this.parameters = data;
        }),
    );
  }

  conditionFormGroup(conditionData?: StatementParameterCondition): FormGroup {
    return new FormGroup({
      id: new FormControl<number | undefined>(conditionData?.id ?? undefined, { nonNullable: true }),
      parameter_id: new FormControl<number | undefined>(conditionData?.parameter_id, { nonNullable: true }),
      selectedParameter: new FormControl<RR.ImgsimParameter | undefined>(undefined, { nonNullable: true }),
      disabled: new FormControl<boolean>(conditionData?.disabled ?? false, { nonNullable: true }),
      operator: new FormControl<string | undefined>(conditionData?.operator, {
        validators: Validators.required,
        nonNullable: true,
      }),
      value: new FormControl<string | undefined>(conditionData?.value, {
        validators: Validators.required,
        nonNullable: true,
      }),
    });
  }

  updateFormGroup(data: StatementParameterConditionGroup | undefined) {
    this.data = data;
    const conditionsFormArray = this.conditionForm.controls.conditions;
    conditionsFormArray.clear();
    if (data) {
      data.statement_parameter_conditions.forEach((conditionData) => {
        conditionsFormArray.push(this.conditionFormGroup(conditionData));
      });

      this.conditionForm.patchValue({
        action: data.action,
        hide_if_invalid: data.hide_if_invalid,
      });
    }
  }

  addCondition() {
    const conditions = this.conditionForm.controls.conditions;
    conditions.push(
      new FormGroup({
        id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        parameter_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
        selectedParameter: new FormControl<RR.ImgsimParameter | undefined>(undefined, { nonNullable: true }),
        disabled: new FormControl(false, { nonNullable: true }),
        operator: new FormControl<string | undefined>(undefined, {
          validators: Validators.required,
          nonNullable: true,
        }),
        value: new FormControl('', { validators: Validators.required, nonNullable: true }),
      }),
    );
    this.hasNewCondition = true;
  }

  removeCondition(conditionToRemove: FormGroup) {
    const conditions = this.conditionForm.controls.conditions;
    conditions.removeAt(conditions.controls.indexOf(conditionToRemove));
  }

  chooseParameter(parameter: RR.ImgsimParameter, condition: FormGroup) {
    condition.controls.selectedParameter.patchValue(parameter);
    condition.controls.parameter_id.patchValue(parameter.id);
    condition.markAsDirty();
  }

  createParamConditionGroup() {
    const conditionGroup = this.conditionForm.controls;
    const valid = conditionGroup.conditions.controls.every((condition) => condition.valid);

    if (valid) {
      // Create statement_parameter_condition_group object
      const statement_parameter_condition_group: Partial<StatementParameterConditionGroup> = {
        statement_id: this.statementId,
        action: conditionGroup.action.value || undefined,
        hide_if_invalid: conditionGroup.hide_if_invalid.value,
        statement_parameter_conditions: [],
      };

      conditionGroup.conditions.controls.forEach((condition) => {
        const formGroupCondition = condition;
        const { parameter_id, disabled, value, operator } = formGroupCondition.controls;

        const statement_parameter_condition: Partial<StatementParameterCondition> = {
          parameter_id: parameter_id.value ?? undefined,
          value: value.value || undefined,
          operator: operator.value || undefined,
          disabled: disabled.value,
        };

        const conditions = statement_parameter_condition_group.statement_parameter_conditions;
        if (conditions) {
          conditions.push(statement_parameter_condition as StatementParameterCondition);
        }
      });

      const operationObservable: Observable<unknown> = this.statementParameterConditionGroupEffect.create(
        statement_parameter_condition_group as StatementParameterConditionGroup,
      );

      // Subscribe to the operation
      this.subscription.add(
        operationObservable
          .pipe(
            filter((result) => result !== null),
            switchMap(() => {
              this.messageService.add({
                type: 'success',
                title: 'Success',
                message: 'Create statement parameter condition group',
              });

              this.hasNewCondition = false;
              return this.statementParameterConditionGroupEffect.find(this.statementId);
            }),
          )
          .subscribe((data) => {
            this.updateFormGroup(data);
            this.conditionForm.markAsPristine();
          }),
      );
    } else {
      this.messageService.add({
        type: 'danger',
        title: 'Error',
        message: 'Cannot create conditions, please check your input and try again.',
      });
    }
  }

  deleteParamCondition(conditionId: number | null) {
    if (conditionId) {
      this.subscription.add(
        this.statementParameterConditionEffect
          .delete(conditionId)
          .pipe(
            switchMap(() => {
              this.messageService.add({
                type: 'success',
                title: 'Success',
                message: 'Delete statement parameter condition',
              });

              return this.statementParameterConditionGroupEffect.find(this.statementId);
            }),
          )
          .subscribe((data) => {
            this.updateFormGroup(data);
          }),
      );
    } else {
      this.messageService.add({
        type: 'danger',
        title: 'Error',
        message: 'Cannot delete condition, no condition selected.',
      });
    }
  }

  addParamCondition() {
    if (this.data) {
      this.conditionForm.controls.conditions.controls
        .filter((condition) => !condition.controls.id.value)
        .map((condition) => {
          const statement_parameter_condition: Partial<StatementParameterCondition> = {
            parameter_id: condition.controls.selectedParameter.value?.id ?? undefined,
            value: condition.controls.value.value || undefined,
            operator: condition.controls.operator.value || undefined,
            disabled: condition.controls.disabled.value,
          };
          return statement_parameter_condition;
        })
        .forEach((statement_parameter_condition) => {
          if (this.data && this.data.id) {
            this.subscription.add(
              this.statementParameterConditionEffect
                .create(this.data.id, statement_parameter_condition)
                .pipe(
                  switchMap(() => {
                    this.messageService.add({
                      type: 'success',
                      title: 'Success',
                      message: 'Added statement parameter condition',
                    });

                    this.hasNewCondition = false;
                    return this.statementParameterConditionGroupEffect.find(this.statementId);
                  }),
                )
                .subscribe((data) => {
                  this.updateFormGroup(data);
                }),
            );
          }
        });
    } else {
      this.messageService.add({
        type: 'danger',
        title: 'Error',
        message: 'Cannot delete condition, no condition selected.',
      });
    }
  }

  updateParamCondition(conditionId: number | null) {
    const condition = this.conditionForm.controls.conditions.controls.find((c) => c.controls.id.value === conditionId);

    if (conditionId && condition && condition.valid) {
      const { parameter_id, disabled, value, operator } = condition.controls;
      const statement_parameter_condition: Partial<StatementParameterCondition> = {
        parameter_id: parameter_id.value ?? undefined,
        value: value.value || undefined,
        operator: operator.value || undefined,
        disabled: disabled.value,
      };

      this.subscription.add(
        this.statementParameterConditionEffect
          .update(conditionId, statement_parameter_condition as StatementParameterCondition)
          .pipe(
            switchMap(() => {
              this.messageService.add({
                type: 'success',
                title: 'Success',
                message: 'Update statement parameter condition',
              });

              return this.statementParameterConditionGroupEffect.find(this.statementId);
            }),
          )
          .subscribe((data) => {
            this.updateFormGroup(data);
          }),
      );
    } else {
      this.messageService.add({
        type: 'danger',
        title: 'Error',
        message: 'Cannot update condition, please check your input and try again.',
      });
    }
  }

  updateParamConditionGroup() {
    if (this.data && this.data.id) {
      const { action, hide_if_invalid } = this.conditionForm.controls;
      const statement_parameter_condition_group: Partial<StatementParameterConditionGroup> = {
        action: action.value || undefined,
        hide_if_invalid: hide_if_invalid.value,
      };

      this.subscription.add(
        this.statementParameterConditionGroupEffect
          .update(this.data.id, statement_parameter_condition_group)
          .pipe(
            switchMap(() => {
              this.messageService.add({
                type: 'success',
                title: 'Success',
                message: 'Update statement parameter condition group',
              });

              return this.statementParameterConditionGroupEffect.find(this.statementId);
            }),
          )
          .subscribe((data) => {
            this.updateFormGroup(data);
            this.conditionForm.controls.action.markAsPristine();
            this.conditionForm.controls.hide_if_invalid.markAsPristine();
          }),
      );
    } else {
      this.messageService.add({
        type: 'danger',
        title: 'Error',
        message: 'Cannot update condition, please check your input and try again.',
      });
    }
  }

  static open({
    modalService,
    statementId,
    templateId,
    region,
    proposed,
  }: {
    modalService: NgbModal;
    statementId: number;
    templateId: number;
    region: RR.Region | undefined;
    proposed: RR.ProposedStatement['proposed'] | undefined;
  }) {
    const modalRef = modalService.open(StatementConditionsModalComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'statement-condition-modal',
    });
    const componentInstance: StatementConditionsModalComponent = modalRef.componentInstance;
    componentInstance.statementId = statementId;
    componentInstance.templateId = templateId;
    componentInstance.region = region;
    componentInstance.proposed = proposed;
    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
