import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-todo-tooltip',
  standalone: true,
  imports: [CommonModule, NgbTooltipModule],
  templateUrl: './todo-tooltip.component.html',
  styleUrls: ['./todo-tooltip.component.css'],
})
export class TodoTooltipComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() @BindObservable() todos: RR.Todo[];
  todos$: Observable<RR.Todo[]>;
  statementTooltipTodos: RR.Todo[];
  unresolvedTodos: RR.Todo[];
  @ViewChild('todoTooltip') todoTooltip: NgbTooltip;
  @Output() onToggleFlagged = new EventEmitter<void>();

  subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.todos$.subscribe((todos) => {
        this.statementTooltipTodos = todos.filter(
          (todo) => !todo.resolved && (todo.todo?.includes('Statement Tooltip') ?? false),
        );
        this.unresolvedTodos = todos.filter((todo) => !todo.resolved);
      }),
    );
  }

  ngAfterViewInit(): void {
    if (this.statementTooltipTodos.length > 0) {
      this.todoTooltip.open();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
