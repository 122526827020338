<div class="bg-light p-2 rounded">
  <div class="row">
    <div class="col col-sm-7">
      <h3>Search for an existing referrer or add a new one</h3>
      <form [formGroup]="searchForm">
        <div class="form-group mb-2 input-group no-shrink">
          <div class="input-group-text">
            <span class="fa fa-search"></span>
          </div>
          <input
            class="form-control col-sm-9"
            type="text"
            [formControl]="searchText"
            placeholder="Search by referrer name or provider number"
            [ngbTypeahead]="suggestReferrers"
            [resultTemplate]="rt"
            (focus)="focus$.next(searchText.value)"
            (selectItem)="selectReferrer($event)"
          />
        </div>
      </form>
    </div>

    <ng-template #rt let-r="result" let-t="term">
      <div *ngIf="r.physician_given_name || r.physician_family_name">
        <ngb-highlight
          class="me-1"
          [result]="r.physician_given_name + ' ' + r.physician_family_name"
          [term]="t"
        ></ngb-highlight>
      </div>
      <div *ngIf="r.service">
        <span>Service: </span>
        <ngb-highlight class="me-1" [result]="r.service" [term]="t"></ngb-highlight>
      </div>
      <div *ngIf="r.medicare_provider_number">
        <span>Provider No: </span>
        <ngb-highlight [result]="r.medicare_provider_number" [term]="t"></ngb-highlight>
      </div>
      <div *ngIf="r.phone_work || r.phone_mobile || r.fax || r.email">
        <span>Phone/Email: </span>
        <ngb-highlight
          [result]="r.phone_work || r.phone_mobile || r.fax || r.email || 'N/A'"
          [term]="t"
        ></ngb-highlight>
      </div>
      <div *ngIf="r.city">
        <span>Suburb: </span>
        <ngb-highlight [result]="r.city ?? '' + r.zip ?? ''" [term]="t"></ngb-highlight>
      </div>
    </ng-template>

    <div class="col-3 d-flex flex-column ms-auto">
      <button
        class="btn btn-outline-primary btn-sm"
        (click)="createNewReferrer()"
        rrTooltip="REGISTRATION_CREATE_NEW_REFERRER"
      >
        + Create New Referrer
      </button>
      <button
        *ngIf="removedReferrer"
        class="btn btn-primary btn-sm mt-2"
        (click)="undoRemoval()"
        rrTooltip="REGISTRATION_UNDO_REMOVAL"
      >
        Undo Removal
      </button>
    </div>
  </div>
</div>
