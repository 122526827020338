<div class="modal-header cursor-move" cdkDrag cdkDragHandle cdkDragRootElement=".modal-content">
  <div>
    <h5 class="modal-title">Image Similarity Parameters (^)</h5>
    <div>
      <rr-report-headline [reportId]="report.id"></rr-report-headline>
    </div>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div class="alert alert-info">
    <i class="text-primary fas fa-question-circle"></i>
    Click a parameterised statement to insert into report
  </div>
  <div class="alert alert-info mt-1">
    <i class="text-primary fas fa-question-circle"></i>
    Add another template to the report to access parameters from a different algorithm
  </div>

  <ng-container *ngIf="imgSimJobStatuses.length > 0">
    <h5 class="mb-1">Job Statuses</h5>
    <ng-container *ngFor="let jobStatus of imgSimJobStatuses">
      <div class="d-flex me-2 mb-2">
        <div class="p-1 bg-light border rounded">
          <span class="fw-semibold">
            {{ (jobStatus.template_id | storeSelectPipe: selectTemplate | async)?.name }}
          </span>
          <span>: {{ jobStatus.status }}</span>
          <div class="">Created: {{ jobStatus.created_at | date: 'short' }}</div>
        </div>
      </div>
    </ng-container>
    <div class="d-flex align-items-center my-2">
      <div *ngIf="isAddingTargetSeriesNumber" class="me-3 w-50">
        <input
          type="number"
          class="form-control mt-2"
          placeholder="Enter the target series number (optional)"
          [formControl]="form.controls.seriesNumber"
        />

        <div class="d-flex gap-1 mt-2">
          <button class="btn btn-sm btn-success" (click)="rerunTemplate()">Submit</button>
          <button class="btn btn-sm btn-secondary" (click)="toggleAddSeriesNumber()">Cancel</button>
        </div>
      </div>
      <button
        *ngIf="
          !isAddingTargetSeriesNumber &&
          (topic.template_id === 12 || topic.template_id === 18 || topic.template_id === 36)
        "
        class="btn btn-sm btn-warning py-1 me-1"
        (click)="toggleAddSeriesNumber()"
      >
        <i class="fa fa-redo"></i>
        Rerun
      </button>
      <button
        (click)="rerunSegmentation()"
        *ngIf="
          topic.template_id === 12 || topic.template_id === 14 || topic.template_id === 18 || topic.template_id === 36
        "
        class="btn btn-sm btn-warning py-1"
      >
        <i class="fa fa-redo"></i>
        Rerun Segmentation
      </button>
    </div>
  </ng-container>

  <div class="mb-2">
    <h5 class="mb-1">Image Type</h5>
    <div class="d-flex">
      <button
        class="btn btn-sm me-2 py-1"
        [class.btn-primary]="form.controls.activeIndex.value === i"
        [class.btn-outline-primary]="form.controls.activeIndex.value !== i"
        (click)="form.controls.activeIndex.setValue(i)"
        *ngFor="let type of templateTypes; let i = index"
      >
        {{ type }}
      </button>
    </div>
  </div>

  <div class="d-flex align-items-center mb-2" *ngIf="!loading">
    <div class="me-2">
      <button (click)="chooseAllProposed()" class="btn btn-outline-dark">Choose all parameterised statements</button>
    </div>
    <!-- <div class="me-2">
        <button (click)="removeAllProposed()" class="btn btn-outline-dark">Remove all parameterised statements</button>
      </div> -->
    <div class="form-check me-4">
      <input
        class="form-check-input"
        type="checkbox"
        [formControl]="form.controls.hideIncompleteSentences"
        id="hide-incomplete"
      />
      <label class="form-check-label" for="hide-incomplete">Hide invalid statements</label>
    </div>
  </div>

  <ng-container *ngIf="segmentedImagesAvailable">
    <!-- <h4>Segmented Images</h4>
      <div class="segmented-images-div mb-3">
        <div *ngFor="let image of segmentedImages" class="image-container">
          <img [src]="image.image_url" loading="lazy" alt="Segmented Image" />
          <div class="overlay">
            <p>{{ image.image_title }}</p>
            <div *ngFor="let param of image.parameters">
              <span>{{ param.title }}: {{ param.value }}</span>
            </div>
            <a href="{{ image.image_url }}" target="_blank">View Image</a>
          </div>
        </div>
      </div> -->
    <div *ngFor="let segmentedMessageButton of segmentedMessageButtons" class="mb-2">
      <a href="#" (click)="openSlider(segmentedMessageButton.url)" target="_blank" rel="noopener noreferrer">
        <button class="btn btn-outline-primary my-1 btn-warning flash">{{ segmentedMessageButton.label }}</button>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && activeResponse">
    <ng-container *ngFor="let group of activeResponse.datasets">
      <div class="list-group d-flex flex-grow-1 flex-column border rounded p-2">
        <ng-container *ngFor="let proposed of group.proposed_statements">
          <div *ngIf="proposed.statement">
            <i class="fas fa-exclamation-triangle" *ngIf="proposed.warning"></i>
            <rr-recommended-statement
              [statement]="proposed.statement"
              [topic]="topic"
              [proposed]="proposed"
              [context]="'IMGSIM'"
              [truncate]="false"
            ></rr-recommended-statement>
          </div>
        </ng-container>
      </div>
      <hr />
    </ng-container>

    <ng-container *ngIf="activeResponse.images as images">
      <div class="mb-2">
        <h4>Images</h4>
        <ul>
          <li *ngFor="let img of images; let i = index">
            <div class="d-flex flex-column">
              <a href="{{ img }}" target="_blank">Image {{ i + 1 }}</a>
              <img [src]="img" alt="Image {{ i + 1 }}" style="width: 600px; height: auto" />
            </div>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="parameters$ | async as parameters">
      <div class="d-flex mb-2 align-items-center">
        <h4 class="mb-0">Parameters</h4>
        <button class="btn btn-outline-primary ms-auto" (click)="toggleTable()">
          {{ tableOpen ? 'Hide Table' : 'Show Table' }}
        </button>
      </div>
      <table *ngIf="tableOpen" class="table table-striped">
        <thead>
          <tr>
            <!-- Add "table-dark" class to style the table header with a darker background -->
            <th class="bg-primary text-white">Name</th>
            <th class="bg-primary text-white">Value</th>
            <th class="bg-primary text-white">Unit</th>
            <th class="bg-primary text-white">Percentile Range</th>
          </tr>
        </thead>
        <tbody>
          <!-- loop parameters -->
          <ng-container *ngFor="let param of parameters">
            <tr *ngIf="param.unit !== 'percentile'">
              <td>
                <ng-container *ngIf="param.calculation_method === 'DNN'">
                  <i class="fas fa-exclamation-circle" ngbTooltip="Calculated with deep neural network (DNN)"></i>
                </ng-container>
                <ng-container *ngIf="param.calculation_method === 'HU'">
                  <i class="fas fa-exclamation-circle" ngbTooltip="Calculated with Hounsfield Units (HU)"></i>
                </ng-container>
                <div class="d-flex gap-3" *ngIf="!isEditingParameterName || currentParameterId !== param.id">
                  <span [ngClass]="{ 'not-optimal-parameter': param.calculation_method === 'HU' }">
                    {{ param.name }}
                  </span>
                  <button (click)="editParameterName(param.id, param.name)" class="btn btn-sm btn-secondary">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div class="d-flex" *ngIf="isEditingParameterName && currentParameterId === param.id">
                  <input
                    #parameterNameInput
                    class="form-control form-control-sm"
                    [formControl]="form.controls.parameterName"
                  />
                  <div class="ms-1 d-flex gap-1">
                    <button (click)="updateParameterName(param.id)" class="btn btn-sm btn-success">
                      <i class="fas fa-check"></i>
                    </button>
                    <button (click)="cancelEditParameterName()" class="btn btn-sm btn-secondary ms-1">
                      <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </td>
              <td>
                {{ param.value | number: '1.0-3' }}
              </td>
              <td *ngIf="param.unit">
                <ng-container *ngIf="param.unit.includes('^')">
                  <ng-container
                    >{{ param.unit.split('^')[0] }}<sup>{{ param.unit.split('^')[1] }}</sup>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!param.unit.includes('^')">
                  {{ param.unit }}
                </ng-container>
              </td>
              <td>
                {{ param.lower_percentile != null ? param.lower_percentile + ' - ' + param.upper_percentile : 'N/A' }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!loading && !activeResponse">
    <strong>No parameters, statements, or images available.</strong>
  </ng-container>
</div>
