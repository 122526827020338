import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PathologyHttpService {
  constructor(private http: HttpClient) {}

  create(pathology: Partial<RR.Pathology>): Observable<RR.Pathology> {
    return this.http.post<RR.Pathology>('/api/pathology', pathology);
  }

  findAll(): Observable<RR.Pathology[]> {
    return this.http.get<RR.Pathology[]>('/api/pathology');
  }

  delete(pathologyId: number): Observable<RR.Pathology> {
    return this.http.delete<RR.Pathology>(`/api/pathology/${pathologyId}`);
  }

  search(search: string): Observable<RR.Pathology[]> {
    return this.http.get<RR.Pathology[]>(`/api/pathology/search?q=${search}`);
  }
}
