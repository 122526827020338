import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { filter, Subscription } from 'rxjs';

import { BookingDocumentAttachmentModalComponent } from '../../modals/booking-document-attachment-modal/booking-document-attachment-modal.component';

@Component({
  standalone: true,
  // SharedModule imported for TooltipDirective
  // TODO: rrTooltip standalone component
  imports: [CommonModule, NgbModule, SharedModule],
  selector: 'rr-document-attachment-button',
  templateUrl: './document-attachment-button.component.html',
  styleUrls: ['./document-attachment-button.component.css'],
})
export class DocumentAttachmentButtonComponent implements OnInit, OnDestroy {
  @Input() bookingId: number;
  @Input() size: 'sm' | 'lg' = 'lg';
  // Button class name when there is no document attached. When there are documents, the className is default to btn-success
  @Input() btnClassName: string;
  @Input() btnSuccessClass: string;
  @Input() dismissActiveModal = false;
  @Input() showText = true;
  @Input() expand = false;
  @Input() sidebar = false;

  hasDocument = false;
  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromBooking.selectBooking(this.bookingId))
        .pipe(
          filter((booking) => !!booking),
          filterDefined(),
        )
        .subscribe((booking) => {
          this.hasDocument = booking.documents.length > 0;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openDocumentAttachmentModal() {
    if (this.dismissActiveModal) {
      this.modalService.dismissAll();
    }
    BookingDocumentAttachmentModalComponent.open(this.modalService, this.bookingId);
  }
}
