import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { BillingItemActions } from '../billing-item/billing-item.action';
import { ScanCodeActions } from '../scan-code/scan-code.action';

const findAllSuccess = createAction('[BillingGroup] Find All Success', props<{ billingGroups: RR.BillingGroup[] }>());
const findManySuccess = createAction('[BillingGroup] Find Many Success', props<{ billingGroups: RR.BillingGroup[] }>());
const findSuccess = createAction('[BillingGroup] Find Success', props<{ billingGroup: RR.BillingGroup }>());
const createSuccess = createAction('[BillingGroup] Create Success', props<{ billingGroup: RR.BillingGroup }>());
const updateSuccess = createAction('[BillingGroup] Update Success', props<{ billingGroup: RR.BillingGroup }>());
const deleteSuccess = createAction('[BillingGroup] Delete Success', props<{ billingGroupId: number }>());
const updateBillingGroupItemSuccessBatch = createBatchAction(
  '[BillingGroup] Update Billing Group Item Success Batch',
  props<{
    actions: {
      updateBillingGroupSuccess: ReturnType<typeof updateSuccess>;
      updateBillingItemSuccess: ReturnType<typeof BillingItemActions.updateSuccess>;
    };
  }>(),
);
const updateScanCodeBillingGroupSuccessBatch = createBatchAction(
  '[BillingGroup] Update Scan Code Billing Group Success Batch',
  props<{
    actions: {
      updateBillingGroupSuccess: ReturnType<typeof updateSuccess>;
      updateScanCodeSuccess: ReturnType<typeof ScanCodeActions.updateSuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for BillingGroup
 *
 */
export const BillingGroupActions = {
  findAllSuccess,
  findManySuccess,
  findSuccess,
  createSuccess,
  updateSuccess,
  deleteSuccess,
  updateBillingGroupItemSuccessBatch,
  updateScanCodeBillingGroupSuccessBatch,
};
