import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { CategoryEffect } from 'app/store/category/category.effect';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import { CategoryEditModalComponent } from './category-edit-modal/category-edit-modal.component';

@Component({
  selector: 'rr-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule],
})
export class CategoryComponent implements OnDestroy {
  @Input() category: RR.Category;
  hover = false;

  subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private categoryEffect: CategoryEffect,
    private messageService: MessageService,
  ) {}

  removeCategory() {
    this.subscription.add(
      this.categoryEffect
        .delete(this.category.id)
        .pipe(take(1))
        .subscribe({
          error: () => {
            this.messageService.add({
              title: 'Error',
              message: 'Remove category failed. This category might already be used to classify some statements',
              type: 'danger',
            });
          },
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  editCategory() {
    CategoryEditModalComponent.open(this.modalService, this.category, this.category.statement_set_id);
  }
}
