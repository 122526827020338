import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SoftwarePreferenceHttpService {
  constructor(private http: HttpClient) {}

  create(softwarePref: Partial<RR.SoftwarePreference>): Observable<RR.SoftwarePreference> {
    return this.http.post<RR.SoftwarePreference>('/api/software_preferences', softwarePref);
  }

  getAll(): Observable<RR.SoftwarePreference[]> {
    return this.http.get<RR.SoftwarePreference[]>('/api/software_preferences');
  }

  update(softwarePrefId: number, changes: Partial<RR.SoftwarePreference>): Observable<RR.SoftwarePreference> {
    return this.http.put<RR.SoftwarePreference>(`/api/software_preferences/${softwarePrefId}`, changes);
  }

  delete(softwarePrefId: number): Observable<RR.SoftwarePreference> {
    return this.http.delete<RR.SoftwarePreference>(`/api/software_preferences/${softwarePrefId}`);
  }
}
