import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type BookingEnquiry = {
  report_id: number;
  accession_number: string;
  id: number;
  modality: string;
  site: string;
  first_name: string;
  last_name: string;
  email: string | undefined;
  phone: string | undefined;
  start_time: string;
  end_time: string;
  notes: string | undefined;
  filename: string | undefined;
  original_filename: string | undefined;
  status: 'PENDING' | 'RESOLVED' | 'CANCELLED';
  staff_note: string | undefined;
  in_progress: boolean;
  in_progress_user_id: number | undefined;
  in_progress_at: string | undefined;
  created_at: string;
  // sms
  sms_sent: boolean | undefined;
  sms_sent_at: string | undefined;
  sms_sent_user_id: number | undefined;
};

export type BookingEnquirySearchBody = {
  offset: string | undefined;
  limit: string | undefined;
  processed: boolean | undefined;
  mobile: string | undefined;
  email: string | undefined;
  name: string | undefined;
};

export interface BookingEnquiryResponse {
  count: number;
  booking_enquiries: BookingEnquiry[];
}

@Injectable()
export class BookingEnquiryHttpService {
  constructor(private http: HttpClient) {}

  getPendingReferralEnquiryCount() {
    return this.http.get<{ count: number }>('/api/referral_enquiry/count');
  }

  getPendingBookingEnquiryCount() {
    return this.http.get<{ count: number }>('/api/enquiry/pending_count');
  }

  getPendingBookingEnquiries(searchBody: BookingEnquirySearchBody) {
    return this.http.post<BookingEnquiryResponse>('/api/enquiry/search', searchBody);
  }

  updateBookingEnquiry(id: number, changes: Partial<BookingEnquiry>) {
    return this.http.put<BookingEnquiry>(`/api/enquiry/${id}/update`, changes);
  }

  getBookingEnquiry(id: number) {
    return this.http.get<BookingEnquiry>(`/api/enquiry/${id}`);
  }
}
