import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { TitleSetActions } from './title-set.action';

export type TitleSetState = EntityState<RR.TitleSet>;

const adapter = createEntityAdapter<RR.TitleSet>();

const initialState = adapter.getInitialState();

export const titleSetReducer = createReducer(
  initialState,
  on(TitleSetActions.findInTemplateSuccess, (state: TitleSetState, { titleSets }) =>
    adapter.upsertMany(titleSets, state),
  ),
  on(TitleSetActions.findSuccess, (state: TitleSetState, { titleSet }) => adapter.upsertOne(titleSet, state)),
  on(TitleSetActions.updateSuccess, (state: TitleSetState, { titleSet }) => adapter.upsertOne(titleSet, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
