import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { DicomMeasurementsResponse, ProposedDataset } from './dicom.service';

/** just for setting the measurementsLoading state */
const loadMeasurements = createAction('[Dicom] Load Measurements', props<{ report_id: number }>());
const loadMeasurementsSuccess = createAction(
  '[Dicom] Load Measurements Success',
  props<{ response: DicomMeasurementsResponse }>(),
);
const loadMeasurementsFailed = createAction('[Dicom] Load Measurements Failed');

const queryDicomFailed = createAction('[Dicom] Query Failed');

const findProposedSuccess = createBatchAction(
  '[Topic] Find Proposed Success Batch',
  props<{
    datasets: ProposedDataset[];
    actions: {
      findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
    };
  }>(),
);

export const DicomActions = {
  loadMeasurements,
  loadMeasurementsSuccess,
  loadMeasurementsFailed,
  queryDicomFailed,
  findProposedSuccess,
};
