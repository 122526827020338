import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { StatementParameterCondition } from '../statement-parameter-condition/statement-parameter-condition.service';

export interface StatementParameterConditionGroup {
  id: number | null;
  statement_id: number;
  action: string;
  statement_parameter_conditions: StatementParameterCondition[];
  hide_if_invalid: boolean;
}

@Injectable()
export class StatementParameterConditionGroupHttpService {
  constructor(private http: HttpClient) {}

  create(statement_parameter_condition_group: StatementParameterConditionGroup) {
    return this.http.post<StatementParameterConditionGroup | undefined>(
      `/api/statement_parameter_condition_group`,
      statement_parameter_condition_group,
    );
  }

  update(id: number, statement_parameter_condition_group: Partial<StatementParameterConditionGroup>) {
    return this.http.put<StatementParameterConditionGroup | undefined>(
      `/api/statement_parameter_condition_group/${id}`,
      statement_parameter_condition_group,
    );
  }

  find(statementId: number) {
    return this.http.get<StatementParameterConditionGroup | undefined>(
      `/api/statement_parameter_condition_group/${statementId}`,
    );
  }
}
