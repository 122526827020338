<div class="col-9">
  <div class="row">
    <div class="col-6">
      <strong>Patient:</strong>
      <p>{{ bookingPatient.first_name }} {{ bookingPatient.last_name }}</p>
    </div>
    <div class="col-3">
      <strong>Sex:</strong>
      <p class="text-capitalize">{{ bookingPatient.gender }}</p>
    </div>
    <div class="col-3" *ngIf="bookingPatient?.dob">
      <strong>DOB:</strong>
      <p>{{ bookingPatient.dob | date: 'dd/MM/yyyy' }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6" *ngIf="bookingPatient?.phone">
      <strong>Phone:</strong>
      <p>{{ bookingPatient.phone }}</p>
    </div>
    <div class="col-sm-6" *ngIf="bookingPatient?.email">
      <strong>Email:</strong>
      <p>{{ bookingPatient.email }}</p>
    </div>
  </div>
</div>
