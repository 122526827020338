<div class="modal-header" *ngIf="report">
  <h5 class="modal-title" *ngIf="report.type === 'default'">Add Preset (A)</h5>
  <h5 class="modal-title" *ngIf="report.type === 'preset'">Update Preset (A)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <rr-similar-preset-warning
    *ngIf="similarPresetTitles && similarPresetTitles.reports.length > 0"
    [presets]="similarPresetTitles"
  ></rr-similar-preset-warning>

  <!-- Adding !loadingPresetTitle will not show the dropdowns when creating a preset -->
  <form [formGroup]="presetForm" *ngIf="topic && report">
    <div class="form-group mb-2">
      <div class="d-flex flex-column gap-3">
        <!--Preview each selection (create) -->
        <label *ngIf="report.type === 'default'"
          >Preset Title: <strong *ngIf="bodyPart">{{ bodyPart.name }} - </strong>
          <strong *ngIf="pathology">{{ pathology.name }}</strong></label
        >
        <!--Preview each selection (update) -->
        <div *ngIf="report.type === 'preset'">
          New preset Title: <strong *ngIf="bodyPart">{{ bodyPart.name }} </strong>
          <strong *ngIf="pathology">{{ pathology.name }}</strong>
        </div>

        <div class="d-flex gap-2">
          <rr-body-part [selectedBodyPartId]="bodyPart?.id" (bodyPart)="onBodyPart($event)"></rr-body-part>
          <rr-pathology [selectedPathologyId]="pathology?.id" (pathology)="onPathology($event)"></rr-pathology>
        </div>

        <textarea
          class="form-control"
          placeholder="Preset notes (Please use complete sentences to describe your notes)"
          [formControl]="presetForm.controls.presetNote"
          rrAutoSize
          ngbAutoFocus
        ></textarea>
      </div>
    </div>

    <button
      permission
      [permission-only]="['preset_manage']"
      *ngIf="report.type === 'default'"
      type="submit"
      rrTooltip="SAVE_FAVOURITE"
      class="btn btn-success"
      role="button"
      [disabled]="!bodyPart || !pathology"
      (click)="createPreset(topic)"
    >
      <span class="fa fa-save"></span> Create Preset
    </button>

    <div class="d-flex gap-1" *ngIf="report.type === 'preset'">
      <button
        permission
        [permission-only]="['preset_manage']"
        type="submit"
        rrTooltip="UPDATE_PRESET"
        class="btn btn-success"
        (click)="updatePreset(report)"
      >
        <span class="fa fa-save"></span> Update Preset
      </button>
      <button
        permission
        [permission-only]="['preset_manage']"
        type="submit"
        class="btn btn-danger"
        rrTooltip="DELETE_PRESET"
        (click)="deletePreset(report)"
      >
        <span class="fa fa-trash"></span> Delete preset
      </button>
    </div>
  </form>

  <hr />
  <div permission [permission-only]="['view_old_favourites']">
    <!-- TODO(preset): remove old favourite code below this line -->
    <h2>Old Favourites:</h2>
    <rr-ref-topics [topic_id]="topic_id" (onSelectFavourite)="onSelectRefFavourite($event)"></rr-ref-topics>

    <h5 class="modal-title" *ngIf="!favourite">Add Favourite (A)</h5>
    <h5 class="modal-title" *ngIf="favourite">Update Favourite (A)</h5>

    <form [formGroup]="tagForm" class="mt-2" *ngIf="!toReplaceFavourite">
      <label class="form-label">Old Favourite Name</label>
      <tag-input
        secondaryPlaceholder="Enter a favourite name"
        [allowDupes]="true"
        #tagInput
        [editable]="true"
        formControlName="tagsInput"
        [addOnBlur]="true"
        [dragZone]="'zone1'"
        [onAdding]="onTagAdding"
        (onTagEdited)="onTagEdited($event)"
      ></tag-input>
    </form>

    <div *ngIf="toReplaceFavourite">
      Replace Favourite
      <rr-favourite-headline [favourite]="toReplaceFavourite"></rr-favourite-headline>
      with this Report
    </div>
    <div class="d-flex mt-1">
      <button class="btn btn-warning" (click)="clearFavouriteName()">Clear all groups</button>

      <div class="flex-grow-1"></div>

      <button
        rrTooltip="CANCEL_FAVOURITE"
        class="btn btn-danger"
        *ngIf="favourite && !toReplaceFavourite"
        role="button"
        (click)="deleteFavouriteLegacy()"
      >
        <span class="fa fa-trash"></span> Delete Favourite
      </button>

      <button
        *ngIf="!toReplaceFavourite && !favourite"
        rrTooltip="SAVE_FAVOURITE"
        class="btn btn-success pe-auto"
        role="button"
        ngbAutoFocus
        (click)="createLegacy()"
        disabled
        ngbTooltip="Favourite creation disabled, please transition to the new favourite system (Presets)"
      >
        <span class="fa fa-save"></span> Create Favourite
      </button>
      <button
        *ngIf="!toReplaceFavourite && favourite"
        rrTooltip="SAVE_FAVOURITE"
        class="btn btn-success"
        role="button"
        ngbAutoFocus
        (click)="updateLegacy(favourite)"
      >
        <span class="fa fa-save"></span> Update Favourite
      </button>

      <button
        *ngIf="toReplaceFavourite"
        rrTooltip="REPLACE_FAVOURITE"
        class="btn btn-success disabled pe-auto"
        role="button"
        ngbAutoFocus
        (click)="replaceFavouriteLegacy()"
        ngbTooltip="Not working"
      >
        <span class="fa fa-save"></span> Replace Favourite
      </button>
    </div>
  </div>
</div>
