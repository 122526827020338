import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { ReferrerNoteSearchFilterForm } from 'app/modules/entity-listing/components/referrer-note-listing/referrer-note-listing-search/referrer-note-listing-search.component';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReferrerContactMethodActions } from '../referrer-contact-method/referrer-contact-method.action';
import { ReferrerNoteCategoryActions } from '../referrer-note-category/referrer-note-category.action';
import { ReferrerActions } from '../referrer/referrer.action';
import { ReferrerSearchResponse } from '../referrer/referrer.service';
import { ReferrerNoteActions, ReferrerNoteBatchActions } from './referrer-note.action';
import { CreateReferrerNoteResponse, ReferrerNoteHttpService } from './referrer-note.service';

@Injectable()
export class ReferrerNoteEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReferrerNoteHttpService,
    private message: MessageService,
  ) {}

  create(referrerNote: Partial<RR.ReferrerNote>) {
    return this.service.create(referrerNote).pipe(
      this.message.handleHttpErrorPipe,
      map((data: CreateReferrerNoteResponse) =>
        ReferrerNoteBatchActions.createSuccess({
          actions: {
            createReferrerNoteSuccess: ReferrerNoteActions.createSuccess({
              referrer_note: data.referrer_note,
            }),
            updateReferrerSuccess: ReferrerActions.updateSuccess({ referrer: data.referrer }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(referrerNoteId: number) {
    return this.service.delete(referrerNoteId).pipe(
      this.message.handleHttpErrorPipe,
      map((data) =>
        ReferrerNoteBatchActions.deleteSuccess({
          actions: {
            deleteReferrerNoteSuccess: ReferrerNoteActions.deleteSuccess({
              referrer_note_id: referrerNoteId,
            }),
            updateReferrerSuccess: ReferrerActions.updateSuccess({ referrer: data.referrer }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(referrerNoteId: number, changes: Partial<RR.ReferrerNote>) {
    return this.service.update(referrerNoteId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((referrer_note) => ReferrerNoteActions.updateSuccess({ referrer_note })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: ReferrerNoteSearchFilterForm, params: HttpParams) {
    return this.service.searchReferrerNotes(queryBody, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response: ReferrerSearchResponse) =>
        ReferrerNoteBatchActions.searchSuccess({
          actions: {
            searchReferrerNoteSuccess: ReferrerNoteActions.searchSuccess({
              searchResult: response,
            }),
            findManyReferrerSuccess: ReferrerActions.findManySuccess({ referrers: response.referrers }),
            findManyReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
              referrer_contact_methods: response.referrer_contact_methods,
            }),
            findManyReferrerNoteCategorySuccess: ReferrerNoteCategoryActions.findManySuccess({
              referrer_note_categories: response.referrer_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
