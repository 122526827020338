import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { PresetSearchResponse } from 'app/store/report/preset';
import { fromPresetTitle } from 'app/store/report/preset-title';
import { Observable, Subscription } from 'rxjs';

import { PrefillService } from '../../prefill.service';
import { PrefillSelectTopicType } from '../../prefill.types';
import { PrefillRowPresetComponent } from '../prefill-row-preset/prefill-row-preset.component';

@Component({
  selector: 'rr-prefill-preset',
  standalone: true,
  imports: [SharedModule, PrefillRowPresetComponent],
  templateUrl: './prefill-preset.component.html',
  styleUrl: './prefill-preset.component.css',
})
export class PrefillPresetComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() preset: PresetSearchResponse;
  preset$: Observable<PresetSearchResponse>;
  @Input() openTopicId: number;
  selectedType: PrefillSelectTopicType;

  subscription = new Subscription();

  constructor(private prefillService: PrefillService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.prefillService.selectPrefillTopics$.subscribe(({ type }) => {
        this.selectedType = type;
      }),
    );
  }

  selectPresetTitle = fromPresetTitle.selectPresetTitle;

  selectTopics() {
    this.prefillService.selectPrefillTopics$.next({ type: 'TOP6_PRESETS' });
    // Select up to 6 topic ids
    const topicIds = [...new Set(this.preset.reports.flatMap((response) => response.topic_ids.toString()))].slice(0, 6);
    this.prefillService.setPrefillPreviewTopics({ openTopicId: this.openTopicId, topicIds });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
