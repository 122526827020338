<div class="modal-header">
  <h5 class="modal-title">{{ institutionId ? 'Edit institution' : 'Create new institution' }}</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <rr-institution-form-edit [institution]="institution" (onChange)="closeModal($event)"></rr-institution-form-edit>
</div>

<div class="modal-footer">
  <button class="btn btn-success" role="button" (click)="submit()">
    {{ institutionId ? 'Update institution' : 'Create institution' }}
  </button>
  <button class="btn btn-light ms-2" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
