<ng-container>
  <div [class.d-none]="editDate">
    <button
      permission
      [permission-only]="['report_approve']"
      class="btn btn-sm btn-info"
      type="button"
      (click)="openDateTimeEditor()"
      rrTooltip="EDIT_REGISTRATION_DATE"
    >
      <span><i class="fas fa-stopwatch"></i> Registration Date</span>
    </button>
    <div [ngbTooltip]="tipContent" #t="ngbTooltip" triggers="mouseenter:mouseleave" container="body">
      <span class="fw-semibold">
        {{ report.created | date: 'MMM d, y, H:mm' }}
      </span>
    </div>
  </div>
  <ng-template #tipContent>
    <small>
      <table>
        <tr>
          <td>Voyager Study Date</td>
          <td>{{ report.study_date | date: 'MMM d, y, H:mm' }}</td>
        </tr>
        <tr>
          <td>Created</td>
          <td>{{ report.created | date: 'MMM d, y, H:mm' }}</td>
        </tr>
        <tr>
          <td>Modified</td>
          <td>{{ report.text_modified | date: 'MMM d, y, H:mm' }}</td>
        </tr>
      </table>
    </small>
  </ng-template>

  <div class="input-group input-group-sm" [class.d-none]="!editDate">
    <input
      class="form-control"
      type="datetime-local"
      max="2100-08-19T00:00"
      rrAutoFocus
      [formControl]="registrationDate"
      (keydown.enter)="$event.preventDefault(); confirmAndSubmit()"
    />

    <button
      class="btn btn-sm btn-danger px-1 py-0"
      rrTooltip="CANCEL_EDIT_DATE"
      type="button"
      (click)="cancelDateTimeEditor()"
    >
      <i class="fas fa-ban fa-sm"></i>
    </button>
    <button class="btn btn-sm btn-success px-1 py-0" rrTooltip="SAVE_DATE" type="button" (click)="confirmAndSubmit()">
      <i class="fas fa-save fa-sm"></i>
    </button>
  </div>
</ng-container>
