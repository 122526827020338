<ng-container *ngIf="topic$ | async as topic">
  <ng-container *ngIf="columnType === 'STUDY_TYPE'">
    <ng-container *ngIf="showFinalPreviewBtn">
      <button
        data-no-bubble
        class="btn btn-sm btn-info"
        *ngIf="topic.id"
        (click)="clipboardView(topic)"
        rrTooltip="CLIPBOARD_VIEW"
      >
        View and Exp (X)
      </button>
    </ng-container>

    <ng-container *ngIf="showSearchByStudyType; else templateName">
      <div
        class="my-0"
        *ngIf="template$ | async as template"
        [title]="topic.title_option_text ? topic.title_option_text : 'NO TITLE'"
        [class.obstetric]="isTopicObstetric()"
      >
        <div
          class="link-dark text-decoration-none"
          (click)="searchByStudyOption.emit(template)"
          rrTooltip="FILTER_WORKLIST_VIA_STUDY_TYPE"
          style="cursor: pointer"
        >
          <strong>{{ template.modality.name }}</strong> – {{ template.name }}
          <i class="ms-1 fas fa-search"></i>
        </div>
      </div>
    </ng-container>
    <ng-template #templateName>
      <div *ngIf="template$ | async as template">
        <strong>{{ template.modality.name }}</strong> – {{ template.name }}
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="columnType === 'REPORT_TITLE'">
    <ng-container *ngIf="showSearchByReportTitle; else titleOptionText">
      <div
        class="link-dark text-decoration-none"
        *ngIf="topic.title_option_id && topic.title_option_text"
        (click)="
          searchByTitleOption.emit({ titleOptionId: topic.title_option_id, titleOptionText: topic.title_option_text })
        "
        rrTooltip="FILTER_WORKLIST_VIA_TITLE"
        style="cursor: pointer"
      >
        <span>{{ topic.title_option_text }}</span>
        <i class="ms-1 fas fa-search"></i>
      </div>
    </ng-container>
    <ng-template #titleOptionText>
      <span>{{ topic.title_option_text }}</span>
    </ng-template>
  </ng-container>
</ng-container>
