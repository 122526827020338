import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { SendEventActions, SendMOPayload, SendReportPayload, SendSMSPayload } from './send-event.action';
import { SendEventHttpService } from './send-event.service';

@Injectable()
export class SendEventEffect {
  constructor(
    private store: Store<AppState>,
    private service: SendEventHttpService,
  ) {}

  sendSMS(data: SendSMSPayload) {
    return this.service.sendSMS(data).pipe(
      // this.message.handleHttpErrorPipe,
      map((sendEvents: RR.SendEvent[]) => SendEventActions.sendSuccess({ sendEvents })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  sendEmail(data: SendReportPayload) {
    return this.service.sendEmail(data).pipe(
      // this.message.handleHttpErrorPipe,
      map((sendEvents: RR.SendEvent[]) => SendEventActions.sendSuccess({ sendEvents })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  sendMedicalObject(data: SendMOPayload) {
    return this.service.sendMedicalObject(data).pipe(
      // this.message.handleHttpErrorPipe,
      map((sendEvents: RR.SendEvent[]) => SendEventActions.sendSuccess({ sendEvents })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
