import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { SharedModule } from 'app/shared/shared.module';
import { Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { SignatureModalComponent } from '../signature-modal/signature-modal.component';
import { SignatureModalRole } from '../signature-modal/signature-modal.types';

@Component({
  selector: 'rr-signature',
  templateUrl: './signature.component.html',
  standalone: true,
  imports: [CommonModule, SharedModule, AutoFocusDirective],
  styles: [
    `
      .content {
        position: relative; /* Absolute position flag button */
        display: block;
        padding-left: 1em;
        padding-right: 1em;
        display: flex;
        width: 100%;
        white-space: normal;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class SignatureComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  @Input() mode: PreviewMode = 'editor';
  @Input() signature_role: SignatureModalRole = 'DOCTOR';
  @Input() autoFocus = false;
  modality: string;
  lastAccessUser: RR.User;
  signatureText: string;
  addBtnText: string;
  subscription: Subscription = new Subscription();
  constructor(
    private modal: NgbModal,
    private reportService: ReportService,
    private templateService: TemplateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.topic$.subscribe((topic) => {
        switch (this.signature_role) {
          case 'DOCTOR':
            // @ts-expect-error strictNullChecks
            this.signatureText = topic.signature_text;
            this.addBtnText = 'Add Doctor Signature';
            break;
          case 'RADIOLOGY_REGISTRAR':
            // @ts-expect-error strictNullChecks
            this.signatureText = topic.radiology_registrar_signature_text;
            this.addBtnText = 'Add Radiology Registrar Signature';
            break;
          case 'TECHNICIAN':
            // @ts-expect-error strictNullChecks
            this.signatureText = topic.technician_signature_text;
            break;
          case 'JUNIOR_TECHNICIAN':
            // @ts-expect-error strictNullChecks
            this.signatureText = topic.junior_signature_text;
            break;
          default:
            // @ts-expect-error strictNullChecks
            this.signatureText = topic.signature_text;
            break;
        }
      }),
    );

    if (this.signature_role === 'TECHNICIAN' || this.signature_role === 'JUNIOR_TECHNICIAN') {
      this.topic$
        .pipe(switchMap((topic) => this.templateService.getTemplateModality(topic.template_id)))
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition
        .subscribe((modality) => {
          if (this.signature_role === 'JUNIOR_TECHNICIAN') {
            this.addBtnText =
              modality === 'US' ? 'Add Sonographer Assistant Signature' : 'Add Radiographer Assistant Signature';
            this.cd.markForCheck();
          } else {
            this.reportService
              .selectKioskUser()
              .pipe(
                filter((u) => !!u),
                take(1),
              )
              /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
              .subscribe((user) => {
                // @ts-expect-error strictNullChecks
                if (user.company_roles.some((companyRoleId) => companyRoleId === 'imaging_technician')) {
                  this.addBtnText = 'Add Imaging Technician Signature';
                } else {
                  this.addBtnText = modality === 'US' ? 'Add Sonographer Signature' : 'Add Radiographer Signature';
                }
                this.cd.markForCheck();
              });
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  open() {
    if (this.mode !== 'editor') return;
    SignatureModalComponent.open({
      modal: this.modal,
      report_id: this.topic.report_id,
      topic_id: this.topic.id,
      signature_role: this.signature_role,
    });
  }

  remove() {
    this.subscription.add(this.reportService.removeSignature(this.topic, this.signature_role).subscribe());
  }

  /**
   * Allow to add/edit signature if editing user is:
   * 1. Doctor
   * 2. Technician and button is not doctor signature button
   */
  checkSignaturePermission() {
    return this.reportService
      .selectKioskUser()
      .pipe(
        map(
          (user) =>
            user &&
            (user.company_roles.some((companyRoleId) => companyRoleId === 'doctor') ||
              user.company_roles.some(
                (companyRoleId) =>
                  new Set([
                    'junior_radiographer',
                    'junior_sonographer',
                    'sonographer',
                    'radiographer',
                    'imaging_technician',
                    'radiology_registrar',
                  ]).has(companyRoleId) && this.signature_role !== 'DOCTOR',
              )),
        ),
      );
  }
}
