import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { ExactMatchTopicState } from './exact-match-topic.reducer';

const adapter = createEntityAdapter<RR.ExactMatchTopic>();
const selectFeature = (state: AppState) => state.exact_match_topic;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = createSelector(selectFeature, (state: ExactMatchTopicState) => state.loaded);

export const fromExactMatchTopic = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
};
