<ng-container *ngIf="institution">
  <div class="col bg-light rounded p-2">
    <div class="row align-items-center">
      <div class="col-sm-9">
        <div class="row">
          <div class="col">
            <strong>Institution name:</strong>
            <p>{{ institution.name }}</p>
          </div>
          <div class="col">
            <strong>Address:</strong>
            <p>{{ institution.address }} {{ institution.suburb }} {{ institution.state }} {{ institution.postcode }}</p>
          </div>
          <div class="col-3">
            <strong>Type:</strong>
            <p>{{ institutionTypeMap[institution.type] }}</p>
          </div>
        </div>
        <div *ngIf="listingPage" class="row">
          <div class="col">
            <strong>Contact person:</strong>
            <p>{{ institution.contact_person }}</p>
          </div>

          <div class="col">
            <strong>Email:</strong>
            <p>{{ institution.email }}</p>
          </div>
          <div class="col-3">
            <strong>Phone:</strong>
            <p>{{ institution.phone }}</p>
          </div>
        </div>
      </div>
      <div class="col d-flex flex-column">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </div>
</ng-container>
