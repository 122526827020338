<div class="modal-header">
  <h4 class="modal-title">
    {{ isGroup ? 'Previous reports with selected group' : 'Previous reports with selected statement' }}
  </h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="d-flex w-100 mb-2">
    <div class="d-flex gap-1">
      <div class="col">
        <label for="minAge">Min Age:</label>
        <input class="form-control" type="number" id="minAge" [formControl]="form.controls.min_age" />
      </div>
      <div class="col">
        <label for="maxAge">Max Age:</label>
        <input class="form-control" type="number" id="maxAge" [formControl]="form.controls.max_age" />
      </div>

      <div class="col">
        <label for="patientSex">Sex:</label>
        <select class="form-control" id="patientSex" [formControl]="form.controls.patient_sex">
          <option value="">Any</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>

      <button class="btn btn-success align-self-end" (click)="filterReportGroups()">Filter</button>
      <button class="btn btn-light align-self-end" (click)="resetFilterReportGroups()">Reset</button>
    </div>
    <div class="col">
      <rr-label-select [reportIds]="selectedReportIds"></rr-label-select>
    </div>
  </div>

  <ng-container *ngIf="currentGroupTagText; else showStatementPreview">
    <div class="mx-2 d-flex gap-1">
      <p>Group reference:</p>
      <strong>{{ currentGroupTagText }}</strong>
    </div>
  </ng-container>

  <ng-template #showStatementPreview>
    <div class="mx-2 d-flex gap-1" *ngIf="isGroup">
      <p>Group reference:</p>
      <rr-statement-preview [statement_id]="statementChoiceId" [template_id]="topic.template_id"></rr-statement-preview>
    </div>
  </ng-template>

  <ng-container *ngIf="isLoading; else content">
    <p>Loading...</p>
  </ng-container>

  <ng-template #content>
    <ng-container *ngFor="let reportGroup of filteredReportGroups">
      <ng-container *ngIf="reportGroup.reports.length > 0">
        <div *ngIf="reportGroup.statement">
          <rr-recommended-statement [statement]="reportGroup.statement" [topic]="topic"></rr-recommended-statement>
        </div>

        <div *ngFor="let report of reportGroup.reports">
          <div class="d-flex align-items-center border-bottom mb-2">
            <input
              class="me-1"
              type="checkbox"
              [id]="'checkbox' + report.report_id"
              (change)="toggleReportSelection(report.report_id, $event)"
            />
            <label [for]="'checkbox' + report.report_id">
              <strong>Acc no: </strong>
              <a [routerLink]="['/report', report.report_id, 'topic', report.topic_id]" target="_blank">
                {{ report.accession_number || 'Unknown' }}</a
              >
              <span> - ({{ report.patient_sex }}, {{ report.patient_age }} Years old)</span>
              <a *ngIf="report.image_url" [href]="report.image_url" target="_blank" class="ms-2" rrTooltip="OPEN_IMAGE"
                >(W) Images</a
              >
              <span *ngIf="report.similarity_percent" class="mb-2">
                - Match: {{ report.similarity_percent * 100 | number: '1.1-1' }}%</span
              >
            </label>
            <rr-report-label-badge [reportId]="report.report_id"></rr-report-label-badge>
            <button
              class="ms-auto btn btn-outline-primary"
              role="button"
              (click)="togglePrefill(report.accession_number)"
            >
              Open in Prefill (B)
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <p *ngIf="isReportGroupEmpty">No reports found.</p>
    <div *ngIf="statementGroupIds && statementGroupIds.length > 0 && isGroup" class="d-flex justify-content-end gap-1">
      <button class="btn btn-outline-secondary" (click)="goToPreviousGroup()">Previous Group</button>
      <button class="btn btn-outline-primary" (click)="goToNextGroup()">Next Group</button>
    </div>
  </ng-template>
</div>
