import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromScanCode, ScanCodeEffect } from 'app/store/scan-code';
import { Subscription, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { ScanCodeHeadlineComponent } from '../../../../shared/components/scan-code-headline/scan-code-headline.component';

@Component({
  selector: 'rr-scan-code-select-modal',
  templateUrl: './scan-code-select-modal.component.html',
  styleUrls: ['./scan-code-select-modal.component.css'],
  standalone: true,
  imports: [CommonModule, ScanCodeHeadlineComponent],
})
export class ScanCodeSelectModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  activeScanCodes: RR.ScanCode[] = [];
  filteredScanCodes: RR.ScanCode[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private scanCodeEffect: ScanCodeEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromScanCode.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.scanCodeEffect.findAll();
            }
            return of(null);
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store.select(fromScanCode.selectActiveScanCodes).subscribe((codes) => {
        this.activeScanCodes = codes;
        this.filteredScanCodes = codes;
      }),
    );
  }

  onSearchTextChanged(text: string) {
    this.filteredScanCodes = this.activeScanCodes.filter(
      (code) =>
        code.code.toLowerCase().includes(text.toLowerCase()) || code.notes.toLowerCase().includes(text.toLowerCase()),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }
  selectScanCode(scanCode: RR.ScanCode) {
    this.activeModal.close(scanCode.id);
  }

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(ScanCodeSelectModalComponent, {
      size: 'lg',
    });
    return modalRef;
  }
}
