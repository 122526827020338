import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ZeroFPImagesComponent } from 'app/shared/components/zero-fp-images/zero-fp-images.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { FeedbackEffect } from 'app/store/report/feedback';
import { fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';

import { FeedbackRecipientsComponent } from '../../components/feedback-recipients/feedback-recipients.component';
import { ReportTextDiffComponent } from '../../components/report-text-diff/report-text-diff.component';
import { SendFeedbackComponent } from '../../components/send-feedback/send-feedback.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FeedbackRecipientsComponent,
    ReportTextDiffComponent,
    SendFeedbackComponent,
    ZeroFPImagesComponent,
  ],
  templateUrl: './send-feedback-modal.component.html',
  styleUrls: ['./send-feedback-modal.component.scss'],
})
export class SendFeedbackModalComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  report$: Observable<RR.Report | undefined>;

  subscription = new Subscription();
  selectedUserId: number;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private feedbackEffect: FeedbackEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    // Find feedbacks in report
    this.subscription.add(this.feedbackEffect.findInReport(this.reportId).subscribe());
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));
  }

  static open(modalService: NgbModal, reportId: number) {
    const modalRef = modalService.open(SendFeedbackModalComponent, {
      size: 'xl',
      scrollable: true,
      centered: false,
      windowClass: 'send-feedback-modal',
    });
    modalRef.componentInstance.reportId = reportId;
    return modalRef;
  }

  onSelectedUserChanged(userId: number) {
    this.selectedUserId = userId;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
