import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { TextObjectChoiceActions } from './text-object-choice.action';

export type TextObjectChoiceState = EntityState<RR.TextObjectChoice>;

const adapter = createEntityAdapter<RR.TextObjectChoice>();

const initialState = adapter.getInitialState();

export const TextObjectChoiceReducer = createReducer(
  initialState,
  on(TextObjectChoiceActions.addMany, (state, { textObjectChoices }) => adapter.addMany(textObjectChoices, state)),
  on(TextObjectChoiceActions.upsertMany, (state, { textObjectChoices }) =>
    adapter.upsertMany(textObjectChoices, state),
  ),
  on(TextObjectChoiceActions.removeMany, (state, { textObjectChoiceIds }) =>
    adapter.removeMany(textObjectChoiceIds, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
