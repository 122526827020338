import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store/app.state';
import { map, switchMap, tap } from 'rxjs/operators';

import { SectionActions } from '../section/section.action';
import { TemplateActions } from '../template/template.action';
import { SubsectionBatchActions, SubsectionActions } from './subsection.action';
import { SubsectionCreateBody, SubsectionHttpService } from './subsection.service';

@Injectable()
export class SubsectionEffect {
  constructor(
    private store: Store<AppState>,
    private service: SubsectionHttpService,
    private injector: Injector,
  ) {}

  create(data: SubsectionCreateBody) {
    return this.service.create(data).pipe(
      map(({ section, subsection }) =>
        SubsectionBatchActions.create({
          actions: {
            sectionUpdateSuccessAction: SectionActions.upsertOne({ section }),
            subsectionCreateSuccessAction: SubsectionActions.addOne({ subsection }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(subsectionId: number, changes: Partial<RR.Subsection>, deleteQuery?: boolean) {
    return this.service.update(subsectionId, changes, deleteQuery).pipe(
      map((subsection: RR.Subsection) => SubsectionActions.upsertOne({ subsection })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  updateMany(subsections: Pick<RR.Subsection, 'id' | 'elements'>[]) {
    return this.service.updateMany(subsections).pipe(
      map((subsections: RR.Subsection[]) => SubsectionActions.upsertMany({ subsections: subsections })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(subsectionId: number) {
    return this.service.delete(subsectionId).pipe(
      map((updatedTemplate) =>
        SubsectionBatchActions.delete({
          actions: {
            templateUpdateSuccessAction: TemplateActions.updateSuccess({ template: updatedTemplate }),
            subsectionDeleteSuccessAction: SubsectionActions.removeOne({ subsectionId }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((subsections: RR.Subsection[]) => SubsectionActions.addMany({ subsections })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  splitSubsection(
    section: RR.Section,
    subsection: RR.Subsection,
    elementId: number,
    newSubsection: Partial<RR.Subsection>,
  ) {
    // We use the injector to avoid a circular dependency:
    // EditorService -> ReportService -> TopicEffect -> TemplateEffect -> SubsectionEffect
    const editorService = this.injector.get(EditorService);
    const index = section.subsection_ids.indexOf(subsection.id);
    console.assert(index !== -1);
    let insertSubsectionPosition: number | null = index + 1;
    if (insertSubsectionPosition >= section.subsection_ids.length) {
      insertSubsectionPosition = null;
    }
    const elementPosition = subsection.elements.indexOf(elementId);
    // @ts-expect-error strictNullChecks
    const changes = { elements: subsection.elements.slice(null, elementPosition) };
    const elements = subsection.elements.slice(elementPosition);

    const obs$ = this.create({
      subsection: { ...newSubsection, elements },
      section_id: section.id,
      index: insertSubsectionPosition,
    })
      .pipe(
        map(({ actions }) => ({
          subsection,
          createdSubsection: actions.subsectionCreateSuccessAction.subsection,
          changes,
        })),
      )
      .pipe(
        switchMap(({ subsection, createdSubsection, changes }) => {
          return this.update(subsection.id, changes, true).pipe(
            map((updatedSubsection) => ({ updatedSubsection, createdSubsection })),
          );
        }),
        tap(({ createdSubsection }) => {
          editorService.selectSubsection(createdSubsection.id);
        }),
      );
    return obs$;
  }
}
