<div class="modal-header">
  <h5 class="modal-title">Region Set</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group mb-2 col-6 ps-0">
    <input
      class="form-control border-secondary"
      type="text"
      placeholder="Subsection name"
      [(ngModel)]="subsectionName"
      name="subsectionName"
      autocomplete="off"
    />
  </div>
  <label class="form-label">Select Region Set</label>
  <div class="d-flex">
    <div class="form-group mb-2 col-6 ps-0">
      <select class="form-select" [(ngModel)]="selectedRegionSet">
        <option *ngFor="let region_set of regionSets$ | async" [ngValue]="region_set">{{ region_set.name }}</option>
      </select>
    </div>
    <div class="ms-auto">
      <button ngbAutoFocus class="btn btn-info" role="button" (click)="manageRegionSet()">
        <span class="fa fa-registered"></span> Edit Region Set
      </button>
    </div>
  </div>

  <div *ngIf="selectedRegionSet">
    <label>Regions</label>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let region of getRegions() | async">{{ region.name }}</li>
    </ul>
  </div>
</div>
<div class="modal-footer d-flex justify-content-end">
  <button class="btn btn-primary" role="button" (click)="okClicked()">OK</button>
  <button class="btn btn-secondary" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
