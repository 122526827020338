import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TUTORIALS } from 'app/app.constants';

import { TutorialModalComponent } from '../tutorial-modal/tutorial-modal.component';

type TutorialObject = {
  TUTORIAL_TITLE: string;
  TUTORIAL_NOTES: string;
  TUTORIAL_SECTION: string;
};
@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radreport-manual-modal.component.html',
})
export class RadreportManualModalComponent {
  tutorial_objects = Object.values(TUTORIALS);
  title: string;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {}

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(RadreportManualModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    return modalRef;
  }

  openVideoModal(tutorialObject: TutorialObject) {
    const type = Object.keys(TUTORIALS).find((key) => TUTORIALS[key] === tutorialObject);
    if (type) {
      TutorialModalComponent.open(this.modalService, type);
    }
  }
}
