import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { TitleOptionActions } from '../title-option/title-option.action';
import { TitleSetActions, TitleSetBatchActions } from './title-set.action';
import { TitleSetHttpService } from './title-set.service';

@Injectable()
export class TitleSetEffect {
  constructor(
    private store: Store<AppState>,
    private service: TitleSetHttpService,
  ) {}

  find(titleSetId: number) {
    return this.service.find(titleSetId).pipe(
      map(({ title_set, title_options }) => {
        return TitleSetBatchActions.findSuccessBatchAction({
          actions: {
            findTitleSetSuccess: TitleSetActions.findSuccess({
              titleSet: title_set,
            }),
            titleOptionFindInTitleSetSuccess: TitleOptionActions.findInTitleSetSuccess({
              titleOptions: title_options,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map(({ title_set, title_options }) =>
        TitleSetBatchActions.findSuccessBatchAction({
          actions: {
            findTitleSetSuccess: TitleSetActions.findSuccess({
              titleSet: title_set,
            }),
            titleOptionFindInTitleSetSuccess: TitleOptionActions.findInTitleSetSuccess({
              titleOptions: title_options,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(titleSetId: number, changes: Partial<ServerTitleSet>) {
    return this.service.update(titleSetId, changes).pipe(
      map(({ title_set, title_options }) => {
        return TitleSetBatchActions.updateSuccessBatchAction({
          actions: {
            updateTitleSetSuccess: TitleSetActions.updateSuccess({
              titleSet: title_set,
            }),
            titleOptionFindInTitleSetSuccess: TitleOptionActions.findInTitleSetSuccess({
              titleOptions: title_options,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
