<ng-container *ngIf="getSendEvents() | async as sendEvents">
  <button
    #distributeReport
    *ngIf="getSendStatus() | async as status"
    [class]="'btn text-nowrap' + btnClass"
    role="button"
    [class.btn-sm]="btnSm"
    [class.btn-success]="status === 'RESOLVED'"
    [class.btn-danger]="status === 'UNRESOLVED'"
    [ngClass]="status === 'EMPTY' ? btnStyle : ''"
    (click)="openSendEventsModal()"
    [rrTooltip]="sendEvents.length > 0 ? null : 'DISTRIBUTE_REPORT'"
    [ngbTooltip]="sendEvents.length > 0 ? sendReportPopContent : null"
    triggers="hover"
    container="body"
  >
    <span>Distribute Report (R)</span>
  </button>
</ng-container>

<ng-template #sendReportPopContent>
  <div class="text-start">
    <div *ngFor="let event of getSendEvents() | async">
      <i
        class="fas"
        [class.fa-sms]="event.communication_type === 'SMS'"
        [class.fa-fax]="event.communication_type === 'FAX'"
        [class.fa-envelope]="event.communication_type === 'EMAIL'"
      ></i>
      {{ event.send_to }}, {{ event.when | date: 'd/MM' }} by {{ event.user_name }}
    </div>
  </div>
</ng-template>
