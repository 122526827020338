import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { GithubIssueActions } from './github-issue.action';

export interface GithubIssueState extends EntityState<RR.GithubIssue> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.GithubIssue>();

const initialState: GithubIssueState = adapter.getInitialState({ loaded: false });

export const githubIssueReducer = createReducer(
  initialState,
  on(GithubIssueActions.findAllSuccess, (state: GithubIssueState, { issues, count }) => {
    return adapter.upsertMany(issues, { ...state, loaded: true, count: count });
  }),
  on(GithubIssueActions.findSuccess, (state: GithubIssueState, { issue }) => {
    return adapter.upsertOne(issue, { ...state, loaded: true });
  }),
);
