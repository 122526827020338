<div ngbDropdown [autoClose]="'outside'">
  <button class="btn btn-outline-dark w-100 text-normal" type="button" ngbDropdownToggle>
    <ng-container *ngIf="checkedRolesList.length">
      <span *ngFor="let role of checkedRolesList; let last = last">
        {{ role.name }}
        <ng-container *ngIf="!last">,</ng-container>
      </span>
    </ng-container>
    <ng-container *ngIf="!checkedRolesList.length"> All roles </ng-container>
  </button>
  <div ngbDropdownMenu>
    <div class="list-group list-group-flush">
      <div class="list-group-item list-group-item-action" *ngFor="let role of allCompanyRoles; let i = index">
        <div class="form-check">
          <input
            id="role-selector-{{ i }}"
            class="form-check-input"
            [type]="mode === 'multiple' ? 'checkbox' : 'radio'"
            (change)="onRoleChange(role)"
            [checked]="checkedRoles[role.id]"
          />
          <label for="role-selector-{{ i }}" class="form-check-label">{{ role.name }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
