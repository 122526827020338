import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'rr-provider',
  templateUrl: 'provider.component.html',
})
export class ProviderComponent {
  @Input() provider: RR.Provider;
}
