<ng-template #tipTodo>
  <ng-container *ngFor="let todo of todos">
    <span *ngIf="todo.resolved" [ngClass]="{ 'font-italic': !todo.todo }">
      <i class="fas fa-check text-success"></i> {{ todo.todo ? todo.todo : 'Noted' }}
    </span>
    <span *ngIf="!todo.resolved" [ngClass]="{ 'font-italic': !todo.todo }">
      <i class="fas fa-times text-danger"></i> {{ todo.todo ? todo.todo : 'Noted' }}
    </span>
  </ng-container>
</ng-template>
<ng-container *ngIf="todos && todos.length > 0">
  <span
    class="fa fa-notes-medical pe-1"
    role="button"
    (click)="onToggleFlagged.emit(); $event.stopPropagation()"
    [class.text-danger]="unresolvedTodos.length > 0"
    [class.text-success]="unresolvedTodos.length === 0"
    [autoClose]="false"
    placement="right"
    [ngbTooltip]="tipTodo"
    #todoTooltip="ngbTooltip"
  ></span>
</ng-container>
