import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { BillingItemActions } from '../billing-item/billing-item.action';
import { BillingItemReferrerTypeActions } from './billing-item-referrer-type.action';
import {
  BillingItemReferrerTypeHttpService,
  BillingItemReferrerTypeResponse,
} from './billing-item-referrer-type.service';

@Injectable()
export class BillingItemReferrerTypeEffect {
  constructor(
    private store: Store<AppState>,
    private service: BillingItemReferrerTypeHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((billingItemsReferrerType: RR.ReferrerTypeBillingItem[]) =>
        BillingItemReferrerTypeActions.findAllSuccess({ billingItemsReferrerType }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addBillingItem(billingItemId: number, referrerType: string) {
    return this.service.addBillingItem(billingItemId, referrerType).pipe(
      map((billingItemReferrerType: BillingItemReferrerTypeResponse) =>
        BillingItemReferrerTypeActions.updateBillingItemReferrerTypeSuccessBatch({
          actions: {
            updateBillingItemReferrerTypeSuccess: BillingItemReferrerTypeActions.updateSuccess({
              billingItemReferrerType: billingItemReferrerType.referrer_type,
            }),
            updateBillingItemSuccess: BillingItemActions.updateSuccess({
              billingItem: billingItemReferrerType.billing_item,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteBillingItem(billingItemId: number, referrerType: string) {
    return this.service.deleteBillingItem(billingItemId, referrerType).pipe(
      map((billingItemReferrerType: BillingItemReferrerTypeResponse) =>
        BillingItemReferrerTypeActions.updateBillingItemReferrerTypeSuccessBatch({
          actions: {
            updateBillingItemReferrerTypeSuccess: BillingItemReferrerTypeActions.updateSuccess({
              billingItemReferrerType: billingItemReferrerType.referrer_type,
            }),
            updateBillingItemSuccess: BillingItemActions.updateSuccess({
              billingItem: billingItemReferrerType.billing_item,
            }),
          },
        }),
      ),
      tap((action) => {
        this.store.dispatch(action);
      }),
    );
  }
}
