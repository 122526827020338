import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { CategoryStatementComboActions } from './category-statement-combo.action';

export type CategoryStatementComboState = EntityState<RR.CategoryStatementCombo>;

const adapter = createEntityAdapter<RR.CategoryStatementCombo>();

const initialState = adapter.getInitialState();

export const categoryStatementComboReducer = createReducer(
  initialState,
  on(CategoryStatementComboActions.findAllSuccess, (state: CategoryStatementComboState, { categories }) =>
    adapter.setAll(categories, state),
  ),
  on(
    CategoryStatementComboActions.addAttributeComboSuccess,
    CategoryStatementComboActions.updateCategoryStatementComboSuccess,
    (state: CategoryStatementComboState, { categoryStatementCombo }) =>
      adapter.upsertOne(categoryStatementCombo, state),
  ),
  on(
    CategoryStatementComboActions.deleteCategoryStatementComboSuccess,
    (state: CategoryStatementComboState, { categoryStatementComboId }) =>
      adapter.removeOne(categoryStatementComboId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
