import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.SectionChoice>();
const selectFeature = (state: AppState) => state.section_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectSectionChoice = (id: number) =>
  createSelector(selectEntities, (sectionChoices: Dictionary<RR.SectionChoice>) => sectionChoices[id]);

export const fromSectionChoice = {
  selectEntities,
  selectAll,
  selectIds,
  selectSectionChoice,
};
