<div class="input-group" placement="bottom">
  <input
    #input
    [formControl]="numberInput"
    class="form-control"
    type="number"
    #tooltip="ngbTooltip"
    (focus)="onFocus()"
    (keyup)="handleKeyPress($event)"
    (blur)="onBlur()"
    [ngbTooltip]="tooltipText"
    triggers="manual"
    [class.is-invalid]="!isWithinRange(input.value)"
    rrTooltip="TAB_OR_ENTER_SPECIFICATION"
    rrVoice
    [speechSynthesis]="true"
    [keydownStopListening]="false"
  />
  <div>
    <button *ngFor="let measurement of proposed_measurements" ngbDropdownItem (click)="clickDropdownItem(measurement)">
      {{ measurement.numeric_value }} {{ measurement.units }}
    </button>
  </div>
  <button
    *ngIf="equivalentTextObject"
    tabindex="-1"
    role="button"
    class="btn"
    permission
    [permission-only]="['template_manage']"
    [class.btn-light]="isWithinRange(input.value)"
    [class.btn-danger]="!isWithinRange(input.value)"
    (click)="openStatementNumberModal()"
    rrTooltip="SET_BOUNDS"
  >
    <span class="fa fa-cog"></span>
  </button>
</div>
