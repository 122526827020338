import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferrerNoteSearchFilterForm } from 'app/modules/entity-listing/components/referrer-note-listing/referrer-note-listing-search/referrer-note-listing-search.component';
import { map, Observable } from 'rxjs';

import { ReferrerSearchResponse } from '../referrer/referrer.service';

export type CreateReferrerNoteResponse = {
  referrer: RR.Referrer;
  referrer_note: RR.ReferrerNote;
};

export type DeleteReferrerNoteResponse = {
  referrer: RR.Referrer;
};

@Injectable()
export class ReferrerNoteHttpService {
  constructor(private http: HttpClient) {}

  create(referrerNote: Partial<RR.ReferrerNote>): Observable<CreateReferrerNoteResponse> {
    return this.http.post<CreateReferrerNoteResponse>('/api/referrer_note', referrerNote);
  }

  delete(referrerNoteId: number): Observable<DeleteReferrerNoteResponse> {
    return this.http.delete<DeleteReferrerNoteResponse>(`/api/referrer_note/${referrerNoteId}`);
  }

  update(referrerNoteId: number, changes: Partial<RR.ReferrerNote>): Observable<RR.ReferrerNote> {
    return this.http.put<RR.ReferrerNote>(`/api/referrer_note/${referrerNoteId}`, changes);
  }

  searchReferrerNotes(queryBody: ReferrerNoteSearchFilterForm, params: HttpParams): Observable<ReferrerSearchResponse> {
    return this.http
      .post<ReferrerSearchResponse>('/api/referrer_note/search', queryBody, {
        params,
      })
      .pipe(
        map((response: ReferrerSearchResponse) => {
          return {
            count: response.count,
            referrers: response.referrers,
            referrer_contact_methods: response.referrer_contact_methods,
            referrer_notes: response.referrer_notes,
            referrer_note_categories: response.referrer_note_categories,
          };
        }),
      );
  }
}
