import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ScanCodeActions } from '../scan-code/scan-code.action';
import { SiteActions } from '../site/site.action';
import { UserActions } from '../user/user/user.action';
import { BookingCodeActions, BookingCodeBatchActions } from './booking-code.action';
import {
  BookingCodeAndRegistrationFormResponse,
  BookingCodeAndScanCodeResponse,
  BookingCodeAndSiteResponse,
  BookingCodeAndUserResponse,
  BookingCodeHttpService,
} from './booking-code.service';

@Injectable()
export class BookingCodeEffect {
  constructor(
    private store: Store<AppState>,
    private service: BookingCodeHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((bookingCodes: RR.BookingCode[]) => BookingCodeActions.findAllSuccess({ bookingCodes })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(bookingCode: Partial<RR.BookingCode>) {
    return this.service.create(bookingCode).pipe(
      map((bookingCode: RR.BookingCode) => BookingCodeActions.createSuccess({ bookingCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(bookingCodeId: number, changes: Partial<RR.BookingCode>) {
    return this.service.update(bookingCodeId, changes).pipe(
      map((bookingCode) => BookingCodeActions.updateSuccess({ bookingCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(bookingCodeId: number) {
    return this.service.delete(bookingCodeId).pipe(
      map((bookingCode) => BookingCodeActions.updateSuccess({ bookingCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addScanCode(bookingCodeId: number, scanCodeId: number) {
    return this.service.addScanCode(bookingCodeId, scanCodeId).pipe(
      map((bookingCodeScanCode: BookingCodeAndScanCodeResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndScanCodeSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: bookingCodeScanCode.booking_code,
            }),
            updateScanCodeSuccess: ScanCodeActions.updateSuccess({
              scanCode: bookingCodeScanCode.scan_code,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeScanCode(bookingCodeId: number) {
    return this.service.removeScanCode(bookingCodeId).pipe(
      map((bookingCodeScanCode: BookingCodeAndScanCodeResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndScanCodeSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: bookingCodeScanCode.booking_code,
            }),
            updateScanCodeSuccess: ScanCodeActions.updateSuccess({
              scanCode: bookingCodeScanCode.scan_code,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addSite(bookingCodeId: number, siteId: number) {
    return this.service.addSite(bookingCodeId, siteId).pipe(
      map((response: BookingCodeAndSiteResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndSiteSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: response.booking_code,
            }),
            updateSiteSuccess: SiteActions.upsertOne({
              site: response.site,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeSite(bookingCodeId: number, siteId: number) {
    return this.service.removeSite(bookingCodeId, siteId).pipe(
      map((response: BookingCodeAndSiteResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndSiteSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: response.booking_code,
            }),
            updateSiteSuccess: SiteActions.upsertOne({
              site: response.site,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addUser(bookingCodeId: number, userId: number) {
    return this.service.addUser(bookingCodeId, userId).pipe(
      map((response: BookingCodeAndUserResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndUserSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: response.booking_code,
            }),
            updateUserSuccess: UserActions.upsertOne({
              user: response.user,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeUser(bookingCodeId: number, userId: number) {
    return this.service.removeUser(bookingCodeId, userId).pipe(
      map((response: BookingCodeAndUserResponse) =>
        BookingCodeBatchActions.updateBookingCodeAndUserSuccessBatch({
          actions: {
            updateBookingCodeSuccess: BookingCodeActions.updateSuccess({
              bookingCode: response.booking_code,
            }),
            updateUserSuccess: UserActions.upsertOne({
              user: response.user,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addRegistrationForm(bookingCodeId: number, formId: number) {
    return this.service.addRegistrationForm(bookingCodeId, formId).pipe(
      this.message.handleHttpErrorPipe,
      map((response: BookingCodeAndRegistrationFormResponse) =>
        BookingCodeActions.updateSuccess({ bookingCode: response.booking_code }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeRegistrationForm(bookingCodeId: number, formId: number) {
    return this.service.removeRegistrationForm(bookingCodeId, formId).pipe(
      this.message.handleHttpErrorPipe,
      map((response: BookingCodeAndRegistrationFormResponse) =>
        BookingCodeActions.updateSuccess({ bookingCode: response.booking_code }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
