import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { RegistrationFormActions } from './registration-form.action';
import { RegistrationFormHttpService } from './registration-form.service';

@Injectable()
export class RegistrationFormEffect {
  constructor(
    private store: Store<AppState>,
    private service: RegistrationFormHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((forms) => RegistrationFormActions.findManySuccess({ forms })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
