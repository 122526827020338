<div class="w-100 text-info" role="button" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <span class="fa fa-tag ms-1"></span> {{ category.text }}

  <div class="item-btns-right btn-group" *ngIf="hover">
    <button
      role="button"
      (click)="removeCategory()"
      class="btn btn-sm btn-danger"
      permission
      [permission-only]="['template_manage']"
      rrTooltip="REMOVE_CATEGORY"
    >
      <span class="fa fa-trash"></span>
    </button>

    <button
      role="button"
      (click)="editCategory()"
      class="btn btn-sm btn-primary"
      permission
      [permission-only]="['template_manage']"
      rrTooltip="EDIT_CATEGORY"
    >
      <span class="fa fa-edit"></span>
    </button>
  </div>
</div>
