import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type SubsectionCreateBody = {
  subsection: Partial<RR.Subsection>;
  section_id: number;
  index?: number | null;
};

@Injectable()
export class SubsectionHttpService {
  constructor(private http: HttpClient) {}

  findAll() {
    return this.http.get<RR.Subsection[]>('/api/subsection');
  }

  create(subsection: SubsectionCreateBody) {
    return this.http.post<{ subsection: RR.Subsection; section: RR.Section }>('/api/subsection', subsection);
  }

  update(subsectionId: number, changes: Partial<RR.Subsection>, deleteQuery?: boolean) {
    return this.http.put<RR.Subsection>(`/api/subsection/${subsectionId}${deleteQuery ? '?delete=true' : ''}`, changes);
  }

  updateMany(subsections: Pick<RR.Subsection, 'id' | 'elements'>[]) {
    return this.http.put<RR.Subsection[]>(`/api/subsections`, { subsections });
  }

  delete(subsectionId: number) {
    return this.http.delete<RR.Template>(`/api/subsection/${subsectionId}`);
  }

  findInTemplate(templateId: number) {
    return this.http.get<RR.Subsection[]>(`/api/template/${templateId}/subsection`);
  }
}
