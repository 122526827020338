<div class="modal-header">
  <div>
    <h5 class="modal-title">Report Due Date</h5>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <form
    [formGroup]="dueDateForm"
    (ngSubmit)="onSubmit()"
    class="d-flex mb-2"
    [ngClass]="parent === 'WORKLIST' ? 'align-items-center' : 'align-items-start'"
  >
    <div ngbDropdown class="d-flex w-100 gap-1">
      <input
        class="form-control"
        type="datetime-local"
        max="2100-08-19T00:00"
        formControlName="due_date"
        (keydown.enter)="onSubmit()"
        rrAutoFocus
      />

      <button
        ngbAutoFocus
        class="btn btn-info dropdown-btn px-1"
        ngbDropdownToggle
        type="button"
        rrTooltip="DUE_TIME_REPORT"
      >
        Time
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button
          ngbDropdownItem
          class="btn btn-info text-dark"
          type="button"
          *ngFor="let time of timeList"
          (click)="setDueTime(time)"
        >
          <span *ngIf="time < 12">{{ time }} am</span>
          <span *ngIf="time > 12">{{ time - 12 }} pm</span>
        </button>
      </div>

      <div ngbDropdown>
        <button
          class="btn btn-secondary dropdown-btn px-1"
          ngbDropdownToggle
          type="button"
          rrTooltip="DUE_TIME_IN_MINUTES_REPORT"
        >
          Due in
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button
            ngbDropdownItem
            class="btn btn-secondary text-dark"
            type="button"
            *ngFor="let increment of incrementList"
            (click)="setDueTimeIncrement(increment)"
          >
            {{ increment }} min
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="d-flex gap-1">
    <button class="btn btn-danger me-1" (click)="onClickDueNow()" rrTooltip="DUE_DATE_DUE_NOW">
      <i class="fas fa-exclamation-circle"></i> Report Due Now
    </button>
    <rr-urgent-button [reportId]="report.id" [btnSize]="''"></rr-urgent-button>
    <rr-super-urgent-button [reportId]="report.id" [btnSize]="''"></rr-super-urgent-button>
  </div>
</div>

<div class="modal-footer d-flex">
  <button class="btn btn-danger" rrTooltip="CANCEL_EDIT_DATE" type="button" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button class="btn btn-success" rrTooltip="SAVE_DATE" (click)="onSubmit()"><i class="fas fa-save"></i> Save</button>
</div>
