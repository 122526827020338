import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { FeedbackCommentActions } from '../feedback-comment/feedback-comment.action';

/**
 * Ngrx Actions for Feedback
 *
 */
export const FeedbackActions = {
  findAllSuccess: createAction('[Feedback] Find All Success', props<{ feedbacks: RR.Feedback[] }>()),

  findManySuccess: createAction('[Feedback] Find Many Success', props<{ feedbacks: RR.Feedback[] }>()),

  createSuccess: createAction('[Feedback] Create Success', props<{ feedback: RR.Feedback }>()),

  updateSuccess: createAction('[Feedback] Update Success', props<{ feedback: RR.Feedback }>()),
};

export const FeedbackBatchActions = {
  findAllSuccess: createBatchAction(
    '[Feedback] Find All Success Batch',
    props<{
      count: number;
      feedbackIds: number[];
      actions: {
        findFeedbacksSuccess: ReturnType<typeof FeedbackActions.findManySuccess>;
        findFeedbackCommentsSuccess: ReturnType<typeof FeedbackCommentActions.findManySuccess>;
      };
    }>(),
  ),

  checkFeedbackSuccess: createBatchAction(
    '[Feedback] Check Success Batch',
    props<{
      actions: {
        updateFeedbackSuccess: ReturnType<typeof FeedbackActions.updateSuccess>;
        updateFeedbackCommentsSuccess: ReturnType<typeof FeedbackCommentActions.updateManySuccess>;
      };
    }>(),
  ),
};
