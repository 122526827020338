import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CategoryHttpService {
  constructor(private http: HttpClient) {}

  findAll(params: HttpParams): Observable<RR.Category[]> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (params) {
      return this.http.get<RR.Category[]>('/api/category', { params });
    }
    return this.http.get<RR.Category[]>('/api/category');
  }

  create(category: Partial<RR.Category>): Observable<RR.Category> {
    return this.http.post<RR.Category>('/api/category', category);
  }

  update(categoryId: number, changes: Partial<RR.Category>): Observable<RR.Category> {
    return this.http.put<RR.Category>(`/api/category/${categoryId}`, changes);
  }

  delete(categoryId: number) {
    return this.http.delete(`/api/category/${categoryId}`);
  }
}
