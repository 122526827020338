<div class="form-group p-1 mb-0">
  <form [formGroup]="searchForm" class="mb-0 mt-1">
    <input type="submit" hidden />
    <div
      class="prefill-tags-container overflow-auto"
      [hidden]="
        !(searchType.value === 'ALL' || searchType.value === 'FAVOURITES' || searchType.value === 'SUBDIVISION')
      "
    >
      <tag-input
        #tagInput
        [editable]="false"
        formControlName="searches"
        [secondaryPlaceholder]="'Input search terms'"
        [placeholder]="'+ Search term'"
        (onTextChange)="onTagInputTextChanged($event)"
        [inputText]="globalSearchText"
        [separatorKeys]="[',', '.']"
        title="Separate search terms with ',' or '.'"
      >
        <ng-template let-item="item">
          <div class="d-flex align-items-center" (click)="onChipClick(item); $event.stopPropagation()">
            <i *ngIf="item.tag && checkHighlightTag(item.tag)" class="fa fa-check text-success me-1"></i>
            <div class="text-truncate" *ngIf="!item.tag" title="{{ item.value }}">
              {{ item.value }}
            </div>
            <div class="text-truncate" *ngIf="item.tag">
              <div *ngIf="item.tag.region_text" title="{{ item.tag.region_text }} {{ item.tag.tag_text }}">
                {{ item.tag.region_text }} {{ item.tag.tag_text }}
              </div>
              <div *ngIf="!item.tag.region_text" title="{{ item.tag.tag_text }}">
                {{ item.tag.tag_text }}
              </div>
            </div>

            <button
              role="button"
              class="btn btn-sm ms-auto"
              (click)="onTagRemoved(item); $event.preventDefault(); $event.stopPropagation()"
              title="Remove this group"
            >
              <span class="fa fa-trash-alt text-danger"></span>
            </button>
          </div>
        </ng-template>
      </tag-input>
    </div>
    <input
      #presetTextInput
      type="text"
      *ngIf="searchType.value === 'PRESET'"
      placeholder="Search for presets"
      class="form-control"
      [formControl]="searchForm.controls.preset"
    />
    <input
      #fullTextInput
      type="text"
      *ngIf="searchType.value === 'FULL_TEXT'"
      placeholder="Search for report text..."
      class="form-control"
      [formControl]="searchForm.controls.fullText"
      data-cy="full-text-input"
    />
    <div class="d-flex" *ngIf="searchType.value === 'DEBUG'">
      <div class="input-group">
        <input
          #accessionNumbersInput
          type="text"
          placeholder="Acc. separated by comma..."
          class="form-control"
          [formControl]="searchForm.controls.accessionNumbers"
          data-cy="accession-numbers-input"
        />
      </div>
    </div>

    <!--Search All, Favourites, My Favourites-->
    <div class="d-flex flex-wrap my-1">
      <div class="form-check me-2" rrTooltip="PREFILL_METADATA_RADIO">
        <input
          id="prefill-search-tab-all"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="ALL"
        />
        <label for="prefill-search-tab-all" class="form-check-label"> Metadata </label>
      </div>

      <div class="form-check me-2" rrTooltip="PREFILL_FAVOURITES_RADIO">
        <input
          permission
          [permission-only]="['view_old_favourites']"
          id="prefill-search-tab-favourite"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="FAVOURITES"
        />
        <label for="prefill-search-tab-favourite" class="form-check-label"> Favourites </label>
      </div>

      <div class="form-check me-2" rrTooltip="PREFILL_PRESETS_RADIO">
        <input
          id="prefill-search-tab-preset"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="PRESET"
        />
        <label for="prefill-search-tab-preset" class="form-check-label"> Presets </label>
      </div>

      <div class="form-check me-2" rrTooltip="PREFILL_SUBDIVISION_RADIO">
        <input
          id="prefill-search-tab-subdivision"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="SUBDIVISION"
        />
        <label for="prefill-search-tab-subdivision" class="form-check-label"> Subdivision </label>
      </div>

      <div class="form-check me-2" rrTooltip="PREFILL_FULL_TEXT_RADIO">
        <input
          id="prefill-search-tab-full-text"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="FULL_TEXT"
        />
        <label for="prefill-search-tab-full-text" class="form-check-label"> Full Text </label>
      </div>

      <div class="form-check me-2" title="Prefill by images similarity" *ngIf="mlTrainedTemplate$ | async">
        <input
          id="prefill-search-tab-images"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="IMAGES"
        />
        <label for="prefill-search-tab-images" class="form-check-label"> Images </label>
      </div>

      <div class="form-check me-2" title="Search by accession number" permission [permission-only]="['testing']">
        <input
          id="prefill-search-tab-debug"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="DEBUG"
        />
        <label for="prefill-search-tab-debug" class="form-check-label">Acc. number</label>
      </div>

      <div class="form-check me-2" title="Search for related studies">
        <input
          id="prefill-search-tab-related_studies"
          class="form-check-input"
          type="radio"
          formControlName="searchType"
          value="RELATED_STUDIES"
        />
        <label for="prefill-search-tab-related_studies" class="form-check-label"> Related Studies </label>
      </div>
    </div>

    <!--Match title, DOB, Sex...-->
    <div class="row">
      <div class="col col-2 pe-0" *ngIf="searchType.value === 'ALL' || searchType.value === 'FAVOURITES'">
        <span title="Only show reports that match template, title, sex or my cases"><b>Only:</b> </span>
      </div>
      <div class="col col-10 px-0">
        <div class="d-flex flex-wrap">
          <div class="form-check" *ngIf="searchType.value === 'ALL'">
            <input
              class="form-check-input pe-0"
              type="checkbox"
              id="template-match"
              name="template-match"
              title="Only show matching template"
              formControlName="templateMatch"
            />
            <label class="form-check-label" for="template-match">Template</label>
          </div>

          <div class="form-check ms-2" *ngIf="searchType.value === 'ALL'">
            <input
              class="form-check-input pe-0"
              type="checkbox"
              id="title-match"
              name="title-match"
              title="Sort by matching title"
              formControlName="titleMatch"
            />
            <label class="form-check-label" for="title-match">Title</label>
          </div>

          <div class="form-check flex-wrap ms-2" *ngIf="searchType.value === 'ALL'">
            <input
              class="form-check-input pe-0"
              type="checkbox"
              id="sex-match"
              name="sex-match"
              title="Sort by matching sex"
              formControlName="sexMatch"
            />
            <label class="form-check-label" for="sex-match">Sex</label>
          </div>

          <div class="form-check ms-2" *ngIf="searchType.value === 'FAVOURITES'">
            <input
              class="form-check-input"
              type="checkbox"
              id="my-fav-match"
              name="my-fav-match"
              title="Sort by matching my favourites"
              formControlName="myFavMatch"
            />
            <label class="form-check-label" for="my-fav-match">My cases</label>
          </div>

          <div class="form-check ms-2" *ngIf="searchType.value === 'ALL'">
            <input
              class="form-check-input"
              type="checkbox"
              id="top-rated-match"
              name="top-rated-match"
              title="Only show top rated reports"
              formControlName="topRatedMatch"
            />
            <label class="form-check-label" for="top-rated-match">5 stars</label>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-0" />

    <div class="row" *ngIf="searchType.value === 'ALL'">
      <div class="col col-2 pe-0">
        <span title="Give higher score to patient's DOB, latest reports or reports with similar groups"
          ><b>Boost:</b>
        </span>
      </div>
      <div class="col col-10 px-0">
        <div class="d-flex flex-wrap">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="dob-boost"
              name="dob-boost"
              title="Boost reports with patient DOBs within 10 years"
              formControlName="dobBoost"
            />
            <label class="form-check-label" for="dob-boost">DOB</label>
          </div>

          <div class="form-check ms-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="created-boost"
              name="created-boost"
              title="Boost recently created reports"
              formControlName="createdBoost"
            />
            <label class="form-check-label" for="created-boost">Latest</label>
          </div>

          <div class="form-check ms-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="report-tags-match"
              name="report-tags-match"
              title="Sort by matching current report choices"
              formControlName="reportTagsMatch"
            />
            <label class="form-check-label" for="report-tags-match">Report Groups</label>
          </div>

          <div class="form-check ms-2">
            <input
              class="form-check-input"
              type="checkbox"
              id="top-rated-boost"
              name="top-rated-boost"
              title="Boost topics with a 5 star rating"
              formControlName="topRatedBoost"
            />
            <label class="form-check-label" for="top-rated-boost">5 stars</label>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-0" />

    <!--Display options: Title, Tags-->
    <div class="d-flex flex-wrap">
      <ng-container *ngIf="topic$ | async as topic">
        <div
          class="me-1 my-1"
          *ngIf="searchType.value === 'IMAGES' && (mlLandmarkTemplate$ | async)"
          title="Search top 5 reports for each landmark"
        >
          <input id="landmark" class="btn-check" type="checkbox" [formControl]="searchForm.controls.imageLandmark" />
          <label for="landmark" class="btn btn-secondary btn-sm">
            <input class="form-check-input" type="checkbox" [checked]="searchForm.controls.imageLandmark.value" />
            Landmark
          </label>
        </div>
      </ng-container>

      <ng-container *ngIf="mlTrainedTemplate$ | async">
        <ng-container *ngIf="!(reportFeaturesExtracted$ | async)">
          <button
            class="btn btn-sm my-1 me-1 btn-info text-nowrap"
            title="Extract features for images comparison"
            (click)="extractImageFeatures()"
          >
            Extract Feature
          </button>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="mlTrainedTemplate$ | async">
        <div [formGroup]="weightsForm">
          <button
            type="button"
            permission
            [permission-only]="['superuser']"
            class="btn btn-sm btn-outline-secondary my-1"
            [ngbPopover]="popoverContent"
            popoverTitle="Weights Information"
            #popover="ngbPopover"
            triggers="manual"
            placement="right"
            [autoClose]="'outside'"
            container="body"
            popoverClass="weights-form-popover"
            rrTooltip="IMGSIM_WEIGHTS"
            (click)="popover.isOpen() ? popover.close() : popover.open()"
          >
            <i class="fas fa-balance-scale-left"></i>
          </button>

          <ng-template #popoverContent>
            <div formArrayName="weights" style="max-height: 300px; overflow-y: auto">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Template ID</th>
                    <th>Column Name</th>
                    <th>Weight</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody *ngFor="let weightControl of getWeightsControls(); let i = index">
                  <tr [formGroupName]="i">
                    <td>{{ weightControl.value.id }}</td>
                    <td>{{ weightControl.value.template_id }}</td>
                    <td>{{ weightControl.value.column_name }}</td>
                    <td>
                      <input
                        rrTooltip="IMGSIM_WEIGHTS_UPDATE_WEIGHT"
                        class="form-control"
                        formControlName="weight"
                        type="number"
                        (change)="updateWeight(weightControl.value.id)"
                      />
                    </td>
                    <td>
                      <div class="input-group">
                        <input formControlName="description" type="text" class="form-control" />
                        <button
                          ngbTooltip="Update weight description text manually via button"
                          placement="right"
                          [openDelay]="300"
                          container="body"
                          class="btn btn-sm btn-outline-success"
                          type="button"
                          (click)="updateWeight(weightControl.value.id)"
                        >
                          <i class="fas fa-pencil-alt fa-sm"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <!--Landmark options-->
    <div class="d-flex flex-wrap mb-1" *ngIf="searchForm.controls.imageLandmark.value && landmarks.length > 0">
      <div
        class="me-1 mt-1"
        *ngFor="let l of landmarks"
        title="Use Shift + Arrow Left/Right to navigate between landmarks"
      >
        <input
          id="landmark-{{ l }}"
          class="btn-check"
          type="checkbox"
          [checked]="selectedLandmark === l"
          (click)="selectLandmark(l)"
        />
        <label for="landmark-{{ l }}" class="btn btn-outline-primary btn-sm"> {{ l }}</label>
      </div>
    </div>
  </form>

  <rr-prefill-search-selections [topic_id]="topic_id"></rr-prefill-search-selections>

  <div class="card-header mt-1 text-white w-100 px-2 py-1 d-flex align-items-center bg-success">
    <h4 class="mb-0">
      <i class="fas fa-chevron-down"></i>
      {{ searchType.value === 'ALL' ? 'METADATA' : searchType.value }} Match
    </h4>
    <h4 class="ms-auto mb-0"><strong>B2</strong></h4>
  </div>

  <div>
    <!-- Count results -->
    <small
      *ngIf="
        result?.hits &&
        (searchType.value === 'ALL' || searchType.value === 'FAVOURITES' || searchType.value === 'RELATED_STUDIES')
      "
      >{{ count }} results</small
    >
    <small *ngIf="searchType.value === 'FAVOURITES'">, ordered by usage</small>
  </div>
</div>

<!--Subdivision if searching by subsection/region-->
<div *ngIf="searchType.value === 'SUBDIVISION' && subdivisionResults && subdivisionResults.length > 0" class="mb-1">
  <div class="form-check ms-0" title="Select top 3 topics for each subdivision and merge topic's subdivisions together">
    <input
      id="manual-subdivision"
      class="form-check-input"
      type="checkbox"
      [checked]="bestMatchForSubdivisions"
      (change)="toggleBestMatchForSubdivisions()"
    />
    <label for="manual-subdivision" class="form-check-label"> Auto select best topics for each subdivision </label>
  </div>
  <select
    *ngIf="!bestMatchForSubdivisions"
    class="form-select"
    id="selectedSubdivision"
    [(ngModel)]="selectedSubdivision"
    (ngModelChange)="onSelectedSubdivisionChanged()"
  >
    <option
      *ngFor="let result of subdivisionResults; let i = index"
      [selected]="result.subdivision === selectedSubdivision"
      [ngValue]="result.subdivision"
    >
      {{ getSubdivisionText(result.subdivision) | async }}
    </option>
  </select>
  <ng-container *ngIf="bestMatchForSubdivisions">
    <div *ngFor="let result of subdivisionResults" class="form-check no-checkbox fw-bold mt-2">
      {{ getSubdivisionText(result.subdivision) | async }}
    </div>
  </ng-container>
</div>

<!--Result for Topic search-->
<div
  *ngIf="
    result &&
    result.hits &&
    !searching &&
    (searchType.value === 'DEBUG' ||
      searchType.value === 'ALL' ||
      searchType.value === 'FAVOURITES' ||
      searchType.value === 'FULL_TEXT' ||
      searchType.value === 'RELATED_STUDIES' ||
      (searchType.value === 'IMAGES' && !searchForm.controls.imageLandmark.value) ||
      !bestMatchForSubdivisions)
  "
  class="list-group search-results"
>
  <div
    role="button"
    rr-prefill-row
    class="list-group-item list-group-item-action"
    *ngFor="let t of result.hits.hits"
    [topic]="t"
    [open_topic_id]="topic_id"
    [showImageSimilarityScore]="searchType.value === 'IMAGES'"
    [searchType]="searchType"
  ></div>
  <div class="row mx-auto mt-1" *ngIf="count">
    <ngb-pagination
      [collectionSize]="count"
      [page]="searchForm.controls.page.value"
      [pageSize]="limit"
      (pageChange)="onPageChange($event)"
      [maxSize]="5"
    >
    </ngb-pagination>
  </div>
</div>

<!--Result for image search by landmark -->
<div
  *ngIf="landmarkResult && searchType.value === 'IMAGES' && searchForm.controls.imageLandmark.value"
  class="list-group search-results"
>
  <div
    role="button"
    rr-prefill-row
    class="list-group-item list-group-item-action"
    *ngFor="let t of landmarkResult.hits.hits"
    [topic]="t"
    [open_topic_id]="topic_id"
    [showImageSimilarityScore]="true"
    [searchType]="searchType"
  ></div>
</div>

<div class="list-group search-results" *ngIf="searchType.value === 'PRESET' && preset">
  <rr-prefill-preset [openTopicId]="topic_id" [preset]="preset"></rr-prefill-preset>
</div>

<div *ngIf="searching" class="d-flex justify-content-center loading mt-2">
  <i class="fa fa-spinner fa-spin text-secondary"></i>
</div>
