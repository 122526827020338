<div class="modal-header">
  <h5 class="modal-title">
    <ng-container *ngIf="!reInvoice">
      {{ invoiceId ? 'Edit invoice' : 'Create new invoice' }}
    </ng-container>
    <ng-container *ngIf="reInvoice">Recreate Invoice</ng-container>
  </h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <rr-invoice-form
    [reportId]="reportId"
    [bookingId]="bookingId"
    [invoiceId]="invoiceId"
    [reInvoice]="reInvoice"
    (onClose)="close()"
  ></rr-invoice-form>
</div>

<div class="modal-footer">
  <button class="btn btn-success" role="button" (click)="submit()" [disabled]="submitting">
    <ng-container *ngIf="!reInvoice">
      {{ invoiceId ? 'Update invoice' : 'Create invoice' }}
    </ng-container>
    <ng-container *ngIf="reInvoice">Recreate Invoice</ng-container>
  </button>
  <button class="btn btn-light ms-2" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
