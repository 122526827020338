import { AfterViewInit, Directive, ElementRef, Host, HostListener, Input, Optional } from '@angular/core';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { environment } from 'environments/environment';

import { TooltipDirective } from './tooltip.directive';

@Directive({
  selector: '[rrTrack]',
  standalone: true,
})
export class TrackDirective implements AfterViewInit {
  /**
   * Usage [rrTrack]="'Open Sidebar'"
   */
  @Input() rrTrack: string;
  /**
   * @deprecated Use rrTrack instead
   */
  @Input() trackAction: string;

  constructor(
    private gaService: GoogleAnalyticsService,
    @Optional() @Host() public tooltipDirective: TooltipDirective | null,
    private element: ElementRef,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const action = this.rrTrack || this.trackAction;
    this.gaService.trackClick(event, {
      action: action,
    });
  }

  ngAfterViewInit() {
    if (
      !environment.production &&
      !this.element.nativeElement.title &&
      !this.tooltipDirective &&
      !this.trackAction &&
      !this.rrTrack
    ) {
      console.warn(
        this.element.nativeElement,
        'elements with [rrTrack] directive must have a title, [rrTooltip], or @Input() trackAction',
      );
    }
  }
}
