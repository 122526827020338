import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { TagChoiceActions, TagChoiceBatchActions } from 'app/store/prefill/tag-choice';
import { ElementActions } from 'app/store/template/element';
import { RegionActions } from 'app/store/template/region';
import { SectionActions } from 'app/store/template/section/section.action';
import { StatementActions } from 'app/store/template/statement';
import { StatementCoincidenceActions } from 'app/store/template/statement-coincidence';
import { StatementSetActions } from 'app/store/template/statement-set';
import { SubsectionActions } from 'app/store/template/subsection';
import { TemplateBatchActions } from 'app/store/template/template';
import { TextObjectActions } from 'app/store/template/text-object';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { VoiceNoteActions, VoiceNoteBatchActions } from './voice-note.action';
import { VoiceNoteHttpService } from './voice-note.service';

@Injectable()
export class VoiceNoteEffect {
  constructor(
    private store: Store<AppState>,
    private service: VoiceNoteHttpService,
    private message: MessageService,
  ) {}

  create(voiceNote: Partial<RR.VoiceNote>) {
    return this.service.create(voiceNote).pipe(
      this.message.handleHttpErrorPipe,
      map((voiceNote: RR.VoiceNote) => VoiceNoteActions.createSuccess({ voiceNote })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(voiceNoteId: number, changes: Partial<RR.VoiceNote>) {
    return this.service.update(voiceNoteId, changes).pipe(
      map((voiceNote) => VoiceNoteActions.updateSuccess({ voiceNote })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(voiceNoteId: number) {
    return this.service.delete(voiceNoteId).pipe(
      map(() => VoiceNoteActions.deleteSuccess({ voiceNoteId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addSuggestedNoteDividerStatementChoice(voiceNoteId: number, topicId: number, threshold: number) {
    return this.service.addSuggestedNoteDividerStatementChoice(voiceNoteId, topicId, threshold).pipe(
      // this.message.handleHttpErrorPipe,
      map(({ statement_choice, dividers }) => {
        const statementChoiceAction = statement_choice && mapChangeTrackerToSuccessActions(statement_choice);
        const tagChoiceActions =
          dividers &&
          dividers.map((divider) => {
            const tagChoiceAction = TagChoiceBatchActions.addSuccess({
              actions: {
                addSuccess: TagChoiceActions.addTagChoiceSuccess({ tag: divider.tag_choice }),
                findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
                  actions: {
                    findStatementsSuccess: StatementActions.addMany({ statements: [divider.statement] }),
                    findRegionsSuccess: RegionActions.addMany({
                      regions: divider.region ? [divider.region] : [],
                    }),
                  },
                }),
              },
            });
            return tagChoiceAction;
          });

        return { statementChoiceAction, tagChoiceActions };
      }),
      tap(({ statementChoiceAction, tagChoiceActions }) => {
        if (statementChoiceAction) {
          this.store.dispatch(statementChoiceAction);
        }
        if (tagChoiceActions) {
          tagChoiceActions.forEach((action) => this.store.dispatch(action));
        }
      }),
    );
  }

  getSuggestedVoiceStatements(reportId: number) {
    return this.service.getSuggestedVoiceStatements(reportId).pipe(
      map(
        ({
          auto_added_statements,
          sections,
          subsections,
          regions,
          elements,
          statement_sets,
          statements,
          dividers,
          text_objects,
          statement_coincidences,
        }) => {
          return VoiceNoteBatchActions.findSuggestedStatementsSuccess({
            actions: {
              auto_added_statements,
              findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                actions: {
                  sectionAddMany: SectionActions.addMany({ sections: sections }),
                  subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
                  regionAddMany: RegionActions.addMany({ regions: regions }),
                  elementAddMany: ElementActions.addMany({ elements: elements }),
                  statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                  statementAddMany: StatementActions.addMany({ statements: statements }),
                  dividerAddMany: StatementActions.addMany({ statements: dividers }),
                  textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                  statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                    statementCoincidences: statement_coincidences,
                  }),
                },
              }),
            },
          });
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
