import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AttributeSetHttpService {
  constructor(private http: HttpClient) {}

  findAll() {
    return this.http.get<{
      attribute_sets: RR.AttributeSet[];
      attribute_options: RR.AttributeOption[];
    }>('/api/attribute_set');
  }

  create(attributeSet: Partial<RR.AttributeSet>): Observable<RR.AttributeSet> {
    return this.http.post<RR.AttributeSet>('/api/attribute_set', attributeSet);
  }

  update(attributeSetId: number, changes: Partial<RR.AttributeSet>): Observable<RR.AttributeSet> {
    return this.http.put<RR.AttributeSet>(`/api/attribute_set/${attributeSetId}`, changes);
  }

  numericSort(attributeSetId: number): Observable<RR.AttributeSet> {
    return this.http.post<RR.AttributeSet>(`/api/attribute_set/${attributeSetId}/numeric_sort`, {});
  }

  alphabeticSort(attributeSetId: number): Observable<RR.AttributeSet> {
    return this.http.post<RR.AttributeSet>(`/api/attribute_set/${attributeSetId}/alphabetic_sort`, {});
  }
}
