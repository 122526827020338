import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { ToastActions } from 'app/store/toast/toast.action';
import { NgbAlertToast, Toast } from 'app/store/toast/toast.reducer';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * TODO: rename to ToastService
 */
@Injectable()
export class MessageService implements OnDestroy {
  subscription = new Subscription();
  toasts: Toast[];

  constructor(private store: Store<AppState>) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  add(message: Toast) {
    if (message.timeout === undefined) {
      message.timeout = 5000;
    }
    this.store.dispatch(ToastActions.add({ toast: message }));
  }

  private webargsValidationErrorMessage(error: HttpErrorResponse) {
    const errorBody: { json?: { [fieldName: string]: string[] } } = error.error;
    let message = '';

    if (errorBody.json) {
      message = Object.entries(errorBody.json)
        .map(([key, messages]) => {
          return `${key}: ${messages.join('\n')}`;
        })
        .join('\n');
    }

    return message;
  }

  private jsonErrorMessage(error: HttpErrorResponse) {
    const errorBody: { code: number; description: string; name: string } = error.error;
    return errorBody.description;
  }

  getErrorMessage(error: HttpErrorResponse) {
    if (error.status === 422) {
      return this.webargsValidationErrorMessage(error);
    } else {
      return this.jsonErrorMessage(error);
    }
  }

  /**
   * Usage:
   * ```ts
   * .subscribe({
   *   error: (error: unknown) => {
   *     if (error instanceof HttpErrorResponse) {
   *       this.messageService.httpErrorMessage(error);
   *     }
   *   },
   * })
   * ```
   */
  async httpErrorMessage(error: unknown | HttpErrorResponse, { title }: { title: string } = { title: 'Error' }) {
    if (error instanceof HttpErrorResponse) {
      let _error: HttpErrorResponse = error;
      if (_error.error instanceof Blob) {
        // Convert the Blob to JSON. Blobs are returned when `responseType: 'blob'` is used.
        _error = new HttpErrorResponse({
          error: JSON.parse(await error.error.text()),
        });
      }
      const toast: NgbAlertToast = {
        type: 'danger',
        title,
        message: '',
      };
      toast.message = this.getErrorMessage(_error);
      if (_error.status === 401) {
        // Only show one unauthorised toast even if many requests simultaneously fail
        toast.id = 'unauthorized';
      }
      this.add(toast);
    } else {
      throw error; // Reraise other _real_ errors. This function only handles HttpErrorResponse.
    }
  }

  /**
   * Handles http errors and displays a toast message. Application errors are reraised. Shields the outer
   * observable from errors.
   *
   * Usage:
   * ```ts
   * fromEvent(this.textInput.nativeElement, 'input').pipe(
   *   switchMap(() =>
   *     this.http.get(...).pipe(
   *       // Important to pipe off the http request, not the outer observable.
   *       this.messageService.handleHttpErrorPipe
   *     )
   *   ),
   * )
   * ```
   */
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  handleHttpErrorPipe = <T>(source$: Observable<T>) =>
    source$.pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          this.httpErrorMessage(error);
          // Swallows the error to shield the outer observer from the error
          return EMPTY;
        }
        throw error; // Reraise other _real_ errors. This function only handles HttpErrorResponse.
      }),
    );
}
