import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { AttributeOptionActions } from './attribute-option.action';

const adapter = createEntityAdapter<RR.AttributeOption>();

const initialState = adapter.getInitialState();

export const attributeOptionReducer = createReducer(
  initialState,
  on(AttributeOptionActions.findSuccess, (state, { attributeOption }) => adapter.upsertOne(attributeOption, state)),
  on(AttributeOptionActions.findAllSuccess, (state, { attributeOptions }) => adapter.setAll(attributeOptions, state)),
  on(AttributeOptionActions.createSuccess, (state, { attributeOption }) => adapter.addOne(attributeOption, state)),
  on(AttributeOptionActions.deleteSuccess, (state, { attributeOptionId }) =>
    adapter.removeOne(attributeOptionId, state),
  ),
  on(AttributeOptionActions.findManySuccess, (state, { attributeOptions }) =>
    adapter.upsertMany(attributeOptions, state),
  ),
  on(
    AttributeOptionActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
