import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InstitutionSearchFilterForm } from 'app/modules/entity-listing/components/institution-listing/institution-listing-search/institution-listing-search.component';
import { map, Observable } from 'rxjs';

export type ESInstitutionSearchResult = {
  institutions: RR.Institution[];
};

@Injectable()
export class InstitutionHttpService {
  constructor(private http: HttpClient) {}

  find(institutionId: number): Observable<RR.Institution> {
    return this.http.get<RR.Institution>(`/api/institution/${institutionId}`);
  }

  searchInstitutions(
    queryBody: InstitutionSearchFilterForm,
    params: HttpParams,
  ): Observable<{ count: number; institutions: RR.Institution[] }> {
    return this.http
      .post<{ count: number; institutions: RR.Institution[] }>('/api/institution/search', queryBody, {
        params,
      })
      .pipe(
        map((response) => {
          const count = response.count;
          const institutions = response.institutions;
          return { count, institutions };
        }),
      );
  }

  create(institution: Partial<RR.Institution>): Observable<RR.Institution> {
    return this.http.post<RR.Institution>('/api/institution', institution);
  }

  update(institutionId: number, changes: Partial<RR.Institution>): Observable<RR.Institution> {
    return this.http.put<RR.Institution>(`/api/institution/${institutionId}`, changes);
  }

  delete(institutionId: number) {
    return this.http.delete(`/api/institution/${institutionId}`);
  }

  elasticSearch(institution: Partial<RR.Institution>) {
    return this.http.post<ESInstitutionSearchResult>('/api/institution/elasticsearch', institution);
  }
}
