import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { RegionChoiceEffect } from 'app/store/report/region-choice';
import { SubsectionChoiceEffect } from 'app/store/report/subsection-choice';
import { Observable, Subscription, concat, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../../shared/directives/tooltip.directive';
import { ElementPreviewComponent } from '../element-preview/element-preview.component';
import { HeadingPreviewDeleteModalComponent } from '../heading-preview/heading-preview-delete-modal.component';

@Component({
  selector: 'rr-subsection-preview',
  templateUrl: './subsection-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipDirective, ElementPreviewComponent],
})
@LifecycleLogger
export class SubsectionPreviewComponent implements OnInit, OnDestroy {
  @Input() topic: RR.Topic;
  // @ts-expect-error noImplicitAny
  @Input() @BindObservable() subsection_choice_id;
  subsection_choice_id$: Observable<number>;
  subsection_choice: RR.SubsectionChoice | undefined;
  @Input() section_choice: RR.SectionChoice;
  @Input() mode: PreviewMode = 'editor';
  // @ts-expect-error noImplicitAny
  elements;
  region_choices$: Observable<RR.RegionChoice[]>;
  hasRegions: Observable<boolean>;
  subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
    private subsectionChoiceEffect: SubsectionChoiceEffect,
    private regionChoiceEffect: RegionChoiceEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.subsection_choice_id$
        .pipe(switchMap((id) => this.reportService.getSubsectionChoice(id)))
        .subscribe((subsection_choice) => {
          this.subsection_choice = subsection_choice;
          this.cd.markForCheck();
        }),
    );
    this.region_choices$ = this.subsection_choice_id$.pipe(
      switchMap((subsection_choice_id) => this.reportService.getRegionChoices(subsection_choice_id)),
      map((regionChoices) => regionChoices.filter((rc): rc is RR.RegionChoice => rc !== undefined)),
    );
    this.hasRegions = this.region_choices$.pipe(map((c) => c.length > 1));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  jumpToRegion(region_id: number) {
    this.editorService.publishFocus({
      region_id,
      subsection_id: this.subsection_choice?.subsection_id,
    });
  }

  async removeRegion(region_choice_id: number) {
    const modalRef = HeadingPreviewDeleteModalComponent.open(this.modal);
    modalRef.componentInstance.headingName = 'Region';
    try {
      await modalRef.result;
      this.subscription.add(
        this.region_choices$
          .pipe(
            take(1),
            switchMap((region_choices) => {
              const region_choice = region_choices.find((rc) => rc.id === region_choice_id);
              if (region_choice) {
                return this.regionChoiceEffect.delete(region_choice.id);
              }
              return of(null);
            }),
          )
          .subscribe(),
      );
    } catch {
      // do nothing on dismiss
    }
  }

  async onlyThisRegion(region_choice: RR.RegionChoice) {
    const modalRef = HeadingPreviewDeleteModalComponent.open(this.modal);
    modalRef.componentInstance.headingName = 'Regions';
    try {
      await modalRef.result;
      this.subscription.add(
        this.region_choices$
          .pipe(
            take(1),
            switchMap((region_choices) => {
              // concat sends the requests one after the other
              return concat(
                ...region_choices
                  .filter((rc) => rc !== region_choice)
                  .map((rc) => this.regionChoiceEffect.delete(rc.id)),
              );
            }),
          )
          .subscribe(),
      );
    } catch {
      // do nothing on dismiss
    }
  }

  focusSubsection() {
    this.editorService.publishFocus({ subsection_id: this.subsection_choice?.subsection_id });
  }

  async removeSubsection() {
    const modalRef = HeadingPreviewDeleteModalComponent.open(this.modal);
    modalRef.componentInstance.headingName = 'Subsection';
    try {
      await modalRef.result;
      // @ts-expect-error strictNullChecks
      this.subscription.add(this.subsectionChoiceEffect.delete(this.subsection_choice.id).subscribe());
    } catch {
      // do nothing on dismiss
    }
  }
}
