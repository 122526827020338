import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { ReferrerContactMethodActions } from '../referrer-contact-method/referrer-contact-method.action';
import { ReferrerNoteCategoryActions } from '../referrer-note-category/referrer-note-category.action';
import { ReferrerActions } from '../referrer/referrer.action';
import { ReferrerSearchResponse } from '../referrer/referrer.service';

/**
 * Ngrx Actions for ReferrerNote
 *
 */
export const ReferrerNoteActions = {
  searchSuccess: createAction('[ReferrerNote] Search Success', props<{ searchResult: ReferrerSearchResponse }>()),
  findManySuccess: createAction('[ReferrerNote] Find Many Success', props<{ referrer_notes: RR.ReferrerNote[] }>()),
  createSuccess: createAction('[ReferrerNote] Create Success', props<{ referrer_note: RR.ReferrerNote }>()),
  deleteSuccess: createAction('[ReferrerNote] Delete Success', props<{ referrer_note_id: number }>()),
  updateSuccess: createAction('[ReferrerNote] Update Success', props<{ referrer_note: RR.ReferrerNote }>()),
};

export const ReferrerNoteBatchActions = {
  createSuccess: createBatchAction(
    '[ReferrerNote] Create Success Batch',
    props<{
      actions: {
        createReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.createSuccess>;
        updateReferrerSuccess: ReturnType<typeof ReferrerActions.updateSuccess>;
      };
    }>(),
  ),
  deleteSuccess: createBatchAction(
    '[ReferrerNote] Delete Success Batch',
    props<{
      actions: {
        deleteReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.deleteSuccess>;
        updateReferrerSuccess: ReturnType<typeof ReferrerActions.updateSuccess>;
      };
    }>(),
  ),
  searchSuccess: createBatchAction(
    '[ReferrerNote] Search Success Batch',
    props<{
      actions: {
        searchReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.searchSuccess>;
        findManyReferrerSuccess: ReturnType<typeof ReferrerActions.findManySuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
        findManyReferrerNoteCategorySuccess: ReturnType<typeof ReferrerNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
};
