<div class="btn-group h-100 w-100">
  <button
    role="button"
    tabindex="-1"
    (click)="filterByLogisticRegression({ scroll: true })"
    class="btn btn-sm d-flex align-items-center justify-content-center rounded-0 px-1"
    [class.btn-warning]="filterType !== 'LOGISTIC_REGRESSION'"
    [class.btn-success]="filterType === 'LOGISTIC_REGRESSION'"
    [class.flash]="filterType === 'LOGISTIC_REGRESSION'"
    rrTooltip="SHORTEN_STATEMENT_LIST_WITH_LOGISTIC_REGRESSION"
  >
    {{ isLraLoading ? 'Loading...' : 'LRA ' + lraFilterPercentage + '%' }}
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="filterByAgeFrequency({ scroll: true, type: 'AGE_FREQUENCY' })"
    class="btn btn-sm d-flex align-items-center justify-content-center px-1"
    [class.btn-warning]="filterType !== 'AGE_FREQUENCY'"
    [class.btn-success]="filterType === 'AGE_FREQUENCY'"
    [class.flash]="filterType === 'AGE_FREQUENCY'"
    rrTooltip="SHORTEN_STATEMENT_LIST_WITH_AGE"
  >
    <i class="fas fa-filter"></i>Age{{ ageFilterPercentage }}%
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="changeFilterPercentage(10)"
    class="btn btn-warning btn-sm px-1"
    rrTooltip="INCREASE_TEN_PERCENT"
  >
    <i class="fas fa-plus"></i>
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="changeFilterPercentage(-10)"
    class="btn btn-warning btn-sm px-1"
    rrTooltip="DECREASE_TEN_PERCENT"
  >
    <i class="fas fa-minus"></i>
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="filterByAgeFrequency({ scroll: true, type: 'FREQUENCY' })"
    class="btn btn-sm d-flex align-items-center justify-content-center px-1"
    [class.btn-warning]="filterType !== 'FREQUENCY'"
    [class.btn-success]="filterType === 'FREQUENCY'"
    [class.flash]="filterType === 'FREQUENCY'"
    rrTooltip="SHORTEN_STATEMENT_LIST"
  >
    <i class="fas fa-filter"></i> {{ frequencyFilterPercentage }}%
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="onShowAllClicked()"
    class="btn btn-sm d-flex align-items-center justify-content-center px-1"
    [class.btn-warning]="filterType !== 'NONE'"
    [class.btn-success]="filterType === 'NONE'"
    rrTooltip="ELEMENT_SHOW_ALL_SENTENCES"
  >
    <i class="fas fa-list me-1"></i>All
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="onScrollClicked('END')"
    class="btn btn-sm btn-info d-flex align-items-center justify-content-center px-1"
    rrTooltip="ELEMENT_SCROLL_TO_BOTTOM"
  >
    <i class="fas fa-arrow-alt-circle-down me-1"></i>End
  </button>
  <button
    role="button"
    tabindex="-1"
    (click)="onScrollClicked('HOME')"
    class="btn btn-sm btn-info d-flex align-items-center justify-content-center rounded-0 px-1"
    rrTooltip="ELEMENT_SCROLL_TO_TOP"
  >
    <i class="fas fa-home me-1"></i>Home
  </button>
</div>
