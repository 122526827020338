import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { TodoActions } from './todo.action';

export type TodoState = EntityState<RR.Todo>;

const adapter = createEntityAdapter<RR.Todo>();

const initialState = adapter.getInitialState();

export const todoReducer = createReducer(
  initialState,
  on(TodoActions.findManySuccess, (state: TodoState, { todos }) => adapter.upsertMany(todos, state)),
  on(TodoActions.createSuccess, (state: TodoState, { todo }) => adapter.addOne(todo, state)),
  on(TodoActions.updateSuccess, (state: TodoState, { todo }) => adapter.upsertOne(todo, state)),
  on(TodoActions.deleteSuccess, (state: TodoState, { todoId }) => adapter.removeOne(todoId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
