import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { MachineActions } from './machine.action';

export type MachineState = EntityState<RR.Machine>;

const adapter = createEntityAdapter<RR.Machine>();

const initialState = adapter.getInitialState();

export const machineReducer = createReducer(
  initialState,
  on(MachineActions.findAllSuccess, (state: MachineState, { machines }) => adapter.setAll(machines, state)),
  on(MachineActions.createSuccess, (state: MachineState, { machine }) => adapter.addOne(machine, state)),
  on(MachineActions.updateSuccess, (state: MachineState, { machine }) => adapter.upsertOne(machine, state)),
  on(MachineActions.deleteSuccess, (state: MachineState, { machineId }) => adapter.removeOne(machineId, state)),
  on(MachineActions.updateUnavailableSuccess, (state, { machineId, unavailable }) => {
    const machine = state.entities[machineId];
    if (machine) {
      const updatedUnavailables = machine.unavailables?.map((u) =>
        u.id === unavailable.id ? { ...u, ...unavailable } : u,
      );
      const updatedMachine = { ...machine, unavailables: updatedUnavailables };
      return adapter.upsertOne(updatedMachine, state);
    }
    return state;
  }),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
