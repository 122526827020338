import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { UserSettingActions } from './user-setting.action';
import { UserSettingHttpService } from './user-setting.service';

@Injectable()
export class UserSettingEffect {
  constructor(
    private store: Store<AppState>,
    private service: UserSettingHttpService,
  ) {}

  findAll(params?: HttpParams) {
    return this.service.findAll(params).pipe(
      map((userSettings: RR.UserSetting[]) => UserSettingActions.findAllSuccess({ userSettings })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findByUser(user_id: number) {
    const params = new HttpParams().set('user_id', user_id);
    return this.findAll(params);
  }

  update(userSettingId: number, changes: Partial<RR.UserSetting>) {
    return this.service.update(userSettingId, changes).pipe(
      map((userSetting) => UserSettingActions.updateSuccess({ userSetting })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
