import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { TagSearchTermActions } from './tag-search-term.action';

export type TagSearchTermState = EntityState<RR.TagSearchTerm>;

const adapter = createEntityAdapter<RR.TagSearchTerm>();

const initialState = adapter.getInitialState();

export const tagSearchTermReducer = createReducer(
  initialState,
  on(TagSearchTermActions.findAllSuccess, (state: TagSearchTermState, { tagSearchTerms }) =>
    adapter.setAll(tagSearchTerms, state),
  ),
  on(TagSearchTermActions.createSuccess, (state: TagSearchTermState, { tagSearchTerm }) =>
    adapter.addOne(tagSearchTerm, state),
  ),
  on(TagSearchTermActions.updateSuccess, (state: TagSearchTermState, { tagSearchTerm }) =>
    adapter.upsertOne(tagSearchTerm, state),
  ),
  on(TagSearchTermActions.deleteSuccess, (state: TagSearchTermState, { tagSearchTermId }) =>
    adapter.removeOne(tagSearchTermId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
