<div class="container d-flex flex-column flex-grow-1">
  <button
    role="button"
    class="btn btn-outline-dark w-100 text-normal mb-2"
    *ngIf="template$ | async as template"
    rrTooltip="GO_BACK_TO"
    (click)="back()"
  >
    <strong>go back to</strong> <rr-template-headline class="ms-2" [template]="template"></rr-template-headline>
  </button>

  <ng-container *ngIf="scanCode$ | async as scanCode">
    <ng-container *ngIf="scanCodeTemplate$ | async as scanCodeTemplate">
      <h3>
        Suggested Template for <rr-scan-code-headline [scanCode]="scanCode"></rr-scan-code-headline>:
        <button [disabled]="saving" (click)="save(scanCodeTemplate.id)" role="button" class="btn btn-outline-dark">
          <rr-template-headline [template]="scanCodeTemplate"></rr-template-headline>
        </button>
      </h3>
    </ng-container>
  </ng-container>
  <rr-template-picker
    [disabled]="saving"
    (onSelect)="onESTemplateSelected($event)"
    *ngIf="!!report"
    class="d-flex flex-column flex-grow-1"
  >
  </rr-template-picker>
</div>
