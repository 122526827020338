import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NgbModal,
  NgbAccordionDirective,
  NgbAccordionItem,
  NgbAccordionHeader,
  NgbAccordionToggle,
  NgbAccordionButton,
  NgbCollapse,
  NgbAccordionCollapse,
  NgbAccordionBody,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { TooltipDirective } from 'app/shared/directives/tooltip.directive';
import { CreateNoteModalComponent } from 'app/shared/modals/create-note-modal/create-note-modal.component';
import { EditNoteModalComponent } from 'app/shared/modals/notes-modal/edit-note-modal/edit-note-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { referrerTypeMap } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { fromPatient, PatientEffect } from 'app/store/patient';
import { fromSendEvent } from 'app/store/report/send-event';
import { distinctUntilChanged, EMPTY, map, Observable, of, Subscription, switchMap } from 'rxjs';

import { NotesComponent } from '../../../../../shared/components/notes/notes.component';
import { ReportAccessionNumberComponent } from '../../../../../shared/components/report-accession-number/report-accession-number.component';
import { PatientFormViewComponent } from '../../../../registration/components/patient-form/patient-form-view/patient-form-view.component';
import { EReferralDataModalComponent } from '../../../modals/e-referral-data-modal/e-referral-data-modal.component';
import { BookingMetadataComponent } from '../booking-metadata/booking-metadata.component';
import { BookingPatientViewComponent } from '../booking-patient-view/booking-patient-view.component';

@Component({
  selector: 'rr-booking-view-summary',
  templateUrl: './booking-view-summary.component.html',
  styleUrls: ['./booking-view-summary.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    BookingMetadataComponent,
    BookingPatientViewComponent,
    PatientFormViewComponent,
    NotesComponent,
    TooltipDirective,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionToggle,
    NgbAccordionButton,
    NgbCollapse,
    NgbAccordionCollapse,
    NgbAccordionBody,
    ReportAccessionNumberComponent,
    SharedModule,
  ],
})
export class BookingViewSummaryComponent implements OnInit, OnDestroy {
  @Input() context: 'REGISTRATION' | 'BOOKING' = 'BOOKING';
  @Input() @BindObservable() bookingId: number | null;
  bookingId$: Observable<number | null>;

  booking$: Observable<RR.Booking | undefined>;

  patient$: Observable<RR.Patient | undefined>;
  referrer$: Observable<RR.Referrer | undefined>;
  reports$: Observable<RR.Report[]>;

  sendEvents: RR.SendEvent[] = [];

  referrerTypes = referrerTypeMap;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private patientEffect: PatientEffect,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.booking$ = this.bookingId$.pipe(
      filterDefined(),
      switchMap((id) => this.store.select(fromBooking.selectBooking(id))),
    );

    this.reports$ = this.bookingId$.pipe(
      filterDefined(),
      switchMap((bookingId) => this.store.select(fromBooking.selectReports(bookingId))),
    );

    // Fetch when selecting existing patient
    this.subscription.add(
      this.booking$
        .pipe(
          filterDefined(),
          map((booking) => booking.patient_id),
          distinctUntilChanged(),
          switchMap((patientId) => {
            if (patientId) {
              return this.patientEffect.findById(patientId);
            } else {
              return EMPTY;
            }
          }),
        )
        .subscribe(),
    );

    this.patient$ = this.booking$.pipe(
      switchMap((booking) => {
        if (booking && booking.patient_id) return this.store.select(fromPatient.selectPatient(booking.patient_id));
        return of(undefined);
      }),
    );

    this.subscription.add(
      this.bookingId$
        .pipe(
          filterDefined(),
          switchMap((id) => this.store.select(fromSendEvent.selectSendEventsInBooking(id))),
        )
        .subscribe((sendEvents) => {
          this.sendEvents = sendEvents;
        }),
    );
  }

  editNotes(bookingId: number) {
    EditNoteModalComponent.open(this.modalService, { bookingId });
  }

  openCreateNoteModal({ patientId }: { patientId?: number }) {
    CreateNoteModalComponent.open({
      modalService: this.modalService,
      patientId: patientId ?? undefined,
    });
  }

  openReferralViewModal(referralId: number) {
    EReferralDataModalComponent.open(this.modalService, referralId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
