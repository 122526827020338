import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PatientNoteCategoryActions } from './patient-note-category.action';

export type PatientNoteCategoryState = EntityState<RR.NoteCategory>;

const adapter = createEntityAdapter<RR.NoteCategory>();
const initialState = adapter.getInitialState();

export const patientNoteCategoryReducer = createReducer(
  initialState,
  on(PatientNoteCategoryActions.findManySuccess, (state: PatientNoteCategoryState, { patient_note_categories }) =>
    adapter.upsertMany(patient_note_categories, state),
  ),
  on(PatientNoteCategoryActions.createSuccess, (state: PatientNoteCategoryState, { patient_note_category }) =>
    adapter.addOne(patient_note_category, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
