import { createAction, props } from '@ngrx/store';

const findSuccess = createAction('[DoctorFollowupLink] Find Success', props<{ doctorFollowup: RR.DoctorFollowup }>());
const createSuccess = createAction(
  '[DoctorFollowupLink] Create Success',
  props<{ doctorFollowup: RR.DoctorFollowup }>(),
);
const deleteSuccess = createAction('[DoctorFollowupLink] Delete Success', props<{ doctorFollowupId: number }>());

export const DoctorFollowupActions = {
  findSuccess,
  createSuccess,
  deleteSuccess,
};
