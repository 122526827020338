<div class="flex row bg-light p-2 rounded">
  <div class="d-flex ps-0 align-items-center">
    <h2 class="d-flex mb-0">Voice Notes <span class="badge bg-success ms-2">Green</span></h2>
    <div class="d-flex align-items-center ms-2">
      <div class="d-flex align-items-center form-check form-switch me-3" rrTooltip="TOGGLE_VOICE_MODE">
        <input
          type="checkbox"
          class="form-check-input"
          id="voiceMode"
          [checked]="voiceMode"
          (change)="toggleVoiceMode()"
        />
        <label class="form-check-label ms-1" for="voiceMode" role="button"><small>Voice Mode</small></label>
      </div>
      <ng-container *ngrxLet="autoUpdate$ as autoUpdate">
        <div class="d-flex align-items-center form-check form-switch" rrTooltip="AUTO_UPDATE_TOGGLE">
          <input
            type="checkbox"
            class="form-check-input"
            id="autoUpdate"
            [checked]="autoUpdate"
            (change)="toggleAutoUpdate()"
          />
          <label class="form-check-label ms-1" for="autoUpdate" role="button">Auto Update Scores</label>
        </div>
        <button
          *ngIf="!autoUpdate"
          rrTooltip="UPDATE_MACHINE_LEARNING_SCORES"
          role="button"
          class="btn btn-sm btn-primary ms-2 align-self-end"
          (click)="manualUpdateScores()"
        >
          <i class="fas fa-sync-alt"></i> Update Scores
        </button>
      </ng-container>
    </div>
  </div>
  <div class="d-flex container my-2 p-0">
    <div class="w-50 border rounded p-2 me-2">
      <div class="d-flex justify-content-between align-items-center flex-nowrap mb-2">
        <div class="d-flex align-items-center">
          <h4 class="mb-0">General Voice Notes</h4>
          <span class="mx-1 badge bg-light text-dark border">
            {{ generalNotes.length }}
          </span>
          <rr-voice-recognition
            class="me-2"
            [reportId]="reportId"
            [source]="'VOICE_NOTES'"
            [excludeVoiceTerms]="true"
          ></rr-voice-recognition>
        </div>
        <div ngbDropdown class="d-inline-block" container="body">
          <button
            (click)="$event.stopPropagation()"
            type="button"
            class="btn btn-sm btn-outline-primary"
            ngbDropdownToggle
          >
            Help
          </button>
          <div ngbDropdownMenu class="dropdown-container">
            <ul class="me-2">
              <li>Click the microphone or text-area to auto-create notes from voice-input.</li>
            </ul>
          </div>
        </div>
      </div>

      <ng-container>
        <div>
          <form class="form-group d-flex mb-1">
            <textarea
              #voiceNoteGeneral
              rrVoice
              [source]="'GENERAL'"
              [speechSynthesis]="false"
              [keydownStopListening]="false"
              [focusOutStopListening]="false"
              [(ngModel)]="generalNoteText"
              class="form-control border-secondary"
              placeholder="Write a new general note..."
              (click)="triggerVoiceNoteFocus()"
              autocomplete="off"
              name="generalNoteText"
              rrAutoSize
              (keydown.enter)="$event.preventDefault(); addGeneralNote(generalNoteText, 'search_clipboard')"
              rrAbbr
            ></textarea>
            <button
              [disabled]="generalNoteText === ''"
              class="btn btn-outline-primary ms-2"
              (click)="addGeneralNote(generalNoteText, 'search_clipboard')"
            >
              Save (Enter)
            </button>
          </form>
        </div>
        <ul cdkDropList (cdkDropListDropped)="dropTagSearchTerm($event)" class="list-group mb-1">
          <ng-container *ngFor="let tagSearchTerm of tagSearchTerms">
            <li cdkDrag class="list-group-item list-group-item-success">
              <rr-tag-search-term
                [tagSearchTerm]="tagSearchTerm"
                (onSelectTagSearchTerm)="selectTemplateTagSearchTerm(tagSearchTerm, 'GENERAL')"
              ></rr-tag-search-term>
            </li>
          </ng-container>
        </ul>
        <ul class="list-group mb-1">
          <ng-container *ngFor="let note of tempNotes">
            <ng-container *ngIf="note.destination === 'GENERAL'">
              <li class="list-group-item list-group-item-secondary">
                <rr-temp-voice-note
                  [note]="note"
                  (onSelectTempNote)="copyTempNoteToSearch(note)"
                  (onDeleteTempNote)="deleteTempNote(note)"
                ></rr-temp-voice-note>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <button
          rrTooltip="VOICE_REPORT_CLEAR"
          class="btn btn-sm btn-outline-secondary me-2"
          (click)="clearAll(generalNotes)"
        >
          Clear All
        </button>
        <button
          rrTooltip="REMOVE_LAST_SUGGESTED"
          [disabled]="!lastAddedStatementId"
          class="btn btn-sm btn-outline-secondary"
          (click)="removeLastAddedStatement()"
        >
          Delete Last Recommended
        </button>
        <!-- text input to save auto add threshold defaulting to 50, with a minimum of 1 and maximum of 100 -->
        <div rrTooltip="VOICE_THRESHOLD" class="d-flex align-items-center mt-1 mb-1">
          <label class="me-2"><strong>Match Threshold (new notes & manual retries):</strong></label>
          <input
            type="number"
            class="form-control form-control-sm"
            [formControl]="autoAddThreshold"
            min="1"
            max="100"
            style="width: 80px"
          />
        </div>
        <div rrTooltip="VOICE_THRESHOLD" class="d-flex align-items-center mb-2">
          <label class="me-2"><strong>Fallback Threshold:</strong></label>
          <input
            type="number"
            class="form-control form-control-sm"
            [formControl]="retryThreshold"
            min="1"
            max="100"
            style="width: 80px"
          />
        </div>
        <ul class="list-group">
          <li class="list-group-item px-1" *ngFor="let note of generalNotes">
            <rr-voice-note-item
              [topicId]="topicId"
              [note]="note"
              (onSelectNote)="selectNote($event, 'GENERAL')"
              (onRetrySearch)="retryStatementSearch($event)"
            ></rr-voice-note-item>
          </li>
        </ul>

        <div class="mt-2" *ngIf="tagSuggested$ | async as suggestedTags">
          <ng-container *ngIf="topicId">
            <rr-voice-note-tags
              [type]="'GENERAL'"
              [tags]="suggestedTags"
              [topicId]="topicId"
              [topicTags]="topicTags"
              [filterTerm]="generalNoteFilterText"
              (onClearFilter)="clearFilter('GENERAL')"
            ></rr-voice-note-tags>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="w-50 border rounded p-2">
      <div class="d-flex justify-content-between align-items-center flex-nowrap mb-2" *ngIf="structuredNotes">
        <div class="d-flex align-items-center">
          <h4 class="mb-0">Structured Voice Notes</h4>
          <span class="mx-1 badge bg-light text-dark border">
            {{ (structuredNotes | structuredNoteLength) || 0 }}
          </span>
        </div>

        <div ngbDropdown class="d-inline-block" container="body">
          <button
            (click)="$event.stopPropagation()"
            type="button"
            class="btn btn-sm btn-outline-primary"
            ngbDropdownToggle
          >
            Help
          </button>
          <div ngbDropdownMenu class="dropdown-container">
            <ul class="me-2">
              <li>Click a subsection heading or text-area to toggle on/off auto-creation of notes from voice-input.</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="mb-2"
        [ngClass]="subsectionName === structuredNote.subsection ? 'p-2 border bg-light' : ''"
        *ngFor="let structuredNote of structuredNotes"
      >
        <div class="d-flex">
          <button
            [ngClass]="subsectionName === structuredNote.subsection ? 'btn-primary' : 'btn-outline-primary'"
            class="btn text-start w-100 mb-1"
            (click)="toggleSubsection(structuredNote.subsection)"
          >
            <div class="d-flex flex-nowrap">
              {{ structuredNote.subsection }}
              <div ngbDropdown>
                <button
                  (click)="$event.stopPropagation()"
                  ngbDropdownToggle
                  *ngIf="structuredNotes | structuredNoteLength: structuredNote.subsection ?? 'NO NAME' as noteLength"
                  class="mx-1 badge bg-light text-dark border"
                >
                  {{ noteLength }}
                </button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let structuredNote of structuredNote.notes">
                    <button (click)="copyToSearch(structuredNote.text)" ngbDropdownItem>
                      {{ structuredNote.text }}
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </button>
          <rr-voice-recognition
            class="ms-2"
            *ngIf="subsectionName === structuredNote.subsection"
            [reportId]="reportId"
            [source]="'STRUCTURED_NOTES'"
            [excludeVoiceTerms]="true"
          ></rr-voice-recognition>
        </div>
        <ng-container *ngIf="subsectionName === structuredNote.subsection">
          <form class="form-group d-flex my-1">
            <textarea
              rrAbbr
              rrVoice
              [source]="'STRUCTURED'"
              [speechSynthesis]="false"
              [keydownStopListening]="false"
              [focusOutStopListening]="false"
              [(ngModel)]="structuredNoteText"
              class="form-control border-secondary"
              placeholder="Write a new '{{ subsectionName }}' note..."
              name="structuredNoteText"
              autocomplete="off"
              rrAutoSize
              (keydown.enter)="$event.preventDefault(); addStructuredNote(structuredNoteText)"
            ></textarea>
            <button
              [disabled]="structuredNoteText === '' || !subsectionName"
              class="btn btn-outline-primary ms-2"
              (click)="addStructuredNote(structuredNoteText)"
            >
              Save (Enter)
            </button>
          </form>
          <ng-container *ngIf="subsectionNameParent$ | async as subsectionParent">
            <ng-container *ngIf="subsectionParent.name === subsectionName">
              <ul
                *ngIf="
                  subsectionParent.id
                    | storeSelectPipe: selectSubsectionTagSearchTerms
                    | async as subsectionTagSearchTerms
                "
                class="list-group mb-1"
                cdkDropList
                (cdkDropListDropped)="
                  dropTagSearchTermSubsection($event, subsectionParent.id, subsectionTagSearchTerms)
                "
              >
                <li
                  cdkDrag
                  *ngFor="let tagSearchTerm of subsectionTagSearchTerms"
                  class="list-group-item list-group-item-success"
                >
                  <rr-tag-search-term
                    [tagSearchTerm]="tagSearchTerm"
                    (onSelectTagSearchTerm)="
                      selectSubsectionTagSearchTerm(subsectionParent.id, tagSearchTerm, 'STRUCTURE')
                    "
                  ></rr-tag-search-term>
                </li>
              </ul>
            </ng-container>
          </ng-container>
          <ul class="list-group mb-1">
            <ng-container *ngFor="let note of tempNotes">
              <ng-container *ngIf="note.destination === 'STRUCTURED'">
                <li class="list-group-item list-group-item-secondary">
                  <rr-temp-voice-note
                    [note]="note"
                    (onSelectTempNote)="copyTempNoteToSearch(note)"
                    (onDeleteTempNote)="deleteTempNote(note)"
                  ></rr-temp-voice-note>
                </li>
              </ng-container>
            </ng-container>
          </ul>
          <ul class="list-group">
            <li class="list-group-item px-1" *ngFor="let note of structuredNote.notes">
              <rr-voice-note-item
                [topicId]="topicId"
                [subsections]="subsections"
                [note]="note"
                (onSelectNote)="selectNote($event, 'STRUCTURE')"
              ></rr-voice-note-item>
            </li>
          </ul>
        </ng-container>

        <div *ngIf="this.subsectionName === structuredNote.subsection">
          <ng-container *ngIf="groupedTags[structuredNote.subsection] && topicId; else noTags">
            <!-- Where there is only a single region, use a simple list -->
            <ng-container *ngIf="!regions?.length">
              <rr-voice-note-tags
                [type]="'STRUCTURED'"
                [tags]="groupedTags[structuredNote.subsection]['null']"
                [topicId]="topicId"
                [topicTags]="topicTags"
                [filterTerm]="structuredNoteFilterText"
                (onClearFilter)="clearFilter('STRUCTURE')"
              ></rr-voice-note-tags>
            </ng-container>
            <!-- With multiple regions we use the panels to display them together -->
            <ng-container *ngIf="regions && regions?.length">
              <ng-container *ngFor="let region of regions">
                <div (click)="setRegion(region.name)" class="p-2 d-flex align-items-center bg-light border fw-bold">
                  <i class="fa fa-registered me-1"></i> {{ region.name }}
                  <rr-tag-copy class="ms-auto" [region]="region" [regions]="regions" [topicId]="topicId"></rr-tag-copy>
                </div>
                <rr-voice-note-tags
                  *ngIf="region.name === activeRegion"
                  [type]="'STRUCTURED'"
                  [tags]="groupedTags[structuredNote.subsection][region.name]"
                  [topicId]="topicId"
                  [topicTags]="topicTags"
                  [filterTerm]="structuredNoteFilterText"
                  (onClearFilter)="clearFilter('STRUCTURE')"
                ></rr-voice-note-tags>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="tagsLoading">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading Groups...</span>
              </div>
            </div>
          </ng-container>
          <ng-template #noTags>
            <span class="m-1" *ngIf="!tagsLoading">No Groups...</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
