import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorService } from 'app/core/services/editor.service';
import { PermissionDirective } from 'app/shared/directives/permission.directive';
import { SharedModule } from 'app/shared/shared.module';

import { PresetFromReportComponent } from '../../../shared/components/preset/preset-from-report/preset-from-report.component';
import { PresetTitleComponent } from '../../../shared/components/preset/preset-title/preset-title.component';
import { NameFavouriteModalComponent } from '../prefill-button/name-favourite-modal.component';
import { PrefillService } from '../prefill/prefill.service';

@Component({
  selector: 'rr-editor-preset',
  standalone: true,
  imports: [PresetFromReportComponent, PresetTitleComponent, CommonModule, SharedModule, PermissionDirective],
  templateUrl: './editor-preset.component.html',
  styleUrl: './editor-preset.component.scss',
})
export class EditorPresetComponent {
  @Input() presetFromReport: RR.Report | undefined;
  @Input() report: RR.Report;
  @Input() topicId: number;

  constructor(
    private modalService: NgbModal,
    private prefillService: PrefillService,
    private editorService: EditorService,
  ) {}

  openPresetModal() {
    NameFavouriteModalComponent.open({ modalService: this.modalService, topic_id: this.topicId });
  }

  openPrefill(report: RR.Report) {
    this.prefillService.setPrefillPreviewTopics({
      openTopicId: this.topicId,
      topicIds: [report.topic_ids[0].toString()],
      forceReload: true,
    });
    this.editorService.togglePrefill(true);
  }
}
