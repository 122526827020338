import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromSession } from 'app/store/session';
import { combineLatest } from 'rxjs';
import { filter, map, share, skipWhile, switchMap } from 'rxjs/operators';

/**
 *  PIPEABLE OPERATOR for selecting store data
 *  const selectLoadedCurrentUser = () =>
 *  pipe(
 *    skipWhile((state: AppState) => !fromSession.selectLoaded(state)),
 *    select(fromSession.selectCurrentUser),
 *  );
 */

/**
 * A service for selecting store data follows observable selector pattern
 * The selector will wait for the data in the store to be loaded before returning it
 *
 * We can use the pipeable operator as above but any irrelevant data chaged in the store will trigger the selector to
 * execute again.
 *
 */
@Injectable()
export class SelectorService {
  constructor(private store: Store<AppState>) {}

  /**
   * Select current logged in user
   * @returns
   */
  selectLoadedCurrentUser() {
    return this.store.select(fromSession.selectLoaded).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromSession.selectCurrentUser)),
    );
  }

  selectCurrentTopicIfLoaded() {
    return combineLatest([
      this.store.select(fromCurrentTopic.selectLoaded),
      this.store.select(fromCurrentTopic.selectTopic),
    ]).pipe(
      filter(([loaded, _topic]) => !!loaded),
      map(([_loaded, topic]) => topic),
      share(),
    );
  }
}
