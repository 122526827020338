<ng-container *ngIf="invoiceItems$ | async as items">
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th [style.width.%]="10">Service date</th>
        <th [style.width.%]="10">Item code</th>
        <th [style.width.%]="35">Description</th>
        <th [style.width.%]="10">Amount expected</th>
        <th [style.width.%]="10">Amount paid</th>
        <th [style.width.%]="25">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <td>
          <strong>{{ item?.service_date | date: 'dd/MM/yyyy' }}</strong>
        </td>
        <td>
          <span>{{ item?.item_code }}</span>
        </td>
        <td>
          <span>{{ item?.description }}</span>
        </td>
        <td>
          <span
            [ngbTooltip]="amtExpected"
            #t="ngbTooltip"
            triggers="mouseenter:mouseleave"
            container="body"
            placement="bottom"
            >${{ item?.amount_expected }}</span
          >
        </td>
        <ng-template #amtExpected>
          <small>
            <strong>Original expected: </strong>
            ${{ item?.amount_expected_original }}
          </small>
        </ng-template>
        <td>
          <span *ngIf="item?.amount_paid">${{ item?.amount_paid }}</span>
        </td>
        <td>
          <div
            class="badge"
            [ngClass]="{
              'bg-success': item?.medipass_status === 'approved',
              'bg-secondary': !item?.medipass_status,
              'bg-danger': item?.medipass_status === 'rejected',
              'bg-warning': item?.medipass_status === 'pending' || item?.medipass_status === 'under-review'
            }"
          >
            <span *ngIf="item && item.medipass_status">{{ itemStatus[item.medipass_status] }}</span>
            <span *ngIf="!item?.medipass_status">None</span>
          </div>
          <div *ngIf="item?.medipass_client_message">{{ item?.medipass_client_message }}</div>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
