import { createEntityAdapter } from '@ngrx/entity';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.TitleOption>();
const selectFeature = (state: AppState) => state.title_option;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

export const fromTitleOption = {
  selectIds,
  selectEntities,
  selectAll,
};
