<div>
  <form class="mt-2" (ngSubmit)="checkPassword($event)">
    <div class="input-group w-50">
      <input
        type="password"
        name="password"
        class="form-control col-4"
        placeholder="Login Password"
        required
        ngbAutoFocus
      />
      <button class="btn btn-success" type="submit">Submit</button>
      <button class="btn btn-secondary" *ngIf="hasCancelButton" (click)="cancel()">Cancel</button>
    </div>
  </form>
  <div class="alert alert-danger col-12 mt-2" *ngIf="!!error">{{ error }}</div>
</div>
