import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { TemplateBatchActions } from 'app/store/template/template';

import { SuggestedStatement } from './voice-note.service';

/**
 * Ngrx Actions for VoiceNotes
 *
 */
export const VoiceNoteActions = {
  findManySuccess: createAction('[Voice Note] Find Many Success', props<{ voice_notes: RR.VoiceNote[] }>()),

  createSuccess: createAction('[Voice Note] Create Success', props<{ voiceNote: RR.VoiceNote }>()),

  updateSuccess: createAction('[Voice Note] Update Success', props<{ voiceNote: RR.VoiceNote }>()),

  deleteSuccess: createAction('[Voice Note] Delete Success', props<{ voiceNoteId: number }>()),
};

export const VoiceNoteBatchActions = {
  findSuggestedStatementsSuccess: createBatchAction(
    '[Voice Note] Find Suggested Statements Success Batch',
    props<{
      actions: {
        auto_added_statements: SuggestedStatement[];
        findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
      };
    }>(),
  ),
};

export type VoiceNoteCreateSuccessAction = ReturnType<typeof VoiceNoteActions.createSuccess>;
