import { CommonModule } from '@angular/common';
import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { medipassHelpers } from 'app/shared/medipass';
import { MedipassService } from 'app/shared/medipass/medipass.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromInvoice } from 'app/store/invoice';
import { fromInvoiceItem } from 'app/store/invoice-item/invoice-item.selector';
import { fromSession } from 'app/store/session';
import { formatISO } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    // For PermissionDirective
    SharedModule,
  ],
  selector: 'rr-invoice-medipass',
  templateUrl: './invoice-medipass.component.html',
  styleUrls: ['./invoice-medipass.component.css'],
})
export class InvoiceMedipassComponent implements OnInit, OnDestroy {
  @Input() invoice_id: number;
  invoice: RR.Invoice;
  invoiceItems: RR.InvoiceItem[] = [];

  subscription: Subscription = new Subscription();
  medipassEnv$: Observable<rrConfigType['MEDIPASS_ENV']>;
  MEDIPASS_WEBHOOK_URL: string | null;

  testServiceText: string | undefined = undefined;
  constructor(
    private store: Store<AppState>,
    private zone: NgZone,
    private messageService: MessageService,
    private medipassService: MedipassService,
  ) {}

  ngOnInit(): void {
    this.medipassEnv$ = this.store.select(fromSession.selectRRConfig).pipe(
      filterDefined(),
      map((rrConfig) => rrConfig.MEDIPASS_ENV),
    );
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.medipassEnv$.pipe(take(1)).subscribe((env) => {
      if (env === 'stg') {
        // Change if you want to test the claim status. Might remove after finish testing
        this.testServiceText = 'STUB_OK';
      }
    });

    this.store
      .select(fromSession.selectRRConfig)
      .pipe(
        filterDefined(),
        map((rrConfig) => rrConfig.MEDIPASS_WEBHOOK_URL),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((MEDIPASS_WEBHOOK_URL) => {
        this.MEDIPASS_WEBHOOK_URL = MEDIPASS_WEBHOOK_URL;
      });

    const invoice$ = this.store.select(fromInvoice.selectInvoice(this.invoice_id)).pipe(filterDefined());

    this.subscription.add(
      invoice$.subscribe((i) => {
        this.invoice = i;
      }),
    );
    this.subscription.add(
      invoice$
        .pipe(switchMap((inv) => this.store.select(fromInvoiceItem.selectInInvoice(inv.id))))
        .subscribe((items) => {
          this.invoiceItems = items.filter((i): i is RR.InvoiceItem => !!i);
        }),
    );
  }

  claimableItems(): MedipassTransactionOptions['claimableItems'] {
    return this.invoiceItems.map((item) => ({
      itemCode: item.item_code,
      serviceDateString: item.service_date ?? '',
      price: `${item.amount_expected}`,
      isGross: true,
      quantity: 1,
      isTaxable: true,
      // serviceText: this.testServiceText,
      reference: `${item.id}`,
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClickBillMedicare() {
    this.openMedipassLightbox();
  }

  async openMedipassLightbox() {
    const claimableItems: MedipassTransactionOptions['claimableItems'] = this.claimableItems();

    const referral: MedipassTransactionOptions['funderData']['medicare']['referral'] = {
      providerNumber: this.invoice.referrer_number ?? '',
      providerName: this.invoice.referrer_name ?? '',
      referrerType: this.invoice.referrer_type ?? 'gp',
      issueDateString: this.invoice.ref_date ?? '',
      period: this.invoice.ref_period ?? 'not-set',
      // Set to Diagnostic
      referralTypeCode: 'D',
    };
    const options: MedipassTransactionOptions = {
      providerNumber: this.invoice.service_provider_number ?? '',
      invoiceReference: `${this.invoice.id}`,
      patient: {
        refId: this.invoice.patient_number ?? '',
        firstName: this.invoice.patient_first_name ?? '',
        lastName: this.invoice.patient_last_name ?? '',
        mobile: this.invoice.patient_mobile ?? '',
        dob:
          (this.invoice.patient_dob && formatISO(new Date(this.invoice.patient_dob), { representation: 'date' })) ?? '',
        sex: (this.invoice.patient_sex && medipassHelpers.getTransformSex(this.invoice.patient_sex)) ?? '',
        accountNumber: this.invoice.patient_medicare_number ?? '',
        reference: this.invoice.patient_medicare_reference_number?.toString() ?? '',
        acceptedDisabilityIndicator: 'N',
      },
      claimableItems,
      funder: 'medicare',
      funderData: {
        medicare: {
          isBulkBilled: true,
          lspn: this.invoice.site_lspn ?? '',
          isInHospital: false,
          referral,
        },
        dva: {
          referral,
        },
      },
      version: '3',
      webhooks: [],
    };
    if (this.MEDIPASS_WEBHOOK_URL) {
      options.webhooks?.push({
        url: `${this.MEDIPASS_WEBHOOK_URL}/api/medipass/transactions/${this.invoice_id}/events`,
        event: 'invoiceCreated,healthFundApprovedInvoice,healthFundRejectedInvoice,healthFundPaidInvoice',
        method: 'POST',
        headers: {},
      });
    } else {
      console.warn('MEDIPASS_WEBHOOK_URL not configured');
    }
    const sdk = await this.medipassService.sdk();
    sdk.renderCreateTransaction(options, {
      disableModifyServiceItems: true,
      onSuccess: (transaction) => {
        this.zone.run(() => {
          // handle successful submission of transaction
          this.messageService.add({
            type: 'success',
            title: 'Success',
            message: 'Status: ' + `${transaction.status}`,
            timeout: 3000,
          });
        });
      },
      onError: (error) => {
        this.zone.run(() => {
          this.messageService.add({ type: 'danger', title: 'Error', message: `${error.message}`, timeout: 3000 });
          console.warn(error.message);
        });
      },
      onCancel: () => {
        this.zone.run(() => {
          this.messageService.add({
            type: 'warning',
            title: 'Warning',
            message: 'Transaction cancelled',
            timeout: 3000,
          });
        });
      },
    });
  }
}
