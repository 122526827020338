import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { BookingRateActions } from '../../booking-rate/booking-rate.action';
import { SignatureActions } from '../../signature/signature.action';
import { SiteActions } from '../../site/site.action';

/**
 * Ngrx Actions for User
 *
 */
export const UserActions = {
  findByIdSuccess: createAction('[User] Find By Id Success', props<{ user: RR.User }>()),

  findAllSuccess: createAction('[User] Find All Success', props<{ users: RR.User[] }>()),

  upsertOne: createAction('[User] Update Success', props<{ user: RR.User }>()),

  upsertMany: createAction('[User] Find Many Success', props<{ users: RR.User[] }>()),
};

export const UserBatchActions = {
  findSuccessBatchAction: createBatchAction(
    '[User] Find Success Batch',
    props<{
      actions: {
        user: ReturnType<typeof UserActions.findByIdSuccess>;
        bookingRates: ReturnType<typeof BookingRateActions.upsertMany>;
      };
    }>(),
  ),

  findAllSuccessBatchAction: createBatchAction(
    '[User] Find All Success Batch',
    props<{
      actions: {
        userFindAllSuccessAction: ReturnType<typeof UserActions.findAllSuccess>;
        bookingRates: ReturnType<typeof BookingRateActions.upsertMany>;
      };
    }>(),
  ),

  findByRolesSuccess: createBatchAction(
    '[User] Find By Role Success Batch',
    props<{
      actions: {
        userFindManySuccess: ReturnType<typeof UserActions.upsertMany>;
        signatureUpdateManySuccessAction: ReturnType<typeof SignatureActions.updateManySuccess>;
        siteUpdateManySuccessAction: ReturnType<typeof SiteActions.upsertMany>;
      };
    }>(),
  ),
};
