import { props, createActionGroup, emptyProps } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { RegionSetActions } from '../region-set/region-set.action';

/**
 * Methods of interacting with the Regions
 *
 * This describes the actions required to interface with the regions, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the regions related to a specific template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of regions returned, while the
 * `getTemplateSuccess` being a subset of all regions will only update the
 * values without deleting them all first.
 */
export const RegionActions = createActionGroup({
  source: 'Region',
  events: {
    addMany: props<{ regions: RR.Region[] }>(),
    addOne: props<{ region: RR.Region }>(),
    upsertOne: props<{ region: RR.Region }>(),
    removeOne: props<{ regionId: number }>(),
    reset: emptyProps(),
  },
});

export const RegionBatchActions = {
  createSuccessBatchAction: createBatchAction(
    '[Region] Create Success Batch',
    props<{
      actions: {
        regionCreateSuccessAction: ReturnType<typeof RegionActions.addOne>;
        regionSetUpdateSuccessAction: ReturnType<typeof RegionSetActions.updateSuccess>;
      };
    }>(),
  ),
};
