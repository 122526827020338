import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rr-measurement-rule',
  templateUrl: 'measurement-rule.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class MeasurementRuleComponent {
  @Input() measurement_rule: RR.MeasurementRule;
}
