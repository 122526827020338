<div class="modal-header">
  <h5 class="modal-title">Add your justification</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group mt-3">
    <textarea
      ngbAutoFocus
      placeholder="This sentence was omitted because..."
      class="form-control textarea"
      [formControl]="justificationText"
    ></textarea>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="sendJustification()">Enter</button>
</div>
