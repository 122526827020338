import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import {
  fromPatientQuestionAnswer,
  PatientQuestionAnswerEffect,
} from 'app/store/questionnaire/patient-question-answer';
import { fromReport, PatientQuestionResponse, ReportHttpService } from 'app/store/report/report';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, skipWhile, switchMap, take } from 'rxjs/operators';

import { QuestionnaireComponent } from './questionnaire/questionnaire.component';

@Component({
  selector: 'rr-patient-questionnaires',
  templateUrl: './patient-questionnaires.component.html',
  styleUrls: ['./patient-questionnaires.component.css'],
  standalone: true,
  imports: [CommonModule, QuestionnaireComponent],
})
export class PatientQuestionnairesComponent implements OnInit, OnDestroy {
  @Input() report_id: number;
  // Optional scan_code_id to reload questions and answers when booking template changed
  @Input() @BindObservable() scan_code_id?: number;
  scan_code_id$: Observable<number>;

  // Optional patient_id to reload questions and answers when report's patient changed
  @Input() @BindObservable() patient_id?: number;
  patient_id$: Observable<number>;

  // Readonly mode when showing patient history on modal in editor
  @Input() viewonly = false;
  patientQuestionSets: RR.PatientQuestionSet[] = [];
  patientQuestions: RR.PatientQuestion[] = [];
  // Active parent question ids in each question set. Not all questions in a set are available for a template
  activeParentQuestionsInQuestionSet: { [id: number]: number[] } = {};
  patientQuestionMap: { [id: number]: RR.PatientQuestion } = {};
  patientAnswers: RR.PatientQuestionAnswer[] = [];
  patientAnswerMap: { [patient_question_id: number]: RR.PatientQuestionAnswer } = {};
  subscription: Subscription = new Subscription();
  constructor(
    private reportHttpService: ReportHttpService,
    private patientQuestionAnswerEffect: PatientQuestionAnswerEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    // No booking id and patient_id -> init patient questionnaire from kios mode or modal
    if (!this.scan_code_id && !this.patient_id) {
      this.loadReportQuestionsAndAnswers();
    }

    // Detect if booking template for report has been changed to reload questions again
    const scanCodeChange$ = this.scan_code_id$.pipe(
      filter((id) => !!id),
      distinctUntilChanged(),
    );
    const patientChange$ = this.patient_id$.pipe(
      filter((id) => !!id),
      distinctUntilChanged(),
    );

    this.subscription.add(
      combineLatest([scanCodeChange$, patientChange$]).subscribe(() => {
        this.loadReportQuestionsAndAnswers();
      }),
    );

    // Report specific question answers
    const reportQuestionAnswer$ = this.store.select(fromPatientQuestionAnswer.selectLoaded(this.report_id)).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromReport.selectPatientAnswers(this.report_id))),
    );

    // Patient specific question answers
    const patientQuestionAnswer$ = this.store.select(fromPatientQuestionAnswer.selectLoaded(this.report_id)).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromReport.selectReport(this.report_id))),
      filter((r) => !!r),
      switchMap((report) =>
        report?.patient_id ? this.store.select(fromPatient.selectPatientAnswers(report.patient_id)) : of(undefined),
      ),
    );

    // Combine report specific question answer and patient specific question answer
    this.subscription.add(
      combineLatest([reportQuestionAnswer$, patientQuestionAnswer$.pipe(filterDefined())]).subscribe(
        ([reportAnswers, patientAnswers]) => {
          // @ts-expect-error strictNullChecks
          this.patientAnswers = [...reportAnswers, ...patientAnswers].filter((ans) => !!ans);
          this.patientAnswerMap = this.patientAnswers.reduce((map, answer) => {
            // @ts-expect-error noImplicitAny
            map[answer.patient_question_id] = answer;
            return map;
          }, {});
        },
      ),
    );
  }

  loadReportQuestionsAndAnswers() {
    // Fetch report patient questions (include report specific questions and patient specific questions)
    this.reportHttpService
      .findPatientQuestions(this.report_id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((data: PatientQuestionResponse) => {
        this.patientQuestionSets = data.patient_question_sets;
        this.patientQuestions = data.patient_questions;
        this.patientQuestionMap = this.patientQuestions.reduce((map, question) => {
          // @ts-expect-error noImplicitAny
          map[question.id] = question;
          return map;
        }, {});

        // Filter out nested questions and questions that are in question set but not available in template
        this.activeParentQuestionsInQuestionSet = this.patientQuestionSets.reduce((map, questionSet) => {
          // @ts-expect-error noImplicitAny
          map[questionSet.id] = questionSet.patient_questions.filter(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            (qid) => !!this.patientQuestionMap[qid] && !this.patientQuestionMap[qid].parent_question_id,
          );
          return map;
        }, {});
      });

    // Fetch answers for the report
    this.subscription.add(this.patientQuestionAnswerEffect.findInReport(this.report_id).subscribe());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
