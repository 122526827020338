import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { NoticeActions } from './notice.action';
import { fromNotice } from './notice.selector';
import { NoticeHttpService } from './notice.service';

@Injectable()
export class NoticeEffect {
  constructor(
    private store: Store<AppState>,
    private service: NoticeHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromNotice.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      this.message.handleHttpErrorPipe,
      map((notices) => {
        return NoticeActions.findAllSuccess({ notices });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(notice: Partial<RR.Notice>) {
    return this.service.create(notice).pipe(
      map((notice: RR.Notice) => NoticeActions.createSuccess({ notice })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(noticeId: number) {
    return this.service.delete(noticeId).pipe(
      map(() => NoticeActions.deleteSuccess({ noticeId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
