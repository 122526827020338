import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterHistoryService } from 'app/core/services/router-history.service';
import { BookingEnquiryBadgeComponent } from 'app/modules/booking/components/booking-enquiry-badge/booking-enquiry-badge.component';
import { FollowupTaskBadgeComponent } from 'app/modules/followup-task/components/followup-task-badge/followup-task-badge.component';
import { ReferralEnquiryBadgeComponent } from 'app/modules/registration/components/referral-enquiry-badge/referral-enquiry-badge.component';
import { DynamicLinkDirective } from 'app/shared/directives/dynamic-link.directive';
import { NoticeModalComponent } from 'app/shared/modals/notice-modal/notice-modal.component';
import { NotificationsModalComponent } from 'app/shared/modals/notifications-modal/notifications-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { Subscription, filter } from 'rxjs';

import { NavLinkComponent } from './nav-link/nav-link.component';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'rr-navigation',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgbDropdownModule,
    RouterModule,
    DynamicLinkDirective,
    FollowupTaskBadgeComponent,
    BookingEnquiryBadgeComponent,
    ReferralEnquiryBadgeComponent,
    NavLinkComponent,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  subscription = new Subscription();
  open: boolean = false;

  constructor(
    private navigationService: NavigationService,
    public routerHistoryService: RouterHistoryService,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      this.navigationService.open$.subscribe((open) => {
        this.open = open;
      }),
    );

    // Close on route change
    this.subscription.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
        this.close();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.navigationService.close();
  }

  openNoticeModal() {
    this.close();
    NoticeModalComponent.open(this.modalService);
  }

  openNotificationModal() {
    this.close();
    NotificationsModalComponent.open(this.modalService);
  }
}
