import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined, trackById } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { fromTopicChoices } from 'app/store/report/topic';
import { Observable, Subscription, switchMap } from 'rxjs';

import { IndexElementComponent } from './index-element.component';

@Component({
  selector: 'rr-index-region',
  standalone: true,
  imports: [CommonModule, IndexElementComponent],
  templateUrl: './index-region.component.html',
  styleUrls: ['./index-region.component.css'],
})
export class IndexRegionComponent implements OnInit, OnDestroy {
  @Input() region: RR.Region;
  @Input() section: RR.Section;
  @Input() subsection: RR.Subsection;
  @Input() elements$: Observable<RR.Element[]>;

  subscription = new Subscription();
  trackBy = trackById;

  regionChoice: RR.RegionChoice | undefined;

  constructor(
    private editorService: EditorService,
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {}

  ngOnInit(): void {
    const topic$ = this.selectorService.selectCurrentTopicIfLoaded().pipe(filterDefined());

    // Select RegionChoices to decided if the Region is active or not
    this.subscription.add(
      topic$
        .pipe(
          switchMap((topic) => {
            return this.store.select(
              fromTopicChoices.selectRegionChoices(topic.id, this.section.id, this.subsection.id, this.region.id),
            );
          }),
        )
        .subscribe((regionChoices) => {
          this.regionChoice = regionChoices[0];
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  jumpToRegion(region_id: number) {
    this.editorService.publishFocus({ subsection_id: this.subsection.id, region_id });
  }
}
