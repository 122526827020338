import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { DoctorFollowupActions } from './doctor-followup.action';

export type DoctorFollowupState = EntityState<RR.DoctorFollowup>;

const adapter = createEntityAdapter<RR.DoctorFollowup>();

const initialState = adapter.getInitialState();

export const doctorFollowupReducer = createReducer(
  initialState,
  on(DoctorFollowupActions.createSuccess, (state: DoctorFollowupState, { doctorFollowup }) =>
    adapter.addOne(doctorFollowup, state),
  ),
  on(DoctorFollowupActions.deleteSuccess, (state: DoctorFollowupState, { doctorFollowupId }) =>
    adapter.removeOne(doctorFollowupId, state),
  ),
  on(DoctorFollowupActions.findSuccess, (state: DoctorFollowupState, { doctorFollowup }) =>
    adapter.upsertOne(doctorFollowup, state),
  ),
);
