import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { Subscription } from 'rxjs';

import { TopicComponent } from '../topic/topic.component';

@Component({
  selector: 'rr-report-edit-pane',
  templateUrl: './edit-pane.component.html',
  styleUrls: ['./edit-pane.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TopicComponent],
})
@LifecycleLogger
export class EditPaneComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @Input() openTopic: RR.Topic;
  subscription = new Subscription();

  prefill: boolean;

  constructor(
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.editorService.prefill.subscribe((prefill) => {
        this.prefill = prefill;
        this.cd.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
