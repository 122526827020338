import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { EditorActions } from '../../editor/editor.action';
import { ReportAccessEventActions } from './access-event.action';
import { ReportAccessEventHttpService } from './access-event.service';

@Injectable()
export class ReportAccessEventEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReportAccessEventHttpService,
  ) {}

  create(accessEvent: Partial<RR.ReportAccessEvent>) {
    return this.service.create(accessEvent).pipe(
      map((accessEvent: RR.ReportAccessEvent) => ReportAccessEventActions.createSuccess({ accessEvent })),
      tap((action) => {
        this.store.dispatch(EditorActions.saveLastReportAccessEvent({ reportAccessEvent: action.accessEvent }));
        this.store.dispatch(action);
      }),
    );
  }

  update(id: number, changes: Partial<RR.ReportAccessEvent>, params?: HttpParams) {
    // @ts-expect-error strictNullChecks
    return this.service.update(id, changes, params).pipe(
      map((accessEvent: RR.ReportAccessEvent) => ReportAccessEventActions.updateSuccess({ accessEvent })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInReport(reportId: number) {
    return this.service.findInReport(reportId).pipe(
      map((accessEvents: RR.ReportAccessEvent[]) => ReportAccessEventActions.findManySuccess({ accessEvents })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
