import { CommonModule } from '@angular/common';
import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'rr-title-case-input',
  templateUrl: './title-case-input.component.html',
  styleUrls: ['./title-case-input.component.scss'],
})
export class TitleCaseInputComponent implements ControlValueAccessor {
  @Input() disableInput = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  public data: string | undefined;

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onChangeFn = (_: string | undefined) => {
    // do nothing
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onTouchedFn = () => {
    // do nothing
  };

  // This will will write the value to the view if the the value changes occur on the model programmatically
  writeValue(value: string) {
    this.data = value;
  }

  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  // When the element is touched, this method will get called
  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
  }

  onChange() {
    const newValue = capitaliseFirstLetter(this.data);
    this.data = newValue;
    this.onChangeFn(newValue);
  }
}

export function capitaliseFirstLetter(value: string | undefined) {
  if (value && value.trim().length !== 0) {
    const arr: string[] = value.trim().split('');
    arr[0] = arr[0].toUpperCase();
    return arr.join('');
  } else {
    return value;
  }
}
