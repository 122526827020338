import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { InstitutionSearchFilterForm } from 'app/modules/entity-listing/components/institution-listing/institution-listing-search/institution-listing-search.component';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { InstitutionActions } from './institution.action';
import { InstitutionHttpService } from './institution.service';

@Injectable()
export class InstitutionEffect {
  constructor(
    private store: Store<AppState>,
    private service: InstitutionHttpService,
    private message: MessageService,
  ) {}

  find(institutionId: number) {
    return this.service.find(institutionId).pipe(
      this.message.handleHttpErrorPipe,
      map((institution: RR.Institution) => InstitutionActions.findSuccess({ institution })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: InstitutionSearchFilterForm, params: HttpParams) {
    return this.service.searchInstitutions(queryBody, params).pipe(
      this.message.handleHttpErrorPipe,
      map(({ count, institutions }) => InstitutionActions.searchSuccess({ count, institutions })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(institution: Partial<RR.Institution>) {
    return this.service.create(institution).pipe(
      this.message.handleHttpErrorPipe,
      map((institution: RR.Institution) => InstitutionActions.createSuccess({ institution })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(institutionId: number, changes: Partial<RR.Institution>) {
    return this.service.update(institutionId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((institution) => InstitutionActions.updateSuccess({ institution })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(institution: RR.Institution) {
    return this.service.delete(institution.id).pipe(
      this.message.handleHttpErrorPipe,
      map(() => InstitutionActions.deleteSuccess({ institution })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
