import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.RegionChoice>();
const selectFeature = (state: AppState) => state.region_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectRegionChoice = (regionChoiceId: number) =>
  createSelector(selectEntities, (regionChoices: Dictionary<RR.RegionChoice>) => regionChoices[regionChoiceId]);

export const fromRegionChoice = {
  selectEntities,
  selectAll,
  selectIds,
  selectRegionChoice,
};
