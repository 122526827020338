import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from 'app/core/services/template.service';
import { Subscription } from 'rxjs';

import { StatementComponent } from './statement.component';

@Component({
  templateUrl: './statement-delete-modal.component.html',
  standalone: true,
  imports: [forwardRef(() => StatementComponent), CommonModule],
})
export class StatementDeleteModalComponent implements OnInit, OnDestroy {
  @Input() statement: RR.Statement;
  topic: RR.Topic | undefined;
  affectedTemplates: RR.Template[];
  showTemplates: boolean;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
  ) {}

  ngOnInit() {
    if (this.statement.statement_set_id) {
      this.subscription.add(
        this.templateService.findStatementSetUsage(this.statement.statement_set_id).subscribe((affectedTemplates) => {
          this.affectedTemplates = affectedTemplates;
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
