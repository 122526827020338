import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, strToNum } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { fromCurrentTopic } from 'app/store/report/topic';
import { BehaviorSubject, Subscription, distinctUntilChanged, map, switchMap, take } from 'rxjs';

import { QuickNavModalComponent } from './quick-nav-modal/quick-nav-modal.component';
import { SidebarTitleModalComponent } from './sidebar/sidebar-title-modal/sidebar-title-modal.component';

type Step = {
  id: 'initials' | 'title' | 'mandatory-statements' | 'navigator' | 'done';
  complete: boolean;
};

/**
 * Service to manage what to do next at certain steps in the editor. For example, when the Navigator modal is closed.
 */
@Injectable({
  providedIn: 'root',
})
export class StepService implements OnDestroy {
  // TODO: track current step in the DB?
  // currentStep: Step = initialsStep;

  subscription = new Subscription();
  topic: RR.Topic | undefined;
  report: RR.Report | undefined; // initialised in EditorComponent

  initialsStep: Step = { id: 'initials', complete: false };
  titleStep: Step = { id: 'title', complete: false };
  mandatoryStatementsStep: Step = { id: 'mandatory-statements', complete: false }; // TODO(mandatory-statements)
  navigatorStep: Step = { id: 'navigator', complete: false };
  doneStep: Step = { id: 'done', complete: false };
  steps = [
    this.initialsStep,
    // First define a report title, important because title is used to determine the "side" in statements. Also, check
    // report warns if the side is wrong.
    this.titleStep,
    // this.mandatoryStatementsStep,
    // Choose base Statements from: Mandatory Statements, Defaults, Prefill, or Favourites
    // Tech signoff
    // DICOM-SR Measurements
    this.navigatorStep,
    this.doneStep,
  ];

  nag$ = new BehaviorSubject<Step['id']>('initials');

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private injector: Injector,
    private route: ActivatedRoute,
  ) {
    const report$ = this.route.params.pipe(map((params) => strToNum(params.report_id))).pipe(
      switchMap((reportId) => this.store.select(fromReport.selectReport(reportId))),
      filterDefined(),
    );
    const distinctReport$ = report$.pipe(distinctUntilChanged((a, b) => a.id === b.id));
    const topic$ = this.store.select(fromCurrentTopic.selectTopic);
    this.subscription.add(
      report$.subscribe((report) => {
        this.report = report;
      }),
    );
    this.subscription.add(
      topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );
    this.subscription.add(
      topic$
        .pipe(
          filterDefined(),
          distinctUntilChanged((a, b) => a.id === b.id),
        )
        .subscribe((topic) => {
          // On switching topics the title needs to be completed again.
          this.titleStep.complete = !!topic.title_option_text;
          this.nag();
        }),
    );

    this.subscription.add(
      distinctReport$.subscribe(() => {
        // Reset steps on new report back to incomplete
        this.steps.forEach((step) => {
          step.complete = false;
        });
        this.nag$ = new BehaviorSubject<Step['id']>('initials');
      }),
    );

    this.subscription.add(
      distinctReport$.pipe(switchMap(() => this.nag$)).subscribe((stepId) => {
        if (window['__e2e__'] !== undefined && ['title', 'navigator'].includes(stepId)) {
          // Don't auto-open show the title or quick nav modal in e2e test
          // this.completeStep(stepId)
          return;
        }
        if (stepId === 'title') {
          if (this.topic?.title_option_text) {
            // If initials are set too quickly, the topic hasn't loaded yet. So we need to check again here.
            this.completeStep('title');
            this.nag();
            return;
          }
          const modalRef = SidebarTitleModalComponent.open(this.modalService, {
            injector: this.injector,
          });

          modalRef.result
            .then(() => {
              this.nag();
            })
            .catch(() => {
              /* do nothing on dismiss */
              this.nag();
            });
        } else if (stepId === 'navigator' && this.report && this.topic) {
          this.completeStep('navigator'); // nothing to do here other than open it
          const modalRef = QuickNavModalComponent.open(this.modalService, this.report.id, this.topic.id);
          modalRef.result
            .catch(() => {
              /* do nothing on dismiss */
            })
            .finally(() => {
              this.nag();
            });
          // TODO(mandatory-statements)
          // } else if (stepId === 'mandatory-statements') {
          //   this.completeStep('mandatory-statements');
          //   this.nag();
        } else if (stepId === 'initials') {
          // handled in EditorComponent
        }
      }),
    );
  }

  completeStep(stepId: Step['id']) {
    const step = this.steps.find((s) => s.id === stepId);
    if (step === undefined) {
      throw new Error(`Step ${stepId} does not exist`);
    }
    step.complete = true;
  }

  /**
   * Nag the user to complete the next step. Usually called when the user closes a modal. If the step hasn't been
   * completed, the modal reopens.
   */
  nag() {
    const incompleteStep = this.steps.find((s) => s.complete === false);
    if (!incompleteStep) {
      return;
    }
    const topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined(), take(1));
    if (incompleteStep.id === this.titleStep.id) {
      // Wait for loaded topic before opening SidebarTitleModal
      this.subscription.add(
        topic$.pipe(take(1)).subscribe(() => {
          this.nag$.next(this.titleStep.id);
        }),
      );
    } else if (incompleteStep.id === this.navigatorStep.id && this.report && this.topic) {
      // Wait for loaded topic before opening QuickNavModal
      this.subscription.add(
        topic$.pipe(take(1)).subscribe(() => {
          this.nag$.next(this.navigatorStep.id);
        }),
      );
    } else if (incompleteStep.id === 'mandatory-statements') {
      this.nag$.next(this.mandatoryStatementsStep.id);
    } else if (incompleteStep.id === 'initials') {
      this.nag$.next(this.initialsStep.id);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
