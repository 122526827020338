<input
  #p="ngbPopover"
  type="text"
  class="form-control"
  [placeholder]="placeholder"
  [formControl]="billingItemSearch"
  [ngbPopover]="billingItemContent"
  popoverClass="billing-item-popover"
  triggers="manual"
  placement="bottom-right"
  ngbTooltip="Hint: Enter for first item"
  [autoClose]="'outside'"
  (keyup)="onSearchTextChanged(billingItemSearch.value); p.open()"
  (keydown.enter)="selectBillingItem(filteredBillingItems[0])"
  (focus)="p.open()"
  [rrAutoFocus]="autoFocus"
/>

<ng-template #billingItemContent>
  <strong class="text-danger">Click outside to close</strong>

  <div class="list-group mb-2" *ngIf="report && report.topic_ids.length > 0">
    <strong>Topic titles:</strong>
    <ng-container *ngFor="let topic_id of report.topic_ids">
      <li class="list-group-item">
        <rr-topic-title-option-text [topic_id]="topic_id"></rr-topic-title-option-text>
      </li>
    </ng-container>
  </div>

  <ng-container *ngrxLet="scanCode$ as scanCode">
    <ng-container *ngrxLet="suggestedBillingItems$ as scanCodeBillingItems">
      <div *ngIf="scanCode && scanCodeBillingItems && scanCodeBillingItems.length > 0">
        <span class="badge bg-info p-1 mb-1">
          Suggested billing items for <rr-scan-code-headline [scanCode]="scanCode"></rr-scan-code-headline>
        </span>
        <ul class="list-group">
          <li
            role="button"
            class="list-group-item list-group-item-action"
            *ngFor="let billingItem of scanCodeBillingItems"
            (click)="selectBillingItem(billingItem)"
          >
            <rr-billing-item [billing_item]="billingItem"></rr-billing-item>
          </li>
        </ul>
      </div>
    </ng-container>
  </ng-container>

  <div class="badge bg-info p-1 mb-1 mt-2">
    All billing items filtered by <strong>"{{ billingItemSearch.value }}"</strong>
  </div>
  <div class="d-flex" *ngIf="filteredBillingItems.length > 0; else noBillingItems">
    <ul class="list-group">
      <li
        role="button"
        class="list-group-item list-group-item-action"
        *ngFor="let billingItem of filteredBillingItems"
        (click)="selectBillingItem(billingItem)"
      >
        <rr-billing-item [billing_item]="billingItem"></rr-billing-item>
      </li>
    </ul>
  </div>
  <ng-template #noBillingItems>
    <h3 class="m-0 mx-2 text-danger">No billing items</h3>
  </ng-template>
</ng-template>
