import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { TitleOptionActions } from './title-option.action';
import { TitleOptionHttpService } from './title-option.service';

@Injectable()
export class TitleOptionEffect {
  constructor(
    private store: Store<AppState>,
    private service: TitleOptionHttpService,
  ) {}

  deleteTitles(titleIds: number[]) {
    return this.service.deleteTitles(titleIds).pipe(
      map(() => TitleOptionActions.deleteManySuccess({ titleIds })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
