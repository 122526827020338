import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { TopicState } from 'app/store/editor';
import { fromExactMatchTopic } from 'app/store/exact-match-topic/exact-match-topic.selector';
import { PresetSearchResponse } from 'app/store/report/preset';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  Observable,
  Subscription,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';

import { PrefillSearchMetadataComponent } from '../prefill-search-metadata/prefill-search-metadata.component';
import { PrefillSearchReportSentencesComponent } from '../prefill-search-report-sentences/prefill-search-report-sentences.component';
import { PrefillService } from '../prefill.service';
import { ESResult, PrefillSelectTopicType, PrefillSourceType } from '../prefill.types';

@Component({
  selector: 'rr-prefill-search-container',
  templateUrl: './prefill-search-container.component.html',
  styleUrls: ['./prefill-search-container.component.scss'],
  standalone: true,
  imports: [PrefillSearchMetadataComponent, PrefillSearchReportSentencesComponent, CommonModule],
})
export class PrefillSearchContainerComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  @Input() report_id: number;

  showExactMatch$: BehaviorSubject<boolean>;
  prefillExpanded$: Observable<boolean>;

  metadataResults: ESResult | null;
  exactMatchResults: RR.ExactMatchTopic[] | null;
  presets: RR.Report[] | null;

  /*
   * Selection source rules:
   *  - Default to select from metadata result
   *  - If user's setting is show exact match by default: auto switch to combine
   *  - Metadata search input changed and trigger new search:
   *    + If source type is COMBINE => keep COMBINE
   *    + If source type is EXACT_MATCH => switch to METADATA
   *  - Exact match search input changed, or show/hide exact match
   *    + Show exact match, or search input changed: keep COMBINE or switch to EXACT_MATCH if current source is METADATA
   *    + Hide exact match: set source type to METADATA
   */
  selectionSourceType: PrefillSourceType = 'METADATA';

  subscription: Subscription = new Subscription();

  // Skip the first search result from metadata and exact_match in case there is prior study selected
  skipFirstResult = {
    metadata: false,
    exact_match: false,
    preset: true,
  };

  isPrefillPreviewLoading: boolean;

  constructor(
    private prefillService: PrefillService,
    private editorService: EditorService,
    private store: Store<AppState>,
  ) {
    this.showExactMatch$ = this.prefillService.exactMatchOpen$;
    this.prefillExpanded$ = this.editorService.prefill.pipe(filter((p) => p));
  }

  ngOnInit(): void {
    this.subscription.add(
      this.prefillService.changePrefillSource$.subscribe((s) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (s) {
          this.selectionSourceType = s;
        }
      }),
    );

    // Subscribe to add more topic to prefill preview object
    this.subscription.add(
      this.prefillService.selectPrefillTopics$.subscribe((data: { type: PrefillSelectTopicType }) => {
        if (data.type !== 'ONE_MORE') {
          if (data.type === 'COMBINE') {
            this.selectionSourceType = 'COMBINE';
          } else if (data.type === 'TOP6_METADATA') {
            this.selectionSourceType = 'METADATA';
          } else {
            this.selectionSourceType = 'EXACT_MATCH';
          }
          // Select prefill topic from new source type
          this.selectPrefillTopics();
        } else {
          // Select one more row from current source type
          this.selectOneMoreTopic();
        }
      }),
    );

    // When first open prefill and it already had prefill topics (prefill prior topics),
    // not auto select from metadata and exact match search
    this.subscription.add(
      this.prefillExpanded$
        .pipe(
          filter((expanded) => expanded),
          take(1),
          switchMap(() => this.prefillService.getPrefillPreviewObject(this.topic_id)),
        )
        .pipe(take(1), withLatestFrom(this.showExactMatch$))
        .subscribe(([previewObj, exact_match_open]) => {
          if (previewObj.topicIds.length) {
            this.skipFirstResult = {
              metadata: true,
              exact_match: exact_match_open,
              preset: true,
            };
          }
        }),
    );

    const exactMatchResults$ = this.store.select(fromExactMatchTopic.selectAll);

    this.subscription.add(
      combineLatest([exactMatchResults$, this.prefillService.showExactMatchResults$]).subscribe(([results, show]) => {
        if (show) {
          this.onExactMatchResultsChanged(results);
        }
      }),
    );

    this.subscription.add(
      this.prefillService.exactMatchOpen$.subscribe((open) => {
        if (!open) {
          this.prefillService.showExactMatchResults$.next(false);
        }
      }),
    );

    this.subscription.add(
      this.prefillService.prefillPreviewLoading$.subscribe((loading) => {
        this.isPrefillPreviewLoading = loading;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPresetResultsChanged(results: PresetSearchResponse) {
    this.presets = results.reports;

    if (!this.skipFirstResult.preset) {
      this.selectPrefillTopics();
    } else {
      this.skipFirstResult.preset = false;
    }
  }

  onMetadataResultsChanged(results: ESResult | null) {
    this.metadataResults = results;

    // Metadata search was triggered, switch to METADATA source if the source is EXACT_MATCH
    if (this.selectionSourceType === 'EXACT_MATCH') {
      this.selectionSourceType = 'METADATA';
    }

    if (!this.skipFirstResult.metadata) {
      this.selectPrefillTopics();
    } else {
      this.skipFirstResult.metadata = false;
    }
  }

  onExactMatchResultsChanged(results: RR.ExactMatchTopic[] | null) {
    this.exactMatchResults = results;

    // Exact match search was triggered, switch to EXACT_MATCH source if the source is METADATA
    if (this.selectionSourceType === 'METADATA') {
      this.selectionSourceType = 'EXACT_MATCH';
    }

    this.selectPrefillTopics();
  }

  selectPrefillTopics() {
    // Checking the loading state ensures that the API is not triggered twice,
    // because prefill topics are already being loaded in the editor.

    if (this.isPrefillPreviewLoading) return;

    if (this.selectionSourceType === 'COMBINE') {
      // Combine top 8 from exact match, top 8 from metadata and top 8 from presets
      const hits = this.metadataResults?.hits.hits || [];

      const es_topic_ids: string[] = hits.slice(0, 8).map((hit: any) => this.getTopicIdFromHit(hit));

      const exact_match_topic_ids = (this.exactMatchResults || []).slice(0, 8).map((t) => t.topic_id.toString());

      const presetTopicIds = [
        ...new Set((this.presets || []).flatMap((preset) => preset.topic_ids.map((id) => id.toString()))),
      ].slice(0, 8);

      const selected_topic_ids = new Set([...es_topic_ids, ...exact_match_topic_ids, ...presetTopicIds]);
      this.prefillService.setPrefillPreviewTopics({
        openTopicId: this.topic_id,
        topicIds: [...selected_topic_ids],
      });
      return;
    } else if (this.selectionSourceType === 'METADATA') {
      // Top 6 results from metadata
      const hits = this.metadataResults?.hits.hits || [];
      const topic_ids = hits.slice(0, 6).map((hit) => this.getTopicIdFromHit(hit));
      this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: topic_ids });
    } else {
      // Top 6 result from exact match
      const topic_ids = (this.exactMatchResults || []).slice(0, 6).map((t) => t.topic_id.toString());
      this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: topic_ids });
    }
  }

  selectOneMoreTopic() {
    this.subscription.add(
      this.prefillService
        .getPrefillPreviewObject(this.topic_id)
        .pipe(take(1))
        .subscribe((previewObj) => {
          let addedTopicIds: string[] = [];
          if (this.selectionSourceType === 'METADATA') {
            // One more from metadata
            const id = this.getNextTopicIdFromResults('METADATA', previewObj);
            if (id) addedTopicIds = [id];
          } else if (this.selectionSourceType === 'EXACT_MATCH') {
            const id = this.getNextTopicIdFromResults('EXACT_MATCH', previewObj);
            if (id) addedTopicIds = [id];
          } else {
            // Combine: 1 more from metadata and one more from exact match
            const metadataId = this.getNextTopicIdFromResults('METADATA', previewObj);
            const exactMatchId = this.getNextTopicIdFromResults('EXACT_MATCH', previewObj);
            if (metadataId) addedTopicIds.push(metadataId);
            if (exactMatchId) addedTopicIds.push(exactMatchId);
          }
          if (addedTopicIds.length) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
            if (!previewObj || !previewObj.topicIds) {
              this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: addedTopicIds });
            } else {
              this.prefillService.setPrefillPreviewTopics({
                openTopicId: this.topic_id,
                topicIds: [...previewObj.topicIds, ...addedTopicIds],
              });
            }
          }
        }),
    );
  }

  getNextTopicIdFromResults(from: 'METADATA' | 'EXACT_MATCH', currentPreviewObj: TopicState['prefillPreview']) {
    if (from === 'METADATA') {
      // One more from metadata
      const hits = this.metadataResults?.hits.hits || [];
      if (!hits.length) return null;
      // PreviewObj might be null/undefined if store value has not been initialised yet
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
      const hit = hits.find((h) => !(currentPreviewObj.topicIds || []).includes(this.getTopicIdFromHit(h)));
      return hit ? this.getTopicIdFromHit(hit) : null;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (from === 'EXACT_MATCH') {
      const exactMatchTopics = this.exactMatchResults || [];
      if (!exactMatchTopics.length) return null;
      const topic = this.exactMatchResults?.find(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        (t: any) => !(currentPreviewObj.topicIds || []).includes(t.topic_id.toString()),
      );
      return topic ? topic.topic_id.toString() : null;
    }
    return null;
  }

  getTopicIdFromHit(hit: RR.ESTopic) {
    return hit._id;
  }
}
