import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';

@Injectable()
export class RegionChoiceHttpService {
  constructor(private http: HttpClient) {}

  create(topic_id: number, subsection_id: number, region_id: number) {
    return this.http.post<ChangeTrackerResponse>('/api/region_choice', {
      region_id,
      topic_id,
      subsection_id,
    });
  }

  delete(regionChoiceId: number) {
    return this.http.delete<ChangeTrackerResponse>(`/api/region_choice/${regionChoiceId}`);
  }
}
