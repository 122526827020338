import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromAttributeSet } from 'app/store/template/attribute-set';
import { fromDefaultAttribute } from 'app/store/template/default-attribute';
import { Observable, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-attribute-placeholder',
  templateUrl: './attribute-placeholder.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributePlaceholderComponent implements OnInit {
  @Input() text_object: RR.TextObjectSet;
  @Input() template_id: number;
  @Input() @BindObservable() region: RR.Region | undefined;
  region$: Observable<RR.Region | undefined>;
  @Input() proposed?: RR.ProposedStatement['proposed'];

  defaultAttribute$: Observable<RR.AttributeOption | undefined>;
  attributeSet$: Observable<RR.AttributeSet | undefined>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.defaultAttribute$ = this.region$.pipe(
      switchMap((region) => {
        return this.store.select(
          fromDefaultAttribute.selectOptionByChoice(this.template_id, this.text_object.id, region?.id || null),
        );
      }),
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.attributeSet$ = this.store.select(fromAttributeSet.selectAttributeSet(this.text_object.attribute_set_id!));
    // TODO(template-structure): Check whether fallbacks are needed. There were previously fallbacks for when te default attribute was not found, however we have also made the default attribute generation more robust and cover a wider gamut of attributes, so it may no longer be needed.
  }
}
