<form [formGroup]="feedbackForm" class="bootstrap-validate">
  <div class="mb-3">
    <h5 class="fw-bold">Feedback Notes:</h5>
    <div class="mb-1">
      <rr-voice-recognition [source]="'GIVE_FEEDBACK'" [excludeVoiceTerms]="true"></rr-voice-recognition>
    </div>
    <textarea
      rows="5"
      class="col-12 form-control"
      formControlName="note"
      rrVoice
      [speechSynthesis]="true"
      [keydownStopListening]="false"
      [focusOutStopListening]="false"
    ></textarea>
    <button class="mt-1 btn btn-success" (click)="submit()" [disabled]="!selectedUserId" ngbAutoFocus>Send</button>
  </div>

  <div class="mb-3">
    <h5 class="fw-bold">
      Rate
      <span class="text-info"
        ><rr-user-headline
          *ngIf="recipientId | selectPipe: userSelectorFn | async as user"
          [user]="user"
        ></rr-user-headline
      ></span>
      for this report:
    </h5>
    <ngb-rating formControlName="rate" class="text-warning h1" [max]="5"> </ngb-rating>
  </div>
</form>
