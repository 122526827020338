import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

import { PaymentFormGroup } from '../invoice-form/invoice-form-edit/invoice-form-edit.component';

const paymentTypes = [
  { id: 'eftpos', name: 'EFTPOS' },
  { id: 'cash', name: 'Cash' },
  { id: 'credit_card', name: 'Credit card' },
  { id: 'medicare', name: 'Medicare' },
] as const;

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'rr-invoice-payment-form',
  templateUrl: './invoice-payment-form.component.html',
  styleUrls: ['./invoice-payment-form.component.css'],
})
export class InvoicePaymentFormComponent implements OnDestroy {
  @Input() paymentFormGroup: PaymentFormGroup;
  @Output() onRemovePayment = new EventEmitter<PaymentFormGroup>();
  paymentTypes = paymentTypes;
  subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  removePayment() {
    this.onRemovePayment.emit(this.paymentFormGroup);
  }
}
