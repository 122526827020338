import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { InvoiceActions } from '../invoice/invoice.action';
import { PatientActions } from '../patient/patient.action';
import { AuditEventActions } from '../report/audit-event/audit-event.action';
import { ReportActions } from '../report/report/report.action';
import { SendEventActions } from '../report/send-event/send-event.action';
import { UserActions } from '../user/user/user.action';

/**
 * Ngrx Actions for Booking
 *
 */
export const BookingActions = {
  findManySuccess: createAction('[Booking] Find Many Success', props<{ bookings: RR.Booking[] }>()),

  findSuccess: createAction('[Booking] Find Success', props<{ booking: RR.Booking }>()),

  createSuccess: createAction('[Booking] Create Success', props<{ booking: RR.Booking }>()),

  updateSuccess: createAction('[Booking] Update Success', props<{ booking: RR.Booking }>()),

  updateBookingPatientSuccess: createAction(
    '[Booking] Update Booking Patient Success',
    props<{ bookingId: number; bookingPatient: RR.BookingPatient }>(),
  ),
};

export const BookingBatchActions = {
  searchSuccess: createBatchAction(
    '[Booking] Search Success Batch',
    props<{
      count: number;
      actions: {
        bookingFindManySuccess: ReturnType<typeof BookingActions.findManySuccess>;
        findManySendEventsSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
        auditEventFindManySuccess: ReturnType<typeof AuditEventActions.findManySuccess>;
        userFindManySuccess: ReturnType<typeof UserActions.upsertMany>;
        reportFindManySuccess: ReturnType<typeof ReportActions.upsertMany>;
        patientFindManySuccess: ReturnType<typeof PatientActions.findManySuccess>;
        findInvoiceSuccess: ReturnType<typeof InvoiceActions.findManySuccess>;
      };
    }>(),
  ),
  searchSameDaySuccess: createBatchAction(
    '[Booking] Search Same Day Success Batch',
    props<{
      actions: {
        bookingFindManySuccess: ReturnType<typeof BookingActions.findManySuccess>;
        reportFindManySuccess: ReturnType<typeof ReportActions.upsertMany>;
      };
    }>(),
  ),
  findSlotsSuccess: createBatchAction(
    '[Booking] Find Slots Success Batch',
    props<{
      slots: RR.SlotGroup[];
      actions: {
        userFindManySuccess: ReturnType<typeof UserActions.upsertMany>;
      };
    }>(),
  ),
  findSuccess: createBatchAction(
    '[Booking] Find Success Batch',
    props<{
      actions: {
        findBookingSuccess: ReturnType<typeof BookingActions.findSuccess>;
        findManySendEventsSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
        reportFindManySuccess: ReturnType<typeof ReportActions.upsertMany>;
      };
    }>(),
  ),
  sendConfirmationSMSSuccess: createBatchAction(
    '[Booking] Send Confirmation SMS Success Batch',
    props<{
      actions: {
        updateBookingSuccess: ReturnType<typeof BookingActions.updateSuccess>;
        createSendEventSuccess: ReturnType<typeof SendEventActions.sendSuccess>;
      };
    }>(),
  ),
  sendPreparationSMSSuccess: createBatchAction(
    '[Booking] Send Preparation SMS Success Batch',
    props<{
      actions: {
        updateBookingSuccess: ReturnType<typeof BookingActions.updateSuccess>;
        createSendEventSuccess: ReturnType<typeof SendEventActions.sendSuccess>;
      };
    }>(),
  ),
};
