import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  funderMap,
  invoiceStatusMap,
  medipassClaimStatusMap,
  medipassTransactionStatusMap,
  paymentTypeMap,
} from 'app/modules/invoice/invoice.constants';
import { AppState } from 'app/store';
import { fromInvoice } from 'app/store/invoice';
import { fromInvoiceItem } from 'app/store/invoice-item/invoice-item.selector';
import { Observable } from 'rxjs';

import { InvoiceItemTableComponent } from '../../components/invoice-item-table/invoice-item-table.component';

@Component({
  standalone: true,
  imports: [CommonModule, InvoiceItemTableComponent],
  selector: 'rr-view-invoice-modal',
  templateUrl: './view-invoice-modal.component.html',
  styleUrls: ['./view-invoice-modal.component.css'],
})
export class ViewInvoiceModalComponent implements OnInit {
  invoiceId: number;

  invoice$: Observable<RR.Invoice | undefined>;
  invoiceItems$: Observable<(RR.InvoiceItem | undefined)[]>;
  funders = funderMap;
  status = invoiceStatusMap;
  medipassTransactionStatus = medipassTransactionStatusMap;
  medipassClaimStatus = medipassClaimStatusMap;
  paymentType = paymentTypeMap;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.invoice$ = this.store.select(fromInvoice.selectInvoice(this.invoiceId));
    this.invoiceItems$ = this.store.select(fromInvoiceItem.selectInInvoice(this.invoiceId));
  }

  viewPdf() {
    const url = new URL(`/api/invoice/${this.invoiceId}/render`, location.origin);
    // TODO: make it a link <a href=...>
    // eslint-disable-next-line no-restricted-properties
    window.open(url.toString());
  }

  static open(modal: NgbModal, invoiceId: number) {
    const modalRef = modal.open(ViewInvoiceModalComponent, {
      size: 'xl',
    });
    const component: ViewInvoiceModalComponent = modalRef.componentInstance;
    component.invoiceId = invoiceId;

    return modalRef;
  }
}
