import { createAction, props } from '@ngrx/store';

import { AddedTopic } from '../report/report/report.service';

/**
 * Ngrx Actions for Notice
 *
 */
export const AddedTopicActions = {
  findAllSuccess: createAction('[AddedTopics] Find All Success', props<{ added_topics: AddedTopic[] }>()),
  updateManySuccess: createAction('[AddedTopics] Update Many Success', props<{ added_topics: AddedTopic[] }>()),
  removeAllSuccess: createAction('[AddedTopics] Remove All Success'),
  removeOneSuccess: createAction('[AddedTopics] Remove One Success', props<{ otherImagingTopicId: number }>()),
};
