import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SelectorService } from 'app/core/services/selector.service';
import { DatePickerComponent } from 'app/shared/components/date-picker/date-picker.component';
import { UserSelectorComponent } from 'app/shared/components/user-selector/user-selector.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { ReportAccessEventEffect } from 'app/store/report/access-event';
import { FeedbackEffect, fromFeedback } from 'app/store/report/feedback';
import { fromUser, UserEffect } from 'app/store/user/user';
import { formatISO } from 'date-fns';
import { combineLatest, finalize, forkJoin, map, of, startWith, Subject, Subscription, switchMap, take } from 'rxjs';

import { FeedbackHeadlineComponent } from '../feedback-headline/feedback-headline.component';
import { UserFeedbackDetailComponent } from '../user-feedback-detail/user-feedback-detail.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    UserFeedbackDetailComponent,
    FeedbackHeadlineComponent,
    DatePickerComponent,
    UserSelectorComponent,
  ],
  selector: 'rr-user-feedback-list',
  templateUrl: './user-feedback-list.component.html',
  styleUrls: ['./user-feedback-list.component.css'],
})
export class UserFeedbackListComponent implements OnInit, OnDestroy {
  @Input() reportId: number | undefined;
  subscription = new Subscription();

  feedbackIdList: number[] = [];

  loggedInUser: RR.User;
  currentFeedback: RR.Feedback | null;
  currentPage = 1;
  filter: 'ALL' | 'UNREAD' = 'ALL';
  feedbackCount = 0;
  feedbackSearching = false;
  PAGE_SIZE = 15;

  fromDate: Date | undefined;
  toDate: Date | undefined;

  user: RR.User;

  filterType = new FormControl<'SENDER' | 'RECIPIENT' | 'REPORT'>('SENDER', { nonNullable: true });
  userSelection$ = new Subject<number | undefined>();

  constructor(
    private selectorService: SelectorService,
    private accessEventEffect: ReportAccessEventEffect,
    private feedbackEffect: FeedbackEffect,
    private userEffect: UserEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromUser.selectAllUserLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.userEffect.findAll();
            }
            return of(null);
          }),
        )
        .subscribe(),
    );
    this.subscription.add(this.userEffect.findAll().subscribe());
    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(filterDefined(), take(1))
        .subscribe((user) => {
          this.loggedInUser = user;
          this.searchFeedbacks();
        }),
    );

    this.subscription.add(
      combineLatest([
        this.filterType.valueChanges.pipe(startWith(this.filterType.value)),
        this.userSelection$.asObservable().pipe(startWith(undefined)),
      ]).subscribe(([filterType, selectedUserId]) => {
        if (selectedUserId !== undefined) {
          this.searchFeedbacks(selectedUserId, filterType);
        } else {
          this.searchFeedbacks();
        }
      }),
    );
  }

  searchFeedbacks(selectedUserId?: number, filterType?: string) {
    this.feedbackSearching = true;
    this.subscription.add(
      this.feedbackEffect
        .search(
          {
            current_user_id: this.loggedInUser.id,
            checked: this.filter,
            from_date: this.fromDate ? formatISO(this.fromDate, { representation: 'date' }) : null,
            to_date: this.toDate ? formatISO(this.toDate, { representation: 'date' }) : null,
          },
          this.getFilterParams(this.currentPage),
        )
        .pipe(
          finalize(() => {
            this.feedbackSearching = false;
          }),
          switchMap((action) => {
            // If selectedUserId is provided, filter the feedbackIds based on the selectedUserId
            if (selectedUserId) {
              return forkJoin(
                action.feedbackIds.map((id) => this.store.select(this.feedbackSelectorFn(id)).pipe(take(1))),
              ).pipe(
                map((feedbacks) => {
                  return feedbacks.filter((feedback) => {
                    if (filterType === 'SENDER') {
                      return feedback !== undefined && feedback.sender_id === selectedUserId;
                    } else if (filterType === 'RECIPIENT') {
                      return feedback !== undefined && feedback.recipient_id === selectedUserId;
                    } else {
                      return feedback !== undefined && feedback.report_id === this.reportId;
                    }
                  });
                }),
                map((filteredFeedbacks) => ({
                  feedbackIds: filteredFeedbacks
                    .filter((feedback): feedback is RR.Feedback => feedback !== undefined)
                    .map((feedback) => feedback.id),
                  count: filteredFeedbacks.length,
                })),
              );
            } else {
              return of({ feedbackIds: action.feedbackIds, count: action.count });
            }
          }),
        )
        .subscribe(({ feedbackIds, count }) => {
          this.feedbackIdList = feedbackIds;
          this.feedbackCount = count;
          this.currentFeedback = null;
        }),
    );
  }

  getFilterParams(page: number) {
    return new HttpParams().set('limit', String(this.PAGE_SIZE)).set('offset', String((page - 1) * this.PAGE_SIZE));
  }

  onFeedbackFilterChanged(f: 'ALL' | 'UNREAD') {
    this.filter = f;
    this.currentPage = 1;
    this.searchFeedbacks();
  }
  // @ts-expect-error noImplicitAny
  onPageChange(_$event) {
    this.searchFeedbacks();
  }

  selectFeedback(feedback: RR.Feedback) {
    this.currentFeedback = feedback;
    this.subscription.add(this.accessEventEffect.findInReport(this.currentFeedback.report_id).subscribe());

    // Mark feedback and relevant comments as checked
    this.subscription.add(this.feedbackEffect.check(feedback.id).subscribe());
  }

  onDatesChanged(dates: { start: Date | undefined; end: Date | undefined }) {
    this.fromDate = dates.start;
    this.toDate = dates.end;
    this.searchFeedbacks();
  }

  onSelectUsers(user: RR.User[]) {
    this.user = user[0];
    this.userSelection$.next(this.user.id);
  }

  feedbackSelectorFn = fromFeedback.selectFeedback;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
