import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { referrerTypeMap } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { ReportEffect } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';

import { ReferrerFormEditComponent } from './referrer-form-edit/referrer-form-edit.component';
import { ReferrerFormSearchComponent } from './referrer-form-search/referrer-form-search.component';
import { ReferrerFormViewComponent } from './referrer-form-view/referrer-form-view.component';

export const REFERRER_FORM_ID = 'referrer-form';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReferrerFormEditComponent,
    ReferrerFormSearchComponent,
    ReferrerFormViewComponent,
    SharedModule,
  ],
  selector: 'rr-referrer-form',
  templateUrl: './referrer-form.component.html',
  styleUrls: ['./referrer-form.component.scss'],
  host: {
    id: REFERRER_FORM_ID,
  },
})
export class ReferrerFormComponent implements OnDestroy, OnInit {
  @ViewChild(ReferrerFormEditComponent) referrerFormEdit: ReferrerFormEditComponent;
  @ViewChild(ReferrerFormSearchComponent) referrerFormSearchComponent: ReferrerFormSearchComponent;

  @Input() @BindObservable() referrer: RR.Referrer | undefined;
  referrer$: Observable<RR.Referrer | undefined>;
  @Input() header: string;
  @Input() reportId?: number | undefined;
  @Input() parent: 'REPORT' | 'REFERRER' | 'BOOKING' = 'REPORT'; // Parent component (report registration or referrer page)
  @Input() noReferrer: boolean;
  @Input() bookingId: number | undefined;

  booking$: Observable<RR.Booking | undefined>;

  @Output() onChange: EventEmitter<RR.Referrer> = new EventEmitter();
  removedReferrer: RR.Referrer | undefined = undefined;
  subscription: Subscription = new Subscription();
  formStatus: string;
  @Output() onNoReferrerChange: EventEmitter<Event> = new EventEmitter();
  /**
   * search: show the search input. create patient button.
   * view: non editable preview of the patient. edit and remove patient buttons.
   * edit: show the form. if there's a patient, edit patient. if there's no patient, create patient.
   */
  viewMode: 'search' | 'view' | 'edit' | 'create' = 'view';
  referrerTypes = referrerTypeMap;

  constructor(
    private messageService: MessageService,
    private reportEffect: ReportEffect,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.referrer$.subscribe((referrer) => {
        if (referrer) {
          // Do not back to view mode if editing referrer because we might want to edit preferred contacts multiple times.
          if (this.viewMode !== 'edit') {
            this.viewMode = 'view';
          }
        } else {
          this.viewMode = 'search';
        }
      }),
    );

    if (this.bookingId) {
      this.booking$ = this.store.select(fromBooking.selectBooking(this.bookingId));
    }
  }

  setFormStatus(status: string) {
    this.formStatus = status;
  }

  selectReferrer($event: { referrer: RR.Referrer; created: boolean }) {
    const { referrer, created } = $event;
    this.messageService.add({
      title: 'Success',
      message: created ? 'Create new referrer successfully!' : 'Update referrer successfully!',
      type: 'success',
    });
    this.onChange.emit(referrer);
    this.viewMode = 'view';
  }

  editReferrer() {
    this.viewMode = 'edit';
  }

  cancel() {
    this.referrerFormEdit.form.markAsPristine();
    if (this.referrer) {
      this.viewMode = 'view';
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (!this.referrer && this.viewMode === 'search') {
      this.referrer = this.removedReferrer;
      this.viewMode = 'view';
    } else {
      this.viewMode = 'search';
    }
  }

  createNewReferrer() {
    this.viewMode = 'create';
    if (this.referrer) this.resetForm();
  }

  removeReferrer() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.removedReferrer = this.referrer!; // non-null assertion because of ngIf
    this.referrer = undefined;
    this.viewMode = 'search';
    this.resetForm();
  }

  deleteReferrerReport() {
    const modalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: `Are you sure you want to delete ${
        (this.referrer?.physician_given_name ?? '') + ' ' + (this.referrer?.physician_family_name ?? '')
      }  from report?`,
      btnConfirmText: 'Yes',
      secondBtnOptionText: 'Cancel',
    });
    modalRef.result.then(
      () => {
        if (this.reportId) {
          this.subscription.add(this.reportEffect.update(this.reportId, { referrer_id: null }).subscribe());
          this.referrer = undefined;
          this.removedReferrer = undefined;
          this.viewMode = 'search';
          this.resetForm();
        }
      },
      () => {
        /* do nothing */
      },
    );
  }

  resetForm() {
    this.referrerFormEdit.form.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
