import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Keyword Abbreviation
 *
 */
export const KeywordAbbreviationActions = {
  findAllSuccess: createAction(
    '[KeywordAbbreviation] Find All Success',
    props<{ keywordAbbreviations: RR.KeywordAbbreviation[] }>(),
  ),

  createSuccess: createAction(
    '[KeywordAbbreviation] Create Success',
    props<{ keywordAbbreviation: RR.KeywordAbbreviation }>(),
  ),

  updateSuccess: createAction(
    '[KeywordAbbreviation] Update Success',
    props<{ keywordAbbreviation: RR.KeywordAbbreviation }>(),
  ),

  deleteSuccess: createAction('[KeywordAbbreviation] Delete Success', props<{ keywordAbbreviationId: number }>()),
};
