<div *ngIf="similarPatients && similarPatients.patients.length > 0" class="row my-2">
  <div class="alert alert-danger" role="alert">
    <h4 *ngIf="similarPatients.reason === 'IDENTICAL_PATIENT'" class="text-danger">Identical patients found</h4>
    <div>
      To update the report using any of the patients listed below, please select the desired patient and click the
      Update button.
    </div>
    <div *ngFor="let patient of similarPatients.patients" class="mt-2">
      <input
        type="radio"
        name="similarPatient"
        class="form-check-input"
        id="patient-{{ patient.id }}"
        (change)="onChangePatient(patient.id)"
      />

      <label
        for="patient-{{ patient.id }}"
        class="form-check-label ms-2"
        [ngbPopover]="popoverContent"
        popoverTitle="Patient Details"
        triggers="mouseenter:mouseleave"
      >
        {{ patient.patient_first_name }} {{ patient.patient_last_name }} ({{ patient.patient_number }})
      </label>
      <a class="ms-1" target="_blank" [routerLink]="['/entities/patients', patient.id]">
        <span class="small fa fa-external-link-alt"></span>
      </a>
      <ng-template #popoverContent>
        <rr-patient-popover [patient]="patient"></rr-patient-popover>
      </ng-template>
    </div>
    <div class="d-flex gap-1">
      <button *ngIf="selectedPatientId" type="button" class="btn btn-primary mt-3" (click)="updateReport()">
        Update
      </button>
      <ng-container *ngIf="report$ | async as report">
        <button
          *ngIf="selectedPatientId"
          type="button"
          class="btn btn-secondary mt-3"
          [routerLink]="['/merge/patient']"
          [queryParams]="{ left: report.patient_id, right: selectedPatientId }"
        >
          Merge
        </button>
      </ng-container>
    </div>
  </div>
</div>
