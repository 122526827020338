import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal,
  NgbModal,
  NgbPopover,
  NgbTypeaheadSelectItemEvent,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { NameFavouriteModalComponent } from 'app/modules/editor/prefill-button/name-favourite-modal.component';
import { SendFeedbackModalComponent } from 'app/modules/feedback/modals/send-feedback-modal/send-feedback-modal.component';
import { DocumentAttachmentButtonComponent } from 'app/modules/registration/components/document-attachment-button/document-attachment-button.component';
import { ReferrerPreferredContactsComponent } from 'app/modules/registration/components/referrer-form/referrer-preferred-contacts/referrer-preferred-contacts.component';
import { RegistrationModalComponent } from 'app/modules/registration/modals/registration-modal/registration-modal.component';
import { PatientInfoComponent } from 'app/shared/components/patient-info/patient-info.component';
import { ProofReadReportComponent } from 'app/shared/components/proof-read-report/proof-read-report.component';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { ReportNotesButtonComponent } from 'app/shared/components/report-notes-button/report-notes-button.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AutoSizeDirective } from 'app/shared/directives/auto-size.directive';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { NextReportModalComponent } from 'app/shared/modals/next-report-modal/next-report-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { checkIfEmail, getEmailValidatorRegex } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { fromPatient } from 'app/store/patient';
import { fromReferrer } from 'app/store/referrer';
import { DoctorFollowupEffect } from 'app/store/report/doctor-followup/doctor-followup.effect';
import { fromFavourite } from 'app/store/report/favourite';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { fromSendEvent } from 'app/store/report/send-event';
import { SendEventEffect } from 'app/store/report/send-event/send-event.effect';
import { fromTodo } from 'app/store/report/todo';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromUrgentNote } from 'app/store/report/urgent-note';
import { fromSession } from 'app/store/session';
import { SignatureEffect } from 'app/store/signature';
import { fromSignature } from 'app/store/signature/signature.selector';
import { SiteEffect, fromSite } from 'app/store/site';
import { fromUser } from 'app/store/user/user';
import { differenceInMinutes } from 'date-fns';
import { combineLatest, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, take } from 'rxjs/operators';

import { SamePatientReportsComponent } from '../../../../../shared/components/same-patient-reports/same-patient-reports.component';
import { ProviderLookupComponent } from '../provider-lookup/provider-lookup.component';
import { SendEventHistoryComponent } from './send-event-history.component';

type SendingFieldName = keyof SendReportModalComponent['infoForm']['controls'];
type SendingType = 'SMS' | 'EMAIL' | 'FAX';

const FIELD_TYPE_TO_SENDING_TYPE = new Map<SendingFieldName, SendingType>([
  ['phone', 'SMS'],
  ['cc_to_sms', 'SMS'],
  ['email', 'EMAIL'],
  ['cc_to_email', 'EMAIL'],
  ['cc_to_email2', 'EMAIL'],
  ['fax', 'FAX'],
  ['cc_to_fax', 'FAX'],
  ['cc_to_fax2', 'FAX'],
]);

@Component({
  standalone: true,
  selector: 'rr-send-report-modal',
  templateUrl: './send-report-modal.component.html',
  styleUrls: ['./send-report-modal.component.scss'],
  imports: [
    CommonModule,
    SharedModule,
    SendEventHistoryComponent,
    ReportNotesButtonComponent,
    ProviderLookupComponent,
    DocumentAttachmentButtonComponent,
    PatientInfoComponent,
    AutoSizeDirective,
    AutoFocusDirective,
    ReferrerPreferredContactsComponent,
    ReferrerNameComponent,
    SamePatientReportsComponent,
  ],
})
// TODO: rename to Distribute
export class SendReportModalComponent implements OnInit, OnDestroy {
  @ViewChild('popover') popover: NgbPopover;
  @ViewChild('sendBtn') sendBtn: ElementRef<HTMLButtonElement>;
  @ViewChild('modalTemplate', { static: true }) modalTemplate!: NgbModalRef;
  @Input() report: RR.Report;
  @Input() parent: 'WORKLIST';
  patient: RR.Patient;
  referrer: RR.Referrer | undefined;
  subscription: Subscription = new Subscription();
  sendEvents: RR.SendEvent[] = [];
  hasMedicalObject: boolean = false;

  infoForm = new FormGroup({
    phone: new FormControl('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[\d-]+$/)],
    }),
    email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.pattern(getEmailValidatorRegex())],
    }),
    fax: new FormControl('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[\d-]+$/)],
    }),
    cc_to_sms: new FormControl('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[\d-]+$/)],
    }),
    cc_to_email: new FormControl('', {
      nonNullable: true,
      validators: [Validators.pattern(getEmailValidatorRegex())],
    }),
    cc_to_email2: new FormControl('', {
      nonNullable: true,
      validators: [Validators.pattern(getEmailValidatorRegex())],
    }),
    cc_to_fax: new FormControl('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[\d-]+$/)],
    }),
    cc_to_fax2: new FormControl('', {
      nonNullable: true,
      validators: [Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^[\d-]+$/)],
    }),
  });

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  ccFocus$ = new Subject<string>();
  ccClick$ = new Subject<string>();
  ccFaxFocus2$ = new Subject<string>();
  ccFaxClick2$ = new Subject<string>();
  numberClick$ = new Subject<string>();
  numberFocus$ = new Subject<string>();
  ccNumberClick$ = new Subject<string>();
  ccNumberFocus$ = new Subject<string>();
  emailClick$ = new Subject<string>();
  emailFocus$ = new Subject<string>();
  ccEmailClick$ = new Subject<string>();
  ccEmailFocus$ = new Subject<string>();
  ccEmailClick2$ = new Subject<string>();
  ccEmailFocus2$ = new Subject<string>();
  suggestedNumbers: FaxSuggestions[] = [];
  suggestedEmails: EmailSuggestions[] = [];
  suggestedPhoneNumbers: PhoneSuggestions[] = [];
  // Allow to send unfinalised report by email or fax. Using it when the report has been transcribed and verified in Voyager manually
  skipWarning = false;
  isSendingMO = true;
  sent_to: string;
  type: string | undefined;
  selectedField: SendingFieldName;
  emailContentType: 'TEXT' | 'URL' = 'TEXT';
  sms_template_text: string;
  // TODO: this field is doing too many things. It can contain sms text, report url message, report text. This is a sign
  // that this component should be broken up into smaller components.
  text = '';
  disabled = false;
  topics: RR.Topic[];
  warnings$: Observable<string[]>;
  emailReportText = '';
  isAddingFaxCustomMessage = true;
  faxCustomMessage = new FormControl('', { nonNullable: true });
  emailSubject = new FormControl('', { nonNullable: true });
  referrer$: Observable<RR.Referrer | undefined>;
  report$: Observable<RR.Report | undefined>;
  ccFromNotes: (string | undefined)[] = [];
  emails: (string | undefined)[] = [];
  notes: RR.UrgentNotes[];
  topicTitles: string;
  recentlyEditedWarning$: Observable<boolean>;
  hasMismatchBetweenStudyDates$: Observable<boolean>;

  // The first doctor appear in the signature
  mainDoctor: RR.User | undefined;
  favourite: RR.Favourite | undefined;
  activeTopic: RR.Topic;
  site: RR.Site | undefined = undefined;

  selectedScanType = new FormControl<string | undefined>(undefined, { nonNullable: true });
  doctorFollowUpText = new FormControl('', { nonNullable: true });
  doctorFollowUpTemplate: string | undefined;

  isLoading = false;
  hasFollowup = false;
  modalInstance: NgbModalRef;
  showTechnicianSignature: boolean = false;
  hasSonographerSignature: boolean = false;

  sentToPatient: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private editorService: EditorService,
    private messageService: MessageService,
    private http: HttpClient,
    private templateService: TemplateService,
    private sendEventEffect: SendEventEffect,
    private reportEffect: ReportEffect,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private clipboard: Clipboard,
    private signatureEffect: SignatureEffect,
    private siteEffect: SiteEffect,
    private doctorFollowupEffect: DoctorFollowupEffect,
  ) {}

  ngOnInit() {
    this.selectedScanType.setValue('CT');

    this.subscription.add(this.siteEffect.findAll().subscribe());

    this.subscription.add(
      this.doctorFollowupEffect.search(this.report.id).subscribe((response) => {
        this.hasFollowup = !!response.doctor_followup;
      }),
    );

    this.subscription.add(
      this.store
        .select(fromSession.selectRRConfig)
        .pipe(
          filterDefined(),
          map((payload) => {
            this.doctorFollowUpTemplate = payload.configuration.doctor_followup_template_text || undefined;
            if (this.doctorFollowUpTemplate) this.doctorFollowUpText.setValue(this.doctorFollowUpTemplate);
            this.updateTextContent();
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.selectedScanType.valueChanges.subscribe((value) => {
        if (value) this.updateTextContent();
      }),
    );

    this.subscription.add(
      this.store
        .select(fromSignature.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.signatureEffect.findAll();
            }
            return of(null);
          }),
        )
        .subscribe(),
    );

    if (this.report.patient_id) {
      this.subscription.add(
        this.store
          .select(fromPatient.selectPatient(this.report.patient_id))
          .pipe(filterDefined())
          .subscribe((p) => {
            this.patient = p;
          }),
      );
    }

    this.report$ = this.store.select(fromReport.selectReport(this.report.id));

    this.subscription.add(
      this.report$.subscribe((report) => {
        if (report) {
          this.showTechnicianSignature = report.show_technician_signature;
        }
      }),
    );

    this.referrer$ = this.report$.pipe(
      switchMap((report) =>
        report && report.referrer_id
          ? this.store.select(fromReferrer.selectReferrer(report.referrer_id))
          : of(undefined),
      ),
    );

    this.subscription.add(
      this.store.select(fromReport.selectTopics(this.report.id)).subscribe((topics) => {
        this.topicTitles = topics.map((topic) => topic.title_option_text).join(', ');
      }),
    );

    this.subscription.add(
      this.referrer$.subscribe((r) => {
        this.referrer = r;
        if (r) this.addSuggested(r);
      }),
    );

    this.subscription.add(
      this.store
        .select(fromReport.selectTopics(this.report.id))
        .pipe(
          map((topics) => {
            const topic = topics.find((t) => !!t.signature_id);
            return topic?.signature_id;
          }),
          filterDefined(),
          switchMap((signatureId) => this.store.select(fromSignature.selectSignature(signatureId))),
          filterDefined(),
          switchMap((signature) =>
            signature.user_ids.length > 0
              ? this.store.select(fromUser.selectUser(signature.user_ids[0]))
              : of(undefined),
          ),
        )
        .subscribe((user) => {
          this.mainDoctor = user;
        }),
    );

    this.infoForm.patchValue({
      phone: this.referrer?.phone_mobile || '',
      email: this.referrer?.email || '',
      fax: this.referrer?.fax || '',
      cc_to_sms: this.report.cc_to_sms || '',
      cc_to_email: this.report.cc_to_email || '',
      cc_to_email2: this.report.cc_to_email || '',
      cc_to_fax: this.report.cc_to_fax || '',
      cc_to_fax2: this.report.cc_to_fax2 || '',
    });

    // Saves the form 500ms after it changes.
    this.subscription.add(
      this.infoForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        // TODO: modal may close before it saves. There's no indication that it hasn't been saved.
        this.onSubmit();
      }),
    );

    this.subscription.add(
      this.store.select(fromSendEvent.selectSendEventsInReport(this.report.id)).subscribe((events) => {
        this.sendEvents = events;
        this.hasMedicalObject = events.some((mo) => mo.communication_type === 'MEDICAL_OBJECTS');
      }),
    );

    this.subscription.add(
      this.reportService
        .getActiveTopics(this.report.id)
        .pipe(take(1))
        .subscribe((topics) => {
          this.topics = topics;
        }),
    );

    this.subscription.add(
      this.reportService
        .getRecommendedInfo(this.report.id)
        .pipe(take(1))
        .subscribe({
          next: (suggestions) => {
            this.suggestedNumbers = suggestions.fax_numbers;
            this.suggestedEmails = suggestions.emails;
          },
          error: (res: unknown) => {
            this.messageService.httpErrorMessage(res, { title: 'Warning' });
          },
        }),
    );

    const booking$ = this.report$.pipe(
      switchMap((report) => (report ? this.store.select(fromBooking.selectBooking(report.booking_id)) : of(undefined))),
    );

    const site$ = booking$.pipe(
      switchMap((booking) => (booking ? this.store.select(fromSite.selectSite(booking.site_id)) : of(undefined))),
    );

    this.subscription.add(
      site$.subscribe((site) => {
        if (site) {
          this.site = site;
        }
      }),
    );
    const bookingNotes$ = booking$.pipe(map((booking) => (booking ? booking.notes : null)));
    const clinicalNotes$ = this.store.select(fromUrgentNote.selectInReport(this.report.id));
    const adminNotes$ = this.store.select(fromTodo.selectInReport(this.report.id));
    const collaterNotes$ = this.report$.pipe(
      switchMap((report) =>
        report && report.referrer_id
          ? this.store.select(fromReferrer.selectReferrer(report.referrer_id))
          : of(undefined),
      ),
      filterDefined(),
      map((referrer) => referrer.collater_notes),
    );

    const patientNotes$ = this.report$.pipe(
      switchMap((report) =>
        report && report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
      ),
      filterDefined(),
      map((patient) => patient.note),
    );

    // Add 'CC' from notes to warning
    this.subscription.add(
      combineLatest([this.report$, clinicalNotes$, bookingNotes$, adminNotes$, collaterNotes$, patientNotes$])
        .pipe(take(1))
        .subscribe(([report, urgentNotes, bookingNotes, adminNotes, collaterNotes, patientNotes]) => {
          if (report && report.study_notes) this.noteRegexSlice(report.study_notes);
          if (report && report.patient_note) this.noteRegexSlice(report.patient_note);
          if (bookingNotes) this.noteRegexSlice(bookingNotes);
          if (collaterNotes) this.noteRegexSlice(collaterNotes);
          if (patientNotes) this.noteRegexSlice(patientNotes);
          adminNotes.map((note) => note.todo).map((note) => this.noteRegexSlice(note || ''));
          urgentNotes.map((note) => note.urgent_notes).map((note) => this.noteRegexSlice(note));

          this.extractEmailsFromNotes();
        }),
    );

    const topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(
      filterDefined(),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    );
    this.subscription.add(
      topic$.subscribe((topic) => {
        this.activeTopic = topic;
        this.hasSonographerSignature = topic.technician_signature_text?.includes('Sonographer') || false;
      }),
    );
    this.subscription.add(
      topic$
        .pipe(
          switchMap((topic) =>
            topic.favourite_id ? this.store.select(fromFavourite.selectById(topic.favourite_id)) : of(undefined),
          ),
        )
        .subscribe((fav) => {
          this.favourite = fav;
        }),
    );

    this.recentlyEditedWarning$ = this.report$.pipe(
      filterDefined(),
      map((r) => !!r.text_modified && differenceInMinutes(new Date(), new Date(r.text_modified)) < 15),
    );

    this.subscription.add(
      this.selectedScanType.valueChanges.subscribe((value) => {
        if (value) this.updateTextContent();
      }),
    );

    this.hasMismatchBetweenStudyDates$ = this.reportService
      .checkVoyagerStudyDate(Number(this.report.accession_number))
      .pipe(
        map((response) => {
          const { rr_study_date, voyager_study_date } = response;
          return rr_study_date !== voyager_study_date;
        }),
      );
  }

  noteRegexSlice(text: string) {
    // expression finds the first (case-insensitive) instance of 'cc' in a note.
    // \b represents a word boundary, so 'cc' must be separated from other charachters to match.
    // Will match: 'cc', 'Cc', 'CC'
    // Will not match: 'Acc', 'CcA', 'Accountant', 'PCCR'
    // Using the position of the match, we copy the rest of the notes text to the array.

    const regExp = new RegExp(/\bcc\b/i);
    const regexMatch = regExp.test(text);
    if (regexMatch) {
      const regexPos = text.search(regExp);
      this.ccFromNotes = [...this.ccFromNotes, text.slice(regexPos)];
    }
  }

  noteCCEmailExtract(text: string) {
    const regExp = checkIfEmail();
    const matches = text.match(regExp);

    if (matches && matches.length > 0) {
      return matches;
    }

    return [];
  }

  extractEmailsFromNotes() {
    const extractedEmails = this.ccFromNotes.map((note) => note && this.noteCCEmailExtract(note));

    // Flatten the array and filter out any null or empty arrays.
    this.emails = extractedEmails.flat().filter((email) => email);
  }

  selectEmail(email: string) {
    this.infoForm.controls.cc_to_email.setValue(email);
    this.infoForm.controls.cc_to_email.markAsDirty();

    this.infoForm.controls.cc_to_email2.setValue(email);
    this.infoForm.controls.cc_to_email2.markAsDirty();

    this.infoForm.controls.email.setValue(email);
    this.infoForm.controls.email.markAsDirty();
  }

  addSuggested(referrer: RR.Referrer) {
    const phoneNumbers = {
      Work: referrer.phone_work,
      Mobile: referrer.phone_mobile,
    };

    const referring_physician = referrer.physician_given_name + ' ' + referrer.physician_family_name;

    // If referrer has email and there isn't this number already
    if (referrer.email && !this.suggestedEmails.some((suggested) => suggested.email === referrer.email)) {
      this.suggestedEmails.push({
        referring_physician,
        // @ts-expect-error strictNullChecks
        referring_service: referrer.service,
        email: referrer.email,
      });
    }

    // If referrer has fax and there isn't this number already
    if (referrer.fax && !this.suggestedNumbers.some((suggested) => suggested.fax_number === referrer.fax)) {
      this.suggestedNumbers.push({
        referring_physician,
        // @ts-expect-error strictNullChecks
        referring_service: referrer.service,
        fax_number: referrer.fax,
      });
    }

    Object.entries(phoneNumbers)
      // Numbers are potentially undefined, so filter those out and duplicates
      .filter(([, number]) => !!number && !this.suggestedPhoneNumbers.some((suggested) => suggested.number === number))
      .map(([type, number]) => {
        this.suggestedPhoneNumbers.push({
          // @ts-expect-error strictNullChecks
          number,
          type,
          referring_physician,
          // @ts-expect-error strictNullChecks
          referring_service: referrer.service,
        });
      });
  }

  onSelectTypeaheadPhone(
    name: keyof Pick<typeof this.infoForm.controls, 'phone' | 'cc_to_sms'>,
    event: NgbTypeaheadSelectItemEvent<PhoneSuggestions>,
  ) {
    this.infoForm.controls[name].setValue(event.item.number);
    event.preventDefault();
  }

  onSelectTypeaheadFax(
    name: keyof Pick<typeof this.infoForm.controls, 'fax' | 'cc_to_fax' | 'cc_to_fax2'>,
    event: NgbTypeaheadSelectItemEvent<FaxSuggestions>,
  ) {
    this.infoForm.controls[name].setValue(event.item.fax_number);
    event.preventDefault();
  }

  onSelectTypeaheadEmail(
    name: keyof Pick<typeof this.infoForm.controls, 'email' | 'cc_to_email' | 'cc_to_email2'>,
    event: NgbTypeaheadSelectItemEvent<EmailSuggestions>,
  ) {
    this.infoForm.controls[name].setValue(event.item.email);
    event.preventDefault();
  }

  /**
   * Check if the report is ready to send out
   * (When it has been saved to Voyager before, or it is being scheduled to send to Voyager)
   */
  isReportReadyToSend() {
    // If the report had been saved to Voyager before
    if (this.report.send_to_voyager_time) {
      return of(true);
    }

    // If it is being scheduled to send to Voyager
    return this.editorService
      .getScheduleSendToVoyagerTask()
      .pipe(map((report_id: number | undefined) => this.report.id === report_id));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openRegistrationModal() {
    RegistrationModalComponent.open(this.modalService, this.report.id);
  }

  openFavouriteModal() {
    NameFavouriteModalComponent.open({
      modalService: this.modalService,
      topic_id: this.activeTopic.id,
      favourite: this.favourite,
    });
  }

  openSendFeedback() {
    SendFeedbackModalComponent.open(this.modalService, this.report.id);
  }

  togglePrefill() {
    this.editorService.togglePrefill(true);
    this.modalService.dismissAll();
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestFaxNumbers = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.click$, this.focus$).pipe(
      map((term) =>
        term === '' ? this.suggestedNumbers : this.suggestedNumbers.filter((v) => v.fax_number.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestPhoneNumbers = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.numberClick$, this.numberFocus$).pipe(
      map((term) =>
        term === ''
          ? this.suggestedPhoneNumbers
          : this.suggestedPhoneNumbers.filter((v) => v.number.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestCCPhoneNumbers = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.ccNumberClick$, this.ccNumberFocus$).pipe(
      map((term) =>
        term === ''
          ? this.suggestedPhoneNumbers
          : this.suggestedPhoneNumbers.filter((v) => v.number.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestCCFaxNumbers = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.ccFocus$).pipe(
      map((term) =>
        term === '' ? this.suggestedNumbers : this.suggestedNumbers.filter((v) => v.fax_number.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestCCFaxNumbers2 = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.ccFaxFocus2$).pipe(
      map((term) =>
        term === '' ? this.suggestedNumbers : this.suggestedNumbers.filter((v) => v.fax_number.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestEmails = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.emailClick$, this.emailFocus$).pipe(
      map((term) =>
        term === '' ? this.suggestedEmails : this.suggestedEmails.filter((v) => v.email.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestCCEmails = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.ccEmailClick$, this.ccEmailFocus$).pipe(
      map((term) =>
        term === '' ? this.suggestedEmails : this.suggestedEmails.filter((v) => v.email.indexOf(term) > -1),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestCCEmails2 = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.ccEmailClick2$, this.ccEmailFocus2$).pipe(
      map((term) =>
        term === '' ? this.suggestedEmails : this.suggestedEmails.filter((v) => v.email.indexOf(term) > -1),
      ),
    );
  };

  onSubmit() {
    const request: Partial<RR.Report> = {};
    if (this.infoForm.controls.phone.value !== '') {
      request.phone = this.infoForm.controls.phone.value;
    }
    if (this.infoForm.controls.cc_to_sms.value !== '') {
      request.cc_to_sms = this.infoForm.controls.cc_to_sms.value;
    }
    if (this.infoForm.controls.email.value !== '') {
      request.email = this.infoForm.controls.email.value;
    }
    if (this.infoForm.controls.cc_to_email.value !== '') {
      request.cc_to_email = this.infoForm.controls.cc_to_email.value;
    }
    if (this.infoForm.controls.cc_to_email2.value !== '') {
      request.cc_to_email2 = this.infoForm.controls.cc_to_email2.value;
    }
    if (this.infoForm.controls.fax.value !== '') {
      request.fax = this.infoForm.controls.fax.value;
    }
    if (this.infoForm.controls.cc_to_fax.value !== '') {
      request.cc_to_fax = this.infoForm.controls.cc_to_fax.value;
    }
    if (this.infoForm.controls.cc_to_fax2.value !== '') {
      request.cc_to_fax2 = this.infoForm.controls.cc_to_fax2.value;
    }
    this.subscription.add(this.reportEffect.update(this.report.id, request).subscribe());
  }

  openSendReport(field: SendingFieldName) {
    if (!this.isAddingFaxCustomMessage) this.toggleCustomMessageButton();
    this.sent_to = this.infoForm.controls[field].value;
    this.selectedField = field;
    this.type = FIELD_TYPE_TO_SENDING_TYPE.get(field);
    this.isSendingMO = false;
    if (this.selectedField === 'phone' || this.selectedField === 'cc_to_sms') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (rrConfig.configuration !== null && rrConfig.configuration.sms_template_text !== null) {
        this.sms_template_text = rrConfig.configuration.sms_template_text;
      } else {
        this.sms_template_text = `{patient_name}
    {image_url}`;
      }

      this.text = this.createBody(this.sms_template_text);
    } else {
      this.text = 'Loading report text...';

      this.initialiseCustomMessages();

      setTimeout(() => {
        this.subscription.add(
          this.reportEffect
            .findReportText(this.report.id)
            .pipe(take(1))
            .subscribe((action) => {
              this.text = action.report.text;
              this.emailReportText = action.report.text;
            }),
        );
      });
    }
    this.warnings$ = this.http.post<string[]>('/api/check_sending_report', {
      report_id: this.report.id,
      to: this.sent_to,
      sending_type: this.type,
    });

    requestAnimationFrame(() => {
      this.sendBtn.nativeElement.focus();
    });
  }

  openSendReportMO() {
    this.isSendingMO = true;
  }

  // @ts-expect-error noImplicitAny
  createBody(text) {
    let message = text;
    message = message.replace('{image_url}', this.report.image_url);
    message = message.replace(
      '{patient_name}',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.patient ? this.patient.patient_first_name + ' ' + this.patient.patient_last_name : '',
    );
    message = message.replace('{site_name}', this.site ? this.site.name : '');
    message = message.replace('{registration_date}', this.report.created ? this.report.created : '');
    return message;
  }

  getTemplate(template_id: number) {
    return this.templateService.getTemplate(template_id);
  }

  saveCCTo(field: keyof typeof this.infoForm.controls, type: string) {
    // TODO: the form autosaves every .5s. Is this necessary still?
    const sendTo = this.infoForm.controls[field].value;
    if (!sendTo || !sendTo.trim()) {
      this.messageService.add({
        title: 'Error',
        type: 'danger',
        message: type === 'EMAIL' ? 'Missing email address' : 'Missing fax number',
        timeout: 5000,
      });
    } else {
      this.messageService.add({
        title: 'Success',
        type: 'success',
        message: type === 'EMAIL' ? 'Save email address successfully' : 'Save fax number successfully',
        timeout: 5000,
      });
    }
  }

  openProofRead() {
    ProofReadReportComponent.open(this.modalService, this.report.id);
  }

  checkSendStatus(sendTo: string, type: string) {
    if (!sendTo) return 'EMPTY';

    if (this.sendEvents.some((e) => e.communication_type === type && e.send_to === sendTo)) return 'RESOLVED';
    return 'UNRESOLVED';
  }

  skipWarningClicked() {
    this.skipWarning = true;
  }

  static open(modalService: NgbModal, report: RR.Report, parent?: 'WORKLIST') {
    const modalRef = modalService.open(SendReportModalComponent, {
      centered: true,
      windowClass: 'send-report-modal',
    });
    modalRef.componentInstance.report = report;
    modalRef.componentInstance.parent = parent;
    return modalRef;
  }

  onChangeEmailContentType(emailContentType: 'TEXT' | 'URL') {
    this.emailContentType = emailContentType;
    if (emailContentType === 'URL') {
      let emailUrlTemplate;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (rrConfig.configuration != null && rrConfig.configuration.email_url_template_text != null) {
        emailUrlTemplate = rrConfig.configuration.email_url_template_text;
      } else {
        emailUrlTemplate = `Click on encrypted link to access to {patient_name} images -and report
        when available- at {image_url} If you've received this Email in error,
        please text "Email URL ERROR" to 0414 450 215.`;
      }

      this.text = this.createBody(emailUrlTemplate);
    }
    if (emailContentType === 'TEXT') {
      this.text = this.emailReportText;
    }
  }

  send() {
    this.disabled = true;
    this.sentToPatient = true;
    if (this.type === 'SMS') {
      this.subscription.add(
        this.sendEventEffect
          .sendSMS({
            report_id: this.report.id,
            send_to: this.sent_to,
            message: this.text,
          })
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.disabled = false;
              this.sentToPatient = false;
              this.messageService.add({
                title: 'Success',
                type: 'success',
                message: `Sent ${this.type} to ${this.sent_to}`,
              });
            },
            error: (error: unknown) => {
              this.disabled = false;
              this.sentToPatient = false;
              if (error instanceof HttpErrorResponse) {
                this.messageService.httpErrorMessage(error, {
                  title: `Failed to send ${this.type} to ${this.sent_to}`,
                });
              }
            },
          }),
      );
    }

    if (this.type === 'FAX') {
      this.subscription.add(
        this.sendEventEffect
          .sendEmail({
            report_id: this.report.id,
            send_to: this.sent_to,
            send_type: this.type,
            message: this.faxCustomMessage.value || undefined,
            // Email subject is just for auditing the fax messages.
            email_subject: this.emailSubject.value,
          })
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.disabled = false;
              this.sentToPatient = false;
              this.messageService.add({
                title: 'Success',
                type: 'success',
                message: `Sent ${this.type} to ${this.sent_to}`,
              });
            },
            error: (error: unknown) => {
              this.disabled = false;
              this.sentToPatient = false;
              if (error instanceof HttpErrorResponse) {
                this.messageService.httpErrorMessage(error, {
                  title: `Failed to send ${this.type} to ${this.sent_to}`,
                });
              }
            },
          }),
      );
    }

    if (this.type === 'EMAIL') {
      this.subscription.add(
        this.sendEventEffect
          .sendEmail({
            report_id: this.report.id,
            send_to: this.sent_to,
            send_type: this.type,
            // @ts-expect-error strictNullChecks
            email_url_message: this.emailContentType === 'URL' ? this.text : null,
            email_subject: this.emailSubject.value,
          })
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.disabled = false;
              this.sentToPatient = false;
              this.messageService.add({
                title: 'Success',
                type: 'success',
                message: `Sent ${this.type} to ${this.sent_to}`,
              });
            },
            error: (error: unknown) => {
              this.disabled = false;
              this.sentToPatient = false;
              if (error instanceof HttpErrorResponse) {
                this.messageService.httpErrorMessage(error, {
                  title: `Failed to send ${this.type} to ${this.sent_to}`,
                });
              }
            },
          }),
      );
    }
  }

  checkThenSend() {
    const hasSent = this.sendEvents.some((e) => e.send_to === this.sent_to);

    if (hasSent) {
      const modalRef = ConfirmMessageModalComponent.open({
        modalService: this.modalService,
        header: 'Confirm',
        message: `The message has already been sent to: "${this.sent_to}". Would you like to send it again?`,
        btnConfirmText: 'Yes',
      });
      modalRef.result.then(
        () => {
          this.send();
        },
        () => {
          /* do nothing */
        },
      );
    } else {
      this.send();
    }
  }

  initialiseCustomMessages() {
    const isCC = ['cc_to_email', 'cc_to_email2', 'cc_to_fax', 'cc_to_sms', 'cc_to_fax2'].includes(this.selectedField);
    const dearReferrer = `Dear Dr. ${this.referrer?.physician_family_name?.trim() ?? ''}`;

    this.faxCustomMessage.patchValue(
      `${!isCC ? dearReferrer + '\n' : ''}'${this.topicTitles}' report is now available (RE: ${
        this.patient.patient_first_name + ' ' + this.patient.patient_last_name
      })\nKind Regards,\nDr ${this.mainDoctor ? this.mainDoctor.name : 'Roger Davies'}`,
    );

    this.emailSubject.patchValue(
      // New lines are not allowed in an email subject
      `${!isCC ? dearReferrer + '. ' : ''}'${this.topicTitles}' report is now available (RE: ${
        this.patient.patient_first_name + ' ' + this.patient.patient_last_name
      })`,
    );
    // TODO: "Kind Regards, Dr Roger Davies" in the email body?
  }

  toggleCustomMessageButton() {
    if (this.isAddingFaxCustomMessage) {
      this.faxCustomMessage.reset();
      // emailSubject can't be null - so set value to empty string
      this.initialiseCustomMessages();
    }
    this.isAddingFaxCustomMessage = !this.isAddingFaxCustomMessage;
  }

  copy(value: string | number) {
    const copied = this.clipboard.copy(value.toString());
    if (copied) {
      this.messageService.add({ type: 'success', title: 'Success', message: 'Copied!', timeout: 1000 });
    } else {
      this.messageService.add({ type: 'danger', title: 'Failure', message: 'Copy Failed!', timeout: 1000 });
    }
  }

  // Doctor Followup create, delete and open modal functions
  openFollowupModal() {
    this.modalInstance = this.modalService.open(this.modalTemplate, { centered: true });
  }

  // The textarea has a default template which is editable and changes according to the selected value
  updateTextContent() {
    if (this.doctorFollowUpTemplate) {
      this.doctorFollowUpText.setValue(
        this.doctorFollowUpTemplate
          .replace('{modality}', this.selectedScanType.value || '')
          .replace('{doctor_followup_link}', ''),
      );
    } else {
      this.doctorFollowUpText.reset();
    }
  }

  deleteLink() {
    const confirmModalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: 'Do you want to delete the followup link?',
      btnConfirmText: 'Yes',
    });

    confirmModalRef.result
      .then(() => {
        this.subscription.add(
          this.doctorFollowupEffect
            .delete(this.report.id)
            .pipe(switchMap(() => this.reportEffect.findReportText(this.report.id).pipe(take(1))))
            .subscribe({
              next: (action) => {
                this.messageService.add({
                  title: 'Success',
                  type: 'success',
                  message: 'Followup link deleted',
                });

                this.hasFollowup = false;
                this.reportService.setReportText(action.report.text);
                confirmModalRef.dismiss();
              },
            }),
        );
      })
      .catch(() => {
        // Do nothing if the user cancels the confirmation
      });
  }

  createLink() {
    const requestData: RR.DoctorFollowUpPayload = {
      referrer_id: this.report.referrer_id,
      accession_number: this.report.accession_number,
      site_id: this.site?.id,
      modality: this.selectedScanType.value || '',
      text: this.doctorFollowUpText.value || '',
    };

    this.isLoading = true;
    this.subscription.add(
      this.doctorFollowupEffect
        .create(this.report.id, requestData)
        .pipe(
          switchMap(() => this.reportEffect.findReportText(this.report.id).pipe(take(1))),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (action) => {
            this.messageService.add({
              title: 'Success',
              type: 'success',
              message: 'Followup link added to report',
            });
            this.hasFollowup = true;
            this.modalInstance.dismiss();

            this.reportService.setReportText(action.report.text);
          },
        }),
    );
  }
  toggleShowTechnicianSignature() {
    this.showTechnicianSignature = !this.showTechnicianSignature;
    this.subscription.add(
      this.reportEffect
        .update(this.report.id, { show_technician_signature: this.showTechnicianSignature })
        .pipe(switchMap(() => this.reportEffect.findReportText(this.report.id).pipe(take(1))))
        .subscribe((action) => {
          this.reportService.setReportText(action.report.text);
        }),
    );
  }

  openNextReportModal() {
    NextReportModalComponent.open({ modalService: this.modalService, reportId: this.report.id });
  }
}
