import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { STATEMENT_LIST_ID } from 'app/app.constants';
import { BindObservable, getPatientAgeToday } from 'app/app.utils';
import { EditorService, FilterElement } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import { ElementFilterType } from 'app/store/editor';
import { fromPatient } from 'app/store/patient';
import { fromElementChoice } from 'app/store/report/element-choice';
import { fromStatementSet } from 'app/store/template/statement-set';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';

type IndexFilterType = 'AGE_FREQUENCY' | 'FREQUENCY' | 'LOGISTIC_REGRESSION' | 'NONE';

@Component({
  selector: 'rr-top-statement-button-group',
  templateUrl: './top-statement-button-group.component.html',
  styleUrls: ['./top-statement-button-group.component.scss'],
  standalone: true,
  imports: [SharedModule],
})
export class TopStatementButtonGroupComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() element: RR.Element;
  element$: Observable<RR.Element>;
  @BindObservable() @Input() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  @Input() report: RR.Report;
  @BindObservable() @Input() element_choice: RR.ElementChoice | null;
  element_choice$: Observable<RR.ElementChoice | null>;
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD';

  @Output() topStatements: EventEmitter<RR.Statement[]> = new EventEmitter();
  @Output() onScroll: EventEmitter<'HOME' | 'END'> = new EventEmitter();

  subscription: Subscription = new Subscription();
  statements: RR.Statement[] = [];
  // Filter statements in element.
  filterType: IndexFilterType;
  statementFrequencies: { statement_id: number; frequency: number }[] = [];
  frequencyFilterPercentage = 20;
  ageFilterPercentage = 20;
  lraFilterPercentage = 20;

  filterStatements: RR.Statement[] = []; // Filter statements after applying the threshold
  current_patient_age = 0;

  // Use for checking to filter out low frequency statements. We don't filter statement that has choices although it has low frequency
  statementChoices: RR.StatementChoice[] = [];

  firstFiltering = false;
  firstFiltering$: Subject<boolean> = new Subject();
  focusedChoiceId: number | null = null;
  filterElementEvent?: FilterElement;

  isLraLoading: boolean = false;

  constructor(
    private templateService: TemplateService,
    private reportService: ReportService,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.reportService
        .selectKioskUserSetting()
        .pipe(
          map((userSetting) => ({
            lraFilteringValue: userSetting.lra_statement_set_filtering_value,
            ageFilteringValue: userSetting.age_statement_set_filtering_value,
          })),
          distinctUntilChanged(),
        )
        .subscribe(({ lraFilteringValue, ageFilteringValue }) => {
          this.lraFilterPercentage = lraFilteringValue * 10;
          this.ageFilterPercentage = ageFilteringValue * 10;
          this.frequencyFilterPercentage = ageFilteringValue * 10; // Default to age filter percentage
          this.cd.detectChanges();
        }),
    );

    this.subscription.add(
      this.editorService
        .elementFilterType()
        .pipe(take(1))
        .subscribe((type) => {
          this.filterType = type;
        }),
    );

    this.subscription.add(
      this.editorService.prefill.subscribe((prefill) => {
        // When first init element component, leave the statement filtering for the element subscription to prevent
        // get_statement_frequencies or LRA api to be called twice. Moreover, when switching filtering type in prefill's
        // divider modal, not doing element filtering in the background
        if (this.firstFiltering && (!prefill || this.parent === 'PREFILL')) {
          this.filterStatementList({ refetch: true, scroll: true });
        }
      }),
    );

    // Reset frequencies of statements in element
    if (window['__e2e__']) {
      console.warn('Do not apply filter for e2e test');
      this.editorService.updateElementFilterType('NONE');
    }

    this.subscription.add(
      this.element$.subscribe(() => {
        // Reset statements data
        this.statements = [];
        this.filterStatements = [];
        this.statementFrequencies = [];
        this.firstFiltering = false;
        this.focusedChoiceId = null;
        this.topStatements.emit(this.filterStatements);
      }),
    );

    if (this.report.patient_id) {
      this.subscription.add(
        this.store.select(fromPatient.selectPatient(this.report.patient_id)).subscribe((patient) => {
          if (patient) {
            this.current_patient_age = getPatientAgeToday(patient) ?? 0;
          }
        }),
      );
    }

    this.subscription.add(
      this.element_choice$
        .pipe(
          switchMap((elementChoice) => {
            return elementChoice
              ? this.store.select(fromElementChoice.selectStatementChoices(elementChoice.id))
              : of([]);
          }),
        )
        .subscribe((statement_choices) => {
          return (this.statementChoices = statement_choices);
        }),
    );

    this.subscription.add(
      this.element$
        .pipe(switchMap((element) => this.store.select(fromStatementSet.selectStatements(element.statement_set_id))))
        .subscribe((statements) => {
          this.statements = statements;
        }),
    );

    this.subscription.add(
      this.element$
        .pipe(
          switchMap((element) =>
            this.store.select(fromStatementSet.selectLoadedInStatementSet(element.statement_set_id)).pipe(
              filter((loaded) => !!loaded),
              take(1),
            ),
          ),
        )
        .subscribe(() => {
          // First loading of the element, load frequency and apply filter
          this.filterStatementList({ refetch: true, scroll: true });
        }),
    );

    this.subscription.add(
      this.element$
        .pipe(switchMap(() => this.editorService.focusEvents))
        .pipe(
          // Only the element with the right target can handle the FilterElement event.
          // There might be multiple elements of the same target (Notepad modal), the elementId ensures this event is
          // handled by the correct element
          filter(
            (e): e is FilterElement =>
              e?.type === 'FilterElement' &&
              e.target === this.parent &&
              (!e.elementId || e.elementId === this.element.id),
          ),
        )
        .subscribe((e) => {
          this.filterElementEvent = e;
          if (this.firstFiltering) {
            // First filtering already done
            this.afterFilter();
          } else {
            this.filterElementEvent = e;
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  afterFilter() {
    this.firstFiltering = true;
    // Declare filterElementEvent in this scope so that arrow functions know that it isn't undefined
    const filterElementEvent = this.filterElementEvent;
    if (filterElementEvent) {
      if (filterElementEvent.statementId !== undefined) {
        this.unfilterStatement(filterElementEvent.statementId);
      }
      filterElementEvent.doneCallback();
      this.filterElementEvent = undefined;
    }
  }

  /**
   * Unfiltering a Statement allows us to navigate to it.
   */
  unfilterStatement(statementId: number) {
    const focusedStatement = this.statements.find((s) => s.id === statementId);
    if (!focusedStatement) return;
    const filterStatementIds = new Set(this.filterStatements.map((s) => s.id));
    // Add the statement and other statements in the same divider back in when focusing on the statement.
    this.filterStatements = this.statements.filter(
      (s) =>
        // Keep all the statements that were already filtered by LRA
        filterStatementIds.has(s.id) ||
        // Add in the new statement
        s.id === statementId ||
        // Add in other statements in the divider with the focused statement
        (focusedStatement.divider_id && s.divider_id === focusedStatement.divider_id) ||
        // If focused statement is a divider, add all statements under it back
        (focusedStatement.is_divider && s.divider_id === focusedStatement.id),
    );
    this.topStatements.emit(this.filterStatements);
  }

  onShowAllClicked() {
    this.filterType = 'NONE';
    this.editorService.updateElementFilterType('NONE');
    this.filterStatementList({ refetch: false, scroll: true });
    if (this.focusedChoiceId) {
      this.editorService.publishFocusChoice(this.focusedChoiceId);
    }
  }

  onFilterStatementClicked(type: ElementFilterType) {
    this.filterType = type;
    this.editorService.updateElementFilterType(type);
    if (this.focusedChoiceId) {
      this.editorService.publishFocusChoice(this.focusedChoiceId);
    }
  }

  getFilterPercentage(type: IndexFilterType) {
    if (type === 'AGE_FREQUENCY') {
      return this.ageFilterPercentage;
    } else if (type === 'FREQUENCY') {
      return this.frequencyFilterPercentage;
    } else if (type === 'LOGISTIC_REGRESSION') {
      return this.lraFilterPercentage;
    }
    return 0;
  }

  setFilterPercentage(type: IndexFilterType, percentage: number) {
    if (type === 'AGE_FREQUENCY') {
      this.ageFilterPercentage = percentage;
    } else if (type === 'FREQUENCY') {
      this.frequencyFilterPercentage = percentage;
    } else if (type === 'LOGISTIC_REGRESSION') {
      this.lraFilterPercentage = percentage;
    }
  }

  changeFilterPercentage(val: number) {
    let filterPercentage = this.getFilterPercentage(this.filterType);

    filterPercentage = Math.min(Math.max(filterPercentage + val, 10), 100);

    this.setFilterPercentage(this.filterType, filterPercentage);

    if (this.filterType !== 'NONE') {
      this.filterStatementsByThreshold({ scroll: false });
    }
  }

  onScrollClicked(type: 'HOME' | 'END') {
    this.onScroll.emit(type);
  }

  filterByLogisticRegression({ scroll }: { scroll: boolean }) {
    this.isLraLoading = true;
    // Switch to ALL filter while the api is loading
    this.onFilterStatementClicked('NONE');
    // Show all statements while the filter is still loading
    this.filterByAll(scroll);
    this.subscription.add(
      this.templateService
        .getStatementFrequenciesLogisticRegression(this.element.statement_set_id, this.topic.id)
        .pipe(
          finalize(() => {
            this.isLraLoading = false;
          }),
        )
        .subscribe((result) => {
          // Check if statement_set_id changes during fetching api (navigate between element too quickly)
          if (result.statement_set_id === this.element.statement_set_id) {
            this.statementFrequencies = result.frequencies;
            // then revert to LRA filter
            if (this.filterType !== 'LOGISTIC_REGRESSION') {
              this.onFilterStatementClicked('LOGISTIC_REGRESSION');
            }

            this.filterStatementsByThreshold({ scroll });
          }
        }),
    );
  }

  filterByAgeFrequency({ scroll, type }: { scroll: boolean; type?: IndexFilterType }) {
    // Only send the filter type when a filter button is clicked, not during page load
    if (type) {
      this.onFilterStatementClicked(type);
    }

    this.subscription.add(
      this.templateService
        .getStatementFrequencies(
          this.element.statement_set_id,
          this.topic.template_id,
          this.topic.id,
          // @ts-expect-error strictNullChecks
          this.filterType === 'AGE_FREQUENCY' ? this.current_patient_age : null,
        )
        .subscribe((result) => {
          // Check if statement_set_id changes during fetching api (navigate between element too quickly)
          if (result.statement_set_id === this.element.statement_set_id) {
            this.statementFrequencies = result.frequencies;
            this.filterStatementsByThreshold({ scroll });
          }
        }),
    );
  }

  filterByAll(scroll: boolean) {
    this.filterStatements = this.statements;
    this.topStatements.emit(this.filterStatements);
    if (scroll) this.scrollToStatementList();
    if (!this.firstFiltering) {
      this.afterFilter();
    }
  }

  filterStatementList({ refetch = true, scroll = false }: { refetch?: boolean; scroll?: boolean } = {}) {
    if (this.filterType !== 'NONE') {
      if (refetch) {
        if (this.filterType === 'LOGISTIC_REGRESSION') {
          this.filterByLogisticRegression({ scroll });
        } else {
          this.filterByAgeFrequency({ scroll });
        }
      } else {
        this.filterStatementsByThreshold({ scroll });
      }
    } else {
      this.filterByAll(scroll);
    }
  }

  getStatementFrequency(statement_id: number) {
    const frequencyObj = this.statementFrequencies.find((f) => f.statement_id === statement_id);
    return frequencyObj ? frequencyObj.frequency : 0;
  }

  filterStatementsByThreshold({ scroll, divider_id }: { scroll: boolean; divider_id?: number }) {
    // Get number of statements in element, then filter to get top x% of statements
    const size = this.statements.filter((s) => !s.is_divider).length;
    const topTenIndex = Math.floor(size * (this.frequencyFilterPercentage / 100));
    const sortedList = this.statementFrequencies.sort((a, b) => b.frequency - a.frequency);

    let freqThreshold = 0;
    if (sortedList.length > topTenIndex) {
      freqThreshold = sortedList[topTenIndex].frequency;
    }
    // Default always filter out statements with frequency = 0 unless filter top 100%
    this.filterStatements = this.statements.filter(
      (s) =>
        (this.getStatementFrequency(s.id) >= freqThreshold &&
          (this.getStatementFrequency(s.id) > 0 || this.frequencyFilterPercentage === 100)) ||
        this.statementChoices.some((choice) => choice.statement_id === s.id) ||
        s.is_divider ||
        (divider_id && s.divider_id === divider_id),
    );

    // Emit topStatements to parent component
    this.topStatements.emit(this.filterStatements);
    if (scroll) {
      this.scrollToStatementList();
    }
    // If first filtering, emit event so that component know to start subscribing to focus statement event
    if (!this.firstFiltering) {
      this.afterFilter();
    }
  }

  expandDivider(divider_id: number) {
    this.filterStatementsByThreshold({ scroll: false, divider_id });
  }

  scrollToStatementList() {
    const el = document.getElementById(STATEMENT_LIST_ID);
    if (el) el.scrollIntoView();
  }
}
