import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { CategoryActions } from './category.action';
import { CategoryHttpService } from './category.service';

@Injectable()
export class CategoryEffect {
  constructor(
    private store: Store<AppState>,
    private service: CategoryHttpService,
  ) {}

  findAll(params?: HttpParams) {
    // @ts-expect-error strictNullChecks
    return this.service.findAll(params).pipe(
      map((categories: RR.Category[]) => CategoryActions.findAllSuccess({ categories })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(category: Partial<RR.Category>) {
    return this.service.create(category).pipe(
      map((category: RR.Category) => CategoryActions.createSuccess({ category })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(categoryId: number, changes: Partial<RR.Category>) {
    return this.service.update(categoryId, changes).pipe(
      map((category) => CategoryActions.updateSuccess({ category })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(categoryId: number) {
    return this.service.delete(categoryId).pipe(
      map(() => CategoryActions.deleteSuccess({ categoryId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
