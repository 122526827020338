import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReportActions } from '../report/report/report.action';
import { WorklistActions } from '../worklist/worklist.action';
import { ReportMergeConflictActions } from './report-merge-conflict.action';
import { ReportMergeConflictService, RequestMergeReport } from './report-merge-conflict.service';

@Injectable()
export class ReportMergeConflictEffect {
  constructor(
    private service: ReportMergeConflictService,
    private store: Store<AppState>,
  ) {}

  findConflicts(leftReportId: number, rightReportId: number) {
    return this.service.findConflicts(leftReportId, rightReportId).pipe(
      map((conflicts) => {
        const action = ReportMergeConflictActions.findAllSuccess({ reportMergeConflicts: conflicts });
        this.store.dispatch(action);
        // Initialise side to left.
        this.store.dispatch(
          ReportMergeConflictActions.selectAllConflictSide({
            side: 'left',
            fieldNames: conflicts.map((c) => c.field_name),
          }),
        );
        return action;
      }),
    );
  }

  merge(data: RequestMergeReport) {
    return this.service.merge(data).pipe(
      map((report) => {
        this.store.dispatch(WorklistActions.unselectReport());
        return ReportActions.mergeSuccess({ request: data, report });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
