import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdown, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { StepService } from 'app/modules/editor/step.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { TitleOptionEffect } from 'app/store/title/title-option';
import { TitleSetEffect } from 'app/store/title/title-set';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { TitleDeleteModalComponent } from '../../../../editor/title-delete-modal/title-delete-modal.component';

interface Word {
  value: string;
  checked: boolean;
}

type WordList = Word[];

type Title = WordList[];

type FilteredTitleType = {
  text: string | undefined;
  id: number;
  score: number;
};

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, NgbModule, AutoFocusDirective],
  selector: 'rr-report-title-edit',
  templateUrl: './title-edit.component.html',
  styleUrls: ['./title-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class TitleEditComponent implements AfterViewInit, OnDestroy {
  @BindObservable() @Input() topic: RR.Topic;
  @Input() dropdown: NgbDropdown;
  topic$: Observable<RR.Topic>;
  @Input() confirmCentered = true; // Position to show confirm modal
  @Input() mode: 'dropdown' | 'normal' = 'normal';
  @Output() onCreateTitle = new EventEmitter<void>();

  subscription = new Subscription();
  conflated_options: Title[];
  title_option$: Observable<RR.TitleOption | undefined>;
  title_set$: Observable<RR.TitleSet>;
  titles: RR.TitleOption[];
  title_text = '';
  isAddingTitle = false;
  loading = true;
  isDeletingTitle = false;
  isEditingTitle = false;

  newTitleText = new FormControl('', { nonNullable: true });
  search_string = new FormControl('', { nonNullable: true });

  constructor(
    private reportService: ReportService,
    private templateService: TemplateService,
    private modal: NgbModal,
    private cd: ChangeDetectorRef,
    private titleOptionEffect: TitleOptionEffect,
    private titleSetEffect: TitleSetEffect,
    private stepService: StepService,
  ) {}

  ngAfterViewInit() {
    this.subscription.add(
      this.topic$.subscribe((topic) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (topic && topic.title_option_text) {
          this.title_text = topic.title_option_text;
        }
      }),
    );

    this.subscription.add(
      this.topic$
        .pipe(switchMap((topic) => this.templateService.getTitleOptions(topic.template_id)))
        .subscribe((titles) => {
          // @ts-expect-error strictNullChecks
          this.titles = titles;
        }),
    );
    this.title_set$ = this.topic$.pipe(switchMap((topic) => this.templateService.getTitleSet(topic.template_id)));
    this.title_option$ = this.topic$.pipe(switchMap((topic) => this.templateService.getTopicTitleOption(topic.id)));

    this.subscription.add(
      combineLatest([this.title_set$, this.title_option$]).subscribe(([title_set, title_option]) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!title_set) return;
        this.conflated_options = (<string[][][]>title_set.conflated_options).map((option_array) =>
          option_array.map((wordList) => {
            return wordList.map((value) => {
              return {
                value,
                checked: false,
              };
            });
          }),
        );
        if (title_option) {
          // Finds the matching title and checks the checkboxes
          const choice_words = title_option.text.split(' ');
          const title_index = this.conflated_options.findIndex((option_array) =>
            choice_words.every((str, i) => {
              const word_list = option_array[i];
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              return word_list && word_list.map((word) => word.value).includes(str);
            }),
          );
          if (title_index !== -1) {
            this.conflated_options[title_index] = this.conflated_options[title_index].map((word_list, i) => {
              return word_list.map((word) => {
                if (word.value === choice_words[i]) {
                  word.checked = true;
                }
                return word;
              });
            });
          }
        }
        this.loading = false;
        this.cd.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save() {
    if (
      // Don't show warning modal when there is no title
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      this.topic.title_option_text !== null &&
      // Don't show warning modal when the selected title is same as previous one
      this.topic.title_option_text !== this.title_text &&
      // Don't show warning when adding new title
      !this.isAddingTitle &&
      // Don't show warning when deleting title
      !this.isDeletingTitle
    ) {
      const header_text = 'Confirm (Tip: Use Space or Enter keys to quickly confirm)';
      const warning_text = 'Are you sure you want to change the title to ' + this.title_text + '?';
      const modalRef = ConfirmMessageModalComponent.open({
        modalService: this.modal,
        header: header_text,
        message: warning_text,
        btnConfirmText: 'Yes',
        centered: this.confirmCentered,
      });
      modalRef.result.then(
        () => {
          this.saveTitle();
        },
        () => {
          /* If user clicks cancel, open the dropdown */
          this.dropdown.open();
        },
      );
    } else {
      // Avoid error when adding a new title
      if (!this.isAddingTitle) {
        this.saveTitle();
      }
    }
  }

  get filteredTitles(): FilteredTitleType[] {
    const search_bag = new Set(this.search_string.value.trim().toUpperCase().split(' '));
    const titleScore = (title: RR.TitleOption) => {
      const text = title.text.toUpperCase();
      const title_bag = new Set(text.split(' '));
      let score = 0;
      search_bag.forEach((word) => {
        const partial_match = [...title_bag].sort((s, t) => t.length - s.length).find((s) => s.startsWith(word));
        // @ts-expect-error strictNullChecks
        score += +title_bag.has(word) || !!partial_match ? word.length / partial_match.length : 0;
      });
      return score;
    };
    return this.titles
      .map((t) => ({
        text: t.text,
        id: t.id,
        score: titleScore(t),
      }))
      .filter((o) => o.score > search_bag.size / 2)
      .sort((a, b) => a.score - b.score);
  }

  setTitleFromSearch(title: FilteredTitleType) {
    if (title.text) {
      this.subscription.add(this.setTopicTitle(this.topic.id, title.id, title.text).subscribe());
    }
    this.search_string.reset();
    this.saveTitle();
  }

  clickOrTickTitle(title_index: number, word_list_index?: number, word_index?: number) {
    this.isEditingTitle = true;
    this.updateTitle(title_index, word_list_index, word_index);
    if (this.mode === 'normal') {
      this.saveTitle();
    }
  }

  updateTitle(title_index: number, word_list_index?: number, word_index?: number) {
    this.updateSelectedTitle(title_index, word_list_index, word_index);
    this.updateConflatedOptions(title_index);
    this.updateTitleText(title_index);
  }

  updateSelectedTitle(title_index: number, word_list_index?: number, word_index?: number) {
    if (typeof word_list_index === 'number') {
      this.conflated_options[title_index][word_list_index] = this.conflated_options[title_index][word_list_index].map(
        (word, word_i) =>
          word_index === word_i ? { checked: true, value: word.value } : { checked: false, value: word.value },
      );
    }
  }

  updateConflatedOptions(title_index: number) {
    const code_lastchar = this.topic.code ? this.topic.code.slice(-1).toLowerCase() : undefined;
    this.conflated_options = this.conflated_options.map((title, title_i) => {
      if (title_i === title_index) {
        return this.updateCurrentTitle(title, code_lastchar);
      }
      return this.clearTitleSelection(title);
    });
  }

  updateCurrentTitle(title: Title, code_lastchar?: string): Title {
    return title.map((word_list) => {
      if (word_list.length > 1 && !word_list.some((word) => word.checked)) {
        return this.autoSelectWord(word_list, code_lastchar);
      }
      return word_list;
    });
  }

  autoSelectWord(word_list: WordList, code_lastchar?: string): WordList {
    const caps_array = word_list.map((word) => word.value.toUpperCase());
    const left_index = caps_array.indexOf('LEFT');
    const right_index = caps_array.indexOf('RIGHT');
    if (code_lastchar === 'l' && left_index !== -1) {
      word_list[left_index].checked = true;
    } else if (code_lastchar === 'r' && right_index !== -1) {
      word_list[right_index].checked = true;
    } else {
      word_list[0].checked = true;
    }
    return word_list;
  }

  clearTitleSelection(title: Title): Title {
    return title.map((word_list) => word_list.map((word) => ({ value: word.value, checked: false })));
  }

  updateTitleText(title_index: number) {
    this.title_text = this.conflated_options[title_index]
      .map((word_list) => (word_list.length === 1 ? word_list[0].value : word_list.find((w) => w.checked)?.value || ''))
      .join(' ');
  }

  createTitleOption() {
    this.setIsAddingTitleState(true);
    const newTitleText = this.newTitleText.value.replace(/\s+/g, ' ').trim().toUpperCase();
    this.title_text = newTitleText;
    this.newTitleText.reset();
    this.saveTitle();

    this.subscription.add(
      this.title_set$
        .pipe(
          take(1),
          switchMap((title_set) => {
            const changes = {
              title_options: [{ text: newTitleText, id: undefined, title_set_id: title_set.id }], // Use undefined instead of null
            };
            // @ts-expect-error strictNullChecks
            return this.titleSetEffect.update(title_set.id, changes);
          }),
          switchMap(() => this.templateService.getTitleOptionFromText(this.topic.template_id, newTitleText)),
          // @ts-expect-error strictNullChecks
          switchMap((option) => this.reportService.setTopicTitle(this.topic.id, option.id, newTitleText)),
          take(1),
        )
        .subscribe({
          next: () => {
            this.setIsAddingTitleState(false);
            this.cd.detectChanges();
          },
          error: () => {
            this.setIsAddingTitleState(false);
          },
        }),
    );
  }

  setIsAddingTitleState(isProcessing: boolean) {
    if (isProcessing) {
      this.search_string.disable();
    } else {
      this.search_string.enable();
    }
    this.isAddingTitle = isProcessing;
  }

  titleMenuPromptRemove(option_array: { value: string; checked: boolean }[][]) {
    this.isDeletingTitle = true;
    const modalRef = this.modal.open(TitleDeleteModalComponent, {
      size: 'lg',
    });
    const componentInstance: TitleDeleteModalComponent = modalRef.componentInstance;
    componentInstance.option_array = option_array;
    modalRef.result.then(
      (selections) => {
        if (selections != null) {
          this.subscription.add(
            this.templateService
              .enumerateTitleOptions(
                option_array.map((word_list) => word_list.map((w) => w.value)),
                selections,
                this.topic.template_id,
              )
              .pipe(
                take(1),
                switchMap((titles) =>
                  // @ts-expect-error strictNullChecks
                  this.titleOptionEffect.deleteTitles(titles.map((t) => t.id)).pipe(
                    take(1),
                    // @ts-expect-error strictNullChecks
                    switchMap(() => this.titleSetEffect.find(titles[0].title_set_id)),
                  ),
                ),
              )
              .subscribe(() => (this.isDeletingTitle = false)),
          );
        }
      },
      () => {
        /* do nothing */
      },
    );
  }

  saveTitle() {
    this.subscription.add(
      this.templateService
        .getTitleOptionFromText(this.topic.template_id, this.title_text)
        .pipe(
          take(1),
          switchMap((title) => {
            if (!title || title.id === this.topic.title_option_id) {
              return of(null);
            }
            return this.setTopicTitle(this.topic.id, title.id, title.text);
          }),
        )

        .subscribe(() => {
          this.newTitleText.reset();
          this.isAddingTitle = false;
          this.isEditingTitle = false;
          this.onCreateTitle.emit();
        }),
    );
  }

  setTopicTitle(topic_id: number, title_option_id: number, title_option_text: string) {
    return this.reportService.setTopicTitle(topic_id, title_option_id, title_option_text).pipe(
      tap(() => {
        this.stepService.completeStep('title');
      }),
    );
  }
}
