<div class="d-flex flex-column flex-grow-1">
  <div class="form-group mb-2 mt-3">
    <div class="d-flex justify-content-between">
      Move to:
      <span class="me-1">
        <i class="fa fa-lightbulb text-warning"></i>Hint: Don't forget you can press 'b' to jump back to the starting
        point after moving a statement.
      </span>
    </div>
    <div class="btn-group d-flex justify-content-center py-1" role="group">
      <input
        class="btn-check"
        type="radio"
        value="history"
        [formControl]="selectedSection"
        id="statement-edit-history"
        (click)="onSectionChanged('history')"
      />
      <label class="btn btn-outline-primary btn-sm" for="statement-edit-history"> History </label>

      <input
        id="statement-edit-findings"
        class="btn-check"
        type="radio"
        value="findings"
        [formControl]="selectedSection"
        (click)="onSectionChanged('findings')"
      />
      <label for="statement-edit-findings" class="btn btn-outline-primary btn-sm"> Findings </label>

      <input
        id="statement-edit-technique"
        class="btn-check"
        type="radio"
        value="technique"
        [formControl]="selectedSection"
        (click)="onSectionChanged('technique')"
      />
      <label for="statement-edit-technique" class="btn btn-outline-primary btn-sm"> Technique </label>

      <input
        id="statement-edit-comment"
        class="btn-check"
        type="radio"
        value="comment"
        [formControl]="selectedSection"
        (click)="onSectionChanged('comment')"
      />
      <label for="statement-edit-comment" class="btn btn-outline-primary btn-sm"> Comment </label>

      <input
        id="statement-edit-ir"
        class="btn-check"
        type="radio"
        value="impression_recommendations"
        [formControl]="selectedSection"
        (click)="onSectionChanged('impression_recommendations')"
      />
      <label for="statement-edit-ir" class="btn btn-outline-primary btn-sm"> Impression & Recommendations </label>
    </div>
  </div>

  <div class="form-group mb-4">
    <input
      type="text"
      class="form-control"
      placeholder="Search by element name"
      #searchTextInput
      (ngModelChange)="searchTextChanged($event)"
      [(ngModel)]="searchText"
    />
  </div>
  <div class="container-fluid">
    <div class="row gx-4">
      <div class="col-6 flex-grow-1">
        <form class="form-group d-flex flex-column max-height overflow-scroll flex-grow-1">
          <div *ngFor="let element of filteredElements">
            <div class="form-check d-flex" id="element-{{ element.statement_set.id }}">
              <input
                class="form-check-input"
                type="radio"
                name="set"
                [(ngModel)]="selectedElement"
                [value]="element"
                id="set-{{ element.statement_set.id }}"
                (change)="onElementSelected($event)"
              />
              <label
                class="mb-0 form-check-label"
                [ngClass]="{ 'fw-bold': element === selectedElement }"
                for="set-{{ element.statement_set.id }}"
              >
                {{ element.statement_set.name ? element.statement_set.name : 'NO NAME' }}
              </label>
            </div>
            <div>
              <span class="badge rounded-pill bg-primary">
                {{ element.subsection.name ? element.subsection.name : 'NO NAME' }}
              </span>
            </div>
            <hr />
          </div>
        </form>
      </div>
      <div class="col-6 d-flex flex-column flex-grow-1" *ngIf="(statements$ | async)?.length">
        <div class="d-flex">
          <h3>Before statement:</h3>
          <div class="ms-auto" *ngIf="selectedElement">
            <button
              role="button"
              (click)="onMoveStatementClicked(selectedElement.statement_set.id, topStatement.id)"
              class="btn btn-sm btn-info me-1"
            >
              <span class="fa fa-arrow-up"></span> Move to top
            </button>
            <button
              role="button"
              (click)="onMoveStatementClicked(selectedElement.statement_set.id, null)"
              class="btn btn-sm btn-info"
            >
              <span class="fa fa-arrow-down"></span> Move to bottom
            </button>
          </div>
        </div>
        <div class="list-group d-flex flex-column max-height overflow-scroll flex-grow-1">
          <ng-container *ngFor="let statementRow of statements$ | async; let i = index">
            <button
              rr-statement
              *ngIf="statementRow.statement_set_id"
              type="stub"
              class="list-group-item list-group-item-action text-start w-100"
              [class.current-statement]="statement.id === statementRow.id"
              [statement]="statementRow"
              [topic]="topic"
              [template_id]="topic.template_id"
              (click)="onMoveStatementClicked(statementRow.statement_set_id, statementRow.id)"
            ></button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
