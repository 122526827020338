import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-report-sign-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-sign-badge.component.html',
  styleUrl: './report-sign-badge.component.css',
})
export class ReportSignBadgeComponent implements OnInit, OnDestroy {
  isSigned: boolean;
  @Input() reportId: number;
  subscription = new Subscription();
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromReport.selectTopics(this.reportId)).subscribe((topics) => {
        this.isSigned = topics.length > 0 && topics.filter((topic) => !topic.signature_id).length === 0;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
