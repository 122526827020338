import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportService } from 'app/core/services/report.service';
import { DicomEffect, ProposedDataset } from 'app/store/dicom';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { Subscription } from 'rxjs';
import { filter, finalize, take } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import { ImgsimParametersModalComponent } from '../imgsim-parameters-modal/imgsim-parameters-modal.component';
import { RecommendedStatementComponent } from '../statement-recommendation/recommended-statement/recommended-statement.component';

@Component({
  selector: 'rr-dicom-sr-modal',
  templateUrl: './dicom-sr-modal.component.html',
  styleUrls: ['./dicom-sr-modal.component.css'],
  standalone: true,
  imports: [CommonModule, RecommendedStatementComponent, SharedModule],
})
export class DicomSrModalComponent implements OnInit, OnDestroy {
  @Input() topic: RR.Topic;
  @ViewChildren(RecommendedStatementComponent) recommendedStatementComponents: RecommendedStatementComponent[];

  data: ProposedDataset[];
  loadingProposed = false;
  subscription = new Subscription();
  report: RR.Report;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    private statementChoiceEffect: StatementChoiceEffect,
    private dicomEffect: DicomEffect,
    private modalService: NgbModal,
  ) {}

  loadProposed() {
    this.loadingProposed = true;
    this.dicomEffect
      .propose(this.topic.id)
      .pipe(
        finalize(() => {
          this.loadingProposed = false;
          this.cd.detectChanges();
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((action) => {
        this.data = action.datasets;
        this.cd.detectChanges();
      });
  }

  openImgsimParameters() {
    ImgsimParametersModalComponent.open(this.modalService, this.report);
    this.activeModal.close();
  }

  chooseAllProposed() {
    const data = this.recommendedStatementComponents.map((component) => {
      // Reach into the component a pull out the element, rather than repeating the complex selector here.
      if (component.proposed === undefined) {
        throw new Error('Proposed is undefined');
      }
      return {
        statement_id: component.statement.id,
        element_id: component.element.id,
        text_objects: this.reportService.createTextObjectsFromProposed(component.proposed),
        region_id: null, // TODO: regions not supported
      };
    });

    this.subscription.add(this.statementChoiceEffect.createMany(this.topic.id, data).subscribe());
  }

  ngOnInit(): void {
    this.subscription.add(
      this.reportService.getReport(this.topic.report_id).subscribe((report) => {
        this.report = report;
      }),
    );

    // When it knows the report.has_dicom_sr_cached, then it can load proposed
    this.subscription.add(
      this.reportService
        .getReport(this.topic.report_id)
        .pipe(
          // @ts-expect-error strictNullChecks
          filter((report) => report.has_dicom_sr_cached),
          take(1),
        )
        .subscribe(() => {
          this.loadProposed();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modal: NgbModal, topic: RR.Topic) {
    const modalRef = modal.open(DicomSrModalComponent, {
      size: 'lg',
      centered: true,
    });
    const componentInstance: DicomSrModalComponent = modalRef.componentInstance;
    componentInstance.topic = topic;
    return modalRef;
  }
}
