/**
 * This code is shared between the Angular and proofreading code. It is in a separate file to prevent the proofreading
 * bundle from including DOM/browser code (web workers can't access the DOM).
 */

const punctuation_list = [
  '!',
  '"',
  '#',
  "'",
  '(',
  ')',
  '*',
  '+',
  ',',
  '.',
  '-',
  '—',
  '–',
  '/',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '[',
  '\\',
  ']',
  '^',
  '_',
  '`',
  '{',
  '|',
  '}',
  '~',
  '…',
];

export const referrerTypes: {
  value: RR.ReferrerType;
  name: string;
}[] = [
  { value: 'gp', name: 'GP' },
  { value: 'physio', name: 'Physio (Allied health)' },
  { value: 'podiatrist', name: 'Podiatrist (Allied health)' },
  { value: 'chiropractor', name: 'Chiropractor (Allied health)' },
  { value: 'osteopath', name: 'Osteopath (Allied health)' },
  { value: 'nurse_practitioner', name: 'Nurse Practitioner (Allied health)' },
  { value: 'specialist', name: 'Specialist' },
  { value: 'midwife', name: 'Midwife' },
  { value: 'medical_officer', name: 'Medical Officer' },
];

const punctuationEscaped = punctuation_list.map((c) => escapeRegex(c));
const punctuation_regexp = regexp_from_word_array(punctuationEscaped, undefined, undefined, 'g');

export const referrerTypeMap: Record<RR.ReferrerType, string> = {
  gp: 'GP',
  physio: 'Physio (Allied health)',
  podiatrist: 'Podiatrist (Allied health)',
  chiropractor: 'Chiropractor (Allied health)',
  osteopath: 'Osteopath (Allied health)',
  nurse_practitioner: 'Nurse Practitioner (Allied health)',
  specialist: 'Specialist',
  midwife: 'Midwife',
  medical_officer: 'Medical Officer',
};

export function stripPunctuation(text: string) {
  const leading_whitespace_regexp = /^ +/;
  const trailing_whitespace_regexp = / +$/;
  let return_value = text.replace(punctuation_regexp, ' ');
  // strip trailing and leading whitespace unless is part of original string
  if (!leading_whitespace_regexp.test(text)) return_value = return_value.replace(leading_whitespace_regexp, '');
  if (!trailing_whitespace_regexp.test(text)) return_value = return_value.replace(trailing_whitespace_regexp, '');
  // .replace(/ +/g, ' '); // replace more than 1 space, with a single space
  return return_value;
}

export function regexp_from_word_array(arr: string[], prefix?: string, postfix?: string, flags?: string): RegExp {
  return new RegExp(`${prefix ?? ''}(?:${arr.join('|')})${postfix ?? ''}`, flags ?? '');
}

export function escapeRegex(text: string) {
  // jQuery UI's escapeRegex()
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function getEmailValidatorRegex() {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+(?!comau)[a-zA-Z]{2,}))$/;
}

export function checkIfEmail() {
  return new RegExp(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(\.[A-Za-z]{2,})?\b/g);
}
