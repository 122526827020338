import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { InvoiceActions } from './invoice.action';

export interface InvoiceState extends EntityState<RR.Invoice> {
  loaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.Invoice>();

const initialState = adapter.getInitialState({
  loaded: {},
});

export const invoiceReducer = createReducer(
  initialState,
  on(InvoiceActions.searchSuccess, (state: InvoiceState, { invoices }) => adapter.upsertMany(invoices, state)),
  on(InvoiceActions.findManySuccess, (state: InvoiceState, { invoices }) => adapter.upsertMany(invoices, state)),
  on(InvoiceActions.findInReportSuccess, (state: InvoiceState, { reportId, invoices }) => ({
    ...adapter.upsertMany(invoices, state),
    loaded: { ...state.loaded, [reportId]: true },
  })),
  on(InvoiceActions.findInBookingSuccess, (state: InvoiceState, { bookingId, invoices }) => ({
    ...adapter.upsertMany(invoices, state),
    loaded: { ...state.loaded, [bookingId]: true },
  })),
  on(InvoiceActions.createSuccess, (state: InvoiceState, { invoice }) => adapter.addOne(invoice, state)),
  on(InvoiceActions.updateSuccess, (state: InvoiceState, { invoice }) => adapter.upsertOne(invoice, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
