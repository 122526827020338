import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type CreateBookingRate = Pick<RR.BookingRate, 'user_id' | 'modality' | 'scans_per_hour'>;

@Injectable()
export class BookingRateHttpService {
  constructor(private http: HttpClient) {}

  create(data: CreateBookingRate) {
    return this.http.post<{
      user: RR.User;
      booking_rate: RR.BookingRate;
    }>('/api/booking_rate', data);
  }

  delete(bookingRateId: number) {
    return this.http.delete<RR.User>(`/api/booking_rate/${bookingRateId}`);
  }
}
