import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Institution>();
const selectFeature = (state: AppState) => state.institution;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInstitution = (id: number) =>
  createSelector(selectEntities, (institutions: Dictionary<RR.Institution>) => institutions[id]);

export const fromInstitution = {
  selectIds,
  selectEntities,
  selectAll,
  selectInstitution,
};
