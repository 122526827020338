<div class="modal-header">
  <h5 class="modal-title">
    Reenter your admin password (<rr-user-headline *ngIf="currentUser | async as user" [user]="user"></rr-user-headline
    >)
  </h5>
  <button class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<form (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group mb-2">
      <input ngbAutoFocus type="password" class="form-control" placeholder="Password" [formControl]="password" />
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" type="submit">Submit</button>
      <button class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  </div>
</form>
