import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RegionHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Region[]> {
    return this.http.get<RR.Region[]>('/api/region');
  }

  create(region: Partial<RR.Region>): Observable<{ region: RR.Region; regionSet: RR.RegionSet }> {
    return this.http.post<{ region: RR.Region; regionSet: RR.RegionSet }>('/api/region', region);
  }

  update(regionId: number, changes: Partial<RR.Region>): Observable<RR.Region> {
    return this.http.put<RR.Region>(`/api/region/${regionId}`, changes);
  }

  delete(regionId: number): Observable<void> {
    return this.http.delete<void>(`/api/region/${regionId}`);
  }

  findInTemplate(templateId: number): Observable<RR.Region[]> {
    return this.http.get<RR.Region[]>(`/api/template/${templateId}/region`);
  }
}
