import { createReducer, on } from '@ngrx/store';
import { RadReportVersionError } from 'app/core/interceptors/app-version-interceptor';

import { ToastActions } from './toast.action';

type BaseToast = {
  /**
   * if left as undefined, defaults to 5000. null for infinite timeout.
   */
  timeout?: number | null;
  id?: string; // replaces message with same id
};

export type NgbAlertToast = BaseToast & {
  title: string;
  message: string;
  type: 'warning' | 'success' | 'danger' | 'info';
};

export type VersionUpdateToast = BaseToast & {
  type: 'VersionUpdateToastComponent';
  data: RadReportVersionError;
};

export type Toast = NgbAlertToast | VersionUpdateToast;

export interface ToastState {
  toasts: Toast[];
}

const initialState: ToastState = {
  toasts: [],
};

export const toastReducer = createReducer(
  initialState,
  on(ToastActions.add, (state: ToastState, { toast }) => {
    if (toast.id) {
      const index = state.toasts.findIndex((t) => t.id === toast.id);
      if (index > -1) {
        // Replace toast with the same id
        return {
          ...state,
          toasts: [...state.toasts.slice(0, index), toast, ...state.toasts.slice(index + 1)],
        };
      }
    }
    return {
      ...state,
      toasts: [...state.toasts, toast],
    };
  }),
  on(ToastActions.remove, (state: ToastState, { toast }) => ({
    ...state,
    toasts: state.toasts.filter((t) => t !== toast),
  })),
);
