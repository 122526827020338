import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { fromInvoice, InvoiceEffect } from 'app/store/invoice';
import { fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';

import { InvoiceFormMetadataComponent } from '../invoice-form-metadata/invoice-form-metadata.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    InvoiceFormMetadataComponent,
    CdkAccordionModule,
    StoreSelectPipe,
    ReportAccessionNumberComponent,
  ],
  selector: 'rr-invoice-report-list',
  templateUrl: './invoice-report-list.component.html',
  styleUrls: ['./invoice-report-list.component.css'],
})
export class InvoiceReportListComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() invoiceId?: number | undefined;
  @Input() reInvoice?: boolean | undefined;
  invoice$: Observable<RR.Invoice | undefined>;
  report$: Observable<RR.Report | undefined>;

  subscription = new Subscription();

  constructor(
    private invoiceEffect: InvoiceEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));

    if (this.invoiceId) {
      this.invoice$ = this.store.select(fromInvoice.selectInvoice(this.invoiceId));
    }
  }

  reportSelectorFn = fromReport.selectReport;

  unlinkReport(reportId: number) {
    if (this.invoiceId) {
      this.subscription.add(this.invoiceEffect.unlinkReport(this.invoiceId, reportId).subscribe());
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
