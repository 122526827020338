import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { SendEventActions } from '../report/send-event/send-event.action';

export const ReferralEnquiryActions = {
  searchSuccess: createAction(
    '[Referral Enquiry] Fetch All Success',
    props<{ count: number; enquiries: RR.ReferralEnquiry[] }>(),
  ),

  updateSuccess: createAction('[Referral Enquiry] Update Success', props<{ enquiry: RR.ReferralEnquiry }>()),
  findSuccess: createAction('[Referral Enquiry] Get Success', props<{ enquiry: RR.ReferralEnquiry }>()),
  deleteSuccess: createAction('[Referral Enquiry] Delete Success', props<{ enquiryId: number }>()),
};

export const ReferralEnquiryBatchActions = {
  searchSuccess: createBatchAction(
    '[Referral Enquiry] Fetch All Success Batch',
    props<{
      actions: {
        searchReferralSuccess: ReturnType<typeof ReferralEnquiryActions.searchSuccess>;
        findManySendEventsSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
      };
    }>(),
  ),

  findSuccess: createBatchAction(
    '[Referral Enquiry] Get Success Batch',
    props<{
      actions: {
        findReferralSuccess: ReturnType<typeof ReferralEnquiryActions.findSuccess>;
        findManySendEventsSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
      };
    }>(),
  ),
  sendConfirmationSMSSuccess: createBatchAction(
    '[Referral Enquiry] Send Confirmation SMS Success Batch',
    props<{
      actions: {
        updateReferralSuccess: ReturnType<typeof ReferralEnquiryActions.updateSuccess>;
        createSendEventSuccess: ReturnType<typeof SendEventActions.sendSuccess>;
      };
    }>(),
  ),
};
