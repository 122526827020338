import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { TemplateActions } from './template.action';

export interface TemplateState extends EntityState<RR.Template> {
  loaded: { [templateId: number]: 'loading' | 'loaded' | undefined };
}

const adapter = createEntityAdapter<RR.Template>();

const initialState = adapter.getInitialState({
  loaded: {},
});

export const templateReducer = createReducer(
  initialState,
  on(TemplateActions.loadTemplate, (state, { templateId }) => ({
    ...state,
    loaded: { ...state.loaded, [templateId]: 'loading' },
  })),
  on(TemplateActions.loadTemplateSuccess, (state, { templateId }) => ({
    ...state,
    loaded: { ...state.loaded, [templateId]: 'loaded' },
  })),
  on(TemplateActions.findSuccess, (state, { template }) => adapter.addOne(template, state)),
  // Using upsertMany to prevent some fetched templates (including deleted) to be removed from the store
  on(TemplateActions.findAllSuccess, (state, { templates }) => adapter.upsertMany(templates, state)),
  on(TemplateActions.findManySuccess, (state, { templates }) => adapter.upsertMany(templates, state)),
  on(TemplateActions.createSuccess, (state, { template }) => adapter.addOne(template, state)),
  on(TemplateActions.updateSuccess, (state, { template }) => adapter.upsertOne(template, state)),
  on(TemplateActions.deleteSuccess, (state, { templateId }) =>
    adapter.updateOne({ id: templateId, changes: { deleted: true } }, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
