import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { UrgentNoteActions } from './urgent-note.action';

export type UrgentNotesState = EntityState<RR.UrgentNotes>;

const adapter = createEntityAdapter<RR.UrgentNotes>();

const initialState = adapter.getInitialState();

export const urgentNoteReducer = createReducer(
  initialState,
  on(UrgentNoteActions.findManySuccess, (state: UrgentNotesState, { urgentNotes }) =>
    adapter.upsertMany(urgentNotes, state),
  ),
  on(UrgentNoteActions.createSuccess, (state: UrgentNotesState, { urgentNote }) => adapter.addOne(urgentNote, state)),

  on(UrgentNoteActions.deleteSuccess, (state: UrgentNotesState, { urgentNoteId }) =>
    adapter.removeOne(urgentNoteId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
