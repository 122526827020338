<div class="w-100 px-1" role="button" (click)="gotoStatement()">
  <rr-statement-text-render
    [statement_id]="statement.id"
    [region]="region"
    [template_id]="topic.template_id"
  ></rr-statement-text-render>
  <span *ngIf="statementPreviewText$ | async as statementPreviewText" [linkify]="statementPreviewText"></span>
  <span
    role="button"
    (click)="openSentenceCategoryModal(); $event.stopPropagation()"
    *ngIf="!(isSentenceCategorised$ | async) && (is_in_sc_mode$ | async)"
    class="fa fa-layer-group text-danger ms-2"
    rrTooltip="CATEGORISE_TAG_SENTENCE"
  >
  </span>
</div>
