import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.UrgentNotes>();
const selectFeature = (state: AppState) => state.urgent_note;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInReport = (reportId: number) =>
  createSelector(selectAll, (urgent_notes) =>
    urgent_notes
      .filter((note) => note.report_id === reportId)
      .sort((t1, t2) => {
        return new Date(t2.created).getTime() - new Date(t1.created).getTime();
      }),
  );

export const fromUrgentNote = {
  selectIds,
  selectEntities,
  selectAll,
  selectInReport,
};
