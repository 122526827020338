import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { TopicActions } from '../topic/topic.action';

/**
 * Ngrx Actions for Favourite
 *
 */
export const FavouriteActions = {
  findSuccess: createAction('[Favourite] Find Success', props<{ favourite: RR.Favourite }>()),
  findManySuccess: createAction('[Favourite] Find Many Success', props<{ favourites: RR.Favourite[] }>()),

  createSuccess: createAction('[Favourite] Create Success', props<{ favourite: RR.Favourite }>()),

  updateSuccess: createAction('[Favourite] Update Success', props<{ favourite: RR.Favourite }>()),

  deleteSuccess: createAction('[Favourite] Delete Success', props<{ favouriteId: number }>()),

  updateManySuccess: createAction('[Favourite] Update Many Success', props<{ favourites: RR.Favourite[] }>()),
};

export const FavouriteBatchActions = {
  createFavouriteSuccessBatch: createBatchAction(
    '[Favourite] Create Success Batch',
    props<{
      actions: {
        updateTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        createFavouriteSuccess: ReturnType<typeof FavouriteActions.createSuccess>;
      };
    }>(),
  ),
};
