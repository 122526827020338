<div class="modal-header">
  <h4 class="modal-title">Make your mark</h4>
  <button role="button" class="btn-close" rrTooltip="INITIALS_CLOSE_BTN" (click)="cancel()"></button>
</div>
<form (submit)="submitInitials()" class="modal-body mb-0">
  <label class="form-label">Your initials or PIN:</label>
  <input
    #input
    class="form-control mb-2"
    [(ngModel)]="initials"
    name="initials"
    (keyup)="onInitialsChanged(initials)"
  />

  <div *ngIf="alert != null" class="alert alert-danger">{{ alert }}</div>
  <div class="alert alert-warning mt-2" *ngIf="noNewFeedbacks > 0">
    You have got {{ noNewFeedbacks }} new feedback or {{ noNewFeedbacks > 1 ? 'comments' : 'comment' }}. Please log in
    your account to check.
  </div>
  <div class="alert alert-info mt-2" *ngIf="numberOfSentenceCategorisation">
    Your categorisation performance today:
    <ul>
      <li>Number of categorised statements: {{ numberOfSentenceCategorisation }}</li>
    </ul>
  </div>

  <div *ngIf="users$ | async as users" class="d-flex flex-column mt-2">
    <span>Or quick initial:</span>
    <div class="list-group">
      <ng-container *ngFor="let user of users">
        <button
          *ngIf="user"
          type="button"
          class="list-group-item list-group-item-action"
          (click)="selectPreviousInitial(user)"
        >
          <rr-user-headline [user]="user"></rr-user-headline>
        </button>
        <button *ngIf="!user" type="button" class="list-group-item list-group-item-action" disabled>Loading...</button>
      </ng-container>
    </div>
  </div>
</form>
<div class="modal-footer">
  <button
    class="btn btn-success"
    role="button"
    *ngIf="kiosk$ && context === 'EDITOR'"
    (click)="hereAllDay()"
    rrTooltip="INITIALS_ENTERED_UNTIL_LOG_OUT"
    permission
    [permission-only]="['report_approve']"
  >
    I'm here all day ({{ (currentUser$ | async)?.initials }})
  </button>
  <button
    [disabled]="initials == null"
    class="btn btn-success"
    rrTooltip="INITIALS_START_EDITING"
    role="button"
    (click)="submitInitials()"
  >
    Start Editing
  </button>
  <button class="btn btn-light" rrTooltip="INITIALS_CLOSE_REPORT" role="button" (click)="cancel()">
    <rr-worklist-link *ngIf="context === 'EDITOR'"> Close Report </rr-worklist-link>
    <rr-worklist-link *ngIf="context === 'REGISTRATION'">Close Booking</rr-worklist-link>
    <ng-container *ngIf="context === undefined">Close</ng-container>
  </button>
</div>
