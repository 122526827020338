import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AllFormLinkPipe } from 'app/shared/pipes/all-form-link.pipe';
import { AvailableForm } from 'app/store/report/report';

@Component({
  selector: 'rr-all-form',
  standalone: true,
  imports: [CommonModule, AllFormLinkPipe, NgbDropdownModule],
  templateUrl: './all-form.component.html',
  styleUrl: './all-form.component.css',
})
export class AllFormComponent {
  @Input() reportId: number;
  @Input() availableForms?: AvailableForm[];
}
