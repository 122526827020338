<ng-container *ngIf="textObject$ | async as textObject">
  <rr-literal-placeholder *ngIf="textObject.type === 'literal'" [text_object]="textObject" [region]="region">
  </rr-literal-placeholder>
  <code *ngIf="textObject.type === 'number'" class="rr-statement-number">{{
    (proposed && proposed[textObject.id] && proposed[textObject.id][0].numeric_value) || textObject.formula || '**'
  }}</code>
  <span *ngIf="textObject.type === 'date'" class="rr-statement-date-placeholder"
    ><rr-date-type [dateType]="textObject.date_type"></rr-date-type
  ></span>
  <rr-attribute-placeholder
    [proposed]="proposed"
    [text_object]="textObject"
    [template_id]="template_id"
    [region]="region"
    *ngIf="textObject.type === 'set'"
  ></rr-attribute-placeholder>
</ng-container>
