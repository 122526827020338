import React from 'react';
import { RenderElementProps } from 'slate-react';

import { useSelectedStyle } from './useSelectedStyle';

export function NumberTextObjectComponent(
  props: RenderElementProps & { onClick: React.DOMAttributes<HTMLSpanElement>['onClick'] },
) {
  const { attributes, children, element, onClick } = props;
  if (element.type !== 'rr-number') {
    throw new Error('NumberTextObjectComponent can only render rr-number elements');
  }

  const style = useSelectedStyle();

  let text = '**';
  if (element.formula) {
    text = `=${element.formula}`;
  } else if (element.lower != null || element.upper != null) {
    text = `** [${element.lower ?? ''}-${element.upper ?? ''}]`;
  }

  return (
    <span className="rr-statement-attribute-placeholder" style={style} onClick={onClick} {...attributes}>
      {text}
      {children}
    </span>
  );
}
