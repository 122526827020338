import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { BookingActions } from '../booking/booking.action';
import { InstitutionActions } from '../institution/institution.action';
import { InvoiceItemActions } from '../invoice-item/invoice-item.action';
import { PaymentActions } from '../payment/payment.action';
import { ReportActions } from '../report/report/report.action';
import { InvoiceActions } from './invoice.action';
import {
  InvoiceBookingCreationHttpResponse,
  InvoiceBookingFindManyHttpResponse,
  InvoiceReportCreationHttpResponse,
  InvoiceReportFindManyHttpResponse,
  PostInvoiceData,
} from './invoice.service';
import { InvoiceUpdateReportsHttpResponse, InvoiceHttpService, InvoiceSearchHttpResponse } from './invoice.service';

@Injectable()
export class InvoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: InvoiceHttpService,
  ) {}

  search(queryBody: any, params: HttpParams) {
    return this.service.search(queryBody, params).pipe(
      map((response: InvoiceSearchHttpResponse) =>
        InvoiceActions.searchSuccessBatch({
          actions: {
            invoiceSearchSuccess: InvoiceActions.searchSuccess({
              invoices: response.invoices,
              count: response.count,
            }),
            invoiceItemFindManySuccess: InvoiceItemActions.findManySuccess({ invoiceItems: response.invoice_items }),
            institutionFindManySuccess: InstitutionActions.findManySuccess({ institutions: response.institutions }),
            findReportSuccess: ReportActions.upsertMany({ reports: response.reports }),
            findPaymentSuccess: PaymentActions.findManySuccess({ payments: response.payments }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInReport(reportId: number) {
    return this.service.findInReport(reportId).pipe(
      map((data: InvoiceReportFindManyHttpResponse) =>
        InvoiceActions.findInReportSuccessBatch({
          actions: {
            findInvoicesInReportSuccess: InvoiceActions.findInReportSuccess({ reportId, invoices: data.invoices }),
            invoiceItemFindManySuccess: InvoiceItemActions.findManySuccess({ invoiceItems: data.invoice_items }),
            institutionFindManySuccess: InstitutionActions.findManySuccess({ institutions: data.institutions }),
            reportFindManySuccess: ReportActions.upsertMany({ reports: data.reports }),
            findPaymentSuccess: PaymentActions.findManySuccess({ payments: data.payments }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInBooking(bookingId: number) {
    return this.service.findInBooking(bookingId).pipe(
      map((data: InvoiceBookingFindManyHttpResponse) =>
        InvoiceActions.findInBookingSuccessBatch({
          actions: {
            findInvoicesInBookingSuccess: InvoiceActions.findInBookingSuccess({
              bookingId,
              invoices: data.invoices,
            }),
            invoiceItemFindManySuccess: InvoiceItemActions.findManySuccess({ invoiceItems: data.invoice_items }),
            institutionFindManySuccess: InstitutionActions.findManySuccess({ institutions: data.institutions }),
            bookingFindManySuccess: BookingActions.findManySuccess({ bookings: data.bookings }),
            findPaymentSuccess: PaymentActions.findManySuccess({ payments: data.payments }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createBookingInvoice(bookingId: number, invoice: PostInvoiceData) {
    return this.service.createBookingInvoice(bookingId, invoice).pipe(
      map((data: InvoiceBookingCreationHttpResponse) => {
        return InvoiceActions.createBookingInvoiceSuccessBatch({
          actions: {
            createInvoiceSuccess: InvoiceActions.createSuccess({ invoice: data.invoice }),
            updateBookingSuccess: BookingActions.updateSuccess({
              booking: data.booking,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createReportInvoice(reportId: number, invoice: PostInvoiceData) {
    return this.service.createReportInvoice(reportId, invoice).pipe(
      map((data: InvoiceReportCreationHttpResponse) => {
        return InvoiceActions.createReportInvoiceSuccessBatch({
          actions: {
            createInvoiceSuccess: InvoiceActions.createSuccess({ invoice: data.invoice }),
            updateReportSuccess: ReportActions.upsertOne({
              report: data.report,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  linkReport(invoiceId: number, reportId: number) {
    return this.service.linkReport(invoiceId, reportId).pipe(
      map((data: InvoiceUpdateReportsHttpResponse) => {
        return InvoiceActions.linkReportSuccessBatch({
          actions: {
            updateReportSuccess: ReportActions.upsertOne({ report: data.report }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({ invoice: data.invoice }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  unlinkReport(invoiceId: number, reportId: number) {
    return this.service.unlinkReport(invoiceId, reportId).pipe(
      map((data: InvoiceUpdateReportsHttpResponse) => {
        return InvoiceActions.unlinkReportSuccessBatch({
          actions: {
            updateReportSuccess: ReportActions.upsertOne({ report: data.report }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({ invoice: data.invoice }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  linkMedipassTransaction(invoiceId: number, transactionId: string) {
    return this.service.linkMedipassTransaction(invoiceId, transactionId).pipe(
      map(({ invoice, invoice_items }) =>
        InvoiceActions.linkMedipassSuccessBatch({
          actions: {
            updateInvoiceSuccess: InvoiceActions.updateSuccess({ invoice }),
            updateInvoiceItemSuccess: InvoiceItemActions.findManySuccess({ invoiceItems: invoice_items }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(invoiceId: number, changes: Partial<RR.Invoice>) {
    return this.service.update(invoiceId, changes).pipe(
      map((invoice: RR.Invoice) => InvoiceActions.updateSuccess({ invoice })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(invoiceId: number, reason?: { reason: string }) {
    return this.service.delete(invoiceId, reason).pipe(
      map((invoice: RR.Invoice) => InvoiceActions.updateSuccess({ invoice })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
