import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { SectionChoiceHttpService } from './section-choice.service';

@Injectable()
export class SectionChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: SectionChoiceHttpService,
  ) {}

  delete(sectionChoiceId: number) {
    return this.service.delete(sectionChoiceId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
