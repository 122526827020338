<ng-container *ngIf="report.patient_id">
  <td>
    {{ nextReportLabel }}
  </td>
  <td>
    <a
      class="btn btn-outline-primary btn-sm"
      [routerLink]="['/report', report.id, 'edit']"
      (click)="voyagerUrlService.openVoyager(report.accession_number)"
      >Open Report</a
    >
    <button
      [rrAutoFocus]="nextReportLabel === 'Next'"
      rrTooltip="NEXT_REPORT_VOYAGER"
      class="btn btn-sm btn-link border"
      (click)="voyagerUrlService.openReportAndVoyager(report)"
    >
      <rr-report-accession-number [report]="report" [shortText]="true"></rr-report-accession-number>
      <span class="small fa fa-external-link-alt"></span>
    </button>
    <div class="mt-2">
      <rr-due-date data-no-bubble [report]="report"></rr-due-date>
    </div>
    <div class="d-flex align-items-center gap-1">
      <rr-report-scan-code [reportId]="report.id"></rr-report-scan-code>
      <span *ngIf="report.super_urgent" class="badge bg-danger flash">Super Urgent</span>
    </div>
  </td>
  <td>
    <rr-patient-info [patientId]="report.patient_id" [editPatient]="true"></rr-patient-info>
  </td>
  <td>
    <p class="text-break" *ngIf="report.study_notes; else noNotes">{{ report.study_notes }}</p>
    <ng-template #noNotes><p>N/A</p></ng-template>
  </td>
</ng-container>
