import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'textBreak',
  pure: true,
})
export class TextBreakPipe implements PipeTransform {
  transform(value: string, breakEvery = 35): string {
    let result = '';
    for (let i = 0; i < value.length; i += breakEvery) {
      result += value.slice(i, i + breakEvery) + (i + breakEvery < value.length ? '<br>' : '');
    }
    return result;
  }
}
