import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { compareDesc, differenceInDays } from 'date-fns';
/**
 * Validate at lease one field is required. For example: either email or phone has to be provided
 * @param validator
 * @param controls
 * @returns
 */
export const atLeastOneRequired =
  (validator: ValidatorFn, controls: string[] | null = null, errorKey: string): ValidatorFn =>
  (form: AbstractControl): ValidationErrors | null => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!controls || !form) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const hasAtLeastOne = controls.some((field) => !validator(form.get(field)!));
    controls.forEach((controlName) => {
      const control = form.get(controlName);
      if (control === null) {
        throw Error(`Could not find FormControl named "${controlName}"`);
      }
      const errors = {
        ...control.errors,
      };
      if (!hasAtLeastOne) {
        errors[errorKey] = true;
      } else {
        delete errors[errorKey];
      }
      if (Object.keys(errors).length === 0) {
        control.setErrors(null);
      } else {
        control.setErrors(errors);
      }
    });
    return hasAtLeastOne
      ? null
      : {
          [errorKey]: true,
        };
  };

/**
 * Validate DOB from a form control input
 * @returns
 */
export function forbiddenDOB(): ValidatorFn {
  return (control: AbstractControl) => {
    const dob = control.value;
    const yesterday = new Date();
    yesterday.setHours(0, 0, 0, 0);
    yesterday.setDate(yesterday.getDate() - 1);
    const born = new Date(dob);
    born.setHours(0, 0, 0, 0);
    const no = born >= yesterday;
    return no ? { forbiddenDOB: { dob } } : null;
  };
}

/**
 * Validate required field when dependent fields have values
 * @param validator
 * @param field
 * @param dependentFields
 * @param errorKey
 * @returns
 */
export const dependentRequired =
  (validator: ValidatorFn, field: string, dependentFields: string[] | null = null, errorKey: string) =>
  (form: FormGroup): ValidationErrors | null => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!field || !dependentFields || !form.controls) {
      return null;
    }

    const hasAtLeastOne = dependentFields.some((f) => !validator(form.controls[f]));
    return hasAtLeastOne && !!validator(form.controls[field]) ? { [errorKey]: true } : null;
  };

export const mustMatch =
  (controlName1: string, controlName2: string) =>
  (form: AbstractControl): ValidationErrors | null => {
    const control1 = form.get(controlName1);
    const control2 = form.get(controlName2);

    if (control1 === null) {
      throw Error(`Could not find FormControl named "${controlName1}"`);
    }
    if (control2 === null) {
      throw Error(`Could not find FormControl named "${controlName2}"`);
    }

    if (control1.value !== control2.value) {
      control2.setErrors({ mustMatch: true });
    } else {
      control2.setErrors(null);
    }

    return null;
  };

export const validateIsBefore =
  (controlName1: string, controlName2: string) =>
  (form: AbstractControl): ValidationErrors | null => {
    const control1 = form.get(controlName1);
    const control2 = form.get(controlName2);

    if (control1 === null) {
      throw Error(`Could not find FormControl named "${controlName1}"`);
    }
    if (control2 === null) {
      throw Error(`Could not find FormControl named "${controlName2}"`);
    }

    if (control1.value && control2.value) {
      if (control1.value.hour >= control2.value.hour && control1.value.minute >= control2.value.minute) {
        control1.setErrors({ isAfter: true });
      } else {
        control1.setErrors(null);
      }
    }

    return null;
  };

export const dateRangeValidator =
  (controlName1: string, controlName2: string, rangeValidation: 'MONTH' | 'YEAR') =>
  (form: AbstractControl): ValidationErrors | null => {
    const fromDateControl = form.get(controlName1);
    const toDateControl = form.get(controlName2);

    if (!fromDateControl || !toDateControl) {
      return null;
    }

    const fromDate = new Date(fromDateControl.value);
    const toDate = new Date(toDateControl.value);

    if (compareDesc(fromDate, toDate) === -1) {
      return { rangeError: "'From date' must be less than or equal 'To date'" };
    }

    if (differenceInDays(toDate, fromDate) > (rangeValidation === 'MONTH' ? 30 : 731)) {
      return { rangeError: `Date range must be less than ${rangeValidation === 'MONTH' ? 'a month' : '2 years'}` };
    }

    return null;
  };
