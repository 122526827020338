import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { ReportEffect } from 'app/store/report/report';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-report-status-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './report-status-modal.component.html',
  styleUrls: ['./report-status-modal.component.css'],
})
export class ReportStatusModalComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  reportStatuses: RR.ReportStatus[];
  statusControl = new FormControl<number | undefined>(undefined, { nonNullable: true });

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    public activeModal: NgbActiveModal,
    private reportEffect: ReportEffect,
  ) {}

  ngOnInit(): void {
    this.statusControl.setValue(this.report.status_id);

    this.subscription.add(
      this.store.select(fromReportStatus.selectAll).subscribe((reportStatuses) => {
        this.reportStatuses = reportStatuses;
      }),
    );
  }

  static open({ modal: modalService, report }: { modal: NgbModal; report: RR.Report }) {
    const modalRef = modalService.open(ReportStatusModalComponent, {
      size: 'md',
    });
    const component: ReportStatusModalComponent = modalRef.componentInstance;
    component.report = report;
    return modalRef;
  }

  onSubmit() {
    this.subscription.add(
      this.reportEffect
        .update(this.report.id, {
          status_id: this.statusControl.value,
        })
        .subscribe(() => {
          this.activeModal.close();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
