import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const selectFeature = (state: AppState) => state.prefillSearch;

const selectSearchForm = createSelector(selectFeature, ({ searchForm }) => searchForm);

export const fromPrefillSearch = {
  selectFeature,
  selectSearchForm,
};
