import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { UserActions } from '../user/user/user.action';
import { BeforeLogoutListenerType } from './session.types';

export const SessionActions = {
  notifiedUserFeedbacks: createAction('[Session] Notify User Feedbacks'),
  logoutSuccess: createAction('[Session] Logout Success'),
  reset: createAction('[Session] Reset'),
  setGoalRoute: createAction('[Session] Set Goal Route', props<{ goalRoute: string }>()),
  registerBeforeLogoutListener: createAction(
    '[Session] Register Before Logout Listener ',
    props<{ listener: BeforeLogoutListenerType }>(),
  ),
  unregisterBeforeLogoutListener: createAction(
    '[Session] Unregister Before Logout Listener ',
    props<{ listener: BeforeLogoutListenerType }>(),
  ),
  unregisterAllBeforeLogoutListeners: createAction('[Session] Unregister All Before Logout Listeners '),
  loginFailed: createAction('[Session] Login Failed'),
  updateConfigSuccess: createAction('[Session] Update Config Success', props<{ configuration: ConfigTemplate }>()),
  setKioskUser: createAction('[Session] Set Kiosk User', props<{ userId: number }>()),
};

export const SessionBatchActions = {
  fetchConfigSuccess: createBatchAction(
    '[Session] Fetch Config Success',
    props<{
      config: rrConfigType;
      // See UserBatchActions.findAllSuccessBatchAction. It should be the same.
      actions: {
        userFindSuccess?: ReturnType<typeof UserActions.findByIdSuccess>;
      };
    }>(),
  ),

  loginSuccess: createBatchAction(
    '[Session] Login Success',
    props<{
      user: RR.User;
      actions: {
        userFindSuccess: ReturnType<typeof UserActions.findByIdSuccess>;
      };
    }>(),
  ),
};
