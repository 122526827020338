import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { PathologyEffect } from 'app/store/pathology/pathology.effect';
import { fromPathology } from 'app/store/pathology/pathology.selector';
import { debounceTime, Observable, startWith, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'rr-pathology',
  standalone: true,
  imports: [SharedModule, CommonModule, VoiceDirective, ScrollingModule],
  templateUrl: './pathology.component.html',
  styleUrl: './pathology.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathologyComponent implements OnInit, OnDestroy {
  @ViewChild('textInput') textInput: ElementRef;
  @Input() selectedPathologyId: number | undefined;
  @Output() pathology = new EventEmitter<RR.Pathology>();
  pathologies$: Observable<RR.Pathology[]>;

  subscription = new Subscription();

  form = new FormGroup({
    search: new FormControl<string>('', { nonNullable: true }),
  });

  constructor(
    private store: Store<AppState>,
    private pathologyEffect: PathologyEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.form.controls.search.valueChanges
        .pipe(
          startWith(''),
          debounceTime(300),
          switchMap((searchInput) => this.pathologyEffect.search(searchInput)),
        )
        .subscribe(),
    );

    this.pathologies$ = this.store.select(fromPathology.selectAll);
  }

  onDropdownChange(isOpen: boolean) {
    if (isOpen) {
      requestAnimationFrame(() => {
        this.textInput.nativeElement.focus();
      });
    }
  }

  selectedPathology(pathology: RR.Pathology) {
    this.pathology.emit(pathology);
    this.selectedPathologyId = pathology.id;
  }

  createPathology() {
    this.subscription.add(this.pathologyEffect.create({ name: this.form.controls.search.value }).subscribe());
  }

  deletePathology(pathologyId: number) {
    this.subscription.add(this.pathologyEffect.delete(pathologyId).subscribe());
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.form.controls.search.setValue(event.detail.term);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
