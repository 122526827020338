import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class KeywordAbbreviationHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.KeywordAbbreviation[]> {
    return this.http.get<RR.KeywordAbbreviation[]>('/api/keyword_abbreviation');
  }

  create(keywordAbbreviation: Partial<RR.KeywordAbbreviation>): Observable<RR.KeywordAbbreviation> {
    return this.http.post<RR.KeywordAbbreviation>('/api/keyword_abbreviation', keywordAbbreviation);
  }

  update(keywordAbbreviationId: number, changes: Partial<RR.KeywordAbbreviation>): Observable<RR.KeywordAbbreviation> {
    return this.http.put<RR.KeywordAbbreviation>(`/api/keyword_abbreviation/${keywordAbbreviationId}`, changes);
  }

  delete(keywordAbbreviationId: number) {
    return this.http.delete(`/api/keyword_abbreviation/${keywordAbbreviationId}`);
  }
}
