import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ChoicePreviewComponent } from './choice-preview.component';

@Injectable()
export class ChoicePreviewService {
  private _popoverOpened = new Subject<ChoicePreviewComponent>();
  public _pendingOpen: number;

  openPopover(choiceComponent: ChoicePreviewComponent) {
    this._popoverOpened.next(choiceComponent);
  }

  openPopoverEvents() {
    return this._popoverOpened.asObservable();
  }

  get pendingOpen() {
    return this._pendingOpen;
  }

  set pendingOpen(p) {
    // only open one choice popover at a time
    clearTimeout(this.pendingOpen);
    this._pendingOpen = p;
  }
}
