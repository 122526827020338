import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { PatientActions } from 'app/store/patient';
import { map, tap } from 'rxjs/operators';

import { ReportActions } from '../../report/report/report.action';
import { PatientQuestionAnswerActions, PatientQuestionAnswerBatchActions } from './patient-question-answer.action';
import {
  PatientQuestionAnswerCreationHttpResponse,
  PatientQuestionAnswerParentResponse,
} from './patient-question-answer.service';
import { PatientQuestionAnswerHttpService } from './patient-question-answer.service';

@Injectable()
export class PatientQuestionAnswerEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientQuestionAnswerHttpService,
  ) {}

  findInReport(reportId: number) {
    return this.service.findInReport(reportId).pipe(
      map((patientQuestionAnswers: RR.PatientQuestionAnswer[]) =>
        PatientQuestionAnswerActions.findManyInReportSuccess({ reportId, patientQuestionAnswers }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(reportId: number, patientQuestionAnswer: Partial<RR.PatientQuestionAnswer>) {
    return this.service.create(reportId, patientQuestionAnswer).pipe(
      map((data: PatientQuestionAnswerCreationHttpResponse) => {
        const actions: ReturnType<typeof PatientQuestionAnswerBatchActions.createSuccessBatchAction>['actions'] = {
          createPatientQuestionAnswerSuccess: PatientQuestionAnswerActions.createSuccess({
            patientQuestionAnswer: data.patient_question_answer,
          }),
        };
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.report) {
          actions.updateReportSuccess = ReportActions.upsertOne({
            report: data.report,
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.patient) {
          actions.updatePatientSuccess = PatientActions.updateSuccess({
            patient: data.patient,
          });
        }
        return PatientQuestionAnswerBatchActions.createSuccessBatchAction({
          actions,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(patientQuestionAnswerId: number, changes: Partial<RR.PatientQuestionAnswer>) {
    return this.service.update(patientQuestionAnswerId, changes).pipe(
      map((patientQuestionAnswer) => PatientQuestionAnswerActions.updateSuccess({ patientQuestionAnswer })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * Delete patient question answer id
   * Update affected report or patient
   * @param patientQuestionAnswerId
   * @returns
   */
  delete(patientQuestionAnswerId: number) {
    return this.service.delete(patientQuestionAnswerId).pipe(
      map((data: PatientQuestionAnswerParentResponse) => {
        const actions: ReturnType<typeof PatientQuestionAnswerBatchActions.deleteSuccessBatchAction>['actions'] = {
          deletePatientQuestionAnswerSuccess: PatientQuestionAnswerActions.deleteSuccess({
            patientQuestionAnswerId,
          }),
        };
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.report) {
          actions.updateReportSuccess = ReportActions.upsertOne({
            report: data.report,
          });
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (data.patient) {
          actions.updatePatientSuccess = PatientActions.updateSuccess({
            patient: data.patient,
          });
        }
        return PatientQuestionAnswerBatchActions.deleteSuccessBatchAction({
          actions,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
