import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { ConfirmPasswordModalComponent } from 'app/modules/admin/modals/confirm-password-modal/confirm-password-modal.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { format, formatISO } from 'date-fns';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'rr-report-date',
  templateUrl: './report-date.component.html',
  styleUrls: ['./report-date.component.css'],
  imports: [CommonModule, SharedModule, AutoFocusDirective],
})
export class ReportDateComponent implements OnInit, OnDestroy {
  @Input() parent: 'EDITOR' | 'WORKLIST' = 'EDITOR';
  @Input() label = true;
  @Input() reportId: number;
  editDate = false;

  registrationDate = new FormControl('', { nonNullable: true });

  subscription = new Subscription();
  report: RR.Report;

  constructor(
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.reportId))
        .pipe(filterDefined())
        .subscribe((report) => {
          if (report.created) {
            this.registrationDate.setValue(this.formatDateTimeString(new Date(report.created)));
            // Workaround for NG0100 error caused when (keydown.enter) event changes ng-touched. Marking as 'touched' preemptively to avoid.
            // https://github.com/angular/angular/issues/22426
            this.registrationDate.markAsTouched();
          }
          this.report = report;
        }),
    );
  }

  updateReportDate() {
    const registrationDate = this.registrationDate.value ? formatISO(new Date(this.registrationDate.value)) : null;
    const changes: Partial<RR.Report> = {
      created: registrationDate,
    };
    this.subscription.add(this.reportEffect.update(this.report.id, changes).subscribe());
  }

  openDateTimeEditor() {
    this.editDate = true;
  }

  cancelDateTimeEditor() {
    this.editDate = false;
  }

  private formatDateTimeString(date: Date): string {
    // Must contain a "T" separator and not the milliseconds
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
    // e.g. 2022-03-15T05:05
    // 'T' quotes to escape the T. So that it isn't parsed a milliseconds by date-fns.
    return format(date, "yyyy-MM-dd'T'HH:mm");
  }

  confirmAndSubmit() {
    // Ask user to reenter password
    const modalRef = this.modalService.open(ConfirmPasswordModalComponent);
    modalRef.result.then(
      () => {
        this.updateReportDate();
        this.editDate = false;
      },
      () => {
        // Error
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
