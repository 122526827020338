import { createAction, props } from '@ngrx/store';

import { PrefillSearchFormType } from './prefill-search.reducer';

export const PrefillSearchActions = {
  /**
   * This saves the form state for restoring later. The valueChanges triggered by this path are filtered so they do not
   * update the FormControls again and create an infinite loop.
   */
  saveSearchForm: createAction(
    '[Prefill Search] Set Search Form',
    props<{
      searchForm: PrefillSearchFormType;
    }>(),
  ),

  updateSearchForm: createAction(
    '[Prefill Search] Update Search Form',
    props<{
      searchForm: Partial<PrefillSearchFormType>;
    }>(),
  ),
};
