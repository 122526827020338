import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { fixMaxHeight } from 'app/app.utils';
import { fromEvent, Subscription } from 'rxjs';
import { auditTime, map, startWith } from 'rxjs/operators';

import { SharedModule } from '../../../../../shared/shared.module';
import { MeasurementRuleComponent } from '../../../dicom-sr/measurement-rule.component';

@Component({
  selector: 'rr-measurement-dropdown',
  templateUrl: './measurement-dropdown.component.html',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    SharedModule,
    MeasurementRuleComponent,
    CommonModule,
  ],
})
export class MeasurementDropdownComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() measurements: RR.MatchingMeasurement[];
  filteredMeasurements: RR.MatchingMeasurement[];
  @Output() onChoose = new EventEmitter<RR.MatchingMeasurement>();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  subscription = new Subscription();

  openChange(open: boolean, dropdown: NgbDropdown) {
    if (open) {
      requestAnimationFrame(() => {
        fixMaxHeight(dropdown['_menu'].nativeElement);
        this.searchInput.nativeElement.focus();
      });
    }
  }

  ngAfterViewInit() {
    this.subscription.add(
      fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(
          auditTime(300),
          map((event) => {
            const target = event.target as HTMLInputElement;
            return target.value;
          }),
          startWith(''),
        )
        .subscribe((value) => {
          this.search(value);
        }),
    );
  }

  ngOnInit() {
    this.filteredMeasurements = [...this.measurements];
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  search(value: string) {
    this.filteredMeasurements = this.measurements.filter((m) => {
      return m.measurement.numeric_value.toString().includes(value);
    });
  }
}
