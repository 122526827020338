<button
  [disabled]="!!invoice.medipass_claim_status || !!invoice.medipass_transaction_status"
  role="button"
  class="btn btn-info"
  (click)="onClickBillMedicare()"
  permission
  [permission-only]="['billing']"
>
  <span class="small fa fa-external-link-alt"></span> Bill Medicare<ng-container
    *ngIf="(medipassEnv$ | async) === 'stg'"
  >
    (*)</ng-container
  >
</button>
