import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { getPatientShortAgeAndSex, strToNum } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { sectionMap } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { FavouriteActions, FavouriteBatchActions, FavouriteHttpService } from 'app/store/report/favourite';
import { fromCurrentReport } from 'app/store/report/report';
import { uniq } from 'lodash-es';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { SharedModule } from '../../../../shared/shared.module';
import { NameFavouriteModalComponent } from '../../prefill-button/name-favourite-modal.component';
import { PrefillSearchMetadataComponent } from '../prefill-search-metadata/prefill-search-metadata.component';
import { PrefillService } from './../prefill.service';

@Component({
  selector: 'rr-prefill-row,[rr-prefill-row]',
  templateUrl: './prefill-row.component.html',
  styleUrls: ['prefill-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, RouterLink, CommonModule, NgbTooltip],
})
@LifecycleLogger
export class PrefillRowComponent implements OnInit, OnDestroy {
  @Input() topic: RR.ESTopic;
  @Input() open_topic_id: number;
  @HostBinding('class.list-group-item-primary') isActive = false;
  subscription = new Subscription();
  favourite: RR.ESTopic['_source']['favourite'];
  userId: number | undefined; // Id of user who is editing the report
  @Input() showImageSimilarityScore: boolean;
  @Input() searchType: PrefillSearchMetadataComponent['searchType'];

  metric: { intersection: number; totalPrefills: number; totalCurrent: number };
  report: RR.Report | undefined;
  constructor(
    private prefillService: PrefillService,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
    private favouriteHttpService: FavouriteHttpService,
    private store: Store<AppState>,
  ) {}

  getSectionTitle(section: RR.TemplateSection) {
    return sectionMap[section].title;
  }

  ngOnInit() {
    this.subscription.add(
      this.prefillService.getPrefillPreviewObject(this.open_topic_id).subscribe((prefillTopicObj) => {
        this.isActive = prefillTopicObj.topicIds.indexOf(this.topic._id) >= 0;
        this.cd.markForCheck();
      }),
    );

    this.favourite = this.topic._source.favourite;
    this.subscription.add(
      this.reportService
        .selectKioskUser()
        .pipe(
          filter((e) => !!e),
          take(1),
        )
        .subscribe((user) => {
          this.userId = user ? user.id : undefined;
        }),
    );

    this.subscription.add(
      this.reportService.getChoicesWithCtx(this.open_topic_id).subscribe((ctx_choices) => {
        const currentStatementIds = uniq(
          ctx_choices
            .filter((ctx) => !!ctx.statement_choice && ctx.statement_choice.statement_id)
            // @ts-expect-error strictNullChecks
            .map((ctx) => ctx.statement_choice.statement_id),
        );
        const prefillStatementIds = uniq(
          this.topic._source.choices.filter((choice) => !!choice.statement_id).map((choice) => choice.statement_id),
        );
        const intersection = prefillStatementIds.filter((value) => currentStatementIds.includes(value));
        this.metric = {
          intersection: intersection.length,
          totalPrefills: prefillStatementIds.length,
          totalCurrent: currentStatementIds.length,
        };
        this.cd.markForCheck();
      }),
    );

    const report$ = this.store.select(fromCurrentReport.selectReport);
    this.subscription.add(
      report$.subscribe((report) => {
        this.report = report;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectPrefill($event: any) {
    this.prefillService.selectPrefillTopics$.next({ type: 'SELECT_TOPIC' });
    if (!$event.target.closest('[data-no-bubble-select-prefill-row]')) {
      if (!$event.shiftKey) {
        this.prefillService.setPrefillPreviewTopics({ openTopicId: this.open_topic_id, topicIds: [this.topic._id] });
      } else {
        if (this.isActive) {
          this.prefillService.removePrefillPreviewTopic(this.open_topic_id, this.topic._id);
        } else {
          this.prefillService.addPrefillPreviewTopic(this.open_topic_id, this.topic._id);
        }
      }
    }
  }

  patientShortAgeAndSex() {
    const patient = {
      patient_age_in_days: this.topic._source.patient_age_in_days,
      patient_sex: this.topic._source.patient_sex,
    };

    return getPatientShortAgeAndSex(patient);
  }

  // Deprecated: I don't think this function is still being used, since it actually not working
  getManualDisplayTags(): string[] {
    const tagHits = this.topic.highlight?.['favourite.tags'] || [];
    return this.favourite.tags.map((t) => {
      const hit = tagHits.find((th: string) => th.replace(/<mark>/g, '').replace(/<\/mark>/g, '') === t);
      return hit || t;
    });
  }

  // @ts-expect-error noImplicitAny
  onFavouriteClicked($event) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.favourite) {
      this.favouriteHttpService
        .findFavouriteById(this.favourite._id)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe((fav) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          if (fav) {
            const modalRef = NameFavouriteModalComponent.open({
              modalService: this.modal,
              topic_id: strToNum(this.topic._id),
              favourite: fav,
            });
            this.handleModalResult(modalRef);
          }
        });
    } else {
      const modalRef = NameFavouriteModalComponent.open({
        modalService: this.modal,
        topic_id: strToNum(this.topic._id),
        favourite: undefined,
      });
      this.handleModalResult(modalRef);
    }
    $event.preventDefault();
    $event.stopPropagation();
  }

  handleModalResult(modalRef: NgbModalRef) {
    modalRef.result.then(
      (action) => {
        if (this.userId === undefined) {
          throw new Error('userId is undefined');
        }
        // Update favourite info
        // CREATE SUCCESS
        if (action.type === FavouriteBatchActions.createFavouriteSuccessBatch.type) {
          const created_favourite = action.actions.createFavouriteSuccess.favourite as RR.Favourite;
          this.favourite = {
            ...created_favourite,
            tags: created_favourite.tags.map((t) => t.text),
            user_ids: [this.userId],
            _id: created_favourite.id,
          };
          // Mutate topic result
          this.topic._source.favourite = this.favourite;
          this.cd.detectChanges();
          return;
        }
        // Delete Success
        if (action.type === FavouriteActions.deleteSuccess.type) {
          // @ts-expect-error strictNullChecks
          this.topic._source.favourite = this.favourite = null;
          this.cd.detectChanges();
          return;
        }
        if (action.type === FavouriteActions.updateSuccess.type) {
          const updated_favourite = action.favourite as RR.Favourite;
          this.favourite.tags = updated_favourite.tags.map((t) => t.text);
          this.cd.detectChanges();
          return;
        }
      },
      () => {
        /* dismissed modal */
      },
    );
  }

  createPreset(reportId: number) {
    NameFavouriteModalComponent.open({
      modalService: this.modal,
      topic_id: strToNum(this.topic._id),
      favourite: undefined,
      reportId: reportId,
    });
  }
}
