import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PercentileBandGroupActions } from './percentile-band-group.action';
import { PercentileBandGroup } from './percentile-band-group.service';

export type PercentileBandGroupState = EntityState<PercentileBandGroup>;

const adapter = createEntityAdapter<PercentileBandGroup>();
const initialState = adapter.getInitialState();

export const percentileBandGroupReducer = createReducer(
  initialState,
  on(PercentileBandGroupActions.searchSuccess, (state: PercentileBandGroupState, { percentile_band_groups }) =>
    adapter.upsertMany(percentile_band_groups, state),
  ),
  on(PercentileBandGroupActions.createSuccess, (state: PercentileBandGroupState, { percentile_band_group }) =>
    adapter.addOne(percentile_band_group, state),
  ),
  on(PercentileBandGroupActions.deleteSuccess, (state: PercentileBandGroupState, { percentile_band_group_id }) =>
    adapter.removeOne(percentile_band_group_id, state),
  ),
  on(PercentileBandGroupActions.updateSuccess, (state: PercentileBandGroupState, { percentile_band_group }) =>
    adapter.upsertOne(percentile_band_group, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
