import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { DefaultAttributeActions } from 'app/store/template/default-attribute/default-attribute.action';
import { map, tap } from 'rxjs';

import {
  StatementChoiceUsageAnalyticsActions,
  StatementChoiceUsageAnalyticsBatchActions,
} from './statement-choice-usage-analytics.action';
import { StatementChoiceUsageAnalyticsHttpService } from './statement-choice-usage-analytics.service';

@Injectable()
export class StatementChoiceUsageAnalyticsEffect {
  constructor(
    private store: Store<AppState>,
    private service: StatementChoiceUsageAnalyticsHttpService,
  ) {}

  findAll(reportId: number, statementChoiceId: number, parent: string) {
    const serviceCall =
      parent === 'choice'
        ? this.service.findAllFromChoice(reportId, statementChoiceId, null)
        : this.service.findAllFromChoice(reportId, null, statementChoiceId);

    return serviceCall.pipe(
      map((response) =>
        StatementChoiceUsageAnalyticsBatchActions.findAllSuccess({
          actions: {
            statementChoiceUsageAnalyticsFindAllSuccess: StatementChoiceUsageAnalyticsActions.findAllSuccess({
              response,
              statementChoiceId,
            }),
            defaultAttributeFindAllSuccess: DefaultAttributeActions.findAllSuccess({
              defaultAttributes: response.default_attributes,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
