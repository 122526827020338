import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

export const selectFeature = (state: AppState) => state.dicom;
export const selectMeasurements = createSelector(selectFeature, (state) => state.measurementsResponse);
export const measurementsLoading = createSelector(selectFeature, (state) => state.measurementsLoading);

export const fromDicom = {
  selectMeasurements,
  measurementsLoading,
};
