<div class="modal-header">
  <div>
    <h5 class="modal-title">Check Report (C)</h5>
    <rr-report-headline [reportId]="report_id"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="report$ | async as report">
    <div *ngIf="!report.referrer_id" class="mb-3">
      <div class="alert alert-warning py-1 mb-2">
        <h3 class="mb-0">Scan Registration</h3>
      </div>
      <div class="d-flex align-items-center" *ngIf="!report.referrer_id">
        <span class="text-danger"><i class="fas fa-exclamation-circle"></i></span>
        <span class="ms-2"> Missing referrer </span>
        <button
          ngbAutoFocus
          rrTooltip="ADD_SIGNATURE"
          role="button"
          class="btn btn-secondary btn-sm ms-2"
          (click)="openRegistrationModal()"
        >
          Add Referrer
        </button>
      </div>
    </div>
  </ng-container>

  <rr-check-topic
    class="mb-3"
    *ngFor="let topic of getActiveTopics() | async"
    [report_id]="report_id"
    [topic]="topic"
    (closeCheckReport)="checkAutoCloseModal($event)"
  ></rr-check-topic>
</div>
<div class="modal-footer">
  <rr-send-report-button
    [report_id]="report_id"
    [btnStyle]="'btn-primary'"
    permission
    [permission-only]="['report_distribute']"
  ></rr-send-report-button>
  <button
    rrTooltip="COPY_TO_CLIPBOARD"
    [rrAutoFocus]="(signatureText$ | async) !== null"
    class="btn btn-primary"
    role="button"
    (click)="viewFinalReport()"
  >
    View and Export (X)
  </button>
  <button rrTooltip="FINDING_TAG" class="btn btn-primary" role="button" (click)="togglePrefill()">
    Back to Prefill (B)
  </button>
  <ng-container *ngIf="{ report: report$ | async, topic: topic$ | async } as data">
    <ng-container *ngIf="data.report && data.topic !== null">
      <button
        class="me-1"
        rr-add-other-imaging-button
        [topic]="data.topic"
        [report]="data.report"
        rrTrack
        rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
      ></button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="{ report: report$ | async, patient: patient$ | async } as data">
    <button
      class="btn mb-1"
      [ngClass]="
        (data.report && data.report.patient_answer_ids.length > 0) ||
        (data.patient && data.patient.patient_answer_ids.length > 0)
          ? 'btn-success'
          : 'btn-primary'
      "
      (click)="openPatientQuestionnaireModal()"
      rrTrack
      rrTooltip="OPEN_PATIENT_QUESTIONNAIRE"
    >
      (Y) Registration Questions
    </button>
  </ng-container>
  <rr-report-notes-button [btnClass]="'btn-primary'" [report_id]="report_id" [small]="false"></rr-report-notes-button>
</div>
