import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { TOOLTIPS } from 'app/app.constants';

@Directive({
  selector: '[rrTooltip]',
  standalone: true,
})
export class TooltipDirective implements AfterViewInit {
  @Input('rrTooltip') tooltip: keyof typeof TOOLTIPS | null;

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}
  ngAfterViewInit() {
    if (this.tooltip !== null) {
      if (!(this.tooltip in TOOLTIPS)) {
        console.warn(`Tooltip key doesn't exist: "${this.tooltip}"`, this.elementRef.nativeElement);
        this.renderer.setAttribute(this.elementRef.nativeElement, 'title', this.tooltip);
      } else {
        this.renderer.setAttribute(this.elementRef.nativeElement, 'title', TOOLTIPS[this.tooltip]);
      }
    }
  }
}
