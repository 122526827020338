import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { AddOtherImagingModalComponent } from 'app/modules/editor/add-other-imaging-modal/add-other-imaging-modal.component';
import { PriorStudyLinksComponent } from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.component';
import {
  PriorStudyLinksService,
  RelatedReports,
} from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.service.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { fromTopic } from 'app/store/report/topic';
import { Observable, Subscription, combineLatest, switchMap } from 'rxjs';

import { ReportHeadlineComponent } from '../report-headline/report-headline.component';

@Component({
  selector: 'rr-same-patient-reports',
  standalone: true,
  imports: [CommonModule, SharedModule, ReportHeadlineComponent, PriorStudyLinksComponent],
  templateUrl: './same-patient-reports.component.html',
  styleUrl: './same-patient-reports.component.css',
})
export class SamePatientReportsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pendingReports', { static: false }) pendingReports: ElementRef;
  @Input() reportId: number;
  @Input() topicId: number;
  @Input() @BindObservable() autoFocus: boolean = false;
  autoFocus$: Observable<boolean>;

  subscription = new Subscription();
  report$: Observable<RR.Report>;
  report: RR.Report;
  topic$: Observable<RR.Topic>;
  topic: RR.Topic;
  relatedReports$: Observable<RelatedReports>;
  relatedReports: RelatedReports | undefined;

  constructor(
    private store: Store<AppState>,
    private priorStudyLinksService: PriorStudyLinksService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId)).pipe(filterDefined());
    this.topic$ = this.store.select(fromTopic.selectTopic(this.topicId)).pipe(filterDefined());
    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );
    this.subscription.add(
      this.topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    // `this.reportEffect.findRelatedReports` already called in EditorComponent
    this.relatedReports$ = combineLatest([this.report$, this.topic$]).pipe(
      switchMap(([report, topic]) => {
        return this.priorStudyLinksService.getRelatedReports(report, topic);
      }),
    );
    this.subscription.add(
      this.relatedReports$.subscribe((data) => {
        this.relatedReports = data;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      combineLatest([this.relatedReports$, this.autoFocus$]).subscribe(([relatedReports, autoFocus]) => {
        if (relatedReports.sameDay.length > 0 && autoFocus) {
          requestAnimationFrame(() => {
            this.pendingReports.nativeElement.focus();
          });
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openSameDayReports() {
    AddOtherImagingModalComponent.open(this.modal, {
      reportId: this.reportId,
      topicId: this.topicId,
      defaultMode: 'sameDay',
      selectable: false,
    });
  }
}
