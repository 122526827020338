import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromReferrerContactMethod } from '../referrer-contact-method/referrer-contact-method.selector';
import { fromReferrerNote } from '../referrer-note/referrer-note.selector';
import { fromSoftwarePreference } from '../software-preferences/software-preferences.selector';

const adapter = createEntityAdapter<RR.Referrer>();
const selectFeature = (state: AppState) => state.referrer;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectReferrer = (id: number) =>
  createSelector(selectEntities, (referrers: Dictionary<RR.Referrer>) => referrers[id]);

const selectReferrersLoaded = (referrer_id: number) =>
  createSelector(selectFeature, ({ referrersLoaded }) => !!referrersLoaded[referrer_id]);

const selectPreferredContacts = (referrer_id: number) =>
  createSelector(
    selectReferrer(referrer_id),
    fromReferrerContactMethod.selectEntities,
    (referrer, contact_methods) =>
      referrer?.contact_method_ids
        .map((c) => contact_methods[c])
        .filter((contact): contact is RR.ReferrerContactMethod => !!contact) || [],
  );

const selectSoftwarePreferences = (referrer_id: number) =>
  createSelector(
    selectReferrer(referrer_id),
    fromSoftwarePreference.selectEntities,
    (referrer, software_preferemces) =>
      referrer?.software_preference_ids
        .map((p) => software_preferemces[p])
        .filter((preference): preference is RR.SoftwarePreference => !!preference) || [],
  );

const selectReferrerNotes = (referrer_id: number) =>
  createSelector(
    selectReferrer(referrer_id),
    fromReferrerNote.selectEntities,
    (referrer, referrer_notes) =>
      referrer?.referrer_note_ids.map((id) => referrer_notes[id]).filter((note): note is RR.ReferrerNote => !!note) ||
      [],
  );

const selectReferrerNotesByReferrerIds = (ids: number[]) =>
  createSelector(fromReferrerNote.selectAll, (referrer_notes: RR.ReferrerNote[]) =>
    referrer_notes.filter((note) => ids.includes(note.referrer_id)),
  );

export const fromReferrer = {
  selectIds,
  selectEntities,
  selectAll,
  selectReferrer,
  selectReferrersLoaded,
  selectPreferredContacts,
  selectReferrerNotes,
  selectReferrerNotesByReferrerIds,
  selectSoftwarePreferences,
};
