import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';

const adapter = createEntityAdapter<RR.TextObject>();
const selectFeature = (state: AppState) => state.text_object;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectTextObject = (textObjectId: number) =>
  createSelector(selectEntities, (textObjects: Dictionary<RR.TextObject>) => textObjects[textObjectId]);

export const fromTextObject = {
  selectIds,
  selectEntities,
  selectAll,
  selectTextObject,
};
