<div class="modal-header">
  <div>
    <h2>View and Export (X): Final report</h2>
    <rr-report-headline [reportId]="report_id"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="p-2">
  <div *ngIf="topic$ | async">
    <div *ngIf="reportError | hasErrors" class="mb-2 text-danger d-flex">
      <div class="fa fa-lg fa-exclamation-triangle text-danger flash me-2"></div>
      <div>
        There are still errors in the report. Please check report again before sending to Voyager!
        <ul>
          <li *ngIf="reportError.incorrect_side">Incorrect side</li>
          <li *ngIf="reportError.signature">Missing signature</li>
          <li *ngIf="reportError.title">Missing title</li>
          <li *ngIf="reportError.unfilled_number">Unfilled number</li>
          <li *ngIf="reportError.incorrect_sex">Incorrect sex in one or more statements</li>
        </ul>
      </div>
    </div>
    <div class="d-flex mb-1 gap-2" *ngIf="(isDoctorEditing() | async) && mode !== 'worklist'">
      <!--Send to Voyager: Send now and Schedule send-->
      <rr-send-voyager-button [report_id]="report_id" [delay]="true" [focus]="delaySentButtonFocus" [btnSm]="true">
      </rr-send-voyager-button>

      <rr-send-voyager-button
        [report_id]="report_id"
        [delay]="false"
        [focus]="!delaySentButtonFocus"
        [btnSm]="true"
        (onSentToVoyager)="onSentToVoyager($event)"
      >
      </rr-send-voyager-button>
    </div>
    <div class="d-flex gap-2 align-content-center flex-wrap">
      <!--Send report-->
      <ng-container *ngIf="mode !== 'worklist'">
        <ng-container *ngIf="report$ | async as report">
          <rr-send-report-button
            [report_id]="report.id"
            [btnStyle]="'btn-primary'"
            [btnSm]="true"
            permission
            [permission-only]="['report_distribute']"
            [focus]="sentToVoyager"
          ></rr-send-report-button>
        </ng-container>
      </ng-container>
      <button
        *ngIf="(isDoctorEditing() | async) && mode !== 'worklist'"
        rrTooltip="OPEN_SIGNATURE"
        class="btn btn-sm"
        [ngClass]="(topic$ | async)?.signature_text ? 'btn-outline-success' : 'btn-outline-dark'"
        role="button"
        (click)="openSignature()"
      >
        <ng-container *ngIf="!(topic$ | async)?.signature_text">
          <span class="fa fa-file-signature"></span>
          Sign (S)
        </ng-container>
        <ng-container *ngIf="(topic$ | async)?.signature_text">
          <i class="fas fa-check-circle"></i>
          Signed (S)
        </ng-container>
      </button>
      <button
        *ngIf="mode !== 'worklist'"
        class="btn btn-sm btn-primary"
        (click)="openCheckReport()"
        rrTrack
        rrTooltip="SIDE_CHECK_REPORT"
      >
        Check Report (C)
      </button>
      <ng-container *ngIf="mode !== 'worklist'">
        <ng-container *ngrxLet="topic$ as topic">
          <ng-container *ngrxLet="report$ as report">
            <button
              rr-add-other-imaging-button
              [topic]="topic"
              [report]="report"
              [btnSm]="true"
              rrTrack
              rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
            ></button>
          </ng-container>
        </ng-container>
      </ng-container>

      <button
        *ngIf="mode !== 'worklist'"
        role="button"
        class="btn btn-sm btn-primary"
        (click)="togglePrefill()"
        rrTrack
        rrTooltip="SIDE_PREFILL_TOGGLE"
      >
        Back to Prefill (B)
      </button>
      <button
        *ngIf="mode !== 'worklist'"
        role="button"
        class="btn btn-sm btn-primary"
        (click)="onFavouriteClicked()"
        rrTooltip="NAME_FAVOURITE"
        [ngClass]="favourite ? 'btn-warning' : 'btn-primary'"
      >
        {{ favourite ? 'Update Favourites (A)' : 'Add Favourites (A)' }}
      </button>
      <rr-report-notes-button
        *ngIf="mode !== 'worklist'"
        [btnClass]="'btn-primary'"
        [report_id]="report_id"
      ></rr-report-notes-button>
      <button
        *ngIf="mode !== 'worklist'"
        class="btn btn-sm btn-outline-secondary"
        (click)="copy(); $event.stopPropagation()"
        [disabled]="fetchingReportText"
        rrTooltip="COPY_REPORT"
      >
        <i class="fas fa-copy fa-sm"></i>
      </button>
      <a class="btn btn-sm btn-primary" [href]="'/api/report/' + report_id + '/render'" target="_blank">Download PDF</a>
    </div>
  </div>
  <h4 class="modal-title" *ngIf="mode === 'worklist'">Clipboard View</h4>
</div>
<div class="modal-body">
  <div>
    <div class="form-check">
      <input
        id="text_source"
        class="form-check-input"
        type="checkbox"
        title="toggle text source"
        [(ngModel)]="showReportUI"
        (click)="updateSourceText()"
        rrTrack
      />
      <label for="text_source" class="h3 m-0 p-0 form-check-label"> Show preview </label>
    </div>
    <div *ngIf="showReportUI">
      <span>Report Groups:</span>
      <span *ngFor="let tag of tags$ | async" class="badge rounded-pill bg-light text-dark border">{{ tag.text }}</span>
    </div>
  </div>
  <hr class="mt-1" />
  <ng-container *ngIf="{ topic: topic$ | async } as data">
    <div *ngIf="showReportUI" (click)="onClick()">
      <div *ngIf="data.topic === undefined" class="p-5">Loading...</div>
      <rr-report-preview *ngIf="data.topic" [topic]="data.topic" [mode]="mode"></rr-report-preview>
    </div>
  </ng-container>
  <div *ngIf="!showReportUI" class="server-text" (mousedown)="onMouseDownServerText($event)">
    {{ (report$ | async)?.text || 'Loading report text...' }}
  </div>
</div>
<div class="p-3 border-top prior-studies" *ngIf="mode === 'final'">
  <ng-container *ngIf="report$ | async as report">
    <rr-add-other-imaging *ngIf="topic$ | async as topic" [topic]="topic" [report]="report"></rr-add-other-imaging>
  </ng-container>
</div>
