import { Pipe, PipeTransform } from '@angular/core';
import { getParameterName } from 'app/app.utils';

@Pipe({
  standalone: true,
  name: 'parameterName',
})
export class ParameterNamePipe implements PipeTransform {
  transform(parameterId: number | null, parameters: RR.ImgsimParameter[] | undefined): string {
    return getParameterName(parameterId, parameters);
  }
}
