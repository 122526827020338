import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.StatementChoice>();
const selectFeature = (state: AppState) => state.statement_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectStatementChoice = (statementChoiceId: number) =>
  createSelector(
    selectEntities,
    (statementChoices: Dictionary<RR.StatementChoice>) => statementChoices[statementChoiceId],
  );

const selectSuggestedStatementChoices = createSelector(selectAll, (statementChoices: RR.StatementChoice[]) =>
  statementChoices.filter((choice) => choice.source === RR.ChoiceSource.voice_notes),
);

export const fromStatementChoice = {
  selectSuggestedStatementChoices,
  selectEntities,
  selectAll,
  selectIds,
  selectStatementChoice,
};
