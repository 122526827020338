import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Site>();
const selectFeature = (state: AppState) => state.site;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectActiveSites = createSelector(selectAll, (sites) => sites.filter((site) => !site.deleted));
const selectSite = (siteId: number) => createSelector(selectEntities, (sites: Dictionary<RR.Site>) => sites[siteId]);
const selectSiteByName = (site: string) =>
  createSelector(selectAll, (sites: RR.Site[]) => sites.find((s) => s.short_name === site));
const selectSites = (siteIds: number[]) =>
  createSelector(selectAll, (sites: RR.Site[]) => sites.filter((site) => siteIds.some((id) => site.id === id)));
const selectLoaded = createSelector(selectFeature, (state) => state.loaded);
export const fromSite = {
  selectIds,
  selectEntities,
  selectAll,
  selectActiveSites,
  selectSite,
  selectSites,
  selectLoaded,
  selectSiteByName,
};
