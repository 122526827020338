import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { SendReportModalComponent } from '../send-events-modal/send-report-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'rr-send-voyager-button',
  templateUrl: './send-voyager-button.component.html',
  styleUrls: ['./send-voyager-button.component.css'],
})
export class SendVoyagerButtonComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sendVoyagerButton', { static: false }) button: ElementRef;
  @Output() onSentToVoyager: EventEmitter<boolean> = new EventEmitter();
  @Input() report_id: number;
  @Input() delay: boolean;
  @Input() btnSm = false;
  @Input() @BindObservable() focus: boolean;
  focus$: Observable<boolean>;

  report$: Observable<RR.Report>;

  subscription: Subscription = new Subscription();
  sendingReportId: number | undefined;
  reportSendStatus: RR.ReportSendStatus;
  sendingToVoyager: boolean;
  constructor(
    private editorService: EditorService,
    private reportService: ReportService,
    private modal: NgbModal,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.report$ = this.store.select(fromReport.selectReport(this.report_id)).pipe(filterDefined());

    this.subscription.add(
      this.editorService.getScheduleSendToVoyagerTask().subscribe((id) => {
        this.sendingReportId = id;
      }),
    );
    this.subscription.add(
      this.reportService.getReportSendStatus(this.report_id).subscribe((status) => {
        this.reportSendStatus = status;
      }),
    );
    this.subscription.add(
      this.focus$.subscribe((f) => {
        if (f) {
          setTimeout(() => {
            this.button.nativeElement.focus();
          });
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (this.focus) {
      this.button.nativeElement.focus();
    }
  }

  sendToVoyager() {
    if (this.delay) {
      this.scheduleSendingToVoyager();
    } else {
      this.sendToVoyagerNow();
    }

    // TODO: I'm not sure if we still need this code
    this.subscription.add(
      this.report$.pipe(take(1)).subscribe((report) => {
        // arguably shouldn't copy, but this line is necessary to replicate `!this.useFullText` behaviour
        // TODO: remove copy behaviour when copy preview text is removed
        // @ts-expect-error strictNullChecks
        navigator.clipboard.writeText(report.text);
        this.reportService
          .getActiveTopics(report.id)
          .pipe(take(1))
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          .subscribe((topics) => topics.forEach((t) => this.reportService.setTopicCopied(t.id)));
      }),
    );
  }

  scheduleSendingToVoyager() {
    // Add sending task to the behaviour subject
    this.editorService.setScheduleSendToVoyager(this.report_id);
    this.messageService.add({
      title: 'Sending',
      type: 'info',
      message:
        'Transcribed status will be Saved to Voyager when you finish reviewing. ' +
        'If you want to send it immediately, please click `Export to Voyager now` or go back to the worklist.',
      timeout: 6000,
    });

    this.checkToOpenSendReportModal();
  }

  sendToVoyagerNow() {
    this.sendingToVoyager = true;
    // Remove schedule send to Voyager if any
    if (this.sendingReportId) {
      this.editorService.setScheduleSendToVoyager(undefined);
    }
    // Send to Voyager
    this.editorService
      .sendToVoyager(this.report_id)
      .pipe(
        finalize(() => {
          this.sendingToVoyager = false;
          this.onSentToVoyager.emit(true);
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: () => {
          this.messageService.add({
            type: 'success',
            title: 'Success',
            message: 'Sent to Voyager',
          });

          this.checkToOpenSendReportModal();
        },
      });
  }

  checkToOpenSendReportModal() {
    setTimeout(() => {
      // Check to show send report modal to send report to registered numbers
      if (this.reportSendStatus === 'UNRESOLVED') {
        this.reportService
          .getReport(this.report_id)
          .pipe(take(1))
          // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
          .subscribe((report) => {
            SendReportModalComponent.open(this.modal, report);
          });
      }
    }, 1000);
  }
}
