import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SocketService } from 'app/core/services/socket.service';
import { AppState } from 'app/store';
import { BookingEnquiryHttpService } from 'app/store/booking-enquiry/booking-enquiry.service';
import { fromSession } from 'app/store/session';
import { Subscription, filter, switchMap } from 'rxjs';

@Component({
  selector: 'rr-booking-enquiry-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './booking-enquiry-badge.component.html',
  styleUrls: ['./booking-enquiry-badge.component.css'],
})
export class BookingEnquiryBadgeComponent implements OnInit, OnDestroy {
  pendingEnquiryCount = 0;

  subscription: Subscription = new Subscription();
  constructor(
    private bookingEnquiryHttpService: BookingEnquiryHttpService,
    private socket: SocketService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromSession.selectRRConfig)
        .pipe(
          filterDefined(),
          filter((rrConfig) => rrConfig.RADBOOKING),
          switchMap(() => this.bookingEnquiryHttpService.getPendingBookingEnquiryCount()),
        )
        .subscribe((res: { count: number }) => {
          this.pendingEnquiryCount = res.count;
        }),
    );

    this.subscription.add(
      this.socket.messageReceivedEvent$.subscribe((data) => {
        if (data.type === 'BOOKING_ENQUIRY_UPDATE') {
          this.pendingEnquiryCount = data.count ?? 0;
        }
      }),
    );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
