<div class="d-flex align-items-center my-2">
  <div class="w-50">
    <div class="form-check">
      <input
        id="note-resolved-{{ patientNote?.id || referrerNote?.id }}"
        value="any"
        type="checkbox"
        [checked]="patientNote?.resolved || referrerNote?.resolved"
        class="me-2 form-check-input"
        (change)="resolveNote(patientNote?.id || referrerNote?.id, !(patientNote?.resolved || referrerNote?.resolved))"
        permission
        [permission-only]="['data_management']"
        [permission-hidden]="true"
      />
      <label
        for="note-resolved-{{ patientNote?.id || referrerNote?.id }}"
        class="form-check-label"
        permission
        [permission-only]="['data_management']"
      >
        <span style="white-space: pre-line" *ngIf="!(patientNote?.resolved || referrerNote?.resolved)">
          {{ patientNote?.note || referrerNote?.note }}
        </span>
        <span style="white-space: pre-line">
          <s *ngIf="patientNote?.resolved || referrerNote?.resolved">
            {{ patientNote?.note || referrerNote?.note }}
          </s>
        </span>
      </label>
    </div>
  </div>
  <div class="d-flex align-items-center ms-auto">
    <ng-container *ngIf="patientNote?.report_id || referrerNote?.report_id">
      <a
        *ngIf="patientNote?.report_id || referrerNote?.report_id | storeSelectPipe: selectReportFn | async as report"
        class="me-2"
        target="_blank"
        [routerLink]="['/report', report.id, 'edit']"
      >
        <rr-report-accession-number [report]="report"></rr-report-accession-number>
      </a>
    </ng-container>

    <div *ngIf="referrerNote?.category_id as id" class="badge bg-info me-2">
      {{ (id | storeSelectPipe: selectReferrerCategoryFn | async)?.title || 'N/A' }}
    </div>
    <div *ngIf="patientNote?.category_id as id" class="badge bg-info me-2">
      {{ (id | storeSelectPipe: selectPatientCategoryFn | async)?.title || 'N/A' }}
    </div>
    <div class="me-2">
      <strong class="me-2">
        <span>
          {{ (patientNote?.author_id || referrerNote?.author_id | storeSelectPipe: selectUserFn | async)?.initials }}
        </span>
      </strong>
      <span>
        {{ patientNote?.created_at || referrerNote?.created_at | date: 'dd/MM/y, H:mm' }}
      </span>
    </div>
    <div class="ms-auto d-flex">
      <button
        class="btn btn-sm btn-outline-primary w-100 me-2"
        (click)="editNote()"
        rrTooltip="EDIT_NOTE"
        permission
        [permission-only]="['data_management']"
        [permission-hidden]="true"
      >
        Edit
      </button>
      <button
        class="btn btn-sm btn-outline-danger w-100"
        (click)="deleteReferrerNote()"
        rrTooltip="DELETE_NOTE"
        permission
        [permission-only]="['data_management']"
        [permission-hidden]="true"
      >
        Delete
      </button>
    </div>
  </div>
</div>
