import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { differenceInDays, isSameDay } from 'date-fns';
import { Observable, of, zip } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export type RelatedReport = {
  report: RR.Report;
  topics: RR.Topic[];
};

export type RelatedReports = {
  all: RelatedReport[];
  related: RelatedReport[];
  unreported: RelatedReport[];
  recentUnreported: RelatedReport[];
  sameDay: RelatedReport[];
};

/**
 * Manages the state for PriorStudyLinksComponent. Nested components update the state.
 */
@Injectable()
export class PriorStudyLinksService {
  constructor(private store: Store<AppState>) {}

  shouldAddOtherImaging(toReport: RR.Report, toTopic: RR.Topic) {
    return this.getRelatedReports(toReport, toTopic).pipe(
      map((relatedReports) => {
        return relatedReports.sameDay.length > 0 && !toTopic.other_imaging;
      }),
    );
  }

  getRelatedReports(toReport: RR.Report, toTopic: RR.Topic): Observable<RelatedReports> {
    return this.store.select(fromReport.selectRelatedReports(toReport.id)).pipe(
      switchMap((relatedReports) => {
        if (relatedReports.length > 0) {
          return zip(
            ...relatedReports.map((report) =>
              this.store.select(fromReport.selectTopics(report.id)).pipe(
                map((topics) => {
                  // This data shape is for doing `selectAll` from the parent component (rather than in each child component).
                  // It helps to have an overall picture of what topics are visible.
                  return {
                    report,
                    topics,
                  };
                }),
              ),
            ),
          );
        } else {
          // Because zip doesn't do anything when given an empty array
          return of([]);
        }
      }),
      map((all) => {
        const unreported = all.filter(({ report }) => !report.send_to_voyager_time);
        const recentUnreported = unreported.filter(
          ({ report }) => report.created && differenceInDays(new Date(), new Date(report.created)) <= 14,
        );
        const sameDay = all.filter(({ report }) => {
          return (
            report.created !== null &&
            toReport.created !== null &&
            isSameDay(new Date(report.created), new Date(toReport.created)) &&
            report.topic_ids.length > 0
          );
        });

        // Related reports are same day reports or report with topic of the same template
        const related = all.filter(
          (relatedReport) =>
            sameDay.includes(relatedReport) || relatedReport.topics.find((t) => t.template_id === toTopic.template_id),
        );

        return {
          all: all,
          related: related,
          unreported: unreported,
          recentUnreported: recentUnreported,
          sameDay: sameDay,
        };
      }),
    );
  }
}
