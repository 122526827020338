import { AfterContentChecked, Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Workaround to provide default ng-content if there was none
 * https://github.com/angular/angular/issues/12530#issuecomment-444833662
 *
 * Renders the TemplateRef passed to `rrNoContent` instead of the contents
 */
@Directive({
  standalone: true,
  selector: '[rrNoContent]',
})
export class NoContentDirective implements AfterContentChecked {
  @Input() rrNoContent: TemplateRef<any>;

  private element: HTMLElement;
  private hasContent = true;

  constructor(
    element: ElementRef,
    private container: ViewContainerRef,
  ) {
    this.element = element.nativeElement;
  }

  ngAfterContentChecked(): void {
    let hasContent = false;
    for (let i = this.element.childNodes.length - 1; i >= 0; --i) {
      const node = this.element.childNodes[i];
      if (node.nodeType === 1 || node.nodeType === 3) {
        hasContent = true;
        break;
      }
    }
    if (hasContent !== this.hasContent) {
      this.hasContent = hasContent;
      if (hasContent) {
        this.container.clear();
      } else {
        this.container.createEmbeddedView(this.rrNoContent);
      }
    }
  }
}
