import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for ReferrerNote
 *
 */
export const ReferrerNoteCategoryActions = {
  findManySuccess: createAction(
    '[ReferrerNoteCategory] Find Many Success',
    props<{ referrer_note_categories: RR.NoteCategory[] }>(),
  ),
  createSuccess: createAction(
    '[ReferrerNoteCategory] Create Success',
    props<{ referrer_note_category: RR.NoteCategory }>(),
  ),
  delete: createAction('[ReferrerNoteCategory] Delete', props<{ referrer_note_category_id: number }>()),
};
