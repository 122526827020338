import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { TemplateService } from 'app/core/services/template.service';
import { ElementEffect } from 'app/store/template/element';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { AutoFocusDirective } from '../../../shared/directives/auto-focus.directive';

@Component({
  templateUrl: './element-delete-modal.component.html',
  standalone: true,
  imports: [FormsModule, AutoFocusDirective, CommonModule],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class ElementDeleteModalComponent implements OnInit {
  element: RR.Element; // input
  statement_set$: Observable<RR.StatementSet>;

  constructor(
    public activeModal: NgbActiveModal,
    private elementEffect: ElementEffect,
    private messageService: MessageService,
    private templateService: TemplateService,
  ) {}

  ngOnInit() {
    this.statement_set$ = this.templateService.getStatementSet(this.element.statement_set_id);
  }

  static open(modalService: NgbModal, element: RR.Element) {
    const modalRef = modalService.open(ElementDeleteModalComponent, {
      size: 'lg',
    });
    const componentInstance: ElementDeleteModalComponent = modalRef.componentInstance;
    componentInstance.element = element;
    return modalRef;
  }

  async removeElement(password: string) {
    if (!password || !password.trim()) return;
    this.elementEffect
      .delete(this.element.id, password)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: () => {
          this.activeModal.dismiss();
        },
        error: () => {
          this.messageService.add({
            title: 'Error',
            message: 'Failed to delete element. Please ensure your admin password is correct',
            type: 'danger',
            timeout: 5000,
          });
        },
      });
  }
}
