import { createAction, props } from '@ngrx/store';

import { PercentileBand } from './percentile-band.service';

/**
 * Ngrx Actions for Percentile Bands
 *
 */
// Actions for PercentileBand
export const PercentileBandActions = {
  searchSuccess: createAction('[PercentileBand] Search Success', props<{ percentile_bands: PercentileBand[] }>()),
  createSuccess: createAction('[PercentileBand] Create Success', props<{ percentile_band: PercentileBand }>()),
  deleteSuccess: createAction('[PercentileBand] Delete Success', props<{ percentile_band_id: number }>()),
  updateSuccess: createAction('[PercentileBand] Update Success', props<{ percentile_band: PercentileBand }>()),
};
