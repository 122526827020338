import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, share, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateActions } from '../template/template/template.action';
import { TagSearchTermActions } from './tag-search-term.action';
import { CreateTagSearchTerm, TagSearchTermHttpService } from './tag-search-term.service';

@Injectable()
export class TagSearchTermEffect {
  constructor(
    private store: Store<AppState>,
    private service: TagSearchTermHttpService,
  ) {}

  findAll(templateId: number) {
    return this.service.findAll(templateId).pipe(
      map((tagSearchTerms: RR.TagSearchTerm[]) => TagSearchTermActions.findAllSuccess({ tagSearchTerms })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createInTemplate(templateId: number, tagSearchTerm: CreateTagSearchTerm) {
    return this.service.createInTemplate(templateId, tagSearchTerm).pipe(
      map((response) =>
        TagSearchTermActions.createInTemplateSuccess({
          actions: {
            createTagSearchItemSuccess: TagSearchTermActions.createSuccess({ tagSearchTerm: response.tag_search_term }),
            findTemplateSuccess: TemplateActions.updateSuccess({ template: response.template }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
      share(),
    );
  }

  createInSubsection(subsectionId: number, tagSearchTerm: CreateTagSearchTerm) {
    return this.service.createInSubsection(subsectionId, tagSearchTerm).pipe(
      map((response) =>
        TagSearchTermActions.createInSubsectionSuccess({
          actions: {
            createTagSearchItemSuccess: TagSearchTermActions.createSuccess({ tagSearchTerm: response.tag_search_term }),
            findSubsectionSuccess: SubsectionActions.upsertOne({ subsection: response.subsection }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(tagSearchTermId: number, changes: Partial<CreateTagSearchTerm>) {
    return this.service.update(tagSearchTermId, changes).pipe(
      map((tagSearchTerm) => TagSearchTermActions.updateSuccess({ tagSearchTerm })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(tagSearchTermId: number) {
    return this.service.delete(tagSearchTermId).pipe(
      map(() => TagSearchTermActions.deleteSuccess({ tagSearchTermId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
