import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { ChoiceErrorActions } from './choice-error.action';

export type ChoiceErrorState = EntityState<RR.ProofreadChoiceError>;

const adapter = createEntityAdapter<RR.ProofreadChoiceError>();

const initialState = adapter.getInitialState();

export const choiceErrorReducer = createReducer(
  initialState,
  on(ChoiceErrorActions.setChoiceError, (state: ChoiceErrorState, { errors }) => adapter.setAll(errors, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
