import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { SubsectionActions } from '../subsection/subsection.action';

/**
 * Methods of interacting with the Elements
 *
 * This describes the actions required to interface with the elements, which are the standard Create, Read, Update, and
 * Delete (CRUD) operations. With the minor addition of getTemplate, which provides a method for only retrieving a
 * subset of the elements related to a specific template.
 */
export const ElementActions = createActionGroup({
  source: 'Element',
  events: {
    addMany: props<{ elements: RR.Element[] }>(),
    addOne: props<{ element: RR.Element }>(),
    upsertOne: props<{ element: RR.Element }>(),
    removeOne: props<{ elementId: number }>(),
    reset: emptyProps(),
  },
});

export const ElementBatchActions = {
  delete: createBatchAction(
    '[Element Batch] Delete',
    props<{
      actions: {
        elementRemoveOne: ReturnType<typeof ElementActions.removeOne>;
        subsectionUpdateSuccessAction: ReturnType<typeof SubsectionActions.upsertOne>;
      };
    }>(),
  ),
  create: createBatchAction(
    '[Element Batch] Create',
    props<{
      actions: {
        elementAddOne: ReturnType<typeof ElementActions.addOne>;
        subsectionUpdateSuccessAction: ReturnType<typeof SubsectionActions.upsertOne>;
        defaultAttributeCreateManySuccessAction: ReturnType<typeof DefaultAttributeActions.createManySuccess>;
      };
    }>(),
  ),
};
