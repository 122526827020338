import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromTextObject } from 'app/store/template/text-object';
import { fromUserSetting } from 'app/store/user/user-setting';
import { Observable, Subscription, map, switchMap } from 'rxjs';

import { DateTypeComponent } from '../../date-type/date-type.component';
import { parseVoiceInput } from '../utils/date-utils';

@Component({
  selector: 'rr-statement-date',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, VoiceDirective],
  templateUrl: './statement-date.component.html',
  styleUrls: ['./statement-date.component.css'],
})
export class StatementDateComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('dropdown') dropdown: NgbDropdown;
  @ViewChild('dateInput') dateInputElementRef!: ElementRef;

  @Output() enter = new EventEmitter();
  @Output() tabDate = new EventEmitter();

  @Input() choice_object: RR.TextObjectChoiceDate;
  textObjectDate: RR.TextObjectDate | undefined;

  dateAttribute = new FormControl<string | undefined>(undefined, { nonNullable: true });
  isDateAttrDropdownClosedByEnter = false;

  subscription = new Subscription();

  isSubmitCalledDueToKeyPress = false;

  voiceMode: boolean;

  placeholder: string;

  constructor(
    private reportService: ReportService,
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {}

  getRelatedTextObject(): Observable<RR.TextObjectDate | undefined> {
    return this.store.select(fromTextObject.selectTextObject(this.choice_object.text_object_id)).pipe(
      map((textObject) => {
        if (textObject && textObject.type !== 'date') {
          throw new Error('TextObject is not of type date');
        }
        return textObject;
      }),
    );
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      this.dateInputElementRef.nativeElement.focus();
      this.prefillDateAttribute(this.choice_object);
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.getRelatedTextObject().subscribe((textObjectDate) => {
        this.textObjectDate = textObjectDate;
        this.placeholder = DateTypeComponent.getDateTypePlaceholder(this.textObjectDate?.date_type);
      }),
    );

    const voiceMode$ = this.selectorService.selectLoadedCurrentUser().pipe(
      filterDefined(),
      switchMap((user) =>
        this.store
          .select(fromUserSetting.selectUserSetting(user.id))
          .pipe(map((userSetting) => userSetting?.voice_recognition_features || false)),
      ),
    );

    this.subscription.add(
      voiceMode$.subscribe((voiceMode) => {
        this.voiceMode = voiceMode;
      }),
    );
  }

  submitFormattedDateFromDropdown() {
    const dateValue = this.dateAttribute.value;
    const ngbDate = this.parseDateAttribute(dateValue);
    const formattedDate = this.formatDateAttribute(ngbDate);
    const textObjChoiceId = this.choice_object.id;
    if (dateValue && textObjChoiceId) {
      this.submitDateAttribute(formattedDate, textObjChoiceId);
    }
  }

  submitDateAttribute(dateValue: string | undefined, textObjChoiceId: number) {
    const data = <{ date: string }>{
      date: dateValue,
    };

    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.reportService.editTextObject(textObjChoiceId, data).subscribe(() => {
      this.dateAttribute.reset();
    });
  }

  parseDateAttribute(value: string | undefined): NgbDateStruct | null {
    const DELIMITER = '/';

    if (!value) {
      return null;
    }
    let yearString, monthString, dayString: string;
    if (this.choice_object.date_type === 'year') {
      dayString = '01';
      monthString = '01';
      [yearString] = value.split(DELIMITER);
    } else if (this.choice_object.date_type === 'month_year') {
      dayString = '01';
      [monthString, yearString] = value.split(DELIMITER);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (this.choice_object.date_type === 'day_month_year' || this.choice_object.date_type === undefined) {
      [dayString, monthString, yearString] = value.split(DELIMITER);
    } else {
      throw new Error(`Unknown date type: ${this.choice_object.date_type}`);
    }
    const day = parseInt(dayString, 10);
    const month = parseInt(monthString, 10);
    let year = parseInt(yearString, 10);

    // Converting 2 digits to 4, for example, 23 to 2023
    if (year >= 0 && year < 100) {
      year += 2000;
    }
    return {
      day: day,
      month: month,
      year: year,
    };
  }

  formatDateAttribute(date: NgbDateStruct | null): string {
    const DELIMITER = '-';
    if (date) {
      // Converting 1 digits to 2, for example 1/1 to 01/01
      const month = String(date.month).padStart(2, '0');
      const year = String(date.year);
      const day = String(date.day).padStart(2, '0');
      return `${year}${DELIMITER}${month}${DELIMITER}${day}`;
    }
    return '';
  }

  handleKeyPress(event: KeyboardEvent) {
    // Handling keyboard events for Enter, Tab, and Space keys
    if (['Enter', ' ', 'Tab'].includes(event.key)) {
      this.isSubmitCalledDueToKeyPress = true;
      // Override default Enter key behavior to prevent form submission by the browser
      if (event.key === 'Enter') {
        event.preventDefault();
      }
      this.submitFormattedDateFromDropdown();

      if (event.key === 'Enter' || event.key === ' ') {
        this.enter.emit();
      } else if (event.key === 'Tab') {
        this.tabDate.emit(event);
      }
    }
  }

  onBlur() {
    if (!this.isSubmitCalledDueToKeyPress) {
      this.submitFormattedDateFromDropdown();
    }
    this.isSubmitCalledDueToKeyPress = false;
  }

  prefillDateAttribute(obj: RR.TextObjectChoiceDate) {
    const dateType = this.choice_object.date_type;
    if (obj.date) {
      let year, month, day: string;
      if (dateType === 'month_year') {
        [year, month] = obj.date.split('-');
        this.dateAttribute.setValue(`${month}/${year}`);
      } else if (dateType === 'year') {
        [year] = obj.date.split('-');
        this.dateAttribute.setValue(`${year}`);
      } else {
        [year, month, day] = obj.date.split('-');
        this.dateAttribute.setValue(`${day}/${month}/${year}`);
      }
    }
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    if (event.detail.term.length > 0) {
      this.handleVoiceInput(event.detail.term);
    }
  }

  handleVoiceInput(input: string) {
    const date = parseVoiceInput(input);
    if (date) {
      this.dateAttribute.setValue(date);
      // emit enter so it can close the current dropdown and go to the next one
      this.enter.emit();
    }

    this.submitFormattedDateFromDropdown();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
