import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { catchError, map, tap } from 'rxjs/operators';

import { AttributeOptionActions } from '../attribute-option/attribute-option.action';
import { DefaultAttributeActions, DefaultAttributeBatchActions } from './default-attribute.action';
import { DefaultAttributeHttpService, DefaultAttributesByStatement } from './default-attribute.service';

@Injectable()
export class DefaultAttributeEffect {
  constructor(
    private store: Store<AppState>,
    private service: DefaultAttributeHttpService,
    private message: MessageService,
  ) {}

  /**
   * @deprecated replaced by findInStatementSet
   */
  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((defaultAttributes: RR.DefaultAttribute[]) => DefaultAttributeActions.findAllSuccess({ defaultAttributes })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(defaultAttribute: Partial<RR.DefaultAttribute>) {
    return this.service.create(defaultAttribute).pipe(
      this.message.handleHttpErrorPipe,
      map((defaultAttributeCreated: RR.DefaultAttribute) =>
        DefaultAttributeActions.createSuccess({ defaultAttribute: defaultAttributeCreated }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(defaultAttributeId: number, changes: Partial<RR.DefaultAttribute>) {
    return this.service.update(defaultAttributeId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((defaultAttribute: RR.DefaultAttribute) => DefaultAttributeActions.updateSuccess({ defaultAttribute })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated
   */
  delete(defaultAttributeId: number) {
    return this.service.delete(defaultAttributeId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => DefaultAttributeActions.deleteSuccess({ defaultAttributeId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      this.message.handleHttpErrorPipe,
      map((defaultAttributes: RR.DefaultAttribute[]) =>
        DefaultAttributeActions.findInTemplateSuccess({ templateId, defaultAttributes }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInStatementSet(templateId: number, statementSetId: number) {
    this.store.dispatch(DefaultAttributeActions.findInStatementSet({ templateId, statementSetId }));
    return this.service.findInStatementSet(templateId, statementSetId).pipe(
      this.message.handleHttpErrorPipe,
      catchError((err: unknown) => {
        this.store.dispatch(DefaultAttributeActions.findInStatementSetFailed({ templateId, statementSetId }));
        // Throw err again so the component can handle it
        throw err;
      }),
      map((defaultAttributes: RR.DefaultAttribute[]) =>
        DefaultAttributeActions.findInStatementSetSuccess({ templateId, statementSetId, defaultAttributes }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findByStatement(statementId: number) {
    return this.service.findByStatement(statementId).pipe(
      this.message.handleHttpErrorPipe,
      map((defaultAttributesByStatement: DefaultAttributesByStatement) => {
        const {
          default_attributes: defaultAttributes,
          attribute_options: attributeOptions,
          usages,
        } = defaultAttributesByStatement;
        return DefaultAttributeBatchActions.findByStatementSuccess({
          usages,
          actions: {
            defaultAttributeFindSuccess: DefaultAttributeActions.findManySuccess({ defaultAttributes }),
            attributeFindSuccess: AttributeOptionActions.findManySuccess({ attributeOptions }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
