import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { DicomEffect, DicomMeasurementsResponse, fromDicom } from 'app/store/dicom';
import { ImgsimParamsHttpService } from 'app/store/imgsim-parameters';
import { TextObjectEffect } from 'app/store/template/text-object';
import { Observable, Subscription } from 'rxjs';

import { ParameterDropdownComponent } from '../../../../shared/components/parameter-dropdown/parameter-dropdown.component';
import { MeasurementRuleComponent } from '../../dicom-sr/measurement-rule.component';
import { NumberTextObjectElement } from '../../slatement/slatement-react/slate-types';
import { MeasurementDropdownComponent } from './measurement-dropdown/measurement-dropdown.component';

type FormType = ReturnType<StatementNumberModalComponent['form']['getRawValue']>;

@Component({
  templateUrl: './statement-number-modal.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MeasurementRuleComponent,
    MeasurementDropdownComponent,
    ParameterDropdownComponent,
  ],
})
export class StatementNumberModalComponent implements OnInit, OnDestroy {
  @Input() report_id: number | undefined;
  @Output() formOutput = new EventEmitter<Pick<FormType, 'upper' | 'lower' | 'formula'>>();
  text_object: RR.TextObjectNumber | undefined;
  slateNumberElement: NumberTextObjectElement | undefined;
  subscription = new Subscription();
  // measurementsSet has many measurements, measurements has many measurement
  measurementsSet: DicomMeasurementsResponse = [];
  measurementRuleSTOs: RR.MeasurementRuleTextObject[] = [];
  linkedPath = '';
  measurementsLoading$: Observable<boolean>;

  parameters: RR.ImgsimParameter[] = [];
  parameterTextObject: RR.ImgsimParameterRuleTextObject | undefined;

  openNumberModalSource: 'slateEditor' | 'statementEditor';

  form = new FormGroup(
    {
      lower: new FormControl<number | null>(null, { nonNullable: true }),
      upper: new FormControl<number | null>(null, { nonNullable: true }),
      formula: new FormControl<string | null>(null, { nonNullable: true }),
      round_to_nearest: new FormControl<boolean>(false, { nonNullable: true }),
      exclude_if_outside_range: new FormControl<boolean>(false, { nonNullable: true }),
      include_if_outside_range: new FormControl<boolean>(false, { nonNullable: true }),
    },
    { validators: this.checkBounds },
  );

  constructor(
    public activeModal: NgbActiveModal,
    private editorService: EditorService,
    private store: Store<AppState>,
    private dicomEffect: DicomEffect,
    private http: HttpClient,
    private imgsimParamsHttpService: ImgsimParamsHttpService,
    private messageService: MessageService,
    private textObjectEffect: TextObjectEffect,
  ) {}

  init() {
    if (this.isTypeStatement()) {
      this.form.patchValue({
        lower: this.text_object.lower,
        upper: this.text_object.upper,
        formula: this.text_object.formula,
        round_to_nearest: this.text_object.round_to_nearest,
        exclude_if_outside_range: this.text_object.exclude_if_outside_range,
        include_if_outside_range: this.text_object.include_if_outside_range,
      });
    } else if (this.isTypeSlate()) {
      this.form.patchValue({
        lower: this.slateNumberElement.lower,
        upper: this.slateNumberElement.upper,
        formula: this.slateNumberElement.formula,
      });
    }
  }

  ngOnInit() {
    // Not loading dicom data when opening number modal from statement edit modal
    if (!this.report_id) return;

    this.measurementsLoading$ = this.store.select(fromDicom.measurementsLoading);
    if (this.isTypeStatement()) {
      this.subscription.add(this.dicomEffect.loadMeasurements(this.report_id).subscribe());
      this.subscription.add(
        this.store
          .select(fromDicom.selectMeasurements)
          .pipe(filterDefined())
          .subscribe((measurements) => {
            this.measurementsSet = measurements;
          }),
      );
      this.getParameters();
      this.getParameterLinkedToTextObject();

      this.subscription.add(
        this.editorService.fetchLinkedMeasurementRules(this.text_object.id).subscribe((data) => {
          if (data.length > 0) {
            this.measurementRuleSTOs = data;
          }
        }),
      );
    }
  }

  isTypeStatement(): this is StatementNumberModalComponent & { text_object: RR.TextObjectNumber } {
    return this.openNumberModalSource === 'statementEditor';
  }

  isTypeSlate(): this is StatementNumberModalComponent & { slateNumberElement: NumberTextObjectElement } {
    return this.openNumberModalSource === 'slateEditor';
  }

  assertIsTypeStatement(): asserts this is StatementNumberModalComponent & { text_object: RR.TextObjectNumber } {
    if (!this.isTypeStatement()) {
      throw Error("StatementNumberModalComponent is not of type 'statementEditor'");
    }
  }

  checkBounds(control: AbstractControl) {
    const lowerControl = control.get('lower');
    const upperControl = control.get('upper');
    if (lowerControl === null || upperControl === null) {
      throw Error('Could not find lower or upper FormControl');
    }

    const lower: number = lowerControl.value;
    const upper: number = upperControl.value;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (lower == null || upper == null || lower < upper) {
      return null;
    } else {
      return { bounds: true };
    }
  }

  close() {
    const form = this.form.getRawValue();

    if (this.openNumberModalSource === 'slateEditor') {
      this.formOutput.emit({
        lower: form.lower,
        upper: form.upper,
        formula: form.formula,
      });
      this.activeModal.close();
    } else {
      if (this.isTypeStatement()) {
        this.subscription.add(
          this.textObjectEffect
            .update(this.text_object.id, {
              ...form,
            })

            .subscribe(() => {
              this.activeModal.close();
            }),
        );
      }
    }
  }

  static open(
    modal: NgbModal,
    {
      text_object,
      report_id,
      openFrom,
      slateNumberElement,
    }: {
      text_object?: RR.TextObjectNumber;
      report_id: number | undefined;
      openFrom: 'statementEditor' | 'slateEditor';
      slateNumberElement?: NumberTextObjectElement;
    },
  ) {
    const modalRef = modal.open(StatementNumberModalComponent, {
      size: 'lg',
    });
    const componentInstance: StatementNumberModalComponent = modalRef.componentInstance;

    componentInstance.text_object = text_object;
    componentInstance.slateNumberElement = slateNumberElement;
    componentInstance.report_id = report_id;
    componentInstance.openNumberModalSource = openFrom;
    componentInstance.init();
    return modalRef;
  }

  chooseMeasurement(matchingMeasurement: RR.MatchingMeasurement) {
    this.assertIsTypeStatement();
    let obs$: Observable<RR.MeasurementRuleTextObject>;
    if (matchingMeasurement.measurement_rule.id != null) {
      obs$ = this.editorService.linkMeasurementRuleToTextObject(
        matchingMeasurement.measurement_rule.id,
        this.text_object.id,
      );
    } else {
      obs$ = this.editorService.linkMeasurementToTextObject(matchingMeasurement.measurement_rule, this.text_object.id);
    }
    this.subscription.add(
      obs$.subscribe((rule_sto) => {
        this.measurementRuleSTOs.push(rule_sto);
      }),
    );
  }

  removeLinkedMeasurementRules(obj: RR.MeasurementRuleTextObject) {
    this.subscription.add(
      this.editorService.deleteMeasurementRuleLink(obj.id).subscribe(() => {
        const index = this.measurementRuleSTOs.findIndex((o) => o.id === obj.id);
        this.measurementRuleSTOs.splice(index, 1);
      }),
    );
  }

  getParameters() {
    if (!this.report_id) return;
    this.subscription.add(
      this.imgsimParamsHttpService.getReportImgSimParams(this.report_id).subscribe((data) => {
        this.parameters = data;
      }),
    );
  }

  chooseParameter(obj: RR.ImgsimParameter) {
    this.assertIsTypeStatement();
    this.subscription.add(
      this.http
        .post<RR.ImgsimParameterRuleTextObject>(`/api/parameter/${obj.id}/text_object/${this.text_object.id}`, {})
        .subscribe({
          next: (data) => {
            this.parameterTextObject = data;
          },
          error: (err: unknown) => {
            this.messageService.httpErrorMessage(err);
          },
        }),
    );
  }

  removeParameter(obj: RR.ImgsimParameterRuleTextObject) {
    this.subscription.add(
      this.http.delete(`/api/parameter/text_object/${obj.id}`).subscribe(() => {
        this.parameterTextObject = undefined;
      }),
    );
  }

  getParameterLinkedToTextObject() {
    this.assertIsTypeStatement();
    this.subscription.add(
      this.http.get<RR.ImgsimParameterRuleTextObject>(`/api/parameter/${this.text_object.id}`).subscribe((data) => {
        this.parameterTextObject = data;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
