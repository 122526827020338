<div class="modal-header">
  <h5 class="modal-title">DICOM SR Length (L)</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div *ngIf="loadingProposed">Loading...</div>
  <div *ngIf="!loadingProposed">
    <button class="btn btn-outline-dark" (click)="chooseAllProposed()">Choose all</button>
    <p>
      <i class="text-primary fas fa-question-circle"></i> Click to add statements with auto filled measurements into the
      report
    </p>
  </div>
  <ng-container *ngFor="let group of data">
    <div class="list-group d-flex flex-grow-1 flex-column">
      <ng-container *ngFor="let proposed of group.proposed_statements">
        <rr-recommended-statement
          [statement]="proposed.statement"
          [topic]="topic"
          [proposed]="proposed"
        ></rr-recommended-statement>
      </ng-container>
    </div>
    <hr />
  </ng-container>
  <div *ngIf="report.has_dicom_sr_cached" class="mt-1">
    <a
      class="d-block"
      style="width: max-content"
      [href]="'/api/dicom/tree?report_id=' + topic.report_id"
      target="_blank"
      >Show debug data (tree)</a
    >
    <a
      class="d-block"
      style="width: max-content"
      [href]="'/api/dicom/dsr2html?report_id=' + topic.report_id"
      target="_blank"
      >Show debug data (dsr2html)</a
    >
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="openImgsimParameters()" rrTooltip="SHOW_IMG_SIMILARITY_PARAMETERS">
    Img Sim Parameters (^)
  </button>
</div>
