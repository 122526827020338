import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromAuditEvent } from '../audit-event/audit-event.selector';

const adapter = createEntityAdapter<RR.Todo>();
const selectFeature = (state: AppState) => state.todo;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInReport = (reportId: number) =>
  createSelector(selectAll, (todos) =>
    todos
      .filter((todo) => todo.report_id === reportId)
      .sort((t1, t2) => {
        return new Date(t2.created).getTime() - new Date(t1.created).getTime();
      }),
  );

const selectTodo = (todoId: number) => createSelector(selectEntities, (todos: Dictionary<RR.Todo>) => todos[todoId]);

const selectAuditEvents = (todoId: number) =>
  createSelector(
    selectTodo(todoId),
    fromAuditEvent.selectEntities,
    (todo: RR.Todo | undefined, auditEvents: Dictionary<RR.AuditEvent>) =>
      todo?.audit_events.map((auditEventId) => auditEvents[auditEventId]).filter((a): a is RR.AuditEvent => !!a),
  );

export const fromTodo = {
  selectIds,
  selectEntities,
  selectAll,
  selectInReport,
  selectAuditEvents,
};
