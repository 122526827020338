<!--Exact match search-->
<ng-container
  *ngIf="{ expanded: prefillExpanded$ | async, exactMatchOpen: prefillService.exactMatchOpen$ | async } as prefill"
>
  <div class="d-flex w-100 mt-auto" rrTooltip="EXACT_MATCH_TOPICS">
    <div
      role="button"
      class="card-header mt-1 text-white w-100 px-2 py-1 d-flex align-items-center"
      [ngClass]="prefill.expanded && prefill.exactMatchOpen ? 'bg-success' : 'bg-secondary'"
      (click)="toggleExactMatch()"
    >
      <h4 class="mb-0">
        <span *ngIf="prefill.expanded && prefill.exactMatchOpen">
          <i class="fas fa-chevron-down"></i>
        </span>
        <span *ngIf="!prefill.exactMatchOpen || !prefill.expanded">
          <i class="fas fa-chevron-right"></i>
        </span>

        Report Statements Match
      </h4>

      <div class="ms-2 d-flex" *ngIf="!searching && prefill.exactMatchOpen && prefill.expanded">
        <button
          *ngIf="!searching && prefill.exactMatchOpen"
          class="btn btn-sm btn-outline-light"
          rrTooltip="EXACT_MATCH_REFRESH"
          (click)="refresh(); $event.stopPropagation()"
        >
          <span class="fa fa-sync"></span>
        </button>
      </div>
      <h4 class="ms-auto mb-0"><strong>B3</strong></h4>
    </div>
  </div>

  <!--Boost exact match-->
  <form *ngIf="prefill.exactMatchOpen && prefill.expanded" [formGroup]="searchForm" class="my-1">
    <div class="d-flex align-items-center form-group">
      <div class="ms-2">
        <label class="mb-0" for="title">Title</label>
        <select class="form-select" id="title" formControlName="title">
          <option *ngFor="let val of BOOST_SCORES" [ngValue]="val">{{ val }}</option>
        </select>
      </div>
      <div class="ms-2">
        <label class="mb-0" for="age">Age</label>
        <select class="form-select" id="age" formControlName="age">
          <option *ngFor="let val of BOOST_SCORES" [ngValue]="val">{{ val }}</option>
        </select>
      </div>
      <div class="ms-2">
        <label class="mb-0" for="rarity">Rare choices</label>
        <select class="form-select" id="rarity" formControlName="rarity">
          <option *ngFor="let val of BOOST_SCORES" [ngValue]="val">{{ val }}</option>
        </select>
      </div>
      <div class="ms-2">
        <label class="mb-0" for="recency">Recent choices</label>
        <select class="form-select" id="recency" formControlName="recency">
          <option *ngFor="let val of BOOST_SCORES" [ngValue]="val">{{ val }}</option>
        </select>
      </div>
    </div>
  </form>

  <div
    *ngIf="searching && prefill.expanded && prefill.exactMatchOpen"
    class="d-flex justify-content-center loading my-4"
  >
    <i class="fa fa-spinner fa-spin text-secondary"></i>
  </div>

  <ng-container *ngIf="similarTopics$ | async as similarTopics">
    <div class="search-results" *ngIf="prefill.expanded && prefill.exactMatchOpen">
      <div *ngIf="similarTopics && similarTopics.length === 0 && !searching">No result</div>
      <div *ngIf="similarTopics && similarTopics.length > 0" class="search-results">
        <rr-prefill-exact-match-row
          role="button"
          class="list-group-item list-group-item-action"
          *ngFor="let result of similarTopics"
          [topic]="result"
          [open_topic_id]="topic_id"
        ></rr-prefill-exact-match-row>
      </div>
    </div>
  </ng-container>
</ng-container>
