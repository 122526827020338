<div class="modal-header">
  <h5 class="modal-title">{{ mode === 'NEW' ? 'New Region Set' : 'Edit Region Set' }}</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <!--Region set list-->
  <div class="d-flex flex-column" *ngIf="mode === 'EDIT'">
    <label class="form-label">Region Sets</label>
    <div class="d-flex">
      <div class="col-6 ps-0">
        <div class="form-group mb-2">
          <select class="form-select" [(ngModel)]="selectedRegionSet">
            <option *ngFor="let region_set of regionSets" [ngValue]="region_set">{{ region_set.name }}</option>
          </select>
        </div>
      </div>
      <div class="ms-1" *ngIf="selectedRegionSet">
        <button class="btn btn-danger" role="button" (click)="deleteRegionSet()">
          <span class="fa fa-trash"></span> Delete
        </button>
      </div>
      <div class="ms-auto">
        <button class="btn btn-success" role="button" (click)="addNewRegionSet()">
          <span class="fa fa-plus"></span> New Region Set
        </button>
      </div>
    </div>

    <!--Rename or add new region for selected region set-->
    <div>
      <form class="form-group mb-2 d-flex flex-column">
        <label class="my-1">Name:</label>
        <div class="d-flex">
          <input
            #selectedRegionSetName
            class="form-control border-secondary col-6"
            type="text"
            placeholder="Region set name"
            [value]="selectedRegionSet.name"
            name="selectedRegionSetName"
            autocomplete="off"
            required
            ngbAutoFocus
          />
          <button
            class="btn btn-primary ms-1"
            [disabled]="selectedRegionSetName.value === ''"
            role="button"
            (click)="renameRegionSet(selectedRegionSet, selectedRegionSetName.value)"
          >
            <span class="fa fa-edit"></span> Rename
          </button>
        </div>

        <label class="my-1">Add region:</label>
        <div class="d-flex">
          <input
            class="form-control border-secondary"
            type="text"
            placeholder="Region name"
            [(ngModel)]="regionName"
            name="regionName"
            autocomplete="off"
            required
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 1"
            [(ngModel)]="discName"
            name="discName"
            autocomplete="off"
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 2"
            [(ngModel)]="nextName"
            name="nextName"
            autocomplete="off"
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 3"
            [(ngModel)]="spaceName"
            name="spaceName"
            autocomplete="off"
          />
          <button [disabled]="regionName === ''" class="btn btn-primary ms-2" (click)="addRegionToSelectedRegionSet()">
            Add
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="selectedRegionSet" class="col-12 ps-0 table-height">
      <table class="table table-bordered table-striped text-center">
        <tr>
          <th class="text-center">Region Name</th>
          <th class="text-center">Variable 1</th>
          <th class="text-center">Variable 2</th>
          <th class="text-center">Variable 3</th>
          <th class="text-center"></th>
        </tr>
        <tr *ngFor="let region of getRegions() | async; let id = index">
          <td class="w-30">
            <span
              class="p-2"
              contenteditable="true"
              (blur)="handleEditRegion($event, region, 'name')"
              (keydown.enter)="handleEditRegion($event, region, 'name')"
              (keydown.escape)="handleEditRegion($event, region, 'name', true)"
              >{{ region.name }}</span
            >
          </td>
          <td class="w-20">
            <span
              class="p-2"
              contenteditable="true"
              (blur)="handleEditRegion($event, region, 'disc_name')"
              (keydown.enter)="handleEditRegion($event, region, 'disc_name')"
              (keydown.escape)="handleEditRegion($event, region, 'disc_name', true)"
              >{{ region.disc_name }}</span
            >
          </td>
          <td class="w-20">
            <span
              class="p-2"
              contenteditable="true"
              (blur)="handleEditRegion($event, region, 'next_name')"
              (keydown.enter)="handleEditRegion($event, region, 'next_name')"
              (keydown.escape)="handleEditRegion($event, region, 'next_name', true)"
              >{{ region.next_name }}</span
            >
          </td>
          <td class="w-20">
            <span
              class="p-2"
              contenteditable="true"
              (blur)="handleEditRegion($event, region, 'space_name')"
              (keydown.enter)="handleEditRegion($event, region, 'space_name')"
              (keydown.escape)="handleEditRegion($event, region, 'space_name', true)"
              >{{ region.space_name }}</span
            >
          </td>
          <td class="w-10">
            <button role="button" class="btn ms-auto" (click)="removeRegionFromRegionSet(region)">
              <span class="fa fa-trash-alt text-danger"></span>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!--New region set-->
  <div *ngIf="mode === 'NEW'">
    <form class="form-group mb-2 d-flex flex-column">
      <input
        class="form-control border-secondary"
        type="text"
        placeholder="Region set name"
        [(ngModel)]="regionSetName"
        name="regionSetName"
        autocomplete="off"
        required
      />
      <div class="text-danger" *ngIf="!!error">{{ error }}</div>
      <div class="mt-2 d-flex flex-column">
        <span>Add regions for this region set:</span>
        <div class="d-flex">
          <input
            class="form-control border-secondary"
            type="text"
            placeholder="Region name"
            [(ngModel)]="regionName"
            name="regionName"
            autocomplete="off"
            required
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 1"
            [(ngModel)]="discName"
            name="discName"
            autocomplete="off"
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 2"
            [(ngModel)]="nextName"
            name="nextName"
            autocomplete="off"
          />
          <input
            class="form-control border-secondary ms-1"
            type="text"
            placeholder="Variable 3"
            [(ngModel)]="spaceName"
            name="spaceName"
            autocomplete="off"
          />
          <button [disabled]="regionName === ''" class="btn btn-primary ms-2" (click)="addRegion()">Add</button>
        </div>

        <ul class="list-group mt-2">
          <li class="list-group-item" *ngFor="let region of regions; let i = index">
            <div class="d-flex align-items-center">
              <div class="col-3">{{ region.name }}</div>
              <div class="col-3">{{ region.disc_name }}</div>
              <div class="col-3">{{ region.next_name }}</div>
              <div class="col-2">{{ region.space_name }}</div>
              <button role="button" class="btn ms-auto" (click)="removeRegion(i)">
                <span class="fa fa-trash-alt text-danger"></span>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer d-flex justify-content-end" *ngIf="mode === 'NEW'">
  <button class="btn btn-secondary" role="button" (click)="cancelClicked()">Cancel</button>
  <button class="btn btn-primary" role="button" (click)="okClicked()">OK</button>
</div>
