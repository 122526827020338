<ng-container *ngIf="report$ | async as report">
  <ng-template #defaultTemplate>
    <i class="fas fa-exclamation-triangle"></i>
    <span class="ms-1">{{ report.super_urgent ? 'Undo Super Urgent' : 'Mark Super Urgent' }} (*)</span>
  </ng-template>
  <button
    role="button"
    class="btn {{ btnSize }} {{ btnClassName }}"
    [ngClass]="report.super_urgent ? 'btn-danger' : btnColour"
    (click)="onClick(report)"
    rrTooltip="MARK_AS_SUPER_URGENT"
    [disabled]="report.urgent"
  >
    <div [rrNoContent]="defaultTemplate"><ng-content></ng-content></div>
  </button>
</ng-container>
