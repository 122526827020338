import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RegionSetHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.RegionSet[]> {
    return this.http.get<RR.RegionSet[]>('/api/region_set');
  }

  create(regionSet: Partial<RR.RegionSet>): Observable<RR.RegionSet> {
    return this.http.post<RR.RegionSet>('/api/region_set', regionSet);
  }

  update(regionSetId: number, changes: Partial<RR.RegionSet>): Observable<RR.RegionSet> {
    return this.http.put<RR.RegionSet>(`/api/region_set/${regionSetId}`, changes);
  }

  delete(regionSetId: number): Observable<void> {
    return this.http.delete<void>(`/api/region_set/${regionSetId}`);
  }

  findInTemplate(templateId: number): Observable<RR.RegionSet[]> {
    return this.http.get<RR.RegionSet[]>(`/api/template/${templateId}/region_set`);
  }
}
