const dayMap: { [key: string]: string } = {
  // ordinal numeric
  '1st': '01',
  '2nd': '02',
  '3rd': '03',
  '4th': '04',
  '5th': '05',
  '6th': '06',
  '7th': '07',
  '8th': '08',
  '9th': '09',
  '10th': '10',
  '11th': '11',
  '12th': '12',
  '13th': '13',
  '14th': '14',
  '15th': '15',
  '16th': '16',
  '17th': '17',
  '18th': '18',
  '19th': '19',
  '20th': '20',
  '21st': '21',
  '22nd': '22',
  '23rd': '23',
  '24th': '24',
  '25th': '25',
  '26th': '26',
  '27th': '27',
  '28th': '28',
  '29th': '29',
  '30th': '30',
  '31st': '31',
  // numeric string
  one: '01',
  two: '02',
  three: '03',
  four: '04',
  five: '05',
  six: '06',
  seven: '07',
  eight: '08',
  nine: '09',
  ten: '10',
  eleven: '11',
  twelve: '12',
  thirteen: '13',
  fourteen: '14',
  fifteen: '15',
  sixteen: '16',
  seventeen: '17',
  eighteen: '18',
  nineteen: '19',
  twenty: '20',
  'twenty-one': '21',
  'twenty-two': '22',
  'twenty-three': '23',
  'twenty-four': '24',
  'twenty-five': '25',
  'twenty-six': '26',
  'twenty-seven': '27',
  'twenty-eight': '28',
  'twenty-nine': '29',
  thirty: '30',
  'thirty-one': '31',
  // ordinal string
  first: '01',
  second: '02',
  third: '03',
  fourth: '04',
  fifth: '05',
  sixth: '06',
  seventh: '07',
  eighth: '08',
  nineth: '09',
  tenth: '10',
  eleventh: '11',
  twelveth: '12',
  thirteenth: '13',
  fourteenth: '14',
  fifteenth: '15',
  sixteenth: '16',
  seventeenth: '17',
  eighteenth: '18',
  nineteenth: '19',
  twentieth: '20',
  'twenty-first': '21',
  'twenty-second': '22',
  'twenty-third': '23',
  'twenty-fourth': '24',
  'twenty-fifth': '25',
  'twenty-sixth': '26',
  'twenty-seventh': '27',
  'twenty-eighth': '28',
  'twenty-nineth': '29',
  thirtieth: '30',
  'thirty-first': '31',
};

const monthMap: { [key: string]: string } = {
  // shortened months
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  sept: '09',
  oct: '10',
  nov: '11',
  dec: '12',
  // full months
  january: '01',
  february: '02',
  march: '03',
  april: '04',
  june: '06',
  july: '07',
  august: '08',
  september: '09',
  october: '10',
  november: '11',
  december: '12',
};

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'sept',
  'oct',
  'nov',
  'dec',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

const days = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  '29th',
  '30th',
  '31st',
  // spoken nums
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
  'twenty-one',
  'twenty-two',
  'twenty-three',
  'twenty-four',
  'twenty-five',
  'twenty-six',
  'twenty-seven',
  'twenty-eight',
  'twenty-nine',
  'thirty',
  'thirty-one',
  // ordinal nums
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'nineth',
  'tenth',
  'eleventh',
  'twelveth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
  'twenty',
  'twenty-first',
  'twenty-second',
  'twenty-third',
  'twenty-fourth',
  'twenty-fifth',
  'twenty-sixth',
  'twenty-seventh',
  'twenty-eighth',
  'twenty-nineth',
  'thirtieth',
  'thirty-first',
];

export function parseVoiceInput(input: string) {
  // split the input into words
  const words = input.split(' ');

  // days <= 31 && can be a number (31), word (thirty-one), or both (31st)

  const dayNum = words.find((word) => !isNaN(Number(word)) && Number(word) <= 31);
  const dayWord = words.find((word) => days.includes(word.toLowerCase()));

  // months have to be the full word (January) or the abbreviation (Jan)
  const month = words.find((word) => months.includes(word.toLowerCase()));

  // years have to be greater than 1900 and is a number (2023)
  const year = words.find((word) => !isNaN(Number(word)) && Number(word) > 1900);

  let day: string | undefined = undefined;
  if (dayWord && dayWord.toLowerCase() in dayMap) {
    day = dayMap[dayWord.toLowerCase()];
  } else if (dayNum) {
    day = dayNum.padStart(2, '0');
  }

  // if we have year, month/year, or day/month/year, we can return a date
  if (!year || (day && !month)) {
    return;
  }

  let date: string;

  if (month) {
    const monthNum = monthMap[month.toLowerCase()];
    if (day) {
      date = `${day}/${monthNum}/${year}`;
    } else {
      date = `${monthNum}/${year}`;
    }
  } else if (year) {
    date = `${year}`;
  } else {
    return;
  }
  return date;
}
