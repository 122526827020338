import { props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { ElementChoiceActions } from '../element-choice/element-choice.action';
import { RegionChoiceActions } from '../region-choice/region-choice.action';
import { SectionChoiceActions } from '../section-choice/section-choice.action';
import { StatementChoiceActions } from '../statement-choice/statement-choice.action';
import { SubsectionChoiceActions } from '../subsection-choice/subsection-choice.action';
import { TextObjectChoiceActions } from '../text-object-choice/text-object-choice.action';
import { TopicActions } from '../topic/topic.action';
import { ReportActions } from './report.action';

export interface ChangeTrackerResponse {
  new_text_object_choice: RR.TextObjectChoice[];
  new_statement_choice: RR.StatementChoice[];
  new_element_choice: RR.ElementChoice[];
  new_region_choice: RR.RegionChoice[];
  new_subsection_choice: RR.SubsectionChoice[];
  new_section_choice: RR.SectionChoice[];
  new_report: RR.Report[];
  new_topic: RR.Topic[];

  // TODO: Partial
  updated_text_object_choice: RR.TextObjectChoice[];
  updated_statement_choice: RR.StatementChoice[];
  updated_element_choice: RR.ElementChoice[];
  updated_region_choice: RR.RegionChoice[];
  updated_subsection_choice: RR.SubsectionChoice[];
  updated_section_choice: RR.SectionChoice[];
  updated_report: RR.Report[];
  updated_topic: RR.Topic[];

  deleted_text_object_choice: number[];
  deleted_statement_choice: number[];
  deleted_element_choice: number[];
  deleted_region_choice: number[];
  deleted_subsection_choice: number[];
  deleted_section_choice: number[];
  deleted_report: number[];
  deleted_topic: number[];
}

/**
 * An hybrid handler to handle new pattern of dispatching actions for new refactoring entities while still keep the old
 * action pattern for the entities that haven't been refactored yet
 * @param response
 * @returns
 */
export const mapChangeTrackerToSuccessActions = (response: ChangeTrackerResponse) => {
  return ChangeTrackerBatchActions.entitiesChangedBatchAction({
    actions: {
      // Changes for Report
      reportAddMany: ReportActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        reports: response.new_report || [],
      }),
      reportUpsertMany: ReportActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        reports: response.updated_report || [],
      }),
      reportRemoveMany: ReportActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        reportIds: response.deleted_report || [],
      }),

      // Changes for Topic
      topicAddMany: TopicActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        topics: response.new_topic || [],
      }),
      topicUpdateMany: TopicActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        topics: response.updated_topic || [],
      }),
      topicDeleteManySuccess: TopicActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        topicIds: response.deleted_topic || [],
      }),

      // Changes for Section Choice
      sectionChoiceAddMany: SectionChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sectionChoices: response.new_section_choice || [],
      }),
      sectionChoiceUpsertMany: SectionChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sectionChoices: response.updated_section_choice || [],
      }),
      sectionChoiceRemoveMany: SectionChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        sectionChoiceIds: response.deleted_section_choice || [],
      }),

      // Changes for Subsection Choice
      subsectionChoiceAddMany: SubsectionChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        subsectionChoices: response.new_subsection_choice || [],
      }),
      subsectionChoiceUpsertMany: SubsectionChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        subsectionChoices: response.updated_subsection_choice || [],
      }),
      subsectionChoiceRemoveMany: SubsectionChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        subsectionChoiceIds: response.deleted_subsection_choice || [],
      }),

      // Changes for Region Choice
      regionChoiceAddMany: RegionChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        regionChoices: response.new_region_choice || [],
      }),
      regionChoiceUpsertMany: RegionChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        regionChoices: response.updated_region_choice || [],
      }),
      regionChoiceRemoveMany: RegionChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        regionChoiceIds: response.deleted_region_choice || [],
      }),

      // Changes for Element Choice
      elementChoiceAddMany: ElementChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        elementChoices: response.new_element_choice || [],
      }),
      elementChoiceUpsertMany: ElementChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        elementChoices: response.updated_element_choice || [],
      }),
      elementChoiceRemoveMany: ElementChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        elementChoiceIds: response.deleted_element_choice || [],
      }),

      // Changes for Statement Choice
      statementChoiceAddMany: StatementChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        statementChoices: response.new_statement_choice || [],
      }),
      statementChoiceUpsertMany: StatementChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        statementChoices: response.updated_statement_choice || [],
      }),
      statementChoiceRemoveMany: StatementChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        statementChoiceIds: response.deleted_statement_choice || [],
      }),

      // Changes for Statement Choice Object
      textObjectChoiceAddMany: TextObjectChoiceActions.addMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        textObjectChoices: response.new_text_object_choice || [],
      }),
      textObjectChoiceUpsertMany: TextObjectChoiceActions.upsertMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        textObjectChoices: response.updated_text_object_choice || [],
      }),
      textObjectChoiceRemoveMany: TextObjectChoiceActions.removeMany({
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        textObjectChoiceIds: response.deleted_text_object_choice || [],
      }),
    },
  });
};

export const ChangeTrackerBatchActions = {
  entitiesChangedBatchAction: createBatchAction(
    '[ChangeTracker] Entities Changed Batch Action',
    props<{
      actions: {
        // Report
        reportAddMany: ReturnType<typeof ReportActions.addMany>;
        reportUpsertMany: ReturnType<typeof ReportActions.upsertMany>;
        reportRemoveMany: ReturnType<typeof ReportActions.removeMany>;

        // Topic
        topicAddMany: ReturnType<typeof TopicActions.addMany>;
        topicUpdateMany: ReturnType<typeof TopicActions.upsertMany>;
        topicDeleteManySuccess: ReturnType<typeof TopicActions.removeMany>;

        // SectionChoice
        sectionChoiceAddMany: ReturnType<typeof SectionChoiceActions.addMany>;
        sectionChoiceUpsertMany: ReturnType<typeof SectionChoiceActions.upsertMany>;
        sectionChoiceRemoveMany: ReturnType<typeof SectionChoiceActions.removeMany>;

        // SubsectionChoice
        subsectionChoiceAddMany: ReturnType<typeof SubsectionChoiceActions.addMany>;
        subsectionChoiceUpsertMany: ReturnType<typeof SubsectionChoiceActions.upsertMany>;
        subsectionChoiceRemoveMany: ReturnType<typeof SubsectionChoiceActions.removeMany>;

        // RegionChoice
        regionChoiceAddMany: ReturnType<typeof RegionChoiceActions.addMany>;
        regionChoiceUpsertMany: ReturnType<typeof RegionChoiceActions.upsertMany>;
        regionChoiceRemoveMany: ReturnType<typeof RegionChoiceActions.removeMany>;

        // ElementChoice
        elementChoiceAddMany: ReturnType<typeof ElementChoiceActions.addMany>;
        elementChoiceUpsertMany: ReturnType<typeof ElementChoiceActions.upsertMany>;
        elementChoiceRemoveMany: ReturnType<typeof ElementChoiceActions.removeMany>;

        // StatementChoice
        statementChoiceAddMany: ReturnType<typeof StatementChoiceActions.addMany>;
        statementChoiceUpsertMany: ReturnType<typeof StatementChoiceActions.upsertMany>;
        statementChoiceRemoveMany: ReturnType<typeof StatementChoiceActions.removeMany>;

        // TextObjectChoice
        textObjectChoiceAddMany: ReturnType<typeof TextObjectChoiceActions.addMany>;
        textObjectChoiceUpsertMany: ReturnType<typeof TextObjectChoiceActions.upsertMany>;
        textObjectChoiceRemoveMany: ReturnType<typeof TextObjectChoiceActions.removeMany>;
      };
    }>(),
  ),
};
