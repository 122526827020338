<div class="col-sm-9 mx-auto my-4">
  <h2 class="text-center mb-2"><strong>Registration Questions</strong></h2>
  <div class="text-center mb-4" *ngIf="!!patientQuestionSets?.length && !viewonly">
    (The answers will be saved automatically when you click on the options)
  </div>
  <div class="text-center mb-4" *ngIf="!patientQuestionSets?.length">
    No patient history questions available for this report.
  </div>
  <ng-container *ngFor="let questionSet of patientQuestionSets">
    <h4 class="mb-2">
      <strong>{{ questionSet.name }}</strong>
    </h4>
    <table class="table table-striped w-100">
      <thead>
        <tr>
          <th scope="col" class="w-75">Question</th>
          <th scope="col" class="w-25 text-end">Answer</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let question_id of activeParentQuestionsInQuestionSet[questionSet.id]">
          <tr
            rr-questionnaire
            *ngIf="patientQuestionMap[question_id]"
            [question]="patientQuestionMap[question_id]"
            [answer]="patientAnswerMap[question_id]"
            [viewonly]="viewonly"
            [report_id]="report_id"
          ></tr>
          <!--Children questions-->
          <ng-container
            *ngIf="
              !!patientQuestionMap[question_id].children_questions?.length &&
              patientAnswerMap[question_id]?.answer === 'Yes'
            "
          >
            <ng-container *ngFor="let children_id of patientQuestionMap[question_id].children_questions">
              <!--To exclude children questions that are not in template-->
              <tr
                rr-questionnaire
                *ngIf="patientQuestionMap[children_id]"
                [question]="patientQuestionMap[children_id]"
                [answer]="patientAnswerMap[children_id]"
                [viewonly]="viewonly"
                [report_id]="report_id"
              ></tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
</div>
