import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { RegionSetActions } from './region-set.action';

export interface RegionSetState extends EntityState<RR.RegionSet> {}

const adapter = createEntityAdapter<RR.RegionSet>();

const initialState = adapter.getInitialState();

export const regionSetReducer = createReducer(
  initialState,
  on(RegionSetActions.findAllSuccess, (state, { regionSets }) => ({
    ...adapter.setAll(regionSets, state),
  })),
  on(RegionSetActions.createSuccess, (state, { regionSet }) => adapter.addOne(regionSet, state)),
  on(RegionSetActions.updateSuccess, (state, { regionSet }) => adapter.upsertOne(regionSet, state)),
  on(RegionSetActions.deleteSuccess, (state, { regionSetId }) => adapter.removeOne(regionSetId, state)),
  on(RegionSetActions.findInTemplateSuccess, (state, { regionSets }) => adapter.addMany(regionSets, state)),
  on(RegionSetActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
