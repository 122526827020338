<form [formGroup]="form" class="bootstrap-validate">
  <div class="form-group mb-4">
    <label class="control-label">Funder</label>

    <select class="form-select" formControlName="funder">
      <option *ngFor="let funder of funders" [value]="funder.id">{{ funder.name }}</option>
    </select>

    <div *ngIf="form.controls.funder.value === 'other'" class="mt-2">
      <rr-select-institution
        [institution_id]="form.controls.institution_id.value"
        (onInstitutionSelect)="selectInstitution($event)"
      ></rr-select-institution>
      <div class="row" *ngIf="form.controls.institution_id.value">
        <div class="form-group mb-2 col">
          <label class="control-label">Contact Person</label>
          <input formControlName="institution_contact_person" type="text" class="form-control" />
        </div>
        <div class="form-group mb-2 col">
          <label class="control-label">Phone</label>
          <input formControlName="institution_phone" type="text" class="form-control" />
        </div>
        <div class="form-group mb-2 col">
          <label class="control-label">Email</label>
          <input formControlName="institution_email" type="text" class="form-control" />
        </div>
      </div>
      <div class="row" *ngIf="form.controls.institution_id.value">
        <div class="form-group mb-2 col">
          <label class="control-label">Claim no or ref no</label>
          <input formControlName="external_claim_no" class="form-control" />
        </div>
        <div class="form-group mb-2 col">
          <label class="control-label">Date of accident</label>
          <input formControlName="accident_date" type="date" class="form-control" />
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="rejectedItemReason">
    <label>Reason for item(s) being rejected</label>
    <ul class="list-group mb-2">
      <ng-container *ngFor="let reason of rejectedItemReason">
        <li class="list-group-item list-group-item-danger">{{ reason }}</li>
      </ng-container>
    </ul>
  </ng-container>

  <label>Items</label>
  <div class="border border-secondary rounded p-2 mb-4">
    <div class="row align-items-center mb-2">
      <strong class="col-3">Date of Service</strong>
      <strong class="col-1 p-0">Item</strong>
      <strong class="col-2">$Expected</strong>
      <ng-container *ngIf="!!invoice && !reInvoice && !invoice.is_draft">
        <strong class="col-1">$Paid</strong>
      </ng-container>
      <strong class="col" *ngIf="!invoice || reInvoice || invoice.is_draft">
        <rr-billing-item-search
          [autoFocus]="!invoice && !reInvoice"
          [report]="report"
          (onSelectBillingItem)="addInvoiceItems($event)"
        ></rr-billing-item-search>
      </strong>
    </div>
    <!-- TODO(strictInputTypes):  -->
    <!-- eslint-disable @angular-eslint/template/no-any -->
    <div
      class="row mb-1"
      *ngFor="let invoiceItem of invoiceItems"
      rr-invoice-item-form
      [invoiceItem]="$any(invoiceItem)"
      [draft]="!invoice || reInvoice || invoice.is_draft"
      (onEditItem)="editInvoiceItem($event.item, $event.changes)"
      (onRemoveItem)="removeInvoiceItem($event)"
    ></div>
    <!-- eslint-enable @angular-eslint/template/no-any -->
  </div>

  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Adjustments</label>
      <input type="number" formControlName="adjustment" class="form-control" />
    </div>

    <div class="form-group mb-2 col">
      <label class="control-label">Amount expected</label>
      <input type="number" formControlName="amount_expected" class="form-control" />
    </div>
  </div>

  <div class="row">
    <div class="form-group mb-2 col">
      <div class="d-flex mb-1 align-items-end">
        <label class="control-label">Amount Paid</label>
        <button class="btn btn-sm btn-outline-success ms-auto" role="button" (click)="addPayment()">Add payment</button>
      </div>
      <ng-container *ngFor="let paymentFormGroup of paymentFormArray.controls">
        <rr-invoice-payment-form
          [paymentFormGroup]="paymentFormGroup"
          (onRemovePayment)="removePayment($event)"
        ></rr-invoice-payment-form>
      </ng-container>
    </div>
    <div class="form-group mb-2 col">
      <label class="control-label">Balance due</label>
      <input type="number" formControlName="balance_due" class="form-control" />
    </div>
  </div>
  <div class="form-group mb-2" *ngIf="form.controls.funder.value !== 'medicare'">
    <label class="control-label">Status <span class="text-danger fa fa-asterisk fa-xs"></span></label>
    <select class="form-select" formControlName="status">
      <option [ngValue]="null">None</option>
      <option *ngFor="let status of invoiceStatus" [value]="status">{{ invoiceStatusMap[status] }}</option>
    </select>
  </div>

  <!--Not showing medipass status when creating new invoice-->
  <div class="form-group mb-2 row" *ngIf="!!invoice && !reInvoice && form.controls.funder.value === 'medicare'">
    <div class="form-group mb-2 col">
      <label class="control-label">Transaction Status</label>
      <input
        class="form-control"
        [disabled]="true"
        [value]="
          form.controls.medipass_transaction_status.value
            ? medipassTransactionStatusMap[form.controls.medipass_transaction_status.value]
            : null
        "
      />
    </div>

    <div class="form-group mb-2 col">
      <label class="control-label">Claim Status</label>
      <input
        class="form-control"
        [disabled]="true"
        [value]="
          form.controls.medipass_claim_status.value
            ? medipassClaimStatusMap[form.controls.medipass_claim_status.value]
            : null
        "
      />
    </div>
  </div>

  <!--Notes-->
  <div class="row">
    <div class="form-group mb-2 col">
      <label class="control-label">Notes</label>
      <textarea formControlName="notes" class="form-control"> </textarea>
    </div>
  </div>
</form>
