import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { PatientNoteCategoryActions } from '../patient-note-category/patient-note-category.action';
import { PatientNoteActions } from '../patient-note/patient-note.action';
import { PatientSearchResponse } from './patient.service';

/**
 * Ngrx Actions for Patient
 *
 */
export const PatientActions = {
  searchSuccess: createAction('[Patient] Search Success', props<{ searchResult: PatientSearchResponse }>()),

  findManySuccess: createAction('[Patient] Find Many Success', props<{ patients: RR.Patient[] }>()),

  findSuccess: createAction('[Patient] Find Success', props<{ patient: RR.Patient }>()),

  createSuccess: createAction('[Patient] Create Success', props<{ patient: RR.Patient }>()),

  updateSuccess: createAction('[Patient] Update Success', props<{ patient: RR.Patient }>()),

  deleteSuccess: createAction('[Patient] Delete Success', props<{ patient: RR.Patient }>()),

  validateSuccess: createAction('[Patient] Validate Success', props<{ patient: RR.Patient }>()),
};

export const PatientBatchActions = {
  searchSuccess: createBatchAction(
    '[Patient] Search Success Batch',
    props<{
      actions: {
        searchPatientSuccess: ReturnType<typeof PatientActions.searchSuccess>;
        findManyPatientNoteSuccess: ReturnType<typeof PatientNoteActions.findManySuccess>;
        findManyPatientNoteCategorySuccess: ReturnType<typeof PatientNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  findManySuccess: createBatchAction(
    '[Patient] Find Many Success Batch',
    props<{
      actions: {
        findManyPatientSuccess: ReturnType<typeof PatientActions.findManySuccess>;
        findManyPatientNoteSuccess: ReturnType<typeof PatientNoteActions.findManySuccess>;
        findManyPatientNoteCategorySuccess: ReturnType<typeof PatientNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  findSuccess: createBatchAction(
    '[Patient] Find Success Batch',
    props<{
      actions: {
        findPatientSuccess: ReturnType<typeof PatientActions.findSuccess>;
        findManyPatientNoteSuccess: ReturnType<typeof PatientNoteActions.findManySuccess>;
        findManyPatientNoteCategorySuccess: ReturnType<typeof PatientNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  updateSuccess: createBatchAction(
    '[Patient] Update Success Batch',
    props<{
      actions: {
        updatePatientSuccess: ReturnType<typeof PatientActions.updateSuccess>;
      };
    }>(),
  ),
};
