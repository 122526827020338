import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbInputDatepicker,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { ReportSearchSort } from 'app/store/report/report';
import { add, endOfWeek, isSameDay, isToday, isTomorrow, isYesterday, startOfWeek, subWeeks } from 'date-fns';
import { Observable, Subscription } from 'rxjs';

const anytime = { id: 'anytime', value: 'Anytime' } as const;
const fromToday = { id: 'fromToday', value: 'From Today' } as const;
const today = { id: 'today', value: 'Today' } as const;
const tomorrow = { id: 'tomorrow', value: 'Tomorrow' } as const;
const yesterday = { id: 'yesterday', value: 'Yesterday' } as const;
const thisWeek = { id: 'thisWeek', value: 'This week' } as const;
const nextWeek = { id: 'nextWeek', value: 'Next week' } as const;
const lastWeek = { id: 'lastWeek', value: 'Last week' } as const;
const thisMonth = { id: 'thisMonth', value: 'This month' } as const;
const nextMonth = { id: 'nextMonth', value: 'Next month' } as const;
const lastMonth = { id: 'lastMonth', value: 'Last month' } as const;
const thisYear = { id: 'thisYear', value: 'This year' } as const;
const lastSixWeeks = { id: 'lastSixWeeks', value: 'Last Six Weeks' } as const;

const OPTIONS = [
  anytime,
  today,
  tomorrow,
  yesterday,
  thisWeek,
  nextWeek,
  lastWeek,
  thisMonth,
  nextMonth,
  lastMonth,
  thisYear,
  lastSixWeeks,
] as const;

const WORKLIST_OPTIONS = [
  anytime,
  today,
  yesterday,
  thisWeek,
  lastWeek,
  thisMonth,
  lastMonth,
  thisYear,
  lastSixWeeks,
] as const;

const BOOKING_LIST_OPTIONS = [fromToday, today, tomorrow, yesterday, thisWeek, nextWeek, lastWeek] as const;

const BOOKING_TIMELINE_OPTIONS = [today, tomorrow, yesterday] as const;

type DateOption = (typeof OPTIONS)[number]['id'] | 'dateRange' | 'fromToday';

const PRESETS_MAP = {
  all: OPTIONS,
  worklist: WORKLIST_OPTIONS,
  booking_list: BOOKING_LIST_OPTIONS,
  booking_timeline: BOOKING_TIMELINE_OPTIONS,
} as const;

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbModule],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  selector: 'rr-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnDestroy {
  // Initial start date and end date
  @Input() label: string;
  @Input() start: Date | undefined;
  @Input() end: Date | undefined;
  @Input() @BindObservable() sort: ReportSearchSort;
  sort$: Observable<ReportSearchSort>;
  @Input() @BindObservable() preset: keyof typeof PRESETS_MAP = 'all';
  preset$: Observable<keyof typeof PRESETS_MAP>;
  // Allow to select date range by default. If false, only allow single date select
  @Input() rangeSelection = true;

  @Output() onDatesChanged = new EventEmitter<{
    start: Date | undefined;
    end: Date | undefined;
  }>();

  @ViewChild('dateInput', { static: true }) dateInput: ElementRef;
  @ViewChild('datepicker', { static: false }) datePicker: NgbInputDatepicker;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  hoveredDate: NgbDate | null = null;

  options = OPTIONS;
  subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.sort$.subscribe((sort) => {
        if (sort === 'focused') {
          this.onOptionChanged('lastSixWeeks');
        }
      }),
    );

    this.subscription.add(
      this.preset$.subscribe((p) => {
        // TODO: Angular not happy with `options: OptionsUnion` in the template at `let option _of_ options`
        this.options = PRESETS_MAP[p] as typeof OPTIONS;
      }),
    );

    // Convert date inputs into component ngbDate
    if (this.start) {
      this.fromDate = this.convertDateToNgbDate(this.start);
    }
    if (this.end) {
      // Search end_date is exclusive, when we emit end_date to parent component, we always +1 to the toDate
      this.toDate = this.convertDateToNgbDate(add(this.end, { days: -1 }));
    }
    this.setDisplayText();
  }

  convertDateToNgbDate(date: Date | undefined) {
    if (date) {
      return NgbDate.from({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      });
    }
    return null;
  }

  convertNgbDateToDate(date: NgbDate | null) {
    if (date) {
      return new Date(date.year, date.month - 1, date.day);
    }
    return undefined;
  }

  getSelectedOption(): DateOption {
    const start = this.convertNgbDateToDate(this.fromDate);
    const end = this.convertNgbDateToDate(this.toDate);

    if (!start && !end) {
      return 'anytime';
    } else if (start && !end && isToday(start)) {
      return 'fromToday';
    } else if (start && end) {
      if (isToday(start) && isToday(end)) {
        return 'today';
      } else if (isYesterday(start) && isYesterday(end)) {
        return 'yesterday';
      } else if (
        isSameDay(start, startOfWeek(new Date(), { weekStartsOn: 1 })) &&
        isSameDay(end, endOfWeek(new Date(), { weekStartsOn: 1 }))
      ) {
        return 'thisWeek';
      } else if (isTomorrow(start) && isTomorrow(end)) {
        return 'tomorrow';
      } else {
        const now = new Date();
        const lastWeekStart = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
        const lastWeekEnd = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
        if (isSameDay(start, lastWeekStart) && isSameDay(end, lastWeekEnd)) {
          return 'lastWeek';
        }
      }
    }
    // In other cases, just display date_range with start and end date
    return 'dateRange';
  }

  onOptionChanged(option: DateOption) {
    const dates = this.getQueryDates(option);
    if (!dates.end && dates.start && !isToday(dates.start)) {
      // If only the start_date is selected, emit end_date = start_date + 1 to search for only selected date
      this.onDatesChanged.emit({ start: dates.start, end: add(dates.start, { days: 1 }) });
    } else {
      this.onDatesChanged.emit(dates);
    }
    this.fromDate = this.convertDateToNgbDate(dates.start);
    this.toDate = this.convertDateToNgbDate(dates.end ? add(dates.end, { days: -1 }) : undefined);
    this.setDisplayText();
  }

  /**
   * Get 'from' and 'to' dates from the date option.
   * to_date is exclusive, so it is 1 day ahead of the selected end_date
   * @param option
   * @returns
   */
  getQueryDates(option: DateOption) {
    if (option === 'anytime') {
      return { start: undefined, end: undefined };
    }

    let from_date: Date | undefined = new Date();
    let to_date: Date | undefined = new Date();
    const now = new Date();
    // `new Date(now.getFullYear(), now.getMonth(), now.getDate())`
    // Is used to get the day, without the time part
    if (option === 'fromToday') {
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      to_date = undefined;
    } else if (option === 'today') {
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    } else if (option === 'tomorrow') {
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
    } else if (option === 'yesterday') {
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    } else if (option === 'thisWeek') {
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      from_date.setDate(from_date.getDate() - ((from_date.getDay() + 6) % 7));
      to_date = add(from_date, { days: 7 });
    } else if (option === 'lastWeek') {
      // Find monday last week
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      from_date.setDate(from_date.getDate() - (from_date.getDay() + 6));
      to_date = new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate());
      to_date.setDate(to_date.getDate() + 7);
    } else if (option === 'lastSixWeeks') {
      from_date = subWeeks(new Date(now.getFullYear(), now.getMonth(), now.getDate()), 6);
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    } else if (option === 'nextWeek') {
      // Find monday next week
      from_date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      from_date = startOfWeek(add(from_date, { days: 7 }), { weekStartsOn: 1 });
      to_date = new Date(from_date.getFullYear(), from_date.getMonth(), from_date.getDate());
      to_date.setDate(to_date.getDate() + 7);
    } else if (option === 'thisMonth') {
      // Month to date
      from_date = new Date(now.getFullYear(), now.getMonth(), 1);
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    } else if (option === 'lastMonth') {
      from_date = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      to_date = new Date(now.getFullYear(), now.getMonth(), 1);
    } else if (option === 'nextMonth') {
      from_date = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      to_date = new Date(now.getFullYear(), now.getMonth() + 2, 1);
    } else if (option === 'thisYear') {
      from_date = new Date(now.getFullYear(), 0, 1);
      to_date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    } else {
      from_date = this.convertNgbDateToDate(this.fromDate);
      to_date = this.convertNgbDateToDate(this.toDate);
      if (to_date) {
        // Add 1 day so that selected to_date is inclusive when searching
        to_date = add(to_date, { days: 1 });
      }
    }
    return { start: from_date, end: to_date };
  }

  onDateSelection(date: NgbDate) {
    if (this.rangeSelection) {
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (this.fromDate && !this.toDate && (date.equals(this.fromDate) || date.after(this.fromDate))) {
        this.toDate = date;
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    } else {
      this.fromDate = this.toDate = date;
    }

    this.onOptionChanged('dateRange');
    // Close date picker if both from date and to date were selected
    if (this.toDate) {
      this.datePicker.close();
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  setDisplayText() {
    const opt = this.getSelectedOption();
    const selectedOption = this.options.find((o) => o.id === opt);
    const startTxt = this.formatNgbDate(this.fromDate);
    const endTxt = this.formatNgbDate(this.toDate);
    if (selectedOption) {
      this.dateInput.nativeElement.value = selectedOption.value;
    } else if (this.fromDate && this.toDate && this.fromDate.equals(this.toDate)) {
      this.dateInput.nativeElement.value = startTxt;
    } else {
      // Todo show start and end
      this.dateInput.nativeElement.value = `${startTxt} ~ ${endTxt}`;
    }
  }

  formatNgbDate(date: NgbDate | null) {
    if (!date) return '';
    return `${date.day}/${date.month}/${date.year}`;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
