import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { AttributeSetActions } from '../attribute-set/attribute-set.action';
import { StatementActions } from '../statement/statement.action';
import { TextObjectActions } from '../text-object/text-object.action';

export const AttributeOptionActions = {
  findSuccess: createAction('[AttributeOption] Find Success', props<{ attributeOption: RR.AttributeOption }>()),

  findAllSuccess: createAction(
    '[AttributeOption] Find All Success',
    props<{ attributeOptions: RR.AttributeOption[] }>(),
  ),

  createSuccess: createAction('[AttributeOption] Create Success', props<{ attributeOption: RR.AttributeOption }>()),

  deleteSuccess: createAction('[AttributeOption] Delete Success', props<{ attributeOptionId: number }>()),

  findManySuccess: createAction(
    '[AttributeOption] Find Many Success',
    props<{
      attributeOptions: RR.AttributeOption[];
    }>(),
  ),

  reset: createAction('[AttributeOption] Reset'),
};

export const AttributeOptionBatchActions = {
  createSuccess: createBatchAction(
    '[AttributeOption] Create Success Batch',
    props<{
      actions: {
        findAttributeOptionSuccess: ReturnType<typeof AttributeOptionActions.findSuccess>;
        findAttributeSetSuccess: ReturnType<typeof AttributeSetActions.findSuccess>;
      };
    }>(),
  ),
  updateSuccess: createBatchAction(
    '[AttributeOption] Update Success Batch',
    props<{
      actions: {
        findAttributeOptionSuccess: ReturnType<typeof AttributeOptionActions.findSuccess>;
        findAttributeSetSuccess: ReturnType<typeof AttributeSetActions.findSuccess>;
      };
    }>(),
  ),
  deleteSuccess: createBatchAction(
    '[AttributeOption] Delete Success Batch',
    props<{
      actions: {
        deleteAttributeOptionSuccess: ReturnType<typeof AttributeOptionActions.deleteSuccess>;
        findAttributeSetSuccess: ReturnType<typeof AttributeSetActions.findSuccess>;
      };
    }>(),
  ),

  findAttributeStatementUsageSuccess: createBatchAction(
    '[AttributeOption] Find Attribute Usage By Statement Success',
    props<{
      actions: {
        statementFindManySuccess: ReturnType<typeof StatementActions.addMany>;
        statementTextObjectFindManySuccess: ReturnType<typeof TextObjectActions.upsertMany>;
      };
    }>(),
  ),
};
