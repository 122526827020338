import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type FollowupTaskPayload = {
  report_id: number;
  send_to: string;
  message: string;
};

export type FollowupTaskSearchHttpResponse = {
  count: number;
  followup_tasks: RR.FollowupTask[];
  reports: RR.Report[];
  bookings: RR.Booking[];
  referrers: RR.Referrer[];
  patients: RR.Patient[];
  // Notes for note button in followup task list
  todos: RR.Todo[];
  voice_notes: RR.VoiceNote[];
  urgent_notes: RR.UrgentNotes[];
};

export type FollowupTaskSearchPayload = {
  accession_number: string | undefined;
  mobile_number: string | undefined;
  incomplete: boolean;
  from_date: string | undefined;
  to_date: string | undefined;
};
@Injectable()
export class FollowupTaskHttpService {
  constructor(private http: HttpClient) {}

  sendFollowupSMS(data: FollowupTaskPayload): Observable<RR.FollowupTask> {
    return this.http.post<RR.FollowupTask>('/api/followup_task', data);
  }

  update(id: number, changes: Partial<RR.FollowupTask>) {
    return this.http.put<RR.FollowupTask>(`/api/followup_task/${id}`, changes);
  }

  search(payload: FollowupTaskSearchPayload, params: HttpParams) {
    return this.http.post<FollowupTaskSearchHttpResponse>('/api/followup_task/search', payload, {
      params,
    });
  }

  getUnprocessTaskCount() {
    return this.http.get<{ count: number }>('/api/followup_task/unprocessed_count');
  }
}
