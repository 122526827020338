import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromFeedback } from '../feedback/feedback.selector';

const adapter = createEntityAdapter<RR.FeedbackComment>();
const selectFeature = (state: AppState) => state.feedback_comment;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInFeedback = (feedbackId: number) =>
  createSelector(fromFeedback.selectFeedback(feedbackId), selectEntities, (feedback, comments) =>
    (feedback?.feedback_comments || [])
      .map((id) => comments[id])
      .filter((comment): comment is RR.FeedbackComment => !!comment),
  );

export const fromFeedbackComment = {
  selectIds,
  selectEntities,
  selectAll,
  selectInFeedback,
};
