import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type TagAutoSelectParam = {
  section: RR.TagSearchSection;
  threshold: number;
  min_tags: number;
  no_nad: boolean;
  nad_placeholder: boolean;
};
export type TagNADSelectParam = {
  section: RR.TagSearchSection;
  threshold: number;
};

export type TagChoiceContextResponse = {
  tag_choice: RR.TagChoice;
  statement: RR.Statement;
  region: RR.Region | undefined;
};

export type MultipleTagChoicesContextResponse = {
  tag_choices: RR.TagChoice[];
  statements: RR.Statement[];
  regions: RR.Region[];
};

export type CreateTagChoiceData = Pick<RR.TagSuggestion, 'tag_id' | 'section' | 'subsection_id' | 'region_id'>;

@Injectable()
export class TagChoiceHttpService {
  constructor(private http: HttpClient) {}

  addTagChoice(topicId: number, tag: CreateTagChoiceData): Observable<TagChoiceContextResponse> {
    return this.http.post<TagChoiceContextResponse>(`/api/topic/${topicId}/tag`, tag);
  }

  deleteTagChoice(topicId: number, tagId: string): Observable<void> {
    return this.http.delete<void>(`/api/topic/${topicId}/tag/${tagId}`);
  }

  getTagChoices(topicId: number): Observable<MultipleTagChoicesContextResponse> {
    return this.http.get<MultipleTagChoicesContextResponse>(`/api/topic/${topicId}/tag`);
  }

  autoChoose(topicId: number, params: TagAutoSelectParam): Observable<MultipleTagChoicesContextResponse> {
    return this.http.post<MultipleTagChoicesContextResponse>(`/api/ml/topic/${topicId}/tag/choose`, params);
  }

  autoChooseNAD(topicId: number, params: TagNADSelectParam): Observable<MultipleTagChoicesContextResponse> {
    return this.http.post<MultipleTagChoicesContextResponse>(`/api/ml/topic/${topicId}/tag/choose_nad`, params);
  }

  autoChooseDelete(topicId: number): Observable<MultipleTagChoicesContextResponse> {
    return this.http.delete<MultipleTagChoicesContextResponse>(`/api/topic/${topicId}/tag/choose`);
  }

  copyTagChoices(
    topicId: number,
    from_region_ids: number[],
    to_region_ids: number[],
  ): Observable<MultipleTagChoicesContextResponse> {
    return this.http.post<MultipleTagChoicesContextResponse>(`/api/topic/${topicId}/tag/copy_to_region`, {
      from_region_ids,
      to_region_ids,
    });
  }
}
