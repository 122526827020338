import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-referrer-popover',
  templateUrl: './referrer-popover.component.html',
  styleUrls: ['./referrer-popover.component.css'],
})
export class ReferrerPopoverComponent {
  @Input() referrer: RR.Referrer;
}
