import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromPatientQuestionAnswer } from 'app/store/questionnaire/patient-question-answer';
import { fromRouter } from 'app/store/router/router.selector';

import { fromTopic } from '../topic/topic.selector';

const adapter = createEntityAdapter<RR.Report>();
const selectFeature = (state: AppState) => state.report;

const { selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectCurrentReport = createSelector(
  selectEntities,
  fromRouter.selectReportId,
  (reports: Dictionary<RR.Report>, reportId: number | null) => (reportId ? reports[reportId] : undefined),
);

const selectReport = (reportId: number) =>
  createSelector(selectEntities, (reports: Dictionary<RR.Report>) => reports[reportId]);

const selectRelatedReportsLoaded = (reportId: number) =>
  createSelector(selectFeature, ({ relatedReportsLoaded }) => !!relatedReportsLoaded[reportId]);

const selectReportsLoaded = (reportId: number) =>
  createSelector(selectFeature, ({ reportsLoaded }) => !!reportsLoaded[reportId]);

const selectRelatedReports = (reportId: number) =>
  createSelector(
    selectReport(reportId),
    fromReport.selectEntities,
    (report: RR.Report | undefined, reports: Dictionary<RR.Report>) =>
      (report?.related_reports || []).map((reportId) => reports[reportId]).filter((r): r is RR.Report => !!r),
  );

const selectManyReports = (reportIds: number[]) =>
  createSelector(fromReport.selectEntities, (reports: Dictionary<RR.Report>) =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (reportIds || []).map((reportId) => reports[reportId]),
  );

const selectRelatedTopics = (reportId: number) =>
  createSelector(
    selectRelatedReports(reportId),
    fromTopic.selectEntities,
    (relatedReports: RR.Report[], topics: Dictionary<RR.Topic>) => {
      return relatedReports.map((report) => report.topic_ids.map((topicId) => topics[topicId])).flat();
    },
  );

const selectPatientAnswers = (reportId: number) =>
  createSelector(
    selectReport(reportId),
    fromPatientQuestionAnswer.selectEntities,
    (report: RR.Report | undefined, answers: Dictionary<RR.PatientQuestionAnswer>) =>
      (report?.patient_answer_ids || []).map((answerId) => answers[answerId]),
  );

const selectAllTopics = (reportId: number) =>
  createSelector(
    selectReport(reportId),
    fromTopic.selectEntities,
    (report: RR.Report | undefined, topics: Dictionary<RR.Topic>) =>
      (report?.topic_ids || []).map((topicId) => topics[topicId]).filter((t): t is RR.Topic => !!t),
  );

const selectTopics = (reportId: number) =>
  createSelector(selectAllTopics(reportId), (topics) => topics.filter((topic) => !topic.deleted));

export const fromReport = {
  selectEntities,
  selectAll,
  selectReport,
  selectRelatedReports,
  selectRelatedReportsLoaded,
  selectReportsLoaded,
  selectRelatedTopics,
  selectTopics,
  selectPatientAnswers,
  selectManyReports,
};

/**
 * Select values based on the currently selected report.
 *
 * This uses the router store to determine the report that is currently
 * selected and performs the selection accordingly.
 */
export const fromCurrentReport = {
  selectReport: selectCurrentReport,
  // Re-Export the router selector here since it makes sense
  selectReportId: fromRouter.selectReportId,
};
