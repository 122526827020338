import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-template-headline',
  templateUrl: './template-headline.component.html',
  styleUrls: ['./template-headline.component.css'],
})
export class TemplateHeadlineComponent {
  @Input() template: RR.Template;
}
