import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.RegistrationForm>();
const selectFeature = (state: AppState) => state.registration_form;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectRegistrationForm = (id: number) =>
  createSelector(selectEntities, (forms: Dictionary<RR.RegistrationForm>) => forms[id]);

export const fromRegistrationForm = {
  selectIds,
  selectEntities,
  selectAll,
  selectRegistrationForm,
};
