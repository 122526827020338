import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class LabelHttpService {
  constructor(private http: HttpClient) {}

  create(label: Partial<RR.Label>): Observable<RR.Label> {
    return this.http.post<RR.Label>('/api/label', label);
  }

  findAll(): Observable<RR.Label[]> {
    return this.http.get<RR.Label[]>('/api/label');
  }

  update(labelId: number, changes: Partial<RR.Label>): Observable<RR.Label> {
    return this.http.put<RR.Label>(`/api/label/${labelId}`, changes);
  }

  delete(labelId: number): Observable<RR.Label> {
    return this.http.delete<RR.Label>(`/api/label/${labelId}`);
  }
}
