import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { EditNoteModalComponent } from 'app/shared/modals/notes-modal/edit-note-modal/edit-note-modal.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { PatientNoteEffect } from 'app/store/patient-note';
import { fromPatientNoteCategory } from 'app/store/patient-note-category';
import { ReferrerNoteEffect } from 'app/store/referrer-note';
import { fromReferrerNoteCategory } from 'app/store/referrer-note-category';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { fromUser } from 'app/store/user/user';
import { Subscription } from 'rxjs';

import { ReportAccessionNumberComponent } from '../../report-accession-number/report-accession-number.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    StoreSelectPipe,
    EditNoteModalComponent,
    RouterModule,
    SharedModule,
    ReportAccessionNumberComponent,
  ],
  selector: 'rr-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css'],
})
export class NoteComponent implements OnInit, OnDestroy {
  @Input() patientNote: RR.PatientNote | undefined = undefined;
  @Input() referrerNote: RR.ReferrerNote | undefined = undefined;
  selectUserFn = fromUser.selectUser;
  selectReferrerCategoryFn = fromReferrerNoteCategory.selectReferrerNoteCategory;
  selectPatientCategoryFn = fromPatientNoteCategory.selectPatientNoteCategory;
  selectReportFn = fromReport.selectReport;

  subscription = new Subscription();

  constructor(
    private referrerNoteEffect: ReferrerNoteEffect,
    private patientNoteEffect: PatientNoteEffect,
    private modalService: NgbModal,
    private reportEffect: ReportEffect,
  ) {}

  ngOnInit(): void {
    const report_id = this.patientNote
      ? this.patientNote.report_id
      : this.referrerNote
        ? this.referrerNote.report_id
        : null;

    if (report_id) {
      this.subscription.add(this.reportEffect.find(report_id).subscribe());
    }
  }

  editNote() {
    EditNoteModalComponent.open(this.modalService, {
      referrerNoteId: this.referrerNote?.id,
      patientNoteId: this.patientNote?.id,
    });
  }

  resolveNote(id: number | undefined, resolved: boolean) {
    if (this.referrerNote && id) this.subscription.add(this.referrerNoteEffect.update(id, { resolved }).subscribe());
    if (this.patientNote && id) this.subscription.add(this.patientNoteEffect.update(id, { resolved }).subscribe());
  }

  deleteReferrerNote() {
    const modalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: 'Do you want to delete this note?',
      btnConfirmText: 'Yes',
    });
    modalRef.result.then(
      () => {
        if (this.referrerNote) this.subscription.add(this.referrerNoteEffect.delete(this.referrerNote.id).subscribe());
        if (this.patientNote) this.subscription.add(this.patientNoteEffect.delete(this.patientNote.id).subscribe());
      },
      () => {
        // Do nothing
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
