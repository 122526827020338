import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from 'app/core/services/template.service';
import { StatementSetCount } from 'app/store/template/statement-set';

@Component({
  templateUrl: './element-usage-modal.component.html',
  standalone: true,
  imports: [CommonModule],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class ElementUsageModalComponent implements OnInit {
  statement_set?: RR.StatementSet; // input
  statement_set_count?: StatementSetCount; // input
  affectedTemplates: RR.Template[];

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
  ) {}

  ngOnInit() {
    let statementSetId: number | undefined = undefined;
    if (this.statement_set) {
      statementSetId = this.statement_set.id;
    } else if (this.statement_set_count) {
      statementSetId = this.statement_set_count.id;
    }
    if (statementSetId === undefined) {
      throw new Error('statementSetId undefined');
    }
    // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
    this.templateService.findStatementSetUsage(statementSetId).subscribe((affectedTemplates) => {
      this.affectedTemplates = affectedTemplates;
    });
  }

  static open(
    modalService: NgbModal,
    config: { statement_set: RR.StatementSet } | { statement_set_count: StatementSetCount },
  ) {
    const modalRef = modalService.open(ElementUsageModalComponent, {
      size: 'lg',
    });
    const componentInstance: ElementUsageModalComponent = modalRef.componentInstance;
    if ('statement_set' in config) {
      componentInstance.statement_set = config.statement_set;
    } else {
      componentInstance.statement_set_count = config.statement_set_count;
    }
    return modalRef;
  }
}
