import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { TagActions } from '../../prefill/tag/tag.action';
import { AttributeOptionActions } from '../attribute-option/attribute-option.action';
import { AttributeSetActions } from '../attribute-set/attribute-set.action';
import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { ElementActions } from '../element/element.action';
import { RegionSetActions } from '../region-set/region-set.action';
import { RegionActions } from '../region/region.action';
import { SectionActions } from '../section/section.action';
import { StatementCoincidenceActions } from '../statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../statement-set/statement-set.action';
import { StatementActions } from '../statement/statement.action';
import { SubsectionActions } from '../subsection/subsection.action';
import { TextObjectActions } from '../text-object/text-object.action';

export const TemplateActions = {
  loadTemplate: createAction('[Template] Load Template', props<{ templateId: number }>()),
  loadTemplateSuccess: createAction('[Template] Load Template Success', props<{ templateId: number }>()),

  findSuccess: createAction('[Template] Find Template Success', props<{ template: RR.Template }>()),

  findAllSuccess: createAction('[Template] Find All Template Success', props<{ templates: RR.Template[] }>()),

  findManySuccess: createAction('[Topic] Find Many Template Success', props<{ templates: RR.Template[] }>()),

  createSuccess: createAction('[Template] Create Success', props<{ template: RR.Template }>()),

  updateSuccess: createAction('[Template] Update Success', props<{ template: RR.Template }>()),

  deleteSuccess: createAction('[Template] Delete Success', props<{ templateId: number }>()),
};

export const TemplateBatchActions = {
  resetAllBatchAction: createBatchAction(
    '[Template] Reset All Batch',
    props<{
      actions: {
        resetSubsectionAction: ReturnType<typeof SubsectionActions.reset>;
        resetElementAction: ReturnType<typeof ElementActions.reset>;
        resetRegionSetAction: ReturnType<typeof RegionSetActions.reset>;
        resetRegionAction: ReturnType<typeof RegionActions.reset>;
        resetStatementSetAction: ReturnType<typeof StatementSetActions.reset>;
        resetStatementAction: ReturnType<typeof StatementActions.reset>;
        resetDefaultAttributeAction: ReturnType<typeof DefaultAttributeActions.reset>;
        resetStatementCoincidenceAction: ReturnType<typeof StatementCoincidenceActions.reset>;
        resetTagAction: ReturnType<typeof TagActions.reset>;
        resetAttributeSetAction: ReturnType<typeof AttributeSetActions.reset>;
        resetAttributeOptionAction: ReturnType<typeof AttributeOptionActions.reset>;
      };
    }>(),
  ),

  findTemplateDataSuccess: createBatchAction(
    '[Topic] Find Template Data Success Batch',
    props<{
      actions: {
        sectionAddMany: ReturnType<typeof SectionActions.addMany>;
        subsectionAddMany: ReturnType<typeof SubsectionActions.addMany>;
        regionAddMany: ReturnType<typeof RegionActions.addMany>;
        elementAddMany: ReturnType<typeof ElementActions.addMany>;
        statementSetAddMany: ReturnType<typeof StatementSetActions.addMany>;
        statementAddMany: ReturnType<typeof StatementActions.addMany>;
        dividerAddMany: ReturnType<typeof StatementActions.addMany>;
        textObjectAddMany: ReturnType<typeof TextObjectActions.upsertMany>;
        statementCoincidenceAddMany: ReturnType<typeof StatementCoincidenceActions.addMany>;
      };
    }>(),
  ),
};
