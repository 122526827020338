import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { EditorActions, fromEditor } from 'app/store/editor';
import { Subscription } from 'rxjs';

import { StructuredVoiceTermsComponent } from '../structured-voice-terms/structured-voice-terms.component';

@Component({
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, StructuredVoiceTermsComponent, SharedModule],
  selector: 'rr-voice-recognition-text',
  templateUrl: './voice-recognition-text.component.html',
  styleUrls: ['./voice-recognition-text.component.css'],
})
export class VoiceRecognitionTextComponent implements OnInit, OnDestroy {
  // SOABS: Search or Add Blue Statmenet
  @Input() parent?: 'SOABS' | undefined;
  subscription = new Subscription();
  voiceResults: RR.VoiceResult[] = [];
  voiceRecognition: boolean;
  globalSearchTerm: string;
  isListening: boolean;

  constructor(
    private store: Store<AppState>,
    private editorService: EditorService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.editorService.globalSearchTerm$.subscribe((response) => {
        // TODO: globalSearchTerm is unused???
        this.globalSearchTerm = response?.term ?? '';
      }),
    );

    this.subscription.add(
      this.store.select(fromEditor.selectVoiceSearchResults).subscribe((results) => {
        this.voiceResults = results;
      }),
    );
  }

  nextTerm() {
    this.editorService.nextSearchTerm();
  }

  copyToSearch(term: string) {
    if (this.parent === 'SOABS') {
      this.editorService.searchTextInSoabs(term);
      return;
    }

    this.editorService.globalSearchTerm$.next({ source: 'TAG', term });
  }

  clearAllVoice() {
    this.store.dispatch(EditorActions.clearVoiceSearchReults());
  }

  deleteResult(voiceResult: RR.VoiceResult) {
    this.store.dispatch(EditorActions.removeVoiceSearchResult({ voiceResult }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
