import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { SignatureActions } from './signature.action';
import { SignatureHttpService } from './signature.service';

@Injectable()
export class SignatureEffect {
  constructor(
    private store: Store<AppState>,
    private service: SignatureHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((signatures: RR.Signature[]) => SignatureActions.findAllSuccess({ signatures })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(signature: Partial<RR.Signature>) {
    return this.service.create(signature).pipe(
      map((signature: RR.Signature) => SignatureActions.createSuccess({ signature })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(signatureId: number, changes: Partial<RR.Signature>) {
    return this.service.update(signatureId, changes).pipe(
      map((signature) => SignatureActions.updateSuccess({ signature })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
