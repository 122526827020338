<div class="modal-header">
  <div>
    <h5 class="modal-title">Attachments (+)</h5>
    <rr-booking-headline [bookingId]="booking_id"></rr-booking-headline>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <rr-booking-document-attachment [booking_id]="booking_id" [parent]="parent"></rr-booking-document-attachment>
</div>
