import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { PatientSearchFilterForm } from 'app/modules/entity-listing/components/patient-listing/patient-listing-search/patient-listing-search.component';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PatientNoteCategoryActions } from '../patient-note-category/patient-note-category.action';
import { PatientNoteActions } from '../patient-note/patient-note.action';
import { PatientActions, PatientBatchActions } from './patient.action';
import { PatientGetResponse, PatientHttpService, PatientSearchResponse } from './patient.service';

@Injectable()
export class PatientEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientHttpService,
    private message: MessageService,
  ) {}

  search(queryBody: PatientSearchFilterForm, withDeleted: boolean, onlyDeleted: boolean, params: HttpParams) {
    return this.service.searchPatients(queryBody, withDeleted, onlyDeleted, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response: PatientSearchResponse) =>
        PatientBatchActions.searchSuccess({
          actions: {
            searchPatientSuccess: PatientActions.searchSuccess({ searchResult: response }),
            findManyPatientNoteSuccess: PatientNoteActions.findManySuccess({
              patient_notes: response.patient_notes,
            }),
            findManyPatientNoteCategorySuccess: PatientNoteCategoryActions.findManySuccess({
              patient_note_categories: response.patient_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findById(patientId: number) {
    return this.service.findById(patientId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: PatientGetResponse) =>
        PatientBatchActions.findSuccess({
          actions: {
            findPatientSuccess: PatientActions.findSuccess({ patient: data.patient }),
            findManyPatientNoteSuccess: PatientNoteActions.findManySuccess({ patient_notes: data.patient_notes }),
            findManyPatientNoteCategorySuccess: PatientNoteCategoryActions.findManySuccess({
              patient_note_categories: data.patient_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(patient: Partial<RR.Patient>) {
    return this.service.create(patient).pipe(
      // this.message.handleHttpErrorPipe,
      map((patient: RR.Patient) => PatientActions.createSuccess({ patient })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(patientId: number, changes: Partial<RR.Patient>) {
    return this.service.update(patientId, changes).pipe(
      // this.message.handleHttpErrorPipe,
      map((patient) => PatientActions.updateSuccess({ patient })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(patient: RR.Patient) {
    return this.service.delete(patient.id).pipe(
      this.message.handleHttpErrorPipe,
      map(() => PatientActions.deleteSuccess({ patient })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  validate(patientId: number, patient: Partial<RR.Patient>) {
    return this.service.validateMedicare(patient).pipe(
      this.message.handleHttpErrorPipe,
      tap((response) => {
        if ('patientVerified' in response.json) {
          this.update(patientId, {
            // Update patient if medicare details have changed
            medicare_number: patient.medicare_number,
            medicare_expiry_month: patient.medicare_expiry_month,
            medicare_expiry_year: patient.medicare_expiry_year,
            medicare_reference_number: patient.medicare_reference_number,
            // Update patient with validation response
            medipass_validation_response: response.json,
            medipass_last_validated: response.patient.medipass_last_validated,
          });
        }
        // When 'error' is in response.json do nothing because we don't save error responses
      }),
    );
  }

  restoreDeletedPatient(patientId: number) {
    return this.service.restoreDeletedPatient(patientId).pipe(
      this.message.handleHttpErrorPipe,
      map((patient) => PatientActions.findSuccess({ patient })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
