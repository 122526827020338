<ng-template #template>
  <button
    type="button"
    class="p-2 list-group-item text-break text-start w-100"
    [class.bg-primary-subtle]="selected"
    (click)="selectPrefill($event); $event.stopPropagation()"
  >
    <div class="d-flex justify-content-between align-items-center mb-1">
      <strong>
        {{ presetTitle.text }}
        <span class="ms-auto badge bg-secondary">Statements: {{ count }}</span>
      </strong>
      <a data-no-bubble [routerLink]="['/report', report.id, 'edit']" target="_blank" class="text-nowrap ms-1">
        <span class="small fa fa-external-link-alt"></span>
      </a>
    </div>
    <small><strong>Notes: </strong></small>{{ report.preset_notes }}
    <div class="mt-1">
      <button
        type="button"
        class="btn btn-outline-primary w-100 mt-3"
        rrTooltip="USE_PRESET_STATEMENTS"
        (click)="onClickUsePreset(); $event.stopPropagation()"
      >
        Use Preset Statements
      </button>
    </div>
  </button>
</ng-template>
