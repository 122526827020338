<ng-container *ngrxLet="report$ as report">
  <span class="text-dark" rrTooltip="SHOW_PATIENT_INFO_BY_EDITORS">
    <div *ngIf="report.patient_id">
      <rr-patient-info [patientId]="report.patient_id" [editPatient]="true"></rr-patient-info>
    </div>
    <div *ngIf="showReferrer && report.referrer_id">
      <rr-referrer-name
        [referrerId]="report.referrer_id"
        [editReferrer]="true"
        [referrerType]="true"
      ></rr-referrer-name>
    </div>
    <rr-report-accession-number [report]="report"></rr-report-accession-number>
  </span>
</ng-container>
<!-- <button
  role="button"
  class="btn btn-sm mb-1 text-secondary"
  (click)="copyText(report.accession_number)"
  data-no-bubble
  rrTrack
  rrTooltip="COPY_ACCESSION_NUMBER"
>
  <i class="fas fa-copy fa-sm"></i>
</button> -->
