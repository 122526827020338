import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FocusService } from 'app/core/services/focus.service';
import { MessageService } from 'app/core/services/message.service';
import { ESTemplate, TemplateHttpService } from 'app/store/template/template';
import { groupBy } from 'lodash-es';
import { fromEvent, of, Subscription } from 'rxjs';
import { startWith, map, debounceTime, switchMap, catchError } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-template-picker',
  templateUrl: './template-picker.component.html',
  styleUrls: ['./template-picker.component.css'],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class TemplatePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @Input() disabled = false;

  @Output() onSelect: EventEmitter<ESTemplate> = new EventEmitter();

  modalities: string[];
  saving: boolean;

  templateSearchResults: _.Dictionary<ESTemplate[]>;
  subscription = new Subscription();

  constructor(
    private templateHTTPService: TemplateHttpService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private focus: FocusService,
  ) {}

  ngOnInit(): void {
    const keyObs = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      map((event: Event) => {
        return (event.target as HTMLInputElement).value;
      }),
      debounceTime(100),
    ); // initialise

    this.subscription.add(
      keyObs
        .pipe(
          startWith(this.searchInput.nativeElement.value),
          switchMap((searchText) => {
            return this.templateHTTPService.searchTemplates(searchText).pipe(
              catchError(() => {
                this.messageService.add({
                  type: 'danger',
                  title: 'Error',
                  message: 'Search failed',
                });
                return of([]);
              }),
            );
          }),
        )
        .subscribe((templates) => {
          this.templateSearchResults = groupBy<ESTemplate>(templates, (t: ESTemplate) => t._source.modality);
          this.modalities = Object.keys(this.templateSearchResults);
          this.cd.markForCheck();
        }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.focus.requestFocus('study').subscribe(() => {
        this.focusTemplateSearch();
      }),
    );
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  focusTemplateSearch = () => {
    this.searchInput.nativeElement.focus();
  };

  selectTemplate(template: ESTemplate) {
    this.onSelect.emit(template);
  }
}
