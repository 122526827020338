import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';

@Component({
  selector: 'rr-title-delete-modal',
  templateUrl: './title-delete-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule],
})
@LifecycleLogger
export class TitleDeleteModalComponent implements OnInit {
  @Input() option_array: { value: string; checked: boolean }[][];

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.option_array = [...this.option_array];
    this.option_array = this.option_array.map((wordList) => {
      return wordList.map((word) => {
        return { value: word.value, checked: false };
      });
    });
  }

  get hasSelection() {
    return this.option_array.some((l) => l.some((w) => w.checked));
  }

  get numberOfWordLists() {
    return this.option_array.filter((w) => w.length > 1).length;
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  cancel = () => this.activeModal.close();

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  remove = () => this.activeModal.close(this.option_array.map((l) => l.filter((w) => w.checked).map((w) => w.value)));
}
