<div class="modal-header">
  <div>
    <h5 class="modal-title">Jump to Groups (J): Finding groups</h5>
    <ng-container *ngIf="report$ | async as report">
      <rr-report-headline [reportId]="report.id"></rr-report-headline>
    </ng-container>

    <div>
      <rr-report-notes-button
        class="me-2"
        *ngIf="report$ | async as report"
        [report_id]="report.id"
      ></rr-report-notes-button>
    </div>
  </div>

  <div class="ms-auto d-flex flex-column">
    <div class="align-self-end">
      <div class="form-check form-switch d-inline-block align-self-end" rrTooltip="AUTO_UPDATE_TOGGLE">
        <input
          type="checkbox"
          class="form-check-input"
          id="autoUpdate"
          [checked]="autoUpdate$ | async"
          (change)="toggleAutoUpdate()"
        />
        <label class="form-check-label" for="autoUpdate" role="button">Auto Update Scores</label>
      </div>

      <button
        *ngIf="!(autoUpdate$ | async)"
        rrTooltip="UPDATE_MACHINE_LEARNING_SCORES"
        role="button"
        class="btn btn-primary ms-2 align-self-end"
        (click)="onManualUpdate()"
      >
        <i class="fas fa-sync-alt"></i> Update Scores
      </button>

      <div class="btn-group">
        <button type="button" rrTooltip="AUTO_SELECT_NAD_TAGS" class="btn btn-primary ms-2" (click)="onSelectNADTags()">
          Auto Select NAD
        </button>
        <div class="btn-group" ngbDropdown autoClose="outside" role="group">
          <button class="btn btn-primary" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <div ngbDropdownItem>
              <form [formGroup]="form" class="d-flex align-items-center align-self-end">
                <div>
                  <label for="nad_threshold" class="me-1">Threshold</label>
                  <input
                    type="number"
                    id="nad_threshold"
                    name="nad_threshold"
                    min="0"
                    max="100"
                    step="10"
                    formControlName="thresholdNad"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <rr-auto-select-groups-button
      [parent]="'FINDING_GROUPS'"
      [sectionType]="section.value"
      [topicId]="topicId"
    ></rr-auto-select-groups-button>
  </div>
  <div class="ms-2">
    <button role="button" class="btn-close" (click)="onClose()"></button>
  </div>
</div>

<div class="d-flex mt-2 mx-4 align-items-center">
  <div rrTooltip="VOYAGER_NOTE_TAGS"><i class="fas fa-search"></i> Voyager Note Groups:</div>
  <div class="ms-1" *ngIf="voyagerNoteTags.length > 0; else noTags">
    <button
      (click)="copyToSearch(item)"
      class="btn btn-sm btn-outline-primary me-1"
      *ngFor="let item of voyagerNoteTags"
    >
      {{ item }}
    </button>
  </div>
  <ng-template #noTags>
    <div class="ms-1 text-muted">No groups</div>
  </ng-template>
</div>

<!--Current report tags-->
<div *ngIf="topicTags.length > 0" class="d-flex mt-2 mx-4 flex-wrap">
  <div *ngFor="let tag of topicTags" class="current-tag-width">
    <rr-prefill-tag [divider]="tag" [topicId]="topicId" [topicTags]="topicTags" [hideScore]="true"></rr-prefill-tag>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="mt-2 mx-4 d-flex align-items-center">
      <rr-voice-recognition class="me-1" [source]="'TAGS'"></rr-voice-recognition>
      <rr-voice-recognition-text></rr-voice-recognition-text>
      <rr-keyword-search
        *ngIf="templateId$ | async as templateId"
        [templateId]="templateId"
        class="col-10"
      ></rr-keyword-search>
    </div>
  </div>
</div>

<div class="my-2 mx-4">
  <div class="btn-group d-flex flex-wrap justify-content-center py-1">
    <ng-container *ngFor="let s of sections; let i = index">
      <input
        id="tag-section-{{ s.value }}"
        class="btn-check"
        type="radio"
        [value]="s.value"
        [formControl]="section"
        (change)="onSectionChanged()"
      />
      <label for="tag-section-{{ s.value }}" class="btn btn-outline-primary btn-sm">
        {{ s.name }}
      </label>
    </ng-container>
  </div>
</div>

<div class="modal-body d-flex pt-0">
  <div class="d-flex flex-column left-list px-1" id="body">
    <div class="overflow-auto">
      <div class="alert alert-dark py-1 text-center">
        <strong>Recommended Groups</strong>
        <ng-container *ngIf="{ loading: suggestedLoading$ | async, isML: isMLSuggested$ | async } as mlData">
          <span
            *ngIf="!mlData.loading"
            class="fa ms-2"
            [title]="
              mlData.isML
                ? 'Groups were suggested by Machine Learning algorithm'
                : 'Groups were suggested by usage count'
            "
            [ngClass]="mlData.isML ? 'fa-robot text-success' : 'fa-sort-numeric-down-alt text-warning'"
          ></span>
        </ng-container>
      </div>
      <ng-container *ngIf="{ loading: suggestedLoading$ | async, tags: tagSuggested$ | async } as data">
        <div *ngIf="data.loading" class="text-center mb-2">
          <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
        </div>
        <div *ngIf="!data.loading">
          <rr-prefill-tag-list
            *ngIf="data.tags"
            [tags]="data.tags"
            [topicId]="topicId"
            [topicTags]="topicTags"
          ></rr-prefill-tag-list>
        </div>
      </ng-container>

      <div *ngIf="(searchText$ | async) !== ''" class="overflow-auto">
        <div class="alert alert-dark py-1 text-center">
          <strong>Global Search Groups</strong>
        </div>
        <div *ngIf="(loadingGlobalSearch$ | async) && (searchText$ | async) !== ''" class="text-center">
          <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
        </div>
        <div *ngIf="globalSearchTags && !(loadingGlobalSearch$ | async)" class="d-flex flex-column">
          <rr-prefill-tag-list
            [globalSearchTags]="globalSearchTags"
            [topicId]="topicId"
            [hideScore]="true"
            [topicTags]="topicTags"
          ></rr-prefill-tag-list>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column right-list px-1">
    <div class="alert alert-dark py-1 text-center">
      <strong>Structured Groups</strong>
    </div>
    <ng-container
      *ngIf="{
        structureLoading: structureLoading$ | async,
        suggestedLoading: suggestedLoading$ | async,
        tags: tagSuggested$ | async,
        structure: structure$ | async
      } as data"
    >
      <div *ngIf="data.structureLoading || data.suggestedLoading" class="text-center mb-2">
        <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
      </div>
      <div *ngIf="!data.suggestedLoading && !data.structureLoading && data.structure && data.tags">
        <rr-prefill-tag-structure
          [tags]="data.tags"
          [structure]="data.structure"
          [topicId]="topicId"
          [section]="section.value"
          [topicTags]="topicTags"
        ></rr-prefill-tag-structure>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="text-center">
      <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
    </div>
  </ng-template>
</div>
