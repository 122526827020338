import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromInstitution, InstitutionEffect } from 'app/store/institution';
import { Subscription, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { InstitutionFormEditComponent } from '../../components/institution-form-edit/institution-form-edit.component';

@Component({
  standalone: true,
  imports: [CommonModule, InstitutionFormEditComponent],
  selector: 'rr-create-institution-modal',
  templateUrl: './create-institution-modal.component.html',
  styleUrls: ['./create-institution-modal.component.css'],
})
export class CreateInstitutionModalComponent implements OnInit, OnDestroy {
  @ViewChild(InstitutionFormEditComponent) institutionFormEditComponent: InstitutionFormEditComponent;
  @Input() institutionId?: number;
  subscription = new Subscription();
  institution: RR.Institution | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private institutionEffect: InstitutionEffect,
  ) {}

  ngOnInit(): void {
    if (this.institutionId) {
      this.subscription.add(
        this.store
          .select(fromInstitution.selectInstitution(this.institutionId))
          .pipe(
            take(1),
            // change to switchMap to avoid nested subscribe
            switchMap((ins) => {
              this.institution = ins;
              if (!ins && this.institutionId) {
                return this.institutionEffect.find(this.institutionId);
              }
              // If the institution already exists, we return an observable of null
              return of(null);
            }),
          )
          .subscribe(),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit() {
    this.institutionFormEditComponent.submit();
  }

  closeModal($event: { institution: RR.Institution; created: boolean }) {
    const { institution } = $event;
    this.activeModal.close(institution);
  }

  static open(modal: NgbModal, institutionId?: number) {
    const modalRef = modal.open(CreateInstitutionModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.institutionId = institutionId;
    return modalRef;
  }
}
