import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  COMMAND_ACTIONS,
  DELIMITER,
  TRIGGER_VOICE_STOP_CURRENT,
  ARE_YOU_LISTENING_TO_ME,
  STOP_SPEECH_SYNTHESIS,
} from 'app/app.constants';
import { filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { BehaviorSubject, filter, Subject, Subscription, take } from 'rxjs';

export type VoiceInputTerm = {
  source: string;
  term: string;
};

export type CommandOutput = {
  command: string;
  destination: string;
};

export type ListeningType = {
  listening: boolean;
  source: string;
};

export const GLOBAL_SEARCH_VOICE_GROUP = 'GLOBAL_SEARCH';

@Injectable()
export class VoiceRecognitionService implements OnDestroy {
  private speechRecognition: any;
  private speechQueue: string[] = [];
  public commandSubject$ = new Subject<CommandOutput>();
  public voiceTermSubject$ = new Subject<VoiceInputTerm>();

  isListening$ = new BehaviorSubject<ListeningType>({ listening: false, source: '' });
  // Source of voice recognition input
  source: string;
  voice: SpeechSynthesisVoice | null;
  subscription = new Subscription();
  lastText: string;
  lastTextTime = Date.now();

  constructor(
    private zone: NgZone,
    private store: Store<AppState>,
  ) {
    this.initSpeechRecognition();
    this.initSpeechSynthesis();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initSpeechSynthesis() {
    this.voice = speechSynthesis.getVoices().find((voice: SpeechSynthesisVoice) => voice.lang === 'en-GB') || null;

    if (!this.voice) {
      speechSynthesis.addEventListener('voiceschanged', () => {
        this.voice = speechSynthesis.getVoices().find((voice: SpeechSynthesisVoice) => voice.lang === 'en-GB') || null;
      });
    }
  }

  handleTranscript(transcript: string) {
    const trimmedTranscript = transcript.trim();
    // If the transcript contains any of the stop words from the TRIGGER_VOICE_STOP_CURRENT list, we remove that word from the transcript.

    const stopWord = TRIGGER_VOICE_STOP_CURRENT.find((word) => trimmedTranscript.includes(word));
    if (stopWord) {
      trimmedTranscript.replace(stopWord, '');
    }

    // remove STOP_SPEECH_SYNTHESIS from transcript
    trimmedTranscript.replace(STOP_SPEECH_SYNTHESIS, '');

    const terms = trimmedTranscript.includes(DELIMITER) ? trimmedTranscript.split(DELIMITER) : [trimmedTranscript];

    terms.forEach((term: string) => {
      this.performAction(term);
    });
  }

  initSpeechRecognition() {
    const { webkitSpeechRecognition }: Window = <Window>window;
    // eslint-disable-next-line new-cap
    this.speechRecognition = new webkitSpeechRecognition();
    this.speechRecognition.continuous = true;
    this.speechRecognition.interimResults = true;
    this.speechRecognition.lang = 'en-AU';
    this.speechRecognition.maxAlternatives = 1;
    this.lastText = '';
    this.lastTextTime = Date.now();

    this.speechRecognition.onresult = (event: any) => {
      if (event.results) {
        const result = event.results[event.resultIndex];
        const transcript = result[0].transcript;
        const lowerTranscript = transcript.toLowerCase();
        if (result.isFinal) {
          if (lowerTranscript.includes(ARE_YOU_LISTENING_TO_ME)) {
            lowerTranscript.replace(ARE_YOU_LISTENING_TO_ME, '');
            this.startSpeechSynth('YES!');
          }
          if (lowerTranscript.includes(STOP_SPEECH_SYNTHESIS)) {
            this.speechQueue = [];
            this.stopSpeechSynth();
          }
          this.handleTranscript(lowerTranscript);
        } else if (lowerTranscript.includes(TRIGGER_VOICE_STOP_CURRENT)) {
          this.handleTranscript(lowerTranscript);
        } else {
          if (lowerTranscript.includes(ARE_YOU_LISTENING_TO_ME)) {
            this.startSpeechSynth('YES!');
          }
          if (lowerTranscript.includes(STOP_SPEECH_SYNTHESIS)) {
            this.speechQueue = [];
            this.stopSpeechSynth();
          }
        }
      }
    };

    this.speechRecognition.onerror = (error: any) => {
      console.error('Error occurred in recognition: ', error);
      this.stopListening();
      this.isListening$.next({ listening: false, source: '' });
    };

    this.speechRecognition.onend = () => {
      this.stopListening();
      this.isListening$.next({ listening: false, source: '' });
      console.info('Stopped listening');
      const audio = new Audio();
      audio.src = '/assets/effects/mute.mp3';
      audio.load();
      audio.play();
    };

    this.speechRecognition.onstart = () => {
      this.isListening$.next({ listening: true, source: this.source });
      console.info('Listening...');
      // play unmute.mp3 from assets/effects
      const audio = new Audio();
      audio.src = '/assets/effects/unmute.mp3';
      audio.load();
      audio.play();
    };
  }

  startListening(source: string) {
    this.subscription.add(
      this.store
        .select(fromSession.selectRRConfig)
        .pipe(
          filterDefined(),
          take(1),
          filter((rrConfig) => rrConfig.SPEECH_RECOGNITION),
        )
        .subscribe(() => {
          if (source !== this.source) {
            this.source = source;
          }

          if (!this.isListening$.getValue().listening) {
            this.speechRecognition.start();
          }
        }),
    );
  }

  stopListening() {
    this.speechRecognition.stop();
  }

  startSpeechSynth(text: string): void {
    if (!this.voice) {
      return;
    }

    // add to speech queue
    this.speechQueue.push(text);

    // if there is ongoing speech, just add text to queue and return
    if (this.speechQueue.length > 1) {
      return;
    }

    this.speakNext();
  }

  speakNext() {
    const text = this.speechQueue[0];
    const now = Date.now();

    // stop endless loop of same text if on speaker
    if (this.lastText === text && now - this.lastTextTime < 5000) {
      return;
    }

    this.lastText = text;
    this.lastTextTime = now;

    this.stopSpeechSynth();
    this.synthesizeSpeechFromText(text);
  }

  stopSpeechSynth(): void {
    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
    }
  }

  private synthesizeSpeechFromText(text: string): void {
    window.speechSynthesis.cancel(); // for bug that sometimes causes speech to stop working https://bugs.chromium.org/p/chromium/issues/detail?id=335907
    // hack: limit the length of the text to 12 words
    const words = text.split(' ');
    if (words.length > 12) {
      text = words.slice(0, 12).join(' ');
    }

    // hack: replace link with 'web url'
    text = text.replace(/https?:\/\/\S+/g, 'web url');

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = this.voice;
    utterance.rate = 1;

    // attach the onend event to the utterance
    utterance.onend = () => {
      this.speechQueue.shift();

      // if there is more text to speak, start it
      if (this.speechQueue.length > 0) {
        this.speakNext();
      }
    };

    speechSynthesis.speak(utterance);
  }

  /**
   * For each search term whether perform a command action or add it to search list
   * @param term
   */
  performAction(term: string) {
    const trimmedTerm = term.trim();
    if (!trimmedTerm) return;

    const command: CommandOutput | null = this.getCommandAction(trimmedTerm);
    if (command) {
      this.zone.run(() => {
        this.commandSubject$.next(command);
      });
    } else {
      this.zone.run(() => {
        this.voiceTermSubject$.next({ source: this.source, term: trimmedTerm });
      });
    }
  }

  private getCommandAction(text: string): CommandOutput | null {
    let command: string | null = null;
    let destination: 'SIDEBAR' | 'TAG_STRUCTURE' | 'VOICE_RECOGNITION' | 'VOICE_NOTES' = 'SIDEBAR';
    const lowerText = text.toLowerCase();
    if (lowerText.includes(COMMAND_ACTIONS.clearSearch)) {
      command = COMMAND_ACTIONS.clearSearch;
    }
    if (lowerText.includes(COMMAND_ACTIONS.clearVoice)) {
      command = COMMAND_ACTIONS.clearVoice;
    }
    if (lowerText.includes(COMMAND_ACTIONS.stopListening)) {
      command = COMMAND_ACTIONS.stopListening;
      destination = 'VOICE_RECOGNITION';
    }
    if (lowerText.includes(COMMAND_ACTIONS.addFavourite)) {
      command = COMMAND_ACTIONS.addFavourite;
    }
    if (lowerText.includes(COMMAND_ACTIONS.toPrefill)) {
      command = COMMAND_ACTIONS.toPrefill;
    }
    if (lowerText.includes(COMMAND_ACTIONS.checkReport)) {
      command = COMMAND_ACTIONS.checkReport;
    }
    if (lowerText.includes(COMMAND_ACTIONS.demographics)) {
      command = COMMAND_ACTIONS.demographics;
    }
    if (lowerText.includes(COMMAND_ACTIONS.toEditor)) {
      command = COMMAND_ACTIONS.toEditor;
    }
    if (lowerText.includes(COMMAND_ACTIONS.prefillFavourites)) {
      command = COMMAND_ACTIONS.prefillFavourites;
    }
    if (lowerText.includes(COMMAND_ACTIONS.giveFeedback)) {
      command = COMMAND_ACTIONS.giveFeedback;
    }
    if (lowerText.includes(COMMAND_ACTIONS.feedbackHelp)) {
      command = COMMAND_ACTIONS.feedbackHelp;
    }
    if (lowerText.includes(COMMAND_ACTIONS.reportTitle)) {
      command = COMMAND_ACTIONS.reportTitle;
    }
    if (lowerText.includes(COMMAND_ACTIONS.tagsModal)) {
      command = COMMAND_ACTIONS.tagsModal;
    }
    // if (lowerText.includes(COMMAND_ACTIONS.copyKeyFindings)) {
    // command = COMMAND_ACTIONS.copyKeyFindings;
    // }
    if (lowerText.includes(COMMAND_ACTIONS.dicom)) {
      command = COMMAND_ACTIONS.dicom;
    }
    if (lowerText.includes(COMMAND_ACTIONS.invoices)) {
      command = COMMAND_ACTIONS.invoices;
    }
    if (lowerText.includes(COMMAND_ACTIONS.notes)) {
      command = COMMAND_ACTIONS.notes;
    }
    if (lowerText.includes(COMMAND_ACTIONS.tutorials)) {
      command = COMMAND_ACTIONS.tutorials;
    }
    // if (lowerText.includes(COMMAND_ACTIONS.prefillPrediction)) {
    // command = COMMAND_ACTIONS.prefillPrediction;
    // }
    if (lowerText.includes(COMMAND_ACTIONS.registration)) {
      command = COMMAND_ACTIONS.registration;
    }
    if (lowerText.includes(COMMAND_ACTIONS.sendReport)) {
      command = COMMAND_ACTIONS.sendReport;
    }
    if (lowerText.includes(COMMAND_ACTIONS.sign)) {
      command = COMMAND_ACTIONS.sign;
    }
    // if (lowerText.includes(COMMAND_ACTIONS.prefillTitle)) {
    // command = COMMAND_ACTIONS.prefillTitle;

    // }
    if (lowerText.includes(COMMAND_ACTIONS.urgent)) {
      command = COMMAND_ACTIONS.urgent;
    }
    // if (lowerText.includes(COMMAND_ACTIONS.webViewerImages)) {
    // command = COMMAND_ACTIONS.webViewerImages;

    // }
    // if (lowerText.includes(COMMAND_ACTIONS.webImages)) {
    // command = COMMAND_ACTIONS.webImages;

    // }
    if (lowerText.includes(COMMAND_ACTIONS.export)) {
      command = COMMAND_ACTIONS.export;
    }
    if (lowerText.includes(COMMAND_ACTIONS.registrationQuestions)) {
      command = COMMAND_ACTIONS.registrationQuestions;
    }
    if (lowerText.includes(COMMAND_ACTIONS.undoList)) {
      command = COMMAND_ACTIONS.undoList;
    }
    if (lowerText.includes(COMMAND_ACTIONS.nextSubsection)) {
      command = COMMAND_ACTIONS.nextSubsection;
      destination = 'TAG_STRUCTURE';
    }
    if (lowerText.includes(COMMAND_ACTIONS.previousSubsection)) {
      command = COMMAND_ACTIONS.previousSubsection;
      destination = 'TAG_STRUCTURE';
    }
    if (lowerText.includes(COMMAND_ACTIONS.deleteLastSentence)) {
      command = COMMAND_ACTIONS.deleteLastSentence;
      destination = 'VOICE_NOTES';
    }

    if (command) {
      return { command, destination };
    }

    return null;
  }
}
