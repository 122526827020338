import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.UserNotification>();

const selectFeature = (state: AppState) => state.user_notification;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectUserNotification = (id: number) => createSelector(selectEntities, (notifications) => notifications[id]);
const selectLoaded = createSelector(selectFeature, (state) => state.loaded);

export const fromNotifications = {
  selectIds,
  selectEntities,
  selectAll,
  selectUserNotification,
  selectLoaded,
};
