<div class="mt-1 d-flex flex-column flex-grow-1">
  <div class="d-flex mb-1 align-items-center justify-content-end gap-2" *ngIf="!prefillBySubdivisions">
    <div class="d-flex align-items-center gap-1" *ngIf="numTopics > 1">
      <label title="Show only statements that appear n times in selected prefill topics">Threshold:</label>
      <select class="form-select" [(ngModel)]="threshold">
        <option *ngFor="let i of getThresholds()" [ngValue]="i">{{ i }}</option>
      </select>

      <button
        rrAutoFocus
        class="btn btn-sm btn-outline-dark text-nowrap"
        [disabled]="threshold <= 1"
        role="button"
        (click)="changeThreshold(-1)"
      >
        Show more
      </button>
      <button
        class="btn btn-sm btn-outline-dark text-nowrap"
        [disabled]="threshold >= numTopics"
        role="button"
        (click)="changeThreshold(1)"
      >
        Show less
      </button>
    </div>
    <div class="form-check" rrTooltip="PREFILL_STATEMENT_LIST_WITH_LOGISTIC_REGRESSION">
      <input
        class="form-check-input"
        type="checkbox"
        id="logReg"
        name="logReg"
        [checked]="logReg"
        (change)="logRegChanged()"
      />
      <label class="form-check-label" for="logReg"
        >LRA <strong>{{ logRegThreshold }}%</strong></label
      >
    </div>
    <div class="d-flex btn-group">
      <button
        rrTooltip="PREFILL_LOGREG_THRESHOLD_INCREASE"
        class="btn btn-outline-dark"
        role="button"
        (click)="changeLogRegThreshold(10)"
        [disabled]="!logReg"
      >
        <i class="fa fa-plus-circle"></i>
      </button>
      <button
        rrTooltip="PREFILL_LOGREG_THRESHOLD_DECREASE"
        class="btn btn-outline-dark"
        role="button"
        (click)="changeLogRegThreshold(-10)"
        [disabled]="!logReg"
      >
        <i class="fa fa-minus-circle"></i>
      </button>
    </div>
    <div ngbDropdown placement="bottom-right">
      <button
        class="btn btn-outline-dark"
        role="button"
        id="dropdownSetting"
        placement="left"
        ngbDropdownToggle
        rrTooltip="PREFILL_SETTING"
        [disabled]="logReg"
      >
        <i class="fa fa-cog"></i>
      </button>
      <div ngbDropdownMenu class="px-2">
        <div class="d-flex align-items-center gap-2">
          <label *ngIf="numTopics > 1" class="btn btn-sm btn-outline-dark text-nowrap" rrTooltip="SHOW_MORE_COMMENTS">
            <input type="checkbox" [checked]="moreComments" (click)="toggleMoreComments()" />
            More C/I&R
          </label>

          <label class="btn btn-sm btn-outline-dark text-nowrap" rrTooltip="SHOW_INDEX">
            <input type="checkbox" [checked]="!hideHistory" (click)="toggleHideHistory()" />
            Show History/Technique
          </label>
        </div>
      </div>
    </div>
  </div>
  <rr-prefill-copy
    class="flex-grow-1"
    [topic]="topic"
    [threshold]="threshold"
    [hideHistoryTechnique]="hideHistory"
    [moreComments]="moreComments"
    [logRegStatements]="filterLogRegStatements"
  ></rr-prefill-copy>
</div>
