import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateService } from 'app/core/services/template.service';

@Component({
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Remove study</h4>
      <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <div>Are you sure you want to remove</div>
      <strong> {{ templateName | async }} </strong>
      <div>from the report?</div>
    </div>
    <div class="modal-footer">
      <button ngbAutoFocus class="btn btn-danger" role="button" (click)="activeModal.close()">Remove study</button>
      <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class TopicDeleteModalComponent implements OnInit {
  // @ts-expect-error noImplicitAny
  topic; // input
  // @ts-expect-error noImplicitAny
  templateName;

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
  ) {}

  ngOnInit() {
    this.templateName = this.templateService.getTemplateName(this.topic.template_id);
  }
}
