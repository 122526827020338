<div class="modal-header">
  <h5 class="modal-title">{{ tutorial_title }}</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p>{{ tutorial_notes }}</p>
  <p>
    <video class="w-100" autoplay controls>
      <source [src]="tutorial_link" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </p>
</div>
