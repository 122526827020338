<div *ngIf="voiceMode$ | async" class="text-nowrap" [ngbTooltip]="tipContent" [openDelay]="200" container="body">
  <button
    *ngIf="!isListening.listening"
    class="btn btn-sm btn-outline-secondary"
    (click)="$event.stopPropagation(); startListening()"
  >
    Not Listening <i class="fas fa-microphone"></i>
  </button>
  <button
    *ngIf="isListening.listening"
    class="btn btn-sm btn-outline-danger flash"
    (click)="$event.stopPropagation(); stopListening()"
  >
    Listening <i class="fas fa-microphone-slash"></i>
  </button>
</div>
<ng-template #tipContent>
  <div class="text-start">
    <h4 class="mb-1">General</h4>
    <div><b>Delimiter:</b> "{{ voiceControls.DELIMITER }}"</div>
    <div><b>Stop voice:</b> "{{ voiceControls.COMMAND_ACTIONS.stopListening }}"</div>
    <div><b>Delete last auto-recommended statement:</b> "{{ voiceControls.COMMAND_ACTIONS.deleteLastSentence }}"</div>
    <div><b>Process singular term:</b> "{{ voiceControls.TRIGGER_VOICE_STOP_CURRENT.join('/ ') }}"</div>
    <div><b>Listening?:</b> "{{ voiceControls.ARE_YOU_LISTENING_TO_ME }}"</div>

    <div class="mt-4">
      <h4 class="mb-1">Editor commands</h4>
      <div><b>Sidebar buttons:</b> "shift plus 'letter'"</div>
      <div><b>Clear global search:</b> "{{ voiceControls.COMMAND_ACTIONS.clearSearch }}"</div>
      <div><b>Clear voice terms:</b> "{{ voiceControls.COMMAND_ACTIONS.clearVoice }}"</div>
    </div>
  </div>
</ng-template>
