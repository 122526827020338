import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { ReferrerActions } from '../referrer/referrer.action';

/**
 * Ngrx Actions for ReferrerContactMethod
 *
 */
export const ReferrerContactMethodActions = {
  findManySuccess: createAction(
    '[ReferrerContactMethod] Find Many Success',
    props<{ referrer_contact_methods: RR.ReferrerContactMethod[] }>(),
  ),
  createSuccess: createAction(
    '[ReferrerContactMethod] Create Success',
    props<{ referrer_contact_method: RR.ReferrerContactMethod }>(),
  ),
  deleteSuccess: createAction(
    '[ReferrerContactMethod] Delete Success',
    props<{ referrer_contact_method_id: number }>(),
  ),
};

export const ReferrerContactMethodBatchActions = {
  createSuccess: createBatchAction(
    '[ReferrerContactMethod] Create Success Batch',
    props<{
      actions: {
        createReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.createSuccess>;
        updateReferrerSuccess: ReturnType<typeof ReferrerActions.updateSuccess>;
      };
    }>(),
  ),
  deleteSuccess: createBatchAction(
    '[ReferrerContactMethod] Delete Success Batch',
    props<{
      actions: {
        deleteReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.deleteSuccess>;
        updateReferrerSuccess: ReturnType<typeof ReferrerActions.updateSuccess>;
      };
    }>(),
  ),
};
