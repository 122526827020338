import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ReferrerActions } from './referrer.action';

export interface ReferrerState extends EntityState<RR.Referrer> {
  referrersLoaded: { [id: number]: boolean };
  count: number;
}

const adapter = createEntityAdapter<RR.Referrer>();

const initialState = adapter.getInitialState({
  referrersLoaded: {},
  count: 0,
});

export const referrerReducer = createReducer(
  initialState,
  on(ReferrerActions.searchSuccess, (state: ReferrerState, searchResult) => {
    const { referrers, count } = searchResult.searchResult;
    return {
      ...adapter.upsertMany(referrers, state),
      count,
    };
  }),
  on(ReferrerActions.findManySuccess, (state: ReferrerState, { referrers }) => {
    const referrersLoadedObj = referrers
      .map((referrer) => referrer.id)
      .reduce((acc, referrer_id) => {
        return { ...acc, [referrer_id]: true };
      }, {});
    return {
      ...adapter.upsertMany(referrers, state),
      referrersLoaded: { ...state.referrersLoaded, ...referrersLoadedObj },
    };
  }),
  on(ReferrerActions.findSuccess, (state: ReferrerState, { referrer }) => ({
    ...adapter.upsertOne(referrer, state),
    referrersLoaded: { ...state.referrersLoaded, [referrer.id]: true },
  })),
  on(ReferrerActions.createSuccess, (state: ReferrerState, { referrer }) => adapter.addOne(referrer, state)),
  on(ReferrerActions.updateSuccess, (state: ReferrerState, { referrer }) => adapter.upsertOne(referrer, state)),
  on(ReferrerActions.deleteSuccess, (state: ReferrerState, { referrer }) =>
    adapter.upsertOne({ ...referrer, deleted: true }, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
