import { Dictionary, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromJustifications } from '../report/justification/justification.selector';
import { fromTemplate } from '../template/template/template.selector';

const adapter = createEntityAdapter<RR.MandatoryStatement>();

const selectFeature = (state: AppState) => state.mandatory_statement;

const { selectEntities } = adapter.getSelectors(selectFeature);

const selectMandatoryStatement = (id: number) =>
  createSelector(selectEntities, (mandatoryStatements) => mandatoryStatements[id]);

const selectByTemplate = (templateId: number) =>
  createSelector(
    selectEntities,
    fromTemplate.selectTemplate(templateId),
    (mandatoryStatements: Dictionary<RR.MandatoryStatement>, template: RR.Template | undefined) => {
      if (!template) {
        return [];
      }
      return template.mandatory_statements
        .map((id) => mandatoryStatements[id])
        .filter((o): o is RR.MandatoryStatement => !!o);
    },
  );

const selectMandatoryStatementByStatementId = (statementId: number, templateId: number) =>
  createSelector(selectByTemplate(templateId), (mandatoryStatements: RR.MandatoryStatement[]) =>
    mandatoryStatements.find((m) => m.statement_id === statementId),
  );

const selectJustification = (mandatoryStatementId: number) =>
  createSelector(
    selectMandatoryStatement(mandatoryStatementId),
    fromJustifications.selectAll,
    (mandatoryStatement: RR.MandatoryStatement | undefined, justifications: RR.Justification[]) => {
      if (!mandatoryStatement) {
        return undefined;
      }
      return justifications.find((j) => j.mandatory_statement_id === mandatoryStatement.id);
    },
  );

export const fromMandatoryStatement = { selectMandatoryStatementByStatementId, selectByTemplate, selectJustification };
