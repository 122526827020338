import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { InvoiceTableComponent } from 'app/modules/invoice/components/invoice-table/invoice-table.component';
import { CreateInvoiceModalComponent } from 'app/modules/invoice/modals/create-invoice-modal/create-invoice-modal.component';
import { AppState } from 'app/store';
import { fromInvoice, InvoiceEffect } from 'app/store/invoice';
import { Observable, Subscription, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, InvoiceTableComponent],
  selector: 'rr-report-invoices',
  templateUrl: './report-invoices.component.html',
  styleUrls: ['./report-invoices.component.css'],
})
export class ReportInvoicesComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  invoices$: Observable<RR.Invoice[]>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private invoiceEffect: InvoiceEffect,
  ) {}

  ngOnInit(): void {
    this.invoices$ = this.store.select(fromInvoice.selectLoaded(this.reportId)).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() =>
        this.store
          .select(fromInvoice.selectInReport(this.reportId))
          .pipe(map((invoices) => invoices.sort((a, b) => b.id - a.id))),
      ),
    );

    this.subscription.add(
      this.store
        .select(fromInvoice.selectLoaded(this.reportId))
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.invoiceEffect.findInReport(this.reportId);
            } else {
              return of(null);
            }
          }),
        )
        .subscribe(),
    );
  }

  createNewInvoice() {
    CreateInvoiceModalComponent.open(this.modalService, this.reportId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
