<ng-container *ngIf="relatedReports && relatedReports.sameDay.length > 0 && report && topic">
  <button
    #pendingReports
    role="button"
    class="btn btn-warning flash"
    rrTooltip="SAME_DAY_PENDING_REPORTS"
    (click)="openSameDayReports()"
  >
    <span
      >Other pending reports for this patient
      <span class="badge bg-primary">{{ relatedReports.sameDay.length }}</span></span
    >
  </button>
</ng-container>
