import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { PatientQuestionActions } from '../patient-question/patient-question.action';

/**
 * Ngrx Actions for PatientQuestionSet
 *
 */
export const PatientQuestionSetActions = {
  findAllSuccess: createAction(
    '[PatientQuestionSet] Find All Success',
    props<{ patientQuestionSets: RR.PatientQuestionSet[] }>(),
  ),

  createSuccess: createAction(
    '[PatientQuestionSet] Create Success',
    props<{ patientQuestionSet: RR.PatientQuestionSet }>(),
  ),

  updateSuccess: createAction(
    '[PatientQuestionSet] Update Success',
    props<{ patientQuestionSet: RR.PatientQuestionSet }>(),
  ),

  deleteSuccess: createAction('[PatientQuestionSet] Delete Success', props<{ patientQuestionSetId: number }>()),

  moveSuccess: createAction(
    '[PatientQuestionSet] Move Success',
    props<{ patientQuestionSets: RR.PatientQuestionSet[] }>(),
  ),
};

export const PatientQuestionSetBatchActions = {
  reorderQuestionsSuccessBatch: createBatchAction(
    '[PatientQuestionSet] Reorder Questions Success Batch',
    props<{
      actions: {
        updatePatientQuestionSetSuccess: ReturnType<typeof PatientQuestionSetActions.updateSuccess>;
        updatePatientQuestionsSuccess: ReturnType<typeof PatientQuestionActions.updateManySuccess>;
      };
    }>(),
  ),
};
