import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { SiteActions } from './site.action';

export interface SiteState extends EntityState<RR.Site> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.Site>();

const initialState = adapter.getInitialState({ loaded: false });

export const siteReducer = createReducer(
  initialState,
  // Site might be nested inside User->Signature and User's findAll action might have `where` condition applied
  // Using `upsertMany` to not clear sites that were already fetched.
  on(SiteActions.findAllSuccess, (state: SiteState, { sites }) => ({
    ...adapter.upsertMany(sites, state),
    loaded: true,
  })),
  on(SiteActions.upsertMany, (state: SiteState, { sites }) => adapter.upsertMany(sites, state)),
  on(SiteActions.upsertOne, (state: SiteState, { site }) => adapter.upsertOne(site, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
