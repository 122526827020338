import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TemplateData } from '../report/topic/topic.service';

export type ProposedDataset = {
  proposed_statements: RR.ProposedStatement[];
};

export type DicomMeasurementsResponse = RR.MatchingMeasurement[][];

type ProposedResponse = {
  datasets: ProposedDataset[];
} & TemplateData;

@Injectable()
export class DicomHttpService {
  constructor(private http: HttpClient) {}

  loadMeasurements(reportId: number) {
    const params = new HttpParams().set('report_id', reportId.toString());
    return this.http.get<DicomMeasurementsResponse>('/api/dicom/measurements', {
      params,
    });
  }

  queryDicom(reportId: number) {
    const params = new HttpParams().set('report_id', reportId.toString());
    return this.http.get<RR.Report>('/api/dicom/query', { params });
  }

  propose(topicId: number) {
    return this.http.get<ProposedResponse>('/api/dicom/propose', {
      params: {
        topic_id: topicId,
      },
    });
  }
}
