import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { PatientQuestionSetActions } from './patient-question-set.action';

export interface PatientQuestionSetState extends EntityState<RR.PatientQuestionSet> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.PatientQuestionSet>();

const initialState = adapter.getInitialState({ loaded: false });

export const patientQuestionSetReducer = createReducer(
  initialState,
  on(PatientQuestionSetActions.findAllSuccess, (state: PatientQuestionSetState, { patientQuestionSets }) => ({
    ...adapter.setAll(patientQuestionSets, state),
    loaded: true,
  })),
  on(PatientQuestionSetActions.createSuccess, (state: PatientQuestionSetState, { patientQuestionSet }) =>
    adapter.addOne(patientQuestionSet, state),
  ),
  on(PatientQuestionSetActions.updateSuccess, (state: PatientQuestionSetState, { patientQuestionSet }) =>
    adapter.upsertOne(patientQuestionSet, state),
  ),
  on(PatientQuestionSetActions.deleteSuccess, (state: PatientQuestionSetState, { patientQuestionSetId }) =>
    adapter.removeOne(patientQuestionSetId, state),
  ),
  on(PatientQuestionSetActions.moveSuccess, (state: PatientQuestionSetState, { patientQuestionSets }) =>
    adapter.upsertMany(patientQuestionSets, state),
  ),
  // Clear store data on logout, open/close editor
  on(EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
