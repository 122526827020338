import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { PatientNoteCategoryActions } from '../patient-note-category/patient-note-category.action';
import { PatientActions } from '../patient/patient.action';
import { PatientSearchResponse } from '../patient/patient.service';

/**
 * Ngrx Actions for PatientNote
 *
 */
export const PatientNoteActions = {
  searchSuccess: createAction('[PatientNote] Search Success', props<{ searchResult: PatientSearchResponse }>()),
  findManySuccess: createAction('[PatientNote] Find Many Success', props<{ patient_notes: RR.PatientNote[] }>()),
  createSuccess: createAction('[PatientNote] Create Success', props<{ patient_note: RR.PatientNote }>()),
  deleteSuccess: createAction('[PatientNote] Delete Success', props<{ patient_note_id: number }>()),
  updateSuccess: createAction('[PatientNote] Update Success', props<{ patient_note: RR.PatientNote }>()),
};

export const PatientNoteBatchActions = {
  createSuccess: createBatchAction(
    '[PatientNote] Create Success Batch',
    props<{
      actions: {
        createPatientNoteSuccess: ReturnType<typeof PatientNoteActions.createSuccess>;
        updatePatientSuccess: ReturnType<typeof PatientActions.updateSuccess>;
      };
    }>(),
  ),
  deleteSuccess: createBatchAction(
    '[PatientNote] Delete Success Batch',
    props<{
      actions: {
        deletePatientNoteSuccess: ReturnType<typeof PatientNoteActions.deleteSuccess>;
        updatePatientSuccess: ReturnType<typeof PatientActions.updateSuccess>;
      };
    }>(),
  ),
  searchSuccess: createBatchAction(
    '[PatientNote] Search Success Batch',
    props<{
      actions: {
        searchPatientNoteSuccess: ReturnType<typeof PatientNoteActions.searchSuccess>;
        findManyPatientSuccess: ReturnType<typeof PatientActions.findManySuccess>;
        findManyPatientNoteCategorySuccess: ReturnType<typeof PatientNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
};
