import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { FeedbackCommentActions } from './feedback-comment.action';

export type FeedbackCommentState = EntityState<RR.FeedbackComment>;

const adapter = createEntityAdapter<RR.FeedbackComment>();

const initialState = adapter.getInitialState();

export const feedbackCommentReducer = createReducer(
  initialState,
  on(FeedbackCommentActions.findAllSuccess, (state: FeedbackCommentState, { feedbackComments }) =>
    adapter.setAll(feedbackComments, state),
  ),
  on(
    FeedbackCommentActions.findManySuccess,
    FeedbackCommentActions.updateManySuccess,
    (state: FeedbackCommentState, { feedbackComments }) => adapter.upsertMany(feedbackComments, state),
  ),
  on(FeedbackCommentActions.createSuccess, (state: FeedbackCommentState, { feedbackComment }) =>
    adapter.addOne(feedbackComment, state),
  ),
  on(FeedbackCommentActions.updateSuccess, (state: FeedbackCommentState, { feedbackComment }) =>
    adapter.upsertOne(feedbackComment, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
