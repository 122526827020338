import { Component, Input, ViewChild } from '@angular/core';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ConvertSecondsToHMSPipe } from 'app/shared/pipes/convert-seconds-hms-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AuditEventHttpService } from 'app/store/report/audit-event';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [SharedModule, NgbPopoverModule, ConvertSecondsToHMSPipe],
  selector: 'rr-access-event',
  templateUrl: './access-event.component.html',
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class AccessEventComponent {
  @ViewChild('popover', { static: true }) popover: NgbPopover;
  @Input() event: RR.ReportAccessEvent;
  @Input() access_events: RR.ReportAccessEvent[] = [];
  @Input() topics: number[];
  @Input() last: boolean;

  subscription = new Subscription();

  constructor(private auditEventHttpService: AuditEventHttpService) {}

  open(event: RR.ReportAccessEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.topics) {
      this.subscription = this.getSummary(event)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe((summary) => {
          if (Object.values(summary.counts).length === 0) {
            this.popover.open({ counts: undefined });
          } else {
            this.popover.open(summary);
          }
        });
    } else {
      this.popover.open({ counts: {} });
    }
  }

  close() {
    this.subscription.unsubscribe();
    this.popover.close();
  }

  getSummary(access_event: RR.ReportAccessEvent) {
    const index = this.access_events.findIndex((e) => e.id === access_event.id);
    let to_date: string | undefined;
    const next_access_event = this.access_events[index + 1];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (next_access_event) to_date = next_access_event.when;

    const data = {
      from_date: access_event.when,
      to_date,
      topic_ids: this.topics,
      user_id: access_event.user_id,
    };
    return this.auditEventHttpService.getSummary(data);
  }
}
