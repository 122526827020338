import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { RegionChoiceHttpService } from './region-choice.service';

@Injectable()
export class RegionChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: RegionChoiceHttpService,
  ) {}

  create({ topicId, subsectionId, regionId }: { topicId: number; subsectionId: number; regionId: number }) {
    return this.service.create(topicId, subsectionId, regionId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(regionChoiceId: number) {
    return this.service.delete(regionChoiceId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
