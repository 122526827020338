import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { ScreenshotService } from './screenshot.service';

@Component({
  selector: '[rr-issue]',
  templateUrl: './issue.component.html',
  standalone: true,
  imports: [NgbTooltip],
})
export class IssueComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  get canvas(): HTMLCanvasElement {
    return document.getElementById('canvas') as HTMLCanvasElement;
  }
  get video(): HTMLVideoElement {
    return document.getElementById('video') as HTMLVideoElement;
  }

  constructor(private screenshotService: ScreenshotService) {}

  ngOnInit() {}

  onClick() {
    this.screenshotService.openDialog();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
