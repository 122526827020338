import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { AbbreviationsModalComponent } from 'app/modules/abbreviation/modals/abbreviations-modal/abbreviations-modal.component';
import { DividerStatementsModalComponent } from 'app/modules/editor/divider/divider-statements-modal/divider-statements-modal.component';
import { DueDateComponent } from 'app/shared/components/datetime/due-date.component';
import { NoteTextComponent } from 'app/shared/components/note-text/note-text.component';
import { NotesComponent } from 'app/shared/components/notes/notes.component';
import { ReportHeadlineComponent } from 'app/shared/components/report-headline/report-headline.component';
import { AutoSizeDirective } from 'app/shared/directives/auto-size.directive';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { PatientEffect, fromPatient } from 'app/store/patient';
import { ReferrerEffect, fromReferrer } from 'app/store/referrer';
import { fromReport } from 'app/store/report/report';
import { fromTodo, TodoEffect } from 'app/store/report/todo';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromUrgentNote, UrgentNoteEffect } from 'app/store/report/urgent-note';
import { Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ConfirmMessageModalComponent } from '../confirm-message-modal/confirm-message-modal.component';
import { CreateNoteModalComponent } from '../create-note-modal/create-note-modal.component';
import { SuperUrgentButtonComponent } from '../due-date-modal/super-urgent-button/super-urgent-button.component';
import { UrgentButtonComponent } from '../due-date-modal/urgent-button/urgent-button.component';
import { EditNoteModalComponent } from './edit-note-modal/edit-note-modal.component';
import { TodoComponent } from './todo/todo.component';
import { VoiceNotesComponent } from './voice-notes/voice-notes.component';

@Component({
  standalone: true,
  selector: 'rr-notes-modal',
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss'],
  imports: [
    SharedModule,
    CommonModule,
    FormsModule,
    VoiceNotesComponent,
    NgbModule,
    TodoComponent,
    ReportHeadlineComponent,
    AutoSizeDirective,
    UrgentButtonComponent,
    DueDateComponent,
    EditNoteModalComponent,
    NoteTextComponent,
    NotesComponent,
    VoiceDirective,
    SuperUrgentButtonComponent,
  ],
})
export class NotesModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() report_id: number;
  @Input() topicId?: number;
  @Input() focusVoice?: boolean;
  @ViewChild('todoInput') todoInput: ElementRef;
  @ViewChild('urgentNotesInput') urgentNotesInput: ElementRef;

  // Optional input study_notes and patient_note
  // They will be passed to the modal in case we open the modal from worklist
  // Otherwise, we need to fetch the report and extract them from the report
  @Input() study_notes: string;
  @Input() patient_note: string;

  todos$: Observable<RR.Todo[]>;
  urgentNotes$: Observable<RR.UrgentNotes[]>;
  referrer$: Observable<RR.Referrer | undefined>;
  report$: Observable<RR.Report | undefined>;
  patient$: Observable<RR.Patient | undefined>;
  focusVoiceMode = false;

  subscription = new Subscription();
  patientNotes: string | null;
  collaterNotes: string | null;
  bookingNote$: Observable<string | null>;
  topic$: Observable<RR.Topic | undefined>;

  newTodo = new FormControl<string>('', { nonNullable: true });
  newNotes = new FormControl<string>('', { nonNullable: true });

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private todoEffect: TodoEffect,
    private urgentNoteEffect: UrgentNoteEffect,
    private modalService: NgbModal,
    private editorService: EditorService,
    private patientEffect: PatientEffect,
    private referrerEffect: ReferrerEffect,
  ) {}

  ngOnInit() {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic);
    this.report$ = this.store.select(fromReport.selectReport(this.report_id));
    this.todos$ = this.store.select(fromTodo.selectInReport(this.report_id));
    this.urgentNotes$ = this.store.select(fromUrgentNote.selectInReport(this.report_id));

    this.subscription.add(
      this.report$
        .pipe(
          filterDefined(),
          take(1),
          switchMap((report) => {
            if (report.patient_id) {
              return this.patientEffect.findById(report.patient_id);
            }
            if (report.referrer_id) {
              return this.referrerEffect.findById(report.referrer_id);
            }
            return of(null);
          }),
        )
        .subscribe(),
    );

    // Not open modal from worklist, get study_notes and patient_note from the store
    if (!this.study_notes && !this.patient_note) {
      this.subscription.add(
        this.report$.pipe(take(1)).subscribe((report) => {
          if (!report) return;
          if (report.study_notes) this.study_notes = report.study_notes;
          if (report.patient_note) this.patient_note = report.patient_note;
        }),
      );
    }

    this.bookingNote$ = this.report$.pipe(
      filterDefined(),
      switchMap((report) => this.store.select(fromBooking.selectBooking(report.booking_id))),
      map((booking) => (booking ? booking.notes : null)),
    );

    this.referrer$ = this.report$.pipe(
      switchMap((report) =>
        report && report.referrer_id
          ? this.store.select(fromReferrer.selectReferrer(report.referrer_id))
          : of(undefined),
      ),
    );

    this.patient$ = this.report$.pipe(
      switchMap((report) =>
        report && report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
      ),
    );

    this.subscription.add(
      this.patient$.subscribe((patient) => {
        this.patientNotes = patient ? patient.note : null;
      }),
    );
    this.subscription.add(
      this.referrer$.subscribe((referrer) => {
        this.collaterNotes = referrer ? referrer.collater_notes : null;
      }),
    );
  }

  addTodo() {
    this.subscription.add(
      this.todoEffect
        .create({ report_id: this.report_id, todo: this.newTodo.value })
        .pipe(take(1))
        .subscribe(() => {
          this.newTodo.reset();
        }),
    );
  }

  addUrgentNotes() {
    this.subscription.add(
      this.urgentNoteEffect
        .create({ report_id: this.report_id, urgent_notes: this.newNotes.value })
        .pipe(take(1))
        .subscribe(() => {
          this.newNotes.reset();
        }),
    );
  }

  deleteUrgentNotes(urgent_note_id: number) {
    this.subscription.add(this.urgentNoteEffect.delete(urgent_note_id).subscribe());
  }

  saveAndClose() {
    if (this.newTodo.value || this.newNotes.value) {
      ConfirmMessageModalComponent.open({
        modalService: this.modalService,
        header: 'Unsaved changes',
        message: 'Are you sure you want to close?',
      })
        .result.then(() => {
          this.activeModal.dismiss();
        })
        .catch(() => {
          /* do nothing dismiss */
        });
    } else {
      this.activeModal.dismiss();
    }
  }

  editNotes(id: { bookingId?: number; patientId?: number; referrerId?: number }) {
    if (id.bookingId || id.patientId || id.referrerId) {
      EditNoteModalComponent.open(this.modalService, id);
    }
  }

  openAbbreviations() {
    AbbreviationsModalComponent.open(this.modalService);
  }

  togglePrefill() {
    this.editorService.togglePrefill(true);
    this.modalService.dismissAll();
  }

  triggerVoiceNoteFocus(focus?: boolean) {
    if (focus) {
      this.focusVoiceMode = true;
    } else {
      this.focusVoiceMode = !this.focusVoiceMode;
    }
    if (this.focusVoiceMode) {
      this.editorService.togglePrefill(true);
      setTimeout(() => {
        this.editorService.triggerVoiceNoteFocus();
      });
    }
  }

  openCreateNoteModal({ patientId, referrerId }: { patientId?: number; referrerId?: number }) {
    CreateNoteModalComponent.open({
      modalService: this.modalService,
      referrerId: referrerId ?? undefined,
      patientId: patientId ?? undefined,
      reportId: this.report_id,
    });
  }

  ngAfterViewInit(): void {
    if (this.focusVoice) {
      setTimeout(() => {
        this.triggerVoiceNoteFocus();
      });
    }
  }

  static open({
    modalService,
    report,
    topicId,
    voiceNotes,
  }: {
    modalService: NgbModal;
    report: RR.Report;
    topicId?: number;
    voiceNotes?: boolean;
  }) {
    const modalRef = modalService.open(NotesModalComponent, {
      scrollable: true,
      size: 'xl',
      centered: true,
    });
    modalRef.componentInstance.report_id = report.id;
    modalRef.componentInstance.study_notes = report.study_notes;
    modalRef.componentInstance.patient_note = report.patient_note;
    modalRef.componentInstance.topicId = topicId;
    modalRef.componentInstance.focusVoice = voiceNotes;
    return modalRef;
  }

  openDividerStatementsModal(topic: RR.Topic) {
    this.activeModal.dismiss();
    DividerStatementsModalComponent.open({
      modalService: this.modalService,
      topic_id: topic.id,
      parent: 'PREFILL_TAG_MODAL',
    });
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    const targetElement = event.target;
    if (targetElement instanceof HTMLTextAreaElement) {
      if (targetElement.name === 'newTodo') {
        this.newTodo.setValue(event.detail.term);
      } else if (targetElement.name === 'newNotes') {
        this.newNotes.setValue(event.detail.term);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
