import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { format, formatISO, setHours } from 'date-fns';
import { Subscription } from 'rxjs';

import { SuperUrgentButtonComponent } from './super-urgent-button/super-urgent-button.component';
import { UrgentButtonComponent } from './urgent-button/urgent-button.component';

@Component({
  selector: 'rr-due-date-modal',
  standalone: true,
  templateUrl: './due-date-modal.component.html',
  styleUrls: ['./due-date-modal.component.css'],
  imports: [SharedModule, ReactiveFormsModule, UrgentButtonComponent, SuperUrgentButtonComponent],
})
export class DueDateModalComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @Input() parent: string;
  timeList: number[] = [9, 11, 13, 15, 18];
  incrementList: number[] = [10, 20, 30, 40, 50, 60];

  subscription = new Subscription();

  dueDateForm = new FormGroup({
    due_date: new FormControl<string | undefined>(undefined, {
      nonNullable: true,
      validators: [Validators.nullValidator],
    }),
  });

  constructor(
    private reportEffect: ReportEffect,
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    // Reselect the report from the store. Because updated params can't be passed to the modal.
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report.id))
        .pipe(filterDefined())
        .subscribe((report) => {
          this.report = report;
        }),
    );

    if (this.report.due_date) {
      this.setDueDate(new Date(this.report.due_date));
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDate(): Date | null {
    const value = this.dueDateForm.controls.due_date.value;
    if (value !== undefined && value !== '') {
      return new Date(value);
    }
    return null;
  }

  onSubmit() {
    const dt = this.getDate();
    this.subscription.add(
      this.reportEffect
        .update(this.report.id, {
          due_date: dt !== null ? formatISO(dt) : null,
        })
        .subscribe(() => {
          this.activeModal.close();
        }),
    );
  }

  private formatDateTimeString(date: Date): string {
    // Must contain a "T" separator and not the milliseconds
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
    // e.g. 2022-03-15T05:05
    // 'T' quotes to escape the T. So that it isn't parsed a milliseconds by date-fns.
    return format(date, "yyyy-MM-dd'T'HH:mm");
  }

  setDueDate(date: string): void;
  setDueDate(date: Date): void;
  setDueDate(date: unknown) {
    if (typeof date === 'string') {
      this.dueDateForm.controls.due_date.setValue(this.formatDateTimeString(new Date(date)));
    } else if (date instanceof Date) {
      this.dueDateForm.controls.due_date.setValue(this.formatDateTimeString(date));
    } else {
      throw new Error(`Unexpected date ${date}`);
    }
  }

  setDueTime(hour: number) {
    const dt = this.getDate() || new Date();
    // Sets hours, minutes, seconds, and millis to 0
    const startOfDay = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    const changedHour = setHours(startOfDay, hour);
    this.setDueDate(changedHour);
  }

  setDueTimeIncrement(minutes: number) {
    const now = new Date();
    const newDueDate = new Date(now.getTime() + minutes * 60000);
    this.setDueDate(newDueDate);
  }

  onClickDueNow() {
    this.setDueDate(new Date());
    const dt = this.getDate();
    this.subscription.add(
      this.reportEffect
        .update(this.report.id, {
          due_date: dt !== null ? formatISO(dt) : null,
          urgent: true,
        })
        .subscribe(),
    );
  }

  static open({
    modalService,
    parent = 'EDITOR',
    report,
  }: {
    modalService: NgbModal;
    parent?: 'EDITOR' | 'WORKLIST';
    report: RR.Report;
  }) {
    const modalRef = modalService.open(DueDateModalComponent);
    const component: DueDateModalComponent = modalRef.componentInstance;
    component.parent = parent;
    component.report = report;
    return modalRef;
  }
}
