import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { fromRegion } from 'app/store/template/region';
import { Subscription } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule],
  selector: 'rr-tag-copy',
  templateUrl: './tag-copy.component.html',
  styleUrls: ['./tag-copy.component.scss'],
})
export class TagCopyComponent implements OnDestroy {
  @Input() regions: { name: string }[];
  @Input() region: { name: string };
  @Input() topicId: number;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private tagChoiceEffect: TagChoiceEffect,
  ) {}

  copyFromRegion(name: string) {
    let fromRegions = [name];
    if (name === 'ALL_REGIONS') {
      fromRegions = this.regions.filter((r) => r.name !== this.region.name).map((r) => r.name);
    }
    this.copyTags(fromRegions, [this.region.name]);
  }

  copyToRegion(name: string) {
    let toRegions = [name];
    if (name === 'ALL_REGIONS') {
      toRegions = this.regions.filter((r) => r.name !== this.region.name).map((r) => r.name);
    }
    this.copyTags([this.region.name], toRegions);
  }

  copyTags(fromNames: string[], toNames: string[]) {
    this.subscription.add(
      this.store
        .select(fromRegion.selectAll)
        .pipe(
          take(1),
          switchMap((regions) => {
            const fromIds = regions.filter((r) => fromNames.includes(r.name)).map((r) => r.id);
            const toIds = regions.filter((r) => toNames.includes(r.name)).map((r) => r.id);
            return this.tagChoiceEffect.copyTags(this.topicId, fromIds, toIds);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
