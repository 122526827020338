import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import { RegionEffect } from 'app/store/template/region';
import { fromRegionSet, RegionSetEffect } from 'app/store/template/region-set';
import { of, Subscription, Observable } from 'rxjs';

@Component({
  selector: 'rr-edit-region-set-modal',
  templateUrl: './edit-region-set-modal.component.html',
  styleUrls: ['./edit-region-set-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, CommonModule],
})
export class EditRegionSetModalComponent implements OnInit, OnDestroy {
  regionSets: RR.RegionSet[];
  selectedRegionSet: RR.RegionSet;
  mode: 'NEW' | 'EDIT' = 'EDIT';
  // Add New Region Set
  regionSetName = '';
  regionName = '';
  discName = '';
  nextName = '';
  spaceName = '';
  regions: any[] = [];
  error = '';

  subscription: Subscription = new Subscription();
  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
    private store: Store<AppState>,
    private regionSetEffect: RegionSetEffect,
    private regionEffect: RegionEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(this.regionSetEffect.findAll().subscribe());

    this.subscription.add(
      this.templateService.getAllRegionSets().subscribe((region_sets) => {
        this.regionSets = region_sets;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.regionSets && this.regionSets.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!this.selectedRegionSet) this.selectedRegionSet = this.regionSets[0];
          const region_set = this.regionSets.find((rs) => rs.id === this.selectedRegionSet.id);
          this.selectedRegionSet = region_set || this.regionSets[0];
        } else {
          // @ts-expect-error strictNullChecks
          this.selectedRegionSet = null;
        }
      }),
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getRegions(): Observable<RR.Region[]> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.selectedRegionSet) return of([]);
    // @ts-expect-error strictNullChecks
    return this.store.select(fromRegionSet.selectRegions(this.selectedRegionSet.id));
  }

  deleteRegionSet() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!this.selectedRegionSet) return;
    this.subscription.add(this.regionSetEffect.delete(this.selectedRegionSet.id).subscribe());
  }

  /**
   * Remove a region from region set in edit mode
   * @param region
   */
  removeRegionFromRegionSet(region: RR.Region) {
    this.subscription.add(this.regionEffect.delete(region.id).subscribe());
  }

  renameRegionSet(region_set: RR.RegionSet, name: string) {
    this.subscription.add(this.regionSetEffect.update(region_set.id, { name }).subscribe());
  }
  /**
   * Edit mode: add region for selected region set
   */
  addRegionToSelectedRegionSet() {
    const region: RR.Region = {
      // @ts-expect-error strictNullChecks
      id: undefined,
      name: this.regionName,
      disc_name: this.discName,
      next_name: this.nextName,
      space_name: this.spaceName,
      region_set_id: this.selectedRegionSet.id,
    };
    this.subscription.add(this.regionEffect.create(region).subscribe());
    this.resetRegionInput();
  }

  /**
   * Add region for a region set in new mode
   */
  addRegion() {
    this.regions.push({
      name: this.regionName,
      disc_name: this.discName,
      next_name: this.nextName,
      space_name: this.spaceName,
    });
    this.resetRegionInput();
  }

  resetRegionInput() {
    this.regionName = '';
    this.discName = '';
    this.nextName = '';
    this.spaceName = '';
  }

  resetRegionSetInput() {
    this.error = '';
    this.regionSetName = '';
    this.regions = [];
    this.resetRegionInput();
  }

  // MODE: NEW, remove region from region list
  removeRegion(idx: number) {
    this.regions.splice(idx, 1);
  }

  addNewRegionSet() {
    this.mode = 'NEW';
    this.resetRegionSetInput();
  }

  cancelClicked() {
    this.mode = 'EDIT';
    this.resetRegionSetInput();
  }

  okClicked() {
    // Add new region set
    if (!this.regionSetName) {
      this.error = 'Please input region set name!';
      return;
    }
    // Add position for each region then call api to create new region set
    this.regions.forEach((r, i) => {
      r.position = i;
    });
    const region_set = { id: undefined, name: this.regionSetName, regions: this.regions };
    this.subscription.add(this.regionSetEffect.create(region_set).subscribe());

    // Reset parameter
    this.mode = 'EDIT';
    this.resetRegionSetInput();
  }

  handleEditRegion($event: any, region: RR.Region, attr: string, cancel?: boolean) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    // if cancel: reset value for dom element
    if (cancel) {
      // @ts-expect-error noImplicitAny
      $event.srcElement.textContent = region[attr];
      return;
    }
    const value = $event.srcElement.textContent;
    // Check if name is empty
    if (attr === 'name' && !value) return;
    // Check if value is unchanged
    // @ts-expect-error noImplicitAny
    if (value === region[attr]) return;
    // Submit changes
    const changes = {};
    // @ts-expect-error noImplicitAny
    changes[attr] = value;
    this.subscription.add(this.regionEffect.update(region.id, changes).subscribe());
  }
}
