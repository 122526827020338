import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { StatementChoiceActions } from './statement-choice.action';

export type StatementChoiceState = EntityState<RR.StatementChoice>;

const adapter = createEntityAdapter<RR.StatementChoice>();

const initialState = adapter.getInitialState();

export const statementChoiceReducer = createReducer(
  initialState,
  on(StatementChoiceActions.addMany, (state: StatementChoiceState, { statementChoices }) =>
    adapter.addMany(statementChoices, state),
  ),
  on(StatementChoiceActions.upsertMany, (state: StatementChoiceState, { statementChoices }) =>
    adapter.upsertMany(statementChoices, state),
  ),
  on(StatementChoiceActions.upsertOne, (state: StatementChoiceState, { statementChoice }) =>
    adapter.upsertOne(statementChoice, state),
  ),
  on(StatementChoiceActions.removeMany, (state: StatementChoiceState, { statementChoiceIds }) =>
    adapter.removeMany(statementChoiceIds, state),
  ),
  on(StatementChoiceActions.addOne, (state: StatementChoiceState, { statementChoice }) =>
    adapter.addOne(statementChoice, state),
  ),

  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
