import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.NoteCategory>();
const selectFeature = (state: AppState) => state.referrer_note_category;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectReferrerNoteCategory = (id: number) =>
  createSelector(selectEntities, (referrer_note_category: Dictionary<RR.NoteCategory>) => referrer_note_category[id]);

export const fromReferrerNoteCategory = {
  selectIds,
  selectEntities,
  selectAll,
  selectReferrerNoteCategory,
};
