import { CommonModule } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { FocusService } from 'app/core/services/focus.service';
import { MessageService } from 'app/core/services/message.service';
import { SelectorService } from 'app/core/services/selector.service';
import { WorklistLinkComponent } from 'app/core/toolbar-navbar/components/worklist-link/worklist-link.component';
import { AttendanceModalComponent } from 'app/modules/report/modals/attendance-modal/attendance-modal.component';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store/app.state';
import { fromReportAccessEvent } from 'app/store/report/access-event';
import { FeedbackHttpService } from 'app/store/report/feedback';
import { fromReport } from 'app/store/report/report';
import { fromSession, SessionEffect, SessionHttpService } from 'app/store/session';
import { SettingEffect } from 'app/store/setting/setting.effect';
import { fromSetting } from 'app/store/setting/setting.selector';
import { fromUser, UserEffect } from 'app/store/user/user';
import { combineLatest, EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

type Context = 'EDITOR' | 'REGISTRATION';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserHeadlineComponent,
    // For PermissionDirective
    SharedModule,
    WorklistLinkComponent,
  ],
  selector: 'rr-initials-modal',
  templateUrl: './initials-modal.component.html',
  styles: [
    `
      .alert ::ng-deep p {
        margin-bottom: 0;
      }
    `,
  ],
})
export class InitialsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input', { static: true }) input: ElementRef;
  @Input() reportId: number | undefined;
  @Input() context: Context | undefined;
  initials: string;
  alert: string;
  subscription = new Subscription();
  kiosk$: Observable<boolean>;
  currentUser$: Observable<RR.User | undefined>;
  noNewFeedbacks = 0;
  initialsChanged: Subject<string> = new Subject<string>();
  numberOfSentenceCategorisation: number;
  users$: Observable<(RR.User | undefined)[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private focus: FocusService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private settingEffect: SettingEffect,
    private userEffect: UserEffect,
    private sessionEffect: SessionEffect,
    private sessionHttpService: SessionHttpService,
    private selectorService: SelectorService,
    private feedbackService: FeedbackHttpService,
    private message: MessageService,
  ) {
    this.currentUser$ = this.selectorService.selectLoadedCurrentUser();
    this.kiosk$ = this.store.select(fromSetting.selectKiosk);
  }

  ngOnInit() {
    // TODO: Is it okay to load every user? Only need users for report.
    this.subscription.add(this.userEffect.findAll(new HttpParams().set('active_only', true)).subscribe());

    if (this.reportId) {
      this.users$ = this.store.select(fromReport.selectReport(this.reportId)).pipe(
        filterDefined(), // registration page shows this modal before the report is loaded
        switchMap((report) => {
          return this.store.select(fromReportAccessEvent.selectAccessEventsInReport(report.id));
        }),
        switchMap((accessEvents) => {
          return combineLatest([this.currentUser$, this.store.select(fromUser.selectEntities)]).pipe(
            map(([currentUser, users]) => {
              const usersList = accessEvents.map((accessEvent) => {
                return users[accessEvent.user_id];
              });
              usersList.unshift(currentUser);
              const uniqueUsers = usersList.filter((user, index) => {
                return usersList.indexOf(user) === index;
              });
              return uniqueUsers;
            }),
          );
        }),
      );
    }

    this.subscription.add(
      this.initialsChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((initials) => {
        this.getFeedbackFromInitials(initials);
      }),
    );

    requestAnimationFrame(() => {
      this.input.nativeElement.focus();
    });
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.focus.requestFocus('initials').subscribe(() => {
        this.input.nativeElement.focus();
      }),
    );
  }

  ngOnDestroy() {
    this.focus.remove('initials');
    this.subscription.unsubscribe();
  }

  hereAllDay() {
    this.subscription.add(
      this.currentUser$.pipe(take(1)).subscribe((user) => {
        this.settingEffect.toggleKiosk();
        this.activeModal.close(user);
      }),
    );
  }

  selectPreviousInitial(user: RR.User) {
    if (user.initials === null) {
      throw new Error('initials are null');
    }
    this.initials = user.initials;
    this.submitInitials();
  }

  submitInitials() {
    this.subscription.add(
      this.sessionEffect
        .submitInitials(this.initials)
        .pipe(
          catchError((error: unknown) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              this.alert = 'Initials not recognised';
              return EMPTY;
            }
            // Other http errors are unexpected, so rethrow
            throw error;
          }),
          this.message.handleHttpErrorPipe,
        )
        .pipe(switchMap(() => this.store.select(fromSession.selectKioskUser)))
        .subscribe({
          next: (user) => {
            this.activeModal.close(user);
            if (user) {
              /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
              this.sessionHttpService.getRadPayOpenShift(user).subscribe((data) => {
                if (data.open_shift == null) {
                  const modalRefAttendance = this.modalService.open(AttendanceModalComponent);
                  const attendanceComponent: AttendanceModalComponent = modalRefAttendance.componentInstance;
                  attendanceComponent.data = data;
                }
                this.settingEffect.setUserClockedOn(data.open_shift != null);
              });
            }
          },
        }),
    );
  }

  onInitialsChanged(initials: string) {
    this.initialsChanged.next(initials);
  }

  getFeedbackFromInitials(initials: string) {
    if (this.context === 'EDITOR') {
      this.noNewFeedbacks = 0;
      this.subscription.add(
        this.feedbackService.getFeedbackStats({ initials }).subscribe((ret) => {
          this.noNewFeedbacks = ret.feedback_count;
          this.numberOfSentenceCategorisation = ret.categorisation_count;
        }),
      );
    }
  }

  cancel() {
    this.modalService.dismissAll();
    this.activeModal.dismiss('Cross click');
  }

  static open({ modal, reportId, context }: { modal: NgbModal; reportId?: number; context?: Context }) {
    const modalRef = modal.open(InitialsModalComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.context = context;
    modalRef.componentInstance.reportId = reportId;
    return modalRef;
  }
}
