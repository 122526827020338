import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type InvoiceItemCreationHttpResponse = {
  invoice: RR.Invoice;
  invoice_items: RR.InvoiceItem[];
};

@Injectable()
export class InvoiceItemHttpService {
  constructor(private http: HttpClient) {}

  create(invoiceId: number, invoiceItems: Partial<RR.InvoiceItem>[]): Observable<InvoiceItemCreationHttpResponse> {
    return this.http.post<InvoiceItemCreationHttpResponse>(`/api/invoice/${invoiceId}/invoice_items`, invoiceItems);
  }

  update(invoiceItemId: number, changes: Partial<RR.InvoiceItem>): Observable<RR.InvoiceItem> {
    return this.http.put<RR.InvoiceItem>(`/api/invoice_item/${invoiceItemId}`, changes);
  }

  delete(invoiceItemId: number): Observable<RR.Invoice> {
    // Delete invoice item will return affected invoice
    return this.http.delete<RR.Invoice>(`/api/invoice_item/${invoiceItemId}`);
  }
}
