import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { InstitutionActions } from './institution.action';

export type InstitutionState = EntityState<RR.Institution>;

const adapter = createEntityAdapter<RR.Institution>();

const initialState = adapter.getInitialState();

export const institutionReducer = createReducer(
  initialState,
  on(InstitutionActions.searchSuccess, (state: InstitutionState, { institutions }) =>
    adapter.upsertMany(institutions, state),
  ),
  on(InstitutionActions.findSuccess, (state: InstitutionState, { institution }) =>
    adapter.upsertOne(institution, state),
  ),
  on(InstitutionActions.findManySuccess, (state: InstitutionState, { institutions }) =>
    adapter.upsertMany(institutions, state),
  ),
  on(InstitutionActions.createSuccess, (state: InstitutionState, { institution }) =>
    adapter.addOne(institution, state),
  ),
  on(InstitutionActions.updateSuccess, (state: InstitutionState, { institution }) =>
    adapter.upsertOne(institution, state),
  ),
  on(InstitutionActions.deleteSuccess, (state: InstitutionState, { institution }) =>
    adapter.upsertOne({ ...institution, deleted: true }, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
