import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Notice>();
const selectFeature = (state: AppState) => state.notice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectByRoles = (companyRoleIds: string[]) =>
  createSelector(selectAll, (notices) =>
    notices
      .filter(
        (n) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          !n.recipients.length || (companyRoleIds || []).some((companyRoleId) => n.recipients.includes(companyRoleId)),
      )
      .sort((a, b) => b.id - a.id),
  );

const selectLoaded = createSelector(selectFeature, (state) => state.loaded);

export const fromNotice = {
  selectIds,
  selectEntities,
  selectAll,
  selectByRoles,
  selectLoaded,
};
