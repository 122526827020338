<!-- Dropdown for selecting doctors -->
<select
  class="form-select"
  data-cy="provider"
  [(ngModel)]="selectedProviderId"
  (change)="onSelectionChange(selectedProviderId)"
>
  <option [ngValue]="null">Please select</option>
  <option *ngFor="let p of filteredProviderNumbers" [ngValue]="p.id">
    {{ p.user_name }}
  </option>
</select>

<!-- Display provider number -->
<div *ngIf="selectedProvider" class="medicare-provider">
  <p><strong>Provider Number:</strong> {{ selectedProvider.medicare_provider_number }}</p>
</div>
