import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { fromReport } from 'app/store/report/report';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ReportHeadlineComponent } from '../../../../shared/components/report-headline/report-headline.component';
import { PatientQuestionnairesComponent } from '../../components/patient-questionnaires/patient-questionnaires.component';

@Component({
  selector: 'rr-patient-questionnaires-modal',
  templateUrl: './patient-questionnaires-modal.component.html',
  styleUrls: ['./patient-questionnaires-modal.component.css'],
  standalone: true,
  imports: [ReportHeadlineComponent, PatientQuestionnairesComponent],
})
export class PatientQuestionnairesModalComponent implements OnInit, OnDestroy {
  @Input() report_id: number;
  report: RR.Report | null;
  patient: RR.Patient | null;

  subscription = new Subscription();
  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromReport.selectReport(this.report_id)).subscribe((r) => {
        // @ts-expect-error strictFunctionTypes
        this.report = r;
      }),
    );

    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report_id))
        .pipe(
          filter((r) => !!r),
          // @ts-expect-error strictFunctionTypes
          switchMap((r) => this.store.select(fromPatient.selectPatient(r.patient_id))),
        )
        .subscribe((p) => {
          // @ts-expect-error strictNullChecks
          this.patient = p;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, report_id: number) {
    const modalRef = modalService.open(PatientQuestionnairesModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.report_id = report_id;
    return modalRef;
  }
}
