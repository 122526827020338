import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { ScanCodeActions } from 'app/store/scan-code/scan-code.action';

import { PatientQuestionSetActions } from '../patient-question-set/patient-question-set.action';

/**
 * Ngrx Actions for PatientQuestion
 *
 */
export const PatientQuestionActions = {
  findAllSuccess: createAction(
    '[PatientQuestion] Find All Success',
    props<{ patientQuestions: RR.PatientQuestion[] }>(),
  ),

  createSuccess: createAction('[PatientQuestion] Create Success', props<{ patientQuestion: RR.PatientQuestion }>()),

  updateSuccess: createAction('[PatientQuestion] Update Success', props<{ patientQuestion: RR.PatientQuestion }>()),

  updateManySuccess: createAction(
    '[PatientQuestion] Update Many Success',
    props<{ patientQuestions: RR.PatientQuestion[] }>(),
  ),

  deleteSuccess: createAction('[PatientQuestion] Delete Success', props<{ patientQuestionId: number }>()),
};

export const PatientQuestionBatchActions = {
  createSuccessBatch: createBatchAction(
    '[PatientQuestion] Create Success Batch',
    props<{
      actions: {
        // Delete patient question will return all affected templates including templates that haven't loaded
        findManyScanCodesSuccess: ReturnType<typeof ScanCodeActions.findManySuccess>;
        updatePatientQuestionSetSuccess: ReturnType<typeof PatientQuestionSetActions.updateSuccess>;
        createPatientQuestionSuccess: ReturnType<typeof PatientQuestionActions.createSuccess>;
      };
    }>(),
  ),
  deleteSuccessBatch: createBatchAction(
    '[PatientQuestion] Delete Success Batch',
    props<{
      actions: {
        // Delete patient question will return all affected templates including templates that haven't loaded
        findManyScanCodesSuccess: ReturnType<typeof ScanCodeActions.findManySuccess>;
        updatePatientQuestionSetSuccess: ReturnType<typeof PatientQuestionSetActions.updateSuccess>;
        deletePatientQuestionSuccess: ReturnType<typeof PatientQuestionActions.deleteSuccess>;
      };
    }>(),
  ),

  nestOrUnnestSuccessBatch: createBatchAction(
    '[PatientQuestion] Nest Or Unnest Success Batch',
    props<{
      actions: {
        updateParentQuestionSuccess: ReturnType<typeof PatientQuestionActions.updateSuccess>;
        updatePatientQuestionSuccess: ReturnType<typeof PatientQuestionActions.updateSuccess>;
      };
    }>(),
  ),
};
