import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { ReferrerContactMethodActions } from '../referrer-contact-method/referrer-contact-method.action';
import { ReferrerNoteCategoryActions } from '../referrer-note-category/referrer-note-category.action';
import { ReferrerNoteActions } from '../referrer-note/referrer-note.action';
import { ReferrerSearchResponse } from './referrer.service';

/**
 * Ngrx Actions for Referrer
 *
 */
export const ReferrerActions = {
  searchSuccess: createAction('[Referrer] Search Success', props<{ searchResult: ReferrerSearchResponse }>()),

  findManySuccess: createAction('[Referrer] Find Many Success', props<{ referrers: RR.Referrer[] }>()),

  findSuccess: createAction('[Referrer] Find Success', props<{ referrer: RR.Referrer }>()),

  createSuccess: createAction('[Referrer] Create Success', props<{ referrer: RR.Referrer }>()),

  updateSuccess: createAction('[Referrer] Update Success', props<{ referrer: RR.Referrer }>()),

  deleteSuccess: createAction('[Referrer] Delete Success', props<{ referrer: RR.Referrer }>()),
};

export const ReferrerBatchActions = {
  createSuccess: createBatchAction(
    '[Referrer] Create Success Batch',
    props<{
      actions: {
        createReferrerSuccess: ReturnType<typeof ReferrerActions.createSuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
      };
    }>(),
  ),
  searchSuccess: createBatchAction(
    '[Referrer] Search Success Batch',
    props<{
      actions: {
        searchReferrerSuccess: ReturnType<typeof ReferrerActions.searchSuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
        findManyReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.findManySuccess>;
        findManyReferrerNoteCategorySuccess: ReturnType<typeof ReferrerNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  findManySuccess: createBatchAction(
    '[Referrer] Find Many Success Batch',
    props<{
      actions: {
        findManyReferrerSuccess: ReturnType<typeof ReferrerActions.findManySuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
        findManyReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.findManySuccess>;
        findManyReferrerNoteCategorySuccess: ReturnType<typeof ReferrerNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  findSuccess: createBatchAction(
    '[Referrer] Find Success Batch',
    props<{
      actions: {
        findReferrerSuccess: ReturnType<typeof ReferrerActions.findSuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
        findManyReferrerNoteSuccess: ReturnType<typeof ReferrerNoteActions.findManySuccess>;
        findManyReferrerNoteCategorySuccess: ReturnType<typeof ReferrerNoteCategoryActions.findManySuccess>;
      };
    }>(),
  ),
  updateSuccess: createBatchAction(
    '[Referrer] Update Success Batch',
    props<{
      actions: {
        updateReferrerSuccess: ReturnType<typeof ReferrerActions.updateSuccess>;
        findManyReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
      };
    }>(),
  ),
};
