import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromProviderNumber, ProviderNumberEffect } from 'app/store/provider-number';
import { Subscription, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { InvoiceLinkReportModalComponent } from '../../modals/invoice-link-report-modal/invoice-link-report-modal.component';
import { LinkMedipassInvoiceComponent } from '../../modals/link-medipass-invoice/link-medipass-invoice.component';
import { InvoiceFormEditComponent } from './invoice-form-edit/invoice-form-edit.component';
import { InvoiceReportListComponent } from './invoice-report-list/invoice-report-list.component';

@Component({
  standalone: true,
  imports: [CommonModule, InvoiceFormEditComponent, InvoiceReportListComponent],
  selector: 'rr-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.css'],
})
export class InvoiceFormComponent implements OnInit, OnDestroy {
  @ViewChild(InvoiceFormEditComponent) invoiceFormEditComponent: InvoiceFormEditComponent;
  @Input() reportId: number | undefined;
  @Input() bookingId: number | undefined;
  @Input() invoiceId?: number | undefined;
  @Input() reInvoice?: boolean | undefined;
  @Output() onClose = new EventEmitter();

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private providerNumberEffect: ProviderNumberEffect,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(fromProviderNumber.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.providerNumberEffect.findAll();
            } else {
              return of(null);
            }
          }),
        )
        .subscribe(),
    );
  }

  openLinkTransactionModal() {
    if (this.invoiceId) {
      LinkMedipassInvoiceComponent.open(this.modalService, this.invoiceId)
        .result.then((result) => {
          if (result) {
            this.activeModal.dismiss();
          }
        })
        .catch(() => {
          return false;
        });
    }
  }

  linkReports() {
    if (this.invoiceId) {
      InvoiceLinkReportModalComponent.open(this.modalService, this.invoiceId);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
