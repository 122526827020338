import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'billingItemLink',
})
export class BillingItemLinkPipe implements PipeTransform {
  transform(item_code: string | undefined) {
    if (!item_code) {
      return null;
    }
    const mbsUrl = new URL('http://www9.health.gov.au/mbs/search.cfm');
    mbsUrl.searchParams.append('q', item_code);
    mbsUrl.searchParams.append('sopt', 'I');

    return mbsUrl;
  }
}
