import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for BookingCodeLocator
 *
 */
export const BookingCodeLocatorActions = {
  findAllSuccess: createAction(
    '[BookingCodeLocator] Find All Success',
    props<{ bookingCodeLocators: RR.BookingCodeLocator[] }>(),
  ),

  createSuccess: createAction(
    '[BookingCodeLocator] Create Success',
    props<{ bookingCodeLocator: RR.BookingCodeLocator }>(),
  ),

  updateSuccess: createAction(
    '[BookingCodeLocator] Update Success',
    props<{ bookingCodeLocator: RR.BookingCodeLocator }>(),
  ),

  deleteSuccess: createAction('[BookingCodeLocator] Delete Success', props<{ bookingCodeLocatorId: number }>()),
};
