import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SelectorService } from 'app/core/services/selector.service';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { VoiceRecognitionComponent } from 'app/shared/components/voice-recognition/voice-recognition/voice-recognition.component';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { FeedbackEffect, fromFeedback } from 'app/store/report/feedback';
import { fromUser } from 'app/store/user/user';
import { Observable, Subscription, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    UserHeadlineComponent,
    VoiceDirective,
    VoiceRecognitionComponent,
    SharedModule, // For SelectPipe
  ],
  selector: 'rr-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.css'],
})
export class SendFeedbackComponent implements OnInit, OnDestroy {
  @Input() selectedUserId: number;
  @Input() reportId: number;
  @Input() @BindObservable() recipientId: number;
  recipientId$: Observable<number>;

  feedbackForm = new FormGroup({
    id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    sender_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    recipient_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    report_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    note: new FormControl('', { nonNullable: true }),
    rate: new FormControl<number | null>(null), // allow null here because <ngb-rating> sets it to null initially
  });

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private selectorService: SelectorService,
    private feedbackEffect: FeedbackEffect,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.feedbackForm.controls.report_id.setValue(this.reportId);

    this.subscription.add(
      this.recipientId$.subscribe((id) => {
        this.feedbackForm.patchValue({ recipient_id: id });
      }),
    );

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((currentUser) => {
        if (currentUser) {
          this.feedbackForm.patchValue({ sender_id: currentUser.id });
        }
      }),
    );

    this.subscription.add(
      this.recipientId$
        .pipe(switchMap((uid) => this.store.select(fromFeedback.selectUserFeedbacksInReport(uid, this.reportId))))
        .subscribe((feedbacks) => {
          // Find the first valid feedback. A user should have only 1 feedback per report
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          const feedback = feedbacks.find((f) => !!f);
          if (feedback) {
            this.feedbackForm.patchValue({
              id: feedback.id,
              note: feedback.note || '',
              rate: feedback.rate,
            });
          } else {
            // Reset form to its initial values
            this.feedbackForm.controls.id.reset();
            this.feedbackForm.controls.note.reset();
            this.feedbackForm.controls.rate.reset();
          }
        }),
    );
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.feedbackForm.controls.note.setValue(this.feedbackForm.controls.note.value + event.detail.term + ' ');
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  userSelectorFn = (userId: number) => {
    return this.store.select(fromUser.selectUser(userId));
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit() {
    if (this.feedbackForm.controls.id.value) {
      // Update feedback
      this.subscription.add(
        this.feedbackEffect
          .update(this.feedbackForm.controls.id.value, {
            sender_id: this.feedbackForm.controls.sender_id.value ?? undefined,
            recipient_id: this.feedbackForm.controls.recipient_id.value ?? undefined,
            report_id: this.feedbackForm.controls.report_id.value,
            note: this.feedbackForm.controls.note.value,
            rate: this.feedbackForm.controls.rate.value,
            checked: false,
          })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update feedback successfully!',
              type: 'success',
            });
          }),
      );
    } else {
      // Create new feedback
      this.subscription.add(
        this.feedbackEffect
          .create({
            sender_id: this.feedbackForm.controls.sender_id.value ?? undefined,
            recipient_id: this.feedbackForm.controls.recipient_id.value ?? undefined,
            report_id: this.feedbackForm.controls.report_id.value,
            note: this.feedbackForm.controls.note.value,
            rate: this.feedbackForm.controls.rate.value,
          })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Send feedback successfully!',
              type: 'success',
            });
          }),
      );
    }
  }
}
