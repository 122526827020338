import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { ReferrerNoteState } from './referrer-note.reducer';

const adapter = createEntityAdapter<RR.ReferrerNote>();
const selectFeature = (state: AppState) => state.referrer_note;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectReferrerNote = (id: number) =>
  createSelector(selectEntities, (referrer_notes: Dictionary<RR.ReferrerNote>) => referrer_notes[id]);

const selectCount = createSelector(selectFeature, (state: ReferrerNoteState) => state.count);

export const fromReferrerNote = {
  selectIds,
  selectEntities,
  selectAll,
  selectReferrerNote,
  selectCount,
};
