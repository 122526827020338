import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { AttributeSetActions } from './attribute-set.action';

export interface AttributeSetState extends EntityState<RR.AttributeSet> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.AttributeSet>();

const initialState: AttributeSetState = adapter.getInitialState({
  loaded: false,
});

export const attributeSetReducer = createReducer(
  initialState,
  on(AttributeSetActions.findSuccess, (state, { attributeSet }) => adapter.upsertOne(attributeSet, state)),
  on(AttributeSetActions.findAllSuccess, (state, { attributeSets }) => ({
    ...adapter.setAll(attributeSets, state),
    loaded: true,
  })),
  on(AttributeSetActions.createSuccess, (state, { attributeSet }) => adapter.addOne(attributeSet, state)),
  on(AttributeSetActions.updateSuccess, (state, { attributeSet }) => adapter.upsertOne(attributeSet, state)),
  on(
    AttributeSetActions.reset,
    EditorActions.open,
    EditorActions.close,
    SessionActions.logoutSuccess,
    () => initialState,
  ),
);
