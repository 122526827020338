import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { PatientAgeSexPipe } from 'app/shared/pipes/patient-age-sex-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, PatientAgeSexPipe, SharedModule],
  selector: 'rr-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css'],
})
export class PatientInfoComponent implements OnInit {
  @Input() @BindObservable() patientId: number;
  patientId$: Observable<number>;

  @Input() strong = true;
  @Input() patientInfo = true;
  @Input() patientNumber = true;
  @Input() editPatient = false;

  patient$: Observable<RR.Patient | undefined>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.patient$ = this.patientId$.pipe(
      switchMap((id) => {
        if (id) {
          return this.store.select(fromPatient.selectPatient(id));
        }
        return of(undefined);
      }),
    );
  }
}
