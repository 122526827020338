<div class="modal-header">
  <div>
    <h5 class="modal-title">Demographics (D)</h5>
    <rr-report-headline [reportId]="report.id"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" rrTooltip="NAVIGATOR_CLOSE_BUTTON" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div>
    <div class="d-flex flex-row w-100 justify-content-between align-items-center">
      <!--Patient name and web viewer links-->
      <div class="d-flex gap-1">
        <ng-container>
          <button
            *ngIf="parent === 'EDITOR'"
            class="btn btn-sm"
            [ngClass]="
              !!report.patient_answer_ids.length || !!(patient$ | async)?.patient_answer_ids?.length
                ? 'btn-success'
                : 'btn-info'
            "
            (click)="openRegistrationModal()"
            role="button"
            rrTrack="Registration - Demographics"
          >
            <span class="text-nowrap">Registration (Q)</span>
          </button>
          <button
            class="btn btn-sm"
            [ngClass]="
              !!report.patient_answer_ids.length || !!(patient$ | async)?.patient_answer_ids?.length
                ? 'btn-success'
                : 'btn-info'
            "
            (click)="openPatientQuestionnaireModal()"
            rrTooltip="OPEN_PATIENT_QUESTIONNAIRE"
          >
            <span class="fa fa-question-circle"></span> Patient Questions (Y)
          </button>
          <button
            class="btn btn-sm btn-primary text-nowrap"
            *ngIf="report.image_url == null || report.image_url2 == null"
            (click)="getImageUrls()"
            data-no-bubble
          >
            <span class="fa fa-sync"></span> Load Image links
          </button>
          <rr-zero-fp-images [report]="report"></rr-zero-fp-images>
          <rr-zero-fp-forms [report]="report"></rr-zero-fp-forms>
        </ng-container>
        <rr-open-voyager-button [report_id]="report.id" [expand]="false"></rr-open-voyager-button>
        <button
          role="button"
          class="btn btn-sm btn-primary ms-1"
          rrTooltip="SIDE_CHECK_REPORT"
          rrTrack="Check Report - Demographics"
          (click)="openCheckReport()"
        >
          Check Report (C)
        </button>
      </div>
      <!--Report date-->
      <rr-report-date [reportId]="report.id"></rr-report-date>
    </div>
  </div>

  <!--Main button div-->
  <div class="d-flex w-100 flex-wrap my-2">
    <div class="d-flex flex-wrap align-items-center w-100 gap-1" *ngIf="topic">
      <!--Sign button-->
      <button
        *ngIf="parent === 'EDITOR'"
        class="btn btn-sm"
        [ngClass]="topic.signature_text ? 'btn-success' : 'btn-outline-dark'"
        role="button"
        (click)="openSignature()"
        [disabled]="(audit | async) || !(isDoctorEditing() | async)"
        rrTooltip="OPEN_SIGNATURE"
        rrTrack
      >
        <ng-container *ngIf="!topic?.signature_text">
          <span class="fa fa-file-signature"></span>
          Sign
        </ng-container>
        <ng-container *ngIf="topic?.signature_text">
          <i class="fa fa-check-circle"></i>
          Signed
        </ng-container>
      </button>

      <!--Editing user-->
      <div class="d-inline-flex align-items-center ms-auto" *ngIf="editing_users.length > 0">
        <i class="fa fa-user-edit"></i>
        <span class="ms-1">{{ getEditingUsers() }}</span>
      </div>
      <!--Attachment modal-->
      <rr-document-attachment-button
        [bookingId]="report.booking_id"
        [size]="'sm'"
        [btnClassName]="'btn-info'"
        [dismissActiveModal]="false"
        data-no-bubble
      ></rr-document-attachment-button>
      <!--Notes Modal-->
      <rr-report-notes-button [report_id]="report.id"> </rr-report-notes-button>
      <button
        *ngIf="parent === 'EDITOR' && topic && (hasPriorRelatedTopic() | async)"
        class="btn btn-warning btn-sm"
        (click)="importPriorRelatedTopicToPrefill()"
        rrTooltip="IMPORT_TO_PREFILL_LATEST_STUDY"
        rrTrack
        data-no-bubble
      >
        <i class="fas fa-file-import"></i>
        <span>Prior Study</span>
      </button>
      <!--Urgent-->
      <rr-urgent-button [reportId]="report.id"></rr-urgent-button>
      <!--Super Urgent-->
      <rr-super-urgent-button [reportId]="report.id"></rr-super-urgent-button>

      <button
        class="btn btn-sm btn-secondary"
        *ngIf="parent === 'EDITOR'"
        (click)="openMetadataModal(); $event.stopPropagation()"
        rrTooltip="EDIT_PATIENT_DETAILS"
        rrTrack
        permission
        [permission-only]="['data_management']"
        [permission-user]="(currentUser$ | async) || undefined"
      >
        <i class="fas fa-user-edit"></i>
        <span class="ms-1">Edit Accession</span>
      </button>

      <!--Clipboard sentence-->
      <ng-template #clipboardPopover>
        <ng-container *ngIf="{ text: getClipboardSentence() | async } as clipboard">
          <div data-no-bubble [class.text-warning]="!clipboard.text">
            {{ clipboard.text ? clipboard.text : 'No data' }}
          </div>
        </ng-container>
      </ng-template>
      <button
        *ngIf="parent === 'EDITOR'"
        [ngbPopover]="clipboardPopover"
        popoverTitle="Clipboard (click to close)"
        [autoClose]="'inside'"
        placement="bottom"
        role="button"
        class="btn btn-secondary btn-sm"
        rrTooltip="EDITOR_CLIPBOARD"
        rrTrack
        container="body"
        data-no-bubble
      >
        <i class="fas fa-paste"></i> Clipboard
      </button>
      <rr-report-status-badge [report]="report"></rr-report-status-badge>
      <!--To be removed eventually-->
      <button
        *ngIf="parent === 'EDITOR'"
        class="btn btn-sm btn-info"
        (click)="toggleSidebar()"
        rrTooltip="OPEN_SIDEBAR"
        rrTrack
        role="button"
      >
        <span class="text-nowrap"><span class="fas fa-compass"></span>Open Sidebar (!)</span>
      </button>
    </div>
  </div>
  <!-- Note from editor component: Always show compress view for Off-site technician -- is this still relevant??-->

  <!--Main content-->
  <rr-patient-detail *ngIf="topic" class="no-shrink" [report]="report" [topic_id]="topic.id" [parent]="parent">
  </rr-patient-detail>
</div>
