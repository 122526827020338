import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ProviderNumberHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.ProviderNumber[]> {
    return this.http.get<RR.ProviderNumber[]>('/api/medicare_provider');
  }
}
