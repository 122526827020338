import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { RouterHistoryService } from 'app/core/services/router-history.service';
import { SelectorService } from 'app/core/services/selector.service';
import { VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { fromUserSetting, UserSettingEffect } from 'app/store/user/user-setting';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ShortcutsComponent } from '../../../../modules/editor/shortcuts/shortcuts.component';
import { DynamicLinkDirective } from '../../../../shared/directives/dynamic-link.directive';
import { PermissionDirective } from '../../../../shared/directives/permission.directive';
import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { IssueComponent } from '../issue/issue.component';
import { SessionDropdownComponent } from '../session-dropdown/session-dropdown.component';
import { WorklistLinkComponent } from '../worklist-link/worklist-link.component';

@Component({
  selector: 'rr-navbar-buttons',
  templateUrl: 'navbar-buttons.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TooltipDirective,
    RouterLink,
    DynamicLinkDirective,
    PermissionDirective,
    WorklistLinkComponent,
    FormsModule,
    IssueComponent,
    ShortcutsComponent,
    SessionDropdownComponent,
  ],
})
export class NavbarButtonsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  version$: Observable<string>;
  currentUser$: Observable<RR.User | undefined>;
  @Input() isEditorPage: boolean = false;
  voiceMode: boolean;
  userSetting: RR.UserSetting;

  constructor(
    private store: Store<AppState>,
    private editorService: EditorService,
    private selectorService: SelectorService,
    private userSettingEffect: UserSettingEffect,
    private voiceService: VoiceRecognitionService,
    protected routerHistoryService: RouterHistoryService,
  ) {
    this.currentUser$ = this.selectorService.selectLoadedCurrentUser().pipe();
    this.version$ = store.select(fromSession.selectRRConfig).pipe(
      filterDefined(),
      map((config) => config.version),
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.currentUser$
        .pipe(filterDefined())
        .pipe(switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))))
        .subscribe((userSetting) => {
          if (userSetting) {
            this.userSetting = userSetting;
            this.voiceMode = userSetting.voice_recognition_features;
          }
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleSCMode() {
    this.editorService.emitToggleSCModeEvent();
  }

  toggleVoiceMode() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.userSetting) {
      this.subscription.add(
        this.userSettingEffect
          .update(this.userSetting.id, {
            voice_recognition_features: !this.userSetting.voice_recognition_features,
          })
          .subscribe(),
      );

      this.voiceService.stopListening();
    }
  }
}
