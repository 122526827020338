import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Favourite>();
const selectFeature = (state: AppState) => state.favourite;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectById = (id: number) => createSelector(selectEntities, (entities) => entities[id]);
export const fromFavourite = {
  selectIds,
  selectEntities,
  selectAll,
  selectById,
};
