import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type AttributeGroup = {
  /**
   * Only data (`default_attributes`) for the first Template (`template_ids[0]`) is returned.
   */
  template_ids: number[];
  attribute_group: number[];
};

export type DefaultAttributesByStatement = {
  default_attributes: RR.DefaultAttribute[];
  attribute_options: RR.AttributeOption[];
  statement: RR.Statement;
  usages: AttributeGroup[];
};

@Injectable()
export class DefaultAttributeHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.DefaultAttribute[]> {
    return this.http.get<RR.DefaultAttribute[]>('/api/default_attribute');
  }

  create(region: Partial<RR.DefaultAttribute>): Observable<RR.DefaultAttribute> {
    return this.http.post<RR.DefaultAttribute>('/api/default_attribute', region);
  }

  update(defaultAttributeId: number, changes: Partial<RR.DefaultAttribute>): Observable<RR.DefaultAttribute> {
    return this.http.put<RR.DefaultAttribute>(`/api/default_attribute/${defaultAttributeId}`, changes);
  }

  delete(defaultAttributeId: number): Observable<void> {
    return this.http.delete<void>(`/api/default_attribute/${defaultAttributeId}`);
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number): Observable<RR.DefaultAttribute[]> {
    return this.http.get<RR.DefaultAttribute[]>(`/api/template/${templateId}/default_attribute`);
  }

  findInStatementSet(templateId: number, statementSetId: number): Observable<RR.DefaultAttribute[]> {
    return this.http.get<RR.DefaultAttribute[]>(
      `/api/template/${templateId}/statement_set/${statementSetId}/default_attribute`,
    );
  }

  findByStatement(statementId: number): Observable<DefaultAttributesByStatement> {
    return this.http.get<DefaultAttributesByStatement>(`/api/statement/${statementId}/default_attribute`);
  }
}
