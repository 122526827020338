import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ReportService } from 'app/core/services/report.service';
import { FinalPreviewModalComponent } from 'app/modules/report/components/preview/final-preview-modal/final-preview-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store/app.state';
import { fromTopic } from 'app/store/report/topic';
import { fromTemplate } from 'app/store/template/template';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

import { OBSTETRIC_TEMPLATE_IDS } from '../../worklist.constants';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'rr-worklist-topic-column',
  templateUrl: 'worklist-topic-column.component.html',
  styleUrls: ['worklist-topic-column.component.scss'],
})
export class WorklistTopicColumnComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  @Input() columnType: 'STUDY_TYPE' | 'REPORT_TITLE';
  @Input() showSearchByReportTitle: boolean = true;
  @Input() showSearchByStudyType: boolean = true;
  @Input() showFinalPreviewBtn: boolean = true;
  @Output() searchByTitleOption = new EventEmitter<{ titleOptionId: number; titleOptionText: string }>();
  @Output() searchByStudyOption = new EventEmitter<RR.Template>();
  topic$: Observable<RR.Topic>;
  template$: Observable<RR.Template | undefined>;
  report$: Observable<RR.Report>;

  subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private reportService: ReportService,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    // We don't get the topicId until the initialisation, so we initialise this value
    // here.
    this.topic$ = this.store
      .select(fromTopic.selectTopic(this.topic_id))
      // We are using skipWhile here since once the values are loaded, we always want
      // the current value and they shouldn't be deleted.
      // @ts-expect-error strictNullChecks
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .pipe(skipWhile((topic: RR.Topic) => topic == null));
    this.template$ = this.store
      .select(fromTemplate.selectTemplateFromTopic(this.topic_id))
      // We are using skipWhile here since once the values are loaded, we always want
      // the current value and they shouldn't be deleted.
      .pipe(skipWhile((template) => template == null));
  }

  clipboardView(topic: RR.Topic) {
    this.subscription.add(
      this.reportService
        .findTopic(topic.id)
        .pipe(take(1))
        .subscribe((topic) => {
          FinalPreviewModalComponent.open(this.modalService, {
            topic_id: topic.id,
            report_id: topic.report_id,
            mode: 'worklist',
          });
        }),
    );
  }

  isTopicObstetric(): boolean {
    let returnVal: boolean;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.topic$.pipe(take(1)).subscribe((topic) => {
      returnVal = OBSTETRIC_TEMPLATE_IDS.has(topic.template_id);
    });
    // @ts-expect-error strictNullChecks
    return returnVal;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
