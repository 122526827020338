import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { VoiceRecognitionComponent } from 'app/shared/components/voice-recognition/voice-recognition/voice-recognition.component';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { PatientEffect, fromPatient } from 'app/store/patient';
import { FeedbackCommentEffect, fromFeedbackComment } from 'app/store/report/feedback-comment';
import { fromReport } from 'app/store/report/report';
import { fromUser } from 'app/store/user/user';
import { distinctUntilChanged, EMPTY, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { ReportDateComponent } from '../../../../shared/components/report-date/report-date.component';
import { ZeroFPImagesComponent } from '../../../../shared/components/zero-fp-images/zero-fp-images.component';
import { AccessEventListComponent } from '../../../worklist/components/access-event-list/access-event-list.component';
import { ReportTextDiffComponent } from '../report-text-diff/report-text-diff.component';

@Component({
  standalone: true,
  selector: 'rr-user-feedback-detail',
  templateUrl: './user-feedback-detail.component.html',
  styleUrls: ['./user-feedback-detail.component.css'],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbRatingModule,
    RouterModule,
    StoreSelectPipe,
    ReportTextDiffComponent,
    VoiceRecognitionComponent,
    VoiceDirective,
    UserHeadlineComponent,
    ZeroFPImagesComponent,
    AccessEventListComponent,
    ReportDateComponent,
  ],
})
export class UserFeedbackDetailComponent implements OnInit, OnDestroy {
  @Input() user: RR.User;
  @Input() @BindObservable() feedback: RR.Feedback;
  feedback$: Observable<RR.Feedback>;
  report: RR.Report | undefined;
  subscription = new Subscription();

  feedbackCommentText = new FormControl('', { nonNullable: true });
  feedbackComments: RR.FeedbackComment[] = [];

  constructor(
    private feedbackCommentEffect: FeedbackCommentEffect,
    private store: Store<AppState>,
    private patientEffect: PatientEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.feedback$
        .pipe(
          map((f) => f.id),
          distinctUntilChanged(),
          switchMap((id) => this.store.select(fromFeedbackComment.selectInFeedback(id))),
        )
        .subscribe((comments) => {
          this.feedbackComments = comments;
        }),
    );

    const report$ = this.feedback$.pipe(
      map((f) => f.report_id),
      distinctUntilChanged(),
      switchMap((reportId) => this.store.select(fromReport.selectReport(reportId))),
    );

    this.subscription.add(
      report$.subscribe((report) => {
        this.report = report;
      }),
    );

    const patientLoaded$ = report$.pipe(
      switchMap((report) => {
        if (report && report.patient_id) {
          return this.store
            .select(fromPatient.selectPatient(report.patient_id))
            .pipe(map((patient) => ({ loaded: patient !== undefined, patientId: report.patient_id })));
        }
        return of({ loaded: true, patientId: null });
      }),
    );

    this.subscription.add(
      patientLoaded$
        .pipe(
          switchMap(({ loaded, patientId }) => {
            if (!loaded && patientId) {
              return this.patientEffect.findById(patientId);
            }
            return EMPTY;
          }),
        )
        .subscribe(),
    );
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.feedbackCommentText.setValue(this.feedbackCommentText.value + event.detail.term + ' ');
  }

  submitComment(feedback_id: number) {
    this.subscription.add(
      this.feedbackCommentEffect
        .create({
          user_id: this.user.id,
          feedback_id,
          comment_text: this.feedbackCommentText.value,
        })
        .pipe(take(1))
        .subscribe(() => {
          this.feedbackCommentText.reset();
        }),
    );
  }

  userSelectorFn = fromUser.selectUser;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
