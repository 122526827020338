import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { ReferrerEffect } from 'app/store/referrer';
import { SoftwarePreferenceEffect, fromSoftwarePreference } from 'app/store/software-preferences';
import { Observable, Subscription } from 'rxjs';

declare interface SoftwarePreferenceForm {
  selected: FormControl<boolean>;
  softwarePref: FormControl<RR.SoftwarePreference>;
}

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, NgbModule, ReactiveFormsModule],
  selector: 'rr-software-preferences',
  templateUrl: './software-preferences.component.html',
  styleUrls: ['./software-preferences.component.scss'],
})
export class SoftwarePreferencesComponent implements OnDestroy, OnInit {
  @Input() @BindObservable() referrer: RR.Referrer;
  referrer$: Observable<RR.Referrer>;
  subscription = new Subscription();
  softwarePrefs: RR.SoftwarePreference[] = [];

  softwareName = new FormControl<string | undefined>(undefined, {
    nonNullable: true,
    validators: [Validators.required],
  });

  softwarePreferencesForm = new FormArray<FormGroup<SoftwarePreferenceForm>>([]);

  constructor(
    private softwarePreferenceEffect: SoftwarePreferenceEffect,
    private messageService: MessageService,
    private store: Store<AppState>,
    private referrerEffect: ReferrerEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromSoftwarePreference.selectAll).subscribe((softwarePrefs) => {
        this.softwarePrefs = softwarePrefs;
      }),
    );

    this.subscription.add(
      this.referrer$.subscribe((referrer) => {
        this.createForm(referrer, this.softwarePrefs);
      }),
    );
  }

  createForm(referrer: RR.Referrer, softwarePrefs: RR.SoftwarePreference[]) {
    this.softwarePreferencesForm.clear();
    softwarePrefs.map((softwarePref) =>
      this.softwarePreferencesForm.push(
        new FormGroup({
          selected: new FormControl<boolean>(referrer.software_preference_ids.includes(softwarePref.id), {
            nonNullable: true,
          }),
          softwarePref: new FormControl<RR.SoftwarePreference>(softwarePref, { nonNullable: true }),
        }),
      ),
    );
  }

  updateReferrer() {
    const selectedPrefs: number[] = this.softwarePreferencesForm.controls
      .map((group: FormGroup<SoftwarePreferenceForm>) => {
        if (group.controls.selected.value) {
          return group.controls.softwarePref.value.id;
        }
        return undefined;
      })
      .filter((id): id is number => typeof id === 'number');

    this.subscription.add(
      this.referrerEffect.update(this.referrer.id, { software_preference_ids: selectedPrefs }).subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: 'Referrer Updated',
            type: 'success',
          });
        },
        error: (error: unknown) => {
          this.messageService.httpErrorMessage(error);
        },
      }),
    );
  }

  // updatePref(id: number, changes: Partial<SoftwarePreference>) {
  //   this.subscription.add(
  //     this.SoftwarePreferenceEffect.update(id, changes).subscribe({
  //       next: () => {
  //         this.messageService.add({
  //           title: 'Success',
  //           message: 'Software Preference Updated',
  //           type: 'success',
  //         });
  //       },
  //       error: (error: unknown) => {
  //         if (error instanceof HttpErrorResponse) {
  //           this.messageService.httpErrorMessage(error);
  //         }
  //       },
  //     }),
  //   );
  // }

  createPref() {
    this.subscription.add(
      this.softwarePreferenceEffect.create({ software_name: this.softwareName.value }).subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: 'Software Preference Created',
            type: 'success',
          });

          this.softwareName.reset();
        },
        error: (error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            this.messageService.httpErrorMessage(error);
          }
        },
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
