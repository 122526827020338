import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.SubsectionChoice>();
const selectFeature = (state: AppState) => state.subsection_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectSubsectionChoice = (id: number) =>
  createSelector(selectEntities, (subsectionChoices: Dictionary<RR.SubsectionChoice>) => subsectionChoices[id]);

export const fromSubsectionChoice = {
  selectEntities,
  selectAll,
  selectIds,
  selectSubsectionChoice,
};
