import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromStatementChoice } from '../statement-choice/statement-choice.selector';

const adapter = createEntityAdapter<RR.ElementChoice>();
const selectFeature = (state: AppState) => state.element_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectElementChoice = (elementChoiceId: number) =>
  createSelector(selectEntities, (elementChoices: Dictionary<RR.ElementChoice>) => elementChoices[elementChoiceId]);

const selectStatementChoices = (elementChoiceId: number) =>
  createSelector(
    selectElementChoice(elementChoiceId),
    fromStatementChoice.selectEntities,
    (elementChoice, statementChoices) => {
      return (
        elementChoice?.statement_choices
          .map((statementChoiceId) => statementChoices[statementChoiceId])
          .filter((c): c is RR.StatementChoice => !!c) ?? []
      );
    },
  );

const selectStatementChoicesByStatementId = (elementChoiceId: number, statementId: number) =>
  createSelector(selectStatementChoices(elementChoiceId), (statementChoices) => {
    return statementChoices.filter(
      (statementChoice): statementChoice is RR.FullStatementChoice => statementChoice.statement_id === statementId,
    );
  });

export const fromElementChoice = {
  selectEntities,
  selectIds,
  selectAll,
  selectElementChoice,
  selectStatementChoices,
  selectStatementChoicesByStatementId,
};
