import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SubsectionChoiceActions } from './subsection-choice.action';

export type SubsectionChoiceState = EntityState<RR.SubsectionChoice>;

const adapter = createEntityAdapter<RR.SubsectionChoice>();

const initialState = adapter.getInitialState();

export const subsectionChoiceReducer = createReducer(
  initialState,
  on(SubsectionChoiceActions.addMany, (state, { subsectionChoices }) => adapter.addMany(subsectionChoices, state)),
  on(SubsectionChoiceActions.upsertMany, (state, { subsectionChoices }) =>
    adapter.upsertMany(subsectionChoices, state),
  ),
  on(SubsectionChoiceActions.removeMany, (state, { subsectionChoiceIds }) =>
    adapter.removeMany(subsectionChoiceIds, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
