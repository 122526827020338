import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BodyPartHttpService {
  constructor(private http: HttpClient) {}

  create(bodyPart: Partial<RR.BodyPart>): Observable<RR.BodyPart> {
    return this.http.post<RR.BodyPart>('/api/body_part', bodyPart);
  }

  findAll(): Observable<RR.BodyPart[]> {
    return this.http.get<RR.BodyPart[]>('/api/body_part');
  }

  search(search: string): Observable<RR.BodyPart[]> {
    return this.http.get<RR.BodyPart[]>(`/api/body_part/search?q=${search}`);
  }

  update(bodyPartId: number, changes: Partial<RR.BodyPart>): Observable<RR.BodyPart> {
    return this.http.put<RR.BodyPart>(`/api/body_part/${bodyPartId}`, changes);
  }

  delete(bodyPartId: number): Observable<RR.BodyPart> {
    return this.http.delete<RR.BodyPart>(`/api/body_part/${bodyPartId}`);
  }
}
