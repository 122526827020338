import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'rr-phone-fax-input',
  templateUrl: './phone-fax-input.component.html',
  styleUrls: ['./phone-fax-input.component.scss'],
})
export class PhoneFaxInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() invalid: boolean | null;
  subscription = new Subscription();

  formControl: FormControl;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onChangeFn = (_: string) => {
    // do nothing
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onTouchedFn = () => {
    // do nothing
  };

  // These are just to make Angular happy. Not needed since the control is passed to the child input
  writeValue() {
    // do nothing
  }

  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  // When the element is touched, this method will get called
  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
  }

  ngOnInit() {
    // From https://stackoverflow.com/questions/47377411/wrapping-a-formcontrol-in-angular-2
    if (this.ngControl instanceof FormControlName) {
      const formGroupDirective = this.ngControl.formDirective as FormGroupDirective;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (formGroupDirective && this.ngControl.name !== null) {
        this.formControl = formGroupDirective.form.controls[this.ngControl.name] as FormControl;
      }
    } else if (this.ngControl instanceof FormControlDirective) {
      this.formControl = this.ngControl.control;
    }

    // Strip out non-numeric characters
    this.subscription.add(
      this.formControl.valueChanges.subscribe((value) => {
        const newValue = value?.replace(/\D/g, '') ?? '';
        if (newValue !== value) {
          // Prevents infinite loop
          this.formControl.setValue(newValue);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
