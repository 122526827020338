import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BookingCodeLocatorHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.BookingCodeLocator[]> {
    return this.http.get<RR.BookingCodeLocator[]>('/api/booking_code_locator');
  }

  create(bookingCodeLocator: Partial<RR.BookingCodeLocator>): Observable<RR.BookingCodeLocator> {
    return this.http.post<RR.BookingCodeLocator>('/api/booking_code_locator', bookingCodeLocator);
  }

  update(bookingCodeLocatorId: number, changes: Partial<RR.BookingCodeLocator>): Observable<RR.BookingCodeLocator> {
    return this.http.put<RR.BookingCodeLocator>(`/api/booking_code_locator/${bookingCodeLocatorId}`, changes);
  }

  delete(bookingCodeLocatorId: number) {
    return this.http.delete<RR.BookingCodeLocator>(`/api/booking_code_locator/${bookingCodeLocatorId}`);
  }
}
