import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Category>();
const selectFeature = (state: AppState) => state.category;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectCategory = (categoryId: number) =>
  createSelector(selectEntities, (categories: Dictionary<RR.Category>) => categories[categoryId]);

export const fromCategory = {
  selectIds,
  selectEntities,
  selectAll,
  selectCategory,
};
