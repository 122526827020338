import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';

const adapter = createEntityAdapter<RR.AttributeOption>();
const selectFeature = (state: AppState) => state.attribute_option;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectAttributeOption = (attributeOptionId: number) =>
  createSelector(
    selectEntities,
    (attributeOption: Dictionary<RR.AttributeOption>) => attributeOption[attributeOptionId],
  );

export const fromAttributeOption = {
  selectIds,
  selectEntities,
  selectAll,
  selectAttributeOption,
};
