import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type CreateReferrerContactMethodResponse = {
  referrer: RR.Referrer;
  referrer_contact_method: RR.ReferrerContactMethod;
};

export type DeleteReferrerContactMethodResponse = {
  referrer: RR.Referrer;
};

export interface ESReferrerBody extends Partial<RR.Referrer> {
  query?: string;
}

@Injectable()
export class ReferrerContactMethodHttpService {
  constructor(private http: HttpClient) {}

  create(referrerContactMethod: Partial<RR.ReferrerContactMethod>): Observable<CreateReferrerContactMethodResponse> {
    return this.http.post<CreateReferrerContactMethodResponse>('/api/referrer_contact_method', referrerContactMethod);
  }

  delete(referrerContactMethodId: number): Observable<DeleteReferrerContactMethodResponse> {
    return this.http.delete<DeleteReferrerContactMethodResponse>(
      `/api/referrer_contact_method/${referrerContactMethodId}`,
    );
  }
}
