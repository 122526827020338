<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div
    *ngFor="let statement_choice of choices; let first = first; let last = last; let i = index; trackBy: trackByFn"
    cdkDrag
    [class.disabled]="disabledDragging"
    [cdkDragDisabled]="disabledDragging"
  >
    <rr-choice-preview
      [first]="first"
      [last]="last"
      [choice]="statement_choice"
      [region_choice]="region_choice"
      [subsection_choice]="subsection_choice"
      [section_choice]="section_choice"
      [element_choice]="element_choice"
      [mode]="mode"
      [template_id]="topic.template_id"
    >
    </rr-choice-preview>
  </div>
</div>
