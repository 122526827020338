import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { ModalAuthComponent } from 'app/shared/modals/modal-auth/modal-auth.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';

import { ReportHeadlineComponent } from '../../../components/report-headline/report-headline.component';
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ModalAuthComponent,
    ReactiveFormsModule,
    ReportHeadlineComponent,
    AutoFocusDirective,
    // For PermissionDirective
    SharedModule,
  ],
  templateUrl: './metadata-modal.component.html',
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class MetadataModalComponent {
  report_id: number; // input
  report: RR.Report;

  // Opening modal from editor or worklist
  parent: 'EDITOR' | 'WORKLIST';

  // Accession number form
  form: FormGroup;

  subscription = new Subscription();
  formSubscriptions = new Subscription();

  // Patient is unnested from Report model but is still returned in the API schema
  otherReport?: RR.Report;
  confirm = false;
  updating = false;
  adminPassword = ''; // input password to confirm changing accession number
  confirmError = '';

  // From editor: editing user, from worklist: logged in user
  currentUser$: Observable<RR.User>;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private reportEffect: ReportEffect,
    private selectorService: SelectorService,
    private store: Store<AppState>,
  ) {}

  init() {
    this.subscription.add(
      this.reportService.getReport(this.report_id).subscribe((report) => {
        this.report = report;

        this.form = new FormGroup({
          accession_number: new FormControl(this.report.accession_number),
        });

        // Resubscribe to these subscriptions because the forms changed
        this.formSubscriptions.unsubscribe();
        this.formSubscriptions = new Subscription();
        this.formSubscriptions.add(
          this.form.valueChanges
            .pipe(
              debounceTime(300),
              switchMap((value) => {
                return this.findOtherReport(value.accession_number);
              }),
              switchMap((action) => {
                if (action && action.reportIds.length) {
                  return this.store.select(fromReport.selectReport(action.reportIds[0]));
                }
                return of(undefined);
              }),
            )
            // eslint-disable-next-line rxjs/no-nested-subscribe -- 2
            .subscribe((report) => {
              this.otherReport = report;
            }),
        );
      }),
    );

    if (this.parent === 'EDITOR') {
      // @ts-expect-error strictNullChecks
      this.currentUser$ = this.reportService.selectKioskUser();
    } else {
      // @ts-expect-error strictNullChecks
      this.currentUser$ = this.selectorService.selectLoadedCurrentUser();
    }
  }

  findOtherReport(accession_number: string) {
    if (!accession_number || accession_number === this.report.accession_number || this.updating) return of(null);

    return this.reportEffect.search(
      {
        accession_number,
      },
      new HttpParams().set('limit', '1'),
    );
  }

  updateAccessionNo() {
    this.updating = true;
    this.reportEffect
      .updateAccession(this.report.id, this.form.controls.accession_number.value)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((action) => {
        const updated_report_id = action.report.id;
        // Reset input data
        this.otherReport = undefined;
        if (this.report.id !== updated_report_id) {
          window.location.href = `/report/${updated_report_id}/edit`;
          // EditorComponent doesn't handle the report_id changing, so we can't use the router
          // this.router.navigate(['/report', updated_report_id, 'edit']);
        }
        this.resetAfterUpdate();
      });
  }

  onAuthenticated() {
    this.updateAccessionNo();
  }

  onCancel() {
    this.confirm = false;
    this.adminPassword = '';
    this.confirmError = '';
  }

  saveButtonClicked() {
    this.confirm = true;
    this.adminPassword = '';
    this.confirmError = '';
  }

  resetAfterUpdate() {
    this.updating = false;
    this.confirm = false;
    this.confirmError = '';
    this.adminPassword = '';
  }

  static open(modalService: NgbModal, report_id: number, parent: 'EDITOR' | 'WORKLIST') {
    const modalRef = modalService.open(MetadataModalComponent);
    const component: MetadataModalComponent = modalRef.componentInstance;
    component.report_id = report_id;
    component.parent = parent;
    component.init();

    return modalRef;
  }
}
