<span class="d-flex flex-nowrap" *ngIf="!editing">
  <ng-container *ngIf="!hit">{{ attribute_option.text }}</ng-container>
  <ng-container *ngIf="hit && hit.highlight">
    <span *ngFor="let highlight of hit.highlight.text" [innerHTML]="highlight"></span>
  </ng-container>
  <span *ngIf="hit && !hit.highlight" [innerHTML]="attribute_option.text"></span>

  <i *ngIf="!attribute_option.text">Blank option</i>
  <span
    class="ms-2 text-dark fw-bold"
    *ngIf="editingUser$ | async as editingUser"
    permission
    [permission-only]="['set_configuration']"
    [permission-user]="editingUser"
    >{{ frequency }}</span
  >
</span>
<div
  class="item-btns-right btn-group item-hover"
  *ngIf="!editing && !!statement_attribute && !autoShortList"
  data-no-bubble-attribute
  (mousedown)="$event.stopPropagation()"
>
  <button
    (click)="setDefaultAttributeOption()"
    [class.active]="isDefault | async"
    class="btn btn-sm btn-primary"
    role="button"
    tabindex="-1"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SET_DEFAULT"
    data-cy="default"
  >
    <span class="fa fa-star"></span>
  </button>
  <button
    *ngIf="displayAsterisk | async"
    (click)="onClickShortlist()"
    class="btn btn-sm"
    role="button"
    tabindex="-1"
    [ngClass]="attribute_half === 'top' ? 'btn-info' : 'btn-warning'"
    permission
    [permission-only]="['template_manage']"
    [title]="attribute_half === 'top' ? 'Remove from shortlist' : 'Add to top of list and choose'"
    data-cy="shortlist"
  >
    <span [class]="attribute_half === 'top' ? 'fa fa-minus-circle' : 'fa fa-asterisk'"></span>
  </button>
  <button
    *ngIf="!selected"
    (click)="editing = true"
    class="btn btn-sm btn-info"
    role="button"
    tabindex="-1"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="EDIT_ATTRIBUTE"
    data-cy="edit"
  >
    <span class="fa fa-edit"></span>
  </button>
</div>
<input
  #textinput
  *ngIf="editing"
  type="text"
  autocomplete="off"
  class="form-control form-control-sm"
  value="{{ attribute_option.text }}"
  (keydown.enter)="editAttributeOption()"
  (keydown.escape)="editing = false"
  (keyup)="$event.preventDefault()"
  (mousedown)="$event.stopPropagation()"
  data-no-bubble-attribute
  rrAutoFocus
/>
<!-- (keyup) and (mousedown) to prevent parent button from receiving a click event -->
<div
  class="item-btns-right btn-group item-hover"
  *ngIf="editing"
  data-no-bubble-attribute
  (mousedown)="$event.stopPropagation()"
>
  <button (click)="removeAttributeOption()" class="btn btn-sm btn-danger" role="button" rrTooltip="REMOVE_ATTRIBUTE">
    <span class="fa fa-trash"></span>
  </button>
  <button (click)="editing = false" class="btn btn-sm btn-danger" role="button" rrTooltip="CANCEL">
    <span class="fa fa-ban"></span>
  </button>
  <button
    (click)="editAttributeOption()"
    class="btn btn-sm btn-success"
    role="button"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SUBMIT"
  >
    <span class="fa fa-check"></span>
  </button>
</div>
