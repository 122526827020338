import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { PermissionActions } from '../../permission/permission.action';

/**
 * Ngrx Actions for Company Role
 *
 */
export const CompanyRoleActions = {
  findAllSuccess: createAction('[CompanyRole] Find All Success', props<{ companyRoles: RR.CompanyRole[] }>()),

  createSuccess: createAction('[CompanyRole] Create Success', props<{ companyRole: RR.CompanyRole }>()),

  updateSuccess: createAction('[CompanyRole] Update Success', props<{ companyRole: RR.CompanyRole }>()),

  deleteSuccess: createAction('[CompanyRole] Delete Success', props<{ companyRoleId: string }>()),
};

export const CompanyRoleBatchActions = {
  findAllSuccess: createBatchAction(
    '[CompanyRole] Find All Success Batch',
    props<{
      actions: {
        companyRoleFindAllSuccess: ReturnType<typeof CompanyRoleActions.findAllSuccess>;
        permissionFindAllSuccess: ReturnType<typeof PermissionActions.findAllSuccess>;
      };
    }>(),
  ),
};
