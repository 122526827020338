import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NO_REFERRER_TYPE_BILLING_ITEM } from 'app/app.constants';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { referrerTypeMap } from 'app/shared/utils/shared.utils';
import { Observable, Subscription, combineLatest, map } from 'rxjs';

@Component({
  selector: 'rr-billing-item-eligibility',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './billing-item-eligibility.component.html',
  styleUrl: './billing-item-eligibility.component.css',
})
export class BillingItemEligibilityComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() billingItem: RR.BillingItem;
  billingItem$: Observable<RR.BillingItem>;
  @Input() @BindObservable() referrerType: RR.ReferrerType | null | undefined;
  referrerType$: Observable<RR.ReferrerType | null | undefined>;

  subscription = new Subscription();

  eligibility$: Observable<{ isEligible: boolean; tooltip: string | null; types: string }>;

  NO_REFERRER_TYPE_BILLING_ITEM = NO_REFERRER_TYPE_BILLING_ITEM;

  constructor() {}

  ngOnInit(): void {
    this.eligibility$ = combineLatest([this.billingItem$, this.referrerType$]).pipe(
      map(([billingItem, referrerType]) => {
        const isEligible = !!referrerType && billingItem.referrer_types.some((rt) => rt === referrerType);
        const eligibleTypes = billingItem.referrer_types.map((rt) => referrerTypeMap[rt]).join(', ');

        let tooltip = NO_REFERRER_TYPE_BILLING_ITEM;
        let types = '';

        if (referrerType) {
          if (isEligible) {
            tooltip = `Eligible for this billing item`;
          } else {
            tooltip = eligibleTypes
              ? `Only ${eligibleTypes} are eligible for this billing item`
              : NO_REFERRER_TYPE_BILLING_ITEM;
          }
        } else if (billingItem.referrer_types.length > 0) {
          types = eligibleTypes;
          tooltip = '';
        }

        return { isEligible, tooltip, types };
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
