import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromRegion } from 'app/store/template/region';
import { fromStatement } from 'app/store/template/statement';

import { adapter } from './tag-choice.state';

// This selects the tag feature from the PrefillState, allowing us to further introspect
// it within this file.
const selectFeature = (state: AppState) => state.tag_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = createSelector(
  selectFeature,
  // This selects the loaded key from the TagState
  ({ loaded }) => loaded,
);

const isSelected = (tagId: string) =>
  createSelector(selectEntities, (selectedEntities: Dictionary<RR.TagChoice>) => tagId in selectedEntities);

const selectSelected = createSelector(
  selectAll,
  fromRegion.selectEntities,
  fromStatement.selectEntities,
  (tagChoices, regions, statements) =>
    tagChoices.map((tag) => ({
      ...tag,
      region_text: tag.region_id ? regions[tag.region_id]?.name : null,
      tag_text: statements[tag.tag_id]?._text,
    })),
);

export const fromTagChoice = {
  selectAll,
  isSelected,
  selectSelected,
  selectLoaded,
};
