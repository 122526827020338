import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { funders } from 'app/modules/invoice/invoice.constants';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { CreateNoteModalComponent } from 'app/shared/modals/create-note-modal/create-note-modal.component';
import { AppState } from 'app/store';
import { BillingItemReferrerTypeEffect } from 'app/store/billing-item-referrer-type/billing-item-referrer-type.effect';
import { BookingEffect, fromBooking } from 'app/store/booking';
import { BookingCodeEffect } from 'app/store/booking-code';
import { InvoiceEffect } from 'app/store/invoice';
import { fromPatient, PatientEffect } from 'app/store/patient';
import { ProviderNumberEffect } from 'app/store/provider-number';
import { fromReferrer, ReferrerEffect } from 'app/store/referrer';
import { AuditEventEffect } from 'app/store/report/audit-event';
import { AvailableForm, fromReport, ReportEffect } from 'app/store/report/report';
import { ScanCodeEffect } from 'app/store/scan-code';
import { SiteEffect } from 'app/store/site';
import { SoftwarePreferenceEffect } from 'app/store/software-preferences';
import { combineLatest, Observable, of, Subscription, tap } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith, switchMap } from 'rxjs/operators';

import { WorklistLinkComponent } from '../../../../core/toolbar-navbar/components/worklist-link/worklist-link.component';
import { DocumentationLinkButtonComponent } from '../../../../shared/components/documentation-link-button/documentation-link-button.component';
import { NotesComponent } from '../../../../shared/components/notes/notes.component';
import { ReferrerNameComponent } from '../../../../shared/components/referrer-name/referrer-name.component';
import { ReportNotesButtonComponent } from '../../../../shared/components/report-notes-button/report-notes-button.component';
import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { BookingViewSummaryComponent } from '../../../booking/components/booking-view/booking-view-summary/booking-view-summary.component';
import { RegistrationService } from '../../services/registration.service';
import { AllFormComponent } from '../all-form/all-form.component';
import { Db4FormComponent } from '../db4-form/db4-form.component';
import { DocumentAttachmentButtonComponent } from '../document-attachment-button/document-attachment-button.component';
import { PatientFormComponent } from '../patient-form/patient-form.component';
import { PatientQuestionnairesComponent } from '../patient-questionnaires/patient-questionnaires.component';
import { ReferrerFormComponent } from '../referrer-form/referrer-form.component';
import { RegistrationEventsComponent } from '../registration-events/registration-events.component';
import { ReportFormComponent } from '../report-form/report-form.component';
import { ReportInvoicesComponent } from '../report-invoices/report-invoices.component';
import { SameDayBookingsComponent } from '../same-day-bookings/same-day-bookings.component';
import { SameDayRegistrationsComponent } from '../same-day-registrations/same-day-registrations.component';

@Component({
  selector: 'rr-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [RegistrationService],
  standalone: true,
  imports: [
    CommonModule,
    DocumentAttachmentButtonComponent,
    ReportNotesButtonComponent,
    RouterLink,
    TooltipDirective,
    Db4FormComponent,
    RegistrationEventsComponent,
    DocumentationLinkButtonComponent,
    ReferrerNameComponent,
    SameDayBookingsComponent,
    BookingViewSummaryComponent,
    FormsModule,
    ReactiveFormsModule,
    PatientFormComponent,
    NotesComponent,
    SameDayRegistrationsComponent,
    ReferrerFormComponent,
    ReportFormComponent,
    ReportInvoicesComponent,
    PatientQuestionnairesComponent,
    WorklistLinkComponent,
    ReportAccessionNumberComponent,
    AllFormComponent,
  ],
})
export class RegistrationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(PatientFormComponent) patientFormComponent: PatientFormComponent;
  @ViewChild(ReferrerFormComponent) referrerFormComponent: ReferrerFormComponent;
  @ViewChild(ReportFormComponent) reportFormComponent: ReportFormComponent;

  @BindObservable() @Input() reportId: number | null;
  reportId$: Observable<number | null>;
  @BindObservable() @Input() bookingId: number | null;
  bookingId$: Observable<number | null>;
  @BindObservable() @Input() referrerId: number | null;
  referrerId$: Observable<number | null>;
  @BindObservable() @Input() patientId: number | null;
  patientId$: Observable<number | null>;

  report: RR.Report | undefined;
  patient: RR.Patient | undefined;
  referrer: RR.Referrer | undefined;
  booking: RR.Booking | undefined;
  noReferrer = false;

  // Same day bookings in case register 2nd scan from another registration
  sameDayBookingIds: number[] = [];

  loadingReport = false;
  subscription: Subscription = new Subscription();

  funders = funders;
  funder = new FormControl<RR.FunderType>('medicare', { nonNullable: true });

  availableForms: AvailableForm[] = [];

  constructor(
    private reportEffect: ReportEffect,
    private bookingEffect: BookingEffect,
    private scanCodeEffect: ScanCodeEffect,
    private auditEventEffect: AuditEventEffect,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private hotkeysService: HotkeysService,
    private title: Title,
    private store: Store<AppState>,
    private patientEffect: PatientEffect,
    private referrerEffect: ReferrerEffect,
    private providerNumberEffect: ProviderNumberEffect,
    private bookingCodeEffect: BookingCodeEffect,
    private siteEffect: SiteEffect,
    private invoiceEffect: InvoiceEffect,
    public registrationService: RegistrationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private softwarePreferenceEffect: SoftwarePreferenceEffect,
    private billingItemReferrerTypeEffect: BillingItemReferrerTypeEffect,
  ) {}

  ngOnInit(): void {
    this.title.setTitle('Registration - RadReport');

    this.subscription.add(this.siteEffect.findAll().subscribe());
    this.subscription.add(this.bookingCodeEffect.findAll().subscribe());
    this.subscription.add(this.scanCodeEffect.findAll().subscribe());
    this.subscription.add(this.providerNumberEffect.findAll().subscribe());
    this.subscription.add(this.softwarePreferenceEffect.getAll().subscribe());
    this.subscription.add(this.billingItemReferrerTypeEffect.findAll().subscribe());

    // managing each observable separately to handle the subscribe of each
    this.subscription.add(
      this.bookingId$
        .pipe(
          distinctUntilChanged(),
          switchMap((bookingId) => (bookingId ? this.bookingEffect.find(bookingId) : of(null))),
        )
        .subscribe(),
    );

    // Load query param entities
    this.subscription.add(
      combineLatest([
        this.referrerId$.pipe(
          distinctUntilChanged(),
          switchMap((referrerId) => (referrerId ? this.referrerEffect.findById(referrerId) : of(undefined))),
        ),
        this.patientId$.pipe(
          distinctUntilChanged(),
          switchMap((patientId) => (patientId ? this.patientEffect.findById(patientId) : of(undefined))),
        ),
      ]).subscribe(),
    );

    const report$ = this.reportId$.pipe(
      switchMap((reportId) => (reportId ? this.store.select(fromReport.selectReport(reportId)) : of(undefined))),
    );

    // Search for a patients same day bookings
    this.subscription.add(
      combineLatest([this.bookingId$, report$])
        .pipe(
          map(([bookingId, report]) => bookingId || report?.booking_id),
          distinctUntilChanged(),
          switchMap((bookingId) => {
            if (bookingId) {
              return this.bookingEffect.searchPatientSameDayBookings(bookingId);
            }

            return of(undefined);
          }),
        )
        .subscribe((action) => {
          if (action && action.actions.bookingFindManySuccess.bookings.length > 1) {
            this.sameDayBookingIds = action.actions.bookingFindManySuccess.bookings.map((b) => b.id);
          }
        }),
    );

    // When there is a reportId param, load report and associated entities
    this.subscription.add(
      this.reportId$
        .pipe(
          filterDefined(),
          switchMap((reportId) => {
            this.loadingReport = true;
            return this.reportEffect.find(reportId).pipe(
              finalize(() => {
                this.loadingReport = false;
              }),
            );
          }),
          map((action) => action.actions.findReportSuccess.report),

          tap((report) => {
            if (report.referrer_id) this.subscription.add(this.referrerEffect.findById(report.referrer_id).subscribe());
            if (report.patient_id) this.subscription.add(this.patientEffect.findById(report.patient_id).subscribe());
            this.subscription.add(this.invoiceEffect.findInReport(report.id).subscribe());
          }),
          switchMap((report) => this.bookingEffect.find(report.booking_id)),
        )
        .subscribe(),
    );

    // If no reportId in params, autofill booking, patient, funder, referrer.
    this.subscription.add(
      combineLatest([this.bookingId$, this.referrerId$, this.patientId$])
        .pipe(
          switchMap(([bookingId, referrerId, patientId]) => {
            const patient$ = patientId ? this.store.select(fromPatient.selectPatient(patientId)) : of(undefined);
            const referrer$ = referrerId ? this.store.select(fromReferrer.selectReferrer(referrerId)) : of(undefined);
            // Only find booking patient when there is no patientId in query params
            const bookingPatient$ =
              !patientId && bookingId
                ? this.store.select(fromBooking.selectBooking(bookingId)).pipe(
                    filterDefined(),

                    tap((booking: RR.Booking) => {
                      this.funder.setValue(booking.funder);

                      if (booking.patient_id) {
                        this.subscription.add(this.patientEffect.findById(booking.patient_id).subscribe());
                      }
                    }),
                    switchMap((booking) =>
                      booking.patient_id
                        ? this.store.select(fromPatient.selectPatient(booking.patient_id))
                        : of(undefined),
                    ),
                  )
                : of(undefined);

            return combineLatest([patient$, referrer$, bookingPatient$]);
          }),
        )
        .subscribe(([patient, referrer, bookingPatient]) => {
          this.patient = patient;
          this.referrer = referrer;
          if (bookingPatient) this.patient = bookingPatient;
        }),
    );

    const reportPatient$ = report$.pipe(
      switchMap((report) =>
        report && report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
      ),
    );

    const reportReferrer$ = report$.pipe(
      switchMap((report) =>
        report && report.referrer_id
          ? this.store.select(fromReferrer.selectReferrer(report.referrer_id))
          : of(undefined),
      ),
    );

    const reportBooking$ = report$.pipe(
      switchMap((report) => (report ? this.store.select(fromBooking.selectBooking(report.booking_id)) : of(undefined))),
    );

    const booking$ = this.bookingId$.pipe(
      switchMap((id) => (id ? this.store.select(fromBooking.selectBooking(id)) : of(undefined))),
    );

    this.subscription.add(
      combineLatest([report$, reportBooking$, booking$, reportPatient$, reportReferrer$]).subscribe(
        ([report, reportBooking, booking, reportPatient, reportReferrer]) => {
          this.booking = reportBooking || booking;
          this.report = report;
          // To prevent overwriting patient and referrer when there are query params
          if (!this.patientId) this.patient = reportPatient;
          if (!this.referrerId) this.referrer = reportReferrer;

          if (this.booking) {
            this.funder.setValue(this.booking.funder);
          }
        },
      ),
    );

    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.p' }).subscribe(() => {
        this.registrationService.focus('patient-form');
      }),
    );
    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.r' }).subscribe(() => {
        this.registrationService.focus('referrer-form');
      }),
    );
    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.b' }).subscribe(() => {
        this.registrationService.focus('report-form');
      }),
    );

    this.subscription.add(
      this.funder.valueChanges.pipe(startWith(this.funder.value)).subscribe((funder) => {
        this.registrationService.emitBooking({ funder });
      }),
    );

    this.subscription.add(
      this.reportId$
        .pipe(
          filterDefined(),
          switchMap((reportId) => this.reportEffect.availableForms(reportId)),
        )
        .subscribe((response) => {
          this.availableForms = response.forms;
        }),
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      combineLatest([
        this.reportId$.pipe(startWith(null)),
        this.bookingId$.pipe(startWith(null)),
        this.referrerId$.pipe(startWith(null)),
        this.patientId$.pipe(startWith(null)),
      ])
        .pipe(
          map(([reportId, bookingId, referrerId, patientId]) => !reportId && !bookingId && !referrerId && !patientId),
        )
        .subscribe((noParams) => {
          if (noParams) {
            this.patientFormComponent.patientFormSearchComponent.searchForm.reset();
            this.referrerFormComponent.referrerFormSearchComponent.searchForm.reset();
            this.reportFormComponent.form.reset();
            this.noReferrer = false;
            this.sameDayBookingIds = [];

            this.cd.detectChanges();
          }
        }),
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  // @ts-expect-error noImplicitReturns
  beforeUnloadHandler() {
    if (
      this.referrerFormComponent.referrerFormEdit.form.dirty ||
      (this.patientFormComponent.patientFormEdit && this.patientFormComponent.patientFormEdit.form.dirty) ||
      this.reportFormComponent.form.dirty
    ) {
      return false;
    }
  }

  onPatientChanged(p: RR.Patient | undefined) {
    this.patient = p;
    if (p) {
      if (this.report) {
        // Create audit event for patient registration
        this.createAuditEvent('REGISTER_PATIENT');
        if (this.report.patient_id !== p.id) {
          // Update report with new selected/created patient
          this.subscription.add(this.reportEffect.update(this.report.id, { patient_id: p.id }).subscribe());
        }
      }
      if (this.booking) {
        this.subscription.add(this.bookingEffect.update(this.booking.id, { patient_id: p.id }).subscribe());
      }
    }
  }

  onReferrerChanged(r: RR.Referrer | undefined) {
    this.referrer = r;
    if (this.report && r) {
      // Create audit event for referrer registration
      this.createAuditEvent('REGISTER_REFERRER');
      if (this.report.referrer_id !== r.id) {
        // Update report with new selected referrer
        this.subscription.add(this.reportEffect.update(this.report.id, { referrer_id: r.id }).subscribe());
      }
    }
  }

  onNoReferrerChange(event: Event) {
    if (event.target && event.target instanceof HTMLInputElement) {
      this.noReferrer = event.target.checked;
    } else {
      console.error('Unexpected event', event);
    }
  }

  onReportChanged(r: RR.Report | undefined) {
    this.report = r;
    if (this.report) {
      // Create audit event for report registration
      this.createAuditEvent('REGISTER_REPORT');
    }
  }

  createAuditEvent(type: RR.AuditEventType) {
    if (!this.report) {
      return;
    }
    // Create audit event for registration
    const event: Partial<RR.AuditEvent> = { report_id: this.report.id, type };
    this.subscription.add(this.auditEventEffect.createRegistrationEvent(event).subscribe());
  }

  copy(value: string | number) {
    const copied = this.clipboard.copy(value.toString());
    if (copied) {
      this.messageService.add({ type: 'success', title: 'Success', message: 'Copied!', timeout: 1000 });
    } else {
      this.messageService.add({ type: 'danger', title: 'Failure', message: 'Copy Failed!', timeout: 1000 });
    }
  }

  changeSameDayBooking(booking: RR.Booking) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          bookingId: booking.id,
          patientId: this.patientId || this.report?.patient_id,
          referrerId: this.referrerId || this.report?.referrer_id,
        },
      }),
    );

    // eslint-disable-next-line no-restricted-properties
    window.open(url, '_blank');
  }

  openCreateNoteModal({ patientId, referrerId }: { patientId?: number; referrerId?: number }) {
    CreateNoteModalComponent.open({
      modalService: this.modalService,
      referrerId: referrerId ?? undefined,
      patientId: patientId ?? undefined,
      reportId: this.reportId ?? undefined,
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
