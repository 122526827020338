<ng-container *ngIf="report$ | async as report">
  <span class="fa fa-clipboard-check" *ngIf="!report || !report.send_to_voyager_time || !report.text_modified"></span>
  <span
    class="fas fa-check-circle"
    *ngIf="report.send_to_voyager_time && report.text_modified && report.send_to_voyager_time > report.text_modified"
  ></span>
  <span
    class="fas fa-exclamation-triangle"
    *ngIf="report.send_to_voyager_time && report.text_modified && report.send_to_voyager_time < report.text_modified"
  ></span>
  Check Report (C)
</ng-container>
