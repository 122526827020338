import { props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { PathologyActions } from '../../pathology/pathology.action';
import { PatientActions } from '../../patient/patient.action';
import { BodyPartActions } from '../../template/body-part/body-part.action';
import { PresetTitleActions } from '../preset-title/preset-title.action';
import { ReportActions } from '../report/report.action';
import { PresetSearchResponse } from './preset.service';

export const PresetBatchActions = {
  createPresetSuccess: createBatchAction(
    '[Preset] Create Success Batch',
    props<{
      actions: {
        createReportSuccess: ReturnType<typeof ReportActions.createSuccess>;
        createPatientSuccess: ReturnType<typeof PatientActions.createSuccess>;
      };
    }>(),
  ),

  findAllPresetSuccess: createBatchAction(
    '[Preset] Find All Success Batch',
    props<{
      response: PresetSearchResponse;
      actions: {
        findAllReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findAllPresetTitleSuccess: ReturnType<typeof PresetTitleActions.findAllSuccess>;
        findBodyPart: ReturnType<typeof BodyPartActions.findManySuccess>;
        findPathology: ReturnType<typeof PathologyActions.findManySuccess>;
      };
    }>(),
  ),

  searchPresetSuccess: createBatchAction(
    '[Preset] Search Success Batch',
    props<{
      response: PresetSearchResponse;
      actions: {
        findAllReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findAllPresetTitleSuccess: ReturnType<typeof PresetTitleActions.findAllSuccess>;
      };
    }>(),
  ),

  // Search for similar presets
  searchAllPresetSuccess: createBatchAction(
    '[Preset] Search All Success Batch',
    props<{
      response: PresetSearchResponse;
      actions: {
        findAllReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findAllPresetTitleSuccess: ReturnType<typeof PresetTitleActions.findAllSuccess>;
      };
    }>(),
  ),

  updatePresetSuccess: createBatchAction(
    '[Preset] Update Success Batch',
    props<{
      actions: {
        updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
        updatePresetTitleSuccess: ReturnType<typeof PresetTitleActions.updateSuccess>;
      };
    }>(),
  ),
};
