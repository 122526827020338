import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { fromSignature } from 'app/store/signature';
import { fromCompanyRole } from 'app/store/user/company-role';
import { UserEffect } from 'app/store/user/user/user.effect';
import { map, take } from 'rxjs/operators';

type AttributeFrequency = {
  attribute_option_id: number;
  frequency: number;
};

export type AttributeFrequencyResponse = {
  attribute_set_frequency: number;
  attribute_options: AttributeFrequency[];
};

@Injectable()
export class AdminService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private userEffect: UserEffect,
    private store: Store<AppState>,
  ) {}

  resetPassword(admin_password: string, user_id: number, password: string) {
    return this.http.post('/api/auth/resetpass', {
      admin_password,
      user_id,
      password,
    });
  }

  changePassword(data: { current_password: string; password?: string }) {
    return this.http.post('/api/auth/changepass', data);
  }

  updateUserFieldWithAuth(admin_password: string, id: number, field: string, value: string) {
    this.userEffect
      .update(id, { [field]: value, admin_password })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: `${field} has been changed to ${value}.`,
            type: 'success',
            timeout: 6000,
          });
        },
      });
  }

  getSignaturesByUser(user_id: number) {
    return this.store.select(fromSignature.selectEntities).pipe(
      map((s) => Object.values(s)),
      // @ts-expect-error strictNullChecks
      map((signatures) => signatures.filter((s) => s.user_ids.find((u) => u === user_id))),
    );
  }

  includesRole(preset_roles: string[], companyRoleIds: string[]) {
    return companyRoleIds.some((companyRoleId) => preset_roles.includes(companyRoleId));
  }

  getAttributeFrequency(attribute_set_id: number) {
    return this.http.get<AttributeFrequencyResponse>(`/api/attribute_set/${attribute_set_id}/frequency`);
  }

  getCompanyRoleName(companyRoleIds: string[]) {
    return this.store.select(fromCompanyRole.selectEntities).pipe(
      map((company_role_entities) => {
        let rolesName = '';
        companyRoleIds.forEach((companyRoleId, index) => {
          const company_role = company_role_entities[companyRoleId];
          if (company_role) {
            if (index > 0) {
              rolesName += ' / ' + company_role.name;
            }
            if (index === 0) {
              rolesName += company_role.name;
            }
          }
        });
        return rolesName;
      }),
    );
  }
}
