<div class="w-100 p-2">
  <div class="mb-2">
    <ul class="list-group">
      <li *ngFor="let label of labels; let i = index" class="list-group-item">
        <div class="form-check d-flex align-items-center justify-content-between">
          <div>
            <input
              class="form-check-input"
              type="checkbox"
              [id]="'label' + i"
              [checked]="selectedLabelIds | includes: label.id"
              (change)="onLabelSelectionChange(label.id, $event)"
              [disabled]="reportIds && reportIds.length === 0"
            />
            <label class="form-check-label" [for]="'label' + i">{{ label.name }}</label>
          </div>
          <div permission [permission-only]="['report_manage']">
            <span role="button" (click)="deleteLabel(label.id); $event.stopPropagation()">
              <i class="fas fa-trash"></i>
            </span>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div permission [permission-only]="['report_manage']" class="input-group" *ngIf="reportIds && reportIds.length === 0">
    <input type="text" class="form-control" [formControl]="labelName" placeholder="Add label name..." />
    <button (click)="createLabel()" [disabled]="!labelName.value" class="btn btn-outline-success">
      <i class="fas fa-plus"></i>
    </button>
  </div>
  <div class="mt-2 apply-label" *ngIf="viewMode === 'filter'">
    <button class="btn btn-success w-100" (click)="onSelectLabelIds(); popoverLabel.close()">Filter</button>
  </div>
  <div class="mt-2 apply-label" *ngIf="reportIds && reportIds.length > 0">
    <button class="btn btn-success w-100" (click)="updateReportLabel()">Apply</button>
  </div>
</div>
