import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { DocumentationLinkEffect, fromDocumentationLink } from 'app/store/documentation-link';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-documentation-link-button',
  templateUrl: './documentation-link-button.component.html',
})
export class DocumentationLinkButtonComponent implements OnInit, OnDestroy {
  @Input() buttonId: number;
  doc: RR.DocumentationLink | undefined = undefined;
  subscription: Subscription = new Subscription();

  constructor(
    private docLinkEffect: DocumentationLinkEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(this.docLinkEffect.findAll().subscribe());

    this.subscription.add(
      this.store.select(fromDocumentationLink.selectAll).subscribe((docs) => {
        this.doc = docs.find((doc) => doc.button_id === this.buttonId);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
