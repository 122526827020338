import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReportAccessEventHttpService {
  constructor(private http: HttpClient) {}

  create(event: Partial<RR.ReportAccessEvent>): Observable<RR.ReportAccessEvent> {
    return this.http.post<RR.ReportAccessEvent>('/api/report_access_events', event);
  }

  update(
    accessEventId: number,
    changes: Partial<RR.ReportAccessEvent>,
    params: HttpParams,
  ): Observable<RR.ReportAccessEvent> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (params)
      return this.http.put<RR.ReportAccessEvent>(`/api/report_access_events/${accessEventId}`, changes, { params });
    return this.http.put<RR.ReportAccessEvent>(`/api/report_access_events/${accessEventId}`, changes);
  }

  findInReport(reportId: number) {
    return this.http.get<RR.ReportAccessEvent[]>(`/api/report/${reportId}/access_events`);
  }
}
