import { createAction, props } from '@ngrx/store';

import {
  Breadcrumb,
  ElementFilterType,
  LastClickedBreadcrumb,
  RightPaneViewModeType,
  TopicState,
} from './editor.state';

export const EditorActions = {
  open: createAction('[Editor] Open Editor'),
  close: createAction('[Editor] Close Editor'),
  // Edit mode
  toggleEditMode: createAction('[Editor] Toggle Edit Mode', props<{ editMode?: boolean }>()),
  // Selected statements
  selectStatement: createAction('[Editor] Select Statement', props<{ statementId: number }>()),
  clearSelectedStatements: createAction('[Editor] Clear Selected Statement'),
  // Prefill
  togglePrefill: createAction('[Editor] Toggle Prefill', props<{ prefill?: boolean }>()),
  // Template chooser
  toggleTemplateChooser: createAction('[Editor] Toggle Template Chooser', props<{ templateChooser?: boolean }>()),
  // Audit
  toggleRightPaneViewMode: createAction(
    '[Editor] Toggle Right Pane View Mode',
    props<{ mode: RightPaneViewModeType }>(),
  ),
  // Schedule send to Voyager task
  setScheduleSendToVoyagerTask: createAction(
    '[Editor] Set Schedule Send To Voyager',
    props<{ reportId: number | undefined }>(),
  ),
  // Clipboard sentence
  saveClipboardSentence: createAction('[Editor] Save Clipboard', props<{ sentence: string }>()),
  // Open sidebar
  openSidebar: createAction('[Editor] Open Sidebar', props<{ open?: boolean }>()),
  // Add voice result to list
  addVoiceSearchResult: createAction(
    '[Editor] Add Voice Search Result',
    props<{ term: string; note_type: RR.VoiceNoteType; subsection?: string }>(),
  ),
  addVoiceNotes: createAction('[Editor] Add Voice Notes', props<{ voiceResults: RR.VoiceResult[] }>()),
  // Clear voice results
  clearVoiceSearchReults: createAction('[Editor] Clear Voice Search Results'),
  removeVoiceSearchResult: createAction('[Editor] Remove Voice Result', props<{ voiceResult: RR.VoiceResult }>()),
  // Element filter
  updateElementFilter: createAction('[Editor] Update Element Filter', props<{ filterType: ElementFilterType }>()),
  // Most recent categories
  saveMostRecentCategory: createAction('[Editor] Save Most Recent Category', props<{ categoryId: number }>()),
  saveMostRecentTop20Category: createAction(
    '[Editor] Save Most Recent Top20 Category',
    props<{ categoryId: number }>(),
  ),
  // Save last report access event
  saveLastReportAccessEvent: createAction(
    '[Editor] Save Last Report Access Event',
    props<{ reportAccessEvent: RR.ReportAccessEvent }>(),
  ),
  // Prefill underline
  togglePrefilledUnderline: createAction(
    '[Editor] Toggle Prefilled Underline',
    props<{ underline?: boolean; choice_ids?: Set<number> }>(),
  ),
  underlinePrefilledChoices: createAction('[Editor] Underline Prefilled Choices', props<{ choice_ids: Set<number> }>()),
  // Topics
  setPrefillTopicPreview: createAction(
    '[Editor] Set Prefill Topic Preview',
    props<{ openTopicId: number; prefillPreview: TopicState['prefillPreview'] }>(),
  ),
  addTopicToPrefillPreview: createAction(
    '[Editor] Add Topic To Prefill Preview',
    props<{ openTopicId: number; topicId: string }>(),
  ),
  removeTopicFromPrefillPreview: createAction(
    '[Editor] Remove Topic From Prefill Preview',
    props<{ openTopicId: number; topicId: string }>(),
  ),
  doGlobalSearch: createAction('[Editor] Do Global Search', props<{ openTopicId: number; text: string }>()),
  saveGlobalSearch: createAction('[Editor] Save Global Search', props<{ openTopicId: number; text: string }>()),
  saveAdjacentStatement: createAction(
    '[Editor] Save Adjacent Statement',
    props<{
      openTopicId: number;
      adjacentStatement: {
        id: number | null;
        element_id: number | null;
        topic_id: number | null;
        region_id: number | null;
      };
    }>(),
  ),
  setBreadcrumb: createAction('[Editor] Set Breadcrumb', props<{ openTopicId: number; breadcrumb: Breadcrumb }>()),
  setLastClickedBreadcrumb: createAction(
    '[Editor] Set Last Clicked Breadcrumb',
    props<{ openTopicId: number; lastClickedBreadcrumb: LastClickedBreadcrumb }>(),
  ),
};
