import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SessionEffect } from 'app/store/session';
import { Subscription } from 'rxjs';

const BANNED_PERMISSIONS = new Set(['admin', 'doctor', 'technician', 'viewer']);

@Directive({
  selector: '[rrNgbTooltip]',
  standalone: true,
})
/**
 * This is a workaround to allow [ngbTooltip] to be used in conjunction with [permission]. Note: the behavior can be a
 * little weird (2 tooltips showing up).
 */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class RRNgbTooltip extends NgbTooltip {}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[permission]',
  standalone: true,
  hostDirectives: [{ directive: RRNgbTooltip }],
})
export class PermissionDirective implements OnInit, OnDestroy {
  /* eslint-disable @angular-eslint/no-input-rename */
  /**
   * Must have at least one of these permissions
   */
  @Input('permission-only') permission_only?: string[];
  @Input('permission-user') user?: RR.User;
  @Input('permission-hidden') hidden: boolean;
  /* eslint-enable @angular-eslint/no-input-rename */

  subscription: Subscription = new Subscription();

  constructor(
    private elementRef: ElementRef,
    private sessionEffect: SessionEffect,
    private renderer: Renderer2,
    private ngbTooltip: RRNgbTooltip,
  ) {}

  ngOnInit() {
    this.evaluate();
    if (this.permission_only && this.permission_only.some((permission) => BANNED_PERMISSIONS.has(permission))) {
      console.warn(`permission-only has banned permission: ${this.permission_only}`);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  evaluate() {
    if (this.permission_only) {
      this.evaluatePermission({ only: this.permission_only });
    }
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  evaluatePermission = (permission: { only?: string[] }) => {
    this.subscription.add(
      this.sessionEffect.authorise(permission, this.user).subscribe((authorised) => {
        if (authorised) {
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'authorised');
          this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
          this.ngbTooltip.ngbTooltip = null;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        } else if (this.hidden && !authorised) {
          // For elements that have hidden attribute
          // And user permission is not authorised
          // Hide from view.
          this.renderer.setAttribute(this.elementRef.nativeElement, 'authorised', 'hidden');
        } else {
          this.renderer.setAttribute(this.elementRef.nativeElement, 'authorised', 'false');
          this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
          this.ngbTooltip.ngbTooltip = `Permission required: ${permission.only}`;
        }
      }),
    );
  };
}
