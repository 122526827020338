import { Update } from '@ngrx/entity';
import { createActionGroup, props } from '@ngrx/store';
import { AddedTopicActions } from 'app/store/added-topics/added-topics.action';
import { createBatchAction } from 'app/store/batching.action';
import { PaymentActions } from 'app/store/payment/payment.action';
import { ReferrerContactMethodActions } from 'app/store/referrer-contact-method/referrer-contact-method.action';

import { BookingActions } from '../../booking/booking.action';
import { InvoiceActions } from '../../invoice/invoice.action';
import { PatientActions } from '../../patient/patient.action';
import { ReferrerActions } from '../../referrer/referrer.action';
import { RequestMergeReport } from '../../report-merge-conflict/report-merge-conflict.service';
import { ScanCodeActions } from '../../scan-code/scan-code.action';
import { TemplateActions } from '../../template/template/template.action';
import { UserActions } from '../../user/user/user.action';
import { ReportAccessEventActions } from '../access-event/access-event.action';
import { AuditEventActions } from '../audit-event/audit-event.action';
import { DocumentActions } from '../document/document.action';
import { FollowupTaskActions } from '../followup-task/followup-task.action';
import { SendEventActions } from '../send-event/send-event.action';
import { TodoActions } from '../todo/todo.action';
import { TopicActions } from '../topic/topic.action';
import { UrgentNoteActions } from '../urgent-note/urgent-note.action';
import { VoiceNoteActions } from '../voice-note/voice-note.action';

/**
 * Ngrx Actions for Report
 *
 */
export const ReportActions = createActionGroup({
  source: 'Report',
  events: {
    addMany: props<{ reports: RR.Report[] }>(),
    upsertOne: props<{ report: RR.Report }>(),
    findTextSuccess: props<{ report: RR.Report & { text: string } }>(),
    upsertMany: props<{ reports: RR.Report[] }>(),
    createSuccess: props<{ report: RR.Report }>(),
    updateOne: props<{ update: Update<RR.Report> }>(),
    removeMany: props<{ reportIds: number[] }>(),
    loadedRelatedReports: props<{ report_id: number }>(),
    mergeSuccess: props<{ request: RequestMergeReport; report: RR.Report }>(),
  },
});

export const ReportBatchActions = {
  findReportSuccess: createBatchAction(
    '[Report] Find Report Success Batch',
    props<{
      actions: {
        findReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
        findTopicSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findTemplateSuccess: ReturnType<typeof TemplateActions.findManySuccess>;
        findPatientSuccess?: ReturnType<typeof PatientActions.findSuccess>;
        findReferrerSuccess?: ReturnType<typeof ReferrerActions.findSuccess>;
        findTodoSuccess: ReturnType<typeof TodoActions.findManySuccess>;
        findVoiceNoteSuccess: ReturnType<typeof VoiceNoteActions.findManySuccess>;
        findUrgentNoteSuccess: ReturnType<typeof UrgentNoteActions.findManySuccess>;
        findAccessEventSuccess: ReturnType<typeof ReportAccessEventActions.findManySuccess>;
        findSendEventSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
        findScanCodeSuccess?: ReturnType<typeof ScanCodeActions.findSuccess>;
        findTodoAuditEventSuccess: ReturnType<typeof AuditEventActions.findManySuccess>;
        findUserSuccess: ReturnType<typeof UserActions.findAllSuccess>;
        findFollowupTaskSuccess: ReturnType<typeof FollowupTaskActions.findManySuccess>;
        findReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
      };
    }>(),
  ),
  findAllReportSuccess: createBatchAction(
    '[Report] Find All Report Success Batch',
    props<{
      /**
       * TODO: including count here is a hack, it should be in the store.
       */
      count: number;
      reportIds: number[];
      actions: {
        findReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findPatientSuccess: ReturnType<typeof PatientActions.findManySuccess>;
        findReferrerSuccess: ReturnType<typeof ReferrerActions.findManySuccess>; // for Distribute Report
        findTopicSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findTodoSuccess: ReturnType<typeof TodoActions.findManySuccess>;
        findVoiceNoteSuccess: ReturnType<typeof VoiceNoteActions.findManySuccess>;
        findUrgentNoteSuccess: ReturnType<typeof UrgentNoteActions.findManySuccess>;
        findAccessEventSuccess: ReturnType<typeof ReportAccessEventActions.findManySuccess>;
        findSendEventSuccess: ReturnType<typeof SendEventActions.findManySuccess>;
        findScanCodeSuccess: ReturnType<typeof ScanCodeActions.findManySuccess>;
        findBookingSuccess: ReturnType<typeof BookingActions.findManySuccess>;
        findInvoiceSuccess: ReturnType<typeof InvoiceActions.findManySuccess>;
        findAuditEventSuccess: ReturnType<typeof AuditEventActions.findManySuccess>;
        findUserSuccess: ReturnType<typeof UserActions.findAllSuccess>;
        findPaymentSuccess: ReturnType<typeof PaymentActions.findManySuccess>;
        findFollowupTaskSuccess: ReturnType<typeof FollowupTaskActions.findManySuccess>;
        findReferrerContactMethodSuccess: ReturnType<typeof ReferrerContactMethodActions.findManySuccess>;
      };
    }>(),
  ),
  findPatientReportsSuccess: createBatchAction(
    '[Report] Find Patient Reports Success Batch',
    props<{
      actions: {
        findReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findTopicSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findScanCodeSuccess: ReturnType<typeof ScanCodeActions.findManySuccess>;
      };
    }>(),
  ),
  findRelatedReportsSuccessAction: createBatchAction(
    '[Report] Find Related Reports Success Batch',
    props<{
      actions: {
        /**
         * Report is already in the store. This is for updating the Report.related_reports field.
         */
        partialUpdateReportSuccess: ReturnType<typeof ReportActions.updateOne>;
        findReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findTopicSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findTemplateSuccess: ReturnType<typeof TemplateActions.findManySuccess>;
        findDocumentSuccess: ReturnType<typeof DocumentActions.upsertMany>;
        findBookingSuccess: ReturnType<typeof BookingActions.findManySuccess>;
        loadedRelatedReports: ReturnType<typeof ReportActions.loadedRelatedReports>;
        findAddedTopicSuccess: ReturnType<typeof AddedTopicActions.findAllSuccess>;
      };
    }>(),
  ),
  updateSuccessBatchAction: createBatchAction(
    '[Report] Update Success Batch',
    props<{
      actions: {
        updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
        updatePatientSuccess?: ReturnType<typeof PatientActions.updateSuccess>;
        updateReferrerSuccess?: ReturnType<typeof ReferrerActions.updateSuccess>;
        updateBookingSuccess?: ReturnType<typeof BookingActions.updateSuccess>;
      };
    }>(),
  ),
  findNextReportsSuccess: createBatchAction(
    '[Report] Find Next Reports Success Batch',
    props<{
      next_report_id: number;
      next_report_same_referrer_id: number;
      next_report_same_scan_code_id: number;
      next_report_xr_id: number;
      next_report_mr_id: number;
      next_report_ct_id: number;
      next_report_us_id: number;
      actions: {
        findReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findTopicSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findPatientSuccess: ReturnType<typeof PatientActions.findManySuccess>;
      };
    }>(),
  ),
};
