<!-- eslint-disable @angular-eslint/template/no-any -->
<rr-choice-preview
  *ngFor="let ctx of clonedChoices; let first = first; let last = last"
  [isClone]="true"
  [first]="first"
  [last]="last"
  [choice]="$any(ctx.statement_choice)"
  [region_choice]="$any(ctx.region_choice)"
  [element_choice]="$any(ctx.element_choice)"
  [section_choice]="$any(ctx.section_choice)"
  [subsection_choice]="$any(ctx.subsection_choice)"
  [mode]="mode"
  [template_id]="topic.template_id"
></rr-choice-preview>
<!-- eslint-enable @angular-eslint/template/no-any -->
<rr-subsection-preview
  *ngFor="let subsection_choice_id of section_choice.subsection_choices"
  [subsection_choice_id]="subsection_choice_id"
  [section_choice]="section_choice"
  [topic]="topic"
  [mode]="mode"
>
</rr-subsection-preview>
