<div class="d-flex btn-group flex-wrap">
  <button
    rrTooltip="PREFILL_COMBINE"
    class="btn btn-sm btn-outline-dark"
    role="button"
    (click)="selectTopics('COMBINE')"
    [class.active]="selectedType === 'COMBINE'"
  >
    Combine
  </button>
  <button
    rrTooltip="PREFILL_TOP6"
    class="btn btn-sm btn-outline-dark"
    role="button"
    (click)="selectTopics('TOP6_METADATA')"
    [class.active]="selectedType === 'TOP6_METADATA'"
  >
    Top 6 B2
  </button>
  <button
    rrTooltip="PREFILL_TOP6_EXACT_MATCH"
    class="btn btn-sm btn-outline-dark"
    role="button"
    (click)="selectTopics('TOP6_EXACT_MATCH')"
    [class.active]="selectedType === 'TOP6_EXACT_MATCH'"
  >
    Top 6 B3
  </button>
  <button
    rrTooltip="PREFILL_ONE_MORE_ROW"
    class="btn btn-sm btn-outline-dark"
    role="button"
    (click)="selectTopics('ONE_MORE')"
    [class.active]="selectedType === 'ONE_MORE'"
  >
    <i class="fa fa-plus-circle"></i>
  </button>
  <button
    rrTooltip="PREFILL_REMOVE_LAST_ROW"
    class="btn btn-sm btn-outline-dark"
    role="button"
    (click)="deselectLastTopic()"
  >
    <i class="fa fa-minus-circle"></i>
  </button>
  <button rrTooltip="PREFILL_CLEAR_ALL" class="btn btn-sm btn-outline-dark" role="button" (click)="clearAllSelection()">
    <i class="fa fa-eraser"></i>
  </button>
</div>
