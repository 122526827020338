<div *ngFor="let tag of tags; trackBy: trackBy">
  <rr-prefill-tag [tag]="tag" [topicId]="topicId" [hideScore]="hideScore" [topicTags]="topicTags"></rr-prefill-tag>
</div>
<div *ngFor="let divider of dividers">
  <rr-prefill-tag
    [divider]="divider"
    [topicId]="topicId"
    [hideScore]="hideScore"
    [topicTags]="topicTags"
  ></rr-prefill-tag>
</div>
<div *ngIf="globalSearchTags">
  <div *ngFor="let tag of globalSearchTags.divider.hits.hits">
    <rr-prefill-tag
      [globalSearchTag]="tag"
      [topicId]="topicId"
      [hideScore]="hideScore"
      [topicTags]="topicTags"
    ></rr-prefill-tag>
  </div>
</div>
