<div *ngFor="let result of structuredVoiceTags">
  <h6 class="p-2 pb-0 mb-0">
    <u>{{ result.subsection }}</u>
  </h6>
  <div *ngFor="let item of result.value">
    <div (click)="copyToSearch(item.term)" ngbDropdownItem class="d-flex p-1 px-2">
      <span>
        {{ item.term }}
      </span>
      <button role="button" class="btn btn-sm ms-auto" (click)="$event.stopPropagation(); deleteResult(item)">
        <span class="fa fa-trash-alt text-danger"></span>
      </button>
    </div>
  </div>
</div>
