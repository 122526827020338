import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, switchMap, tap } from 'rxjs/operators';

import { AddedTopicActions } from '../../added-topics/added-topics.action';
import { AppState } from '../../app.state';
import { EditorActions } from '../../editor/editor.action';
import { TagChoiceEffect } from '../../prefill/tag-choice/tag-choice.effect';
import { HttpParamsObject } from '../../store.types';
import { ElementActions } from '../../template/element/element.action';
import { RegionActions } from '../../template/region/region.action';
import { SectionActions } from '../../template/section/section.action';
import { StatementCoincidenceActions } from '../../template/statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../../template/statement-set/statement-set.action';
import { StatementActions } from '../../template/statement/statement.action';
import { SubsectionActions } from '../../template/subsection/subsection.action';
import { TemplateBatchActions } from '../../template/template/template.action';
import { TemplateEffect } from '../../template/template/template.effect';
import { TextObjectActions } from '../../template/text-object/text-object.action';
import { ElementChoiceActions } from '../element-choice/element-choice.action';
import { FavouriteActions } from '../favourite/favourite.action';
import { JustificationActions } from '../justification/justification.action';
import { RegionChoiceActions } from '../region-choice/region-choice.action';
import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { ReportActions } from '../report/report.action';
import { SectionChoiceActions } from '../section-choice/section-choice.action';
import { StatementChoiceActions } from '../statement-choice/statement-choice.action';
import { SubsectionChoiceActions } from '../subsection-choice/subsection-choice.action';
import { TextObjectChoiceActions } from '../text-object-choice/text-object-choice.action';
import { TopicActions, TopicBatchActions } from './topic.action';
import { TopicHttpService } from './topic.service';

@Injectable()
export class TopicEffect {
  constructor(
    private service: TopicHttpService,
    private store: Store<AppState>,
    private message: MessageService,
    private tagChoiceEffect: TagChoiceEffect,
    private templateEffect: TemplateEffect,
  ) {}

  find(topicId: number, params: HttpParamsObject = {}) {
    return this.service.find(topicId, params).pipe(
      this.message.handleHttpErrorPipe,
      map(
        ({
          topic,
          favourite,
          justifications,
          section_choices,
          subsection_choices,
          region_choices,
          element_choices,
          statement_choices,
          text_object_choices,
          sections,
          subsections,
          regions,
          elements,
          statement_sets,
          statements,
          statement_coincidences,
          dividers,
          text_objects,
        }) => {
          const action = TopicBatchActions.findTopicSuccess({
            actions: {
              findTopicSuccess: TopicActions.upsertOne({ topic }),
              findJustificationSuccess: JustificationActions.findManySuccess({ justifications }),
              findSectionChoiceSuccess: SectionChoiceActions.upsertMany({ sectionChoices: section_choices }),
              findSubsectionChoiceSuccess: SubsectionChoiceActions.upsertMany({
                subsectionChoices: subsection_choices,
              }),
              findRegionChoiceSuccess: RegionChoiceActions.upsertMany({ regionChoices: region_choices }),
              findElementChoiceSuccess: ElementChoiceActions.upsertMany({ elementChoices: element_choices }),
              findStatementChoiceSuccess: StatementChoiceActions.upsertMany({
                statementChoices: statement_choices,
              }),
              findTextObjectChoicesSuccess: TextObjectChoiceActions.upsertMany({
                textObjectChoices: text_object_choices,
              }),

              findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                actions: {
                  sectionAddMany: SectionActions.addMany({ sections: sections }),
                  subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
                  regionAddMany: RegionActions.addMany({ regions: regions }),
                  elementAddMany: ElementActions.addMany({ elements: elements }),
                  statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                  statementAddMany: StatementActions.addMany({ statements: statements }),
                  dividerAddMany: StatementActions.addMany({ statements: dividers }),
                  textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                  statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                    statementCoincidences: statement_coincidences,
                  }),
                },
              }),
            },
          });

          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (favourite) {
            action.actions.findFavouriteSuccess = FavouriteActions.findSuccess({ favourite });
          }
          return action;
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findMultipleTopics(topicIds: number[]) {
    return this.service.findMultipleTopics(topicIds).pipe(
      this.message.handleHttpErrorPipe,
      map(
        ({
          topics,
          favourites,
          section_choices,
          subsection_choices,
          region_choices,
          element_choices,
          statement_choices,
          text_object_choices,
          sections,
          subsections,
          regions,
          elements,
          statement_sets,
          statements,
          dividers,
          text_objects,
          statement_coincidences,
        }) => {
          return TopicBatchActions.findMultipleTopicSuccess({
            actions: {
              findTopicsSuccess: TopicActions.upsertMany({ topics }),
              findFavouriteSuccess: FavouriteActions.findManySuccess({ favourites }),
              findSectionChoiceSuccess: SectionChoiceActions.upsertMany({ sectionChoices: section_choices }),
              findSubsectionChoiceSuccess: SubsectionChoiceActions.upsertMany({
                subsectionChoices: subsection_choices,
              }),
              findRegionChoiceSuccess: RegionChoiceActions.upsertMany({ regionChoices: region_choices }),
              findElementChoiceSuccess: ElementChoiceActions.upsertMany({ elementChoices: element_choices }),
              findStatementChoiceSuccess: StatementChoiceActions.upsertMany({
                statementChoices: statement_choices,
              }),
              findTextObjectChoicesSuccess: TextObjectChoiceActions.upsertMany({
                textObjectChoices: text_object_choices,
              }),

              findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                actions: {
                  sectionAddMany: SectionActions.addMany({ sections: sections }),
                  subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
                  regionAddMany: RegionActions.addMany({ regions: regions }),
                  elementAddMany: ElementActions.addMany({ elements: elements }),
                  statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                  statementAddMany: StatementActions.addMany({ statements: statements }),
                  dividerAddMany: StatementActions.addMany({ statements: dividers }),
                  textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                  statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                    statementCoincidences: statement_coincidences,
                  }),
                },
              }),
            },
          });
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(topic: Partial<RR.Topic>) {
    return this.service.create(topic).pipe(
      this.message.handleHttpErrorPipe,
      map(({ report, topic }) => {
        return TopicBatchActions.createTopicSuccess({
          actions: {
            createTopicSuccess: TopicActions.addOne({ topic }),
            updateReportSuccess: ReportActions.upsertOne({ report }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(topicId: number, changes: Partial<RR.Topic> & { move_to_topic_id?: number }) {
    return this.service.update(topicId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topic, report }) => {
        return TopicBatchActions.updateTopicSuccess({
          actions: {
            updateTopicSuccess: TopicActions.upsertOne({ topic }),
            updateReportSuccess: ReportActions.upsertOne({ report }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  autoChooseSignature(topicId: number) {
    return this.service.autoChooseSignature(topicId).pipe(
      this.message.handleHttpErrorPipe,
      map((topic) => {
        return TopicActions.upsertOne({ topic });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  copyOtherImaging(topicId: number, fromTopicId: number, includeKeyFindings: boolean) {
    return this.service.copyOtherImaging(topicId, fromTopicId, includeKeyFindings).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topic, added_topics }) => {
        return TopicBatchActions.findAddedTopicSuccess({
          actions: {
            updateTopicSuccess: TopicActions.upsertOne({ topic }),
            updateAddedTopicsSuccess: AddedTopicActions.updateManySuccess({ added_topics }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  clearOtherImaging(topicId: number) {
    return this.service.clearOtherImaging(topicId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topic }) => {
        return TopicBatchActions.removeAllAddedTopicSuccess({
          actions: {
            updateTopicSuccess: TopicActions.upsertOne({ topic }),
            removeAllAddedTopicsSuccess: AddedTopicActions.removeAllSuccess(),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteOtherImaging(topicId: number, otherImagingTopicId: number) {
    return this.service.deleteOtherImaging(topicId, otherImagingTopicId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topic }) => {
        return TopicBatchActions.removeOneAddedTopicSuccess({
          actions: {
            updateTopicSuccess: TopicActions.upsertOne({ topic }),
            removeOneAddedTopicsSuccess: AddedTopicActions.removeOneSuccess({ otherImagingTopicId }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  copyTopic(topicId: number) {
    return this.service.copyTopic(topicId).pipe(
      this.message.handleHttpErrorPipe,
      map((topic) => {
        return TopicActions.upsertOne({ topic });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  copyChoices(topicId: number, choiceIds: number[], keepNumbers: boolean) {
    return this.service.copyChoices(topicId, choiceIds, keepNumbers).pipe(
      this.message.handleHttpErrorPipe,
      map((response) => mapChangeTrackerToSuccessActions(response)),
      tap((action) => {
        this.store.dispatch(
          EditorActions.underlinePrefilledChoices({
            choice_ids: new Set(action.actions.statementChoiceAddMany.statementChoices.map((c) => c.id)),
          }),
        );
        this.store.dispatch(action);
      }),
    );
  }

  refTopics(topicId: number) {
    return this.service.refTopics(topicId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ topics, reports, favourites }) => {
        return TopicBatchActions.findRefTopicSuccess({
          topicId,
          actions: {
            findTopicsSuccess: TopicActions.upsertMany({ topics }),
            findReportsSuccess: ReportActions.upsertMany({ reports }),
            findFavouritesSuccess: FavouriteActions.findManySuccess({ favourites }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  openTopic(topicId: number) {
    this.store.dispatch(TopicActions.openTopic({ topicId }));
    // Note: also update report refresh() method in EditorWarningModalComponent
    return this.find(topicId).pipe(
      this.message.handleHttpErrorPipe,
      switchMap((action) => {
        const topic = action.actions.findTopicSuccess.topic;
        return this.templateEffect.loadTemplate(topic.template_id).pipe(map(() => topic));
      }),
      switchMap((topic) => {
        // Load the tags that have been selected from the database. This provides
        // data for filling the exact match prefill panel.
        return this.tagChoiceEffect.loadTagChoices(topicId).pipe(map(() => topic));
      }),
    );
    // TopicBatchActions.findTopicSuccess is dispatched in the find method
  }
}
