import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { fromTopic, TopicEffect } from 'app/store/report/topic';
import { Observable, Subscription } from 'rxjs';

import { RefTopicComponent } from './ref-topic/ref-topic.component';

export type RefTopicsContext = 'FAVOURITES' | 'DEMOGRAPHICS';

@Component({
  standalone: true,
  imports: [CommonModule, RefTopicComponent, StoreSelectPipe],
  selector: 'rr-ref-topics',
  templateUrl: './ref-topics.component.html',
  styleUrls: ['./ref-topics.component.css'],
})
export class RefTopicsComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  @Input() context: RefTopicsContext = 'FAVOURITES';
  @Output() onSelectFavourite = new EventEmitter<RR.Favourite>();

  topic$: Observable<RR.Topic | undefined>;

  subscription = new Subscription();

  constructor(
    private topicEffect: TopicEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.topicEffect.refTopics(this.topic_id).subscribe());
    this.topic$ = this.store.select(fromTopic.selectTopic(this.topic_id));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectTopicSelectorFn = fromTopic.selectTopic;
}
