import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type BillingItemReferrerTypeResponse = {
  referrer_type: RR.ReferrerTypeBillingItem;
  billing_item: RR.BillingItem;
};

@Injectable()
export class BillingItemReferrerTypeHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.ReferrerTypeBillingItem[]> {
    return this.http.get<RR.ReferrerTypeBillingItem[]>(`/api/billing_item/referrer_type`);
  }

  // Add billing item to referrer type
  addBillingItem(billingItemId: number, referrerType: string): Observable<BillingItemReferrerTypeResponse> {
    return this.http.put<BillingItemReferrerTypeResponse>(
      `/api/billing_item/${billingItemId}/referrer_type/${referrerType}`,
      {},
    );
  }

  // delete billing item from referrer type
  deleteBillingItem(billingItemId: number, referrerType: string): Observable<BillingItemReferrerTypeResponse> {
    return this.http.delete<BillingItemReferrerTypeResponse>(
      `/api/billing_item/${billingItemId}/referrer_type/${referrerType}`,
    );
  }
}
