import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './heading-preview-delete-modal.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class HeadingPreviewDeleteModalComponent {
  @Input() headingName: string;

  constructor(public activeModal: NgbActiveModal) {}

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(HeadingPreviewDeleteModalComponent);
    return modalRef;
  }
}
