import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { StatementActions } from '../statement/statement.action';

/**
 * Methods of interacting with the Statement Text Objects
 *
 * The only method we are implementing for the Text Objects is the update. All other
 * uses should be through the statement. This is because we want to be able to update
 * values within the text objects individually. When working with the statements, the
 * text objects are part of them, so we shouldn't have the get, create or delete
 * actions.
 */
export const TextObjectActions = createActionGroup({
  source: 'TextObject',
  events: {
    upsertMany: props<{ textObjects: RR.TextObject[] }>(),
    deleteManySuccess: props<{ textObjectIds: number[] }>(),
    upsertOne: props<{ textObject: RR.TextObject }>(),
    reset: emptyProps(),
  },
});

export const TextObjectBatchActions = {
  updateSuccess: createBatchAction(
    '[Text Object] Update Success Batch',
    props<{
      actions: {
        statementUpdateSuccessAction: ReturnType<typeof StatementActions.upsertOne>;
        textObjectUpdateSuccessAction: ReturnType<typeof TextObjectActions.upsertOne>;
      };
    }>(),
  ),
};
