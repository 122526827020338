<div class="d-flex rounded p-2 my-2 align-items-center bg-info">
  <div *ngIf="similarPatients.patients.length && similarReferralPatients.length">
    <strong class="text-white mb-0"
      >Found similar existing patients and E-referral patients. Click to see suggestions.</strong
    >
  </div>
  <div *ngIf="!similarPatients.patients.length && similarReferralPatients.length">
    <strong class="text-white mb-0">Similar referral patients found, click to see suggestions</strong>
  </div>
  <div
    *ngIf="
      similarPatients.patients.length && similarPatients.reason === 'IDENTICAL_NAME' && !similarReferralPatients.length
    "
  >
    <strong class="text-white mb-0">Found patients with identical names - Click to see Suggestions</strong>
  </div>

  <div
    *ngIf="
      similarPatients.patients.length &&
      similarPatients.reason === 'IDENTICAL_MEDICARE' &&
      !similarReferralPatients.length
    "
  >
    <strong class="text-white mb-0">Found patients with identical medicare number - Click to see Suggestions</strong>
  </div>

  <button
    *ngIf="similarReferralPatients.length || similarPatients.patients.length"
    class="btn btn-light ms-auto"
    [ngbPopover]="suggestionContent"
    #suggestionPopover="ngbPopover"
    popoverClass="suggestion-popover"
    placement="bottom"
    container="body"
  >
    Show suggestions
  </button>
</div>

<ng-template #suggestionContent>
  <div>
    <h5>
      <strong>Patient Suggestion</strong>
    </h5>
    <h6>
      <strong class="text-danger">Selecting a suggestion will overwrite current patient</strong>
    </h6>
    <h6>
      <strong class="text-danger">To find previous patient, remove and search again</strong>
    </h6>
    <div class="list-group border border-danger">
      <!-- Similar Patients -->
      <ng-container *ngIf="similarPatients.patients.length">
        <h6 class="p-1 mb-0">
          <strong>Similar Existing Patients</strong>
        </h6>
        <div *ngFor="let patient of similarPatients.patients" class="list-group-item list-group-item-action">
          <div
            [ngbPopover]="popoverPatient"
            popoverTitle="Patient Details"
            [openDelay]="300"
            popoverClass="patient-form-patient-popover"
            placement="bottom-left"
            triggers="mouseenter:mouseleave"
            role="button"
            (click)="emitExistingPatient(patient)"
          >
            <div class="d-flex">
              <strong>{{ patient.patient_first_name }} {{ patient.patient_last_name }}</strong>
              <div *ngIf="patient.patient_sex" class="text-capitalize ms-auto">{{ patient.patient_sex }}</div>
            </div>
            <div *ngIf="patient.patient_dob">DOB: {{ patient.patient_dob | date: 'dd/MM/yyyy' }}</div>
            <div *ngIf="patient.medicare_number || patient.veteran_file_number">
              Medicare/Veteran No: {{ patient.medicare_number || patient.veteran_file_number }}
            </div>
            <div *ngIf="patient.phone_mobile || patient.phone_home || patient.phone_work || patient.email">
              Phone/Email:
              {{ patient.phone_mobile || patient.phone_home || patient.phone_work || patient.email || 'N/A' }}
            </div>
            <div *ngIf="patient.city || patient.zip">Address: {{ patient.city ?? '' }} {{ patient.zip ?? '' }}</div>

            <ng-template #popoverPatient>
              <rr-patient-popover [patient]="patient"></rr-patient-popover>
            </ng-template>
          </div>
        </div>
      </ng-container>

      <!-- Similar Referral Patients -->
      <ng-container *ngIf="similarReferralPatients.length">
        <h6 class="p-1 mb-0">
          <strong>Referral Patients</strong>
        </h6>
        <div *ngFor="let patient of similarReferralPatients" class="list-group-item list-group-item-action">
          <div
            popoverTitle="Patient Details"
            popoverClass="patient-form-patient-popover"
            placement="bottom-left"
            triggers="mouseenter:mouseleave"
            role="button"
            (click)="emitExistingPatient(patient)"
          >
            <div class="d-flex">
              <strong>{{ patient.given_name }} {{ patient.family_name }}</strong>
              <div *ngIf="patient.sex" class="text-capitalize ms-auto">{{ patient.sex }}</div>
            </div>
            <div *ngIf="patient.date_of_birth">DOB: {{ patient.date_of_birth | date: 'dd/MM/yyyy' }}</div>
            <div *ngIf="patient.medicare_number">Medicare No: {{ patient.medicare_number }}</div>
            <div *ngIf="patient.mobile_number">Phone: {{ patient.mobile_number || 'N/A' }}</div>
            <div *ngIf="patient.patient_address">Address: {{ patient.patient_address }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
