import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ScanCodeHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.ScanCode[]> {
    return this.http.get<RR.ScanCode[]>('/api/scan_code');
  }

  create(scanCode: Partial<RR.ScanCode>): Observable<RR.ScanCode> {
    return this.http.post<RR.ScanCode>('/api/scan_code', scanCode);
  }

  update(scanCodeId: number, changes: Partial<RR.ScanCode>): Observable<RR.ScanCode> {
    return this.http.put<RR.ScanCode>(`/api/scan_code/${scanCodeId}`, changes);
  }

  delete(scanCodeId: number) {
    return this.http.delete<RR.ScanCode>(`/api/scan_code/${scanCodeId}`);
  }
}
