import { createAction, props } from '@ngrx/store';
import { PatientActions } from 'app/store/patient';

import { createBatchAction } from '../../batching.action';
import { ReportActions } from '../../report/report/report.action';

/**
 * Ngrx Actions for PatientQuestionAnswer
 *
 */
export const PatientQuestionAnswerActions = {
  findManyInReportSuccess: createAction(
    '[PatientQuestionAnswer] Find Many In Report Success',
    props<{ reportId: number; patientQuestionAnswers: RR.PatientQuestionAnswer[] }>(),
  ),

  createSuccess: createAction(
    '[PatientQuestionAnswer] Create Success',
    props<{ patientQuestionAnswer: RR.PatientQuestionAnswer }>(),
  ),

  updateSuccess: createAction(
    '[PatientQuestionAnswer] Update Success',
    props<{ patientQuestionAnswer: RR.PatientQuestionAnswer }>(),
  ),

  deleteSuccess: createAction('[PatientQuestionAnswer] Delete Success', props<{ patientQuestionAnswerId: number }>()),
};

export const PatientQuestionAnswerBatchActions = {
  createSuccessBatchAction: createBatchAction(
    '[PatientQuestionAnswer] Create Success Batch',
    props<{
      actions: {
        createPatientQuestionAnswerSuccess: ReturnType<typeof PatientQuestionAnswerActions.createSuccess>;
        updateReportSuccess?: ReturnType<typeof ReportActions.upsertOne>;
        updatePatientSuccess?: ReturnType<typeof PatientActions.updateSuccess>;
      };
    }>(),
  ),
  deleteSuccessBatchAction: createBatchAction(
    '[PatientQuestionAnswer] Delete Success Batch',
    props<{
      actions: {
        deletePatientQuestionAnswerSuccess: ReturnType<typeof PatientQuestionAnswerActions.deleteSuccess>;
        updateReportSuccess?: ReturnType<typeof ReportActions.upsertOne>;
        updatePatientSuccess?: ReturnType<typeof PatientActions.updateSuccess>;
      };
    }>(),
  ),
};
