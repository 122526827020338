<div class="modal-header">
  <h5 class="modal-title">RadReport Tutorials (O)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p>How do I use RadReport?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I use RadReport'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I Create a report from a Template?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I Create a report from a Template'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I search for a Statement to Add to a Report?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I search for a Statement to Add to a Report'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I add numbers or values to a Statement?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I add numbers or values to a statement'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I use the multiple Regions feature?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I use the multiple Regions feature'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I check and View the final Report?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I check and View the final Report'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I view Images belonging to a Report?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I view Images belonging to a Report'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I view old reports?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I view old reports'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I see who else is editing a report?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I see who else is editing a report'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I leave edit messages?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I leave edit messages'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I Prefill the Report from a saved Template?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I Prefill the Report from a saved Template'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How to Process and Distribute a report?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How to Process and Distribute a report'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>How do I see the latest Features added to RadReport?</p>
  <ul>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'How do I see the latest Features added to RadReport'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
  <p>Statement categorisation</p>
  <ul>
    <li>
      <a href="https://docs.google.com/document/d/1-P3VImC6bxXNJREpWbyFFRnwZwMXa0su-VdvpmJdfRI/edit" target="_blank"
        >How to categorise statements (Google Docs)?</a
      >
    </li>
    <ng-container *ngFor="let object of tutorial_objects; let i = index">
      <li *ngIf="object.TUTORIAL_SECTION === 'Sentence categorisation'">
        <a href="javascript:void(0)" (click)="openVideoModal(object)">{{ object.TUTORIAL_TITLE }}</a>
      </li>
    </ng-container>
  </ul>
</div>
