<ng-container *ngIf="rrConfig">
  <rr-navigation></rr-navigation>
  <rr-navbar *ngIf="showToolbar"></rr-navbar>
  <rr-toast-list></rr-toast-list>
  <div class="rr-view-content d-flex flex-grow-1">
    <router-outlet (deactivate)="onDeactivate($event)"></router-outlet>
  </div>
  <div class="staging-message staging-left" *ngIf="rrConfig.environment === 'staging'">Staging</div>
  <div class="staging-message staging-right" *ngIf="rrConfig.environment === 'staging'">Staging</div>
</ng-container>
<!-- Portal to the bottom of the body for the screenshot dialog so that it hovers above everything -->
<ng-template [cdkPortalOutlet]="screenshotService.componentPortal"></ng-template>
