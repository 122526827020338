import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromSubsection } from '../template/subsection/subsection.selector';
import { fromTemplate } from '../template/template/template.selector';

const adapter = createEntityAdapter<RR.TagSearchTerm>();
const selectFeature = (state: AppState) => state.tag_search_term;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInTemplate = (templateId: number) =>
  createSelector(
    fromTemplate.selectTemplate(templateId),
    selectEntities,
    (template, tagSearchTerms) =>
      template?.tag_search_terms
        .map((tagSearchTermId) => tagSearchTerms[tagSearchTermId])
        .filter((tagSearchTerm): tagSearchTerm is RR.TagSearchTerm => !!tagSearchTerm) || [],
  );

const selectInSubsection = (subsectionId: number) =>
  createSelector(
    fromSubsection.selectSubsection(subsectionId),
    selectEntities,
    (subsection, tagSearchTerms) =>
      subsection?.tag_search_terms
        .map((tagSearchTermId) => tagSearchTerms[tagSearchTermId])
        .filter((tagSearchTerm): tagSearchTerm is RR.TagSearchTerm => !!tagSearchTerm) || [],
  );

export const fromTagSearchTerm = {
  selectIds,
  selectEntities,
  selectAll,
  selectInTemplate,
  selectInSubsection,
};
