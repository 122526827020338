import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ReportStatusActions } from './report-status.action';

export type ReportStatusState = EntityState<RR.ReportStatus> & {
  loaded: boolean;
};

const adapter = createEntityAdapter<RR.ReportStatus>();

const initialState = adapter.getInitialState({
  loaded: false,
});

export const reportStatusReducer = createReducer(
  initialState,
  on(ReportStatusActions.findAllSuccess, (state: ReportStatusState, { reportStatuses }) => ({
    ...adapter.upsertMany(reportStatuses, state),
    loaded: true,
  })),
);
