import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ReportInvoicesComponent } from 'app/modules/registration/components/report-invoices/report-invoices.component';
import { AppState } from 'app/store';
import { fromInvoice } from 'app/store/invoice';
import { Subscription, take } from 'rxjs';

import { CreateInvoiceModalComponent } from '../create-invoice-modal/create-invoice-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReportInvoicesComponent],
  selector: 'rr-report-invoices-modal',
  templateUrl: './report-invoices-modal.component.html',
  styleUrls: ['./report-invoices-modal.component.css'],
})
export class ReportInvoicesModalComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() editDraft: boolean;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(fromInvoice.selectInReport(this.reportId))
        .pipe(take(1))
        .subscribe((invoices) => {
          const draft = invoices.find((i) => i.is_draft && !i.deleted);
          const nonDeletedInvoices = invoices.find((i) => !i.deleted);
          if (draft && this.editDraft) {
            // Open draft invoice for editing
            CreateInvoiceModalComponent.open(this.modalService, this.reportId, undefined, draft.id);
          } else if (!nonDeletedInvoices) {
            // Open create-new-invoice if all invoices are deleted.
            this.createNewInvoice();
          }
        }),
    );
  }

  createNewInvoice() {
    CreateInvoiceModalComponent.open(this.modalService, this.reportId);
  }

  static open(modal: NgbModal, reportId: number, editDraft = false) {
    const modalRef = modal.open(ReportInvoicesModalComponent, { windowClass: 'invoices-modal' });
    const component: ReportInvoicesModalComponent = modalRef.componentInstance;
    component.reportId = reportId;
    //  Auto open draft invoice to edit
    component.editDraft = editDraft;

    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
