import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromProviderNumber } from 'app/store/provider-number';
import { fromSite } from 'app/store/site';
import { fromUser } from 'app/store/user/user';
import { combineLatest, Observable, Subscription } from 'rxjs';

declare interface ProviderNumberView extends RR.ProviderNumber {
  site_name?: string;
  user_name?: string;
}

@Component({
  selector: 'rr-medicare-provider',
  templateUrl: './medicare-provider.component.html',
  styleUrls: ['./medicare-provider.component.css'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: MedicareProviderComponent, multi: true }],
  standalone: true,
  imports: [CommonModule, FormsModule],
})
export class MedicareProviderComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() @BindObservable() site_id: number | null;
  site_id$: Observable<number | null>;

  disabled: boolean;
  providerNumbers$: Observable<RR.ProviderNumber[]>;
  providerNumbers: ProviderNumberView[];
  filteredProviderNumbers: ProviderNumberView[];
  users$: Observable<RR.User[]>;
  sites$: Observable<RR.Site[]>;

  subscription: Subscription = new Subscription();

  selectedProviderId: number | null = null;
  selectedProvider: ProviderNumberView | undefined = undefined;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.providerNumbers$ = this.store.select(fromProviderNumber.selectAll).pipe(filterDefined());
    this.users$ = this.store.select(fromUser.selectAll);
    this.sites$ = this.store.select(fromSite.selectActiveSites);

    this.subscription.add(
      this.providerNumbers$.subscribe((providerNumbers) => {
        this.providerNumbers = providerNumbers;
        // Sync the selectedProviderId to the selectedProvider when new data comes in
        this.findProviderById();
      }),
    );

    this.subscription.add(
      combineLatest([this.providerNumbers$, this.users$, this.sites$, this.site_id$]).subscribe(
        ([providerNumbers, users, sites, selectedSiteId]) => {
          // Filter provider numbers based on the selected site
          this.filteredProviderNumbers = this.updateProviderNumbers(
            providerNumbers.filter((p) => p.site_id === selectedSiteId),
            sites,
            users,
          );
        },
      ),
    );

    // Switching sites invalidates the selected provider
    this.subscription.add(
      this.site_id$.subscribe((selectedSiteId) => {
        if (selectedSiteId !== null && this.selectedProvider && this.selectedProvider.site_id !== selectedSiteId) {
          // avoid ExpressionChangedAfterItHasBeenCheckedError error
          requestAnimationFrame(() => {
            this.onSelectionChange(null);
          });
        }
      }),
    );
  }

  updateProviderNumbers(
    providerNumbers: RR.ProviderNumber[],
    sites: RR.Site[],
    users: RR.User[],
  ): ProviderNumberView[] {
    return providerNumbers.map((p) => {
      const site_name = sites.find((site) => site.id === p.site_id)?.short_name;
      const user_name = users.find((user) => user.id === p.user_id)?.name;
      return { ...p, site_name, user_name };
    });
  }

  // onChange and onTouch are for the component to communicate back with the parent form when input is touched or value changed
  // @ts-expect-error noImplicitAny
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onChange = (_) => {
    // Do nothing
  };
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onTouch = () => {
    // Do nothing
  };

  writeValue(providerId: number | null): void {
    this.selectedProviderId = providerId;
    // Sync the selectedProviderId to the selectedProvider
    this.findProviderById();
  }

  // @ts-expect-error noImplicitAny
  registerOnChange(fn: (_) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Handle doctor selection change
  onSelectionChange(providerId: number | null) {
    this.writeValue(providerId);
    // Call onChange to update the form
    this.onChange(providerId);
  }

  findProviderById(): void {
    const selectedProvider = this.providerNumbers.find((p) => p.id === this.selectedProviderId);
    this.selectedProvider = selectedProvider;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
