import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EditorActions } from 'app/store/editor';

import { PresetTitleActions } from './preset-title.action';

export interface PresetTitleState extends EntityState<RR.PresetTitle> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.PresetTitle>();
const initialState = adapter.getInitialState({ loaded: false });

export const PresetTitleReducer = createReducer(
  initialState,
  on(PresetTitleActions.findAllSuccess, (state: PresetTitleState, { preset_titles }) =>
    adapter.upsertMany(preset_titles, { ...state, loaded: true }),
  ),
  on(PresetTitleActions.updateSuccess, (state: PresetTitleState, { preset_title }) =>
    adapter.upsertOne(preset_title, state),
  ),
  on(PresetTitleActions.findSuccess, (state: PresetTitleState, { preset_title }) =>
    adapter.upsertOne(preset_title, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
