<ng-container *ngIf="(!invoiceId || reInvoice) && (report$ | async) as report">
  <rr-invoice-form-metadata [reportId]="report.id"></rr-invoice-form-metadata>
</ng-container>

<ng-container *ngIf="!reInvoice && (invoice$ | async) as invoice">
  <div class="mb-2">
    <span>Reports</span>
  </div>

  <cdk-accordion class="accordion">
    <cdk-accordion-item
      *ngFor="let report_id of invoice.reports"
      #accordionItem="cdkAccordionItem"
      class="card"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + report_id"
    >
      <div
        class="card-header d-flex justify-content-between align-items-center border-0"
        (click)="accordionItem.toggle()"
      >
        <!-- Display accession_number without using reportDictionary[report_id].accession_number hack -->
        <div>
          <span>Report</span>
          <ng-container *ngIf="report_id | storeSelectPipe: reportSelectorFn | async as report">
            <rr-report-accession-number [report]="report"></rr-report-accession-number>
          </ng-container>
        </div>
        <div>
          <button
            *ngIf="invoice.reports.length > 1"
            class="btn btn-sm btn-outline-danger me-3"
            (click)="unlinkReport(report_id); $event.stopPropagation()"
          >
            Remove
          </button>
          <i class="align-self-center" [ngClass]="accordionItem.expanded ? 'fas fa-angle-up' : 'fas fa-angle-down'"></i>
        </div>
      </div>
      <div
        class="card-body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + report_id"
      >
        <rr-invoice-form-metadata
          [reportId]="report_id"
          [invoiceId]="invoiceId"
          [reInvoice]="reInvoice"
        ></rr-invoice-form-metadata>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</ng-container>
