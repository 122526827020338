import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PercentileBandRuleActions } from './percentile-band-rule.action';
import { PercentileBandRule } from './percentile-band-rule.service';

export type PercentileBandRuleState = EntityState<PercentileBandRule>;

const adapter = createEntityAdapter<PercentileBandRule>();
const initialState = adapter.getInitialState();

export const percentileBandRuleReducer = createReducer(
  initialState,
  on(PercentileBandRuleActions.searchSuccess, (state: PercentileBandRuleState, { percentile_band_rules }) => {
    return adapter.upsertMany(percentile_band_rules, state);
  }),
  on(PercentileBandRuleActions.createSuccess, (state: PercentileBandRuleState, { percentile_band_rule }) =>
    adapter.addOne(percentile_band_rule, state),
  ),
  on(PercentileBandRuleActions.deleteSuccess, (state: PercentileBandRuleState, { percentile_band_rule_id }) =>
    adapter.removeOne(percentile_band_rule_id.toString(), state),
  ),
  on(PercentileBandRuleActions.updateSuccess, (state: PercentileBandRuleState, { percentile_band_rule }) =>
    adapter.upsertOne(percentile_band_rule, state),
  ),
  on(EditorActions.open, EditorActions.close, () => initialState),
);
