import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { StatementUsages } from './statement-choice-usage-analytics.service';

const adapter = createEntityAdapter<StatementUsages>();
const selectFeature = (state: AppState) => state.statement_choice_usage_analytics;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = (statementChoiceId: number) =>
  createSelector(selectFeature, (state) => state.loaded[statementChoiceId] || false);

const selectReportsByStatementChoiceId = (statementChoiceId: number) =>
  createSelector(selectFeature, (state) => state.statementChoiceIdMapping[statementChoiceId]);

export const fromStatementChoiceUsageAnalytics = {
  selectEntities,
  selectAll,
  selectIds,
  selectLoaded,
  selectReportsByStatementChoiceId,
};
