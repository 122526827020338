import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { IssueModalComponent } from './issue-modal/issue-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ScreenshotService {
  componentPortal?: ComponentPortal<IssueModalComponent>;

  openDialog() {
    this.componentPortal = new ComponentPortal(IssueModalComponent);
  }

  dismissDialog() {
    this.componentPortal = undefined;
  }

  async takeScreenshot() {
    // Taken from https://eladalon1983.github.io/gcbcm-demo/
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.hidden = true;
    document.body.append(canvas);

    function drawCanvas(canvas: HTMLCanvasElement, img: ImageBitmap) {
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');
      if (!context) {
        throw new Error('no 2d canvas context');
      }
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
    }

    type ChromeOnlyOptions = {
      preferCurrentTab: boolean;
    };
    const options: MediaStreamConstraints & ChromeOnlyOptions = {
      audio: false,
      video: true,
      preferCurrentTab: true,
    };
    const stream: MediaStream = await navigator.mediaDevices.getDisplayMedia(options);
    const track = stream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(track);
    const imageBitmap = await imageCapture.grabFrame();
    drawCanvas(canvas, imageBitmap);
    track.stop();

    const blob = await new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        // This converts the callback to a promise that can be awaited
        resolve(blob);
      });
    });
    canvas.remove();
    return blob;
  }
}
