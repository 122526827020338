<div class="d-flex align-items-center w-100" (click)="onNoteClicked()">
  <span *ngIf="note.note_type === 'search_clipboard'" rrTooltip="ADDED_VIA_SEARCH_CLIPBOARD">
    <i class="fas fa-search me-1"></i>
  </span>
  <span *ngIf="note.note_type === 'general'" rrTooltip="ADDED_VIA_VOICE">
    <i class="fas fa-microphone me-1"></i>
  </span>
  <span *ngIf="note.successful_recommendation === false" rrTooltip="VOICE_NOTE_RECOMMENDED_FAILED" class="text-danger">
    <i class="fas fa-bolt me-1"></i>
  </span>
  <span *ngIf="note.successful_recommendation === true" rrTooltip="VOICE_NOTE_RECOMMENDED" class="text-success">
    <i class="fas fa-bolt me-1"></i>
  </span>
  <button
    rrTooltip="VOICE_NOTE_RECOMMENDED_RERUN"
    role="button"
    class="btn btn-sm py-0 px-1 me-1"
    (click)="$event.stopPropagation(); onRetrySearch.emit(note)"
  >
    <span class="fa fa-redo text-secondary"></span>
  </button>
  <span
    #contenteditableElement
    [attr.contenteditable]="editMode"
    (keydown.enter)="$event.preventDefault(); updateNote(note.id)"
  >
    {{ note.text }}
  </span>
  <div class="d-flex flex-nowrap ms-auto">
    <button
      *ngIf="note.subsection !== 'NO NAME'"
      role="button"
      class="btn btn-sm btn-outline-secondary py-0 me-1 text-nowrap"
      rrTooltip="INSERT_INTO_SOABS"
      (click)="insertIntoSoabs(note.text)"
    >
      <i class="fas fa-search-plus"></i>
      <!-- SOABS: Search or Add Blue Statmenet -->
      SOABS
    </button>
    <button
      *ngIf="note.subsection !== 'NO NAME'"
      role="button"
      class="btn btn-sm btn-outline-secondary py-0 text-nowrap"
      (click)="$event.stopPropagation(); addTagSearchTerm(note)"
      rrTooltip="PERSIST_VOICE_NOTE"
    >
      <span class="fa fa-plus"></span>
      Persist
    </button>
    <button
      *ngIf="!editMode"
      role="button"
      class="btn btn-sm py-0 px-1"
      (click)="$event.stopPropagation(); editNote()"
      rrTooltip="EDIT_VOICE_NOTE"
    >
      <span class="fa fa-edit text-secondary"></span>
    </button>
    <button
      *ngIf="editMode"
      role="button"
      class="btn btn-sm py-0 px-1"
      rrTooltip="PERSIST_VOICE_NOTE"
      (click)="$event.stopPropagation(); updateNote(note.id)"
    >
      <span class="fa fa-check text-success"></span>
    </button>
    <button
      rrTooltip="DELETE_VOICE_NOTE"
      role="button"
      class="btn btn-sm py-0 px-1"
      (click)="$event.stopPropagation(); deleteVoiceNote(note.id)"
    >
      <span class="fa fa-trash-alt text-danger"></span>
    </button>
  </div>
</div>
