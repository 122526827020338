import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { UserActions } from '../../user/user/user.action';
import { AuditEventActions } from '../audit-event/audit-event.action';

/**
 * Ngrx Actions for Todo (Report Note)
 *
 */
export const TodoActions = {
  findManySuccess: createAction('[Todo] Find Many Success', props<{ todos: RR.Todo[] }>()),

  createSuccess: createAction('[Todo] Create Success', props<{ todo: RR.Todo }>()),

  updateSuccess: createAction('[Todo] Update Success', props<{ todo: RR.Todo }>()),

  deleteSuccess: createAction('[Todo] Delete Success', props<{ todoId: number }>()),
};

export const TodoBatchActions = {
  createTodoBatchSuccess: createBatchAction(
    '[Todo] Create And Update Todo Success Batch',
    props<{
      actions: {
        createTodoSuccess: ReturnType<typeof TodoActions.createSuccess>;
        createAuditEventSuccess: ReturnType<typeof AuditEventActions.findManySuccess>;
        createUserSuccess: ReturnType<typeof UserActions.findAllSuccess>;
      };
    }>(),
  ),

  updateTodoBatchSuccess: createBatchAction(
    '[Todo] Update Todo Success Batch',
    props<{
      actions: {
        updateTodoSuccess: ReturnType<typeof TodoActions.updateSuccess>;
        updateAuditEventSuccess: ReturnType<typeof AuditEventActions.findManySuccess>;
        updateUserSuccess: ReturnType<typeof UserActions.findAllSuccess>;
      };
    }>(),
  ),
};
