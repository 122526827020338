import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromTopic } from 'app/store/report/topic';
import { VoiceNoteEffect } from 'app/store/report/voice-note';
import { TagSearchTermEffect } from 'app/store/tag-search-term';
import { filter, Observable, Subscription, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'rr-voice-note-item',
  templateUrl: './voice-note-item.component.html',
  styleUrls: ['./voice-note-item.component.css'],
})
export class VoiceNoteItemComponent implements OnInit, OnDestroy {
  @ViewChild('contenteditableElement') contenteditableElement: ElementRef;

  @Input() topicId: number | undefined;
  @Input() note: RR.VoiceNote;
  @Input() @BindObservable() subsections: RR.Subsection[] | undefined;
  subsections$: Observable<RR.Subsection[] | undefined>;

  @Output() onSelectNote = new EventEmitter();
  @Output() onRetrySearch = new EventEmitter();

  subscription = new Subscription();

  editMode = false;
  template_id: number;

  constructor(
    private store: Store<AppState>,
    private voiceNoteEffect: VoiceNoteEffect,
    private tagSearchTermEffect: TagSearchTermEffect,
    private editorService: EditorService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.topicId) {
      this.subscription.add(
        this.store
          .select(fromTopic.selectTopic(this.topicId))
          .pipe(filterDefined())
          .subscribe((topic) => {
            this.template_id = topic.template_id;
          }),
      );
    }

    this.subscription.add(
      this.subsections$
        .pipe(
          filter((subsections) => !!subsections?.length),
          take(1),
        )
        .subscribe((subsections) => {
          this.subsections = subsections;
        }),
    );
  }

  deleteVoiceNote(voiceNoteId: number) {
    this.subscription.add(this.voiceNoteEffect.delete(voiceNoteId).subscribe());
  }

  editNote() {
    this.editMode = true;
    const currentElement = this.contenteditableElement.nativeElement;

    // setTimeout used to make sure note has contenteditable attribute before trying to focus.
    setTimeout(() => {
      currentElement.focus();
    }, 100);
  }

  updateNote(voiceNoteId: number) {
    const text = this.contenteditableElement.nativeElement.innerText;
    this.subscription.add(
      this.voiceNoteEffect
        .update(voiceNoteId, {
          text,
        })
        .subscribe(),
    );

    this.editMode = false;
  }

  onNoteClicked() {
    if (this.editMode) return;
    this.onSelectNote.emit(this.note);
  }

  insertIntoSoabs(noteText: string) {
    this.editorService.togglePrefill(false);
    this.editorService.triggerBlueStatementSearch();
    this.editorService.searchTextInSoabs(noteText);
    this.activeModal.close();
  }

  addTagSearchTerm(note: RR.VoiceNote) {
    if (!note.subsection) {
      this.subscription.add(
        this.tagSearchTermEffect.createInTemplate(this.template_id, { text: note.text }).subscribe(),
      );
    } else if (note.subsection !== 'NO NAME' && this.subsections && this.subsections.length) {
      const subsections = this.subsections.filter((subsection) => subsection.name === note.subsection);
      // TODO(section): this was filtered by `subsection.template_id === this.template_id` in the old code, unsure why.
      const subsection = subsections[0];
      this.subscription.add(
        this.tagSearchTermEffect.createInSubsection(subsection.id, { text: note.text }).subscribe(),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
