<div *ngIf="parent === 'OTHER'" class="form-group form-check mb-2">
  <input
    type="checkbox"
    class="form-check-input mt-1"
    id="showDeleted"
    [checked]="showDeletedInvoices"
    (click)="toggleDeletedInvoices()"
  />
  <label class="form-check-label" for="showDeleted">Show deleted invoices</label>
</div>
<ng-container *ngIf="invoices$ | async as invoices">
  <table class="table table-sm table-striped">
    <thead>
      <tr>
        <th [style.width.%]="5">#</th>
        <th [hidden]="parent === 'OTHER'" [style.width.%]="17.5">Reports and Bookings</th>
        <th [style.width.%]="15">Items</th>
        <th [style.width.%]="15">Funder</th>
        <th [style.width.%]="7.5">Adj</th>
        <th [style.width.%]="7.5">Amount Paid</th>
        <th [style.width.%]="5">State</th>
        <th [style.width.%]="15">Status</th>
        <th [style.width.%]="22.5">Actions</th>
      </tr>
    </thead>

    <tbody>
      <tr
        *ngFor="let invoice of invoices"
        [class.text-decoration-line-through]="invoice.deleted"
        [class.d-none]="parent === 'OTHER' && !showDeletedInvoices && invoice.deleted"
        [class.text-secondary]="invoice.deleted || invoice.is_draft"
      >
        <td>
          <strong>{{ invoice.id }}</strong>
        </td>
        <td [hidden]="parent === 'OTHER'">
          <div>
            <ng-container *ngIf="invoice.patient_first_name">{{ invoice?.patient_first_name }} </ng-container>
            <ng-container *ngIf="invoice.patient_last_name">{{ invoice?.patient_last_name }}</ng-container>
            <button
              role="button"
              class="btn text-secondary p-0 pb-1 ms-1"
              ngbTooltip="Search by patient"
              (click)="
                searchByPatient({
                  patient_first_name: invoice.patient_first_name,
                  patient_last_name: invoice.patient_last_name
                })
              "
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          <div class="d-flex flex-wrap">
            <ng-container *ngFor="let report_id of invoice.reports">
              <ng-container *ngIf="report_id | storeSelectPipe: reportSelectorFn | async as report">
                <a class="me-2" target="_blank" [routerLink]="['/report', report.id, 'edit']">
                  <rr-report-accession-number [report]="report"></rr-report-accession-number>
                </a>
                <rr-registration-link [btnClass]="undefined" [btnStatus]="false" [newTab]="true" [report]="report">
                </rr-registration-link>
              </ng-container>
            </ng-container>
          </div>
        </td>
        <td>
          <ng-container *ngFor="let item_id of invoice.invoice_items">
            <div
              *ngIf="item_id | storeSelectPipe: invoiceItemSelectorFn | async as item"
              [class.text-danger]="item.medipass_status === 'rejected'"
            >
              {{ item.item_code }} - ${{ item.amount_expected }}
              <span *ngIf="item.amount_paid || item.medipass_status"
                >(${{ item.amount_paid }} {{ item.medipass_status ? itemStatus[item.medipass_status] : '' }})</span
              >
            </div>
          </ng-container>
        </td>
        <td>
          <div
            *ngIf="
              invoice.institution_id &&
              (invoice.institution_id | storeSelectPipe: institutionSelectorFn | async) as institution
            "
          >
            <strong>
              {{ institution.name }}
            </strong>
          </div>
          <div *ngIf="invoice.funder">{{ funders[invoice.funder] }}</div>
        </td>
        <td>
          <span>${{ invoice.adjustment }}</span>
        </td>
        <td>
          <span>${{ invoice.amount_paid }}</span>
        </td>
        <td>
          <span *ngIf="invoice.deleted && !invoice.is_draft">Deleted</span>
          <span *ngIf="invoice.deleted && invoice.is_draft">Deleted draft</span>
          <span *ngIf="!invoice.deleted && invoice.is_draft">Draft</span>
          <span *ngIf="!invoice.deleted && !invoice.is_draft">Ready</span>
        </td>
        <td>
          <div class="d-flex flex-column">
            <div *ngIf="invoice.invoice_paid">Paid: {{ invoice.invoice_paid | date: 'dd/MM/yyyy' }}</div>
            <div
              class="d-flex badge"
              [ngClass]="{
                'bg-light text-dark': invoice.is_draft && invoice.status,
                'bg-success':
                  !invoice.deleted &&
                  !invoice.is_draft &&
                  (invoice.status === 'paid' ||
                    invoice.medipass_claim_status === 'paid' ||
                    invoice.medipass_claim_status === 'accepted' ||
                    invoice.medipass_claim_status === 'approved'),
                'bg-secondary':
                  !invoice.deleted &&
                  !invoice.is_draft &&
                  !invoice.status &&
                  !invoice.medipass_transaction_status &&
                  !invoice.medipass_claim_status,
                'bg-danger':
                  !invoice.deleted &&
                  !invoice.is_draft &&
                  (invoice.medipass_claim_status === 'rejected' || invoice.medipass_transaction_status === 'cancelled'),
                'bg-warning':
                  !invoice.deleted &&
                  !invoice.is_draft &&
                  (invoice.status === 'outstanding' ||
                    invoice.medipass_claim_status === 'submitted' ||
                    invoice.medipass_claim_status === 'under-review'),
                'bg-info': !invoice.deleted && !invoice.is_draft && invoice.medipass_transaction_status === 'pending'
              }"
            >
              <span *ngIf="invoice.medipass_transaction_status || invoice.medipass_claim_status">
                <strong>Bulk-bill: </strong>
                <span *ngIf="invoice.medipass_claim_status">{{
                  medipassClaimStatus[invoice.medipass_claim_status]
                }}</span>
                <span *ngIf="!invoice.medipass_claim_status && invoice.medipass_transaction_status">{{
                  medipassTransactionStatus[invoice.medipass_transaction_status]
                }}</span>
              </span>
              <span *ngIf="!invoice.medipass_transaction_status && !invoice.medipass_claim_status && invoice.status">
                <strong>Patient-claim: </strong>
                {{ status[invoice.status] }}
              </span>
              <span
                *ngIf="
                  !invoice.deleted &&
                  !invoice.is_draft &&
                  !invoice.status &&
                  !invoice.medipass_transaction_status &&
                  !invoice.medipass_claim_status
                "
              >
                None
              </span>
            </div>
          </div>
        </td>
        <td>
          <div *ngIf="!invoice.deleted">
            <button
              *ngIf="invoice.is_draft"
              class="btn btn-info mb-1"
              (click)="promoteInvoice(invoice.id)"
              title="Convert from draft to active invoice"
            >
              <span class="fa fa-arrow-up"></span> Finalise Draft
            </button>
            <div *ngIf="!invoice.is_draft && invoice.funder === 'medicare'" class="mb-1">
              <rr-invoice-medipass [invoice_id]="invoice.id"></rr-invoice-medipass>
            </div>
          </div>
          <button
            *ngIf="invoice.invoice_items | selectPipe: checkInvoiceItemStatusFn : 'rejected' | async as items"
            class="btn btn-secondary mb-1 me-1"
            title="Re-create Invoice"
            (click)="createNewInvoice(invoice)"
          >
            <span class="fas fa-plus"></span>
            ReInvoice
          </button>
          <button
            *ngIf="
              invoice.medipass_claim_status === 'rejected' ||
              (invoice.invoice_items | selectPipe: checkInvoiceItemStatusFn : 'rejected' | async)
            "
            role="button"
            class="btn mb-1"
            [ngClass]="invoice.no_further_action_required ? 'btn-success' : 'btn-secondary'"
            (click)="markComplete(invoice)"
            ngbTooltip="Mark complete"
          >
            <ng-container *ngIf="!invoice.no_further_action_required; else invoiceComplete">
              <span><i class="fas fa-times"></i></span>
            </ng-container>
            <ng-template #invoiceComplete>
              <span><i class="fas fa-check"></i></span>
            </ng-template>
          </button>
          <div class="mb-1">
            <button class="btn btn-info me-1" (click)="viewInvoice(invoice.id)" title="View Invoice">
              <span class="fa fa-eye"></span>
            </button>
            <button
              *ngIf="!invoice.deleted"
              class="btn btn-info me-1"
              (click)="editInvoice(invoice)"
              title="Edit Invoice"
            >
              <span class="fa fa-edit"></span>
            </button>
            <button
              class="btn btn-danger me-1"
              (click)="deleteInvoice(invoice)"
              *ngIf="!invoice.deleted && !invoice.medipass_transaction_status"
              title="Delete Invoice"
            >
              <span class="fa fa-trash"></span>
            </button>
          </div>
          <rr-db4-form-invoice [invoiceId]="invoice.id"></rr-db4-form-invoice>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="(invoices$ | async)?.length === 0">
  <h1 class="ms-2">No invoices to show...</h1>
</ng-container>
