import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { fromAttributeSet } from 'app/store/template/attribute-set';
import { Observable, Subscription } from 'rxjs';

import { AttributeMatch } from '../bruteForceAttributeSearch';

@Component({
  selector: 'rr-attribute-match',
  standalone: true,
  imports: [CommonModule, StoreSelectPipe],
  templateUrl: './attribute-match.component.html',
  styleUrls: ['./attribute-match.component.css'],
})
export class AttributeMatchComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() match: AttributeMatch;
  match$: Observable<AttributeMatch>;
  @Output() onMouseDown = new EventEmitter<MouseEvent>();

  selectAttributeSet = fromAttributeSet.selectAttributeSet;

  subscription = new Subscription();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.subscription.add(
      this.match$.subscribe((match) => {
        this.el.nativeElement.title = `Attribute: ${match.attributeOption.text}\nSet: ${match.attributeSetId}\nFrequency: ${match.attributeOption.frequency}`;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('mousedown', ['$event'])
  _onMouseDown(event: MouseEvent) {
    // Prevent the editor from losing focus and the selection.
    event.preventDefault();
    this.onMouseDown.emit(event);
  }
}
