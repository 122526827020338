import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { PathologyActions } from 'app/store/pathology';
import { BodyPartActions } from 'app/store/template/body-part';

export const PresetTitleActions = {
  findSuccess: createAction('[PresetTitle] Find Success', props<{ preset_title: RR.PresetTitle }>()),
  findAllSuccess: createAction('[PresetTitle] Find All Success', props<{ preset_titles: RR.PresetTitle[] }>()),
  updateSuccess: createAction('[PresetTitle] Update Success', props<{ preset_title: RR.PresetTitle }>()),
};

export const PresetTitleBatchActions = {
  findSuccess: createBatchAction(
    '[PresetTitle] Find Success Batch',
    props<{
      actions: {
        presetTitle: ReturnType<typeof PresetTitleActions.findSuccess>;
        bodyPart: ReturnType<typeof BodyPartActions.findManySuccess>;
        pathology: ReturnType<typeof PathologyActions.findManySuccess>;
      };
    }>(),
  ),
};
