import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromRouter } from 'app/store/router/router.selector';

import { fromElementChoice } from '../element-choice/element-choice.selector';
import { fromJustifications } from '../justification/justification.selector';
import { fromRegionChoice } from '../region-choice/region-choice.selector';
import { fromSectionChoice } from '../section-choice/section-choice.selector';
import { fromSubsectionChoice } from '../subsection-choice/subsection-choice.selector';

const adapter = createEntityAdapter<RR.Topic>();
const selectFeature = (state: AppState) => state.topic;

const { selectEntities } = adapter.getSelectors(selectFeature);

// @ts-expect-error strictNullChecks
const selectCurrentTopic = createSelector(
  selectEntities,
  fromRouter.selectTopicId,
  (topics: Dictionary<RR.Topic>, topicId?: number) => (topicId !== undefined ? topics[topicId] : undefined),
);

const selectTopic = (topicId: number) =>
  createSelector(selectEntities, (topics: Dictionary<RR.Topic>) => topics[topicId]);

const selectSectionChoices = (topicId: number, sectionId: number) =>
  createSelector(
    fromTopic.selectTopic(topicId),
    fromSectionChoice.selectEntities,
    (topic: RR.Topic | undefined, sectionChoices: Dictionary<RR.SectionChoice>) =>
      topic?.section_choices
        .map((id) => sectionChoices[id])
        .filter((sc): sc is RR.SectionChoice => sc !== undefined)
        .filter((sectionChoice) => sectionChoice.section_id === sectionId) || [],
  );

const selectSubsectionChoices = (topicId: number, sectionId: number, subsectionId: number) =>
  createSelector(
    selectSectionChoices(topicId, sectionId),
    fromSubsectionChoice.selectEntities,
    (sectionChoices, subsectionChoices) => {
      return sectionChoices
        .map((sectionChoice) =>
          sectionChoice.subsection_choices
            .map((subsectionChoiceId) => subsectionChoices[subsectionChoiceId])
            .filter((subsectionChoice): subsectionChoice is RR.SubsectionChoice => !!subsectionChoice)
            .filter((subsectionChoice) => subsectionChoice.subsection_id === subsectionId),
        )
        .flat();
    },
  );
const selectRegionChoices = (topicId: number, sectionId: number, subsectionId: number, regionId: number | null) =>
  createSelector(
    selectSubsectionChoices(topicId, sectionId, subsectionId),
    fromRegionChoice.selectEntities,
    (subsectionChoices, regionChoices) => {
      return subsectionChoices
        .map((subsectionChoice) =>
          subsectionChoice.region_choices
            .map((regionChoiceId) => regionChoices[regionChoiceId])
            .filter((regionChoice): regionChoice is RR.RegionChoice => !!regionChoice)
            .filter((regionChoice) => regionChoice.region_id === regionId),
        )
        .flat();
    },
  );
const selectElementChoices = (
  topicId: number,
  sectionId: number,
  subsectionId: number,
  regionId: number | null,
  elementId: number,
) =>
  createSelector(
    selectRegionChoices(topicId, sectionId, subsectionId, regionId),
    fromElementChoice.selectEntities,
    (regionChoices, elementChoices) => {
      return regionChoices
        .map((regionChoice) =>
          regionChoice.element_choices
            .map((elementId) => elementChoices[elementId])
            .filter((elementChoice): elementChoice is RR.ElementChoice => !!elementChoice)
            .filter((elementChoice) => elementChoice.element_id === elementId),
        )
        .flat();
    },
  );

const selectLoaded = (topicId: number) => createSelector(selectFeature, (state) => state.loaded[topicId]);

const selectLoadedCurrentTopic = createSelector(selectCurrentTopic, selectFeature, (topic, state) => {
  return topic ? state.loaded[topic.id] : undefined;
});

const selectJustifications = (topicId: number) =>
  createSelector(selectTopic(topicId), fromJustifications.selectEntities, (topic, justifications) => {
    if (!topic) {
      return [];
    }
    return topic.justifications
      .map((justificationId) => justifications[justificationId])
      .filter((j): j is RR.Justification => !!j);
  });

// const selectCurrentTemplateSubsections = createSelector(selectCurrentTemplateId

// Select multiple topic for prefill
const selectTopics = (topicIds: number[]) =>
  createSelector(selectEntities, (topics: Dictionary<RR.Topic>) =>
    topicIds.map((tid) => topics[tid]).filter((topic) => !!topic),
  );

export const fromTopic = {
  selectEntities,
  selectTopic,
  selectTopics,
  selectLoaded,
  selectJustifications,
};

/**
 * Select entity-choices based on a best effort match against the template structure.
 *
 * These selectors can return undefined if the template's structure no longer matches up with the report's structure.
 * They should return a list with a single entity in it. There's a problem with the data if more than one ElementChoice
 * is found with the same element_id.
 */
export const fromTopicChoices = {
  selectSectionChoices,
  selectSubsectionChoices,
  selectRegionChoices,
  selectElementChoices,
};

/**
 * Select values based on the currently selected topic.
 *
 * This uses the router store to determine the topic that is currently
 * selected and performs the selection accordingly.
 */
export const fromCurrentTopic = {
  selectTopic: selectCurrentTopic,
  selectLoaded: selectLoadedCurrentTopic,
  // Re-Export the router selector here since it makes sense
  selectTopicId: fromRouter.selectTopicId,
};
