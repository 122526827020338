import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocketService } from 'app/core/services/socket.service';
import { SharedModule } from 'app/shared/shared.module';
import { FollowupTaskHttpService } from 'app/store/report/followup-task';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'rr-followup-task-badge',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './followup-task-badge.component.html',
  styleUrls: ['./followup-task-badge.component.css'],
})
export class FollowupTaskBadgeComponent implements OnInit, OnDestroy {
  unprocessedTaskCount = 0;

  subscription: Subscription = new Subscription();
  constructor(
    private followupTaskHttpService: FollowupTaskHttpService,
    private socket: SocketService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.followupTaskHttpService
        .getUnprocessTaskCount()
        .pipe(take(1))
        .subscribe((res: { count: number }) => {
          this.unprocessedTaskCount = res.count;
        }),
    );

    this.subscription.add(
      this.socket.messageReceivedEvent$.subscribe((data) => {
        if (data.type === 'UNPROCESSED_FOLLOWUP_TASK_UPDATE') {
          this.unprocessedTaskCount = data.count ?? 0;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
