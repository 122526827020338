<div class="col-sm-9 mx-auto">
  <h2 class="text-center mb-2">
    <strong>{{ header }}</strong>
  </h2>
  <div *ngIf="selfRegistration" class="mb-2 col">
    <span class="text-info fa fa-asterisk fa-xs me-2"></span>
    <span>Note: Please inform staff if your name or medicare number is incorrect</span>
  </div>
  <div class="d-flex">
    <div class="col-sm-12">
      <!-- Hidden used so form state isn't lost when switching viewMode state -->
      <rr-patient-form-search
        [hidden]="!(!selfRegistration && viewMode === 'search')"
        [bookingPatient]="bookingPatient"
        [removedPatient]="removedPatient"
        (onCreateNewPatient)="createNewPatient()"
        (onUndoRemoval)="cancel()"
        (onSelectPatient)="selectPatient($event)"
      ></rr-patient-form-search>

      <div class="mb-2">
        <div class="badge bg-danger me-2" *ngIf="expiredMedicare && viewMode === 'view'">Expired Medicare</div>
        <div class="badge bg-warning" *ngIf="!formValid && viewMode === 'view'">Missing Details</div>
      </div>

      <rr-patient-form-view
        *ngIf="patient && viewMode === 'view'"
        [invalid]="!formValid"
        [patient]="patient"
        [kiosk]="selfRegistration"
      >
        <ng-container buttons>
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="editPatient()"
            rrTooltip="REGISTRAION_EDIT_PATIENT"
          >
            Edit Patient
          </button>
          <button
            *ngIf="!selfRegistration"
            class="btn btn-outline-primary mt-2"
            (click)="removePatient()"
            rrTooltip="REGISTRAION_REMOVE_PATIENT"
          >
            Remove Patient
          </button>
        </ng-container>
      </rr-patient-form-view>

      <rr-patient-form-edit
        *ngIf="viewMode === 'edit' || viewMode === 'create'"
        [viewMode]="viewMode"
        [parent]="parent"
        [patient]="patient"
        [bookingPatient]="bookingPatient"
        [searchFormPatientDetails]="searchFormPatientDetails"
        [selfRegistration]="selfRegistration"
        (onCancel)="cancel()"
        (onRemove)="removePatient()"
        (onChange)="selectPatient($event)"
      ></rr-patient-form-edit>
    </div>
  </div>
</div>
