import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated use IncludesPipe
 */
@Pipe({
  standalone: true,
  name: 'numberInArray',
})
export class NumberInArrayPipe implements PipeTransform {
  transform(array: number[] | undefined, number: number) {
    if (!array || !number) {
      return false;
    }

    return array.includes(number);
  }
}
