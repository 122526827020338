import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface DoctorFollowupResponse {
  doctor_followup: RR.DoctorFollowup | null;
  report: RR.Report;
}

@Injectable({
  providedIn: 'root',
})
export class DoctorFollowupHttpService {
  constructor(private http: HttpClient) {}

  search(reportId: number) {
    return this.http.get<DoctorFollowupResponse>(`/api/report/${reportId}/doctor_followup`);
  }

  create(reportId: number, changes: RR.DoctorFollowUpPayload) {
    return this.http.post<DoctorFollowupResponse>(`/api/report/${reportId}/doctor_followup`, changes);
  }

  delete(reportId: number) {
    return this.http.delete<DoctorFollowupResponse>(`/api/report/${reportId}/doctor_followup`);
  }
}
