import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTypeaheadSelectItemEvent, NgbTypeahead, NgbPagination, NgbHighlight } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { DatePickerComponent } from 'app/shared/components/date-picker/date-picker.component';
import {
  BooleanParam,
  createEnumParam,
  DateTimeParam,
  decodeQueryParams,
  DelimitedNumericArrayParam,
  encodeQueryParams,
  NumberParam,
  StringParam,
} from 'app/shared/serialise-query-params';
import { AppState } from 'app/store';
import { fromInstitution, InstitutionEffect, InstitutionHttpService } from 'app/store/institution';
import { fromInvoice, InvoiceEffect, InvoiceHttpService, InvoiceSearchBody } from 'app/store/invoice';
import { SiteEffect } from 'app/store/site';
import { add, formatISO } from 'date-fns';
import { saveAs } from 'file-saver-es';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';

import { DatePickerComponent as DatePickerComponent_1 } from '../../../../shared/components/date-picker/date-picker.component';
import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { SiteSelectorComponent } from '../../../worklist/site-selector/site-selector.component';
import { InvoiceTableComponent } from '../../components/invoice-table/invoice-table.component';

type InvoiceSearchStatus =
  | 'any'
  | 'errored'
  | RR.InvoiceStatusType
  | RR.MedipassClaimStatusType
  | RR.MedipassTransactionStatusType;

const queryParamSchema = {
  page: NumberParam,
  patientName: StringParam,
  patientNumber: StringParam,
  institution: StringParam,
  institutionId: NumberParam,
  referrerName: StringParam,
  invoiceNo: NumberParam,
  accessionNumber: StringParam,
  // TODO: undefined does not serialise to query params
  fromDate: DateTimeParam,
  toDate: DateTimeParam,
  anySite: BooleanParam,
  status: createEnumParam<InvoiceSearchStatus>([
    'any',
    'errored',
    'outstanding',
    'paid',
    'submitted',
    'under-review',
    'accepted',
    'approved',
    'rejected',
    'pending',
    'cancelled',
    'completed',
  ]),
  draft: BooleanParam,
  deleted: BooleanParam,
  no_further_action_required: BooleanParam,
  partially_complete: BooleanParam,
  unpaid: BooleanParam,
  unbalanced: BooleanParam,
  funder: createEnumParam<RR.FunderType | 'any'>(['medicare', 'dva', 'private_patient', 'other', 'bupa']),
  sites: DelimitedNumericArrayParam,
};

@Component({
  selector: 'rr-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoFocusDirective,
    NgbTypeahead,
    DatePickerComponent_1,
    SiteSelectorComponent,
    InvoiceTableComponent,
    forwardRef(() => InvoiceTableComponent),
    NgbPagination,
    NgbHighlight,
  ],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  @ViewChild('institutionSearch') institutionSearchElement: ElementRef | undefined;
  @ViewChild(DatePickerComponent) datePickerComponent: DatePickerComponent;
  PAGE_SIZE = 20;
  invoiceCount = 0;
  searchForm = new FormGroup({
    page: new FormControl<number>(1, { nonNullable: true }),
    patientName: new FormControl('', { nonNullable: true }),
    patientNumber: new FormControl('', { nonNullable: true }),
    // There are 2 FormControls for Institution. One for search input. One for the institutionId selected in the typeahead
    institution: new FormControl<string>('', { nonNullable: true }),
    institutionId: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    referrerName: new FormControl('', { nonNullable: true }),
    invoiceNo: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    accessionNumber: new FormControl('', { nonNullable: true }),
    fromDate: new FormControl<Date | undefined>(new Date(), { nonNullable: true }),
    toDate: new FormControl<Date | undefined>(add(new Date(), { days: 1 }), { nonNullable: true }),
    anySite: new FormControl(true, { nonNullable: true }),
    status: new FormControl<InvoiceSearchStatus>('any', { nonNullable: true }),
    draft: new FormControl(true, { nonNullable: true }),
    deleted: new FormControl(false, { nonNullable: true }),
    no_further_action_required: new FormControl(false, { nonNullable: true }),
    partially_complete: new FormControl(false, { nonNullable: true }),
    unpaid: new FormControl(false, { nonNullable: true }),
    unbalanced: new FormControl(false, { nonNullable: true }),
    funder: new FormControl<RR.FunderType | 'any'>('any', { nonNullable: true }),
    sites: new FormControl<number[]>([], { nonNullable: true }),
  });
  invoices$: Observable<RR.Invoice[]>;
  subscription: Subscription = new Subscription();
  focus$ = new Subject<string>();
  institutionObject$: Observable<RR.Institution | undefined>;

  constructor(
    private title: Title,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private invoiceEffect: InvoiceEffect,
    private siteEffect: SiteEffect,
    private institutionEffect: InstitutionEffect,
    private institutionService: InstitutionHttpService,
    private invoiceService: InvoiceHttpService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    const decoded = decodeQueryParams(queryParamSchema, this.route.snapshot.queryParams);
    this.searchForm.patchValue(decoded);

    this.subscription.add(
      this.searchForm.valueChanges.subscribe(() => {
        const formValue = this.searchForm.getRawValue();
        // No typescript error: see comment in worklist component
        const encoded = encodeQueryParams(queryParamSchema, formValue);
        this.router.navigate([], {
          queryParams: encoded,
          replaceUrl: true,
        });
      }),
    );

    this.title.setTitle('Invoices - RadReport');
    this.subscription.add(this.siteEffect.findAll().subscribe());

    this.subscription.add(
      this.searchForm.valueChanges
        .pipe(
          startWith(this.searchForm.value),
          switchMap(() => this.search()),
        )
        .subscribe((action) => {
          this.invoiceCount = action.actions.invoiceSearchSuccess.count;
          // this.invoiceIdList = action.actions.invoiceSearchSuccess.invoices;
          this.invoices$ = this.store
            .select(fromInvoice.selectEntities)
            .pipe(
              map((entities) =>
                action.actions.invoiceSearchSuccess.invoices
                  .map((i) => entities[i.id])
                  .filter((i): i is RR.Invoice => i !== undefined),
              ),
            );
        }),
    );

    const institutionId$ = this.searchForm.controls.institutionId.valueChanges.pipe(
      startWith(this.searchForm.controls.institutionId.value),
    );
    this.institutionObject$ = institutionId$.pipe(
      switchMap((institutionId) =>
        institutionId ? this.store.select(fromInstitution.selectInstitution(institutionId)) : of(undefined),
      ),
    );

    this.subscription.add(
      institutionId$
        .pipe(
          filterDefined(),
          switchMap((institutionId) => {
            return this.institutionEffect.find(institutionId);
          }),
        )
        .subscribe(),
    );
  }

  onPageChange(page: number) {
    // Infinite loop resetting to page 1 when the search form changes
    if (this.searchForm.controls.page.value !== page) {
      this.searchForm.controls.page.setValue(page);
    }
  }

  defaultFilter(page: number) {
    return new HttpParams().set('limit', String(this.PAGE_SIZE)).set('offset', String((page - 1) * this.PAGE_SIZE));
  }

  searchRequestBody(): InvoiceSearchBody {
    return {
      patient_name: this.searchForm.controls.patientName.value,
      patient_number: this.searchForm.controls.patientNumber.value,
      referrer_name: this.searchForm.controls.referrerName.value,
      invoice_no: this.searchForm.controls.invoiceNo.value,
      institution: this.searchForm.controls.institutionId.value,
      accession_number: this.searchForm.controls.accessionNumber.value,
      funder: this.searchForm.controls.funder.value,
      status: this.searchForm.controls.status.value,
      draft: this.searchForm.controls.draft.value,
      deleted: this.searchForm.controls.deleted.value,
      no_further_action_required: this.searchForm.controls.no_further_action_required.value,
      partially_complete: this.searchForm.controls.partially_complete.value,
      unpaid: this.searchForm.controls.unpaid.value,
      unbalanced: this.searchForm.controls.unbalanced.value,
      sites: this.searchForm.controls.sites.value,
      from_date: this.searchForm.controls.fromDate.value
        ? formatISO(this.searchForm.controls.fromDate.value, { representation: 'date' })
        : undefined,
      to_date: this.searchForm.controls.toDate.value
        ? formatISO(this.searchForm.controls.toDate.value, { representation: 'date' })
        : undefined,
      payment_type: undefined,
    };
  }

  search() {
    const fromDate = this.searchForm.controls.fromDate.value;
    const toDate = this.searchForm.controls.toDate.value;
    if (fromDate && toDate && fromDate > toDate) {
      throw new Error('Invalid fromDate or toDate');
    }

    const params = this.defaultFilter(this.searchForm.controls.page.value);
    const query = this.searchRequestBody();

    return this.invoiceEffect.search(query, params).pipe(take(1));
  }

  queryDatesChanged(dates: { start: Date | undefined; end: Date | undefined }) {
    this.searchForm.patchValue({ fromDate: dates.start, toDate: dates.end });
  }

  searchByPatient(p: Partial<RR.Patient>) {
    const first_name = p.patient_first_name ?? '';
    const last_name = p.patient_last_name ?? '';
    this.searchForm.controls.patientName.patchValue(`${first_name + ' ' + last_name}`);
  }

  clearField(formField: keyof typeof this.searchForm.controls) {
    this.searchForm.get(formField)?.reset();
  }

  clearInstitution() {
    this.searchForm.controls.institutionId.reset();
    requestAnimationFrame(() => {
      this.institutionSearchElement?.nativeElement.focus();
    });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestInstitutions = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.focus$).pipe(
      switchMap((text) =>
        this.institutionSearch(text).pipe(
          map((result) => result.institutions),
          this.messageService.handleHttpErrorPipe,
        ),
      ),
    );
  };

  institutionSearch(text: string) {
    return this.institutionService.elasticSearch({
      name: text,
      phone: text,
      contact_person: text,
      email: text,
    });
  }

  clearFilters() {
    this.searchForm.patchValue({
      patientName: '',
      patientNumber: '',
      referrerName: '',
      invoiceNo: undefined,
      institution: '',
      institutionId: undefined,
      accessionNumber: '',
      funder: 'any',
      status: 'any',
    });
    this.datePickerComponent.onOptionChanged('today');
  }

  generateDaysheet() {
    const body = this.searchRequestBody();
    this.subscription.add(
      this.invoiceService.daysheet(body).subscribe({
        next: (data) => {
          const blob = new Blob([data.body as BlobPart], { type: 'application/pdf' });
          saveAs(
            blob,
            data.headers.get('Content-Disposition')?.split('filename=')[1].replace(/['"]/g, '') ?? 'daysheet.pdf',
          );
        },
        error: (error: unknown) => {
          this.messageService.httpErrorMessage(error);
        },
      }),
    );
  }

  generateWeeklyCashReport() {
    const body = this.searchRequestBody();
    body.payment_type = 'cash';
    this.subscription.add(
      this.invoiceService.weeklyCashReport(body).subscribe({
        next: (data) => {
          const blob = new Blob([data.body as BlobPart], { type: 'application/pdf' });
          saveAs(
            blob,
            data.headers.get('Content-Disposition')?.split('filename=')[1].replace(/['"]/g, '') ?? 'daysheet.pdf',
          );
        },
        error: (error: unknown) => {
          this.messageService.httpErrorMessage(error);
        },
      }),
    );
  }

  downloadCSV(institution: RR.Institution) {
    const body = this.searchRequestBody();
    this.subscription.add(
      this.invoiceService.invoiceCSV(body).subscribe((data) => {
        const date = new Date();
        const formatDate = formatISO(date, { representation: 'date' });
        const formatInstitution = institution.name.split(' ').join('-').toLowerCase();
        const blob = new Blob([data.body as BlobPart], { type: 'text/csv' });
        saveAs(blob, `${formatDate}-${formatInstitution}.csv`);
      }),
    );
  }

  onSelectInstitution(event: NgbTypeaheadSelectItemEvent<RR.Institution>) {
    this.searchForm.patchValue({
      institutionId: event.item.id,
    });
    // This stops an Institution object getting stored in the FormControl<string>
    event.preventDefault();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
