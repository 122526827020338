import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, getUserShortName } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AddOtherImagingButtonComponent } from 'app/modules/editor/add-other-imaging-button/add-other-imaging-button.component';
import { ImgsimParametersModalComponent } from 'app/modules/editor/imgsim-parameters-modal/imgsim-parameters-modal.component';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { BookingEffect, fromBooking } from 'app/store/booking';
import { fromProviderNumber } from 'app/store/provider-number';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { fromTopic } from 'app/store/report/topic';
import { fromSession, SessionEffect } from 'app/store/session';
import { fromSignature } from 'app/store/signature/signature.selector';
import { fromSite } from 'app/store/site';
import { fromUser } from 'app/store/user/user';
import { UserEffect } from 'app/store/user/user/user.effect';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { SendVoyagerButtonComponent } from '../../send-report/send-voyager-button/send-voyager-button.component';
import { CheckReportModalComponent } from '../check-report-modal/check-report-modal.component';
import { SignatureModalRole, TechnicianSignature } from './signature-modal.types';

type DoctorFormGroup = FormGroup<{
  id: FormControl<number>;
  initials: FormControl<string>;
  name: FormControl<string>;
  checked: FormControl<boolean>;
}>;

@Component({
  standalone: true,
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.css'],
  imports: [
    CommonModule,
    UserHeadlineComponent,
    NgbModule,
    ReactiveFormsModule,
    StoreSelectPipe,
    DragDropModule,
    AddOtherImagingButtonComponent,
    AutoFocusDirective,
    SendVoyagerButtonComponent,
  ],
})
export class SignatureModalComponent implements OnInit, OnDestroy {
  parent: 'FINAL_PREVIEW' | undefined = undefined;
  signatures: RR.Signature[] = [];
  doctorSignatures: RR.Signature[];
  topic_id: number; // input
  report_id: number; // input
  signature_role: SignatureModalRole; // input
  topic$: Observable<RR.Topic>;
  report$: Observable<RR.Report | undefined>;
  report: RR.Report | undefined;
  currentUserId: number; // Id of user who is editing the report
  sites: RR.Site[] = [];
  loading = true;

  // Technician role
  technician_role: string;
  juniorSignatures: TechnicianSignature[] = [];
  imagingTechSignatures: TechnicianSignature[] = [];
  seniorSignatures: TechnicianSignature[] = [];
  radiologyRegistrarSignatures: TechnicianSignature[] = [];
  site$: Observable<RR.Site | undefined>;
  subscription = new Subscription();
  providerNumber$: Observable<RR.ProviderNumber | undefined>;
  providerNumberName$: Observable<RR.User | undefined>;
  booking$: Observable<RR.Booking | undefined>;
  providerNumbers$: Observable<RR.ProviderNumber[]>;
  providerNumbers: RR.ProviderNumber[];
  booking: RR.Booking | undefined;
  providerNumber: RR.ProviderNumber | undefined;
  signature$: Observable<RR.Signature | undefined>;

  searchForm = new FormGroup({
    site_id: new FormControl(-1, { nonNullable: true }),
    doctors: new FormArray<DoctorFormGroup>([]),
  });

  constructor(
    protected activeModal: NgbActiveModal,
    private sessionEffect: SessionEffect,
    private reportService: ReportService,
    private reportEffect: ReportEffect,
    private templateService: TemplateService,
    private modalService: NgbModal,
    private userEffect: UserEffect,
    private store: Store<AppState>,
    private bookingEffect: BookingEffect,
    private editorService: EditorService,
  ) {}

  ngOnInit() {
    this.topic$ = this.store.select(fromTopic.selectTopic(this.topic_id)).pipe(filterDefined());
    this.report$ = this.store.select(fromReport.selectReport(this.report_id));
    this.subscription.add(
      this.report$.subscribe((r) => {
        this.report = r;
      }),
    );

    this.booking$ = this.report$.pipe(
      switchMap((report) => (report ? this.store.select(fromBooking.selectBooking(report.booking_id)) : of(undefined))),
    );
    this.subscription.add(
      this.booking$.subscribe((b) => {
        this.booking = b;
      }),
    );

    this.site$ = this.booking$.pipe(
      switchMap((booking) => (booking ? this.store.select(fromSite.selectSite(booking.site_id)) : of(undefined))),
    );

    this.providerNumber$ = this.report$.pipe(
      switchMap((report) =>
        report?.medicare_provider_id
          ? this.store.select(fromProviderNumber.selectProviderNumber(report.medicare_provider_id))
          : of(undefined),
      ),
    );
    this.subscription.add(
      this.providerNumber$.subscribe((p) => {
        this.providerNumber = p;
      }),
    );

    this.providerNumbers$ = this.store.select(fromProviderNumber.selectAll);
    this.subscription.add(
      this.providerNumbers$.subscribe((p) => {
        this.providerNumbers = p;
      }),
    );

    this.providerNumberName$ = this.providerNumber$.pipe(
      filterDefined(),
      switchMap((p) => this.store.select(fromUser.selectUser(p.user_id))),
    );

    this.signature$ = this.topic$.pipe(
      switchMap((topic) =>
        topic.signature_id ? this.store.select(fromSignature.selectSignature(topic.signature_id)) : of(undefined),
      ),
    );

    this.subscription.add(
      combineLatest([this.signature$, this.site$]).subscribe(([signature, site]) => {
        // Prefer signature.site_id over booking.site_id
        if (signature) {
          this.searchForm.patchValue({
            site_id: signature.site_id || undefined,
          });
        } else if (site) {
          this.searchForm.patchValue({
            site_id: site.id,
          });
        }
      }),
    );

    // Filter signature for doctors/technicians
    if (this.signature_role === 'DOCTOR') {
      this.subscription.add(
        this.userEffect
          .findByRoles(['doctor'])
          .pipe(take(1))
          .subscribe(() => {
            this.loading = false;
          }),
      );

      this.subscription.add(
        this.store.select(fromSite.selectActiveSites).subscribe((sites) => {
          this.sites = sites;
        }),
      );

      this.subscription.add(
        this.store.select(fromSignature.selectAll).subscribe((signatures) => {
          this.signatures = signatures;
        }),
      );

      this.subscription.add(
        combineLatest([
          this.store.select(fromSession.selectKioskUser),
          this.store.select(fromUser.selectAll),
        ]).subscribe(([kioskUser, users]) => {
          if (kioskUser === undefined) {
            throw new Error('kioskUser undefined');
          }
          this.addDoctors(
            kioskUser.id,
            users.filter((u) => u.company_roles.some((companyRoleId) => companyRoleId === 'doctor')),
          );
        }),
      );

      this.subscription.add(
        combineLatest([this.topic$, this.sessionEffect.authorise({ only: ['report_manage'] })])
          .pipe(
            take(1),
            switchMap(([topic, authorised]) => {
              if (!topic.signature_text && authorised) {
                return this.reportService.chooseLastSignature(topic.id);
              } else {
                // If the condition isn't met, emit a dummy value to keep the pipe working.
                return of(null);
              }
            }),
          )
          .subscribe(),
      );

      this.subscription.add(
        combineLatest([
          this.searchForm.valueChanges.pipe(startWith(null)),
          this.store.select(fromUser.selectEntities),
        ]).subscribe(([_value, users]) => {
          const form = this.searchForm.getRawValue();
          const site_id = form.site_id;
          const checkedDoctors: number[] = form.doctors.filter((d) => d.checked).map((d) => d.id);

          this.doctorSignatures = this.signatures.filter((signature) => {
            return (
              (site_id === -1 || site_id === signature.site_id) &&
              signature.user_ids.find((user) =>
                users[user]?.company_roles.some((companyRoleId) => companyRoleId === 'doctor'),
              ) &&
              (checkedDoctors.length === 0 || checkedDoctors.every((id) => !!signature.user_ids.find((u) => u === id)))
            );
          });
        }),
      );
    } else {
      const modality$ = this.topic$.pipe(
        switchMap((topic) => {
          return this.templateService.getTemplateModality(topic.template_id);
        }),
      );

      // Fetch user by roles
      this.subscription.add(
        modality$
          .pipe(
            take(1),
            switchMap((modality) => {
              const roles =
                modality === 'US'
                  ? ['junior_sonographer', 'sonographer', 'radiology_registrar']
                  : ['junior_radiographer', 'radiographer', 'imaging_technician', 'radiology_registrar'];

              return this.userEffect.findByRoles(roles).pipe(take(1));
            }),
          )
          .subscribe(() => {
            this.loading = false;
          }),
      );

      this.subscription.add(
        combineLatest([modality$, this.store.select(fromUser.selectActiveUsers)]).subscribe(([modality, users]) => {
          this.juniorSignatures = [];
          this.imagingTechSignatures = [];
          this.seniorSignatures = [];
          this.radiologyRegistrarSignatures = [];
          // Modality: US - only show sonographer signatures. Other modalities: show radiographer signatures
          this.technician_role = modality === 'US' ? 'sonographer' : 'radiographer';
          // Show junior signatures
          const junior_users = users
            .filter((user) =>
              user.company_roles.some((companyRoleId) => companyRoleId === `junior_${this.technician_role}`),
            )
            .sort(this.sortUsers);
          let prefix = this.technician_role === 'sonographer' ? 'Sonographer' : 'Radiographer';
          for (const user of junior_users) {
            // Create junior signature for junior
            this.juniorSignatures.push({
              user_id: user.id,
              text: `${prefix} Assistant: ${getUserShortName(user)}`,
            });
          }
          // Show imaging technician when modality is not US
          if (modality !== 'US') {
            const imaging_tech_users = users
              .filter((user) => user.company_roles.some((companyRoleId) => companyRoleId === 'imaging_technician'))
              .sort(this.sortUsers);
            for (const user of imaging_tech_users) {
              // Create junior signature for junior
              this.imagingTechSignatures.push({
                user_id: user.id,
                text: `Imaging Technician: ${getUserShortName(user)}`,
              });
            }
          }

          // Senior signatures
          const senior_users = users
            .filter((user) => user.company_roles.some((companyRoleId) => companyRoleId === this.technician_role))
            .sort(this.sortUsers);

          // Note: changing prefix here affects report text generation in the backend
          prefix = this.technician_role === 'sonographer' ? 'Sonographer' : 'Radiographer';
          for (const user of senior_users) {
            // Create senior signature for senior
            this.seniorSignatures.push({
              user_id: user.id,
              text: `${prefix}: ${getUserShortName(user)}`,
            });
          }

          // Radiology Registrar signatures
          const radiology_registrars = users
            .filter((user) => user.company_roles.some((companyRoleId) => companyRoleId === 'radiology_registrar'))
            .sort(this.sortUsers);
          prefix = 'Radiology Registrar';
          for (const user of radiology_registrars) {
            this.radiologyRegistrarSignatures.push({
              user_id: user.id,
              text: `${prefix}: ${getUserShortName(user)}`,
            });
          }
        }),
      );
    }
  }

  addDoctors(currentUserId: number, doctors: RR.User[]) {
    const doctorFormArray = new FormArray(
      doctors.map(
        (doctor) =>
          new FormGroup({
            id: new FormControl(doctor.id),
            initials: new FormControl(doctor.initials),
            name: new FormControl(doctor.name),
            checked: new FormControl(currentUserId === doctor.id),
          }) as DoctorFormGroup,
      ),
    );

    this.searchForm.setControl('doctors', doctorFormArray);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  sortUsers = (a: RR.User, b: RR.User) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  setSignature(signature: RR.Signature) {
    this.subscription.add(
      this.reportService
        .setSignature(this.topic_id, signature, this.signature_role)
        .pipe(
          take(1),
          switchMap(() => this.reportEffect.findReportText(this.report_id)),
        )
        .subscribe(),
    );
  }

  chooseDoctorSignature(signature: RR.Signature) {
    // Find medicare provider number from signature to update report provider number.
    const signatureProviderNumber = this.providerNumbers.find((p) => p.id === signature.medicare_provider_id);

    if (this.signature_role === 'DOCTOR') {
      if (signatureProviderNumber && signatureProviderNumber.id !== this.report?.medicare_provider_id && this.booking) {
        // If selecting a signature that is able to update medicare_provider_number
        const modalRef = ConfirmMessageModalComponent.open({
          modalService: this.modalService,
          header: 'Warning: You are updating the provider number and signature',
          message: `${
            'Provider Number: ' +
            (this.providerNumber?.medicare_provider_number || 'None') +
            ' -> ' +
            signatureProviderNumber.medicare_provider_number
          }`,
          btnConfirmText: 'Update Both',
          secondBtnOptionText: 'Only Update Signature',
        });
        modalRef.result.then(
          (result) => {
            if (result) {
              // If choose to update both
              // Update booking.site_id, topic.signature_id, report.medicare_provider_id

              if (this.booking) {
                this.subscription.add(
                  this.bookingEffect.update(this.booking.id, { site_id: signatureProviderNumber.site_id }).subscribe(),
                );
              }
              this.subscription.add(
                this.reportEffect
                  .update(this.report_id, {
                    medicare_provider_id: signatureProviderNumber.id,
                  })
                  .subscribe(),
              );

              this.setSignature(signature);
            } else {
              // Only update topic.signature_id
              this.setSignature(signature);
            }
          },
          () => {
            // Do nothing
          },
        );
      } else {
        // Selecting signature that is unable to update medicare_provider_number
        this.setSignature(signature);
      }
    }
  }

  openCheckReport() {
    this.activeModal.dismiss();
    CheckReportModalComponent.open(this.modalService, this.report_id, this.topic_id);
  }

  chooseTechnicianSignature(signature: TechnicianSignature, role: SignatureModalRole) {
    this.subscription.add(
      this.topic$
        .pipe(
          take(1),
          switchMap((t) => {
            // Check if signature needs to be removed
            if (
              (role === 'JUNIOR_TECHNICIAN' && signature.text === t.junior_signature_text) ||
              (role === 'TECHNICIAN' && signature.text === t.technician_signature_text) ||
              (role === 'RADIOLOGY_REGISTRAR' && signature.text === t.radiology_registrar_signature_text)
            ) {
              return this.reportService.removeSignature(t, role);
            }

            // Otherwise, set the signature for the topic
            return this.reportService.setSignature(this.topic_id, signature, role).pipe(
              take(1),
              switchMap(() => this.reportEffect.findReportText(this.report_id)),
            );
          }),
        )
        .subscribe(),
    );
  }

  clearSignature(role: SignatureModalRole) {
    this.subscription.add(
      this.topic$
        .pipe(
          take(1),
          switchMap((t) => this.reportService.removeSignature(t, role)),
        )
        .subscribe(),
    );
  }

  close() {
    this.activeModal.close();
  }

  getSite(site_id: number) {
    return this.store.select(fromSite.selectSite(site_id));
  }

  getInitials(id: number) {
    return this.store.select(fromUser.selectUser(id)).pipe(
      filterDefined(),
      map((user) => user.initials),
    );
  }

  userSelectorFn = fromUser.selectUser;

  checkContainTechnicianSignature(signatures: TechnicianSignature[], text: string) {
    return signatures.some((s) => s.text === text);
  }

  static open({
    modal,
    report_id,
    topic_id,
    signature_role,
    parent,
  }: {
    modal: NgbModal;
    report_id: number;
    topic_id: number;
    signature_role: SignatureModalRole;
    parent?: 'FINAL_PREVIEW' | undefined;
  }) {
    const modalRef = modal.open(SignatureModalComponent, {
      size: 'lg',
    });
    const componentInstance: SignatureModalComponent = modalRef.componentInstance;
    componentInstance.report_id = report_id;
    componentInstance.topic_id = topic_id;
    componentInstance.signature_role = signature_role;
    componentInstance.parent = parent;
    return modalRef;
  }

  openImgsimParameters() {
    if (this.report) {
      ImgsimParametersModalComponent.open(this.modalService, this.report);
      this.activeModal.dismiss();
    }
  }

  openPrefill() {
    this.modalService.dismissAll();
    this.editorService.togglePrefill(true);
  }

  onSentToVoyager(sentToVoyager: boolean) {
    this.activeModal.dismiss(sentToVoyager);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
