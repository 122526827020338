import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { PercentileBandRule } from './percentile-band-rule.service';

const adapter = createEntityAdapter<PercentileBandRule>();
const selectFeature = (state: AppState) => state.percentile_band_rule;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectPercentileBandRule = (id: number) =>
  createSelector(selectEntities, (percentile_band_rules: Dictionary<PercentileBandRule>) => percentile_band_rules[id]);

export const fromPercentileBandRule = {
  selectIds,
  selectEntities,
  selectAll,
  selectPercentileBandRule,
};
