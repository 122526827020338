<div class="input-group my-1" *ngIf="filterTerm">
  <div class="input-group-text">
    <span><i class="fa fa-search"></i></span>
  </div>
  <input type="text" class="form-control" [value]="filterTerm" disabled />
  <button class="btn btn-outline-secondary" ngbTooltip="Select/deselect all highlighted tags" (click)="selectAll()">
    <i class="fa fa-check-square"></i>
    <small class="badge bg-secondary ms-1">all</small>
  </button>
  <button class="btn btn-outline-secondary" ngbTooltip="Select/deselect top choice" (click)="selectTopChoice()">
    <i class="fa fa-check-square"></i>
    <small class="badge bg-secondary ms-1">1</small>
  </button>
  <button class="btn btn-outline-secondary" (click)="clearFilterTerm()">
    <i class="fa fa-times"></i>
  </button>
</div>
<div *ngIf="filteredTags?.length; else noTags" class="tag-list mb-4">
  <div *ngFor="let tag of filteredTags">
    <rr-prefill-tag
      [tag]="tag"
      [topicId]="topicId"
      [topicTags]="topicTags"
      [filterTerm$]="filterTerm$"
    ></rr-prefill-tag>
  </div>
</div>
<ng-container *ngIf="tagsLoading">
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading Groups...</span>
    </div>
  </div>
</ng-container>
<ng-template #noTags>
  <span class="m-1" *ngIf="!tagsLoading">No Groups...</span>
</ng-template>
