import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { StatementActions, StatementBatchActions } from './statement.action';

const adapter = createEntityAdapter<RR.Statement>();

export interface StatementState extends EntityState<RR.Statement> {
  statementBuilderSetId: number | undefined;
  statementBuilderTemplateId: number | undefined;
}

const initialState: StatementState = adapter.getInitialState({
  loaded: {},
  statementBuilderSetId: undefined,
  statementBuilderTemplateId: undefined,
});

export const statementReducer = createReducer(
  initialState,
  on(StatementActions.addMany, (state, { statements }) => adapter.addMany(statements, state)),
  on(StatementActions.addOne, (state, { statement }) => adapter.addOne(statement, state)),
  on(StatementActions.upsertOne, (state, { statement }) => adapter.upsertOne(statement, state)),
  on(StatementActions.upsertMany, (state, { statements }) => adapter.upsertMany(statements, state)),
  on(StatementActions.removeOne, (state, { statementId }) => adapter.removeOne(statementId, state)),
  on(StatementActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
  on(StatementBatchActions.findInStatementBuilderSuccess, (state, { statementSetId, templateId }) => ({
    ...state,
    statementBuilderSetId: statementSetId,
    statementBuilderTemplateId: templateId,
  })),
);
