import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-patient-form-view',
  templateUrl: './patient-form-view.component.html',
  styleUrls: ['./patient-form-view.component.css'],
})
export class PatientFormViewComponent {
  @Input() patient: RR.Patient | undefined;
  @Input() invalid: boolean;
  // Is in kiosk mode?
  @Input() kiosk: boolean;
}
