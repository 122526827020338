import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VoyagerUrlService } from 'app/core/services/voyager-url.service';
import { EditorExpandedState } from 'app/modules/editor/editor.component';
import { SendReportButtonComponent } from 'app/modules/report/components/send-report/send-report-button/send-report-button.component';
import { ProofReadReportComponent } from 'app/shared/components/proof-read-report/proof-read-report.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { NotesModalComponent } from 'app/shared/modals/notes-modal/notes-modal.component';
import { SharedModule } from 'app/shared/shared.module';

import { DueDateComponent } from '../../../../../shared/components/datetime/due-date.component';
import { ReportDateComponent } from '../../../../../shared/components/report-date/report-date.component';
import { ReportSignBadgeComponent } from '../../../../../shared/components/report-sign-badge/report-sign-badge.component';
import { ZeroFPImagesComponent } from '../../../../../shared/components/zero-fp-images/zero-fp-images.component';
import { DocumentAttachmentButtonComponent } from '../../../../registration/components/document-attachment-button/document-attachment-button.component';
import { VoyagerStatusBadgeComponent } from '../../../../worklist/components/voyager-status-badge/voyager-status-badge.component';
import { WorklistTopicColumnComponent } from '../../../../worklist/components/worklist-topic-column/worklist-topic-column.component';
import { RelatedTopicComponent } from '../related-topic/related-topic.component';

@Component({
  selector: '[rr-related-report-row]',
  standalone: true,
  templateUrl: './related-report-row.component.html',
  styleUrl: './related-report-row.component.css',
  imports: [
    SharedModule,
    CommonModule,
    DocumentAttachmentButtonComponent,
    ReportDateComponent,
    ZeroFPImagesComponent,
    VoyagerStatusBadgeComponent,
    DueDateComponent,
    RelatedTopicComponent,
    ReportSignBadgeComponent,
    WorklistTopicColumnComponent,
    ReportAccessionNumberComponent,
    SendReportButtonComponent,
  ],
})
export class RelatedReportRowComponent {
  @Input() report: RR.Report;
  @Input() editorExpandedState: EditorExpandedState;
  @Input() selectable: boolean;
  constructor(
    private modalService: NgbModal,
    public voyagerUrlService: VoyagerUrlService,
  ) {}

  openProofRead() {
    ProofReadReportComponent.open(this.modalService, this.report.id);
  }

  openNotesModal() {
    NotesModalComponent.open({ modalService: this.modalService, report: this.report });
  }
}
