import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Machine
 *
 */
export const MachineActions = {
  findAllSuccess: createAction('[Machine] Find All Success', props<{ machines: RR.Machine[] }>()),

  createSuccess: createAction('[Machine] Create Success', props<{ machine: RR.Machine }>()),

  updateSuccess: createAction('[Machine] Update Success', props<{ machine: RR.Machine }>()),

  deleteSuccess: createAction('[Machine] Delete Success', props<{ machineId: number }>()),

  updateUnavailableSuccess: createAction(
    '[Machine] Update Unavailable Success',
    props<{ machineId: number; unavailable: RR.MachineUnavailable }>(),
  ),
};
