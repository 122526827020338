import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { UserActions } from '../user/user/user.action';
import { BookingRateActions, BookingRateBatchActions } from './booking-rate.action';
import { BookingRateHttpService, CreateBookingRate } from './booking-rate.service';

@Injectable()
export class BookingRateEffect {
  constructor(
    private store: Store<AppState>,
    private service: BookingRateHttpService,
    private message: MessageService,
  ) {}

  create(bookingRate: CreateBookingRate) {
    return this.service.create(bookingRate).pipe(
      this.message.handleHttpErrorPipe,
      map(({ user, booking_rate }) =>
        BookingRateBatchActions.create({
          actions: {
            bookingRate: BookingRateActions.create({ bookingRate: booking_rate }),
            user: UserActions.upsertOne({ user }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(bookingRateId: number) {
    return this.service.delete(bookingRateId).pipe(
      this.message.handleHttpErrorPipe,
      map((user) => UserActions.upsertOne({ user })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
