import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AbbreviationsComponent } from '../../components/abbreviations/abbreviations.component';

@Component({
  standalone: true,
  imports: [AbbreviationsComponent],
  selector: 'rr-abbreviations-modal',
  templateUrl: './abbreviations-modal.component.html',
  styleUrls: ['./abbreviations-modal.component.css'],
})
export class AbbreviationsModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(AbbreviationsModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    return modalRef;
  }
}
