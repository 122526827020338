import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SendMOPayload, SendReportPayload, SendSMSPayload } from './send-event.action';

@Injectable()
export class SendEventHttpService {
  constructor(private http: HttpClient) {}

  sendSMS(data: SendSMSPayload): Observable<RR.SendEvent[]> {
    return this.http.post<RR.SendEvent[]>('/api/sms', data);
  }

  sendEmail(data: SendReportPayload): Observable<RR.SendEvent[]> {
    return this.http.post<RR.SendEvent[]>('/api/email_to', data);
  }

  sendMedicalObject(data: SendMOPayload): Observable<RR.SendEvent[]> {
    return this.http.post<RR.SendEvent[]>('/api/send_to_medical_objects', data);
  }
}
