import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.StatementCoincidence>();

const selectFeature = (state: AppState) => state.statement_coincidence;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectStatementCoincidence = (coincidenceId: number) =>
  createSelector(
    selectEntities,
    (statementCoincidences: Dictionary<RR.StatementCoincidence>) => statementCoincidences[coincidenceId],
  );

export const fromStatementCoincidence = {
  selectIds,
  selectEntities,
  selectAll,
  selectStatementCoincidence,
};
