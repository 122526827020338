<div [class.fw-bold]="(!feedback.checked && feedback.sender_id !== currentUser.id) || (uncheckedComments$ | async)">
  <div class="d-flex">
    <span>
      {{ feedback.created | date: 'dd/MM/yyyy' }}
    </span>
    <span class="ms-auto" *ngIf="uncheckedComments$ | async"><i class="fas fa-comments text-warning"></i></span>
  </div>
  <div>
    {{ (feedback.sender_id | storeSelectPipe: userSelectorFn | async)?.name }} <i class="fas fa-arrow-right"></i>
    {{ (feedback.recipient_id | storeSelectPipe: userSelectorFn | async)?.name }}
  </div>
</div>
