import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetsOpenShift } from 'app/store/session';
import { SettingEffect } from 'app/store/setting/setting.effect';
@Component({
  selector: 'rr-attendance-modal',
  templateUrl: './attendance-modal.component.html',
  standalone: true,
})
export class AttendanceModalComponent {
  data: TimesheetsOpenShift;

  constructor(
    public activeModal: NgbActiveModal,
    private settingEffect: SettingEffect,
  ) {}

  openRadpay() {
    // @ts-expect-error strictNullChecks
    this.settingEffect.setUserClockedOn(null);
    this.activeModal.dismiss();
  }
}
