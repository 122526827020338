<div class="d-flex align-items-center flex-wrap gap-2">
  <div class="d-flex gap-2" *ngIf="selectable">
    <button
      #popoverHeading="ngbPopover"
      rrTooltip="COPY_REPORT_HEADINGS"
      [ngbPopover]="popoverHeadingContent"
      popoverTitle="Other Imaging"
      triggers="manual"
      container="body"
      (mouseenter)="openPopoverHeading()"
      (mouseleave)="closePopovers()"
      (click)="addPriorReports()"
      class="btn btn-sm btn-primary"
      role="button"
      [disabled]="(addedTopic$ | async)?.included_key_findings === false"
    >
      H
    </button>
    <ng-template #popoverHeadingContent let-data="data">
      <div [innerText]="data"></div>
    </ng-template>
    <button
      #popoverKeyFindings="ngbPopover"
      [ngbPopover]="popoverKeyFindingsContent"
      rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
      popoverTitle="Key Findings"
      triggers="manual"
      container="body"
      (mouseenter)="openPopoverKF()"
      (mouseleave)="closePopovers()"
      (click)="addPriorReports({ keyFinding: true })"
      class="btn btn-sm btn-primary"
      role="button"
      [disabled]="(addedTopic$ | async)?.included_key_findings"
    >
      KF
    </button>
    <ng-template #popoverKeyFindingsContent let-data="data">
      <div class="pop-content" [innerText]="data"></div>
    </ng-template>
    <button
      (click)="deleteOtherImaging()"
      class="btn btn-sm btn-danger text-nowrap"
      [disabled]="(addedTopic$ | async) === undefined"
      rrTooltip="DELETE_PRIOR_REPORTS_HEADINGS"
    >
      Delete Prior
    </button>
  </div>
  <div class="d-flex align-items-center gap-2">
    <button class="btn btn-sm btn-info text-nowrap" (click)="importToPrefill()" rrTooltip="IMPORT_TO_PREFILL">
      <i class="fas fa-file-import"></i>
      Prefill from Prior Report
    </button>
  </div>
</div>
