import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { SessionActions } from '../..//session/session.action';
import { EditorActions } from '../../editor/editor.action';
import { SectionActions } from './section.action';

export interface SectionState extends EntityState<RR.Section> {}

const adapter = createEntityAdapter<RR.Section>();
const initialState = adapter.getInitialState();

export const sectionReducer = createReducer(
  initialState,
  on(SectionActions.addMany, (state, { sections }) => adapter.addMany(sections, state)),
  on(SectionActions.upsertOne, (state, { section }) => adapter.upsertOne(section, state)),
  on(EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
