<p (click)="onClick()" role="button" class="h4" [class.text-muted]="!active">
  {{ subsection.name || 'NO NAME' }}
</p>

<ng-container *ngIf="subsection.region_set_id != null">
  <rr-index-region
    cdkDropList
    [cdkDropListData]="subsection"
    (cdkDropListDropped)="drop($event)"
    *ngFor="let region of regions; let index = index"
    [region]="region"
    [section]="section"
    [subsection]="subsection"
    [elements$]="elements$"
  >
  </rr-index-region>
</ng-container>

<ng-container *ngIf="subsection.region_set_id == null">
  <div cdkDropList [cdkDropListData]="subsection" (cdkDropListDropped)="drop($event)">
    <div
      cdkDrag
      [cdkDragData]="element"
      rr-index-element
      *ngFor="let element of elements$ | async; trackBy: trackBy"
      [class.text-muted]="!active"
      [element]="element"
    ></div>
  </div>
</ng-container>
