import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BindObservable } from 'app/app.utils';
import { Observable, Subscription } from 'rxjs';

/**
 * Adds the href to the link tag: <a href="..."> to support "Open in new tab", ctrl + click, etc...
 * When clicked normally it programatically navigates with Angular's router so that page doesn't do a full refresh.
 */
@Directive({
  selector: 'a[rrDynamicLink]',
  standalone: true,
})
export class DynamicLinkDirective implements OnInit, OnDestroy {
  @BindObservable() @Input() rrDynamicLink: string; // the href
  rrDynamicLink$: Observable<string>;
  subscription = new Subscription();

  constructor(
    private router: Router,
    private el: ElementRef<HTMLAnchorElement>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.rrDynamicLink$.subscribe((rrDynamicLink) => {
        this.el.nativeElement.href = rrDynamicLink;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClickDynamicLink($event: MouseEvent) {
    // Navigate programatically with Angular so that the page doesn't refresh. But the link has a [href] so that open in
    // new tab works.
    $event.preventDefault();
    if (!($event.currentTarget instanceof HTMLAnchorElement)) {
      throw new Error('currentTarget is not an anchor element');
    }
    // event.currentTarget.href doesn't work with navigateByUrl because it includes the base (http://localhost:5050/...)
    const href = $event.currentTarget.getAttribute('href');
    if (href === null) {
      throw new Error('href is null');
    }
    this.router.navigateByUrl(href);
  }
}
