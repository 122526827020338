import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromInvoice } from '../invoice/invoice.selector';

const adapter = createEntityAdapter<RR.InvoiceItem>();
const selectFeature = (state: AppState) => state.invoice_item;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInvoiceItem = (id: number) =>
  createSelector(selectEntities, (invoiceItems: Dictionary<RR.InvoiceItem>) => invoiceItems[id]);

const selectInvoiceItems = (ids: number[]) =>
  createSelector(selectEntities, (invoiceItems: Dictionary<RR.InvoiceItem>) =>
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    (ids || []).map((id) => invoiceItems[id]),
  );

const selectInInvoice = (invoiceId: number) =>
  // @ts-expect-error strictNullChecks
  createSelector(
    fromInvoice.selectInvoice(invoiceId),
    selectEntities,
    (invoice: RR.Invoice, invoiceItems: Dictionary<RR.InvoiceItem>) =>
      invoice.invoice_items.map((itemId) => invoiceItems[itemId]),
  );

export const fromInvoiceItem = {
  selectIds,
  selectEntities,
  selectAll,
  selectInvoiceItem,
  selectInvoiceItems,
  selectInInvoice,
};
