import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { RegionActions } from 'app/store/template/region';
import { StatementActions } from 'app/store/template/statement';
import { map, tap } from 'rxjs/operators';

import { TagChoiceActions, TagChoiceBatchActions } from './tag-choice.action';
import {
  CreateTagChoiceData,
  MultipleTagChoicesContextResponse,
  TagAutoSelectParam,
  TagChoiceContextResponse,
  TagChoiceHttpService,
  TagNADSelectParam,
} from './tag-choice.service';

@Injectable()
export class TagChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: TagChoiceHttpService,
    private message: MessageService,
  ) {}

  loadTagChoices(topicId: number) {
    this.store.dispatch(TagChoiceActions.loadTagChoices());
    return this.service.getTagChoices(topicId).pipe(
      map((res: MultipleTagChoicesContextResponse) =>
        TagChoiceBatchActions.loadSuccess({
          actions: {
            loadSuccess: TagChoiceActions.loadTagChoicesSuccess({ tags: res.tag_choices }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: res.statements }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.regions }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addTagChoice(topicId: number, tag: CreateTagChoiceData) {
    this.store.dispatch(TagChoiceActions.toggleLoading());
    return this.service.addTagChoice(topicId, tag).pipe(
      map((res: TagChoiceContextResponse) =>
        TagChoiceBatchActions.addSuccess({
          actions: {
            addSuccess: TagChoiceActions.addTagChoiceSuccess({ tag: res.tag_choice }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: [res.statement] }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.region ? [res.region] : [] }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteTagChoice(topicId: number, tagId: string) {
    this.store.dispatch(TagChoiceActions.toggleLoading());
    return this.service.deleteTagChoice(topicId, tagId).pipe(
      map(() => TagChoiceActions.deleteTagChoiceSuccess({ id: tagId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  autoSelect(topicId: number, params: TagAutoSelectParam) {
    return this.service.autoChoose(topicId, params).pipe(
      this.message.handleHttpErrorPipe,
      map((res: MultipleTagChoicesContextResponse) =>
        TagChoiceBatchActions.autoSelectSuccess({
          actions: {
            autoSelectSuccess: TagChoiceActions.autoSelectTagChoicesSuccess({ tags: res.tag_choices }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: res.statements }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.regions }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  autoSelectNAD(topicId: number, params: TagNADSelectParam) {
    return this.service.autoChooseNAD(topicId, params).pipe(
      map((res: MultipleTagChoicesContextResponse) =>
        TagChoiceBatchActions.autoSelectSuccess({
          actions: {
            autoSelectSuccess: TagChoiceActions.autoSelectTagChoicesSuccess({ tags: res.tag_choices }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: res.statements }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.regions }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  autoSelectDelete(topicId: number) {
    return this.service.autoChooseDelete(topicId).pipe(
      map((res: MultipleTagChoicesContextResponse) =>
        TagChoiceBatchActions.autoSelectSuccess({
          actions: {
            autoSelectSuccess: TagChoiceActions.autoSelectTagChoicesSuccess({ tags: res.tag_choices }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: res.statements }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.regions }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  copyTags(topicId: number, from_region_ids: number[], to_region_ids: number[]) {
    this.store.dispatch(TagChoiceActions.toggleLoading());
    return this.service.copyTagChoices(topicId, from_region_ids, to_region_ids).pipe(
      map((res: MultipleTagChoicesContextResponse) =>
        TagChoiceBatchActions.copySuccess({
          actions: {
            copySuccess: TagChoiceActions.copyTagChoicesSuccess({ tags: res.tag_choices }),
            findTemplateDataSuccess: TagChoiceBatchActions.findTemplateDataSuccess({
              actions: {
                findStatementsSuccess: StatementActions.addMany({ statements: res.statements }),
                findRegionsSuccess: RegionActions.addMany({ regions: res.regions }),
              },
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
