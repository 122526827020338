import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { getPatientShortAgeAndSex } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { uniq } from 'lodash-es';
import { Subscription } from 'rxjs';

import { SharedModule } from '../../../../shared/shared.module';
import { PrefillService } from './../prefill.service';

@Component({
  selector: 'rr-prefill-exact-match-row',
  templateUrl: './prefill-exact-match-row.component.html',
  styleUrls: ['./prefill-exact-match-row.component.scss'],
  standalone: true,
  imports: [SharedModule, RouterLink, NgbTooltip, CommonModule],
})
export class PrefillExactMatchRowComponent implements OnInit, OnDestroy {
  @Input() topic: RR.ExactMatchTopic;
  @Input() open_topic_id: number;
  @HostBinding('class.list-group-item-primary') isActive = false;
  subscription = new Subscription();
  metric: { intersection: number; totalPrefills: number; totalCurrent: number };
  constructor(
    private prefillService: PrefillService,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.prefillService.getPrefillPreviewObject(this.open_topic_id).subscribe((prefillTopicObj) => {
        this.isActive = prefillTopicObj.topicIds.indexOf(this.topic_id) >= 0;
        this.cd.markForCheck();
      }),
    );

    this.subscription.add(
      this.reportService.getChoicesWithCtx(this.open_topic_id).subscribe((ctx_choices) => {
        const currentStatementIds = uniq(
          ctx_choices
            .filter((ctx) => !!ctx.statement_choice && ctx.statement_choice.statement_id)
            // @ts-expect-error strictNullChecks
            .map((ctx) => ctx.statement_choice.statement_id),
        );
        const prefillStatementIds = uniq(this.topic.statement_ids);
        const intersection = prefillStatementIds.filter((value) => currentStatementIds.includes(value));
        this.metric = {
          intersection: intersection.length,
          totalPrefills: prefillStatementIds.length,
          totalCurrent: currentStatementIds.length,
        };
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get topic_id(): string {
    return this.topic.topic_id.toString();
  }

  selectPrefill($event: any) {
    this.prefillService.selectPrefillTopics$.next({ type: 'SELECT_TOPIC' });
    if (!$event.target.closest('[data-no-bubble-select-prefill-row]')) {
      if (!$event.shiftKey) {
        this.prefillService.setPrefillPreviewTopics({ openTopicId: this.open_topic_id, topicIds: [this.topic_id] });
      } else {
        if (this.isActive) {
          this.prefillService.removePrefillPreviewTopic(this.open_topic_id, this.topic_id);
        } else {
          this.prefillService.addPrefillPreviewTopic(this.open_topic_id, this.topic_id);
        }
      }
    }
  }

  patientShortAgeAndSex() {
    const patient = {
      patient_age_in_days: this.topic.patient_age,
      patient_sex: this.topic.patient_gender,
    };

    return getPatientShortAgeAndSex(patient);
  }
}
