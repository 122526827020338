<div class="d-flex flex-column flex-grow-1">
  <div *ngFor="let userId of recipientIds; let i = index" class="d-flex align-items-center form-check my-1 me-sm-2">
    <input
      type="radio"
      role="button"
      class="form-check-input me-2"
      id="user-{{ i }}"
      (change)="selectUser(userId)"
      [checked]="userId === selectedUserId"
      name="sendFeedbackRadio"
    />
    <label role="button" for="user-{{ i }}" class="form-check-label">
      <rr-user-headline *ngIf="userId | selectPipe: userSelectorFn | async as user" [user]="user"></rr-user-headline>
    </label>
    <span
      class="ms-1"
      *ngIf="parent === 'SEND_FEEDBACK' && (userId | selectPipe: checkSentFeedbackSelectorFn | async)"
      ngbPopover="A feedback has been sent already!"
      triggers="mouseenter:mouseleave"
      role="button"
      placement="right"
    >
      <i class="fa fa-check-circle text-success"></i>
    </span>
  </div>

  <div class="pt-2" *ngIf="parent === 'SEND_FEEDBACK'">
    <rr-user-selector
      buttonLabel="Someone else"
      [filterByRole]="true"
      mode="single"
      (onSelect)="onUserSelectorChange($event)"
    ></rr-user-selector>
  </div>
</div>
