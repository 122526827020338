import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { RegionSetActions } from '../region-set/region-set.action';
import { RegionActions, RegionBatchActions } from './region.action';
import { RegionHttpService } from './region.service';

@Injectable()
export class RegionEffect {
  constructor(
    private store: Store<AppState>,
    private service: RegionHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((regions: RR.Region[]) => RegionActions.addMany({ regions })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(region: Partial<RR.Region>) {
    return this.service.create(region).pipe(
      map(({ region, regionSet }) =>
        RegionBatchActions.createSuccessBatchAction({
          actions: {
            regionCreateSuccessAction: RegionActions.addOne({ region }),
            regionSetUpdateSuccessAction: RegionSetActions.updateSuccess({ regionSet }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(regionId: number, changes: Partial<RR.Region>) {
    return this.service.update(regionId, changes).pipe(
      map((region: RR.Region) => RegionActions.upsertOne({ region })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(regionId: number) {
    return this.service.delete(regionId).pipe(
      map(() => RegionActions.removeOne({ regionId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((regions: RR.Region[]) => RegionActions.addMany({ regions })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
