import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { ReferralEnquiryEffect, fromReferralEnquiry } from 'app/store/referral-enquiry';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-referral-view-summary',
  standalone: true,
  templateUrl: './referral-view-summary.component.html',
  styleUrls: ['./referral-view-summary.component.css'],
  imports: [CommonModule],
})
export class ReferralViewSummaryComponent implements OnInit, OnDestroy {
  @Input() referralId: number;
  referral$: Observable<RR.ReferralEnquiry | undefined>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private referralEnquiryEffect: ReferralEnquiryEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.referralEnquiryEffect.get(this.referralId).subscribe());
    this.referral$ = this.store.select(fromReferralEnquiry.selectReferralEnquiry(this.referralId));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
