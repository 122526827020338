import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

const SELECTABLE_DOCUMENT_TYPES: RR.DocumentType[] = ['unclassified', 'referral', 'pathology'];

@Component({
  selector: 'rr-document-type-select',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './document-type-select.component.html',
  styleUrl: './document-type-select.component.css',
})
export class DocumentTypeSelectComponent implements OnInit {
  @Input() control: FormControl<RR.DocumentType>;
  @Input() documentType: RR.DocumentType = 'unclassified';
  documentTypes = SELECTABLE_DOCUMENT_TYPES;

  ngOnInit(): void {
    this.control.setValue(this.documentType);
  }
}
