import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectorService } from 'app/core/services/selector.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'rr-modal-auth',
  templateUrl: 'modal-auth.component.html',
})
export class ModalAuthComponent implements OnInit, OnDestroy {
  @Input() hasCancelButton: boolean;
  @Output() onAuthenticated = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  error: string | undefined;
  loggedInUser: RR.User | undefined;

  subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private selectorService: SelectorService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(take(1))
        .subscribe((user) => {
          this.loggedInUser = user;
        }),
    );
  }

  checkPassword(event: any) {
    this.error = undefined;
    if (!event.target.password.value || !event.target.password.value.trim()) {
      this.error = 'Please enter your password';
      return;
    }
    this.subscription.add(
      this.http
        .post<{ confirm: boolean }>('/api/auth/user_confirm', {
          username: this.loggedInUser?.username,
          password: event.target.password.value,
        })
        .pipe(take(1))
        .subscribe((val) => {
          if (val.confirm) {
            this.onAuthenticated.emit();
          } else {
            // @ts-expect-error strictNullChecks
            this.error = 'Password is incorrect. Please enter correct login password for ' + this.loggedInUser.username;
            event.target.password.value = '';
          }
        }),
    );
  }

  cancel() {
    this.onCancel.emit();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
