import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type DocumentHttpResponse = {
  booking: RR.Booking;
  document: RR.Document;
};
@Injectable()
export class DocumentHttpService {
  constructor(private http: HttpClient) {}

  find(filename: string): Observable<RR.Document> {
    return this.http.get<RR.Document>(`/api/document/${filename}`);
  }

  findContent(filename: string) {
    return this.http.get(`/api/document/${filename}/content`);
  }

  create(bookingId: number, data: FormData): Observable<DocumentHttpResponse> {
    return this.http.post<DocumentHttpResponse>(`/api/booking/${bookingId}/document`, data);
  }

  updateDocumentType(filename: string, data: { type: RR.DocumentType }) {
    return this.http.put<RR.Document>(`/api/document/${filename}`, data);
  }

  delete(documentId: string): Observable<DocumentHttpResponse> {
    return this.http.delete<DocumentHttpResponse>(`/api/document/${documentId}`);
  }

  findInBooking(booking_id: number): Observable<RR.Document[]> {
    return this.http.get<RR.Document[]>(`/api/document/booking/${booking_id}`);
  }
}
