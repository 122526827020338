import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BookingCodeActions } from './booking-code.action';

export interface BookingCodeState extends EntityState<RR.BookingCode> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.BookingCode>();

const initialState = adapter.getInitialState({ loaded: false });

export const bookingCodeReducer = createReducer(
  initialState,
  on(BookingCodeActions.findAllSuccess, (state: BookingCodeState, { bookingCodes }) => ({
    ...adapter.setAll(bookingCodes, state),
    loaded: true,
  })),
  on(BookingCodeActions.findManySuccess, (state: BookingCodeState, { bookingCodes }) =>
    adapter.upsertMany(bookingCodes, state),
  ),
  on(BookingCodeActions.findSuccess, (state: BookingCodeState, { bookingCode }) =>
    adapter.upsertOne(bookingCode, state),
  ),
  on(BookingCodeActions.createSuccess, (state: BookingCodeState, { bookingCode }) =>
    adapter.addOne(bookingCode, state),
  ),
  on(BookingCodeActions.updateSuccess, (state: BookingCodeState, { bookingCode }) =>
    adapter.upsertOne(bookingCode, state),
  ),
  on(BookingCodeActions.deleteSuccess, (state: BookingCodeState, { bookingCodeId }) =>
    adapter.removeOne(bookingCodeId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
