<ng-container *ngIf="regionChoice !== undefined">
  <h4 role="button" (click)="jumpToRegion(region.id)">
    {{ region.name }}
  </h4>
  <div
    cdkDrag
    rr-index-element
    *ngFor="let element of elements$ | async; trackBy: trackBy"
    [element]="element"
    [region]="region"
  ></div>
</ng-container>
