import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { PatientDuplicate } from 'app/store/patient';

import { PatientPopoverComponent } from '../patient-form/patient-popover/patient-popover.component';

@Component({
  selector: 'rr-patient-suggestions',
  standalone: true,
  templateUrl: './patient-suggestions.component.html',
  styleUrls: ['./patient-suggestions.component.css'],
  imports: [CommonModule, NgbModule, PatientPopoverComponent],
})
export class PatientSuggestionsComponent implements OnInit {
  @ViewChild('savePopover') popover: NgbPopover | undefined;
  @Input() similarPatients: PatientDuplicate = {
    patients: [],
    reason: 'dummy',
  };
  @Input() similarReferralPatients: RR.ReferralEnquiry[] = [];
  @Output() patientSelected = new EventEmitter<{ patient: RR.Patient; created?: boolean }>();

  constructor() {}

  emitExistingPatient(patient: RR.Patient | RR.ReferralEnquiry) {
    let unifiedPatient: Partial<RR.Patient> | Partial<RR.ReferralEnquiry> = {};

    if ('patient_first_name' in patient) {
      unifiedPatient = { ...patient };
    } else {
      unifiedPatient = {
        patient_first_name: patient.given_name,
        patient_last_name: patient.family_name,
        phone_mobile: patient.mobile_number,
        patient_dob: patient.date_of_birth,
        medicare_number: patient.medicare_number,
        address: patient.patient_address,
        city: patient.patient_suburb,
        patient_sex: patient.sex.toLocaleLowerCase() === 'f' ? 'female' : 'male',
      };
    }
    this.patientSelected.emit({ patient: unifiedPatient as RR.Patient });
  }

  ngOnInit(): void {}
}
