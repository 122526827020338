<div class="modal-header">
  <div>
    <h5 *ngIf="referrerId" class="modal-title">Edit Collater Notes</h5>
    <h5 *ngIf="patientId" class="modal-title">Edit Patient Notes</h5>
    <h5 *ngIf="bookingId" class="modal-title">Edit Booking Notes</h5>
    <h5 *ngIf="referrerNoteId" class="modal-title">Edit Referrer Note</h5>
    <h5 *ngIf="patientNoteId" class="modal-title">Edit Patient Note</h5>
    <h5 *ngIf="enquiryId" class="modal-title">Edit Enquiry Staff Note</h5>
    <rr-booking-headline *ngIf="bookingId" [bookingId]="bookingId"></rr-booking-headline>
    <rr-patient-info *ngIf="patientId" [patientId]="patientId"></rr-patient-info>
    <strong>
      <rr-referrer-name *ngIf="referrerId" [referrerId]="referrerId" [referrerType]="true"></rr-referrer-name>
    </strong>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div *ngIf="patientId || referrerId || referrerNoteId || patientNoteId" class="alert alert-danger mb-3">
    <h5 class="m-0">
      Warning: Editing will result in permanent changes to
      <strong *ngIf="patientId || patientNoteId">Patient's</strong>
      <strong *ngIf="referrerId || referrerNoteId">Referrer's</strong>
      record
    </h5>
  </div>
  <div class="form-group mb-2">
    <label>Notes</label>
    <textarea rrAutoFocus rows="3" class="form-control my-2" [(ngModel)]="notes"></textarea>
  </div>
  <button role="button" class="btn btn-success" (click)="updateNote()">Save</button>
</div>
