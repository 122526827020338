import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { PrefillService } from 'app/modules/editor/prefill/prefill.service';
import { VoiceNotesComponent } from 'app/shared/modals/notes-modal/voice-notes/voice-notes.component';
import { AppState } from 'app/store';
import { fromTagChoice } from 'app/store/prefill/tag-choice';
import { fromCurrentReport } from 'app/store/report/report';
import { fromStatement } from 'app/store/template/statement';
import { fromStatementSet } from 'app/store/template/statement-set';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ReportNotesButtonComponent } from '../../../../shared/components/report-notes-button/report-notes-button.component';
import { VoiceNotesComponent as VoiceNotesComponent_1 } from '../../../../shared/modals/notes-modal/voice-notes/voice-notes.component';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../../shared/shared.module';
import { ElementComponent } from '../../element/element.component';
import { TagComponent } from '../../prefill/prefill-tag/tag/tag.component';
import { PrefillSelectTopicType } from '../../prefill/prefill.types';
import { VoiceNoteSugesstedStatementComponent } from '../../voice-note-suggested-statements/voice-note-suggested-statements.component';

@Component({
  selector: 'rr-divider-statements-modal',
  templateUrl: './divider-statements-modal.component.html',
  styleUrls: ['./divider-statements-modal.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ReportNotesButtonComponent,
    TagComponent,
    ElementComponent,
    VoiceNoteSugesstedStatementComponent,
    VoiceNotesComponent_1,
    StoreSelectPipe,
  ],
})
export class DividerStatementsModalComponent implements OnInit, AfterViewInit {
  @ViewChild(VoiceNotesComponent) voiceNotesComponent: VoiceNotesComponent;
  @Input() topic_id: number;
  @Input() divider_id?: number | undefined;
  @Input() region_id?: number | null;
  @Input() parent: 'PREFILL' | 'PREFILL_TAG_MODAL' | 'STATEMENT_RECOMMENDER';

  topic$: Observable<RR.Topic>;
  report$: Observable<RR.Report | undefined>;
  element$: Observable<RR.Element>;
  region$: Observable<RR.Region | undefined>;
  subsection$: Observable<RR.Subsection | undefined>;
  tagChoices$: Observable<RR.TagChoice[]>;
  showAutoSuggested = false;
  focusVoiceMode = false;
  section$: Observable<RR.Section>;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private templateService: TemplateService,
    private modalService: NgbModal,
    private prefillService: PrefillService,
    private store: Store<AppState>,
    private editorService: EditorService,
  ) {}

  ngOnInit() {
    this.report$ = this.store.select(fromCurrentReport.selectReport);
    this.topic$ = this.reportService.getTopic(this.topic_id);
    this.region$ = this.region_id ? this.templateService.getRegion(this.region_id) : of(undefined);
    this.element$ = this.topic$.pipe(
      switchMap((t) =>
        this.divider_id ? this.templateService.getStatementElements(this.divider_id, t.template_id) : of(null),
      ),
      filterDefined(),
      map((elements) => elements[0]),
      filterDefined(),
    );
    const iElement$ = combineLatest([this.element$, this.topic$]).pipe(
      switchMap(([element, topic]) => {
        return this.templateService
          .getElements(topic.template_id)
          .pipe(map((elements) => elements.find((e) => e.element.id === element.id)));
      }),
    );
    this.section$ = iElement$.pipe(
      filterDefined(),
      map((element) => element.section),
    );
    this.subsection$ = iElement$.pipe(
      filterDefined(),
      map((element) => element.subsection),
    );
    this.tagChoices$ = this.store.select(fromTagChoice.selectAll);
  }

  ngAfterViewInit(): void {
    this.editorService.publishFocus({ statement_id: this.divider_id, target: 'PREFILL' });

    // disabled: RD isn't using this anymore
    // this.voiceNotesComponent.startListeningGeneralNote();
  }

  voiceNoteTextFocus() {
    this.editorService.triggerVoiceNoteFocus();
  }

  triggerVoiceNoteFocus(focus?: boolean) {
    if (focus) {
      this.focusVoiceMode = true;
    } else {
      this.focusVoiceMode = !this.focusVoiceMode;
    }
    if (this.focusVoiceMode) {
      setTimeout(() => {
        this.voiceNoteTextFocus();
      });
    } else {
      this.editorService.publishFocus({ statement_id: this.divider_id, target: 'PREFILL' });
    }
  }

  selectElementName = fromStatementSet.selectStatementSetName;
  selectStatement = fromStatement.selectStatement;

  refreshExactMatch(type?: PrefillSelectTopicType) {
    this.editorService.togglePrefill(true);
    this.prefillService.refreshExactMatch$.next(type);
    this.modalService.dismissAll();
  }

  combineEMAndMetadata() {
    this.editorService.togglePrefill(true);
    this.prefillService.selectPrefillTopics$.next({ type: 'COMBINE' });
    this.modalService.dismissAll();
  }

  toggleAutoSuggested() {
    this.showAutoSuggested = !this.showAutoSuggested;
  }

  static open({
    modalService,
    topic_id,
    divider_id,
    region_id,
    parent,
  }: {
    modalService: NgbModal;
    topic_id: number;
    divider_id?: number;
    region_id?: number | null | undefined;
    parent: 'PREFILL' | 'PREFILL_TAG_MODAL' | 'STATEMENT_RECOMMENDER';
  }) {
    const modalRef = modalService.open(DividerStatementsModalComponent, {
      size: 'xl',
      scrollable: true,
      centered: true,
      windowClass: 'divider-statements-modal',
    });
    const self: DividerStatementsModalComponent = modalRef.componentInstance;
    self.topic_id = topic_id;
    self.divider_id = divider_id;
    self.region_id = region_id;
    self.parent = parent;
    return modalRef;
  }
}
