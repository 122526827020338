import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PatientNoteCategoryHttpService {
  constructor(private http: HttpClient) {}

  create(patientNoteCategory: Partial<RR.NoteCategory>): Observable<RR.NoteCategory> {
    return this.http.post<RR.NoteCategory>('/api/patient_note_category', patientNoteCategory);
  }

  delete(patientNoteCategoryId: number): Observable<number> {
    return this.http.delete<number>(`/api/patient_note_category/${patientNoteCategoryId}`);
  }
}
