import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { IElement, TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { fromStatement } from 'app/store/template/statement';
import { fromStatementSet } from 'app/store/template/statement-set';
import { combineLatest, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { StatementComponent } from '../../statement/statement.component';

@Component({
  selector: 'rr-statement-moving',
  templateUrl: './statement-moving.component.html',
  styleUrls: ['./statement-moving.component.css'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, forwardRef(() => StatementComponent), CommonModule],
})
export class StatementMovingComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() statement_id: number;
  statement_id$: Observable<number>;
  @Input() topic: RR.Topic;
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD' = 'EDITOR';
  @Output() onMoveCompleted = new EventEmitter();

  @ViewChild('searchTextInput') searchTextInput: ElementRef;
  statement: RR.Statement;
  selectedSection = new FormControl<string>('history', { nonNullable: true });
  searchText = '';
  elements: IElement[] = [];
  selectedElement: IElement | undefined;
  sectionElements: IElement[] = [];
  filteredElements: IElement[] = [];
  statements$: Observable<RR.Statement[]>;
  dividers$: Observable<RR.Statement[]>;

  topStatement: RR.Statement;

  subscription = new Subscription();

  constructor(
    private templateService: TemplateService,
    private editorService: EditorService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const elements$ = this.templateService.getElements(this.topic.template_id);
    const statement$ = this.statement_id$.pipe(
      switchMap((statement_id) => this.store.select(fromStatement.selectStatement(statement_id))),
      filterDefined(),
    );

    this.subscription.add(
      elements$.subscribe((elements) => {
        this.elements = elements;
      }),
    );

    this.subscription.add(
      combineLatest([statement$, elements$])
        .pipe(take(1))
        .subscribe(([statement, elements]) => {
          this.moveSentenceInitialisation(elements, statement);
        }),
    );

    this.subscription.add(
      statement$.subscribe((s) => {
        this.statement = s;
      }),
    );

    requestAnimationFrame(() => {
      this.scrollToSelectedElement();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  moveSentenceInitialisation(elements: IElement[], statement: RR.Statement) {
    this.selectedElement = elements.find((e) => e.statement_set.id === statement.statement_set_id);
    // Check to load statements in statement sets because the element is now lazy loaded
    if (this.selectedElement) {
      this.subscription.add(
        this.templateService
          .loadStatementSet(this.selectedElement.statement_set.id, this.topic.template_id)
          .subscribe(),
      );
      this.selectedSection.setValue(this.selectedElement.section.type);
      this.statements$ = this.store.select(fromStatementSet.selectStatements(this.selectedElement.statement_set.id));
      this.dividers$ = this.templateService.getDividers(this.selectedElement.statement_set.id);

      this.sectionElements = this.elements.filter((el) => el.section.type === this.selectedSection.value);
      this.filteredElements = this.sectionElements;
      this.subscription.add(
        this.statements$.pipe(take(1)).subscribe((statements) => {
          this.topStatement = statements[0];
        }),
      );
    }
  }

  onSectionChanged(section: string) {
    this.sectionElements = this.elements.filter((el) => el.section.type === section);
    this.filteredElements = this.sectionElements;
    this.searchTextChanged(this.searchText);
    this.searchTextInput.nativeElement.focus();
  }

  searchTextChanged(searchTerm: string) {
    if (!searchTerm) {
      this.filteredElements = this.sectionElements;
    } else {
      this.filteredElements = this.sectionElements.filter((e) => {
        return e.statement_set.name.toLowerCase().includes(searchTerm.toLowerCase());
      });
    }
    this.autoSelectElement();
  }

  autoSelectElement() {
    if (this.filteredElements.length === 0) {
      this.selectedElement = undefined;
      this.statements$ = of([]);
      return;
    }

    const element = this.filteredElements.find((e) => e.statement_set.statements.includes(this.statement_id));
    if (element) {
      this.selectedElement = element;
    } else {
      this.selectedElement = this.filteredElements[0];
    }
    // Check to load statements in statement sets because the element is now lazy loaded
    this.subscription.add(
      this.templateService.loadStatementSet(this.selectedElement.statement_set.id, this.topic.template_id).subscribe(),
    );

    this.statements$ = this.store.select(fromStatementSet.selectStatements(this.selectedElement.statement_set.id));
    requestAnimationFrame(() => {
      this.scrollToSelectedElement();
    });
  }

  scrollToSelectedElement() {
    if (!this.selectedElement) return;
    const item = document.getElementById(`set-${this.selectedElement.statement_set.id}`);
    if (item) {
      item.scrollIntoView();
    }
  }

  onElementSelected($event: Event) {
    $event.preventDefault();
    if (!this.selectedElement) return;

    // Check to load statements in statement sets because the element is now lazy loaded
    this.subscription.add(
      this.templateService.loadStatementSet(this.selectedElement.statement_set.id, this.topic.template_id).subscribe(),
    );
    this.statements$ = this.store.select(fromStatementSet.selectStatements(this.selectedElement.statement_set.id));
    this.subscription.add(
      this.statements$.pipe(take(1)).subscribe((statements) => {
        this.topStatement = statements[0];
      }),
    );
  }
  onMoveStatementClicked(statement_set_id: number, statement_id: number | null) {
    if (statement_id === this.statement_id) throw new Error("Can't move a statement to itself");
    if (statement_set_id === this.statement.statement_set_id) {
      this.subscription.add(
        this.templateService.moveStatementBefore(this.statement, statement_id).subscribe(() => {
          this.editorService.publishFocus({
            statement_id: this.statement.id,
            element_id: this.selectedElement?.element.id,
            target: this.parent,
          });
          this.onMoveCompleted.emit();
        }),
      );
    } else {
      if (statement_id === null) {
        throw new Error('statement_id is null');
      }
      this.subscription.add(
        this.templateService
          .moveStatementToStatementSet(this.statement.id, statement_set_id, statement_id, true)
          .subscribe(() => {
            this.editorService.publishFocus({
              statement_id: this.statement.id,
              element_id: this.selectedElement?.element.id,
              target: this.parent,
            });
            this.onMoveCompleted.emit();
          }),
      );
    }
  }
}
