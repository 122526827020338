import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { BookingRateActions } from '../../booking-rate/booking-rate.action';
import { SignatureActions } from '../../signature/signature.action';
import { SiteActions } from '../../site/site.action';
import { UserActions, UserBatchActions } from './user.action';
import { UserHttpResponse, UserHttpService } from './user.service';

@Injectable()
export class UserEffect {
  constructor(
    private store: Store<AppState>,
    private service: UserHttpService,
    private message: MessageService,
  ) {}

  findAll(params?: HttpParams) {
    // TODO(bookingRate): return booking rates from api
    return this.service.findAll(params).pipe(
      this.message.handleHttpErrorPipe,
      map(({ users, booking_rates }) => {
        return UserBatchActions.findAllSuccessBatchAction({
          actions: {
            userFindAllSuccessAction: UserActions.findAllSuccess({
              users,
            }),
            bookingRates: BookingRateActions.upsertMany({
              bookingRates: booking_rates,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findById(userId: number) {
    return this.service.findById(userId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ user, booking_rates }) =>
        UserBatchActions.findSuccessBatchAction({
          actions: {
            user: UserActions.findByIdSuccess({ user }),
            bookingRates: BookingRateActions.upsertMany({ bookingRates: booking_rates }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(userId: number, changes: Partial<RR.User> & { deactivate?: boolean; admin_password?: string }) {
    return this.service.update(userId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((user) => UserActions.upsertOne({ user })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findByRoles(roles: string[]) {
    return this.service.findByRoles(roles).pipe(
      this.message.handleHttpErrorPipe,
      map((response: UserHttpResponse) => {
        return UserBatchActions.findByRolesSuccess({
          actions: {
            userFindManySuccess: UserActions.upsertMany({
              users: response.users,
            }),
            signatureUpdateManySuccessAction: SignatureActions.updateManySuccess({
              signatures: response.signatures,
            }),
            siteUpdateManySuccessAction: SiteActions.upsertMany({ sites: response.sites }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
