import { Component, Input } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'rr-report-accession-number',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './report-accession-number.component.html',
  styleUrl: './report-accession-number.component.css',
})
export class ReportAccessionNumberComponent {
  @Input() report: RR.Report;
  @Input() shortText = false;
}
