import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { PermissionActions } from '../../permission/permission.action';
import { CompanyRoleActions, CompanyRoleBatchActions } from './company-role.action';
import { CompanyRoleHttpService } from './company-role.service';

@Injectable()
export class CompanyRoleEffect {
  constructor(
    private store: Store<AppState>,
    private service: CompanyRoleHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map(({ companyRoles, permissions }) =>
        CompanyRoleBatchActions.findAllSuccess({
          actions: {
            companyRoleFindAllSuccess: CompanyRoleActions.findAllSuccess({ companyRoles }),
            permissionFindAllSuccess: PermissionActions.findAllSuccess({
              permissions,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(companyRole: Partial<RR.CompanyRole>) {
    return this.service.create(companyRole).pipe(
      this.message.handleHttpErrorPipe,
      map((companyRole: RR.CompanyRole) => CompanyRoleActions.createSuccess({ companyRole })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(companyRoleId: string, changes: Partial<RR.CompanyRole>) {
    return this.service.update(companyRoleId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((companyRole) => CompanyRoleActions.updateSuccess({ companyRole })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(companyRoleId: string) {
    return this.service.delete(companyRoleId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => CompanyRoleActions.deleteSuccess({ companyRoleId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addPermission(companyRoleId: string, permissionId: RR.Permission['id']) {
    return this.service.addPermission(companyRoleId, permissionId).pipe(
      map((companyRole) => CompanyRoleActions.updateSuccess({ companyRole })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removePermission(companyRoleId: string, permissionId: RR.Permission['id']) {
    return this.service.removePermission(companyRoleId, permissionId).pipe(
      this.message.handleHttpErrorPipe,
      map((companyRole) => CompanyRoleActions.updateSuccess({ companyRole })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
