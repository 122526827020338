import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { PercentileBandActions } from './percentile-band.action';
import { PercentileBand } from './percentile-band.service';

export type PercentileBandState = EntityState<PercentileBand>;

const adapter = createEntityAdapter<PercentileBand>();
const initialState = adapter.getInitialState();

export const percentileBandReducer = createReducer(
  initialState,
  on(PercentileBandActions.searchSuccess, (state: PercentileBandState, { percentile_bands }) => {
    return adapter.upsertMany(percentile_bands, state);
  }),
  on(PercentileBandActions.createSuccess, (state: PercentileBandState, { percentile_band }) =>
    adapter.addOne(percentile_band, state),
  ),
  on(PercentileBandActions.deleteSuccess, (state: PercentileBandState, { percentile_band_id }) =>
    adapter.removeOne(percentile_band_id.toString(), state),
  ),
  on(PercentileBandActions.updateSuccess, (state: PercentileBandState, { percentile_band }) =>
    adapter.upsertOne(percentile_band, state),
  ),
  on(EditorActions.open, EditorActions.close, () => initialState),
);
