import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class StatementCoincidenceHttpService {
  constructor(private http: HttpClient) {}

  create(statementCoincidence: Partial<RR.StatementCoincidence>): Observable<RR.StatementCoincidence> {
    return this.http.post<RR.StatementCoincidence>('/api/statement_coincidence', statementCoincidence);
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number): Observable<RR.StatementCoincidence[]> {
    return this.http.get<RR.StatementCoincidence[]>(`/api/template/${templateId}/statement_coincidence`);
  }
}
