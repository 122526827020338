import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { SelectPipe } from 'app/shared/pipes/select-pipe';
import { AppState } from 'app/store';
import { PartialBooking } from 'app/store/booking';
import { fromBookingCode } from 'app/store/booking-code';
import { fromSite } from 'app/store/site';
import { fromUser, UserEffect } from 'app/store/user/user';
import { distinctUntilChanged, map, Observable, of, Subscription, switchMap } from 'rxjs';

import { BookingCodeHeadlineComponent } from '../../booking-code-headline/booking-code-headline.component';

@Component({
  selector: 'rr-booking-metadata',
  templateUrl: './booking-metadata.component.html',
  styleUrls: ['./booking-metadata.component.css'],
  standalone: true,
  imports: [CommonModule, BookingCodeHeadlineComponent, SelectPipe],
})
export class BookingMetadataComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() booking: PartialBooking | RR.Booking;
  @Input() parent?: 'REVIEW' | undefined;
  booking$: Observable<PartialBooking | RR.Booking>;
  @Input() selectedDate: Date | null;
  @Input() displayBookingNotes = false;

  subscription = new Subscription();

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  selectBookingCodeFn = (booking_code_id: number) => {
    return this.store.select(fromBookingCode.selectBookingCode(booking_code_id));
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  selectSiteFn = (site_id: number) => {
    return this.store.select(fromSite.selectSite(site_id));
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  selectUserFn = (user_id: number) => {
    return this.store.select(fromUser.selectUser(user_id));
  };

  constructor(
    private store: Store<AppState>,
    private userEffect: UserEffect,
  ) {}

  ngOnInit(): void {
    const userId$ = this.booking$.pipe(
      map((booking) => booking.rr_user_id),
      distinctUntilChanged(),
      filterDefined(),
    );

    this.subscription.add(
      userId$
        .pipe(
          switchMap((userId) =>
            this.store.select(fromUser.selectUsersLoaded(userId)).pipe(map((loaded) => ({ loaded, userId }))),
          ),
          switchMap(({ loaded, userId }) => {
            if (!loaded) {
              return this.userEffect.findById(userId);
            }
            return of(null);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
