import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store/app.state';
import { fromUser } from 'app/store/user/user';
import { fromEvent, Subscription } from 'rxjs';
import { auditTime, map, startWith, take } from 'rxjs/operators';

import { CompanyRoleSelectorComponent } from '../company-role-selector/company-role-selector.component';
import { UserHeadlineComponent } from '../user-headline/user-headline.component';

let nextId = 0;

@Component({
  standalone: true,
  imports: [CommonModule, UserHeadlineComponent, NgbDropdownModule, CompanyRoleSelectorComponent],
  selector: 'rr-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.css'],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class UserSelectorComponent implements OnInit {
  @Input() buttonLabel = 'Users';
  /**
   * single: radio buttons
   * multiple: checkboxes
   */
  @Input() mode: 'single' | 'multiple' = 'multiple';
  @Input() filterByRole = false;
  @Input() initialUsers: number[] | undefined;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @Output() onSelect = new EventEmitter<RR.User[]>();
  openedUsers = false;
  subscription = new Subscription();
  /**
   * So that the labels and checkbox inputs have unique ids across instances of this component
   */
  instanceId = `${nextId++}`;

  allUsers: RR.User[];
  users: RR.User[];
  checkedUsers: { [id: number]: RR.User } = {};
  checkedUsersList: RR.User[] = []; // to avoid Object.values() function call in the template

  currentUser: RR.User;
  searchQuery = '';

  constructor(
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromUser.selectActiveUsers)
        .pipe(take(1))
        .subscribe((users) => {
          if (this.initialUsers && this.initialUsers.length > 0) {
            this.initialUsers.forEach((userId) => {
              const user = users.find((u) => u.id === userId);
              if (user) {
                // Set the initial state without making onSelect emit (like in `onChange`)
                this._checkSingle(user);
              }
            });
          }
        }),
    );

    this.subscription.add(
      this.store.select(fromUser.selectActiveUsers).subscribe((users) => {
        this.allUsers = users;
        this.searchUsers('');
      }),
    );

    this.subscription.add(
      fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(
          auditTime(300),
          map((event) => {
            const target = event.target as HTMLInputElement;
            return target.value;
          }),
          startWith(''),
        )
        .subscribe((searchQuery) => {
          this.searchQuery = searchQuery;
          this.searchUsers(searchQuery);
        }),
    );

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((currentUser) => {
        // @ts-expect-error strictNullChecks
        this.currentUser = currentUser;
      }),
    );
  }

  searchUsers(search: string, companyRoles: RR.CompanyRole[] = []) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    let users = [...this.allUsers].filter((u) => u);
    users = users.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.currentUser) {
      const index = users.findIndex((value) => {
        return value.id === this.currentUser.id;
      });
      if (index !== -1) {
        // move current user to top of the list
        const deleted = users.splice(index, 1)[0]; // delete it
        users.splice(0, 0, deleted); // add it back to the top
      }
    }

    users = users.filter((u) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (this.checkedUsers[u.id]) {
        // always show the checked user
        return true;
      }
      return (
        // if there are search terms, they must match the user.
        (!search || u.name.toLowerCase().includes(search.toLowerCase())) &&
        // if roles are checked, they must match the user.
        (!companyRoles.length ||
          u.company_roles.some((id) => companyRoles.map((checkedRole) => checkedRole.id).includes(id)))
      );
    });
    this.users = users;
  }

  _checkSingle(user: RR.User) {
    if (this.mode === 'multiple') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (this.checkedUsers[user.id]) {
        delete this.checkedUsers[user.id];
      } else {
        this.checkedUsers[user.id] = user;
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (this.mode === 'single') {
      this.checkedUsers = {
        [user.id]: user,
      };
    }
    this.checkedUsersList = Object.values(this.checkedUsers);
  }

  onChange(user: RR.User) {
    this._checkSingle(user);
    this.onSelect.emit(this.checkedUsersList);
  }

  onRoleChanged(roles: RR.CompanyRole[]) {
    this.searchUsers(this.searchQuery, roles);
  }

  onUserDropdown(didOpen: boolean) {
    if (didOpen) {
      requestAnimationFrame(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  onReset() {
    this.checkedUsers = {};
    this.checkedUsersList = [];
  }
}
