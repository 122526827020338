import { createActionGroup, props } from '@ngrx/store';

/**
 * Ngrx Actions for SubsectionChoice
 *
 */
export const SubsectionChoiceActions = createActionGroup({
  source: 'Subsection Choice',
  events: {
    addMany: props<{ subsectionChoices: RR.SubsectionChoice[] }>(),
    upsertMany: props<{ subsectionChoices: RR.SubsectionChoice[] }>(),
    removeMany: props<{ subsectionChoiceIds: number[] }>(),
  },
});
