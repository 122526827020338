import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { ScanCodeActions } from 'app/store/scan-code/scan-code.action';
import { map, tap } from 'rxjs/operators';

import { PatientQuestionSetActions } from '../patient-question-set/patient-question-set.action';
import { PatientQuestionActions, PatientQuestionBatchActions } from './patient-question.action';
import {
  PatientQuestionDeleteResponse,
  PatientQuestionCreationResponse,
  NestingPatientQuestionResponse,
  ScanCodePatientQuestionsResponse,
} from './patient-question.service';
import { PatientQuestionHttpService } from './patient-question.service';

@Injectable()
export class PatientQuestionEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientQuestionHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((patientQuestions: RR.PatientQuestion[]) => PatientQuestionActions.findAllSuccess({ patientQuestions })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(patientQuestionId: number, changes: Partial<RR.PatientQuestion>) {
    return this.service.update(patientQuestionId, changes).pipe(
      map((patientQuestion) => PatientQuestionActions.updateSuccess({ patientQuestion })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createInSet(patientQuestionSetId: number, patientQuestion: Partial<RR.PatientQuestion>, allScanCode: boolean) {
    return this.service.createInSet(patientQuestionSetId, patientQuestion, allScanCode).pipe(
      map((data: PatientQuestionCreationResponse) =>
        PatientQuestionBatchActions.createSuccessBatch({
          actions: {
            findManyScanCodesSuccess: ScanCodeActions.findManySuccess({ scanCodes: data.scan_codes }),
            updatePatientQuestionSetSuccess: PatientQuestionSetActions.updateSuccess({
              patientQuestionSet: data.patient_question_set,
            }),
            createPatientQuestionSuccess: PatientQuestionActions.createSuccess({
              patientQuestion: data.patient_question,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * Delete patient question permanently from a question set.
   * Update affected question set and current template
   * @param patientQuestionId
   * @param templateId
   * @returns
   */
  delete(patientQuestionId: number) {
    return this.service.delete(patientQuestionId).pipe(
      map((data: PatientQuestionDeleteResponse) => {
        return PatientQuestionBatchActions.deleteSuccessBatch({
          actions: {
            findManyScanCodesSuccess: ScanCodeActions.findManySuccess({ scanCodes: data.scan_codes }),
            updatePatientQuestionSetSuccess: PatientQuestionSetActions.updateSuccess({
              patientQuestionSet: data.patient_question_set,
            }),
            deletePatientQuestionSuccess: PatientQuestionActions.deleteSuccess({ patientQuestionId }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addToScanCode(scanCodeId: number, patientQuestionId: number) {
    return this.service.addToScanCode(scanCodeId, patientQuestionId).pipe(
      map((data: ScanCodePatientQuestionsResponse) => ScanCodeActions.updateSuccess({ scanCode: data.scan_code })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeFromScanCode(scanCodeId: number, patientQuestionId: number) {
    return this.service.removeFromScanCode(scanCodeId, patientQuestionId).pipe(
      map((data: ScanCodePatientQuestionsResponse) => ScanCodeActions.updateSuccess({ scanCode: data.scan_code })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  nestOrUnnest(parent_question_id: number, question_id: number) {
    return this.service.nestOrUnnest(parent_question_id, question_id).pipe(
      map((data: NestingPatientQuestionResponse) =>
        PatientQuestionBatchActions.nestOrUnnestSuccessBatch({
          actions: {
            updateParentQuestionSuccess: PatientQuestionActions.updateSuccess({
              patientQuestion: data.parent_patient_question,
            }),
            updatePatientQuestionSuccess: PatientQuestionActions.updateSuccess({
              patientQuestion: data.patient_question,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
