import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromPermission } from 'app/store/permission';

const adapter = createEntityAdapter<RR.CompanyRole>();
const selectFeature = (state: AppState) => state.company_role;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectCompanyRole = (id: string) =>
  createSelector(selectEntities, (roles: Dictionary<RR.CompanyRole>) => roles[id]);

const selectPermissions = (companyRoleId: string) => {
  return createSelector(
    selectCompanyRole(companyRoleId),
    fromPermission.selectEntities,
    (companyRole: RR.CompanyRole | undefined, permissions: Dictionary<RR.Permission>) => {
      return (
        companyRole?.permissions
          .map((permissionId) => permissions[permissionId])
          .filter((permission): permission is RR.Permission => permission !== undefined) ?? []
      );
    },
  );
};

const selectLoaded = createSelector(selectFeature, ({ loaded }) => loaded);

export const fromCompanyRole = {
  selectIds,
  selectEntities,
  selectAll,
  selectCompanyRole,
  selectPermissions,
  selectLoaded,
};
