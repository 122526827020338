import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EditorExpandedState } from 'app/modules/editor/editor.component';
import { DocumentAttachmentButtonComponent } from 'app/modules/registration/components/document-attachment-button/document-attachment-button.component';
import { VoyagerStatusBadgeComponent } from 'app/modules/worklist/components/voyager-status-badge/voyager-status-badge.component';
import { ZeroFPImagesComponent } from 'app/shared/components/zero-fp-images/zero-fp-images.component';

import { ReportDateComponent } from '../../../../../shared/components/report-date/report-date.component';
import { RelatedReport } from '../prior-study-links.service.component';
import { RelatedReportRowComponent } from '../related-report-row/related-report-row.component';
import { RelatedTopicComponent } from '../related-topic/related-topic.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RelatedTopicComponent,
    RouterModule,
    ReportDateComponent,
    DocumentAttachmentButtonComponent,
    ZeroFPImagesComponent,
    VoyagerStatusBadgeComponent,
    RelatedReportRowComponent,
  ],
  selector: 'rr-related-report',
  templateUrl: './related-report.component.html',
})
export class RelatedReportComponent {
  @Input() report: RR.Report;
  @Input() editorExpandedState: EditorExpandedState;
  @Input() selectable: boolean;
  @Input() relatedReports: RelatedReport[];

  constructor() {}
}
