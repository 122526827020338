import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface FindAttributeSetUsageParams {
  offset: number;
  limit: number;
}

export interface StatementCreateBody {
  index?: number;
  statement: Partial<Omit<RR.Statement, 'text'>>;
}

export interface TextObjectsPatchResponse {
  defaultAttributes: RR.DefaultAttribute[];
  textObjects: RR.TextObject[];
  statement: RR.Statement;
  textObjectsDeleted: RR.TextObject['id'][];
}

// No 'id' field when creating. Must have type, attribute_set_id, and order. The rest are optional
type RequiredTextObjectSetFields = 'type' | 'attribute_set_id' | 'order';
export type CreateTextObjectSet = Pick<RR.TextObjectSet, RequiredTextObjectSetFields> &
  Partial<Omit<RR.TextObjectSet, RequiredTextObjectSetFields | 'id'>> & {
    default_option_id?: number;
  };
// Must have 'id' when updating.
// Note: the backend does accept a Partial, but it makes the frontend types confusing.
type UpdateTextObjectSet = Pick<RR.TextObjectSet, 'id'> & CreateTextObjectSet & { default_option_id?: number };

type RequiredTextObjectDateFields = 'type';
export type CreateTextObjectDate = Pick<RR.TextObjectDate, RequiredTextObjectDateFields> &
  Partial<Omit<RR.TextObjectDate, RequiredTextObjectDateFields | 'id'>>;

type UpdateTextObjectDate = Pick<RR.TextObjectDate, 'id'> & CreateTextObjectDate;

type RequiredTextObjectNumberFields = 'type';
export type CreateTextObjectNumber = Pick<RR.TextObjectNumber, RequiredTextObjectNumberFields> &
  Partial<Omit<RR.TextObjectNumber, RequiredTextObjectNumberFields | 'id'>>;

type UpdateTextObjectNumber = Pick<RR.TextObjectNumber, 'id'> & CreateTextObjectNumber;

export type CreateTextObjectLiteral = Pick<RR.TextObjectLiteral, 'type' | 'value'>;
type UpdateTextObjectLiteral = Pick<RR.TextObjectLiteral, 'id'> & CreateTextObjectLiteral;

export type PatchTextObject =
  | CreateTextObjectSet
  | UpdateTextObjectSet
  | CreateTextObjectNumber
  | UpdateTextObjectNumber
  | CreateTextObjectLiteral
  | UpdateTextObjectLiteral
  | CreateTextObjectDate
  | UpdateTextObjectDate;

@Injectable()
export class StatementHttpService {
  constructor(private http: HttpClient) {}

  create(data: StatementCreateBody) {
    return this.http.post<{
      textObjects: RR.TextObject[];
      statement: RR.Statement;
      statementSet: RR.StatementSet;
      defaultAttributes: RR.DefaultAttribute[];
    }>('/api/statement', data);
  }

  approve(statementChoiceId: number, text?: string) {
    return this.http.post<{
      textObjects: RR.TextObject[];
      statement: RR.Statement;
      statementSet: RR.StatementSet;
      defaultAttributes: RR.DefaultAttribute[];
      statementChoice: RR.StatementChoice;
      textObjectChoices: RR.TextObjectChoice[];
    }>(`/api/statement_choice/${statementChoiceId}/approve`, { text });
  }

  update(statementId: number, changes: Partial<RR.Statement>) {
    return this.http.put<{
      textObjects: RR.TextObject[];
      statement: RR.Statement;
      statementSets: RR.StatementSet[];
      defaultAttributes: RR.DefaultAttribute[];
    }>(`/api/statement/${statementId}`, changes);
  }

  patchTextObjects(statementId: number, textObjects: PatchTextObject[]) {
    return this.http.patch<TextObjectsPatchResponse>(`/api/statement/${statementId}/text_objects`, {
      text_objects: textObjects,
    });
  }

  delete(statementId: number) {
    return this.http.delete<RR.StatementSet>(`/api/statement/${statementId}`);
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number): Observable<RR.Statement[]> {
    return this.http.get<RR.Statement[]>(`/api/template/${templateId}/statement`);
  }

  findInStatementSet(statementSetId: number) {
    return this.http.get<{
      statements: RR.Statement[];
      textObjects: RR.TextObject[];
      statementCoincidences: RR.StatementCoincidence[];
    }>(`/api/statement_set/${statementSetId}/statement`);
  }

  findAttributeSetUsage(attributeSetId: number, { offset, limit }: FindAttributeSetUsageParams) {
    const params = new HttpParams().set('offset', String(offset)).set('limit', String(limit));
    return this.http.get<{ count: number; statements: RR.Statement[]; textObjects: RR.TextObject[] }>(
      `/api/attribute_set/${attributeSetId}/usage`,
      { params },
    );
  }

  findStatementBuilder() {
    return this.http.get<{
      statementSet: RR.StatementSet;
      statements: RR.Statement[];
      textObjects: RR.TextObject[];
      template: RR.Template;
      defaultAttributes: RR.DefaultAttribute[];
    }>('/api/statement_template');
  }
}
