import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type Focus = 'initials' | 'study';

@Injectable()
export class FocusService {
  /**
   * The inputs that are requesting focus.
   */
  requesting: Focus[] = [];

  priority: Focus[] = ['initials', 'study'];

  subjects = {
    initials: new Subject(),
    study: new Subject(),
  };

  requestFocus(focus: Focus) {
    setTimeout(() => {
      this.add(focus);
    });
    return this.subjects[focus];
  }

  add(focus: Focus) {
    this.requesting.push(focus);
    this.focusNext();
  }

  remove(focus: Focus) {
    this.requesting.splice(this.requesting.indexOf(focus), 1);
    this.focusNext();
  }

  focusNext() {
    for (const focus of this.priority) {
      if (this.requesting.includes(focus)) {
        this.subjects[focus].next(true);
        break;
      }
    }
  }
}
