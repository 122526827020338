import { ComponentFactoryResolver, Directive, ElementRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { MentionDirective } from 'angular-mentions';
import { AppState } from 'app/store';
import { fromKeywordAbbreviation } from 'app/store/keyword-abbr';
import { skipWhile, Subscription, switchMap, take } from 'rxjs';

type AbbrItem = {
  label: string;
  value: string;
};

@Directive({
  standalone: true,
  selector: '[rrAbbr]',
})
export class AbbreviationDirective extends MentionDirective implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();

  constructor(
    el: ElementRef,
    componentResolver: ComponentFactoryResolver,
    viewContainerRef: ViewContainerRef,
    private store: Store<AppState>,
  ) {
    super(el, componentResolver, viewContainerRef);
    this.mentionConfig = {
      mentionSelect: (item: AbbrItem) => {
        return item.value;
      },
    };
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromKeywordAbbreviation.selectLoaded)
        .pipe(
          skipWhile((loaded) => !loaded),
          switchMap(() => this.store.select(fromKeywordAbbreviation.selectAll)),
          take(1),
        )
        .subscribe((abbrs) => {
          this.mention = abbrs.map((abbr) => ({ label: `${abbr.abbreviation}: ${abbr.keyword}`, value: abbr.keyword }));
          this.updateConfig();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
