import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';

import { PermissionDirective } from './directives/permission.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TrackDirective } from './directives/track.directive';
import { IncludesPipe } from './pipes/includes.pipe';
import { SelectPipe } from './pipes/select-pipe';
import { StoreSelectPipe } from './pipes/store-select.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    LetDirective,
    StoreSelectPipe,
    SelectPipe,
    IncludesPipe,
    PermissionDirective,
    TooltipDirective,
    TrackDirective,
  ],
  exports: [
    // If a module imports shared.module, no need to import these.
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    LetDirective,
    StoreSelectPipe,
    SelectPipe,
    IncludesPipe,
    PermissionDirective,
    TooltipDirective,
    TrackDirective,
  ],
  providers: [],
})
export class SharedModule {
  // hack to prevent `ng generate @angular/core:standalone` removing SharedModule, "Has no class members"
  _dummy = 1;
}
