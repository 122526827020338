import { Dictionary, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { AddedTopic } from '../report/report/report.service';

const adapter = createEntityAdapter<AddedTopic>();
const selectFeature = (state: AppState) => state.added_topics;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectAddedTopic = (topicId: number) =>
  createSelector(selectEntities, (addedTopics: Dictionary<AddedTopic>) => addedTopics[topicId]);

export const fromAddedTopic = {
  selectIds,
  selectEntities,
  selectAll,
  selectAddedTopic,
};
