import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { NoticeActions } from './notice.action';

export interface NoticeState extends EntityState<RR.Notice> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.Notice>();

const initialState = adapter.getInitialState({
  loaded: false,
});

export const noticeReducer = createReducer(
  initialState,
  on(NoticeActions.findAllSuccess, (state: NoticeState, { notices }) => ({
    ...adapter.setAll(notices, state),
    loaded: true,
  })),
  on(NoticeActions.createSuccess, (state: NoticeState, { notice }) => adapter.addOne(notice, state)),
  on(NoticeActions.deleteSuccess, (state: NoticeState, { noticeId }) => adapter.removeOne(noticeId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
