import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { TemplateService } from 'app/core/services/template.service';
import { LinkifyDirective } from 'app/shared/directives/linkify/linkify.directive';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, LinkifyDirective],
  selector: 'rr-literal-placeholder',
  templateUrl: './literal-placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiteralPlaceholderComponent implements OnInit, OnDestroy {
  @Input() text_object: RR.TextObjectLiteral;
  @Input() @BindObservable() region: RR.Region | undefined;
  region$: Observable<RR.Region | undefined>;

  text: string;
  subscription = new Subscription();

  constructor(private templateService: TemplateService) {}

  ngOnInit() {
    this.subscription.add(
      this.region$
        .pipe(
          map((region) =>
            this.text_object.value
              ? this.templateService.replaceStatementTextTemplateWithRegion(this.text_object.value, region)
              : '',
          ),
        )
        .subscribe((text: string) => {
          return (this.text = text);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
