import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuditEventHttpService {
  constructor(private http: HttpClient) {}

  getSummary(data: {
    from_date: string;
    to_date: string | undefined;
    topic_ids: number[];
    user_id: number;
  }): Observable<RR.AuditEventSummary> {
    return this.http.post<RR.AuditEventSummary>('/api/audit_summary', {
      from_date: data.from_date,
      to_date: data.to_date,
      topic_ids: data.topic_ids,
      user_id: data.user_id,
    });
  }

  create(event: Partial<RR.AuditEvent>): Observable<RR.AuditEvent> {
    return this.http.post<RR.AuditEvent>('/api/audit_events', event);
  }

  createRegistration(event: Partial<RR.AuditEvent>): Observable<RR.AuditEvent> {
    return this.http.post<RR.AuditEvent>('/api/audit_events/registration', event);
  }

  findAll(topicId: number) {
    const params = new HttpParams().set('topic_id', topicId.toString());
    return this.http.get<{ audit_events: RR.AuditEvent[]; users: RR.User[] }>('/api/audit_events', {
      params,
    });
  }
}
