import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type RequestMergeReport = {
  left_report_id: number;
  right_report_id: number;
  resolved_conflicts: Pick<RR.EntityMergeConflict<RR.Report>, 'field_name' | 'consensus_value'>[];
};

@Injectable()
export class ReportMergeConflictService {
  constructor(private http: HttpClient) {}

  findConflicts(leftReportId: number, rightReportId: number) {
    return this.http.get<RR.EntityMergeConflict<RR.Report>[]>('/api/report/conflicts', {
      params: new HttpParams().set('left_report_id', leftReportId).set('right_report_id', rightReportId),
    });
  }

  merge(data: RequestMergeReport) {
    return this.http.put<RR.Report>('/api/report/merge', data);
  }
}
