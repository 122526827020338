<div class="col-sm-9 mx-auto">
  <h2 class="text-center mb-2">
    <strong>{{ header }}</strong>
  </h2>
  <div class="d-flex">
    <div class="col-sm-12">
      <div class="mb-1 d-flex align-items-center gap-3">
        <div>
          Ahpra:
          <a target="_blank" class="link-primary" href="https://www.ahpra.gov.au/">
            Look up health practitioner
            <span class="ms-1 small fa fa-external-link-alt"></span>
          </a>
        </div>
        <div *ngIf="referrer">
          Referrer management page
          <a
            target="_blank"
            class="text-secondary ms-1"
            [routerLink]="['/entities/referrers', referrer.id]"
            data-no-bubble
            rrTooltip="OPEN_REFERRER_MANAGEMENT_PAGE"
          >
            <i class="fas fa-external-link-square-alt"></i>
          </a>
        </div>
      </div>
      <div class="form-check mb-2 bg-light p-2 rounded d-flex" *ngIf="!referrer && !removedReferrer">
        <div class="ms-4">
          <input
            class="form-check-input"
            type="checkbox"
            id="no-referrer"
            [checked]="noReferrer"
            (change)="onNoReferrerChange.emit($event)"
          />
        </div>

        <label class="form-check-label" for="no-referrer">This registration has no referrer.</label>
      </div>

      <rr-referrer-form-search
        [hidden]="!(viewMode === 'search' && !noReferrer)"
        [removedReferrer]="removedReferrer"
        [bookingId]="bookingId"
        (onCreateNewReferrer)="createNewReferrer()"
        (onUndoRemoval)="cancel()"
        (onSelectReferrer)="selectReferrer($event)"
      ></rr-referrer-form-search>

      <div class="mb-2">
        <div class="badge bg-warning" *ngIf="formStatus === 'INVALID' && (viewMode === 'edit' || viewMode === 'view')">
          Missing Details
        </div>
        <div
          class="badge bg-danger"
          *ngIf="
            formStatus === 'INVALID' &&
            (viewMode === 'edit' || viewMode === 'view') &&
            referrer &&
            !referrer.referrer_type
          "
        >
          Referrer type is missing for the current referrer. Please specify the type of referrer to proceed with the
          registration. Click on 'Edit Referrer' to fill in the referrer type.
        </div>
        <ng-container *ngIf="booking$ | async as booking">
          <div
            class="badge bg-danger ms-1"
            *ngIf="
              formStatus === 'INVALID' &&
              (viewMode === 'edit' || viewMode === 'view') &&
              booking.referrer_type &&
              referrer &&
              referrer.referrer_type &&
              booking.referrer_type !== referrer?.referrer_type
            "
          >
            The selected referrer type ({{ referrerTypes[referrer.referrer_type] }}) does not match the referrer type
            ({{ referrerTypes[booking.referrer_type] }}) specified at the time of booking creation.
          </div>
        </ng-container>
      </div>

      <rr-referrer-form-view
        *ngIf="referrer && viewMode === 'view'"
        [referrer]="referrer"
        [invalid]="formStatus === 'INVALID'"
      >
        <ng-container buttons>
          <button
            type="button"
            class="btn btn-outline-primary"
            (click)="editReferrer()"
            rrTooltip="REGISTRAION_EDIT_REFERRER"
          >
            Edit Referrer
          </button>
          <button
            class="btn btn-outline-primary mt-2"
            (click)="removeReferrer()"
            rrTooltip="REGISTRAION_REMOVE_REFERRER"
          >
            Temp Clear Referrer (Safe)
          </button>
          <button *ngIf="reportId" class="btn btn-outline-secondary mt-2" (click)="deleteReferrerReport()">
            Remove Referrer From Report
          </button>
        </ng-container>
      </rr-referrer-form-view>

      <rr-referrer-form-edit
        [hidden]="!(viewMode === 'edit' || viewMode === 'create')"
        [viewMode]="viewMode"
        [referrer]="referrer"
        [parent]="parent"
        (onCancel)="cancel()"
        (onChange)="selectReferrer($event)"
        (onStatusChange)="setFormStatus($event)"
      ></rr-referrer-form-edit>
    </div>
  </div>
</div>
