import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PatientMergeConflictService {
  constructor(private http: HttpClient) {}

  findConflicts(leftPatientId: number, rightPatientId: number) {
    return this.http.get<RR.EntityMergeConflict<RR.Patient>[]>('/api/patient/conflicts', {
      params: new HttpParams().set('left_id', leftPatientId).set('right_id', rightPatientId),
    });
  }

  merge(data: RR.EntityMergePayload<RR.Patient>) {
    return this.http.put<RR.Patient>('/api/patient/merge', data);
  }
}
