import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TemplateData } from '../report/topic/topic.service';

export type MandatoryStatementCreate = {
  template_id: number;
} & Pick<RR.MandatoryStatement, 'type' | 'statement_id' | 'divider_id' | 'reason'>;

@Injectable()
export class MandatoryStatementHttpService {
  constructor(private http: HttpClient) {}
  create(data: MandatoryStatementCreate) {
    return this.http.post<{
      template: RR.Template;
      mandatory_statement: RR.MandatoryStatement;
    }>('/api/mandatory_statement', data);
  }

  delete(mandatoryStatementId: number) {
    return this.http.delete<RR.Template>(`/api/mandatory_statement/${mandatoryStatementId}`);
  }

  findInTemplate(templateId: number) {
    return this.http.get<
      {
        template: RR.Template;
        mandatory_statements: RR.MandatoryStatement[];
      } & TemplateData
    >(`/api/template/${templateId}/mandatory_statement`);
  }
}
