import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { ReportAccessEventEffect } from 'app/store/report/access-event';
import { fromCurrentReport } from 'app/store/report/report';
import { BeforeLogout, SessionActions } from 'app/store/session';
import { interval, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
const TRACKING_TIMEOUT = 60 * 1000; // 60s

@Component({
  selector: 'rr-time-tracker',
  templateUrl: './time-tracker.component.html',
  styleUrls: ['./time-tracker.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class TimeTrackerComponent implements OnInit, OnDestroy, BeforeLogout {
  report: RR.Report;
  report$: Observable<RR.Report>;
  // Time counter
  timer: Subscription;
  counter: Date = new Date(0, 0, 0, 0, 0, 0);
  totalWorkingTime = 0;
  // Tracking time spent on editing report
  trackingTimerID: any;
  isTracking = false; // pause time tracking after TRACKING_TIMEOUT without any activity
  trackingTimeStart: number;
  subscription = new Subscription();
  currentUser: RR.User;
  loggedOut = false;
  userActiveSubscription: Subscription = new Subscription();

  constructor(
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private editorService: EditorService,
    private store: Store<AppState>,
    private accessEventEffect: ReportAccessEventEffect,
    private selectorService: SelectorService,
  ) {}

  ngOnInit() {
    // @ts-expect-error strictNullChecks
    this.report$ = this.store.select(fromCurrentReport.selectReport).pipe(filter((report) => report != null));

    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );

    // Counting time spent on report
    this.zone.runOutsideAngular(() => {
      // eslint-disable-next-line rxjs-angular/prefer-composition
      this.timer = interval(1000).subscribe((_t) => {
        if (this.isTracking) {
          // Because trackingTimeStart is reset after user becomes inactive
          const activeTime = Math.round((Date.now() - this.trackingTimeStart) / 1000);
          this.updateTimerUI(this.totalWorkingTime + activeTime);
        }
      });
    });

    // If report has not been completed, or it has been changed after sending to Voyager => Track time working on the report
    if (
      !this.report.send_to_voyager_time ||
      (this.report.text_modified && this.report.text_modified > this.report.send_to_voyager_time)
    ) {
      this.setupEventListener();
      this.startTimeTracker();
    }

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((user) => {
        // @ts-expect-error strictNullChecks
        this.currentUser = user;
      }),
    );

    // Register before logout listener so that `doBeforeLogout` can be executed before loging out.
    // Need to bind `doBeforeLogout` with `this` so that it will have the correct scope when executing the function
    this.store.dispatch(SessionActions.registerBeforeLogoutListener({ listener: this.doBeforeLogout.bind(this) }));
  }

  doBeforeLogout() {
    this.loggedOut = true;
    // Don't save snapshot if the report has saved to voyager
    const canSaveSnapShot = !this.report.send_to_voyager_time;

    return this.editorService.getScheduleSendToVoyagerTask().pipe(
      take(1),
      switchMap((report_id) => {
        const duration = Math.round((Date.now() - this.trackingTimeStart) / 1000);
        const obs1 =
          !duration || duration <= 0
            ? of(null)
            : this.reportService.lastReportAccessEvent().pipe(
                filterDefined(),
                switchMap((rae) =>
                  this.accessEventEffect
                    .update(rae.id, { duration }, new HttpParams().set('save_snapshot', String(+canSaveSnapShot)))
                    .pipe(
                      catchError(() => {
                        // This can error if the session expired, we are already logged out. Make sure we still emit a
                        // value so logout can continue.
                        return of(null);
                      }),
                    ),
                ),
              );
        const obs2 = report_id ? this.editorService.sendToVoyager(report_id) : of(null);
        // Check to remove send to voyager task
        if (report_id) {
          this.editorService.setScheduleSendToVoyager(undefined);
        }
        return zip(obs1, obs2);
      }),
    );
  }

  ngOnDestroy() {
    this.userActiveSubscription.unsubscribe();
    this.timer.unsubscribe();
    // Update working time for report access event
    if (!this.loggedOut) {
      this.updateReportEditingTime();
      // Check scheduled task to send report to Voyager
      this.checkAndSendReportToVoyager();
    }
    this.store.dispatch(SessionActions.unregisterBeforeLogoutListener({ listener: this.doBeforeLogout }));
    clearTimeout(this.trackingTimerID);
    this.subscription.unsubscribe();
  }

  @HostListener('window:beforeunload')
  unloadHandler() {
    if (this.isTracking) {
      this.updateReportEditingTime();
    }
    clearTimeout(this.trackingTimerID);
    // Check scheduled task to send report to Voyager
    this.checkAndSendReportToVoyager();
  }

  startTimeTracker() {
    this.trackingTimerID = setTimeout(() => {
      this.zone.run(() => {
        // Time out, user becomes inactive
        this.isTracking = false;
        this.updateReportEditingTime(true);
        // Check scheduled task to send report to Voyager
        this.checkAndSendReportToVoyager();
      });
    }, TRACKING_TIMEOUT);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  resetTimeTracker = () => {
    clearTimeout(this.trackingTimerID);
    // Resume tracking time spent on editing the report
    if (!this.isTracking) {
      this.isTracking = true;
      this.trackingTimeStart = Date.now();
    }
    this.startTimeTracker();
  };

  updateTimerUI(time: number) {
    this.counter = new Date(0, 0, 0, 0, 0, 0);
    this.counter.setSeconds(time);
    this.cd.detectChanges();
  }
  /**
   * Update editing time for report access event
   */
  updateReportEditingTime(inactive = false) {
    // Is user has stopped editing for n seconds, deduct that n seconds from editing time
    // Update duration for access event
    NgZone.assertInAngularZone();
    let duration = Math.round((Date.now() - this.trackingTimeStart) / 1000);
    if (inactive) {
      duration -= TRACKING_TIMEOUT / 1000;
    }
    if (duration > 0) {
      if (inactive) {
        // If user become inactive update total working time
        // Total working time = working time of multiple active sessions
        this.totalWorkingTime += duration;
        this.updateTimerUI(this.totalWorkingTime);
      }
      // Get current access event (for updating time spent on the report)
      this.subscription.add(
        this.reportService
          .lastReportAccessEvent()
          .pipe(
            filter((e) => !!e),
            take(1),
            switchMap((rae) => {
              // Don't save snapshot if the report has saved to voyager
              const canSaveSnapShot = !this.report.send_to_voyager_time;
              return this.accessEventEffect.update(
                // @ts-expect-error strictNullChecks
                rae.id,
                { duration },
                new HttpParams().set('save_snapshot', String(+canSaveSnapShot)),
              );
            }),
          )
          .subscribe(),
      );
    }
  }

  /**
   * Check schedule send to Voyager tasks to send report to Voyager
   */
  checkAndSendReportToVoyager() {
    this.editorService
      .getScheduleSendToVoyagerTask()
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((report_id) => {
        if (report_id) {
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          this.editorService.sendToVoyager(report_id).pipe(take(1)).subscribe();
        }
        this.editorService.setScheduleSendToVoyager(undefined);
      });
  }

  // Setup event listener to check whether user is active or inactive
  setupEventListener() {
    this.zone.runOutsideAngular(() => {
      this.userActiveSubscription = this.editorService.addUserActiveCheckListener(this.resetTimeTracker);
    });
  }
}
