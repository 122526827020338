import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap, map } from 'rxjs/operators';

import { AppState } from '../app.state';
import { SoftwarePreferenceActions } from './software-preferences.action';
import { SoftwarePreferenceHttpService } from './software-preferences.service';

@Injectable()
export class SoftwarePreferenceEffect {
  constructor(
    private store: Store<AppState>,
    private service: SoftwarePreferenceHttpService,
  ) {}

  create(softwarePref: Partial<RR.SoftwarePreference>) {
    return this.service.create(softwarePref).pipe(
      map((data: RR.SoftwarePreference) =>
        SoftwarePreferenceActions.createSuccess({
          software_pref: data,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(softwarePrefId: number) {
    return this.service.delete(softwarePrefId).pipe(
      map((data: RR.SoftwarePreference) =>
        SoftwarePreferenceActions.deleteSuccess({
          software_pref_id: data.id,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(softwarePrefId: number, changes: Partial<RR.SoftwarePreference>) {
    return this.service.update(softwarePrefId, changes).pipe(
      map((software_pref) => SoftwarePreferenceActions.updateSuccess({ software_pref })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  getAll() {
    return this.service.getAll().pipe(
      map((software_prefs) => SoftwarePreferenceActions.getAllSuccess({ software_prefs })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
