<div class="modal-header">
  <div>
    <h5 class="modal-title">Notes (N)</h5>
    <rr-report-headline [reportId]="report_id"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" (click)="saveAndClose()"></button>
</div>

<div class="modal-body">
  <div class="d-flex flex-wrap gap-1 align-items-center mb-2">
    <button
      role="button"
      class="btn btn-outline-secondary"
      (click)="triggerVoiceNoteFocus()"
      rrTooltip="FOCUS_VOICE_NOTES"
    >
      {{ focusVoiceMode ? 'Unfocus voice notes' : 'Focus voice notes' }}
    </button>
    <button
      *ngIf="topic$ | async as topic"
      rrTooltip="OPEN_GROUP_MODAL"
      role="button"
      class="btn btn-outline-info"
      (click)="openDividerStatementsModal(topic)"
    >
      <i class="fas fa-eye"></i>
      Statement Groups (>)
    </button>
    <ng-container *ngIf="report$ | async as report">
      <rr-urgent-button [reportId]="report.id" [btnSize]="''"></rr-urgent-button>
      <rr-super-urgent-button [reportId]="report.id" [btnSize]="''"></rr-super-urgent-button>
    </ng-container>
    <button
      role="button"
      class="btn btn-outline-info"
      (click)="togglePrefill()"
      rrTrack
      rrTooltip="SIDE_PREFILL_TOGGLE"
    >
      Back to Prefill (B)
    </button>
    <button
      [ngbTooltip]="tipContent"
      [openDelay]="500"
      role="button"
      class="btn btn-outline-secondary"
      (click)="openAbbreviations()"
      rrTooltip="ABBR_LIST"
    >
      Abbreviations List
    </button>
    <ng-template #tipContent>
      <div>Use '&commat;' followed by abbreviation in text boxes e.g. '&commat;'</div>
    </ng-template>
    <div>
      <rr-due-date *ngIf="report$ | async as report" [report]="report"></rr-due-date>
    </div>
  </div>
  <div *ngIf="!focusVoiceMode" class="alert alert-info">
    <i class="fa fa-lightbulb text-warning me-1"></i>
    <span>
      Any note containing 'cc'/'cc:' (case + format insensitive) will display the proceeding text in 'Distribute Report
      (R)'
    </span>
  </div>
  <div *ngIf="!focusVoiceMode" class="container mb-5">
    <div class="row">
      <div class="col bg-light p-2 me-4">
        <h3 class="d-flex align-items-end">Admin Notes<span class="badge ms-2 bg-info">Blue</span></h3>
        <div>
          <form class="form-group mb-2 d-flex" data-cy="newTodo">
            <textarea
              rrVoice
              rrAbbr
              class="form-control border-secondary"
              placeholder="Write a new admin note..."
              [formControl]="newTodo"
              name="newTodo"
              autocomplete="off"
              #todoInput
              rrAutoSize
            ></textarea>
            <button [disabled]="!newTodo.value" class="btn btn-primary ms-2" (click)="addTodo()" rrTooltip="SAVE_NOTE">
              Save
            </button>
          </form>
        </div>
        <div class="">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let todo of todos$ | async">
              <rr-todo [todo]="todo"></rr-todo>
            </li>
          </ul>
        </div>
        <ng-container *ngIf="todos$ | async as todos">
          <div class="mt-2" *ngIf="todos.length > 0">
            <i class="fa fa-lightbulb text-warning me-1"></i>
            <span>Click checkbox to resolve/unresolve note</span>
          </div>
          <div class="mt-2" *ngIf="todos.length > 0">
            <i class="fa fa-lightbulb text-warning me-1"></i>
            <span>Hover on note to see the author</span>
          </div>
        </ng-container>
      </div>
      <div class="col bg-light p-2">
        <h3 class="d-flex align-items-end">Clinical Note<span class="badge ms-2 bg-danger">Red</span></h3>
        <div>
          <form class="form-group mb-2 d-flex">
            <textarea
              rrVoice
              rrAbbr
              class="form-control border-secondary"
              placeholder="Write a new clinical note..."
              [formControl]="newNotes"
              name="newNotes"
              autocomplete="off"
              #urgentNotesInput
              rrAutoSize
            ></textarea>

            <button
              [disabled]="!newNotes.value"
              class="btn btn-primary ms-2"
              (click)="addUrgentNotes()"
              rrTooltip="SAVE_NOTE"
            >
              Save
            </button>
          </form>
        </div>
        <div>
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let note of urgentNotes$ | async">
              <div class="d-flex align-items-center">
                <span style="white-space: pre-line">
                  <i class="fas fa-clipboard text-danger me-1"></i>
                  <rr-note-text [note]="note.urgent_notes"></rr-note-text>
                </span>
                <button role="button" class="btn ms-auto" (click)="deleteUrgentNotes(note.id)" rrTooltip="DELETE_NOTE">
                  <span class="fa fa-trash-alt text-danger"></span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <ng-container *ngIf="study_notes || patient_note">
      <div class="row mt-2">
        <!--Voyager study & patient note-->
        <div class="col bg-light p-2 me-4">
          <h3 class="d-flex align-items-end">Voyager Study Note<span class="badge ms-2 bg-warning">Yellow</span></h3>
          <div *ngIf="study_notes">
            <ul class="list-group">
              <li class="list-group-item" *ngIf="study_notes">
                <div class="d-flex align-items-center">
                  <span style="white-space: pre-line">
                    <i class="fas fa-sticky-note text-warning me-2"></i>
                    <rr-note-text [note]="study_notes"></rr-note-text>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col bg-light p-2">
          <h3 class="d-flex align-items-end">Voyager Patient Note</h3>
          <div *ngIf="patient_note">
            <ul class="list-group">
              <li class="list-group-item" *ngIf="patient_note">
                <div class="d-flex align-items-center">
                  <span style="white-space: pre-line">
                    <i class="fas fa-sticky-note text-warning me-2"></i>
                    <rr-note-text [note]="patient_note"></rr-note-text>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="row mt-2">
        <div class="col bg-light p-2 me-4">
          <h3 class="d-flex align-items-end">Collater Note<span class="badge ms-2 bg-secondary">Grey</span></h3>
          <div>
            <ul class="list-group">
              <div class="d-flex">
                <li *ngIf="collaterNotes" class="list-group-item rounded w-100 me-2">
                  <div class="d-flex align-items-center">
                    <span style="white-space: pre-line">
                      <i class="fas fa-sticky-note text-secondary me-2"></i>
                      <rr-note-text [note]="collaterNotes"></rr-note-text>
                    </span>
                  </div>
                </li>

                <button
                  *ngIf="referrer$ | async as referrer"
                  class="btn btn-outline-primary"
                  (click)="editNotes({ referrerId: referrer.id })"
                  [rrTooltip]="collaterNotes ? 'EDIT_NOTE' : 'ADD_NOTE'"
                >
                  {{ collaterNotes ? 'Edit' : 'Add' }}
                </button>
              </div>
            </ul>
          </div>
        </div>
        <div class="col bg-light p-2">
          <h3 class="d-flex align-items-end">Patient Note<span class="badge ms-2 bg-secondary">Grey</span></h3>
          <div>
            <ul class="list-group">
              <div class="d-flex">
                <li *ngIf="patientNotes" class="list-group-item rounded w-100 me-2">
                  <div class="d-flex align-items-center">
                    <span style="white-space: pre-line">
                      <i class="fas fa-sticky-note text-secondary me-2"></i>
                      <rr-note-text [note]="patientNotes"></rr-note-text>
                    </span>
                  </div>
                </li>
                <button
                  *ngIf="patient$ | async as patient"
                  class="btn btn-outline-primary"
                  (click)="editNotes({ patientId: patient.id })"
                  [rrTooltip]="patientNotes ? 'EDIT_NOTE' : 'ADD_NOTE'"
                >
                  {{ patientNotes ? 'Edit' : 'Add' }}
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngrxLet="bookingNote$ | async as bookingNotes">
      <div class="row mt-2 mb-4">
        <div class="col-6 bg-light p-2">
          <h3 class="d-flex align-items-end">Booking Note<span class="badge ms-2 bg-black">Black</span></h3>
          <div>
            <ul class="list-group">
              <div class="d-flex">
                <li *ngIf="bookingNotes" class="list-group-item rounded w-100 me-2">
                  <div class="d-flex align-items-center">
                    <span style="white-space: pre-line">
                      <i class="fas fa-sticky-note me-2"></i>
                      <rr-note-text [note]="bookingNotes"></rr-note-text>
                    </span>
                  </div>
                </li>
                <button
                  *ngIf="report$ | async as report"
                  class="btn btn-outline-primary"
                  (click)="editNotes({ bookingId: report.booking_id })"
                  [rrTooltip]="bookingNotes ? 'EDIT_NOTE' : 'ADD_NOTE'"
                >
                  {{ bookingNotes ? 'Edit' : 'Add' }}
                </button>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="patient$ | async as patient">
      <rr-notes [patientId]="patient.id" [type]="'patient'" [minimalView]="true" [reportId]="report_id">
        <button
          role="button"
          class="btn btn-outline-success"
          (click)="openCreateNoteModal({ patientId: patient.id })"
          rrTooltip="ADD_NOTE"
        >
          <i class="fas fa-plus"></i>
          Add Note
        </button>
      </rr-notes>
    </ng-container>
  </div>

  <rr-voice-notes
    [reportId]="report_id"
    [topicId]="topicId"
    (onVoiceMode)="triggerVoiceNoteFocus($event)"
  ></rr-voice-notes>
</div>
