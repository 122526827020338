<div class="d-flex align-items-center">
  <div class="input-group global-search-input me-1">
    <input
      rrVoice
      [source]="'GLOBAL_SEARCH'"
      #globalSearchInput
      *ngIf="openTopic"
      class="form-control border-secondary"
      type="text"
      placeholder="Search Groups, Statements and Index"
      [(ngModel)]="searchText"
      (input)="onTextChange()"
      rrTooltip="SEARCH_TEMPLATE_TEXT"
      (click)="clickSearchBox()"
      autocomplete="off"
    />
    <button role="button" (click)="clear()" class="btn btn-outline-secondary" rrTooltip="CLEAR_TEXT">
      <span class="fa fa-backspace"></span>
    </button>
    <div ngbDropdown *ngIf="savedGlobalSearches && !!savedGlobalSearches.length">
      <button
        role="button"
        class="btn btn-outline-secondary search-history-button"
        rrTooltip="GLOBAL_SEARCH_SHOW_HISTORY"
        (click)="showSearchHistory()"
        ngbDropdownToggle
      >
        <i class="fas fa-history"></i>
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button ngbDropdownItem *ngFor="let search of savedGlobalSearches" (click)="chooseHistory(search)">
          {{ search }}
        </button>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center">
    <rr-voice-recognition-text></rr-voice-recognition-text>
    <rr-voice-recognition [source]="GLOBAL_SEARCH_VOICE_GROUP"></rr-voice-recognition>
  </div>
</div>

<div #globalSearchDropdown="ngbDropdown" ngbDropdown placement="bottom-left" (openChange)="openChange($event)">
  <div ngbDropdownAnchor class="no-dropdown-toggle"></div>
  <ul ngbDropdownMenu class="scrollable-dropdown pb-0 px-1">
    <nav class="nav-tabs" ngbNav #tabset="ngbNav" [(activeId)]="tabActiveId">
      <ng-container [ngbNavItem]="'sentences-tab'">
        <a ngbNavLink>
          Statements
          <span class="badge bg-primary" *ngIf="statementResultHits">{{ statementResultHits.length }}</span>
        </a>
        <ng-template ngbNavContent>
          <div class="btn-group d-flex justify-content-center py-1" role="group">
            <input
              class="btn-check"
              type="radio"
              [formControl]="searchSection"
              value="global-search-all"
              (click)="clickSectionTab('all')"
              id="global-search-all"
            />
            <label for="global-search-all" class="btn btn-outline-primary btn-sm"> All </label>

            <input
              class="btn-check"
              type="radio"
              [formControl]="searchSection"
              value="history"
              (click)="clickSectionTab('history')"
              id="global-search-history"
            />
            <label for="global-search-history" class="btn btn-outline-primary btn-sm">
              History
              <span
                class="badge bg-primary"
                *ngIf="statementCount && statementCount.history_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              [formControl]="searchSection"
              value="technique"
              (click)="clickSectionTab('technique')"
              id="global-search-technique"
            />
            <label for="global-search-technique" class="btn btn-outline-primary btn-sm">
              Technique
              <span
                class="badge bg-primary"
                *ngIf="statementCount && statementCount.technique_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              [formControl]="searchSection"
              class="btn-check"
              type="radio"
              value="findings"
              (click)="clickSectionTab('findings')"
              id="global-search-findings"
            />
            <label for="global-search-findings" class="btn btn-outline-primary btn-sm">
              Findings
              <span
                class="badge bg-primary"
                *ngIf="statementCount && statementCount.findings_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              value="comment"
              [formControl]="searchSection"
              (click)="clickSectionTab('comment')"
              id="global-search-comment"
            />
            <label for="global-search-comment" class="btn btn-outline-primary btn-sm">
              Comment
              <span
                class="badge bg-primary"
                *ngIf="statementCount && statementCount.comment_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              value="impression_recommendations"
              [formControl]="searchSection"
              (click)="clickSectionTab('impression_recommendations')"
              id="global-search-ir"
            />
            <label for="global-search-ir" class="btn btn-outline-primary btn-sm">
              Impression & Recommendations
              <span
                class="badge bg-primary"
                *ngIf="statementCount && statementCount.impression_recommendations_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>
          </div>
          <div class="search-results-container">
            <li
              ngbDropdownItem
              class="dropdown-item"
              *ngFor="let statement of statementResultHits"
              (click)="chooseAndFocusStatement(statement)"
              role="button"
              rrTooltip="HIGHLIGHT_AND_CHOOSE_STATEMENT"
            >
              <span
                class="badge bg-info"
                *ngIf="
                  searchSection.value === 'all' ||
                  searchSection.value === 'comment' ||
                  searchSection.value === 'impression_recommendations'
                "
                >{{ getSectionTitle(statement._source.section) }}</span
              >
              <div class="d-flex justify-content-between">
                <div class="w-50">
                  <ng-container *ngIf="statement.highlight?.text">
                    <ng-container ngPreserveWhitespaces *ngFor="let highlight of statement.highlight.text">
                      <span class="fw-bold" [innerHTML]="highlight"></span>
                    </ng-container>
                  </ng-container>
                  <span class="fw-bold" *ngIf="!statement.highlight?.text" [innerHTML]="statement._source.text"></span>
                </div>
                <div class="breadcrumb-text text-secondary w-50">
                  <ng-container *ngIf="statement._source.subsection">
                    <span class="fw-bold d-block"> Subsection: {{ statement._source.subsection }} </span>
                  </ng-container>
                  <ng-container *ngIf="statement._source.statement_set_id">
                    <span
                      class="d-block"
                      *ngIf="
                        statement._source.statement_set_id | storeSelectPipe: selectStatementSet | async as statementSet
                      "
                    >
                      Element: {{ statementSet.name }}
                      <span
                        class="jump-to-source-icon text-info"
                        (click)="focusStatementOnly(statement); $event.stopPropagation()"
                        rrTooltip="HIGHLIGHT_STATEMENT_ONLY"
                      >
                        <i class="fa fa-external-link-square-alt"></i>
                      </span>
                    </span>
                  </ng-container>
                </div>
              </div>
            </li>
            <li
              ngbDropdownItem
              class="dropdown-item"
              *ngIf="statementResultHits.length === 0 && !(loading$ | async)"
              disabled
            >
              No search results
            </li>
            <div *ngIf="searchText !== '' && (loading$ | async)" class="d-flex justify-content-center my-2">
              <i class="fa fa-spinner fa fa-spin text-secondary"></i>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-container [ngbNavItem]="'index-tab'">
        <a ngbNavLink>
          <div>
            Index
            <span class="badge bg-primary">{{ (statementSetResult && statementSetResult.hits.hits.length) || 0 }}</span>
          </div>
        </a>
        <!-- .tab-content -->
        <ng-template ngbNavContent>
          <ng-container *ngIf="statementSetResult">
            <li
              ngbDropdownItem
              class="dropdown-item"
              *ngFor="let statementSet of statementSetResult.hits.hits"
              (click)="gotoStatementSet(statementSet)"
              role="button"
            >
              <ng-container *ngIf="statementSet.highlight">
                <span
                  class="fw-bold"
                  *ngFor="let highlight of statementSet.highlight.name"
                  [innerHTML]="highlight"
                ></span>
              </ng-container>
              <span *ngIf="!statementSet.highlight" [innerHTML]="statementSet._source.name"></span>
            </li>
          </ng-container>
          <li
            ngbDropdownItem
            class="dropdown-item"
            *ngIf="!statementSetResult || !statementSetResult.hits.hits.length"
            disabled
          >
            No search results
          </li>
        </ng-template>
      </ng-container>
      <!--TAGS-->
      <ng-container [ngbNavItem]="'tags-tab'">
        <a ngbNavLink>
          <div>
            Groups
            <span class="badge bg-primary" *ngIf="dividerResultHits">{{ dividerResultHits.length }}</span>
          </div>
        </a>
        <!-- .tab-content -->
        <ng-template ngbNavContent>
          <div class="btn-group d-flex justify-content-center py-1" role="group">
            <input
              class="btn-check"
              type="radio"
              [formControl]="searchSection"
              value="all"
              (click)="clickSectionTab('all')"
              id="global-search-tags-all"
            />
            <label class="btn btn-outline-primary btn-sm" for="global-search-tags-all"> All</label>

            <input
              class="btn-check"
              type="radio"
              value="history"
              [formControl]="searchSection"
              (click)="clickSectionTab('history')"
              id="global-search-tags-history"
            />
            <label for="global-search-tags-history" class="btn btn-outline-primary btn-sm">
              History
              <span
                class="badge bg-primary"
                *ngIf="dividerCount && dividerCount.history_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              value="technique"
              [formControl]="searchSection"
              (click)="clickSectionTab('technique')"
              id="global-search-tags-technique"
            />
            <label for="global-search-tags-technique" class="btn btn-outline-primary btn-sm">
              Technique
              <span
                class="badge bg-primary"
                *ngIf="dividerCount && dividerCount.technique_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              value="findings"
              [formControl]="searchSection"
              (click)="clickSectionTab('findings')"
              id="global-search-tags-findings"
            />
            <label for="global-search-tags-findings" class="btn btn-outline-primary btn-sm">
              Findings
              <span
                class="badge bg-primary"
                *ngIf="dividerCount && dividerCount.findings_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              [formControl]="searchSection"
              class="btn-check"
              type="radio"
              value="comment"
              (click)="clickSectionTab('comment')"
              id="global-search-tags-comment"
            />
            <label for="global-search-tags-comment" class="btn btn-outline-primary btn-sm">
              Comment
              <span
                class="badge bg-primary"
                *ngIf="dividerCount && dividerCount.comment_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>

            <input
              class="btn-check"
              type="radio"
              value="impression_recommendations"
              [formControl]="searchSection"
              (click)="clickSectionTab('impression_recommendations')"
              id="global-search-tags-ir"
            />
            <label for="global-search-tags-ir" class="btn btn-outline-primary btn-sm">
              Impression & Recommendations
              <span
                class="badge bg-primary"
                *ngIf="dividerCount && dividerCount.impression_recommendations_count.cardinality.value; let count"
                >{{ count >= 20 ? 20 : count }}</span
              >
            </label>
          </div>
          <div class="search-results-container list-group">
            <ng-container *ngFor="let statement of dividerResultHits">
              <li
                *ngrxLet="statement | selectPipe: tagSelectedSelectorFn | async as selected"
                class="list-group-item-action py-1"
                (click)="onClickTag($event, statement, !!selected)"
                rrTooltip="SEARCH_DIVIDER_IN_PREFILL"
                role="button"
              >
                <span class="badge bg-info" *ngIf="statement._source.section">{{
                  getSectionTitle(statement._source.section)
                }}</span>
                <div class="d-flex justify-content-between">
                  <div class="w-50">
                    <i *ngIf="selected" class="fa fa-check text-success me-1"></i>
                    <ng-container *ngIf="statement.highlight?.text">
                      <ng-container ngPreserveWhitespaces *ngFor="let highlight of statement.highlight.text">
                        <span class="fw-bold" [innerHTML]="highlight"></span>
                      </ng-container>
                    </ng-container>
                    <span
                      class="fw-bold"
                      *ngIf="!statement.highlight?.text"
                      [innerHTML]="statement._source.text"
                    ></span>
                    <button
                      class="btn btn-sm btn-info ms-1"
                      (click)="jumpToTag($event, statement)"
                      rrTooltip="JUMP_TO_DIVIDER_IN_TEMPLATE"
                    >
                      <span class="fa fa-external-link-square-alt"></span>
                    </button>
                  </div>
                  <div class="breadcrumb-text text-secondary w-50">
                    <span class="fw-bold d-block">
                      <strong>Subsection:</strong>
                      {{ statement._source.subsection ? statement._source.subsection : 'UNKNOWN' }}
                    </span>
                    <ng-container *ngIf="statement._source.statement_set_id">
                      <span
                        class="d-block"
                        *ngIf="
                          statement._source.statement_set_id
                            | storeSelectPipe: selectStatementSet
                            | async as statementSet
                        "
                      >
                        <strong>Element:</strong> {{ statementSet.name }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </li>
            </ng-container>
            <li
              ngbDropdownItem
              class="dropdown-item"
              *ngIf="dividerResultHits.length === 0 && !(loading$ | async)"
              disabled
            >
              No search results
            </li>
            <div *ngIf="searchText !== '' && (loading$ | async)" class="d-flex justify-content-center my-2">
              <i class="fa fa-spinner fa fa-spin text-secondary"></i>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="tabset" class="mt-2"></div>
  </ul>
</div>
