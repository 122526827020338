import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { invoiceItemStatusMap } from 'app/modules/invoice/invoice.constants';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule],
  selector: 'rr-invoice-item-table',
  templateUrl: './invoice-item-table.component.html',
  styleUrls: ['./invoice-item-table.component.css'],
})
export class InvoiceItemTableComponent {
  @Input() invoiceItems$: Observable<(RR.InvoiceItem | undefined)[]>;
  itemStatus = invoiceItemStatusMap;
}
