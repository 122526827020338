import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { BookingEffect, fromBooking } from 'app/store/booking';
import { fromReport } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { formatISO } from 'date-fns';
import { Subscription } from 'rxjs';

import { RegistrationLinkComponent } from '../../../../shared/components/registration-link/registration-link.component';
import { ScanCodeHeadlineComponent } from '../../../../shared/components/scan-code-headline/scan-code-headline.component';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';
import { BookingMetadataComponent } from '../../../booking/components/booking-view/booking-metadata/booking-metadata.component';

@Component({
  selector: 'rr-same-day-bookings',
  templateUrl: './same-day-bookings.component.html',
  styleUrls: ['./same-day-bookings.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ScanCodeHeadlineComponent,
    RouterLink,
    RegistrationLinkComponent,
    BookingMetadataComponent,
    StoreSelectPipe,
    ReportAccessionNumberComponent,
  ],
})
export class SameDayBookingsComponent implements OnDestroy {
  @Input() bookingId: number | null;
  @Input() sameDayBookingIds: number[];
  @Output() onSelectBooking: EventEmitter<RR.Booking> = new EventEmitter();

  subscription = new Subscription();

  constructor(private bookingEffect: BookingEffect) {}

  selectScanCode = fromScanCode.selectScanCode;
  selectReport = fromReport.selectReport;
  selectBooking = fromBooking.selectBooking;

  onClickBooking(booking: RR.Booking) {
    if (booking.reports.length > 0 || booking.id === this.bookingId) return;
    if (!booking.arrived_time) {
      this.subscription.add(this.bookingEffect.update(booking.id, { arrived_time: formatISO(new Date()) }).subscribe());
    }
    this.onSelectBooking.emit(booking);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
