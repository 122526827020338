<div>
  <div class="modal-header">
    <h5 class="modal-title">Select Scan Code</h5>
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body scroll-y">
    <h3>Choose a Scan Code</h3>
    <input
      #searchInput
      type="text"
      class="form-control"
      placeholder="Search: UPFEM, CTLEG, XSKUL, MMANK..."
      name="searchInput"
      (keyup)="onSearchTextChanged(searchInput.value)"
    />
    <div class="d-flex flex-column flex-grow-1 mt-1 pt-1 pb-1 align-items-start">
      <button
        *ngFor="let scanCode of filteredScanCodes"
        (click)="selectScanCode(scanCode)"
        role="button"
        class="list-group-item list-group-item-action text-start border-0 py-1"
      >
        <rr-scan-code-headline [scanCode]="scanCode" [showNotes]="true"></rr-scan-code-headline>
      </button>
    </div>
  </div>
</div>
