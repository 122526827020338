import { Dictionary, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.SexSpecificWord>();
const selectFeature = (state: AppState) => state.sex_specific_word;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectSexSpecificWord = (sexSpecificWordId: number) =>
  createSelector(
    selectEntities,
    (sexSpecificWords: Dictionary<RR.SexSpecificWord>) => sexSpecificWords[sexSpecificWordId],
  );

const selectLoaded = createSelector(selectFeature, (state) => state.loaded);

export const fromSexSpecificWord = {
  selectIds,
  selectEntities,
  selectAll,
  selectSexSpecificWord,
  selectLoaded,
};
