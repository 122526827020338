import { createEntityAdapter } from '@ngrx/entity';
import { AppState } from 'app/store/app.state';

import { Parameter } from './imgsim-parameters.service';

const adapter = createEntityAdapter<Parameter>();
const selectFeature = (state: AppState) => state.imgsim_paremeter;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

export const fromImgSimParameter = {
  selectIds,
  selectEntities,
  selectAll,
};
