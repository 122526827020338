<!--DISPLAY FAVOURITE INFORMATION-->
<div (click)="selectPrefill($event)">
  <div class="d-flex">
    <div class="d-flex flex-column flex-grow-1">
      <div class="w-100 unselectable">
        <div>
          <div class="float-end">
            <button
              permission
              [permission-only]="['preset_manage']"
              *ngIf="searchType.value === 'FAVOURITES' && report && report.type === 'default'"
              class="btn btn-primary btn-sm"
              (click)="createPreset(topic._source.report_id)"
            >
              Create preset
            </button>
            <a
              rrTooltip="OPEN_PREFILL_IN_NEW_WINDOW"
              [routerLink]="['/report', topic._source.report_id, 'topic', topic._id]"
              target="_blank"
              class="text-nowrap ms-1"
            >
              <span class="small fa fa-external-link-alt"></span
            ></a>
            <span
              rrTooltip="NAME_FAVOURITE"
              class="fa fa-star px-1"
              [ngClass]="favourite ? 'fav-active' : 'fav-inactive'"
              (click)="onFavouriteClicked($event)"
            ></span>
          </div>
          <!--Scores-->
          <span
            class="badge bg-secondary me-1"
            *ngIf="metric && metric.totalCurrent > 0 && searchType.value !== 'DEBUG'"
            rrTooltip="PREFILL_METRIC_1"
            >{{ (metric.intersection / metric.totalCurrent) * 100 | number: '1.0-0' }}%</span
          >
          <span
            class="badge bg-secondary me-1"
            *ngIf="metric && metric.totalPrefills > 0 && searchType.value !== 'DEBUG'"
            rrTooltip="PREFILL_METRIC_2"
            >{{ (metric.intersection / metric.totalPrefills) * 100 | number: '1.0-0' }}%</span
          >
          <span class="badge bg-secondary me-1" *ngIf="showImageSimilarityScore" title="Image similarity score">{{
            topic._score | number: '1.0-2'
          }}</span>

          <ng-container
            *ngIf="
              searchType.value === 'ALL' ||
              searchType.value === 'IMAGES' ||
              searchType.value === 'SUBDIVISION' ||
              searchType.value === 'DEBUG' ||
              searchType.value === 'RELATED_STUDIES'
            "
          >
            <!--Patient info and report title-->
            <span ngbTooltip="{{ topic._source.patient_age_in_days | date: 'mediumDate' }}"
              ><b>{{ patientShortAgeAndSex() }} </b></span
            >
            <strong class="ms-1">{{ topic._source.title ? topic._source.title : 'No Title' }}</strong>
          </ng-container>
        </div>

        <div *ngIf="favourite">
          <div class="tag" *ngFor="let tag of getManualDisplayTags()">
            <span class="tag-text" [innerHTML]="tag" rrTooltip="HOVER_TAG" data-no-bubble-select-prefill-row></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-auto">
    <ng-container *ngIf="topic.inner_hits && topic.inner_hits.choices.hits.hits.length > 0">
      <div *ngFor="let hit of topic?.inner_hits?.choices?.hits?.hits">
        <strong class="section-title" *ngIf="hit._source?.section">{{ getSectionTitle(hit._source.section) }}</strong>
        <p class="mb-1" [innerHTML]="hit.highlight && hit.highlight['choices.text']"></p>
      </div>
    </ng-container>
  </div>
</div>
