import { Pipe, PipeTransform } from '@angular/core';
import { Selector, Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { Observable, of } from 'rxjs';

type Arr = readonly unknown[];

@Pipe({
  standalone: true,
  name: 'storeSelectPipe',
  pure: true,
})
export class StoreSelectPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform<T, U, V extends Arr>(
    ids: U | undefined,
    /**
     * @param ids are passed from the left side of the pipe `12345 | storeSelect`
     * @param args are passed from the right hand side with the colon syntax `storeSelect: selectorFn:'arg1':'arg2'`
     */
    fnReference: (ids: U, ...args: [...V]) => Selector<AppState, T>,
    ...args: V
  ): Observable<T | null> {
    if (ids === undefined) {
      return of(null);
    }

    return this.store.select(fnReference(ids, ...args));
  }
}
