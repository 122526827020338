import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type BillingGroupItemResponse = {
  billing_group: RR.BillingGroup;
  billing_item: RR.BillingItem;
};

export type ScanCodeBillingGroup = {
  billing_group: RR.BillingGroup;
  scan_code: RR.ScanCode;
};
@Injectable()
export class BillingGroupHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.BillingGroup[]> {
    return this.http.get<RR.BillingGroup[]>('/api/billing_group');
  }

  create(billingGroup: Partial<RR.BillingGroup>): Observable<RR.BillingGroup> {
    return this.http.post<RR.BillingGroup>('/api/billing_group', billingGroup);
  }

  update(billingGroupId: number, changes: Partial<RR.BillingGroup>): Observable<RR.BillingGroup> {
    return this.http.put<RR.BillingGroup>(`/api/billing_group/${billingGroupId}`, changes);
  }

  delete(billingGroupId: number) {
    return this.http.delete<RR.BillingGroup>(`/api/billing_group/${billingGroupId}`);
  }

  addBillingItem(billingGroupId: number, billingItemId: number): Observable<BillingGroupItemResponse> {
    return this.http.post<BillingGroupItemResponse>(
      `/api/billing_group/${billingGroupId}/billing_item/${billingItemId}`,
      {},
    );
  }

  deleteBillingItem(billingGroupId: number, billingItemId: number): Observable<BillingGroupItemResponse> {
    return this.http.delete<BillingGroupItemResponse>(
      `/api/billing_group/${billingGroupId}/billing_item/${billingItemId}`,
    );
  }

  addToScanCode(billingGroupId: number, scanCodeId: number): Observable<ScanCodeBillingGroup> {
    return this.http.post<ScanCodeBillingGroup>(`/api/scan_code/${scanCodeId}/billing_group/${billingGroupId}`, {});
  }

  deleteFromScanCode(billingGroupId: number, scanCodeId: number): Observable<ScanCodeBillingGroup> {
    return this.http.delete<ScanCodeBillingGroup>(`/api/scan_code/${scanCodeId}/billing_group/${billingGroupId}`);
  }
}
