import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { LastClickedBreadcrumb } from 'app/store/editor';
import { fromElement } from 'app/store/template/element';
import { fromSection } from 'app/store/template/section';
import { fromSubsection } from 'app/store/template/subsection';

@Component({
  selector: 'rr-last-clicked-breadcrumb',
  standalone: true,
  imports: [CommonModule, StoreSelectPipe],
  templateUrl: './last-clicked-breadcrumb.component.html',
  styleUrls: ['./last-clicked-breadcrumb.component.css'],
})
export class LastClickedBreadcrumbComponent implements OnInit {
  @Input() lastClickedBreadcrumb: LastClickedBreadcrumb;

  constructor() {}

  ngOnInit(): void {}

  selectSection = fromSection.selectSection;
  selectSubsection = fromSubsection.selectSubsection;
  selectStatementSet = fromElement.selectStatementSet;
}
