import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, take } from 'rxjs';

import { SharedModule } from '../../../../shared/shared.module';
import { PrefillService } from '../prefill.service';
import { PrefillSelectTopicType } from '../prefill.types';

@Component({
  selector: 'rr-prefill-search-selections',
  templateUrl: './prefill-search-selections.component.html',
  standalone: true,
  imports: [SharedModule, CommonModule],
})
export class PrefillSearchSelectionsComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  selectedType: PrefillSelectTopicType;

  subscription = new Subscription();

  constructor(private prefillService: PrefillService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.prefillService.selectPrefillTopics$.subscribe(({ type }) => {
        this.selectedType = type;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectTopics(type: PrefillSelectTopicType) {
    this.prefillService.selectPrefillTopics$.next({ type });
    if (type !== 'TOP6_METADATA') {
      this.prefillService.showExactMatchResults$.next(true);
    }
  }

  deselectLastTopic() {
    this.subscription.add(
      this.prefillService
        .getPrefillPreviewObject(this.topic_id)
        .pipe(take(1))
        .subscribe((previewObj) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          if (!previewObj || !previewObj.topicIds || previewObj.topicIds.length === 0) return;
          const lastTopicId = previewObj.topicIds[previewObj.topicIds.length - 1];
          this.prefillService.removePrefillPreviewTopic(this.topic_id, lastTopicId);
        }),
    );
  }

  clearAllSelection() {
    this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: [] });
  }
}
