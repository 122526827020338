import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

type IssueCreateBody = { title?: string; description?: string; url?: string };

export type IssueUploadImageResponse = {
  image_url: string;
};

@Injectable()
export class IssueHttpService {
  constructor(private http: HttpClient) {}

  create({ title, description, url }: IssueCreateBody) {
    return this.http.post('/api/issue', {
      title,
      description,
      url,
    });
  }

  uploadImage(blob: FormData) {
    return this.http.post<IssueUploadImageResponse>('/api/issue/upload_image', blob);
  }
}
