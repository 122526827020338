import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { BodyPartActions } from './body-part.action';

export type BodyPartState = EntityState<RR.BodyPart>;

const adapter = createEntityAdapter<RR.BodyPart>();
const initialState = adapter.getInitialState();

export const BodyPartReducer = createReducer(
  initialState,
  on(BodyPartActions.findManySuccess, (state: BodyPartState, { body_parts }) => adapter.upsertMany(body_parts, state)),
  on(BodyPartActions.findAllSuccess, (state: BodyPartState, { body_parts }) => adapter.setAll(body_parts, state)),
  on(BodyPartActions.createSuccess, (state: BodyPartState, { body_part }) => adapter.addOne(body_part, state)),
  on(BodyPartActions.deleteSuccess, (state: BodyPartState, { body_part_id }) =>
    adapter.removeOne(body_part_id.toString(), state),
  ),
  on(BodyPartActions.updateSuccess, (state: BodyPartState, { body_part }) => adapter.upsertOne(body_part, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
