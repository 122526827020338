import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { AddedTopic } from '../report/report/report.service';
import { AddedTopicActions } from './added-topics.action';

export type AddedTopicState = EntityState<AddedTopic>;

const adapter = createEntityAdapter<AddedTopic>({
  selectId: (addedTopic: AddedTopic) => addedTopic.topic_id,
});

const initialState = adapter.getInitialState();

export const AddedTopicReducer = createReducer(
  initialState,
  on(AddedTopicActions.findAllSuccess, (state: AddedTopicState, { added_topics }) =>
    adapter.setAll(added_topics, state),
  ),
  on(AddedTopicActions.updateManySuccess, (state: AddedTopicState, { added_topics }) =>
    adapter.upsertMany(added_topics, state),
  ),
  on(AddedTopicActions.removeAllSuccess, (state: AddedTopicState) => adapter.removeAll(state)),
  on(AddedTopicActions.removeOneSuccess, (state: AddedTopicState, { otherImagingTopicId }) =>
    adapter.removeOne(otherImagingTopicId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
