import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { PercentileBand } from './percentile-band.service';

const adapter = createEntityAdapter<PercentileBand>();
const selectFeature = (state: AppState) => state.percentile_band;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectPercentileBand = (id: number) =>
  createSelector(selectEntities, (percentile_bands: Dictionary<PercentileBand>) => percentile_bands[id]);

const selectBandsForGroup = (groupId: number) =>
  createSelector(selectAll, (bands: PercentileBand[]) => {
    return bands.filter((band) => band.percentile_band_group_id === groupId);
  });

export const fromPercentileBand = {
  selectIds,
  selectEntities,
  selectAll,
  selectPercentileBand,
  selectBandsForGroup,
};
