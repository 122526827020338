import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { BookingActions } from 'app/store/booking';
import { PatientActions } from 'app/store/patient';
import { ReferrerActions } from 'app/store/referrer';

import { ReportActions } from '../report/report.action';
import { TodoActions } from '../todo/todo.action';
import { UrgentNoteActions } from '../urgent-note/urgent-note.action';
import { VoiceNoteActions } from '../voice-note/voice-note.action';

const findManySuccess = createAction('[FollowupTask] Find Many Success', props<{ followupTasks: RR.FollowupTask[] }>());
const createSuccess = createAction('[FollowupTask] Create Success', props<{ followupTask: RR.FollowupTask }>());
const updateSuccess = createAction('[FollowupTask] Update Success', props<{ followupTask: RR.FollowupTask }>());

const searchSuccessBatch = createBatchAction(
  '[FollowupTask] Search Success Batch',
  props<{
    count: number;
    followupTaskIds: number[];
    actions: {
      findManyFollowupTasksSuccess: ReturnType<typeof findManySuccess>;
      findManyReportsSuccess: ReturnType<typeof ReportActions.upsertMany>;
      findManyBookingsSuccess: ReturnType<typeof BookingActions.findManySuccess>;
      findManyReferrersSuccess: ReturnType<typeof ReferrerActions.findManySuccess>;
      findManyPatientsSuccess: ReturnType<typeof PatientActions.findManySuccess>;
      findManyTodosSuccess: ReturnType<typeof TodoActions.findManySuccess>;
      findManyUrgentNotesSuccess: ReturnType<typeof UrgentNoteActions.findManySuccess>;
      findManyVoiceNotesSuccess: ReturnType<typeof VoiceNoteActions.findManySuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for FollowupTask
 *
 */
export const FollowupTaskActions = {
  findManySuccess,
  createSuccess,
  updateSuccess,
  searchSuccessBatch,
};
