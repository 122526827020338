import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type PostInvoiceData = Omit<
  RR.Invoice,
  // server_default fields
  | 'id'
  | 'deleted'
  | 'deletion_reason'
  | 'reports'
  | 'medipass_transaction_status'
  | 'medipass_claim_status'
  | 'no_further_action_required'
  | 'amount_expected_original'
  | 'invoice_paid'
  | 'bookings'
  // Override invoice_items to exclude server fields
  | 'invoice_items'
  | 'payments'
>;

export type InvoiceReportCreationHttpResponse = {
  report: RR.Report;
  invoice: RR.Invoice;
};

export type InvoiceBookingCreationHttpResponse = {
  booking: RR.Booking;
  invoice: RR.Invoice;
};

export type InvoiceUpdateReportsHttpResponse = {
  report: RR.Report;
  invoice: RR.Invoice;
};

export type InvoiceReportFindManyHttpResponse = {
  invoices: RR.Invoice[];
  invoice_items: RR.InvoiceItem[];
  institutions: RR.Institution[];
  reports: RR.Report[];
  payments: RR.Payment[];
};

export type InvoiceBookingFindManyHttpResponse = {
  invoices: RR.Invoice[];
  invoice_items: RR.InvoiceItem[];
  institutions: RR.Institution[];
  bookings: RR.Booking[];
  payments: RR.Payment[];
};

export type InvoiceSearchHttpResponse = {
  count: number;
} & InvoiceReportFindManyHttpResponse;

export type InvoiceSearchBody = {
  patient_name: string;
  patient_number: string;
  referrer_name: string;
  invoice_no: number | undefined;
  institution: number | undefined;
  accession_number: string;
  funder: 'any' | RR.FunderType;
  status: 'any' | 'errored' | RR.InvoiceStatusType | RR.MedipassClaimStatusType | RR.MedipassTransactionStatusType;
  draft: boolean;
  deleted: boolean;
  no_further_action_required: boolean;
  partially_complete: boolean;
  unpaid: boolean;
  unbalanced: boolean;
  sites: number[];
  from_date: string | undefined;
  to_date: string | undefined;
  payment_type: RR.PaymentType | undefined;
};

@Injectable()
export class InvoiceHttpService {
  constructor(private http: HttpClient) {}

  search(queryBody: InvoiceSearchBody, params: HttpParams) {
    return this.http.post<InvoiceSearchHttpResponse>('/api/invoice/search', queryBody, {
      params,
    });
  }

  findInReport(reportId: number): Observable<InvoiceReportFindManyHttpResponse> {
    return this.http.get<InvoiceReportFindManyHttpResponse>(`/api/report/${reportId}/invoices`);
  }

  findInBooking(bookingId: number): Observable<InvoiceBookingFindManyHttpResponse> {
    return this.http.get<InvoiceBookingFindManyHttpResponse>(`/api/booking/${bookingId}/invoices`);
  }

  createBookingInvoice(bookingId: number, invoice: PostInvoiceData): Observable<InvoiceBookingCreationHttpResponse> {
    return this.http.post<InvoiceBookingCreationHttpResponse>(`/api/booking/${bookingId}/invoice`, invoice);
  }

  createReportInvoice(reportId: number, invoice: PostInvoiceData): Observable<InvoiceReportCreationHttpResponse> {
    return this.http.post<InvoiceReportCreationHttpResponse>(`/api/report/${reportId}/invoice`, invoice);
  }

  update(invoiceId: number, changes: Partial<RR.Invoice>): Observable<RR.Invoice> {
    return this.http.put<RR.Invoice>(`/api/invoice/${invoiceId}`, changes);
  }

  linkReport(invoiceId: number, reportId: number): Observable<InvoiceUpdateReportsHttpResponse> {
    return this.http.post<InvoiceUpdateReportsHttpResponse>(`/api/invoice/${invoiceId}/report/${reportId}`, null);
  }

  unlinkReport(invoiceId: number, reportId: number): Observable<InvoiceUpdateReportsHttpResponse> {
    return this.http.delete<InvoiceUpdateReportsHttpResponse>(`/api/invoice/${invoiceId}/report/${reportId}`);
  }

  linkMedipassTransaction(invoiceId: number, transactionId: string) {
    return this.http.post<{
      invoice: RR.Invoice;
      invoice_items: RR.InvoiceItem[];
    }>(`/api/invoice/${invoiceId}/medipass/${transactionId}`, null);
  }

  delete(invoiceId: number, reason?: { reason: string }): Observable<RR.Invoice> {
    return this.http.delete<RR.Invoice>(`/api/invoice/${invoiceId}`, { body: reason });
  }

  invoiceCSV(body: InvoiceSearchBody) {
    return this.http.post(`/api/invoice/csv`, body, {
      headers: {
        Accept: 'text/csv',
      },
      observe: 'response',
      responseType: 'blob',
    });
  }

  daysheet(body: InvoiceSearchBody) {
    return this.http.post(`/api/invoice/daysheet`, body, {
      headers: {
        Accept: 'application/pdf',
      },
      observe: 'response',
      responseType: 'blob',
    });
  }

  weeklyCashReport(body: InvoiceSearchBody) {
    return this.http.post(`/api/invoice/weekly_cash_report`, body, {
      headers: {
        Accept: 'application/pdf',
      },
      observe: 'response',
      responseType: 'blob',
    });
  }
}
