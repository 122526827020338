import { createAction, props } from '@ngrx/store';

/**
 * Describe all possible actions that can take place with a Tag.
 *
 * These are all the events that we can trigger. These describe the different
 * state transitions that take place, along with the data required to make them
 * occur.
 *
 */

export const TagActions = {
  // Reset the state of the store back to its initial state
  reset: createAction('[PrefillTag] Reset'),

  loadSuggestedSuccess: createAction(
    '[PrefillTag] Load Suggested Success',
    props<{ section: string; suggestedTags: RR.TagSuggestion[]; isMLSuggested: boolean }>(),
  ),

  loadStructureSuccess: createAction(
    '[PrefillTag] Load Structure Success',
    props<{ section: string; structure: RR.StructuredTagSubsection[] }>(),
  ),

  search: createAction('[PrefillTag] Search', props<{ text: string }>()),

  toggleAutoUpdate: createAction('[PrefillTag] Toggle Auto Update', props<{ auto?: boolean }>()),
  toggleSuggestedLoading: createAction('[PrefillTag] Toggle Suggested Loading', props<{ section: string }>()),
  toggleStructureLoading: createAction('[PrefillTag] Toggle Structure Loading', props<{ section: string }>()),
};
