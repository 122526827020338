import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { DicomActions } from './dicom.action';
import { DicomMeasurementsResponse } from './dicom.service';

export interface DicomState {
  measurementsResponse: DicomMeasurementsResponse | null;
  measurementsLoading: boolean;
}

export const initialState: DicomState = {
  measurementsResponse: null,
  measurementsLoading: false,
};

export const dicomReducer = createReducer(
  initialState,
  on(DicomActions.loadMeasurements, (state) => ({ ...state, measurementsLoading: true })),
  on(DicomActions.loadMeasurementsSuccess, (state, action) => ({
    ...state,
    measurementsResponse: action.response,
    measurementsLoading: false,
  })),
  on(DicomActions.loadMeasurementsFailed, (state) => ({
    ...state,
    measurementsLoading: false,
  })),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
