import { createAction, props } from '@ngrx/store';

import { StatementParameterConditionGroup } from './statement-parameter-condition-group.service';

export const StatementParameterConditionGroupActions = {
  createSuccess: createAction(
    '[StatementParameterConditionGroup] Create Success',
    props<{ statement_parameter_condition_group: StatementParameterConditionGroup }>(),
  ),
  deleteSuccess: createAction(
    '[StatementParameterConditionGroup] Delete Success',
    props<{ statement_parameter_condition_group_id: number }>(),
  ),
  updateSuccess: createAction(
    '[StatementParameterConditionGroup] Update Success',
    props<{ statement_parameter_condition_group: StatementParameterConditionGroup }>(),
  ),
  findSuccess: createAction(
    '[StatementParameterConditionGroup] Find Success',
    props<{ statement_parameter_condition_group: StatementParameterConditionGroup }>(),
  ),
};
