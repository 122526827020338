<div>
  <div class="d-flex">
    <strong>{{ patient.patient_first_name }} {{ patient.patient_last_name }}</strong>
    <div *ngIf="patient.patient_sex" class="text-capitalize ms-auto">{{ patient.patient_sex }}</div>
  </div>
  <div *ngIf="patient.patient_number">Patient Number: {{ patient.patient_number }}</div>
  <div *ngIf="patient.patient_dob">DOB: {{ patient.patient_dob | date: 'dd/MM/yyyy' }}</div>
  <ng-container *ngIf="patient.medicare_number">
    <div>Medicare number: {{ patient.medicare_number }}</div>
    <div>Expiry: {{ patient.medicare_expiry_year }}/{{ patient.medicare_expiry_year }}</div>
  </ng-container>
  <div *ngIf="patient.veteran_file_number">Veteran file number: {{ patient.veteran_file_number }}</div>
  <div *ngIf="patient.phone_home">Home Phone: {{ patient.phone_home }}</div>
  <div *ngIf="patient.phone_work">Work Phone: {{ patient.phone_work }}</div>
  <div *ngIf="patient.phone_mobile">Mobile Phone: {{ patient.phone_mobile }}</div>
  <div *ngIf="patient.email">Email: {{ patient.email }}</div>
  <div *ngIf="patient.address">Address: {{ patient.address }}</div>
  <div *ngIf="patient.city">City: {{ patient.city }}</div>
  <div *ngIf="patient.state">State: {{ patient.state }}</div>
  <div *ngIf="patient.zip">Postcode: {{ patient.zip }}</div>
</div>
