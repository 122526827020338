import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { PercentileBandRuleActions } from '../percentile-band-rule/percentile-band-rule.action';
import { PercentileBandActions } from '../percentile-band/percentile-band.action';
import { PercentileBandGroup } from './percentile-band-group.service';

/**
 * Ngrx Actions for PercentileBandGroup
 *
 */
export const PercentileBandGroupActions = {
  searchSuccess: createAction(
    '[PercentileBandGroup] Search Success',
    props<{ percentile_band_groups: PercentileBandGroup[] }>(),
  ),
  createSuccess: createAction(
    '[PercentileBandGroup] Create Success',
    props<{ percentile_band_group: PercentileBandGroup }>(),
  ),
  deleteSuccess: createAction('[PercentileBandGroup] Delete Success', props<{ percentile_band_group_id: number }>()),
  updateSuccess: createAction(
    '[PercentileBandGroup] Update Success',
    props<{ percentile_band_group: PercentileBandGroup }>(),
  ),
};

export const PercentileBandGroupBatchActions = {
  searchSuccess: createBatchAction(
    '[PercentileBandGroupo] Search Success Batch',
    props<{
      actions: {
        searchPercentileBandGroupSuccess: ReturnType<typeof PercentileBandGroupActions.searchSuccess>;
        searchPercentileBandSuccess: ReturnType<typeof PercentileBandActions.searchSuccess>;
        searchPercentileBandRuleSuccess: ReturnType<typeof PercentileBandRuleActions.searchSuccess>;
      };
    }>(),
  ),
};
