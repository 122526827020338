import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs';

import { AppState } from '../app.state';
import { LandmarkLabelActions } from './landmark-label.action';
import { LandmarkLabelHttpService } from './landmark-label.service';

@Injectable()
export class LandmarkLabelEffect {
  constructor(
    private store: Store<AppState>,
    private service: LandmarkLabelHttpService,
  ) {}

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((landmarkLabels: RR.LandmarkLabel[]) => LandmarkLabelActions.findAllSuccess({ landmarkLabels })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  find(id: string) {
    return this.service.find(id).pipe(
      map((landmarkLabel: RR.LandmarkLabel) => LandmarkLabelActions.findSuccess({ landmarkLabel })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(landmark: Partial<RR.LandmarkLabel>) {
    return this.service.create(landmark).pipe(
      map((landmarkLabel: RR.LandmarkLabel) => LandmarkLabelActions.createSuccess({ landmarkLabel })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(id: string, changes: Partial<RR.LandmarkLabel>) {
    return this.service.update(id, changes).pipe(
      map((landmarkLabel: RR.LandmarkLabel) => LandmarkLabelActions.updateSuccess({ landmarkLabel })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
