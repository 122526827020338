import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { VersionUpdateService } from 'app/core/services/version-update.service';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface RadReportVersionError {
  message: string;
  version: string;
  client_version: string;
}

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  rrConfig: rrConfigType | undefined;

  constructor(
    private updateService: VersionUpdateService,
    private store: Store<AppState>,
  ) {
    this.store.select(fromSession.selectRRConfig).subscribe((rrConfig) => {
      this.rrConfig = rrConfig;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const server_version = event.headers.get('RadReport-Version');
          if (this.rrConfig && server_version != null && server_version !== this.rrConfig.version) {
            this.updateService.notifyUpdate({
              message: 'version_mismatch',
              version: server_version,
              client_version: this.rrConfig.version,
            });
          }
        }
        return event;
      }),
    );
  }
}
