import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { UserNotificationsActions } from './notifications.action';
import { fromNotifications } from './notifications.selector';
import { NotificationsService, UserNotificationPayload } from './notifications.service';

@Injectable()
export class UserNotificationsEffect {
  constructor(
    private store: Store<AppState>,
    private service: NotificationsService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromNotifications.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      this.message.handleHttpErrorPipe,
      map((notifications) => {
        return UserNotificationsActions.findAllSuccess({ notifications });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(notification: UserNotificationPayload) {
    return this.service.create(notification).pipe(
      this.message.handleHttpErrorPipe,
      tap((response: RR.UserNotification) => {
        this.store.dispatch(UserNotificationsActions.createSuccess({ notification: response }));
      }),
    );
  }

  read(notificationId: number) {
    return this.service.read(notificationId).pipe(
      this.message.handleHttpErrorPipe,
      tap((response: RR.UserNotification) => {
        this.store.dispatch(UserNotificationsActions.readSuccess({ notification: response }));
      }),
    );
  }
}
