<div class="container">
  <form [formGroup]="searchForm" novalidate>
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Abbreviation</th>
          <th>Keyword</th>
          <th permission [permission-only]="['set_configuration']">Actions</th>
        </tr>
        <tr>
          <td>
            <input
              type="text"
              placeholder="Search abbreviation&hellip;"
              class="form-control"
              formControlName="abbreviation"
            />
          </td>
          <td>
            <div class="input-group">
              <input type="text" placeholder="Search keyword&hellip;" class="form-control" formControlName="keyword" />
            </div>
          </td>
          <td permission [permission-only]="['set_configuration']">
            <button class="btn btn-info" (click)="openEditKeywordAbbrModal()">
              <span class="fa fa-plus"></span> Create New Abbreviation
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let a of abbreviations">
          <td>{{ a.abbreviation }}</td>
          <td>{{ a.keyword }}</td>
          <td permission [permission-only]="['set_configuration']">
            <div class="d-flex">
              <button class="btn btn-info" (click)="openEditKeywordAbbrModal(a.id)">
                <span class="fa fa-edit"></span> Edit
              </button>
              <button class="btn btn-danger ms-2" (click)="deleteKeywordAbbr(a.id)">
                <span class="fa fa-trash"></span> Delete
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      *ngIf="totalResults > 0 && totalResults > shownResults"
      role="button"
      class="btn btn-primary d-block mx-auto"
      (click)="search(true)"
    >
      Show {{ totalResults - shownResults }} more results
    </button>
  </form>
</div>
