import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import Fuse from 'fuse.js';

// This selects the tag feature from the PrefillState, allowing us to further introspect
// it within this file.
const selectFeature = (state: AppState) => state.tag;

const selectSuggestedTagsBySection = (section: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
  createSelector(selectFeature, (state) => state.sectionTags[section].suggestedTags || []);

const selectOrderedSuggestedTagsBySection = (section: string) =>
  createSelector(selectSuggestedTagsBySection(section), (tags: RR.TagSuggestion[]) =>
    // Need to clone into a new list to not modify readonly store data
    [...tags].sort((a, b) => b.score - a.score),
  );

const isStructureLoading = (section: string) =>
  createSelector(selectFeature, (state) => state.sectionTags[section].isStructureLoading);

const isStructureLoaded = (section: string) =>
  createSelector(selectFeature, (state) => state.sectionTags[section].isStructureLoaded);

const isSuggestedLoading = (section: string) =>
  createSelector(selectFeature, (state) => state.sectionTags[section].isSuggestedLoading);

const isSuggestedLoaded = (section: string) =>
  createSelector(selectFeature, (state) => state.sectionTags[section].isSuggestedLoaded);

const isMLSuggested = (section: string) =>
  createSelector(selectFeature, (state) => state.sectionTags[section].isMLSuggested);

const selectFilterText = createSelector(selectFeature, ({ filter: { text } }) => text);

const selectFilteredSuggestedTags = (section: RR.TemplateSection) =>
  createSelector(
    selectOrderedSuggestedTagsBySection(section),
    selectFilterText,
    (tags: RR.TagSuggestion[], text: string) => {
      // When there is no search text we want to include all the values
      const sectionTags = tags.filter((t) => t.section === section);
      if (text === '') {
        return sectionTags;
      } else {
        // This looks inside the text of the tag, region, and subsection to look for a match
        const searchOptions: Fuse.IFuseOptions<RR.TagSuggestion> = {
          keys: ['tag_text', 'region_text', 'subsection_text'],
          // Ignore where in the string the text appears.
          // Mainly added this to find tags with "===" suffixes (a search hack Roger is using).
          ignoreLocation: true,
        };
        const fuse = new Fuse(sectionTags, searchOptions);
        const results = fuse.search(text);
        return results.map((r) => r.item);
      }
    },
  );

const selectTemplateStructure = (section: RR.TemplateSection) =>
  createSelector(selectFeature, ({ sectionTags }) => sectionTags[section].templateStructure);

const selectAutoUpdate = createSelector(selectFeature, ({ autoUpdate }) => autoUpdate);

export const fromTag = {
  selectFilterText,
  selectAutoUpdate,
  isStructureLoading,
  isSuggestedLoading,
  isMLSuggested,
  isSuggestedLoaded,
  isStructureLoaded,
  selectTemplateStructure,
  selectFilteredSuggestedTags,
  selectOrderedSuggestedTagsBySection,
};
