import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromInvoice } from '../invoice/invoice.selector';

const adapter = createEntityAdapter<RR.Payment>();
const selectFeature = (state: AppState) => state.payment;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectPayment = (id: number) =>
  createSelector(selectEntities, (payments: Dictionary<RR.Payment>) => payments[id]);

const selectPayments = (ids: number[]) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  createSelector(selectEntities, (payments: Dictionary<RR.Payment>) => (ids || []).map((id) => payments[id]));

const selectInInvoice = (invoiceId: number) =>
  // @ts-expect-error strictNullChecks
  createSelector(
    fromInvoice.selectInvoice(invoiceId),
    selectEntities,
    (invoice: RR.Invoice, payments: Dictionary<RR.Payment>) =>
      invoice.payments.map((paymentId) => payments[paymentId]).filter((p): p is RR.Payment => !!p),
  );

export const fromPayment = {
  selectIds,
  selectEntities,
  selectAll,
  selectPayment,
  selectPayments,
  selectInInvoice,
};
