import { CommonModule } from '@angular/common';
import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { TemplateService } from 'app/core/services/template.service';
import { PriorStudyLinksService } from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.service.component';
import { Observable, of, Subscription, switchMap } from 'rxjs';

import { AddOtherImagingModalComponent } from '../add-other-imaging-modal/add-other-imaging-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'button[rr-add-other-imaging-button]',
  templateUrl: './add-other-imaging-button.component.html',
  styleUrls: ['./add-other-imaging-button.component.css'],
})
export class AddOtherImagingButtonComponent implements OnInit, OnDestroy {
  // TODO: Refactor hostbinded classes
  @Input() btnLight = false;
  @Input() btnNav = false;
  @Input() btnSm = false;
  @HostBinding('class.btn') class1 = true;
  @HostBinding('class.btn-sm') get btnSmall() {
    return this.btnSm;
  }
  @HostBinding('class.btn-outline-primary') get navButtonClass() {
    return this.btnNav;
  }
  @HostBinding('class.btn-light') get noWarning() {
    return !this.shouldAddOtherImaging && this.btnLight;
  }
  @HostBinding('class.btn-primary') get noWarning2() {
    return !this.shouldAddOtherImaging && !this.btnLight && !this.btnNav;
  }
  @HostBinding('class.btn-warning') get warning() {
    return this.shouldAddOtherImaging;
  }
  @HostBinding('disabled') get disabled() {
    return !this.isTemplateLoaded;
  }

  @Input() @BindObservable() topic: RR.Topic | undefined;
  topic$: Observable<RR.Topic | undefined>;
  @Input() report: RR.Report;

  subscription = new Subscription();

  shouldAddOtherImaging: boolean;
  isTemplateLoaded: boolean;

  constructor(
    private priorStudyLinksService: PriorStudyLinksService,
    private modalService: NgbModal,
    private templateService: TemplateService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) =>
            topic ? this.priorStudyLinksService.shouldAddOtherImaging(this.report, topic) : of(false),
          ),
        )
        .subscribe((shouldAddOtherImaging) => {
          this.shouldAddOtherImaging = shouldAddOtherImaging;
        }),
    );

    // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
    this.templateService.isCurrentTemplateLoaded().subscribe((isTemplateLoaded) => {
      this.isTemplateLoaded = isTemplateLoaded;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click')
  openAddOtherImaging() {
    if (this.topic) {
      AddOtherImagingModalComponent.open(this.modalService, { reportId: this.report.id, topicId: this.topic.id });
    }
  }
}
