/// <reference types="@angular/localize" />
/// <reference types="./types/index" />

import './polyfills';

import { registerLocaleData } from '@angular/common';
import localeAu from '@angular/common/locales/en-AU';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ROUTES } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { CoreModule } from 'app/core/core.module';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { CustomRouteSerializer } from 'app/custom-route-serializer';

import { environment } from './environments/environment';
// import cssCheck from './checkForUndefinedCSSClasses';
// cssCheck();

if (environment.production) {
  enableProdMode();
}

registerLocaleData(localeAu, 'en-AU');

bootstrapApplication(AppComponent, {
  providers: [
    GoogleAnalyticsService,
    provideRouter(ROUTES),
    importProvidersFrom(
      CoreModule.forRoot(),
      StoreRouterConnectingModule.forRoot({
        serializer: CustomRouteSerializer,
      }),
    ),
    provideAnimations(),
  ],
});
