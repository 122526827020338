<div class="modal-header border-bottom-0">
  <div class="d-flex flex-column w-100">
    <div class="d-flex align-items-center">
      <h5 class="mb-0 modal-title">Statement Groups (>)</h5>
      <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
    </div>
  </div>
</div>
<div class="sticky-top px-3 pb-2 pt-1 border-2 border-bottom">
  <div class="d-flex flex-column">
    <div class="mb-1 d-flex flex-wrap text-nowrap">
      <button
        role="button"
        class="btn btn-sm btn-outline-secondary me-2"
        (click)="triggerVoiceNoteFocus()"
        rrTooltip="FOCUS_VOICE_NOTES"
      >
        {{ focusVoiceMode ? 'Unfocus voice notes' : 'Focus voice notes' }}
      </button>
      <rr-report-notes-button
        *ngIf="report$ | async as report"
        [report_id]="report.id"
        [topicId]="topic_id"
        [flash]="false"
        (click)="activeModal.dismiss()"
      ></rr-report-notes-button>
      <button
        rrTooltip="TOGGLE_AUTO_SUGGESTED_SENTENCES"
        role="button"
        class="btn btn-sm btn-outline-secondary ms-auto"
        (click)="toggleAutoSuggested()"
      >
        {{ showAutoSuggested ? 'Hide' : 'Show' }} Auto-recommended
      </button>
    </div>
    <div *ngIf="!focusVoiceMode" class="d-flex flex-wrap text-nowrap">
      <button
        rrTooltip="REFRESH_EXACT_MATCH_FROM_MODAL"
        role="button"
        class="btn btn-sm btn-primary"
        (click)="refreshExactMatch()"
        *ngIf="parent !== 'STATEMENT_RECOMMENDER'"
      >
        <i class="fa fa-sync"></i> Refresh Exact Match
      </button>
      <button
        rrTooltip="REFRESH_AND_SELECT_TOP6_EXACT_MATCH"
        role="button"
        class="btn btn-sm btn-primary ms-2"
        (click)="refreshExactMatch('TOP6_EXACT_MATCH')"
        *ngIf="parent !== 'STATEMENT_RECOMMENDER'"
      >
        <i class="fa fa-sync"></i> Refresh Exact Match & Select Top 6
      </button>

      <button
        rrTooltip="PREFILL_TAG_MODAL_COMBINE"
        role="button"
        class="btn btn-sm btn-primary ms-2"
        (click)="combineEMAndMetadata()"
      >
        <i class="fa fa-tasks"></i> Combine Exact Match & Metadata
      </button>
    </div>
  </div>
</div>
<div class="modal-body pt-1">
  <ng-container *ngIf="!focusVoiceMode">
    <h5 class="mb-0">Groups</h5>
    <div class="d-flex mb-2" *ngIf="tagChoices$ | async as tagChoices">
      <rr-prefill-tag
        *ngFor="let tagChoice of tagChoices"
        rrTooltip="DIVIDER_TAG_BTN"
        [tagChoice]="tagChoice"
        [topicId]="topic_id"
        (click)="activeModal.dismiss()"
      ></rr-prefill-tag>
    </div>

    <ng-container *ngIf="report$ | async as report">
      <ng-container *ngIf="divider_id">
        <div class="d-flex flex-column mx-auto" id="divider-statement-body">
          <nav>
            <ol class="breadcrumb text-info mb-0">
              <li *ngIf="section$ | async as section" class="breadcrumb-item">
                {{ section.title | uppercase }}
              </li>
              <li *ngIf="subsection$ | async as subsection" class="breadcrumb-item">
                {{ subsection.name ? subsection.name : 'NO NAME' }}
              </li>
              <li *ngIf="region$ | async as region" class="breadcrumb-item">
                {{ region.name }}
              </li>
              <li *ngIf="element$ | async as element" class="breadcrumb-item">
                {{ element.statement_set_id | storeSelectPipe: selectElementName | async }}
              </li>
              <li *ngIf="divider_id | storeSelectPipe: selectStatement | async as divider" class="breadcrumb-item">
                {{ divider._text }}
              </li>
            </ol>
          </nav>
        </div>
        <div
          class="mb-4"
          *ngIf="{
            element: element$ | async,
            region: region$ | async,
            subsection: subsection$ | async,
            section: section$ | async
          } as data"
        >
          <rr-element
            *ngIf="report && data.section && data.subsection && data.element"
            [report]="report"
            [element]="data.element"
            [region]="data.region || undefined"
            [subsection]="data.subsection"
            [section]="data.section"
            [parent]="'PREFILL'"
            class="d-flex flex-column flex-grow-1 element-scroll"
          ></rr-element>
        </div>
      </ng-container>
      <ng-container *ngIf="!divider_id">
        <div class="alert alert-warning">
          <h4 class="mb-0"><strong>No Group Selected: </strong></h4>
          <span>Please add or select a Group</span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="report$ | async as report">
    <ng-container *ngIf="showAutoSuggested">
      <div class="mb-3 p-2 bg-light border shadow-sm rounded">
        <h3>Auto-recommended Statements</h3>
        <div class="suggested-scroll mb-3" *ngIf="{ report: report$ | async, topic: topic$ | async } as data">
          <rr-voice-note-suggested-statements
            *ngIf="data.report && data.topic"
            [reportId]="data.report.id"
            [topic]="data.topic"
          ></rr-voice-note-suggested-statements>
        </div>
      </div>
    </ng-container>
    <div class="p-3">
      <rr-voice-notes class="voice-scroll" [reportId]="report.id" [topicId]="topic_id"></rr-voice-notes>
    </div>
  </ng-container>
</div>
