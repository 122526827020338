import { createAction, props } from '@ngrx/store';

const createSuccess = createAction('[UserNotification] Create Success', props<{ notification: RR.UserNotification }>());
const findAllSuccess = createAction(
  '[UserNotification] Find All Success',
  props<{ notifications: RR.UserNotification[] }>(),
);
const readSuccess = createAction('[UserNotification] Read Success', props<{ notification: RR.UserNotification }>());

export const UserNotificationsActions = {
  createSuccess,
  findAllSuccess,
  readSuccess,
};
