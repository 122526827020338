import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'rr-report-scan-code',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './report-scan-code.component.html',
  styleUrl: './report-scan-code.component.css',
})
export class ReportScanCodeComponent implements OnInit {
  @Input() reportId: number;
  report$: Observable<RR.Report | undefined>;
  scanCode$: Observable<RR.ScanCode | undefined>;
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));

    this.scanCode$ = this.report$.pipe(
      switchMap((report) =>
        report?.scan_code_id ? this.store.select(fromScanCode.selectScanCode(report.scan_code_id)) : of(undefined),
      ),
    );
  }
}
