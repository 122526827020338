<ng-container *ngIf="patient$ | async as patient">
  <span [class.fw-semibold]="strong">
    <ng-container *ngIf="patient.patient_first_name">{{ patient.patient_first_name }} </ng-container>
    <ng-container *ngIf="patient.patient_last_name">{{ patient.patient_last_name }}</ng-container>
  </span>
  <ng-container *ngIf="patient && patientInfo">
    <span [innerHTML]="patient | patientAgeSex"></span>
  </ng-container>
  <ng-container *ngIf="patient.patient_number && patientNumber">
    <span ngbTooltip="Patient Number" container="body">, {{ patient.patient_number }}</span>
  </ng-container>
  <ng-container *ngIf="editPatient">
    <a
      permission
      [permission-only]="['data_management']"
      target="_blank"
      [routerLink]="['/entities/patients', patientId]"
      rrTooltip="OPEN_PATIENT_MANAGEMENT_PAGE"
      data-no-bubble
      class="text-secondary ms-1"
      ><i class="fas fa-external-link-square-alt"></i
    ></a>
  </ng-container>
</ng-container>
