import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { UrgentNoteActions } from './urgent-note.action';
import { UrgentNoteHttpService } from './urgent-note.service';

@Injectable()
export class UrgentNoteEffect {
  constructor(
    private store: Store<AppState>,
    private service: UrgentNoteHttpService,
  ) {}

  create(urgentNote: Partial<RR.UrgentNotes>) {
    return this.service.create(urgentNote).pipe(
      map((urgentNote: RR.UrgentNotes) => UrgentNoteActions.createSuccess({ urgentNote })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(urgentNoteId: number) {
    return this.service.delete(urgentNoteId).pipe(
      map(() => UrgentNoteActions.deleteSuccess({ urgentNoteId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
