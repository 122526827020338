import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { convertSecondsToHMS } from 'app/app.utils';
import { take } from 'rxjs/operators';

interface AuditEvent {
  counts: {
    CREATE_CHOICE: number;
    DELETE_CHOICE: number;
  };
  performance: {
    CREATE_PCT: number;
    DELETE_PCT: number;
    OVERALL_PCT: number;
  };
  from_date: any;
  to_date: any;
  user_id: number;
  user_name: string;
  duration: number;
  audit_duration: number;
}

@Component({
  standalone: true,
  imports: [CommonModule, NgbPopoverModule],
  selector: 'rr-access-event-summary',
  templateUrl: './access-event-summary.component.html',
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class AccessEventSummaryComponent {
  @ViewChild('popover', { static: true }) popover: NgbPopover;
  @Input() report_id: number;
  summary_audit_events: AuditEvent[] = [];
  totalTime: number[] = [0, 0, 0];
  auditTime: number;
  constructor(private http: HttpClient) {}

  open(event: any) {
    event.stopPropagation();
    this.http
      .get<AuditEvent[]>(`/api/report/${this.report_id}/audit_summary`)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        this.summary_audit_events = response || [];
        const reportTime = this.summary_audit_events
          .map((a) => ({ duration: a.duration, audit_duration: a.audit_duration }))
          .reduce((a, b) => ({
            duration: a.duration + b.duration,
            audit_duration: a.audit_duration + b.audit_duration,
          }));
        this.totalTime = convertSecondsToHMS(reportTime.duration);
        this.auditTime = reportTime.audit_duration;
        this.popover.open();
      });
  }

  getTimeString() {
    return `${this.totalTime[0]}h ${this.totalTime[1]}m ${this.totalTime[2]}s`;
  }
}
