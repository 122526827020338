import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { InvoiceItemActions } from './invoice-item.action';

export type InvoiceItemState = EntityState<RR.InvoiceItem>;

const adapter = createEntityAdapter<RR.InvoiceItem>();

const initialState = adapter.getInitialState();

export const invoiceItemReducer = createReducer(
  initialState,
  on(InvoiceItemActions.findManySuccess, (state: InvoiceItemState, { invoiceItems }) =>
    adapter.upsertMany(invoiceItems, state),
  ),
  on(InvoiceItemActions.createManySuccess, (state: InvoiceItemState, { invoiceItems }) =>
    adapter.addMany(invoiceItems, state),
  ),
  on(InvoiceItemActions.updateSuccess, (state: InvoiceItemState, { invoiceItem }) =>
    adapter.upsertOne(invoiceItem, state),
  ),
  on(InvoiceItemActions.deleteSuccess, (state: InvoiceItemState, { invoiceItemId }) =>
    adapter.removeOne(invoiceItemId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
