import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { Subscription } from 'rxjs';

import { ChoicePreviewComponent } from '../choice-preview/choice-preview.component';
import { SubsectionPreviewComponent } from '../subsection-preview/subsection-preview.component';

@Component({
  selector: 'rr-section-preview',
  templateUrl: './section-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ChoicePreviewComponent, SubsectionPreviewComponent],
})
@LifecycleLogger
export class SectionPreviewComponent implements OnInit, OnDestroy {
  @Input() section_choice_id: number;
  section_choice: RR.SectionChoice;
  @Input() topic: RR.Topic;
  @Input() mode: PreviewMode = 'editor';
  private subscriptions: Subscription = new Subscription();
  trackBy = trackById;
  clonedChoices: RR.Ctx[] = [];

  constructor(
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.reportService.getSectionChoice(this.section_choice_id).subscribe((section_choice) => {
        // @ts-expect-error strictNullChecks
        this.section_choice = section_choice;
      }),
    );
    this.subscriptions.add(
      this.reportService.getSectionClones(this.topic.id, this.section_choice.id).subscribe((choices) => {
        this.clonedChoices = choices;
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
