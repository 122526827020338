import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { SelectorService } from 'app/core/services/selector.service';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { UserSelectorComponent } from 'app/shared/components/user-selector/user-selector.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReportAccessEvent } from 'app/store/report/access-event';
import { fromFeedback } from 'app/store/report/feedback';
import { fromReport } from 'app/store/report/report';
import { CompanyRoleEffect } from 'app/store/user/company-role';
import { fromUser, UserEffect } from 'app/store/user/user';
import { uniq } from 'lodash-es';
import { combineLatest, map, Subscription, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserHeadlineComponent,
    UserSelectorComponent,
    SharedModule, // For SelectPipe
  ],
  selector: 'rr-feedback-recipients',
  templateUrl: './feedback-recipients.component.html',
  styleUrls: ['./feedback-recipients.component.css'],
})
export class FeedbackRecipientsComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() selectedUserId: number | undefined;
  // Parent component
  @Input() parent: 'SELF_CHECK_FEEDBACK' | 'SEND_FEEDBACK';

  @Output() onUserSelected = new EventEmitter<number>();

  recipientIds: number[];
  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private selectorService: SelectorService,
    private userEffect: UserEffect,
    private companyRoleEffect: CompanyRoleEffect,
  ) {}

  ngOnInit(): void {
    // Some users are loaded in the nested report, some have access events, and fetch the rest because the user selector
    // dropdown lets you select anyone.
    this.subscription.add(this.userEffect.findAll(new HttpParams().set('active_only', true)).subscribe());

    // Company roles for the dropdown
    this.subscription.add(this.companyRoleEffect.findAll().subscribe());

    // Get current user and all users who accessed or signed the report
    this.subscription.add(
      this.store
        .select(fromReport.selectTopics(this.reportId))
        .pipe(
          take(1),
          map((topics) => {
            const techIds = topics
              .map((t) => [
                t.junior_signature_user_id,
                t.technician_signature_user_id,
                t.radiology_registrar_signature_user_id,
              ])
              .flat()
              .filter((userId): userId is number => userId != null);

            return techIds;
          }),
          switchMap((techIds) => {
            return combineLatest([
              this.store.select(fromReportAccessEvent.selectAccessEventsInReport(this.reportId)).pipe(take(1)),
              this.selectorService.selectLoadedCurrentUser(),
            ]).pipe(map(([accessEvents, currentUser]) => ({ accessEvents, currentUser, techIds })));
          }),
        )
        .subscribe(({ accessEvents, currentUser, techIds }) => {
          // Get users from report access events and report signatures
          this.recipientIds = uniq(accessEvents.map((accessEvent) => accessEvent.user_id).concat(techIds));

          if (currentUser && !this.recipientIds.includes(currentUser.id)) {
            this.recipientIds.push(currentUser.id);
          }

          // Auto select current user if self check feedback, otherwise select the first user in the list
          if (this.recipientIds.length > 0) {
            if (this.parent === 'SEND_FEEDBACK') {
              this.onUserSelected.emit(this.recipientIds[0]);
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            } else if (this.parent === 'SELF_CHECK_FEEDBACK' && currentUser) {
              this.onUserSelected.emit(currentUser.id);
            }
          }
        }),
    );
  }

  selectUser(userId: number) {
    this.onUserSelected.emit(userId);
  }

  /**
   * Select user from rr-user-selector component
   * @param users
   */
  onUserSelectorChange(users: RR.User[]) {
    // Single select should be enabled for rr-user-selector. So this list should only contain up 1 user.
    if (users.length > 0) {
      this.selectUser(users[0].id);
    }
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  userSelectorFn = (userId: number) => {
    return this.store.select(fromUser.selectUser(userId));
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  checkSentFeedbackSelectorFn = (userId: number) => {
    return this.store
      .select(fromFeedback.selectUserFeedbacksInReport(userId, this.reportId))
      .pipe(map((feedbacks) => !!feedbacks.length));
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
