import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-send-event-history',
  templateUrl: './send-event-history.component.html',
  styleUrls: ['./send-events-history.component.scss'],
})
export class SendEventHistoryComponent {
  @Input()
  sendEvents: RR.SendEvent[] = [];
}
