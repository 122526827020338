import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TitleOptionHttpService {
  constructor(private http: HttpClient) {}

  deleteTitles(titleIds: number[]) {
    return this.http.request('DELETE', '/api/title_option', {
      body: { ids: titleIds },
    });
  }
}
