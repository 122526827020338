import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { BindObservable } from 'app/app.utils';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { ReportEffect } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { formatISO } from 'date-fns';
import { Observable, Subscription, switchMap } from 'rxjs';

import { RegistrationLinkComponent } from '../../../../shared/components/registration-link/registration-link.component';
import { ScanCodeHeadlineComponent } from '../../../../shared/components/scan-code-headline/scan-code-headline.component';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';

@Component({
  selector: 'rr-same-day-registrations',
  templateUrl: './same-day-registrations.component.html',
  styleUrls: ['./same-day-registrations.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ScanCodeHeadlineComponent,
    RegistrationLinkComponent,
    StoreSelectPipe,
    ReportAccessionNumberComponent,
  ],
})
export class SameDayRegistrationsComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() patientId: number;
  patientId$: Observable<number>;

  sameDayReports: RR.Report[] = [];
  subscription: Subscription = new Subscription();
  constructor(private reportEffect: ReportEffect) {}

  ngOnInit(): void {
    this.subscription.add(
      this.patientId$
        .pipe(switchMap((id) => this.reportEffect.patientSearch(id, formatISO(new Date(), { representation: 'date' }))))
        .subscribe((action) => {
          this.sameDayReports = action.actions.findReportSuccess.reports;
        }),
    );
  }

  selectScanCode = fromScanCode.selectScanCode;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
