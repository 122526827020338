import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { ScanCodeActions } from 'app/store/scan-code/scan-code.action';
import { map, tap } from 'rxjs/operators';

import { PatientQuestionActions } from '../patient-question/patient-question.action';
import { ScanCodePatientQuestionsResponse } from '../patient-question/patient-question.service';
import { PatientQuestionSetBatchActions } from './patient-question-set.action';
import { PatientQuestionSetActions } from './patient-question-set.action';
import { PatientQuestionSetHttpService, ReorderingQuestionsInSetResponse } from './patient-question-set.service';

@Injectable()
export class PatientQuestionSetEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientQuestionSetHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((patientQuestionSets: RR.PatientQuestionSet[]) =>
        PatientQuestionSetActions.findAllSuccess({ patientQuestionSets }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(patientQuestionSet: Partial<RR.PatientQuestionSet>) {
    return this.service.create(patientQuestionSet).pipe(
      map((patientQuestionSet: RR.PatientQuestionSet) =>
        PatientQuestionSetActions.createSuccess({ patientQuestionSet }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(patientQuestionSetId: number, changes: Partial<RR.PatientQuestionSet>) {
    return this.service.update(patientQuestionSetId, changes).pipe(
      map((patientQuestionSet) => PatientQuestionSetActions.updateSuccess({ patientQuestionSet })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(patientQuestionSetId: number) {
    return this.service.delete(patientQuestionSetId).pipe(
      map(() => PatientQuestionSetActions.deleteSuccess({ patientQuestionSetId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  removeAllFromScanCode(scanCodeId: number, patientQuestionSetId: number) {
    return this.service.removeAllFromScanCode(scanCodeId, patientQuestionSetId).pipe(
      map((data: ScanCodePatientQuestionsResponse) => ScanCodeActions.updateSuccess({ scanCode: data.scan_code })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addAllToScanCode(scanCodeId: number, patientQuestionSetId: number) {
    return this.service.addAllToScanCode(scanCodeId, patientQuestionSetId).pipe(
      map((data: ScanCodePatientQuestionsResponse) => ScanCodeActions.updateSuccess({ scanCode: data.scan_code })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  move(patientQuestionSetId: number, step: number) {
    return this.service.move(patientQuestionSetId, step).pipe(
      map((patientQuestionSets) => PatientQuestionSetActions.moveSuccess({ patientQuestionSets })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  reorderQuestionsInSet(patientQuestionSetId: number, changes: Partial<RR.PatientQuestionSet>) {
    return this.service.reorderQuestionsInSet(patientQuestionSetId, changes).pipe(
      map((data: ReorderingQuestionsInSetResponse) =>
        PatientQuestionSetBatchActions.reorderQuestionsSuccessBatch({
          actions: {
            updatePatientQuestionSetSuccess: PatientQuestionSetActions.updateSuccess({
              patientQuestionSet: data.patient_question_set,
            }),
            updatePatientQuestionsSuccess: PatientQuestionActions.updateManySuccess({
              patientQuestions: data.patient_questions,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
