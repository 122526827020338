import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';

import { StatementChoiceActions } from '../../report/statement-choice/statement-choice.action';
import { TextObjectChoiceActions } from '../../report/text-object-choice/text-object-choice.action';
import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { StatementCoincidenceActions } from '../statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../statement-set/statement-set.action';
import { TemplateActions } from '../template/template.action';
import { TextObjectActions } from '../text-object/text-object.action';
import { StatementActions, StatementBatchActions } from './statement.action';
import {
  FindAttributeSetUsageParams,
  PatchTextObject,
  StatementCreateBody,
  StatementHttpService,
} from './statement.service';

export type StatementCreateEffect = {
  index?: number;
  statement: Partial<Omit<RR.Statement, 'text'>>;
  textObjects: PatchTextObject[];
};

@Injectable()
export class StatementEffect {
  constructor(
    private service: StatementHttpService,
    private store: Store<AppState>,
    private message: MessageService,
  ) {}

  findInStatementSet(statementSetId: number) {
    this.store.dispatch(StatementActions.findInStatementSet({ statementSetId }));
    return this.service.findInStatementSet(statementSetId).pipe(
      this.message.handleHttpErrorPipe,
      catchError((err: unknown) => {
        this.store.dispatch(StatementActions.findInStatementSetFailed({ statementSetId }));
        throw err;
      }),
      map(({ statements, textObjects, statementCoincidences }) =>
        StatementBatchActions.findInStatementSetSuccess({
          statementSetId,
          actions: {
            statementFindSuccess: StatementActions.upsertMany({ statements }),
            textObjectFindSuccess: TextObjectActions.upsertMany({ textObjects }),
            statementCoincidenceFindSuccess: StatementCoincidenceActions.addMany({ statementCoincidences }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAttributeSetUsage(attributeSetId: number, params: FindAttributeSetUsageParams) {
    return this.service.findAttributeSetUsage(attributeSetId, params).pipe(
      this.message.handleHttpErrorPipe,
      map(({ statements, textObjects, count }) =>
        StatementBatchActions.findAttributeSetUsageSuccess({
          count,
          actions: {
            statementFindSuccess: StatementActions.upsertMany({ statements }),
            textObjectFindSuccess: TextObjectActions.upsertMany({ textObjects }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * Creates an empty Statement and then sends a patch request to update the TextObjects
   */
  createWithTextObjects({ statement, index, textObjects }: StatementCreateEffect) {
    return this._create({ statement, index }).pipe(
      switchMap((action) => {
        const statementId = action.actions.statementCreateSuccessAction.statement.id;
        return this.patchTextObjects(statementId, textObjects).pipe(map(() => action));
      }),
    );
  }

  _create(data: StatementCreateBody) {
    return this.service.create(data).pipe(
      this.message.handleHttpErrorPipe,
      map(({ textObjects, statement, statementSet, defaultAttributes }) =>
        StatementBatchActions.createSuccessBatchAction({
          actions: {
            textObjectFindManySuccess: TextObjectActions.upsertMany({ textObjects }),
            statementCreateSuccessAction: StatementActions.addOne({ statement }),
            statementSetUpdateSuccessAction: StatementSetActions.upsertOne({ statementSet }),
            defaultAttributeUpdateManySuccessAction: DefaultAttributeActions.updateManySuccess({ defaultAttributes }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  approveChoice(statementChoiceId: number, text: string) {
    return this.service.approve(statementChoiceId, text).pipe(
      this.message.handleHttpErrorPipe,
      map(({ textObjects, statement, statementSet, defaultAttributes, statementChoice, textObjectChoices }) => {
        return StatementBatchActions.approveChoiceSuccessBatchAction({
          actions: {
            textObjectFindManySuccess: TextObjectActions.upsertMany({ textObjects }),
            statementCreateSuccessAction: StatementActions.addOne({ statement }),
            statementSetUpdateSuccessAction: StatementSetActions.upsertOne({ statementSet }),
            defaultAttributeUpdateManySuccessAction: DefaultAttributeActions.updateManySuccess({ defaultAttributes }),
            statementChoiceUpdateSuccessAction: StatementChoiceActions.upsertOne({
              statementChoice,
            }),
            textObjectChoiceCreateManySuccessAction: TextObjectChoiceActions.addMany({
              textObjectChoices,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(statementId: number, changes: Partial<RR.Statement>) {
    return this.service.update(statementId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map(({ textObjects, statement, statementSets, defaultAttributes }) =>
        StatementBatchActions.updateSuccessBatchAction({
          actions: {
            textObjectFindManySuccess: TextObjectActions.upsertMany({ textObjects }),
            statementUpdateSuccessAction: StatementActions.upsertOne({ statement }),
            statementSetUpdateManySuccessAction: StatementSetActions.upsertMany({ statementSets }),
            defaultAttributeUpdateManySuccessAction: DefaultAttributeActions.updateManySuccess({ defaultAttributes }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  patchTextObjects(statementId: number, textObjects: PatchTextObject[]) {
    return this.service.patchTextObjects(statementId, textObjects).pipe(
      this.message.handleHttpErrorPipe,
      map(({ textObjects, textObjectsDeleted, statement, defaultAttributes }) => {
        return StatementBatchActions.patchSuccessBatchAction({
          actions: {
            textObjectFindManySuccess: TextObjectActions.upsertMany({ textObjects }),
            textObjectDeleteManySuccess: TextObjectActions.deleteManySuccess({ textObjectIds: textObjectsDeleted }),
            statementUpdateSuccessAction: StatementActions.upsertOne({ statement }),
            defaultAttributeUpdateManySuccessAction: DefaultAttributeActions.updateManySuccess({ defaultAttributes }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(statementId: number) {
    return this.service.delete(statementId).pipe(
      this.message.handleHttpErrorPipe,
      map((updatedStatementSet) =>
        StatementBatchActions.deleteSuccessBatchAction({
          actions: {
            statementDeleteSuccessAction: StatementActions.removeOne({ statementId }),
            statementSetUpdateSuccessAction: StatementSetActions.upsertOne({ statementSet: updatedStatementSet }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number) {
    const obs$ = this.service.findInTemplate(templateId).pipe(
      this.message.handleHttpErrorPipe,
      map((statements: RR.Statement[]) => StatementActions.addMany({ statements })),
      tap((action) => this.store.dispatch(action)),
      share(),
    );
    obs$.subscribe(); // deprecated, see https://github.com/fluidsolar/radreport/issues/1597
    return obs$;
  }

  findInStatementBuilder() {
    return this.service.findStatementBuilder().pipe(
      this.message.handleHttpErrorPipe,
      map(({ statementSet, statements, textObjects, template, defaultAttributes }) => {
        return StatementBatchActions.findInStatementBuilderSuccess({
          statementSetId: statementSet.id,
          templateId: template.id,
          actions: {
            statementFindSuccess: StatementActions.upsertMany({ statements }),
            textObjectFindSuccess: TextObjectActions.upsertMany({ textObjects }),
            statementSetUpdateSuccess: StatementSetActions.upsertOne({ statementSet }),
            templateFindSuccess: TemplateActions.findSuccess({ template }),
            defaultAttributeFindSuccess: DefaultAttributeActions.findManySuccess({ defaultAttributes }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
