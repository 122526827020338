import { Dictionary, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.AuditEvent>();
const selectFeature = (state: AppState) => state.audit_event;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectAuditEvent = (id: number) =>
  createSelector(selectEntities, (audit_events: Dictionary<RR.AuditEvent>) => audit_events[id]);

export const fromAuditEvent = {
  selectIds,
  selectEntities,
  selectAll,
  selectAuditEvent,
};
