import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { StatementChoiceActions } from '../../report/statement-choice/statement-choice.action';
import { TextObjectChoiceActions } from '../../report/text-object-choice/text-object-choice.action';
import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { StatementCoincidenceActions } from '../statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../statement-set/statement-set.action';
import { TemplateActions } from '../template/template.action';
import { TextObjectActions } from '../text-object/text-object.action';

/**
 * Methods of interacting with the Statements
 *
 * This describes the actions required to interface with the statements, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the statements related to a specific template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of statements returned, while the
 * `getTemplateSuccess` being a subset of all statements will only update the
 * values without deleting them all first.
 */
export const StatementActions = createActionGroup({
  source: 'Statement',
  events: {
    addMany: props<{ statements: RR.Statement[] }>(),
    addOne: props<{ statement: RR.Statement }>(),
    upsertOne: props<{ statement: RR.Statement }>(),
    upsertMany: props<{ statements: RR.Statement[] }>(),
    removeOne: props<{ statementId: number }>(),
    findInStatementSet: props<{ statementSetId: number }>(),
    findInStatementSetFailed: props<{ statementSetId: number }>(),
    reset: emptyProps(),
  },
});

export const StatementBatchActions = {
  createSuccessBatchAction: createBatchAction(
    '[Statement] Create Success Batch',
    props<{
      actions: {
        textObjectFindManySuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        statementCreateSuccessAction: ReturnType<typeof StatementActions.addOne>;
        statementSetUpdateSuccessAction: ReturnType<typeof StatementSetActions.upsertOne>;
        defaultAttributeUpdateManySuccessAction: ReturnType<typeof DefaultAttributeActions.updateManySuccess>;
      };
    }>(),
  ),

  updateSuccessBatchAction: createBatchAction(
    '[Statement] Update Success Batch',
    props<{
      actions: {
        textObjectFindManySuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        statementUpdateSuccessAction: ReturnType<typeof StatementActions.upsertOne>;
        statementSetUpdateManySuccessAction: ReturnType<typeof StatementSetActions.upsertMany>;
        defaultAttributeUpdateManySuccessAction: ReturnType<typeof DefaultAttributeActions.updateManySuccess>;
      };
    }>(),
  ),

  patchSuccessBatchAction: createBatchAction(
    '[Statement] Patch Success Batch',
    props<{
      actions: {
        textObjectFindManySuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        textObjectDeleteManySuccess: ReturnType<typeof TextObjectActions.deleteManySuccess>;
        statementUpdateSuccessAction: ReturnType<typeof StatementActions.upsertOne>;
        defaultAttributeUpdateManySuccessAction: ReturnType<typeof DefaultAttributeActions.updateManySuccess>;
      };
    }>(),
  ),

  deleteSuccessBatchAction: createBatchAction(
    '[Statement] Delete Success Batch',
    props<{
      actions: {
        statementDeleteSuccessAction: ReturnType<typeof StatementActions.removeOne>;
        statementSetUpdateSuccessAction: ReturnType<typeof StatementSetActions.upsertOne>;
      };
    }>(),
  ),

  approveChoiceSuccessBatchAction: createBatchAction(
    '[Statement] Approve Choice Success Batch',
    props<{
      actions: {
        textObjectFindManySuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        statementCreateSuccessAction: ReturnType<typeof StatementActions.addOne>;
        statementSetUpdateSuccessAction: ReturnType<typeof StatementSetActions.upsertOne>;
        defaultAttributeUpdateManySuccessAction: ReturnType<typeof DefaultAttributeActions.updateManySuccess>;
        statementChoiceUpdateSuccessAction: ReturnType<typeof StatementChoiceActions.upsertOne>;
        textObjectChoiceCreateManySuccessAction: ReturnType<typeof TextObjectChoiceActions.addMany>;
      };
    }>(),
  ),

  findInStatementSetSuccess: createBatchAction(
    '[Statement] Find In StatementSet Success',
    props<{
      statementSetId: number;
      actions: {
        statementFindSuccess: ReturnType<typeof StatementActions.upsertMany>;
        textObjectFindSuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        statementCoincidenceFindSuccess: ReturnType<typeof StatementCoincidenceActions.addMany>;
      };
    }>(),
  ),

  findInStatementBuilderSuccess: createBatchAction(
    '[Statement] Find In StatementSet Builder Success',
    props<{
      statementSetId: number;
      templateId: number;
      actions: {
        statementFindSuccess: ReturnType<typeof StatementActions.upsertMany>;
        textObjectFindSuccess: ReturnType<typeof TextObjectActions.upsertMany>;
        statementSetUpdateSuccess: ReturnType<typeof StatementSetActions.upsertOne>;
        templateFindSuccess: ReturnType<typeof TemplateActions.findSuccess>;
        defaultAttributeFindSuccess: ReturnType<typeof DefaultAttributeActions.findManySuccess>;
      };
    }>(),
  ),

  findAttributeSetUsageSuccess: createBatchAction(
    '[Statement] Find AttributeSet Usage Success',
    props<{
      count: number;
      actions: {
        statementFindSuccess: ReturnType<typeof StatementActions.upsertMany>;
        textObjectFindSuccess: ReturnType<typeof TextObjectActions.upsertMany>;
      };
    }>(),
  ),
};
