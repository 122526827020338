import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { PrefillSearchActions } from 'app/store/prefill/prefill-search';
import { LabelEffect } from 'app/store/report/label/label.effect';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { StatementChoiceUsageAnalyticsEffect } from 'app/store/report/statement-choice-usage-analytics/statement-choice-usage-analytics.effect';
import { fromStatementChoiceUsageAnalytics } from 'app/store/report/statement-choice-usage-analytics/statement-choice-usage-analytics.selector';
import { StatementUsages } from 'app/store/report/statement-choice-usage-analytics/statement-choice-usage-analytics.service';
import { Subscription, switchMap, take, EMPTY, mergeMap, from, filter, finalize } from 'rxjs';

import { SelectPipe } from '../../../../shared/pipes/select-pipe';
import { SharedModule } from '../../../../shared/shared.module';
import { ReportLabelBadgeComponent } from '../../../report/components/report-label-badge/report-label-badge.component';
import { LabelSelectComponent } from '../../../report/components/report-label/label-select/label-select.component';
import { StatementPreviewComponent } from '../../statement-edit/statement-preview/statement-preview.component';
import { RecommendedStatementComponent } from '../../statement-recommendation/recommended-statement/recommended-statement.component';

@Component({
  selector: 'rr-statement-choice-usage-analytics-modal',
  templateUrl: './statement-choice-usage-analytics-modal.component.html',
  styleUrls: ['./statement-choice-usage-analytics-modal.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    LabelSelectComponent,
    StatementPreviewComponent,
    RecommendedStatementComponent,
    RouterLink,
    SharedModule,
    ReportLabelBadgeComponent,
    CommonModule,
    SelectPipe,
  ],
})
export class StatementChoiceUsageAnalyticsModalComponent implements OnInit, OnDestroy {
  @Input() openModal: (statementId: number) => void;
  statementChoiceId: number;
  topic: RR.Topic;
  parent: 'choice' | 'statement';

  isLoading = false;
  isGroup = false;
  isReportGroupEmpty = false;
  reportGroups: StatementUsages[] | null;
  filteredReportGroups: StatementUsages[] | null;

  selectedReportIds: number[] = [];
  statementGroupIds: number[] = [];
  statementGroupTags: string[] | undefined = [];
  currentGroupTagText = '';

  subscription = new Subscription();

  form = new FormGroup({
    min_age: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    max_age: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    patient_sex: new FormControl<string>('', { nonNullable: true }),
  });

  constructor(
    private statementChoiceUsageAnalyticsEffect: StatementChoiceUsageAnalyticsEffect,
    private store: Store<AppState>,
    public activeModal: NgbActiveModal,
    private editorService: EditorService,
    private reportEffect: ReportEffect,
    private labelEffect: LabelEffect,
  ) {}

  ngOnInit(): void {
    const isLoaded$ = this.store.select(fromStatementChoiceUsageAnalytics.selectLoaded(this.statementChoiceId));

    this.subscription.add(this.labelEffect.findAll().subscribe());

    this.subscription.add(
      isLoaded$
        .pipe(
          take(1),
          switchMap((isLoaded) => {
            if (!isLoaded) {
              this.isLoading = true;
              return this.statementChoiceUsageAnalyticsEffect
                .findAll(this.topic.report_id, this.statementChoiceId, this.parent)
                .pipe(
                  mergeMap((statementUsages) =>
                    from(statementUsages.actions.statementChoiceUsageAnalyticsFindAllSuccess.response.statement_usages),
                  ),
                  mergeMap((item) => from(item.reports)),
                  mergeMap((report) => this.updateReportStore(report.report_id)),
                );
            } else {
              this.isLoading = false;
              return EMPTY;
            }
          }),
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store
        .select(fromStatementChoiceUsageAnalytics.selectReportsByStatementChoiceId(this.statementChoiceId))
        .subscribe((reportGroups) => {
          this.reportGroups = reportGroups;
          this.filteredReportGroups = reportGroups;
          this.isReportGroupEmpty = this.areAllReportGroupsEmpty();
        }),
    );

    this.updateGroupTagText(this.statementChoiceId);
  }

  static open(
    modalService: NgbModal,
    statementChoiceId: number,
    topic: RR.Topic,
    parent: string,
    isGroup?: boolean,
    statementGroupIds?: number[],
    statementGroupTags?: string[],
  ) {
    let modalRef;

    const openModalFn = (id: number) => {
      modalRef = modalService.open(StatementChoiceUsageAnalyticsModalComponent, {
        size: 'xl',
        scrollable: true,
      });
      modalRef.componentInstance.statementChoiceId = id;
      modalRef.componentInstance.topic = topic;
      modalRef.componentInstance.parent = parent;
      modalRef.componentInstance.isGroup = isGroup;
      modalRef.componentInstance.statementGroupIds = statementGroupIds;
      modalRef.componentInstance.statementGroupTags = statementGroupTags;
      modalRef.componentInstance.openModal = openModalFn;
    };

    openModalFn(statementChoiceId);

    return modalRef;
  }

  togglePrefill(accessionNo: number) {
    this.editorService.togglePrefill();
    this.store.dispatch(
      PrefillSearchActions.updateSearchForm({
        searchForm: {
          accessionNumbers: accessionNo.toString(),
          searchType: 'DEBUG',
        },
      }),
    );
    this.activeModal.dismiss();
  }

  areAllReportGroupsEmpty(): boolean {
    if (this.filteredReportGroups && this.filteredReportGroups.length > 0) {
      return this.filteredReportGroups.every((group) => group.reports.length === 0);
    } else {
      // Return true if reportGroups is undefined or empty,
      return true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleReportSelection(reportId: number, event: Event): void {
    const isSelected = (event.target as HTMLInputElement).checked;
    if (isSelected) {
      this.selectedReportIds = [...this.selectedReportIds, reportId];
    } else {
      this.selectedReportIds = this.selectedReportIds.filter((id) => id !== reportId);
    }
  }

  updateReportStore(reportId: number) {
    return this.store.select(fromReport.selectReportsLoaded(reportId)).pipe(
      take(1),
      filter((isLoaded) => !isLoaded),
      switchMap(() => this.reportEffect.find(reportId)),
    );
  }

  filterReportGroups(): void {
    // Filtering the reports from store
    if (!this.reportGroups) return;

    const minAge = this.form.controls.min_age.value;
    const maxAge = this.form.controls.max_age.value;
    const patientSex = this.form.controls.patient_sex.value;

    this.filteredReportGroups = this.reportGroups.map((group) => ({
      ...group,
      reports: group.reports.filter((report) => {
        const patientAge = report.patient_age;
        const ageMatch =
          (minAge != null ? patientAge >= minAge : true) && (maxAge != null ? patientAge <= maxAge : true);
        const sexMatch = patientSex ? report.patient_sex === patientSex : true;
        return ageMatch && sexMatch;
      }),
    }));

    this.isReportGroupEmpty = this.areAllReportGroupsEmpty();
  }

  resetFilterReportGroups() {
    this.form.reset();
    this.filteredReportGroups = this.reportGroups;
  }

  goToNextGroup() {
    const currentIndex = this.statementGroupIds.indexOf(this.statementChoiceId);
    const nextIndex = currentIndex + 1;
    if (nextIndex < this.statementGroupIds.length) {
      this.navigateToGroup(this.statementGroupIds[nextIndex]);
    }
  }

  goToPreviousGroup() {
    const currentIndex = this.statementGroupIds.indexOf(this.statementChoiceId);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      this.navigateToGroup(this.statementGroupIds[prevIndex]);
    }
  }

  updateGroupTagText(statementId: number) {
    if (this.statementGroupTags && this.statementGroupTags.length > 0) {
      this.statementChoiceId = statementId;
      const currentIndex = this.statementGroupIds.indexOf(statementId);
      if (currentIndex !== -1 && currentIndex < this.statementGroupTags.length) {
        this.currentGroupTagText = this.statementGroupTags[currentIndex];
      } else {
        this.currentGroupTagText = '';
      }
    }
  }

  navigateToGroup(statementId: number) {
    // Close the current modal
    this.activeModal.close();
    this.openModal(statementId);
    this.updateGroupTagText(statementId);
  }

  reportsSelectorFn = fromStatementChoiceUsageAnalytics.selectReportsByStatementChoiceId;
}
