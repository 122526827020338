<div *ngIf="(loading$ | async) && selectedTopics.length === 0" class="d-flex justify-content-center loading mt-2">
  <i class="fa fa-spinner fa-2x fa-spin text-secondary mb-3"></i>
</div>

<div class="card mb-1 w-100 flex-auto" *ngIf="topic">
  <!--Top action buttons-->
  <div class="d-flex bg-light rounded p-1">
    <div class="ms-1">
      <h3 *ngIf="(loading$ | async) === false" class="align-self-center mb-0">
        {{ selectedTopics.length }} {{ selectedTopics.length > 1 ? 'topics' : 'topic' }} selected.
      </h3>
      <div *ngIf="metric && metric.totalCurrent > 0" rrTooltip="PREFILL_METRIC_1">
        {{ metric.intersection }} / {{ metric.totalCurrent }} =
        {{ (metric.intersection / metric.totalCurrent) * 100 | number: '1.0-0' }}%
      </div>
      <div *ngIf="metric && metric.totalPrefills > 0" rrTooltip="PREFILL_METRIC_2">
        {{ metric.intersection }} / {{ metric.totalPrefills }} =
        {{ (metric.intersection / metric.totalPrefills) * 100 | number: '1.0-0' }}%
      </div>

      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="hideExcluded"
          [checked]="hideExcludedTags"
          (change)="toggleHideExcludedTags()"
        />
        <label class="form-check-label ms-2 text-nowrap" for="hideExcluded">Hide excluded groups</label>
      </div>
      <div *ngIf="prefillService.exactMatchOpen$ | async" class="d-flex justify-content-center">
        <ng-container *ngIf="(prefillService.showExactMatchResults$ | async) === false">
          <button
            *ngIf="prefillService.isExactMatchResultsLoading$ | async; else showResultsButton"
            class="btn btn-warning text-nowrap"
            disabled
          >
            Loading B3 results...
          </button>
          <ng-template #showResultsButton>
            <button
              class="btn btn-warning text-nowrap flash"
              (click)="showExactMatchResults()"
              rrTooltip="SHOW_B3_RESULTS"
            >
              Show B3 Results
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column ms-auto my-1">
      <div class="d-flex justify-content-end">
        <button
          title="Show prefill tutorial"
          class="btn btn-sm btn-outline-dark me-1"
          role="button"
          (click)="openPrefillTutorialModal()"
        >
          <i class="fa fa-question"></i>
        </button>
        <button
          class="btn btn-sm btn-outline-dark"
          id="copy-all"
          (click)="copyAll(false)"
          role="button"
          rrTooltip="COPY_ALL"
          [disabled]="selectedTopics.length === 0"
        >
          <span class="fa fa-exchange-alt"></span> Copy all statements
        </button>
        <button
          class="btn btn-sm btn-outline-dark ms-2"
          id="copy-all-keep-numbers"
          (click)="copyAll(true)"
          role="button"
          rrTooltip="COPY_ALL_KEEP_NUMBERS"
          [disabled]="selectedTopics.length === 0"
        >
          <span class="fa fa-exchange-alt"></span> Copy all & keep numbers
        </button>
      </div>
      <div class="d-flex justify-content-end mt-1">
        <ng-container *ngIf="topic.title_option_id">
          <button
            class="btn btn-sm btn-outline-dark text-nowrap"
            rrTooltip="PREFILL_TITLE_SENTENCES"
            [disabled]="processingPrefillTitle"
            (click)="prefillSentences('TITLE')"
          >
            <span class="fa fa-file-import"></span> Prefill Title (T)
          </button>

          <button
            (click)="prefillSentences('PREDICTION')"
            [disabled]="processingPrefillTitle"
            class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
            rrTooltip="PREFILL_PREDICTION_SENTENCES"
          >
            <span class="fa fa-file-import"></span> Prefill Prediction (P)
          </button>
        </ng-container>
      </div>
      <div class="d-flex justify-content-end mt-1">
        <button
          title="Jump to the bottom of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="prefillScrollTo('BOTTOM')"
        >
          <i class="fa fa-arrow-alt-circle-down"></i> End
        </button>

        <button
          title="Jump to the top of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="prefillScrollTo('TOP')"
        >
          <i class="fa fa-home"></i> Home
        </button>
        <button
          title="Jump to the previous Subsection of Prefill"
          class="btn btn-sm btn-outline-dark ms-1"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-subsection]' })"
        >
          <i class="fa fa-arrow-up"></i> Previous Subsection
        </button>
        <button
          title="Jump to the next Subsection of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-subsection]' })"
        >
          <i class="fa fa-arrow-down"></i> Next Subsection
        </button>
      </div>
      <div class="d-flex justify-content-end mt-1 gap-1">
        <button
          title="Jump to the previous section of Prefill"
          class="btn btn-sm btn-outline-dark"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-section]' })"
        >
          <i class="fa fa-arrow-up"></i> Previous Section
        </button>
        <button
          title="Jump to the next section of Prefill"
          class="btn btn-sm btn-outline-dark text-nowrap"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-section]' })"
        >
          <i class="fa fa-arrow-down"></i> Next Section
        </button>
        <button
          class="btn btn-primary"
          rrTooltip="CORRELATED_SENTENCE_SEARCH"
          (click)="impressionAndCommentsPrediction()"
        >
          Statistical Statements Prediction
        </button>
      </div>
      <div class="mt-1 align-self-end">
        <button
          class="btn btn-primary"
          *ngIf="incompleteMandatoryStatements && incompleteMandatoryStatements.length > 0"
          (click)="mandatoryStatementService.openMandatoryStatementModal(incompleteMandatoryStatements)"
        >
          Pending Statements
          <span class="badge rounded-pill bg-danger me-1">{{ incompleteMandatoryStatements.length }} </span>
        </button>
      </div>
    </div>
  </div>

  <!--Merge choices from all reports, display in template order-->
  <div #scrollContainer id="PREFILL_COPY_SCROLL" class="card-block p-1" *ngIf="selectedTopics.length > 0">
    <!--Loading spinner-->
    <div *ngIf="loading$ | async" class="d-flex justify-content-center loading mt-2">
      <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
    </div>
    <!--Key findings-->
    <div *ngIf="keyFindings.length">
      <div class="form-check item-hover-parent">
        <label
          title="Click to copy all key findings in prefill to the report"
          class="form-check-label text-primary"
          (click)="copyAllKeyFindingChoices()"
        >
          <h3><strong>Key Findings</strong></h3>
        </label>
        <button class="btn btn-sm btn-light item-hover ms-1" (click)="copyAllKeyFindingChoices()">
          <i class="fas fa-exchange-alt"></i> Copy key findings
        </button>
      </div>
      <ul class="list-group">
        <li *ngFor="let choice of keyFindings; trackBy: trackById; let last = last">
          <ng-template #keyFindingTemplate>
            <div class="form-check no-checkbox item-hover-parent">
              <span
                class="form-check-label btn-link"
                (click)="copyChoices([choice.id], false, choice)"
                *ngIf="getChoiceText(choice) | async as choiceText"
                [linkify]="choiceText"
              >
              </span>
              <span class="fa fa-asterisk ms-1 text-danger"></span>
              <span
                *ngIf="is_in_sc_mode$ | async"
                class="ms-1 fa fa-layer-group"
                role="button"
                [ngClass]="(checkSentenceCategories(choice) | async) ? 'text-success' : 'text-danger'"
                [title]="getSentenceCategoriesTooltip(choice) | async"
                (click)="categoriseSentence(choice)"
              >
              </span>
              <button
                class="btn btn-sm btn-light item-hover ms-3"
                permission
                [permission-only]="['template_manage']"
                (click)="editStatement(choice)"
                rrTooltip="EDIT_STATEMENT"
              >
                <i class="fas fa-edit"></i> Edit
              </button>
            </div>
          </ng-template>

          <ng-container *ngIf="getCorrespondingChoiceInReport(choice); else keyFindingTemplate; let statementChoice">
            <ng-container *ngIf="statementChoice | selectPipe: getElementDetailsFn | async as element">
              <ng-container *ngIf="element.subsection">
                <rr-report-choice
                  [ngbPopover]="element.statement.tooltip"
                  triggers="mouseenter:mouseleave"
                  [disablePopover]="!element.statement.tooltip"
                  [choice]="statementChoice"
                  [statement]="element.statement"
                  [topic]="topic"
                  [element]="element.element"
                  [subsection]="element.subsection"
                  [section]="element.section"
                  [region]="element.region"
                  [parentComponent]="'PREFILL_COPY'"
                  [last]="last"
                  class="px-1 py-0 border-0 d-flex justify-content-between list-group-item"
                  [ngClass]="element.isSuggestion ? 'list-group-item-success' : 'list-group-item-info'"
                >
                </rr-report-choice>
              </ng-container>
            </ng-container>
          </ng-container>
        </li>
      </ul>
    </div>

    <hr class="my-3" />

    <!--Choice template-->
    <ng-template #choiceTmpl let-statementChoice="choice">
      <ng-template #statementChoiceTemplate>
        <div class="form-check no-checkbox btn-link item-hover-parent">
          <span
            *ngIf="getChoiceText(statementChoice) | async as choiceText"
            [linkify]="choiceText"
            class="form-check-label"
            (click)="copyChoices([statementChoice.id], false, statementChoice)"
          >
          </span>
          <span
            class="fa fa-asterisk ms-1 text-danger"
            *ngIf="isDefaultKeyFinding(statementChoice.statement_id) | async"
          ></span>
          <span
            class="fa fa-comment ms-1 text-danger"
            *ngIf="isDefaultComment(statementChoice.statement_id) | async"
          ></span>
          <span
            class="fa fa-italic ms-1 text-danger"
            *ngIf="isDefaultImpression(statementChoice.statement_id) | async"
          ></span>

          <span
            *ngIf="is_in_sc_mode$ | async"
            class="ms-1 fa fa-layer-group"
            role="button"
            [ngClass]="(checkSentenceCategories(statementChoice) | async) ? 'text-success' : 'text-danger'"
            [title]="getSentenceCategoriesTooltip(statementChoice) | async"
            (click)="categoriseSentence(statementChoice)"
          >
          </span>

          <button
            class="btn btn-sm btn-light item-hover ms-2"
            permission
            [permission-only]="['template_manage']"
            (click)="editStatement(statementChoice)"
            rrTooltip="EDIT_STATEMENT"
          >
            <i class="fas fa-edit"></i> Edit
          </button>
        </div>
      </ng-template>
      <!--
        TODO:
        passing these `null`s isn't great, but we've got away passing them for ages without bugs,
        because the ChoiceComponent functionality that references `element` and `subsection` is
        conditionally blocked from uses when `parentComponent === 'PREFILL_COPY'`. Still, should
        probably clean this up at some point.
      -->
      <!-- TODO(strictNullInputTypes): remove any$() -->
      <ul class="list-group">
        <ng-container
          *ngIf="getCorrespondingChoiceInReport(statementChoice); else statementChoiceTemplate; let reportChoice"
        >
          <ng-container *ngIf="reportChoice | selectPipe: getElementDetailsFn | async as element">
            <ng-container *ngIf="element.subsection">
              <rr-report-choice
                [ngbPopover]="element.statement.tooltip"
                triggers="mouseenter:mouseleave"
                [disablePopover]="!element.statement.tooltip"
                [choice]="reportChoice"
                [statement]="element.statement"
                [topic]="topic"
                [element]="element.element"
                [subsection]="element.subsection"
                [section]="element.section"
                [region]="element.region"
                [parentComponent]="'PREFILL_COPY'"
                class="px-1 py-0 border-0 d-flex justify-content-between list-group-item"
                [ngClass]="element.isSuggestion ? 'list-group-item-success' : 'list-group-item-info'"
              >
              </rr-report-choice>
            </ng-container>
          </ng-container>
        </ng-container>
      </ul>
    </ng-template>
    <!--Element template-->
    <ng-template
      #elementTmpl
      let-element="element"
      let-region="region"
      let-subsection="subsection"
      let-section="section"
    >
      <li class="element">
        <div class="form-check no-checkbox fw-bold item-hover-parent">
          <i class="fa fa-stream me-1"></i>
          <label
            title="Click to copy all statements in this element to the report"
            class="form-check-label mb-2"
            (click)="copyElementChoices(element, region)"
          >
            {{ (getStatementSet(element.id) | async)?.name || 'Unnamed Element' }}
          </label>
          <button class="btn btn-sm btn-light item-hover ms-1 btns-right" (click)="copyElementChoices(element, region)">
            <i class="fas fa-exchange-alt"></i> Copy element
          </button>
        </div>

        <!--Dividers and Statement choices inside element-->
        <ul>
          <li *ngFor="let divider of getElementDividers(element, region); trackBy: trackById">
            <div *ngIf="!hideExcludedTags || !exclusiveTagMap[divider.tag_id]">
              <div class="d-flex align-items-center item-hover-parent text-info mb-1">
                <label
                  class="form-check-label"
                  [ngClass]="{
                    'text-success fw-bold': isSearchingDivider(divider) && !exclusiveTagMap[divider.tag_id]
                  }"
                  [class.text-warning]="!!exclusiveTagMap[divider.tag_id]"
                  (click)="showDividerSentences(divider, element, region, subsection, section)"
                  rrTooltip="SHOW_DIVIDER_SENTENCES"
                >
                  <i class="fa fa-tag"></i>
                  {{ divider.tag_text }}
                  <span
                    rrTooltip="EXCLUSIVE_DIVIDER"
                    [class.invisible]="!exclusiveTagMap[divider.tag_id]"
                    class="badge bg-warning ms-2"
                    >Excluded Group</span
                  >
                </label>
                <button
                  class="btn btn-sm btn-outline-info mx-2"
                  (click)="onClickPopoverGroupStatements(popoverGroupStatements, divider)"
                  [ngbPopover]="popoverGroupStatementsContent"
                  #popoverGroupStatements="ngbPopover"
                  triggers="manual"
                  [autoClose]="false"
                  [placement]="'bottom'"
                  [popoverClass]="'group-statements-popover'"
                  rrTooltip="SHOW_DIVIDER_SENTENCES"
                  [disabled]="divider.isNotepad"
                >
                  <ng-template #popoverGroupStatementsContent>
                    <!-- TODO: This button is disabled for Global Notepads. To fix this, we need a way to pass element, region, etc to `<rr-recommended-statement>`. For an example, see the custom Notepad handling in `this.showDividerSentences(...)` -->
                    <ng-container *ngIf="!divider.isNotepad">
                      <ng-container
                        *ngFor="let statement of getStatementsUnderDivider(divider) | async; trackBy: trackById"
                      >
                        <rr-recommended-statement
                          [statement]="statement"
                          [topic]="topic"
                          [region]="region"
                        ></rr-recommended-statement>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                  <span><i class="fa fa-list"></i></span>
                </button>
                <button
                  class="btn btn-sm btn-light"
                  (click)="openStatementUsageAnalytics(divider)"
                  rrTooltip="FIND_PREVIOUS_REPORT_BY_STATEMENT"
                >
                  <i class="fa fa-file-medical"></i> Statement Usages
                </button>
                <button
                  permission
                  [permission-only]="['report_manage']"
                  class="btn btn-sm btn-light item-hover"
                  (click)="editTag(divider, element, region, subsection)"
                  rrTooltip="EDIT_STATEMENT"
                >
                  <i class="fas fa-edit text-primary"></i> Edit
                </button>

                <button
                  class="btn btn-sm btn-light item-hover"
                  (click)="showDividerSentences(divider, element, region, subsection, section)"
                  rrTooltip="SHOW_DIVIDER_SENTENCES"
                >
                  <i class="fas fa-eye"></i> Show
                </button>
                <button
                  class="btn btn-sm btn-light item-hover"
                  (click)="openNotepadModal(element, region, subsection, section)"
                >
                  <i class="fas fa-file-alt"></i> Global statements
                </button>
              </div>
              <!--Divider is not empty, show choices under it-->
              <ul *ngIf="!isEmptyDivider(divider)">
                <li
                  *ngFor="
                    let statementChoice of getStatementChoicesUnderDivider(element, region, divider);
                    trackBy: trackById
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="
                      choiceTmpl;
                      context: {
                        choice: statementChoice
                      }
                    "
                  >
                  </ng-container>
                </li>
              </ul>
              <!-- No choices under divider, show its statements in template-->
              <ul *ngIf="isEmptyDivider(divider)">
                <li *ngFor="let statement of getStatementsUnderDivider(divider) | async; trackBy: trackById">
                  <div class="form-check no-checkbox btn-link">
                    <rr-statement-text-render
                      [statement_id]="statement.id"
                      [region]="region"
                      [template_id]="topic.template_id"
                      class="form-check-label text-danger"
                      (click)="selectStatement(statement, element, region)"
                    ></rr-statement-text-render>
                    <span class="fa fa-asterisk ms-1 text-danger" *ngIf="statement.default_key_finding"></span>
                    <span class="fa fa-comment ms-1 text-danger" *ngIf="statement.default_comment"></span>
                    <span
                      class="fa fa-italic ms-1 text-danger"
                      *ngIf="statement.default_impression_recommendation"
                    ></span>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!--Statement choices that doesn't belong to any divider-->
        <ul>
          <li *ngFor="let statementChoice of getOrphanStatementChoices(element, region); trackBy: trackById">
            <ng-container
              *ngTemplateOutlet="
                choiceTmpl;
                context: {
                  choice: statementChoice
                }
              "
            >
            </ng-container>
          </li>
        </ul>
      </li>
    </ng-template>

    <!--Sections-->
    <ul class="ps-0" *ngFor="let section of usedSections" [attr.name]="section.type">
      <li
        *ngIf="
          (section.type !== 'history' && section.type !== 'technique') ||
          !hideHistoryTechnique ||
          checkSectionInUse(section)
        "
      >
        <div class="form-check item-hover-parent">
          <label
            title="Click to copy all statements in this section to the report"
            class="form-check-label"
            (click)="copySectionChoices(section)"
          >
            <!-- Highlight section title when jumping between sections -->
            <h3
              class="text-primary"
              [attr.data-section]="section.id"
              #sectionElement
              [class.highlighted]="sectionElement === sectionOrSubsectionEl"
            >
              <strong>
                {{ section.title }}
              </strong>
            </h3>
          </label>
          <button class="btn btn-sm btn-light item-hover ms-1" (click)="copySectionChoices(section)">
            <i class="fas fa-exchange-alt"></i> Copy section
          </button>
          <div class="d-flex align-items-center gap-1">
            <rr-auto-select-groups-button
              [sectionType]="section.type"
              [topicId]="topic.id"
              [parent]="'PREFILL'"
            ></rr-auto-select-groups-button>
          </div>
        </div>

        <!--Clone sentences-->
        <ul
          *ngIf="
            (section.type === 'comment' && cloneComments.length > 0) ||
            (section.type === 'impression_recommendations' && cloneImpressions.length > 0)
          "
        >
          <div class="form-check no-checkbox item-hover-parent">
            <i class="fa fa-book me-1"></i>
            <label class="form-check-label subsection-text fw-bold">
              {{ section.type === 'comment' ? 'CLONED COMMENTS' : 'CLONED I&R' }}
            </label>
          </div>
          <ng-container *ngIf="section.type === 'comment' ? cloneComments : cloneImpressions as clones">
            <li *ngFor="let statementChoice of clones; trackBy: trackById" class="px-3">
              <ng-container
                *ngTemplateOutlet="
                  choiceTmpl;
                  context: {
                    choice: statementChoice
                  }
                "
              >
              </ng-container>
            </li>
          </ng-container>
        </ul>

        <!--Subsections-->
        <ul *ngFor="let subsection of getSubsections(section); trackBy: trackById">
          <li class="subsection text-dark mb-4">
            <div *ngIf="!subsection.region_set_id">
              <div class="form-check no-checkbox item-hover-parent">
                <i class="fa fa-book me-1"></i>
                <!-- Highlight subsection title when jumping between subsection -->
                <label
                  title="Click to copy all statements in this subsection to the report"
                  class="form-check-label subsection-text fw-bold"
                  (click)="copySubsectionChoices(subsection)"
                  [attr.data-subsection]="subsection.id"
                  #subsectionElement
                  [class.highlighted]="subsectionElement === sectionOrSubsectionEl"
                >
                  {{ subsection.name || 'UNNAMED SUBSECTION' }}
                </label>
                <button class="btn btn-sm btn-light item-hover ms-1" (click)="copySubsectionChoices(subsection)">
                  <i class="fas fa-exchange-alt"></i> Copy subsection
                </button>
                <button
                  *ngIf="(mlLandmarkTemplate$ | async) && subsection.landmark_label_id"
                  class="btn btn-sm btn-light item-hover ms-1"
                  (click)="filterLandmark(subsection.landmark_label_id)"
                >
                  <i class="fas fa-search"></i> Search {{ subsection.landmark_label_id }}
                </button>
              </div>

              <ul *ngFor="let element of getSubsectionElements(subsection.id)" class="element-list">
                <li>
                  <ng-container
                    *ngTemplateOutlet="
                      elementTmpl;
                      context: {
                        element: element,
                        region: null,
                        subsection: subsection,
                        section: section
                      }
                    "
                  >
                  </ng-container>
                </li>
              </ul>
            </div>
            <!--Show region inside subsection-->
            <div *ngIf="subsection.region_set_id">
              <div class="region" *ngFor="let region of getRegions(subsection.region_set_id) | async">
                <div class="form-check no-checkbox item-hover-parent fw-bold" *ngIf="checkExistRegionChoice(region.id)">
                  <i class="fa fa-registered me-1"></i>
                  <label
                    title="Click to copy all statements in this region to the report"
                    class="form-check-label"
                    (click)="copyRegionChoices(region)"
                    [attr.data-region]="region.id"
                  >
                    {{ region.name }}
                  </label>
                  <button class="btn btn-sm btn-light item-hover ms-1" (click)="copyRegionChoices(region)">
                    <i class="fas fa-exchange-alt"></i> Copy region
                  </button>

                  <button
                    *ngIf="(mlLandmarkTemplate$ | async) && region.landmark_label_id"
                    class="btn btn-sm btn-light item-hover ms-1"
                    (click)="filterLandmark(region.landmark_label_id)"
                  >
                    <i class="fas fa-search"></i> Search
                    <rr-landmark-label-headline
                      *ngIf="region.landmark_label_id | storeSelectPipe: selectLandmarkLabel | async as landmarkLabel"
                      [landmarkLabel]="landmarkLabel"
                    ></rr-landmark-label-headline>
                  </button>
                </div>

                <ul *ngFor="let element of getSubsectionElements(subsection.id)">
                  <div *ngIf="checkElementInRegionIsUsed(element, region)">
                    <ng-container
                      *ngTemplateOutlet="
                        elementTmpl;
                        context: {
                          element: element,
                          region: region,
                          subsection: subsection
                        }
                      "
                    >
                    </ng-container>
                  </div>
                </ul>
              </div>
            </div>
          </li>
        </ul>

        <hr class="my-3" />
      </li>
    </ul>
  </div>
</div>
