<div>
  <!--Audit summary and audit events-->
  <rr-access-event-summary *ngIf="accessEvents.length > 0" [report_id]="report.id" class="me-2">
  </rr-access-event-summary>
</div>

<rr-access-event
  *ngFor="let event of showAllEditors ? accessEvents : visibleAccessEvents; last as last"
  [event]="event"
  [last]="last"
  [topics]="report.topic_ids"
  [access_events]="accessEvents"
>
</rr-access-event>

<ng-container *ngIf="visibleAccessEvents.length < accessEvents.length && !showAllEditors">
  <div
    class="btn-link text-primary"
    role="button"
    rrTooltip="HIDDEN_EDITORS"
    (click)="toggleReportEditors()"
    data-no-bubble
  >
    {{ accessEvents.length - visibleAccessEvents.length }} hidden
  </div>
</ng-container>
<ng-container *ngIf="accessEvents.length > 1 && showAllEditors">
  <div class="btn-link text-primary" role="button" (click)="toggleReportEditors()" data-no-bubble>Hide editors</div>
</ng-container>
