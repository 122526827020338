import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { TooltipDirective } from 'app/shared/directives/tooltip.directive';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { DocumentEffect, fromDocument } from 'app/store/report/document';
import { Subscription, of } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';

import { DocumentTypeSelectComponent } from '../../../../shared/components/document-type-select/document-type-select.component';
@Component({
  selector: 'rr-booking-document-attachment',
  templateUrl: './booking-document-attachment.component.html',
  styleUrls: ['./booking-document-attachment.component.css'],
  standalone: true,
  imports: [CommonModule, DocumentTypeSelectComponent, TooltipDirective],
})
export class BookingDocumentAttachmentComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() booking_id: number;
  @Input() header: string;
  @Input() parent: 'MODAL' | 'COMPONENT';
  documents: RR.Document[];
  subscription: Subscription = new Subscription();

  currentFile: File | null = null;
  submitting = false;

  form = new FormGroup({
    type: new FormControl<RR.DocumentType>('unclassified', { nonNullable: true }),
  });

  isEditingType = false;
  currentDocumentId: string | null = null;

  constructor(
    private documentEffect: DocumentEffect,
    private store: Store<AppState>,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromDocument.selectLoaded(this.booking_id))
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.documentEffect.findDocumentsInBooking(this.booking_id);
            }
            return of(null);
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.store.select(fromBooking.selectDocuments(this.booking_id)).subscribe((documents) => {
        // @ts-expect-error strictNullChecks
        this.documents = documents;
      }),
    );
  }

  onFileChange() {
    this.currentFile = this.fileInput.nativeElement.files[0];
  }

  submit() {
    if (!this.currentFile) return;
    this.submitting = true;
    const formData = new FormData();
    formData.append('file', this.currentFile, this.currentFile.name);
    formData.append('type', this.form.controls.type.value);
    this.subscription.add(
      this.documentEffect
        .create(this.booking_id, formData)
        .pipe(
          finalize(() => {
            this.submitting = false;
          }),
        )
        .subscribe({
          next: () => {
            this.currentFile = null;
            this.fileInput.nativeElement.value = '';
            this.messageService.add({
              title: 'Success',
              message: 'Upload report document successfully!',
              type: 'success',
            });
            this.cancelEdit();
          },
        }),
    );
  }

  updateDocumentType(filename: string) {
    const data = { type: this.form.controls.type.value };
    this.subscription.add(
      this.documentEffect.updateDocumentType(filename, data).subscribe(() => {
        this.cancelEdit();
      }),
    );
  }

  deleteDocument(documentId: string) {
    this.subscription.add(this.documentEffect.delete(documentId).subscribe());
  }

  editDocumentTypeMode(documentId: string) {
    this.currentDocumentId = documentId;
    this.isEditingType = true;
  }

  cancelEdit() {
    this.isEditingType = false;
    this.currentDocumentId = null;
    this.form.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
