import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyRoleHttpService {
  constructor(private http: HttpClient) {}

  findAll() {
    return this.http.get<{
      companyRoles: RR.CompanyRole[];
      permissions: RR.Permission[];
    }>('/api/company_role');
  }

  create(companyRole: Partial<RR.CompanyRole>): Observable<RR.CompanyRole> {
    return this.http.post<RR.CompanyRole>('/api/company_role', companyRole);
  }

  update(companyRoleId: string, changes: Partial<RR.CompanyRole>): Observable<RR.CompanyRole> {
    return this.http.put<RR.CompanyRole>(`/api/company_role/${companyRoleId}`, changes);
  }

  delete(companyRoleId: string) {
    return this.http.delete(`/api/company_role/${companyRoleId}`);
  }

  addPermission(companyRoleId: string, permissionId: RR.Permission['id']): Observable<RR.CompanyRole> {
    return this.http.post<RR.CompanyRole>(`/api/company_role/${companyRoleId}/permission/${permissionId}`, {});
  }

  removePermission(companyRoleId: string, permissionId: RR.Permission['id']): Observable<RR.CompanyRole> {
    return this.http.delete<RR.CompanyRole>(`/api/company_role/${companyRoleId}/permission/${permissionId}`);
  }
}
