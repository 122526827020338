import { Pipe, PipeTransform } from '@angular/core';
import { getPatientAgeAndSex, getPatientDOB } from 'app/app.utils';
import { format } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'patientDOBInfo',
})
export class PatientDOBInfoPipe implements PipeTransform {
  transform(patient: RR.Patient | undefined) {
    if (!patient) {
      return false;
    }

    const dob = getPatientDOB(patient);
    const dobString = dob !== undefined ? format(dob, 'MMM d, yyyy') : '';
    const ageAndSex = getPatientAgeAndSex(patient, true);
    return `${dobString}${ageAndSex}`;
  }
}
