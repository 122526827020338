import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MandatoryStatementService } from 'app/core/services/mandatory-statement.service';
import { ReportService } from 'app/core/services/report.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromMandatoryStatement } from 'app/store/mandatory-statement';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromStatement } from 'app/store/template/statement';
import { Observable, Subscription } from 'rxjs';

import { RecommendedStatementComponent } from '../statement-recommendation/recommended-statement/recommended-statement.component';
import { StatementComponent } from '../statement/statement.component';

@Component({
  selector: 'rr-mandatory-statements-modal',
  standalone: true,
  imports: [CommonModule, SharedModule, RecommendedStatementComponent, StatementComponent],
  templateUrl: './mandatory-statements-modal.component.html',
  styleUrl: './mandatory-statements-modal.component.css',
})
export class MandatoryStatementsModalComponent implements OnInit, OnDestroy {
  incompleteMandatoryStatements: RR.MandatoryStatement[] | undefined;
  statements$: Observable<RR.Statement[]>;
  topic: RR.Topic;
  mandatoryStatement: { [statementId: number]: RR.MandatoryStatement | undefined } = {};
  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private reportService: ReportService,
    protected mandatoryStatementService: MandatoryStatementService,
  ) {}

  ngOnInit(): void {
    const topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());

    this.subscription.add(
      topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    const mandatoryStatements$ = this.reportService.getIncompleteMandatoryStatements(this.topic);

    this.subscription.add(
      mandatoryStatements$.subscribe((mandatoryStatements: RR.MandatoryStatement[]) => {
        mandatoryStatements.forEach((mandatoryStatement) => {
          if (mandatoryStatement.statement_id) {
            this.mandatoryStatement[mandatoryStatement.statement_id] = mandatoryStatement;
          }
        });
      }),
    );
  }

  static open(modal: NgbModal, incompleteMandatoryStatements: RR.MandatoryStatement[] | undefined) {
    const modalRef = modal.open(MandatoryStatementsModalComponent, {
      size: 'lg',
      scrollable: true,
    });

    const componentInstance: MandatoryStatementsModalComponent = modalRef.componentInstance;
    componentInstance.incompleteMandatoryStatements = incompleteMandatoryStatements;

    return modalRef;
  }

  selectChoice(statementId: number) {
    this.mandatoryStatement[statementId] = undefined;
  }

  selectStatement = fromStatement.selectStatement;
  selectJustification = fromMandatoryStatement.selectJustification;

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
