<div
  ngbDropdown
  #dropdown="ngbDropdown"
  (openChange)="dropdownOpenChanged($event)"
  [autoClose]="'outside'"
  [placement]="'bottom'"
>
  <button ngbDropdownToggle class="btn btn-outline-dark btn-sm w-100" [disabled]="disabled" rrTooltip="ADD_TITLE">
    {{ topic.title_option_text || 'Indicate report title (I)' }}
  </button>
  <div
    #dropdownMenuEl
    ngbDropdownMenu
    permission
    [permission-only]="['report_manage']"
    class="w-100 p-0 title-dropdown border border-secondary"
    id="titleDropdown"
  >
    <rr-report-title-edit
      [topic]="topic"
      [mode]="'dropdown'"
      (onCreateTitle)="onCreateTitle()"
      [dropdown]="dropdown"
    ></rr-report-title-edit>
  </div>
</div>
