import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromBooking } from '../booking/booking.selector';
import { fromReport } from '../report/report/report.selector';

const adapter = createEntityAdapter<RR.Invoice>();
const selectFeature = (state: AppState) => state.invoice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = (reportId: number) => createSelector(selectFeature, (state) => !!state.loaded[reportId]);
const selectBookingInvoiceLoaded = (bookingId: number) =>
  createSelector(selectFeature, (state) => !!state.loaded[bookingId]);

const selectInvoice = (id: number) =>
  createSelector(selectEntities, (invoices: Dictionary<RR.Invoice>) => invoices[id]);

const selectInReport = (reportId: number) =>
  createSelector(
    fromReport.selectReport(reportId),
    selectEntities,
    (report: RR.Report | undefined, invoices: Dictionary<RR.Invoice>) =>
      report?.invoice_ids.map((invId) => invoices[invId]).filter((i): i is RR.Invoice => !!i) || [],
  );

const selectInBooking = (bookingId: number) =>
  createSelector(
    fromBooking.selectBooking(bookingId),
    selectEntities,
    (booking: RR.Booking | undefined, invoices: Dictionary<RR.Invoice>) =>
      booking?.invoice_ids.map((invId) => invoices[invId]).filter((i): i is RR.Invoice => !!i) || [],
  );

export const fromInvoice = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
  selectBookingInvoiceLoaded,
  selectInvoice,
  selectInReport,
  selectInBooking,
};
