import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { fromLabel } from 'app/store/report/label/label.selector';
import { fromReport } from 'app/store/report/report';

import { SharedModule } from '../../../../shared/shared.module';

@Component({
  selector: 'rr-report-label-badge',
  standalone: true,
  templateUrl: './report-label-badge.component.html',
  styleUrls: ['./report-label-badge.component.css'],
  imports: [CommonModule, SharedModule],
})
export class ReportLabelBadgeComponent {
  @Input() reportId: number;

  constructor() {}

  reportSelectorFn = fromReport.selectReport;
  labelSelectorFn = fromLabel.selectLabel;
}
