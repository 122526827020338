import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { NoticeEffect, fromNotice } from 'app/store/notice';
import { Subscription, of, switchMap } from 'rxjs';

function getSeenNoticeId() {
  const _seenNoticeId = localStorage.getItem('seenNoticeID');
  return _seenNoticeId !== null ? Number(_seenNoticeId) : null;
}

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notice-modal.component.html',
  styleUrls: ['./notice-modal.component.scss'],
})
export class NoticeModalComponent implements OnInit, OnDestroy {
  notices: RR.Notice[];
  seenNoticeID: number | null;
  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private selectorService: SelectorService,
    private store: Store<AppState>,
    private noticeEffect: NoticeEffect,
  ) {
    this.seenNoticeID = getSeenNoticeId();
  }

  ngOnInit(): void {
    this.subscription.add(this.noticeEffect.findAll().subscribe());

    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(switchMap((user) => (user ? this.store.select(fromNotice.selectByRoles(user.company_roles)) : of([]))))
        .subscribe((notices) => {
          this.notices = notices;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    localStorage.setItem('seenNoticeID', String(this.notices[0].id));
    this.activeModal.close();
  }

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(NoticeModalComponent, {
      size: 'lg',
      scrollable: true,
    });
    return modalRef;
  }

  static shouldShowNotices(notices: RR.Notice[]) {
    const firstNotice = notices[0];
    const seenNoticeID = getSeenNoticeId();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return firstNotice && (seenNoticeID == null || firstNotice.id !== seenNoticeID);
  }
}
