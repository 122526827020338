import { Component, Injector, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { PrefillSearchActions, PrefillSearchFormType } from 'app/store/prefill/prefill-search';
import { fromCurrentReport } from 'app/store/report/report';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromCurrentTopic } from 'app/store/report/topic';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { DemographicsModalComponent } from '../../../../shared/modals/demographics-modal/demographics-modal.component';
import { SharedModule } from '../../../../shared/shared.module';
import { TitleEditComponent } from '../../../report/components/preview/title-edit/title-edit.component';
import { StepService } from '../../step.service';
import { TitleWarningModalComponent } from './title-warning-modal/title-warning-modal.component';

@Component({
  selector: 'rr-sidebar-title-modal',
  templateUrl: './sidebar-title-modal.component.html',
  styleUrls: ['./sidebar-title-modal.component.scss'],
  standalone: true,
  imports: [SharedModule, forwardRef(() => TitleEditComponent)],
})
export class SidebarTitleModalComponent implements OnInit, OnDestroy {
  topic: RR.Topic;
  report: RR.Report;
  subscription: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private editorService: EditorService,
    private modalService: NgbModal,
    private statementChoiceEffect: StatementChoiceEffect,
    private stepService: StepService,
  ) {}

  ngOnInit() {
    // Subscribe to topic in case title changed after selecting
    this.subscription.add(
      this.store
        .select(fromCurrentTopic.selectTopic)
        .pipe(filter((topic) => topic != null))
        .subscribe((t) => {
          // @ts-expect-error strictNullChecks
          this.topic = t;
        }),
    );
    this.subscription.add(
      this.store
        .select(fromCurrentReport.selectReport)
        .pipe(filter((report) => report != null))
        .subscribe((r) => {
          // @ts-expect-error strictNullChecks
          this.report = r;
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openPatientInformation() {
    DemographicsModalComponent.open(this.modalService, this.report, this.topic, 'EDITOR');
  }

  togglePrefill(on?: boolean) {
    this.activeModal.dismiss();
    this.editorService.togglePrefill(on);
  }

  setPrefillType(searchType: PrefillSearchFormType['searchType']) {
    this.store.dispatch(PrefillSearchActions.updateSearchForm({ searchForm: { searchType: searchType } }));
    this.togglePrefill(true);
  }

  prefillSentences(type: 'TITLE' | 'PREDICTION') {
    this.activeModal.dismiss();
    this.statementChoiceEffect
      .createPredictionChoices(this.topic.id, type)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe(() => {
        this.editorService.togglePrefill(true);
      });
  }

  static open(
    modalService: NgbModal,
    {
      injector,
    }: {
      // With this, StepService gets created and destroyed at the some time EditorComponent is destroyed. Standard DI
      // doesn't work because modals are not children in the component tree.
      injector: Injector;
    },
  ) {
    const modalRef = modalService.open(SidebarTitleModalComponent, {
      injector,
      size: 'lg',
      beforeDismiss: () => {
        const component: SidebarTitleModalComponent = modalRef.componentInstance;
        if (!component.stepService.titleStep.complete) {
          component.reportTitleChecker();
          // Returning false blocks the modal from closing.
          return false;
        }
        // Now that the title is selected, allow the modal to close.
        return true;
      },
    });
    return modalRef;
  }

  reportTitleChecker() {
    if (!this.stepService.titleStep.complete) {
      const modalRef = TitleWarningModalComponent.open(this.modalService);
      modalRef.result
        .then(() => {
          // "Skip Selecting Title" clicked
          this.stepService.completeStep('title');
          this.activeModal.close();
        })
        .catch(() => {
          /* Modal dismissed */
        });
    } else {
      this.activeModal.close();
    }
  }
}
