import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { strToNum } from 'app/app.utils';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: '[rr-invoice-item-form]',
  templateUrl: './invoice-item-form.component.html',
  styleUrls: ['./invoice-item-form.component.css'],
})
export class InvoiceItemFormComponent {
  @Input() invoiceItem: RR.InvoiceItem;
  @Input() draft: boolean;
  @Output() onEditItem = new EventEmitter<{ item: RR.InvoiceItem; changes: Partial<RR.InvoiceItem> }>();
  @Output() onRemoveItem = new EventEmitter<RR.InvoiceItem>();

  editItemAmount(value: string, field: string) {
    if (!value) return;
    const changes = { [field]: strToNum(value) };
    this.onEditItem.emit({ item: this.invoiceItem, changes });
  }

  editItemServiceDate(value: string) {
    this.onEditItem.emit({ item: this.invoiceItem, changes: { service_date: value || null } });
  }

  removeInvoiceItem() {
    this.onRemoveItem.emit(this.invoiceItem);
  }
}
