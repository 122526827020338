import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PercentileBandActions } from './percentile-band.action';
import { PercentileBandHttpService } from './percentile-band.service';
import { PercentileBand, PercentileBandBatchCreate } from './percentile-band.service';

@Injectable()
export class PercentileBandEffect {
  constructor(
    private store: Store<AppState>,
    private service: PercentileBandHttpService,
    private message: MessageService,
  ) {}

  create(percentileBand: Partial<PercentileBand>, percentileBandGroupId: number) {
    return this.service.createPercentileBand(percentileBandGroupId, percentileBand).pipe(
      this.message.handleHttpErrorPipe,
      map((data: PercentileBand) => PercentileBandActions.createSuccess({ percentile_band: data })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(percentileBandId: number) {
    return this.service.deletePercentileBand(percentileBandId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => PercentileBandActions.deleteSuccess({ percentile_band_id: percentileBandId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(percentileBandId: number, changes: Partial<PercentileBand>) {
    return this.service.updatePercentileBand(percentileBandId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((percentile_band) => PercentileBandActions.updateSuccess({ percentile_band })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  createBatch(percentileBandGroupId: number, data: PercentileBandBatchCreate) {
    return this.service.createPercentileBandBatch(percentileBandGroupId, data).pipe(
      this.message.handleHttpErrorPipe,
      map((percentile_bands) => PercentileBandActions.searchSuccess({ percentile_bands })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
