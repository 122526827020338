import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserNotificationsEffect } from 'app/store/notifications/notifications.effect';
import { Subscription } from 'rxjs';

@Component({
  // used attribute selector for the .list-group, extra DOM levels interfere with the styles
  selector: '[rr-user-notification]',
  host: {
    class: 'list-group-item d-flex justify-content-between align-items-start gap-2',
  },
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-notification.component.html',
  styleUrl: './user-notification.component.css',
})
export class UserNotificationComponent implements OnInit, OnDestroy {
  @Input() notification: RR.UserNotification;
  subscription = new Subscription();

  constructor(
    private userNotificationsEffect: UserNotificationsEffect,
    private router: Router,
  ) {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  read(notificationId: number) {
    this.subscription.add(this.userNotificationsEffect.read(notificationId).subscribe());
  }

  openReport(notification: RR.UserNotificationPathology) {
    if (!notification.read) {
      this.read(notification.id);
    }
    const reportURL = this.router.createUrlTree(['/report', notification.report_id, 'edit']);
    // eslint-disable-next-line no-restricted-properties
    window.open(reportURL.toString(), '_blank');
  }
}
