import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { IncludesPipe } from 'app/shared/pipes/includes.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { BodyPartEffect, fromBodyPart } from 'app/store/template/body-part';
import { TemplateEffect, fromTemplate } from 'app/store/template/template';
import { Observable, Subscription, debounceTime, map, merge, startWith, switchMap, take } from 'rxjs';

@Component({
  selector: 'rr-body-part',
  standalone: true,
  imports: [CommonModule, SharedModule, NgbModule, ReactiveFormsModule, IncludesPipe, VoiceDirective, ScrollingModule],
  templateUrl: './body-part.component.html',
  styleUrls: ['./body-part.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BodyPartComponent implements OnInit, OnDestroy {
  @ViewChild('textInput') textInput: ElementRef;
  @Input() template: RR.Template | undefined;
  @Input() selectedBodyPartId: number | undefined;
  @Output() bodyPart = new EventEmitter<RR.BodyPart>();
  bodyParts$: Observable<RR.BodyPart[]>;

  subscription = new Subscription();

  form = new FormGroup({
    search: new FormControl<string>('', { nonNullable: true }),
  });

  selectedBodyParts: number[] = [];

  constructor(
    private store: Store<AppState>,
    private bodyPartEffect: BodyPartEffect,
    private templateEffect: TemplateEffect,
  ) {}

  ngOnInit(): void {
    if (this.template) {
      this.selectedBodyParts = [...this.template.body_parts];
    } else {
      this.selectedBodyParts = [];
    }

    this.subscription.add(
      this.form.controls.search.valueChanges
        .pipe(
          startWith(''),
          debounceTime(300),
          switchMap((searchInput) => this.bodyPartEffect.search(searchInput)),
        )
        .subscribe(),
    );

    this.bodyParts$ = this.store.select(fromBodyPart.selectAll);
  }

  onDropdownChange(isOpen: boolean) {
    if (isOpen) {
      requestAnimationFrame(() => {
        this.textInput.nativeElement.focus();
      });
    }
  }

  createBodyPart() {
    this.subscription.add(this.bodyPartEffect.create({ name: this.form.controls.search.value }).subscribe());
  }

  updateTemplate(bodyPart: RR.BodyPart, event: Event) {
    if (this.template) {
      const isChecked = (event.target as HTMLInputElement).checked;

      if (isChecked) {
        if (!this.selectedBodyParts.includes(bodyPart.id)) {
          this.selectedBodyParts = [...this.selectedBodyParts, bodyPart.id];
        }
      } else {
        this.selectedBodyParts = this.selectedBodyParts.filter((id) => id !== bodyPart.id);
      }

      this.subscription.add(
        this.templateEffect.update(this.template.id, { body_parts: this.selectedBodyParts }).subscribe(),
      );
    } else {
      this.bodyPart.emit(bodyPart);
      this.selectedBodyPartId = bodyPart.id;
    }
  }

  deleteBodyPart(bodyPartId: number) {
    // Update all templates store containing the selected body part
    this.subscription.add(
      this.bodyPartEffect
        .delete(bodyPartId)
        .pipe(
          switchMap(() => this.store.select(fromTemplate.selectActiveTemplates).pipe(take(1))),
          map((templates) => templates.filter((template) => template.body_parts.includes(bodyPartId))),
          switchMap((templatesToUpdate) => {
            const updateObservables = templatesToUpdate.map((template) => {
              const updatedBodyParts = template.body_parts.filter((id) => id !== bodyPartId);
              return this.templateEffect.update(template.id, { body_parts: updatedBodyParts });
            });
            return merge(...updateObservables);
          }),
        )
        .subscribe(),
    );
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.form.controls.search.setValue(event.detail.term);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
