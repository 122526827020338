import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[rrAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  // If no input is passed, an empty string is used as default
  @Input() rrAutoFocus?: boolean | string;

  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit() {
    // If input is false, do not apply focus
    if (this.rrAutoFocus === false) return;
    requestAnimationFrame(() => {
      this.elementRef.nativeElement.focus();
    });
  }
}
