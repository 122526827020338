<div
  ngbDropdown
  (openChange)="onDropdownChange($event)"
  dropdownClass="dropdown-width"
  class="d-inline-block body-part"
>
  <ng-container *ngIf="pathologies$ | async as pathologies">
    <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>
      Pathologies
      <div class="badge bg-primary">{{ pathologies.length }}</div>
    </button>
    <div class="w-100 p-2" ngbDropdownMenu>
      <div class="mb-2">
        <div permission [permission-only]="['template_manage']" class="input-group mb-2">
          <input
            #textInput
            rrVoice
            type="text"
            class="form-control"
            [formControl]="form.controls.search"
            placeholder="Add or search for pathology..."
          />
          <button (click)="createPathology()" [disabled]="!form.controls.search.value" class="btn btn-outline-success">
            <i class="fas fa-plus"></i>
          </button>
        </div>
        <cdk-virtual-scroll-viewport itemSize="10" minBufferPx="600" maxBufferPx="900" class="dropdown-viewport">
          <ul class="list-group">
            <li *cdkVirtualFor="let pathology of pathologies; let i = index" class="list-group-item">
              <div class="form-check d-flex align-items-center justify-content-between">
                <div>
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pathology"
                    [id]="pathology.id"
                    [checked]="pathology.id === selectedPathologyId"
                    (change)="selectedPathology(pathology)"
                  />
                  <label class="form-check-label" [for]="pathology.id">
                    {{ pathology.name }}
                  </label>
                </div>

                <div>
                  <span role="button" (click)="deletePathology(pathology.id)">
                    <i class="fas fa-trash"></i>
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </ng-container>
</div>
