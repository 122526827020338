import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Category
 *
 */
export const CategoryActions = {
  findAllSuccess: createAction('[Category] Find All Success', props<{ categories: RR.Category[] }>()),

  createSuccess: createAction('[Category] Create Success', props<{ category: RR.Category }>()),

  updateSuccess: createAction('[Category] Update Success', props<{ category: RR.Category }>()),

  deleteSuccess: createAction('[Category] Delete Success', props<{ categoryId: number }>()),
};
