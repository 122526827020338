import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { FeedbackCommentActions } from '../feedback-comment/feedback-comment.action';
import { FeedbackActions, FeedbackBatchActions } from './feedback.action';
import { FeedbackHttpService, FeedbackQueryBody } from './feedback.service';

@Injectable()
export class FeedbackEffect {
  constructor(
    private store: Store<AppState>,
    private service: FeedbackHttpService,
  ) {}

  findAll(params?: HttpParams) {
    return this.service.findAll(params).pipe(
      map((feedbacks: RR.Feedback[]) => FeedbackActions.findAllSuccess({ feedbacks })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findInReport(reportId: number) {
    return this.service.findInReport(reportId).pipe(
      map((feedbacks: RR.Feedback[]) => FeedbackActions.findManySuccess({ feedbacks })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(feedback: Partial<RR.Feedback>) {
    return this.service.create(feedback).pipe(
      map((feedback: RR.Feedback) => FeedbackActions.createSuccess({ feedback })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(feedbackId: number, changes: Partial<RR.Feedback>) {
    return this.service.update(feedbackId, changes).pipe(
      map((feedback) => FeedbackActions.updateSuccess({ feedback })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: FeedbackQueryBody, params: HttpParams) {
    return this.service.search(queryBody, params).pipe(
      map(({ count, feedbacks, feedback_comments }) => {
        return FeedbackBatchActions.findAllSuccess({
          count: count,
          feedbackIds: feedbacks.map((feedback) => feedback.id),
          actions: {
            findFeedbacksSuccess: FeedbackActions.findManySuccess({ feedbacks }),
            findFeedbackCommentsSuccess: FeedbackCommentActions.findManySuccess({
              feedbackComments: feedback_comments,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  check(feedbackId: number) {
    return this.service.check(feedbackId).pipe(
      map(({ feedback, feedback_comments }) => {
        return FeedbackBatchActions.checkFeedbackSuccess({
          actions: {
            updateFeedbackSuccess: FeedbackActions.updateSuccess({ feedback }),
            updateFeedbackCommentsSuccess: FeedbackCommentActions.updateManySuccess({
              feedbackComments: feedback_comments,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
