<ng-container>
  <ng-container *ngIf="report$ | async as report">
    <button
      #sendVoyagerButton
      class="btn"
      role="button"
      (click)="sendToVoyager()"
      placement="bottom"
      [class.btn-sm]="btnSm"
      [class.btn-primary]="!report || !report.send_to_voyager_time || !report.text_modified"
      [class.btn-success]="
        report &&
        report.send_to_voyager_time &&
        (!report.text_modified || report.send_to_voyager_time > report.text_modified)
      "
      [class.btn-warning]="
        report &&
        report.send_to_voyager_time &&
        report.text_modified &&
        report.send_to_voyager_time < report.text_modified
      "
      [disabled]="(delay && report_id === sendingReportId) || sendingToVoyager"
      [rrTooltip]="delay ? 'DELAY_SEND_TO_VOYAGER' : 'SEND_TO_VOYAGER'"
      permission
      [permission-only]="['report_approve']"
    >
      <ng-container *ngIf="delay && report_id === sendingReportId">
        <i class="fas fa-clock"></i>
        Pending export to Voyager...
      </ng-container>
      <ng-container *ngIf="sendingToVoyager">
        <i class="fas fa-spinner fa-spin"></i>
        Exporting...
      </ng-container>
      <ng-container *ngIf="(!delay && !sendingToVoyager) || (delay && report_id !== sendingReportId)">
        <i class="fas fa-paper-plane" *ngIf="!report.send_to_voyager_time && !delay"></i>
        <i class="fas fa-clock" *ngIf="!report.send_to_voyager_time && delay"></i>
        <i
          class="fas fa-check-circle"
          *ngIf="
            report &&
            report.send_to_voyager_time &&
            report.text_modified &&
            report.send_to_voyager_time > report.text_modified
          "
        ></i>
        <i
          class="fas fa-exclamation-triangle"
          *ngIf="
            report &&
            report.send_to_voyager_time &&
            report.text_modified &&
            report.send_to_voyager_time < report.text_modified
          "
        ></i>
        <span *ngIf="!report.send_to_voyager_time">
          {{ delay ? 'Export to Voyager when review finished' : 'Export to Voyager now' }}</span
        >
        <span
          *ngIf="
            report &&
            report.send_to_voyager_time &&
            (!report.text_modified || report.send_to_voyager_time > report.text_modified)
          "
        >
          {{ delay ? 'Export to Voyager again when review finished' : 'Export to Voyager again now' }}</span
        >
        <span
          *ngIf="
            report &&
            report.send_to_voyager_time &&
            report.text_modified &&
            report.send_to_voyager_time < report.text_modified
          "
        >
          {{
            delay
              ? 'Report changed, export to Voyager again when review finished'
              : 'Report changed, export to Voyager again now'
          }}</span
        >
      </ng-container>
    </button>
  </ng-container>
</ng-container>
