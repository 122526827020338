import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { ReferrerSearchFilterForm } from 'app/modules/entity-listing/components/referrer-listing/referrer-listing-search/referrer-listing-search.component';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReferrerContactMethodActions } from '../referrer-contact-method/referrer-contact-method.action';
import { ReferrerNoteCategoryActions } from '../referrer-note-category/referrer-note-category.action';
import { ReferrerNoteActions } from '../referrer-note/referrer-note.action';
import { ReferrerActions, ReferrerBatchActions } from './referrer.action';
import { ReferrerGetResponse, ReferrerHttpService, ReferrerResponse, ReferrerSearchResponse } from './referrer.service';

@Injectable()
export class ReferrerEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReferrerHttpService,
    private message: MessageService,
  ) {}

  search(queryBody: ReferrerSearchFilterForm, withDeleted: boolean, onlyDeleted: boolean, params: HttpParams) {
    return this.service.searchReferrers(queryBody, withDeleted, onlyDeleted, params).pipe(
      this.message.handleHttpErrorPipe,
      map((response: ReferrerSearchResponse) =>
        ReferrerBatchActions.searchSuccess({
          actions: {
            searchReferrerSuccess: ReferrerActions.searchSuccess({ searchResult: response }),
            findManyReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
              referrer_contact_methods: response.referrer_contact_methods,
            }),
            findManyReferrerNoteSuccess: ReferrerNoteActions.findManySuccess({
              referrer_notes: response.referrer_notes,
            }),
            findManyReferrerNoteCategorySuccess: ReferrerNoteCategoryActions.findManySuccess({
              referrer_note_categories: response.referrer_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findById(referrerId: number) {
    return this.service.findById(referrerId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: ReferrerGetResponse) =>
        ReferrerBatchActions.findSuccess({
          actions: {
            findReferrerSuccess: ReferrerActions.findSuccess({ referrer: data.referrer }),
            findManyReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
              referrer_contact_methods: data.referrer_contact_methods,
            }),
            findManyReferrerNoteSuccess: ReferrerNoteActions.findManySuccess({ referrer_notes: data.referrer_notes }),
            findManyReferrerNoteCategorySuccess: ReferrerNoteCategoryActions.findManySuccess({
              referrer_note_categories: data.referrer_note_categories,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(referrer: Partial<RR.Referrer>) {
    return this.service.create(referrer).pipe(
      // this.message.handleHttpErrorPipe,
      map((data: ReferrerResponse) =>
        ReferrerBatchActions.createSuccess({
          actions: {
            createReferrerSuccess: ReferrerActions.createSuccess({ referrer: data.referrer }),
            findManyReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
              referrer_contact_methods: data.referrer_contact_methods,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(referrerId: number, changes: Partial<RR.Referrer>) {
    return this.service.update(referrerId, changes).pipe(
      // this.message.handleHttpErrorPipe,
      map((data: ReferrerResponse) =>
        ReferrerBatchActions.updateSuccess({
          actions: {
            updateReferrerSuccess: ReferrerActions.updateSuccess({ referrer: data.referrer }),
            findManyReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
              referrer_contact_methods: data.referrer_contact_methods,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(referrer: RR.Referrer) {
    return this.service.delete(referrer.id).pipe(
      this.message.handleHttpErrorPipe,
      map(() => ReferrerActions.deleteSuccess({ referrer })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  restoreDeletedReferrer(referrerId: number) {
    return this.service.restoreDeletedReferrer(referrerId).pipe(
      this.message.handleHttpErrorPipe,
      map((referrer) => ReferrerActions.findSuccess({ referrer })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
