import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportEffect } from 'app/store/report/report';
import { Subscription, take } from 'rxjs';

import { ReportHeadlineComponent } from '../report-headline/report-headline.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReportHeadlineComponent],
  selector: 'rr-proof-read-report',
  templateUrl: './proof-read-report.component.html',
  styleUrls: ['./proof-read-report.component.css'],
})
export class ProofReadReportComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  subscription = new Subscription();
  text: string;

  constructor(
    private reportEffect: ReportEffect,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.reportEffect
        .findReportText(this.reportId)
        .pipe(take(1))
        .subscribe((action) => {
          this.text = action.report.text;
        }),
    );
  }

  static open(modalService: NgbModal, reportId: number) {
    const modalRef = modalService.open(ProofReadReportComponent, {
      size: 'lg',
      centered: true,
      scrollable: true,
    });
    modalRef.componentInstance.reportId = reportId;
    return modalRef;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
