import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'rr-booking-code-headline',
  templateUrl: './booking-code-headline.component.html',
  styleUrls: ['./booking-code-headline.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingCodeHeadlineComponent {
  @Input() bookingCode: RR.BookingCode;
  @Input() showModality = true;
  @Input() hideIcon = false;
}
