import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SelectorService } from 'app/core/services/selector.service';
import { DividerStatementsModalComponent } from 'app/modules/editor/divider/divider-statements-modal/divider-statements-modal.component';
import { NavigationService } from 'app/shared/components/navigation/navigation.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromUserSetting } from 'app/store/user/user-setting';
import { Observable, switchMap } from 'rxjs';

import { GlobalSearchComponent } from '../global-search/global-search.component';
import { NavbarButtonsComponent } from '../navbar-buttons/navbar-buttons.component';
import { NavbarComponent } from '../toolbar-navbar/toolbar-navbar.component';

@Component({
  selector: 'rr-editor-navbar',
  templateUrl: './editor-navbar.component.html',
  styleUrls: ['editor-navbar.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, GlobalSearchComponent, NavbarButtonsComponent, NavbarComponent],
})
export class EditorNavbarComponent extends NavbarComponent {
  @Input() openTopic: RR.Topic;
  rrConfig = rrConfig;
  userSettings$: Observable<RR.UserSetting | undefined>;

  constructor(
    private modalService: NgbModal,
    private selectorService: SelectorService,
    private store: Store<AppState>,
    navigationService: NavigationService,
  ) {
    super(navigationService);
    this.userSettings$ = this.selectorService.selectLoadedCurrentUser().pipe(
      filterDefined(),
      switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
    );
  }

  openDividerStatementsModal() {
    DividerStatementsModalComponent.open({
      modalService: this.modalService,
      topic_id: this.openTopic.id,
      parent: 'PREFILL_TAG_MODAL',
    });
  }

  toggleSideNavigation() {
    this.navigationService.open();
  }
}
