<div class="flex-grow-1 d-flex flex-column">
  <div *ngIf="feedback.note" class="mb-4">
    <h4 class="fw-bold mb-1">Feedback Notes:</h4>
    <p class="mb-0">{{ feedback.note }}</p>
  </div>
  <div class="mb-4">
    <h4 class="fw-bold mb-1">Comments:</h4>
    <div *ngFor="let comment of feedbackComments" class="my-1">
      <span class="fw-bold">
        <ng-container *ngIf="comment.user_id | storeSelectPipe: userSelectorFn | async as user">
          <rr-user-headline [user]="user"></rr-user-headline>
        </ng-container>
      </span>
      <span class="text-secondary comment-time ms-2">
        {{ comment.created | date: 'dd/MM/yyyy, h:mm a' }}
      </span>
      <div class="ms-2">
        {{ comment.comment_text }}
      </div>
    </div>
    <textarea
      class="col-12 form-control mb-2"
      [formControl]="feedbackCommentText"
      rrVoice
      [speechSynthesis]="true"
      [keydownStopListening]="false"
      [focusOutStopListening]="false"
    ></textarea>
    <div class="d-flex">
      <div class="me-1">
        <rr-voice-recognition [source]="'CHECK_FEEDBACK'" [excludeVoiceTerms]="true"></rr-voice-recognition>
      </div>
      <button class="btn btn-sm btn-info" (click)="submitComment(feedback.id)">
        <i class="fas fa-comments"></i> Submit Comment
      </button>
    </div>
  </div>

  <div class="mb-4">
    <h4 class="fw-bold mb-1">Report rating:</h4>
    <ngb-rating *ngIf="feedback.rate" [(rate)]="feedback.rate" class="text-warning h1 mb-0" [max]="5" [readonly]="true">
    </ngb-rating>
    <span *ngIf="!feedback.rate">No rating</span>
  </div>
  <div class="mb-3" *ngIf="report">
    <h4 class="fw-bold mb-1">Editors:</h4>
    <rr-access-event-list [report]="report"></rr-access-event-list>
  </div>
  <div class="mb-3" *ngIf="report">
    <rr-report-date data-no-bubble [reportId]="report.id" [label]="true"></rr-report-date>
  </div>
  <div class="d-flex">
    <h4 class="fw-bold me-2">Compare your modifications with final report:</h4>
    <a class="me-2" [routerLink]="['/report', feedback.report_id, 'edit']" target="_blank"
      >Open Report <i class="small fa fa-external-link-alt"></i
    ></a>
    <rr-zero-fp-images *ngIf="report" [report]="report"></rr-zero-fp-images>
  </div>
  <rr-report-text-diff
    class="d-flex"
    *ngIf="feedback && user"
    [userId]="user.id"
    [reportId]="feedback.report_id"
  ></rr-report-text-diff>
</div>
