<ng-container *ngIf="referrer$ | async as referrer">
  <span [class.fw-semibold]="strong">
    <ng-container *ngIf="referrer.title">{{ referrer.title }} </ng-container>
    <ng-container *ngIf="referrer.physician_given_name">{{ referrer.physician_given_name }} </ng-container>
    <ng-container *ngIf="referrer.physician_family_name">{{ referrer.physician_family_name }}</ng-container>
  </span>
  <ng-container *ngIf="referrer.referrer_type && referrerType">
    <span ngbTooltip="Referrer Type" container="body">, {{ referrer.referrer_type | uppercase }}</span>
  </ng-container>
  <ng-container *ngIf="editReferrer">
    <a
      permission
      [permission-only]="['data_management']"
      target="_blank"
      class="text-secondary ms-1"
      [routerLink]="['/entities/referrers', referrerId]"
      data-no-bubble
      rrTooltip="OPEN_REFERRER_MANAGEMENT_PAGE"
    >
      <i class="fas fa-external-link-square-alt"></i>
    </a>
  </ng-container>
</ng-container>
