import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromElement } from '../element/element.selector';
import { fromRegionSet } from '../region-set/region-set.selector';
import { fromRegion } from '../region/region.selector';
import { fromStatementSet } from '../statement-set/statement-set.selector';

const adapter = createEntityAdapter<RR.Subsection>();
const selectFeature = (state: AppState) => state.subsection;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectSubsection = (subsectionId: number) =>
  createSelector(selectEntities, (subsections: Dictionary<RR.Subsection>) => subsections[subsectionId]);

const selectElements = (subsectionId: number) =>
  createSelector(
    selectSubsection(subsectionId),
    fromElement.selectEntities,
    (subsection: RR.Subsection | undefined, elements: Dictionary<RR.Element>) =>
      subsection?.elements.map((elementId) => elements[elementId]).filter((e): e is RR.Element => !!e) || [],
  );

const selectStatementSets = (subsectionId: number) =>
  createSelector(
    selectElements(subsectionId),
    fromStatementSet.selectEntities,
    (elements: RR.Element[], statementSets: Dictionary<RR.StatementSet>) =>
      elements.map((element) => statementSets[element.statement_set_id]),
  );

const selectRegionSet = (subsectionId: number) =>
  createSelector(
    selectSubsection(subsectionId),
    fromRegionSet.selectEntities,
    (subsection: RR.Subsection | undefined, regionSets: Dictionary<RR.RegionSet>) =>
      (subsection?.region_set_id && regionSets[subsection.region_set_id]) || undefined,
  );

const selectRegions = (subsectionId: number) =>
  createSelector(
    selectRegionSet(subsectionId),
    fromRegion.selectEntities,
    (regionSet: RR.RegionSet | undefined, regions: Dictionary<RR.Region>) => {
      // TODO(template-structure): duplicated code, can't reuse fromRegionSet.selectRegions(regionSet?.id) in the output selector
      return regionSet?.region_ids.map((regionId) => regions[regionId]).filter((r): r is RR.Region => !!r) || [];
    },
  );

export const fromSubsection = {
  selectIds,
  selectEntities,
  selectAll,
  selectSubsection,
  selectRegions,
  selectRegionSet,
  selectElements,
  selectStatementSets,
};
