import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.TextObjectChoice>();
const selectFeature = (state: AppState) => state.text_object_choice;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectTextObjectChoice = (textObjectChoiceId: number) =>
  createSelector(
    selectEntities,
    (textObjectChoices: Dictionary<RR.TextObjectChoice>) => textObjectChoices[textObjectChoiceId],
  );

export const fromTextObjectChoice = {
  selectIds,
  selectEntities,
  selectAll,
  selectTextObjectChoice,
};
