import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class NoticeHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Notice[]> {
    return this.http.get<RR.Notice[]>('/api/notice');
  }

  create(notice: Partial<RR.Notice>): Observable<RR.Notice> {
    return this.http.post<RR.Notice>('/api/notice', notice);
  }

  delete(noticeId: number) {
    return this.http.delete(`/api/notice/${noticeId}`);
  }
}
