import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromStatement } from 'app/store/template/statement';
import { Observable, switchMap } from 'rxjs';

import { StatementComponent } from '../../statement/statement.component';

@Component({
  selector: 'rr-statement-preview',
  templateUrl: './statement-preview.component.html',
  styleUrls: ['./statement-preview.component.css'],
  standalone: true,
  imports: [CommonModule, forwardRef(() => StatementComponent)],
})
export class StatementPreviewComponent implements OnInit {
  @Input() region: RR.Region | undefined;
  @Input() topic: RR.Topic;
  @Input() @BindObservable() statement_id: number;
  statement_id$: Observable<number>;
  @Input() template_id: number;

  statement$: Observable<RR.Statement>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // statement_id input might be changed when moving multiple statements
    this.statement$ = this.statement_id$.pipe(
      switchMap((statement_id) => this.store.select(fromStatement.selectStatement(statement_id))),
      filterDefined(),
    );
  }
}
