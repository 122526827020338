import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for UrgentNotes (Admin Notes)
 *
 */
export const UrgentNoteActions = {
  findManySuccess: createAction('[UrgentNotes] Find Many Success', props<{ urgentNotes: RR.UrgentNotes[] }>()),

  createSuccess: createAction('[UrgentNotes] Create Success', props<{ urgentNote: RR.UrgentNotes }>()),

  deleteSuccess: createAction('[UrgentNotes] Delete Success', props<{ urgentNoteId: number }>()),
};
