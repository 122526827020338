import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { LandmarkLabelEffect } from 'app/store/landmark-label';
import { fromRegion, RegionEffect } from 'app/store/template/region';
import { fromSubsection, SubsectionEffect } from 'app/store/template/subsection';
import { Subscription, switchMap, take } from 'rxjs';

@Component({
  selector: 'rr-edit-landmark-modal',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './edit-landmark-modal.component.html',
  styleUrls: ['./edit-landmark-modal.component.css'],
})
export class EditLandmarkModalComponent implements OnInit, OnDestroy {
  @Input() subsection_id: number | undefined;
  @Input() region_id: number | undefined;
  @Input() landmark_label_id: string | null;

  subsection: RR.Subsection | undefined;
  region: RR.Region | undefined;
  landmarkName: string;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private subsectionEffect: SubsectionEffect,
    private regionEffect: RegionEffect,
    private landmarkLabelEffect: LandmarkLabelEffect,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (this.subsection_id) {
      this.subscription.add(
        this.store.select(fromSubsection.selectSubsection(this.subsection_id)).subscribe((s) => {
          this.subsection = s;
        }),
      );
    } else if (this.region_id) {
      this.subscription.add(
        this.store.select(fromRegion.selectRegion(this.region_id)).subscribe((r) => {
          this.region = r;
        }),
      );
    }

    if (this.landmark_label_id) {
      this.subscription.add(
        this.landmarkLabelEffect
          .find(this.landmark_label_id)
          .pipe(take(1))
          .subscribe((action) => {
            const landmark = action.landmarkLabel;
            this.landmarkName = landmark.name;
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  saveLandmark() {
    // If the landmark was already created, just update landmark name for it
    if (this.landmark_label_id) {
      this.subscription.add(
        this.landmarkLabelEffect
          .update(this.landmark_label_id, { name: this.landmarkName })
          .pipe(take(1))
          .subscribe(() => {
            this.onSuccess();
          }),
      );
    } else {
      // Creating new landmark before updating subsection/region with that landmark id
      this.subscription.add(
        this.landmarkLabelEffect
          .create({ name: this.landmarkName })
          .pipe(
            switchMap((action) => {
              const landmark = action.landmarkLabel;
              if (this.subsection_id) {
                return this.subsectionEffect.update(this.subsection_id, { landmark_label_id: landmark.id });
              } else if (this.region_id) {
                return this.regionEffect.update(this.region_id, { landmark_label_id: landmark.id });
              } else {
                throw new Error('region_id and subsection_id undefined');
              }
            }),
            take(1),
          )
          .subscribe(() => {
            this.onSuccess();
          }),
      );
    }
  }

  onSuccess() {
    this.messageService.add({ type: 'success', title: 'Success', message: 'Save landmark successfully!' });
    this.activeModal.dismiss();
  }

  static open(
    modalService: NgbModal,
    data: { subsection_id?: number; region_id?: number; landmark_label_id: string | null },
  ) {
    const modalRef = modalService.open(EditLandmarkModalComponent, {
      size: 'lg',
    });
    const component: EditLandmarkModalComponent = modalRef.componentInstance;
    component.subsection_id = data.subsection_id;
    component.region_id = data.region_id;
    component.landmark_label_id = data.landmark_label_id;
    return modalRef;
  }
}
