<input
  class="form-control"
  placeholder="e.g. 0455201721"
  maxlength="10"
  (blur)="onTouchedFn()"
  [formControl]="formControl"
  [class.ng-valid]="!invalid || ngControl.valid"
  [class.ng-invalid]="invalid || ngControl.invalid"
  [class.ng-touched]="ngControl.touched"
  [class.ng-untouched]="!ngControl.touched"
/>
