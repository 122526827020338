import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Notice
 *
 */
export const NoticeActions = {
  findAllSuccess: createAction('[Notice] Find All Success', props<{ notices: RR.Notice[] }>()),

  createSuccess: createAction('[Notice] Create Success', props<{ notice: RR.Notice }>()),

  deleteSuccess: createAction('[Notice] Delete Success', props<{ noticeId: number }>()),
};
