import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { getDeepestRoute } from 'app/app.utils';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

// https://ngrx.io/guide/router-store/configuration
export class CustomRouteSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const route = getDeepestRoute(routerState.root);

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}
