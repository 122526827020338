<div class="d-flex flex-grow-1">
  <div class="d-flex flex-column flex-grow-1">
    <rr-date-picker
      class="w-25"
      label="Date:"
      [start]="fromDate"
      [end]="toDate"
      (onDatesChanged)="onDatesChanged($event)"
    ></rr-date-picker>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex mb-2 align-items-center">
        <div class="form-check ms-0" (change)="onFeedbackFilterChanged('ALL')">
          <input id="all_feedback" class="form-check-input" type="radio" [checked]="filter === 'ALL'" value="ALL" />
          <label for="all_feedback" class="form-check-label"> All </label>
        </div>

        <div class="form-check ms-2" (change)="onFeedbackFilterChanged('UNREAD')">
          <input id="unread" class="form-check-input" type="radio" [checked]="filter === 'UNREAD'" value="UNREAD" />
          <label for="unread" class="form-check-label"> Unread </label>
        </div>
      </div>
    </div>
    <div class="w-25 my-3">
      <div>
        <span>Filter By: </span>
      </div>
      <!-- Filter by sender or recipient -->
      <div class="d-flex mb-2">
        <div class="form-check ms-2">
          <input
            id="sender"
            class="form-check-input"
            type="radio"
            name="filterType"
            [formControl]="filterType"
            value="SENDER"
          />
          <label for="sender" class="form-check-label">Sender</label>
        </div>
        <div class="form-check ms-2">
          <input
            id="recipient"
            class="form-check-input"
            type="radio"
            name="filterType"
            [formControl]="filterType"
            value="RECIPIENT"
          />
          <label for="recipient" class="form-check-label">Recipient</label>
        </div>
        <div *ngIf="reportId" class="form-check ms-2">
          <input
            id="report"
            class="form-check-input"
            type="radio"
            name="filterType"
            [formControl]="filterType"
            value="REPORT"
          />
          <label for="report" class="form-check-label">Report</label>
        </div>
      </div>

      <rr-user-selector
        rrTooltip="FILTER_FEEDBACK_BY_USER"
        [buttonLabel]="'Filter by user'"
        [mode]="'single'"
        (onSelect)="onSelectUsers($event)"
      ></rr-user-selector>
    </div>
    <div class="d-flex flex-row flex-grow-1 mh-0">
      <div class="w-25 d-flex flex-column">
        <div *ngIf="feedbackCount === 0 && !feedbackSearching">
          You have no {{ filter === 'UNREAD' ? 'unread' : '' }} feedback.
        </div>
        <div *ngIf="feedbackCount > 0 && !feedbackSearching">
          You have <strong>{{ feedbackCount }}</strong> {{ filter === 'UNREAD' ? 'unread' : '' }} feedbacks.
        </div>

        <ul class="list-group feedback-list flex-grow-1 overflow-auto">
          <ng-container *ngFor="let feedbackId of feedbackIdList; let i = index">
            <ng-container *ngIf="feedbackId | storeSelectPipe: feedbackSelectorFn | async as feedback">
              <button
                type="button"
                [class.active]="currentFeedback && feedback.id === currentFeedback.id"
                class="list-group-item list-group-item-action d-flex"
                (click)="selectFeedback(feedback)"
                role="button"
              >
                <rr-feedback-headline [feedback]="feedback" [currentUser]="loggedInUser"></rr-feedback-headline>
              </button>
            </ng-container>
          </ng-container>
        </ul>

        <div class="d-flex justify-content-center mt-2">
          <ngb-pagination
            *ngIf="feedbackCount > 0"
            [collectionSize]="feedbackCount"
            [maxSize]="3"
            [rotate]="true"
            [pageSize]="PAGE_SIZE"
            [ellipses]="false"
            [(page)]="currentPage"
            (pageChange)="onPageChange($event)"
          >
          </ngb-pagination>
        </div>
      </div>

      <div class="w-75 ms-2 d-flex overflow-auto">
        <ng-container *ngIf="currentFeedback && loggedInUser">
          <rr-user-feedback-detail
            class="d-flex flex-grow-1"
            [user]="loggedInUser"
            [feedback]="currentFeedback"
          ></rr-user-feedback-detail>
        </ng-container>
      </div>
    </div>
  </div>
</div>
