import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { BillingGroupEffect, fromBillingGroup } from 'app/store/billing-group';
import { BillingItemEffect } from 'app/store/billing-item';
import { BookingEffect, fromBooking, PartialBooking } from 'app/store/booking';
import { fromBookingCode } from 'app/store/booking-code';
import { fromProviderNumber } from 'app/store/provider-number';
import { ReportEffect } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { fromSite } from 'app/store/site';
import { fromUser } from 'app/store/user/user';
import { addMinutes, formatISO } from 'date-fns';
import { combineLatest, NEVER, Observable, of, Subscription } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { BookingCodeDetailComponent } from '../../../booking/components/booking-code/booking-code-detail/booking-code-detail.component';
import { BookingCodeSelectModalComponent } from '../../../booking/modals/booking-code-select-modal/booking-code-select-modal.component';
import { ScanCodeSelectModalComponent } from '../../modals/scan-code-select-modal/scan-code-select-modal.component';
import { RegistrationService } from '../../services/registration.service';
import { MedicareProviderComponent } from '../medicare-provider/medicare-provider.component';

export const REPORT_FORM_ID = 'report-form';

@Component({
  selector: 'rr-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TooltipDirective,
    FormsModule,
    ReactiveFormsModule,
    MedicareProviderComponent,
    BookingCodeDetailComponent,
  ],
  host: {
    id: REPORT_FORM_ID,
  },
})
export class ReportFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() @BindObservable() report?: RR.Report;
  report$: Observable<RR.Report | undefined>;
  @Input() @BindObservable() bookingId: number | null;
  bookingId$: Observable<number | null>;

  @Input() patient?: RR.Patient;
  @Input() referrer?: RR.Referrer;
  @Input() noReferrer: boolean;
  @Input() header: string;
  @Output() onChange = new EventEmitter<RR.Report>();

  viewMode: 'view' | 'edit' = 'view';
  sites: RR.Site[] = [];
  subscription: Subscription = new Subscription();
  bookingCode$: Observable<RR.BookingCode | undefined>;
  reportScanCode$: Observable<RR.ScanCode | undefined>;
  formScanCode$: Observable<RR.ScanCode | undefined>;
  formBookingCode$: Observable<RR.BookingCode | undefined>;
  reportBooking$: Observable<RR.Booking | undefined>;
  reportBooking: RR.Booking | undefined;
  providerNumber$: Observable<RR.ProviderNumber | undefined>;
  formProviderNumber$: Observable<RR.ProviderNumber | undefined>;

  providerNumber: RR.ProviderNumber | undefined;
  doctor: string | undefined;
  site$: Observable<RR.Site | undefined>;
  radresBooking: RR.Booking | undefined;
  booking$: Observable<RR.Booking | undefined>;

  booking: RR.Booking | undefined;

  form = new FormGroup({
    referral_date: new FormControl('', { nonNullable: true }),
    scan_code_id: new FormControl<number | undefined>(undefined, {
      validators: Validators.required,
      nonNullable: true,
    }),
    scan_code_side: new FormControl<RR.ScanCodeSide | undefined>(undefined, { nonNullable: true }),
    // Booking fields
    site_id: new FormControl<number | null>(null, { validators: Validators.required, nonNullable: true }),
    booking_code_id: new FormControl<number | undefined>(undefined, {
      validators: Validators.required,
      nonNullable: true,
    }),
    medicare_provider_id: new FormControl<number | undefined>(undefined, {
      validators: Validators.required,
      nonNullable: true,
    }),
  });

  selectedBillingItemIds: number[] = [];
  availableBillingItems: RR.BillingItem[] = [];
  funder: RR.FunderType;

  isReferralDateInFuture: boolean;
  // Method to check if the referral date is in the future
  checkReferralDate() {
    const referralDate = this.form.controls.referral_date.value;
    this.isReferralDateInFuture = new Date(referralDate as string) > new Date();
  }

  constructor(
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private messageService: MessageService,
    private bookingEffect: BookingEffect,
    private modalService: NgbModal,
    private router: Router,
    public registrationService: RegistrationService,
    private cd: ChangeDetectorRef,
    private billingItemEffect: BillingItemEffect,
    private billingGroupEffect: BillingGroupEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.report$.subscribe((report) => {
        if (report) {
          this.viewMode = 'view';
        } else {
          this.viewMode = 'edit';
        }
      }),
    );

    this.subscription.add(
      this.store.select(fromSite.selectActiveSites).subscribe((allSites) => {
        this.sites = allSites.sort((a, b) => a.id - b.id);
      }),
    );

    // Booking from bookingId param
    this.booking$ = this.bookingId$.pipe(
      switchMap((id) => (id ? this.store.select(fromBooking.selectBooking(id)) : of(undefined))),
    );

    this.subscription.add(
      this.booking$.subscribe((booking) => {
        this.booking = booking;
      }),
    );

    // The Booking saved to the Report
    this.reportBooking$ = this.report$.pipe(
      switchMap((report) => (report ? this.store.select(fromBooking.selectBooking(report.booking_id)) : of(undefined))),
    );
    this.subscription.add(
      this.reportBooking$.subscribe((reportBooking) => {
        this.reportBooking = reportBooking;
      }),
    );

    this.providerNumber$ = this.report$.pipe(
      switchMap((report) =>
        report?.medicare_provider_id
          ? this.store.select(fromProviderNumber.selectProviderNumber(report.medicare_provider_id))
          : of(undefined),
      ),
    );

    const formProviderNumber$ = this.form.controls.medicare_provider_id.valueChanges.pipe(
      switchMap((medicare_provider_id) =>
        medicare_provider_id
          ? this.store.select(fromProviderNumber.selectProviderNumber(medicare_provider_id))
          : of(undefined),
      ),
    );

    this.subscription.add(
      formProviderNumber$.subscribe((providerNumber) => {
        this.providerNumber = providerNumber;
      }),
    );

    this.subscription.add(
      formProviderNumber$
        .pipe(
          switchMap((providerNumber) =>
            providerNumber ? this.store.select(fromUser.selectUser(providerNumber.user_id)) : of(undefined),
          ),
        )
        .subscribe((user) => {
          this.doctor = user?.name;
        }),
    );

    this.site$ = this.reportBooking$.pipe(
      filterDefined(),
      switchMap((booking) => this.store.select(fromSite.selectSite(booking.site_id))),
    );

    this.bookingCode$ = this.reportBooking$.pipe(
      filterDefined(),
      switchMap((booking) => this.store.select(fromBookingCode.selectBookingCode(booking.booking_code_id))),
    );

    this.reportScanCode$ = this.report$.pipe(
      map((report) => report?.scan_code_id),
      switchMap((scan_code_id) =>
        scan_code_id ? this.store.select(fromScanCode.selectScanCode(scan_code_id)) : of(undefined),
      ),
    );

    this.formScanCode$ = this.form.controls.scan_code_id.valueChanges.pipe(
      startWith(this.form.controls.scan_code_id.value),
      switchMap(() => {
        // Unsure why the valueChanges value is sometimes the old one. Use form value instead:
        const scanCodeId = this.form.controls.scan_code_id.value;
        return scanCodeId ? this.store.select(fromScanCode.selectScanCode(scanCodeId)) : of(undefined);
      }),
    );

    this.formBookingCode$ = this.form.controls.booking_code_id.valueChanges.pipe(
      startWith(this.form.controls.booking_code_id.value),
      switchMap(() => {
        const booking_code_id = this.form.controls.booking_code_id.value;
        return booking_code_id ? this.store.select(fromBookingCode.selectBookingCode(booking_code_id)) : of(undefined);
      }),
    );

    this.formProviderNumber$ = this.form.controls.medicare_provider_id.valueChanges.pipe(
      startWith(this.form.controls.medicare_provider_id.value),
      switchMap(() => {
        const medicare_provider_id = this.form.controls.medicare_provider_id.value;
        return medicare_provider_id
          ? this.store.select(fromProviderNumber.selectProviderNumber(medicare_provider_id))
          : of(undefined);
      }),
    );

    this.subscription.add(
      this.store
        .select(fromBillingGroup.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.billingGroupEffect.findAll();
            } else {
              return NEVER;
            }
          }),
        )
        .subscribe(),
    );

    this.subscription.add(this.billingItemEffect.findAll().subscribe());

    this.subscription.add(
      this.registrationService.bookingData.subscribe((data) => {
        this.selectedBillingItemIds = data.billing_items;
      }),
    );

    this.subscription.add(
      this.formBookingCode$
        .pipe(
          filterDefined(),
          switchMap((bookingCode) =>
            bookingCode.scan_code_id
              ? this.store.select(fromScanCode.selectBillingItems(bookingCode.scan_code_id))
              : of([]),
          ),
        )
        .subscribe((billingItems) => {
          this.availableBillingItems = billingItems;
        }),
    );

    this.subscription.add(
      this.registrationService.bookingData.subscribe((data) => {
        this.funder = data.funder;
      }),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      combineLatest([this.report$, this.reportBooking$, this.booking$]).subscribe(
        ([report, reportBooking, booking]) => {
          if (!report) {
            if (booking) {
              this.radresBooking = booking;
              this.patchReportFormWithBooking(booking);
            }
          } else {
            this.patchReportForm(report);
            if (reportBooking) this.patchReportFormWithBooking(reportBooking, report);
          }

          // To satisfy ng0100 error
          this.cd.detectChanges();
        },
      ),
    );
  }

  patchReportForm(report: Partial<RR.Report>) {
    this.form.patchValue({
      referral_date: report.referral_date || '',
      scan_code_id: report.scan_code_id ?? undefined,
      scan_code_side: report.scan_code_side || undefined,
      medicare_provider_id: report.medicare_provider_id ?? undefined,
    });
  }

  patchReportFormWithBooking(booking: RR.Booking, report?: RR.Report) {
    if (booking.booking_code_id) {
      this.subscription.add(
        this.store
          .select(fromBookingCode.selectBookingCode(booking.booking_code_id))
          .pipe(filterDefined(), take(1))
          .subscribe((bookingCode) => {
            // if report doesn't have scan_code_id, use booking
            if (report && !report.scan_code_id && bookingCode.scan_code_id) {
              this.form.patchValue({
                site_id: booking.site_id,
                booking_code_id: booking.booking_code_id,
                scan_code_id: bookingCode.scan_code_id,
              });
              // if report has scan_code_id, use report
            } else if (report && report.scan_code_id) {
              this.form.patchValue({
                site_id: booking.site_id,
                booking_code_id: booking.booking_code_id,
                scan_code_id: report.scan_code_id,
              });
              // if no report, use booking
            } else {
              this.form.markAsDirty();
              this.form.patchValue({
                site_id: booking.site_id,
                booking_code_id: booking.booking_code_id,
                scan_code_id: bookingCode.scan_code_id,
              });
            }
          }),
      );
    } else {
      this.form.patchValue({
        site_id: booking.site_id,
      });
    }
    this.formValidation();
  }

  formValidation() {
    // This shows the inline errors messages
    this.form.markAllAsTouched();
  }

  editBooking() {
    this.formValidation();
    this.viewMode = 'edit';
  }

  cancel() {
    this.form.markAsPristine();
    this.viewMode = 'view';
    if (this.report) {
      this.patchReportForm(this.report);
      this.registrationService.emitBooking({ billing_items: this.reportBooking?.billing_items });
    }

    if (this.booking) {
      this.registrationService.emitBooking({ billing_items: this.booking.billing_items });
    }
  }

  submitReport() {
    this.formValidation();
    // Check input data
    if (!this.form.valid) {
      this.messageService.add({
        title: 'Error',
        message: 'Some fields are still invalid',
        type: 'danger',
      });
      return;
    }

    const bookingToUpdate = this.reportBooking ? this.reportBooking : this.radresBooking;
    if (bookingToUpdate) {
      // Update report or register a new report from booking
      const changes: Partial<RR.Booking> = {};
      if (bookingToUpdate.booking_code_id !== this.form.controls.booking_code_id.value) {
        changes.booking_code_id = this.form.controls.booking_code_id.value || undefined;
      }
      if (bookingToUpdate.site_id !== this.form.controls.site_id.value) {
        changes.site_id = this.form.controls.site_id.value || undefined;
      }
      if (!!this.patient?.id && bookingToUpdate.patient_id !== this.patient.id) {
        changes.patient_id = this.patient.id;
      }
      if (bookingToUpdate.billing_items[0] !== this.selectedBillingItemIds[0]) {
        changes.billing_items = this.selectedBillingItemIds;
      }

      if (Object.keys(changes).length > 0) {
        this.subscription.add(this.bookingEffect.update(bookingToUpdate.id, changes).subscribe());
      }
      this.createOrUpdateReport(bookingToUpdate.id);
    } else {
      // Third: No radresBooking or reportBooking => Create a walk-in booking
      const booking: PartialBooking = {
        booking_code_id: this.form.controls.booking_code_id.value || undefined,
        patient_id: this.patient?.id,
        type: 'walkin',
        start_time: formatISO(new Date(), { representation: 'complete' }),
        end_time: formatISO(addMinutes(new Date(), 20), { representation: 'complete' }),
        site_id: this.form.controls.site_id.value || undefined,
        referrer_type: this.referrer?.referrer_type,
        funder: this.funder,
      };

      this.subscription.add(
        this.bookingEffect
          .create({
            booking,
            billing_items: this.selectedBillingItemIds,
          })
          .pipe(take(1))
          .subscribe((action) => {
            this.createOrUpdateReport(action.booking.id);
          }),
      );
    }
  }

  createOrUpdateReport(booking_id: number | undefined) {
    if (this.report) {
      const changes: Partial<RR.Report> = {
        referral_date: this.form.controls.referral_date.value || undefined,
        scan_code_id: this.form.controls.scan_code_id.value || null,
        scan_code_side: this.form.controls.scan_code_side.value || null,
        medicare_provider_id: this.form.controls.medicare_provider_id.value || null,
      };
      if (booking_id) changes.booking_id = booking_id;
      this.updateReport(this.report.id, changes);
    } else {
      const newReport: Partial<RR.Report> = {
        patient_id: this.patient?.id,
        referrer_id: this.referrer?.id,
        // Booking id either come form prefilling booking or the new walkin booking created
        booking_id: booking_id,
        referral_date: this.form.controls.referral_date.value || undefined,
        scan_code_id: this.form.controls.scan_code_id.value || null,
        scan_code_side: this.form.controls.scan_code_side.value || null,
        medicare_provider_id: this.form.controls.medicare_provider_id.value || null,
      };
      this.createReport(newReport);
    }
  }

  updateReport(id: number, changes: Partial<RR.Report>) {
    this.subscription.add(
      this.reportEffect
        .update(id, changes)
        .pipe(take(1))
        .subscribe({
          next: (action) => {
            const report = action.actions.updateReportSuccess.report;
            this.postSubmitReportSuccess(report, false);
          },
        }),
    );
  }

  createReport(newReport: Partial<RR.Report>) {
    this.subscription.add(
      this.reportEffect
        .create(newReport)
        .pipe(take(1))
        .subscribe({
          next: (action) => {
            const report = action.report;
            this.postSubmitReportSuccess(report, true);
          },
        }),
    );
  }

  postSubmitReportSuccess(report: RR.Report, created = true) {
    this.form.markAsPristine();
    this.messageService.add({
      title: 'Success',
      message: created ? 'Create new report successfully!' : 'Update report successfully!',
      type: 'success',
    });
    // document.getElementById('patient-form').scrollIntoView({ behavior: 'smooth', block: 'start' });
    // Emit report to parent component after registering
    this.onChange.emit(report);
    if (created) {
      // This use queryParams to update the report.id in the URL without "navigating" and destroying the
      // RegistrationComponent
      this.router.navigate(['registration', 'report'], {
        queryParams: {
          reportId: report.id,
        },
      });
    }
    this.viewMode = 'view';
  }

  selectBookingCode() {
    // Reset the billing item selection whenever the booking code is updated
    this.registrationService.emitBooking({ billing_items: [] });
    const modalRef = BookingCodeSelectModalComponent.open(this.modalService);
    modalRef.result.then(
      (id: number) => {
        this.form.markAsDirty();
        this.form.controls.booking_code_id.patchValue(id);
      },
      () => {
        // Do nothing
      },
    );
  }

  onBillingItemChange(billingItemIds: number[]) {
    // this.selectedBillingItemIds = billingItemIds;
    this.registrationService.emitBooking({ billing_items: billingItemIds });
  }

  selectScanCode() {
    const modalRef = ScanCodeSelectModalComponent.open(this.modalService);
    modalRef.result.then(
      (id: number) => {
        this.form.controls.scan_code_id.patchValue(id);
        this.form.markAsDirty();
        this.subscription.add(
          this.store
            .select(fromScanCode.selectScanCode(id))
            .pipe(take(1))
            .subscribe((scanCode) => {
              if (!this.form.controls.booking_code_id.value && scanCode && scanCode.booking_codes.length > 0) {
                // Default to first booking code that linked to scan code
                this.form.controls.booking_code_id.patchValue(scanCode.booking_codes[0]);
              }
              // Patch value for report scan code side
              if (scanCode?.has_side) {
                if (!this.form.controls.scan_code_side.value) {
                  // Default to left if scan code has side and no side selected for the report
                  this.form.controls.scan_code_side.patchValue('left');
                }
              } else {
                if (this.form.controls.scan_code_side.value) {
                  this.form.controls.scan_code_side.patchValue(undefined);
                }
              }
            }),
        );
      },
      () => {
        // Do nothing
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
