<div class="modal-header cursor-move" cdkDrag cdkDragHandle cdkDragRootElement=".modal-content">
  <div>
    <h5 class="modal-title">Navigator ($)</h5>
    <rr-report-headline [reportId]="report_id"></rr-report-headline>
    <div (click)="activeModal.dismiss()">
      <rr-same-patient-reports [reportId]="report_id" [topicId]="topic_id"></rr-same-patient-reports>
    </div>
  </div>

  <button role="button" class="btn-close" rrTooltip="NAVIGATOR_CLOSE_BUTTON" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body d-flex flex-column overflow-auto">
  <div class="d-flex alert alert-warning" role="alert" *ngIf="showReportChangedWarning">
    <h4>
      <span class="text-warning me-2"><i class="fas fa-exclamation-triangle"></i></span>
      This report has been completed and saved to Voyager, do you really want to edit this report?
    </h4>
    <div>
      <button class="btn btn-outline-primary" role="button">
        <rr-worklist-link>Escape to worklist (Esc)</rr-worklist-link>
      </button>
    </div>
  </div>
  <div *ngIf="activeTopics.length > 1">
    <div class="d-flex flex-column alert alert-warning" role="alert">
      <h4>
        <span class="text-warning me-2"><i class="fas fa-exclamation-triangle"></i></span>
        This report has multiple <strong>templates</strong>
      </h4>
      <ul class="list-group my-2">
        <ng-container *ngFor="let activeTopic of activeTopics">
          <button
            role="button"
            class="list-group-item list-group-item-action list-group-item-light d-flex"
            [class.active]="topic === activeTopic"
            rrTooltip="REORDER_STUDY_TITLE"
            (click)="setTopicOpen(activeTopic.id)"
          >
            <rr-template-headline
              *ngIf="activeTopic.template_id | storeSelectPipe: templateSelectorFn | async as template"
              [template]="template"
            ></rr-template-headline>
            <strong class="ms-auto" *ngIf="!activeTopic.title_option_id">No Title Selected</strong>
          </button>
        </ng-container>
      </ul>
    </div>
  </div>
  <rr-duplicate-patient-warning [reportId]="report.id"></rr-duplicate-patient-warning>

  <ng-container>
    <button
      (click)="setPrefillType('PRESET')"
      class="btn btn-outline-primary my-1"
      rrTooltip="PREFILL_PRESET"
      role="button"
      [rrTrack]="'Prefill Presets - Quick Nav'"
    >
      <span class="fas fa-filter me-1"></span>Prefill Presets (F)
    </button>
    <button (click)="openNextReportModal()" class="btn btn-outline-primary my-1">Next Case</button>
    <button
      (click)="toggleSidebar()"
      class="btn btn-outline-primary my-1"
      rrTooltip="OPEN_SIDEBAR"
      role="button"
      [rrTrack]="'Open Sidebar - Quick Nav'"
    >
      <span class="fas fa-compass me-1"></span>Open Sidebar (!)
    </button>
    <rr-document-attachment-button
      [bookingId]="report.booking_id"
      [size]="'lg'"
      [btnClassName]="'btn-outline-primary'"
      [dismissActiveModal]="true"
      [expand]="true"
      class="my-1"
    ></rr-document-attachment-button>
    <button
      #backToPrefill
      role="button"
      class="btn btn-outline-primary my-1"
      (click)="openPrefill()"
      rrTooltip="NAVIGATOR_PREFILL"
    >
      <span>Back to Prefill (B)</span>
    </button>
    <button
      (click)="openCheckReport()"
      class="btn btn-outline-primary my-1"
      rrTooltip="NAVIGATOR_CHECK_REPORT"
      role="button"
    >
      <span class="fas fa-binoculars me-1"></span>Check Report (C)
    </button>
    <button
      (click)="openPatientInformation()"
      class="btn btn-outline-primary my-1"
      rrTooltip="PATIENT_INFO"
      role="button"
    >
      <span class="fa fa-file-import me-1"></span><strong>{{ relatedReports.length }}</strong> Prior Related Reports in
      Demographics (D)
    </button>
    <button
      class="my-1"
      rr-add-other-imaging-button
      [topic]="topic"
      [report]="report"
      [btnNav]="true"
      rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
      (click)="activeModal.dismiss()"
    ></button>
    <button (click)="openDicomSR()" class="btn btn-outline-primary my-1" rrTooltip="SHOW_DICOM_SR_TABLE" role="button">
      <span class="fas fa-ruler-horizontal me-1"></span>Dicom SR Length (L)
    </button>
    <button
      #imgSim
      (click)="openImgsimParameters()"
      [ngClass]="
        (imgsimParametersAvailable$ | async)
          ? 'btn btn-outline-primary my-1 btn-warning flash'
          : 'btn btn-outline-primary my-1'
      "
      rrTooltip="SHOW_IMG_SIMILARITY_PARAMETERS"
      role="button"
    >
      <span class="fas fa-robot me-1"></span>Image Similarity Parameters (^)
    </button>
    <button
      (click)="openNotes(); activeModal.dismiss()"
      rrTooltip="VOICE_NOTES_AUTO_OPEN"
      role="button"
      class="btn btn-outline-primary my-1"
    >
      <span class="fas fa-microphone me-1"></span>Voice Notes and Start Listening
    </button>
    <rr-report-notes-button
      [report_id]="report_id"
      [btnClass]="'btn-outline-primary my-1'"
      [expand]="true"
      [small]="false"
      (click)="activeModal.dismiss()"
    ></rr-report-notes-button>
    <button (click)="openSendReport()" class="btn btn-outline-primary my-1" rrTooltip="DISTRIBUTE_REPORT" role="button">
      <span class="fas fa-paper-plane me-1"></span>Distribute Report (R)
    </button>
    <button
      (click)="sign()"
      class="btn btn-outline-primary my-1"
      rrTooltip="NAVIGATOR_SIGN"
      role="button"
      *ngIf="isDoctorEditing() | async"
    >
      <span class="fa fa-file-signature me-1"></span>Sign (S)
    </button>
    <button #viewAndExport role="button" class="btn btn-outline-primary my-1" (click)="openFinalReport()">
      View and Export (X)
    </button>
    <button
      class="btn my-1"
      [ngClass]="!!report.patient_answer_ids.length ? 'btn-success' : 'btn-outline-primary'"
      (click)="openPatientQuestionnaireModal()"
      rrTooltip="OPEN_PATIENT_QUESTIONNAIRE"
    >
      <span class="fa fa-book-medical"></span> Open Registration Questions (Y)
    </button>
    <!--
      TODO: cloned signatures intended as a temporary solution to:
        https://gitlab.fluidsolar.com.au/adi/radreport/-/issues/4594
    -->
    <!--Junior signature-->
    <rr-signature
      (click)="activeModal.dismiss()"
      [topic]="topic"
      [mode]="'editor'"
      [signature_role]="'JUNIOR_TECHNICIAN'"
      class="my-1"
    ></rr-signature>
    <!--Senior signature-->
    <rr-signature
      (click)="activeModal.dismiss()"
      [topic]="topic"
      [mode]="'editor'"
      [signature_role]="'TECHNICIAN'"
      class="my-1"
    ></rr-signature>
    <!--Radiology Registrar signature-->
    <rr-signature
      (click)="activeModal.dismiss()"
      [topic]="topic"
      [mode]="'editor'"
      [signature_role]="'RADIOLOGY_REGISTRAR'"
      class="my-1"
    >
    </rr-signature>
    <!--Doctor signature-->
    <rr-signature
      (click)="activeModal.dismiss()"
      [topic]="topic"
      [mode]="'editor'"
      [signature_role]="'DOCTOR'"
      class="my-1 w-100"
    ></rr-signature>
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-light" rrTooltip="NAVIGATOR_GO_BACK_TO_WORKLIST" role="button">
    <rr-worklist-link>Escape to worklist ( <kbd>Shift</kbd> + <kbd>Esc</kbd> )</rr-worklist-link>
  </button>
</div>
