<div class="modal-header">
  <h5 class="modal-title" *ngIf="!affectedTemplates">Element used</h5>
  <h5 class="modal-title" *ngIf="affectedTemplates">
    Element <b *ngIf="statement_set">({{ statement_set.name }})</b
    ><b *ngIf="statement_set_count">({{ statement_set_count.name }})</b> used in
    <strong>{{ affectedTemplates.length }}</strong> visible templates
  </h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <ul class="mb-0">
    <li *ngFor="let template of affectedTemplates">{{ template.modality.name }} - {{ template.name }}</li>
  </ul>
</div>

<div class="modal-footer">
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
