import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.BookingRate>();
const selectFeature = (state: AppState) => state.booking_rate;

// The @ngrx/entity adapter provides a number of selectors for u
const { selectEntities } = adapter.getSelectors(selectFeature);

const selectBookingRate = (id: number) => createSelector(selectEntities, (bookingRates) => bookingRates[id]);

export const fromBookingRate = {
  selectBookingRate,
  selectEntities,
};
