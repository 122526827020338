import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ReferrerNoteCategoryHttpService {
  constructor(private http: HttpClient) {}

  create(referrerNoteCategory: Partial<RR.NoteCategory>): Observable<RR.NoteCategory> {
    return this.http.post<RR.NoteCategory>('/api/referrer_note_category', referrerNoteCategory);
  }

  delete(referrerNoteCategoryId: number): Observable<number> {
    return this.http.delete<number>(`/api/referrer_note_category/${referrerNoteCategoryId}`);
  }
}
