import { Injectable } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ModalTimerService {
  getLastOpenedTime(modalId: string): Date | undefined {
    const lastOpenedTime: string | null = localStorage.getItem('modalLastOpened_' + modalId);
    if (lastOpenedTime === null) {
      return undefined;
    }
    return new Date(lastOpenedTime);
  }

  setLastOpenedTime(modalId: string, date: Date): void {
    localStorage.setItem('modalLastOpened_' + modalId, date.toString());
  }

  hasModalIntervalExpired(modalId: string): boolean {
    const lastOpenedTime = this.getLastOpenedTime(modalId);
    if (lastOpenedTime === undefined) {
      return true; // if the modal has never been opened, it should be opened
    }

    const currentTime = new Date();
    return differenceInMinutes(currentTime, lastOpenedTime) > 60;
  }
}
