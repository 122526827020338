import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromDocument } from '../report/document/document.selector';
import { fromReport } from '../report/report/report.selector';

const adapter = createEntityAdapter<RR.Booking>();
const selectFeature = (state: AppState) => state.booking;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectBooking = (booking_id: number) =>
  createSelector(selectEntities, (entities: Dictionary<RR.Booking>) => entities[booking_id]);

/**
 * Select bookings from search result's ids to display in booking list or booking timeline
 * @param bookingIds
 * @returns
 */
const selectBookings = (bookingIds: number[]) =>
  createSelector(
    selectEntities,
    (entities: Dictionary<RR.Booking>) => bookingIds.map((id) => entities[id]).filter((b) => !!b) as RR.Booking[],
  );

const selectDocuments = (bookingId: number) =>
  createSelector(
    selectBooking(bookingId),
    fromDocument.selectEntities,
    (booking: RR.Booking | undefined, documents: Dictionary<RR.Document>) =>
      (booking?.documents || []).map((documentId) => documents[documentId]),
  );

const selectReports = (bookingId: number) =>
  createSelector(fromReport.selectAll, (reports) => reports.filter((r) => r.booking_id === bookingId));

export const fromBooking = {
  selectIds,
  selectEntities,
  selectAll,
  selectBooking,
  selectBookings,
  selectDocuments,
  selectReports,
};
