/**
 * Inspiration:
 * https://github.com/xipheCom/ngrx-batch-action-reducer/blob/master/src/index.ts
 * https://github.com/tshelburne/redux-batched-actions/blob/master/src/index.js
 * https://gitlab.com/linagora/petals-cockpit/blob/master/frontend/src/app/shared/helpers/batch-actions.helper.ts
 */

import { Action, ActionCreator, NotAllowedCheck } from '@ngrx/store';
import { ActionCreatorProps, ActionReducer, Creator } from '@ngrx/store/src/models';

interface BatchAction extends Action {
  isBatchAction: true; // type is `true`, not `boolean`
  actions: Record<string, Action | BatchAction>;
}

/**
 * Allows you to call multiple reducers in a single action, without race conditions.
 *
 * Example:
 *
 * ```ts
 * export const createStatementSuccessBatch = createBatchAction(
 *   '[Statement] Create Success Batch',
 *   props<{
 *     actions: {
 *       statementCreateSuccess: ReturnType<typeof StatementActions.createSuccess>;
 *       statementSetUpdateSuccess: ReturnType<typeof StatementSetActions.updateSuccess>;
 *     };
 *   }>(),
 * );
 *
 * let action = createStatementSuccessBatch({
 *   actions: {
 *     statementCreateSuccess: StatementActions.createSuccess(exampleData1),
 *     statementSetUpdateSuccess: StatementSetActions.updateSuccess(exampleData2),
 *   },
 * });
 *
 *
 * // Then when referencing this action it is typed correctly, for example:
 * let statement = action.actions.statementCreateSuccess.statement;
 * ```
 */
export function createBatchAction<
  T extends string,
  P extends {
    actions: {
      [key: string]: ReturnType<ActionCreator<string, Creator>>;
    };
  },
>(type: T, _config: ActionCreatorProps<P> & NotAllowedCheck<P>) {
  // See the definition of createAction() in ngrx: https://github.com/ngrx/platform/blob/0b63a174b128d69394737fa127f9277b1a3b33f9/modules/store/src/action_creator.ts#L101
  // @ts-expect-error noImplicitAny
  const creator = (props): BatchAction => ({
    ...props,
    type,
    isBatchAction: true,
  });
  // This is the same magic that createAction() uses, the only difference is the `& BatchAction`.
  // The result is a function that also has a property called type. It behaves like both a function and an object.
  return Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  }) as ActionCreator<T, (props: P & NotAllowedCheck<P>) => P & Action<T> & BatchAction>;
}
export function enableBatching<S>(reduce: ActionReducer<S>): ActionReducer<S> {
  // @ts-expect-error strictNullChecks
  return function batchingReducer(state: S, action: Action | BatchAction): S {
    const nextState = reduce(state, action);
    // This narrows the type
    if ('isBatchAction' in action) {
      // from createBatchAction. actions is an object and the values are the Actions
      return Object.values(action.actions).reduce(batchingReducer, nextState);
      // Above allows nesting of batch actions. Simple version is below.
      // .reduce(reduce, nextState)
    } else {
      return nextState;
    }
  };
}
