<div placement="bottom">
  <input
    rrVoice
    [speechSynthesis]="true"
    [keydownStopListening]="false"
    #dateInput
    class="form-control date-attribute-input"
    [placeholder]="placeholder"
    [formControl]="dateAttribute"
    (keydown)="handleKeyPress($event)"
    (blur)="onBlur()"
    ngbDatepicker
    [value]="dateAttribute.value"
  />
</div>
