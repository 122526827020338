import { createActionGroup, props } from '@ngrx/store';

/**
 * Ngrx Actions for RegionChoice
 *
 */
export const RegionChoiceActions = createActionGroup({
  source: 'Region Choice',
  events: {
    addMany: props<{ regionChoices: RR.RegionChoice[] }>(),
    upsertMany: props<{ regionChoices: RR.RegionChoice[] }>(),
    removeMany: props<{ regionChoiceIds: number[] }>(),
  },
});
