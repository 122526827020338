import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReportAccessEvent } from 'app/store/report/access-event';
import { Subscription } from 'rxjs';

import { AccessEventSummaryComponent } from '../access-event/access-event-summary/access-event-summary.component';
import { AccessEventComponent } from '../access-event/access-event.component';

declare type AccessEventListParent = 'WORKLIST' | 'DEMOGRAPHICS' | 'DOCTOR_WORKLIST' | 'USER_FEEDBACK';

@Component({
  selector: 'rr-access-event-list',
  standalone: true,
  imports: [SharedModule, AccessEventComponent, AccessEventSummaryComponent],
  templateUrl: './access-event-list.component.html',
  styleUrls: ['./access-event-list.component.css'],
})
export class AccessEventListComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @Input() parent: AccessEventListParent;
  showAllEditors = false;
  accessEvents: RR.ReportAccessEvent[] = [];
  visibleAccessEvents: RR.ReportAccessEvent[] = [];
  maxEditors = 2;

  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    if (this.parent === 'DEMOGRAPHICS') {
      this.showAllEditors = true;
    }

    this.subscription.add(
      this.store.select(fromReportAccessEvent.selectAccessEventsInReport(this.report.id)).subscribe((accessEvents) => {
        this.accessEvents = accessEvents;
        // don't show "1 hidden", only hide 2 or more
        // For example, MAX_EDITORS=2:
        //    if number of editors is 3, then show all 3
        //    if number of editors is 4, then show 2 and hide 2
        //    if number of editors is 5, then show 2 and hide 3
        //    ...etc
        this.visibleAccessEvents = accessEvents.slice(
          0,
          accessEvents.length === this.maxEditors + 1 ? this.maxEditors + 1 : this.maxEditors,
        );
      }),
    );
  }

  toggleReportEditors() {
    this.showAllEditors = !this.showAllEditors;
  }
}
