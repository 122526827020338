import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined, truncateText } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { CorrelatedStatementScore } from 'app/store/correlated-statement-search';
import { Breadcrumb } from 'app/store/editor';
import { fromTopic, fromTopicChoices } from 'app/store/report/topic';
import { fromRegion } from 'app/store/template/region';
import { fromRegionSet } from 'app/store/template/region-set';
import { fromStatementSet } from 'app/store/template/statement-set';
import { combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';

import { SharedModule } from '../../../../shared/shared.module';
import { StatementConditionsModalComponent } from '../../statement-edit/statement-conditions-modal/statement-conditions-modal';
import { StatementComponent } from '../../statement/statement.component';

@Component({
  selector: 'rr-recommended-statement',
  templateUrl: './recommended-statement.component.html',
  styleUrls: ['./recommended-statement.component.css'],
  standalone: true,
  imports: [SharedModule, FormsModule, forwardRef(() => StatementComponent), CommonModule],
})
export class RecommendedStatementComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  @Input() statement: RR.Statement;
  @Input() proposed?: RR.ProposedStatement | undefined;
  @Input() context?: 'IMGSIM' | 'MAIN_EDITOR' | undefined;
  @Input() correlationScore?: CorrelatedStatementScore[] | undefined;
  @Input() truncate = true;
  @Input() region?: RR.Region;
  selectedRegion?: RR.Region;
  section: RR.Section;
  subsection: RR.Subsection;
  element: RR.Element;
  elementChoice: RR.ElementChoice;
  breadcrumb$: Observable<Breadcrumb>;
  subscription = new Subscription();
  topicLoaded$: Observable<boolean>;

  constructor(
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private templateService: TemplateService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    const parents$ = this.loadStatementParents(this.statement.id).pipe(filterDefined());
    this.subscription.add(
      parents$.subscribe((element) => {
        this.element = element.element;
        this.subsection = element.subsection;
        this.section = element.section;
      }),
    );

    this.topicLoaded$ = this.topic$.pipe(
      switchMap((topic) => this.store.select(fromTopic.selectLoaded(topic.id))),
      skipWhile((loaded) => !loaded),
    );

    if (!this.subsection.region_set_id) {
      // No Regions
      if (this.region) {
        throw new Error('Invalid Input, this Subsection does not have Regions');
      }
      this.subscription.add(this.selectElementChoices({ regionId: null }).subscribe());
    } else {
      // Has Regions
      if (this.region) {
        this.selectedRegion = this.region;
        this.subscription.add(this.selectElementChoices({ regionId: this.selectedRegion.id }).subscribe());
      } else {
        this.breadcrumb$ = this.editorService.getBreadcrumb(this.topic.id);
        const firstRegion$ = this.store.select(fromRegionSet.selectRegions(this.subsection.region_set_id)).pipe(
          map((regions) => {
            if (regions && regions.length > 0) {
              return regions[0].id;
            }
            return null;
          }),
        );

        const combine$ = combineLatest([this.breadcrumb$, firstRegion$]);
        this.subscription.add(
          combine$
            .pipe(
              switchMap(([breadcrumb, first_region_id]) => {
                const regionId = breadcrumb.region_id || first_region_id;
                if (regionId) {
                  return this.store.select(fromRegion.selectRegion(regionId)).pipe(filterDefined());
                }
                return EMPTY;
              }),
            )
            .subscribe((region) => {
              this.selectedRegion = region;
              this.cd.markForCheck();
            }),
        );

        this.subscription.add(
          combine$
            .pipe(
              switchMap(([breadcrumb, first_region_id]) => {
                const regionId = breadcrumb.region_id || first_region_id;
                return this.selectElementChoices({ regionId: regionId });
              }),
            )
            .subscribe(),
        );
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  jumpToStatement() {
    const regionId = this.selectedRegion ? this.selectedRegion.id : undefined;
    requestAnimationFrame(() => {
      this.editorService.publishFocus({
        statement_id: this.statement.id,
        element_id: this.element.id,
        region_id: regionId,
      });
    });
  }

  getElementName(element: RR.Element): Observable<string> {
    return this.store.select(fromStatementSet.selectStatementSetName(element.statement_set_id));
  }

  getRegions(regionSetId: number) {
    return this.store.select(fromRegionSet.selectRegions(regionSetId));
  }

  getShortSectionName(section: RR.TemplateSection) {
    switch (section) {
      case 'history': {
        return 'His';
      }
      case 'technique': {
        return 'Tech';
      }
      case 'findings': {
        return 'Find';
      }
      case 'comment': {
        return 'Com';
      }
      case 'impression_recommendations': {
        return 'I&R';
      }
    }
  }

  openConditionModal() {
    StatementConditionsModalComponent.open({
      modalService: this.modalService,
      statementId: this.statement.id,
      templateId: this.topic.template_id,
      region: this.selectedRegion,
      proposed: this.proposed ? this.proposed.proposed : undefined,
    });
  }

  truncateText(text: string) {
    if (!this.truncate) return text;
    return truncateText(text, 20);
  }

  getStatementElement(statement_id: number) {
    return this.templateService
      .getStatementElements(statement_id, this.topic.template_id)
      .pipe(map((elements) => elements[0]));
  }

  loadStatementParents(statementId: number) {
    const element$ = this.getStatementElement(statementId);
    return element$.pipe(
      switchMap((element) => {
        return this.templateService.getElements(this.topic.template_id).pipe(
          map((elements) => {
            return elements.find((el) => el.element.id === element.id);
          }),
        );
      }),
    );
  }

  onRegionChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const regionId = target.value ? Number(target.value) : null;

    if (regionId) {
      this.subscription.add(
        this.store
          .select(fromRegion.selectRegion(regionId))
          .pipe(filterDefined())
          .subscribe((region) => {
            this.selectedRegion = region;
          }),
      );
      this.subscription.add(this.selectElementChoices({ regionId: regionId }).subscribe());
    }
  }

  selectElementChoices({ regionId }: { regionId: number | null }) {
    return this.topicLoaded$.pipe(
      switchMap(() =>
        this.store.select(
          fromTopicChoices.selectElementChoices(
            this.topic.id,
            this.section.id,
            this.subsection.id,
            regionId,
            this.element.id,
          ),
        ),
      ),
      tap((elementChoices) => {
        this.elementChoice = elementChoices[0];
        this.cd.detectChanges();
      }),
    );
  }
}
