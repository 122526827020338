<button
  (click)="openDocumentAttachmentModal()"
  class="btn"
  [class.btn-sm]="size === 'sm'"
  [class.w-100]="expand"
  [class.sidebar-btn]="sidebar"
  [ngClass]="hasDocument ? btnSuccessClass || 'btn-success' : btnClassName"
  role="button"
  rrTooltip="OPEN_DOCUMENT_ATTACHMENT"
>
  <ng-container *ngIf="!sidebar">
    <span class="fas fa-paperclip" [class.me-2]="showText"></span> <span *ngIf="showText">Attachments (+)</span>
  </ng-container>
  <ng-container *ngIf="sidebar">
    <span>(+) Attachments</span>
  </ng-container>
</button>
