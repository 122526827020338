import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { AddOtherImagingButtonComponent } from 'app/modules/editor/add-other-imaging-button/add-other-imaging-button.component';
import { NameFavouriteModalComponent } from 'app/modules/editor/prefill-button/name-favourite-modal.component';
import { SignatureModalComponent } from 'app/modules/report/components/preview/signature-modal/signature-modal.component';
import { ReportHeadlineComponent } from 'app/shared/components/report-headline/report-headline.component';
import { ReportNotesButtonComponent } from 'app/shared/components/report-notes-button/report-notes-button.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { HasErrorsPipe } from 'app/shared/pipes/has-errors';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromFavourite } from 'app/store/report/favourite';
import { fromReport, ReportEffect, ReportHttpService } from 'app/store/report/report';
import { Observable, of, Subscription } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { AddOtherImagingComponent } from '../../../../editor/add-other-imaging/add-other-imaging.component';
import { SendReportButtonComponent } from '../../send-report/send-report-button/send-report-button.component';
import { SendVoyagerButtonComponent } from '../../send-report/send-voyager-button/send-voyager-button.component';
import { CheckReportModalComponent } from '../check-report-modal/check-report-modal.component';
import { ReportPreviewComponent } from '../report-preview/report-preview.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReportHeadlineComponent,
    SendVoyagerButtonComponent,
    SendReportButtonComponent,
    forwardRef(() => AddOtherImagingButtonComponent),
    ReportNotesButtonComponent,
    AutoFocusDirective,
    ReportPreviewComponent,
    SharedModule, // For PermissionDirective
    HasErrorsPipe,
    AddOtherImagingComponent,
  ],
  selector: 'rr-final-preview-modal',
  templateUrl: './final-preview-modal.component.html',
  styleUrls: ['./final-preview-modal.component.css'],
})
export class FinalPreviewModalComponent implements OnInit, OnDestroy {
  report_id: number;
  topic_id: number;
  report$: Observable<RR.Report>;
  topic$: Observable<RR.Topic>;
  @Input() mode: PreviewMode;
  showReportUI = false;
  favourite: RR.Favourite;
  subscription: Subscription = new Subscription();
  tags$: Observable<RR.Divider[]>;
  fetchingReportText = true;
  reportSendStatus: RR.ReportSendStatus;
  delaySentButtonFocus = true;
  sentToVoyager = false;

  // Show warning if any topic is still missing
  reportError: {
    signature: boolean;
    title: boolean;
    unfilled_number: boolean;
    incorrect_side: boolean;
    incorrect_sex: boolean;
  };

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private reportEffect: ReportEffect,
    private editorService: EditorService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private messageService: MessageService,
    private reportHttpService: ReportHttpService,
  ) {}

  ngOnInit() {
    this.topic$ = this.reportService.getTopic(this.topic_id);
    this.report$ = this.store.select(fromReport.selectReport(this.report_id)).pipe(filterDefined());

    // Automatically open the signature modal if the doctor's signature is missing
    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) =>
            this.reportService.isDoctorEditing().pipe(
              take(1),
              map((isDoctor) => ({ topic, isDoctor })),
            ),
          ),
          take(1),
        )
        .subscribe(({ topic, isDoctor }) => {
          if (!topic.signature_text && isDoctor) {
            this.openSignature();
          }
        }),
    );

    this.subscription.add(
      this.reportHttpService
        .checkReport(this.report_id)
        .pipe(
          take(1),
          map((result) =>
            result.topics.reduce(
              (acc, checkTopic) => {
                acc.signature = acc.signature || checkTopic.missing_signature;
                acc.title = acc.title || checkTopic.missing_title;
                acc.unfilled_number = acc.unfilled_number || checkTopic.unfilled_number;
                acc.incorrect_side = acc.incorrect_side || checkTopic.incorrect_side;
                acc.incorrect_sex = acc.incorrect_sex || checkTopic.incorrect_sex;
                return acc;
              },
              {
                signature: false,
                title: false,
                unfilled_number: false,
                incorrect_side: false,
                incorrect_sex: false,
              },
            ),
          ),
        )
        .subscribe((errors) => {
          this.reportError = errors;
        }),
    );

    // Using set timeout to avoid error: Expression has been changed after checked
    setTimeout(() => {
      this.fetchReportText();
    });
    this.subscription.add(
      this.reportService
        .getTopic(this.topic_id)
        .pipe(
          switchMap((topic) =>
            topic.favourite_id ? this.store.select(fromFavourite.selectById(topic.favourite_id)) : of(null),
          ),
        )
        .subscribe((fav) => {
          // @ts-expect-error strictNullChecks
          this.favourite = fav;
        }),
    );

    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.report$.pipe(take(1)).subscribe((r) => {
      this.delaySentButtonFocus = !r.send_to_voyager_time;
    });
    this.subscription.add(
      this.editorService.getScheduleSendToVoyagerTask().subscribe((report_id) => {
        // Focus on Send To Voyager Now button if schedule sending to Voyager has been activated
        if (report_id === this.report_id) {
          this.delaySentButtonFocus = false;
        }
      }),
    );
    // Get topic tags
    this.tags$ = this.reportService.fetchTopicDividers(this.topic_id);

    this.subscription.add(
      this.reportService.getReportSendStatus(this.report_id).subscribe((status) => {
        this.reportSendStatus = status;
      }),
    );
  }

  onClick() {
    this.activeModal.dismiss();
  }

  onMouseDownServerText(_event: MouseEvent) {
    // Shows the preview UI when server text is clicked,
    // but doesn't if the text gets selected (so you can still copy part of a report)
    document.addEventListener(
      'mouseup',
      (_event2) => {
        // @ts-expect-error strictNullChecks
        if (!window.getSelection().toString()) {
          this.showReportUI = true;
        }
      },
      { once: true },
    );
  }

  copy() {
    this.subscription.add(
      this.report$.pipe(take(1)).subscribe((report) => {
        // @ts-expect-error strictNullChecks
        navigator.clipboard.writeText(report.text);
      }),
    );
    this.subscription.add(
      this.report$
        .pipe(
          switchMap((report) => this.reportService.getActiveTopics(report.id)),
          take(1),
        )
        .subscribe((topics) => {
          topics.forEach((t) => {
            return this.reportService.setTopicCopied(t.id);
          });
        }),
    );
    this.messageService.add({
      type: 'success',
      title: 'Success',
      message: 'Copied report to clipboard',
      timeout: 2000,
    });
    this.activeModal.dismiss();
  }

  fetchReportText() {
    this.fetchingReportText = true;
    this.subscription.add(
      this.reportEffect
        .findReportText(this.report_id)
        .pipe(
          finalize(() => {
            this.fetchingReportText = false;
          }),
        )
        .subscribe(),
    );
  }

  openCheckReport() {
    this.activeModal.dismiss();
    CheckReportModalComponent.open(this.modalService, this.report_id, this.topic_id);
  }

  onFavouriteClicked() {
    NameFavouriteModalComponent.open({
      modalService: this.modalService,
      topic_id: this.topic_id,
      favourite: this.favourite,
    });
  }

  // @ts-expect-error noImplicitAny
  static open(modal: NgbModal, { topic_id, report_id, mode }) {
    const modalRef = modal.open(FinalPreviewModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    const componentInstance: FinalPreviewModalComponent = modalRef.componentInstance;
    componentInstance.topic_id = topic_id;
    componentInstance.report_id = report_id;
    componentInstance.mode = mode;
    return modalRef;
  }

  openSignature() {
    const modalRef = SignatureModalComponent.open({
      modal: this.modalService,
      report_id: this.report_id,
      topic_id: this.topic_id,
      signature_role: 'DOCTOR',
      parent: 'FINAL_PREVIEW',
    });
    modalRef.result.then(
      // On close
      () => {
        // do nothing
      },
      // On dismiss
      (sentToVoayager) => {
        requestAnimationFrame(() => {
          this.onSentToVoyager(sentToVoayager);
        });
      },
    );
  }

  togglePrefill() {
    this.editorService.togglePrefill(true);
    this.modalService.dismissAll();
  }

  updateSourceText() {
    // at this point in the handler, `this.useFullText` has not yet toggled, so the condition is the
    // reverse of what you might expect
    if (this.showReportUI) {
      this.fetchReportText();
    }
  }

  isDoctorEditing() {
    return this.reportService.isDoctorEditing();
  }

  // It will indicate that the report has been sent and will focus on the 'Distribute Report' button.
  onSentToVoyager(sentToVoyager: boolean) {
    this.sentToVoyager = sentToVoyager;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
