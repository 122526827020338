import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { ReferralEnquiryEffect } from 'app/store/referral-enquiry';
import { ReferrerEffect, ReferrerHttpService } from 'app/store/referrer';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule, ReactiveFormsModule, SharedModule],
  selector: 'rr-referrer-form-search',
  templateUrl: './referrer-form-search.component.html',
  styleUrls: ['./referrer-form-search.component.css'],
})
export class ReferrerFormSearchComponent implements OnInit, OnDestroy {
  @Input() removedReferrer: RR.Referrer | undefined = undefined;
  @Input() bookingId: number | undefined = undefined;
  @Output() onCreateNewReferrer = new EventEmitter();
  @Output() onUndoRemoval = new EventEmitter();
  @Output() onSelectReferrer: EventEmitter<{ referrer: RR.Referrer; created: boolean }> = new EventEmitter();

  focus$ = new Subject<string>();
  searchForm: FormGroup;
  similarReferrers: RR.Referrer[] = [];

  subscription = new Subscription();

  get searchText() {
    return this.searchForm.controls.searchText as FormControl;
  }

  constructor(
    private referrerService: ReferrerHttpService,
    private messageService: MessageService,
    private referrerEffect: ReferrerEffect,
    private store: Store<AppState>,
    private referralEnquiryEffect: ReferralEnquiryEffect,
  ) {}

  ngOnInit(): void {
    this.searchForm = new FormGroup({ searchText: new FormControl('', { nonNullable: true }) });

    if (this.bookingId) {
      this.subscription.add(
        this.store
          .select(fromBooking.selectBooking(this.bookingId))
          .pipe(
            switchMap((booking) =>
              booking && booking.referral_enquiry_id
                ? this.referralEnquiryEffect.get(booking.referral_enquiry_id)
                : of(undefined),
            ),
          )
          .subscribe((referralEnquiryAction) => {
            if (!referralEnquiryAction) return;
            const referralEnquiry = referralEnquiryAction.enquiry;
            this.searchForm.patchValue({
              searchText: referralEnquiry.referrer_provider_id,
            });
          }),
      );
    }
  }

  createNewReferrer() {
    this.onCreateNewReferrer.emit();
  }

  undoRemoval() {
    this.onUndoRemoval.emit();
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestReferrers = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.focus$).pipe(
      switchMap((text) =>
        this.search(text).pipe(
          map((result) => result.referrers),
          this.messageService.handleHttpErrorPipe,
        ),
      ),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  selectReferrer = (event: NgbTypeaheadSelectItemEvent<RR.Referrer>) => {
    // Fetch again because ES results are not added to store
    this.subscription.add(
      this.referrerEffect.findById(event.item.id).subscribe(() => {
        this.onSelectReferrer.emit({ referrer: event.item, created: false });
      }),
    );
    event.preventDefault();
  };

  search(query: string) {
    return this.referrerService.elasticSearch({
      query,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
