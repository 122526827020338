import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { fromElementChoice } from 'app/store/report/element-choice';
import { ElementChoiceEffect } from 'app/store/report/element-choice/element-choice.effect';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { ChoiceConcatenation, CompositeChoice } from '../choice-concatenation.service';
import { ChoicePreviewComponent } from '../choice-preview/choice-preview.component';

@Component({
  selector: 'rr-element-preview',
  templateUrl: './element-preview.component.html',
  styleUrls: ['./element-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CdkDropList, CdkDrag, ChoicePreviewComponent],
})
@LifecycleLogger
export class ElementPreviewComponent implements OnInit, OnDestroy {
  // @ts-expect-error noImplicitAny
  @Input() @BindObservable() element_choice_id;
  element_choice_id$: Observable<number>;
  element_choice: RR.ElementChoice;
  @Input() region_choice: RR.RegionChoice;
  @Input() subsection_choice: RR.SubsectionChoice;
  @Input() section_choice: RR.SectionChoice;
  @Input() topic: RR.Topic;
  @Input() mode: PreviewMode = 'editor';
  choices: (RR.StatementChoice | CompositeChoice)[] = [];
  statementChoices: RR.StatementChoice[];
  subscription = new Subscription();
  disabledDragging = false;

  constructor(
    private choiceConcatenation: ChoiceConcatenation,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private elementChoiceEffect: ElementChoiceEffect,
  ) {}

  ngOnInit() {
    const element_choice$ = this.element_choice_id$.pipe(switchMap((id) => this.reportService.getElementChoice(id)));
    this.subscription.add(
      element_choice$.subscribe((element_choice) => {
        // @ts-expect-error strictNullChecks
        this.element_choice = element_choice;
        this.cd.markForCheck();
      }),
    );

    const choices$ = element_choice$.pipe(
      // @ts-expect-error strictNullChecks
      switchMap((element_choice) => this.store.select(fromElementChoice.selectStatementChoices(element_choice.id))),
    );
    this.subscription.add(
      choices$.subscribe((choices) => {
        this.statementChoices = choices;
      }),
    );
    let compositeChoices$: Observable<(RR.StatementChoice | CompositeChoice)[]>;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.section_choice && this.section_choice.type === 'history') {
      compositeChoices$ = choices$.pipe(switchMap((choices) => this.choiceConcatenation.concatenateChoices(choices)));
    } else {
      compositeChoices$ = choices$;
    }
    this.subscription.add(
      compositeChoices$.subscribe((choices) => {
        this.choices = choices;
        this.cd.markForCheck();
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // get region_id() {
  //   return this.topic_region ? this.topic_region.region_id : null;
  // }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === -1 || event.currentIndex === -1 || event.previousIndex === event.currentIndex) return;
    this.disabledDragging = true;

    const srcIndex = event.previousIndex;
    const desIndex = event.currentIndex;
    moveItemInArray(this.statementChoices, event.previousIndex, event.currentIndex);

    this.subscription.add(
      this.elementChoiceEffect
        .update(this.element_choice.id, {
          statement_choices: this.statementChoices.map((c) => c.id),
        })
        .pipe(
          finalize(() => {
            this.disabledDragging = false;
          }),
          catchError(() => {
            moveItemInArray(this.statementChoices, desIndex, srcIndex);
            this.cd.markForCheck();
            return EMPTY;
          }),
        )

        .subscribe(),
    );
  }

  trackByFn(_index: number, choice: RR.StatementChoice | CompositeChoice) {
    if ('id' in choice) {
      return choice.id;
    }
    // CompositeChoices don't have tracking, which is fine because we don't delete or move them.
    return undefined;
  }
}
