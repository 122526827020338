import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { fromUser } from '../user/user/user.selector';

const selectFeature = (state: AppState) => state.session;

const selectCurrentUserId = createSelector(selectFeature, (state) => {
  return state.currentUserId;
});

const selectCurrentUser = createSelector(
  selectCurrentUserId,
  fromUser.selectEntities,
  (userId: number | undefined, users: Dictionary<RR.User>) => {
    return userId ? users[userId] : undefined;
  },
);

const selectKioskUserId = createSelector(selectFeature, (state) => {
  return state.kioskUserId;
});

const selectKioskUser = createSelector(
  selectKioskUserId,
  fromUser.selectEntities,
  (userId: number | undefined, users: Dictionary<RR.User>) => {
    return userId ? users[userId] : undefined;
  },
);

const selectLoaded = createSelector(selectFeature, (state) => {
  return state.loaded;
});

const selectGoalRoute = createSelector(selectFeature, (state) => {
  return state.goalRoute;
});

const selectUserFeedbackNotification = createSelector(selectFeature, (state) => {
  return state.userFeedbackNotification;
});

const selectRRConfig = createSelector(selectFeature, (state) => {
  return state.rrConfig;
});

const selectBeforeLogoutListeners = createSelector(selectFeature, (state) => state.beforeLogoutListeners);

export const fromSession = {
  selectCurrentUserId,
  selectCurrentUser,
  selectKioskUser,
  selectLoaded,
  selectGoalRoute,
  selectUserFeedbackNotification,
  selectRRConfig,
  selectBeforeLogoutListeners,
};
