<div>
  <div class="modal-header">
    <h5 class="modal-title">Select Booking Code</h5>
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body scroll-y">
    <h3>Choose a Booking Code</h3>
    <input
      [disabled]="(bookingCodesLoaded$ | async) === false"
      #searchInput
      type="text"
      class="form-control"
      placeholder="Search: UPFEM, CTLEG, XSKUL, MMANK..."
      name="searchInput"
      (keyup)="onSearchTextChanged(searchInput.value)"
      ngbAutoFocus
    />
    <div *ngFor="let modality of modalities">
      <h3 class="mt-2">{{ modality }}</h3>
      <div class="d-flex flex-column flex-grow-1 mt-1 pt-1 pb-1 align-items-start">
        <button
          *ngFor="let bookingCode of groupedBookingCodes[modality]"
          (click)="selectBookingCode(bookingCode)"
          role="button"
          class="list-group-item list-group-item-action text-start border-0 py-1"
          data-cy="booking-code"
        >
          <rr-booking-code-headline [bookingCode]="bookingCode"></rr-booking-code-headline>
        </button>
      </div>
    </div>

    <ng-container *ngIf="(bookingCodesLoaded$ | async) === false">
      <div class="d-flex justify-content-center my-2">
        <div class="spinner-border" role="status"></div>
      </div>
    </ng-container>
  </div>
</div>
