import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { SiteActions } from './site.action';
import { fromSite } from './site.selector';
import { SiteHttpService } from './site.service';

@Injectable()
export class SiteEffect {
  constructor(
    private store: Store<AppState>,
    private service: SiteHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromSite.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      this.message.handleHttpErrorPipe,
      map((sites: RR.Site[]) => SiteActions.findAllSuccess({ sites })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
