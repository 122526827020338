import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const selectFeature = (state: AppState) => state.toast;

const selectToasts = createSelector(selectFeature, (state) => state.toasts);

export const fromToast = {
  selectToasts,
};
