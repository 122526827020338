import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BookingActions } from './booking.action';

export type BookingState = EntityState<RR.Booking>;

const adapter = createEntityAdapter<RR.Booking>();

const initialState = adapter.getInitialState();

export const bookingReducer = createReducer(
  initialState,
  on(BookingActions.findManySuccess, (state: BookingState, { bookings }) => adapter.upsertMany(bookings, state)),
  on(BookingActions.findSuccess, (state: BookingState, { booking }) => adapter.upsertOne(booking, state)),
  on(BookingActions.createSuccess, (state: BookingState, { booking }) => adapter.upsertOne(booking, state)),
  on(BookingActions.updateSuccess, (state: BookingState, { booking }) => adapter.upsertOne(booking, state)),
  on(BookingActions.updateBookingPatientSuccess, (state: BookingState, { bookingId, bookingPatient }) => {
    const updatedBooking: Update<RR.Booking> = { id: bookingId, changes: { booking_patient: bookingPatient } };
    return adapter.updateOne(updatedBooking, state);
  }),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
