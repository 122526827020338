import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { TemplateActions, TemplateBatchActions } from '../template/template/template.action';

const createSuccess = createAction(
  '[MandatoryStatement] Create Success',
  props<{ mandatoryStatement: RR.MandatoryStatement }>(),
);

const deleteSuccess = createAction('[MandatoryStatement] Delete Success', props<{ mandatoryStatementId: number }>());

const findManySuccess = createAction(
  '[MandatoryStatement] Find Many Success',
  props<{ mandatoryStatements: RR.MandatoryStatement[] }>(),
);

const createSuccessBatch = createBatchAction(
  '[MandatoryStatement] Create Success Batch',
  props<{
    actions: {
      mandatoryStatementCreateSuccess: ReturnType<typeof createSuccess>;
      templateUpdateSuccess: ReturnType<typeof TemplateActions.updateSuccess>;
    };
  }>(),
);

const deleteSuccessBatch = createBatchAction(
  '[MandatoryStatement] Delete Success Batch',
  props<{
    actions: {
      mandatoryStatementDeleteSuccess: ReturnType<typeof deleteSuccess>;
      templateUpdateSuccess: ReturnType<typeof TemplateActions.updateSuccess>;
    };
  }>(),
);

const findInTemplateSuccess = createBatchAction(
  '[MandatoryStatement] Find In Template Success',
  props<{
    actions: {
      findMandatoryStatementSuccess: ReturnType<typeof findManySuccess>;
      findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
    };
  }>(),
);

export const MandatoryStatementActions = {
  findInTemplateSuccess,
  findManySuccess,
  createSuccess,
  createSuccessBatch,
  deleteSuccess,
  deleteSuccessBatch,
};
