import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-user-headline',
  templateUrl: './user-headline.component.html',
  styleUrls: ['./user-headline.component.css'],
})
export class UserHeadlineComponent {
  @Input() user: RR.User;
}
