<div class="modal-header">
  <h5 class="modal-title">Delete statement</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="topic">
    <p>This statement will be deleted from the template:</p>
    <div class="list-group">
      <button
        rr-statement
        [topic]="topic"
        [template_id]="topic.template_id"
        type="stub"
        class="list-group-item list-group-item-action text-start w-100"
        [statement]="statement"
      ></button>
    </div>
    <hr />
  </ng-container>
  <p *ngIf="!affectedTemplates" class="text-danger">
    Deleting this statement will affect <strong>multiple templates</strong>.
  </p>
  <p *ngIf="affectedTemplates" class="text-danger">
    Deleting this statement will affect
    <strong>{{ affectedTemplates.length }} templates</strong>.
    <button class="btn btn-outline-dark btn-sm" (click)="showTemplates = !showTemplates">
      {{ showTemplates ? 'Hide' : 'Show ' + affectedTemplates.length + ' templates' }}
    </button>
  </p>
  <ul *ngIf="showTemplates" class="mb-0">
    <li *ngFor="let template of affectedTemplates">{{ template.modality.name }} - {{ template.name }}</li>
  </ul>
</div>

<div class="modal-footer">
  <button ngbAutoFocus class="btn btn-danger" role="button" (click)="activeModal.close()">Confirm Delete</button>
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
