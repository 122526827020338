import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ReferrerNoteActions } from './referrer-note.action';

export interface ReferrerNoteState extends EntityState<RR.ReferrerNote> {
  count: number;
}

const adapter = createEntityAdapter<RR.ReferrerNote>();
const initialState = adapter.getInitialState({
  count: 0,
});

export const referrerNoteReducer = createReducer(
  initialState,
  on(ReferrerNoteActions.searchSuccess, (state: ReferrerNoteState, searchResult) => {
    const { referrer_notes, count } = searchResult.searchResult;
    return {
      ...adapter.upsertMany(referrer_notes, state),
      count,
    };
  }),
  on(ReferrerNoteActions.findManySuccess, (state: ReferrerNoteState, { referrer_notes }) =>
    adapter.upsertMany(referrer_notes, state),
  ),
  on(ReferrerNoteActions.createSuccess, (state: ReferrerNoteState, { referrer_note }) =>
    adapter.addOne(referrer_note, state),
  ),
  on(ReferrerNoteActions.deleteSuccess, (state: ReferrerNoteState, { referrer_note_id }) =>
    adapter.removeOne(referrer_note_id, state),
  ),
  on(ReferrerNoteActions.updateSuccess, (state: ReferrerNoteState, { referrer_note }) =>
    adapter.upsertOne(referrer_note, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
