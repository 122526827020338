<ng-container *ngIf="report$ | async as report">
  <ng-template #defaultTemplate>
    <i class="fas fa-exclamation-circle"></i>
    <span class="ms-1">{{ report.urgent ? 'Undo Urgent' : 'Mark Urgent' }} (U)</span>
  </ng-template>
  <button
    role="button"
    class="btn {{ btnSize }} {{ btnClassName }}"
    [ngClass]="report.urgent ? 'btn-danger' : btnColour"
    (click)="onClick(report)"
    rrTooltip="MARK_AS_URGENT"
    [disabled]="report.super_urgent"
  >
    <div [rrNoContent]="defaultTemplate"><ng-content></ng-content></div>
  </button>
</ng-container>
