import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { GithubIssueCommentActions } from '../github-issue-comment/github-issue-comment.action';
import { GithubIssueActions, GithubIssueBatchActions } from './github-issue.action';
import {
  GithubIssueService,
  GithubIssueCommentPayload,
  GithubIssueCommentCreateResponse,
} from './github-issue.service';

@Injectable()
export class GithubIssueEffect {
  constructor(
    private store: Store<AppState>,
    private service: GithubIssueService,
  ) {}

  findAll(status: string, sort: string, offset: number, limit: number) {
    return this.service.findAll(status, sort, offset, limit).pipe(
      map((response) => {
        return GithubIssueBatchActions.findAllSuccess({
          actions: {
            githubIssueFindAll: GithubIssueActions.findAllSuccess({ issues: response.issues, count: response.count }),
            githubIssueCommentFindAll: GithubIssueCommentActions.findAllSuccess({ comments: response.comments }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(comment: GithubIssueCommentPayload, githubIssueId: number) {
    return this.service.create(comment, githubIssueId).pipe(
      map((response: GithubIssueCommentCreateResponse) => {
        return GithubIssueCommentActions.createCommentSuccess({
          actions: {
            githubIssueFind: GithubIssueActions.findSuccess({ issue: response.issue }),
            githubIssueCommentCreate: GithubIssueCommentActions.createSuccess({ comment: response.comment }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findById(githubIssueId: number) {
    return this.service.findById(githubIssueId).pipe(
      map((response) =>
        GithubIssueBatchActions.findByIdSuccess({
          actions: {
            githubIssueFind: GithubIssueActions.findSuccess({ issue: response.issue }),
            githubIssueCommentFindAll: GithubIssueCommentActions.findAllSuccess({ comments: response.comments }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
