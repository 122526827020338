import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable, fixMaxHeight } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { SharedModule } from 'app/shared/shared.module';
import { Observable, Subscription, filter } from 'rxjs';

import { TitleEditComponent } from '../title-edit/title-edit.component';

@Component({
  selector: 'rr-title-edit-dropdown',
  standalone: true,
  imports: [CommonModule, SharedModule, NgbDropdownModule, AutoFocusDirective, TitleEditComponent],
  templateUrl: './title-edit-dropdown.component.html',
  styleUrls: ['./title-edit-dropdown.component.css'],
})
export class TitleEditDropdownComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  disabled = false;
  @ViewChild('dropdown', { static: true }) dropdown: NgbDropdown;
  @ViewChild('dropdownMenuEl', { static: true }) dropdownEl: ElementRef;
  @ViewChild(TitleEditComponent, { static: true }) titleEdit: TitleEditComponent;

  subscription = new Subscription();

  constructor(private editorService: EditorService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.editorService.jumpToTitleEvents.pipe(filter((e) => e !== false)).subscribe(() => {
        this.editorService.jumpToTitleEvents.next(false);
        setTimeout(() => {
          this.dropdown.open();
        });
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onCreateTitle() {
    this.dropdown.close();
  }

  dropdownOpenChanged(nowOpen: boolean) {
    if (!nowOpen) {
      this.titleEdit.save();
    } else {
      requestAnimationFrame(() => {
        fixMaxHeight(this.dropdownEl.nativeElement);
      });
    }
  }
}
