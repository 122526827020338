import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { GithubIssueActions } from '../github-issue/github-issue.action';

const findAllSuccess = createAction(
  '[GithubIssueComment] Find All Success',
  props<{ comments: RR.GithubIssueComment[] }>(),
);

const createSuccess = createAction('[GithubIssueComment] Create Success', props<{ comment: RR.GithubIssueComment }>());

const createCommentSuccess = createBatchAction(
  '[GithubIssueComment] Create Success Batch',
  props<{
    actions: {
      githubIssueFind: ReturnType<typeof GithubIssueActions.findSuccess>;
      githubIssueCommentCreate: ReturnType<typeof createSuccess>;
    };
  }>(),
);

export const GithubIssueCommentActions = {
  createSuccess,
  createCommentSuccess,
  findAllSuccess,
};
