import { HttpParams } from '@angular/common/http';

import { HttpParamsObject } from './store.types';

export function createHttpParams(params: HttpParamsObject): HttpParams {
  let httpParams = new HttpParams();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (params == null) return httpParams;
  Object.keys(params).forEach((param) => {
    const value = params[param];
    if (Array.isArray(value)) {
      throw new Error('Not implemented');
      // value.forEach((_value) => {
      //   // append the parameter multiple times. e.g. ?x=1&x=2
      //   httpParams.append(param, String(_value));
      // });
    } else {
      httpParams = httpParams.append(param, String(value));
    }
  });
  return httpParams;
}
