import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { SessionActions } from '../session/session.action';
import { SettingActions } from './setting.action';

export interface SettingState {
  kiosk: boolean;
  userClockedOn: boolean;
}

const initialState: SettingState = {
  kiosk: rehydrateBoolean('kiosk', true),
  // @ts-expect-error strictNullChecks
  userClockedOn: undefined,
};

function rehydrateBoolean(str: string, defaultsTo: boolean) {
  const localStorageItem = localStorage.getItem(str);
  // strictly speaking, there's no guarantee that `localStorageItem` is actually a serialized boolean.
  const localStorageValue: any = localStorageItem == null ? defaultsTo : JSON.parse(localStorageItem);
  return typeof localStorageValue === 'boolean' ? localStorageValue : defaultsTo;
}

export const settingReducer = createReducer(
  initialState,
  on(SettingActions.toggleKiosk, (state: SettingState) => ({ ...state, kiosk: !state.kiosk })),
  on(SettingActions.setUserClockedOn, (state: SettingState, { value }) => ({ ...state, userClockedOn: value })),
  // Clear store data on open/close editor
  on(SessionActions.logoutSuccess, (state) => ({ ...state, kiosk: true })),
  // @ts-expect-error strictNullChecks
  on(EditorActions.close, (state) => ({ ...state, userClockedOn: undefined })),
);
