import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { DocumentationLinkEffect, fromDocumentationLink } from 'app/store/documentation-link';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, SharedModule],
  selector: 'rr-documentation-button-dropdown',
  templateUrl: './documentation-button-dropdown.component.html',
})
export class DocumentationButtonDropdownComponent implements OnInit, OnDestroy {
  docs: RR.DocumentationLink[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    private docLinkEffect: DocumentationLinkEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.subscription.add(this.docLinkEffect.findAll().subscribe());

    this.subscription.add(
      this.store.select(fromDocumentationLink.selectAll).subscribe((docs) => {
        this.docs = docs.filter((doc) => doc.button_id === null).sort((a, b) => a.id - b.id);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
