import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { fromTopic } from 'app/store/report/topic';
import { Subscription } from 'rxjs';

import { AddOtherImagingComponent } from '../add-other-imaging/add-other-imaging.component';
import { PriorReportMode } from '../add-other-imaging/prior-study-links/prior-study-links.component';

@Component({
  standalone: true,
  imports: [CommonModule, AddOtherImagingComponent, AutoFocusDirective],
  selector: 'rr-add-other-imaging-modal',
  templateUrl: './add-other-imaging-modal.component.html',
  styleUrls: ['./add-other-imaging-modal.component.css'],
})
export class AddOtherImagingModalComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() topicId: number;
  @Input() defaultMode: PriorReportMode;
  /**
   * Whether the user can select the prior reports to add with the "H" and "KF" and "Delete Prior" buttons
   */
  @Input() selectable: boolean;

  subscription = new Subscription();
  report: RR.Report | undefined;
  topic: RR.Topic | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    protected router: Router,
    protected el: ElementRef,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromReport.selectReport(this.reportId)).subscribe((report) => {
        this.report = report;
      }),
    );
    this.subscription.add(
      this.store.select(fromTopic.selectTopic(this.topicId)).subscribe((topic) => {
        this.topic = topic;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  static open(
    modal: NgbModal,
    options: { reportId: number; topicId: number; defaultMode?: PriorReportMode; selectable?: boolean },
  ) {
    const { reportId, topicId, defaultMode = 'all', selectable = true } = options;
    const modalRef = modal.open(AddOtherImagingModalComponent, {
      size: 'xl',
    });
    const componentInstance: AddOtherImagingModalComponent = modalRef.componentInstance;
    componentInstance.reportId = reportId;
    componentInstance.topicId = topicId;
    componentInstance.defaultMode = defaultMode;
    componentInstance.selectable = selectable;
    return modalRef;
  }
}
