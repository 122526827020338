// https://github.com/showdownjs/showdown/blob/master/src/subParsers/makehtml/autoLinks.js
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { BindObservable } from 'app/app.utils';
import { Observable, Subscription } from 'rxjs';

const urlStart = /(?:https?:\/\/|www\.)/i; // leading character substrings implying the intent for a hyperlinked URL string
const domainName = /(?:(?:[^./\s<>#"'%{}|\\^[\]`]|%[0-9A-F]{2})+\.)+/i; // repeated, dot-separated substrings of legal domain chars
const topLevelDomain =
  // TODO: accept internationalized ccTLDs -- find the currently-used non-latin alphabets' unicode ranges, and accept arbitrary
  // strings restricted to one of those alphabets as valid TLDs under IRI.
  // Prior to IRI, legal top-level domain names may contain letters, numbers, and hyphens; but may not start or end in a hyphen,
  // and may not be all-numeric, per RFC 3696
  /(?:(?:[A-Z0-9]+[A-Z0-9\-]+[A-Z0-9]+)|(?:[A-Z]+[0-9]+[A-Z0-9]*)|(?:[0-9]+[A-Z]+[A-Z0-9]*)|(?:[A-Z]+))/i;
const path = /(?:(?:#|\/)[^\s<>"'{}|\\^[\]`]*)?/; // slash and/or anchor, followed by legal URL characters
const urlregex = RegExp(urlStart.source + domainName.source + topLevelDomain.source + path.source, 'gi');
const trailingPunctuation = new Set(['.', ',', ':', ';', '!', '?', "'", '"']);

const replaceLink = (raw_url: string) => {
  let url: string;
  let punct = '';
  if (
    trailingPunctuation.has(raw_url.charAt(raw_url.length - 1)) && // attempt to omit stray punctuation like commas and semicolons
    !trailingPunctuation.has(raw_url.charAt(raw_url.length - 2)) // but assume that consecutive punctuations are part of the URL
  ) {
    url = raw_url.slice(0, -1);
    punct = raw_url.charAt(raw_url.length - 1);
  } else if (
    raw_url.slice(raw_url.length - 4) === '...' // likely an intentional natural-language ellipsis rather than the end of a URL
  ) {
    url = raw_url.slice(0, -3);
    punct = '...';
  } else {
    url = raw_url;
  }
  const linktext = url;
  const destination = url.slice(0, 4) === 'www.' ? `http://${url}` : url;
  return `<a href="${destination}" target="_blank">${linktext}</a>${punct}`;
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[linkify]',
  standalone: true,
})
export class LinkifyDirective implements OnInit {
  @BindObservable() @Input() linkify: string;
  linkify$: Observable<string>;
  subscription = new Subscription();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.subscription.add(
      this.linkify$.subscribe((linkify) => {
        this.elementRef.nativeElement.innerHTML = this.process(linkify);
      }),
    );
  }

  process(text: string) {
    if (text) {
      return text.replace(urlregex, replaceLink);
    }
    return '';
  }
}

// exposed only for unit tests, DO NOT import elsewhere
export const __TEST_EXPORTS = {
  topLevelDomain,
  urlregex,
  replaceLink,
};
