<div>
  <button class="btn btn-sm btn-info" (click)="openDateTimeEditor()" rrTooltip="EDIT_DUE_DATE" type="button">
    <span><i class="fas fa-stopwatch"></i> Due Date</span>
  </button>
  <div *ngIf="report.due_date" class="text-danger">
    <span class="fw-semibold">
      {{ report.due_date | date: 'MMM d, y, H:mm' }}
    </span>
  </div>
</div>
