import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SelectorService } from 'app/core/services/selector.service';
import { VoiceInputTerm, VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { AppState } from 'app/store';
import { fromUserSetting } from 'app/store/user/user-setting';
import { delay, filter, map, Observable, Subscription, switchMap, take } from 'rxjs';

let nextId = 0;

export type VoiceInputDetail = { term: string; source: string | undefined };

@Directive({
  standalone: true,
  selector: '[rrVoice]',
})
export class VoiceDirective implements OnInit, OnDestroy {
  // Each rrVoice input will have its own unique id so it can listen to its dictated text
  // However, the dictated text can be populated to the inputs that are in the same group (eg: Global search).
  @Input() source: string | undefined;
  @Input() keydownStopListening = true;
  @Input() focusOutStopListening = true;
  @Input() speechSynthesis = false;
  // Auto capitalise first letter and add full stop at the end of the sentence
  @Input() decorate = false;
  inputId = `${nextId++}`;
  voiceMode$: Observable<boolean>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private el: ElementRef,
    private voiceService: VoiceRecognitionService,
    private selectorService: SelectorService,
  ) {}

  ngOnInit(): void {
    // Only subscribe to the voice input term from the same input group
    this.subscription.add(
      this.voiceService.voiceTermSubject$
        .pipe(
          filter(
            (voiceInput: VoiceInputTerm) => voiceInput.source === this.source || voiceInput.source === this.inputId,
          ),
        )
        .subscribe((voiceInput) => {
          if (this.speechSynthesis) this.voiceService.startSpeechSynth(voiceInput.term);
          this.copyToInput(voiceInput.term);
        }),
    );

    this.voiceMode$ = this.selectorService.selectLoadedCurrentUser().pipe(
      filterDefined(),
      switchMap((user) =>
        this.store
          .select(fromUserSetting.selectUserSetting(user.id))
          .pipe(map((userSetting) => userSetting?.voice_recognition_features || false)),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('focusin', ['$event'])
  onFocusIn() {
    this.subscription.add(
      this.voiceMode$
        // Delay start listening so that the focusout event from previous input can stop listening first
        .pipe(take(1), delay(100))
        .subscribe((voiceMode) => {
          if (voiceMode) {
            this.voiceService.startListening(this.source || this.inputId);
          }
        }),
    );
  }

  @HostListener('focusout', ['$event'])
  onFocusOut() {
    if (this.focusOutStopListening) {
      this.subscription.add(
        this.voiceMode$.pipe(take(1)).subscribe((voiceMode) => {
          if (voiceMode) {
            this.voiceService.stopListening();
          }
        }),
      );
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(_event: KeyboardEvent) {
    if (this.keydownStopListening) {
      this.voiceService.stopListening();
    }
  }

  /**
   * Capitalise the first letter and add full stop to the end
   * @param text
   * @returns
   */
  decorateSentence(text: string) {
    if (!text || !text.trim()) return text;
    let result = text.trim();
    result = result[0].toUpperCase() + result.slice(1);
    if (!result.endsWith('.')) {
      result += '.';
    }
    return result;
  }

  copyToInput(term: string) {
    const element = this.el.nativeElement;
    // bubbles: true, allows event to bubble up and be captured by event listeners
    const voiceEvent = new CustomEvent<VoiceInputDetail>('voiceInput', {
      bubbles: true,
      detail: {
        term: this.decorate ? this.decorateSentence(term) : term,
        source: this.source,
      },
    });

    element.dispatchEvent(voiceEvent);
  }
}
