import { createActionGroup, props } from '@ngrx/store';

/**
 * Ngrx Actions for StatementChoice
 *
 */
export const StatementChoiceActions = createActionGroup({
  source: 'Statement Choice',
  events: {
    addOne: props<{ statementChoice: RR.StatementChoice }>(),
    addMany: props<{ statementChoices: RR.StatementChoice[] }>(),
    upsertOne: props<{ statementChoice: RR.StatementChoice }>(),
    upsertMany: props<{ statementChoices: RR.StatementChoice[] }>(),
    removeMany: props<{ statementChoiceIds: number[] }>(),
  },
});
