import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentationLinkHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.DocumentationLink[]> {
    return this.http.get<RR.DocumentationLink[]>('/api/documentation_link');
  }

  create(doc: Partial<RR.DocumentationLink>): Observable<RR.DocumentationLink> {
    return this.http.post<RR.DocumentationLink>('/api/documentation_link', doc);
  }

  update(id: number, doc: RR.DocumentationLink): Observable<RR.DocumentationLink> {
    return this.http.put<RR.DocumentationLink>(`/api/documentation_link/${id}`, { ...doc, id: undefined });
  }

  delete(docId: number) {
    return this.http.delete(`/api/documentation_link/${docId}`);
  }
}
