import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { LabelActions } from './label.action';
import { LabelHttpService } from './label.service';

@Injectable()
export class LabelEffect {
  constructor(
    private store: Store<AppState>,
    private service: LabelHttpService,
    private message: MessageService,
  ) {}

  create(label: Partial<RR.Label>) {
    return this.service.create(label).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.Label) =>
        LabelActions.createSuccess({
          label: data,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(labelId: number) {
    return this.service.delete(labelId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.Label) =>
        LabelActions.deleteSuccess({
          label_id: data.id,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated unused
   */
  update(labelId: number, changes: Partial<RR.Label>) {
    return this.service.update(labelId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((label) => LabelActions.updateSuccess({ label })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((labels) => LabelActions.findAllSuccess({ labels })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
