<div class="modal-header">
  <h4 class="modal-title">Create Note</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <ng-container *ngIf="referrerId | storeSelectPipe: referrerSelector | async as referrer">
    <rr-notes [referrerId]="referrer.id" [reportId]="reportId"></rr-notes>
  </ng-container>
  <ng-container *ngIf="patientId | storeSelectPipe: patientSelector | async as patient">
    <rr-notes [patientId]="patient.id" [type]="'patient'" [reportId]="reportId"></rr-notes>
  </ng-container>
</div>
