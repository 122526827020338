<div class="col-sm-9 mx-auto my-2">
  <h2 class="text-center mb-2">
    <strong>{{ header }}</strong>
    <div *ngIf="!noReferrer && (!referrer || !referrer.referrer_type)">
      <small>
        (To continue with the registration, please either select a referrer or indicate that this registration has no
        referrer.)
      </small>
    </div>
  </h2>
  <div class="col-sm-12" *ngIf="noReferrer || (referrer && referrer.referrer_type)">
    <!-- Select a referrer and define the referrer_type before showing the form. The "To continue..." message above
    clarifies what to do -->
    <h6 class="text-danger" *ngIf="form.invalid">Incomplete</h6>
    <div
      *ngIf="viewMode === 'view'"
      class="row form-group bg-light rounded p-2 align-items-center"
      [ngClass]="{
        'border border-danger': form.invalid
      }"
      data-cy="report-form-view"
    >
      <div class="col-sm-9" *ngIf="viewMode === 'view'">
        <div class="row">
          <div class="col-sm-4">
            <strong>Scan Code:</strong>
            <p *ngIf="reportScanCode$ | async as scanCode">
              {{ scanCode?.code }}
              <span *ngIf="scanCode.has_side" class="text-capitalize ms-1">{{ report?.scan_code_side }}</span>
            </p>
          </div>
          <div class="col-sm-4">
            <strong>Booking Code:</strong>
            <p>{{ (bookingCode$ | async)?.code }}</p>
          </div>
          <div class="col-sm-4">
            <strong>Referral Date:</strong>
            <p>{{ report?.referral_date | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <strong>Site:</strong>
            <p>{{ (site$ | async)?.short_name }}</p>
          </div>
          <div class="col-sm-4">
            <strong>Provider Number:</strong>
            <p>{{ (providerNumber$ | async)?.medicare_provider_number }}</p>
          </div>
          <div class="col-sm-4">
            <strong>Voyager Study Notes:</strong>
            <p class="text-truncate" *ngIf="report?.study_notes; else noNotes">{{ report?.study_notes }}</p>
            <ng-template #noNotes><p>No</p></ng-template>
          </div>
        </div>
        <ng-container *ngIf="bookingCode$ | async as bookingCode">
          <div class="row" *ngIf="bookingCode.booking_notes">
            <div class="col">
              <strong>Booking Code Notes:</strong>
              <p [innerHTML]="bookingCode.booking_notes"></p>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col d-flex">
        <button
          type="button"
          class="btn btn-outline-primary w-100"
          (click)="editBooking()"
          rrTooltip="REGISTRAION_EDIT_REPORT"
        >
          {{ report ? 'Edit Registration' : 'New Registration' }}
        </button>
      </div>
    </div>

    <div [hidden]="!(viewMode === 'edit')" class="bg-light p-2 rounded">
      <form class="bootstrap-validate">
        <div class="row">
          <div class="form-group mb-2 col">
            <label class="control-label">Scan Code <span class="text-danger fa fa-asterisk fa-xs"></span></label>
            <div
              class="input-group"
              (click)="selectScanCode()"
              [formControl]="form.controls.scan_code_id"
              ngDefaultControl
              data-cy="scanCodeInput"
            >
              <div role="button" readonly class="form-control flex-grow-1">
                {{ (formScanCode$ | async)?.code }}
              </div>
              <button class="btn btn-secondary" rrTooltip="SELECT_BOOKING_SCAN_CODE">Select</button>
            </div>
            <span class="invalid-feedback" *ngIf="form.controls.scan_code_id.errors?.required"
              >Scan code is required</span
            >
          </div>
          <div class="form-group mb-2 col" *ngIf="(formScanCode$ | async)?.has_side">
            <label class="control-label">Scan Side <span class="text-danger fa fa-asterisk fa-xs"></span></label>
            <div class="d-flex flex-wrap" [formControl]="form.controls.scan_code_side" ngDefaultControl>
              <ng-container *ngFor="let side of ['left', 'right', 'bilateral']">
                <div class="form-check me-2">
                  <input
                    id="{{ side }}"
                    class="form-check-input"
                    type="radio"
                    [formControl]="form.controls.scan_code_side"
                    [value]="side"
                  />
                  <label for="{{ side }}" class="text-capitalize">{{ side }}</label>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="form-group mb-2 col">
            <label class="control-label">Booking Code <span class="text-danger fa fa-asterisk fa-xs"></span></label>
            <div
              class="input-group"
              (click)="selectBookingCode()"
              [formControl]="form.controls.booking_code_id"
              data-cy="bookingCodeIdInput"
              ngDefaultControl
            >
              <div role="button" readonly class="form-control flex-grow-1">
                {{ (formBookingCode$ | async)?.code }}
              </div>
              <button class="btn btn-secondary" rrTooltip="SELECT_BOOKING_BOOKING_CODE">Select</button>
            </div>
            <span class="invalid-feedback" *ngIf="form.controls.booking_code_id.errors?.required"
              >Booking code is required</span
            >
          </div>
        </div>

        <ng-container *ngIf="formBookingCode$ | async as bookingCode">
          <div class="row" *ngIf="!!bookingCode.booking_notes">
            <div class="form-group mb-2 col">
              <span>Booking Code Notes:</span>
              <div [innerHTML]="bookingCode.booking_notes"></div>
            </div>
          </div>
        </ng-container>

        <div class="row">
          <div class="col-sm-4 form-group mb-2">
            <label class="control-label">Referral Date</label>
            <input
              data-cy="referral-date"
              [formControl]="form.controls.referral_date"
              type="date"
              class="form-control"
              (change)="checkReferralDate()"
            />
            <ng-container>
              <div class="text-warning" *ngIf="isReferralDateInFuture">
                Referral date is in the future. Check date, then check procedure under Help.
              </div>
            </ng-container>
          </div>
          <div class="col-sm-3 form-group mb-2">
            <label class="control-label">Site <span class="text-danger fa fa-asterisk fa-xs"></span></label>
            <div class="d-flex flex-wrap">
              <ng-container *ngFor="let s of sites">
                <div class="form-check me-2">
                  <input
                    id="site-{{ s.id }}"
                    class="form-check-input"
                    type="radio"
                    [formControl]="form.controls.site_id"
                    [value]="s.id"
                  />
                  <label data-cy="site-label" for="site-{{ s.id }}">{{ s.short_name }}</label>
                </div>
              </ng-container>
            </div>
            <span class="invalid-feedback" *ngIf="form.controls.site_id.errors?.required"> Site is required </span>
          </div>
          <div class="col form-group mb-2 ps-0">
            <label class="control-label">Providing Doctor<span class="text-danger fa fa-asterisk fa-xs"></span></label>
            <rr-medicare-provider
              [site_id]="form.controls.site_id.value"
              [formControl]="form.controls.medicare_provider_id"
            ></rr-medicare-provider>
            <span
              class="invalid-feedback"
              *ngIf="
                form.controls.medicare_provider_id.invalid &&
                (form.controls.medicare_provider_id.touched || form.controls.medicare_provider_id.dirty)
              "
            >
              Provider number is required
            </span>
          </div>
        </div>

        <div class="row" *ngIf="form.controls.booking_code_id.value">
          <div class="form-group col">
            <rr-booking-code-detail
              [bookingCodeId]="form.controls.booking_code_id.value"
              [referrerType]="referrer?.referrer_type"
              [booking]="booking || reportBooking"
              [funder]="funder"
              (onBillingItemChange)="onBillingItemChange($event)"
            ></rr-booking-code-detail>
          </div>
        </div>

        <div class="d-flex flex-column align-items-center">
          <div *ngIf="!patient && !report" class="mb-2">
            Need a patient to register.
            <button
              class="btn btn-outline-danger btn-sm"
              rrTooltip="REGISTER_BOOKING_PATIENT"
              (click)="registrationService.focus('patient-form')"
            >
              Step 1
            </button>
          </div>
          <div *ngIf="!referrer && !report && !noReferrer" class="mb-2">
            Need a referrer to register.
            <button
              class="btn btn-outline-danger btn-sm"
              rrTooltip="REGISTER_BOOKING_REFERRER"
              (click)="registrationService.focus('referrer-form')"
            >
              Step 2
            </button>
          </div>

          <div *ngIf="availableBillingItems.length > 0 && selectedBillingItemIds.length === 0" class="mb-2">
            Need to select at least one billing item to register.
            <button class="btn btn-outline-danger btn-sm">Step 3</button>
          </div>
        </div>
        <div class="d-flex w-50 mx-auto justify-content-center">
          <!--Cannot create report without a patient-->
          <button
            *ngIf="!report"
            class="btn btn-primary"
            rrTooltip="REGISTER_BOOKING"
            [disabled]="
              form.invalid ||
              !patient ||
              (!referrer && !noReferrer) ||
              form.pristine ||
              (availableBillingItems.length > 0 && selectedBillingItemIds.length === 0)
            "
            (click)="submitReport()"
          >
            Register
          </button>
          <!-- Check if the booking code, scan code, or billing item has been updated, or if the user has not
             selected any billing item after changing the booking code. -->
          <button
            *ngIf="report"
            class="btn btn-primary"
            rrTooltip="REGISTER_BOOKING"
            [disabled]="
              form.invalid ||
              (form.controls.booking_code_id.value === reportBooking?.booking_code_id &&
                form.controls.scan_code_id.value === (reportScanCode$ | async)?.id &&
                reportBooking?.billing_items?.[0] === selectedBillingItemIds[0] &&
                form.pristine) ||
              (availableBillingItems.length > 0 && selectedBillingItemIds.length === 0)
            "
            (click)="submitReport()"
          >
            Save Changes
          </button>
          <button class="btn btn-light ms-2" (click)="cancel()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
