<div (click)="selectPrefill($event)" class="unselectable">
  <div class="d-flex">
    <div class="d-flex flex-column flex-grow-1">
      <div class="w-100 unselectable">
        <div>
          <div class="float-end">
            <a
              rrTooltip="OPEN_PREFILL_IN_NEW_WINDOW"
              [routerLink]="['/report', topic.report_id, 'topic', topic.topic_id]"
              target="_blank"
              class="text-nowrap ms-1"
              data-no-bubble-select-prefill-row
            >
              <span class="small fa fa-external-link-alt"></span
            ></a>
          </div>
          <span ngbTooltip="{{ topic.patient_age | date: 'mediumDate' }}"
            ><b>{{ patientShortAgeAndSex() }} </b></span
          >
          <strong class="ms-1">{{ topic.title ? topic.title : 'No Title' }}</strong>
        </div>
      </div>
      <div class="d-flex">
        <span class="badge bg-secondary me-1" *ngIf="metric && metric.totalCurrent > 0" rrTooltip="PREFILL_METRIC_1"
          >{{ (metric.intersection / metric.totalCurrent) * 100 | number: '1.0-0' }}%</span
        >
        <span class="badge bg-secondary me-1" *ngIf="metric && metric.totalPrefills > 0" rrTooltip="PREFILL_METRIC_2"
          >{{ (metric.intersection / metric.totalPrefills) * 100 | number: '1.0-0' }}%</span
        >
        <span class="badge bg-secondary">Match: {{ topic.matches }}</span>
        <span class="ms-1 badge bg-secondary"
          >Score: {{ topic.norm_score * 100 | number: '1.0-2' }}% ({{ topic.score | number: '1.0-2' }})</span
        >
      </div>
    </div>
  </div>
</div>
