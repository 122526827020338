import { Component, OnInit } from '@angular/core';
import { RouterHistoryService } from 'app/core/services/router-history.service';
import { DynamicLinkDirective } from 'app/shared/directives/dynamic-link.directive';
import { NoContentDirective } from 'app/shared/directives/no-content.directive';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  selector: 'rr-worklist-link',
  standalone: true,
  imports: [SharedModule, DynamicLinkDirective, NoContentDirective],
  templateUrl: './worklist-link.component.html',
  styleUrls: ['./worklist-link.component.css'],
})
export class WorklistLinkComponent implements OnInit {
  constructor(protected routerHistoryService: RouterHistoryService) {}

  ngOnInit(): void {}
}
