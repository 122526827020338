import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { LandmarkLabelActions } from './landmark-label.action';

export type LandmarkLabelState = EntityState<RR.LandmarkLabel>;

const adapter = createEntityAdapter<RR.LandmarkLabel>();

const initialState = adapter.getInitialState({});

export const landmarkLabelReducer = createReducer(
  initialState,
  on(LandmarkLabelActions.findAllSuccess, (state: LandmarkLabelState, { landmarkLabels }) => ({
    ...adapter.setAll(landmarkLabels, state),
  })),
  on(LandmarkLabelActions.findSuccess, (state: LandmarkLabelState, { landmarkLabel }) => {
    return adapter.upsertOne(landmarkLabel, state);
  }),
  on(LandmarkLabelActions.createSuccess, (state: LandmarkLabelState, { landmarkLabel }) => {
    return adapter.addOne(landmarkLabel, state);
  }),
  on(LandmarkLabelActions.updateSuccess, (state: LandmarkLabelState, { landmarkLabel }) => {
    return adapter.upsertOne(landmarkLabel, state);
  }),
);
