import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { BookingActions } from 'app/store/booking/booking.action';
import { map, tap } from 'rxjs/operators';

import { DocumentActions, DocumentBatchActions } from './document.action';
import { DocumentHttpResponse, DocumentHttpService } from './document.service';

@Injectable()
export class DocumentEffect {
  constructor(
    private store: Store<AppState>,
    private service: DocumentHttpService,
    private message: MessageService,
  ) {}

  /**
   * @deprecated unused
   */
  find(filename: string) {
    return this.service.find(filename).pipe(
      this.message.handleHttpErrorPipe,
      map((document: RR.Document) => DocumentActions.upsertOne({ document })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(bookingId: number, data: FormData) {
    return this.service.create(bookingId, data).pipe(
      this.message.handleHttpErrorPipe,
      map((data: DocumentHttpResponse) => {
        return DocumentBatchActions.createSuccessBatch({
          actions: {
            createDocumentSuccess: DocumentActions.addOne({ document: data.document }),
            updateBookingSuccess: BookingActions.updateSuccess({
              booking: data.booking,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  updateDocumentType(filename: string, data: { type: RR.DocumentType }) {
    return this.service.updateDocumentType(filename, data).pipe(
      this.message.handleHttpErrorPipe,
      map((document: RR.Document) => DocumentActions.upsertOne({ document })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(documentId: string) {
    return this.service.delete(documentId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: DocumentHttpResponse) => {
        return DocumentBatchActions.deleteSuccessBatch({
          actions: {
            deleteDocumentSuccess: DocumentActions.removeOne({ documentId }),
            updateBookingSuccess: BookingActions.updateSuccess({
              booking: data.booking,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findDocumentsInBooking(booking_id: number) {
    return this.service.findInBooking(booking_id).pipe(
      this.message.handleHttpErrorPipe,
      map((documents: RR.Document[]) => DocumentActions.findInBookingSuccess({ booking_id, documents })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
