import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

import { AuditComponent } from '../../../editor/audit/audit.component';

@Component({
  selector: 'rr-registration-events',
  templateUrl: './registration-events.component.html',
  styleUrls: ['./registration-events.component.css'],
  standalone: true,
  imports: [CommonModule, AuditComponent],
})
export class RegistrationEventsComponent implements OnInit, OnDestroy {
  @Input() report_id: number;

  loading = true;
  events: RR.AuditEvent[] = [];
  subscription = new Subscription();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.subscription.add(
      this.http
        .get<RR.AuditEvent[]>(`/api/report/${this.report_id}/registration_events`)
        .pipe(
          take(1),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe((events) => {
          this.events = events;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
