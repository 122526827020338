import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './shortcuts-modal.component.html',
})
export class ShortcutsModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
