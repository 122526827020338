import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { PrefillSearchActions } from './prefill-search.action';

export type PrefillSearchType =
  | 'ALL'
  | 'FAVOURITES'
  | 'PRESET'
  | 'SUBDIVISION'
  | 'IMAGES'
  | 'FULL_TEXT'
  | 'DEBUG'
  | 'RELATED_STUDIES';

export type PrefillSearchFormType = {
  fullText: string;
  searchType: PrefillSearchType;
  accessionNumbers: string;
};

export interface PrefillSearchState {
  updateReason: 'SAVE_FORM' | 'UPDATE_FORM';
  searchForm: PrefillSearchFormType | null;
  /**
   * Undefined is allowed so that updateSearchForm can be called with Partial<PrefillSearchFormType> before the state is
   * first saved by the prefill form.
   */
  searchFormInit: Partial<PrefillSearchFormType> | null;
}

export const initialState: PrefillSearchState = {
  updateReason: 'SAVE_FORM',
  searchForm: null, // The default state is defined in the FormGroup
  searchFormInit: null,
};

export const prefillSearchReducer = createReducer(
  initialState,
  on(PrefillSearchActions.saveSearchForm, (state, { searchForm }) => ({
    ...state,
    updateReason: 'SAVE_FORM' as const,
    searchForm,
  })),
  on(PrefillSearchActions.updateSearchForm, (state, { searchForm }) => {
    if (state.searchForm === null) {
      return {
        ...state,
        updateReason: 'UPDATE_FORM' as const,
        searchFormInit: {
          ...state.searchFormInit,
          ...searchForm,
        },
      };
    } else {
      return {
        ...state,
        updateReason: 'UPDATE_FORM' as const,
        searchForm: {
          ...state.searchForm,
          ...searchForm,
        },
      };
    }
  }),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
