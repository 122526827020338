import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.BodyPart>();
const selectFeature = (state: AppState) => state.body_part;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectBodyPart = (id: number) =>
  createSelector(selectEntities, (bodyParts: Dictionary<RR.BodyPart>) => bodyParts[id]);

export const fromBodyPart = {
  selectIds,
  selectEntities,
  selectAll,
  selectBodyPart,
};
