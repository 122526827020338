import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { INDEX_SCROLL_ID } from 'app/app.constants';
import { scrollIntoView, trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { fromTopicChoices } from 'app/store/report/topic';
import { fromSection } from 'app/store/template/section';
import { Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { IndexSubsectionComponent } from './index-subsection.component';

@Component({
  standalone: true,
  imports: [CommonModule, DragDropModule, IndexSubsectionComponent],
  selector: 'rr-index-section',
  templateUrl: 'index-section.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      ul {
        padding-left: 5px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@LifecycleLogger
export class IndexSectionComponent implements OnDestroy, OnInit {
  // We have an index component for each section. This allows us to input the
  // section we are choosing.
  @Input() section: RR.Section;

  section_choice: RR.SectionChoice;

  // The current topic that we are using
  topic$: Observable<RR.Topic>;
  // The list of subsections that exist in the template for the current topic
  // and in the section that has been chosen
  subsections$: Observable<RR.Subsection[]>;

  jumpSubscription = new Subscription();
  subscription = new Subscription();
  trackBy = trackById;

  constructor(
    private editorService: EditorService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {
    // @ts-expect-error strictNullChecks
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    this.topic$ = this.selectorService.selectCurrentTopicIfLoaded().pipe(filter((topic: RR.Topic) => topic != null));
  }

  ngOnInit() {
    // This needs to be in the onInit since the section is not defined before initialisation
    this.subsections$ = this.store.select(fromSection.selectSubsections(this.section.id));

    this.jumpSubscription.add(
      this.editorService.jumpToSectionEvents
        .pipe(filter((e) => e != null && e.section === this.section.type))
        .subscribe(() => {
          scrollIntoView({ element: this.el.nativeElement, parent: document.querySelector('#' + INDEX_SCROLL_ID) });
          // TODO(layoutng)
          // this.el.nativeElement.scrollIntoView();
        }),
    );

    this.subscription = this.topic$
      .pipe(switchMap((topic) => this.store.select(fromTopicChoices.selectSectionChoices(topic.id, this.section.id))))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((section_choices) => {
        this.section_choice = section_choices[0];
        this.cd.markForCheck();
      });
  }

  ngOnDestroy() {
    this.jumpSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  jumpToSection() {
    this.editorService.jumpToSection(this.section);
  }

  drop(_event: CdkDragDrop<RR.Subsection>) {
    // TODO: There's no Section model to update. Can't do this cleanly.
    // this.subsections$.pipe(take(1)).subscribe((subsections) => {
    //   let newSubsections = [...subsections];
    //   moveItemInArray(newSubsections, event.previousIndex, event.currentIndex);
    //   this.store.dispatch(
    //     SectionActions.update({
    //       subsectionId: this.subsection.id,
    //       changes: {
    //         subsections: newSubsections.map((subsection) => subsection.id),
    //       },
    //     })
    //   );
    // });
  }
}
