import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { AuditEventActions } from './audit-event.action';

export type AuditEventState = EntityState<RR.AuditEvent>;

const adapter = createEntityAdapter<RR.AuditEvent>();

const initialState = adapter.getInitialState();

export const auditEventReducer = createReducer(
  initialState,
  on(AuditEventActions.findManySuccess, (state: AuditEventState, { auditEvents }) =>
    adapter.upsertMany(auditEvents, state),
  ),
  on(AuditEventActions.createSuccess, (state: AuditEventState, { auditEvent }) => adapter.addOne(auditEvent, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
