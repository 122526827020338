import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TextBreakPipe } from 'app/shared/pipes/text-break-pipe';

@Component({
  standalone: true,
  imports: [CommonModule, TextBreakPipe],
  selector: 'rr-note-text',
  templateUrl: './note-text.component.html',
  styleUrls: ['./note-text.component.css'],
})
export class NoteTextComponent {
  @Input() note: string;
}
