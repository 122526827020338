import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type PatientQuestionAnswerParentResponse = {
  report: RR.Report;
  patient: RR.Patient;
};

export type PatientQuestionAnswerCreationHttpResponse = {
  patient_question_answer: RR.PatientQuestionAnswer;
} & PatientQuestionAnswerParentResponse;

@Injectable()
export class PatientQuestionAnswerHttpService {
  constructor(private http: HttpClient) {}

  findInReport(reportId: number): Observable<RR.PatientQuestionAnswer[]> {
    return this.http.get<RR.PatientQuestionAnswer[]>(`/api/report/${reportId}/patient_question_answer`);
  }

  create(
    reportId: number,
    patientQuestionAnswer: Partial<RR.PatientQuestionAnswer>,
  ): Observable<PatientQuestionAnswerCreationHttpResponse> {
    return this.http.post<PatientQuestionAnswerCreationHttpResponse>(
      `/api/report/${reportId}/patient_question_answer`,
      patientQuestionAnswer,
    );
  }

  update(
    patientQuestionAnswerId: number,
    changes: Partial<RR.PatientQuestionAnswer>,
  ): Observable<RR.PatientQuestionAnswer> {
    return this.http.put<RR.PatientQuestionAnswer>(`/api/patient_question_answer/${patientQuestionAnswerId}`, changes);
  }

  delete(patientQuestionAnswerId: number) {
    return this.http.delete<PatientQuestionAnswerParentResponse>(
      `/api/patient_question_answer/${patientQuestionAnswerId}`,
    );
  }
}
