import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store';
import { UserNotificationsEffect } from 'app/store/notifications/notifications.effect';
import { fromNotifications } from 'app/store/notifications/notifications.selector';
import { Observable, Subscription, map, switchMap } from 'rxjs';

import { UserNotificationComponent } from './user-notification/user-notification.component';

@Component({
  selector: 'rr-notifications-modal',
  standalone: true,
  imports: [CommonModule, UserNotificationComponent],
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.css'],
})
export class NotificationsModalComponent implements OnDestroy, OnInit {
  subscription = new Subscription();

  notifications$: Observable<RR.UserNotification[]>;
  unreadNotifications$: Observable<RR.UserNotification[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private selectorService: SelectorService,
    private store: Store<AppState>,
    private notificationsEffect: UserNotificationsEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.notificationsEffect.findAll().subscribe());

    const user$ = this.selectorService.selectLoadedCurrentUser().pipe(filterDefined());

    this.notifications$ = user$.pipe(
      switchMap((user) =>
        this.store
          .select(fromNotifications.selectAll)
          .pipe(map((notifications) => notifications.filter((n) => n.recipient_id === user.id && n.read))),
      ),
    );

    this.unreadNotifications$ = user$.pipe(
      switchMap((user) =>
        this.store
          .select(fromNotifications.selectAll)
          .pipe(map((notifications) => notifications.filter((n) => n.recipient_id === user.id && !n.read))),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(NotificationsModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    return modalRef;
  }
}
