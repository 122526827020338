import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReferrer } from 'app/store/referrer';
import { Observable, switchMap } from 'rxjs';

/**
 * Combines the Referrer title, given name, and family name into a string.
 */
@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'rr-referrer-name',
  templateUrl: './referrer-name.component.html',
  styleUrls: ['./referrer-name.component.css'],
})
export class ReferrerNameComponent implements OnInit {
  @Input() @BindObservable() referrerId: number;
  @Input() strong = true;
  @Input() referrerType = false;
  @Input() editReferrer = false;
  referrerId$: Observable<number>;

  referrer$: Observable<RR.Referrer | undefined>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.referrer$ = this.referrerId$.pipe(switchMap((id) => this.store.select(fromReferrer.selectReferrer(id))));
  }
}
