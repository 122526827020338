import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NgbModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ELEMENT_SCROLL_ID } from 'app/app.constants';
import { filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { IElement, TemplateService } from 'app/core/services/template.service';
import { EditLandmarkModalComponent } from 'app/modules/report/modals/edit-landmark-modal/edit-landmark-modal.component';
import { AppState } from 'app/store';
import { Breadcrumb, fromBreadcrumb } from 'app/store/editor';
import { fromLandmarkLabel } from 'app/store/landmark-label/landmark-label.selector';
import { RegionChoiceEffect } from 'app/store/report/region-choice';
import { fromCurrentReport } from 'app/store/report/report';
import { fromCurrentTopic, fromTopicChoices } from 'app/store/report/topic';
import { ElementEffect } from 'app/store/template/element';
import { fromRegionSet } from 'app/store/template/region-set';
import { fromSection } from 'app/store/template/section';
import { fromStatementSet } from 'app/store/template/statement-set';
import { fromCurrentTemplate } from 'app/store/template/template';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, publishReplay, refCount, skipWhile, switchMap, take } from 'rxjs/operators';

import { StoreSelectPipe } from '../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { ElementComponent } from '../element/element.component';
import { LandmarkLabelHeadlineComponent } from '../prefill/landmark-label-headline/landmark-label-headline.component';
import { SubsectionEditModalComponent } from '../subsection-edit-modal/subsection-edit-modal.component';
import { SubsectionBreadcrumbComponent } from './subsection-breadcrumb/subsection-breadcrumb.component';

@Component({
  selector: 'rr-topic',
  templateUrl: 'topic.component.html',
  styleUrls: ['topic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    SharedModule,
    SubsectionBreadcrumbComponent,
    LandmarkLabelHeadlineComponent,
    ElementComponent,
    StoreSelectPipe,
  ],
})
@LifecycleLogger
export class TopicComponent implements OnInit, OnDestroy {
  @Output() onExpand = new EventEmitter();

  topic$: Observable<RR.Topic>;
  topicLoadedTopic$: Observable<RR.Topic>;
  report$: Observable<RR.Report>;
  breadcrumb$: Observable<Breadcrumb>;

  dropdownSubsections$: Observable<RR.Subsection[]>;
  dropdownStatementSets$: Observable<RR.StatementSet[]>;

  selectedSection$: Observable<RR.Section | undefined>;
  selectedSubsection$: Observable<RR.Subsection>;
  selectedElement$: Observable<RR.Element>;
  selectedRegion$: Observable<RR.Region | undefined>;

  subscription = new Subscription();

  elements: IElement[] = [];

  // Read only. Only updated by store callbacks.

  selectedIndex = 0;
  // breadcrumb: TopicState['breadcrumb'];

  sections: RR.Section[] = [];
  subsections: RR.Subsection[] = [];

  subsectionChoice: RR.SubsectionChoice;
  activeRegion: RR.Region | undefined;
  activeRegionChoice: RR.RegionChoice;
  region_choices: RR.RegionChoice[] = [];
  region_set$: Observable<RR.RegionSet>;
  regions$: Observable<RR.Region[]>;
  previousRegionChoice: RR.RegionChoice;
  nextRegionChoice: RR.RegionChoice;
  copyingRegion = false;
  isQuickNavOpen = false;

  getStatementSetName(statement_set_id: number) {
    return this.store.select(fromStatementSet.selectStatementSetName(statement_set_id));
  }

  constructor(
    private reportService: ReportService,
    private modalService: NgbModal,
    private templateService: TemplateService,
    private editorService: EditorService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private regionChoiceEffect: RegionChoiceEffect,
    private selectorService: SelectorService,
    private elementEffect: ElementEffect,
  ) {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.report$ = this.store.select(fromCurrentReport.selectReport).pipe(filterDefined());

    this.subscription.add(
      this.store.select(fromCurrentTemplate.selectSections).subscribe((sections) => {
        this.sections = sections;
      }),
    );
    this.breadcrumb$ = this.store.select(fromBreadcrumb.selectCurrent).pipe(filterDefined());
    this.dropdownSubsections$ = this.isTemplateLoaded().pipe(
      skipWhile((isLoaded) => !isLoaded),
      switchMap(() => this.store.select(fromBreadcrumb.selectSubsectionOptions)),
    );
    // @ts-expect-error strictNullChecks
    this.dropdownStatementSets$ = this.isTemplateLoaded().pipe(
      skipWhile((isLoaded) => !isLoaded),
      switchMap(() => this.store.select(fromBreadcrumb.selectStatementSetOptions)),
    );

    this.selectedSection$ = this.store.select(fromBreadcrumb.selectCurrentSection);
    // @ts-expect-error strictNullChecks
    this.selectedSubsection$ = this.store.select(fromBreadcrumb.selectCurrentSubsection);
    this.selectedRegion$ = this.store.select(fromBreadcrumb.selectCurrentRegion);
    // @ts-expect-error strictNullChecks
    this.selectedElement$ = this.store.select(fromBreadcrumb.selectCurrentElement);
  }

  ngOnInit() {
    this.subscription.add(
      this.store
        .select(fromCurrentTopic.selectTopic)
        .pipe(
          filterDefined(),
          // Only run when the Topic to a different id, not when the Topic is updated
          distinctUntilChanged((a, b) => a.id === b.id),
          switchMap(() => {
            return this.breadcrumb$.pipe(
              switchMap((breadcrumb) => {
                // Only select a default breadcrumb if there is no breadcrumb
                if (breadcrumb.section_id !== null) {
                  return EMPTY;
                }
                return this.isTemplateLoaded().pipe(
                  skipWhile((isLoaded) => !isLoaded),
                  switchMap(() => this.store.select(fromCurrentTemplate.selectSections)),
                  take(1),
                );
              }),
            );
          }),
        )
        .subscribe((sections) => {
          this.selectSection(sections[0]);
        }),
    );

    this.subscription.add(
      this.breadcrumb$
        .pipe(
          switchMap((breadcrumb) =>
            breadcrumb.element_id
              ? this.topic$.pipe(
                  take(1),
                  map((topic) => ({
                    topic,
                    breadcrumb,
                  })),
                )
              : of(undefined),
          ),
        )
        .subscribe((responseObj) => {
          if (responseObj) {
            const { topic, breadcrumb } = responseObj;
            this.editorService.publishHighlight(
              {
                element_id: breadcrumb.element_id,
                topic_id: topic.id,
                region_id: breadcrumb.region_id,
                source: 'BREADCRUMB',
              },
              { highlight: true, scroll: true },
            );
            this.selectedIndex = this.getIndex();
            requestAnimationFrame(() => {
              const elementScroll: HTMLElement | null = document.querySelector('#' + ELEMENT_SCROLL_ID);
              if (elementScroll) {
                elementScroll.scrollTop = 0;
              }
            });
          }
        }),
    );

    this.subscription.add(
      this.topic$
        .pipe(switchMap((topic) => this.templateService.getElements(topic.template_id)))
        .subscribe((elements) => {
          this.elements = elements;
        }),
    );

    this.subscription.add(
      this.selectedSection$
        .pipe(filterDefined())
        .pipe(switchMap((selectedSection) => this.store.select(fromSection.selectSubsections(selectedSection.id))))
        .subscribe((subsections) => {
          this.subsections = subsections;
        }),
    );

    // Subscribe to element update event
    this.subscription.add(
      // This is a workaround. Probably can be fixed by moving the state in this component to the store.
      this.elementEffect.elementUpdateEvents.subscribe(
        (event: { type: 'CREATE_ELEMENT' | 'REMOVE_ELEMENT'; elementId: number }) => {
          if (event.type === 'REMOVE_ELEMENT') {
            this.selectedIndex > 0
              ? this.selectElement(this.elements[this.selectedIndex - 1].element.id)
              : this.selectElement(this.elements[0].element.id);
          } else {
            const newEl = this.elements.find((e) => {
              return e.element.id === event.elementId;
            });
            if (newEl) {
              this.selectElement(newEl.element.id);
            } else {
              // Fallback to first element
              this.selectElement(this.elements[0].element.id);
            }
          }
        },
      ),
    );

    this.editorService.registerEditorShortcuts = () => this.registerKeyboardShortcuts();
    this.editorService.unregisterEditorShortcuts = () => this.editorService.unregisterKeyboardShortcuts();

    // Init regions and active regions
    this.initRegions();
  }

  /**
   * Init data for regions and active region
   */
  initRegions() {
    // Get region set and regions
    this.subscription.add(
      this.selectedSubsection$.subscribe((subsection) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (subsection?.region_set_id) {
          this.region_set$ = this.store
            .select(fromRegionSet.selectRegionSet(subsection.region_set_id))
            .pipe(filterDefined());
          // @ts-expect-error strictNullChecks
          this.regions$ = this.store.select(fromRegionSet.selectRegions(subsection.region_set_id));
        } else {
          // @ts-expect-error strictNullChecks
          this.region_set$ = of(null);
          this.regions$ = of([]);
        }
      }),
    );

    // Get subsection choice, region choices and active region
    const selectedSubsectionChoice: Observable<RR.SubsectionChoice> = combineLatest([
      this.selectorService.selectCurrentTopicIfLoaded().pipe(filterDefined()),
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
      this.breadcrumb$.pipe(filter((breadcrumb) => breadcrumb != null)),
    ])
      .pipe(
        switchMap(([topic, breadcrumb]) => {
          const subsection_id = breadcrumb.subsection_id;
          const section = breadcrumb.section_id;
          return (
            this.store
              // @ts-expect-error strictNullChecks
              .select(fromTopicChoices.selectSubsectionChoices(topic.id, section, subsection_id))
              .pipe(map((subsectionChoices) => subsectionChoices[0]))
          );
        }),
      )
      // without replay() region_choices didn't update unless you selected the subsection twice
      .pipe(publishReplay(1), refCount());
    this.subscription.add(
      selectedSubsectionChoice.subscribe((sc) => {
        this.subsectionChoice = sc;
      }),
    );

    const regionChoices$ = selectedSubsectionChoice.pipe(
      switchMap((sc) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (sc) {
          return this.reportService.getRegionChoices(sc.id);
        }
        return of([] as RR.RegionChoice[]);
      }),
    );
    this.subscription.add(
      regionChoices$.subscribe((region_choices) => {
        // @ts-expect-error strictNullChecks
        this.region_choices = region_choices;
      }),
    );

    const activeRegionChoice$ = combineLatest(regionChoices$, this.selectedRegion$).pipe(
      map(([region_choices, activeRegion]) => {
        let activeRegionChoice = null;
        if (activeRegion) {
          // @ts-expect-error strictNullChecks
          activeRegionChoice = region_choices.find((rc) => rc.region_id === activeRegion.id);
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!activeRegionChoice) {
          activeRegionChoice = region_choices[0];
        }
        return activeRegionChoice;
      }),
    );

    this.subscription.add(
      activeRegionChoice$.subscribe((activeRegionChoice) => {
        // @ts-expect-error strictNullChecks
        this.activeRegionChoice = activeRegionChoice;
        this.cd.markForCheck();
      }),
    );

    // Get index of active region, prev and next regions. They are used for moving between regions
    this.subscription.add(
      this.selectedRegion$.subscribe((activeRegion) => {
        this.activeRegion = activeRegion;
        this.cd.markForCheck();
        // Get index for next/previous active regions
        if (activeRegion) {
          const activeIndex = this.region_choices.findIndex((tr) => tr.region_id === activeRegion.id);
          this.previousRegionChoice = this.region_choices[activeIndex - 1];
          this.nextRegionChoice = this.region_choices[activeIndex + 1];
        }
      }),
    );
  }

  isTemplateLoaded(): Observable<boolean> {
    return this.templateService.isCurrentTemplateLoaded();
  }

  /**
   * Add shortcuts for moving between elements/subsections/sections
   */
  registerKeyboardShortcuts() {
    this.editorService.registerKeyboardShortcuts({
      right: () => this.next(),
      left: () => this.previous(),
      'shift.left': () => this.previousSubsection(),
      'shift.right': () => this.nextSubsection(),
      'alt.left': () => this.previousSection(),
      'alt.right': () => this.nextSection(),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.editorService.unregisterKeyboardShortcuts();
  }

  // Check if a region is selected in the report
  checkSelectedRegion(region_id: number): boolean {
    const region = this.region_choices.find((r) => r.region_id === region_id);
    return !!region;
  }

  // Check to add or remove a region from region choices
  addOrRemoveRegion(region_id: number) {
    this.subscription.add(
      combineLatest([this.topic$, this.selectedSubsection$])
        .pipe(
          take(1),
          // add switchMap to avoid nested subscribe
          switchMap(([topic, selectedSubsection]) => {
            const region_choice = this.region_choices.find((rc) => rc.region_id === region_id);
            if (region_choice) {
              return this.regionChoiceEffect.delete(region_choice.id);
            } else {
              return this.regionChoiceEffect.create({
                topicId: topic.id,
                subsectionId: selectedSubsection.id,
                regionId: region_id,
              });
            }
          }),
        )
        .subscribe(),
    );
  }

  getIndex(): number {
    let index: number;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.selectedElement$.pipe(take(1)).subscribe((selectedElement) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (selectedElement == null) {
        // @ts-expect-error strictNullChecks
        index = null;
      } else {
        index = this.elements.findIndex((e) => e.element.id === selectedElement.id);
      }
    });
    // @ts-expect-error strictNullChecks
    return index;
  }

  getSubsectionIndex(): number {
    let index: number;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.selectedSubsection$.pipe(take(1)).subscribe((selectedSubsection) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (selectedSubsection == null) {
        // @ts-expect-error strictNullChecks
        index = null;
      } else {
        index = this.subsections.findIndex((s) => s.id === selectedSubsection.id);
      }
    });
    // @ts-expect-error strictNullChecks
    return index;
  }

  getSectionIndex(): number {
    let index: number;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.selectedSection$.pipe(take(1)).subscribe((selectedSection) => {
      if (selectedSection === undefined) {
        // @ts-expect-error strictNullChecks
        index = null;
      } else {
        index = this.sections.indexOf(selectedSection);
      }
    });
    // @ts-expect-error strictNullChecks
    return index;
  }

  // Get next element in the same subsection of selected element
  getNextElement() {
    let nextElement: IElement | undefined = undefined;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.selectedElement$.pipe(take(1)).subscribe((selectedElement) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (selectedElement) {
        nextElement = this.elements.find(
          (element, index) => element.subsection.id === selectedElement.subsection_id && index > this.getIndex(),
        );
      }
    });
    return nextElement;
  }

  // Move between elements
  previous() {
    const index = this.getIndex();
    // if (index === -1) {
    //   this.previousSubsection();
    //   return;
    // }
    if (index > 0) {
      this.selectElement(this.elements[index - 1].element.id);
      this.cd.markForCheck();
    }
  }

  next() {
    const index = this.getIndex();
    // if (index === -1) {
    //   this.nextSubsection();
    //   return;
    // }
    if (index < this.elements.length - 1) {
      this.selectElement(this.elements[index + 1].element.id);
      this.cd.markForCheck();
    }
  }

  // Move between subsections
  previousSubsection() {
    let index = this.getSubsectionIndex();
    if (index > 0) {
      index--;
      this.selectSubsection(this.subsections[index]);
      this.cd.markForCheck();
    }
  }

  nextSubsection() {
    let index = this.getSubsectionIndex();
    if (index < this.subsections.length - 1) {
      index++;
      this.selectSubsection(this.subsections[index]);
      this.cd.markForCheck();
    }
  }

  // Move between sections
  previousSection() {
    let index = this.getSectionIndex();
    if (index > 0) {
      index--;
      this.selectSection(this.sections[index]);
      this.cd.markForCheck();
    }
  }

  nextSection() {
    let index = this.getSectionIndex();
    if (index < this.sections.length - 1) {
      index++;
      this.selectSection(this.sections[index]);
      this.cd.markForCheck();
    }
  }

  findFirstElement(subsection_id: number) {
    return this.elements.find((element) => {
      return element.subsection.id === subsection_id;
    });
  }

  // Select section/ subsection/ element from dropdown sections/subsections/elements
  selectSection(section: RR.Section) {
    this.editorService.selectSection(section);
  }

  selectSubsection(subsection: RR.Subsection) {
    this.editorService.selectSubsection(subsection.id);
  }

  selectElementByStatementSetId(statement_set_id: number) {
    const element = this.elements.find((e) => {
      return e.element.statement_set_id === statement_set_id;
    });
    // @ts-expect-error strictNullChecks
    this.selectElement(element.element.id);
  }

  selectElement(elementId: number) {
    this.editorService.selectElement(elementId);
  }

  /**
   * Select a region from dropdown, set region_choice associate with it to be active region
   * @param region_id
   */
  selectRegion(region_id: number) {
    this.editorService.selectRegion(region_id);
  }

  /**
   * Handle copy from region button
   * @param from_region_choice
   * @param to_region_choice
   * @param event
   */
  copyFromRegion(from_region_choice: RR.RegionChoice, to_region_choice: RR.RegionChoice, event: MouseEvent) {
    this.copyingRegion = true;
    event.preventDefault();
    event.stopPropagation(); // To stop the underlying card-header from being clicked

    // TODO (template-structure): Remove the need to pass the topicId, instead
    // use the current id from the store.
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.topic$.pipe(take(1)).subscribe((topic) =>
      this.reportService
        .copyRegionChoices(topic.id, from_region_choice.id, to_region_choice.id)
        .pipe(take(1))
        /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
        .subscribe(() => {
          this.copyingRegion = false;
          this.cd.markForCheck();
        }),
    );
  }

  nextRegion() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.nextRegionChoice) {
      this.selectRegion(this.nextRegionChoice.region_id);
    }
  }

  previousRegion() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.previousRegionChoice) {
      this.selectRegion(this.previousRegionChoice.region_id);
    }
  }

  openSubsectionEditModal() {
    // this.topic$.pipe(take(1)).subscribe((topic) =>
    //   SubsectionEditModalComponent.open(this.modalService, {
    //     topic,
    //     nextElement: this.getNextElement(),
    //     subsection_choice: this.subsectionChoice,
    //   }),
    // );
    SubsectionEditModalComponent.open(this.modalService, {
      nextElement: this.getNextElement(),
      subsection_choice: this.subsectionChoice,
    });
  }

  editLandmark(data: { subsection_id?: number; region_id?: number; landmark_label_id: string | null }) {
    EditLandmarkModalComponent.open(this.modalService, data);
  }

  selectLandmarkLabel = fromLandmarkLabel.selectLandmarkLabel;
}
