import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TUTORIALS } from 'app/app.constants';

export type TutorialType = keyof typeof TUTORIALS;
@Component({
  standalone: true,
  imports: [],
  templateUrl: './tutorial-modal.component.html',
})
export class TutorialModalComponent implements OnInit {
  @Input() tutorial_type: TutorialType;
  tutorial_title: string;
  tutorial_notes: string;
  tutorial_link: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.tutorial_title = TUTORIALS[this.tutorial_type].TUTORIAL_TITLE;
    this.tutorial_notes = TUTORIALS[this.tutorial_type].TUTORIAL_NOTES;

    switch (this.tutorial_type) {
      case 'IMAGES':
        // use file-loader manually because the jstests don't know how to load this
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-view-images.mp4';
        break;
      case 'GLOBAL_SEARCH':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-search-for-sentences.mp4';
        break;
      case 'START_TEMPLATE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-start-a-template.mp4';
        break;
      case 'NAME_FAVOURITE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-name-a-favourite.mp4';
        break;
      case 'MAKE_FINDING_COMMENT':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-make-a-finding-a-comment.mp4';
        break;
      case 'FIND_DELETED_SENTENCES':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-find-sentences-that-were-deleted.mp4';
        break;
      case 'DELETE_ADD_SENTENCE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-delete-and-add-new-sentence.mp4';
        break;
      case 'ADD_DIMENSIONS_IN_SENTENCES':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-dimensions-into-sentences.mp4';
        break;
      case 'ADD_SIG':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-signatures-onto-the-report.mp4';
        break;
      case 'SMS_LINK_TO_PATIENT':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-sms-a-link-to-a-patient.mp4';
        break;
      case 'VIEW_PREVIOUS_REPORTS':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-view-patients-previous-reports.mp4';
        break;
      case 'VIEW_UPDATES':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-view-updates-on-RadReport.mp4';
        break;
      case 'KEYBOARD_SHORTCUTS':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/keyboard-shortcuts-for-RadReport.mp4';
        break;
      case 'ADD_DUE_DATE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-add-a-due-date-to-a-report.mp4';
        break;
      case 'ADD_ATTRIBUTES':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-attributes-to-a-sentence.mp4';
        break;
      case 'ACCESS_RECENT_SEARCHES':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-access-your-most-recent-searches.mp4';
        break;
      case 'ADD_SAME_SENTENCE_REGIONS':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-the-same-sentence-to-another-region.mp4';
        break;
      case 'ADD_TODO_URGENT_NOTES':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-to-do-lists-and-urgent-notes.mp4';
        break;
      case 'FLAG_SENTENCES':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-flag-sentences.mp4';
        break;
      case 'VIEW_WORKLIST':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-view-the-worklist.mp4';
        break;
      case 'CONCURRENT':
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-view-who-is-editing-the-report.mp4';
        break;
      case 'ADD_TITLE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-add-a-report-title.mp4';
        break;
      case 'MOVE_SENTENCE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-move-sentences-in-a-report.mp4';
        break;
      case 'PREFILL_REPORT':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-prefill-a-report.mp4';
        break;
      case 'CHECK_REVIEW_REPORT':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-check-and-review-a-report.mp4';
        break;
      case 'ADD_A_SENTENCE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-add-a-sentence.mp4';
        break;
      case 'FIND_AND_SELECT_A_TEMPLATE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-find-and-select-a-template.mp4';
        break;
      case 'DUPLICATE_A_SENTENCE':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-duplicate-a-sentence.mp4';
        break;
      case 'LEAVE_MESSAGES_IN_FLAG':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-leave-messages-in-a-flag.mp4';
        break;
      case 'SEE_ONLY_MY_FAVOURITES':
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-see-only-my-favourites.mp4';
        break;
      case 'CHECK_MY_PERFORMANCE': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-check-my-performance.mp4';
        break;
      }
      case 'CHECK_THE_EDITORS': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-check-the-editors.mp4';
        break;
      }
      case 'FILTER_THE_WORKLIST': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-filter-the-worklist.mp4';
        break;
      }
      case 'NAV_AROUND': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-navigate-around-the-panels.mp4';
        break;
      }
      case 'SEARCH_PATIENT': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-search-for-a-patient.mp4';
        break;
      }
      case 'COPY_REPORT_TO_CLIPBOARD': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-copy-a-report-to-clipboard.mp4';
        break;
      }
      case 'EDIT_PATIENT_METADATA': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-edit-patient-metadata.mp4';
        break;
      }
      case 'PRINT_REPORT': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-print-a-report-from-RadReport.mp4';
        break;
      }
      case 'VIEW_REPORT_WITHOUT_OPENING_EDITOR': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-view-a-report-without-opening-the-report-editor.mp4';
        break;
      }
      case 'PREFILL_PREVIOUS_REPORT': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-prefill-a-template-using-previous-reports.mp4';
        break;
      }
      case 'CHOOSE_APPROPRIATE_ATTRIBUTE': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-choose-the-most-appropriate-attribute.mp4';
        break;
      }
      case 'ADD_PRIOR_REPORTS': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-history-of-previous-reports.mp4';
        break;
      }
      case 'ADD_DEFAULT_SENTENCES': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-add-default-sentences.mp4';
        break;
      }
      case 'WAYS_TO_PREFILL': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/4-ways-to-prefill.mp4';
        break;
      }
      case 'PREFILL_ADD_DELETE_SENTENCES': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-add-or-delete-sentences-using-prefill-report.mp4';
        break;
      }
      case 'PREFILL_DIVIDER_SENTENCES': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-choose-similar-prefill-divider-sentences.mp4';
        break;
      }
      case 'EDIT_PREFILL_DIVIDER_SENTENCES': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-edit-prefill-divider-sentences.mp4';
        break;
      }
      case 'FAX_FROM_RADREPORT': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-fax-from-radreport.mp4';
        break;
      }
      case 'PREFILL_WHOLE_REPORT': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-prefill-a-whole-report-including-numbers.mp4';
        break;
      }
      case 'PREFILL_HISTORY_TECH': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-prefill-history-and-technique.mp4';
        break;
      }
      case 'PREFILL_SUBSECTION': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-prefill-subsections.mp4';
        break;
      }
      case 'USE_PREVIOUS_REPORT': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-prefill-using-a-patients-previous-report.mp4';
        break;
      }
      case 'START_REPORT_USING_PREFILL': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-start-a-report-using-a-prefill.mp4';
        break;
      }
      case 'USE_FAVOURITE_TAGS': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-use-most-common-favourite-tags.mp4';
        break;
      }
      case 'PREFILL_TOP_SIX_ROWS': {
        this.tutorial_link =
          'https://radreport.adelaidemri.com/download/videos/how-to-use-The-Prefill-Top-6-Rows-Function.mp4';
        break;
      }
      case 'PREFILL_USING_DOB': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-prefill-using-DOB.mp4';
        break;
      }
      case 'HOW_TO_CATEGORISE_SENTENCES': {
        this.tutorial_link = 'https://radreport.adelaidemri.com/download/videos/how-to-categorise-sentences.mp4';
        break;
      }
    }
  }

  static open(modalService: NgbModal, tutorial_type: TutorialType) {
    const modalRef = modalService.open(TutorialModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    modalRef.componentInstance.tutorial_type = tutorial_type;
    return modalRef;
  }
}
