const transformSex: Map<RR.Sex, MedipassPatientSex> = new Map();
transformSex.set('female', 'F');
transformSex.set('male', 'M');
transformSex.set('other', 'O');

export const medipassHelpers = {
  getPatientSex(patient: RR.Patient): MedipassPatientSex {
    if (!patient.patient_sex) return undefined;
    return transformSex.get(patient.patient_sex);
  },
  getTransformSex(sex: RR.Sex): MedipassPatientSex {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!sex) return undefined;
    return transformSex.get(sex);
  },
  getReferrerName(referrer: RR.Referrer) {
    // `referrer.title` too?
    return [referrer.physician_given_name, referrer.physician_family_name].filter((n) => !!n).join(' ');
  },
};
