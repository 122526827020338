import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { LabelActions } from './label.action';

export type LabelState = EntityState<RR.Label>;

const adapter = createEntityAdapter<RR.Label>();
const initialState = adapter.getInitialState();

export const LabelReducer = createReducer(
  initialState,
  on(LabelActions.findAllSuccess, (state: LabelState, { labels }) => adapter.upsertMany(labels, state)),
  on(LabelActions.createSuccess, (state: LabelState, { label }) => adapter.addOne(label, state)),
  on(LabelActions.deleteSuccess, (state: LabelState, { label_id }) => adapter.removeOne(label_id.toString(), state)),
  on(LabelActions.updateSuccess, (state: LabelState, { label }) => adapter.upsertOne(label, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
