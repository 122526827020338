import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for PatientNote
 *
 */
export const PatientNoteCategoryActions = {
  findManySuccess: createAction(
    '[PatientNoteCategory] Find Many Success',
    props<{ patient_note_categories: RR.NoteCategory[] }>(),
  ),
  createSuccess: createAction(
    '[PatientNoteCategory] Create Success',
    props<{ patient_note_category: RR.NoteCategory }>(),
  ),
  delete: createAction('[PatientNoteCategory] Delete', props<{ patient_note_category_id: number }>()),
};
