import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { UserActions } from '../user/user/user.action';

export const BookingRateActions = {
  create: createAction('[BookingRate] Create', props<{ bookingRate: RR.BookingRate }>()),
  upsertMany: createAction('[BookingRate] Upsert Many', props<{ bookingRates: RR.BookingRate[] }>()),
};

export const BookingRateBatchActions = {
  create: createBatchAction(
    '[BookingRate] Create Batch',
    props<{
      actions: {
        bookingRate: ReturnType<typeof BookingRateActions.create>;
        user: ReturnType<typeof UserActions.upsertOne>;
      };
    }>(),
  ),
};
