import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, strToNum } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ModalTimerService } from 'app/core/services/modal-timer.service';
import { SelectorService } from 'app/core/services/selector.service';
import { UserFeedbackListComponent } from 'app/modules/feedback/components/user-feedback-list/user-feedback-list.component';
import { FeedbackModalComponent } from 'app/modules/feedback/modals/feedback-modal/feedback-modal.component';
import { DocumentationButtonDropdownComponent } from 'app/shared/components/documentation-button-dropdown/documentation-button-dropdown.component';
import { AppState } from 'app/store';
import { FeedbackHttpService } from 'app/store/report/feedback';
import { fromSession, SessionActions } from 'app/store/session';
import { fromSetting } from 'app/store/setting/setting.selector';
import { Subscription, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Component({
  standalone: true,
  selector: 'rr-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss'],
  imports: [CommonModule, NgbModule, RouterModule, UserFeedbackListComponent, DocumentationButtonDropdownComponent],
})
export class ShortcutsComponent implements OnInit, OnDestroy {
  rrConfig = rrConfig;
  currentUser: RR.User;
  noFeedbacks = 0;
  subscription = new Subscription();
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();

  reportId: number | undefined;
  reportFeedbackCount = 0;

  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private editorService: EditorService,
    private selectorService: SelectorService,
    private feedbackService: FeedbackHttpService,
    private route: ActivatedRoute,
    private modalTimerService: ModalTimerService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.route.params
        .pipe(
          switchMap((params: { [key: string]: string }) => {
            if (params['report_id']) {
              return of(strToNum(params['report_id']));
            }
            return of(undefined);
          }),
        )
        .subscribe((reportId) => {
          this.reportId = reportId;
        }),
    );

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((currentUser) => {
        // @ts-expect-error strictNullChecks
        this.currentUser = currentUser;
      }),
    );

    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(
          // Prevent user already being loaded but set to null on logout
          take(1),
          filterDefined(),
          switchMap((user) =>
            this.feedbackService.getFeedbackStats({ initials: user.initials, reportId: this.reportId }).pipe(
              switchMap((ret) => {
                this.reportFeedbackCount = ret.report_feedback_count;
                this.noFeedbacks = ret.feedback_count;

                if (this.noFeedbacks > 0 && this.modalTimerService.hasModalIntervalExpired('feedbackModal')) {
                  return this.store.select(fromSession.selectUserFeedbackNotification).pipe(
                    take(1),
                    tap((notified) => {
                      if (!notified) {
                        this.openFeedbackModal();
                        this.store.dispatch(SessionActions.notifiedUserFeedbacks());
                      }
                    }),
                  );
                } else {
                  return of(null);
                }
              }),
            ),
          ),
        )
        .subscribe(),
    );
  }

  checkUserClockedOn() {
    return this.store.select(fromSetting.selectUserClockedOn);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openFeedbackModal() {
    const modalRef = FeedbackModalComponent.open(this.modal, this.noFeedbacks, this.reportId);
    modalRef.result.then(
      (noFeedbacks) => {
        this.noFeedbacks = noFeedbacks;
      },
      () => {
        /* modal dismissed */
      },
    );
  }
}
