<div>
  <div class="mb-1">
    <strong>Category:</strong>
    {{ selectedCategory?.title || 'None' }}
    <div>
      <small class="text-danger">Click search box to show all </small>
      <br />
      <small class="text-danger">Select category from drop-menu, or save <strong>new</strong></small>
    </div>
  </div>
  <div class="form-group mb-2 input-group no-shrink">
    <input
      class="form-control"
      type="text"
      #searchInputText
      placeholder="Search categories"
      [formControl]="title"
      [ngbTypeahead]="searchCategories"
      [resultTemplate]="rt"
      (focus)="focus$.next(searchInputText.value)"
      [inputFormatter]="formatter"
      (selectItem)="selectCategory($event.item)"
    />
    <button
      *ngIf="title.getRawValue().length || selectedCategory"
      (click)="clearCategory()"
      class="input-group-text btn btn-secondary px-2"
    >
      <span class="fa fa-times"></span>
    </button>
    <button
      [disabled]="
        (referrerNoteCategoriesTitles || patientNoteCategoriesTitles | includes: title.getRawValue()) ||
        !title.getRawValue().length
      "
      (click)="createNoteCategory()"
      class="input-group-text btn btn-primary"
    >
      Save
    </button>
  </div>
</div>
<ng-template #rt let-c="result" let-t="term">
  <div *ngIf="c.title">
    <ngb-highlight class="me-1" [result]="c.title" [term]="t"></ngb-highlight>
  </div>
</ng-template>
