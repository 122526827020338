import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotesComponent } from 'app/shared/components/notes/notes.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { fromPatient } from 'app/store/patient';
import { fromReferrer } from 'app/store/referrer';

@Component({
  standalone: true,
  imports: [CommonModule, StoreSelectPipe, NotesComponent],
  selector: 'rr-create-note-modal',
  templateUrl: './create-note-modal.component.html',
})
export class CreateNoteModalComponent {
  referrerId?: number;
  patientId?: number;
  reportId: number | undefined;
  referrerSelector = fromReferrer.selectReferrer;
  patientSelector = fromPatient.selectPatient;

  constructor(public activeModal: NgbActiveModal) {}

  static open({
    modalService,
    referrerId,
    patientId,
    reportId,
  }: {
    modalService: NgbModal;
    referrerId?: number | undefined;
    patientId?: number | undefined;
    reportId?: number | undefined;
  }) {
    const modalRef = modalService.open(CreateNoteModalComponent, {
      size: 'lg',
      centered: true,
      windowClass: 'create-note-modal',
    });
    const self: CreateNoteModalComponent = modalRef.componentInstance;
    self.referrerId = referrerId;
    self.patientId = patientId;
    self.reportId = reportId;

    return modalRef;
  }
}
