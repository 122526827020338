import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { FeedbackActions } from './feedback.action';

export type FeedbackState = EntityState<RR.Feedback>;

const adapter = createEntityAdapter<RR.Feedback>();

const initialState = adapter.getInitialState();

export const feedbackReducer = createReducer(
  initialState,
  on(FeedbackActions.findAllSuccess, (state: FeedbackState, { feedbacks }) => adapter.setAll(feedbacks, state)),
  on(FeedbackActions.findManySuccess, (state: FeedbackState, { feedbacks }) => adapter.upsertMany(feedbacks, state)),
  on(FeedbackActions.createSuccess, (state: FeedbackState, { feedback }) => adapter.addOne(feedback, state)),
  on(FeedbackActions.updateSuccess, (state: FeedbackState, { feedback }) => adapter.upsertOne(feedback, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
