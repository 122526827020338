<div class="sticky-top">
  <input class="form-control" type="text" [formControl]="search_string" placeholder="search" rrAutoFocus />
  <div *ngIf="isEditingTitle && search_string.value === '' && mode === 'dropdown'">
    <button (click)="saveTitle()" class="btn btn-success w-100 my-1">Save new title</button>
  </div>
</div>

<ng-container *ngIf="!loading; else termsLoading">
  <div *ngIf="!search_string.value.trim()">
    <div
      *ngFor="let option_array of conflated_options; let title_index = index"
      class="title-option item-hover-parent"
      (click)="clickOrTickTitle(title_index); $event.stopPropagation()"
      tabindex="-1"
      role="button"
    >
      <div
        *ngFor="let word_list of option_array; let word_list_index = index"
        class="d-flex align-items-center mx-1 my-1"
      >
        <span *ngIf="word_list.length === 1">{{ word_list[0].value }}</span>
        <div *ngIf="word_list.length > 1" class="btn-group-vertical">
          <label
            *ngFor="let word of word_list; let word_index = index"
            class="btn btn-sm mb-0 text-start"
            role="button"
            [class.btn-primary]="word.checked"
            [class.btn-light]="!word.checked"
            (click)="
              clickOrTickTitle(title_index, word_list_index, word_index);
              $event.stopPropagation();
              $event.preventDefault()
            "
          >
            <input type="checkbox" [(ngModel)]="word.checked" tabindex="-1" />
            {{ word.value }}
          </label>
        </div>
      </div>
      <div class="item-btns-right btn-group item-hover">
        <button
          role="button"
          (click)="titleMenuPromptRemove(option_array); $event.stopPropagation()"
          class="btn btn-danger btn-sm"
          permission
          [permission-only]="['template_manage']"
          title="Remove"
          tabindex="-1"
        >
          <span class="fa fa-trash"></span>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!!search_string.value.trim()">
    <!-- click event is blocked by search box blur event, hence `(mouseup)` instead of `(click)` -->
    <div
      *ngFor="let title of filteredTitles"
      class="p-1 title-option item-hover-parent"
      (mouseup)="setTitleFromSearch(title); $event.stopPropagation()"
      tabindex="-1"
    >
      {{ title.text }}
    </div>
  </div>
  <div
    *ngIf="!loading"
    class="title-option w-100 sticky-bottom mt-1 pb-0"
    (click)="$event.stopPropagation()"
    permission
    [permission-only]="['template_manage']"
  >
    <form class="w-100" (ngSubmit)="createTitleOption()">
      <input
        type="text"
        [formControl]="newTitleText"
        class="form-control w-100 py-2 rounded"
        name="newTitleText"
        id="newTitleText"
        placeholder="Something else&hellip;"
      />
    </form>
  </div>
</ng-container>
<ng-template #termsLoading>
  <div class="d-flex justify-content-center my-2">
    <div class="spinner-border" role="status"></div>
  </div>
</ng-template>
