import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UrgentNoteHttpService {
  constructor(private http: HttpClient) {}

  create(urgentNotes: Partial<RR.UrgentNotes>): Observable<RR.UrgentNotes> {
    return this.http.post<RR.UrgentNotes>('/api/urgent_notes', urgentNotes);
  }

  delete(urgentNoteId: number) {
    return this.http.delete(`/api/urgent_notes/${urgentNoteId}`);
  }
}
