import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { StatementSetEffect } from '../statement-set/statement-set.effect';
import { StatementEffect } from '../statement/statement.effect';
import { PatchTextObject } from '../statement/statement.service';
import { SubsectionActions } from '../subsection/subsection.action';
import { ElementActions, ElementBatchActions } from './element.action';
import { ElementCreateBody, ElementHttpService } from './element.service';

/**
 * These used to be proper ngrx/effects, e.g. `@Effect()` decorator. But for the reasons described in
 * https://gitlab.fluidsolar.com.au/adi/radreport2/-/issues/42 they were switched to method calls.
 */
@Injectable()
export class ElementEffect {
  elementUpdateEvents = new Subject<{ type: 'CREATE_ELEMENT' | 'REMOVE_ELEMENT'; elementId: number }>();

  constructor(
    private store: Store<AppState>,
    private service: ElementHttpService,
    private statementSetEffect: StatementSetEffect,
    private statementEffect: StatementEffect,
    private message: MessageService,
  ) {}

  create(data: ElementCreateBody) {
    return this.service.create(data).pipe(
      this.message.handleHttpErrorPipe,
      switchMap(({ element, subsection }) => {
        // After creating the Element, fill the Element with the initial DefaultAttributes.
        return this.service.fillElement(element.id).pipe(
          map((defaultAttributes) => ({
            element,
            subsection,
            defaultAttributes,
          })),
        );
      }),
      map(({ element, subsection, defaultAttributes }) =>
        // A single batch action that updates the element, subsection, and defaultAttribute stores.
        ElementBatchActions.create({
          actions: {
            // Element was created
            elementAddOne: ElementActions.addOne({ element }),
            // Subsection was updated because the ids in Subsection.elements changed
            subsectionUpdateSuccessAction: SubsectionActions.upsertOne({ subsection }),
            // TDAs were also created
            defaultAttributeCreateManySuccessAction: DefaultAttributeActions.createManySuccess({ defaultAttributes }),
          },
        }),
      ),
      // explicitly dispatch the action to the store. ngrx/effects normally calls this behind the scenes.
      tap((action) => this.store.dispatch(action)),
      tap((action) => {
        this.elementUpdateEvents.next({
          type: 'CREATE_ELEMENT',
          elementId: action.actions.elementAddOne.element.id,
        });
      }),
    );
  }

  update(elementId: number, changes: Partial<RR.Element>) {
    return this.service.update(elementId, changes).pipe(
      map((element: RR.Element) => ElementActions.upsertOne({ element })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(elementId: number, password: string) {
    return this.service.delete(elementId, password).pipe(
      map((updatedSubsection) =>
        ElementBatchActions.delete({
          actions: {
            elementRemoveOne: ElementActions.removeOne({ elementId }),
            subsectionUpdateSuccessAction: SubsectionActions.upsertOne({ subsection: updatedSubsection }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
      tap((action) => {
        this.elementUpdateEvents.next({
          type: 'REMOVE_ELEMENT',
          elementId: action.actions.elementRemoveOne.elementId,
        });
      }),
    );
  }

  /**
   * @param templateId Finds all the Elements in this `templateId`
   */
  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((elements: RR.Element[]) => ElementActions.addMany({ elements })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * Creates a new StatementSet, Statement, and Element. Adds the Element to the bottom of the Subsection.
   */
  createElementWithStatement(
    textObjects: PatchTextObject[],
    statement: Partial<RR.Statement>,
    statementSet: Partial<RR.StatementSet>,
    element: ElementCreateBody,
  ) {
    return this.statementSetEffect.create(statementSet).pipe(
      map(({ actions }) => {
        // Update the statement and element with the newly created statement set id
        // This passes the result along to the next step in the pipe.
        const createdStatementSet = actions.statementSetCreateSuccessAction.statementSet;
        return {
          statementSet: createdStatementSet,
          element: { element: { ...element.element, statement_set_id: createdStatementSet.id }, index: element.index },
          statement: { ...statement, statement_set_id: createdStatementSet.id },
        };
      }),
      // With the statementSet created we can create the element. The element
      // needs to be created before the statement so that the creation of the
      // text objects will work.
      switchMap(({ statement, statementSet, element }) =>
        this.create(element)
          // Also updating the subsection it is contained within
          .pipe(
            map(({ actions }) => {
              const elementAction = actions.elementAddOne;
              const subsectionAction = actions.subsectionUpdateSuccessAction;
              return {
                statement,
                statementSet,
                element: elementAction.element,
                subsection: subsectionAction.subsection,
              };
            }),
          ),
      ),
      // This finally creates the new statement, which returns the
      // statement set, updated to contain the statement.
      switchMap(({ statement, element, subsection }) =>
        this.statementEffect
          .createWithTextObjects({ statement, textObjects })
          // When creating the statement we also update the statement set
          .pipe(
            map(({ actions }) => {
              const newStatement = actions.statementCreateSuccessAction.statement;
              const statementSet = actions.statementSetUpdateSuccessAction.statementSet;
              const defaultAttributes = actions.defaultAttributeUpdateManySuccessAction.defaultAttributes;
              return {
                element,
                statement: newStatement,
                statementSet,
                subsection,
                defaultAttributes,
              };
            }),
          ),
      ),
      map(({ element, statement }) => ({ element, statement })),
    );
  }
}
