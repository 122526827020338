import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { SexSpecificWordActions } from './sex-specific-word.action';
import { SexSpecificWordHttpService } from './sex-specific-word.service';

@Injectable()
export class SexSpecificWordEffect {
  constructor(
    private store: Store<AppState>,
    private service: SexSpecificWordHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((sex_specific_words: RR.SexSpecificWord[]) => SexSpecificWordActions.findAllSuccess({ sex_specific_words })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(data: Partial<RR.SexSpecificWord>) {
    return this.service.create(data).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.SexSpecificWord) =>
        SexSpecificWordActions.createSuccess({
          sex_specific_word: data,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(sexSpecificWordId: number) {
    return this.service.delete(sexSpecificWordId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => SexSpecificWordActions.deleteSuccess({ sex_specific_word_id: sexSpecificWordId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
