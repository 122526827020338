import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { atLeastOneContact, BindObservable, expiredMedicare } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { combineLatest, Observable, of, Subscription, switchMap } from 'rxjs';

import { PatientFormEditComponent } from './patient-form-edit/patient-form-edit.component';
import { PatientFormSearchComponent } from './patient-form-search/patient-form-search.component';
import { PatientFormViewComponent } from './patient-form-view/patient-form-view.component';

export type PatientFormParent = 'REGISTRATION' | 'PATIENT' | 'BOOKING';
export const PATIENT_FORM_ID = 'patient-form';

@Component({
  standalone: true,
  imports: [CommonModule, PatientFormEditComponent, PatientFormViewComponent, PatientFormSearchComponent, SharedModule],
  selector: 'rr-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  host: {
    id: PATIENT_FORM_ID,
  },
})
export class PatientFormComponent implements OnInit, OnDestroy {
  @ViewChild(PatientFormSearchComponent) patientFormSearchComponent: PatientFormSearchComponent;
  @ViewChild(PatientFormEditComponent) patientFormEdit: PatientFormEditComponent | undefined;
  /**
   * A real patient entity. Not a partial patient. If this is here, it is in patient editing mode.
   */
  @Input() @BindObservable() patient: RR.Patient | undefined;
  patient$: Observable<RR.Patient | undefined>;
  @Input() @BindObservable() bookingId: number | null;
  bookingId$: Observable<number | null>;

  @Input() header: string;
  // Parent component (report registration, patient listing page, or booking list)
  @Input() parent: PatientFormParent = 'REGISTRATION';
  // Patient self registration
  @Input() selfRegistration = false;
  // Patient changed when select existing patient from suggestions or
  // after submitting patient or clear form to start register new patient again
  @Output() onChange: EventEmitter<RR.Patient> = new EventEmitter();

  removedPatient: RR.Patient;
  bookingPatient: RR.Booking['booking_patient'] | undefined;
  searchFormPatientDetails: Partial<RR.Patient>;
  /**
   * search: show the search input. create patient button.
   * view: non editable preview of the patient. edit and remove patient buttons.
   * edit: show the form. if there's a patient, edit patient. if there's no patient, create patient.
   */
  viewMode: 'search' | 'view' | 'edit' | 'create' = 'view';
  formValid = true;
  expiredMedicare: boolean;
  subscription: Subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    const booking$ = this.bookingId$.pipe(
      switchMap((id) => (id ? this.store.select(fromBooking.selectBooking(id)) : of(undefined))),
    );

    this.subscription.add(
      combineLatest([this.patient$, booking$]).subscribe(([patient, booking]) => {
        if (this.selfRegistration) {
          this.viewMode = 'edit';
        } else if (patient) {
          this.viewMode = 'view';
        } else {
          this.bookingPatient = booking && booking.booking_patient;
          this.viewMode = 'search';
        }
      }),
    );

    // Mirroring form validation from patient-form-edit
    this.subscription.add(
      this.patient$.subscribe((patient) => {
        if (patient) {
          const atLeastOnePatientContact = atLeastOneContact(patient, [
            'phone_mobile',
            'phone_home',
            'phone_work',
            'email',
          ]);

          const missingPatientDetails =
            !patient.patient_first_name || !patient.patient_last_name || !patient.patient_dob || !patient.patient_sex;

          this.formValid = !missingPatientDetails && atLeastOnePatientContact;
          this.expiredMedicare = expiredMedicare(
            patient.medicare_expiry_month || undefined,
            patient.medicare_expiry_year || undefined,
          );
        }
      }),
    );
  }

  createNewPatient() {
    this.viewMode = 'create';
    const {
      patient_first_name: patient_full_name,
      patient_dob,
      medicare_number,
      phone_mobile,
    } = this.patientFormSearchComponent.getPatientDataFromSearchInput();

    let patient_first_name = '';
    let patient_last_name = '';

    if (patient_full_name) {
      const separatedNames = patient_full_name.split(' ');

      // If there's only one name
      if (separatedNames.length === 1) {
        patient_first_name = separatedNames[0];
      }
      // If there are two names
      else if (separatedNames.length === 2) {
        patient_first_name = separatedNames[0];
        patient_last_name = separatedNames[1];
      }
      // If there are three or more names
      else {
        patient_first_name = separatedNames[0];
        patient_last_name = separatedNames.slice(1).join(' ');
      }
    }

    this.searchFormPatientDetails = {
      patient_first_name,
      patient_last_name,
      patient_dob,
      medicare_number,
      phone_mobile,
    };
  }

  selectPatient($event: { patient: RR.Patient; created: boolean }) {
    const { patient, created } = $event;
    this.messageService.add({
      title: 'Success',
      message: created ? 'Create new patient successfully!' : 'Update patient successfully!',
      type: 'success',
    });
    this.onChange.emit(patient);
    this.viewMode = 'view';
  }

  editPatient() {
    this.viewMode = 'edit';
  }

  removePatient() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.removedPatient = this.patient!; // non-null assertion because of ngIf
    this.patient = undefined;
    this.viewMode = 'search';
  }

  cancel() {
    if (this.patient) {
      this.viewMode = 'view';
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (!this.patient && this.viewMode === 'search') {
      this.patient = this.removedPatient;
      this.viewMode = 'view';
    } else {
      this.viewMode = 'search';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
