import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type ElementChoiceUpdate = Partial<RR.ElementChoice>;

@Injectable()
export class ElementChoiceHttpService {
  constructor(private http: HttpClient) {}

  update(id: number, elementChoice: ElementChoiceUpdate) {
    return this.http.put<{
      report: RR.Report;
      element_choice: RR.ElementChoice;
    }>(`/api/element_choice/${id}`, elementChoice);
  }
}
