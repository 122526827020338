import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { PresetEffect } from 'app/store/report/preset';
import { fromPresetTitle } from 'app/store/report/preset-title';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-preset-title',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './preset-title.component.html',
  styleUrl: './preset-title.component.css',
})
export class PresetTitleComponent implements OnInit, OnDestroy {
  @Input() presetTitleId: number;
  reportId$: Observable<number>;
  presetTitle$: Observable<RR.PresetTitle>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private presetEffect: PresetEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.presetEffect.findPresetTitle(this.presetTitleId).subscribe());

    this.presetTitle$ = this.store.select(fromPresetTitle.selectPresetTitle(this.presetTitleId)).pipe(filterDefined());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
