import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { AttributeSetActions } from '../attribute-set/attribute-set.action';
import { StatementActions } from '../statement/statement.action';
import { TextObjectActions } from '../text-object/text-object.action';
import { AttributeOptionActions, AttributeOptionBatchActions } from './attribute-option.action';
import { AttributeOptionHttpService } from './attribute-option.service';

@Injectable()
export class AttributeOptionEffect {
  constructor(
    private store: Store<AppState>,
    private service: AttributeOptionHttpService,
    private message: MessageService,
  ) {}

  create(attributeOption: Partial<RR.AttributeOption> & { attribute_set_id: number }) {
    return this.service.create(attributeOption).pipe(
      this.message.handleHttpErrorPipe,
      map(({ attribute_option, attribute_set }) =>
        AttributeOptionBatchActions.createSuccess({
          actions: {
            findAttributeOptionSuccess: AttributeOptionActions.findSuccess({ attributeOption: attribute_option }),
            findAttributeSetSuccess: AttributeSetActions.findSuccess({ attributeSet: attribute_set }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(attributeOptionId: number, changes: Partial<RR.AttributeOption> & { move_to_attribute_option_id?: number }) {
    return this.service.update(attributeOptionId, changes).pipe(
      // commented because an error handler has `moveItemInArray`
      // this.message.handleHttpErrorPipe,
      // Update an attribute options may effect the position of the other options
      map(({ attribute_option, attribute_set }) =>
        AttributeOptionBatchActions.updateSuccess({
          actions: {
            findAttributeOptionSuccess: AttributeOptionActions.findSuccess({ attributeOption: attribute_option }),
            findAttributeSetSuccess: AttributeSetActions.findSuccess({ attributeSet: attribute_set }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(attributeOptionId: number) {
    return this.service.delete(attributeOptionId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ attribute_set }) =>
        AttributeOptionBatchActions.deleteSuccess({
          actions: {
            deleteAttributeOptionSuccess: AttributeOptionActions.deleteSuccess({ attributeOptionId }),
            findAttributeSetSuccess: AttributeSetActions.findSuccess({ attributeSet: attribute_set }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAttributeStatementUsage(attributeOptionId: number) {
    return this.service.findAttributeStatementUsage(attributeOptionId).pipe(
      this.message.handleHttpErrorPipe,
      map(({ statements, text_objects }) =>
        AttributeOptionBatchActions.findAttributeStatementUsageSuccess({
          actions: {
            statementFindManySuccess: StatementActions.addMany({ statements }),
            statementTextObjectFindManySuccess: TextObjectActions.upsertMany({ textObjects: text_objects }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
