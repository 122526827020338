import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ElementActions } from '../template/element/element.action';
import { RegionActions } from '../template/region/region.action';
import { SectionActions } from '../template/section/section.action';
import { StatementCoincidenceActions } from '../template/statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../template/statement-set/statement-set.action';
import { StatementActions } from '../template/statement/statement.action';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { TextObjectActions } from '../template/text-object/text-object.action';
import { ImgsimParameterActions, ImgsimParamsActions } from './imgsim-parameters.action';
import { ImgsimParamsHttpService, Parameter, ProposedResponse } from './imgsim-parameters.service';

@Injectable()
export class ImgsimParamsEffect {
  constructor(
    private store: Store<AppState>,
    private service: ImgsimParamsHttpService,
    private message: MessageService,
  ) {}

  propose(topicId: number) {
    return this.service.propose(topicId).pipe(
      this.message.handleHttpErrorPipe,
      map((proposedResponses: ProposedResponse[]) => {
        const parameters = proposedResponses
          .map((response) => response.parameters)
          .filter((params): params is Parameter[] => params !== undefined)
          .flat();

        this.store.dispatch(ImgsimParameterActions.addMany({ parameters: parameters }));

        return proposedResponses.map(
          ({
            datasets,
            images,
            title,
            sections,
            subsections,
            regions,
            elements,
            statement_sets,
            statements,
            dividers,
            text_objects,
            statement_coincidences,
          }) => {
            return ImgsimParamsActions.findProposedSuccess({
              datasets,
              title,
              images,

              actions: {
                findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                  actions: {
                    sectionAddMany: SectionActions.addMany({ sections }),
                    subsectionAddMany: SubsectionActions.addMany({ subsections }),
                    regionAddMany: RegionActions.addMany({ regions }),
                    elementAddMany: ElementActions.addMany({ elements }),
                    statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                    statementAddMany: StatementActions.addMany({ statements }),
                    dividerAddMany: StatementActions.addMany({ statements: dividers }),
                    textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                    statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                      statementCoincidences: statement_coincidences,
                    }),
                  },
                }),
              },
            });
          },
        );
      }),
      tap((actions) => actions.forEach((action) => this.store.dispatch(action))),
    );
  }

  updateImgsimParameterName(parameterId: number, data: { name: string }) {
    return this.service.updateImgsimParameterName(parameterId, data).pipe(
      this.message.handleHttpErrorPipe,
      map((parameter) => ImgsimParameterActions.upsertOne({ parameter })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
