<div ngbDropdown [autoClose]="false" placement="bottom" #attrReplaceDropdown="ngbDropdown">
  <div ngbDropdownMenu>
    <strong class="text-secondary px-2 mb-1 text-nowrap">Switch attribute set:</strong>
    <ng-container *ngIf="results.length > 0">
      <ng-container *ngFor="let result of results">
        <div
          ngbDropdownItem
          *ngIf="result.attributeSetId | storeSelectPipe: selectAttributeSet | async as attributeSet"
          title="{{ result.attributeSetId }}"
          (click)="switchAttributeSet(result); attrReplaceDropdown.close()"
        >
          {{ attributeSet.name }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="results.length === 0">
      <div ngbDropdownItem>
        <i>No matches found</i>
      </div>
    </ng-container>
  </div>
</div>
