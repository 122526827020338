import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { distinctUntilKeyChanged } from 'rxjs/operators';

type GAEvent = {
  event: string;
  action: string;
};

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService implements OnDestroy {
  gtmCode = 'GTM-NDRTKJLJ';
  // analyticsCode = 'G-YHXV31XHSC';
  subscription = new Subscription();

  constructor(private store: Store<AppState>) {
    // Subscribe early (in the constructor) so that the initial navigation event isn't lost.
    this.addGtagFunction();

    // GTM already tracks all pages

    this.subscription.add(
      this.store
        .select(fromSession.selectCurrentUser)
        .pipe(filterDefined(), distinctUntilKeyChanged('id'))
        .subscribe((user) => {
          window.dataLayer.push({
            userId: user.username,
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  shouldTrack() {
    return environment.production && rrConfig.environment === 'production';
  }

  initialise() {
    if (this.shouldTrack()) {
      this.appendGtmScript();
    }
  }

  /**
   * <script async src="https://www.google-analytics.com/analytics.js"></script>
   * Only requests analytics.js if we're in production mode. This is to save time in the tests.
   */

  appendScript(src: string) {
    const script: HTMLScriptElement = document.createElement('script');
    script.async = true;
    script.src = src;
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }

  appendGtmScript() {
    // GA config is set in Google Tag Manager:
    // cookieDomain: 'none' because we don't want to interfere with adelaidemri.com cookies
    // send_page_view: false because we're manually sending page views
    this.appendScript(`https://www.googletagmanager.com/gtm.js?id=${this.gtmCode}`);
  }

  addGtagFunction() {
    // This is from the snippet in Google Analytics.
    // https://analytics.google.com/analytics/web/#/a157541970w221716414p210741891/admin/tracking/tracking-code/
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  trackEvent(event: GAEvent) {
    if (this.shouldTrack()) {
      window.dataLayer.push({
        event: event.event,
        action: event.action,
      });
    }
  }

  /**
   * If a category isn't set, guess the category from the parent element.
   * If an action isn't set, get the action from the element tooltip.
   * @param eventDefaults optional defaults for category and action.
   */
  trackClick(mouseEvent: MouseEvent, eventDefaults: { action?: string } = {}) {
    // `currentTarget` is the element with click listener. `target` can be an element inside the button, like a <span>.
    if (!(mouseEvent.currentTarget instanceof HTMLElement)) {
      console.warn("MouseEvent target isn't instanceof HTMLElement");
      return;
    }
    const target: HTMLElement = mouseEvent.currentTarget;
    const title = target.title;
    const action = eventDefaults.action || title;

    if (action) {
      this.trackEvent({
        event: 'Click',
        action,
      });
    } else {
      console.warn("could't determine the GA action for element", mouseEvent.target);
    }
  }
}
