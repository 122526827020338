import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { UserActions } from '../../user/user/user.action';
import { AuditEventActions } from '../audit-event/audit-event.action';
import { TodoActions, TodoBatchActions } from './todo.action';
import { TodoGet, TodoHttpService } from './todo.service';

@Injectable()
export class TodoEffect {
  constructor(
    private store: Store<AppState>,
    private service: TodoHttpService,
  ) {}

  create(todo: Partial<RR.Todo>) {
    return this.service.create(todo).pipe(
      map((todo: TodoGet) => {
        const action = TodoBatchActions.createTodoBatchSuccess({
          actions: {
            createTodoSuccess: TodoActions.createSuccess({ todo: todo.todo }),
            createAuditEventSuccess: AuditEventActions.findManySuccess({ auditEvents: todo.audit_events }),
            createUserSuccess: UserActions.findAllSuccess({ users: todo.users }),
          },
        });
        return action;
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(todoId: number, changes: Partial<RR.Todo>) {
    return this.service.update(todoId, changes).pipe(
      map((todo: TodoGet) => {
        const action = TodoBatchActions.updateTodoBatchSuccess({
          actions: {
            updateTodoSuccess: TodoActions.updateSuccess({ todo: todo.todo }),
            updateAuditEventSuccess: AuditEventActions.findManySuccess({ auditEvents: todo.audit_events }),
            updateUserSuccess: UserActions.findAllSuccess({ users: todo.users }),
          },
        });
        return action;
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(todoId: number) {
    return this.service.delete(todoId).pipe(
      map(() => TodoActions.deleteSuccess({ todoId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
