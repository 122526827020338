import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export type JustificationPayload = {
  justification: string;
  mandatory_statement_id: number;
  topic_id: number | undefined;
};

@Injectable()
export class JustificationHttpService {
  constructor(private http: HttpClient) {}

  create(topicId: number, data: JustificationPayload) {
    return this.http.post<{ justification: RR.Justification; topic: RR.Topic }>(
      `/api/topic/${topicId}/justification`,
      data,
    );
  }

  delete(justificationId: number) {
    return this.http.delete<{ topic: RR.Topic }>(`/api/justification/${justificationId}`);
  }
}
