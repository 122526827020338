import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { GithubIssueCommentActions } from '../github-issue-comment/github-issue-comment.action';

const findAllSuccess = createAction(
  '[GithubIssue] Find All Success',
  props<{ issues: RR.GithubIssue[]; count: number }>(),
);

const findSuccess = createAction('[GithubIssue] Find Success', props<{ issue: RR.GithubIssue }>());

export const GithubIssueActions = {
  findAllSuccess,
  findSuccess,
};

export const GithubIssueBatchActions = {
  findAllSuccess: createBatchAction(
    '[GithubIssue] Find All Success Batch',
    props<{
      actions: {
        githubIssueFindAll: ReturnType<typeof findAllSuccess>;
        githubIssueCommentFindAll: ReturnType<typeof GithubIssueCommentActions.findAllSuccess>;
      };
    }>(),
  ),
  findByIdSuccess: createBatchAction(
    '[GithubIssue] Find By Id Success Batch',
    props<{
      actions: {
        githubIssueFind: ReturnType<typeof findSuccess>;
        githubIssueCommentFindAll: ReturnType<typeof GithubIssueCommentActions.findAllSuccess>;
      };
    }>(),
  ),
};
