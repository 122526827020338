import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { BookingHeadlineComponent } from 'app/shared/components/booking-headline/booking-headline.component';
import { PatientInfoComponent } from 'app/shared/components/patient-info/patient-info.component';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { BookingEffect, fromBooking } from 'app/store/booking';
import { BookingEnquiryHttpService } from 'app/store/booking-enquiry/booking-enquiry.service';
import { fromPatient, PatientEffect } from 'app/store/patient';
import { PatientNoteEffect, fromPatientNote } from 'app/store/patient-note';
import { ReferralEnquiryEffect } from 'app/store/referral-enquiry';
import { fromReferrer, ReferrerEffect } from 'app/store/referrer';
import { fromReferrerNote, ReferrerNoteEffect } from 'app/store/referrer-note';
import { Subscription, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BookingHeadlineComponent,
    PatientInfoComponent,
    ReferrerNameComponent,
  ],
  selector: 'rr-edit-note-modal',
  templateUrl: './edit-note-modal.component.html',
  styleUrls: ['./edit-note-modal.component.scss'],
})
export class EditNoteModalComponent implements OnInit, OnDestroy {
  @Input() bookingId?: number;
  @Input() patientId?: number;
  @Input() referrerId?: number;
  @Input() referrerNoteId?: number;
  @Input() patientNoteId?: number;
  @Input() enquiryId?: number;
  @Input() referralId?: number;

  notes: string | null;
  subscription: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private bookingEffect: BookingEffect,
    private referrerEffect: ReferrerEffect,
    private patientEffect: PatientEffect,
    private referrerNoteEffect: ReferrerNoteEffect,
    private messageService: MessageService,
    private enquiryService: BookingEnquiryHttpService,
    private patientNoteEffect: PatientNoteEffect,
    private referralEnquiryEffect: ReferralEnquiryEffect,
  ) {}

  ngOnInit(): void {
    if (this.bookingId) {
      this.subscription.add(
        this.store
          .select(fromBooking.selectBooking(this.bookingId))
          .pipe(filterDefined())
          .subscribe((booking) => {
            this.notes = booking.notes;
          }),
      );
    }

    if (this.referrerId) {
      this.subscription.add(
        this.store
          .select(fromReferrer.selectReferrer(this.referrerId))
          .pipe(filterDefined())
          .subscribe((referrer) => {
            this.notes = referrer.collater_notes;
          }),
      );
    }

    if (this.patientId) {
      this.subscription.add(
        this.store
          .select(fromPatient.selectPatient(this.patientId))
          .pipe(filterDefined())
          .subscribe((patient) => {
            this.notes = patient.note;
          }),
      );
    }

    if (this.referrerNoteId) {
      this.subscription.add(
        this.store
          .select(fromReferrerNote.selectReferrerNote(this.referrerNoteId))
          .pipe(filterDefined())
          .subscribe((note) => {
            this.notes = note.note;
          }),
      );
    }

    if (this.patientNoteId) {
      this.subscription.add(
        this.store
          .select(fromPatientNote.selectPatientNote(this.patientNoteId))
          .pipe(filterDefined())
          .subscribe((note) => {
            this.notes = note.note;
          }),
      );
    }
  }

  updateNote() {
    if (this.bookingId) {
      this.subscription.add(
        this.bookingEffect
          .update(this.bookingId, { notes: this.notes })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update booking notes successfully!',
              type: 'success',
            });
            this.activeModal.dismiss();
          }),
      );
    }
    if (this.referrerId) {
      this.subscription.add(
        this.referrerEffect
          .update(this.referrerId, { collater_notes: this.notes })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update collater notes successfully!',
              type: 'success',
            });
            this.activeModal.dismiss();
          }),
      );
    }
    if (this.patientId) {
      this.subscription.add(
        this.patientEffect
          .update(this.patientId, { note: this.notes })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update patient notes successfully!',
              type: 'success',
            });
            this.activeModal.dismiss();
          }),
      );
    }
    if (this.referrerNoteId && this.notes) {
      this.subscription.add(
        this.referrerNoteEffect
          .update(this.referrerNoteId, { note: this.notes })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update referrer note successfully!',
              type: 'success',
            });
            this.activeModal.dismiss();
          }),
      );
    }
    if (this.patientNoteId && this.notes) {
      this.subscription.add(
        this.patientNoteEffect
          .update(this.patientNoteId, { note: this.notes })
          .pipe(take(1))
          .subscribe(() => {
            this.messageService.add({
              title: 'Success',
              message: 'Update patient note successfully!',
              type: 'success',
            });
            this.activeModal.dismiss();
          }),
      );
    }

    if (this.enquiryId) {
      this.subscription.add(
        this.enquiryService.updateBookingEnquiry(this.enquiryId, { staff_note: this.notes || '' }).subscribe(() => {
          this.messageService.add({
            title: 'Success',
            message: 'Update enquiry staff note successfully!',
            type: 'success',
          });
          // To update enquiry list after closing notes.
          this.activeModal.close(true);
        }),
      );
    }

    if (this.referralId) {
      this.subscription.add(
        this.referralEnquiryEffect.update(this.referralId, { staff_note: this.notes || '' }).subscribe(() => {
          this.messageService.add({
            title: 'Success',
            message: 'Update referral staff note successfully!',
            type: 'success',
          });
          // To update enquiry list after closing notes.
          this.activeModal.close(true);
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(
    modalService: NgbModal,
    id: {
      bookingId?: number;
      patientId?: number;
      referrerId?: number;
      referrerNoteId?: number;
      patientNoteId?: number;
      enquiryId?: number;
      referralId?: number;
    },
    enquiryNote?: string,
  ) {
    const modalRef = modalService.open(EditNoteModalComponent, {
      size: 'lg',
    });
    const { bookingId, referrerId, patientId, referrerNoteId, patientNoteId, enquiryId, referralId } = id;
    modalRef.componentInstance.bookingId = bookingId;
    modalRef.componentInstance.referrerId = referrerId;
    modalRef.componentInstance.patientId = patientId;
    modalRef.componentInstance.referrerNoteId = referrerNoteId;
    modalRef.componentInstance.patientNoteId = patientNoteId;
    modalRef.componentInstance.enquiryId = enquiryId;
    modalRef.componentInstance.referralId = referralId;
    modalRef.componentInstance.notes = enquiryNote;

    return modalRef;
  }
}
