import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { SignatureActions } from './signature.action';

export interface SignatureState extends EntityState<RR.Signature> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.Signature>();

const initialState = adapter.getInitialState({ loaded: false });

export const signatureReducer = createReducer(
  initialState,
  // Signature is nested inside User and User's findAll action might have `where` condition applied
  // Using `upsertMany` to not clear some of the signatures that were already fetched.
  on(SignatureActions.findAllSuccess, (state: SignatureState, { signatures }) => ({
    ...adapter.upsertMany(signatures, state),
    loaded: true,
  })),
  on(SignatureActions.createSuccess, (state: SignatureState, { signature }) => adapter.addOne(signature, state)),
  on(SignatureActions.updateSuccess, (state: SignatureState, { signature }) => adapter.upsertOne(signature, state)),
  on(SignatureActions.updateManySuccess, (state: SignatureState, { signatures }) =>
    adapter.upsertMany(signatures, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
