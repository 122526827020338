import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectorService } from 'app/core/services/selector.service';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, UserHeadlineComponent],
  templateUrl: './confirm-password-modal.component.html',
})
export class ConfirmPasswordModalComponent {
  password = new FormControl('', { nonNullable: true });
  currentUser: Observable<RR.User>;

  constructor(
    public activeModal: NgbActiveModal,
    private selectorService: SelectorService,
  ) {
    // @ts-expect-error strictNullChecks
    this.currentUser = this.selectorService.selectLoadedCurrentUser();
  }

  onSubmit() {
    this.activeModal.close(this.password.value);
  }
}
