<div ngbDropdown>
  <div class="btn-group w-100">
    <a rrTooltip="VIEW_ALL_FORMS" class="btn btn-primary" [href]="reportId | allFormLink" target="_blank">
      All Forms
    </a>
    <button
      class="btn btn-primary dropdown-toggle-split"
      ngbDropdownToggle
      *ngIf="availableForms && availableForms.length > 0"
    ></button>
  </div>
  <div class="dropdown-menu" ngbDropdownMenu>
    <a ngbDropdownItem *ngFor="let form of availableForms" [href]="form.url" target="_blank">
      {{ form.name }}
    </a>
  </div>
</div>
