import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type CreateFeedbackCommentResponse = {
  feedback: RR.Feedback;
  feedback_comment: RR.FeedbackComment;
};

export type CreateFeedbackComment = Pick<RR.FeedbackComment, 'user_id' | 'comment_text'> & {
  feedback_id: number;
};

@Injectable()
export class FeedbackCommentHttpService {
  constructor(private http: HttpClient) {}

  findAll(params?: HttpParams): Observable<RR.FeedbackComment[]> {
    if (params) {
      return this.http.get<RR.FeedbackComment[]>('/api/feedback_comment', { params });
    }
    return this.http.get<RR.FeedbackComment[]>('/api/feedback_comment');
  }

  create(feedbackComment: CreateFeedbackComment): Observable<CreateFeedbackCommentResponse> {
    return this.http.post<CreateFeedbackCommentResponse>('/api/feedback_comment', feedbackComment);
  }

  update(feedbackCommentId: number, changes: Partial<RR.FeedbackComment>): Observable<RR.FeedbackComment> {
    return this.http.put<RR.FeedbackComment>(`/api/feedback_comment/${feedbackCommentId}`, changes);
  }
}
