import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { ReferralEnquiryActions } from './referral-enquiry.action';

export type ReferralEnquiryState = EntityState<RR.ReferralEnquiry>;

const adapter = createEntityAdapter<RR.ReferralEnquiry>();

const initialState = adapter.getInitialState();

export const referralEnquiryReducer = createReducer(
  initialState,
  on(ReferralEnquiryActions.searchSuccess, (state: ReferralEnquiryState, { enquiries }) =>
    adapter.upsertMany(enquiries, state),
  ),
  on(ReferralEnquiryActions.updateSuccess, (state: ReferralEnquiryState, { enquiry }) =>
    adapter.upsertOne(enquiry, state),
  ),
  on(ReferralEnquiryActions.deleteSuccess, (state: ReferralEnquiryState, { enquiryId }) =>
    adapter.removeOne(enquiryId, state),
  ),
  on(ReferralEnquiryActions.findSuccess, (state: ReferralEnquiryState, { enquiry }) =>
    adapter.upsertOne(enquiry, state),
  ),
);
