<ng-container *ngIf="invoice$ | async as invoice">
  <div class="modal-header">
    <h5 class="modal-title">Invoice #{{ invoice.id }}</h5>
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col">
        <h4><strong>Invoice</strong></h4>
        <div class="row">
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Invoice no:</div>
              <div class="col col-sm-6">
                {{ invoice.id }}
              </div>
            </div>
          </div>
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Invoice date:</div>
              <div class="col col-sm-6">
                {{ invoice.invoice_created | date: 'dd/MM/yyyy' }}
              </div>
            </div>
          </div>
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Status:</div>
              <div class="col col-sm-6">
                <span *ngIf="invoice.medipass_claim_status">{{
                  medipassClaimStatus[invoice.medipass_claim_status]
                }}</span>
                <span *ngIf="!invoice.medipass_claim_status && invoice.medipass_transaction_status">{{
                  medipassTransactionStatus[invoice.medipass_transaction_status]
                }}</span>
                <span
                  *ngIf="!invoice.medipass_transaction_status && !invoice.medipass_claim_status && invoice.status"
                  >{{ status[invoice.status] }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Funder:</div>
              <div class="col col-sm-6">
                <ng-container *ngIf="invoice.funder">{{ funders[invoice.funder] }}</ng-container>
              </div>
            </div>
          </div>
          <!-- <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Payment type:</div>
              <div class="col col-sm-6">
                <ng-container *ngIf="invoice.payment_type">{{ paymentType[invoice.payment_type] }}</ng-container>
              </div>
            </div>
          </div> -->
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Adjustments:</div>
              <div class="col col-sm-6">${{ invoice.adjustment }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">GST included:</div>
              <div class="col col-sm-6">${{ invoice.gst_included }}</div>
            </div>
          </div>
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Amount expected:</div>
              <div class="col col-sm-6">${{ invoice.amount_expected }}</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Amount paid:</div>
              <div class="col col-sm-6">${{ invoice.amount_paid }}</div>
            </div>
          </div>
          <div class="col col-sm-4">
            <div class="row">
              <div class="col col-sm-6">Balance due:</div>
              <div class="col col-sm-6">${{ invoice.balance_due }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col col-sm-6">
        <h4><strong>Service provider</strong></h4>
        <div class="row">
          <div class="col col-sm-6">Name:</div>
          <div class="col col-sm-6">{{ invoice.service_provider_name }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Provider number:</div>
          <div class="col col-sm-6">{{ invoice.service_provider_number }}</div>
        </div>
      </div>
      <div class="col col-sm-6">
        <h4><strong>Payee provider</strong></h4>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col col-sm-6">
        <h4><strong>Patient details</strong></h4>
        <div class="row">
          <div class="col col-sm-6">Patient number:</div>
          <div class="col col-sm-6">{{ invoice.patient_number }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Name:</div>
          <div class="col col-sm-6">{{ invoice.patient_first_name }} {{ invoice.patient_last_name }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">DOB:</div>
          <div class="col col-sm-6">{{ invoice.patient_dob | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="row" *ngIf="invoice.patient_medicare_number">
          <div class="col col-sm-6">Medicare:</div>
          <div class="col col-sm-6">{{ invoice.patient_medicare_number }}</div>
        </div>
        <div class="row" *ngIf="invoice.patient_medicare_reference_number">
          <div class="col col-sm-6">Ref number:</div>
          <div class="col col-sm-6">{{ invoice.patient_medicare_reference_number }}</div>
        </div>
      </div>
      <div class="col col-sm-6">
        <h4><strong>Referral details</strong></h4>
        <div class="row">
          <div class="col col-sm-6">Referrer:</div>
          <div class="col col-sm-6">{{ invoice.referrer_name }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Referrer number:</div>
          <div class="col col-sm-6">{{ invoice.referrer_number }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Referrer type:</div>
          <div class="col col-sm-6">{{ invoice.referrer_type }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Date of referral:</div>
          <div class="col col-sm-6">{{ invoice.ref_date | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="row">
          <div class="col col-sm-6">Period of referral:</div>
          <div class="col col-sm-6">{{ invoice.ref_period }}</div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <h4><strong>Service items</strong></h4>
        <rr-invoice-item-table [invoiceItems$]="invoiceItems$"></rr-invoice-item-table>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-info ms-auto" role="button" (click)="viewPdf()">View PDF</button>
  </div>
</ng-container>
