<div class="alert alert-info mt-1 mb-0" role="alert">
  <div *ngFor="let event of sendEvents">
    <div class="row">
      <div class="col-1">
        <span
          class="fas fa-lg text-success"
          [class.fa-sms]="event.communication_type === 'SMS'"
          [class.fa-fax]="event.communication_type === 'FAX'"
          [class.fa-envelope]="event.communication_type === 'EMAIL'"
          [class.fa-globe]="event.communication_type === 'MEDICAL_OBJECTS'"
        ></span>
      </div>
      <div class="col-11 mb-1">
        <strong>{{ event.user_name }}</strong> sent {{ event.communication_type }} to
        <strong>{{ event.send_to }}</strong> at {{ event.when | date: 'short' }}.
      </div>
    </div>
    <div *ngIf="event.message" class="row justify-content-end">
      <div class="col-11">
        <strong>Fax Msg: </strong>
        <div>
          {{ event.message }}
        </div>
      </div>
    </div>
  </div>
</div>
