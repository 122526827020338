<div class="d-flex py-3">
  <!--Audit Events-->
  <div *ngIf="auditEvent">
    <button
      class="btn btn-sm btn-info"
      *ngIf="auditEvent.type === 'CREATE_CHOICE'"
      (click)="jumpToStatements(auditEvent)"
      [class.disabled]="!auditEvent.statement_choice_id"
      rrTooltip="GOTO_CHANGES"
    >
      <i class="fas fa-map-marked-alt"></i>
    </button>
    <button
      class="btn btn-sm btn-info"
      *ngIf="auditEvent.type === 'DELETE_CHOICE'"
      (click)="undoDelete(auditEvent)"
      rrTooltip="UNDO_CHANGES"
    >
      <i class="fas fa-undo-alt"></i>
    </button>
    <button class="btn btn-sm btn-info" *ngIf="auditEvent.type === 'SAVE_DUE_DATE'" disabled>
      <i class="fas fa-exclamation-circle"></i>
    </button>
    <button class="btn btn-sm btn-info" *ngIf="auditEvent.type === 'RENAME_ACCESSION'" disabled>
      <i class="fas fa-file-alt"></i>
    </button>
    <button class="btn btn-sm btn-info" *ngIf="auditEvent.type === 'CHANGE_PATIENT_INFO'" disabled>
      <i class="fas fa-id-card-alt"></i>
    </button>
    <button class="btn btn-sm btn-success" *ngIf="auditEvent.type === 'SEND_TO_VOYAGER'" disabled>
      <i class="fas fa-check-circle"></i>
    </button>
    <button class="btn btn-sm btn-info" *ngIf="auditEvent.type === 'ADD_SIGNATURE'" disabled>
      <i class="fas fa-file-signature"></i>
    </button>
    <button class="btn btn-sm btn-info" *ngIf="auditEvent.type === 'REMOVE_SIGNATURE'" disabled>
      <i class="fas fa-file-signature"></i>
    </button>
    <button
      class="btn btn-sm btn-info"
      *ngIf="
        auditEvent.type === 'REGISTER_PATIENT' ||
        auditEvent.type === 'REGISTER_REFERRER' ||
        auditEvent.type === 'REGISTER_REPORT'
      "
      disabled
    >
      <i class="fas fa-registered"></i>
    </button>
  </div>
  <!--Report Send Events-->
  <div *ngIf="sendEvent">
    <button class="btn btn-sm btn-success" *ngIf="sendEvent.communication_type === 'SMS'" disabled>
      <i class="fas fa-sms"></i>
    </button>
    <button class="btn btn-sm btn-success" *ngIf="sendEvent.communication_type === 'FAX'" disabled>
      <i class="fas fa-fax"></i>
    </button>
    <button class="btn btn-sm btn-success" *ngIf="sendEvent.communication_type === 'EMAIL'" disabled>
      <i class="fas fa-envelope"></i>
    </button>
  </div>
  <!--Audit Events-->
  <div class="ms-2" *ngIf="auditEvent">
    <span
      class="text-info"
      role="button"
      *ngIf="auditEvent.type === 'DELETE_CHOICE'"
      (click)="openTutorialModal('FIND_DELETED_SENTENCES'); $event.stopPropagation()"
      rrTooltip="TUTORIALS_FIND_DELETED_SENTENCES"
      ><i class="fas fa-question-circle"></i
    ></span>
    <small *ngIf="user$ | async as user"> {{ user.username }} </small>
    <span [ngSwitch]="auditEvent.type">
      <ng-container *ngSwitchCase="'CREATE_CHOICE'"
        ><span class="fw-bold">Added</span>
        <ng-container *ngIf="auditEvent.action && auditEvent.action.section">
          a statement to <span class="fw-bold">{{ auditEvent.action.section }}</span></ng-container
        >
      </ng-container>
      <ng-container *ngSwitchCase="'DELETE_CHOICE'"
        ><span class="fw-bold">Removed</span>
        <ng-container *ngIf="auditEvent.action && auditEvent.action.section">
          a statement from <span class="fw-bold">{{ auditEvent.action.section }}</span></ng-container
        >
      </ng-container>
      <ng-container *ngSwitchCase="'SAVE_DUE_DATE'">
        <span class="fw-bold">Saved</span>
        <ng-container> a new due date on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'RENAME_ACCESSION'">
        <span class="fw-bold">Changed</span>
        <ng-container> accession number on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CHANGE_PATIENT_INFO'">
        <span class="fw-bold">Changed</span>
        <ng-container> patient information on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'SEND_TO_VOYAGER'">
        <span class="fw-bold">Sent</span>
        <ng-container> this report to Voyager on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'ADD_SIGNATURE'">
        <span class="fw-bold">Added</span>
        <ng-container> a signature on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'REMOVE_SIGNATURE'">
        <span class="fw-bold">Removed</span>
        <ng-container> a signature on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'REGISTER_PATIENT'">
        <span class="fw-bold">Registered</span>
        <ng-container> a patient </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'REGISTER_REFERRER'">
        <span class="fw-bold">Registered</span>
        <ng-container> a referrer </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'REGISTER_REPORT'">
        <span class="fw-bold">Registered</span>
        <ng-container> a scan </ng-container>
      </ng-container>
    </span>
    <small>
      {{ auditEvent.when | date: 'short' }}
    </small>
    <div class="small fw-bold" *ngIf="auditEvent.action && auditEvent.action.text">
      {{ auditEvent.action.text }}
    </div>
  </div>
  <!--Report Send Events-->
  <div class="ms-2" *ngIf="sendEvent">
    <small> {{ sendEvent.user_name }} </small>
    <span [ngSwitch]="sendEvent.communication_type">
      <ng-container *ngSwitchCase="'SMS'">
        <span class="fw-bold">Sent</span>
        <ng-container> this report through SMS on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'EMAIL'">
        <span class="fw-bold">Sent</span>
        <ng-container> this report through Email on </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'FAX'">
        <span class="fw-bold">Sent</span>
        <ng-container> this report through Fax on </ng-container>
      </ng-container>
    </span>
    <small>
      {{ sendEvent.when | date: 'short' }}
    </small>
  </div>
</div>
