import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SendEventActions } from './send-event.action';

export type SendEventState = EntityState<RR.SendEvent>;

const adapter = createEntityAdapter<RR.SendEvent>();

const initialState = adapter.getInitialState();

export const sendEventReducer = createReducer(
  initialState,
  on(SendEventActions.findManySuccess, (state: SendEventState, { sendEvents }) =>
    adapter.upsertMany(sendEvents, state),
  ),
  on(SendEventActions.sendSuccess, (state: SendEventState, { sendEvents }) => adapter.upsertMany(sendEvents, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
