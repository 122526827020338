import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { LabelEffect } from 'app/store/report/label/label.effect';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { Subscription, finalize, from, map, merge, mergeMap, switchMap, take } from 'rxjs';

@Component({
  selector: 'rr-labels',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css'],
})
export class LabelsComponent implements OnInit, OnDestroy {
  @Input() selectedLabelIds: number[] = [];
  @Input() labels: RR.Label[];
  @Input() reportIds: number[];
  @Input() popoverLabel: NgbPopover;
  @Input() viewMode: 'filter' | 'create';

  @Output() onSelect = new EventEmitter<number[]>();

  labelName = new FormControl<string | undefined>(undefined, {
    nonNullable: true,
    validators: [Validators.required],
  });

  subscription = new Subscription();

  constructor(
    private labelEffect: LabelEffect,
    private reportEffect: ReportEffect,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {}

  createLabel() {
    this.subscription.add(
      this.labelEffect.create({ name: this.labelName.value }).subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            message: 'Report Label Created',
            type: 'success',
          });

          this.labelName.reset();
        },
      }),
    );
  }

  deleteLabel(labelId: number) {
    // Update all reports store containing the selected label
    this.subscription.add(
      this.labelEffect
        .delete(labelId)
        .pipe(
          switchMap(() => this.store.select(fromReport.selectAll).pipe(take(1))),
          map((reports) => reports.filter((report) => report.label_ids.includes(labelId))),
          switchMap((reportsToUpdate) => {
            const updateObservables = reportsToUpdate.map((report) => {
              const updatedLabels = report.label_ids.filter((id) => id !== labelId);
              return this.reportEffect.update(report.id, { label_ids: updatedLabels });
            });
            return merge(...updateObservables);
          }),
        )
        .subscribe(),
    );
  }

  updateReportLabel() {
    this.subscription.add(
      from(this.reportIds)
        .pipe(
          mergeMap((reportId) => this.reportEffect.update(reportId, { label_ids: this.selectedLabelIds })),
          finalize(() => this.popoverLabel.close()),
        )
        .subscribe(),
    );
  }

  onSelectLabelIds() {
    this.onSelect.emit(this.selectedLabelIds);
  }

  onLabelSelectionChange(labelId: number, event: Event) {
    const isSelected = (event.target as HTMLInputElement).checked;
    if (isSelected) {
      if (!this.selectedLabelIds.includes(labelId)) {
        this.selectedLabelIds = [...this.selectedLabelIds, labelId];
      }
    } else {
      this.selectedLabelIds = this.selectedLabelIds.filter((id) => id !== labelId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
