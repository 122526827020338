import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ElementActions } from '../template/element/element.action';
import { RegionActions } from '../template/region/region.action';
import { SectionActions } from '../template/section/section.action';
import { StatementCoincidenceActions } from '../template/statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../template/statement-set/statement-set.action';
import { StatementActions } from '../template/statement/statement.action';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateActions, TemplateBatchActions } from '../template/template/template.action';
import { TextObjectActions } from '../template/text-object/text-object.action';
import { MandatoryStatementActions } from './mandatory-statement.action';
import { MandatoryStatementCreate, MandatoryStatementHttpService } from './mandatory-statement.service';

@Injectable()
export class MandatoryStatementEffect {
  constructor(
    private store: Store<AppState>,
    private service: MandatoryStatementHttpService,
  ) {}

  create(data: MandatoryStatementCreate) {
    return this.service.create(data).pipe(
      tap(({ template, mandatory_statement }) => {
        this.store.dispatch(
          MandatoryStatementActions.createSuccessBatch({
            actions: {
              mandatoryStatementCreateSuccess: MandatoryStatementActions.createSuccess({
                mandatoryStatement: mandatory_statement,
              }),
              templateUpdateSuccess: TemplateActions.updateSuccess({ template }),
            },
          }),
        );
      }),
    );
  }

  delete(mandatoryStatementId: number) {
    return this.service.delete(mandatoryStatementId).pipe(
      tap((template) => {
        this.store.dispatch(
          MandatoryStatementActions.deleteSuccessBatch({
            actions: {
              mandatoryStatementDeleteSuccess: MandatoryStatementActions.deleteSuccess({
                mandatoryStatementId,
              }),
              templateUpdateSuccess: TemplateActions.updateSuccess({ template }),
            },
          }),
        );
      }),
    );
  }

  replace(mandatoryStatementId: number, data: MandatoryStatementCreate) {
    return this.delete(mandatoryStatementId).pipe(switchMap(() => this.create(data)));
  }

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      tap(
        ({
          mandatory_statements,
          sections,
          subsections,
          regions,
          elements,
          statement_sets,
          statements,
          dividers,
          text_objects,
          statement_coincidences,
        }) => {
          this.store.dispatch(
            MandatoryStatementActions.findInTemplateSuccess({
              actions: {
                findMandatoryStatementSuccess: MandatoryStatementActions.findManySuccess({
                  mandatoryStatements: mandatory_statements,
                }),
                findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                  actions: {
                    sectionAddMany: SectionActions.addMany({ sections: sections }),
                    subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
                    regionAddMany: RegionActions.addMany({ regions: regions }),
                    elementAddMany: ElementActions.addMany({ elements: elements }),
                    statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                    statementAddMany: StatementActions.addMany({ statements: statements }),
                    dividerAddMany: StatementActions.addMany({ statements: dividers }),
                    textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                    statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                      statementCoincidences: statement_coincidences,
                    }),
                  },
                }),
              },
            }),
          );
        },
      ),
    );
  }
}
