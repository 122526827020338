import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, nbspRegex } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { fromPathology } from 'app/store/pathology';
import { fromPatient } from 'app/store/patient';
import { FavouriteEffect } from 'app/store/report/favourite';
import { PresetEffect, PresetSearchResponse } from 'app/store/report/preset';
import { fromPresetTitle } from 'app/store/report/preset-title';
import { fromCurrentReport } from 'app/store/report/report';
import { TopicEffect, fromTopic } from 'app/store/report/topic';
import { fromBodyPart } from 'app/store/template/body-part';
import { TagInputComponent, TagInputModule } from 'ngx-chips';
import { Subscription, of } from 'rxjs';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';

import { FavouriteHeadlineComponent } from '../../../shared/components/favourite-headline/favourite-headline.component';
import { PathologyComponent } from '../../../shared/components/pathology/pathology.component';
import { SimilarPresetWarningComponent } from '../../../shared/components/similar-preset-warning/similar-preset-warning.component';
import { SharedModule } from '../../../shared/shared.module';
import { BodyPartComponent } from '../../admin/components/template-body-part/body-part.component';
import { RefTopicsComponent } from './ref-topics/ref-topics.component';

@Component({
  selector: 'rr-name-favourite',
  templateUrl: 'name-favourite-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RefTopicsComponent,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    FavouriteHeadlineComponent,
    SharedModule,
    SimilarPresetWarningComponent,
    PathologyComponent,
    BodyPartComponent,
  ],
})
export class NameFavouriteModalComponent implements OnInit, OnDestroy {
  @ViewChild('tagInput') tagInput: TagInputComponent;
  favourite: RR.Favourite | undefined;
  toReplaceFavourite: RR.Favourite | undefined;
  tagForm: FormGroup;
  subscription = new Subscription();

  presetForm = new FormGroup({
    presetNote: new FormControl('', { nonNullable: true }),
  });
  reportId: number | undefined; // input
  topic_id: number; // input
  topic: RR.Topic | undefined;
  report: RR.Report | undefined;
  patient: RR.Patient | undefined;

  similarPresetTitles: PresetSearchResponse | undefined;
  presetTitle: RR.PresetTitle;
  hasSelectedBodyPartOrPathology: boolean = false;

  pathology: RR.Pathology | undefined;
  bodyPart: RR.BodyPart | undefined;
  loadingPresetTitle: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private favouriteEffect: FavouriteEffect,
    private topicEffect: TopicEffect,
    private router: Router,
    private store: Store<AppState>,
    private presetEffect: PresetEffect,
  ) {}

  ngOnInit() {
    // Init tag input reactive form. Without reactive form, ngx-chips doesn't work properly
    if (this.favourite) {
      this.tagForm = new FormGroup({
        tagsInput: new FormControl(
          this.favourite.tags.map((tag) => ({ display: tag.text, value: tag })),
          [],
        ), // add validators here
      });
      requestAnimationFrame(() => {
        this.tagInput.focus(true);
      });
    } else {
      this.tagForm = new FormGroup({
        tagsInput: new FormControl([], []), // add validators here
      });
    }

    const topic$ = this.store.select(fromTopic.selectTopic(this.topic_id));
    this.subscription.add(
      topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    const report$ = this.store.select(fromCurrentReport.selectReport);
    this.subscription.add(
      report$.subscribe((report) => {
        this.report = report;
      }),
    );

    this.subscription.add(
      report$
        .pipe(
          filterDefined(),
          switchMap((report) => {
            if (report.patient_id === null) {
              return of(undefined);
            }
            return this.store.select(fromPatient.selectPatient(report.patient_id));
          }),
        )
        .subscribe((patient) => {
          this.patient = patient;
        }),
    );

    this.subscription.add(
      report$
        .pipe(
          filterDefined(),
          take(1),
          filter((report) => report.type === 'preset'),
          switchMap((report) =>
            this.presetEffect.findPresetTitle(report.preset_title_id).pipe(
              finalize(() => {
                this.loadingPresetTitle = false;
              }),
            ),
          ),
        )
        .subscribe(),
    );

    const presetTitle$ = report$.pipe(
      filterDefined(),
      filter((report) => report.type === 'preset'),
      switchMap((report) => this.store.select(fromPresetTitle.selectPresetTitle(report.preset_title_id))),
    );

    this.subscription.add(
      presetTitle$.pipe(take(1), filterDefined()).subscribe((presetTitle) => {
        this.presetTitle = presetTitle;
        if (this.report && this.report.preset_notes) {
          this.presetForm.controls.presetNote.setValue(this.report.preset_notes);
        }
      }),
    );

    const bodyPart$ = presetTitle$.pipe(
      filterDefined(),
      switchMap((presetTitle) => this.store.select(fromBodyPart.selectBodyPart(presetTitle.body_part_id))),
    );

    this.subscription.add(
      bodyPart$.pipe(take(1)).subscribe((bodyPart) => {
        this.bodyPart = bodyPart;
      }),
    );

    const pathology$ = presetTitle$.pipe(
      filterDefined(),
      switchMap((presetTitle) => this.store.select(fromPathology.selectPathology(presetTitle.pathology_id))),
    );

    this.subscription.add(
      pathology$.pipe(take(1)).subscribe((pathology) => {
        this.pathology = pathology;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  clearFavouriteName() {
    this.tagForm.setValue({ tagsInput: [] });
  }

  deleteFavouriteLegacy() {
    if (this.favourite) {
      // Delete favourite
      this.subscription.add(
        this.favouriteEffect
          .delete(this.favourite.id)
          .pipe(take(1))
          .subscribe((action) => {
            this.activeModal.close(action);
          }),
      );
    }
  }

  replaceFavouriteLegacy() {
    this.subscription.add(
      this.reportService
        .selectKioskUser()
        .pipe(
          take(1),
          switchMap((user) => {
            if (!this.toReplaceFavourite) {
              throw new Error('Favourite undefined');
            }
            const toReplaceFavourite = this.toReplaceFavourite;
            let user_ids = [...toReplaceFavourite.user_ids];
            // If user is not in the user_ids list, add user_id into that list
            if (user && user_ids.indexOf(user.id) === -1) {
              user_ids = [...user_ids, user.id];
            }
            return this.favouriteEffect.delete(toReplaceFavourite.id).pipe(
              switchMap(() => {
                return this.favouriteEffect.create(this.topic_id, {
                  tags: toReplaceFavourite.tags,
                  user_ids,
                });
              }),
            );
          }),
        )
        .subscribe(),
    );
  }

  static open({
    modalService,
    topic_id,
    favourite,
    reportId,
  }: {
    modalService: NgbModal;
    topic_id: number;
    favourite?: RR.Favourite | undefined;
    reportId?: number | undefined;
  }) {
    const modalRef = modalService.open(NameFavouriteModalComponent, {
      size: 'lg',
    });
    const componentInstance: NameFavouriteModalComponent = modalRef.componentInstance;
    componentInstance.topic_id = topic_id;
    componentInstance.favourite = favourite;
    componentInstance.reportId = reportId;
    return modalRef;
  }

  /**
   * Create a new anonymised Report and a Topic with the same template_id
   */
  createPreset(topic: RR.Topic) {
    const currentReport = this.report;
    if (!(this.pathology && this.bodyPart) || !currentReport) return;

    this.subscription.add(
      this.presetEffect
        .create({
          report: {
            body_part_id: this.bodyPart.id,
            pathology_id: this.pathology.id,
            preset_notes: this.presetForm.controls.presetNote.value,
          },
          patient: {
            patient_dob: this.patient?.patient_dob || null,
            patient_sex: this.patient?.patient_sex || null,
          },
        })
        .pipe(
          switchMap((action) => {
            const report = action.actions.createReportSuccess.report;
            return this.topicEffect
              .create({
                report_id: report.id,
                template_id: topic.template_id,
                title_option_id: topic.title_option_id,
                title_option_text: topic.title_option_text,
              })
              .pipe(
                map((topicAction) => {
                  const topic = topicAction.actions.createTopicSuccess.topic;
                  return {
                    report,
                    topic,
                  };
                }),
              );
          }),
        )
        .subscribe({
          next: ({ report, topic }) => {
            this.activeModal.dismiss();
            const queryParams = {
              presetFromReportId: this.reportId ? this.reportId : currentReport.id,
            };
            const url = this.router.createUrlTree(['/report', report.id, 'topic', topic.id], {
              queryParams: queryParams,
            });
            // eslint-disable-next-line no-restricted-properties
            window.open(this.router.serializeUrl(url), '_blank');
          },
        }),
    );
  }

  updatePreset(report: RR.Report) {
    if (!(this.pathology && this.bodyPart)) return;

    const changes = {
      preset_notes: this.presetForm.controls.presetNote.value,
      body_part_id: this.bodyPart.id,
      pathology_id: this.pathology.id,
    };

    this.subscription.add(
      this.presetEffect.update(report.preset_title_id, changes).subscribe({
        next: (action) => {
          this.activeModal.close(action);
        },
      }),
    );
  }

  deletePreset(report: RR.Report) {
    const message = `Are you sure you want to delete preset #${this.presetTitle.text}?`;
    this.subscription.add(
      this.reportService.deleteReport(report, message).subscribe(() => {
        window.close();
      }),
    );
  }

  searchPreset() {
    if (this.pathology && this.bodyPart) {
      this.subscription.add(
        this.presetEffect
          .searchAll({ pathology_id: this.pathology.id, body_part_id: this.bodyPart.id })
          .subscribe((presets) => {
            this.similarPresetTitles = presets.response;
          }),
      );
    }
  }

  onPathology(pathology: RR.Pathology) {
    this.pathology = pathology;
    this.searchPreset();
  }

  onBodyPart(bodyPart: RR.BodyPart) {
    this.bodyPart = bodyPart;
    this.searchPreset();
  }

  createLegacy() {
    setTimeout(() => {
      this.subscription.add(
        this.reportService
          .selectKioskUser()
          .pipe(
            take(1),
            switchMap((user) => {
              const tags: RR.FavouriteTag[] = this.tagForm.value.tagsInput.map((tag: any) => ({
                ...tag.value,
                text: tag.value.text.replace(nbspRegex, ' '),
              }));
              return this.favouriteEffect.create(this.topic_id, {
                user_ids: user ? [user.id] : [],
                tags,
              });
            }),
          )
          .subscribe((action) => {
            this.activeModal.close(action);
          }),
      );
    }, 100);
  }

  updateLegacy(favourite: RR.Favourite) {
    // Hacking to add the last tag on blur. TODO: Need to fix it later (because save was happening before onblur)
    setTimeout(() => {
      this.subscription.add(
        this.reportService
          .selectKioskUser()
          .pipe(
            take(1),
            switchMap((user) => {
              const tags: RR.FavouriteTag[] = this.tagForm.value.tagsInput.map((tag: any) => ({
                ...tag.value,
                text: tag.value.text.replace(nbspRegex, ' '),
              }));
              let user_ids = [...favourite.user_ids];
              // If user is not in the user_ids list, add user_id into that list
              if (user && user_ids.indexOf(user.id) === -1) {
                user_ids = [...user_ids, user.id];
              }
              return this.favouriteEffect.update(favourite.id, { tags, user_ids });
            }),
          )
          .subscribe((action) => {
            this.activeModal.close(action);
          }),
      );
    }, 100);
  }

  /**
   * Intercept to transform tag into Favourite Tag
   * @param tag
   */
  onTagAdding(tag: any) {
    if (typeof tag === 'string') {
      const value = { id: undefined, text: tag };
      return of({ display: tag, value });
    } else {
      return of(tag);
    }
  }

  /**
   * After edit a tag, update the value for the item in form control.
   * We don't have the onTagEditing to intercept the edit event before the value change
   * @param item
   */
  onTagEdited(item: any) {
    const editingTag = this.tagForm.value.tagsInput.find((tag: any) => tag.value === item.value);
    if (editingTag) {
      editingTag.value.text = item.display;
    }
  }

  onSelectRefFavourite(favourite: RR.Favourite) {
    this.toReplaceFavourite = favourite;
  }
}
