import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { StatementChoiceUsageAnalyticsActions } from './statement-choice-usage-analytics.action';
import { StatementUsages } from './statement-choice-usage-analytics.service';

export interface StatementChoiceUsageAnalyticsState extends EntityState<StatementUsages> {
  loaded: { [statementChoiceId: number]: boolean };
  statementChoiceIdMapping: { [statementChoiceId: number]: StatementUsages[] };
}

const adapter = createEntityAdapter<StatementUsages>({
  selectId: (statementUsages) => statementUsages.statement.id,
});

const initialState = adapter.getInitialState({
  loaded: {},
  statementChoiceIdMapping: {},
});

export const statementChoiceUsageAnalyticsReducer = createReducer(
  initialState,
  on(StatementChoiceUsageAnalyticsActions.findAllSuccess, (state, { response, statementChoiceId }) => {
    const data: StatementUsages[] = response.statement_usages.map((reports) => {
      return {
        ...reports,
      };
    });

    return {
      ...state,
      ...adapter.upsertMany(data, state),
      loaded: { ...state.loaded, [statementChoiceId]: true },
      defaultAttributes: response.default_attributes,
      statementChoiceIdMapping: {
        ...state.statementChoiceIdMapping,
        [statementChoiceId]: data,
      },
    };
  }),
);
