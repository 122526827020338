import { createEntityAdapter } from '@ngrx/entity';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Permission>();
const selectFeature = (state: AppState) => state.permission;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

export const fromPermission = {
  selectIds,
  selectEntities,
  selectAll,
};
