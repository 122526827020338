import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { PatientNameComponent } from 'app/shared/components/patient-name/patient-name.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { ProcessingDiffDirective } from 'app/shared/directives/processing-diff.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { fromReportAccessEvent } from 'app/store/report/access-event';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { fromUser } from 'app/store/user/user';
import { combineLatest, Observable, of, Subscription, switchMap, tap } from 'rxjs';

import { TopicTitleOptionTextComponent } from '../../../invoice/components/topic-title-option-text/topic-title-option-text.component';

@Component({
  standalone: true,
  selector: 'rr-report-text-diff',
  templateUrl: './report-text-diff.component.html',
  styleUrls: ['./report-text-diff.component.css'],
  imports: [
    CommonModule,
    PatientNameComponent,
    UserHeadlineComponent,
    ProcessingDiffDirective,
    SharedModule, // For SelectPipe
    TopicTitleOptionTextComponent,
    ReportAccessionNumberComponent,
  ],
})
export class ReportTextDiffComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() userId: number;
  userId$: Observable<number>;

  @Input() @BindObservable() reportId: number;
  reportId$: Observable<number>;

  reportTextLoading = true;
  finalReportText: string | null | undefined;
  accessUserSnapshot: string;

  report$: Observable<RR.Report>;
  patient$: Observable<RR.Patient | undefined>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.reportId$
        .pipe(
          tap(() => {
            this.reportTextLoading = true;
          }),
          switchMap((reportId) => this.reportEffect.findReportText(reportId)),
        )
        .subscribe(() => {
          this.reportTextLoading = false;
        }),
    );

    this.report$ = this.reportId$.pipe(
      switchMap((reportId) => this.store.select(fromReport.selectReport(reportId))),
      filterDefined(),
    );

    const accessEvents$ = this.reportId$.pipe(
      switchMap((reportId) => this.store.select(fromReportAccessEvent.selectAccessEventsInReport(reportId))),
    );

    // Get user snapshot text
    this.subscription.add(
      // Get all the accessevents for that report
      combineLatest([this.userId$, accessEvents$]).subscribe(([userId, events]) => {
        // Sort the events with descending order and filter out the latest event with snapshot text
        const snapshotEvents = events
          .sort((a, b) => {
            return new Date(b.when).getTime() - new Date(a.when).getTime();
          })
          .filter((event) => event.user_id === userId && event.snapshot_text);

        if (snapshotEvents.length > 0) {
          this.accessUserSnapshot = snapshotEvents[0].snapshot_text;
        } else {
          this.accessUserSnapshot = '';
        }
      }),
    );

    // Get final report text
    this.subscription.add(
      this.report$.subscribe((r) => {
        this.finalReportText = r.text;
      }),
    );

    this.patient$ = this.report$.pipe(
      switchMap((r) => (r.patient_id ? this.store.select(fromPatient.selectPatient(r.patient_id)) : of(undefined))),
    );
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  userSelectorFn = (userId: number) => {
    return this.store.select(fromUser.selectUser(userId));
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
