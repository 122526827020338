import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { AttributeOptionActions } from '../attribute-option/attribute-option.action';
import { AttributeGroup } from './default-attribute.service';

/**
 * Methods of interacting with the DefaultAttributes
 *
 * This describes the actions required to interface with the regions, which
 * are the standard Create, Read, Update, and Delete (CRUD) operations. With
 * the minor addition of getTemplate, which provides a method for only
 * retrieving a subset of the DefaultAttributes related to a specific
 * template.
 *
 * We have a getSuccess distinct from getTemplateSuccess, since these provide
 * different methods of updating the store. The `getSuccess` action will always
 * replace every value with the set of default attributes returned, while the
 * `getTemplateSuccess` being a subset of all attributes will only update the
 * values without deleting them all first.
 */
export const DefaultAttributeActions = {
  findAllSuccess: createAction(
    '[Default Attribute] Find All Success',
    props<{ defaultAttributes: RR.DefaultAttribute[] }>(),
  ),

  createSuccess: createAction('[Default Attribute] Create Success', props<{ defaultAttribute: RR.DefaultAttribute }>()),

  updateSuccess: createAction('[Default Attribute] Update Success', props<{ defaultAttribute: RR.DefaultAttribute }>()),

  deleteSuccess: createAction('[Default Attribute] Delete Success', props<{ defaultAttributeId: number }>()),

  findInTemplateSuccess: createAction(
    '[DefaultAttribute] Find In Template Success',
    props<{ templateId: number; defaultAttributes: RR.DefaultAttribute[] }>(),
  ),

  findInStatementSet: createAction(
    '[DefaultAttribute] Find In StatementSet',
    props<{ templateId: number; statementSetId: number }>(),
  ),

  findInStatementSetSuccess: createAction(
    '[DefaultAttribute] Find In StatementSet Success',
    props<{ templateId: number; statementSetId: number; defaultAttributes: RR.DefaultAttribute[] }>(),
  ),

  findInStatementSetFailed: createAction(
    '[DefaultAttribute] Find In StatementSet Failed',
    props<{ templateId: number; statementSetId: number }>(),
  ),

  createManySuccess: createAction(
    '[Default Attribute] Create Many Success',
    props<{ defaultAttributes: RR.DefaultAttribute[] }>(),
  ),
  updateManySuccess: createAction(
    '[DefaultAttribute] Update Many Success',
    props<{ defaultAttributes: RR.DefaultAttribute[] }>(),
  ),
  findManySuccess: createAction(
    '[DefaultAttribute] Find Many Success',
    props<{ defaultAttributes: RR.DefaultAttribute[] }>(),
  ),

  reset: createAction('[DefaultAttribute] Reset'),
};

export const DefaultAttributeBatchActions = {
  findByStatementSuccess: createBatchAction(
    '[DefaultAttribute] Find By Statement Success',
    props<{
      usages: AttributeGroup[];
      actions: {
        defaultAttributeFindSuccess: ReturnType<typeof DefaultAttributeActions.findManySuccess>;
        attributeFindSuccess: ReturnType<typeof AttributeOptionActions.findManySuccess>;
      };
    }>(),
  ),
};
