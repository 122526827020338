import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for TitleOption
 *
 */
export const TitleOptionActions = {
  findInTemplateSuccess: createAction(
    '[TitleOption] Find In Template Success',
    props<{ titleOptions: RR.TitleOption[] }>(),
  ),

  findInTitleSetSuccess: createAction(
    '[TitleOption] Find In Title Set Success',
    props<{ titleOptions: RR.TitleOption[] }>(),
  ),

  deleteManySuccess: createAction('[TitleOption] Delete Many Success', props<{ titleIds: number[] }>()),
};
