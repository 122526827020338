import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.UserSetting>();
const selectFeature = (state: AppState) => state.user_setting;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectUserSetting = (user_id: number) =>
  createSelector(selectAll, (userSettings: RR.UserSetting[]) => userSettings.find((us) => us.user_id === user_id));

const selectUserSettingLoaded = (user_id: number) =>
  createSelector(selectUserSetting(user_id), (userSetting) => !!userSetting);

export const fromUserSetting = {
  selectIds,
  selectEntities,
  selectAll,
  selectUserSetting,
  selectUserSettingLoaded,
};
