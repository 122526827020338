import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { BillingItemActions } from './billing-item.action';
import { fromBillingItem } from './billing-item.selector';
import { BillingItemHttpService } from './billing-item.service';

@Injectable()
export class BillingItemEffect {
  constructor(
    private store: Store<AppState>,
    private service: BillingItemHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromBillingItem.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      this.message.handleHttpErrorPipe,
      map((billingItems: RR.BillingItem[]) => BillingItemActions.findAllSuccess({ billingItems })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(billingItem: Partial<RR.BillingItem>) {
    return this.service.create(billingItem).pipe(
      this.message.handleHttpErrorPipe,
      map((billingItem: RR.BillingItem) => BillingItemActions.createSuccess({ billingItem })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(billingItemId: number, changes: Partial<RR.BillingItem>) {
    return this.service.update(billingItemId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((billingItem) => BillingItemActions.updateSuccess({ billingItem })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(billingItemId: number) {
    return this.service.delete(billingItemId).pipe(
      this.message.handleHttpErrorPipe,
      map((billingItem) => BillingItemActions.updateSuccess({ billingItem })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
