import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { InvoiceActions } from '../invoice/invoice.action';
import { PaymentActions } from './payment.action';
import { PaymentCreationHttpResponse, PaymentHttpService } from './payment.service';

@Injectable()
export class PaymentEffect {
  constructor(
    private store: Store<AppState>,
    private service: PaymentHttpService,
  ) {}

  createMany(invoiceId: number, payments: Pick<RR.Payment, 'payment_type' | 'amount'>[]) {
    return this.service.createMany(invoiceId, payments).pipe(
      map((data: PaymentCreationHttpResponse) => {
        return PaymentActions.createManySuccessBatch({
          actions: {
            createPaymentsSuccess: PaymentActions.createManySuccess({ payments: data.payments }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({
              invoice: data.invoice,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteMany(invoiceId: number, paymentIds: number[]) {
    return this.service.deleteMany(invoiceId, paymentIds).pipe(
      map((data) => {
        return PaymentActions.deleteManySuccessBatch({
          actions: {
            deletePaymentSuccess: PaymentActions.deleteManySuccess({ paymentIds }),
            updateInvoiceSuccess: InvoiceActions.updateSuccess({
              invoice: data.invoice,
            }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(paymentId: number, changes: Partial<RR.Payment>) {
    return this.service.update(paymentId, changes).pipe(
      map((payment: RR.Payment) => PaymentActions.updateSuccess({ payment })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
