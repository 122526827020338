import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RegistrationFormHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.RegistrationForm[]> {
    return this.http.get<RR.RegistrationForm[]>('/api/registration_form');
  }
}
