import { createActionGroup, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { ReportActions } from '../report/report.action';

/**
 * Ngrx Actions for ElementChoice
 *
 */
export const ElementChoiceActions = createActionGroup({
  source: 'Element Choice',
  events: {
    addMany: props<{ elementChoices: RR.ElementChoice[] }>(),
    upsertMany: props<{ elementChoices: RR.ElementChoice[] }>(),
    upsertOne: props<{ elementChoice: RR.ElementChoice }>(),
    removeMany: props<{ elementChoiceIds: number[] }>(),
  },
});

export const ElementChoiceBatchActions = {
  updateSuccess: createBatchAction(
    '[ElementChoice] Update Success Batch',
    props<{
      actions: {
        elementChoiceUpdateSuccess: ReturnType<typeof ElementChoiceActions.upsertOne>;
        reportUpdateSuccess: ReturnType<typeof ReportActions.upsertOne>;
      };
    }>(),
  ),
};
