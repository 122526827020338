import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromScanCode } from '../scan-code/scan-code.selector';

const adapter = createEntityAdapter<RR.BillingGroup>();
const selectFeature = (state: AppState) => state.billing_group;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectBillingGroup = (id: number) => createSelector(selectEntities, (billingGroups) => billingGroups[id]);
const selectLoaded = createSelector(selectFeature, (billing_group) => billing_group.loaded);
const selectInScanCode = (scanCodeId: number) =>
  createSelector(
    fromScanCode.selectScanCode(scanCodeId),
    selectEntities,
    (scanCode, billingGroups) =>
      scanCode?.billing_groups.map((gid) => billingGroups[gid]).filter((g): g is RR.BillingGroup => !!g) || [],
  );

export const fromBillingGroup = {
  selectIds,
  selectEntities,
  selectAll,
  selectBillingGroup,
  selectLoaded,
  selectInScanCode,
};
