import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { ElementChoiceActions } from './element-choice.action';

export type ElementChoiceState = EntityState<RR.ElementChoice>;

const adapter = createEntityAdapter<RR.ElementChoice>();

const initialState = adapter.getInitialState();

export const elementChoiceReducer = createReducer(
  initialState,
  on(ElementChoiceActions.addMany, (state, { elementChoices }) => adapter.addMany(elementChoices, state)),
  on(ElementChoiceActions.upsertMany, (state, { elementChoices }) => adapter.upsertMany(elementChoices, state)),
  on(ElementChoiceActions.upsertOne, (state, { elementChoice }) => adapter.upsertOne(elementChoice, state)),
  on(ElementChoiceActions.removeMany, (state, { elementChoiceIds }) => adapter.removeMany(elementChoiceIds, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
