import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromSendEvent } from 'app/store/report/send-event';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { SendReportModalComponent } from '../send-events-modal/send-report-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, NgbModule, SendReportModalComponent],
  selector: 'rr-send-report-button',
  templateUrl: './send-report-button.component.html',
  styleUrls: ['./send-report-button.component.scss'],
})
export class SendReportButtonComponent implements OnDestroy, AfterViewInit {
  @ViewChild('distributeReport', { static: false }) distributeReport: ElementRef;
  @Input() @BindObservable() focus: boolean;
  focus$: Observable<boolean>;
  @Input() report_id: number;
  @Input() btnSm = false;
  @Input() btnStyle: string;
  @Input() btnClass = '';
  @Input() parent: 'WORKLIST';

  subscription = new Subscription();
  constructor(
    private reportService: ReportService,
    private modal: NgbModal,
    private store: Store<AppState>,
  ) {}

  ngAfterViewInit(): void {
    this.subscription.add(
      this.focus$.subscribe((focus) => {
        if (focus) {
          requestAnimationFrame(() => {
            this.distributeReport.nativeElement.focus();
          });
        }
      }),
    );
  }

  getSendEvents() {
    return this.store.select(fromSendEvent.selectSendEventsInReport(this.report_id));
  }

  getSendStatus() {
    return this.reportService.getReportSendStatus(this.report_id);
  }

  openSendEventsModal() {
    this.subscription.add(
      this.reportService
        .getReport(this.report_id)
        .pipe(take(1))
        .subscribe((report) => {
          SendReportModalComponent.open(this.modal, report, this.parent);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
