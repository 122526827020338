import { Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BookingHeadlineComponent } from '../../../../shared/components/booking-headline/booking-headline.component';
import { BookingDocumentAttachmentComponent } from '../../../booking/components/booking-document-attachment/booking-document-attachment.component';

@Component({
  selector: 'rr-booking-document-attachment-modal',
  templateUrl: './booking-document-attachment-modal.component.html',
  standalone: true,
  imports: [BookingHeadlineComponent, BookingDocumentAttachmentComponent],
})
export class BookingDocumentAttachmentModalComponent {
  @Input() booking_id: number;
  @Input() parent: 'MODAL' | 'COMPONENT';

  constructor(public activeModal: NgbActiveModal) {}

  static open(modal: NgbModal, booking_id: number) {
    const modalRef = modal.open(BookingDocumentAttachmentModalComponent, {
      size: 'lg',
      centered: true,
    });
    const self: BookingDocumentAttachmentModalComponent = modalRef.componentInstance;
    self.booking_id = booking_id;
    self.parent = 'MODAL';
    return modalRef;
  }
}
