import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { EMPTY } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { DocumentationLinkActions } from './documentation-link.action';
import { fromDocumentationLink } from './documentation-link.selector';
import { DocumentationLinkHttpService } from './documentation-link.service';

@Injectable()
export class DocumentationLinkEffect {
  constructor(
    private store: Store<AppState>,
    private service: DocumentationLinkHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromDocumentationLink.selectLoadingStatus).pipe(
      take(1),
      switchMap((loadingStatus) => {
        if (loadingStatus === 'loading' || loadingStatus === 'loaded') {
          return EMPTY;
        } else {
          // loading has started
          this.store.dispatch(DocumentationLinkActions.findAll()); // Set loadingStatus to loading
          return this.service.findAll().pipe(
            this.message.handleHttpErrorPipe,
            map((docs) => DocumentationLinkActions.findAllSuccess({ docs })),
            tap((action) => this.store.dispatch(action)), // set loadingStatus to loaded
          );
        }
      }),
    );
  }

  create(doc: Partial<RR.DocumentationLink>) {
    return this.service.create(doc).pipe(
      this.message.handleHttpErrorPipe,
      map((doc: RR.DocumentationLink) => DocumentationLinkActions.createSuccess({ doc })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(id: number, doc: RR.DocumentationLink) {
    return this.service.update(id, doc).pipe(
      this.message.handleHttpErrorPipe,
      map((doc: RR.DocumentationLink) => DocumentationLinkActions.updateSuccess({ doc })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(docId: number) {
    return this.service.delete(docId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => DocumentationLinkActions.deleteSuccess({ docId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
