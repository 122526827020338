<div class="modal-header">
  <h4 class="modal-title">{{ !category || clone ? 'Create New Category' : 'Edit Category' }}</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div permission [permission-only]="['template_manage']">
    <div>
      <span>Name:</span>
      <input
        type="text"
        [(ngModel)]="categoryText"
        class="form-control mt-1"
        name="newCategory"
        id="newCategory"
        placeholder="Enter category name"
        autocomplete="off"
        rrAutoFocus
      />
    </div>
    <div class="mt-2">
      <span>Tooltip:</span>
      <textarea
        class="form-control mt-1"
        placeholder="Enter tooltip for category"
        [(ngModel)]="categoryTooltip"
        name="tooltip"
        autocomplete="off"
      ></textarea>
    </div>
    <div class="d-flex mt-2">
      <button
        [disabled]="!categoryText || categoryText.trim() === ''"
        class="btn btn-primary ms-auto"
        (click)="submitCategory()"
      >
        Save
      </button>
    </div>
  </div>
</div>
