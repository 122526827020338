import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { PathologyActions } from './pathology.action';
import { PathologyHttpService } from './pathology.service';

@Injectable()
export class PathologyEffect {
  constructor(
    private store: Store<AppState>,
    private service: PathologyHttpService,
    private message: MessageService,
  ) {}

  create(pathology: Partial<RR.Pathology>) {
    return this.service.create(pathology).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.Pathology) =>
        PathologyActions.createSuccess({
          pathology: data,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(pathologyId: number) {
    return this.service.delete(pathologyId).pipe(
      this.message.handleHttpErrorPipe,
      map((data: RR.Pathology) =>
        PathologyActions.deleteSuccess({
          pathology_id: data.id,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((pathologies) => PathologyActions.findManySuccess({ pathologies })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(search: string) {
    return this.service.search(search).pipe(
      this.message.handleHttpErrorPipe,
      map((pathologies) => PathologyActions.findAllSuccess({ pathologies })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
