import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BillingItemActions } from './billing-item.action';

export interface BillingItemState extends EntityState<RR.BillingItem> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.BillingItem>();

const initialState = adapter.getInitialState({ loaded: false });

export const billingItemReducer = createReducer(
  initialState,
  on(BillingItemActions.findAllSuccess, (state: BillingItemState, { billingItems }) => ({
    ...adapter.setAll(billingItems, state),
    loaded: true,
  })),
  on(BillingItemActions.findManySuccess, (state: BillingItemState, { billingItems }) =>
    adapter.upsertMany(billingItems, state),
  ),
  on(BillingItemActions.findSuccess, (state: BillingItemState, { billingItem }) =>
    adapter.upsertOne(billingItem, state),
  ),
  on(BillingItemActions.createSuccess, (state: BillingItemState, { billingItem }) =>
    adapter.addOne(billingItem, state),
  ),
  on(BillingItemActions.updateSuccess, (state: BillingItemState, { billingItem }) =>
    adapter.upsertOne(billingItem, state),
  ),
  on(BillingItemActions.deleteSuccess, (state: BillingItemState, { billingItemId }) =>
    adapter.removeOne(billingItemId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
