import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import {
  TutorialModalComponent,
  TutorialType,
} from 'app/modules/tutorial/modals/tutorial-modal/tutorial-modal.component';
import { AppState } from 'app/store';
import { fromUser } from 'app/store/user/user';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
})
export class AuditComponent implements OnInit, OnDestroy {
  @Input() event: RR.AuditEvent | RR.SendEvent;
  auditEvent: RR.AuditEvent | undefined;
  sendEvent: RR.SendEvent | undefined;
  @Input() topic: RR.Topic;

  subscription = new Subscription();
  user$: Observable<RR.User | undefined>;

  constructor(
    private editorService: EditorService,
    private reportService: ReportService,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    if ('communication_type' in this.event) {
      this.sendEvent = this.event;
    } else if ('type' in this.event) {
      this.auditEvent = this.event;
      this.user$ = this.store.select(fromUser.selectUser(this.event.user_id));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  jumpToStatements(event: RR.AuditEvent) {
    const statement_choice_id = event.statement_choice_id;
    this.subscription.add(
      this.reportService
        .getStatementChoice(statement_choice_id)
        .pipe(take(1))
        .subscribe((choice) => {
          if (!choice) return;
          this.editorService.publishFocusChoice(statement_choice_id);
        }),
    );
  }

  undoDelete(event: RR.AuditEvent) {
    // Report specific choice
    if (!event.statement_id && event.action) {
      this.subscription.add(
        this.reportService
          .createSpecificChoice(this.topic.id, event.element_id, event.region_id, event.action.text)
          .pipe(take(1))
          .subscribe((action) => {
            const choice = action.actions.statementChoiceAddMany.statementChoices[0];
            this.editorService.publishFocusChoice(choice.id);
          }),
      );
      return;
    }
    const data = {
      statement_id: event.statement_id,
      element_id: event.element_id,
      region_id: event.region_id,
      topic_id: this.topic.id,
      clones: [],
      exclusive: false,
    };
    const region_id = event.region_id ? event.region_id : null;
    // @ts-expect-error strictNullChecks
    this.editorService.publishFocus({ statement_id: event.statement_id, element_id: event.element_id, region_id });
    this.subscription.add(this.reportService.chooseStatement(data).subscribe());
  }

  openTutorialModal(type: TutorialType) {
    TutorialModalComponent.open(this.modalService, type);
  }
}
