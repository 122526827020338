import { Injectable } from '@angular/core';
import { MessageService } from 'app/core/services/message.service';

import {
  StatementParameterConditionGroupHttpService,
  StatementParameterConditionGroup,
} from './statement-parameter-condition-group.service';

@Injectable()
export class StatementParameterConditionGroupEffect {
  constructor(
    private service: StatementParameterConditionGroupHttpService,
    private message: MessageService,
  ) {}

  find(statementId: number) {
    return this.service.find(statementId).pipe(this.message.handleHttpErrorPipe);
  }

  create(statement_parameter_condition_group: StatementParameterConditionGroup) {
    return this.service.create(statement_parameter_condition_group).pipe(this.message.handleHttpErrorPipe);
  }

  update(id: number, statement_parameter_condition_group: Partial<StatementParameterConditionGroup>) {
    return this.service.update(id, statement_parameter_condition_group).pipe(this.message.handleHttpErrorPipe);
  }
}
