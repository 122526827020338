<ng-container *ngIf="presets$ | async as presets">
  <div class="alert alert-danger" role="alert">
    <h4 class="text-danger">Presets with similar names found</h4>
    <ng-container class="d-flex gap-2" *ngFor="let report of presets.reports">
      <ng-container *ngIf="report.preset_title_id | storeSelectPipe: selectPresetTitle | async as presetTitle">
        <label for="preset-{{ report.id }}" class="form-check-label">
          {{ presetTitle.text }}
          <span class="badge bg-secondary ms-3">Statements: {{ presets.statement_counts[report.id] || 0 }}</span>
        </label>
        <a class="ms-1" target="_blank" [routerLink]="['/report', report.id, 'edit']">
          <span class="small fa fa-external-link-alt"></span>
        </a>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
