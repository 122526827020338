import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rr-date-type',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './date-type.component.html',
  styleUrls: ['./date-type.component.css'],
})
export class DateTypeComponent implements OnInit {
  @Input() dateType: RR.DateType;
  placeholder: string;

  ngOnInit(): void {
    this.placeholder = DateTypeComponent.getDateTypePlaceholder(this.dateType);
  }

  // static so that we can get a placeholder string in the component.ts file
  static getDateTypePlaceholder(dateType: RR.DateType | undefined) {
    if (dateType === 'day_month_year') {
      return 'dd/mm/yyyy';
    } else if (dateType === 'month_year') {
      return 'mm/yyyy';
    } else if (dateType === 'year') {
      return 'yyyy';
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (dateType === undefined) {
      return 'dd/mm/yyyy';
    } else {
      throw new Error(`Unknown date type: ${dateType}`);
    }
  }
}
