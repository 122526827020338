import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { CategoryActions } from './category.action';

export type CategoryState = EntityState<RR.Category>;

const adapter = createEntityAdapter<RR.Category>();

const initialState = adapter.getInitialState();

export const categoryReducer = createReducer(
  initialState,
  on(CategoryActions.findAllSuccess, (state: CategoryState, { categories }) => adapter.setAll(categories, state)),
  on(CategoryActions.createSuccess, (state: CategoryState, { category }) => adapter.addOne(category, state)),
  on(CategoryActions.updateSuccess, (state: CategoryState, { category }) => adapter.upsertOne(category, state)),
  on(CategoryActions.deleteSuccess, (state: CategoryState, { categoryId }) => adapter.removeOne(categoryId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
