import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Signature>();
const selectFeature = (state: AppState) => state.signature;

// The @ngrx/entity adapter provides a number of selectors for us
const selectSignature = (signatureId: number) =>
  createSelector(selectEntities, (signatures: Dictionary<RR.Signature>) => signatures[signatureId]);
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectLoaded = createSelector(selectFeature, (signature) => signature.loaded);

export const fromSignature = {
  selectSignature,
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
};
