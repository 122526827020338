import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ProviderNumber>();
const selectFeature = (state: AppState) => state.provider_number;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectProviderNumber = (id: number) => createSelector(selectEntities, (providerNumbers) => providerNumbers[id]);
const selectLoaded = createSelector(selectFeature, (provider_number) => provider_number.loaded);

export const fromProviderNumber = {
  selectIds,
  selectEntities,
  selectAll,
  selectProviderNumber,
  selectLoaded,
};
