import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.PatientQuestionAnswer>();
const selectFeature = (state: AppState) => state.patient_question_answer;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectLoaded = (report_id: number) => createSelector(selectFeature, (state) => state.loaded[report_id]);
export const fromPatientQuestionAnswer = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
};
