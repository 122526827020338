<div class="modal-header">
  <div>
    <h5 class="modal-title">Report Status</h5>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div class="form-check d-flex" *ngFor="let status of reportStatuses">
    <input
      id="report-status-{{ status.id }}"
      class="form-check-input"
      type="radio"
      name="set"
      [value]="status.id"
      [formControl]="statusControl"
    />
    <label for="report-status-{{ status.id }}" class="form-check-label ps-1 w-100">
      {{ status.name }}
    </label>
  </div>
</div>

<div class="modal-footer d-flex">
  <button class="btn btn-danger" type="button" (click)="activeModal.dismiss()">Cancel</button>
  <button class="btn btn-success" (click)="onSubmit()"><i class="fas fa-save"></i> Save</button>
</div>
