<nav>
  <ol
    class="breadcrumb align-items-center py-0 ps-1 pe-0 mb-0 text-info"
    role="button"
    rrTooltip="HIGHLIGHT_STATEMENT_ONLY"
    (click)="jumpToStatement()"
  >
    <li class="breadcrumb-item">
      {{ section.title }}
    </li>
    <li class="breadcrumb-item" [title]="subsection.name ? subsection.name : 'NO NAME'">
      {{ truncateText(subsection.name ? subsection.name : 'NO NAME') }}
    </li>
    <li class="breadcrumb-item" *ngIf="subsection.region_set_id && selectedRegion">
      {{ selectedRegion.name }}
    </li>
    <ng-container *ngIf="getElementName(element) | async as elementName">
      <li class="breadcrumb-item" [title]="elementName">
        {{ truncateText(elementName) }}
      </li>
    </ng-container>
  </ol>
  <div *ngIf="subsection.region_set_id as region_set_id">
    <select class="form-select" (change)="onRegionChange($event)" *ngIf="!(breadcrumb$ | async)?.region_id">
      <option
        *ngFor="let r of getRegions(region_set_id) | async"
        [value]="r.id"
        [selected]="selectedRegion ? selectedRegion.id === r.id : false"
      >
        {{ r?.name }}
      </option>
    </select>
  </div>
</nav>
<div class="d-flex no-wrap mb-2">
  <a
    rr-statement
    class="list-group-item list-group-item-action text-start text-dark w-100 p-0 text-break"
    [statement]="statement"
    [topic]="topic"
    [template_id]="topic.template_id"
    [element]="element"
    [subsection]="subsection"
    [section]="section"
    [region]="selectedRegion"
    [element_choice]="elementChoice"
    type="recommendation"
    [proposed]="proposed"
    [correlationScore]="correlationScore"
    [context]="context"
  ></a>
  <button
    *ngIf="context === 'IMGSIM'"
    class="btn btn-sm btn-outline-dark ms-1"
    (click)="openConditionModal()"
    permission
    [permission-only]="['set_configuration']"
  >
    <i class="fa fa-cog"></i>
  </button>
</div>
