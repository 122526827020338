<div class="modal-header">
  <h4 class="modal-title">{{ data.employee.name }}</h4>
  <button role="button" class="btn-close" (click)="activeModal.close()"></button>
</div>

<div class="modal-body">
  <div class="alert alert-warning mt-2">
    <a target="_blank" [href]="data.url" (click)="openRadpay()">You forgot to clock in today.</a>
  </div>
</div>

<div class="modal-footer d-flex">
  <a role="button" class="btn btn-primary ms-auto" ngbAutoFocus target="_blank" [href]="data.url" (click)="openRadpay()"
    >Clock in</a
  >
  <button class="btn btn-light ms-2" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
