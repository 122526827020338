import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PATIENT_FORM_ID } from '../components/patient-form/patient-form.component';
import { REFERRER_FORM_ID } from '../components/referrer-form/referrer-form.component';
import { REPORT_FORM_ID } from '../components/report-form/report-form.component';

type BookingData = Pick<RR.Booking, 'billing_items' | 'funder'>;

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  /**
   * The data is shared between components (BookingCodeDetailComponent, BookingReviewComponent, ReportFormComponent). It
   * is easier to co-ordinate these components with this shared service than with Inputs/Outputs.
   */
  bookingData = new BehaviorSubject<BookingData>({
    billing_items: [],
    funder: 'medicare',
  });

  emitBooking(data: Partial<BookingData>) {
    this.bookingData.next({
      ...this.bookingData.getValue(),
      ...data,
    });
  }

  focus(id: typeof PATIENT_FORM_ID | typeof REFERRER_FORM_ID | typeof REPORT_FORM_ID) {
    document.getElementById(id)?.scrollIntoView(true);
  }
}
