import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: 'textarea[rrAutoSize]',
  // so that we can `#noteTextArea="rrAutoSize"`
  exportAs: 'rrAutoSize',
})
export class AutoSizeDirective implements AfterViewInit, OnDestroy {
  subscription = new Subscription();
  constructor(public element: ElementRef) {}

  /**
   * re-adjust when this emits
   */
  @Input() resizeOn: EventEmitter<any>;

  @HostListener('input', ['$event.target'])
  onInput(_textArea: HTMLTextAreaElement) {
    this.adjust();
  }

  ngAfterViewInit() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.resizeOn) {
      this.subscription.add(
        this.resizeOn.subscribe(() => {
          this.adjust();
        }),
      );
    }
    this.element.nativeElement.style.overflow = 'hidden';
    this.subscription = fromEvent(this.element.nativeElement, 'input').subscribe(() => {
      this.adjust();
    });

    requestAnimationFrame(() => {
      this.adjust();
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  adjust() {
    this.element.nativeElement.style.height = 'auto';
    this.element.nativeElement.style.height = this.element.nativeElement.scrollHeight + 'px';
  }
}
