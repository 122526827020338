<div class="modal-header">
  <div class="modal-title">Indicate Report Title (I)</div>
  <button role="button" class="btn-close" rrTooltip="NAVIGATOR_CLOSE_BUTTON" (click)="reportTitleChecker()"></button>
</div>

<div class="modal-body">
  <rr-report-title-edit [topic]="topic" [confirmCentered]="false"></rr-report-title-edit>
  <br />
  <button
    role="button"
    class="btn btn-sm btn-primary ms-2"
    (click)="prefillSentences('TITLE')"
    rrTrack
    rrTooltip="PREFILL_TITLE_SENTENCES"
  >
    <span>Prefill Title (T)</span>
  </button>
  <button
    role="button"
    class="btn btn-sm btn-primary ms-2"
    (click)="setPrefillType('PRESET')"
    rrTrack
    rrTooltip="PREFILL_PRESET"
  >
    <span><i class="fas fa-filter me-1"></i>Prefill PRESETS (F)</span>
  </button>
</div>

<div class="modal-footer d-flex">
  <button class="btn btn-success ms-auto" role="button" (click)="reportTitleChecker()">Save</button>
</div>
