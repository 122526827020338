import { createReducer, on } from '@ngrx/store';

import { WorklistActions } from './worklist.action';

export interface WorklistState {
  pageSize: number;
  selectedReportId: number;
}

const initialState: WorklistState = {
  pageSize: 20,
  // @ts-expect-error strictNullChecks
  selectedReportId: undefined,
};

export const worklistReducer = createReducer(
  initialState,
  on(WorklistActions.updatePageSize, (state, { pageSize }) => {
    return { ...state, pageSize };
  }),
  // @ts-expect-error strictNullChecks
  on(WorklistActions.selectReport, (state, { reportId }) => {
    if (state.selectedReportId === reportId) {
      // Unselect if the same report is clicked
      return { ...state, selectedReportId: undefined };
    } else {
      return { ...state, selectedReportId: reportId };
    }
  }),
  // @ts-expect-error strictNullChecks
  on(WorklistActions.unselectReport, (state) => {
    return { ...state, selectedReportId: undefined };
  }),
);
