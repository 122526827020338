import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Document>({
  selectId: (document: RR.Document) => document.filename,
});
const selectFeature = (state: AppState) => state.document;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);
const selectLoaded = (booking_id: number) => createSelector(selectFeature, (state) => state.loaded[booking_id]);
export const fromDocument = {
  selectIds,
  selectEntities,
  selectAll,
  selectLoaded,
};
