import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SignatureHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Signature[]> {
    return this.http.get<RR.Signature[]>('/api/signature');
  }

  create(signature: Partial<RR.Signature>): Observable<RR.Signature> {
    return this.http.post<RR.Signature>('/api/signature', signature);
  }

  update(signatureId: number, changes: Partial<RR.Signature>): Observable<RR.Signature> {
    return this.http.put<RR.Signature>(`/api/signature/${signatureId}`, changes);
  }
}
