import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class VoyagerUrlService {
  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  getVoyagerURL(acccessionNumber: string): SafeUrl {
    const voyagerLaunchURL = new URL('voyager://');
    voyagerLaunchURL.searchParams.append('accession', acccessionNumber);
    // The min version. Change this to force outdated clients to be upgraded.
    // Version string must have at least one dot. For example "1.0"
    // https://github.com/fluidsolar/voyager-launcher/blob/9aee20c183031f4e305bbeb84a328f76c29e7bff/VoyagerLauncher/Program.cs#L42
    voyagerLaunchURL.searchParams.append('version', '3.1');
    return this.sanitizer.bypassSecurityTrustUrl(voyagerLaunchURL.toString());
  }

  openVoyager(accessionNumber: RR.Report['accession_number']) {
    if (accessionNumber === null) {
      return;
    }
    const safeUrl = this.getVoyagerURL(accessionNumber);
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, safeUrl);

    if (sanitizedUrl) {
      // eslint-disable-next-line no-restricted-properties
      window.open(sanitizedUrl, '_self');
    }
  }

  openReportAndVoyager(report: RR.Report) {
    const reportURL = this.router.createUrlTree(['/report', report.id, 'edit']);
    // eslint-disable-next-line no-restricted-properties
    window.open(reportURL.toString(), '_blank');

    this.openVoyager(report.accession_number);
  }
}
