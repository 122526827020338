/* eslint-disable no-console */
import { environment } from 'environments/environment';

export function LifecycleLogger(constructor: any) {
  // webpack will compile this out for production builds
  /* eslint-disable-next-line no-constant-condition, @typescript-eslint/no-unnecessary-condition */
  if (!environment.production && false) {
    // @ts-expect-error allowUnreachableCode
    const ngOnInit = constructor.prototype.ngOnInit;
    const ngOnDestroy = constructor.prototype.ngOnDestroy;
    const ngOnChanges = constructor.prototype.ngOnChanges;

    constructor.prototype.ngOnInit = function () {
      console.debug(constructor.name + ' %cngOnInit', 'font-weight:bold');
      if (ngOnInit) ngOnInit.apply(this);
    };
    constructor.prototype.ngOnDestroy = function () {
      console.debug(constructor.name + ' %cngOnDestroy', 'font-weight:bold');
      if (ngOnDestroy) ngOnDestroy.apply(this);
    };
    // @ts-expect-error noImplicitAny
    constructor.prototype.ngOnChanges = function (...args) {
      console.debug(constructor.name + ' %cngOnChanges', 'font-weight:bold');
      if (ngOnChanges) ngOnChanges.apply(this, args);
    };
  }
  return constructor;
}
/* eslint-enable no-console */
