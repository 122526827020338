<div class="tag-container d-flex align-items-center">
  <span
    *ngIf="tag || divider"
    class="fa fa-eye text-info me-1"
    (click)="openDividerStatementsModal()"
    rrTooltip="SHOW_DIVIDER_SENTENCES"
    role="button"
  ></span>
  <i *ngIf="selected$ | async" class="fa fa-check text-success me-1"></i>
  <i *ngIf="isInCurrentReport$ | async" class="fa fa-tag text-warning me-1" rrTooltip="CURRENT_REPORT_DIVIDER"></i>

  <button
    *ngIf="tag"
    class="btn btn-link p-0 tag-text-button"
    [class.text-success]="selected$ | async"
    (click)="onClick()"
    ngbTooltip="{{ tag.tag_text }}"
  >
    <ngb-highlight
      [result]="showRegionText ? (tag.region_text ?? '') + ' ' + tag.tag_text : tag.tag_text"
      [term]="(filterTerm$ | async) ?? ''"
      [highlightClass]="'custom-ngb-highlight'"
    ></ngb-highlight>
  </button>
  <button
    *ngIf="divider"
    class="btn btn-link p-0 tag-text-button"
    [class.text-success]="selected$ | async"
    (click)="onClick()"
    ngbTooltip="{{ divider.text }}"
  >
    {{ divider.text }}
  </button>
  <button
    *ngIf="globalSearchTag"
    class="btn btn-link p-0 tag-text-button"
    [class.text-success]="selected$ | async"
    (click)="onClick()"
    ngbTooltip="{{ globalSearchTag._source.text }}"
  >
    <!-- showRegionText not supported because statements in global search don't have a region -->
    {{ globalSearchTag._source.text }}
  </button>
  <div *ngIf="tagChoice" class="btn-group mb-1 me-2" role="group">
    <button class="btn btn-sm btn-outline-primary" (click)="onClick()">
      <div
        class="d-flex align-items-center"
        *ngIf="tagChoice.tag_id | storeSelectPipe: selectStatement | async as divider"
      >
        {{ divider._text }}
      </div>
    </button>
    <button
      class="btn btn-outline-danger btn-sm"
      (click)="onTagRemoved(tagChoice); $event.preventDefault(); $event.stopPropagation()"
      title="Remove this group"
    >
      <span class="fa fa-trash-alt"></span>
    </button>
  </div>

  <ng-container *ngIf="tag">
    <span *ngIf="!hideScore" class="ms-auto badge bg-info tag-button"> {{ tag.score * 100 | number: '1.0-2' }}% </span>
  </ng-container>
</div>
