import './slate-types.d.ts';
import { SLATE_EDITOR_ID } from 'app/app.constants';
import React, { useMemo, useCallback, useEffect, KeyboardEventHandler, useRef } from 'react';
import { createEditor, Descendant, Editor, Element, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, Editable, withReact, RenderElementProps, ReactEditor } from 'slate-react';

import { AttributeTextObjectComponent } from './AttributeTextObjectComponent';
import { DateTextObjectComponent } from './DateTextObjectComponent';
import { handleDoubleClickOnWindows, handleInsertData } from './helpers';
import { NumberTextObjectComponent } from './NumberTextObjectComponent';
import { AttributeTextObjectElement, DateTextObjectElement, NumberTextObjectElement } from './slate-types';

function withInlines(editor: Editor) {
  const { isInline, isVoid, isSelectable, insertData } = editor;

  editor.isInline = (element: Element) => {
    // With this, attributes will be inserted as children of the paragraph. Without, it splits the paragraph into two.
    // TODO(slate-literal): add rr-literal to this list
    return ['rr-attribute', 'rr-number', 'rr-date'].includes(element.type) ? true : isInline(element);
  };

  editor.isVoid = (element: Element) => {
    // Prevents placing a cursor inside the attribute.
    return ['rr-attribute', 'rr-number', 'rr-date'].includes(element.type) ? true : isVoid(element);
  };

  editor.isSelectable = (element: Element) => {
    // Prevents selecting the attribute when you move the cursor over it. Instead it goes straight past.
    // return !['rr-attribute', 'rr-number'].includes(element.type) && isSelectable(element);
    return isSelectable(element);
  };

  editor.insertData = (data) => {
    handleInsertData(editor, insertData, data);
  };

  return editor;
}

function withSingleLine(editor: Editor) {
  const { normalizeNode } = editor;

  // Forces a single paragraph. Statements cannot have multiple paragraphs.
  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      if (editor.children.length > 1) {
        Transforms.mergeNodes(editor);
      }
    }
    return normalizeNode([node, path]);
  };

  return editor;
}

export type SlateEditorProps = {
  initialValue: Descendant[];
  templateId: number;
  onChange: (value: Descendant[]) => void;
  onEditor: (editor: Editor) => void;
  onKeyDown: KeyboardEventHandler<HTMLDivElement>;
  onClickAttribute: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, element: AttributeTextObjectElement) => void;
  onClickNumberAttribute: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    element: NumberTextObjectElement,
  ) => void;
  onClickDateAttribute: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, element: DateTextObjectElement) => void;
  /**
   * dev mode is to help with the debugging in the sandbox page. Set to false in the editor
   */
  devMode: boolean;
};

function App(props: SlateEditorProps) {
  const { initialValue } = props;
  return <MyEditor {...props} initialValue={initialValue} />;
}

function MyEditor(props: SlateEditorProps & { initialValue: Descendant[] }) {
  const editor = useMemo(() => withSingleLine(withInlines(withHistory(withReact(createEditor())))), []);
  const isFirstRun = useRef(true);

  const onClickNormalize = () => {
    Editor.normalize(editor, { force: true });
  };

  const onClickTestSelect = () => {
    Transforms.select(editor, { offset: 0, path: [0, 0] });
    ReactEditor.focus(editor);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      // Slate already handles the initialValue, so do nothing for the first render.
      isFirstRun.current = false;
    } else {
      // Slate doesn't handle changing the initialValue. This manually changes it.
      editor.children = props.initialValue;
      editor.onChange();
    }
  }, [props.initialValue]);

  useEffect(() => {
    props.onEditor(editor);
  }, [editor]);

  // After the `props.onEditor(editor)` effect so that `this.slateValue$.subscribe()` always has an `editor` to work with.
  useEffect(() => {
    // Send the `initialValue` to Angular because the `onChange` callback is not called for the first render.
    props.onChange(props.initialValue);
  }, []);

  const onChange = useCallback((value: Descendant[]) => {
    // Pass the value back to Angular
    props.onChange(value);
  }, []);

  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
    // Pass the event back to Angular
    props.onKeyDown(event);
  }, []);

  const renderElement = useCallback(
    (_props: RenderElementProps) => {
      const _element = _props.element; // Typescript 5.4 narrowing only works if there's an assignment
      switch (_element.type) {
        case 'rr-attribute':
          return (
            <AttributeTextObjectComponent
              {..._props}
              templateId={props.templateId}
              onClick={(event) => props.onClickAttribute(event, _element)}
            />
          );
        case 'rr-number':
          return (
            <NumberTextObjectComponent {..._props} onClick={(event) => props.onClickNumberAttribute(event, _element)} />
          );
        case 'rr-date':
          return (
            <DateTextObjectComponent {..._props} onClick={(event) => props.onClickDateAttribute(event, _element)} />
          );
        // TODO(slate-literal)
        case 'rr-literal':
          return <span {..._props.attributes}>{_props.children}</span>;
        case 'paragraph':
          return <p {..._props.attributes}>{_props.children}</p>;
      }
    },
    [props.templateId],
  );

  return (
    <>
      <Slate editor={editor} initialValue={props.initialValue} onChange={onChange}>
        {props.devMode && <button onClick={onClickNormalize}>Normalize</button>}
        {props.devMode && <button onClick={onClickTestSelect}>Test Selection</button>}

        <Editable
          className="d-block border shadow-sm p-1"
          id={SLATE_EDITOR_ID}
          placeholder="Enter a Statement..."
          renderElement={renderElement}
          onKeyDown={onKeyDown}
          onDoubleClick={handleDoubleClickOnWindows(editor)}
        />
      </Slate>
    </>
  );
}

export const SlateEditor = App;
