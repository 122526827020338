/**
 * Run with:
 *  ts-node src/attributeSearch.ts
 */

type PartialAttributeOption = Pick<RR.AttributeOption, 'id' | 'text' | 'frequency'>;

export type AttributeMatch = {
  attributeOption: PartialAttributeOption;
  attributeSetId: number;
  length: number;
};

export type DenormalisedAttribute =
  | {
      attributeOption: RR.AttributeOption;
      attributeSet: RR.AttributeSet;
    }
  | {
      // Simplied version with just 'id' and 'text' is for the tests
      attributeOption: PartialAttributeOption;
      attributeSet: Pick<RR.AttributeSet, 'id' | 'name'>;
    };
/*
This brute for searches all the suffixes of the text and returns the longest matches.

text: One two three four
attributes:
three four
four

All the suffixes are:
ou
fou < matches "four", length of match is 3/4
 fou
e fou
ee fou
ree fou
hree fou
three fou < matches "three four", length of match is 9/10
 three fou
o three fou
wo three fou
two three fou
 two three fou
e two three fou
ne two three fou
One two three fou
*/
export function findLongestMatches(options: {
  text: string;
  attributes: DenormalisedAttribute[];
  maxMatches?: number;
}): AttributeMatch[] {
  const { attributes, maxMatches = 10 } = options;
  // Case insensitive
  const text = options.text.toLowerCase();

  const matches: AttributeMatch[] = [];
  const MIN_MATCH_LENGTH = 1;
  if (text.length < MIN_MATCH_LENGTH) {
    throw new Error(`Text must be at least ${MIN_MATCH_LENGTH} characters long`);
  }

  // Create a reverse index of all the suffixes of text, to quickly look them up.
  const suffixIndex = new Map<string, number>();
  const words = text.split(' ');

  for (let i = words.length - 1; i >= 0; i--) {
    const suffix = words.slice(i).join(' ');
    suffixIndex.set(suffix, suffix.length);
  }
  // console.debug(suffixIndex);

  // Iterate through each attribute
  for (const attribute of attributes) {
    // Check if a prefix of the attribute is in the reverseIndex
    for (let j = MIN_MATCH_LENGTH; j <= attribute.attributeOption.text.length; j++) {
      // TODO: can we only search whole words, except for the last word, which has all prefixes
      const prefix = attribute.attributeOption.text.slice(0, j).toLowerCase();
      if (suffixIndex.has(prefix)) {
        const matchLength = suffixIndex.get(prefix);
        if (matchLength !== undefined) {
          const match: AttributeMatch = {
            attributeOption: attribute.attributeOption,
            attributeSetId: attribute.attributeSet.id,
            length: matchLength,
          };
          matches.push(match);
        }
      }
    }
  }

  matches.sort((a, b) => {
    return (
      // Sort by longest matching text first,
      b.length - a.length ||
      // Then by shortest attribute text
      a.attributeOption.text.length - b.attributeOption.text.length ||
      // Then by frequency descending
      b.attributeOption.frequency - a.attributeOption.frequency
    );
  });
  // console.debug(matches);
  return matches.slice(0, maxMatches);
}

// Comment to enable debug logging
// globalThis.console.debug = function () {};
