import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type PaymentCreationHttpResponse = {
  invoice: RR.Invoice;
  payments: RR.Payment[];
};

@Injectable()
export class PaymentHttpService {
  constructor(private http: HttpClient) {}

  createMany(
    invoiceId: number,
    payments: Pick<RR.Payment, 'payment_type' | 'amount'>[],
  ): Observable<PaymentCreationHttpResponse> {
    return this.http.post<PaymentCreationHttpResponse>(`/api/invoice/${invoiceId}/payments`, payments);
  }

  deleteMany(invoiceId: number, paymentIds: number[]): Observable<{ invoice: RR.Invoice }> {
    return this.http.request<{ invoice: RR.Invoice }>('delete', `/api/invoice/${invoiceId}/payments`, {
      body: { payment_ids: paymentIds },
    });
  }

  update(paymentId: number, changes: Partial<RR.Payment>): Observable<RR.Payment> {
    return this.http.put<RR.Payment>(`/api/payment/${paymentId}`, changes);
  }

  delete(paymentId: number): Observable<RR.Invoice> {
    return this.http.delete<RR.Invoice>(`/api/payment/${paymentId}`);
  }
}
