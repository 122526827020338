import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AppState } from '../app.state';
import { SettingActions } from './setting.action';
import { fromSetting } from './setting.selector';

@Injectable()
export class SettingEffect {
  constructor(private store: Store<AppState>) {}

  toggleKiosk() {
    this.store
      .select(fromSetting.selectKiosk)
      .pipe(take(1))
      .subscribe((b) => localStorage.setItem('kiosk', JSON.stringify(!b)));
    this.store.dispatch(SettingActions.toggleKiosk());
  }

  setUserClockedOn(value: boolean) {
    this.store.dispatch(SettingActions.setUserClockedOn({ value }));
  }
}
