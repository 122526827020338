import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagChoiceContextResponse } from 'app/store/prefill/tag-choice/tag-choice.service';
import { Observable } from 'rxjs';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';
import { TemplateData } from '../topic/topic.service';

export interface DividerStatementResponse {
  statement_choice: ChangeTrackerResponse | null;
  dividers: TagChoiceContextResponse[] | null;
}

export type SuggestedStatement = {
  statement: RR.Statement;
};

export type SuggestedStatementResponse = {
  auto_added_statements: SuggestedStatement[];
} & TemplateData;

@Injectable()
export class VoiceNoteHttpService {
  constructor(private http: HttpClient) {}
  create(voiceNote: Partial<RR.VoiceNote>): Observable<RR.VoiceNote> {
    return this.http.post<RR.VoiceNote>('/api/voice_notes', voiceNote);
  }
  update(voiceNoteId: number, changes: Partial<RR.VoiceNote>): Observable<RR.VoiceNote> {
    return this.http.put<RR.VoiceNote>(`/api/voice_notes/${voiceNoteId}`, changes);
  }
  delete(voiceNoteId: number) {
    return this.http.delete(`/api/voice_notes/${voiceNoteId}`);
  }
  addSuggestedNoteDividerStatementChoice(
    voiceNoteId: number,
    topicId: number,
    threshold: number,
  ): Observable<DividerStatementResponse> {
    return this.http.post<DividerStatementResponse>(
      `/api/voice_notes/${voiceNoteId}/topic/${topicId}/auto_add_es_matches?threshold=${threshold}`,
      null,
    );
  }

  getSuggestedVoiceStatements(reportId: number): Observable<SuggestedStatementResponse> {
    return this.http.get<SuggestedStatementResponse>(`/api/voice_notes/report/${reportId}/auto_add_es_matches`);
  }
}
