<!-- https://getbootstrap.com/docs/5.2/components/tooltips/#disabled-elements -->
<div [ngbTooltip]="disabled ? 'Patient record has no medicare number' : ''">
  <a
    rrTooltip="VIEW_DB4_FORM"
    class="btn btn-primary w-100"
    [class.disabled]="disabled"
    [href]="reportId | db4Link"
    target="_blank"
  >
    DB4 Form
  </a>
</div>
