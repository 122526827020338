import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

import { fromAttributeOption } from '../attribute-option/attribute-option.selector';

const adapter = createEntityAdapter<RR.DefaultAttribute>();

const selectFeature = (state: AppState) => state.default_attribute;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoadedInStatementSet = (templateId: number, statementSetId: number) =>
  createSelector(
    selectFeature,
    ({ statementSetStateLoadingState: loadingState }) => loadingState[templateId]?.[statementSetId] === 'loaded',
  );

const selectLoadingOrLoadedInStatementSet = (templateId: number, statementSetId: number) =>
  createSelector(
    selectFeature,
    ({ statementSetStateLoadingState: loadingState }) => !!loadingState[templateId]?.[statementSetId],
  );

/**
 * Convert the DefaultAttribute values to a string for lookup
 *
 * When we are searching for template default attributes, we want to do so
 * using the templateId, the textObjectId and the regionId, which are not
 * standard keys of the template default attributes. This function provides a
 * way to consistently convert these values to a string providing a simple
 * method for performing a dictionary lookup.
 *
 * This function is only intended for use within this module, all external use
 * should be through one of the selectors.
 */
export function attributeKeyString(templateId: number, textObjectId: number, regionId: number | null): string {
  // String([1, 2, null]) is '1,2,'
  return String([templateId, textObjectId, regionId]);
}

/**
 * Generate the mapping used for lookup of default attributes
 *
 * This generates the mapping from the keys generated by the
 * `attributeKeyString` function, to the DefaultAttributes.
 */
const selectChoiceMap = createSelector(selectAll, (attribute: RR.DefaultAttribute[]) => {
  const attributeMap: Record<string, RR.DefaultAttribute> = {};
  attribute.map((a) => (attributeMap[attributeKeyString(a.template_id, a.text_object_id, a.region_id)] = a));
  return attributeMap;
});

/**
 * Select an individual DefaultAttribute from template information.
 *
 * This is a function for selecting the DefaultAttributes based on
 * where they are used.
 *
 * @param templateId: The template to which the DefaultAttributes
 * apply
 * @param textObjectId: The text object we want to default choice for
 * @param regionId: The region in which the text object lies.
 */
const selectByChoice = (templateId: number, textObjectId: number, regionId: number | null) =>
  createSelector(
    selectChoiceMap,
    (attributeMap: Record<string, RR.DefaultAttribute | undefined>) =>
      attributeMap[attributeKeyString(templateId, textObjectId, regionId)],
  );

/**
 * Select the AttributeOption based on the location in the template
 *
 * The Attribute option includes the text that will be used, so this retrieves
 * the option that will be used from the template default attribute.
 *
 * @param templateId: The template to which the DefaultAttributes
 * apply
 * @param textObjectId: The text object we want to default choice for
 * @param regionId: The region in which the text object lies.
 */
const selectOptionByChoice = (templateId: number, textObjectId: number, regionId: number | null) =>
  createSelector(
    selectByChoice(templateId, textObjectId, regionId),
    fromAttributeOption.selectEntities,
    (defaultAttribute: RR.DefaultAttribute | undefined, attributeOptions: Dictionary<RR.AttributeOption>) =>
      // It is possible that the defaultAttribute is not set (is null), so use
      // the optional chaining to prevent trying to access the default_option_id of
      // the null type.
      defaultAttribute?.default_option_id ? attributeOptions[defaultAttribute.default_option_id] : undefined,
  );

export const fromDefaultAttribute = {
  selectLoadedInStatementSet,
  selectLoadingOrLoadedInStatementSet,
  selectIds,
  selectEntities,
  selectAll,
  selectByChoice,
  selectChoiceMap,
  selectOptionByChoice,
};
