<ng-template #zeroFPImagesTmpl> (W) Images <span class="small fa fa-external-link-alt"></span> </ng-template>
<a
  [ngClass]="{
    'text-nowrap d-block': !sidebar,
    'btn btn-sm btn-light text-nowrap w-100 text-start': sidebar
  }"
  *ngIf="report.image_url != null"
  [href]="report.image_url"
  target="_blank"
  rrTooltip="OPEN_IMAGE"
>
  <div [rrNoContent]="zeroFPImagesTmpl"><ng-content></ng-content></div
></a>
