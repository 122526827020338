import { CommonModule } from '@angular/common';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { Subscription, finalize } from 'rxjs';

import { InvoiceFormComponent } from '../../components/invoice-form/invoice-form.component';

@Component({
  standalone: true,
  imports: [CommonModule, InvoiceFormComponent],
  selector: 'rr-create-invoice-modal',
  templateUrl: './create-invoice-modal.component.html',
  styleUrls: ['./create-invoice-modal.component.css'],
})
export class CreateInvoiceModalComponent implements OnDestroy {
  @ViewChild(InvoiceFormComponent) invoiceFormComponent: InvoiceFormComponent;
  reportId: number | undefined;
  bookingId: number | undefined;
  invoiceId: number | undefined;
  reInvoice: boolean | undefined;
  submitting = false;
  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private messageService: MessageService,
  ) {}

  static open(modal: NgbModal, reportId?: number, bookingId?: number, invoiceId?: number, reInvoice?: boolean) {
    const modalRef = modal.open(CreateInvoiceModalComponent, {
      size: 'lg',
    });
    const component: CreateInvoiceModalComponent = modalRef.componentInstance;
    component.invoiceId = invoiceId;
    component.reInvoice = reInvoice;
    component.reportId = reportId;
    component.bookingId = bookingId;

    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submit() {
    this.submitting = true;
    this.subscription.add(
      this.invoiceFormComponent.invoiceFormEditComponent
        .submit()
        .pipe(
          finalize(() => {
            this.submitting = false;
          }),
        )
        .subscribe({
          next: (success) => {
            if (success) {
              this.close();
            }
          },
          error: (err: unknown) => {
            this.messageService.httpErrorMessage(err);
          },
        }),
    );
  }

  close() {
    this.activeModal.dismiss();
  }
}
