import { NgForOf } from '@angular/common';
import { Directive, Input, NgIterable, inject } from '@angular/core';

/**
 * https://medium.com/ngconf/make-trackby-easy-to-use-a3dd5f1f733b
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngForTrackById]',
  standalone: true,
})
export class NgForTrackByIdDirective<T extends { id: string | number }> {
  @Input() ngForOf!: NgIterable<T>;

  private ngFor = inject(NgForOf<T>, { self: true });

  constructor() {
    this.ngFor.ngForTrackBy = (_index: number, item: T) => item.id;
  }
}
