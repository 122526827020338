import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { SectionActions } from './section.action';
import { SectionHttpService } from './section.service';

@Injectable()
export class SectionEffect {
  constructor(
    private store: Store<AppState>,
    private service: SectionHttpService,
  ) {}

  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((sections: RR.Section[]) => SectionActions.addMany({ sections })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
