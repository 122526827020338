import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DB4LinkPipe } from './db4-form-link-pipe';

@Component({
  standalone: true,
  imports: [CommonModule, DB4LinkPipe, NgbModule],
  selector: 'rr-db4-form',
  templateUrl: './db4-form.component.html',
  styleUrls: ['./db4-form.component.css'],
})
export class Db4FormComponent {
  @Input() reportId: number;
  @Input() disabled: boolean | undefined;
}
