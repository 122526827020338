import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { VoyagerUrlService } from 'app/core/services/voyager-url.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule],
  selector: 'rr-open-voyager-button',
  templateUrl: './open-voyager-button.component.html',
  styleUrls: ['./open-voyager-button.component.scss'],
})
export class OpenVoyagerButtonComponent implements OnInit, OnDestroy {
  @ViewChild('openButton', { static: false }) btnEl: ElementRef<HTMLAnchorElement>;

  @Input() report_id: number;
  @Input() btnClass = 'btn-info';
  @Input() expand = true;

  subscription: Subscription = new Subscription();
  voyagerLaunchURL: SafeUrl | undefined;

  constructor(
    private store: Store<AppState>,
    private voyagerUrlService: VoyagerUrlService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report_id))
        .pipe(filterDefined())
        .subscribe((report) => {
          if (report.accession_number !== null) {
            this.voyagerLaunchURL = this.voyagerUrlService.getVoyagerURL(report.accession_number);
          }
        }),
    );
  }

  triggerClickEvent() {
    this.btnEl.nativeElement.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
