import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ReferralEnquiryApiResponse {
  count: number;
  data: RR.ReferralEnquiry[];
  send_events: RR.SendEvent[];
}

export interface ReferralEnquirySearchBody {
  offset: number;
  limit: number;
  patient_name?: string;
  patient_mobile?: string;
  patient_medicare_number?: string;
  referrer_name?: string;
  processed?: boolean;
  all?: boolean;
}

export type SendReferrralEnquiryConfirmSMSResponse = {
  referral_enquiry: RR.ReferralEnquiry;
  send_event: RR.SendEvent;
};

@Injectable()
export class ReferralEnquiryHttpService {
  constructor(private http: HttpClient) {}

  search(body: ReferralEnquirySearchBody) {
    return this.http.post<ReferralEnquiryApiResponse>('/api/referral_enquiry/search', body);
  }

  lazy_search(body: ReferralEnquirySearchBody) {
    return this.http.post<ReferralEnquiryApiResponse>('/api/referral_enquiry/lazy_search', body);
  }

  /**
   * @param changes only `status` can be updated with the API at this time
   */
  update(enquiryId: number, changes: Partial<RR.ReferralEnquiry>) {
    return this.http.put<RR.ReferralEnquiry>(`/api/referral_enquiry/${enquiryId}`, changes);
  }

  get(enquiryId: number) {
    return this.http.get<RR.ReferralEnquiry>(`/api/referral_enquiry/${enquiryId}`);
  }

  sendReferralConfirmationSMS(
    enquiryId: number,
    sendTo: string,
    message: string,
  ): Observable<SendReferrralEnquiryConfirmSMSResponse> {
    return this.http.post<SendReferrralEnquiryConfirmSMSResponse>(
      `/api/referral_enquiry/${enquiryId}/send_confirm_sms`,
      {
        referral_enquiry_id: enquiryId,
        send_to: sendTo,
        message: message,
      },
    );
  }
}
