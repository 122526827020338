<input
  class="form-control fw-bold"
  [disabled]="disableInput"
  [class.ng-valid]="ngControl.valid"
  [class.ng-invalid]="ngControl.invalid"
  [class.ng-touched]="ngControl.touched"
  [class.ng-untouched]="!ngControl.touched"
  (input)="onChange()"
  (change)="onChange()"
  (blur)="onTouchedFn()"
  [(ngModel)]="data"
/>
