import { createActionGroup, props } from '@ngrx/store';
import { AddedTopicActions } from 'app/store/added-topics/added-topics.action';
import { createBatchAction } from 'app/store/batching.action';
import { TemplateBatchActions } from 'app/store/template/template/template.action';

import { ElementChoiceActions } from '../element-choice/element-choice.action';
import { FavouriteActions } from '../favourite/favourite.action';
import { JustificationActions } from '../justification/justification.action';
import { RegionChoiceActions } from '../region-choice/region-choice.action';
import { ReportActions } from '../report/report.action';
import { SectionChoiceActions } from '../section-choice/section-choice.action';
import { StatementChoiceActions } from '../statement-choice/statement-choice.action';
import { SubsectionChoiceActions } from '../subsection-choice/subsection-choice.action';
import { TextObjectChoiceActions } from '../text-object-choice/text-object-choice.action';

/**
 * Ngrx Actions for Topic
 *
 */
export const TopicActions = createActionGroup({
  source: 'Topic',
  events: {
    openTopic: props<{ topicId: number }>(),
    addOne: props<{ topic: RR.Topic }>(),
    addMany: props<{ topics: RR.Topic[] }>(),
    upsertOne: props<{ topic: RR.Topic }>(),
    upsertMany: props<{ topics: RR.Topic[] }>(),
    removeMany: props<{ topicIds: number[] }>(),
  },
});

export const TopicBatchActions = {
  findTopicSuccess: createBatchAction(
    '[Topic] Find Topic Success Batch',
    props<{
      actions: {
        findTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        findFavouriteSuccess?: ReturnType<typeof FavouriteActions.findSuccess>;
        findJustificationSuccess?: ReturnType<typeof JustificationActions.findManySuccess>;
        findSectionChoiceSuccess: ReturnType<typeof SectionChoiceActions.upsertMany>;
        findSubsectionChoiceSuccess: ReturnType<typeof SubsectionChoiceActions.upsertMany>;
        findRegionChoiceSuccess: ReturnType<typeof RegionChoiceActions.upsertMany>;
        findElementChoiceSuccess: ReturnType<typeof ElementChoiceActions.upsertMany>;
        findStatementChoiceSuccess: ReturnType<typeof StatementChoiceActions.upsertMany>;
        findTextObjectChoicesSuccess: ReturnType<typeof TextObjectChoiceActions.upsertMany>;
        findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
      };
    }>(),
  ),
  findMultipleTopicSuccess: createBatchAction(
    '[Topic] Find Multiple Topic Success Batch',
    props<{
      actions: {
        findTopicsSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findFavouriteSuccess: ReturnType<typeof FavouriteActions.findManySuccess>;
        findSectionChoiceSuccess: ReturnType<typeof SectionChoiceActions.upsertMany>;
        findSubsectionChoiceSuccess: ReturnType<typeof SubsectionChoiceActions.upsertMany>;
        findRegionChoiceSuccess: ReturnType<typeof RegionChoiceActions.upsertMany>;
        findElementChoiceSuccess: ReturnType<typeof ElementChoiceActions.upsertMany>;
        findStatementChoiceSuccess: ReturnType<typeof StatementChoiceActions.upsertMany>;
        findTextObjectChoicesSuccess: ReturnType<typeof TextObjectChoiceActions.upsertMany>;

        findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
      };
    }>(),
  ),
  findRefTopicSuccess: createBatchAction(
    '[Topic] Find Ref Topic Success',
    props<{
      topicId: number;
      actions: {
        findTopicsSuccess: ReturnType<typeof TopicActions.upsertMany>;
        findReportsSuccess: ReturnType<typeof ReportActions.upsertMany>;
        findFavouritesSuccess: ReturnType<typeof FavouriteActions.findManySuccess>;
      };
    }>(),
  ),
  createTopicSuccess: createBatchAction(
    '[Topic] Create Topic Success Batch',
    props<{
      actions: {
        createTopicSuccess: ReturnType<typeof TopicActions.addOne>;
        updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
      };
    }>(),
  ),
  updateTopicSuccess: createBatchAction(
    '[Topic] Update Topic Success Batch',
    props<{
      actions: {
        updateTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
      };
    }>(),
  ),
  findAddedTopicSuccess: createBatchAction(
    '[Topic] Find Added Topic Success',
    props<{
      actions: {
        updateTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        updateAddedTopicsSuccess: ReturnType<typeof AddedTopicActions.updateManySuccess>;
      };
    }>(),
  ),
  removeAllAddedTopicSuccess: createBatchAction(
    '[Topic] Remove All Added Topic Success',
    props<{
      actions: {
        updateTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        removeAllAddedTopicsSuccess: ReturnType<typeof AddedTopicActions.removeAllSuccess>;
      };
    }>(),
  ),
  removeOneAddedTopicSuccess: createBatchAction(
    '[Topic] Remove One Added Topic Success',
    props<{
      actions: {
        updateTopicSuccess: ReturnType<typeof TopicActions.upsertOne>;
        removeOneAddedTopicsSuccess: ReturnType<typeof AddedTopicActions.removeOneSuccess>;
      };
    }>(),
  ),
};
