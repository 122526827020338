import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.BillingItem>();
const selectFeature = (state: AppState) => state.billing_item;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectBillingItem = (id: number) => createSelector(selectEntities, (billingItems) => billingItems[id]);
const selectLoaded = createSelector(selectFeature, (billing_item) => billing_item.loaded);

export const fromBillingItem = {
  selectIds,
  selectEntities,
  selectAll,
  selectBillingItem,
  selectLoaded,
};
