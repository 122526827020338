import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { BookingCodeLocatorActions } from './booking-code-locator.action';

export type BookingCodeLocatorState = EntityState<RR.BookingCodeLocator>;

const adapter = createEntityAdapter<RR.BookingCodeLocator>();

const initialState = adapter.getInitialState();

export const bookingCodeLocatorReducer = createReducer(
  initialState,
  on(BookingCodeLocatorActions.findAllSuccess, (state: BookingCodeLocatorState, { bookingCodeLocators }) =>
    adapter.setAll(bookingCodeLocators, state),
  ),
  on(BookingCodeLocatorActions.createSuccess, (state: BookingCodeLocatorState, { bookingCodeLocator }) =>
    adapter.addOne(bookingCodeLocator, state),
  ),
  on(BookingCodeLocatorActions.updateSuccess, (state: BookingCodeLocatorState, { bookingCodeLocator }) =>
    adapter.upsertOne(bookingCodeLocator, state),
  ),
  on(BookingCodeLocatorActions.deleteSuccess, (state: BookingCodeLocatorState, { bookingCodeLocatorId }) =>
    adapter.removeOne(bookingCodeLocatorId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
