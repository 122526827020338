<div class="d-flex align-items-center" title="Report signature status">
  <span [ngClass]="isSigned ? 'bg-success' : 'bg-danger'" class="mb-1 badge"
    >Signed
    <ng-container *ngIf="isSigned; else notSigned">
      <span class="text-white"><i class="fas fa-check"></i></span>
    </ng-container>
    <ng-template #notSigned>
      <span class="text-white"><i class="fas fa-times"></i></span>
    </ng-template>
  </span>
</div>
