import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { StatementActions } from '../statement/statement.action';
import { TextObjectActions, TextObjectBatchActions } from './text-object.action';
import { TextObjectHttpService } from './text-object.service';

@Injectable()
export class TextObjectEffect {
  constructor(
    private store: Store<AppState>,
    private service: TextObjectHttpService,
  ) {}

  update(textObjectId: number, changes: Partial<RR.TextObject>) {
    return this.service.update(textObjectId, changes).pipe(
      map(({ statement, textObject }) =>
        TextObjectBatchActions.updateSuccess({
          actions: {
            statementUpdateSuccessAction: StatementActions.upsertOne({ statement }),
            textObjectUpdateSuccessAction: TextObjectActions.upsertOne({ textObject: textObject }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated replaced by findInStatementSet
   */
  findInTemplate(templateId: number) {
    return this.service.findInTemplate(templateId).pipe(
      map((textObjects: RR.TextObject[]) => TextObjectActions.upsertMany({ textObjects })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
