import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { AuditEventEffect, fromAuditEvent } from 'app/store/report/audit-event';
import { fromSendEvent } from 'app/store/report/send-event';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AuditComponent } from '../audit.component';

@Component({
  standalone: true,
  imports: [CommonModule, AuditComponent],
  selector: 'rr-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css'],
})
export class AuditListComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  auditEvents: (RR.AuditEvent | RR.SendEvent)[];

  subscription = new Subscription();

  constructor(
    private editorService: EditorService,
    private store: Store<AppState>,
    private auditEventEffect: AuditEventEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.topic$
        .pipe(
          distinctUntilChanged((a, b) => a.id === b.id),
          switchMap((topic) => this.auditEventEffect.findAll(topic.id)),
        )
        .subscribe(),
    );

    this.subscription.add(
      combineLatest([
        this.topic$,
        this.store.select(fromAuditEvent.selectAll),
        // TODO: sendEvents aren't loaded unless you open (R) Distribute Report
        this.store.select(fromSendEvent.selectSendEventsInReport(this.topic.report_id)),
      ]).subscribe(([topic, auditEvents, sendEvents]) => {
        this.auditEvents = auditEvents
          .filter((a) => a.topic_id === topic.id)
          // TODO(snipping): Remove from db so we don't need to filter out
          .filter((a) => a.type !== 'LAUNCH_SNIPPING');
        this.auditEvents = this.auditEvents.concat(sendEvents);
      }),
    );
  }

  toggleAudit() {
    this.editorService.toggleRightPaneViewMode('AUDIT');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
