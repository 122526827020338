import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ReferrerMergeConflictService {
  constructor(private http: HttpClient) {}

  findConflicts(leftReferrerId: number, rightReferrerId: number) {
    return this.http.get<RR.EntityMergeConflict<RR.Referrer>[]>('/api/referrer/conflicts', {
      params: new HttpParams().set('left_id', leftReferrerId).set('right_id', rightReferrerId),
    });
  }

  merge(data: RR.EntityMergePayload<RR.Referrer>) {
    return this.http.put<RR.Referrer>('/api/referrer/merge', data);
  }
}
