import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AppState } from 'app/store/app.state';
import { TopicEffect, fromCurrentTopic } from 'app/store/report/topic';
import { Observable, Subscription, of } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'rr-editor-warning-modal',
  templateUrl: './editor-warning-modal.component.html',
  styleUrls: ['./editor-warning-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, AutoFocusDirective],
})
export class EditorWarningModalComponent implements OnDestroy {
  @BindObservable() @Input() editing: string[];
  editing$: Observable<string[]>;
  name$: Observable<string>;
  @Input() modal_type: 'REPORT_BLOCK_UNBLOCK' | 'REPORT_EDITED';

  subscription = new Subscription();
  refreshing = false;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private topicEffect: TopicEffect,
  ) {
    this.name$ = this.editing$.pipe(map((editing) => editing.join(', ')));
  }

  back() {
    this.activeModal.close(true);
  }

  refresh() {
    this.refreshing = true;
    this.subscription.add(
      this.store
        .select(fromCurrentTopic.selectTopicId)
        .pipe(
          take(1),
          switchMap((topicId) => {
            if (topicId) {
              return this.topicEffect.find(topicId);
            }
            return of(null);
          }),
          finalize(() => {
            this.refreshing = false;
          }),
          tap(() => this.activeModal.dismiss()),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
