import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { TitleOptionActions } from '../title-option/title-option.action';

/**
 * Ngrx Actions for TitleSet
 *
 */
export const TitleSetActions = {
  findSuccess: createAction('[TitleSet] Find Success', props<{ titleSet: RR.TitleSet }>()),

  findInTemplateSuccess: createAction('[TitleSet] Find In Template Success', props<{ titleSets: RR.TitleSet[] }>()),

  updateSuccess: createAction('[TitleSet] Update Success', props<{ titleSet: RR.TitleSet }>()),
};

export const TitleSetBatchActions = {
  findSuccessBatchAction: createBatchAction(
    '[TitleSet] Find Success Batch',
    props<{
      actions: {
        findTitleSetSuccess: ReturnType<typeof TitleSetActions.findSuccess>;
        titleOptionFindInTitleSetSuccess: ReturnType<typeof TitleOptionActions.findInTitleSetSuccess>;
      };
    }>(),
  ),

  // Using when adding title option in title set (Update title set with new title options added)
  updateSuccessBatchAction: createBatchAction(
    '[TitleSet] Update Success Batch',
    props<{
      actions: {
        updateTitleSetSuccess: ReturnType<typeof TitleSetActions.updateSuccess>;
        titleOptionFindInTitleSetSuccess: ReturnType<typeof TitleOptionActions.findInTitleSetSuccess>;
      };
    }>(),
  ),
};
