import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { AddOtherImagingButtonComponent } from 'app/modules/editor/add-other-imaging-button/add-other-imaging-button.component';
import { AddOtherImagingComponent } from 'app/modules/editor/add-other-imaging/add-other-imaging.component';
import { PriorStudyLinksComponent } from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.component';
import { RefTopicsComponent } from 'app/modules/editor/prefill-button/ref-topics/ref-topics.component';
import {
  TutorialModalComponent,
  TutorialType,
} from 'app/modules/tutorial/modals/tutorial-modal/tutorial-modal.component';
import { AccessEventComponent } from 'app/modules/worklist/components/access-event/access-event.component';
import { PatientDOBInfoComponent } from 'app/shared/components/patient-dob-info/patient-dob-info.component';
import { ReportDateComponent } from 'app/shared/components/report-date/report-date.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromBooking } from 'app/store/booking';
import { fromPatient } from 'app/store/patient';
import { fromCurrentTopic, TopicEffect } from 'app/store/report/topic';
import { fromSite } from 'app/store/site';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AccessEventListComponent } from '../../../modules/worklist/components/access-event-list/access-event-list.component';
import { ReportAccessionNumberComponent } from '../report-accession-number/report-accession-number.component';

@Component({
  standalone: true,
  selector: 'rr-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
  imports: [
    CommonModule,
    PriorStudyLinksComponent,
    forwardRef(() => AddOtherImagingComponent),
    AddOtherImagingButtonComponent,
    RefTopicsComponent,
    PatientDOBInfoComponent,
    SharedModule,
    ReportDateComponent,
    AccessEventComponent,
    AccessEventListComponent,
    ReportAccessionNumberComponent,
  ],
})
@LifecycleLogger
export class PatientDetailComponent implements OnInit, OnDestroy {
  @ViewChild('popover') popover: NgbPopover;
  @ViewChild('popoverHeading') popoverHeading: NgbPopover;
  @Input() @BindObservable() report: RR.Report;
  report$: Observable<RR.Report>;
  patient: RR.Patient;

  // Use topic_id instead of topic to avoid the subscribed event called each time topic change
  @Input() @BindObservable() topic_id: number;
  @Input() parent: 'EDITOR' | 'WORKLIST';
  topic_id$: Observable<number>;
  subscription = new Subscription();

  topic$: Observable<RR.Topic>;
  booking$: Observable<RR.Booking | undefined>;
  site$: Observable<RR.Site | undefined>;

  constructor(
    private editorService: EditorService,
    private store: Store<AppState>,
    private topicEffect: TopicEffect,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());

    this.subscription.add(
      this.report$
        .pipe(
          take(1),
          switchMap((report) => {
            if (report.patient_id) {
              return this.store.select(fromPatient.selectPatient(report.patient_id));
            } else {
              return EMPTY;
            }
          }),
        )
        .subscribe((patient) => {
          if (patient) this.patient = patient;
        }),
    );

    if (this.topic_id) {
      this.subscription.add(this.topicEffect.refTopics(this.topic_id).subscribe());
    }

    this.booking$ = this.store.select(fromBooking.selectBooking(this.report.booking_id));

    this.site$ = this.booking$.pipe(
      filterDefined(),
      switchMap((booking) => this.store.select(fromSite.selectSite(booking.site_id))),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openTutorialModal(type: TutorialType) {
    TutorialModalComponent.open(this.modalService, type);
  }

  toggleAudit() {
    this.activeModal.close();
    this.editorService.toggleRightPaneViewMode('AUDIT');
  }
}
