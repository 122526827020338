<ng-template #defaultTemplate
  ><span class="fas fa-th-list"></span>
  <span class="text-nowrap ms-1 d-none d-xl-block" rrTooltip="NAVBAR_BTN_WORKLIST">Worklist</span></ng-template
>
<a
  class="nav-link d-flex align-items-center px-2"
  *ngIf="routerHistoryService.lastWorklistUrl$ | async as url"
  [rrDynamicLink]="url"
  data-cy="worklist-link"
>
  <div [rrNoContent]="defaultTemplate"><ng-content></ng-content></div>
</a>
