<div class="modal-header">
  <h5 class="modal-title">Warning</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div *ngIf="modal_type === 'REPORT_BLOCK_UNBLOCK'">
    <p *ngIf="editing.length > 0">This report is being edited by {{ name$ | async }}.</p>
    <p *ngIf="editing.length === 0">The previous editor has closed the report.</p>
    <p>Do you want to edit it?</p>
    <div class="d-flex justify-content-center mt-2">
      <button role="button" class="btn btn-primary" rrAutoFocus (click)="refresh()" [disabled]="refreshing">
        Yes and reload report
      </button>
      <button role="button" class="btn btn-secondary ms-2" (click)="back()">No and go back</button>
    </div>
  </div>
</div>
