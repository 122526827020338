import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { FeedbackRecipientsComponent } from '../../components/feedback-recipients/feedback-recipients.component';
import { ReportTextDiffComponent } from '../../components/report-text-diff/report-text-diff.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReportTextDiffComponent, FeedbackRecipientsComponent],
  templateUrl: './self-check-feedback-modal.component.html',
  styleUrls: ['./self-check-feedback-modal.component.scss'],
})
export class SelfCheckFeedbackModalComponent {
  @Input() report_id: number;

  subscription = new Subscription();
  selectedUserId: number | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
  ) {}

  onSelectedUserChanged(userId: number) {
    this.selectedUserId = userId;
    this.cd.detectChanges();
  }

  static open(modalService: NgbModal, report_id: number) {
    const modalRef = modalService.open(SelfCheckFeedbackModalComponent, {
      size: 'xl',
      scrollable: true,
      centered: false,
      windowClass: 'self-check-feedback-modal',
    });
    modalRef.componentInstance.report_id = report_id;
    return modalRef;
  }
}
