import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { getSectionAttr, TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import { StatementEffect } from 'app/store/template/statement';
import {
  fromStatementSet,
  StatementSetCount,
  StatementSetEffect,
  StatementSetHttpService,
} from 'app/store/template/statement-set';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';

import { AutoFocusDirective } from '../../../shared/directives/auto-focus.directive';
import { ElementUsageModalComponent } from '../element/element-usage-modal.component';
import { StatementComponent } from '../statement/statement.component';

@Component({
  templateUrl: './library-modal.component.html',
  styleUrls: ['./library-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, AutoFocusDirective, StatementComponent],
})
export class LibraryModalComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('createElementButton') createElementButton: ElementRef;
  template_id: number; // input
  section: RR.TemplateSection = 'history'; // input
  topic: RR.Topic; // input
  statement_sets: StatementSetCount[] = [];
  selectedSetId: number;
  statements$: Observable<RR.Statement[]>;
  statementSet$: Observable<RR.StatementSet>;
  loading = true;
  loadingMore = false;
  offset = 0;
  count = 0;
  limit = 20;

  subscription: Subscription = new Subscription();
  searchText = '';
  top = '0px';
  hiddenCount = 0;
  showAll = true;
  allStatementSets: StatementSetCount[] = [];

  sectionFormControl: FormControl<RR.TemplateSection>;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private templateService: TemplateService,
    private statementEffect: StatementEffect,
    private statementSetEffect: StatementSetEffect,
    private statementSetHttpService: StatementSetHttpService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
  ) {}

  ngOnInit() {
    this.sectionFormControl = new FormControl(this.section, { nonNullable: true });
    this.search();
    this.subscription.add(
      fromEvent(this.searchInput.nativeElement, 'input')
        .pipe(debounceTime(250))
        .subscribe(() => {
          this.search();
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selected() {
    this.subscription.add(this.statementSetEffect.findById(this.selectedSetId).subscribe());
    this.subscription.add(this.statementEffect.findInStatementSet(this.selectedSetId).subscribe());

    // @ts-expect-error strictNullChecks
    this.statementSet$ = this.store.select(fromStatementSet.selectStatementSet(this.selectedSetId));
    this.statements$ = this.store.select(fromStatementSet.selectStatements(this.selectedSetId));
  }

  search(more = false) {
    if (more) {
      this.loadingMore = true;
      this.offset += this.limit;
    } else {
      this.offset = 0;
    }
    const text = this.searchText;
    const searchParams = new HttpParams()
      .set('search', text)
      .set('section', this.sectionFormControl.value)
      .set('limit', String(this.limit))
      .set('offset', String(this.offset));
    this.subscription.add(
      this.statementSetHttpService
        .counts(searchParams)
        .pipe(
          finalize(() => {
            this.loading = false;
            this.loadingMore = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe((r) => {
          this.count = r.data.length;
          if (more) {
            this.allStatementSets = [...this.allStatementSets, ...r.data];
          } else {
            this.allStatementSets = r.data;
          }

          this.showHiddenElements();
          this.cd.markForCheck();
        }),
    );
  }

  statementSetUsed(statement_set_id: number) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (statement_set_id == null) {
      return of(false);
    } else {
      return this.templateService.getElements(this.template_id).pipe(
        map((elements) => {
          const element = elements.find((e) => e.element.statement_set_id === statement_set_id);
          // TODO(template-structure): this condition looks backwards?
          return element === undefined;
        }),
      );
    }
  }

  focusCreate(event: Event) {
    // `HTMLElement` type is broken, property `offsetParent` should be of type `HTMLElement | null`
    const target = event.target as HTMLElement;
    this.top = target.offsetTop + (<HTMLElement>target.offsetParent).offsetTop + 'px';
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (this.createElementButton) {
        this.createElementButton.nativeElement.focus();
      }
    });
  }

  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const bottomDistance = target.scrollHeight - (target.scrollTop + target.clientHeight);
    if (bottomDistance <= 0 && !this.loadingMore && !this.loading) {
      this.search(true);
    }
  }

  showElementUsageModal(statement_set_count: StatementSetCount) {
    ElementUsageModalComponent.open(this.modal, { statement_set_count });
  }

  countUsed(statement_set: StatementSetCount) {
    const usedSections: { [K in RR.TemplateSection]?: number } = {};
    if (statement_set.count_history > 0) usedSections.history = statement_set.count_history;
    if (statement_set.count_findings > 0) usedSections.findings = statement_set.count_findings;
    if (statement_set.count_technique > 0) usedSections.technique = statement_set.count_technique;
    if (statement_set.count_comment > 0) usedSections.comment = statement_set.count_comment;
    if (statement_set.count_impression_recommendations > 0)
      usedSections.impression_recommendations = statement_set.count_impression_recommendations;
    return usedSections;
  }

  isUnusedStatementSet(statement_set: StatementSetCount) {
    return (
      statement_set.count_history === 0 &&
      statement_set.count_findings === 0 &&
      statement_set.count_technique === 0 &&
      statement_set.count_comment === 0 &&
      statement_set.count_impression_recommendations === 0
    );
  }
  showHiddenElements() {
    if (this.showAll) {
      this.statement_sets = [...this.allStatementSets];
    } else {
      this.statement_sets = this.allStatementSets.filter((s) => s.count_statement >= 2);
    }
    this.hiddenCount = this.allStatementSets.length - this.statement_sets.length;
  }

  getSectionTitle(name: string) {
    return getSectionAttr(name, 'abbrTitle');
  }
}
