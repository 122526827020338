import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor';
import { RegistrationFormActions } from './registration-form.action';

export type RegistrationFormState = EntityState<RR.RegistrationForm>;

const adapter = createEntityAdapter<RR.RegistrationForm>();
const initialState = adapter.getInitialState();

export const RegistrationFormReducer = createReducer(
  initialState,
  on(RegistrationFormActions.findManySuccess, (state: RegistrationFormState, { forms }) =>
    adapter.upsertMany(forms, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
