import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for SoftwarePreference
 */
export const SoftwarePreferenceActions = {
  createSuccess: createAction('[SoftwarePreference] Create Success', props<{ software_pref: RR.SoftwarePreference }>()),
  deleteSuccess: createAction('[SoftwarePreference] Delete Success', props<{ software_pref_id: number }>()),
  updateSuccess: createAction('[SoftwarePreference] Update Success', props<{ software_pref: RR.SoftwarePreference }>()),
  getAllSuccess: createAction(
    '[SoftwarePreference] Get All Success',
    props<{ software_prefs: RR.SoftwarePreference[] }>(),
  ),
};
