import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromCurrentTopic, fromTopic } from '../../report/topic/topic.selector';
import { fromElement } from '../element/element.selector';
import { fromSection } from '../section';

const adapter = createEntityAdapter<RR.Template>();
const selectFeature = (state: AppState) => state.template;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectCurrentTemplateId = createSelector(fromCurrentTopic.selectTopic, (topic?: RR.Topic) => topic?.template_id);

const selectCurrentTemplate = createSelector(
  selectEntities,
  selectCurrentTemplateId,
  (templates: Dictionary<RR.Template>, templateId?: number) => (templateId && templates[templateId]) || undefined,
);

const selectTemplate = (templateId: number) =>
  createSelector(selectEntities, (templates: Dictionary<RR.Template>) => templates[templateId]);

const selectActiveTemplates = createSelector(selectAll, (templates) => templates.filter((t) => !t.deleted));
/**
 * Given a topicId, select the template used by that topic
 *
 * This first finds the templateId from the topic, then selects that template
 * from the store.
 *
 */
const selectTemplateFromTopic = (topicId: number) =>
  createSelector(
    fromTopic.selectTopic(topicId),
    fromTemplate.selectEntities,
    (topic: RR.Topic | undefined, templates: Dictionary<RR.Template>) =>
      (topic?.template_id && templates[topic.template_id]) || undefined,
  );

const selectSections = (templateId: number) =>
  createSelector(
    fromSection.selectEntities,
    selectTemplate(templateId),
    (sections: Dictionary<RR.Section>, template?: RR.Template) =>
      template?.section_ids.map((sectionId) => sections[sectionId]).filter((s): s is RR.Section => !!s) || [],
  );

const selectCurrentSections = createSelector(
  selectCurrentTemplate,
  fromSection.selectEntities,
  (template: RR.Template | undefined, sections: Dictionary<RR.Section>) =>
    template?.section_ids.map((sectionId) => sections[sectionId]).filter((s): s is RR.Section => !!s) || [],
);

const selectSection = (templateId: number, section: RR.TemplateSection) =>
  createSelector(selectSections(templateId), (sections: RR.Section[]) => sections.find((s) => s.type === section));

const selectCurrentNotepads = createSelector(
  fromElement.selectEntities,
  selectCurrentTemplate,
  (elements: Dictionary<RR.Element>, template?: RR.Template) => {
    return template?.notepads.map((notepadId) => elements[notepadId]).filter((n): n is RR.Element => !!n) || [];
  },
);

const selectLoaded = (templateId: number) => createSelector(selectFeature, (state) => state.loaded[templateId]);

export const fromTemplate = {
  selectEntities,
  selectAll,
  selectActiveTemplates,
  selectTemplate,
  selectSection,
  selectSections,
  selectTemplateFromTopic,
  selectLoaded,
};

/**
 * Select values based on the currently selected topic.
 *
 * This uses the router store to determine the topic that is currently
 * selected and performs the selection accordingly.
 */
export const fromCurrentTemplate = {
  selectTemplate: selectCurrentTemplate,
  // Re-Export the router selector here since it makes sense
  selectTemplateId: selectCurrentTemplateId,
  selectSections: selectCurrentSections,
  selectNotepads: selectCurrentNotepads,
};
