import { DecodedValueMap, EncodedValueMap, QueryParamConfigMap } from './types';

/**
 * Convert the values in query to strings via the encode functions configured
 * in paramConfigMap
 *
 * @param paramConfigMap Map from query name to { encode, decode } config
 * @param query Query updates mapping param name to decoded value
 */
export function decodeQueryParams<QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
  encodedQuery: Partial<EncodedValueMap<QPCMap>>,
): Partial<DecodedValueMap<QPCMap>> {
  const decodedQuery: Partial<DecodedValueMap<QPCMap>> = {};

  // iterate over all keys in the config (#30)
  const paramNames = Object.keys(paramConfigMap);

  // warn if any non configured keys that are in the URL are also included
  for (const encodedKey of Object.keys(encodedQuery)) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (paramConfigMap[encodedKey] === undefined) {
      console.warn(`Skipping ${encodedKey} during decoding. It is not defined in paramConfigMap`);
    }
  }

  for (const paramName of paramNames) {
    // Don't decode fields that are undefined. Otherwise, the form default will be replaced with undefined.
    if (paramName in encodedQuery) {
      const encodedValue = encodedQuery[paramName];
      decodedQuery[paramName as keyof QPCMap] = paramConfigMap[paramName].decode(encodedValue as string | string[]);
    }
  }

  return decodedQuery;
}
