import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';
import {
  ChoiceCreationObject,
  RegionChoiceCopyPayload,
  StatementChoiceCreatePayload,
  UpdateChoicePayload,
} from './statement-choice.type';

@Injectable()
export class StatementChoiceHttpService {
  constructor(private http: HttpClient) {}

  create(statementChoice: StatementChoiceCreatePayload) {
    return this.http.post<ChangeTrackerResponse>('/api/statement_choice', statementChoice);
  }

  update(statementChoiceId: number, changes: UpdateChoicePayload) {
    return this.http.put<ChangeTrackerResponse>(`/api/statement_choice/${statementChoiceId}`, changes);
  }

  delete(statementChoiceId: number) {
    return this.http.delete<ChangeTrackerResponse>(`/api/statement_choice/${statementChoiceId}`);
  }

  deleteMany(statementChoiceIds: number[]) {
    const requestOptions = { body: { ids: statementChoiceIds } };
    return this.http.request<ChangeTrackerResponse>('delete', '/api/statement_choice', requestOptions);
  }

  copyRegionChoices(payload: RegionChoiceCopyPayload) {
    return this.http.post<ChangeTrackerResponse>('/api/copy_region_choices', payload);
  }

  createManyChoices(topicId: number, statements: ChoiceCreationObject[]) {
    return this.http.post<ChangeTrackerResponse>('/api/create_choices', { topic_id: topicId, statements });
  }

  createPredictionChoices(topicId: number, type: 'TITLE' | 'PREDICTION') {
    return this.http.post<ChangeTrackerResponse>('/api/create_title_default_choices', { topic_id: topicId, type });
  }
}
