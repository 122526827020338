import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { AppState } from 'app/store';
import { fromStatement } from 'app/store/template/statement';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { TextObjectRenderComponent } from '../text-object-render/text-object-render.component';

@Component({
  selector: 'rr-statement-text-render',
  templateUrl: './statement-text-render.component.html',
  standalone: true,
  imports: [TextObjectRenderComponent, CommonModule],
})
@LifecycleLogger
export class StatementTextRenderComponent implements OnInit {
  @Input() @BindObservable() statement_id: number;
  statement_id$: Observable<number>;
  // TODO: Get the current template_id from the store
  @Input() template_id: number;
  // TODO: Get the current region from the store
  /**
   * TODO: This is set to undefined for Notepads because Default Attributes are not defined per Notepad-Region. This is nuanced because in TextObjectRenderComponent we could actually still render the [[space_name]] placeholders with the Region.
   */
  @Input() region: RR.Region | undefined;
  @Input() proposed?: RR.ProposedStatement['proposed'];

  statement$: Observable<RR.Statement | undefined>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.statement$ = this.statement_id$.pipe(
      switchMap((statementId) => this.store.select(fromStatement.selectStatement(statementId))),
    );
  }
}
