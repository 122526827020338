<div class="modal-header">
  <div>
    <h5 class="modal-title">Registration Query (Q)</h5>
    <rr-report-headline [reportId]="reportId"></rr-report-headline>
  </div>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <rr-registration
    *ngIf="report$ | async as report"
    [reportId]="reportId"
    [bookingId]="report.booking_id"
    [referrerId]="report.referrer_id"
    [patientId]="report.patient_id"
  ></rr-registration>
</div>
