import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AppState } from 'app/store';
import { KeywordAbbreviationEffect } from 'app/store/keyword-abbr';
import { fromKeywordAbbreviation } from 'app/store/keyword-abbr/keyword-abbr.selector';
import { Subscription, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AutoFocusDirective],
  selector: 'rr-edit-keyword-abbreviation-modal',
  templateUrl: './edit-keyword-abbreviation-modal.component.html',
  styleUrls: ['./edit-keyword-abbreviation-modal.component.css'],
})
export class EditKeywordAbbreviationModalComponent implements OnInit, OnDestroy {
  keyword_abbr_id: number | undefined;
  subscription = new Subscription();
  form: FormGroup;

  get abbreviation() {
    return this.form.controls.abbreviation;
  }
  get keyword() {
    return this.form.controls.keyword;
  }

  constructor(
    private store: Store<AppState>,
    private keywordAbbrEffect: KeywordAbbreviationEffect,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      abbreviation: new FormControl('', { validators: Validators.required, nonNullable: true }),
      keyword: new FormControl('', { validators: Validators.required, nonNullable: true }),
    });

    if (this.keyword_abbr_id) {
      this.subscription.add(
        this.store
          .select(fromKeywordAbbreviation.selectKeywordAbbreviation(this.keyword_abbr_id))
          .pipe(take(1))
          .subscribe((kwAbbr) => {
            if (kwAbbr) {
              this.form.patchValue({
                abbreviation: kwAbbr.abbreviation,
                keyword: kwAbbr.keyword,
              });
            }
          }),
      );
    }
  }

  submit() {
    if (this.keyword_abbr_id) {
      this.subscription.add(
        this.keywordAbbrEffect
          .update(this.keyword_abbr_id, {
            abbreviation: this.abbreviation.value,
            keyword: this.keyword.value,
          })
          .pipe(take(1))
          .subscribe(() => {
            this.activeModal.dismiss();
          }),
      );
    } else {
      this.subscription.add(
        this.keywordAbbrEffect
          .create({
            abbreviation: this.abbreviation.value,
            keyword: this.keyword.value,
          })
          .pipe(take(1))
          .subscribe(() => {
            this.activeModal.dismiss();
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, keyword_abbr_id?: number) {
    const modalRef = modalService.open(EditKeywordAbbreviationModalComponent, {
      size: 'md',
    });
    modalRef.componentInstance.keyword_abbr_id = keyword_abbr_id;
    return modalRef;
  }
}
