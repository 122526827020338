import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { ReportAccessEventActions } from './access-event.action';

export type ReportAccessEventState = EntityState<RR.ReportAccessEvent>;

const adapter = createEntityAdapter<RR.ReportAccessEvent>();

const initialState = adapter.getInitialState();

export const reportAccessEventReducer = createReducer(
  initialState,
  on(ReportAccessEventActions.findManySuccess, (state: ReportAccessEventState, { accessEvents }) =>
    adapter.upsertMany(accessEvents, state),
  ),
  on(ReportAccessEventActions.createSuccess, (state: ReportAccessEventState, { accessEvent }) =>
    adapter.addOne(accessEvent, state),
  ),
  on(ReportAccessEventActions.updateSuccess, (state: ReportAccessEventState, { accessEvent }) =>
    adapter.upsertOne(accessEvent, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
