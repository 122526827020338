import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ProofreadChoiceError>();
const selectFeature = (state: AppState) => state.choiceErrors;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectChoiceError = (id: number) =>
  createSelector(selectEntities, (errors: Dictionary<RR.ProofreadChoiceError>) => errors[id]);

export const fromChoiceError = {
  selectIds,
  selectEntities,
  selectAll,
  selectChoiceError,
};
