import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, getPatientAgeAndSex } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { DocumentAttachmentButtonComponent } from 'app/modules/registration/components/document-attachment-button/document-attachment-button.component';
import { PatientQuestionnairesModalComponent } from 'app/modules/registration/modals/patient-questionnaires-modal/patient-questionnaires-modal.component';
import { RegistrationModalComponent } from 'app/modules/registration/modals/registration-modal/registration-modal.component';
import { CheckReportModalComponent } from 'app/modules/report/components/preview/check-report-modal/check-report-modal.component';
import { SignatureModalComponent } from 'app/modules/report/components/preview/signature-modal/signature-modal.component';
import { ReportStatusBadgeComponent } from 'app/modules/worklist/components/report-status-label/report-status-badge.component';
import { OpenVoyagerButtonComponent } from 'app/shared/components/open-voyager-button/open-voyager-button.component';
import { PatientDetailComponent } from 'app/shared/components/patient-detail/patient-detail.component';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { ReportDateComponent } from 'app/shared/components/report-date/report-date.component';
import { ReportHeadlineComponent } from 'app/shared/components/report-headline/report-headline.component';
import { ReportNotesButtonComponent } from 'app/shared/components/report-notes-button/report-notes-button.component';
import { ZeroFPFormsComponent } from 'app/shared/components/zero-fp-forms/zero-fp-forms.component';
import { ZeroFPImagesComponent } from 'app/shared/components/zero-fp-images/zero-fp-images.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { fromReferrer } from 'app/store/referrer';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { fromTodo } from 'app/store/report/todo';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { PrefillService } from '../../../modules/editor/prefill/prefill.service';
import { SuperUrgentButtonComponent } from '../due-date-modal/super-urgent-button/super-urgent-button.component';
import { UrgentButtonComponent } from '../due-date-modal/urgent-button/urgent-button.component';
import { MetadataModalComponent } from './metadata-modal/metadata-modal.component';

@Component({
  standalone: true,
  selector: 'rr-demographics-modal',
  templateUrl: './demographics-modal.component.html',
  styleUrls: ['./demographics-modal.component.scss'],
  imports: [
    CommonModule,
    ReportHeadlineComponent,
    PatientDetailComponent,
    NgbModule,
    DocumentAttachmentButtonComponent,
    ReportNotesButtonComponent,
    OpenVoyagerButtonComponent,
    ReportDateComponent,
    ReferrerNameComponent,
    SharedModule, // for PermissionDirective
    ZeroFPImagesComponent,
    ZeroFPFormsComponent,
    UrgentButtonComponent,
    SuperUrgentButtonComponent,
    ReportStatusBadgeComponent,
  ],
})
export class DemographicsModalComponent implements OnInit, OnDestroy {
  @Input() parent: 'EDITOR' | 'WORKLIST';
  topic: RR.Topic | undefined;
  report: RR.Report;
  report$: Observable<RR.Report | undefined>;
  subscription = new Subscription();
  audit: Observable<boolean>;
  patient$: Observable<RR.Patient>;
  referrer$: Observable<RR.Referrer>;
  todos: RR.Todo[] = [];
  urgentNotes: RR.UrgentNotes[] = [];
  editing_users: string[] = [];
  activeTopics: RR.Topic[];
  isUrgent: boolean;
  // From editor: editing user, from worklist: logged in user
  currentUser$: Observable<RR.User>;

  constructor(
    public activeModal: NgbActiveModal,
    private editorService: EditorService,
    private modal: NgbModal,
    private reportService: ReportService,
    private prefillService: PrefillService,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private selectorService: SelectorService,
    private reportEffect: ReportEffect,
  ) {
    this.audit = this.store.select((s) => s.editor.rightPaneViewMode === 'AUDIT');
  }

  ngOnInit() {
    // Reselect the report from the store. Because updated params can't be passed to the modal.
    this.report$ = this.store.select(fromReport.selectReport(this.report.id));
    this.subscription.add(
      this.report$.pipe(filterDefined()).subscribe((report) => {
        this.report = report;
      }),
    );
    // @ts-expect-error strictNullChecks
    this.patient$ = this.store.select(fromPatient.selectPatient(this.report.patient_id));
    // @ts-expect-error strictNullChecks
    this.referrer$ = this.store.select(fromReferrer.selectReferrer(this.report.referrer_id));

    this.subscription.add(
      this.store.select(fromTodo.selectInReport(this.report.id)).subscribe((todos) => {
        this.todos = todos;
        this.cd.markForCheck();
      }),
    );

    if (this.parent === 'EDITOR') {
      // @ts-expect-error strictNullChecks
      this.currentUser$ = this.reportService.selectKioskUser();
    } else {
      // @ts-expect-error strictNullChecks
      this.currentUser$ = this.selectorService.selectLoadedCurrentUser();
    }

    this.subscription.add(
      this.reportService
        .getActiveTopics(this.report.id)
        .pipe(take(1))
        .subscribe((topics) => {
          this.activeTopics = topics;
        }),
    );

    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.report.id))
        .pipe(
          filter((r) => !!r),
          // @ts-expect-error strictNullChecks
          map((r) => r.urgent),
        )
        .subscribe((u) => {
          // @ts-expect-error strictNullChecks
          this.isUrgent = u;
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  patientShortAgeAndSex(patient: RR.Patient) {
    return getPatientAgeAndSex(patient, true);
  }

  toggleSidebar() {
    this.activeModal.dismiss();
    return this.editorService.toggleSidebar(true);
  }

  openMetadataModal() {
    MetadataModalComponent.open(this.modal, this.report.id, this.parent);
  }

  openSignature() {
    if (this.topic) {
      SignatureModalComponent.open({
        modal: this.modal,
        report_id: this.report.id,
        topic_id: this.topic.id,
        signature_role: 'DOCTOR',
      });
    }
  }

  openPatientQuestionnaireModal() {
    PatientQuestionnairesModalComponent.open(this.modal, this.report.id);
  }

  openRegistrationModal() {
    RegistrationModalComponent.open(this.modal, this.report.id);
  }

  getEditingUsers() {
    return this.editing_users.join(', ');
  }

  isDoctorEditing() {
    return this.reportService.isDoctorEditing();
  }

  getClipboardSentence() {
    return this.editorService.getClipboardSentence();
  }

  hasPriorRelatedTopic() {
    if (this.topic) {
      return this.reportService.getLatestRelatedTopic(this.topic.id, this.report.id).pipe(map((topic) => !!topic));
    }
    return of(false);
  }

  importPriorRelatedTopicToPrefill() {
    if (!this.topic) return;

    this.reportService
      .getLatestRelatedTopic(this.topic.id, this.report.id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((latestTopic) => {
        if (!latestTopic || !this.topic) return;
        this.prefillService.importTopicToPrefill(this.topic.id, latestTopic.id.toString(), true);
      });
    this.activeModal.dismiss();
  }

  openCheckReport() {
    this.activeModal.dismiss();
    if (this.topic) {
      CheckReportModalComponent.open(this.modal, this.report.id, this.topic.id);
    }
  }

  static open(modalService: NgbModal, report: RR.Report, topic: RR.Topic, parent: 'EDITOR' | 'WORKLIST') {
    const modalRef = modalService.open(DemographicsModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true,
    });
    const self: DemographicsModalComponent = modalRef.componentInstance;
    self.topic = topic;
    self.report = report;
    self.parent = parent;
    return modalRef;
  }

  getImageUrls() {
    this.subscription.add(this.reportEffect.getImageUrls(this.report.id).subscribe());
  }
}
