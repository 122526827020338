import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EditorActions } from 'app/store/editor/editor.action';

import { DocumentActions } from './document.action';

export interface DocumentState extends EntityState<RR.Document> {
  loaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.Document>({
  selectId: (document: RR.Document) => document.filename,
});

const initialState = adapter.getInitialState({
  loaded: {},
});

export const documentReducer = createReducer(
  initialState,
  on(DocumentActions.upsertOne, (state: DocumentState, { document }) => adapter.upsertOne(document, state)),
  on(DocumentActions.upsertMany, (state: DocumentState, { documents }) => adapter.upsertMany(documents, state)),
  on(DocumentActions.addOne, (state: DocumentState, { document }) => adapter.addOne(document, state)),
  on(DocumentActions.removeOne, (state: DocumentState, { documentId }) => adapter.removeOne(documentId, state)),
  on(DocumentActions.findInBookingSuccess, (state: DocumentState, { booking_id, documents }) => ({
    ...adapter.upsertMany(documents, state),
    loaded: { ...state.loaded, [booking_id]: true },
  })),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
