import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rr-title-warning-modal',
  templateUrl: './title-warning-modal.component.html',
  styleUrls: ['./title-warning-modal.component.css'],
  standalone: true,
})
export class TitleWarningModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(TitleWarningModalComponent, {
      scrollable: true,
      size: 'lg',
      centered: true,
    });
    return modalRef;
  }

  close() {
    this.activeModal.close();
  }
}
