<div
  *ngIf="eligibility$ | async as eligibility"
  container="body"
  placement="top"
  [ngbTooltip]="eligibility.tooltip"
  class="h-100"
>
  <i
    class="fas fa-2x"
    [class.fa-check]="eligibility.isEligible"
    [class.fa-times]="!eligibility.isEligible && !eligibility.types"
    [style.color]="eligibility.isEligible ? 'green' : 'red'"
  ></i>
  <span *ngIf="eligibility.types">{{ eligibility.types }}</span>
</div>
