<a
  class="btn btn-sm btn-outline-secondary me-1 d-xxl-flex align-items-center position-relative"
  ngbTooltip="Radreport Feedback Issues"
  routerLinkActive="active"
  [routerLink]="['/issues', -1]"
  *ngIf="currentUser"
>
  <i class="fas fa-tasks"></i> <span class="d-none d-xxl-block ms-1"> RR Issues </span>
</a>

<a
  *ngIf="{ state: checkUserClockedOn() | async } as userClockedOn"
  class="btn btn-sm me-1 d-xxl-flex align-items-center"
  [class.btn-outline-success]="userClockedOn.state === true"
  [class.btn-outline-danger]="userClockedOn.state === false"
  [class.btn-outline-secondary]="userClockedOn.state == null"
  target="_blank"
  [href]="rrConfig.TIMESHEETS_KIOSK_URL"
  ngbTooltip="RadPay"
>
  <span class="fas fa-clock"></span> <span class="d-none d-xxl-block ms-1"> RadPay </span>
</a>

<button
  role="button"
  class="btn btn-sm btn-outline-secondary me-1 d-xxl-flex align-items-center position-relative"
  (click)="openFeedbackModal()"
  ngbTooltip="Feedback"
  *ngIf="currentUser"
>
  <i class="fas fa-envelope-open-text"></i>
  <span class="d-none d-xxl-block ms-1">Feedback</span>
  <div>
    <span class="badge rounded-pill bg-danger report-feedback-count" *ngIf="currentUser && reportFeedbackCount > 0">
      R:{{ reportFeedbackCount }}
    </span>
    <span class="badge rounded-pill bg-danger unread-feedback" *ngIf="currentUser && noFeedbacks > 0"
      >U:{{ noFeedbacks }}</span
    >
  </div>
</button>

<rr-documentation-button-dropdown *ngIf="currentUser"></rr-documentation-button-dropdown>

<a
  id="navbar-login-button"
  *ngIf="!currentUser"
  role="button"
  class="btn btn-sm btn-secondary me-1 d-flex align-items-center"
  routerLinkActive="active"
  routerLink="/login"
>
  Sign In
</a>

<a
  id="navbar-register-button"
  *ngIf="!currentUser"
  role="button"
  class="btn btn-sm btn-secondary me-1 d-flex align-items-center"
  routerLinkActive="active"
  routerLink="/register"
>
  Register
</a>
