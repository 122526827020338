import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { TopicEffect, fromTopic } from 'app/store/report/topic';
import { EMPTY, Observable, Subscription, switchMap, take } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-topic-title-option-text',
  templateUrl: './topic-title-option-text.component.html',
  styleUrls: ['./topic-title-option-text.component.css'],
})
export class TopicTitleOptionTextComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  topic$: Observable<RR.Topic | undefined>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private topicEffect: TopicEffect,
  ) {}

  ngOnInit(): void {
    const loaded$ = this.store.select(fromTopic.selectLoaded(this.topic_id));

    this.subscription.add(
      loaded$
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.topicEffect.find(this.topic_id);
            } else {
              return EMPTY;
            }
          }),
        )
        .subscribe(),
    );
    this.topic$ = this.store.select(fromTopic.selectTopic(this.topic_id));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
