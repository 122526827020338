import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { tap, map } from 'rxjs/operators';

import { ExactMatchTopicActions } from './exact-match-topic.action';
import { ExactMatchTopicPayload, ExactMatchTopicHttpService } from './exact-match-topic.service';

@Injectable()
export class ExactMatchTopicEffect {
  constructor(
    private store: Store<AppState>,
    private service: ExactMatchTopicHttpService,
  ) {}

  findAll(data: ExactMatchTopicPayload) {
    return this.service.findAll(data).pipe(
      map((exact_matches_topic: RR.ExactMatchTopic[]) =>
        ExactMatchTopicActions.findAllSuccess({ exact_matches_topic }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
