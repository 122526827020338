<div ngbTooltip="template note" class="d-flex align-items-center w-100" (click)="onTagSearchTermClicked()">
  <span
    #contenteditableElement
    class=""
    [attr.contenteditable]="editMode"
    (keydown.enter)="$event.preventDefault(); updateTagSearchTerm(tagSearchTerm.id)"
  >
    {{ tagSearchTerm.text }}
  </span>
  <div class="d-flex flex-nowrap ms-auto">
    <button
      role="button"
      class="btn btn-sm btn-outline-secondary py-0 me-1 text-nowrap"
      rrTooltip="INSERT_INTO_SOABS"
      (click)="insertIntoSoabs(tagSearchTerm.text)"
    >
      <i class="fas fa-search-plus"></i>
      <!-- SOABS: Search or Add Blue Statmenet -->
      SOABS
    </button>
    <button
      *ngIf="!editMode"
      role="button"
      class="btn btn-sm py-0 px-1"
      (click)="$event.stopPropagation(); editTagSearchTerm()"
    >
      <span class="fa fa-edit text-secondary"></span>
    </button>
    <button
      *ngIf="editMode"
      role="button"
      class="btn btn-sm py-0 px-1"
      (click)="$event.stopPropagation(); updateTagSearchTerm(tagSearchTerm.id)"
    >
      <span class="fa fa-check text-success"></span>
    </button>
    <button
      role="button"
      class="btn btn-sm py-0 px-1"
      (click)="$event.stopPropagation(); deleteTagSearchTerm(tagSearchTerm.id)"
    >
      <span class="fa fa-trash-alt text-danger"></span>
    </button>
  </div>
</div>
