<div
  ngbDropdown
  placement="bottom-end"
  #dropdown="ngbDropdown"
  [hidden]="(currentUser$ | async) == null"
  display="dynamic"
>
  <a class="nav-link px-2" ngbDropdownToggle role="button" id="sessionDropdown">
    <span class="ms-1 d-none d-xxl-inline me-1">{{ (currentUser$ | async)?.name }}</span>
    <span *ngIf="kioskUser$ | async as kioskUser" [ngbTooltip]="kioskUser.name">({{ kioskUser.initials }})</span>
  </a>
  <div ngbDropdownMenu>
    <button role="button" class="dropdown-item" (click)="switchUser()" rrTooltip="SWITCH_USER">
      <i class="fa fa-people-arrows"></i> Switch User
    </button>
    <button role="button" class="dropdown-item" (click)="logout()">
      <span class="fa fa-sign-out-alt"></span> Sign Out
    </button>
    <button
      role="button"
      class="dropdown-item"
      (click)="toggleKiosk()"
      permission
      [permission-only]="['report_approve']"
    >
      <input type="checkbox" [checked]="(kioskState$ | async) === false" /> I'm Here All Day
    </button>
    <a
      class="dropdown-item"
      routerLinkActive="active"
      routerLink="/admin/templates"
      title="Admin area"
      permission
      [permission-only]="['user_manage']"
    >
      <span class="fa fa-cog"></span> Admin area
    </a>

    <a
      class="dropdown-item"
      routerLinkActive="active"
      routerLink="/user-setting"
      title="User Settings"
      *ngIf="currentUser$ | async"
    >
      <span class="fa fa-user"></span> User Settings
    </a>
    <a class="dropdown-item" routerLinkActive="active" routerLink="/entities" title="Data Management">
      <span class="fa fa-list"></span> Data Management
    </a>
  </div>
</div>
