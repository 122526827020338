<div class="modal-header">
  <h4 class="modal-title">{{ header }}</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<form class="bootstrap-validate" [formGroup]="form" (ngSubmit)="accept()">
  <div class="modal-body d-flex flex-column align-items-center">
    <label class="form-label">
      <strong>{{ message }}</strong>
    </label>
    <div class="w-50">
      <div class="mb-3" *ngIf="textInput">
        <input
          ngbAutoFocus
          type="text"
          formControlName="messageInputField"
          class="form-control mb-2"
          placeholder="{{ placeholder }}"
          (keydown.enter)="$event.preventDefault()"
        />
      </div>
      <div *ngIf="password" class="mb-3">
        <label class="form-label"><strong>Confirm Password</strong></label>
        <input ngbAutoFocus type="password" formControlName="password" class="form-control" placeholder="Password" />
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex">
    <button [disabled]="form.invalid" type="submit" class="btn btn-success" ngbAutoFocus>
      {{ btnConfirmText || 'OK' }}
    </button>
    <button *ngIf="secondBtnOptionText" type="button" class="btn btn-primary ms-2" (click)="acceptSecondOption()">
      {{ secondBtnOptionText }}
    </button>
    <button
      type="button"
      *ngIf="btnConfirmText"
      class="btn btn-outline-secondary ms-auto"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
  </div>
</form>
