import { Injectable } from '@angular/core';
import { MessageService } from 'app/core/services/message.service';

import {
  StatementParameterConditionHttpService,
  StatementParameterCondition,
} from './statement-parameter-condition.service';

@Injectable()
export class StatementParameterConditionEffect {
  constructor(
    private service: StatementParameterConditionHttpService,
    private message: MessageService,
  ) {}

  update(id: number, changes: StatementParameterCondition) {
    return this.service.update(changes, id).pipe(this.message.handleHttpErrorPipe);
  }

  create(groupId: number, condition: Partial<StatementParameterCondition>) {
    return this.service.create(groupId, condition).pipe(this.message.handleHttpErrorPipe);
  }

  delete(id: number) {
    return this.service.delete(id).pipe(this.message.handleHttpErrorPipe);
  }
}
