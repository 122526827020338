import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { SuggestedStatement, VoiceNoteEffect } from 'app/store/report/voice-note';
import { Subscription, finalize, startWith, switchMap, tap } from 'rxjs';

import { RecommendedStatementComponent } from '../statement-recommendation/recommended-statement/recommended-statement.component';

@Component({
  selector: 'rr-voice-note-suggested-statements',
  templateUrl: './voice-note-suggested-statements.component.html',
  styleUrls: ['./voice-note-suggested-statements.component.css'],
  standalone: true,
  imports: [RecommendedStatementComponent, CommonModule],
})
export class VoiceNoteSugesstedStatementComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() topic: RR.Topic;

  subscription = new Subscription();
  suggestedStatements: SuggestedStatement[] | undefined;
  loading = true;

  constructor(
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private voiceNoteEffect: VoiceNoteEffect,
    private editorService: EditorService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.editorService.autoSuggestedStatementLoadTrigger$
        .pipe(
          startWith(null),
          tap(() => {
            this.loading = true;
          }),
          switchMap(() => this.voiceNoteEffect.getSuggestedVoiceStatements(this.reportId)),
          finalize(() => {
            this.loading = false;
            this.cd.detectChanges();
          }),
        )
        .subscribe({
          next: (response) => {
            if (response.actions.auto_added_statements.length > 0) {
              this.suggestedStatements = response.actions.auto_added_statements;
            } else {
              this.suggestedStatements = undefined;
            }
            this.cd.detectChanges();
          },
          error: (err: unknown) => {
            this.suggestedStatements = undefined;
            this.messageService.httpErrorMessage(err);
            this.cd.detectChanges();
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
