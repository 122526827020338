import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { TemplateService } from 'app/core/services/template.service';
import { AdminService } from 'app/modules/admin/services/admin.service';
import { PriorStudyLinksService } from 'app/modules/editor/add-other-imaging/prior-study-links/prior-study-links.service.component';
import { NotesModalComponent } from 'app/shared/modals/notes-modal/notes-modal.component';
import { AppState } from 'app/store';
import { fromTodo } from 'app/store/report/todo';
import { fromTopic, TopicEffect } from 'app/store/report/topic';
import { fromTemplate } from 'app/store/template/template';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../../shared/directives/tooltip.directive';
import { AddOtherImagingComponent } from '../../../../editor/add-other-imaging/add-other-imaging.component';
import { SignatureModalComponent } from '../signature-modal/signature-modal.component';

declare type errors = {
  choice: RR.StatementChoice;
  section: RR.TemplateSection;
  statement_ids: Set<number>;
  reason: string;
}[];

@Component({
  selector: 'rr-check-topic',
  templateUrl: './check-topic.component.html',
  styleUrls: ['./check-topic.component.css'],
  standalone: true,
  imports: [CommonModule, TooltipDirective, forwardRef(() => AddOtherImagingComponent)],
})
export class CheckTopicComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  @Input() report_id: number;
  @Output() closeCheckReport: EventEmitter<any> = new EventEmitter();

  // Choices with unfilled number
  unfilled$: Observable<RR.StatementChoice[]>;
  // Choices with number out of expected range
  invalidNumberChoices$: Observable<RR.StatementChoice[]>;
  hasNecessaryKeyFindings$: Observable<boolean>;
  comments$: Observable<RR.StatementChoice[]>;
  impressionRecommendations$: Observable<RR.StatementChoice[]>;
  techniques$: Observable<RR.StatementChoice[]>;
  topicHasTitle$: Observable<boolean>;
  flaggedChoices$: Observable<RR.StatementChoice[]>;
  shouldAddOtherImaging$: Observable<boolean>;
  unresolvedTodos$: Observable<RR.Todo[]>;
  currentUserHasSigned$: Observable<boolean>;
  multipleTopics$: Observable<boolean>;
  numberOfKeyFindings$: Observable<number>;

  signatureText$: Observable<string>;
  templateModality$: Observable<string>; // For checking technician signature
  technicianSignatureText$: Observable<string>;
  subscription = new Subscription();
  allErrors: errors;
  errorComments: errors = [];
  errorImpression: errors = [];
  errorHistory: errors = [];
  errorTechnique: errors = [];
  errorFindings: errors = [];
  report$: Observable<RR.Report>;
  report: RR.Report;
  reportSendStatus: RR.ReportSendStatus;

  noError = false;
  checkNoError$: Observable<
    [
      RR.StatementChoice[],
      RR.StatementChoice[],
      RR.StatementChoice[],
      boolean,
      RR.StatementChoice[],
      RR.StatementChoice[],
      boolean,
      RR.Todo[],
      RR.StatementChoice[],
      string,
      boolean,
      string,
      boolean,
      number,
    ]
  >;
  topicLoaded$: Observable<boolean>;

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private editorService: EditorService,
    private templateService: TemplateService,
    private adminService: AdminService,
    private modal: NgbModal,
    private store: Store<AppState>,
    private selectorService: SelectorService,
    private priorStudyLinksService: PriorStudyLinksService,
    private topicEffect: TopicEffect,
  ) {}

  ngOnInit() {
    this.noError = false;
    this.report$ = this.reportService.getReport(this.report_id);

    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );

    // @ts-expect-error strictNullChecks
    this.unfilled$ = this.reportService.getChoicesWithUnfilledNumbers(this.topic.id);
    // @ts-expect-error strictNullChecks
    this.invalidNumberChoices$ = this.reportService.getChoicesWithOutOfRangeNumbers(this.topic.id);
    this.hasNecessaryKeyFindings$ = this.reportService.hasNecessaryKeyFindings(this.topic.id);
    // @ts-expect-error strictNullChecks
    this.comments$ = this.reportService.getCommentChoices(this.topic.id);
    // @ts-expect-error strictNullChecks
    this.impressionRecommendations$ = this.reportService.getImpressionAndRecommendationChoices(this.topic.id);
    // @ts-expect-error strictNullChecks
    this.techniques$ = this.reportService.getTechniqueChoices(this.topic.id);
    this.topicHasTitle$ = this.reportService.topicHasTitle(this.topic.id);
    this.unresolvedTodos$ = this.store
      .select(fromTodo.selectInReport(this.report_id))
      .pipe(map((todos) => todos.filter((t) => !t.resolved && !t.statement_choice_id)));
    // @ts-expect-error strictNullChecks
    this.flaggedChoices$ = this.reportService.getFlaggedChoices(this.report_id, this.topic.id);
    this.shouldAddOtherImaging$ = this.priorStudyLinksService.shouldAddOtherImaging(this.report, this.topic);
    this.numberOfKeyFindings$ = this.reportService
      .getKeyFindings(this.topic.id)
      .pipe(map((kfs: RR.Ctx[]) => kfs.length));

    this.currentUserHasSigned$ = this.selectorService
      .selectLoadedCurrentUser()
      .pipe(switchMap((u) => (u ? this.reportService.userHasSigned(this.topic.id, u) : of(false))));
    // @ts-expect-error strictNullChecks
    this.signatureText$ = this.topic$.pipe(map((t) => t.signature_text));
    this.templateModality$ = this.topic$.pipe(
      switchMap((t) => this.templateService.getTemplateModality(t.template_id)),
    );
    // @ts-expect-error strictNullChecks
    this.technicianSignatureText$ = this.topic$.pipe(map((t) => t.technician_signature_text));
    this.subscription.add(
      this.reportService.getReportSendStatus(this.report_id).subscribe((status) => {
        this.reportSendStatus = status;
      }),
    );

    const loaded$ = this.topic$.pipe(switchMap((topic) => this.store.select(fromTopic.selectLoaded(topic.id))));

    this.subscription.add(
      loaded$
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.topicEffect.find(this.topic.id);
            } else {
              return of(null);
            }
          }),
        )
        .subscribe(),
    );

    this.topicLoaded$ = loaded$.pipe(skipWhile((loaded) => !loaded));

    this.checkNoError$ = this.topicLoaded$.pipe(
      switchMap(() =>
        combineLatest([
          this.comments$,
          this.impressionRecommendations$,
          this.techniques$,
          this.hasNecessaryKeyFindings$,
          this.unfilled$,
          this.invalidNumberChoices$,
          this.topicHasTitle$,
          this.unresolvedTodos$,
          this.flaggedChoices$,
          this.signatureText$,
          this.currentUserHasSigned$,
          this.technicianSignatureText$,
          this.shouldAddOtherImaging$,
          this.numberOfKeyFindings$,
        ]),
      ),
    );

    this.subscription.add(
      this.topicLoaded$
        .pipe(
          switchMap(() =>
            combineLatest([this.reportService.getChoiceErrors, this.reportService.getChoicesWithCtx(this.topic.id)]),
          ),
        )
        .subscribe(([proofreadings, ctxs]) => {
          const error_ids = new Set(proofreadings.map((e) => e.id));
          // @ts-expect-error strictNullChecks
          const error_ctxs = ctxs.filter((c) => error_ids.has(c.statement_choice.id));
          const section_errors = (section: RR.TemplateSection) => this.allErrors.filter((o) => o.section === section);
          // @ts-expect-error strictNullChecks
          this.allErrors = error_ctxs.map((ctx) => ({
            choice: ctx.statement_choice,
            // @ts-expect-error strictNullChecks
            section: ctx.section_choice.type,
            // @ts-expect-error strictNullChecks
            statement_ids: proofreadings.find((e) => e.id === ctx.statement_choice.id).statement_ids,
            // @ts-expect-error strictNullChecks
            reason: proofreadings.find((e) => e.id === ctx.statement_choice.id).reason,
          }));
          this.errorComments = section_errors('comment');
          this.errorImpression = section_errors('impression_recommendations');
          this.errorHistory = section_errors('history');
          this.errorFindings = section_errors('findings');
          this.errorTechnique = section_errors('technique');
        }),
    );

    this.subscription.add(
      this.checkNoError$.subscribe(
        ([
          comments,
          impressionRecommendations,
          techniques,
          hasNecessaryKeyFindings,
          unfilled,
          invalidNumberChoices,
          topicHasTitle,
          unresolvedTodos,
          flaggedChoices,
          signatureText,
          currentUserHasSigned,
          technicianSignatureText,
          shouldAddOtherImaging,
          numberOfKeyFindings,
        ]) => {
          if (
            !!comments.length &&
            !!impressionRecommendations.length &&
            !!techniques.length &&
            hasNecessaryKeyFindings &&
            !unfilled.length &&
            !invalidNumberChoices.length &&
            topicHasTitle &&
            !unresolvedTodos.length &&
            !flaggedChoices.length &&
            !!signatureText &&
            currentUserHasSigned &&
            !!technicianSignatureText &&
            !shouldAddOtherImaging &&
            numberOfKeyFindings <= 5
          ) {
            this.noError = true;
          }
        },
      ),
    );
  }

  ngAfterViewInit() {
    // If report has only 1 topic, and this topic has no error, auto dismiss check report modal
    this.checkNoError$
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe(
        ([
          comments,
          impressionRecommendations,
          techniques,
          hasNecessaryKeyFindings,
          unfilled,
          invalidNumberChoices,
          topicHasTitle,
          unresolvedTodos,
          flaggedChoices,
          signatureText,
          currentUserHasSigned,
          technicianSignatureText,
          shouldAddOtherImaging,
          numberOfKeyFindings,
        ]) => {
          if (
            !!comments.length &&
            !!impressionRecommendations.length &&
            !!techniques.length &&
            hasNecessaryKeyFindings &&
            !unfilled.length &&
            !invalidNumberChoices.length &&
            topicHasTitle &&
            !unresolvedTodos.length &&
            !flaggedChoices.length &&
            !!signatureText &&
            currentUserHasSigned &&
            !!technicianSignatureText &&
            !shouldAddOtherImaging &&
            numberOfKeyFindings <= 5 &&
            !this.allErrors.length
          ) {
            this.closeCheckReport.emit(null);
          }
        },
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  jumpToSection(section: RR.TemplateSection) {
    this.subscription.add(
      this.store.select(fromTemplate.selectSection(this.topic.template_id, section)).subscribe((_section) => {
        if (!_section) {
          throw Error(`Section "${section}" not found in template`);
        }
        this.activeModal.dismiss();
        this.editorService.jumpToSection(_section, this.topic.id);
      }),
    );
  }

  getChoiceText(choice_id: number) {
    return this.reportService.getChoiceText(choice_id);
  }

  publishChoiceFocus(choice: RR.StatementChoice) {
    this.editorService.setTopicOpen(this.topic.id);
    this.editorService.publishFocusChoice(choice.id);
    this.activeModal.dismiss();
  }

  jumpToTitle() {
    this.editorService.jumpToTitle(this.topic.id);
    this.activeModal.dismiss();
  }

  getTemplateName() {
    return this.topic$.pipe(switchMap((t) => this.templateService.getTemplateName(t.template_id)));
  }

  getPositionOfTopic() {
    return this.reportService.getPositionOfTopic(this.report_id, this.topic.id);
  }

  dismissError(statement_id_set: Set<number>) {
    if (statement_id_set.size !== 2) {
      throw Error(`Expected argument \`statement_id_set\` to have \`size\` 2, received set \`${statement_id_set}\``);
    }
    const [statement1_id, statement2_id] = statement_id_set;
    this.subscription.add(this.editorService.markAsNotError(statement1_id, statement2_id).subscribe());
  }

  openSignature(role: 'DOCTOR' | 'TECHNICIAN') {
    SignatureModalComponent.open({
      modal: this.modal,
      report_id: this.report_id,
      topic_id: this.topic.id,
      signature_role: role,
    });
  }

  isDoctorEditing() {
    return this.reportService.isDoctorEditing();
  }

  checkTechnicianSignaturePermission() {
    return this.reportService
      .selectKioskUser()
      .pipe(
        map(
          (user) =>
            user &&
            this.adminService.includesRole(
              [
                'doctor',
                'junior_radiographer',
                'junior_sonographer',
                'sonographer',
                'radiographer',
                'imaging_technician',
              ],
              user.company_roles,
            ),
        ),
      );
  }

  openNotesModal() {
    NotesModalComponent.open({ modalService: this.modal, report: this.report });
  }
}
