import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { mapChangeTrackerToSuccessActions } from '../report/report-change-tracker.action';
import { SubsectionChoiceHttpService } from './subsection-choice.service';

@Injectable()
export class SubsectionChoiceEffect {
  constructor(
    private store: Store<AppState>,
    private service: SubsectionChoiceHttpService,
  ) {}

  create(subsectionId: number, topicId: number) {
    return this.service.create(subsectionId, topicId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(sectionChoiceId: number, changes: Partial<RR.SubsectionChoice>) {
    return this.service.update(sectionChoiceId, changes).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(sectionChoiceId: number) {
    return this.service.delete(sectionChoiceId).pipe(
      map((response) => {
        return mapChangeTrackerToSuccessActions(response);
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
