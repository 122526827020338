<div class="modal-header">
  <h5 class="modal-title">Accession Number</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <!--Confirm changes-->
  <div *ngIf="confirm">
    <span>Enter your password to confirm changes:</span>
    <rr-modal-auth
      (onAuthenticated)="onAuthenticated()"
      (onCancel)="onCancel()"
      [hasCancelButton]="true"
    ></rr-modal-auth>
  </div>

  <!--Change accession number-->
  <div permission [permission-only]="['report_approve']" [permission-user]="(currentUser$ | async) || undefined">
    <div *ngIf="!confirm">
      <span>Accession number:</span>
      <form [formGroup]="form">
        <div class="d-flex">
          <input type="text" class="form-control" formControlName="accession_number" rrAutoFocus />
          <button
            [disabled]="!form.value.accession_number || form.value.accession_number === report.accession_number"
            class="btn ms-2"
            [ngClass]="otherReport ? 'btn-danger' : 'btn-success'"
            (click)="saveButtonClicked()"
          >
            Save
          </button>
        </div>
      </form>
      <div *ngIf="otherReport">
        <span class="text-danger"
          >Report with accession no: {{ form.value.accession_number }} already exists. <br />Pressing 'Save' will delete
          this report and move all topics to the existing report.
        </span>
      </div>

      <div *ngIf="otherReport">
        <strong>Existing report:</strong>
        <rr-report-headline [reportId]="otherReport.id"></rr-report-headline>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="ms-auto btn btn-secondary" role="button" (click)="activeModal.dismiss()">Close</button>
</div>
