import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface StatementChoiceUsageAnalytics {
  id: number;
  accession_number: number;
  patient_age: number;
  patient_sex: string;
  report_id: number;
  topic_id: number;
  image_url: string;
  similarity_percent: number;
}

export interface StatementUsages {
  reports: StatementChoiceUsageAnalytics[];
  statement: RR.Statement;
}

export interface StatementChoiceUsageAnalyticsResponse {
  statement_usages: StatementUsages[];
  default_attributes: RR.DefaultAttribute[];
}

@Injectable()
export class StatementChoiceUsageAnalyticsHttpService {
  constructor(private http: HttpClient) {}

  findAllFromChoice(reportId: number, statementChoiceId: number | null, statementId: number | null) {
    return this.http.post<StatementChoiceUsageAnalyticsResponse>(`/api/ml/statement_usage`, {
      statement_id: statementId,
      report_id: reportId,
      statement_choice_id: statementChoiceId,
    });
  }
}
