import { fromAttributeOption } from 'app/store/template/attribute-option';
import { fromAttributeSet } from 'app/store/template/attribute-set';
import { fromDefaultAttribute } from 'app/store/template/default-attribute';
import { fromTextObject } from 'app/store/template/text-object';
import { useState, useEffect } from 'react';
import { Subscription } from 'rxjs';
import { RenderElementProps } from 'slate-react';

import { useSelectedStyle } from './useSelectedStyle';
import { useStore } from './useStore';

export function AttributeTextObjectComponent(
  props: RenderElementProps & { templateId: number; onClick: React.DOMAttributes<HTMLSpanElement>['onClick'] },
) {
  const { attributes, children, element } = props;
  if (element.type !== 'rr-attribute') {
    throw new Error('AttributeTextObjectComponent can only render rr-attribute elements');
  }

  const [textObject, setTextObject] = useState<RR.TextObject | undefined>(undefined);
  const [defaultAttribute, setDefaultAttribute] = useState<RR.AttributeOption | undefined>(undefined);
  const [attributeSet, setAttributeSet] = useState<RR.AttributeSet | undefined>(undefined);
  const store = useStore();
  const style = useSelectedStyle();

  // Get the TextObject from the store
  useEffect(() => {
    const subscription = new Subscription();
    if (element.text_object_id) {
      // Newly added attributes don't have a `text_object_id` yet.
      subscription.add(
        store.select(fromTextObject.selectTextObject(element.text_object_id)).subscribe((textObject) => {
          setTextObject(textObject);
        }),
      );
    }
    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [element.text_object_id]);

  // Get the DefaultAttribute for the TextObject from the store
  useEffect(() => {
    const subscription = new Subscription();
    if (element.text_object_id) {
      // It has an `id`, so get the DefaultAttribute from the store.
      subscription.add(
        store
          .select(fromDefaultAttribute.selectOptionByChoice(props.templateId, element.text_object_id, null))
          .subscribe((defaultAttribute) => {
            setDefaultAttribute(defaultAttribute);
          }),
      );
    } else if (element.default_option_id) {
      // Before the TextObject is created, show a preview of what the DefaultAttribute would look like.
      subscription.add(
        store
          .select(fromAttributeOption.selectAttributeOption(element.default_option_id))
          .subscribe((defaultAttribute) => {
            setDefaultAttribute(defaultAttribute);
          }),
      );
    }
    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [props.templateId]);

  // Get the AttributeSet for the TextObject from the store
  useEffect(() => {
    const subscription = new Subscription();
    subscription.add(
      store.select(fromAttributeSet.selectAttributeSet(element.attribute_set_id)).subscribe((attributeSet) => {
        setAttributeSet(attributeSet);
      }),
    );
    return function cleanup() {
      subscription.unsubscribe();
    };
  });

  return (
    <span className="rr-statement-attribute-placeholder" style={style} onClick={props.onClick} {...attributes}>
      {!defaultAttribute && textObject && textObject.id}
      {defaultAttribute && defaultAttribute.text} -{' '}
      {attributeSet && <em title={`${attributeSet.id}`}>{attributeSet.name}</em>}
      {children}
    </span>
  );
}
