<form class="d-flex flex-column flex-grow-1" autocomplete="off" rrTooltip="CHOOSE_STUDY_TYPE">
  <h3>Choose a Study Type</h3>
  <input
    #searchInput
    type="text"
    class="form-control"
    placeholder="Search: XR, MR, Chest, Knee..."
    name="searchInput"
  />
  <div class="d-flex flex-column flex-grow-1 mt-1 pt-1 pb-1 align-items-start results">
    <div
      *ngFor="let modality of modalities; let last = last"
      class="rr-modality-group w-100 list-group mb-1 border-bottom"
      [class.border-bottom-0]="last"
    >
      <button
        *ngFor="let template of templateSearchResults[modality]"
        (click)="selectTemplate(template)"
        [disabled]="disabled"
        type="button"
        data-cy="template"
        class="list-group-item list-group-item-action text-start border-0 py-1"
        [title]="template._source.code"
      >
        {{ template._source.modality }} – {{ template._source.name }} – {{ (template._source.code || []).join(',') }}
      </button>
    </div>
  </div>
</form>
