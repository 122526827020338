<div class="container bg-light rounded p-2">
  <table class="table">
    <tbody *ngIf="referral$ | async as referral">
      <tr>
        <th>Patient Given Name</th>
        <td>{{ referral.given_name }}</td>
      </tr>
      <tr>
        <th>Patient Family Name</th>
        <td>{{ referral.family_name }}</td>
      </tr>
      <tr>
        <th>Sex</th>
        <td>{{ referral.sex }}</td>
      </tr>
      <tr>
        <th>Requested Scans</th>
        <td>{{ referral.requested_scans }}</td>
      </tr>
      <tr>
        <th>Date of Birth</th>
        <td>{{ referral.date_of_birth }}</td>
      </tr>
      <tr>
        <th>Medicare Number</th>
        <td>{{ referral.medicare_number }}</td>
      </tr>
      <tr>
        <th>Mobile Number</th>
        <td>{{ referral.mobile_number }}</td>
      </tr>
      <tr>
        <th>Patient Address</th>
        <td>
          {{ referral.patient_address }}, {{ referral.patient_suburb }} {{ referral.patient_state }}
          {{ referral.patient_postcode }}
        </td>
      </tr>
      <tr>
        <th>Radiology Centre Name</th>
        <td>{{ referral.radiology_centre_name }}</td>
      </tr>
      <tr>
        <th>Clinical Details</th>
        <td>{{ referral.clinical_details }}</td>
      </tr>
      <tr>
        <th>Referrer Title</th>
        <td>{{ referral.referrer_title }}</td>
      </tr>
      <tr>
        <th>Referrer Given Name</th>
        <td>{{ referral.referrer_given_name }}</td>
      </tr>
      <tr>
        <th>Referrer Family Name</th>
        <td>{{ referral.referrer_family_name }}</td>
      </tr>
      <tr>
        <th>Referrer Provider ID</th>
        <td>{{ referral.referrer_provider_id }}</td>
      </tr>
    </tbody>
  </table>
</div>
