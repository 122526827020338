import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';
import { DefaultAttributeActions } from 'app/store/template/default-attribute/default-attribute.action';

import { StatementChoiceUsageAnalyticsResponse } from './statement-choice-usage-analytics.service';

const findAllSuccess = createAction(
  '[StatementChoiceUsageAnalytics] Find All Success',
  props<{ response: StatementChoiceUsageAnalyticsResponse; statementChoiceId: number }>(),
);

export const StatementChoiceUsageAnalyticsActions = {
  findAllSuccess,
};

export const StatementChoiceUsageAnalyticsBatchActions = {
  findAllSuccess: createBatchAction(
    '[StatementChoiceUsageAnalytics] Find All Success Batch',
    props<{
      actions: {
        statementChoiceUsageAnalyticsFindAllSuccess: ReturnType<
          typeof StatementChoiceUsageAnalyticsActions.findAllSuccess
        >;
        defaultAttributeFindAllSuccess: ReturnType<typeof DefaultAttributeActions.findAllSuccess>;
      };
    }>(),
  ),
};
