import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { elementsAreEmpty, filterDefined, scrollPreviewToElement } from 'app/app.utils';
import { ChooseStatementData, ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store/app.state';
import {
  Breadcrumb,
  EditorActions,
  EditorState,
  ElementFilterType,
  RightPaneViewModeType,
  fromBreadcrumb,
  fromEditor,
} from 'app/store/editor';
import { ReportEffect } from 'app/store/report/report';
import { fromTextObjectChoice } from 'app/store/report/text-object-choice';
import { TodoEffect, fromTodo } from 'app/store/report/todo';
import { fromCurrentTopic } from 'app/store/report/topic';
import { fromRegion } from 'app/store/template/region';
import { fromSection } from 'app/store/template/section';
import { StatementCoincidenceEffect } from 'app/store/template/statement-coincidence';
import { fromStatementSet } from 'app/store/template/statement-set';
import { fromSubsection } from 'app/store/template/subsection/subsection.selector';
import { fromCurrentTemplate } from 'app/store/template/template';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject, Subscription, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap, throttleTime } from 'rxjs/operators';

import { HotkeysService } from './hotkeys.service';

/**
 * Highlight and Focus Events data types
 */

// The source where highlight event is published, some components might ignore the highlight event from some certain source
type HighlightSource = 'INDEX_SEARCH' | 'CHOICE_PREVIEW' | 'ELEMENT' | 'BREADCRUMB';

interface FocusItemBase {
  type: string;
  doneCallback: () => void;
  timeout: number;
}

export interface LoadElement extends FocusItemBase {
  type: 'LoadElement';
  elementId: number;
}

export interface FilterElement extends FocusItemBase {
  type: 'FilterElement';
  /**
   * The statement to not filter with LRA so that we can focus it later.
   */
  statementId?: number;
  elementId?: number;
  target?: FocusItemTarget;
}

export interface FocusStatement extends FocusItemBase {
  type: 'FocusStatement';
  statementId: number;
}

export interface FocusChoice extends FocusItemBase {
  type: 'FocusChoice';
  statementChoiceId: number;
}

export interface FocusTextObjectChoice extends FocusItemBase {
  type: 'FocusTextObjectChoice';
  textObjectChoiceId: number;
}

type FocusItemType = LoadElement | FilterElement | FocusStatement | FocusChoice | FocusTextObjectChoice;
type FocusItemTypeWithoutCallback =
  | Omit<LoadElement, 'doneCallback'>
  | Omit<FilterElement, 'doneCallback'>
  | Omit<FocusStatement, 'doneCallback'>
  | Omit<FocusChoice, 'doneCallback'>
  | Omit<FocusTextObjectChoice, 'doneCallback'>;

declare interface HighlightItemType {
  statement_id?: number | null;
  element_id?: number | null;
  region_id?: number | null;
  topic_id?: number | null;
  source?: HighlightSource;
}

declare interface HighlightOptions {
  highlight: boolean;
  scroll?: boolean;
}

type HighlightEvent = {
  item: HighlightItemType;
  options: HighlightOptions;
};

declare interface GlobalSearchTermType {
  source: 'GLOBAL' | 'PREFILL' | 'TAG';
  term: string;
}

export type CorrelatedStatementSource = {
  statementId: number | undefined;
  templateId: number | undefined;
  region: RR.Region | undefined;
  source: 'BUTTON' | 'STATEMENT';
};

declare interface FocusItem {
  element_id?: number;
  region_id?: number;
  subsection_id?: number;
  statement_id?: number;
  statement_choice_id?: number;
  text_object_choice_id?: number;
  target?: FocusItemTarget;
}

type FocusItemTarget = 'EDITOR' | 'PREFILL' | 'NOTEPAD';

@Injectable()
export class EditorService implements OnDestroy {
  /**
   * @deprecated make this store state
   */
  is_in_sc_mode = false;

  focusEvents = new BehaviorSubject<FocusItemType | null>(null);

  highlightEvents = new BehaviorSubject<HighlightEvent | null>(null);

  jumpToSectionEvents = new BehaviorSubject<{ section: RR.TemplateSection } | null>(null);
  jumpToTitleEvents = new BehaviorSubject<boolean>(false);
  // Sync search term between global search and index search
  globalSearchTerm$ = new BehaviorSubject<GlobalSearchTermType | null>(null);
  clearGlobalSearch$ = new Subject();

  subscription = new Subscription();
  shortcutsSubscription = new Subscription();

  shortcutsRegistered = false;

  registerPrefillShortcuts?: () => void;
  unregisterPrefillShortcuts?: () => void;
  registerEditorShortcuts?: () => void;
  unregisterEditorShortcuts?: () => void;

  private toggleSCModeEvent = new BehaviorSubject<boolean>(false);
  private selectedStatementSource = new BehaviorSubject<CorrelatedStatementSource | null>(null);
  selectedStatement$ = this.selectedStatementSource.asObservable();
  private _selectTopChoice = new Subject<'GENERAL' | 'STRUCTURED'>();
  selectTopChoice$: Observable<'GENERAL' | 'STRUCTURED'> = this._selectTopChoice.asObservable();
  private voiceNoteFocusSubject: Subject<void> = new Subject();
  voiceNoteFocus$ = this.voiceNoteFocusSubject.asObservable();
  private autoSuggestedStatementLoadTrigger: Subject<void> = new Subject();
  autoSuggestedStatementLoadTrigger$ = this.autoSuggestedStatementLoadTrigger.asObservable();
  private soabsTextSearch = new BehaviorSubject<string | null>(null);
  soabsTextSearch$ = this.soabsTextSearch.asObservable();
  private toggleBlueStatementSearch: Subject<void> = new Subject();
  toggleBlueStatementSearch$ = this.toggleBlueStatementSearch.asObservable();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private http: HttpClient,
    private statementCoincidenceEffect: StatementCoincidenceEffect,
    private reportEffect: ReportEffect,
    private reportService: ReportService,
    private templateService: TemplateService,
    private todoEffect: TodoEffect,
    private hotkeysService: HotkeysService,
  ) {}

  searchAndToggleCorrelatedStatement(statementSource: CorrelatedStatementSource) {
    this.selectedStatementSource.next(statementSource);
  }

  triggerSelectTopChoice(type: 'GENERAL' | 'STRUCTURED'): void {
    this._selectTopChoice.next(type);
  }

  searchTextInSoabs(value: string | null): void {
    this.soabsTextSearch.next(value);
  }

  triggerBlueStatementSearch(): void {
    this.toggleBlueStatementSearch.next();
  }

  triggerVoiceNoteFocus() {
    this.voiceNoteFocusSubject.next();
  }

  triggerAutoSuggestedLoad() {
    this.autoSuggestedStatementLoadTrigger.next();
  }

  publishFocus(focusItem: FocusItem) {
    const {
      element_id,
      region_id,
      subsection_id,
      statement_id,
      statement_choice_id,
      text_object_choice_id,
      target = 'EDITOR',
    } = focusItem;
    // make sure the editor is visible in the center (not prefill or template chooser)
    if (target !== 'PREFILL') {
      this.togglePrefill(false);
    }

    this.toggleTemplateChooser(false);

    // subsection_id, region_id, element_id just change the Store
    // there's currently no scrolling/focusing needed for them
    if (target === 'EDITOR') {
      if (element_id != null) {
        this.selectElement(element_id, region_id);
      } else if (subsection_id != null) {
        this.selectSubsection(subsection_id, region_id);
      }
    }

    let queue: FocusItemTypeWithoutCallback[] = [];

    if (element_id !== undefined) {
      queue.push({
        type: 'LoadElement',
        elementId: element_id,
        timeout: 3000,
      });
    }

    if (statement_id !== undefined || statement_choice_id !== undefined || text_object_choice_id !== undefined) {
      queue.push({
        type: 'FilterElement',
        timeout: 5000, // LRA is slow
        elementId: element_id,
        statementId: statement_id,
        target,
      });
    }

    if (statement_id !== undefined) {
      queue.push({
        type: 'FocusStatement',
        statementId: statement_id,
        timeout: 500, // Can take a while to render all the StatementComponents
      });
    }

    if (statement_choice_id !== undefined) {
      queue.push({
        type: 'FocusChoice',
        statementChoiceId: statement_choice_id,
        timeout: 300,
      });
    }

    if (text_object_choice_id !== undefined) {
      queue.push({
        type: 'FocusTextObjectChoice',
        textObjectChoiceId: text_object_choice_id,
        timeout: 300,
      });
    }

    const processNextTaskInQueue = () => {
      const item = queue.shift();
      if (item === undefined) {
        // No items left in the queue
        this.focusEvents.next(null);
        return;
      }

      const setTimeoutId = setTimeout(() => {
        console.warn(`Timeout: Couldn't focus number/attribute after ${item.timeout}ms: `, item);
        console.warn('Remaining items in queue:', queue);
        // It failed. But if the callbacks eventually are called, there should be nothing in the queue to process.
        queue = [];
        this.focusEvents.next(null);
      }, item.timeout);

      function doneCallback() {
        clearTimeout(setTimeoutId);
        processNextTaskInQueue();
      }
      this.focusEvents.next({
        ...item,
        doneCallback,
      });
    };

    // Process the first task in the queue, which will process the rest of the queue when after each doneCallback
    processNextTaskInQueue();
  }

  publishFocusTextObjectChoice(text_object_choice_id: number) {
    this.store
      .select(fromTextObjectChoice.selectTextObjectChoice(text_object_choice_id))
      .pipe(
        filterDefined(),
        take(1),
        switchMap((textObjectChoice) => this.publishFocusChoiceData(textObjectChoice.statement_choice_id)),
      )
      .subscribe((data) => {
        this.publishFocus({
          ...data,
          text_object_choice_id,
        });
      });
  }

  publishFocusChoiceData(statement_choice_id: number): Observable<{
    element_id?: number;
    region_id?: number;
    statement_id?: number;
    statement_choice_id: number;
  }> {
    return this.reportService.getStatementChoice(statement_choice_id).pipe(
      // @ts-expect-error strictNullChecks
      switchMap((choice) => this.reportService.getChoiceParents(choice)),
      take(1),
      map((ctx) => {
        let region_id;
        if (ctx.region_choice) {
          region_id = ctx.region_choice.region_id;
        }
        return {
          element_id: ctx.element_choice?.element_id,
          region_id,
          statement_id: ctx.statement_choice?.statement_id ?? undefined,
          statement_choice_id,
        };
      }),
    );
  }

  publishFocusChoice(statement_choice_id: number) {
    this.publishFocusChoiceData(statement_choice_id)
      .pipe(take(1))
      .subscribe((data) => {
        this.publishFocus(data);
      });
  }

  getOpenTopicId() {
    return this.store.select(fromCurrentTopic.selectTopicId);
  }

  getEditorTopics(topic_id: number) {
    return this.store.select(fromEditor.selectEditorTopic(topic_id)).pipe(filterDefined());
  }

  saveGlobalSearch(topic_id: number, text: string) {
    text = text.toLowerCase().trim();
    if (text) {
      this.store.dispatch(EditorActions.saveGlobalSearch({ openTopicId: topic_id, text }));
    }
  }

  clearGlobalSearch() {
    this.clearGlobalSearch$.next(true);
  }

  getSavedGlobalSearches(topic_id: number) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
    return this.getEditorTopics(topic_id).pipe(map((t) => (t.savedGlobalSearches ? t.savedGlobalSearches : [])));
  }

  doGlobalSearch(topic_id: number, text: string) {
    this.store.dispatch(EditorActions.doGlobalSearch({ openTopicId: topic_id, text }));
  }

  getGlobalSearch(topic_id: number) {
    return this.getEditorTopics(topic_id).pipe(
      select((t) => (t.globalSearch ? t.globalSearch : '')),
      distinctUntilChanged(),
    );
  }

  getAdjacentStatement(topic_id: number) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
    return this.getEditorTopics(topic_id).pipe(select((t) => (t.adjacentStatement ? t.adjacentStatement : null)));
  }

  getFirstRegion(subsection_id: number) {
    return this.templateService.getSubsection(subsection_id).pipe(
      // @ts-expect-error strictNullChecks
      switchMap((subsection: RR.Subsection) => this.templateService.getRegions(subsection.region_set_id)),
      map((regions: RR.Region[]) => (regions.length > 0 ? regions[0] : undefined)),
    );
  }

  getActiveRegionOrFirstRegion(topic_id: number, subsection_id: number) {
    return this.getBreadcrumb(topic_id).pipe(
      switchMap((breadcrumb) => {
        if (breadcrumb.region_id) {
          return this.templateService.getRegion(breadcrumb.region_id);
        }
        return this.getFirstRegion(subsection_id);
      }),
    );
  }

  toggleEditMode(on?: boolean) {
    this.store.dispatch(EditorActions.toggleEditMode({ editMode: on }));
  }

  editMode = this.store.select(fromEditor.selectEditMode);

  select(statement_id: number) {
    this.store.dispatch(EditorActions.selectStatement({ statementId: statement_id }));
  }

  clearSelectedStatements() {
    this.store.dispatch(EditorActions.clearSelectedStatements());
  }

  selectedStatements = this.store.select(fromEditor.selectSelectedStatements);

  isSelected(statement_id: number) {
    return this.selectedStatements.pipe(
      map((selectedStatements) => {
        return selectedStatements.has(statement_id);
      }),
    );
  }

  togglePrefill(on?: boolean) {
    this.store.dispatch(EditorActions.togglePrefill({ prefill: on }));
  }

  get prefill() {
    return this.store.select(fromEditor.selectIsPrefillOpen);
  }

  toggleTemplateChooser(on?: boolean) {
    this.store.dispatch(EditorActions.toggleTemplateChooser({ templateChooser: on }));
  }

  get templateChooser() {
    return this.store.select(fromEditor.selectIsTemplateChooserOpen);
  }

  toggleUnderlinePrefilled(payload: EditorState['underlinePrefilled']) {
    this.store.dispatch(EditorActions.togglePrefilledUnderline({ ...payload }));
  }

  getUnderlinePrefilled() {
    return this.store.select(fromEditor.selectUnderlinePrefilled);
  }

  toggleRightPaneViewMode(mode: RightPaneViewModeType) {
    this.store.dispatch(EditorActions.toggleRightPaneViewMode({ mode: mode }));
  }

  setTopicOpen(topic_id: number) {
    this.togglePrefill(false);
    this.toggleTemplateChooser(false);
    this.toggleRightPaneViewMode('REPORT_PREVIEW');
    this.reportService
      .getTopic(topic_id)
      .pipe(take(1))
      .subscribe((topic) => {
        this.router.navigate(['/report', topic.report_id, 'topic', topic_id]);
      });
  }

  jumpToSection(section: RR.Section, topic_id?: number) {
    // We can select a section in another topic, to do so we first have to
    // switch to that topic, making it the current one.
    if (topic_id != null) {
      this.setTopicOpen(topic_id);
    }
    this.selectSection(section);
    this.jumpToSectionEvents.next({ section: section.type });
    scrollPreviewToElement(document.getElementById(section.type));
  }

  jumpToTitle(topic_id: number) {
    this.setTopicOpen(topic_id);
    this.jumpToTitleEvents.next(true);
  }

  /**
   * @param scroll trigger scroll
   * only scrolls when hovering in the middle pane
   * doesn't not scroll when hovering preview or index
   */
  publishHighlight(item: HighlightItemType, options: HighlightOptions) {
    this.highlightEvents.next({ item, options });
  }

  getHighlight(element_id: number, topic_id: number, statement_id?: number, region_id?: number) {
    return this.highlightEvents.pipe(
      map((e) => {
        if (!e) return false;
        if (e.item.element_id !== element_id || e.item.topic_id !== topic_id) return false;
        if (!!e.item.statement_id && !!statement_id && e.item.statement_id !== statement_id) return false;
        if (!!e.item.region_id && !!region_id && e.item.region_id !== region_id) return false;
        return e.options.highlight;
      }),
    );
  }

  getScrollEvents(
    element_id: number,
    topic_id: number,
    statement_id?: number,
    region_id?: number,
    excludeSources?: HighlightSource[],
  ) {
    return this.highlightEvents.pipe(
      filter((e): e is HighlightEvent => !!e),
      filter((e) => e.options.highlight),
      filter((e) => e.item.element_id === element_id && e.item.topic_id === topic_id),
      filter((e) => e.item.statement_id == null || statement_id == null || e.item.statement_id === statement_id),
      filter((e) => (!e.item.region_id && !region_id) || e.item.region_id === region_id),
      filter((e) => !!e.options.scroll),
      // @ts-expect-error strictNullChecks
      filter((e) => !excludeSources?.length || !excludeSources.includes(e.item.source)),
    );
  }

  chooseAndFocusStatement(data: ChooseStatementData) {
    this.reportService.chooseStatement(data).subscribe((action) => {
      const choice = action.actions.statementChoiceAddMany.statementChoices[0];
      this.publishFocusChoice(choice.id);
    });
  }

  greyOutEmptySections(previewEl: HTMLElement) {
    const isCopying = false;
    const toHide = this.checkDomElements(previewEl, isCopying);

    for (const el of Array.from<HTMLElement>(previewEl.querySelectorAll('.text-black-50'))) {
      if (!toHide.includes(el)) el.classList.remove('text-black-50');
    }

    for (const el of toHide) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (el) el.classList.add('text-black-50');
    }
  }

  checkDomElements(previewEl: HTMLElement, isCopying: boolean) {
    const toHide: HTMLElement[] = [];
    // Hide empty subsections
    const subEls = previewEl.querySelectorAll<HTMLElement>('rr-subsection-preview');
    for (const el of Array.from(subEls)) {
      if (elementsAreEmpty(el.querySelectorAll('rr-element-preview'))) {
        if (isCopying) {
          toHide.push(el);
        } else {
          const subsectionHeading = el.querySelector('.subsection-heading');
          if (subsectionHeading instanceof HTMLElement) {
            toHide.push(subsectionHeading);
          }
        }
      }
    }

    // Hide empty regions
    const regionEls = previewEl.querySelectorAll<HTMLElement>('.region');
    for (const el of Array.from(regionEls)) {
      if (elementsAreEmpty(el.querySelectorAll('rr-element-preview'))) {
        if (isCopying) {
          toHide.push(el);
        } else {
          const regionHeading = el.querySelector('.region-heading');
          if (regionHeading instanceof HTMLElement) {
            toHide.push(regionHeading);
          }
        }
      }
    }

    // Hide empty sections
    const headingEls = previewEl.querySelectorAll<HTMLElement>('rr-heading-preview');
    for (const el of Array.from(headingEls)) {
      const sectionEl = el.nextElementSibling;
      if (sectionEl != null) {
        if (elementsAreEmpty(sectionEl.querySelectorAll('rr-element-preview')) && !el.querySelector('.other-imaging')) {
          if (isCopying) {
            toHide.push(el);
          } else {
            const sectionHeading = el.querySelector('.section-heading');
            if (sectionHeading instanceof HTMLElement) {
              toHide.push(sectionHeading);
            }
          }
        }
      }
    }

    return toHide;
  }

  setScheduleSendToVoyager(reportId: number | undefined) {
    this.store.dispatch(EditorActions.setScheduleSendToVoyagerTask({ reportId }));
  }

  getScheduleSendToVoyagerTask() {
    return this.store.select(fromEditor.selectScheduleSendToVoyagerTask);
  }

  /**
   * Send to voyager, always send the latest report text
   * @param report_id
   */
  sendToVoyager(report_id: number) {
    // it is being subscribed at time-tracker.component
    return this.reportEffect.sendToVoyager(report_id);
  }

  setBreadcrumb(topic_id: number, breadcrumb: Breadcrumb) {
    this.store.dispatch(EditorActions.setBreadcrumb({ openTopicId: topic_id, breadcrumb }));
  }

  getBreadcrumb(topic_id: number) {
    return this.getEditorTopics(topic_id).pipe(select((t) => t.breadcrumb));
  }

  findFirstElement(template_id: number, subsection_id: number) {
    return this.templateService.getElements(template_id).pipe(
      map((elements) => {
        return elements.find((element) => {
          return element.subsection.id === subsection_id;
        });
      }),
    );
  }

  // Select section/ subsection/ element from dropdown sections/subsections/elements
  selectSection(section: RR.Section) {
    // select the first subsection in the section
    this.store
      .select(fromSection.selectSubsections(section.id))
      .pipe(
        take(1),
        filter((subsections): subsections is RR.Subsection[] => !!subsections),
        switchMap((subsections) => {
          const firstSubsection: RR.Subsection = subsections[0];
          if (firstSubsection.region_set_id) {
            return this.getFirstRegion(firstSubsection.id).pipe(
              take(1),
              map((firstRegion) => ({
                firstRegion,
                firstSubsection,
              })),
            );
          } else {
            return of({ firstSubsection, firstRegion: undefined });
          }
        }),
      )
      .subscribe(({ firstSubsection, firstRegion }) => {
        // select the first element in the subsection
        const firstElementId = firstSubsection.elements[0];
        this.store
          .select(fromCurrentTopic.selectTopicId)
          .pipe(take(1), filterDefined())
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe((topic_id) => {
            this.store.dispatch(
              EditorActions.setLastClickedBreadcrumb({
                openTopicId: topic_id,
                lastClickedBreadcrumb: {
                  type: 'section',
                  section_id: section.id,
                },
              }),
            );
            this.setBreadcrumb(topic_id, {
              section_id: section.id,
              subsection_id: firstSubsection.id,
              element_id: firstElementId,
              region_id: firstRegion ? firstRegion.id : null,
            });
          });
      });
  }

  selectSubsection(subsectionId: number, region_id?: number) {
    const iElement$ = this.store.select(fromCurrentTemplate.selectTemplate).pipe(
      filterDefined(),
      switchMap((template) => this.templateService.getElements(template.id)),
      map((elements) => elements.find((iElement) => iElement.subsection.id === subsectionId)),
    );
    combineLatest([
      iElement$,
      this.store.select(fromSubsection.selectSubsection(subsectionId)).pipe(filterDefined()),
      this.store.select(fromSubsection.selectRegions(subsectionId)),
      this.store.select(fromCurrentTopic.selectTopicId).pipe(filterDefined()),
    ])
      .pipe(take(1))
      .subscribe(([iElement, subsection, regions, topicId]) => {
        if (!iElement) {
          throw new Error(`Element not found`);
        }
        const section = iElement.section;
        if (regions.length > 0 && region_id == null) {
          // when a region_id isn't specified (null) default to the first one
          region_id = regions[0]?.id;
        }
        this.store.dispatch(
          EditorActions.setLastClickedBreadcrumb({
            openTopicId: topicId,
            lastClickedBreadcrumb: {
              type: 'subsection',
              section_id: section.id,
              subsection_id: subsection.id,
            },
          }),
        );
        if (subsection.elements.length > 0) {
          this.setBreadcrumb(topicId, {
            section_id: section.id,
            subsection_id: subsection.id,
            region_id,
            element_id: subsection.elements[0],
          });
        }
      });
  }

  selectRegion(region_id: number) {
    this.store
      .select(fromCurrentTopic.selectTopicId)
      .pipe(
        take(1),
        switchMap((topicId) =>
          topicId
            ? this.getBreadcrumb(topicId).pipe(
                take(1),
                map((breadcrumb) => ({
                  breadcrumb,
                  topicId,
                })),
              )
            : of(undefined),
        ),
      )
      .subscribe((responseObj) => {
        if (responseObj) {
          const { breadcrumb, topicId } = responseObj;
          this.setBreadcrumb(topicId, {
            ...breadcrumb,
            region_id,
          });
        }
      });
  }

  selectElement(element_id: number, region_id?: number) {
    const iElement$ = this.store.select(fromCurrentTemplate.selectTemplate).pipe(
      filterDefined(),
      switchMap((template) => this.templateService.getElements(template.id)),
      map((elements) => elements.find((iElement) => iElement.element.id === element_id)),
    );
    combineLatest([
      iElement$,
      this.store.select(fromBreadcrumb.selectCurrent),
      this.store.select(fromCurrentTopic.selectTopicId).pipe(filterDefined()),
      this.store.select(fromRegion.selectEntities),
    ])
      .pipe(take(1))
      .subscribe(([iElement, breadcrumb, topicId, region_entities]) => {
        if (!iElement) {
          console.warn(`Element with id ${element_id} not found`);
          return;
        }
        if (!breadcrumb) {
          console.warn('No breadcrumb found');
          return;
        }
        const { subsection, section } = iElement;

        const activeRegion = breadcrumb.region_id != null ? region_entities[breadcrumb.region_id] : null;
        // keep same region selected when switching elements

        let next_region_id: number | null | undefined = region_id;
        if (activeRegion && next_region_id == null) {
          next_region_id = subsection.region_set_id === activeRegion.region_set_id ? activeRegion.id : null;
        } else if (next_region_id == null) {
          this.getFirstRegion(subsection.id)
            .pipe(take(1))
            // eslint-disable-next-line rxjs/no-nested-subscribe -- 2
            .subscribe((firstRegion) => {
              if (firstRegion) {
                next_region_id = firstRegion.id;
              }
            });
        }

        this.store.dispatch(
          EditorActions.setLastClickedBreadcrumb({
            openTopicId: topicId,
            lastClickedBreadcrumb: {
              type: 'element',
              section_id: section.id,
              subsection_id: subsection.id,
              element_id,
            },
          }),
        );
        this.setBreadcrumb(topicId, {
          section_id: section.id,
          subsection_id: subsection.id,
          element_id,
          region_id: next_region_id,
        });
      });
  }

  /**
   * This saves the location for jumping back to the original element by pressing "b" (designed for move statements)
   * TODO: Should be able to go back multiple times. Getting back to where you were in the editor from prefill is a
   * problem too.
   */
  saveAdjacentStatement(statement_id: number, element: RR.Element, topic_id: number, region_id: number | undefined) {
    return this.store.select(fromStatementSet.selectStatementNeighbourId(element.statement_set_id, statement_id)).pipe(
      take(1),
      tap((neighbourId) => {
        if (neighbourId === undefined) return;
        const changes = {
          id: neighbourId,
          element_id: element.id,
          topic_id,
          region_id: region_id || null,
        };
        this.store.dispatch(EditorActions.saveAdjacentStatement({ openTopicId: topic_id, adjacentStatement: changes }));
      }),
    );
  }

  markAsNotError(statement1_id: number, statement2_id: number) {
    return this.statementCoincidenceEffect.create({
      statement1_id,
      statement2_id,
      validity: true,
    });
  }

  saveMostRecentCategory(cat_id: number, top20 = false) {
    if (top20) {
      this.store.dispatch(EditorActions.saveMostRecentTop20Category({ categoryId: cat_id }));
    } else {
      this.store.dispatch(EditorActions.saveMostRecentCategory({ categoryId: cat_id }));
    }
  }

  getMostRecentCategories(top20 = false) {
    if (top20) {
      return this.store.select(fromEditor.selectMostRecentTop20Categories);
    }
    return this.store.select(fromEditor.selectMostRecentCategories);
  }

  getClipboardSentence() {
    return this.store.select(fromEditor.selectClipboard);
  }

  saveClipboardSentence(sentence: string) {
    return this.store.dispatch(EditorActions.saveClipboardSentence({ sentence }));
  }

  addUserActiveCheckListener(callback: any) {
    return combineLatest([
      fromEvent(document, 'mousemove').pipe(startWith(null)),
      fromEvent(document, 'keydown').pipe(startWith(null)),
      fromEvent(document, 'mousedown').pipe(startWith(null)),
      fromEvent(document, 'mousewheel').pipe(startWith(null)),
    ])
      .pipe(throttleTime(1000), tap(callback))
      .subscribe();
  }

  removeUserActiveCheckListener(userActiveSubscription: Subscription) {
    userActiveSubscription.unsubscribe();
  }

  toggleSidebar(open?: boolean) {
    this.store.dispatch(EditorActions.openSidebar({ open }));
  }

  isSidebarOpen() {
    return this.store.select(fromEditor.selectIsSidebarOpen);
  }

  elementFilterType() {
    return this.store.select(fromEditor.selectElementFilterType);
  }

  updateElementFilterType(type: ElementFilterType) {
    this.store.dispatch(EditorActions.updateElementFilter({ filterType: type }));
  }

  emitToggleSCModeEvent() {
    this.is_in_sc_mode = !this.is_in_sc_mode;
    this.toggleSCModeEvent.next(this.is_in_sc_mode);
  }

  toggleSCModeEventListener() {
    return this.toggleSCModeEvent.asObservable();
  }

  linkMeasurementToTextObject(measurement_rule: Partial<RR.MeasurementRule>, text_object_id: number) {
    return this.http.post<RR.MeasurementRuleTextObject>('/api/measurement_rule_text_object', {
      text_object_id,
      measurement_rule,
    });
  }

  linkMeasurementRuleToTextObject(measurement_rule_id: number, text_object_id: number) {
    return this.http.post<RR.MeasurementRuleTextObject>('/api/measurement_rule_text_object', {
      text_object_id,
      measurement_rule_id,
    });
  }

  fetchLinkedMeasurementRules(sto_id: number) {
    const params = new HttpParams().set('text_object_id', sto_id.toString());
    return this.http.get<RR.MeasurementRuleTextObject[]>('/api/measurement_rule_text_object', {
      params,
    });
  }

  deleteMeasurementRuleLink(id: number) {
    return this.http.delete<RR.MeasurementRule>(`/api/measurement_rule_text_object/${id}`);
  }

  /**
   * Select the next voice search term for the global search inputs
   */
  nextSearchTerm() {
    this.store
      .select(fromEditor.selectVoiceSearchResults)
      .pipe(withLatestFrom(this.globalSearchTerm$))
      .pipe(take(1))
      .subscribe(([voiceResults, searchTerm]) => {
        const startingPos = voiceResults.findIndex((result) => searchTerm?.term === result.term);
        let nextTerm = voiceResults[0];
        if (startingPos >= 0 && startingPos < voiceResults.length - 1) {
          nextTerm = voiceResults[startingPos + 1];
        }
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        if (nextTerm) this.globalSearchTerm$.next({ source: 'TAG', term: nextTerm.term });
      });
  }

  createMedicalNotesFromStatementTooltip(statementChoiceId: number, reportId: number, statement: RR.Statement) {
    if (!statement.tooltip) {
      return;
    }
    const todoText = `Statement Tooltip: ${statement.tooltip}`;
    this.subscription.add(
      this.store
        .select(fromTodo.selectInReport(reportId))
        .pipe(
          take(1),
          filter((todos) => !todos.some((todo) => todo.todo === todoText)),
          switchMap(() =>
            this.todoEffect.create({
              report_id: reportId,
              todo: todoText,
              statement_choice_id: statementChoiceId,
            }),
          ),
          filter((result) => !!result),
        )
        .subscribe(),
    );
  }

  registerKeyboardShortcuts(actions: { [key: string]: () => void }) {
    if (this.shortcutsRegistered) {
      this.unregisterKeyboardShortcuts();
    }

    this.shortcutsSubscription = new Subscription();

    Object.keys(actions).forEach((key) => {
      this.shortcutsSubscription.add(this.hotkeysService.addShortcut({ keys: key }).subscribe(actions[key]));
    });

    this.shortcutsRegistered = true;
  }

  subscribeToKeyboardShortcuts() {
    this.subscription.add(
      this.prefill.subscribe((prefill) => {
        if (!prefill) {
          if (this.unregisterPrefillShortcuts === undefined) {
            throw new Error('unregisterPrefillShortcuts is undefined');
          }
          if (this.registerEditorShortcuts === undefined) {
            throw new Error('registerEditorShortcuts is undefined');
          }
          this.unregisterPrefillShortcuts();
          this.registerEditorShortcuts();
        } else {
          if (this.unregisterEditorShortcuts === undefined) {
            throw new Error('unregisterEditorShortcuts is undefined');
          }
          if (this.registerPrefillShortcuts === undefined) {
            throw new Error('registerPrefillShortcuts is undefined');
          }
          this.unregisterEditorShortcuts();
          this.registerPrefillShortcuts();
        }
      }),
    );
  }

  unregisterKeyboardShortcuts() {
    this.shortcutsSubscription.unsubscribe();
    this.shortcutsRegistered = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unregisterKeyboardShortcuts();
  }
}
