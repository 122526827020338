import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportStatusHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.ReportStatus[]> {
    return this.http.get<RR.ReportStatus[]>('/api/report_status');
  }
}
