import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.FollowupTask>();
const selectFeature = (state: AppState) => state.followup_task;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectFollowupTasksInReport = (reportId: number) =>
  createSelector(selectAll, (tasks) => tasks.filter((e) => e.report_id === reportId));

const selectFollowupTask = (id: number) => createSelector(selectEntities, (tasks) => tasks[id]);

export const fromFollowupTask = {
  selectIds,
  selectEntities,
  selectAll,
  selectFollowupTasksInReport,
  selectFollowupTask,
};
