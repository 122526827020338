import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for Signature
 *
 */
export const SignatureActions = {
  findAllSuccess: createAction('[Signature] Find All Success', props<{ signatures: RR.Signature[] }>()),

  createSuccess: createAction('[Signature] Create Success', props<{ signature: RR.Signature }>()),

  updateSuccess: createAction('[Signature] Update Success', props<{ signature: RR.Signature }>()),

  updateManySuccess: createAction('[Signature] Update Many Success', props<{ signatures: RR.Signature[] }>()),
};
