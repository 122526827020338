import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { NoteTextComponent } from 'app/shared/components/note-text/note-text.component';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { AppState } from 'app/store';
import { fromTodo, TodoEffect } from 'app/store/report/todo';
import { fromUser } from 'app/store/user/user';
import { Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbModule, StoreSelectPipe, NoteTextComponent],
  selector: 'rr-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.css'],
})
export class TodoComponent implements OnInit, OnDestroy {
  @Input() todo: RR.Todo;
  audit_events$: Observable<RR.AuditEvent[] | undefined>;

  subscription = new Subscription();

  constructor(
    private todoEffect: TodoEffect,
    private reportService: ReportService,
    private editorService: EditorService,
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.audit_events$ = this.store.select(fromTodo.selectAuditEvents(this.todo.id));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  resolveTodo(todo_id: number, resolved: boolean) {
    this.subscription.add(this.todoEffect.update(todo_id, { resolved }).subscribe());
  }

  deleteTodo(todo_id: number) {
    this.subscription.add(this.todoEffect.delete(todo_id).subscribe());
  }

  getStatementChoiceText(statement_choice_id: number) {
    return this.reportService.getChoiceText(statement_choice_id);
  }

  jumpToSentence(choice_id: number) {
    this.editorService.publishFocusChoice(choice_id);
    this.activeModal.dismiss();
  }

  selectUserSelectorFn = fromUser.selectUser;
}
