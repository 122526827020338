<div class="modal-header">
  <div>
    <h5 class="modal-title">Give Feedback (G)</h5>
    <rr-zero-fp-images *ngIf="report$ | async as report" [report]="report" rrTooltip="OPEN_IMAGE">
      (W) Web Viewer Images <span class="small fa fa-external-link-alt"></span>
    </rr-zero-fp-images>
  </div>
  <button role="button" class="btn-close ms-auto" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body d-flex flex-row">
  <div class="row d-flex flex-row flex-grow-1">
    <div class="col-3">
      <rr-feedback-recipients
        [selectedUserId]="selectedUserId"
        [reportId]="reportId"
        [parent]="'SEND_FEEDBACK'"
        (onUserSelected)="onSelectedUserChanged($event)"
      ></rr-feedback-recipients>
    </div>

    <div class="col-9" *ngIf="selectedUserId">
      <rr-send-feedback [reportId]="reportId" [recipientId]="selectedUserId" [selectedUserId]="selectedUserId">
      </rr-send-feedback>

      <div class="d-flex flex-column flex-grow-1 overflow-auto">
        <rr-report-text-diff [userId]="selectedUserId" [reportId]="reportId"></rr-report-text-diff>
      </div>
    </div>
  </div>
</div>
