import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { ScanCodeHeadlineComponent } from 'app/shared/components/scan-code-headline/scan-code-headline.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AppState } from 'app/store';
import { BillingGroupEffect } from 'app/store/billing-group/billing-group.effect';
import { fromBillingGroup } from 'app/store/billing-group/billing-group.selector';
import { fromBillingItem } from 'app/store/billing-item/billing-item.selector';
import { fromReport } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { BillingItemComponent } from '../billing-item/billing-item.component';
import { TopicTitleOptionTextComponent } from '../topic-title-option-text/topic-title-option-text.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    TopicTitleOptionTextComponent,
    BillingItemComponent,
    ScanCodeHeadlineComponent,
    AutoFocusDirective,
    LetDirective,
  ],
  selector: 'rr-billing-item-search',
  templateUrl: './billing-item-search.component.html',
  styleUrls: ['./billing-item-search.component.css'],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class BillingItemSearchComponent implements OnInit {
  @Input() report: RR.Report | undefined;
  @Input() autoFocus: boolean;
  @Input() placeholder = 'Search billing items';
  @Output() onSelectBillingItem = new EventEmitter();

  subscription = new Subscription();
  scanCode$: Observable<RR.ScanCode | undefined>;
  allBillingItems: RR.BillingItem[];
  filteredBillingItems: RR.BillingItem[];
  suggestedBillingItems$: Observable<RR.BillingItem[]>;

  billingItemSearch = new FormControl('', { nonNullable: true });

  constructor(
    private store: Store<AppState>,
    private billingGroupEffect: BillingGroupEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromBillingGroup.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.billingGroupEffect.findAll();
            } else {
              return of();
            }
          }),
        )
        .subscribe(),
    );

    if (this.report) {
      const report$ = this.store.select(fromReport.selectReport(this.report.id));

      this.scanCode$ = report$.pipe(
        map((report) => report?.scan_code_id),
        filterDefined(),
        switchMap((scId) => this.store.select(fromScanCode.selectScanCode(scId))),
      );

      this.subscription.add(
        this.scanCode$.subscribe((c) => {
          if (c) {
            this.billingItemSearch.patchValue(c.code);
          }
        }),
      );

      this.suggestedBillingItems$ = this.scanCode$.pipe(
        filterDefined(),
        switchMap((sc) => this.store.select(fromScanCode.selectBillingItems(sc.id))),
        map((billingItems) => billingItems.filter((item) => !!item && !item.deleted)),
      );
    }

    const allBillingItems$ = this.store.select(fromBillingItem.selectAll);

    this.subscription.add(
      allBillingItems$.subscribe((allItems) => {
        this.allBillingItems = allItems.filter((item) => !item.deleted);
        const searchText = this.billingItemSearch.value;
        if (searchText) {
          this.onSearchTextChanged(searchText);
        } else {
          this.filteredBillingItems = this.allBillingItems;
        }
      }),
    );
  }

  selectBillingItem(billingItem: RR.BillingItem) {
    this.onSelectBillingItem.emit(billingItem);
  }

  onSearchTextChanged(text: string) {
    this.filteredBillingItems = this.allBillingItems.filter(
      (item) =>
        item.item_code.toLowerCase().includes(text.toLowerCase()) ||
        item.notes?.toLowerCase().includes(text.toLowerCase()),
    );
  }
}
