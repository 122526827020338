import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { TitleOptionActions } from './title-option.action';

export type TitleOptionState = EntityState<RR.TitleOption>;

const adapter = createEntityAdapter<RR.TitleOption>();

const initialState = adapter.getInitialState();

export const titleOptionReducer = createReducer(
  initialState,
  on(
    TitleOptionActions.findInTemplateSuccess,
    TitleOptionActions.findInTitleSetSuccess,
    (state: TitleOptionState, { titleOptions }) => adapter.upsertMany(titleOptions, state),
  ),
  on(TitleOptionActions.deleteManySuccess, (state: TitleOptionState, { titleIds }) =>
    adapter.removeMany(titleIds, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
