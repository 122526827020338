<div *ngIf="favourite" class="d-flex">
  <rr-favourite-headline [favourite]="favourite"></rr-favourite-headline>
  <a
    *ngIf="topic"
    [routerLink]="['/report', topic.report_id, 'topic', topic.id]"
    target="_blank"
    class="d-flex align-items-center text-nowrap ms-1"
    (click)="$event.stopPropagation()"
  >
    <rr-report-accession-number
      *ngIf="topic.report_id | storeSelectPipe: selectReportSelectorFn | async as refReport"
      [report]="refReport"
    ></rr-report-accession-number>
    <span class="small fa fa-external-link-alt ms-1"></span>
  </a>
  <button *ngIf="context === 'FAVOURITES'" class="btn btn-primary ms-1" (click)="onSelectFavourite.emit(favourite)">
    Select
  </button>
</div>
