import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ProviderNumberActions } from './provider-number.action';
import { ProviderNumberHttpService } from './provider-number.service';

@Injectable()
export class ProviderNumberEffect {
  constructor(
    private store: Store<AppState>,
    private service: ProviderNumberHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((providerNumbers: RR.ProviderNumber[]) => ProviderNumberActions.findAllSuccess({ providerNumbers })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
