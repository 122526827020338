import { createAction, props } from '@ngrx/store';

export type SendSMSPayload = {
  report_id: number;
  send_to: string;
  message?: string;
};

export type SendReportPayload = {
  report_id: number;
  send_to: string;
  message?: string;
  send_type?: 'EMAIL' | 'FAX';
  /**
   * email_subject must be defined. It has no server fallback.
   */
  email_subject: string;
  email_url_message?: string;
};

export type SendMOPayload = {
  report_id: number;
  provider: RR.Provider;
  cc?: RR.Provider[];
};

/**
 * Ngrx Actions for Report Send Event
 *
 */
export const SendEventActions = {
  findManySuccess: createAction('[SendEvent] Find Many Success', props<{ sendEvents: RR.SendEvent[] }>()),

  sendSuccess: createAction('[SendEvent] Create Success', props<{ sendEvents: RR.SendEvent[] }>()),
};
