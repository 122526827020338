import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NOTEPAD_SCROLL_ID } from 'app/app.constants';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store/app.state';
import { fromCurrentReport } from 'app/store/report/report';
import { fromElement } from 'app/store/template/element';
import { fromStatementSet } from 'app/store/template/statement-set';
import { fromCurrentTemplate } from 'app/store/template/template';
import { map, Observable, Subscription, switchMap } from 'rxjs';

import { StoreSelectPipe } from '../../../shared/pipes/store-select.pipe';
import { ElementComponent } from '../element/element.component';

@Component({
  templateUrl: './notepad-modal.component.html',
  styleUrls: ['./notepad-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, forwardRef(() => ElementComponent), StoreSelectPipe],
})
export class NotepadModalComponent implements OnInit, AfterViewInit, OnDestroy {
  SCROLL_ID = NOTEPAD_SCROLL_ID;
  @Input() template_id: number;
  @Input() topic: RR.Topic;
  @Input() element: RR.Element;
  @Input() subsection: RR.Subsection;
  @Input() section: RR.Section;
  @Input() region: RR.Region | undefined;
  @Input() focusChoiceId: number | undefined;

  notepads$: Observable<RR.Element[]>;
  report$: Observable<RR.Report | undefined>;
  subscription: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private editorService: EditorService,
  ) {}

  ngOnInit() {
    this.notepads$ = this.store.select(fromCurrentTemplate.selectNotepads);
    this.report$ = this.store.select(fromCurrentReport.selectReport);
  }

  ngAfterViewInit(): void {
    // setTimeout to prevent NG0100 warning
    setTimeout(() => {
      if (this.focusChoiceId) {
        // There might be multiple notepads in the notepads modal that can handle the `ElementFilter` event and
        // cause the focusStatement event failed. Therefore, we need to find the right notepad that has the focused choice
        this.subscription.add(
          // Find the focus data from choice_id, this will include the statement_id of that choice
          this.editorService
            .publishFocusChoiceData(this.focusChoiceId)
            .pipe(
              switchMap((data) =>
                // Find the statementSet that has the choice's statement_id
                this.store.select(fromElement.selectGlobalStatementSets).pipe(
                  map((statementSets) => ({
                    statementSet: data.statement_id
                      ? statementSets.find((ss) => data.statement_id && ss.statements.includes(data.statement_id))
                      : undefined,
                    data,
                  })),
                ),
              ),
              // Find the notepad that link to the above statement set
              switchMap(({ statementSet, data }) =>
                this.notepads$.pipe(
                  map((notepads) => ({
                    notepad: notepads.find((np) => statementSet && np.statement_set_id === statementSet.id),
                    data,
                  })),
                ),
              ),
            )
            .subscribe(({ notepad, data }) => {
              // Publish focus with element_id = notepad.id and target = NOTEPAD so that only the notepad element in
              // the notepad modal can handle the FilterElement event before focusing on statement and statement choice
              this.editorService.publishFocus({ ...data, element_id: notepad?.id, target: 'NOTEPAD' });
            }),
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectElementName = fromStatementSet.selectStatementSetName;

  static open({
    modal,
    template_id,
    topic,
    element,
    subsection,
    section,
    region,
    focusedChoiceId,
  }: {
    modal: NgbModal;
    template_id: number;
    topic: RR.Topic;
    element: RR.Element;
    subsection: RR.Subsection;
    section: RR.Section;
    region: RR.Region | undefined;
    focusedChoiceId?: number | undefined;
  }) {
    const modalRef = modal.open(NotepadModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    const componentInstance: NotepadModalComponent = modalRef.componentInstance;
    componentInstance.template_id = template_id;
    componentInstance.topic = topic;
    componentInstance.element = element;
    componentInstance.section = section;
    componentInstance.subsection = subsection;
    componentInstance.region = region;
    componentInstance.focusChoiceId = focusedChoiceId;
    return modalRef;
  }
}
