<div permission [permission-only]="['template_manage']" *ngIf="!choice_object.attribute_option_id">
  <span class="header-title py-1">Approve Blue Attributes:</span>
  <div class="d-flex align-items-center justify-content-between">
    <span class="text-info">{{ name }}</span>
    <button
      type="button"
      class="btn btn-sm btn-success"
      rrTooltip="APPROVE_BLUE_ATTRIBUTE"
      (click)="approveBlueAttribute()"
    >
      <i class="fas fa-check"></i>
    </button>
  </div>
  <span class="hr-sect">&</span>
</div>

<span class="header-title py-1" *ngIf="attributeSet">{{ attributeSet.name }}: {{ attributeSet.id }}</span>
<div class="mb-0 input-group">
  <button *ngIf="searchQuery.value" class="btn btn-sm btn-outline-secondary" type="button" (click)="clearField()">
    <i class="fas fa-times"></i>
  </button>
  <input
    rrVoice
    [speechSynthesis]="true"
    [keydownStopListening]="false"
    type="text"
    class="form-control form-control-sm"
    placeholder="Search attributes&hellip;"
    [formControl]="searchQuery"
    (keydown.enter)="onEnter()"
    rrAutoFocus
  />
  <button
    *ngIf="!!equivalentTextObject"
    class="btn btn-sm btn-info"
    role="button"
    tabindex="-1"
    rrTooltip="CREATE_BLUE_ATTRIBUTE"
    [ngbPopover]="blueAttributeConfirmPopover"
    #popover="ngbPopover"
    triggers="manual"
    (click)="clickBluePlus()"
  >
    <span class="fa fa-plus"></span>
  </button>
  <ng-template #blueAttributeConfirmPopover>
    <div class="d-flex flex-column">
      <span>
        Are you sure you want to add <b>{{ searchQuery.value === '' ? 'BLANK' : searchQuery.value }}</b> into the
        statement rather than using any of these search results?
      </span>
      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-sm btn-info me-2" (click)="addBlueAttribute()">Yes</button>
        <button type="button" class="btn btn-sm btn-secondary">Cancel</button>
      </div>
    </div>
  </ng-template>
  <button
    *ngIf="!!equivalentTextObject"
    class="btn btn-sm btn-success"
    permission
    [permission-only]="['superuser']"
    role="button"
    tabindex="-1"
    (click)="addAdminAttribute()"
    rrTooltip="CREATE_ADMIN_ATTRIBUTE"
  >
    <span class="fa fa-plus"></span>
  </button>
</div>

<div class="scrollable" #scrollElement>
  <ng-container *ngIf="!isSearchingAttribute">
    <div class="list-group top" #topElement>
      <!--Auto shortlist-->
      <span
        class="hr-text"
        data-content="Auto Short List"
        *ngIf="shortListResponses && shortListResponses.attributes.length > 0"
      ></span>
      <div class="d-flex justify-content-center py-1" *ngIf="loadingShortList">
        <i class="fa fa-spinner fa-spin text-secondary"></i>
      </div>
      <ng-container *ngIf="!loadingShortList && shortListResponses && shortListResponses.attributes.length > 0">
        <ng-container *ngFor="let response of shortListResponses.attributes">
          <ng-container *ngIf="response.attribute_option_id | storeSelectPipe: selectAttributeOption | async as option">
            <button
              rr-attribute-option
              [attr.data-attribute-id]="option.id"
              role="button"
              tabindex="-1"
              rrTooltip="INSERT_INTO_SENTENCE"
              [attribute_option]="option"
              [statement_attribute]="equivalentTextObject"
              [choice]="choice"
              [chosen]="option.id === choice_object.attribute_option_id"
              [selected]="selectedAttribute?.attribute === option && selectedAttribute?.half === 'top'"
              [attribute_half]="'top'"
              (onChoose)="onClickAttribute(option)"
              [autoShortList]="true"
              [frequency]="response.frequency"
            ></button>
          </ng-container>
        </ng-container>
        <button
          class="btn btn-sm btn-info"
          *ngIf="shortListResponses.unfiltered_count > shortListResponses.attributes.length"
          (click)="getSentenceAttributeSetShortlist({ text_object_id: choice_object.text_object_id, filtering: false })"
        >
          Show {{ shortListResponses.unfiltered_count - shortListResponses.attributes.length }} hidden attribute(s)
        </button>
      </ng-container>
      <!--Manual shortlist-->
      <span class="hr-text" *ngIf="topAttributes" data-content="Manual Short List"></span>
      <button
        rr-attribute-option
        [attr.data-attribute-id]="option.id"
        *ngFor="let option of topAttributes"
        role="button"
        tabindex="-1"
        rrTooltip="INSERT_INTO_SENTENCE"
        [attribute_option]="option"
        [statement_attribute]="equivalentTextObject"
        [choice]="choice"
        [chosen]="option.id === choice_object.attribute_option_id"
        [selected]="selectedAttribute?.attribute === option && selectedAttribute?.half === 'top'"
        [attribute_half]="'top'"
        (onChoose)="onClickAttribute(option)"
        [autoShortList]="false"
      ></button>
    </div>
    <div class="list-group" #bottomElement>
      <ng-container *ngIf="bottomAttributes">
        <div class="d-flex my-1 align-items-center">
          <span class="hr-text" data-content="All Attributes"></span>
          <button class="btn btn-sm btn-info" role="button" (click)="showAllAttributes = !showAllAttributes">
            <span class="fa" [class.fa-eye-slash]="showAllAttributes" [class.fa-eye]="!showAllAttributes"></span>
            {{ showAllAttributes ? 'Hide All' : 'Show All' }}
          </button>
        </div>
        <div *ngIf="showAllAttributes">
          <button
            rr-attribute-option
            [attr.data-attribute-id]="option.id"
            *ngFor="let option of bottomAttributes"
            role="button"
            tabindex="-1"
            rrTooltip="INSERT_INTO_SENTENCE"
            [attribute_option]="option"
            [statement_attribute]="equivalentTextObject"
            [choice]="choice"
            [chosen]="option.id === choice_object.attribute_option_id"
            [selected]="selectedAttribute?.attribute === option && selectedAttribute?.half === 'bottom'"
            [attribute_half]="'bottom'"
            (onChoose)="onClickAttribute(option)"
          ></button>
        </div>
        <div *ngIf="!showAllAttributes">
          <ng-container *ngIf="getSelectedBottomAttribute() as option">
            <button
              rr-attribute-option
              [attr.data-attribute-id]="option.id"
              role="button"
              tabindex="-1"
              rrTooltip="INSERT_INTO_SENTENCE"
              [attribute_option]="option"
              [statement_attribute]="equivalentTextObject"
              [choice]="choice"
              [chosen]="option.id === choice_object.attribute_option_id"
              [selected]="selectedAttribute?.attribute === option && selectedAttribute?.half === 'bottom'"
              [attribute_half]="'bottom'"
              (onChoose)="onClickAttribute(option)"
            ></button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="list-group" *ngIf="isSearchingAttribute" #searchResultElement>
    <button
      rr-attribute-option
      [attr.data-attribute-id]="hit.store_attribute_option.id"
      *ngFor="let hit of attributeSearchHits"
      role="button"
      tabindex="-1"
      rrTooltip="INSERT_INTO_SENTENCE"
      [hit]="hit"
      [attribute_option]="hit.store_attribute_option"
      [statement_attribute]="equivalentTextObject"
      [choice]="choice"
      [chosen]="hit.store_attribute_option.id === choice_object.attribute_option_id"
      [selected]="selectedAttribute?.attribute === hit.store_attribute_option"
      [attribute_half]="'bottom'"
      (onChoose)="onClickAttribute(hit.store_attribute_option)"
      (onDelete)="onAttributeDelete($event)"
    ></button>
  </div>
</div>
