import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type PatientQuestionResponse = {
  patient_question_sets: RR.PatientQuestionSet[];
  patient_questions: RR.PatientQuestion[];
};

export type ReportExtractFeaturesResult = {
  result: string;
};

export type ImgSimWeight = {
  id: number;
  template_id: number;
  column_name: string;
  weight: number;
  description: string;
};

export type ReportSearchSort = 'modified' | 'priority' | 'created' | 'accession' | 'patient_last_name' | 'focused';

export type ReportSearchBody = {
  flagged?: boolean;
  sort?: ReportSearchSort;
  sign_report_type?: 'SIGNED_REPORT' | 'UNSIGNED_REPORT' | 'ALL';
  urgent?: boolean;
  date_type?: string;
  sent_report_type?: 'ALL' | 'SENT_REPORT' | 'UNSENT_REPORT';
  unbilled_report?: boolean;
  deleted_report?: boolean;
  from_date?: string | null;
  to_date?: string | null;
  template_id?: number;
  template?: string;
  query?: string;
  today?: boolean;
  patient_id?: number | null;
  referrer_id?: number | null;
  dob?: string;
  accessed_by_user_ids?: number[];
  signed_by_user_ids?: number[];
  report_label_ids?: number[];
  sites?: number[];
  exlude_edited_by_doctor?: boolean;
  exclude_injections?: boolean;
  modalities?: string[];
  accession_number?: string;
  status_id?: number | null;
  patient_number?: string;
  scan_code_id?: number;
  topic_title_id?: number;
  referrer_provider_number?: string;
};

declare interface StatementText {
  sentence_text: string;
}

export type AddedTopic = {
  included_key_findings: boolean;
  topic_id: number;
};

export type NextReportResponse = {
  reports: RR.Report[];
  topics: RR.Topic[];
  patients: RR.Patient[];
  next_report_id: number;
  next_report_same_referrer_id: number;
  next_report_same_scan_code_id: number;
  next_report_xr_id: number;
  next_report_mr_id: number;
  next_report_ct_id: number;
  next_report_us_id: number;
};

export type NextReportBody = {
  report_id: number;
  report_statuses: number[];
};

type CheckReportResult = {
  id: number;
  topics: CheckTopicResult[];
};

type CheckTopicResult = {
  id: number;
  missing_title: boolean;
  missing_signature: boolean;
  unfilled_number: boolean;
  incorrect_side: boolean;
  incorrect_sex: boolean;
};

export type AvailableForm = {
  name: string;
  url: string;
};

type AvailableFormsResponse = {
  forms: AvailableForm[];
};

@Injectable()
export class ReportHttpService {
  constructor(private http: HttpClient) {}

  find(reportId: number) {
    return this.http.get<{
      report: RR.Report;
      topics: RR.Topic[];
      templates: RR.Template[];
      patient: RR.Patient | null;
      referrer: RR.Referrer | null;
      access_events: RR.ReportAccessEvent[];
      send_events: RR.SendEvent[];
      todos: RR.Todo[];
      audit_events: RR.AuditEvent[];
      users: RR.User[];
      voice_notes: RR.VoiceNote[];
      urgent_notes: RR.UrgentNotes[];
      scan_code: RR.ScanCode | null;
      billing_items: RR.BillingItem[];
      followup_tasks: RR.FollowupTask[];
      referrer_contact_methods: RR.ReferrerContactMethod[];
    }>(`/api/report/${reportId}`);
  }

  findReportText(reportId: number) {
    return this.http.get<RR.Report & { text: string }>(`/api/report/${reportId}/text`);
  }

  findStatementChoiceText(statement_choice_id: number): Observable<{ sentence_text: string }> {
    const params = new HttpParams().set('statement_choice_id', statement_choice_id);

    return this.http.get<{ sentence_text: string }>('/api/statement_choice_text', { params });
  }

  findDividerText(statement_id: number): Observable<{ sentence_text: string }> {
    const params = new HttpParams().set('statement_id', statement_id);

    return this.http.get<{ sentence_text: string }>('/api/statement_text', { params });
  }

  findStatementTextWithAttributes(statement_id: number): Observable<StatementText> {
    const params = new HttpParams().set('statement_id', statement_id);

    return this.http.get<StatementText>('/api/statement_text_with_attributes', { params });
  }

  create(report: Partial<RR.Report>) {
    return this.http.post<RR.Report>('/api/report/form', report);
  }

  createTestReport() {
    return this.http.post<RR.Report>('/api/report/test', {});
  }

  findRelatedReports(report_id: number, topic_id: number) {
    return this.http.get<{
      added_topics: AddedTopic[];
      reports: RR.Report[];
      topics: RR.Topic[];
      templates: RR.Template[];
      bookings: RR.Booking[];
      documents: RR.Document[];
    }>(`/api/report/${report_id}/topic/${topic_id}/related_reports`);
  }

  update(reportId: number, changes: Partial<RR.Report>) {
    return this.http.put<{
      report: RR.Report;
      referrer: RR.Referrer | null;
      patient: RR.Patient | null;
      booking: RR.Booking | null;
    }>(`/api/report/${reportId}`, changes);
  }

  updateAccession(reportId: number, accessionNumber: string) {
    return this.http.put<RR.Report>(`/api/report/${reportId}/accession_number`, {
      accession_number: accessionNumber,
    });
  }

  /**
   * Find minimal report is only used for checking permission and navigation to default topic in the report. There is no
   * effect because we don't to add the entities to the store. Also those entities are unused and will be clear by the
   * EditorActions.open action
   */
  findMinimal(reportId: number) {
    return this.http.get<Pick<RR.Report, 'id' | 'topic_ids'>>(`/api/report/${reportId}/minimal`);
  }

  search(queryBody: ReportSearchBody, params: HttpParams) {
    return this.http.post<{
      count: number;
      reports: RR.Report[];
      topics: RR.Topic[];
      patients: RR.Patient[];
      referrers: RR.Referrer[];
      scan_codes: RR.ScanCode[];
      billing_items: RR.BillingItem[];
      bookings: RR.Booking[];
      todos: RR.Todo[];
      voice_notes: RR.VoiceNote[];
      access_events: RR.ReportAccessEvent[];
      urgent_notes: RR.UrgentNotes[];
      send_events: RR.SendEvent[];
      invoices: RR.Invoice[];
      audit_events: RR.AuditEvent[];
      users: RR.User[];
      payments: RR.Payment[];
      followup_tasks: RR.FollowupTask[];
      referrer_contact_methods: RR.ReferrerContactMethod[];
    }>('/api/report_search', queryBody, {
      params,
    });
  }

  patientSearch(patient_id: number, date?: string) {
    const params = date ? { date } : undefined;
    return this.http.get<{ reports: RR.Report[]; topics: RR.Topic[]; scan_codes: RR.ScanCode[] }>(
      `/api/patient/${patient_id}/reports`,
      { params: params },
    );
  }

  sendToVoyager(reportId: number) {
    return this.http.post<RR.Report>('/api/send_to_voyager', {
      report_id: reportId,
    });
  }

  findPatientQuestions(reportId: number) {
    return this.http.get<PatientQuestionResponse>(`/api/report/${reportId}/patient_questions`);
  }

  extractImageFeatures(reportId: number) {
    return this.http.post<ReportExtractFeaturesResult>(`/api/ml/report/${reportId}/features`, {});
  }

  getImgSimWeights(templateId: number): Observable<{ weights: ImgSimWeight[] }> {
    return this.http.get<{ weights: ImgSimWeight[] }>(`/api/ml/template/${templateId}/weights`);
  }

  updateImgSimWeight(weightId: number, data: Partial<ImgSimWeight>): Observable<ImgSimWeight> {
    return this.http.put<ImgSimWeight>(`/api/ml/weights/${weightId}`, data);
  }

  getImageUrls(reportId: number) {
    return this.http.get<RR.Report>(`/api/report/${reportId}/images`);
  }

  delete(reportId: number, password: string) {
    return this.http.delete<RR.Report>(`/api/report/${reportId}`, { body: { password } });
  }

  checkReport(reportId: number) {
    return this.http.post<CheckReportResult>(`/api/report/${reportId}/check`, {});
  }

  restoreDeletedReport(reportId: number) {
    return this.http.post<RR.Report>(`/api/restore_report/${reportId}`, {});
  }

  nextReport(data: NextReportBody): Observable<NextReportResponse> {
    return this.http.post<NextReportResponse>(`/api/report/next`, data);
  }

  availableForms(reportId: number) {
    return this.http.get<AvailableFormsResponse>(`/api/form/available_forms/${reportId}`);
  }
}
