import { createActionGroup, props } from '@ngrx/store';

/**
 * Ngrx Actions for SectionChoice
 *
 */
export const SectionChoiceActions = createActionGroup({
  source: 'Section Choice',
  events: {
    addMany: props<{ sectionChoices: RR.SectionChoice[] }>(),
    upsertMany: props<{ sectionChoices: RR.SectionChoice[] }>(),
    removeMany: props<{ sectionChoiceIds: number[] }>(),
  },
});
