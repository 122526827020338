import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChangeTrackerResponse } from '../report/report-change-tracker.action';

@Injectable()
export class SectionChoiceHttpService {
  constructor(private http: HttpClient) {}

  delete(sectionChoiceId: number) {
    return this.http.delete<ChangeTrackerResponse>(`/api/section_choice/${sectionChoiceId}`);
  }
}
