import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { institutionTypeMap } from 'app/modules/invoice/invoice.constants';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-institution-form-view',
  templateUrl: './institution-form-view.component.html',
  styleUrls: ['./institution-form-view.component.css'],
})
export class InstitutionFormViewComponent {
  @Input() institution: RR.Institution | undefined;
  @Input() listingPage?: boolean;

  institutionTypeMap = institutionTypeMap;

  // constructor() {}
}
