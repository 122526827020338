<div class="input-group">
  <button *ngIf="searchText" class="btn btn-outline-secondary" type="button" (click)="clearField()">
    <i class="fas fa-times"></i>
  </button>
  <input
    rrVoice
    [source]="'GLOBAL_SEARCH'"
    type="search"
    placeholder="Search groups"
    class="form-control"
    [formControl]="searchText"
    rrAutoFocus
    rrAbbr
  />
</div>
