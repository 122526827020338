<ng-container>
  <rr-prefill-search-metadata
    [topic_id]="topic_id"
    [report_id]="report_id"
    (onResultsChanged)="onMetadataResultsChanged($event)"
    (onPresetsChanged)="onPresetResultsChanged($event)"
    class="d-flex flex-column flex-grow-1 flex-shrink-0"
  ></rr-prefill-search-metadata>
  <rr-prefill-search-report-sentences
    [topic_id]="topic_id"
    class="d-flex flex-column"
    [class.flex-grow-1]="showExactMatch$ | async"
  ></rr-prefill-search-report-sentences>
</ng-container>
