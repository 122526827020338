import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Feedback>();
const selectFeature = (state: AppState) => state.feedback;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectUserFeedbacks = (userId: number) =>
  createSelector(selectAll, (feedbacks: RR.Feedback[]) =>
    feedbacks
      .filter((feedback) => feedback.recipient_id === userId)
      .sort((fd1, fd2) => new Date(fd1.created).getTime() - new Date(fd2.created).getTime()),
  );

const selectUserFeedbacksInReport = (userId: number, reportId: number) =>
  createSelector(selectAll, (feedbacks) =>
    feedbacks
      .filter((feedback) => feedback.recipient_id === userId && feedback.report_id === reportId)
      .sort((fd1, fd2) => new Date(fd1.created).getTime() - new Date(fd2.created).getTime()),
  );

const selectFeedback = (feedbackId: number) => createSelector(selectEntities, (feedbacks) => feedbacks[feedbackId]);

export const fromFeedback = {
  selectIds,
  selectEntities,
  selectAll,
  selectUserFeedbacks,
  selectUserFeedbacksInReport,
  selectFeedback,
};
