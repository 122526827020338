import { Pipe, PipeTransform } from '@angular/core';
import { getPatientDOB } from 'app/app.utils';

@Pipe({
  standalone: true,
  name: 'patientDOB',
})
export class PatientDOBPipe implements PipeTransform {
  transform(patient: RR.Patient) {
    return getPatientDOB(patient);
  }
}
