import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { IncludesPipe } from 'app/shared/pipes/includes.pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromLabel } from 'app/store/report/label/label.selector';
import { fromReport } from 'app/store/report/report';
import { Observable, Subscription, map, switchMap } from 'rxjs';

import { LabelsComponent } from '../labels/labels.component';

@Component({
  selector: 'rr-label-select',
  standalone: true,
  templateUrl: './label-select.component.html',
  styleUrls: ['./label-select.component.css'],
  imports: [CommonModule, LabelsComponent, SharedModule, NgbModule, ReactiveFormsModule, IncludesPipe],
})
export class LabelSelectComponent implements OnInit, OnDestroy {
  @ViewChild('popoverLabel') popoverLabel: NgbPopover;

  @Input() viewMode: 'filter' | 'create';
  @Input() @BindObservable() reportIds: number[];
  reportIds$: Observable<number[]>;

  @Input() @BindObservable() initialLabels: number[];
  initialLabels$: Observable<number[]>;

  @Output() onSelect = new EventEmitter<number[]>();

  labels$: Observable<RR.Label[]>;

  selectedLabelIds: number[] = [];

  subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.labels$ = this.store.select(fromLabel.selectAll);

    if (this.viewMode === 'filter') {
      this.subscription.add(
        this.initialLabels$.subscribe((labelIds) => {
          this.selectedLabelIds = labelIds;
        }),
      );
    }

    this.subscription.add(
      this.reportIds$
        .pipe(
          switchMap((reportIds) => this.store.select(fromReport.selectManyReports(reportIds))),
          map((reports) => reports.filter((report): report is RR.Report => report !== undefined)),
          map((reports) => reports.flatMap((report) => report.label_ids)),
          map((labelIds) => [...new Set(labelIds)]),
        )
        .subscribe((selectedLabels) => {
          this.selectedLabelIds = selectedLabels;
        }),
    );
  }

  onSelectLabelIds(event: number[]) {
    this.onSelect.emit(event);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
