import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EditorActions } from 'app/store/editor/editor.action';

import { TopicActions, TopicBatchActions } from './topic.action';

export interface TopicEntityState extends EntityState<RR.Topic> {
  loaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.Topic>();
const initialState = adapter.getInitialState({
  loaded: {},
});

export const topicReducer = createReducer(
  initialState,
  on(TopicBatchActions.findRefTopicSuccess, (state, { topicId, actions }) => {
    const { findTopicsSuccess } = actions;
    return adapter.updateOne(
      {
        id: topicId,
        changes: {
          ref_topics: findTopicsSuccess.topics.map((t) => t.id),
        },
      },
      state,
    );
  }),
  on(TopicBatchActions.findTopicSuccess, (state, action) => {
    // TODO(report-schema): don't use TopicBatchActions here?
    const topic = action.actions.findTopicSuccess.topic;
    return {
      ...state,
      loaded: { ...state.loaded, [topic.id]: true },
    };
  }),
  on(TopicActions.addOne, (state, { topic }) => adapter.addOne(topic, state)),
  on(TopicActions.addMany, (state, { topics }) => adapter.addMany(topics, state)),
  // Using upsert for find and find many success in case the topic was loaded as related topic of a report, then it was
  // fetched again for prefill
  on(TopicActions.upsertOne, (state, { topic }) => adapter.upsertOne(topic, state)),
  on(TopicActions.upsertMany, (state, { topics }) => adapter.upsertMany(topics, state)),
  on(TopicActions.removeMany, (state, { topicIds }) => adapter.removeMany(topicIds, state)),
  on(EditorActions.open, EditorActions.close, () => initialState),
);
