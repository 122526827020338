import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { INDEX_SCROLL_ID } from 'app/app.constants';
import { filterDefined, isTextInput, strToNum } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { SocketService } from 'app/core/services/socket.service';
import { EditorNavbarComponent } from 'app/core/toolbar-navbar/components/editor-navbar/editor-navbar.component';
import { NavbarComponent } from 'app/core/toolbar-navbar/components/toolbar-navbar/toolbar-navbar.component';
import { AdminService } from 'app/modules/admin/services/admin.service';
import { SignatureModalComponent } from 'app/modules/report/components/preview/signature-modal/signature-modal.component';
import { PresetFromReportComponent } from 'app/shared/components/preset/preset-from-report/preset-from-report.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { AppState } from 'app/store';
import { fromAppSelector } from 'app/store/app.selector';
import { BookingEffect } from 'app/store/booking';
import { CategoryStatementComboEffect } from 'app/store/category-statement-combo';
import { CategoryEffect } from 'app/store/category/category.effect';
import { CorrelatedSearchEffect } from 'app/store/correlated-statement-search';
import { DicomEffect } from 'app/store/dicom';
import { EditorActions, RightPaneViewModeType } from 'app/store/editor';
import { InvoiceEffect, fromInvoice } from 'app/store/invoice';
import { fromPatient } from 'app/store/patient';
import { ProviderNumberEffect } from 'app/store/provider-number';
import { fromReferrer } from 'app/store/referrer';
import { fromPresetTitle } from 'app/store/report/preset-title';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { ReportStatusEffect } from 'app/store/report/report-status/report-status.effect';
import { TopicEffect, fromCurrentTopic } from 'app/store/report/topic';
import { fromVoiceNote } from 'app/store/report/voice-note';
import { SessionActions, SessionEffect, fromSession } from 'app/store/session';
import { fromSetting } from 'app/store/setting/setting.selector';
import { SexSpecificWordEffect } from 'app/store/sex-specific-words/sex-specific-word.effect';
import { TemplateEffect } from 'app/store/template/template';
import { UserSettingEffect, fromUserSetting } from 'app/store/user/user-setting';
import { Observable, Subscription, connectable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, switchMap, take } from 'rxjs/operators';

import { DueDateComponent } from '../../shared/components/datetime/due-date.component';
import { OpenVoyagerButtonComponent } from '../../shared/components/open-voyager-button/open-voyager-button.component';
import { PatientInfoComponent } from '../../shared/components/patient-info/patient-info.component';
import { PresetTitleComponent } from '../../shared/components/preset/preset-title/preset-title.component';
import { ReferrerNameComponent } from '../../shared/components/referrer-name/referrer-name.component';
import { ReportDateComponent } from '../../shared/components/report-date/report-date.component';
import { ReportNotesButtonComponent } from '../../shared/components/report-notes-button/report-notes-button.component';
import { ZeroFPImagesComponent } from '../../shared/components/zero-fp-images/zero-fp-images.component';
import { DemographicsModalComponent } from '../../shared/modals/demographics-modal/demographics-modal.component';
import { InitialsModalComponent } from '../../shared/modals/initials-modal/initials-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { CheckReportComponent } from '../report/components/check-report/check-report.component';
import { IndexComponent } from '../report/components/index/index.component';
import { ReportPreviewComponent } from '../report/components/preview/report-preview/report-preview.component';
import { AuditListComponent } from './audit/audit-list/audit-list.component';
import { CorrelatedSearchComponent } from './correlated-statement-search/correlated-statement-search.component';
import { EditPaneComponent } from './edit-pane/edit-pane.component';
import { EditorPresetComponent } from './editor-preset/editor-preset.component';
import { EditorWarningModalComponent } from './editor-warning-modal/editor-warning-modal.component';
import { PrefillSearchContainerComponent } from './prefill/prefill-search-container/prefill-search-container.component';
import { PrefillComponent } from './prefill/prefill.component';
import { PrefillService } from './prefill/prefill.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StepService } from './step.service';
import { TemplateChooserComponent } from './template-chooser/template-chooser.component';
import { TimeTrackerComponent } from './time-tracker/time-tracker.component';
import { TopicButtonComponent } from './topic-button/topic-button.component';

const EDITOR_BODY_CLASS = 'rr-editor';

export type EditorExpandedState = 'INDEX' | 'PREFILL';
const USER_INACTIVE_TIMEOUT = 5 * 60 * 1000; // 5 minutes
@Component({
  selector: 'rr-editor',
  templateUrl: 'editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // Every component below EditorComponent gets the same instance of StepService. In ng-bootstrap modals, you must
    // pass in the `Injector`, otherwise you will get a new instance.
    StepService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    EditorNavbarComponent,
    NavbarComponent,
    SidebarComponent,
    IndexComponent,
    PrefillSearchContainerComponent,
    TemplateChooserComponent,
    EditPaneComponent,
    PrefillComponent,
    CdkDropList,
    TopicButtonComponent,
    SharedModule,
    NgbTooltip,
    PatientInfoComponent,
    ReferrerNameComponent,
    TimeTrackerComponent,
    ReportDateComponent,
    DueDateComponent,
    ReportNotesButtonComponent,
    OpenVoyagerButtonComponent,
    ZeroFPImagesComponent,
    ReportPreviewComponent,
    AuditListComponent,
    CorrelatedSearchComponent,
    CheckReportComponent,
    PresetTitleComponent,
    PresetFromReportComponent,
    ReportAccessionNumberComponent,
    EditorPresetComponent,
  ],
})
@LifecycleLogger
export class EditorComponent implements OnInit, OnDestroy {
  report: RR.Report;
  presetFromReport: RR.Report | undefined;
  patient: RR.Patient | undefined;
  referrer: RR.Referrer | undefined;
  subscription = new Subscription();
  proofreadingSubscription = new Subscription();
  kioskState: boolean;
  rightPaneViewMode$: Observable<RightPaneViewModeType>;
  prefill: boolean;
  templateChooser: boolean;
  openTopic: RR.Topic | undefined;
  activeTopics: RR.Topic[];
  proofreading: Worker;
  editMode = false;
  editorExpandedState: EditorExpandedState = 'INDEX';
  INDEX_SCROLL_ID = INDEX_SCROLL_ID;
  disabledDraggingTopic = false;
  currentUser: RR.User;
  wsWarningModel: NgbModalRef | undefined;
  userActiveSubscription: Subscription = new Subscription();
  // Check active/inactive user
  activityTimerID: any;
  userInactive = false;
  // Users who are editing the same report
  editing_users: string[] = [];
  report$: Observable<RR.Report>;
  topic$: Observable<RR.Topic>;
  // Press 'b' to jump back to the original location of the moved statement
  isQuickNavOpen: boolean;

  // Invoice items button status
  hasInvoiceItems$: Observable<boolean>;

  sexSpecificWords: RR.SexSpecificWord[];
  reportId$: Observable<number>;
  topicId$: Observable<number>;
  reportId: number;
  topicId: number;

  constructor(
    // TODO(store): We should probably use the router store for all selections
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private reportService: ReportService,
    private adminService: AdminService,
    private cd: ChangeDetectorRef,
    private sessionEffect: SessionEffect,
    private modal: NgbModal,
    private editorService: EditorService,
    private store: Store<AppState>,
    private hotkeysService: HotkeysService,
    private zone: NgZone,
    private socket: SocketService,
    private templateEffect: TemplateEffect,
    private categoryEffect: CategoryEffect,
    private categoryStatementComboEffect: CategoryStatementComboEffect,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private topicEffect: TopicEffect,
    private dicomEffect: DicomEffect,
    private reportEffect: ReportEffect,
    private userSettingEffect: UserSettingEffect,
    private selectorService: SelectorService,
    private bookingEffect: BookingEffect,
    private providerNumberEffect: ProviderNumberEffect,
    private invoiceEffect: InvoiceEffect,
    private stepService: StepService,
    private sexSpecificWordEffect: SexSpecificWordEffect,
    private reportStatusEffect: ReportStatusEffect,
    private prefillService: PrefillService,
    private correlatedSearchEffect: CorrelatedSearchEffect,
  ) {
    this.rightPaneViewMode$ = this.store.select((s) => s.editor.rightPaneViewMode);
    this.reportId$ = this.route.params.pipe(map((params) => strToNum(params.report_id)));
    this.topicId$ = this.route.params.pipe(map((params) => strToNum(params.topic_id)));
  }

  ngOnInit() {
    document.body.classList.add(EDITOR_BODY_CLASS);
    // Send message to websocket server, notify that this user is editing the report
    this.handleWebsocket();
    // Dispatch editor action to refresh some store data
    this.store.dispatch(EditorActions.open());

    this.subscription.add(
      this.reportId$.subscribe((reportId) => {
        this.reportId = reportId;
      }),
    );

    this.subscription.add(
      this.topicId$.subscribe((topicId) => {
        this.topicId = topicId;
      }),
    );

    const loadReport$ = connectable(
      this.reportId$.pipe(
        switchMap((reportId) => {
          return this.reportEffect.find(reportId);
        }),
        map((action) => action.actions.findReportSuccess.report),
      ),
    );
    // connect() lets us subscribe multiple times without re-executing the observable
    this.subscription.add(loadReport$.connect());

    // When the report is not ready, call the api the fetch the report.
    // refactored to avoid nested subscribe
    this.subscription.add(
      loadReport$
        .pipe(
          switchMap((report) => {
            return this.store.select(fromReport.selectRelatedReportsLoaded(report.id)).pipe(
              filter((loaded) => !loaded),
              map(() => report),
            );
          }),
          switchMap((report) => {
            return this.reportEffect.findRelatedReports(report.id, this.topicId);
          }),
        )
        .subscribe(),
    );

    // To display the template names for other topics
    this.subscription.add(this.templateEffect.findAll().subscribe());

    // To load provider numbers for signature-modal
    this.subscription.add(this.providerNumberEffect.findAll().subscribe());
    // Report Status shown in Demographics
    this.subscription.add(this.reportStatusEffect.findAll().subscribe());

    // To load invoices for the report. They will be used for invoice items status and check report
    this.subscription.add(
      this.reportId$.pipe(switchMap((reportId) => this.invoiceEffect.findInReport(reportId))).subscribe(),
    );
    this.hasInvoiceItems$ = this.reportId$.pipe(
      switchMap((reportId) => this.store.select(fromInvoice.selectInReport(reportId))),
      map((invoices) => !!invoices.find((i) => !i.deleted && !!i.invoice_items.length)),
    );

    this.report$ = this.reportId$.pipe(
      switchMap((reportId) => this.store.select(fromReport.selectReport(reportId))),
      filterDefined(),
    );

    // preview report -- preview components based on report from Redux store
    this.subscription.add(
      this.store.select(fromSetting.selectKiosk).subscribe((b) => {
        this.kioskState = b;
      }),
    );
    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
        this.cd.detectChanges();
      }),
    );

    // Used below when loading things related to the report, distinct so we don't reload if just a field changes.
    const distinctReport$ = this.report$.pipe(distinctUntilChanged((a, b) => a.id === b.id));
    this.subscription.add(
      distinctReport$
        .pipe(
          switchMap((report) => {
            if (report.voice_note_ids.length > 0) {
              return this.store.select(fromVoiceNote.selectInReport(report.id)).pipe(
                filter((voiceNotes) => !!voiceNotes.length),
                take(1),
              );
            } else {
              return of([]);
            }
          }),
        )
        .subscribe((voiceNotes) => {
          if (voiceNotes.length) {
            const voiceResults = voiceNotes.map((voiceNote) => ({
              term: voiceNote.text,
              note_type: voiceNote.note_type,
              subsection: voiceNote.subsection,
            }));

            this.store.dispatch(EditorActions.addVoiceNotes({ voiceResults }));
          }
        }),
    );

    this.subscription.add(
      distinctReport$.pipe(switchMap((report) => this.bookingEffect.find(report.booking_id))).subscribe(),
    );

    this.subscription.add(
      this.store
        .select(fromSession.selectRRConfig)
        .pipe(
          filterDefined(),
          take(1),
          filter((rrConfig) => rrConfig.DICOM),
          switchMap(() => distinctReport$),
          // TODO: `report.has_dicom_sr_cached` isn't initialised from server yet. If it was queryDicom() could be skipped.
          // filter((report) => !report.has_dicom_sr_cached)
          switchMap((report) => this.dicomEffect.queryDicom(report.id)),
        )
        .subscribe(),
    );

    const activeTopics$ = this.reportId$.pipe(switchMap((reportId) => this.reportService.getActiveTopics(reportId)));
    this.subscription.add(
      activeTopics$.subscribe((activeTopics) => {
        this.activeTopics = activeTopics;
      }),
    );

    this.subscription.add(
      this.report$
        .pipe(
          filter((report) => Boolean(report.type === 'preset' && report.preset_title_id)),
          switchMap((report) => this.store.select(fromPresetTitle.selectPresetTitle(report.preset_title_id))),
        )
        .subscribe((presetTitle) => {
          if (presetTitle) {
            const title = `Preset: ${presetTitle.text}`;
            this.title.setTitle(`${title} - RadReport`);
          }
        }),
    );

    const patient$ = this.report$.pipe(
      switchMap((report) =>
        report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
      ),
    );
    this.subscription.add(
      patient$.subscribe((patient) => {
        this.patient = patient;
      }),
    );

    this.subscription.add(
      this.report$
        .pipe(
          filter((report) => report.type === 'default'),
          switchMap((report) => patient$.pipe(map((patient) => ({ report, patient }) as const))),
        )
        .subscribe(({ report, patient }) => {
          this.title.setTitle(this.getDocumentTitleForReport(report, patient));
        }),
    );

    this.subscription.add(
      this.report$
        .pipe(
          switchMap((report) =>
            report.referrer_id ? this.store.select(fromReferrer.selectReferrer(report.referrer_id)) : of(undefined),
          ),
        )
        .subscribe((referrer) => {
          this.referrer = referrer;
        }),
    );

    const presetFromReport$ = this.route.queryParams.pipe(
      map((params) => params['presetFromReportId']),
      filter((reportId) => !!reportId),
      switchMap((reportId) => this.reportEffect.find(reportId)),
      map((action) => action.actions.findReportSuccess.report),
    );

    // Presets: open the report we came from in prefill so we can choose statements to copy
    this.subscription.add(
      distinctReport$
        .pipe(
          filter((report) => report.type === 'preset'),
          switchMap(() => presetFromReport$.pipe(take(1))),
        )
        .subscribe((presetFromReport) => {
          this.presetFromReport = presetFromReport;
          this.openPrefill(presetFromReport);
        }),
    );

    // Process the fromLogin query param
    this.subscription.add(
      distinctReport$
        .pipe(
          switchMap((report) => this.route.queryParams.pipe(map((params) => ({ report, params })))),
          take(1),
        )
        .subscribe(({ report, params }) => {
          if (params['fromLogin']) {
            this.accessReportAsCurrentUser(report);
            this.stepService.completeStep('initials');
          } else {
            this.stepService.nag();
          }
          // Clear fromLogin now that it has been processed
          this.router.navigate([], {
            queryParams: {
              fromLogin: null,
            },
            queryParamsHandling: 'merge',
          });
        }),
    );

    this.subscription.add(
      distinctReport$
        .pipe(
          // Don't subscribe until the report is loaded, otherwise accessReport() will error in e2es
          switchMap((report) => this.stepService.nag$.pipe(map((stepId) => ({ report, stepId })))),
          filter(({ stepId }) => stepId === 'initials'),
        )
        .subscribe(({ report }) => {
          // Calling in setTimeout prevents ExpressionChangedAfterItHasBeenCheckedError
          setTimeout(() => this.showInitialsModal(report));
        }),
    );

    if (typeof Worker !== 'undefined') {
      // Create a new
      const worker = new Worker(new URL('app/core/workers/proofreading.worker', import.meta.url));
      this.proofreading = worker;
    } else {
      // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
      console.error('Browser does not support Webworkers, cannot proofread');
    }

    this.subscription.add(
      this.sexSpecificWordEffect.findAll().subscribe((words) => {
        this.sexSpecificWords = words.sex_specific_words;
      }),
    );

    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());

    const topicLoadedTopic$ = this.selectorService.selectCurrentTopicIfLoaded().pipe(filterDefined());

    // TODO(template-structure): This should be an effect on the change on
    // topic in the router store. It is possible to reach invalid values,
    // however without changing to an effect this is not easy to solve.

    // Only called when changing topics
    const distinctTopic$ = this.topic$.pipe(distinctUntilChanged((a, b) => a.id === b.id));

    this.subscription.add(
      distinctTopic$.pipe(switchMap((openTopic) => this.topicEffect.openTopic(openTopic.id))).subscribe(),
    );

    this.subscription.add(
      distinctTopic$
        .pipe(
          switchMap((openTopic) =>
            this.reportService.selectKioskUser().pipe(
              filterDefined(),
              take(1),
              map((user) => {
                // When you open a new topic: show the modal again if the tech hasn't signed.
                this.showTechnicianSignatureModal(openTopic, user);
              }),
            ),
          ),
        )
        .subscribe(),
    );

    // Requires the choices to be loaded
    this.subscription.add(
      topicLoadedTopic$.pipe(distinctUntilChanged((a, b) => a.id === b.id)).subscribe((openTopic) => {
        this.configureProofreading(openTopic.id, openTopic.report_id);
      }),
    );

    this.subscription.add(
      this.topic$.subscribe((openTopic) => {
        this.openTopic = openTopic;
        this.cd.detectChanges();
      }),
    );
    this.subscription.add(
      this.editorService.prefill.subscribe((prefill) => {
        this.editorExpandedState = prefill ? 'PREFILL' : 'INDEX';
        this.cd.detectChanges();
      }),
    );
    this.subscription.add(
      this.editorService.templateChooser.subscribe((templateChooser) => {
        this.templateChooser = templateChooser;
        this.cd.detectChanges();
      }),
    );
    this.subscription.add(
      this.editorService.editMode.subscribe((editMode) => {
        this.editMode = editMode;
        this.cd.detectChanges();
      }),
    );

    this.zone.runOutsideAngular(() => {
      // Run outside angular for performance reasons
      // Before removing: check you can hold a key down in a textbox without the UI locking
      document.addEventListener('keydown', this.onKeydown);
    });

    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: 'b' }).subscribe(() => {
        if (this.openTopic) {
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          this.editorService.getAdjacentStatement(this.openTopic.id).subscribe((statement) => {
            this.editorService.publishFocus({
              // @ts-expect-error strictNullChecks
              statement_id: statement?.id,
              // @ts-expect-error strictNullChecks
              element_id: statement?.element_id,
              // @ts-expect-error strictNullChecks
              region_id: statement?.region_id,
            });
          });
        }
      }),
    );

    this.subscription.add(this.correlatedSearchEffect.statisticalStatementsPrediction(this.topicId).subscribe());
  }

  // Setup event listener to check whether user is active or inactive
  setupEventListener() {
    this.zone.runOutsideAngular(() => {
      this.userActiveSubscription = this.editorService.addUserActiveCheckListener(this.resetTimer);
    });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  resetTimer = () => {
    clearTimeout(this.activityTimerID);
    // Check if change state from inactive -> active
    if (this.userInactive) {
      this.userInactive = false;
      // Send ACTIVE message to websocket server
      this.socket.send({
        type: 'OPEN_REPORT',
        report_id: this.reportId,
        user_id: this.currentUser.id,
        user_name: this.currentUser.name,
      });
    }
    this.startTimer();
  };

  startTimer() {
    // If user do nothing for 15 minutes => change to inactive
    this.activityTimerID = setTimeout(() => {
      // User becomes inactive
      this.userInactive = true;
      // Send INACTIVE message to websocket server
      this.socket.send({
        type: 'USER_INACTIVE',
        report_id: this.reportId,
        user_id: this.currentUser.id,
        user_name: this.currentUser.name,
      });
    }, USER_INACTIVE_TIMEOUT);
  }

  // Handle websocket event
  handleWebsocket() {
    this.subscription.add(
      this.reportId$
        .pipe(
          startWith(null),
          // pairwise doesn't emit until there are 2 values
          pairwise(),
          switchMap(([reportId1, reportId2]) => {
            return this.reportService.selectKioskUser().pipe(
              filterDefined(),
              take(1),
              map((user) => ({ reportId1, reportId2, user })),
            );
          }),
        )
        .subscribe(({ reportId1, reportId2, user }) => {
          this.currentUser = user;
          if (reportId1 !== null) {
            // Close report1 before opening report2
            this.socket.send({
              type: 'CLOSE_REPORT',
              report_id: reportId1,
              user_id: user.id,
              user_name: user.name,
            });
          }
          if (reportId2 === null) {
            // There should always be a reportId2 (current report)
            throw new Error('reportId cannot be null');
          }
          this.socket.send({
            type: 'OPEN_REPORT',
            report_id: reportId2,
            user_id: user.id,
            user_name: user.name,
          });

          // Register event listener to check active/inactive user
          this.setupEventListener();
          this.startTimer();
        }),
    );

    this.subscription.add(
      this.socket.messageReceivedEvent$.subscribe((data) => {
        if (data.type === 'REPORT_BLOCKED') {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- is defined for REPORT_BLOCKED
          this.editing_users = data.editing!;
          setTimeout(() => {
            if (this.wsWarningModel) {
              // don't open 2 modals
              this.wsWarningModel.close();
            }
            this.wsWarningModel = this.modal.open(EditorWarningModalComponent, {
              backdrop: 'static',
            });
            this.wsWarningModel.componentInstance.editing = data.editing;
            this.wsWarningModel.componentInstance.modal_type = 'REPORT_BLOCK_UNBLOCK';
            this.wsWarningModel.result.then(
              (back) => {
                // Go back button
                if (back) {
                  // Close report
                  this.router.navigate(['start']);
                  this.wsWarningModel = undefined;
                }
              },
              () => {
                // Click yes, or click outside
                this.socket.send({
                  type: 'CONTINUE_REPORT',
                  report_id: this.reportId,
                  user_id: this.currentUser.id,
                  user_name: this.currentUser.name,
                });
                this.wsWarningModel = undefined;
              },
            );
          });
        } else if (data.type === 'REPORT_UNBLOCKED') {
          if (this.wsWarningModel) this.wsWarningModel.componentInstance.editing = [];
        } else if (data.type === 'REPORT_EDITING_USERS_UPDATE') {
          if (this.wsWarningModel) this.wsWarningModel.componentInstance.editing = data.editing;
          // List of editing users changed
          if (data.editing && !this.wsWarningModel && !this.userInactive) {
            if (this.editing_users.length < data.editing.length) {
              const namesDiff = data.editing.filter(
                (u: string) => !this.editing_users.includes(u) && u !== this.currentUser.name,
              );
              if (namesDiff.length > 0) {
                ConfirmMessageModalComponent.open({
                  modalService: this.modal,
                  header: 'Alert',
                  message: `${namesDiff.join(', ')} has opened this report and started editing.`,
                });
              }
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- is defined for REPORT_EDITING_USERS_UPDATE
          this.editing_users = data.editing!;
          this.cd.detectChanges();
        }
      }),
    );
  }

  getEditingUsers() {
    return this.editing_users.join(', ');
  }

  openPatientInformation() {
    if (this.openTopic) {
      DemographicsModalComponent.open(this.modal, this.report, this.openTopic, 'EDITOR');
    }
  }

  compressViewClick($event: any) {
    if ($event.target.closest('[data-no-bubble]')) {
      return;
    }
    this.openPatientInformation();
  }

  /**
   * Check to show signature modal if user is clerical/junior/sono/rad and topic doesn't have technician signature
   * @param openTopic
   * @param user
   */
  showTechnicianSignatureModal(openTopic: RR.Topic, user: RR.User) {
    // If topic already has technician signature, or editing user is not clerical, junior, sono/rad, we don't need to show modal
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!openTopic || !user) return;
    if (openTopic.technician_signature_text) return;
    if (
      !this.adminService.includesRole(
        ['clerical', 'junior_radiographer', 'junior_sonographer', 'sonographer', 'radiographer', 'imaging_technician'],
        user.company_roles,
      )
    ) {
      return;
    }

    // Show technician signature modal here
    SignatureModalComponent.open({
      modal: this.modal,
      report_id: openTopic.report_id,
      topic_id: openTopic.id,
      signature_role: 'TECHNICIAN',
    });
  }

  // @ts-expect-error noImplicitReturns
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onKeydown = (event) => {
    // key S and target is body or alt+s
    if (event.code === 'KeyS' && (!isTextInput(event.target) || event.altKey)) {
      // Allow alt key to use the shortcut if you're focussed in a textarea
      return false;
    }
    if (event.code === 'KeyE' && !isTextInput(event.target)) {
      this.sessionEffect
        .authorise({ only: ['set_configuration'] })
        .pipe(
          take(1),
          filter((a) => a),
        )
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe(() => {
          this.editorService.toggleEditMode();
        });
      return false;
    }
  };

  configureProofreading(topic_id: number, report_id: number) {
    this.proofreadingSubscription.unsubscribe();
    this.proofreadingSubscription = this.store
      .select(fromAppSelector.selectProofreadableTopic(report_id, topic_id))
      .pipe(
        debounceTime(300),
        filter((t) => {
          if (!t) {
            this.reportService.markChoicesAsErrors({});
          }
          return !!t;
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((t) => {
        this.proofreading.postMessage({ ...t, wordList: this.sexSpecificWords });
      });
    this.proofreading.onmessage = (message) => {
      this.reportService.markChoicesAsErrors(message.data);
    };
  }

  get showIndex() {
    return !this.prefill && !this.templateChooser && this.openTopic;
  }

  ngOnDestroy() {
    document.body.classList.remove(EDITOR_BODY_CLASS);
    document.removeEventListener('keydown', this.onKeydown);
    this.userActiveSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.proofreadingSubscription.unsubscribe();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.proofreading) {
      this.proofreading.terminate();
      // @ts-expect-error strictNullChecks
      delete this.proofreading.onmessage;
    }

    // Send message to websocket server to close report
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.currentUser) {
      this.socket.send({
        type: 'CLOSE_REPORT',
        report_id: this.reportId,
        user_id: this.currentUser.id,
        user_name: this.currentUser.name,
      });
    }

    clearTimeout(this.activityTimerID);
  }

  /**
   * 1. When you just logged in
   * 2. and when "I'm here all day" is ticked
   */
  accessReportAsCurrentUser(report: RR.Report) {
    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(take(1))
        .subscribe((user) => {
          if (user === undefined) {
            throw new Error('user is undefined');
          }
          this.store.dispatch(SessionActions.setKioskUser({ userId: user.id }));
          this.accessReport(report.id, user);
        }),
    );
  }

  accessReport(report_id: number, user: RR.User) {
    this.reportService.createReportAccessEvent(report_id, user.id);

    // Fetch user settings for user who edit the report. If setting already exists in the store, it doesn't dispatch api call
    this.subscription.add(
      this.store
        .select(fromUserSetting.selectUserSettingLoaded(user.id))
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.userSettingEffect.findByUser(user.id);
            }
            return of(null);
          }),
        )
        .subscribe(),
    );

    // Set element filter type from user setting in editor so that the filteringType in store doesn't get reset whenever
    // the top-statement-button-group is initialised
    this.subscription.add(
      this.reportService
        .selectKioskUserSetting()
        .pipe(
          map((userSetting) => userSetting.statement_set_filtering_type),
          distinctUntilChanged(),
        )
        .subscribe((filteringType) => {
          this.editorService.updateElementFilterType(filteringType);
        }),
    );
  }

  showInitialsModal(report: RR.Report) {
    if (this.kioskState) {
      const result = InitialsModalComponent.open({
        modal: this.modal,
        reportId: this.report.id,
        context: 'EDITOR',
      }).result;
      result.then(
        (user) => {
          if (this.kioskState) {
            this.accessReport(report.id, user);
          } else {
            // kioskState changed to false after the modal was submitted
            this.accessReportAsCurrentUser(report);
          }
          this.stepService.completeStep('initials');
          this.stepService.nag();
        },
        () => {
          /* dismissed modal */
          this.stepService.nag();
        },
      );
    } else {
      this.stepService.completeStep('initials');
      this.accessReportAsCurrentUser(report);
      this.stepService.nag();
    }
  }

  setTopicOpen(topic_id: number) {
    if (topic_id === -1) {
      this.togglePrefill(false);
      this.editorService.toggleTemplateChooser(true);
    } else {
      this.editorService.setTopicOpen(topic_id);
    }
  }

  openSignature() {
    if (this.openTopic) {
      SignatureModalComponent.open({
        modal: this.modal,
        report_id: this.report.id,
        topic_id: this.openTopic.id,
        signature_role: 'DOCTOR',
      });
    }
  }

  togglePrefill(on?: boolean) {
    this.editorService.togglePrefill(on);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === -1 || event.currentIndex === -1 || event.previousIndex === event.currentIndex) return;
    const srcIndex = event.previousIndex;
    const desIndex = event.currentIndex;
    moveItemInArray(this.activeTopics, event.previousIndex, event.currentIndex);
    const source_id = this.activeTopics[event.previousIndex].id;
    const des_id = this.activeTopics[event.currentIndex].id;
    this.reportService
      .moveTopic(source_id, des_id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        error: () => {
          moveItemInArray(this.activeTopics, desIndex, srcIndex);
          this.cd.markForCheck();
        },
      });
  }

  toggleSidebar() {
    return this.editorService.toggleSidebar();
  }

  isDoctorEditing() {
    return this.reportService.isDoctorEditing();
  }

  getDocumentTitleForReport(report: RR.Report, patient?: RR.Patient): string {
    let title = '';
    if (report.type !== 'default') {
      throw new Error('Only default reports are supported');
    }
    if (!patient || (!patient.patient_first_name && patient.patient_last_name)) {
      title = report.accession_number ? `Accession: ${report.accession_number}` : '';
    } else {
      title = patient.patient_first_name + ' ' + patient.patient_last_name;
      if (report.accession_number) {
        title += ' - ' + report.accession_number;
      }
    }
    return `${title} - RadReport`;
  }

  toggleRightPane(type: RightPaneViewModeType) {
    this.editorService.toggleRightPaneViewMode(type);
  }

  copyText(toCopy: any) {
    this.clipboard.copy(toCopy);
    this.messageService.add({
      type: 'success',
      title: 'Success',
      message: 'Copied accession number to clipboard',
      timeout: 2000,
    });
  }

  openPrefill(report: RR.Report) {
    if (report.topic_ids.length === 0) {
      throw new Error('report has no topics');
    }
    this.prefillService.setPrefillPreviewTopics({
      openTopicId: this.topicId,
      topicIds: [report.topic_ids[0].toString()],
      forceReload: true,
    });
    this.togglePrefill(true);
  }
}
