import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class SectionHttpService {
  constructor(private http: HttpClient) {}

  findInTemplate(templateId: number) {
    return this.http.get<RR.Section[]>(`/api/template/${templateId}/section`);
  }
}
