import { createActionGroup, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { Parameter, ProposedDataset } from './imgsim-parameters.service';

export const ImgsimParameterActions = createActionGroup({
  source: 'Imgsim Parameter',
  events: {
    addMany: props<{ parameters: Parameter[] }>(),
    upsertOne: props<{ parameter: Parameter }>(),
  },
});

const findProposedSuccess = createBatchAction(
  '[Topic] Find Proposed Success Batch',
  props<{
    datasets: ProposedDataset[];
    title: string;
    images: string[] | undefined;
    actions: {
      findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
    };
  }>(),
);

export const ImgsimParamsActions = {
  findProposedSuccess,
};
