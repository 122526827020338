import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface UserHttpResponse {
  users: RR.User[];
  signatures: RR.Signature[];
  sites: RR.Site[];
}

export interface Break {
  id: number;
  start: Date;
  end: Date;
}

export interface UserRoster {
  user: RR.User;
  site: RR.Site;
  end_time: Date;
  start_time: Date;
  breaks: Break[];
  modalities: string[];
}

export interface UserRosterResponse {
  [key: number]: UserRoster;
}

@Injectable()
export class UserHttpService {
  constructor(private http: HttpClient) {}

  findAll(params?: HttpParams) {
    return this.http.get<{ users: RR.User[]; booking_rates: RR.BookingRate[] }>('/api/user', { params });
  }

  findById(id: number) {
    return this.http.get<{ user: RR.User; booking_rates: RR.BookingRate[] }>(`/api/user/${id}`);
  }

  update(
    userId: number,
    changes: Partial<RR.User> & { deactivate?: boolean; admin_password?: string },
  ): Observable<RR.User> {
    return this.http.put<RR.User>(`/api/user/${userId}`, changes);
  }

  findByRoles(roles: string[]): Observable<UserHttpResponse> {
    return this.http.get<UserHttpResponse>('/api/get_users_with_roles', {
      params: {
        roles,
      },
    });
  }

  findAllByRoster(when: string): Observable<UserRosterResponse> {
    return this.http.post<UserRosterResponse>(`/api/rostered_users`, { when });
  }
}
