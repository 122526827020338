import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PatientSearchFilterForm } from 'app/modules/entity-listing/components/patient-listing/patient-listing-search/patient-listing-search.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type ESPatientSearchResult = {
  patients: RR.Patient[];
};

export interface ESPatientBody extends Partial<RR.Patient> {
  patient_full_name?: string;
}

export type MedipassValidationResponse = {
  status_code: number;
  json: RR.MedipassValidation | { errors: string[]; errorCode: number; message: string };
  patient: Omit<RR.Patient, 'id'> & { id: null };
};

export type PatientSearchResponse = {
  count: number;
  patients: RR.Patient[];
  patient_notes: RR.PatientNote[];
  patient_note_categories: RR.NoteCategory[];
};

export type PatientGetResponse = {
  patient: RR.Patient;
  patient_notes: RR.PatientNote[];
  patient_note_categories: RR.NoteCategory[];
};

export type PatientDuplicate = {
  reason: string;
  patients: RR.Patient[];
};

@Injectable()
export class PatientHttpService {
  constructor(private http: HttpClient) {}

  searchPatients(
    queryBody: PatientSearchFilterForm,
    withDeleted: boolean,
    onlyDeleted: boolean,
    params: HttpParams,
  ): Observable<PatientSearchResponse> {
    params = params.set('with_deleted', String(withDeleted)).set('only_deleted', String(onlyDeleted));
    return this.http
      .post<PatientSearchResponse>('/api/patient/search', queryBody, {
        params,
      })
      .pipe(
        map((response: PatientSearchResponse) => {
          return {
            count: response.count,
            patients: response.patients,
            patient_notes: response.patient_notes,
            patient_note_categories: response.patient_note_categories,
          };
        }),
      );
  }

  findAll(with_deleted = false, queryBody: any): Observable<PatientSearchResponse[]> {
    const params = new HttpParams().set('paginated', false).set('with_deleted', with_deleted);
    return this.http.post<PatientSearchResponse[]>('/api/patient/search', queryBody, {
      params,
    });
  }

  findById(patientId: number): Observable<PatientGetResponse> {
    return this.http.get<PatientGetResponse>(`/api/patient/${patientId}`);
  }

  create(patient: Partial<RR.Patient>): Observable<RR.Patient> {
    return this.http.post<RR.Patient>('/api/patient', patient);
  }

  update(patientId: number, changes: Partial<RR.Patient>): Observable<RR.Patient> {
    return this.http.put<RR.Patient>(`/api/patient/${patientId}`, changes);
  }

  delete(patientId: number) {
    return this.http.delete(`/api/patient/${patientId}`);
  }

  elasticSearch(body: ESPatientBody) {
    return this.http.post<ESPatientSearchResult>('/api/patient/elasticsearch', body);
  }

  validateMedicare(patient: Partial<RR.Patient>) {
    return this.http.post<MedipassValidationResponse>('/api/patient/medicare_validate', patient);
  }

  findDuplicatePatient(patient: Partial<RR.Patient>) {
    return this.http.post<PatientDuplicate>('/api/patient/duplicate', patient);
  }

  restoreDeletedPatient(patientId: number) {
    return this.http.post<RR.Patient>(`/api/restore_patient/${patientId}`, {});
  }

  findDuplicatePatientById(patientId: number) {
    return this.http.get<PatientDuplicate>(`/api/patient/${patientId}/duplicate`);
  }
}
