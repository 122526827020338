<div class="modal-header">
  <h5 class="modal-title">Global Statements</h5>
  <div class="ms-auto">
    <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
  </div>
</div>

<div class="modal-body" [id]="SCROLL_ID">
  <nav class="mb-2">
    <ol class="breadcrumb text-info">
      <li *ngIf="section" class="breadcrumb-item">
        {{ section.title | uppercase }}
      </li>
      <li *ngIf="subsection" class="breadcrumb-item">
        {{ subsection.name ? subsection.name : 'NO NAME' }}
      </li>
      <li *ngIf="region" class="breadcrumb-item">
        {{ region.name }}
      </li>
      <li *ngIf="element" class="breadcrumb-item">
        {{ element.statement_set_id | storeSelectPipe: selectElementName | async }}
      </li>
    </ol>
  </nav>
  <div class="mb-3" *ngFor="let notepad of notepads$ | async">
    <rr-element
      *ngIf="report$ | async as report"
      [report]="report"
      [element]="notepad"
      [createInElement]="element"
      [region]="region"
      [subsection]="subsection"
      [section]="section"
      [parent]="'NOTEPAD'"
    ></rr-element>
  </div>
</div>
<div class="modal-footer">
  <span class="ms-auto px-3 text-muted">Click greyed area to dismiss</span>
</div>
