<ng-container *ngIf="patient$ | async as patient">
  <ng-container *ngIf="patient.title">
    <span [class.fw-bold]="bookingList">
      {{ patient.title }}
    </span>
  </ng-container>
  <ng-container *ngIf="patient.patient_first_name">
    <span [class.fw-bold]="bookingList">
      {{ patient.patient_first_name }}
    </span>
  </ng-container>
  <ng-container *ngIf="patient.patient_last_name">
    <span [class.fw-bold]="bookingList">
      {{ patient.patient_last_name }}
    </span>
  </ng-container>
  <ng-container *ngIf="bookingList">
    <div *ngIf="patient.patient_dob">
      <rr-patient-dob-info [patientId]="patient.id"></rr-patient-dob-info>
    </div>
    <div *ngIf="patient.phone_home || patient.phone_mobile || patient.phone_work">
      {{ patient.phone_mobile || patient.phone_work || patient.phone_home }}
    </div>
  </ng-container>
</ng-container>
