import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { BillingItemActions } from '../billing-item/billing-item.action';
import { ScanCodeActions } from '../scan-code/scan-code.action';
import { BillingGroupActions } from './billing-group.action';
import { ScanCodeBillingGroup } from './billing-group.service';
import { BillingGroupHttpService, BillingGroupItemResponse } from './billing-group.service';

@Injectable()
export class BillingGroupEffect {
  constructor(
    private store: Store<AppState>,
    private service: BillingGroupHttpService,
  ) {}

  findAll() {
    return this.service.findAll().pipe(
      map((billingGroups: RR.BillingGroup[]) => BillingGroupActions.findAllSuccess({ billingGroups })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(billingGroup: Partial<RR.BillingGroup>) {
    return this.service.create(billingGroup).pipe(
      map((billingGroup: RR.BillingGroup) => BillingGroupActions.createSuccess({ billingGroup })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(billingGroupId: number, changes: Partial<RR.BillingGroup>) {
    return this.service.update(billingGroupId, changes).pipe(
      map((billingGroup) => BillingGroupActions.updateSuccess({ billingGroup })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(billingGroupId: number) {
    return this.service.delete(billingGroupId).pipe(
      map((billingGroup) => BillingGroupActions.updateSuccess({ billingGroup })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addBillingItem(billingGroupId: number, billingItemId: number) {
    return this.service.addBillingItem(billingGroupId, billingItemId).pipe(
      map((billingGroupItem: BillingGroupItemResponse) =>
        BillingGroupActions.updateBillingGroupItemSuccessBatch({
          actions: {
            updateBillingGroupSuccess: BillingGroupActions.updateSuccess({
              billingGroup: billingGroupItem.billing_group,
            }),
            updateBillingItemSuccess: BillingItemActions.updateSuccess({
              billingItem: billingGroupItem.billing_item,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteBillingItem(billingGroupId: number, billingItemId: number) {
    return this.service.deleteBillingItem(billingGroupId, billingItemId).pipe(
      map((billingGroupItem: BillingGroupItemResponse) =>
        BillingGroupActions.updateBillingGroupItemSuccessBatch({
          actions: {
            updateBillingGroupSuccess: BillingGroupActions.updateSuccess({
              billingGroup: billingGroupItem.billing_group,
            }),
            updateBillingItemSuccess: BillingItemActions.updateSuccess({
              billingItem: billingGroupItem.billing_item,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  addToScanCode(billingGroupId: number, scanCodeId: number) {
    return this.service.addToScanCode(billingGroupId, scanCodeId).pipe(
      map((scanCodeBillingGroup: ScanCodeBillingGroup) =>
        BillingGroupActions.updateScanCodeBillingGroupSuccessBatch({
          actions: {
            updateBillingGroupSuccess: BillingGroupActions.updateSuccess({
              billingGroup: scanCodeBillingGroup.billing_group,
            }),
            updateScanCodeSuccess: ScanCodeActions.updateSuccess({
              scanCode: scanCodeBillingGroup.scan_code,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  deleteFromScanCode(billingGroupId: number, scanCodeId: number) {
    return this.service.deleteFromScanCode(billingGroupId, scanCodeId).pipe(
      map((scanCodeBillingGroup: ScanCodeBillingGroup) =>
        BillingGroupActions.updateScanCodeBillingGroupSuccessBatch({
          actions: {
            updateBillingGroupSuccess: BillingGroupActions.updateSuccess({
              billingGroup: scanCodeBillingGroup.billing_group,
            }),
            updateScanCodeSuccess: ScanCodeActions.updateSuccess({
              scanCode: scanCodeBillingGroup.scan_code,
            }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
