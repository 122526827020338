<div
  class="d-flex align-items-center mb-1"
  title="Saved to Voyager status. This also changes the Voyager status to 'transcribed'."
>
  <span [ngClass]="report.send_to_voyager_time ? 'bg-success' : 'bg-danger'" class="badge"
    >Saved to Voyager
    <ng-container *ngIf="report.send_to_voyager_time; else notSent">
      <span class="text-white"><i class="fas fa-check"></i></span>
    </ng-container>
    <ng-template #notSent>
      <span class="text-white"><i class="fas fa-times"></i></span>
    </ng-template>
  </span>
</div>
