<div class="row">
  <div class="col">
    <div ngbDropdown container="body" class="d-inline-block" display="dynamic">
      <button
        rrTooltip="DOCUMENTATION_LINK"
        type="button"
        class="btn btn-sm btn-outline-secondary me-1 d-xxl-flex align-items-center position-relative"
        ngbDropdownToggle
      >
        Help
      </button>
      <div ngbDropdownMenu>
        <a *ngFor="let doc of docs" class="dropdown-item" [href]="doc.url" target="_blank">{{ doc.title }}</a>
        <div class="p-1" *ngIf="docs.length < 1">No documentation linked</div>
      </div>
    </div>
  </div>
</div>
