import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { PatientQuestionActions } from './patient-question.action';

export interface PatientQuestionState extends EntityState<RR.PatientQuestion> {
  loaded: { [template_id: number]: boolean };
}

const adapter = createEntityAdapter<RR.PatientQuestion>();

const initialState = adapter.getInitialState({ loaded: {} });

export const patientQuestionReducer = createReducer(
  initialState,
  on(PatientQuestionActions.findAllSuccess, (state: PatientQuestionState, { patientQuestions }) =>
    adapter.setAll(patientQuestions, state),
  ),
  on(PatientQuestionActions.createSuccess, (state: PatientQuestionState, { patientQuestion }) =>
    adapter.addOne(patientQuestion, state),
  ),
  on(PatientQuestionActions.updateSuccess, (state: PatientQuestionState, { patientQuestion }) =>
    adapter.upsertOne(patientQuestion, state),
  ),
  on(PatientQuestionActions.updateManySuccess, (state: PatientQuestionState, { patientQuestions }) =>
    adapter.upsertMany(patientQuestions, state),
  ),
  on(PatientQuestionActions.deleteSuccess, (state: PatientQuestionState, { patientQuestionId }) =>
    adapter.removeOne(patientQuestionId, state),
  ),
  // Clear store data on logout, open/close editor
  on(EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),
);
