import { createAction, props } from '@ngrx/store';

/**
 * Ngrx Actions for User Setting
 *
 */
export const UserSettingActions = {
  findAllSuccess: createAction('[UserSetting] Find All Success', props<{ userSettings: RR.UserSetting[] }>()),

  updateSuccess: createAction('[UserSetting] Update Success', props<{ userSetting: RR.UserSetting }>()),
};
