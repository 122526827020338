import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { tap } from 'rxjs/operators';

import { DoctorFollowupActions } from './doctor-followup.action';
import { DoctorFollowupHttpService, DoctorFollowupResponse } from './doctor-followup.service';

@Injectable()
export class DoctorFollowupEffect {
  constructor(
    private store: Store<AppState>,
    private service: DoctorFollowupHttpService,
    private message: MessageService,
  ) {}

  search(reportId: number) {
    return this.service.search(reportId).pipe(
      this.message.handleHttpErrorPipe,
      tap((response: DoctorFollowupResponse) => {
        if (response.doctor_followup)
          this.store.dispatch(DoctorFollowupActions.findSuccess({ doctorFollowup: response.doctor_followup }));
      }),
    );
  }

  create(reportId: number, changes: RR.DoctorFollowUpPayload) {
    return this.service.create(reportId, changes).pipe(
      this.message.handleHttpErrorPipe,
      tap((response: DoctorFollowupResponse) => {
        if (response.doctor_followup)
          this.store.dispatch(DoctorFollowupActions.createSuccess({ doctorFollowup: response.doctor_followup }));
      }),
    );
  }

  // Fix backend to return followupId in response
  delete(reportId: number) {
    return this.service.delete(reportId).pipe(
      this.message.handleHttpErrorPipe,
      tap(() => {
        this.store.dispatch(DoctorFollowupActions.deleteSuccess({ doctorFollowupId: reportId }));
      }),
    );
  }
}
