<div class="form-check">
  <input id="site-any" type="checkbox" rrTooltip="ANY_SITE" class="form-check-input" [formControl]="any" />
  <label for="site-any" rrTooltip="ANY_SITE" class="form-check-label">Any</label>
</div>
<div>
  <div class="form-check" *ngFor="let site of sites; let i = index">
    <input
      id="site-{{ i }}"
      type="checkbox"
      class="form-check-input"
      (change)="onCheck(site, $event)"
      [checked]="formArray.value | includes: site.id"
    />
    <label for="site-{{ i }}" class="mb-0 form-check-label" [title]="site.name">
      {{ site.short_name }}
    </label>
  </div>
</div>
