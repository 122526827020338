import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MessageService } from 'app/core/services/message.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AutoSizeDirective } from 'app/shared/directives/auto-size.directive';
import { SharedModule } from 'app/shared/shared.module';
import { IssueHttpService, IssueUploadImageResponse } from 'app/store/issue/issue.service';
import { finalize, from, Subscription } from 'rxjs';

import { ScreenshotService } from '../screenshot.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AutoSizeDirective,
    ReactiveFormsModule,
    FormsModule,
    AutoFocusDirective,
    DragDropModule,
    SharedModule, // For PermissionDirective
  ],
  templateUrl: './issue-modal.component.html',
  styleUrls: ['./issue-modal.component.css'],
})
export class IssueModalComponent implements OnDestroy, OnInit {
  @Input() show = true;
  width = 600;
  screenshotBlob: Blob | null;
  screenshotBlobUrl?: SafeUrl;
  @ViewChild('textInput2', { static: true }) textInput2: ElementRef;
  PLACEHOLDER1 = 'Issue title. What did you do? What did you expect to happen? What actually happened?';
  PLACEHOLDER2 = 'Optional: steps to reproduce or further details';
  subscription = new Subscription();
  href: string | undefined;
  loading = false;
  loadingImage = false;

  issueForm = new FormGroup({
    title: new FormControl<string | null>(null, { nonNullable: true }),
    description: new FormControl<string | null>(null, { nonNullable: true }),
  });
  dragPosition = { x: 0, y: 0 };

  constructor(
    private messageService: MessageService,
    private issueHttpService: IssueHttpService,
    private screenshotService: ScreenshotService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    // width / 2 centres the modal on the screen
    this.dragPosition.x = window.innerWidth / 2 - this.width / 2;
    this.dragPosition.y = 100;

    this.href = window.location.href;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickScreenshot() {
    this.show = false;
    this.subscription.add(
      from(this.screenshotService.takeScreenshot())
        .pipe(
          finalize(() => {
            this.show = true;
          }),
        )
        .subscribe((blob) => {
          NgZone.assertInAngularZone(); // warn if these screenshot APIs take us out of the zone
          this.screenshotBlob = blob;
          if (blob === null) {
            throw new Error('no screenshot blob');
          }
          this.screenshotBlobUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        }),
    );
  }

  onEnter1(event: Event) {
    // new lines are not allowed in gitlab issue titles (rendered as "=0A")
    event.preventDefault();
    this.textInput2.nativeElement.focus();
  }

  onSubmit() {
    const createIssue = (uploadImageResponse?: IssueUploadImageResponse) => {
      this.loading = true;
      let description = this.issueForm.controls.description.value || '';
      if (uploadImageResponse) {
        description += `\n`;
        description += `![screenshot](${uploadImageResponse.image_url})`;
      }
      this.subscription.add(
        this.issueHttpService
          .create({
            title: this.issueForm.controls.title.value || undefined,
            description,
            url: this.href,
          })
          .pipe(
            finalize(() => {
              this.loading = false;
            }),
          )
          .subscribe({
            next: () => {
              this.messageService.add({
                title: 'Issue submitted',
                message: '',
                type: 'success',
              });
              this.screenshotService.dismissDialog();
            },
            error: (res: unknown) => {
              this.messageService.httpErrorMessage(res);
            },
          }),
      );
    };

    if (this.screenshotBlob) {
      this.loadingImage = true;
      const formData = new FormData();
      formData.append('file', this.screenshotBlob, 'screenshot.png');
      this.subscription.add(
        this.issueHttpService
          .uploadImage(formData)
          .pipe(
            finalize(() => {
              this.loadingImage = false;
            }),
          )
          .subscribe({
            next: (response) => {
              createIssue(response);
            },
            error: (error: unknown) => this.messageService.httpErrorMessage(error),
          }),
      );
    } else {
      createIssue();
    }
  }

  dismiss() {
    this.screenshotService.dismissDialog();
  }
}
