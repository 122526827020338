import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor';
import { ImgsimParameterActions } from './imgsim-parameters.action';
import { Parameter } from './imgsim-parameters.service';

export type ImgSimParameterState = EntityState<Parameter>;

const adapter = createEntityAdapter<Parameter>();

const initialState = adapter.getInitialState();

export const ImgSimParameterReducer = createReducer(
  initialState,
  on(ImgsimParameterActions.addMany, (state: ImgSimParameterState, { parameters }) =>
    adapter.addMany(parameters, state),
  ),
  on(ImgsimParameterActions.upsertOne, (state: ImgSimParameterState, { parameter }) =>
    adapter.upsertOne(parameter, state),
  ),

  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
