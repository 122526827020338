import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-scan-code-headline',
  templateUrl: './scan-code-headline.component.html',
  styleUrls: ['./scan-code-headline.component.css'],
})
export class ScanCodeHeadlineComponent {
  @Input() scanCode: RR.ScanCode;
  @Input() showNotes = false;
}
