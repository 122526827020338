import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Template ES search result
 */
export interface ESTemplate {
  _id: string;
  _source: {
    code: string[];
    modality: string;
    name: string;
    titles: string[];
  };
}

@Injectable()
export class TemplateHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Template[]> {
    return this.http.get<RR.Template[]>('/api/template');
  }

  find(templateId: number) {
    return this.http.get<RR.Template>(`/api/template/${templateId}`);
  }

  create(template: Partial<RR.Template>): Observable<RR.Template> {
    return this.http.post<RR.Template>('/api/template', template);
  }

  update(templateId: number, changes: Partial<RR.Template>): Observable<RR.Template> {
    return this.http.put<RR.Template>(`/api/template/${templateId}`, changes);
  }

  delete(templateId: number) {
    return this.http.delete(`/api/template/${templateId}`, { responseType: 'text' });
  }

  searchTemplates(search: string): Observable<ESTemplate[]> {
    return this.http.get<ESTemplate[]>(`/api/template_search?search=${search}`);
  }
}
