import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { ReportStatusEffect } from 'app/store/report/report-status/report-status.effect';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { ScanCodeEffect, fromScanCode } from 'app/store/scan-code';
import { EMPTY, Observable, Subscription, combineLatest, filter, map, switchMap, take } from 'rxjs';

import { NextReportComponent } from './next-report/next-report.component';

@Component({
  selector: 'rr-next-report-modal',
  standalone: true,
  imports: [NextReportComponent, CommonModule],
  templateUrl: './next-report-modal.component.html',
  styleUrl: './next-report-modal.component.css',
})
export class NextReportModalComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  subscription = new Subscription();
  loaded$: Observable<boolean>;

  constructor(
    private reportStatusEffect: ReportStatusEffect,
    private scanCodeEffect: ScanCodeEffect,
    private store: Store<AppState>,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromScanCode.selectLoaded)
        .pipe(
          take(1),
          filter((loaded) => !loaded),
          switchMap(() => this.scanCodeEffect.findAll()),
        )
        .subscribe(),
    );
    this.subscription.add(
      this.store
        .select(fromReportStatus.selectLoaded)
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (loaded) {
              return EMPTY;
            }
            return this.reportStatusEffect.findAll();
          }),
        )
        .subscribe(),
    );

    this.loaded$ = combineLatest([
      this.store.select(fromReportStatus.selectLoaded),
      this.store.select(fromScanCode.selectLoaded),
    ]).pipe(map((loaded) => loaded.every(Boolean)));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open({ modalService, reportId }: { modalService: NgbModal; reportId: number }) {
    const modalRef = modalService.open(NextReportModalComponent, {
      size: 'xl',
    });
    modalRef.componentInstance.reportId = reportId;
    return modalRef;
  }
}
