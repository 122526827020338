import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromSubsection } from '../subsection/subsection.selector';

const adapter = createEntityAdapter<RR.Section>();
const selectFeature = (state: AppState) => state.section;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectEntities } = adapter.getSelectors(selectFeature);

const selectSection = (sectionId: number) =>
  createSelector(selectEntities, (sections: Dictionary<RR.Section>) => sections[sectionId]);

const selectSubsections = (sectionId: number) =>
  createSelector(
    selectSection(sectionId),
    fromSubsection.selectEntities,
    (section: RR.Section | undefined, subsections: Dictionary<RR.Subsection>) =>
      section?.subsection_ids.map((subsectionId) => subsections[subsectionId]).filter((s): s is RR.Subsection => !!s) ||
      [],
  );

export const fromSection = {
  selectEntities,
  selectSection,
  selectSubsections,
};
