import { createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.VoiceNote>();
const selectFeature = (state: AppState) => state.voice_note;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectInReport = (reportId: number) =>
  createSelector(selectAll, (voice_notes) =>
    voice_notes
      .filter((voice_notes) => voice_notes.report_id === reportId)
      .sort((vN1, vN2) => {
        return new Date(vN2.created).getTime() - new Date(vN1.created).getTime();
      }),
  );

export const fromVoiceNote = {
  selectIds,
  selectEntities,
  selectAll,
  selectInReport,
};
