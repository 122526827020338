<div class="modal-header">
  <h5 class="modal-title">Categorise Statement</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="getStatement() | async as statement">
  <div class="d-flex align-items-end mb-1">
    <strong>{{ statement.is_divider ? 'Group' : 'Statement' }}</strong>

    <div class="ms-auto">
      <ng-container *ngIf="getNextUncategorisedDivider() | async as divider">
        <button
          rrTooltip="NEXT_UNCATEGORISED_DIVIDER"
          *ngIf="statement.is_divider"
          role="button"
          class="btn btn-info me-2"
          (click)="nextDivider(divider.id)"
        >
          Next uncategorised group
        </button>
      </ng-container>

      <ng-container *ngIf="getNextDivider() | async as divider">
        <button
          rrTooltip="NEXT_DIVIDER"
          *ngIf="statement.is_divider"
          role="button"
          class="btn btn-info"
          (click)="nextDivider(divider.id)"
        >
          Next group
        </button>
      </ng-container>
    </div>
  </div>

  <ng-template #popoverDividerSentences>
    <div *ngIf="dividerSentences.length === 0">No statements under this group</div>
    <div *ngIf="dividerSentences.length > 0">
      <button
        rr-statement
        type="stub"
        class="list-group-item text-start w-100"
        *ngFor="let statement of dividerSentences; let i = index"
        [statement]="statement"
        [topic]="(topic$ | async)!"
        [template_id]="(topic$ | async)!.template_id"
      ></button>
    </div>
  </ng-template>

  <div
    [ngbPopover]="popoverDividerSentences"
    [disablePopover]="!statement.is_divider"
    popoverTitle="Statement Groups"
    triggers="mouseenter:mouseleave"
    placement="bottom"
    popoverClass="divider-sentences"
    class="alert alert-success w-100 py-1 mb-2"
    id="sentence"
  >
    <ng-container *ngIf="getTextObjects() | async as textObjects">
      <ng-container *ngFor="let obj of textObjects; let index = index; trackBy: trackBy">
        <span class="me-1" *ngIf="obj.type === 'literal' && obj.value">{{ getLiteral(obj.value) | async }}</span>
        <span class="me-1 rr-statement-number" *ngIf="obj.type === 'number'">{{
          obj.formula ? obj.formula : '**'
        }}</span>

        <!-- Return TexObject Type which does not contain the text field. Should be added? -->
        <span class="me-1" *ngIf="obj.type === 'date'">dd/mm/yyy</span>

        <div
          ngbDropdown
          container="body"
          class="d-inline-block"
          *ngIf="obj.type === 'set' && obj.attribute_set_id"
          (openChange)="onOpenDropdown($event, obj.attribute_set_id, obj.id)"
        >
          <button class="btn btn-sm attribute-toggle" ngbDropdownToggle>
            {{ getSelectedAttributeText(obj.id) | async }}
          </button>
          <div ngbDropdownMenu class="attribute-dropdown">
            <div class="text-center" *ngIf="loadingAttributeSet">
              <span><i class="fa fa-spinner fa fa-spin text-secondary me-2"></i></span> Loading...
            </div>
            <div *ngIf="!loadingAttributeSet">
              <button
                ngbDropdownItem
                *ngFor="let option of attributeSetOptions"
                (click)="selectAttribute(obj.id, option.id); $event.stopPropagation()"
              >
                <span [class.font-italic]="!option.text">{{ option.text ? option.text : 'Blank Option' }}</span>
                <span class="badge bg-secondary ms-2">{{ option.frequency }}</span>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="d-flex mb-1">
    <span><strong>Categories</strong></span>

    <ng-template #popoverCategories>
      <ul class="list-group">
        <li
          role="button"
          (click)="categoriseSentenceFromTop20(cat.category_id)"
          *ngFor="let cat of topCategories"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          {{ getCategoryName(cat.category_id) | async }}
          <span class="badge bg-primary rounded-pill">{{ cat.count }}</span>
        </li>
      </ul>
    </ng-template>
    <button
      (click)="showTopCategories()"
      [ngbPopover]="popoverCategories"
      popoverTitle="Top 20 Categories"
      #popover="ngbPopover"
      triggers="manual"
      placement="bottom-right"
      [autoClose]="'outside'"
      class="btn btn-sm btn-info ms-auto"
      popoverClass="summary"
      rrTooltip="TOP_20_CATEGORIES"
      (hidden)="closeTopCategoriesPopover()"
    >
      <span class="fa fa-medal"></span>
      Select top 20 categories
    </button>
  </div>
  <ul class="list-group" *ngIf="getSentenceCategories() | async as categories">
    <li class="list-group-item list-group-item-info item-hover-parent" *ngFor="let cat of categories">
      {{ cat?.text }}
      <div class="float-end btn-group">
        <button
          rrTooltip="REMOVE_CATEGORY_FOR_SENTENCE"
          (click)="removeSentenceCategory(cat.id)"
          class="btn btn-sm btn-danger item-hover"
          role="button"
        >
          <span class="fa fa-trash"></span> Remove
        </button>
      </div>
    </li>
    <li class="list-group-item list-group-item-secondary" *ngIf="categories.length === 0">
      This statement has not been classified yet.
    </li>
  </ul>

  <ng-container *ngIf="getLastSelectedCategories() | async as lastCategoryIds">
    <div *ngIf="lastCategoryIds.length > 0" class="mt-4">
      <strong>Most recent selected categories</strong> (Click on the category to assign it to the sentence)
      <ul class="list-group">
        <li
          role="button"
          *ngFor="let id of lastCategoryIds"
          class="list-group-item list-group-item-action list-group-item-primary"
          (click)="categoriseSentence(id)"
        >
          {{ getCategoryName(id) | async }}
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="getLastSelectedCategories(true) | async as lastCategoryIds">
    <div *ngIf="lastCategoryIds.length > 0" class="mt-4">
      <strong>Most recent selected categories from top 20</strong>
      <ul class="list-group">
        <li
          role="button"
          *ngFor="let id of lastCategoryIds"
          class="list-group-item list-group-item-action list-group-item-primary"
          (click)="categoriseSentence(id)"
        >
          {{ getCategoryName(id) | async }}
        </li>
      </ul>
    </div>
  </ng-container>
</div>
<div class="modal-footer d-flex">
  <div>
    <span class="fa fa-lightbulb text-warning me-2"></span> How to categorise statements?
    <a
      class="me-2"
      href="https://docs.google.com/document/d/1-P3VImC6bxXNJREpWbyFFRnwZwMXa0su-VdvpmJdfRI/edit"
      target="_blank"
      ><strong>Google Docs</strong></a
    >

    <a href="javascript:void(0)" (click)="openVideoModal('HOW_TO_CATEGORISE_SENTENCES')"><strong>Video</strong></a>
  </div>
  <div class="ms-auto">
    <button role="button" class="btn btn-primary" (click)="activeModal.dismiss()">
      <span class="fa fa-save"></span> Save And Close
    </button>
  </div>
</div>
