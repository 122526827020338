<button
  type="button"
  class="list-group-item list-group-item-action d-flex align-items-center"
  [class.active]="openTopic === topic"
  cdkDrag
  rrTooltip="REORDER_STUDY_TITLE"
>
  <small *ngIf="template">
    <rr-template-headline [template]="template"></rr-template-headline>
  </small>

  <div class="d-flex flex-nowrap ms-auto">
    <!--Open study in prefill-->
    <button
      role="button"
      class="btn btn-sm btn-info py-0 px-1 me-1"
      *ngIf="openTopic !== topic"
      (click)="openPrefill(); $event.stopPropagation()"
      rrTooltip="IMPORT_TO_PREFILL"
    >
      <i class="fas fa-file-import fa-sm"></i>
    </button>

    <!-- Copy history and techniques to current topic-->
    <button
      role="button"
      class="btn btn-sm btn-info py-0 px-1 me-1"
      *ngIf="openTopic !== topic"
      (click)="copyAllChoices(); $event.stopPropagation()"
      rrTooltip="COPY_ALL_CHOICES"
    >
      <i class="fa fa-sm" [ngClass]="copyingHistoryTechnique ? 'fa-spinner' : 'fa-copy'"></i>
      <span class="ms-1" *ngIf="copyingHistoryTechnique">Copying...</span>
    </button>

    <!-- Delete topic -->
    <button
      permission
      [permission-only]="['report_manage']"
      role="button"
      class="btn btn-sm btn-danger py-0 px-1"
      (click)="promptRemove(); $event.stopPropagation()"
      rrTooltip="REMOVE_STUDY_TITLE"
    >
      <i class="fas fa-trash fa-sm"></i>
    </button>
  </div>
</button>
