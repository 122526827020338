import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TimesheetsOpenShift } from './session.types';

@Injectable()
export class SessionHttpService {
  constructor(private http: HttpClient) {}

  fetchConfig() {
    return this.http.get<rrConfigType>('/api/configuration');
  }

  login(username: string, password: string) {
    return this.http.post<RR.User>('/api/auth/login', {
      username,
      password,
    });
  }

  logout() {
    return this.http.get('/api/auth/logout', {});
  }

  register(user: Partial<RR.User> & { password: string }) {
    return this.http.post('/api/register', user);
  }

  /**
   * This only reminds users to clock on by returning information about the open shift.
   * It does not authorise the employee. The employee must still use their login to clock on.
   */
  getRadPayOpenShift(user: RR.User) {
    const headers = {};
    return this.http.post<TimesheetsOpenShift>('/api/radpay/open_shift', { user_id: user.id }, { headers });
  }

  submitInitials(initials: string) {
    return this.http.post<RR.User>('/api/initials', {
      initials,
    });
  }

  updateConfig(changes: Partial<ConfigTemplate>) {
    return this.http.post<ConfigTemplate>('/api/configuration', changes);
  }
}
