import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TemplateData } from '../report/topic/topic.service';

export type CorrelatedStatement = {
  statement: RR.Statement;
  score: CorrelatedStatementScore[];
  filtered_by_subsection: boolean;
  filtered_by_age: boolean;
};

export type CorrelatedStatementScore = {
  name: string;
  value: number;
};

export type CorrelatedResponse = {
  suggested_statements: CorrelatedStatement[];
  default_attributes: RR.DefaultAttribute[];
} & TemplateData;

@Injectable()
export class CorrelatedSearchHttpService {
  constructor(private http: HttpClient) {}

  correlatedSearch(topicId: number, statementId: number) {
    return this.http.get<CorrelatedResponse>(`/api/correlated_statements_search/${topicId}/statement/${statementId}`);
  }

  reportCorrelatedSearch(reportId: number) {
    return this.http.get<CorrelatedResponse>(`/api/correlated_statements_report_search/${reportId}`);
  }

  statisticalStatementsPrediction(topicId: number) {
    return this.http.get<CorrelatedResponse>(`/api/ml/statistical_statement_prediction/${topicId}`);
  }
}
