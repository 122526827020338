import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { SocketService } from 'app/core/services/socket.service';
import { ConfirmPasswordModalComponent } from 'app/modules/admin/modals/confirm-password-modal/confirm-password-modal.component';
import {
  SignatureModalRole,
  TechnicianSignature,
} from 'app/modules/report/components/preview/signature-modal/signature-modal.types';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { fromAppSelector } from 'app/store/app.selector';
import { AppState } from 'app/store/app.state';
import { fromBooking } from 'app/store/booking';
import { EditorActions, fromEditor } from 'app/store/editor';
import { fromMandatoryStatement } from 'app/store/mandatory-statement';
import { fromPatient } from 'app/store/patient';
import { fromReferrer } from 'app/store/referrer';
import {
  fromReportAccessEvent,
  ReportAccessEventActions,
  ReportAccessEventEffect,
} from 'app/store/report/access-event';
import { ChoiceErrorEffect } from 'app/store/report/choice-error/choice-error.effect';
import { fromChoiceError } from 'app/store/report/choice-error/choice-error.selector';
import { fromElementChoice } from 'app/store/report/element-choice';
import { fromRegionChoice } from 'app/store/report/region-choice';
import { fromCurrentReport, fromReport, ReportEffect } from 'app/store/report/report';
import { fromSectionChoice } from 'app/store/report/section-choice';
import { fromSendEvent } from 'app/store/report/send-event';
import { fromStatementChoice, StatementChoiceEffect, UpdateChoicePayload } from 'app/store/report/statement-choice';
import { fromSubsectionChoice } from 'app/store/report/subsection-choice';
import { fromTextObjectChoice, TextObjectChoiceEffect } from 'app/store/report/text-object-choice';
import { fromTodo } from 'app/store/report/todo';
import { fromTopic, TopicEffect } from 'app/store/report/topic';
import { fromUrgentNote } from 'app/store/report/urgent-note';
import { fromSession } from 'app/store/session';
import { fromSignature } from 'app/store/signature';
import { attributeKeyString, fromDefaultAttribute } from 'app/store/template/default-attribute';
import { fromStatement } from 'app/store/template/statement';
import { fromTextObject } from 'app/store/template/text-object/text-object.selector';
import { UserEffect } from 'app/store/user/user';
import { fromUserSetting } from 'app/store/user/user-setting';
import { compareAsc, format } from 'date-fns';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of, zip } from 'rxjs';
import {
  catchError,
  filter,
  map,
  publishReplay,
  refCount,
  share,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { MessageService } from './message.service';
import { TemplateService } from './template.service';

export function reduceTextArrayToText(text_object_choices: RR.TextObjectChoice[]) {
  return text_object_choices
    .map((obj) => {
      switch (obj.type) {
        case 'number':
          const text = obj.text || (obj.formula ? obj.formula : '**');
          return text;
        default:
          return obj.text;
      }
    })
    .join('');
}

export type NoteCounter = {
  urgentNotes: number;
  studyNotes: number;
  todos: number;
  voiceNotes: number;
  minorNotes: number;
  bookingNotes: number;
};

export type ChooseStatementData = {
  topic_id: number;
  element_id: number;
  statement_id: number;
  region_id?: number;
  proposed?: RR.ProposedStatement;
  text_objects?: {
    id: number;
    text: string;
  }[];
  attribute_option_objects?: {
    attribute_option_id: number;
    text: string;
  }[];
  clones?: RR.ReportSection[];
};
// TODO(store): Refactor report service to always act upon the current report / topic
@Injectable()
export class ReportService {
  constructor(
    private store: Store<AppState>,
    private templateService: TemplateService,
    private http: HttpClient,
    private socket: SocketService,
    private userEffect: UserEffect,
    private reportAccessEventEffect: ReportAccessEventEffect,
    private reportEffect: ReportEffect,
    private TextObjectChoiceEffect: TextObjectChoiceEffect,
    private statementChoiceEffect: StatementChoiceEffect,
    private topicEffect: TopicEffect,
    private choiceErrorEffect: ChoiceErrorEffect,
    private modalService: NgbModal,
    private message: MessageService,
  ) {}

  private reportTextSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public reportText$: Observable<string | undefined> = this.reportTextSubject.asObservable();

  setReportText(text: string | undefined): void {
    this.reportTextSubject.next(text);
  }

  /**
   * @deprecated select and filter entity
   */

  getReport(reportId: number): Observable<RR.Report> {
    return this.store.select(fromReport.selectReport(reportId)).pipe(filterDefined());
  }

  hasNotes(reportId: number): Observable<NoteCounter> {
    const report$ = this.store.select(fromReport.selectReport(reportId)).pipe(filterDefined());
    const todos$ = this.store.select(fromTodo.selectInReport(reportId));
    const urgentNotes$ = this.store.select(fromUrgentNote.selectInReport(reportId));
    const referrer$ = report$.pipe(
      switchMap((report) =>
        report.referrer_id ? this.store.select(fromReferrer.selectReferrer(report.referrer_id)) : of(null),
      ),
    );
    const patient$ = report$.pipe(
      switchMap((report) =>
        report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(null),
      ),
    );
    const booking$ = report$.pipe(
      switchMap((report) => this.store.select(fromBooking.selectBooking(report.booking_id))),
    );
    return combineLatest([report$, referrer$, patient$, urgentNotes$, todos$, booking$]).pipe(
      map(([report, referrer, patient, urgentNotes, todos, booking]) => {
        const notes: NoteCounter = {
          urgentNotes: 0,
          studyNotes: 0,
          todos: 0,
          voiceNotes: 0,
          minorNotes: 0,
          bookingNotes: 0,
        };
        if (urgentNotes.length) {
          notes.urgentNotes = urgentNotes.length;
        }
        if (report.study_notes) {
          notes.studyNotes++;
        }
        if (todos.find(({ resolved }) => !resolved)) {
          notes.todos = todos.reduce((acc, { resolved }) => (resolved ? acc : acc + 1), 0);
        }
        if (report.voice_note_ids.length) {
          notes.voiceNotes = report.voice_note_ids.length;
        }
        if (referrer?.collater_notes) {
          notes.minorNotes++;
        }
        if (patient?.note) {
          notes.minorNotes++;
        }
        if (booking && booking.notes) {
          notes.bookingNotes++;
        }
        return notes;
      }),
    );
  }

  /**
   * @deprecated select and filter entity
   */
  getSubsectionChoice(subsectionChoiceId: number) {
    return this.store
      .select(fromSubsectionChoice.selectSubsectionChoice(subsectionChoiceId))
      .pipe(filter((ssc) => !!ssc));
  }

  /**
   * @deprecated select and filter entity
   */
  getSectionChoice(sectionChoiceId: number) {
    return this.store.select(fromSectionChoice.selectSectionChoice(sectionChoiceId)).pipe(filter((sc) => !!sc));
  }

  /**
   * @deprecated select and filter entity
   */
  getElementChoice(elementChoiceId: number) {
    return this.store.select(fromElementChoice.selectElementChoice(elementChoiceId)).pipe(filter((ec) => !!ec));
  }

  /**
   * @deprecated select and filter entity
   */
  getRegionChoice(regionChoiceId: number) {
    return this.store.select(fromRegionChoice.selectRegionChoice(regionChoiceId)).pipe(filter((rc) => !!rc));
  }

  /**
   * @deprecated select and filter entity
   */
  getStatementChoice(id: number) {
    return this.store
      .select(fromStatementChoice.selectStatementChoice(id))
      .pipe(filter((statementChoice) => !!statementChoice));
  }

  getTextObjectChoices(statement_choice_id: number) {
    return this.store.select(fromAppSelector.selectTextObjectChoicesByChoiceId(statement_choice_id));
  }

  /**
   * If the report is ready in the store, return that report. Otherwise, call the api to fetch the report
   * @param report_id
   * @param params
   */
  findReport(report_id: number) {
    return this.store.select(fromReport.selectReport(report_id)).pipe(
      take(1),
      switchMap((report) => {
        if (!report) {
          return this.reportEffect.find(report_id);
        }
        return of(null);
      }),
      share(),
    );
  }

  getTopic(id: number): Observable<RR.Topic> {
    return this.store.select(fromTopic.selectTopic(id)).pipe(filterDefined());
  }

  topicHasTitle(topic_id: number) {
    return this.getTopic(topic_id).pipe(map((t) => !!t.title_option_text));
  }

  getVisibleFlatChoices(topic_id: number) {
    return this.getChoicesWithCtx(topic_id).pipe(
      map((ctxs) => {
        return ctxs.map((c) => c.statement_choice);
      }),
    );
  }

  getFlaggedChoices(report_id: number, topic_id: number) {
    return combineLatest([
      this.getVisibleFlatChoices(topic_id),
      this.store.select(fromTodo.selectInReport(report_id)),
    ]).pipe(
      map(([choices, todos]) => {
        return choices.filter((c) => {
          return todos.filter((t) => t.statement_choice_id === c?.id && !t.resolved).length > 0;
        });
      }),
    );
  }

  getChoicesWithUnfilledNumbers(topic_id: number) {
    return this.getVisibleFlatChoices(topic_id).pipe(
      map((choices) =>
        choices.filter((c) => {
          if (!c) return false;
          let text_object_choices: RR.TextObjectChoice[] = [];

          this.getTextObjectChoices(c.id)
            .pipe(take(1))
            .subscribe((_text_object_choices) => {
              text_object_choices = _text_object_choices.filter((choice): choice is RR.TextObjectChoice => !!choice);
            });
          return !!text_object_choices.find((o) => (o.type === 'number' || o.type === 'date') && !o.text);
        }),
      ),
    );
  }

  getChoicesWithOutOfRangeNumbers(topic_id: number) {
    return this.getVisibleFlatChoices(topic_id).pipe(
      map((choices) =>
        choices.filter((c) => {
          if (!c?.statement_id) return false;

          let text_object_choices: RR.TextObjectChoice[] = [];
          this.getTextObjectChoices(c.id)
            .pipe(take(1))
            .subscribe((_text_object_choices) => {
              text_object_choices = _text_object_choices.filter((choice): choice is RR.TextObjectChoice => !!choice);
            });

          let statement: RR.Statement | null = null;
          this.templateService
            .getStatement(c.statement_id)
            .pipe(take(1))
            .subscribe((s) => {
              statement = s;
            });
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!statement) return false;

          let textObjects: RR.TextObject[] = [];
          this.store
            .select(fromStatement.selectTextObjects(c.statement_id))
            .pipe(take(1))
            .subscribe((_textObjects) => {
              textObjects = _textObjects;
            });

          return !!text_object_choices.find((o) => {
            // If choice obj is not number, or not link to a statement text object, or text is empty or is not a number
            if (o.type !== 'number' || !o.text_object_id || !o.text || Number.isNaN(Number(o.text))) return false;
            const textObjectNumber = textObjects.find(
              (obj): obj is RR.TextObjectNumber => obj.id === o.text_object_id && obj.type === 'number',
            );
            if (!textObjectNumber) return false;
            let lower: number;
            if (textObjectNumber.lower !== null) {
              lower = textObjectNumber.lower;
            } else if (textObjectNumber.auto_lower !== null) {
              lower = textObjectNumber.auto_lower;
            } else {
              lower = -Number.MAX_VALUE;
            }
            let upper: number;
            if (textObjectNumber.upper != null) {
              upper = textObjectNumber.upper;
            } else if (textObjectNumber.auto_upper != null) {
              upper = textObjectNumber.auto_upper;
            } else {
              upper = -Number.MAX_VALUE;
            }
            return Number(o.text) < lower || Number(o.text) > upper;
          });
        }),
      ),
    );
  }

  markChoicesAsErrors(errors: { [k: string]: RR.ProofreadChoiceError }) {
    this.choiceErrorEffect.setChoiceErrors(Object.values(errors));
  }

  getChoiceError(choice_id: number) {
    return this.store.select(fromChoiceError.selectChoiceError(choice_id)).pipe(
      map((error) => {
        // Just shows the first error for the choice
        return error ? error.reason : '';
      }),
    );
  }

  getChoiceErrors = this.store.select(fromChoiceError.selectAll);

  chooseLastSignature(topic_id: number) {
    return this.topicEffect.autoChooseSignature(topic_id);
  }

  setSignature(topic_id: number, signature: RR.Signature | TechnicianSignature, signature_role: SignatureModalRole) {
    let changes: Partial<RR.Topic> = {};
    switch (signature_role) {
      case 'DOCTOR':
        signature = signature as RR.Signature;
        changes = { signature_text: signature.text, signature_id: signature.id };
        break;
      case 'RADIOLOGY_REGISTRAR':
        signature = signature as TechnicianSignature;
        changes = {
          radiology_registrar_signature_text: signature.text,
          radiology_registrar_signature_user_id: signature.user_id,
        };
        break;
      case 'TECHNICIAN':
        signature = signature as TechnicianSignature;
        changes = { technician_signature_text: signature.text, technician_signature_user_id: signature.user_id };
        break;
      case 'JUNIOR_TECHNICIAN':
        signature = signature as TechnicianSignature;
        changes = { junior_signature_text: signature.text, junior_signature_user_id: signature.user_id };
        break;
    }
    return this.topicEffect.update(topic_id, changes);
  }

  removeSignature(topic: RR.Topic, signature_role: SignatureModalRole) {
    let changes: Partial<RR.Topic> = {};
    switch (signature_role) {
      case 'DOCTOR':
        changes = { signature_text: null, signature_id: null };
        break;
      case 'RADIOLOGY_REGISTRAR':
        changes = { radiology_registrar_signature_text: null, radiology_registrar_signature_user_id: null };
        break;
      case 'TECHNICIAN':
        changes = { technician_signature_text: null, technician_signature_user_id: null };
        break;
      case 'JUNIOR_TECHNICIAN':
        changes = { junior_signature_text: null, junior_signature_user_id: null };
        break;
    }
    return this.topicEffect.update(topic.id, changes);
  }

  /**
   * Check if user has signed the study. Only check for doctor
   * @param topic_id
   * @param user_id
   */
  userHasSigned(topic_id: number, user: RR.User): Observable<boolean> {
    if (!user.company_roles.some((companyRoleId) => companyRoleId === 'doctor')) return of(true);

    return combineLatest([this.getTopic(topic_id), this.store.select(fromSignature.selectEntities)]).pipe(
      map(([topic, signature_entities]) => {
        if (topic.signature_id) {
          const signature = signature_entities[topic.signature_id];
          return !!signature?.user_ids.find((u) => u === user.id);
        }
        return false;
      }),
    );
  }

  createReportAccessEvent(report_id: number, user_id: number) {
    // Fetch data of user who is editing the report
    this.userEffect.findById(user_id).subscribe();

    this.store
      .select(fromReportAccessEvent.selectAccessEventsInReport(report_id))
      .pipe(
        take(1),
        // add switchMap to avoid nested subscribe
        switchMap((access_events) => {
          if (!access_events.length || access_events.slice(-1)[0].user_id !== user_id) {
            return this.reportAccessEventEffect.create({ report_id, user_id });
          } else {
            const last_access_event = access_events.slice(-1)[0];
            this.store.dispatch(ReportAccessEventActions.createSuccess({ accessEvent: last_access_event }));
            // Dispatch action to save last report access event in editor
            this.store.dispatch(EditorActions.saveLastReportAccessEvent({ reportAccessEvent: last_access_event }));
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  lastReportAccessEvent() {
    return this.store.select(fromEditor.selectLastReportAccessEvent);
  }

  selectKioskUser(): Observable<RR.User | undefined> {
    return this.store.select(fromSession.selectKioskUser);
  }

  selectKioskUserSetting(): Observable<RR.UserSetting> {
    return this.selectKioskUser().pipe(
      filterDefined(),
      switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
      filterDefined(),
    );
  }

  getReportSendStatus(report_id: number): Observable<RR.ReportSendStatus> {
    return combineLatest([
      this.getReport(report_id),
      this.store.select(fromSendEvent.selectSendEventsInReport(report_id)),
    ]).pipe(map(([report, send_events]) => this.getSendStatus(report, send_events)));
  }

  getSendStatus(report: RR.Report, send_events: RR.SendEvent[]) {
    // Haven't input any cc to fields => EMPTY
    if (
      !report.phone &&
      !report.fax &&
      !report.email &&
      !report.cc_to_sms &&
      !report.cc_to_fax &&
      !report.cc_to_fax2 &&
      !report.cc_to_email
    ) {
      return 'EMPTY';
    }

    // Input cc to sms/fax/email but don't have any send event to those numbers => UNRESOLVED
    if (
      (report.phone && !this.checkResolved(send_events, report.phone, 'SMS')) ||
      (report.cc_to_sms && !this.checkResolved(send_events, report.cc_to_sms, 'SMS')) ||
      (report.fax && !this.checkResolved(send_events, report.fax, 'FAX')) ||
      (report.cc_to_fax && !this.checkResolved(send_events, report.cc_to_fax, 'FAX')) ||
      (report.cc_to_fax2 && !this.checkResolved(send_events, report.cc_to_fax2, 'FAX')) ||
      (report.email && !this.checkResolved(send_events, report.email, 'EMAIL')) ||
      (report.cc_to_email && !this.checkResolved(send_events, report.cc_to_email, 'EMAIL'))
    )
      return 'UNRESOLVED';
    return 'RESOLVED';
  }

  checkResolved(sendEvents: RR.SendEvent[], sendTo: string, type: 'SMS' | 'EMAIL' | 'FAX') {
    return sendEvents.some((e) => e.communication_type === type && e.send_to === sendTo);
  }

  createTopic(report_id: number, template_id: number) {
    this.notifyEdit();
    return this.topicEffect.create({ report_id, template_id });
  }

  removeTopic(topic_id: number) {
    return this.updateTopic(topic_id, {
      deleted: true,
    });
  }

  setTopicTitle(topic_id: number, title_option_id: number, title_option_text: string) {
    return this.updateTopic(topic_id, {
      title_option_id,
      title_option_text,
    });
  }

  setOtherImaging(topic_id: number, other_imaging: string | null) {
    return this.updateTopic(topic_id, {
      other_imaging,
    });
  }

  getActiveTopics(report_id: number) {
    return this.store.select(fromReport.selectTopics(report_id));
  }

  getPositionOfTopic(report_id: number, topic_id: number) {
    return this.getActiveTopics(report_id).pipe(
      map((activeTopics) => {
        if (activeTopics.length > 1) {
          const index = activeTopics.findIndex((v) => {
            return v.id === topic_id;
          });
          return `${index + 1} of ${activeTopics.length}`;
        }
        return '';
      }),
    );
  }

  getRegionChoices(subsection_choice_id: number) {
    return combineLatest(
      this.store.select(fromRegionChoice.selectEntities),
      this.getSubsectionChoice(subsection_choice_id),
      (region_choice_entities, subsection_choice) => {
        return subsection_choice?.region_choices.map((id) => region_choice_entities[id]) || [];
      },
    );
  }

  moveTopic(topic_id: number, move_to_topic_id: number) {
    return this.topicEffect.update(topic_id, { move_to_topic_id });
  }

  updateTopic(topic_id: number, changes: Partial<RR.Topic>) {
    this.notifyEdit();
    return this.topicEffect.update(topic_id, changes);
  }

  setTopicCopied(topic_id: number) {
    this.topicEffect.copyTopic(topic_id).subscribe();
  }

  findTopic(topic_id: number) {
    return this.store.select(fromTopic.selectEntities).pipe(
      take(1),
      switchMap((topic_entities) => {
        const topic_exists = topic_entities[topic_id];
        // topic.sections, a hack to check we don't have a partial topic (e.g. from report.related_topics)
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        if (!topic_exists || topic_exists.section_choices == null) {
          return this.topicEffect.find(topic_id).pipe(map((action) => action.actions.findTopicSuccess.topic));
        }
        return of(topic_exists);
      }),
    );
  }

  /**
   * @deprecated select and filter from store
   */
  getChoiceParents(statement_choice: RR.StatementChoice): Observable<RR.Ctx> {
    return this.getElementChoice(statement_choice.element_choice_id).pipe(
      switchMap((element_choice) =>
        // @ts-expect-error strictNullChecks
        this.getRegionChoice(element_choice.region_choice_id).pipe(
          switchMap((region_choice) =>
            // @ts-expect-error strictNullChecks
            this.getSubsectionChoice(region_choice.subsection_choice_id).pipe(
              switchMap((subsection_choice) =>
                // @ts-expect-error strictNullChecks
                this.getSectionChoice(subsection_choice.section_choice_id).pipe(
                  switchMap((section_choice) =>
                    // @ts-expect-error strictNullChecks
                    this.getTopic(section_choice.topic_id).pipe(
                      map((topic) => ({
                        statement_choice,
                        element_choice,
                        region_choice,
                        subsection_choice,
                        section_choice,
                        topic,
                      })),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    );
  }

  getCommentChoices(topic_id: number) {
    return this.getChoicesWithCtx(topic_id).pipe(
      map((ctxs) => {
        return ctxs
          .filter((ctx) => {
            return ctx.section_choice?.type === 'comment' || (ctx.statement_choice?.clones || []).includes('comment');
          })
          .map((ctx) => ctx.statement_choice);
      }),
    );
  }

  getImpressionAndRecommendationChoices(topic_id: number) {
    return this.getChoicesWithCtx(topic_id).pipe(
      map((ctxs) => {
        return ctxs
          .filter((ctx) => {
            return (
              ctx.section_choice?.type === 'impression_recommendations' ||
              (ctx.statement_choice?.clones || []).includes('impression_recommendations')
            );
          })
          .map((ctx) => ctx.statement_choice);
      }),
    );
  }

  getTechniqueChoices(topic_id: number) {
    return this.getChoicesWithCtx(topic_id).pipe(
      map((ctxs) => {
        return ctxs
          .filter((ctx) => {
            return ctx.section_choice?.type === 'technique';
          })
          .map((ctx) => ctx.statement_choice);
      }),
    );
  }

  getKeyFindingChoices(topic: RR.Topic) {
    return this.getKeyFindings(topic.id).pipe(
      map((choices) => {
        return choices.map((c) => c.statement_choice);
      }),
    );
  }

  getKeyFindings(topic_id: number) {
    return this.getChoicesWithCtx(topic_id)
      .pipe(
        map((ctxs) => {
          return ctxs.filter((ctx) => {
            const c = ctx.statement_choice;
            return !!c?.clones && c.clones.includes('key_finding');
          });
        }),
      )
      .pipe(publishReplay(1), refCount());
  }

  getSectionClones(topic_id: number, section_choice_id: number) {
    return this.store.select(fromAppSelector.selectSectionClones(topic_id, section_choice_id));
  }

  getShowHeadingPreview(topic_id: number, section_choice_id: number) {
    return this.store.select(fromAppSelector.selectShowHeadingPreview(topic_id, section_choice_id));
  }

  getChoicesWithCtx(topic_id: number): Observable<RR.Ctx[]> {
    return this.store.select(fromAppSelector.selectChoicesWithCtx(topic_id));
  }

  getReportSpecificChoices(element_choice_id: number) {
    return this.store
      .select(fromElementChoice.selectStatementChoices(element_choice_id))
      .pipe(map((choices) => choices.filter((c) => c.statement_id === null)));
  }

  getLegacyChoices(element_choice_id: number) {
    return combineLatest(
      this.store.select(fromStatement.selectEntities),
      this.store.select(fromElementChoice.selectStatementChoices(element_choice_id)),
      (statement_entities, choices) => {
        return choices.filter((c) => {
          if (c.statement_id === null) return false;
          const statement = statement_entities[c.statement_id];
          return !statement || statement.legacy;
        });
      },
    );
  }

  getChoiceText(choice_id: number) {
    return this.getTextObjectChoices(choice_id).pipe(
      map((text_object_choices) => {
        const textObjectChoices = text_object_choices.filter((choice): choice is RR.TextObjectChoice => !!choice);
        return reduceTextArrayToText(textObjectChoices);
      }),
    );
  }

  updateStatementChoice(choice: RR.StatementChoice, changes: UpdateChoicePayload) {
    this.notifyEdit();
    return this.statementChoiceEffect.update(choice.id, changes);
  }

  createTextObjectsFromProposed(proposed: RR.ProposedStatement) {
    return Object.entries(proposed.proposed).map(([sto_id, proposedMeasurements]) => {
      const firstProposedMeasurement: RR.MeasurementValue = proposedMeasurements[0];
      return {
        id: Number(sto_id),
        text: String(firstProposedMeasurement.numeric_value),
      };
    });
  }

  singleSelectStatement(elementChoiceId: number | undefined, data: ChooseStatementData) {
    // TODO: how to get the change tracker action type from `mapChangeTrackerToSuccessActions`
    let obs$: Observable<null | any> = of(null);
    if (elementChoiceId !== undefined) {
      obs$ = this.store.select(fromElementChoice.selectStatementChoices(elementChoiceId)).pipe(
        take(1),
        switchMap((choices) => this.removeStatementChoices(choices.filter((choice) => choice.statement_id !== null))),
      );
    }

    return obs$.pipe(switchMap(() => this.chooseStatement(data)));
  }

  chooseStatement(data: ChooseStatementData) {
    this.notifyEdit();
    const {
      topic_id,
      element_id,
      statement_id,
      region_id = null,
      clones = undefined,
      proposed,
      attribute_option_objects,
    } = data;

    let { text_objects } = data;

    if (proposed) {
      text_objects = this.createTextObjectsFromProposed(proposed);
    }

    return this.statementChoiceEffect.create({
      topic_id,
      statement_id,
      element_id,
      // @ts-expect-error strictNullChecks
      region_id,
      clones,
      text_objects,
      attribute_option_objects,
    });
  }

  copyRegionChoices(topic_id: number, from_region_choice_id: number, to_region_choice_id: number) {
    this.notifyEdit();
    return this.statementChoiceEffect.copyRegionChoices({ topic_id, from_region_choice_id, to_region_choice_id });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  toggleClone = (choice: RR.StatementChoice, clone: RR.ReportSection) => {
    const clones = choice.clones || [];
    return clones.includes(clone) ? this.removeClone(choice, clone) : this.addClone(choice, clone);
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  addClone = (choice: RR.StatementChoice, add_clone: RR.ReportSection) => {
    return this.updateStatementChoice(choice, { add_clone });
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  removeClone = (choice: RR.StatementChoice, remove_clone: RR.ReportSection) => {
    return this.updateStatementChoice(choice, { remove_clone });
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  removeStatementChoice = (statement_choice: RR.StatementChoice) => {
    this.notifyEdit();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (statement_choice !== undefined) {
      this.statementChoiceEffect.delete(statement_choice.id).subscribe();
    }
  };

  deleteUnderlined(topic_id: number, choice_ids: Set<number>) {
    this.getChoicesWithCtx(topic_id)
      .pipe(
        map((ctxs) =>
          ctxs
            .map((ctx) => ctx.statement_choice)
            .filter((c): c is RR.StatementChoice => !!c && !!c.prefilled_from_choice_id && choice_ids.has(c.id)),
        ),
        take(1),
      )
      .subscribe((choices) => {
        return (
          this.removeStatementChoices(choices)
            .pipe(take(1))
            // eslint-disable-next-line rxjs/no-nested-subscribe -- 2
            .subscribe(() => {
              // Toggle underlying prefill after delete prefilled choices
              this.store.dispatch(EditorActions.togglePrefilledUnderline({ underline: false, choice_ids: new Set() }));
            })
        );
      });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  removeStatementChoices = (choices: RR.StatementChoice[]) => {
    this.notifyEdit();
    return this.statementChoiceEffect.deleteMany(choices.map((c) => c.id));
  };

  editTextObject(text_object_choice_id: number, changes: Partial<RR.TextObjectChoice>) {
    this.notifyEdit();
    return this.TextObjectChoiceEffect.update(text_object_choice_id, changes);
  }

  hasNecessaryKeyFindings(topic_id: number): Observable<boolean> {
    return this.getKeyFindings(topic_id).pipe(
      map((key_findings) => {
        return key_findings.length > 0;
      }),
    );
  }

  createSpecificChoice(topic_id: number, element_id: number, region_id: number, text: string) {
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return this.statementChoiceEffect.create({
      topic_id,
      element_id,
      region_id,
      text_object_choices: [
        {
          attribute_option_id: null,
          text,
          type: 'literal',
        } as any,
      ],
      exclusive: false,
    });
  }

  fetchTopicDividers(topic_id: number) {
    return this.http.get<RR.Divider[]>(`/api/topic/${topic_id}/dividers`);
  }

  /**
   * Copy prior studies to other imaging field
   * @param topic_id
   * @param from_topic_ids
   * @param include_key_findings
   */
  copyOtherImaging(topic_id: number, from_topic_id: number, include_key_findings: boolean) {
    return this.topicEffect.copyOtherImaging(topic_id, from_topic_id, include_key_findings);
  }

  /**
   * From related topics in report, return format string:
   * accession_number, template modality - template name (study date)
   * @param report
   */
  getOtherImagingHeading(topic_ids: number[]): Observable<string> {
    return this.store.select(fromTopic.selectEntities).pipe(
      switchMap((topic_entities) => {
        return zip(
          ...topic_ids.map((topic_id) => {
            const topic = topic_entities[topic_id];
            if (topic != null) {
              return combineLatest([
                this.getReport(topic.report_id),
                this.templateService.getTemplate(topic.template_id),
              ]).pipe(
                map(([ret_report, template]) => {
                  const date = new Date(ret_report.created ?? Date.now());
                  const heading = `${ret_report.accession_number}, ${template?.modality.name} - (${
                    topic.title_option_text || 'NO TITLE'
                  }), ${format(date, 'dd-MM-yyyy H:mm')}`;
                  return {
                    date,
                    heading,
                  };
                }),
              );
            } else {
              return EMPTY;
            }
          }),
        );
      }),
      map((headings) =>
        headings
          .sort((h1, h2) => compareAsc(h1.date, h2.date))
          .map((h) => h.heading)
          .join('\n'),
      ),
    );
  }

  getTitleSide(title_text: string): 'LEFT' | 'RIGHT' | null {
    if (!title_text) {
      return null;
    }
    const title_words = title_text.split(' ').map((t) => (t ? t.toLowerCase() : ''));
    if (title_words.includes('right') && !title_words.includes('left')) return 'RIGHT';
    if (title_words.includes('left') && !title_words.includes('right')) return 'LEFT';
    return null;
  }

  /**
   * Get the latest priors related topic with has the same template with a topic
   * If don't have any topic with the same template, return the latest prior topic
   * @param topic_id
   * @param related_topic_ids
   */
  getLatestRelatedTopic(topic_id: number, report_id: number): Observable<RR.Topic | undefined> {
    if (!topic_id || !report_id) return of(undefined);
    return combineLatest([
      this.store.select(fromReport.selectRelatedTopics(report_id)),
      this.store.select(fromTopic.selectTopic(topic_id)),
    ]).pipe(
      map(([relatedTopics, topic]) => {
        relatedTopics = relatedTopics
          // Remove any ids that were not present in the topic entities, so
          // would have a null value.
          .filter((_relatedTopic) => _relatedTopic != null)
          // Sort in descending of the topicId, so the largest, hence most
          // recent topicId is first in the list.
          // @ts-expect-error strictNullChecks
          .sort((topicA, topicB) => topicB.id - topicA.id);

        // Look for the first topic within the same template
        const relatedTopic = relatedTopics.find((t) => t?.template_id === topic?.template_id);
        // If we find a topic in the same template, then return that value
        if (relatedTopic != null) {
          return relatedTopic;
          // Otherwise we return the first topic from the list of related topics
        } else {
          // This will fallback to null if there are no values in the relatedToipcs list
          return relatedTopics[0];
        }
      }),
    );
  }

  checkSelectedRegionStatement({
    choice,
    region_id,
    element,
  }: {
    choice: RR.StatementChoice;
    region_id: number;
    element: RR.Element;
  }) {
    return combineLatest([
      this.getChoiceParents(choice),
      this.store.select(fromRegionChoice.selectEntities),
      this.store.select(fromElementChoice.selectEntities),
      this.store.select(fromStatementChoice.selectEntities),
      this.store.select(fromTextObjectChoice.selectEntities),
      this.store.select(fromTextObject.selectEntities),
      this.store.select(fromStatement.selectEntities),
      this.store.select(fromDefaultAttribute.selectChoiceMap),
    ]).pipe(
      map(
        ([
          ctx,
          region_choice_entities,
          element_choice_entities,
          statement_choice_entities,
          text_object_choices_entities,
          text_object_entities,
          statement_entities,
          default_attribute_map,
        ]) => {
          const element_id = ctx.element_choice?.element_id;
          const statement_id = choice.statement_id;
          const attribute_ids: number[] = [];
          const default_attribute_ids: number[] = [];
          const region_choices = ctx.subsection_choice?.region_choices.map(
            (region_choice_id) => region_choice_entities[region_choice_id],
          );
          const region_choice = region_choices?.find((rc) => rc?.region_id === region_id);
          if (region_choice) {
            const element_choices = region_choice.element_choices
              .map((element_choice_id) => element_choice_entities[element_choice_id])
              // Filter out undefined entity
              .filter((element_choice_entity) => element_choice_entity);

            const element_choice = element_choices.find((ec) => ec?.element_id === element_id);

            if (element_choice) {
              const statement_choices = element_choice.statement_choices
                .map((statement_choice_id) => statement_choice_entities[statement_choice_id])
                // Filter out undefined entity
                .filter((statement_choice_entity) => statement_choice_entity);

              const statement_choice = statement_choices.find((sc) => sc?.statement_id === statement_id);

              if (statement_choice) {
                const text_object_choices = statement_choice.text_object_choices
                  .map((text_object_choice_id) => text_object_choices_entities[text_object_choice_id])
                  // Filter out undefined entity
                  .filter((text_object_choices_entity) => text_object_choices_entity);

                text_object_choices.map((text_object_choice) => {
                  if (text_object_choice?.type === 'set' && !!text_object_choice.attribute_option_id) {
                    attribute_ids.push(text_object_choice.attribute_option_id);
                  }
                });

                const statement = statement_id !== null ? statement_entities[statement_id] : undefined;
                if (statement) {
                  const textObjects = statement.text_objects
                    .map((id) => text_object_entities[id])
                    .filter((o): o is RR.TextObject => !!o);

                  textObjects.forEach((text_object) => {
                    if (text_object.type === 'set') {
                      // TODO: Replace with the store access. This will require
                      // rethinking the subscriptions, so is a little more
                      // complicated than a direct replacement
                      if (ctx.topic !== undefined && ctx.region_choice !== undefined) {
                        let regionId: number | null = ctx.region_choice.region_id;
                        if (element.type === 'notepad') {
                          // Notepads are global, so we just have one lot of Default Attributes for them, not per Region
                          regionId = null;
                        }
                        const default_attribute =
                          default_attribute_map[attributeKeyString(ctx.topic.template_id, text_object.id, regionId)];
                        if (default_attribute.default_option_id) {
                          default_attribute_ids.push(default_attribute.default_option_id);
                        }
                      }
                    }
                  });
                }
                // This should be inside the `if (statement_choice)` block
                return {
                  checked: true,
                  // return if statement choice has default attributes
                  isDefault: isEqual(attribute_ids, default_attribute_ids),
                  // return if statement choice has no-default attributes
                  noDefaults: !isEqual(attribute_ids, default_attribute_ids),
                };
              }
            }
          }
          return {
            checked: false,
            isDefault: false,
            // TODO: this displays under "current" in the UI which doesn't make sense, instead it should check if the
            // other statement choice has the same attributes as the current one.
            noDefaults: false,
          };
        },
      ),
    );
  }

  getLogisticRegressionStatements(topic_id: number, statement_set_id: number) {
    const params = new HttpParams().set('topic_id', String(topic_id)).set('element_id', String(statement_set_id));
    return this.http.get<number[]>('/api/topic/logistic_regression_probabilities', { params });
  }

  getNextRecommendedStatements(statement_choice_id: number) {
    return this.http.get<{ statement_id: number; frequency: number }[]>('/api/get_top_next_statements', {
      params: new HttpParams().set('statement_choice_id', String(statement_choice_id)),
    });
  }

  getStructuredTags(topic_id: number, sections: string) {
    return this.http.get<any>(`/api/topic/${topic_id}/get_structured_tags?section=${sections}`);
  }

  getRecommendedInfo(report_id: number) {
    return this.http.get<{ emails: EmailSuggestions[]; fax_numbers: FaxSuggestions[] }>(
      `/api/report/${report_id}/requesting_suggestion_numbers`,
    );
  }

  isDoctorEditing() {
    return this.selectKioskUser().pipe(
      map((user) => user?.company_roles.some((companyRoleId) => companyRoleId === 'doctor')),
    );
  }

  /**
   * Notifies other users when someone else is editing the report they have open.
   */
  notifyEdit() {
    const currentReportId$ = this.store.select(fromCurrentReport.selectReportId);
    this.selectKioskUser()
      .pipe(take(1), filterDefined(), withLatestFrom(currentReportId$))
      .subscribe(([currentUser, currentReportId]) => {
        if (currentReportId !== null) {
          this.socket.send({
            type: 'REPORT_EDITED',
            report_id: currentReportId,
            user_id: currentUser.id,
            user_name: currentUser.name,
          });
        }
      });
  }

  /**
   * Check if the report has imgsim parameters that can be prefilled to flash the button
   */
  checkImgsimPrefill(report_id: number) {
    return this.http.get<{
      available: boolean;
    }>(`/api/report/${report_id}/check_imgsim_params`);
  }

  getAllMandatoryStatements(topic: RR.Topic): Observable<RR.MandatoryStatement[]> {
    return this.store.select(fromMandatoryStatement.selectByTemplate(topic.template_id));
  }

  getIncompleteMandatoryStatements(topic: RR.Topic): Observable<RR.MandatoryStatement[]> {
    return combineLatest([
      this.getChoicesWithCtx(topic.id),
      this.store.select(fromTopic.selectJustifications(topic.id)),
    ]).pipe(
      switchMap(([choices, justifications]) => {
        return this.getAllMandatoryStatements(topic).pipe(
          map((mandatoryStatements) =>
            mandatoryStatements.filter((mandatoryStatement) => {
              return (
                mandatoryStatement.statement_id !== null &&
                mandatoryStatement.type === 'statement' &&
                !choices.some((choice) => choice.statement_choice?.statement_id === mandatoryStatement.statement_id) &&
                !justifications.some((justification) => justification.mandatory_statement_id === mandatoryStatement.id)
              );
            }),
          ),
        );
      }),
    );
  }

  checkVoyagerStudyDate(acccessionNo: number) {
    return this.http.get<{ rr_study_date: string; voyager_study_date: string }>(
      `/api/report/${acccessionNo}/check_study_date`,
    );
  }

  deleteReport(report: RR.Report, message: string) {
    const modalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: message,
      btnConfirmText: 'Yes',
    });

    return from(modalRef.result).pipe(
      catchError(() => {
        // We put the catchError first, so that real errors in the switchMap are not caught
        // Dismiss delete report modal
        return EMPTY;
      }),
      switchMap(() => this.confirmPasswordToDelete(report.id)),
    );
  }

  confirmPasswordToDelete(reportId: number) {
    // Ask user to reenter password
    const modalRef = this.modalService.open(ConfirmPasswordModalComponent);

    return from(modalRef.result).pipe(
      catchError(() => {
        // Dismiss password modal
        return EMPTY;
      }),
      switchMap((password) =>
        this.reportEffect.delete(reportId, password).pipe(
          this.message.handleHttpErrorPipe,
          tap(() => {
            this.message.add({
              title: 'Success',
              type: 'success',
              message: 'Deletion was successful.',
            });
          }),
        ),
      ),
    );
  }
}
