<div class="my-2">
  <div class="btn-group d-flex flex-wrap justify-content-center py-1" role="group">
    <ng-container *ngIf="structureTopicTagMap$ | async as structureTopicTagMap">
      <ng-container *ngFor="let subsection of structure; let i = index">
        <input
          id="structure-tag-{{ i }}"
          class="btn-check"
          type="radio"
          [value]="i"
          [formControl]="subsectionIx"
          (click)="dictateSpeech()"
          (change)="onSubsectionChange()"
        />
        <label
          for="structure-tag-{{ i }}"
          class="btn btn-outline-primary btn-sm d-flex flex-nowrap justify-content-center"
        >
          <i *ngIf="structureTopicTagMap[i]" class="fa fa-tag text-warning me-1" rrTooltip="CURRENT_REPORT_DIVIDER"></i>
          {{ subsection.name || 'NO NAME' }}
          <div ngbDropdown>
            <button
              ngbDropdownToggle
              *ngIf="structuredNotes | structuredNoteLength: subsection.name ?? 'NO NAME' as noteLength"
              class="mx-1 badge bg-light text-dark border"
            >
              {{ noteLength }}
            </button>
            <div ngbDropdownMenu>
              <ng-container *ngFor="let structuredNote of structuredNotes">
                <ng-container *ngIf="structuredNote.subsection === (subsection.name ?? 'NO NAME')">
                  <button (click)="copyToSearch(note.text)" *ngFor="let note of structuredNote.notes" ngbDropdownItem>
                    {{ note.text }}
                  </button>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </label>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="groupedTags$ | async as groupedTags">
  <div *ngIf="groupedTags[subsectionName] as subsectionTags">
    <!-- Where there is only a single region, use a simple list -->
    <div *ngIf="regions.length === 0">
      <div *ngIf="subsectionTags['null'] as tags">
        <div *ngFor="let tag of tags; trackBy: trackBy">
          <rr-prefill-tag [tag]="tag" [topicId]="topicId" [topicTags]="topicTags"></rr-prefill-tag>
        </div>
      </div>
    </div>
    <!-- With multiple regions we use the panels to display them together -->
    <!-- TODO: default all to open [activeIds]="allRegionsActive" -->
    <div ngbAccordion *ngIf="regions.length > 0" class="d-flex flex-row flex-wrap">
      <div
        ngbAccordionItem
        *ngFor="let region of regions; let i = index"
        id="region-{{ i }}"
        class="region-card"
        [collapsed]="false"
      >
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="d-flex align-items-center">
            <span class="fw-bold"> <i class="fa fa-registered me-1"></i> {{ region.name }}</span>
            <rr-tag-copy class="ms-auto" [region]="region" [regions]="regions" [topicId]="topicId"></rr-tag-copy>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <div
              *ngFor="let tag of subsectionTags[region.name] | slice: 0 : 20; let i = index; trackBy: trackBy"
              id="tag-{{ i }}"
            >
              <rr-prefill-tag
                [tag]="tag"
                [topicId]="topicId"
                [showRegionText]="false"
                [topicTags]="topicTags"
              ></rr-prefill-tag>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
