import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { ScanCodeActions } from '../scan-code/scan-code.action';
import { SiteActions } from '../site/site.action';
import { UserActions } from '../user/user/user.action';

/**
 * Ngrx Actions for BookingCode
 *
 */
export const BookingCodeActions = {
  findAllSuccess: createAction('[BookingCode] Find All Success', props<{ bookingCodes: RR.BookingCode[] }>()),

  findManySuccess: createAction('[BookingCode] Find Many Success', props<{ bookingCodes: RR.BookingCode[] }>()),

  findSuccess: createAction('[BookingCode] Find Success', props<{ bookingCode: RR.BookingCode }>()),

  createSuccess: createAction('[BookingCode] Create Success', props<{ bookingCode: RR.BookingCode }>()),

  updateSuccess: createAction('[BookingCode] Update Success', props<{ bookingCode: RR.BookingCode }>()),

  deleteSuccess: createAction('[BookingCode] Delete Success', props<{ bookingCodeId: number }>()),
};

export const BookingCodeBatchActions = {
  updateBookingCodeAndScanCodeSuccessBatch: createBatchAction(
    '[BookingCode] Update Booking Code And Scan Code Success Batch',
    props<{
      actions: {
        updateBookingCodeSuccess: ReturnType<typeof BookingCodeActions.updateSuccess>;
        updateScanCodeSuccess: ReturnType<typeof ScanCodeActions.updateSuccess>;
      };
    }>(),
  ),
  updateBookingCodeAndSiteSuccessBatch: createBatchAction(
    '[BookingCode] Update Booking Code And Site Success Batch',
    props<{
      actions: {
        updateBookingCodeSuccess: ReturnType<typeof BookingCodeActions.updateSuccess>;
        updateSiteSuccess: ReturnType<typeof SiteActions.upsertOne>;
      };
    }>(),
  ),
  updateBookingCodeAndUserSuccessBatch: createBatchAction(
    '[BookingCode] Update Booking Code And User Success Batch',
    props<{
      actions: {
        updateBookingCodeSuccess: ReturnType<typeof BookingCodeActions.updateSuccess>;
        updateUserSuccess: ReturnType<typeof UserActions.upsertOne>;
      };
    }>(),
  ),
};
