<div class="modal-header">Which options do you want to remove from this title?</div>
<div class="modal-body">
  <div class="card p-1 bg-light flex-row">
    <div *ngFor="let wordList of option_array" class="d-flex align-items-center me-1">
      <span *ngIf="wordList.length === 1">{{ wordList[0].value }}</span>
      <div *ngIf="wordList.length > 1" class="btn-group-vertical">
        <label
          *ngFor="let word of wordList"
          class="btn btn-sm btn-light mb-0"
          [class.btn-danger]="word.checked"
          [class.btn-light]="!word.checked"
        >
          <input type="checkbox" [(ngModel)]="word.checked" /> {{ word.value }}
        </label>
      </div>
    </div>
  </div>
  <p>This will affect all future reports.</p>
</div>
<div class="modal-footer">
  <button
    role="button"
    class="btn btn-danger"
    type="button"
    (click)="remove()"
    [disabled]="!hasSelection && numberOfWordLists > 0"
  >
    Remove
  </button>
  <button role="button" class="btn btn-light" type="button" (click)="cancel()">Cancel</button>
</div>
