<p
  class="h2 fw-bold item-hover-parent section-heading mb-3"
  role="button"
  (click)="jumpToSection()"
  rrTooltip="NAVIGATE_MIDDLE_PANE"
  *ngIf="(show | async) || showOtherImaging()"
  id="{{ section_choice.type }}"
>
  {{ section_choice.heading
  }}<button
    (click)="removeSection(); $event.stopPropagation()"
    *ngIf="mode === 'editor'"
    class="item-hover btn btn-light btn-sm"
    role="button"
    rrTooltip="REMOVE_SENTENCES"
  >
    <span class="fa fa-trash"></span> Remove all
  </button>
</p>
<div *ngIf="showOtherImaging()" class="mb-2">
  <p class="h4 item-hover-parent" rrTooltip="EDIT_OTHER_IMAGING">
    OTHER IMAGING<button
      (click)="removeOtherImaging(); $event.stopPropagation()"
      *ngIf="mode === 'editor'"
      class="item-hover btn btn-light btn-sm"
      role="button"
      rrTooltip="REMOVE_SENTENCES"
    >
      <span class="fa fa-trash"></span> Remove
    </button>
    <button
      (click)="editOtherImaging(true); $event.stopPropagation()"
      *ngIf="mode === 'editor'"
      class="item-hover btn btn-light btn-sm ms-2"
      role="button"
      rrTooltip="EDIT_OTHER_IMAGING"
    >
      <span class="fa fa-edit"></span> Edit
    </button>
  </p>
  <div
    [innerText]="topic.other_imaging"
    *ngIf="!editingOtherImaging"
    rrTooltip="EDIT_OTHER_IMAGING"
    class="other-imaging"
  ></div>
  <div>
    <textarea *ngIf="editingOtherImaging" #otherImagingInput [rows]="6" class="form-control">{{
      topic.other_imaging
    }}</textarea>
    <div class="d-flex justify-content-end mt-1">
      <button
        (click)="editOtherImaging(false); $event.stopPropagation()"
        *ngIf="mode === 'editor' && editingOtherImaging"
        class="btn btn-secondary btn-sm"
        role="button"
      >
        <span class="fa fa-times"></span> Cancel
      </button>

      <button
        (click)="saveOtherImaging(); $event.stopPropagation()"
        *ngIf="mode === 'editor' && editingOtherImaging"
        class="btn btn-primary btn-sm ms-2"
        role="button"
      >
        <span class="fa fa-save"></span> Save
      </button>
    </div>
  </div>
</div>
