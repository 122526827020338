// Code from ng-diff-match-patch source
// https://github.com/elliotforbes/ng-diff-match-patch/blob/master/projects/ng-diff-match-patch/src/lib/processingDiff.directive.ts

import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';
import { Diff, DiffOp, DiffMatchPatch } from 'diff-match-patch-ts';

@Directive({
  selector: '[rrProcessingDiff]',
  standalone: true,
})
export class ProcessingDiffDirective implements OnInit, OnChanges {
  @Input() left = '';
  @Input() right = '';

  private dmp: DiffMatchPatch;

  public constructor(private el: ElementRef) {
    this.dmp = new DiffMatchPatch();
  }

  public ngOnInit(): void {
    this.updateHtml();
  }

  // eslint-disable-next-line no-restricted-syntax
  public ngOnChanges(): void {
    this.updateHtml();
  }

  private updateHtml(): void {
    this.el.nativeElement.innerHTML = this.createHtml(this.getProcessingDiff(this.left, this.right));
  }

  private getProcessingDiff(left: string, right: string) {
    const diffs = this.dmp.diff_main(left, right);
    this.dmp.diff_cleanupEfficiency(diffs);
    return diffs;
  }

  // TODO: Need to fix this for line diffs
  private createHtml(diffs: Array<Diff>): string {
    let html: string;
    html = '<div>';
    for (const diff of diffs) {
      diff[1] = diff[1].replace(/\n/g, '<br/>');

      if (diff[0] === DiffOp.Equal) {
        html += '<span class="equal">' + diff[1] + '</span>';
      }
      if (diff[0] === DiffOp.Delete) {
        html += '<del>' + diff[1] + '</del>';
      }
      if (diff[0] === DiffOp.Insert) {
        html += '<ins>' + diff[1] + '</ins>';
      }
    }
    html += '</div>';
    return html;
  }
}
