import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbPagination, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { getReportSections } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { TopicState } from 'app/store/editor';
import { PrefillSearchActions, PrefillSearchType, fromPrefillSearch } from 'app/store/prefill/prefill-search';
import { TagChoiceEffect, fromTagChoice } from 'app/store/prefill/tag-choice';
import { PresetEffect, PresetSearchResponse } from 'app/store/report/preset';
import { ReportExtractFeaturesResult, ReportHttpService, fromReport } from 'app/store/report/report';
import { fromTopic } from 'app/store/report/topic';
import { fromSession } from 'app/store/session';
import { fromRegion } from 'app/store/template/region';
import { fromSubsection } from 'app/store/template/subsection';
import { fromTemplate } from 'app/store/template/template';
import { fromUserSetting } from 'app/store/user/user-setting';
import { isEqual } from 'lodash-es';
import { TagInputComponent, TagInputModule } from 'ngx-chips';
import { TagModel } from 'ngx-chips/core/tag-model';
import {
  EMPTY,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  iif,
  map,
  merge,
  of,
  share,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import { SharedModule } from '../../../../shared/shared.module';
import { DividerStatementsModalComponent } from '../../divider/divider-statements-modal/divider-statements-modal.component';
import { PrefillRowComponent } from '../prefill-row/prefill-row.component';
import { PrefillSearchSelectionsComponent } from '../prefill-search-selections/prefill-search-selections.component';
import { PrefillService } from '../prefill.service';
import { ESResult, SubdivisionResult } from '../prefill.types';
import { PrefillPresetComponent } from '../preset/prefill-preset/prefill-preset.component';

type SearchFormTrigger = {
  value: PrefillSearchMetadataComponent['searchForm']['value'];
  searchTriggeredBy: 'searchForm';
};

type ManualSearchTrigger = {
  searchTriggeredBy: 'manual';
};

type TagInputTrigger = {
  value: string; // from tagInputText$
  searchTriggeredBy: 'searchInput';
};

export type TriggerUnion = SearchFormTrigger | ManualSearchTrigger | TagInputTrigger;

export type PrefillSearchBody = {
  search: {
    query: string;
    divider_id?: number | null;
    region_id?: number | null;
  }[];
  section: string;
  only: {
    template: boolean;
    title?: boolean;
    patient_sex?: boolean;
    my_favourites?: boolean;
    top_rated?: boolean;
  };
  boosts: {
    patient_dob?: boolean;
    created_date?: boolean;
    report_tags?: boolean;
    top_rated?: boolean;
  };
  user_id: number | undefined;
  accession_numbers?: string;
};

type SearchChip = {
  display: string;
  value: string;
  tag?: RR.TagChoice;
};

@Component({
  selector: 'rr-prefill-search-metadata',
  templateUrl: './prefill-search-metadata.component.html',
  styleUrls: ['./prefill-search-metadata.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    SharedModule,
    NgbPopover,
    CommonModule,
    NgbTooltip,
    PrefillSearchSelectionsComponent,
    PrefillRowComponent,
    NgbPagination,
    PrefillPresetComponent,
  ],
})
export class PrefillSearchMetadataComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic_id: number;
  topic_id$: Observable<number>;
  topic$: Observable<RR.Topic | undefined>;

  @Input() report_id: number;
  @Output() onResultsChanged = new EventEmitter();
  @Output() onPresetsChanged = new EventEmitter();

  @ViewChild('fullTextInput') fullTextInput: ElementRef<HTMLInputElement>;
  @ViewChild('presetTextInput') presetTextInput: ElementRef<HTMLInputElement>;
  @ViewChild('tagInput') tagInput?: TagInputComponent;

  searchForm = new FormGroup({
    page: new FormControl<number>(1, { nonNullable: true }),
    searches: new FormControl<SearchChip[]>([], { nonNullable: true }), // Value in searches form control should have SearchChip type
    fullText: new FormControl<string>('', { nonNullable: true }),
    searchType: new FormControl<PrefillSearchType>('ALL', { nonNullable: true }),
    templateMatch: new FormControl(true, { nonNullable: true }),
    titleMatch: new FormControl(false, { nonNullable: true }),
    sexMatch: new FormControl(false, { nonNullable: true }),
    myFavMatch: new FormControl(false, { nonNullable: true }),
    topRatedMatch: new FormControl(false, { nonNullable: true }),
    dobBoost: new FormControl(true, { nonNullable: true }),
    createdBoost: new FormControl(true, { nonNullable: true }),
    reportTagsMatch: new FormControl(true, { nonNullable: true }),
    topRatedBoost: new FormControl(false, { nonNullable: true }),
    imageLandmark: new FormControl(false, { nonNullable: true }),
    accessionNumbers: new FormControl<string>('', { nonNullable: true }),
    preset: new FormControl<string>('', { nonNullable: true }),
  });
  searching = false;

  count = 0;
  limit = 20;
  trackBy = trackById;
  result: ESResult | null;
  preset: PresetSearchResponse | undefined;
  // Subdivision search result when searching by subdivision checkbox is on
  subdivisionResults: SubdivisionResult[] = [];
  selectedSubdivision: RR.Subdivision | null;

  weightsForm: FormGroup = new FormGroup({
    weights: new FormArray([]),
  });

  // Subscription that listen to topic_id changed
  subscription = new Subscription();
  prefillExpanded$: Observable<true>;
  tagInputText$: Subject<string> = new Subject();
  prefillPreviewObject$: Observable<TopicState['prefillPreview']>;

  // Mirroring search text between prefill, global, and tag search
  // If search text is received from other search input, should not mirror it back to them
  globalSearchText = '';
  mirroring = false;

  // User who is editing the report, may not be the login user
  currentUser: RR.User | undefined;

  bestMatchForSubdivisions = true;
  mlTrainedTemplate$: Observable<boolean>;
  reportFeaturesExtracted$: Observable<boolean>;
  mlLandmarkTemplate$: Observable<boolean>;

  // Landmarks from image search
  landmarks: string[] = [];
  selectedLandmark: string | undefined;
  landmarkResult: ESResult;
  landmarkHotkeySubscription = new Subscription();

  // searches.value should have the type of SearchChip[]
  get searches() {
    return this.searchForm.controls.searches;
  }

  get searchType() {
    return this.searchForm.controls.searchType;
  }

  get searchFormValue() {
    return this.searchForm.value;
  }

  get templateMatch() {
    return this.searchForm.controls.templateMatch;
  }

  get titleMatch() {
    return this.searchForm.controls.titleMatch;
  }
  get sexMatch() {
    return this.searchForm.controls.sexMatch;
  }
  get myFavMatch() {
    return this.searchForm.controls.myFavMatch;
  }
  get topRatedMatch() {
    return this.searchForm.controls.topRatedMatch;
  }
  get dobBoost() {
    return this.searchForm.controls.dobBoost;
  }
  get createdBoost() {
    return this.searchForm.controls.createdBoost;
  }
  get reportTagsMatch() {
    return this.searchForm.controls.reportTagsMatch;
  }
  get topRatedBoost() {
    return this.searchForm.controls.topRatedBoost;
  }

  getWeightsControls(): AbstractControl[] {
    return (this.weightsForm.get('weights') as FormArray).controls;
  }

  constructor(
    private prefillService: PrefillService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private editorService: EditorService,
    private modal: NgbModal,
    private reportService: ReportService,
    private store: Store<AppState>,
    private tagChoiceEffect: TagChoiceEffect,
    private reportHttpService: ReportHttpService,
    private hotkeyService: HotkeysService,
    private presetEffect: PresetEffect,
  ) {
    this.prefillExpanded$ = this.editorService.prefill.pipe(filter((p): p is true => p));
  }

  ngOnInit(): void {
    this.initPrefillMetadataSearch();
    // Init search subscription to listen to search form changes, perform search, emit search results
    this.initSearchSubscription();

    // Apply user settings for prefill
    this.subscription.add(
      this.reportService
        .selectKioskUser()
        .pipe(
          filterDefined(),
          take(1),
          tap((user) => {
            this.currentUser = user;
          }),
          switchMap((user) =>
            iif(() => !!user, this.store.select(fromUserSetting.selectUserSetting(user.id)), of(undefined)),
          ),
          take(1),
        )
        .subscribe((userSetting) => {
          if (userSetting) {
            this.applyUserSettings(userSetting);
            this.cd.markForCheck();
          }
        }),
    );
  }

  initPrefillMetadataSearch() {
    this.subscription.add(
      this.topic_id$.subscribe((topicId) => {
        // Check if selected topic has the template that was already trained for ML image similarity
        // Auto switch to Images option if it's ML template and features were extracted
        this.checkMLTrainedTemplate(topicId);
      }),
    );

    // Handle landmark filter triggered by clicking on button in the prefill-copy
    this.subscription.add(
      this.prefillService.changeLandmarkFilter$.subscribe((landmark) => {
        if (this.searchType.value !== 'IMAGES' || !this.searchForm.controls.imageLandmark.value) {
          this.searchForm.patchValue({ searchType: 'IMAGES', imageLandmark: true });
        } else if (this.landmarks.includes(landmark)) {
          this.selectLandmark(landmark);
        }
      }),
    );

    this.topic$ = this.topic_id$.pipe(switchMap((topicId) => this.store.select(fromTopic.selectTopic(topicId))));
    this.prefillPreviewObject$ = this.topic_id$.pipe(
      switchMap((topicId) => this.prefillService.getPrefillPreviewObject(topicId)),
    );

    // Mirroring global search text to tag input
    this.subscription.add(
      this.editorService.globalSearchTerm$
        .pipe(
          debounceTime(200),
          filterDefined(),
          filter((e) => e.source !== 'PREFILL'),
        )
        .subscribe((globalSearch) => {
          this.globalSearchText = globalSearch.term;
          this.mirroring = true;
        }),
    );

    // Patch search value on tags selected or removed
    this.subscription.add(
      this.store
        .select(fromTagChoice.selectLoaded)
        .pipe(
          filter((loaded) => loaded),
          switchMap(() => this.store.select(fromTagChoice.selectSelected)),
          // distinctUntilChanged not working with array, need to provide comparison func for it
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        )
        .subscribe((tags) => {
          const searchValue: SearchChip[] = tags.map((tag) => ({
            display: tag.region_id ? `${tag.region_text} ${tag.tag_text}` : tag.tag_text ?? '',
            tag,
            value: tag.tag_text ?? '',
          }));
          // Combine selected tags and current manual search tags in prefill search input
          const manualTags = this.searches.value.filter((t) => !t.tag || !tags.find((tag) => tag.id === t.tag?.id));

          // Update the form control but not trigger the search again
          this.searches.setValue([...searchValue, ...manualTags], { emitEvent: false });
        }),
    );
  }

  initSearchSubscription() {
    // Save the searchForm state to the store
    this.subscription.add(
      this.searchForm.valueChanges.subscribe(() => {
        this.store.dispatch(
          PrefillSearchActions.saveSearchForm({
            searchForm: this.searchForm.getRawValue(),
          }),
        );
      }),
    );

    // Patch search form on search type changed, but not when triggered by "Save the searchForm state to the store"
    this.subscription.add(
      this.store
        .select(fromPrefillSearch.selectFeature)
        .pipe(
          // TODO: initialise the searchForm once from the store. Then only change the Form state directly and save
          // changes to the store.
          // This is to prevent loops: Form Change -> Save to Store -> patchValue -> Form Change again
          filter((searchState) => searchState.updateReason === 'UPDATE_FORM'),
        )
        .subscribe((searchState) => {
          if (searchState.searchForm !== null) {
            this.searchForm.patchValue(searchState.searchForm);
          } else if (searchState.searchFormInit !== null) {
            this.searchForm.patchValue(searchState.searchFormInit);
          }
        }),
    );

    this.subscription.add(
      this.topic_id$.subscribe(() => {
        // Reset search result on changing topic
        this.result = null;
      }),
    );

    const search$ = this.searchForm.valueChanges.pipe(distinctUntilChanged());

    const triggerSearch$: Observable<TriggerUnion> = merge(
      // refresh search if any of these emit new values
      search$.pipe(
        map((value) => {
          return {
            value,
            searchTriggeredBy: 'searchForm' as const,
          };
        }),
      ),
      this.prefillService.manualSearchSubject.pipe(
        map(() => {
          return {
            searchTriggeredBy: 'manual' as const,
          };
        }),
      ),
      this.tagInputText$.pipe(distinctUntilChanged()).pipe(
        map((value) => {
          return {
            value,
            searchTriggeredBy: 'searchInput' as const,
          };
        }),
      ),
    ).pipe(
      share(), // share the observable with the searchResultsUpdated subscription further down
    );

    let lastSearch: typeof this.searchForm.value | null = null;

    this.subscription.add(
      triggerSearch$
        .pipe(
          debounceTime(200),
          switchMap((searchReason) => {
            const changed = !isEqual(this.searchForm.value, lastSearch);
            // detect if the form has actually changed
            if (changed) {
              const pageChanged = lastSearch && lastSearch.page !== this.searchForm.value.page;
              if (!pageChanged) {
                this.searchForm.patchValue({ page: 1 }, { emitEvent: false });
              }
              this.searching = true;
              lastSearch = this.searchForm.value;
              this.cd.detectChanges();

              // Unsubscribe landmark hotkey if any
              this.landmarkHotkeySubscription.unsubscribe();

              return this.executeSearchBasedOnType(searchReason);
            } else {
              // If the search is the same as the last search (and we're not forcing a search), skip it
              return EMPTY;
            }
          }),
        )
        .subscribe({
          next: () => {
            this.searching = false;
            this.cd.detectChanges();
          },
        }),
    );

    this.subscription.add(
      this.prefillService.searchResultsUpdated
        .pipe(
          filter(
            ({ reason }) =>
              reason === undefined ||
              reason.searchTriggeredBy === 'searchForm' ||
              reason.searchTriggeredBy === 'searchInput' ||
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              reason.searchTriggeredBy === 'manual',
          ),
          // Wait for a second, if another search is triggered in this time, then don't emit onResultsChanged.
          switchMap(() => of(null).pipe(delay(1000), takeUntil(triggerSearch$))),
        )
        .subscribe(() => {
          // Outputs to PrefillSearchContainerComponent which selects the top 6 results
          if (this.searchType.value === 'IMAGES' && this.searchForm.controls.imageLandmark.value) {
            this.updateSearchResultForLandmark();
          } else {
            this.onResultsChanged.emit(this.result);
          }
        }),
    );

    this.subscription.add(
      this.prefillExpanded$
        .pipe(switchMap(() => this.searchType.valueChanges.pipe(startWith(this.searchType.value))))
        .subscribe((searchType) => {
          // When the searchType changes
          // 1. Focus the search input
          if (this.tagInput && searchType === 'ALL') {
            this.tagInput.inputForm.focus();
          }
          if (searchType === 'FULL_TEXT') {
            requestAnimationFrame(() => {
              this.fullTextInput.nativeElement.focus();
            });
          }
          if (searchType === 'PRESET') {
            requestAnimationFrame(() => {
              this.presetTextInput.nativeElement.focus();
            });
          }
          // 2. Clear the results because it will be refreshed anyway
          if (!(searchType === 'SUBDIVISION')) {
            // Switched to something other than SUBDIVISION
            this.subdivisionResults = [];
            this.selectedSubdivision = null;
            // Reset best match for subdivision in case there were results in previous search
            this.prefillService.prefillBySubdivisions$.next([]);
          }
          if (
            !(
              searchType === 'ALL' ||
              searchType === 'FAVOURITES' ||
              searchType === 'IMAGES' ||
              searchType === 'FULL_TEXT' ||
              searchType === 'RELATED_STUDIES'
            )
          ) {
            // Essentially switched from one of these to SUBDIVISION
            this.count = 0;
            this.result = null;
          }
        }),
    );

    // Initial preset search
    this.subscription.add(this.searchByPreset().pipe(take(1)).subscribe());
  }

  executeSearchBasedOnType(searchReason: TriggerUnion) {
    if (this.searchType.value === 'IMAGES') {
      return this.searchByImages(searchReason);
    } else if (this.searchType.value === 'SUBDIVISION') {
      return this.searchBySubdivision(searchReason);
    } else if (this.searchType.value === 'FAVOURITES') {
      return this.searchByFavourites(searchReason);
    } else if (this.searchType.value === 'PRESET') {
      return this.searchByPreset().pipe(this.messageService.handleHttpErrorPipe);
    } else if (this.searchType.value === 'FULL_TEXT') {
      return this.searchByFullText(searchReason);
    } else if (this.searchType.value === 'ALL') {
      return this.searchByAll(searchReason);
    } else if (this.searchType.value === 'DEBUG' && this.searchForm.controls.accessionNumbers.value) {
      return this.searchByAccessionNumber(searchReason);
    } else if (this.searchType.value === 'RELATED_STUDIES') {
      return this.searchByRelatedStudies(searchReason);
    } else {
      return EMPTY;
    }
  }

  defaultResponseHandler(json: ESResult, searchReason: TriggerUnion) {
    this.result = json;
    this.count = this.result.hits.total.value;
    this.prefillService.searchResultsUpdated.next({
      result: this.result,
      reason: searchReason,
    });
  }

  updateLandmarks(json: ESResult) {
    if (this.searchForm.controls.imageLandmark.value) {
      this.landmarks = [...new Set(json.hits.hits.flatMap((h) => h._source.landmarks))].sort((a, b) =>
        a.localeCompare(b),
      );

      if (this.landmarks.length > 0) {
        this.selectedLandmark = this.landmarks[0];
        this.registerLandmarkHotkeys();
      }
    } else {
      this.landmarks = [];
      this.selectedLandmark = undefined;
    }
  }

  updateSubdivisions(json: SubdivisionResult[]) {
    this.bestMatchForSubdivisions = true;
    if (json.length > 0) {
      this.subdivisionResults = json;
      this.selectBestMatchForSubdivisions();
      this.cd.detectChanges();
      return;
    } else {
      this.subdivisionResults = [];
      this.selectedSubdivision = null;
    }
  }

  getSearchBody(searchReason: TriggerUnion) {
    const search = this.searches.value.map((s: SearchChip) => ({
      query: s.value,
      divider_id: s.tag?.tag_id ?? null,
      region_id: s.tag?.region_id ?? null,
    }));

    if (searchReason.searchTriggeredBy === 'searchInput') {
      search.push({
        query: searchReason.value,
        divider_id: null,
        region_id: null,
      });
    }

    const body = {
      search: search,
      section: 'key_finding',
      only: {
        template: this.templateMatch.value,
        title: this.titleMatch.value,
        patient_sex: this.sexMatch.value,
        my_favourites: this.myFavMatch.value,
        top_rated: this.topRatedMatch.value,
      },
      boosts: {
        patient_dob: this.dobBoost.value,
        created_date: this.createdBoost.value,
        report_tags: this.reportTagsMatch.value,
        top_rated: this.topRatedBoost.value,
      },
      user_id: this.currentUser?.id,
    };
    return body;
  }

  searchByAll(searchReason: TriggerUnion): Observable<ESResult> {
    const body = this.getSearchBody(searchReason);
    const url = `/api/topic/${this.topic_id}/search`;
    return this.performPostSearch(url, body, searchReason);
  }

  searchByImages(searchReason: TriggerUnion): Observable<ESResult> {
    const landmark = this.searchForm.controls.imageLandmark.value ? 2 : 1;
    const url = `/api/ml/report/${this.topic_id}/search_by_images`;

    return this.http.post<ESResult>(url, { landmark }).pipe(
      tap((json: ESResult) => {
        const sortedJson = this.sortResults({ result: json, reverse: true });
        this.updateLandmarks(sortedJson);
        this.defaultResponseHandler(sortedJson, searchReason);
      }),
      this.messageService.handleHttpErrorPipe,
    );
  }

  searchBySubdivision(searchReason: TriggerUnion): Observable<SubdivisionResult[]> {
    const url = `/api/topic/${this.topic_id}/search/by_subdivision`;
    const body = this.getSearchBody(searchReason);
    return this.http.post<SubdivisionResult[]>(url, body).pipe(
      tap((json: SubdivisionResult[]) => {
        this.updateSubdivisions(json);
      }),
      this.messageService.handleHttpErrorPipe,
    );
  }

  searchByFavourites(searchReason: TriggerUnion): Observable<ESResult> {
    const url = `/api/topic/${this.topic_id}/search/favourites`;
    const body = this.getSearchBody(searchReason);
    body.boosts = {
      patient_dob: false,
      created_date: false,
      report_tags: false,
      top_rated: false,
    };
    body.only = {
      template: true,
      title: false,
      patient_sex: false,
      my_favourites: this.myFavMatch.value,
      top_rated: false,
    };

    return this.performPostSearch(url, body, searchReason);
  }

  searchByPreset() {
    return this.presetEffect
      .search(
        {
          topic_id: this.topic_id,
          search: this.searchForm.controls.preset.value,
        },
        {
          from: (this.searchForm.controls.page.value - 1) * this.limit,
          size: this.limit,
        },
      )
      .pipe(
        tap((action) => {
          this.preset = action.response;
          this.onPresetsChanged.emit(this.preset);
          // this.count = this.result.hits.total.value;
        }),
      );
  }

  searchByFullText(searchReason: TriggerUnion): Observable<ESResult> {
    const url = `/api/topic/${this.topic_id}/search/full_text`;
    const body = { search: [{ query: this.searchForm.controls.fullText.value }] };

    return this.performPostSearch(url, body, searchReason);
  }

  searchByRelatedStudies(searchReason: TriggerUnion) {
    const url = `/api/topic/${this.topic_id}/search/related_studies_prefill`;
    return this.http
      .get<ESResult>(url, {
        params: new HttpParams()
          .set('from', String((this.searchForm.controls.page.value - 1) * this.limit))
          .set('size', String(this.limit)),
      })
      .pipe(
        this.messageService.handleHttpErrorPipe,
        tap((json) => {
          const topic_ids = json.hits.hits.map((hit) => hit._id);

          this.prefillService.setPrefillPreviewTopics({
            openTopicId: this.topic_id,
            topicIds: topic_ids,
            forceReload: true,
          });

          this.defaultResponseHandler(json, searchReason);
        }),
      );
  }

  performPostSearch(url: string, body: Partial<PrefillSearchBody>, searchReason: TriggerUnion): Observable<ESResult> {
    return this.http
      .post<ESResult>(url, body, {
        params: new HttpParams()
          .set('from', String((this.searchForm.controls.page.value - 1) * this.limit))
          .set('size', String(this.limit)),
      })
      .pipe(
        this.messageService.handleHttpErrorPipe,
        tap((json: ESResult) => {
          this.defaultResponseHandler(json, searchReason);
        }),
      );
  }

  searchByAccessionNumber(searchReason: TriggerUnion) {
    const url = `/api/debug/prefill_interface_with_accession_samples`;
    const body = {
      accession_numbers: this.searchForm.controls.accessionNumbers.value,
    };

    return this.performPostSearch(url, body, searchReason);
  }

  updateWeight(weightId: number) {
    const weightsArray = this.weightsForm.get('weights') as FormArray;
    const weightGroup = weightsArray.controls.find((control) => control.get('id')?.value === weightId) as FormGroup;

    const newValues = {
      weight: weightGroup.get('weight')?.value,
      description: weightGroup.get('description')?.value,
    };

    this.subscription.add(
      this.reportHttpService.updateImgSimWeight(weightId, newValues).subscribe({
        next: (updatedWeight) => {
          weightGroup.patchValue(
            {
              weight: updatedWeight.weight,
              description: updatedWeight.description,
            },
            { emitEvent: false },
          );

          this.messageService.add({
            title: 'Success',
            message: 'Update successful',
            type: 'success',
          });
        },
        error: (err: unknown) => {
          this.messageService.httpErrorMessage(err);
        },
      }),
    );
  }

  checkMLTrainedTemplate(topicId: number) {
    const template$ = this.store.select(fromTemplate.selectTemplateFromTopic(topicId));

    this.mlTrainedTemplate$ = template$.pipe(
      withLatestFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined())),
      map(([t, rrConfig]) => !!t && rrConfig.IMGSIM_WHITELIST_TEMPLATES.includes(t.id)),
    );

    this.mlLandmarkTemplate$ = template$.pipe(
      withLatestFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined())),
      map(([t, rrConfig]) => !!t && rrConfig.IMGSIM_LANDMARK_TEMPLATES.includes(t.id)),
    );

    this.subscription.add(
      template$
        .pipe(
          withLatestFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined())),
          filter(([_, rrConfig]) => rrConfig.IMAGE_SIMILARITY),
          map(([template, _rrConfig]) => template),
          switchMap((template) => (template ? this.reportHttpService.getImgSimWeights(template.id) : of(null))),
        )
        .subscribe((data) => {
          const weights = data && data.weights;
          if (weights) {
            weights.sort((a, b) => a.id - b.id);
            const weightsArray = this.weightsForm.get('weights') as FormArray;
            weightsArray.clear();
            weights.forEach((weight) => {
              const weightGroup = new FormGroup({
                id: new FormControl(weight.id),
                template_id: new FormControl(weight.template_id),
                column_name: new FormControl(weight.column_name),
                weight: new FormControl(weight.weight),
                description: new FormControl(weight.description),
              });
              weightsArray.push(weightGroup);
            });
          }
        }),
    );

    const accession_number$ = this.store.select(fromReport.selectReport(this.report_id)).pipe(
      filterDefined(),
      map((r: RR.Report) => r.accession_number),
    );

    this.reportFeaturesExtracted$ = combineLatest([
      template$.pipe(
        filterDefined(),
        map((t) => t.id),
      ),
      accession_number$.pipe(filterDefined()),
    ]).pipe(
      distinctUntilChanged(),
      withLatestFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined())),
      filter(([_, rrConfig]) => rrConfig.IMAGE_SIMILARITY),
      switchMap(([[tid, accNo], _]) =>
        this.http.post<{ extracted: boolean }>(`/api/imgsim/features_extracted`, {
          accession_number: accNo,
          template_id: tid,
        }),
      ),
      map((r) => r.extracted),
      share(),
    );

    // Auto switch to Images option if template was trained and report features were extracted
    this.subscription.add(
      combineLatest([this.mlTrainedTemplate$, this.reportFeaturesExtracted$])
        .pipe(take(1))
        .subscribe(([trained, extracted]) => {
          if (trained && extracted) {
            this.searchForm.patchValue({ searchType: 'IMAGES' });
          }
        }),
    );

    // default landmark selection to true if landmark is available
    this.subscription.add(
      this.mlLandmarkTemplate$.subscribe((mlLandmarkTemplate) => {
        if (mlLandmarkTemplate) {
          this.searchForm.controls.imageLandmark.setValue(true);
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.landmarkHotkeySubscription.unsubscribe();
  }

  onPageChange(page: number) {
    if (this.searchForm.controls.page.value !== page) {
      this.searchForm.controls.page.setValue(page);
    }
  }

  sortResults({ result, reverse = false }: { result: ESResult; reverse?: boolean }) {
    result.hits.hits = result.hits.hits.sort((a, b) => {
      const scoreA = a._score;
      const scoreB = b._score;
      return scoreB - scoreA;
    });
    if (reverse) {
      result.hits.hits = result.hits.hits.reverse();
    }
    return result;
  }

  applyUserSettings(userSetting: RR.UserSetting) {
    // if it's null, then leave the default which is already set in the searchForm initialisation
    this.titleMatch.setValue(userSetting.prefill_match_title);
    this.sexMatch.setValue(userSetting.prefill_match_sex);
    this.dobBoost.setValue(userSetting.prefill_boost_dob);
    this.myFavMatch.setValue(userSetting.prefill_match_my_case);
    this.createdBoost.setValue(userSetting.prefill_boost_latest);
    this.reportTagsMatch.setValue(userSetting.prefill_boost_report_tags);
  }

  onTagRemoved(tag: SearchChip) {
    let value: SearchChip[];
    // Remove selected tags in the prefill search
    if (tag.tag) {
      value = this.searches.value.filter((t: SearchChip) => t.tag?.tag_id !== tag.tag?.tag_id);
      // Update store and db
      this.subscription.add(this.tagChoiceEffect.deleteTagChoice(this.topic_id, String(tag.tag.id)).subscribe());
    } else {
      // Remove custom input tags in the prefill search
      value = this.searches.value.filter((t: SearchChip) => t.value !== tag.value);
    }

    this.searches.setValue(value);
  }

  onChipClick(tag: SearchChip) {
    if (!tag.tag || !tag.tag.tag_id) return;
    DividerStatementsModalComponent.open({
      modalService: this.modal,
      topic_id: this.topic_id,
      divider_id: tag.tag.tag_id,
      region_id: tag.tag.region_id,
      parent: 'PREFILL',
    });
  }

  onTagInputTextChanged(text: TagModel) {
    if (!(typeof text === 'string')) {
      throw new Error('text not string');
    }
    if (!this.mirroring) {
      this.editorService.globalSearchTerm$.next({ source: 'PREFILL', term: text });
    } else {
      this.mirroring = false;
    }
    this.tagInputText$.next(text);
  }

  checkHighlightTag(tag: RR.TagChoice): Observable<boolean> {
    return this.store.select(fromTagChoice.isSelected(tag.id));
  }

  onSelectedSubdivisionChanged() {
    this.result =
      this.subdivisionResults.find((r) => r.subdivision === this.selectedSubdivision)?.search_result || null;
    this.count = this.result?.hits.total.value || 0;
    if (this.result) {
      this.result = this.sortResults({ result: this.result });
    }
    this.prefillService.searchResultsUpdated.next({ result: this.result });
  }

  getSubdivisionText(subdivision: RR.Subdivision) {
    return combineLatest([
      of(getReportSections().find((s) => s.name === subdivision.section)?.abbrTitle),

      this.store
        .select(fromSubsection.selectSubsection(subdivision.subsection_id))
        .pipe(map((subsection) => subsection?.name)),

      subdivision.region_id
        ? this.store.select(fromRegion.selectRegion(subdivision.region_id)).pipe(map((region) => region?.name))
        : of(null),
    ]).pipe(
      map(([section, subsection, region]) => `${section} / ${subsection || 'NO NAME'}${region ? ' / ' + region : ''}`),
    );
  }

  toggleBestMatchForSubdivisions() {
    this.bestMatchForSubdivisions = !this.bestMatchForSubdivisions;
    if (!this.bestMatchForSubdivisions && this.subdivisionResults.length > 0) {
      this.selectedSubdivision = this.subdivisionResults[0].subdivision;
      this.onSelectedSubdivisionChanged();
      this.prefillService.prefillBySubdivisions$.next([]);
    } else {
      this.selectBestMatchForSubdivisions();
    }
  }

  selectBestMatchForSubdivisions() {
    // Get top 3 topics for each subdivisions
    const subdivisionBestMatches = this.subdivisionResults.map((s) => ({
      subdivision: s.subdivision,
      topicIds: s.search_result.hits.hits.slice(0, 3).map((esTopic) => esTopic._id),
    }));
    const topicIds = new Set(subdivisionBestMatches.map((m) => m.topicIds).flat());
    this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: [...topicIds] });
    this.prefillService.prefillBySubdivisions$.next(subdivisionBestMatches);
  }

  hasPriorRelatedTopic() {
    return this.reportService.getLatestRelatedTopic(this.topic_id, this.report_id).pipe(map((topic) => !!topic));
  }

  extractImageFeatures() {
    this.reportHttpService
      .extractImageFeatures(this.report_id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((response: ReportExtractFeaturesResult) => {
        if (response.result === 'OK') {
          this.messageService.add({
            type: 'success',
            title: 'Success',
            message: 'Extract feature success!',
          });
          this.reportFeaturesExtracted$ = of(true);
          this.cd.detectChanges();
        } else {
          this.messageService.add({
            type: 'danger',
            title: 'Error',
            message: 'Extract feature failed!',
          });
        }
      });
  }

  selectLandmark(l: string) {
    this.selectedLandmark = l;
    this.updateSearchResultForLandmark();
  }

  updateSearchResultForLandmark() {
    if (this.result) {
      const hits = this.result.hits.hits.filter((t) => {
        const sourceLandmarks = t._source.landmarks;

        // Check if the selectedLandmark is present in the array of landmarks
        return Array.isArray(sourceLandmarks) && sourceLandmarks.includes(this.selectedLandmark || '');
      });

      this.landmarkResult = { ...this.result, hits: { hits, total: { value: hits.length } } };
      this.onResultsChanged.emit(this.landmarkResult);
    }
  }

  selectNextOrPrevLandmark(step: number) {
    if (this.landmarks.length === 0) return;
    if (!this.selectedLandmark) {
      this.selectLandmark(this.landmarks[0]);
      return;
    }
    const index = this.landmarks.indexOf(this.selectedLandmark) + step;
    if (index < 0) {
      this.selectLandmark(this.landmarks[0]);
    } else if (index > this.landmarks.length - 1) {
      this.selectLandmark(this.landmarks[this.landmarks.length - 1]);
    } else {
      this.selectLandmark(this.landmarks[index]);
    }
  }

  registerLandmarkHotkeys() {
    this.landmarkHotkeySubscription = new Subscription();
    this.landmarkHotkeySubscription.add(
      this.hotkeyService.addShortcut({ keys: `shift.ArrowRight` }).subscribe(() => {
        this.selectNextOrPrevLandmark(1);
      }),
    );

    this.landmarkHotkeySubscription.add(
      this.hotkeyService.addShortcut({ keys: `shift.ArrowLeft` }).subscribe(() => {
        this.selectNextOrPrevLandmark(-1);
      }),
    );
  }
}
