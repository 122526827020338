import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReportActions } from '../report/report/report.action';
import { ElementActions } from '../template/element/element.action';
import { RegionActions } from '../template/region/region.action';
import { SectionActions } from '../template/section/section.action';
import { StatementCoincidenceActions } from '../template/statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../template/statement-set/statement-set.action';
import { StatementActions } from '../template/statement/statement.action';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { TextObjectActions } from '../template/text-object/text-object.action';
import { DicomActions } from './dicom.action';
import { DicomHttpService } from './dicom.service';

@Injectable()
export class DicomEffect {
  constructor(
    private store: Store<AppState>,
    private service: DicomHttpService,
  ) {}

  loadMeasurements(reportId: number) {
    return this.service.loadMeasurements(reportId).pipe(
      map((response) => {
        return DicomActions.loadMeasurementsSuccess({ response });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  queryDicom(reportId: number) {
    return this.service.queryDicom(reportId).pipe(
      map((report) => {
        return ReportActions.upsertOne({ report });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  propose(topicId: number) {
    return this.service.propose(topicId).pipe(
      map(
        ({
          datasets,
          sections,
          subsections,
          regions,
          elements,
          statement_sets,
          statements,
          dividers,
          text_objects,
          statement_coincidences,
        }) => {
          return DicomActions.findProposedSuccess({
            datasets,
            actions: {
              findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
                actions: {
                  sectionAddMany: SectionActions.addMany({ sections: sections }),
                  subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
                  regionAddMany: RegionActions.addMany({ regions: regions }),
                  elementAddMany: ElementActions.addMany({ elements: elements }),
                  statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
                  statementAddMany: StatementActions.addMany({ statements: statements }),
                  dividerAddMany: StatementActions.addMany({ statements: dividers }),
                  textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
                  statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
                    statementCoincidences: statement_coincidences,
                  }),
                },
              }),
            },
          });
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
