import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { forkJoin } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LandmarkLabelEffect } from '../../landmark-label/landmark-label.effect';
import { MandatoryStatementEffect } from '../../mandatory-statement/mandatory-statement.effect';
import { TagActions } from '../../prefill/tag/tag.action';
import { AttributeSetEffect } from '../../template/attribute-set/attribute-set.effect';
import { ElementEffect } from '../../template/element/element.effect';
import { RegionSetEffect } from '../../template/region-set/region-set.effect';
import { RegionEffect } from '../../template/region/region.effect';
import { SectionEffect } from '../../template/section/section.effect';
import { StatementSetEffect } from '../../template/statement-set/statement-set.effect';
import { SubsectionEffect } from '../../template/subsection/subsection.effect';
import { TitleSetEffect } from '../../title/title-set/title-set.effect';
import { AttributeOptionActions } from '../attribute-option/attribute-option.action';
import { AttributeSetActions } from '../attribute-set/attribute-set.action';
import { DefaultAttributeActions } from '../default-attribute/default-attribute.action';
import { ElementActions } from '../element/element.action';
import { RegionSetActions } from '../region-set/region-set.action';
import { RegionActions } from '../region/region.action';
import { StatementCoincidenceActions } from '../statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../statement-set/statement-set.action';
import { StatementActions } from '../statement/statement.action';
import { SubsectionActions } from '../subsection/subsection.action';
import { TemplateActions, TemplateBatchActions } from './template.action';
import { fromTemplate } from './template.selector';
import { TemplateHttpService } from './template.service';

@Injectable()
export class TemplateEffect {
  constructor(
    private store: Store<AppState>,
    private service: TemplateHttpService,
    private message: MessageService,
    private sectionEffect: SectionEffect,
    private subsectionEffect: SubsectionEffect,
    private regionSetEffect: RegionSetEffect,
    private regionEffect: RegionEffect,
    private statementSetEffect: StatementSetEffect,
    private elementEffect: ElementEffect,
    private attributeSetEffect: AttributeSetEffect,
    private titleSetEffect: TitleSetEffect,
    private landmarkLabelEffect: LandmarkLabelEffect,
    private mandatoryStatementEffect: MandatoryStatementEffect,
  ) {}

  /**
   * Find all the templates
   */
  findAll() {
    return this.service.findAll().pipe(
      this.message.handleHttpErrorPipe,
      map((templates) => TemplateActions.findAllSuccess({ templates })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  find(templateId: number) {
    return this.service.find(templateId).pipe(
      this.message.handleHttpErrorPipe,
      map((template) => TemplateActions.findSuccess({ template })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(template: Partial<RR.Template>) {
    return this.service.create(template).pipe(
      this.message.handleHttpErrorPipe,
      map((templateCreated) => TemplateActions.createSuccess({ template: templateCreated })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  // @ts-expect-error noImplicitAny
  update(templateId, changes: Partial<RR.Template>) {
    return this.service.update(templateId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((template: RR.Template) => TemplateActions.updateSuccess({ template })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(templateId: number) {
    return this.service.delete(templateId).pipe(
      this.message.handleHttpErrorPipe,
      map(() => TemplateActions.deleteSuccess({ templateId })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  resetAll() {
    this.store.dispatch(
      TemplateBatchActions.resetAllBatchAction({
        actions: {
          resetSubsectionAction: SubsectionActions.reset(),
          resetElementAction: ElementActions.reset(),
          resetRegionSetAction: RegionSetActions.reset(),
          resetRegionAction: RegionActions.reset(),
          resetStatementSetAction: StatementSetActions.reset(),
          resetStatementAction: StatementActions.reset(),
          resetDefaultAttributeAction: DefaultAttributeActions.reset(),
          resetStatementCoincidenceAction: StatementCoincidenceActions.reset(),
          resetTagAction: TagActions.reset(),
          resetAttributeSetAction: AttributeSetActions.reset(),
          resetAttributeOptionAction: AttributeOptionActions.reset(),
        },
      }),
    );
  }

  loadTemplate(templateId: number) {
    // The following are now loaded with `findInStatementSet`
    // this.defaultAttributeEffect.findInTemplate(templateId);
    // this.statementEffect.findInTemplate(templateId);
    // this.textObjectEffect.findInTemplate(templateId);
    // this.statementCoincidenceEffect.findInTemplate(templateId);
    return this.store.select(fromTemplate.selectLoaded(templateId)).pipe(
      take(1),
      filter((loaded) => {
        return !(loaded === 'loaded' || loaded === 'loading');
      }),
      tap(() => {
        this.store.dispatch(TemplateActions.loadTemplate({ templateId }));
      }),
      switchMap(() => {
        return forkJoin([
          this.attributeSetEffect.findAll(),
          this.sectionEffect.findInTemplate(templateId),
          this.subsectionEffect.findInTemplate(templateId),
          this.elementEffect.findInTemplate(templateId),
          this.regionSetEffect.findInTemplate(templateId),
          this.regionEffect.findInTemplate(templateId),
          this.statementSetEffect.findInTemplate(templateId),
          this.titleSetEffect.findInTemplate(templateId),
          this.landmarkLabelEffect.findInTemplate(templateId),
          this.mandatoryStatementEffect.findInTemplate(templateId),
          this.find(templateId),
        ]);
      }),
      this.message.handleHttpErrorPipe,
      tap(() => {
        this.store.dispatch(TemplateActions.loadTemplateSuccess({ templateId }));
      }),
    );
  }
}
