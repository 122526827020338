import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { InstitutionFormViewComponent } from 'app/modules/invoice/components/institution-form-view/institution-form-view.component';
import { AppState } from 'app/store';
import { fromInstitution, InstitutionEffect, InstitutionHttpService } from 'app/store/institution';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { institutionTypeMap } from '../../invoice.constants';
import { CreateInstitutionModalComponent } from '../../modals/create-institution-modal/create-institution-modal.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbModule, InstitutionFormViewComponent],
  selector: 'rr-select-institution',
  templateUrl: './select-institution.component.html',
  styleUrls: ['./select-institution.component.css'],
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class SelectInstitutionComponent implements OnInit {
  @Output() onInstitutionSelect = new EventEmitter<RR.Institution | null>();
  @Input() @BindObservable() institution_id: number | undefined;
  institution_id$: Observable<number | undefined>;
  institution$: Observable<RR.Institution | undefined>;
  institution: RR.Institution | undefined;

  focus$ = new Subject<string>();

  institutionTypeMap = institutionTypeMap;

  searchText = new FormControl('', { nonNullable: true });

  subscription = new Subscription();

  constructor(
    private modal: NgbModal,
    private store: Store<AppState>,
    private institutionEffect: InstitutionEffect,
    private institutionService: InstitutionHttpService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.institution$ = this.institution_id$.pipe(
      switchMap((id) => (id ? this.store.select(fromInstitution.selectInstitution(id)) : of(undefined))),
    );

    this.subscription.add(
      this.institution$.subscribe((i) => {
        this.institution = i;
      }),
    );
  }

  createNewInstitution() {
    const modelRef = CreateInstitutionModalComponent.open(this.modal);
    modelRef.result.then((ins: RR.Institution) => {
      this.onInstitutionSelect.emit(ins);
    });
  }

  editInstitution() {
    if (this.institution_id) {
      CreateInstitutionModalComponent.open(this.modal, this.institution_id);
    }
  }

  removeInstitution() {
    this.searchText.reset();
    this.onInstitutionSelect.emit(null);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestInstitutions = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.focus$).pipe(
      switchMap((text) =>
        this.search(text).pipe(
          map((result) => result.institutions),
          this.messageService.handleHttpErrorPipe,
        ),
      ),
    );
  };

  search(text: string) {
    return this.institutionService.elasticSearch({
      name: text,
      phone: text,
      contact_person: text,
      email: text,
    });
  }

  selectInstitution(ins: RR.Institution) {
    // Fetch again because ES results are not added to store
    this.subscription.add(
      this.institutionEffect.find(ins.id).subscribe(() => {
        this.onInstitutionSelect.emit(ins);
      }),
    );
  }
}
