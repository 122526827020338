import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { JustificationEffect } from 'app/store/report/justification';
import { JustificationPayload } from 'app/store/report/justification/justification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rr-justification-modal',
  templateUrl: './justification-modal.component.html',
  styleUrls: ['./justification-modal.component.css'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class JustificationModalComponent implements OnInit, OnDestroy {
  justificationText = new FormControl<undefined | string>(undefined, { nonNullable: true });
  topic: RR.Topic;
  mandatoryStatementId: number | undefined;

  subscription = new Subscription();
  constructor(
    public activeModal: NgbActiveModal,
    private justificationEffect: JustificationEffect,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {}

  static open(
    modalService: NgbModal,
    {
      topic,
      mandatoryStatementId,
    }: {
      topic: RR.Topic;
      mandatoryStatementId: number | undefined;
    },
  ) {
    const modalRef = modalService.open(JustificationModalComponent, {
      size: 'lg',
      scrollable: true,
    });

    const component: JustificationModalComponent = modalRef.componentInstance;
    component.topic = topic;
    component.mandatoryStatementId = mandatoryStatementId;

    return modalRef;
  }

  sendJustification() {
    const mandatory_id = this.mandatoryStatementId;

    if (mandatory_id === undefined) {
      return;
    }
    const data: JustificationPayload = {
      justification: this.justificationText.value || '',
      mandatory_statement_id: mandatory_id,
      topic_id: this.topic.id,
    };

    this.subscription.add(
      this.justificationEffect.create(this.topic.id, data).subscribe({
        next: () => {
          this.messageService.add({
            title: 'Success',
            type: 'success',
            message: 'Justification Sent',
          });

          this.activeModal.dismiss();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
