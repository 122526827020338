import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

type Arr = readonly unknown[];

/**
 *
 * The pipe only executes again if the inputs change. This avoids a function call in the template, which would run on
 * every CD cycle, like so: `{{ selectBookingCodeFn(bookingCodeId) | async }}`
 *
 * Basic Usage:
 *
 * Component:
 * ```ts
 * bookingCodeId: number = 123
 * selectBookingCodeFn = (bookingCodeId: number) => {
 *   return this.store.select(fromBookingCode.selectBookingCode(bookingCodeId));
 * };
 * ```
 *
 * Template:
 * ```html
 * {{ (bookingCodeId | selectPipe: selectBookingCodeFn | async) }}
 * ```
 *
 *
 * Complex usage (pass other arguments to the Pipe - which then get passed to the wrapped function):
 *
 * ```ts
 * selectSomething(id: number, anotherNumber: number, someText: string) {
 *   ...etc
 * }
 * ```html
 * {{ (input | selectPipe: selectSomething:123:"some text" | async) }}
 * ```
 *
 */
@Pipe({ name: 'selectPipe', standalone: true, pure: true })
export class SelectPipe implements PipeTransform {
  transform<T, U, V extends Arr>(
    ids: U,
    fnReference: (ids: U, ...args: [...V]) => Observable<T>,
    ...args: V
  ): Observable<T> {
    return fnReference(ids, ...args);
  }
}
