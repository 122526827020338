<div class="modal-header">
  <h5 class="modal-title">Medical Note</h5>

  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body pb-0">
  <div>
    <form class="form-group mb-2 d-flex">
      <input
        class="form-control border-secondary"
        type="text"
        placeholder="Please enter your note"
        [(ngModel)]="note"
        name="note"
        autocomplete="off"
        #noteInput
      />

      <button class="btn btn-primary ms-2" (click)="addTodo()">Save</button>
    </form>
  </div>
</div>
