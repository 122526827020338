<div class="container-fluid">
  <div class="mt-2 d-flex flex-column float-start sticky-top col-sm-2 px-0">
    <rr-document-attachment-button
      *ngIf="report"
      [bookingId]="report.booking_id"
      [size]="'lg'"
      [btnClassName]="'btn-primary'"
      [dismissActiveModal]="true"
      [expand]="true"
    ></rr-document-attachment-button>

    <ng-container *ngIf="report">
      <rr-report-notes-button
        *ngIf="report"
        [btnClass]="'btn-primary'"
        [small]="false"
        class="mt-2 w-100"
        [report_id]="report.id"
        [expand]="true"
      ></rr-report-notes-button>
    </ng-container>
    <a
      *ngIf="report"
      class="btn btn-primary mt-2"
      routerLink="/registration/booking"
      target="_blank"
      [queryParams]="{ self: true, bookingId: report.booking_id }"
      title="Open a new window to see booking information"
      rrTooltip="VIEW_BOOKING"
    >
      View booking info
    </a>

    <rr-db4-form class="mt-2" *ngIf="!!report" [disabled]="patient && !patient.medicare_number" [reportId]="report.id">
      DB4 Form
    </rr-db4-form>
    <div class="mt-2">
      <rr-all-form *ngIf="report" [availableForms]="availableForms" [reportId]="report.id"></rr-all-form>
    </div>

    <ul class="mt-2 d-flex flex-column list-group">
      <li
        (click)="registrationService.focus('patient-form')"
        class="list-group-item list-group-item-action list-group-item-dark pointer"
      >
        <strong>Shift + P:</strong>
        <div>Patient Details</div>
      </li>
      <li
        (click)="registrationService.focus('referrer-form')"
        class="list-group-item list-group-item-action list-group-item-dark pointer"
      >
        <strong>Shift + R:</strong>
        <div>Referrer Details</div>
      </li>
      <li
        (click)="registrationService.focus('report-form')"
        class="list-group-item list-group-item-action list-group-item-dark pointer"
      >
        <strong>Shift + B:</strong>
        <div>Patient Scan Registration</div>
      </li>
    </ul>
    <!--Registration events-->
    <div *ngIf="report" class="mt-2">
      <rr-registration-events [report_id]="report.id"></rr-registration-events>
    </div>
  </div>

  <div class="container mt-2">
    <div class="mt-1 mb-2 col-sm-9 mx-auto d-flex align-items-center p-1 bg-light border rounded">
      <div class="mx-2"><strong>Help: </strong></div>
      <rr-documentation-link-button [buttonId]="10"></rr-documentation-link-button>
      <rr-documentation-link-button [buttonId]="11"></rr-documentation-link-button>
      <rr-documentation-link-button [buttonId]="12"></rr-documentation-link-button>
      <rr-documentation-link-button [buttonId]="13"></rr-documentation-link-button>
    </div>
    <div class="p-2 col-sm-9 mx-auto bg-light rounded mb-2">
      <div data-cy="registrationMetadata" class="row align-items-center">
        <div class="col">
          <rr-report-accession-number *ngIf="report" [report]="report"></rr-report-accession-number>
          <p *ngIf="!report">Accession Number: None</p>
        </div>
        <div class="col">
          <strong>Patient:</strong>
          <p>{{ patient ? patient.patient_first_name + ' ' + patient.patient_last_name : 'None' }}</p>
        </div>
        <div class="col">
          <strong>Referrer:</strong>
          <ng-container *ngIf="referrer">
            <p><rr-referrer-name [referrerId]="referrer.id" [referrerType]="true"></rr-referrer-name></p>
          </ng-container>
          <p *ngIf="!referrer">None</p>
        </div>
      </div>
    </div>

    <div class="col-9 mx-auto my-4">
      <div class="d-flex flex-column mb-3">
        <!-- If there's other sameDayBookings, warn and show user those bookings -->
        <!-- Or, warn if a report has been registered for the booking already - to prevent multiple reports for a single booking -->
        <ng-container *ngIf="sameDayBookingIds.length > 1">
          <h5 *ngIf="booking && booking.id === bookingId && booking.reports.length > 0">
            <strong>
              <span class="fa fa-lg fa-exclamation-triangle text-danger"></span>
              This booking has been registered.
            </strong>
          </h5>
          <rr-same-day-bookings
            (onSelectBooking)="changeSameDayBooking($event)"
            [sameDayBookingIds]="sameDayBookingIds"
            [bookingId]="bookingId"
          ></rr-same-day-bookings>
        </ng-container>
      </div>

      <rr-booking-view-summary
        *ngIf="booking || bookingId"
        [context]="'REGISTRATION'"
        [bookingId]="(booking && booking.id) || bookingId"
      ></rr-booking-view-summary>
    </div>

    <div class="mt-4"></div>
    <div class="col-sm-9 mx-auto">
      <h2 class="text-center mb-2">
        <strong>Step 1 - Funder</strong>
      </h2>

      <select class="form-select" [formControl]="funder">
        <option *ngFor="let funder of funders" [value]="funder.id">{{ funder.name }}</option>
      </select>
    </div>

    <div class="my-4"></div>
    <rr-patient-form
      [bookingId]="bookingId"
      [patient]="patient"
      [parent]="'REGISTRATION'"
      (onChange)="onPatientChanged($event)"
      header="Step 2 - Patient Details"
    ></rr-patient-form>
    <div class="col-9 mx-auto">
      <rr-notes
        *ngIf="patient"
        [patientId]="patient.id"
        [reportId]="report?.id"
        [type]="'patient'"
        [minimalView]="true"
      >
        <button role="button" class="btn btn-outline-success" (click)="openCreateNoteModal({ patientId: patient.id })">
          <i class="fas fa-plus"></i>
          Add Note
        </button>
      </rr-notes>
    </div>

    <div *ngIf="!report && patient" class="my-4 col-9 mx-auto">
      <rr-same-day-registrations [patientId]="patient.id"></rr-same-day-registrations>
    </div>

    <div class="my-4"></div>
    <rr-referrer-form
      [referrer]="referrer"
      [parent]="'REPORT'"
      [bookingId]="bookingId ?? undefined"
      [reportId]="report?.id"
      header="Step 3 - Referrer Details"
      (onChange)="onReferrerChanged($event)"
      (onNoReferrerChange)="onNoReferrerChange($event)"
      [noReferrer]="noReferrer"
    ></rr-referrer-form>

    <div class="my-4"></div>
    <rr-report-form
      *ngIf="!loadingReport"
      [bookingId]="bookingId"
      [report]="report"
      [patient]="patient"
      [referrer]="referrer"
      [noReferrer]="noReferrer"
      (onChange)="onReportChanged($event)"
      header="Step 4 - Patient Scan Registration"
    ></rr-report-form>

    <!-- If existing registration -->
    <div class="d-flex col-9 mx-auto my-4">
      <ng-container *ngIf="report">
        <a
          type="button"
          class="btn btn-outline-primary ms-auto"
          routerLink="/registration/report"
          [queryParams]="{
            patientId: report.patient_id,
            referrerId: report.referrer_id
          }"
          rrTooltip="REGISTER_NEW_PATIENT_SCAN"
        >
          Create Walk-in Booking and Register New Scan for this Patient
        </a>
      </ng-container>
    </div>

    <div class="my-4"></div>
    <ng-container *ngIf="report">
      <div class="col-9 mx-auto my-2">
        <h2 class="text-center">
          <strong>Invoices</strong>
        </h2>
        <div class="bg-light p-2">
          <rr-report-invoices [reportId]="report.id" header="Invoices"></rr-report-invoices>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="d-flex justify-content-center my-4">
    <button
      role="button"
      class="btn btn-secondary"
      title="Please make sure you have saved all your changes before going back to worklist."
      data-cy="back-to-worklist"
    >
      <rr-worklist-link>Go back to worklist</rr-worklist-link>
    </button>
  </div>
</div>
