import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { SessionActions } from '../../session/session.action';
import { TagChoiceActions } from './tag-choice.action';
import { adapter, initialState } from './tag-choice.state';

export const tagChoiceReducer = createReducer(
  initialState,

  // Reset back to the initial state
  on(TagChoiceActions.reset, EditorActions.open, EditorActions.close, SessionActions.logoutSuccess, () => initialState),

  on(TagChoiceActions.loadTagChoices, (state) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),

  on(TagChoiceActions.toggleLoading, (state) => ({
    ...state,
    loading: true,
  })),

  on(TagChoiceActions.loadTagChoicesSuccess, (state, { tags: tags }) => {
    return {
      ...adapter.setAll(tags, state),
      loaded: true,
      loading: false,
    };
  }),

  on(TagChoiceActions.autoSelectTagChoicesSuccess, (state, { tags }) => adapter.setAll(tags, state)),

  on(TagChoiceActions.addTagChoiceSuccess, (state, { tag: tag }) => {
    return {
      ...adapter.addOne(tag, state),
      loading: false,
    };
  }),

  on(TagChoiceActions.deleteTagChoiceSuccess, (state, { id: id }) => {
    return {
      ...adapter.removeOne(id, state),
      loading: false,
    };
  }),

  on(TagChoiceActions.copyTagChoicesSuccess, (state, { tags }) => {
    return {
      ...adapter.addMany(tags, state),
      loading: false,
    };
  }),
);
