import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromTextObject } from '../text-object/text-object.selector';

const adapter = createEntityAdapter<RR.Statement>();
const selectFeature = (state: AppState) => state.statement;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

/**
 * Select a single statement given the Id
 *
 * This converts the statementId into the corresponding region.
 *
 * @params: statementId: The identifier of the statement which is set within the database.
 */
const selectStatement = (statementId: number) =>
  createSelector(selectEntities, (statements: Dictionary<RR.Statement>) => statements[statementId]);

const selectTextObjects = (statementId: number) =>
  createSelector(
    selectStatement(statementId),
    fromTextObject.selectEntities,
    (statement: RR.Statement | undefined, textObjects: Dictionary<RR.TextObject>) => {
      if (!statement) return [];
      return statement.text_objects
        .map((textObjectId) => textObjects[textObjectId])
        .filter((o): o is RR.TextObject => !!o);
    },
  );

const selectStatements = (statementIds: number[]) =>
  createSelector(selectEntities, (statements: Dictionary<RR.Statement>) =>
    statementIds.map((id) => statements[id]).filter((s): s is RR.Statement => !!s),
  );

const selectStatementBuilderSetId = createSelector(
  selectFeature,
  (statementTemplate) => statementTemplate.statementBuilderSetId,
);

const selectStatementBuilderTemplateId = createSelector(
  selectFeature,
  (statementTemplate) => statementTemplate.statementBuilderTemplateId,
);

const selectStatementsFromStatementBuilder = createSelector(
  selectEntities,
  selectStatementBuilderSetId,
  (statements: Dictionary<RR.Statement>, statementSetId: number | undefined) => {
    if (!statementSetId) return;
    return Object.values(statements).filter((statement) => statement?.statement_set_id === statementSetId);
  },
);

export const fromStatement = {
  selectIds,
  selectEntities,
  selectAll,
  selectStatement,
  selectTextObjects,
  selectStatements,
  selectStatementsFromStatementBuilder,
  selectStatementBuilderSetId,
  selectStatementBuilderTemplateId,
};
