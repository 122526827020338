<div class="bg-light d-flex flex-column w-100">
  <!--Top 1/3-->
  <div role="button">
    <div class="m-3">
      <div class="my-2">
        <div class="d-flex gap-2 align-items-center">
          <label class="control-label" role="button" rrTooltip="DETAILED_EDIT_HISTORY" (click)="toggleAudit()">
            <span class="btn btn-sm btn-info border me-1">Undo List (Z):</span>
          </label>
          <span
            class="small text-primary"
            role="button"
            (click)="openTutorialModal('FIND_DELETED_SENTENCES')"
            rrTooltip="TUTORIALS_FIND_DELETED_SENTENCES"
            ><i class="fas fa-question-circle"></i></span
          >&nbsp;
          <rr-access-event-list [report]="report" [parent]="'DEMOGRAPHICS'"></rr-access-event-list>
        </div>
      </div>
    </div>
  </div>

  <!--Middle 2/3-->
  <ng-container *ngIf="report">
    <div class="m-3 d-flex flex-column">
      <div class="d-flex flex-row justify-content-between me-5">
        <div>
          <rr-report-accession-number [report]="report"></rr-report-accession-number>
        </div>
        <div>
          <label class="control-label">Born</label>
          <rr-patient-dob-info [patientId]="patient.id"></rr-patient-dob-info>
        </div>
        <div>
          <label class="control-label">Voyager Study date</label>
          <rr-report-date [reportId]="report.id" [label]="false"></rr-report-date>
        </div>
        <div class="col-sm-2 ps-0 pe-0" *ngIf="topic$ | async as topic">
          <label class="control-label">Medicare item</label>
          <p>{{ topic.title_option_text }}</p>
        </div>
        <div *ngIf="site$ | async as site">
          <label class="control-label">Site</label>
          <p [title]="site.name">{{ site.short_name }}</p>
        </div>
        <div *ngIf="report.phone">
          <label class="control-label">Phone</label>
          <p>{{ report.phone }}</p>
        </div>
        <div *ngIf="report.fax">
          <label class="control-label">Fax</label>
          <p>{{ report.fax }}</p>
        </div>
        <div *ngIf="report.email">
          <label class="control-label">Email</label>
          <p>{{ report.email }}</p>
        </div>
      </div>
      <!-- Favourites used for this report-->
      <div *ngIf="topic$ | async as topic" class="mt-2">
        <rr-ref-topics [context]="'DEMOGRAPHICS'" [topic_id]="topic.id"></rr-ref-topics>
      </div>
    </div>
  </ng-container>

  <rr-add-other-imaging *ngIf="topic$ | async as topic" [report]="report" [topic]="topic"></rr-add-other-imaging>
</div>
