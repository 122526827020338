import { Dictionary, createEntityAdapter } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.ReportStatus>();
const selectFeature = (state: AppState) => state.report_status;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoaded = createSelector(selectFeature, (state) => state.loaded);

const selectReportStatus = (id: number) =>
  createSelector(selectEntities, (reportStatuses: Dictionary<RR.ReportStatus>) => reportStatuses[id]);

const selectByType = (value: RR.ReportStatusType) =>
  createSelector(selectAll, (reportStatuses: RR.ReportStatus[]) =>
    reportStatuses.find((reportStatus) => reportStatus.value === value),
  );

export const fromReportStatus = {
  selectIds,
  selectEntities,
  selectAll,
  selectReportStatus,
  selectByType,
  selectLoaded,
};
