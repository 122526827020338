import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.Pathology>();
const selectFeature = (state: AppState) => state.pathology;

const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectPathology = (id: number) =>
  createSelector(selectEntities, (pathologies: Dictionary<RR.Pathology>) => pathologies[id]);

export const fromPathology = {
  selectIds,
  selectEntities,
  selectAll,
  selectPathology,
};
