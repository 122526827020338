<div class="d-flex flex-column flex-grow-1 overflow-auto">
  <h5 class="fw-bold">Diffs Preview:</h5>
  <nav>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" *ngIf="patient$ | async as patient">
        Patient Name: <rr-patient-name [patientId]="patient.id"></rr-patient-name>,
        <span ngbTooltip="Patient number" *ngIf="patient.patient_number"> &nbsp;{{ patient?.patient_number }} </span>
      </li>
      <li class="breadcrumb-item" *ngIf="report$ | async as report">
        <rr-report-accession-number [report]="report"></rr-report-accession-number>
      </li>
      <li class="breadcrumb-item">
        Selected user:
        <rr-user-headline *ngIf="userId | selectPipe: userSelectorFn | async as user" [user]="user"></rr-user-headline>
      </li>
      <ng-container *ngIf="report$ | async as report">
        <li class="breadcrumb-item" *ngFor="let topic_id of report.topic_ids">
          Report Title: <rr-topic-title-option-text [topic_id]="topic_id"></rr-topic-title-option-text>
        </li>
      </ng-container>
      <li class="breadcrumb-item" *ngIf="report$ | async as report">
        Due: {{ report.due_date | date: 'MMM d, y, H:mm' }}
      </li>
    </ol>
  </nav>
  <div *ngIf="!accessUserSnapshot">No draft report recorded.</div>

  <div *ngIf="reportTextLoading" class="d-flex align-items-center justify-content-center loading mt-2">
    <i class="fa fa-spinner fa fa-spin fa-2x text-secondary"></i> <span class="ms-2">Loading report text...</span>
  </div>

  <div *ngIf="accessUserSnapshot && finalReportText && !reportTextLoading">
    <div class="d-flex flex-row align-items-center mb-2" *ngIf="accessUserSnapshot !== finalReportText">
      <span class="dot-red me-1"></span>Removed or modified text <span class="dot-green ms-1 me-1"></span> Added text
    </div>
    <div class="mb-2" *ngIf="accessUserSnapshot === finalReportText">Draft report and final report are identical.</div>

    <pre rrProcessingDiff class="bg-light" [left]="accessUserSnapshot" [right]="finalReportText"></pre>
  </div>
</div>
