<div class="modal-header">
  <h5 class="modal-title">Element Library</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body pb-0">
  <div>
    <div class="btn-group d-flex justify-content-center py-1" role="group">
      <input
        id="library-all"
        class="btn-check"
        type="radio"
        value="all"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-all" class="btn btn-outline-primary btn-sm"> All </label>

      <input
        id="library-history"
        class="btn-check"
        type="radio"
        value="history"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-history" class="btn btn-outline-primary btn-sm"> History </label>

      <input
        id="library-findings"
        class="btn-check"
        type="radio"
        value="findings"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-findings" class="btn btn-outline-primary btn-sm"> Findings </label>

      <input
        id="library-technique"
        class="btn-check"
        type="radio"
        value="technique"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-technique" class="btn btn-outline-primary btn-sm"> Technique </label>

      <input
        id="library-comment"
        class="btn-check"
        type="radio"
        value="comment"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-comment" class="btn btn-outline-primary btn-sm"> Comment </label>

      <input
        id="library-ir"
        class="btn-check"
        type="radio"
        value="impression_recommendations"
        [formControl]="sectionFormControl"
        (change)="search(false)"
      />
      <label for="library-ir" class="btn btn-outline-primary btn-sm"> Impression & Recommendations </label>
    </div>

    <div class="form-group mb-2">
      <input
        type="text"
        class="form-control"
        placeholder="Search by element name"
        rrAutoFocus
        #searchInput
        [(ngModel)]="searchText"
      />
    </div>
    <div class="form-group mb-2 d-flex">
      <span>Hidden elements: {{ hiddenCount }}</span>
      <div class="form-check ms-3">
        <input
          id="show-hidden-element"
          value="any"
          type="checkbox"
          [(ngModel)]="showAll"
          class="form-check-input"
          (change)="showHiddenElements()"
        />
        <label for="show-hidden-element" class="form-check-label">Show all elements</label>
      </div>
    </div>
    <hr />
  </div>
  <div class="row content" (scroll)="onScroll($event)">
    <p class="p-3 w-100" *ngIf="loading">Loading...</p>
    <div class="col-6" *ngIf="!loading">
      <form *ngFor="let statement_set of statement_sets">
        <div class="form-check d-flex">
          <input
            class="form-check-input"
            type="radio"
            name="set"
            [(ngModel)]="selectedSetId"
            (ngModelChange)="selected()"
            [value]="statement_set.id"
            id="set-{{ statement_set.id }}"
            (click)="focusCreate($event)"
            [disabled]="!(statementSetUsed(statement_set.id) | async)"
          />
          <label
            for="set-{{ statement_set.id }}"
            class="form-check-label"
            [ngClass]="{ 'text-warning': statement_set.count_statement < 2 }"
          >
            {{ statement_set.name }}
          </label>
        </div>
        <div class="ms-3 d-flex">
          <span *ngIf="isUnusedStatementSet(statement_set)" class="badge rounded-pill bg-warning used-text">
            Used: 0
          </span>
          <span
            *ngFor="let item of countUsed(statement_set) | keyvalue"
            class="badge rounded-pill bg-primary used-text"
          >
            {{ getSectionTitle(item.key) }}: {{ item.value }}
          </span>
          <span
            role="button"
            class="fa fa-question-circle align-self-center ms-2"
            (click)="showElementUsageModal(statement_set)"
          ></span>
        </div>
        <input type="submit" hidden />
      </form>
      <hr />
      <div *ngIf="!loading && count >= limit" class="alert alert-info text-center mb-3" (click)="search(true)">
        {{ loadingMore ? 'Loading more...' : 'Click to load more' }}
      </div>
    </div>
    <ng-container *ngIf="statementSet$ | async as statementSet">
      <div class="col-6" *ngIf="statements$ | async as statements" [ngStyle]="{ top: top }">
        <ng-container *ngFor="let statement of statements; let i = index">
          <button
            rr-statement
            *ngIf="statement"
            [topic]="topic"
            [template_id]="topic.template_id"
            type="stub"
            class="list-group-item list-group-item-action text-start w-100"
            [statement]="statement"
          ></button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button
    #createElementButton
    *ngIf="!!selectedSetId"
    class="btn btn-success"
    role="button"
    (click)="activeModal.close(selectedSetId)"
  >
    Create element
  </button>
  <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
</div>
