import { createEntityAdapter } from '@ngrx/entity';
import { AppState } from 'app/store/app.state';

const adapter = createEntityAdapter<RR.CategoryStatementCombo>();
const selectFeature = (state: AppState) => state.category_statement_combo;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

export const fromCategoryStatementCombo = {
  selectIds,
  selectEntities,
  selectAll,
};
