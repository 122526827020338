import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorService } from 'app/core/services/editor.service';
import { TagSearchTermEffect } from 'app/store/tag-search-term';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'rr-tag-search-term',
  templateUrl: './tag-search-term.component.html',
  styleUrls: ['./tag-search-term.component.css'],
})
export class TagSearchTermComponent implements OnDestroy {
  @ViewChild('contenteditableElement') contenteditableElement: ElementRef;

  @Input() tagSearchTerm: RR.TagSearchTerm;
  @Output() onSelectTagSearchTerm = new EventEmitter();

  editMode = false;

  subscription = new Subscription();

  constructor(
    private tagSearchTermEffect: TagSearchTermEffect,
    private editorService: EditorService,
    private activeModal: NgbActiveModal,
  ) {}

  deleteTagSearchTerm(tagSearchTermId: number) {
    this.subscription.add(this.tagSearchTermEffect.delete(tagSearchTermId).subscribe());
  }

  editTagSearchTerm() {
    this.editMode = true;
    const currentElement = this.contenteditableElement.nativeElement;

    // setTimeout used to make sure note has contenteditable attribute before trying to focus.
    setTimeout(() => {
      currentElement.focus();
    }, 100);
  }

  updateTagSearchTerm(tagSearchTermId: number) {
    const text = this.contenteditableElement.nativeElement.innerText;
    this.subscription.add(
      this.tagSearchTermEffect
        .update(tagSearchTermId, {
          text,
        })
        .subscribe(),
    );

    this.editMode = false;
  }

  onTagSearchTermClicked() {
    if (this.editMode) return;
    this.onSelectTagSearchTerm.emit(this.tagSearchTerm);
  }

  insertIntoSoabs(noteText: string) {
    this.editorService.togglePrefill(false);
    this.editorService.triggerBlueStatementSearch();
    this.editorService.searchTextInSoabs(noteText);
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
