import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Remove subsection</h4>
      <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
    </div>
    <div class="modal-body">
      <p>Are you sure you want to remove this subsection?</p>
      <p>The subsection will be deleted and its elements will be moved to the previous subsection.</p>
    </div>
    <div class="modal-footer">
      <button ngbAutoFocus class="btn btn-danger" role="button" (click)="activeModal.close()">Remove subsection</button>
      <button class="btn btn-light" role="button" (click)="activeModal.dismiss()">Cancel</button>
    </div>
  `,
  standalone: true,
})
export class SubsectionDeleteModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
