import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { MandatoryStatementActions } from './mandatory-statement.action';

export type MandatoryStatementState = EntityState<RR.MandatoryStatement>;

const adapter = createEntityAdapter<RR.MandatoryStatement>();
const initialState = adapter.getInitialState();

export const mandatoryStatementReducer = createReducer(
  initialState,
  on(MandatoryStatementActions.findManySuccess, (state, { mandatoryStatements }) =>
    adapter.addMany(mandatoryStatements, state),
  ),
  on(MandatoryStatementActions.createSuccess, (state, { mandatoryStatement }) =>
    adapter.addOne(mandatoryStatement, state),
  ),
  on(MandatoryStatementActions.deleteSuccess, (state, { mandatoryStatementId }) =>
    adapter.removeOne(mandatoryStatementId, state),
  ),
);
