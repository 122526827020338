import { createAction, props } from '@ngrx/store';
import { createBatchAction } from 'app/store/batching.action';

import { AttributeOptionActions } from '../attribute-option/attribute-option.action';

export const AttributeSetActions = {
  findSuccess: createAction('[AttributeSet] Find Success', props<{ attributeSet: RR.AttributeSet }>()),

  findAllSuccess: createAction('[AttributeSet] Find All Success', props<{ attributeSets: RR.AttributeSet[] }>()),

  createSuccess: createAction('[AttributeSet] Create Success', props<{ attributeSet: RR.AttributeSet }>()),

  updateSuccess: createAction('[AttributeSet] Update Success', props<{ attributeSet: RR.AttributeSet }>()),

  reset: createAction('[AttributeSet] Reset'),
};

export const AttributeSetBatchActions = {
  findAllSuccessBatchAction: createBatchAction(
    '[AttributeSet] Find All Success Batch',
    props<{
      actions: {
        attributeSetFindAllSuccessAction: ReturnType<typeof AttributeSetActions.findAllSuccess>;
        attributeOptionFindAllSuccessAction: ReturnType<typeof AttributeOptionActions.findAllSuccess>;
      };
    }>(),
  ),
};
