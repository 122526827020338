import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { PatientNoteCategoryActions } from './patient-note-category.action';
import { PatientNoteCategoryHttpService } from './patient-note-category.service';

@Injectable()
export class PatientNoteCategoryEffect {
  constructor(
    private store: Store<AppState>,
    private service: PatientNoteCategoryHttpService,
  ) {}

  create(patientNoteCategory: Partial<RR.NoteCategory>) {
    return this.service.create(patientNoteCategory).pipe(
      map((patient_note_category: RR.NoteCategory) =>
        PatientNoteCategoryActions.createSuccess({
          patient_note_category,
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(patientNoteCategoryId: number) {
    return this.service.delete(patientNoteCategoryId).pipe(
      map(() => PatientNoteCategoryActions.delete({ patient_note_category_id: patientNoteCategoryId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
