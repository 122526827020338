<div
  class="d-flex align-items-center"
  *ngIf="sectionType === 'findings' || sectionType === 'comment' || sectionType === 'impression_recommendations'"
>
  <form [formGroup]="form" class="d-flex align-items-center">
    <div class="d-flex flex-column me-2">
      <label for="threshold" class="form-label">Threshold</label>
      <input
        class="form-control"
        style="width: 4em"
        type="number"
        id="threshold"
        name="threshold"
        min="0"
        max="100"
        step="10"
        formControlName="threshold"
      />
    </div>
    <div class="d-flex flex-column me-2">
      <label for="min" class="form-label">Min groups</label>
      <input
        class="form-control"
        style="width: 4em"
        type="number"
        id="min"
        name="min"
        min="0"
        max="100"
        step="1"
        formControlName="min"
      />
    </div>
    <div class="form-check ms-2">
      <input id="no_nad" class="form-check-input" type="checkbox" formControlName="excludeNad" />
      <label for="no_nad" class="form-check-label">Exclude NAD</label>
    </div>
  </form>

  <ng-container *ngIf="(filteredTagChoices$ | async)?.length === 0">
    <div class="btn-group">
      <button type="button" rrTooltip="AUTO_SELECT_TAGS" class="btn btn-primary ms-2" (click)="onAutoSelect()">
        Auto Select Groups
      </button>
      <div ngbDropdown autoClose="outside" class="btn-group">
        <button class="btn btn-primary" ngbDropdownToggle></button>
        <div class="dropdown-menu px-3" ngbDropdownMenu>
          <div ngbDropdownItem>
            <div class="d-flex align-items-center gap-2">
              <input
                id="placeholder_nad"
                class="form-check-input"
                type="checkbox"
                [formControl]="form.controls.placeholderNad"
              />
              <label for="placeholder_nad" class="form-check-label">Include NAD placeholders</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(filteredTagChoices$ | async)?.length === 0">
    <button
      *ngIf="parent === 'FINDING_GROUPS'"
      rrTooltip="AUTO_SELECT_TAGS_AND_CLOSE"
      role="button"
      class="btn btn-primary ms-2"
      (click)="onAutoSelect(true)"
    >
      Auto Select and Close
    </button>
  </ng-container>

  <ng-container *ngIf="filteredTagChoices$ | async as tagChoices">
    <button
      *ngIf="tagChoices.length > 0"
      rrTooltip="DELETE_AUTO_SELECTED_TAGS"
      role="button"
      class="btn btn-warning ms-2"
      (click)="onAutoSelectDelete()"
    >
      Delete Auto
    </button>
  </ng-container>
</div>
