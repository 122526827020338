import { createAction, props } from '@ngrx/store';

export const ReportMergeConflictActions = {
  selectConflictSide: createAction(
    '[ReportMergeConflict] Select Side',
    props<{ reportMergeConflict: RR.EntityMergeConflict<RR.Report>; side: RR.MergeConflictSide }>(),
  ),
  selectAllConflictSide: createAction(
    '[ReportMergeConflict] Select All Side',
    props<{ fieldNames?: RR.EntityMergeConflict<RR.Report>['field_name'][]; side: RR.MergeConflictSide }>(),
  ),

  findAllSuccess: createAction(
    '[ReportMergeConflict] Find All Success',
    props<{ reportMergeConflicts: RR.EntityMergeConflict<RR.Report>[] }>(),
  ),
};
