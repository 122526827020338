<ng-template #categorisedTooltip>
  <div class="text-start" *ngIf="scMode$ | async">
    <div>
      Categorised tags: {{ numberOfCategorisedDividers | async }}/{{ getNumberOfStatements('DIVIDER') | async }}
    </div>
    <div>Statements: {{ numberOfCategorisedStatements | async }}/{{ getNumberOfStatements('STATEMENT') | async }}</div>
  </div>
</ng-template>

<div class="px-3 mb-2 placeholder-glow" *ngrxLet="statementSet$ as statementSet">
  <ng-container *ngIf="!statementSet">
    <span class="placeholder col-3"></span>&nbsp; <span class="placeholder col-5"></span>&nbsp;
    <span class="placeholder col-3"></span>&nbsp; <span class="placeholder col-4"></span>&nbsp;
    <span class="placeholder col-3"></span>
  </ng-container>
  <span
    *ngIf="statementSet"
    role="button"
    [ngbTooltip]="(scMode$ | async) ? categorisedTooltip : null"
    tooltipClass="categorised-tooltip"
    placement="top"
    container="body"
    [class.rr-element-highlight]="highlight | async"
    >{{ statementSet.name }}</span
  >
</div>
