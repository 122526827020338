import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PercentileBandRule } from '../percentile-band-rule/percentile-band-rule.service';
import { PercentileBand } from '../percentile-band/percentile-band.service';

export interface PercentileBandGroup {
  id: number;
  y: number;
  x: number;
  percentile_band_rule_id: number;
}

export interface PercentileBandsResponse {
  percentile_band_groups: PercentileBandGroup[];
  percentile_bands: PercentileBand[];
  percentile_band_rules: PercentileBandRule[];
}

@Injectable()
export class PercentileBandGroupHttpService {
  constructor(private http: HttpClient) {}

  getPercentileBands(): Observable<PercentileBandsResponse> {
    return this.http.get<PercentileBandsResponse>('/api/percentile_bands');
  }

  createPercentileBandGroup(percentileBandGroup: Partial<PercentileBandGroup>): Observable<PercentileBandGroup> {
    return this.http.post<PercentileBandGroup>('/api/percentile_band_group', percentileBandGroup);
  }

  updatePercentileBandGroup(
    percentileBandGroupId: number,
    changes: Partial<PercentileBandGroup>,
  ): Observable<PercentileBandGroup> {
    return this.http.put<PercentileBandGroup>(`/api/percentile_band_group/${percentileBandGroupId}`, changes);
  }

  deletePercentileBandGroup(percentileBandGroupId: number): Observable<void> {
    return this.http.delete<void>(`/api/percentile_band_group/${percentileBandGroupId}`);
  }
}
