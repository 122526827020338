import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { TopicActions } from '../topic/topic.action';
import { FavouriteBatchActions } from './favourite.action';
import { FavouriteActions } from './favourite.action';
import { FavouriteHttpService } from './favourite.service';

@Injectable()
export class FavouriteEffect {
  constructor(
    private store: Store<AppState>,
    private service: FavouriteHttpService,
  ) {}

  // Legacy code below this line

  /**
   * @deprecated
   */
  create(topicId: number, favourite: Partial<RR.Favourite>) {
    return this.service.create(topicId, favourite).pipe(
      map((favourite) => {
        return FavouriteBatchActions.createFavouriteSuccessBatch({
          actions: {
            updateTopicSuccess: TopicActions.upsertOne({ topic: favourite.topic as RR.Topic }),
            createFavouriteSuccess: FavouriteActions.createSuccess({ favourite }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated
   */
  update(favouriteId: number, changes: Partial<RR.Favourite>) {
    return this.service.update(favouriteId, changes).pipe(
      map((favourite) => FavouriteActions.updateSuccess({ favourite })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  /**
   * @deprecated
   */
  delete(favouriteId: number) {
    return this.service.delete(favouriteId).pipe(
      map(() => FavouriteActions.deleteSuccess({ favouriteId })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
