import { Component, Input } from '@angular/core';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { RadReportVersionError } from 'app/core/interceptors/app-version-interceptor';

@Component({
  selector: 'rr-version-update-toast',
  templateUrl: './version-update-toast.component.html',
  styleUrls: ['./version-update-toast.component.css'],
  standalone: true,
  imports: [NgbAlert],
})
export class VersionUpdateToastComponent {
  @Input() error: RadReportVersionError;

  reload() {
    localStorage.setItem('seenRRVersion', this.error.version);
    window.location.reload();
  }
}
