import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store/app.state';
import { map, tap } from 'rxjs/operators';

import { SendEventActions } from '../report/send-event/send-event.action';
import { ReferralEnquiryActions, ReferralEnquiryBatchActions } from './referral-enquiry.action';
import {
  ReferralEnquiryApiResponse,
  ReferralEnquiryHttpService,
  ReferralEnquirySearchBody,
  SendReferrralEnquiryConfirmSMSResponse,
} from './referral-enquiry.service';

@Injectable()
export class ReferralEnquiryEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReferralEnquiryHttpService,
    private message: MessageService,
  ) {}

  get(enquiryId: number) {
    return this.service.get(enquiryId).pipe(
      this.message.handleHttpErrorPipe,
      map((enquiry: RR.ReferralEnquiry) => ReferralEnquiryActions.findSuccess({ enquiry })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(body: ReferralEnquirySearchBody) {
    return this.service.search(body).pipe(
      this.message.handleHttpErrorPipe,
      map((response: ReferralEnquiryApiResponse) =>
        ReferralEnquiryBatchActions.searchSuccess({
          actions: {
            searchReferralSuccess: ReferralEnquiryActions.searchSuccess({
              count: response.count,
              enquiries: response.data,
            }),
            findManySendEventsSuccess: SendEventActions.findManySuccess({ sendEvents: response.send_events }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  lazy_search(body: ReferralEnquirySearchBody) {
    return this.service.lazy_search(body).pipe(
      this.message.handleHttpErrorPipe,
      map((response: ReferralEnquiryApiResponse) =>
        ReferralEnquiryBatchActions.searchSuccess({
          actions: {
            searchReferralSuccess: ReferralEnquiryActions.searchSuccess({
              count: response.count,
              enquiries: response.data,
            }),
            findManySendEventsSuccess: SendEventActions.findManySuccess({ sendEvents: response.send_events }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(enquiryId: number, changes: Partial<RR.ReferralEnquiry>) {
    return this.service.update(enquiryId, changes).pipe(
      this.message.handleHttpErrorPipe,
      map((enquiry: RR.ReferralEnquiry) => ReferralEnquiryActions.updateSuccess({ enquiry })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  // delete(enquiryId: number) {
  //   const obs$ = this.service.delete(enquiryId).pipe(
  //     map(() => ReferralEnquiryActions.deleteSuccess({ enquiryId })),
  //     tap((action) => this.store.dispatch(action)),
  //     share(),
  //   );
  //   obs$.subscribe();
  //   return obs$;
  // }

  sendReferralConfirmationSMS(enquiryId: number, sendTo: string, message: string) {
    return this.service.sendReferralConfirmationSMS(enquiryId, sendTo, message).pipe(
      this.message.handleHttpErrorPipe,
      map((data: SendReferrralEnquiryConfirmSMSResponse) =>
        ReferralEnquiryBatchActions.sendConfirmationSMSSuccess({
          actions: {
            updateReferralSuccess: ReferralEnquiryActions.updateSuccess({ enquiry: data.referral_enquiry }),
            createSendEventSuccess: SendEventActions.sendSuccess({ sendEvents: [data.send_event] }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
