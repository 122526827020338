import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'structuredNoteLength',
})
export class StructuredNoteLengthPipe implements PipeTransform {
  transform(structuredNotes: RR.StructuredNote[], subsectionName?: string | undefined) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!structuredNotes) {
      return false;
    }

    if (subsectionName) {
      return structuredNotes.filter((result) => result.subsection.toLowerCase() === subsectionName.toLowerCase())[0]
        ?.notes.length;
    } else {
      return structuredNotes.reduce((acc, result) => acc + result.notes.length, 0);
    }
  }
}
