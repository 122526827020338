import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ReferrerActions } from '../referrer/referrer.action';
import { ReferrerContactMethodActions, ReferrerContactMethodBatchActions } from './referrer-contact-method.action';
import {
  CreateReferrerContactMethodResponse,
  ReferrerContactMethodHttpService,
} from './referrer-contact-method.service';

@Injectable()
export class ReferrerContactMethodEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReferrerContactMethodHttpService,
  ) {}

  create(referrerContactMethod: Partial<RR.ReferrerContactMethod>) {
    return this.service.create(referrerContactMethod).pipe(
      map((data: CreateReferrerContactMethodResponse) =>
        ReferrerContactMethodBatchActions.createSuccess({
          actions: {
            createReferrerContactMethodSuccess: ReferrerContactMethodActions.createSuccess({
              referrer_contact_method: data.referrer_contact_method,
            }),
            updateReferrerSuccess: ReferrerActions.updateSuccess({ referrer: data.referrer }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(referrerContactMethodId: number) {
    return this.service.delete(referrerContactMethodId).pipe(
      map((data) =>
        ReferrerContactMethodBatchActions.deleteSuccess({
          actions: {
            deleteReferrerContactMethodSuccess: ReferrerContactMethodActions.deleteSuccess({
              referrer_contact_method_id: referrerContactMethodId,
            }),
            updateReferrerSuccess: ReferrerActions.updateSuccess({ referrer: data.referrer }),
          },
        }),
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
