import { state, style, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  COMMAND_ACTIONS,
  SIDEBAR_COMPONENT_SELECTOR,
  SIDEBAR_INVOICE_ITEMS_ID,
  SIDEBAR_NOTES_ID,
  SIDEBAR_REGISTRATION_QUESTIONS_ID,
  SIDEBAR_WEB_VIEWER_ID,
} from 'app/app.constants';
import { filterDefined, getImageUrl } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { NoteCounter, ReportService } from 'app/core/services/report.service';
import { RouterHistoryService } from 'app/core/services/router-history.service';
import { SelectorService } from 'app/core/services/selector.service';
import { CommandOutput, VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { SelfCheckFeedbackModalComponent } from 'app/modules/feedback/modals/self-check-feedback-modal/self-check-feedback-modal.component';
import { SendFeedbackModalComponent } from 'app/modules/feedback/modals/send-feedback-modal/send-feedback-modal.component';
import { ReportInvoicesModalComponent } from 'app/modules/invoice/modals/report-invoices-modal/report-invoices-modal.component';
import { BookingDocumentAttachmentModalComponent } from 'app/modules/registration/modals/booking-document-attachment-modal/booking-document-attachment-modal.component';
import { PatientQuestionnairesModalComponent } from 'app/modules/registration/modals/patient-questionnaires-modal/patient-questionnaires-modal.component';
import { RegistrationModalComponent } from 'app/modules/registration/modals/registration-modal/registration-modal.component';
import { CheckReportModalComponent } from 'app/modules/report/components/preview/check-report-modal/check-report-modal.component';
import { FinalPreviewModalComponent } from 'app/modules/report/components/preview/final-preview-modal/final-preview-modal.component';
import { SignatureModalComponent } from 'app/modules/report/components/preview/signature-modal/signature-modal.component';
import { SignatureModalRole } from 'app/modules/report/components/preview/signature-modal/signature-modal.types';
import { SendReportModalComponent } from 'app/modules/report/components/send-report/send-events-modal/send-report-modal.component';
import { RadreportManualModalComponent } from 'app/modules/tutorial/modals/radreport-manual-modal/radreport-manual-modal.component';
import { OpenVoyagerButtonComponent } from 'app/shared/components/open-voyager-button/open-voyager-button.component';
import { NextReportModalComponent } from 'app/shared/modals/next-report-modal/next-report-modal.component';
import { AppState } from 'app/store';
import { EditorActions, ElementFilterType } from 'app/store/editor';
import { fromInvoice } from 'app/store/invoice';
import { fromPatient } from 'app/store/patient';
import { PrefillSearchActions, PrefillSearchFormType } from 'app/store/prefill/prefill-search';
import { fromFavourite } from 'app/store/report/favourite';
import { fromCurrentReport, ReportEffect } from 'app/store/report/report';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromTodo } from 'app/store/report/todo';
import { fromCurrentTopic } from 'app/store/report/topic';
import { filter, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { WorklistLinkComponent } from '../../../core/toolbar-navbar/components/worklist-link/worklist-link.component';
import { OpenVoyagerButtonComponent as OpenVoyagerButtonComponent_1 } from '../../../shared/components/open-voyager-button/open-voyager-button.component';
import { ZeroFPFormsComponent } from '../../../shared/components/zero-fp-forms/zero-fp-forms.component';
import { ZeroFPImagesComponent } from '../../../shared/components/zero-fp-images/zero-fp-images.component';
import { DemographicsModalComponent } from '../../../shared/modals/demographics-modal/demographics-modal.component';
import { SuperUrgentButtonComponent } from '../../../shared/modals/due-date-modal/super-urgent-button/super-urgent-button.component';
import { UrgentButtonComponent } from '../../../shared/modals/due-date-modal/urgent-button/urgent-button.component';
import { NotesModalComponent } from '../../../shared/modals/notes-modal/notes-modal.component';
import { SharedModule } from '../../../shared/shared.module';
import { DocumentAttachmentButtonComponent } from '../../registration/components/document-attachment-button/document-attachment-button.component';
import { AddOtherImagingButtonComponent } from '../add-other-imaging-button/add-other-imaging-button.component';
import { AddOtherImagingModalComponent } from '../add-other-imaging-modal/add-other-imaging-modal.component';
import { DicomSrModalComponent } from '../dicom-sr-modal/dicom-sr-modal.component';
import { DividerStatementsModalComponent } from '../divider/divider-statements-modal/divider-statements-modal.component';
import { ImgsimParametersModalComponent } from '../imgsim-parameters-modal/imgsim-parameters-modal.component';
import { NameFavouriteModalComponent } from '../prefill-button/name-favourite-modal.component';
import { PrefillTagSearchModalComponent } from '../prefill/prefill-tag/prefill-tag-search-modal.component';
import { QuickNavModalComponent } from '../quick-nav-modal/quick-nav-modal.component';
import { ShortcutsModalComponent } from '../shortcuts/shortcuts-modal.component';
import { SidebarTitleModalComponent } from './sidebar-title-modal/sidebar-title-modal.component';
import { listenToTriggers } from './triggers';

@Component({
  selector: SIDEBAR_COMPONENT_SELECTOR,
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    // This is set by isOpen
    trigger('openClose', [
      state(
        'open',
        style({
          width: '210px',
        }),
      ),
      state(
        'closed',
        style({
          //  if changed, also change sidebar-width in _custom.scss
          width: '80px',
        }),
      ),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    AddOtherImagingButtonComponent,
    UrgentButtonComponent,
    SuperUrgentButtonComponent,
    OpenVoyagerButtonComponent_1,
    ZeroFPFormsComponent,
    ZeroFPImagesComponent,
    DocumentAttachmentButtonComponent,
    RouterLink,
    WorklistLinkComponent,
  ],
})
export class SidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sidebar', { static: false }) sidebarEl: ElementRef<HTMLDivElement>;
  @ViewChild(OpenVoyagerButtonComponent) openVoyagerButton: OpenVoyagerButtonComponent;
  // TODO: load topic before rendering the sidebar to eliminate undefined from the type
  topic: RR.Topic | undefined;
  topic$: Observable<RR.Topic>;
  report: RR.Report;
  favourite: RR.Favourite | undefined;
  currentUser: RR.User | undefined;
  todos: RR.Todo[] = [];
  report$: Observable<RR.Report>;
  patient$: Observable<RR.Patient>;
  subscription = new Subscription();
  hotkeySubscription: Subscription = new Subscription();
  companyRole: SignatureModalRole;
  isOpen$: Observable<boolean>;
  isOpen: boolean;
  webviewerFormsUrl: string;
  hasInvoiceItems$: Observable<boolean>;
  isListening: boolean;

  getImageUrl = getImageUrl;
  SIDEBAR_REGISTRATION_QUESTIONS_ID = SIDEBAR_REGISTRATION_QUESTIONS_ID;
  SIDEBAR_INVOICE_ITEMS_ID = SIDEBAR_INVOICE_ITEMS_ID;
  SIDEBAR_WEB_VIEWER_ID = SIDEBAR_WEB_VIEWER_ID;
  SIDEBAR_NOTES_ID = SIDEBAR_NOTES_ID;
  hasNotes: NoteCounter;
  sendStatus$: Observable<RR.ReportSendStatus>;
  globalSearchTerm: string;

  constructor(
    private editorService: EditorService,
    private modalService: NgbModal,
    private reportService: ReportService,
    private reportEffect: ReportEffect,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private hotkeysService: HotkeysService,
    private router: Router,
    private gaService: GoogleAnalyticsService,
    private renderer: Renderer2,
    private statementChoiceEffect: StatementChoiceEffect,
    private selectorService: SelectorService,
    private voiceService: VoiceRecognitionService,
    private routerHistoryService: RouterHistoryService,
    private messageService: MessageService,
    private http: HttpClient,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    // Select topic and report
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.report$ = this.store.select(fromCurrentReport.selectReport).pipe(filterDefined());
    this.patient$ = this.report$.pipe(
      filterDefined(),
      map((r) => r.patient_id),
      filterDefined(),
      switchMap((patient_id) => this.store.select(fromPatient.selectPatient(patient_id))),
      filterDefined(),
    );
    this.isOpen$ = this.editorService.isSidebarOpen();

    this.subscription.add(
      this.topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );
    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );
    this.subscription.add(
      this.isOpen$.subscribe((state) => {
        this.isOpen = state;
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      this.getImageUrl2().subscribe((url) => {
        // @ts-expect-error strictNullChecks
        this.webviewerFormsUrl = url;
      }),
    );

    this.sendStatus$ = this.reportService.getReportSendStatus(this.report.id);

    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(take(1))
        .subscribe((user) => {
          this.currentUser = user;
          if (this.currentUser) {
            this.setCompanyRole();
          }
        }),
    );

    // Only load once when switching topics
    const topicIdChanged$ = this.topic$.pipe(distinctUntilChanged((a, b) => a.id === b.id));
    this.subscription.add(
      topicIdChanged$
        .pipe(
          switchMap((topic) =>
            topic.favourite_id ? this.store.select(fromFavourite.selectById(topic.favourite_id)) : of(undefined),
          ),
        )
        .subscribe((fav) => {
          this.favourite = fav;
        }),
    );

    // Get todos of report
    this.subscription.add(
      this.store.select(fromTodo.selectInReport(this.report.id)).subscribe((todos) => {
        this.todos = todos;
        this.cd.markForCheck();
      }),
    );

    this.hasInvoiceItems$ = this.store
      .select(fromInvoice.selectInReport(this.report.id))
      .pipe(map((invoices) => !!invoices.find((i) => !i.deleted && !!i.invoice_items.length)));

    this.subscription.add(
      this.reportService.hasNotes(this.report.id).subscribe((val) => {
        this.hasNotes = val;
      }),
    );

    this.subscription.add(
      this.voiceService.isListening$.subscribe((listening) => {
        this.isListening = listening.listening;
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      this.editorService.globalSearchTerm$.pipe(filterDefined()).subscribe((response) => {
        this.globalSearchTerm = response.term;
      }),
    );

    this.subscription.add(
      this.voiceService.commandSubject$
        .pipe(filter((command: CommandOutput) => command.destination === 'SIDEBAR'))
        .subscribe(({ command }) => {
          if (command === COMMAND_ACTIONS.clearSearch) {
            this.clearSearch();
          }
          if (command === COMMAND_ACTIONS.clearVoice) {
            this.clearAllVoice();
          }
          if (command === COMMAND_ACTIONS.addFavourite) {
            this.openFavourites();
          }
          if (command === COMMAND_ACTIONS.toPrefill) {
            this.togglePrefill();
          }
          if (command === COMMAND_ACTIONS.checkReport) {
            this.openCheckReport();
          }
          if (command === COMMAND_ACTIONS.demographics) {
            this.openPatientInformation();
          }
          if (command === COMMAND_ACTIONS.toEditor) {
            this.backToEditor();
          }
          if (command === COMMAND_ACTIONS.prefillFavourites) {
            this.setPrefillType('FAVOURITES');
            this.togglePrefill();
          }
          if (command === COMMAND_ACTIONS.giveFeedback) {
            this.openSendFeedback();
          }
          if (command === COMMAND_ACTIONS.feedbackHelp) {
            this.openSelfCheckFeedback();
          }
          if (command === COMMAND_ACTIONS.reportTitle) {
            this.openReportTitle();
          }
          if (command === COMMAND_ACTIONS.tagsModal) {
            this.toggleFindingTagsAndPrefill('findings');
          }
          if (command === COMMAND_ACTIONS.dicom) {
            this.openDicomSR();
          }
          if (command === COMMAND_ACTIONS.invoices) {
            this.openInvoiceItems();
          }
          if (command === COMMAND_ACTIONS.notes) {
            this.openNotes();
          }
          if (command === COMMAND_ACTIONS.tutorials) {
            this.openTutorials();
          }
          if (command === COMMAND_ACTIONS.registration) {
            this.openRegistrationModal();
          }
          if (command === COMMAND_ACTIONS.sendReport) {
            this.openSendReport();
          }
          if (command === COMMAND_ACTIONS.sign) {
            this.openSignature(this.companyRole);
          }
          if (command === COMMAND_ACTIONS.urgent) {
            this.markAsUrgent();
          }
          if (command === COMMAND_ACTIONS.superUrgent) {
            this.markAsSuperUrgent();
          }
          if (command === COMMAND_ACTIONS.export) {
            this.openFinalReport();
          }
          if (command === COMMAND_ACTIONS.registrationQuestions) {
            this.openPatientQuestionnaireModal();
          }
          if (command === COMMAND_ACTIONS.undoList) {
            this.toggleAudit();
          }
          this.cd.detectChanges();
        }),
    );

    this.subscribeToShortcuts();
  }

  ngAfterViewInit(): void {
    // Adds a open delay to the sidebar, for if the user briefly hovers it by mistake.
    // Also a close delay, to be more forgiving with mouse pathing.
    const isOpenedFn = () => {
      return this.isOpen;
    };
    const openFn = () => {
      // None, we don't want to open the sidebar on hover. We open by clicking the button.
    };
    const closeFn = () => {
      this.editorService.toggleSidebar(false);
    };
    listenToTriggers(
      this.renderer,
      this.sidebarEl.nativeElement,
      // triggers mouseenter is still needed to reset the close delay. e.g. if the user hovers the sidebar and then
      // moves the mouse away but comes back before 500ms, it should stay open.
      'mouseenter:mouseleave',
      isOpenedFn,
      openFn,
      closeFn,
      0, // open delay
      400, // closeDelay
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.hotkeySubscription.unsubscribe();
  }

  clearAllVoice() {
    this.store.dispatch(EditorActions.clearVoiceSearchReults());
  }

  // Other functions
  setElementFilterType(type: ElementFilterType) {
    this.editorService.updateElementFilterType(type);
  }

  setPrefillType(searchType: PrefillSearchFormType['searchType']) {
    this.store.dispatch(PrefillSearchActions.updateSearchForm({ searchForm: { searchType: searchType } }));
  }

  openAddOtherImaging() {
    if (this.topic) {
      AddOtherImagingModalComponent.open(this.modalService, { reportId: this.report.id, topicId: this.topic.id });
    }
  }

  // @ts-expect-error noImplicitAny
  newTab(url) {
    // TODO: make it a link <a href=...>
    // eslint-disable-next-line no-restricted-properties
    window.open(url, '_blank');
  }

  navigateToWorklist() {
    this.subscription.add(
      this.routerHistoryService.lastWorklistUrl$.pipe(take(1)).subscribe((url) => {
        this.router.navigateByUrl(url);
      }),
    );
  }

  getImageUrl2() {
    return this.reportService.getReport(this.report.id).pipe(map((r) => r.image_url2));
  }

  toggleSidebar() {
    this.editorService.toggleSidebar();
  }

  backToEditor() {
    if (!this.topic) return;
    this.editorService.setTopicOpen(this.topic.id);
  }

  togglePrefill() {
    this.editorService.togglePrefill(true);
  }

  prefillSentences(type: 'TITLE' | 'PREDICTION') {
    if (!this.topic) return;
    this.statementChoiceEffect
      .createPredictionChoices(this.topic.id, type)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe(() => {
        this.editorService.togglePrefill(true);
      });
  }

  toggleFindingTagsAndPrefill(section: RR.TagSearchSection) {
    if (!this.topic) return;
    // Toggle prefill before showing Finding Tags modal
    this.editorService.togglePrefill(true);
    PrefillTagSearchModalComponent.open(this.modalService, this.topic.id, section);
  }

  markAsUrgent() {
    this.subscription.add(this.reportEffect.update(this.report.id, { urgent: !this.report.urgent }).subscribe());
  }

  markAsSuperUrgent() {
    // toggle the super_urgent status
    const newSuperUrgentStatus = !this.report.super_urgent;
    this.subscription.add(this.reportEffect.markSuperUrgent(this.report.id, newSuperUrgentStatus).subscribe());
  }

  toggleAudit() {
    this.editorService.toggleRightPaneViewMode('AUDIT');
  }

  toggleSuggestions() {
    this.editorService.toggleRightPaneViewMode('SUGGESTIONS');
  }

  clearSearch() {
    this.editorService.clearGlobalSearch();
  }

  toggleVoiceRecognition() {
    if (!this.isListening) {
      this.voiceService.startListening('GLOBAL_SEARCH');
    } else {
      this.voiceService.stopListening();
    }
  }

  nextTerm() {
    this.editorService.nextSearchTerm();
  }

  setCompanyRole() {
    const roles: string[] = this.currentUser?.company_roles || [];
    if (roles.some((role) => role === 'doctor')) {
      this.companyRole = 'DOCTOR';
    } else if (roles.some((role) => ['sonographer', 'radiographer', 'imaging_technician'].includes(role))) {
      this.companyRole = 'TECHNICIAN';
    } else if (roles.some((role) => ['junior_radiographer', 'junior_sonographer'].includes(role))) {
      this.companyRole = 'JUNIOR_TECHNICIAN';
    } else if (roles.some((role) => role === 'radiology_registrar')) {
      this.companyRole = 'RADIOLOGY_REGISTRAR';
    }
  }

  openRegistrationModal() {
    // Unsubscribe from hotkeys initiated with component to prevent problem with duplicate hotkeys
    this.hotkeySubscription.unsubscribe();
    const modalRef = RegistrationModalComponent.open(this.modalService, this.report.id);
    modalRef.result.then(
      () => {
        /* do nothing */
      },
      () => {
        // On dismissal of registration modal remove shortcuts used
        this.hotkeysService.removeShortcuts(['shift.r', 'shift.p', 'shift.b']);
        // Re-establish the hotkeySubscription that was unsubscribed from
        this.hotkeySubscription = new Subscription();
        // Subscribe to all hotkeys again
        this.subscribeToShortcuts();
      },
    );
  }

  openInvoiceItems() {
    ReportInvoicesModalComponent.open(this.modalService, this.report.id, false);
  }

  openTutorials() {
    this.modalService.open(RadreportManualModalComponent, { size: 'xl', scrollable: true });
  }

  openShortcuts() {
    this.modalService.open(ShortcutsModalComponent, { size: 'lg' });
  }

  showImgSimJobStatus() {
    if (!this.topic) return;
    this.subscription.add(
      this.http
        .get<{ status: string }>(`/api/debug/report/${this.report.id}/imgsim_job_status`)
        .pipe(take(1))
        .subscribe({
          next: (data) => {
            this.messageService.add({
              message: 'Current job status: ' + data.status,
              title: 'Job Status',
              type: 'info',
              timeout: 5000,
            });
          },
          error: (err: unknown) => {
            this.messageService.httpErrorMessage(err);
          },
        }),
    );
  }

  openSignature(role: SignatureModalRole) {
    if (!this.topic) return;
    SignatureModalComponent.open({
      modal: this.modalService,
      report_id: this.report.id,
      topic_id: this.topic.id,
      signature_role: role,
    });
  }

  openFinalReport() {
    if (!this.topic) return;
    FinalPreviewModalComponent.open(this.modalService, {
      topic_id: this.topic.id,
      report_id: this.report.id,
      mode: 'final',
    });
  }

  openCheckReport() {
    if (!this.topic) return;
    CheckReportModalComponent.open(this.modalService, this.report.id, this.topic.id);
  }

  openSendReport() {
    SendReportModalComponent.open(this.modalService, this.report);
  }

  openDicomSR() {
    if (!this.topic) return;
    DicomSrModalComponent.open(this.modalService, this.topic);
  }

  openImgsimParameters() {
    ImgsimParametersModalComponent.open(this.modalService, this.report);
  }

  openDividerSentences() {
    if (!this.topic) return;
    DividerStatementsModalComponent.open({
      modalService: this.modalService,
      topic_id: this.topic.id,
      parent: 'PREFILL_TAG_MODAL',
    });
  }

  openVoiceNotes() {
    NotesModalComponent.open({ modalService: this.modalService, report: this.report, voiceNotes: true });
  }

  openReportTitle() {
    if (!this.topic) return;
    SidebarTitleModalComponent.open(this.modalService, { injector: this.injector });
  }

  openPatientInformation() {
    if (!this.topic) return;
    DemographicsModalComponent.open(this.modalService, this.report, this.topic, 'EDITOR');
  }

  openSelfCheckFeedback() {
    SelfCheckFeedbackModalComponent.open(this.modalService, this.report.id);
  }

  openFavourites() {
    if (!this.topic) return;
    NameFavouriteModalComponent.open({
      modalService: this.modalService,
      topic_id: this.topic.id,
      favourite: this.favourite,
    });
  }

  openDocumentAttachmentModal() {
    BookingDocumentAttachmentModalComponent.open(this.modalService, this.report.booking_id);
  }

  openNotes() {
    NotesModalComponent.open({ modalService: this.modalService, report: this.report });
  }

  openPatientQuestionnaireModal() {
    PatientQuestionnairesModalComponent.open(this.modalService, this.report.id);
  }

  openSendFeedback() {
    SendFeedbackModalComponent.open(this.modalService, this.report.id);
  }

  openNavModal() {
    if (this.topic?.id) {
      QuickNavModalComponent.open(this.modalService, this.report.id, this.topic.id);
    }
  }

  openNextReportModal() {
    NextReportModalComponent.open({ modalService: this.modalService, reportId: this.report.id });
  }

  subscribeToShortcuts() {
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.$' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyOpenNavModal',
        });
        this.openNavModal();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.backspace' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyClearGlobalSearch',
        });
        this.clearSearch();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.@' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyToggleVoiceRecognition',
        });

        this.toggleVoiceRecognition();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.!' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyOpenSidebar',
        });
        this.editorService.toggleSidebar();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.t' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyPrefillTitle',
        });
        this.prefillSentences('TITLE');
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.p' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyPrefillPrediction',
        });
        this.prefillSentences('PREDICTION');
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.l' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyDicom',
        });
        this.modalService.dismissAll();
        this.openDicomSR();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.^' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyImgSim',
        });
        this.modalService.dismissAll();
        this.openImgsimParameters();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.<' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyImgSim',
        });
        this.modalService.dismissAll();
        this.openVoiceNotes();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.>' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyImgSim',
        });
        this.modalService.dismissAll();
        this.openDividerSentences();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.s' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeySign',
        });
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        if (this.companyRole) {
          this.modalService.dismissAll();
          this.openSignature(this.companyRole);
        }
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.h' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeySelfCheck',
        });
        this.modalService.dismissAll();
        this.openSelfCheckFeedback();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.d' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyPatientInfo',
        });
        this.modalService.dismissAll();
        this.openPatientInformation();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.a' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyFavourites',
        });
        this.modalService.dismissAll();
        this.openFavourites();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.j' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyFindings',
        });
        this.modalService.dismissAll();
        this.toggleFindingTagsAndPrefill('findings');
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.f' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyPrefillFavourites',
        });
        this.togglePrefill();
        this.setPrefillType('FAVOURITES');
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.r' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyFindings',
        });
        this.modalService.dismissAll();
        this.openSendReport();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.g' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyFeedback',
        });
        if (
          this.companyRole === 'DOCTOR' ||
          this.companyRole === 'TECHNICIAN' ||
          this.companyRole === 'RADIOLOGY_REGISTRAR'
        ) {
          this.modalService.dismissAll();
          this.openSendFeedback();
        }
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.n' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyNotes',
        });
        this.modalService.dismissAll();
        this.openNotes();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.u' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyMarkAsUrgent',
        });
        this.modalService.dismissAll();
        this.markAsUrgent();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.*' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyMarkAsSuperUrgent',
        });
        this.modalService.dismissAll();
        this.markAsSuperUrgent();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.z' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyToggleAudit',
        });
        this.modalService.dismissAll();
        this.toggleAudit();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.&' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyToggleSuggestions',
        });
        this.modalService.dismissAll();
        this.toggleSuggestions();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.x' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyFinalReport',
        });
        if (this.companyRole === 'DOCTOR') {
          this.modalService.dismissAll();
          this.openFinalReport();
        }
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.c' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyCheckReport',
        });
        this.modalService.dismissAll();
        this.openCheckReport();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.i' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyTitle',
        });
        this.modalService.dismissAll();
        this.openReportTitle();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.?' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyShortcutsMenu',
        });
        this.modalService.dismissAll();
        this.openShortcuts();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.esc' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyCloseWorklist',
        });
        this.modalService.dismissAll();
        this.navigateToWorklist();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeySubscription.add(
        this.hotkeysService.addShortcut({ keys: 'shift.v' }).subscribe(() => {
          this.openVoyagerButton.triggerClickEvent();
        }),
      ),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.w' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyViewerImages',
        });
        this.newTab(getImageUrl(this.report));
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.k' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyCopyKF',
        });
        this.openAddOtherImaging();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.e' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyBackToEditor',
        });
        this.backToEditor();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.%' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyToggleLRA',
        });
        this.setElementFilterType('LOGISTIC_REGRESSION');
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.+' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyOpenDocumentAttachment',
        });
        this.modalService.dismissAll();
        this.openDocumentAttachmentModal();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.q' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyOpenRegistrationModal',
        });
        this.modalService.dismissAll();
        this.openRegistrationModal();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.y' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyOpenPatientQuestions',
        });
        this.modalService.dismissAll();
        this.openPatientQuestionnaireModal();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.m' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyInvoiceItem',
        });
        this.modalService.dismissAll();
        this.openInvoiceItems();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift.b' }).subscribe(() => {
        this.togglePrefill();
      }),
    );
    this.hotkeySubscription.add(
      this.hotkeysService.addShortcut({ keys: 'shift./' }).subscribe(() => {
        this.gaService.trackEvent({
          event: 'Hotkey',
          action: 'hotkeyNextReport',
        });
        this.openNextReportModal();
      }),
    );
  }
}
