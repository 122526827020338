import { CdkListbox, CdkOption, ListboxValueChangeEvent } from '@angular/cdk/listbox';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { NgForTrackByIdDirective } from 'app/shared/directives/ng-for-track-by-id.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'rr-report-status-selector',
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, CdkListbox, CdkOption, NgForTrackByIdDirective],
  templateUrl: './report-status-selector.component.html',
  styleUrl: './report-status-selector.component.css',
})
export class ReportStatusSelectorComponent implements OnDestroy {
  subscription = new Subscription();
  reportStatuses: RR.ReportStatus[];
  @Input() @BindObservable() value: RR.ReportStatus[] = [];
  value$: Observable<RR.ReportStatus[]>;
  /**
   * valueMap prevents a function call in the template
   */
  valueMap: { [key: string]: RR.ReportStatus } = {};
  @Output() onChange = new EventEmitter<RR.ReportStatus[]>();

  buttonText = 'Select Status';

  constructor(private store: Store<AppState>) {
    this.subscription.add(
      this.store.select(fromReportStatus.selectAll).subscribe((reportStatuses) => {
        this.reportStatuses = reportStatuses;
      }),
    );

    this.subscription.add(
      this.value$.subscribe(() => {
        // `_onChange` also triggers this, because the parent component sends it back in as `@Input() value`.
        if (this.value.length > 0) {
          this.buttonText = this.value.map((status) => status.name).join(', ');
        } else {
          this.buttonText = 'Select Status';
        }

        this.valueMap = this.value.reduce(
          (acc, status) => {
            acc[status.id] = status;
            return acc;
          },
          {} as typeof this.valueMap,
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  _onChange(event: ListboxValueChangeEvent<RR.ReportStatus>) {
    this.value = Array.from(
      // This hack is because `The type 'readonly ReportStatus[]' is 'readonly' and cannot be assigned to the mutable
      // type 'ReportStatus[]'`
      event.value,
    );
    this.onChange.emit(this.value);
  }
}
