import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../../editor/editor.action';
import { PatientQuestionAnswerActions } from './patient-question-answer.action';

export interface PatientQuestionAnswerState extends EntityState<RR.PatientQuestionAnswer> {
  loaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.PatientQuestionAnswer>();

const initialState = adapter.getInitialState({
  loaded: {},
});

export const patientQuestionAnswerReducer = createReducer(
  initialState,
  on(
    PatientQuestionAnswerActions.findManyInReportSuccess,
    (state: PatientQuestionAnswerState, { reportId, patientQuestionAnswers }) => ({
      ...adapter.upsertMany(patientQuestionAnswers, state),
      loaded: { ...state.loaded, [reportId]: true },
    }),
  ),
  on(PatientQuestionAnswerActions.createSuccess, (state: PatientQuestionAnswerState, { patientQuestionAnswer }) =>
    adapter.addOne(patientQuestionAnswer, state),
  ),
  on(PatientQuestionAnswerActions.updateSuccess, (state: PatientQuestionAnswerState, { patientQuestionAnswer }) =>
    adapter.upsertOne(patientQuestionAnswer, state),
  ),
  on(PatientQuestionAnswerActions.deleteSuccess, (state: PatientQuestionAnswerState, { patientQuestionAnswerId }) =>
    adapter.removeOne(patientQuestionAnswerId, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
