import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { IElement, TemplateService } from 'app/core/services/template.service';
import { EditLandmarkModalComponent } from 'app/modules/report/modals/edit-landmark-modal/edit-landmark-modal.component';
import { AppState } from 'app/store/app.state';
import { fromBreadcrumb } from 'app/store/editor';
import { fromLandmarkLabel } from 'app/store/landmark-label/landmark-label.selector';
import { SubsectionChoiceEffect } from 'app/store/report/subsection-choice';
import { fromCurrentTopic, fromTopicChoices } from 'app/store/report/topic';
import { ElementCreateBody, ElementEffect } from 'app/store/template/element';
import { SubsectionEffect } from 'app/store/template/subsection';
import { Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AutoFocusDirective } from '../../../shared/directives/auto-focus.directive';
import { StoreSelectPipe } from '../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { LandmarkLabelHeadlineComponent } from '../prefill/landmark-label-headline/landmark-label-headline.component';
import { RegionSetModalComponent } from '../region-set-modal/region-set-modal.component';
import { StatementEditComponentOnSubmit, StatementEditComponent } from '../statement-edit/statement-edit.component';
import { LibraryModalComponent } from '../statement-set-create/library-modal.component';
import { SubsectionDeleteModalComponent } from '../subsection/subsection-delete-modal.component';

@Component({
  // selector: 'rr-subsection-edit',
  templateUrl: './subsection-edit-modal.component.html',
  standalone: true,
  imports: [
    SharedModule,
    NgbTooltip,
    LandmarkLabelHeadlineComponent,
    StatementEditComponent,
    FormsModule,
    AutoFocusDirective,
    StoreSelectPipe,
    CommonModule,
  ],
})
export class SubsectionEditModalComponent implements OnInit, OnDestroy {
  @Input() nextElement: IElement | undefined; // Using for create element after current selected element
  @Input() subsection_choice: RR.SubsectionChoice;
  topic: RR.Topic | undefined;
  selectedSection: RR.Section | undefined;
  selectedSubsection: RR.Subsection | undefined;
  selectedElement: RR.Element | undefined;
  dropdownSubsections: RR.Subsection[];

  subscription: Subscription = new Subscription();

  mode: 'NONE' | 'RENAME' | 'INSERT' | 'CREATE_ELEMENT' | 'HIDE_SECTION_HEADING' = 'NONE';
  inputText = '';

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
    private modal: NgbModal,
    private store: Store<AppState>,
    private subsectionEffect: SubsectionEffect,
    private elementEffect: ElementEffect,
    private subsectionChoiceEffect: SubsectionChoiceEffect,
    private messageService: MessageService,
    private editorService: EditorService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.store.select(fromBreadcrumb.selectCurrentSection).subscribe((section) => {
        this.selectedSection = section;
      }),
    );

    this.subscription.add(
      this.store.select(fromBreadcrumb.selectCurrentSubsection).subscribe((subsection) => {
        this.selectedSubsection = subsection;
      }),
    );

    this.subscription.add(
      this.store.select(fromBreadcrumb.selectCurrentElement).subscribe((element) => {
        this.selectedElement = element;
      }),
    );

    this.subscription.add(
      this.store.select(fromCurrentTopic.selectTopic).subscribe((topic) => {
        this.topic = topic;
      }),
    );

    this.subscription.add(
      this.store.select(fromBreadcrumb.selectSubsectionOptions).subscribe((subsections) => {
        this.dropdownSubsections = subsections;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickInsert(section: RR.Section, subsection: RR.Subsection, element: RR.Element) {
    this.subscription.add(this.splitSubsection(section, subsection, element).subscribe());
    this.mode = 'NONE';
  }

  onClickRename(subsection: RR.Subsection) {
    const name = this.inputText;
    this.subscription.add(
      this.subsectionEffect
        .update(subsection.id, {
          name,
        })
        .subscribe(),
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.subsection_choice) {
      this.subscription.add(
        this.subsectionChoiceEffect
          .update(this.subsection_choice.id, {
            subsection_name: name,
          })
          .subscribe(),
      );
    }
    this.mode = 'NONE';
  }

  createStatementSet(data: StatementEditComponentOnSubmit, subsection: RR.Subsection) {
    const statement: Partial<RR.Statement> = {
      ...data.statement,
    };
    const text = data.textObjects
      .filter((textObject): textObject is RR.TextObjectLiteral => textObject.type === 'literal')
      .map((textObject) => textObject.value)
      .join('');
    const statementSet: Partial<RR.StatementSet> = {
      // We need to give the statement set a name, so we use the same one as
      // for the statement.
      name: text,
    };
    const element: ElementCreateBody = {
      index: this.nextElement?.subsection.elements.indexOf(this.nextElement.element.id),
      element: {
        subsection_id: subsection.id,
      },
    };
    this.subscription.add(
      this.elementEffect.createElementWithStatement(data.textObjects, statement, statementSet, element).subscribe({
        next: ({ element, statement }) => {
          this.mode = 'NONE';
          if (data.dismissModal) {
            this.activeModal.dismiss();
          }
          // Focus the just created Statement so that it isn't filtered
          // TODO: there seems to be a race condition, the statements are filtered before they are selected?
          this.editorService.publishFocus({
            element_id: element.id,
            statement_id: statement.id,
          });
        },
      }),
    );
  }

  toggleEdit(mode: this['mode']) {
    if (mode === 'RENAME') {
      throw new Error('use toggleModeRename');
    }
    this.mode = mode;
    this.inputText = '';
  }

  toggleModeRename(subsection: RR.Subsection) {
    this.mode = 'RENAME';
    this.inputText = subsection.name ?? '';
  }

  toggleDefault(subsection: RR.Subsection) {
    // The subsection is read only, so the changes to the subsection will not
    // be reflected unless the modal is closed and re-opened.
    this.subscription.add(
      this.subsectionEffect
        .update(subsection.id, {
          default: !subsection.default,
        })
        .subscribe(),
    );
  }

  async addFromLibrary(topic: RR.Topic, section: RR.Section, subsection: RR.Subsection) {
    const modalRef = this.modal.open(LibraryModalComponent, {
      size: 'lg',
    });
    const componentInstance: LibraryModalComponent = modalRef.componentInstance;
    componentInstance.template_id = topic.template_id;
    componentInstance.section = section.type;
    componentInstance.topic = topic;
    modalRef.result.then(
      async (statementSetId) => {
        if (statementSetId == null) {
          return;
        }
        return this.createElement(statementSetId, subsection);
      },
      () => {
        // dismiss
      },
    );
  }

  createElement(statementSetId: number, subsection: RR.Subsection) {
    const beforeElementId = this.nextElement?.element.id;
    // Create it after the selectedElement (before the next one)
    this.templateService.createElement(statementSetId, subsection.id, beforeElementId);
  }

  async deleteSubsection(subsection: RR.Subsection) {
    const modalRef = this.modal.open(SubsectionDeleteModalComponent);
    try {
      await modalRef.result;
      this.subscription.add(this.subsectionEffect.delete(subsection.id).subscribe());
    } catch (dismissed) {
      /* do nothing */
    }
  }

  splitSubsection(section: RR.Section, subsection: RR.Subsection, element: RR.Element) {
    const element_id = element.id;
    const newSubsection: Partial<RR.Subsection> = {
      name: this.inputText,
    };
    return this.subsectionEffect.splitSubsection(section, subsection, element_id, newSubsection);
  }

  addSubsectionWithRegionSet(topic: RR.Topic, subsection: RR.Subsection) {
    const modalRef = this.modal.open(RegionSetModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.subsection = subsection;
    modalRef.componentInstance.topic = topic;
  }

  showOrHideHeading(topic: RR.Topic, section: RR.Section, subsection: RR.Subsection) {
    this.subscription.add(
      this.subsectionEffect
        .update(subsection.id, {
          default_hide_heading: !subsection.default_hide_heading,
        })
        .pipe(
          take(1),
          switchMap((action) => {
            return this.store
              .select(fromTopicChoices.selectSubsectionChoices(topic.id, section.id, subsection.id))
              .pipe(map((subSectionChoices) => ({ action, subSectionChoices })));
          }),
        )
        .subscribe(({ action, subSectionChoices }) => {
          const subSectionChoice = subSectionChoices[0];
          if (subSectionChoice) {
            this.subscription.add(
              this.subsectionChoiceEffect
                .update(subSectionChoice.id, {
                  hide_heading: action.subsection.default_hide_heading,
                })
                // eslint-disable-next-line rxjs/no-nested-subscribe
                .subscribe(),
            );
          }
        }),
    );
  }

  editLandmark(data: { subsection_id?: number; region_id?: number; landmark_label_id: string | null }) {
    EditLandmarkModalComponent.open(this.modal, data);
  }

  selectLandmarkLabel = fromLandmarkLabel.selectLandmarkLabel;

  static open(
    modal: NgbModal,
    { nextElement, subsection_choice }: { nextElement?: IElement; subsection_choice: RR.SubsectionChoice },
  ) {
    const modalRef = modal.open(SubsectionEditModalComponent, {
      size: 'xl',
      centered: true,
    });
    const componentInstance: SubsectionEditModalComponent = modalRef.componentInstance;
    componentInstance.nextElement = nextElement;
    componentInstance.subsection_choice = subsection_choice;
    return modalRef;
  }
}
