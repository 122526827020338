<h5>
  <strong>
    <span class="fa fa-lg fa-exclamation-triangle text-warning"></span>
    There are other same-day bookings for this patient:
  </strong>
</h5>

<ul class="list-group mb-2">
  <ng-container *ngFor="let sameDayBookingId of sameDayBookingIds">
    <li
      *ngIf="sameDayBookingId | storeSelectPipe: selectBooking | async as booking"
      role="button"
      class="list-group-item list-group-item-action"
      [class.active]="sameDayBookingId === bookingId"
      (click)="onClickBooking(booking)"
    >
      <div class="my-2">
        <div class="d-flex align-items-center">
          <h5 class="mb-0 me-2">
            {{ booking.type | titlecase }} - {{ booking.booking_patient ? 'Booking Patient' : 'Existing Patient' }}
          </h5>
          <button
            *ngIf="booking.id !== bookingId && booking.reports.length === 0"
            class="btn btn-outline-primary ms-auto"
            (click)="$event.stopPropagation(); onClickBooking(booking)"
          >
            Register
          </button>
          <ng-container *ngIf="booking.reports.length > 0">
            <ng-container *ngIf="booking.reports[0] | storeSelectPipe: selectReport | async as report">
              <h5 class="text-success mb-0 me-2">Registration Complete <i class="fas fa-check-circle"></i></h5>

              <div class="d-flex align-items-center ms-auto">
                <ng-container *ngIf="report.scan_code_id as scanCodeId">
                  <rr-scan-code-headline
                    *ngIf="scanCodeId | storeSelectPipe: selectScanCode | async as scanCode"
                    class="me-1"
                    [scanCode]="scanCode"
                  ></rr-scan-code-headline>
                </ng-container>
                <button
                  role="button"
                  [class.text-white]="booking.id === bookingId"
                  class="btn btn-link me-1"
                  [routerLink]="['/report', report.id, 'edit']"
                >
                  <rr-report-accession-number [report]="report"></rr-report-accession-number>
                </button>
                <rr-registration-link [newTab]="true" [report]="report">Registration (Q)</rr-registration-link>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <rr-booking-metadata [booking]="booking"></rr-booking-metadata>
      </div></li
  ></ng-container>
</ul>
