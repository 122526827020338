import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserActions } from './user.action';

export interface UserState extends EntityState<RR.User> {
  allUserLoaded: boolean;
  usersLoaded: { [id: number]: boolean };
}

const adapter = createEntityAdapter<RR.User>();

const initialState = adapter.getInitialState({
  allUserLoaded: false,
  usersLoaded: {},
});

export const userReducer = createReducer(
  initialState,
  // User findAll action might have `where` condition that filter out users. So using upsertMany here instead of setAll
  on(UserActions.findAllSuccess, (state: UserState, { users }) => {
    const usersLoadedObj = users
      .map((user) => user.id)
      .reduce((acc, user_id) => {
        return { ...acc, [user_id]: true };
      }, {});
    return {
      ...adapter.upsertMany(users, state),
      allUserLoaded: true,
      usersLoaded: { ...state.usersLoaded, ...usersLoadedObj },
    };
  }),
  on(UserActions.findByIdSuccess, (state: UserState, { user }) => ({
    ...adapter.addOne(user, state),
    allUserLoaded: state.allUserLoaded,
    usersLoaded: { ...state.usersLoaded, [user.id]: true },
  })),
  on(UserActions.upsertOne, (state: UserState, { user }) => adapter.upsertOne(user, state)),
  on(UserActions.upsertMany, (state: UserState, { users }) => adapter.upsertMany(users, state)),
);
