import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { EditorExpandedState } from 'app/modules/editor/editor.component';
import { PrefillService } from 'app/modules/editor/prefill/prefill.service';
import { SharedModule } from 'app/shared/shared.module';
import { fromAddedTopic } from 'app/store/added-topics/added-topics.selector';
import { AppState } from 'app/store/app.state';
import { EditorActions } from 'app/store/editor';
import { AddedTopic } from 'app/store/report/report';
import { TopicEffect, fromCurrentTopic, fromTopic } from 'app/store/report/topic';
import { fromTemplate } from 'app/store/template/template';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
  selector: 'rr-related-topic',
  templateUrl: './related-topic.component.html',
})
export class RelatedTopicComponent implements OnInit, OnDestroy {
  @ViewChild('popoverHeading') popoverHeading: NgbPopover;
  @ViewChild('popoverKeyFindings') popoverKeyFindings: NgbPopover;
  @Input() topic_id: number;
  @Input() relatedTopic: RR.Topic;
  @Input() editorExpandedState: EditorExpandedState;
  @Input() report: RR.Report;
  @Input() selectable: boolean;

  template: RR.Template;
  documents: RR.Document[];
  templateName$: Observable<string>;
  subscription = new Subscription();
  currentTopicId: number | null;

  addedTopic$: Observable<AddedTopic | undefined>;

  constructor(
    private prefillService: PrefillService,
    private store: Store<AppState>,
    private modalService: NgbModal,
    private reportService: ReportService,
    private messageService: MessageService,
    private http: HttpClient,
    private topicEffect: TopicEffect,
  ) {}

  ngOnInit() {
    this.addedTopic$ = this.store.select(fromAddedTopic.selectAddedTopic(this.topic_id));

    const topic$ = this.store.select(fromTopic.selectTopic(this.topic_id));
    this.subscription.add(
      topic$.subscribe((topic) => {
        // @ts-expect-error strictNullChecks
        this.relatedTopic = topic;
      }),
    );

    this.subscription.add(
      this.store.select(fromTemplate.selectTemplate(this.relatedTopic.template_id)).subscribe((t) => {
        // @ts-expect-error strictNullChecks
        this.template = t;
      }),
    );

    this.subscription.add(
      this.store.select(fromCurrentTopic.selectTopicId).subscribe((topicId) => {
        this.currentTopicId = topicId;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addPriorReports({ keyFinding = false }: { keyFinding?: boolean } = {}) {
    if (this.currentTopicId) {
      this.subscription.add(
        this.reportService.copyOtherImaging(this.currentTopicId, this.topic_id, keyFinding).subscribe({
          next: () => {
            this.messageService.add({
              title: 'Success',
              message: 'Add other imaging successfully!',
              type: 'success',
            });
          },
        }),
      );
    }
  }

  importToPrefill() {
    this.subscription.add(
      this.store
        .select(fromCurrentTopic.selectTopicId)
        .pipe(filterDefined(), take(1))
        .subscribe((openTopicId) => {
          const topic_id = this.relatedTopic.id.toString();
          this.prefillService.setPrefillPreviewTopics({
            openTopicId: openTopicId,
            topicIds: [topic_id],
            forceReload: true,
          });
          if (this.editorExpandedState === 'INDEX') {
            this.store.dispatch(EditorActions.togglePrefill({ prefill: true }));
          }
          // Dismiss all modals to show prior topic in prefill
          this.modalService.dismissAll();
        }),
    );
  }

  openPopoverHeading() {
    this.subscription.add(
      this.reportService
        .getOtherImagingHeading([this.topic_id])
        .pipe(take(1))
        .subscribe((heading) => {
          this.popoverHeading.open({ data: heading });
        }),
    );
  }

  openPopoverKF() {
    this.subscription.add(
      this.http
        .get<{ data: string }>('/api/topic/get_other_imaging', {
          params: {
            from_topic_ids: [this.topic_id],
          },
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.popoverKeyFindings.open({ data: res.data });
        }),
    );
  }

  closePopovers() {
    this.popoverHeading.close();
    this.popoverKeyFindings.close();
  }

  deleteOtherImaging() {
    if (this.currentTopicId) {
      this.subscription.add(this.topicEffect.deleteOtherImaging(this.currentTopicId, this.relatedTopic.id).subscribe());
    }
  }
}
