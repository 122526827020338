import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type FeedbackStats = {
  feedback_count: number;
  categorisation_count: number;
  report_feedback_count: number;
  report_id: number;
};

export type FeedbackQueryBody = {
  current_user_id: number;
  checked: 'ALL' | 'UNREAD';
  from_date: string | null;
  to_date: string | null;
};

export type FeedbackSearchResponse = {
  count: number;
  feedbacks: RR.Feedback[];
  feedback_comments: RR.FeedbackComment[];
};

export type FeedbackCheckResponse = {
  feedback: RR.Feedback;
  feedback_comments: RR.FeedbackComment[];
};

@Injectable()
export class FeedbackHttpService {
  constructor(private http: HttpClient) {}

  findAll(params?: HttpParams): Observable<RR.Feedback[]> {
    if (params) {
      return this.http.get<RR.Feedback[]>('/api/feedback', { params });
    }
    return this.http.get<RR.Feedback[]>('/api/feedback');
  }

  findInReport(reportId: number) {
    return this.http.get<RR.Feedback[]>(`/api/report/${reportId}/feedbacks`);
  }

  create(feedback: Partial<RR.Feedback>): Observable<RR.Feedback> {
    return this.http.post<RR.Feedback>('/api/feedback', feedback);
  }

  update(feedbackId: number, changes: Partial<RR.Feedback>): Observable<RR.Feedback> {
    return this.http.put<RR.Feedback>(`/api/feedback/${feedbackId}`, changes);
  }

  delete(feedbackId: number) {
    return this.http.delete(`/api/feedback/${feedbackId}`);
  }

  getFeedbackStats({ initials, reportId }: { initials: string | null; reportId?: number }) {
    let params = new HttpParams();
    if (reportId !== undefined) {
      params = params.set('report_id', reportId.toString());
    }
    return this.http.get<FeedbackStats>(`/api/feedback/new/initials/${initials}`, { params });
  }

  search(queryBody: FeedbackQueryBody, params: HttpParams) {
    return this.http.post<FeedbackSearchResponse>('/api/feedback/search', queryBody, { params });
  }

  check(feedbackId: number): Observable<FeedbackCheckResponse> {
    return this.http.put<FeedbackCheckResponse>(`/api/feedback/${feedbackId}/check`, {});
  }
}
