import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromCompanyRole } from 'app/store/user/company-role';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbDropdownModule],
  selector: 'rr-company-role-selector',
  templateUrl: './company-role-selector.component.html',
  styleUrls: ['./company-role-selector.component.scss'],
})
export class CompanyRoleSelectorComponent implements OnInit, OnDestroy {
  /**
   * single: radio buttons
   * multiple: checkboxes
   */
  @Input() mode: 'single' | 'multiple' = 'multiple';
  @Output() onSelect = new EventEmitter<RR.CompanyRole[]>();
  @Input() formArray: FormControl<string[]> | undefined;

  subscription = new Subscription();
  allCompanyRoles: RR.CompanyRole[] = [];
  checkedRoles: { [id: string]: RR.CompanyRole } = {};
  checkedRolesList: RR.CompanyRole[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // this.companyRoleEffect.findAll(); // loaded by parent - UsersListComponent, SendFeedbackModal
    this.subscription.add(
      this.store.select(fromCompanyRole.selectAll).subscribe((roles) => {
        this.allCompanyRoles = roles;

        if (this.formArray) {
          this.checkedRoles = this.formArray.value.reduce(
            (prev, curr) => {
              const companyRole = this.allCompanyRoles.find((r) => r.id === curr);
              if (!companyRole) {
                return prev;
              }
              prev[curr] = companyRole;
              return prev;
            },
            {} as typeof this.checkedRoles,
          );
          this.checkedRolesList = Object.values(this.checkedRoles);
        }
      }),
    );
  }

  onRoleChange(role: RR.CompanyRole) {
    if (this.mode === 'single') {
      this.checkedRoles = { [role.id]: role };
      this.checkedRolesList = Object.values(this.checkedRoles);
      this.onSelect.emit(this.checkedRolesList);
      if (this.formArray) this.formArray.setValue(this.checkedRolesList.map((r) => r.id));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.checkedRoles[role.id]) {
      delete this.checkedRoles[role.id];
    } else {
      this.checkedRoles[role.id] = role;
    }
    this.checkedRolesList = Object.values(this.checkedRoles);
    this.onSelect.emit(this.checkedRolesList);
    if (this.formArray) this.formArray.setValue(this.checkedRolesList.map((r) => r.id));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
