import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ReferralViewSummaryComponent } from '../../components/booking-view/referral-view-summary/referral-view-summary.component';

@Component({
  selector: 'rr-e-referral-data-modal',
  templateUrl: './e-referral-data-modal.component.html',
  styleUrls: ['./e-referral-data-modal.component.scss'],
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, ReferralViewSummaryComponent],
})
export class EReferralDataModalComponent {
  referralId: number;

  constructor(public activeModal: NgbActiveModal) {}

  static open(modalService: NgbModal, referralId: number) {
    const modalRef = modalService.open(EReferralDataModalComponent, {
      scrollable: true,
      size: 'md',
    });
    modalRef.componentInstance.referralId = referralId;

    return modalRef;
  }
}
