import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { SelectorService } from 'app/core/services/selector.service';
import { AppState } from 'app/store/app.state';
import { fromTopicChoices } from 'app/store/report/topic';
import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: '[rr-subsection-breadcrumb]',
  templateUrl: './subsection-breadcrumb.component.html',
  styleUrls: ['./subsection-breadcrumb.component.css'],
  standalone: true,
})
export class SubsectionBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() topic: RR.Topic;
  @BindObservable() @Input() subsection: RR.Subsection | null;
  subsection$: Observable<RR.Subsection | null>;
  @Input() section: RR.Section;

  @HostBinding('attr.title') title: string;
  subscription = new Subscription();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private store: Store<AppState>,
    private selectorService: SelectorService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.subsection$.subscribe((subsection) => {
        this.title = subsection?.name ?? '';
      }),
    );
    this.subscription.add(
      this.subsection$
        .pipe(
          switchMap((subsection) => {
            if (!subsection) {
              return of(null);
            }
            return this.selectorService.selectCurrentTopicIfLoaded().pipe(
              filterDefined(),
              map((topic) => {
                return {
                  topic,
                  subsection,
                };
              }),
            );
          }),
          filterDefined(),
          switchMap(({ topic, subsection }) => {
            return this.store
              .select(fromTopicChoices.selectSubsectionChoices(topic.id, this.section.id, subsection.id))
              .pipe(map((subsection_choices) => subsection_choices[0]));
          }),
        )
        .subscribe((subsectionChoice) => {
          if (subsectionChoice) {
            this.renderer.removeClass(this.el.nativeElement, 'text-muted');
          } else {
            this.renderer.addClass(this.el.nativeElement, 'text-muted');
          }
        }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
