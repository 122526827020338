import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MandatoryStatementsModalComponent } from 'app/modules/editor/mandatory-statements-modal/mandatory-statements-modal.component';
import { JustificationModalComponent } from 'app/modules/editor/modals/justification-modal/justification-modal.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { JustificationEffect } from 'app/store/report/justification';
import { Subscription } from 'rxjs';

import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class MandatoryStatementService implements OnDestroy {
  subscription = new Subscription();
  constructor(
    private modalService: NgbModal,
    private justificationEffect: JustificationEffect,
    private messageService: MessageService,
  ) {}

  mandatoryStatementChecker(
    statement: RR.Statement | undefined,
    incompleteMandatoryStatements: RR.MandatoryStatement[] | undefined,
  ) {
    if (!statement) return;
    /**
     * If there are pending mandatory statements to review and the selected statement is not a mandatory one, then show warning modal.
     */
    if (
      incompleteMandatoryStatements &&
      incompleteMandatoryStatements.length > 0 &&
      !this.isIncompleteMandatoryStatement(statement.id, incompleteMandatoryStatements)
    ) {
      this.openMandatoryStatementModal(incompleteMandatoryStatements);
    }
  }

  isIncompleteMandatoryStatement(statementId: number, incompleteMandatoryStatements: RR.MandatoryStatement[]) {
    return incompleteMandatoryStatements.some((statement) => {
      return statement.statement_id === statementId;
    });
  }

  openMandatoryStatementModal(incompleteMandatoryStatements: RR.MandatoryStatement[]) {
    MandatoryStatementsModalComponent.open(this.modalService, incompleteMandatoryStatements);
  }

  openJustificationModal(topic: RR.Topic, mandatoryStatement: RR.MandatoryStatement) {
    JustificationModalComponent.open(this.modalService, {
      topic: topic,
      mandatoryStatementId: mandatoryStatement.id,
    });
  }

  deleteJustification(justification: RR.Justification) {
    const confirmModalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: 'Do you want to delete this justification?',
      btnConfirmText: 'Yes',
    });

    confirmModalRef.result
      .then(() => {
        this.subscription.add(
          this.justificationEffect.delete(justification.id).subscribe({
            next: () => {
              this.messageService.add({
                title: 'Success',
                type: 'success',
                message: 'Justification deleted',
              });

              confirmModalRef.dismiss();
            },
          }),
        );
      })
      .catch(() => {
        // Do nothing if the user cancels the confirmation
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
