<ng-container *ngIf="topic$ | async as topic">
  <div *ngIf="topic.ref_topics && topic.ref_topics.length > 0" class="alert alert-secondary">
    <div>Prefilled from:</div>
    <rr-ref-topic
      class="d-block mb-1"
      *ngFor="let refTopicId of topic.ref_topics"
      [topic]="(refTopicId | storeSelectPipe: selectTopicSelectorFn | async) || undefined"
      [context]="context"
      (onSelectFavourite)="onSelectFavourite.emit($event)"
    >
    </rr-ref-topic>
  </div>
</ng-container>
