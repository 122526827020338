import { ActivatedRouteSnapshot } from '@angular/router';
import { isBefore } from 'date-fns';
import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PercentileBandRule } from './store/percentile-band-rule';

export function strToNum(str: string): number {
  const NonNumericString = Error(`passed value "${str}" is not a valid number literal`);
  if (str === '' || str.trim() !== str) throw NonNumericString;
  const return_value = Number(str);
  if (Number.isNaN(return_value)) throw NonNumericString;
  return return_value;
}

export function concatenateStrings(...args: string[]): string {
  return args.length <= 2 ? args.join(' and ') : `${args.slice(0, -1).join(', ')}, and ${args.slice(-1)[0]}`;
}
export const nbspRegex = /\xA0/g;

function scroll(parent: Element, scrollTop: number) {
  parent.scrollTop = scrollTop;
}

export function scrollPreviewToElement(html_element: HTMLElement | null) {
  if (html_element === null) return;
  scrollIntoView({
    element: html_element,
    parent: document.querySelector('#preview-scroll'),
    topOffset: 25,
    scrollToTop: true,
  });
}

/**
 * Scrolls element into view when it not within the bounds of parent
 * When there is a position sticky heading at the top, use topOffset to scroll to the edge of the heading
 * (document.documentElement is <html>)
 * @param parent document.querySelector('.preview') or 'rr-topic' or 'rr-index'
 */
export function scrollIntoView({
  element,
  parent = document.documentElement,
  topOffset = 0,
  scrollToTop = false,
}: {
  element: HTMLElement;
  parent?: Element | HTMLElement | null;
  topOffset?: number;
  scrollToTop?: boolean;
}) {
  if (parent === null) {
    // If the querySelector element returns null
    console.warn('scrollIntoView parent should not be null');
    return;
  }
  let parentElementTop: number;
  let parentElementBottom: number;
  const scrollTop = parent.scrollTop;
  if (parent === document.documentElement) {
    parentElementTop = 0;
    parentElementBottom = window.innerHeight;
  } else {
    parentElementTop = parent.getBoundingClientRect().top;
    parentElementBottom = parent.getBoundingClientRect().bottom;
  }
  const parentHeight = parentElementBottom - parentElementTop;
  const elementTop = element.getBoundingClientRect().top;
  const elementBottom = element.getBoundingClientRect().bottom;
  const elementHeight = elementBottom - elementTop;
  const elementOffsetTop = elementTop - (parentElementTop - scrollTop);
  if (elementTop - topOffset < parentElementTop || scrollToTop) {
    // topOffset, don't scroll all the way to the edge
    scroll(parent, elementOffsetTop - topOffset);
  } else if (parentElementBottom < elementBottom) {
    scroll(parent, elementOffsetTop - parentHeight + elementHeight);
  }
}

/**
 * @param NEAR_EDGE_THRESHOLD how many pixels away from the edge is considered "near"
 * @returns where the element is scrolled to in the viewport of the container. Above, below, or visible in the viewport.
 */
export function elementRelativeToParent({
  element,
  parentElement,
  NEAR_EDGE_THRESHOLD = 20,
}: {
  element: HTMLElement;
  parentElement: HTMLElement;
  NEAR_EDGE_THRESHOLD?: number;
}) {
  const elementRect = element.getBoundingClientRect();
  const parentRect = parentElement.getBoundingClientRect();
  const parentScrollTop = parentElement.scrollTop;
  const parentHeight = parentRect.bottom - parentRect.top;
  const parentScrollBottom = parentScrollTop + parentHeight;
  const elementHeight = elementRect.bottom - elementRect.top;
  const elementOffsetTop = elementRect.top - (parentRect.top - parentScrollTop);
  const elementOffsetBottom = elementOffsetTop + elementHeight;

  const isAbove = elementOffsetTop < parentScrollTop;
  const isBelow = elementOffsetBottom > parentScrollBottom;
  const isBetween = elementOffsetTop > parentScrollTop && parentScrollBottom > elementOffsetBottom;
  const isNearTop = Math.abs(elementOffsetTop - parentScrollTop) < NEAR_EDGE_THRESHOLD;

  return {
    isNearTop,
    isAbove,
    isBelow,
    isBetween,
  };
}

export function getPatientDOB(patient: RR.Patient): Date | undefined {
  if (patient.patient_dob !== null) {
    return new Date(patient.patient_dob);
  }
  return undefined;
}

export function getPatientAgeToday(patient: RR.Patient): number | undefined {
  const today = new Date();

  const birthDate = getPatientDOB(patient);
  if (!birthDate) {
    return undefined;
  }
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function getPatientAgeAndSex(patient: RR.Patient, abbr?: boolean): string | undefined {
  const results: string[] = [];
  if (patient.patient_sex === 'female') {
    results.push('F');
  } else if (patient.patient_sex === 'male') {
    results.push('M');
  }

  const patient_age = getPatientAgeToday(patient);
  if (patient_age) {
    if (patient_age > 1) {
      results.push(`<strong>${patient_age}</strong> ${abbr ? 'yo' : 'years old'}`);
    } else if (patient_age === 1) {
      results.push(`<strong>1</strong> ${abbr ? 'yo' : 'year old'}`);
    } else if (patient_age < 1) {
      results.push(`<strong>${getBabyAge(patient)}</strong> ${abbr ? 'mo' : 'months old'}`);
    }
  }

  if (results.length > 0) {
    return `(${results.join(' ')})`.trim();
  }
  return undefined;
}

export function getPatientShortInfo(report: RR.Report, patient: RR.Patient) {
  let result = getPatientAgeAndSex(patient);
  if (report.accession_number != null) {
    result += ', ' + report.accession_number;
  }
  return result;
}

export function getBabyAge(patient: RR.Patient): number | undefined {
  const today = new Date();
  const birthDate = getPatientDOB(patient);
  if (birthDate) {
    const age = today.getFullYear() - birthDate.getFullYear();
    if (age === 1) {
      return 12 - birthDate.getMonth() + today.getMonth();
    } else if (age < 1) {
      return today.getMonth() - birthDate.getMonth();
    }
  }
  return undefined;
}

export function getPatientShortAgeAndSex(patient: { patient_sex: RR.Sex; patient_age_in_days: number }) {
  let age = '';
  // TODO: potentially off by 1 day b/c of leap years.
  // potentially return to using reportService.getPatientAgeToday with date modifier
  age = String(Math.floor(patient.patient_age_in_days / 365.25));

  return `${getPatientSexAbbr(patient)} ${age}`.trim();
}

export function getPatientSexAbbr(patient: { patient_sex: RR.Sex; patient_age_in_days: number }) {
  if (patient.patient_sex === 'female') {
    return 'F';
  } else if (patient.patient_sex === 'male') {
    return 'M';
  }
  return 'Unknown Sex';
}

export function getImageUrl(report: RR.Report) {
  return report.image_url;
}

export function trackById(_index: number, item: { id: number | string }) {
  return item.id;
}

export function isTextInput(target: HTMLElement) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (target != null && ['textarea', 'input'].includes(target.nodeName.toLowerCase())) || target.isContentEditable;
}

/**
 * Take all the remaining vertical space and no more
 * to make sure that the element does not flow outside the viewport
 * @param el the element, it should have overflow-y set
 */
export function fixMaxHeight(el: HTMLElement) {
  const MAX_HEIGHT = window.innerHeight - el.getBoundingClientRect().top;
  el.style.maxHeight = MAX_HEIGHT + 'px';
}

const REGEX_WHITESPACE = /^\s*$/;
export function elementsAreEmpty(elements: NodeListOf<HTMLElement>) {
  // seems to be a browser bug:
  // when the parent element has display: none the child innerText is ' '
  // but without display: none the innerText is ''
  //
  // so I added a check for ' ' aswell to prevent elements from reappearing after hiding
  return Array.from(elements).every(
    (element) =>
      REGEX_WHITESPACE.test(element.innerText) || getComputedStyle(element).getPropertyValue('display') === 'none',
  );
}

type SubjectByProp = Map<string, ReplaySubject<any>>;

// eslint-disable-next-line @typescript-eslint/ban-types
const subjects: WeakMap<Object, SubjectByProp> = new WeakMap();

type ValueByProp = Map<string, any>;

// eslint-disable-next-line @typescript-eslint/ban-types
const values: WeakMap<Object, ValueByProp> = new WeakMap();

function subject(instance: any, key: string): ReplaySubject<any> {
  let subjectByProp = subjects.get(instance);

  if (!subjectByProp) {
    subjectByProp = new Map<string, ReplaySubject<any>>();
    subjects.set(instance, subjectByProp);
  }

  let _subject = subjectByProp.get(key);

  if (!_subject) {
    _subject = new ReplaySubject<any>(1);
    subjectByProp.set(key, _subject);
  }

  return _subject;
}

function valueMap(instance: any): ValueByProp {
  let _valueMap = values.get(instance);

  if (!_valueMap) {
    _valueMap = new Map<string, any>();
    values.set(instance, _valueMap);
  }

  return _valueMap;
}

/**
 * https://github.com/ohjames/observable-input
 *
 * Binds a property to an observable companion property.
 * @param {string} observableKey
 * optional custom key of the companion property.
 * If not provided, the observableKey is the name of the original property key with a '$' suffix.
 */
export function BindObservable(observableKey?: string) {
  return (target: any, propertyKey: string) => {
    observableKey = observableKey || propertyKey + '$';

    delete target[propertyKey];
    delete target[observableKey];

    Object.defineProperty(target, propertyKey, {
      set(value) {
        valueMap(this).set(propertyKey, value);
        subject(this, observableKey as string).next(value);
      },
      get() {
        return valueMap(this).get(propertyKey);
      },
    });

    Object.defineProperty(target, observableKey, {
      get() {
        return subject(this, observableKey as string);
      },
    });
  };
}

export function getDeepestRoute(routeSnapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  // consider /report/<id>/edit, the firstChild refers to /report,
  // but we need to know we are actually in the edit route
  if (routeSnapshot.firstChild) {
    return getDeepestRoute(routeSnapshot.firstChild);
  }
  return routeSnapshot;
}

export function convertSecondsToHMS(s: number): number[] {
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const seconds = Math.floor((s % 3600) % 60);
  return [hours, minutes, seconds];
}

/**
 * Check if 2 list have exactly the same items
 * @param list1
 * @param list2
 */
export function checkSameStatementLists(list1: RR.Statement[], list2: RR.Statement[]) {
  if (list1.length !== list2.length) return false;
  for (const i in list1) {
    if (list1[i] !== list2[i]) return false;
  }
  return true;
}

/**
 * @deprecated most of the cases should be handled by webargsValidationErrorMessage in MessageService
 * Parses responses from marshmallow ValidationError 400s
 */
export function validationErrorToMessages(error?: string | { items: { [k: string]: string } }) {
  if (typeof error === 'string') {
    return [error];
  } else if (error?.items) {
    return Object.entries(error.items).map(([key, value]) => {
      // eg.
      // initials: Shorter than minimum length 1.
      return `${key}: ${value}`;
    });
  } else {
    return undefined;
  }
}

/**
 * Truncate text
 */
export function truncateText(text: string, len: number) {
  if (!text) return '';
  if (text.length <= len) return text;
  return text.slice(0, len) + '...';
}

export function getUserRoleName(role_id: string) {
  switch (role_id) {
    case 'junior_sonographer':
      return 'Sono Assistant';
    case 'sonographer':
      return 'Sono';
    case 'junior_radiographer':
      return 'Rad Assistant';
    case 'radiographer':
      return 'Rad';
    case 'imaging_technician':
      return 'Img Tech';
    case 'doctor':
      return 'Doctor';
    default:
      return 'Other';
  }
}

export function getUserShortName(user: RR.User) {
  const fullname = user.name;
  if (!fullname.trim()) return '';

  const parts = fullname.trim().split(' ');
  // eg: "H Bartlett" instead of "Hayden Bartlett"
  return (
    parts
      .slice(0, parts.length - 1)
      .map((p) => p[0])
      .join(' ') +
    ' ' +
    parts[parts.length - 1]
  );
}
/**
 * Source: https://medium.com/ngconf/filtering-types-with-correct-type-inference-in-rxjs-f4edf064880d
 *
 * A type guard (`input is T`) is required for the type to be correct for subscribe (and other operators). Added for
 * strictNullChecks.
 */
export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}
export function filterDefined<T>() {
  return (source$: Observable<null | undefined | T>) => source$.pipe(filter(inputIsNotNullOrUndefined));
}

export function atLeastOneContact(entity: RR.Patient | RR.Referrer, keys: string[]): boolean {
  return Object.keys(entity).some((key) => keys.includes(key) && entity[key] !== null && entity[key] !== '');
}

export function expiredMedicare(expiryMonth: number | undefined, expiryYear: number | undefined): boolean {
  const expMonth = expiryMonth;
  const expYear = Number('20' + expiryYear);
  let dateObj;
  if (expMonth && expYear) dateObj = new Date(expYear, expMonth - 1);

  const currentDate = new Date();

  return !!dateObj && isBefore(dateObj, currentDate);
}

/**
 * Return the tagId from statement_id, region_id and subsection_id
 * @param statement_id
 * @param subsection_id
 * @param region_id
 * @returns
 */
export function constructTagId(statement_id: number, subsection_id: number, region_id: number | null) {
  const region = region_id ?? '';
  return `${statement_id}-${subsection_id}-${region}`;
}

/**
 * Return the tagId from a divider
 * @param divider
 * @returns
 */
export function constructTagIdFromDivider(divider: RR.Divider) {
  return constructTagId(divider.statement_id, divider.subsection_id, divider.region_id);
}

export function getParameterName(parameterId: number | null, parameters: RR.ImgsimParameter[] | undefined): string {
  if (!parameterId || !parameters) {
    return '';
  }

  const parameter = parameters.find((param) => param.id === parameterId);

  return parameter ? parameter.parameter : '';
}

export function getPercentileRuleName(rule: PercentileBandRule, parameters: RR.ImgsimParameter[] | undefined) {
  const parameterName = getParameterName(rule.imgsim_parameter_id, parameters);
  return parameterName ? `${parameterName} - ${rule.operator} - ${rule.value}` : '';
}
