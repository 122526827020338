import React from 'react';
import { RenderElementProps } from 'slate-react';

import { useSelectedStyle } from './useSelectedStyle';

const transform: Map<RR.DateType, string> = new Map();
transform.set('day_month_year', 'dd/mm/yyyy');
transform.set('month_year', 'mm/yyyy');
transform.set('year', 'yyyy');

export function DateTextObjectComponent(
  props: RenderElementProps & { onClick: React.DOMAttributes<HTMLSpanElement>['onClick'] },
) {
  const { attributes, children, element, onClick } = props;
  if (element.type !== 'rr-date') {
    throw new Error('DateTextObjectComponent can only render rr-date elements');
  }

  const style = useSelectedStyle();
  const dateTypeString = transform.get(element.date_type);
  return (
    <span className="rr-statement-attribute-placeholder" style={style} onClick={onClick} {...attributes}>
      {dateTypeString}
      {children}
    </span>
  );
}
