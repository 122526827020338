<h3 class="px-0 py-2 mb-3 card-header rounded-0 bg-light sticky-top" role="button" (click)="jumpToSection()">
  <b class="col">{{ section.title }}</b>
</h3>

<div cdkDropListGroup>
  <rr-index-subsection
    *ngFor="let subsection of subsections$ | async; trackBy: trackBy"
    class="d-block"
    [section]="section"
    [subsection]="subsection"
    [section_choice]="section_choice"
  >
  </rr-index-subsection>
</div>
