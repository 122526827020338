<ng-template #zeroFPFormsTmpl> Forms <span class="small fa fa-external-link-alt"></span> </ng-template>
<a
  [ngClass]="{
    'text-nowrap d-block': !sidebar,
    'btn btn-sm btn-light text-nowrap w-100 text-start': sidebar
  }"
  *ngIf="report.image_url2 != null"
  rrTooltip="OPEN_IMAGE2"
  [href]="report.image_url2"
  target="_blank"
>
  <div [rrNoContent]="zeroFPFormsTmpl"><ng-content></ng-content></div>
</a>
