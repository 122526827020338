import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, NgbDropdownModule],
  selector: 'rr-structured-voice-terms',
  templateUrl: './structured-voice-terms.component.html',
  styleUrls: ['./structured-voice-terms.component.css'],
})
export class StructuredVoiceTermsComponent implements OnInit, OnDestroy {
  // SOABS: Search or Add Blue Statmenet
  @Input() parent?: 'SOABS' | undefined;
  @Input() @BindObservable() voiceResults: RR.VoiceResult[];
  voiceResults$: Observable<RR.VoiceResult[]>;
  subscription = new Subscription();
  structuredVoiceTags: { subsection: string; value: RR.VoiceResult[] }[] = [];

  constructor(
    private editorService: EditorService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.voiceResults$.subscribe((results) => {
        const groupedVoiceResults = results.reduce(
          (acc, curr) => {
            const key = curr.subsection ?? '';
            // Fix with 'noUncheckedIndexedAccess'
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const group = acc[key] || [];
            group.push(curr);
            acc[key] = group;
            return acc;
          },
          {} as { [subsection: string]: RR.VoiceResult[] },
        );

        this.structuredVoiceTags = Object.keys(groupedVoiceResults)
          .filter((subsection) => !!subsection)
          .map((subsection) => ({ subsection, value: groupedVoiceResults[subsection] }));
      }),
    );
  }

  copyToSearch(term: string) {
    if (this.parent === 'SOABS') {
      this.editorService.searchTextInSoabs(term);
      return;
    }

    this.editorService.globalSearchTerm$.next({ source: 'TAG', term });
  }

  deleteResult(voiceResult: RR.VoiceResult) {
    this.store.dispatch(EditorActions.removeVoiceSearchResult({ voiceResult }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
