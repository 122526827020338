<div *ngIf="invoiceId" class="d-flex align-items-center mb-2">
  <ng-container *ngIf="reportId">
    <button class="btn btn-outline-primary me-2" (click)="linkReports()">Link Reports</button>
  </ng-container>
  <button class="btn btn-outline-secondary me-2" (click)="openLinkTransactionModal()">Link Medipass Transaction</button>
</div>

<ng-container *ngIf="reportId">
  <rr-invoice-report-list
    [reportId]="reportId"
    [invoiceId]="invoiceId"
    [reInvoice]="reInvoice"
  ></rr-invoice-report-list>
</ng-container>

<div class="my-2"></div>

<rr-invoice-form-edit
  [reportId]="reportId"
  [bookingId]="bookingId"
  [invoiceId]="invoiceId"
  [reInvoice]="reInvoice"
  (onClose)="onClose.emit()"
></rr-invoice-form-edit>
