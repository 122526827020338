import { createAction, props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { BookingActions } from '../booking/booking.action';
import { InstitutionActions } from '../institution/institution.action';
import { InvoiceItemActions } from '../invoice-item/invoice-item.action';
import { PaymentActions } from '../payment/payment.action';
import { ReportActions } from '../report/report/report.action';

const searchSuccess = createAction('[Invoice] Search Success', props<{ invoices: RR.Invoice[]; count: number }>());

const findInReportSuccess = createAction(
  '[Invoice] Find In Report Success',
  props<{ reportId: number; invoices: RR.Invoice[] }>(),
);

const findInBookingSuccess = createAction(
  '[Invoice] Find In Booking Success',
  props<{ bookingId: number; invoices: RR.Invoice[] }>(),
);

const findManySuccess = createAction('[Invoice] Find Many Success', props<{ invoices: RR.Invoice[] }>());

const createSuccess = createAction('[Invoice] Create Success', props<{ invoice: RR.Invoice }>());

// Exported to resolve circular type between invoice.action and invoice-item.action
export const updateSuccess = createAction('[Invoice] Update Success', props<{ invoice: RR.Invoice }>());

const createReportInvoiceSuccessBatch = createBatchAction(
  '[Invoice] Create Report Invoice Success Batch',
  props<{
    actions: {
      createInvoiceSuccess: ReturnType<typeof createSuccess>;
      updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
    };
  }>(),
);
const createBookingInvoiceSuccessBatch = createBatchAction(
  '[Invoice] Create Booking Invoice Success Batch',
  props<{
    actions: {
      createInvoiceSuccess: ReturnType<typeof createSuccess>;
      updateBookingSuccess: ReturnType<typeof BookingActions.updateSuccess>;
    };
  }>(),
);
const findInReportSuccessBatch = createBatchAction(
  '[Invoice] Find In Report Success Batch',
  props<{
    actions: {
      findInvoicesInReportSuccess: ReturnType<typeof findInReportSuccess>;
      invoiceItemFindManySuccess: ReturnType<typeof InvoiceItemActions.findManySuccess>;
      institutionFindManySuccess: ReturnType<typeof InstitutionActions.findManySuccess>;
      reportFindManySuccess: ReturnType<typeof ReportActions.upsertMany>;
      findPaymentSuccess: ReturnType<typeof PaymentActions.findManySuccess>;
    };
  }>(),
);
const findInBookingSuccessBatch = createBatchAction(
  '[Invoice] Find In Booking Success Batch',
  props<{
    actions: {
      findInvoicesInBookingSuccess: ReturnType<typeof findInBookingSuccess>;
      invoiceItemFindManySuccess: ReturnType<typeof InvoiceItemActions.findManySuccess>;
      institutionFindManySuccess: ReturnType<typeof InstitutionActions.findManySuccess>;
      bookingFindManySuccess: ReturnType<typeof BookingActions.findManySuccess>;
      findPaymentSuccess: ReturnType<typeof PaymentActions.findManySuccess>;
    };
  }>(),
);
const searchSuccessBatch = createBatchAction(
  '[Invoice] Search Invoice Success Batch',
  props<{
    actions: {
      invoiceSearchSuccess: ReturnType<typeof searchSuccess>;
      invoiceItemFindManySuccess: ReturnType<typeof InvoiceItemActions.findManySuccess>;
      institutionFindManySuccess: ReturnType<typeof InstitutionActions.findManySuccess>;
      findReportSuccess: ReturnType<typeof ReportActions.upsertMany>;
      findPaymentSuccess: ReturnType<typeof PaymentActions.findManySuccess>;
    };
  }>(),
);
const linkReportSuccessBatch = createBatchAction(
  '[Invoice] Link Report Success Batch',
  props<{
    actions: {
      updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
      updateInvoiceSuccess: ReturnType<typeof updateSuccess>;
    };
  }>(),
);
const unlinkReportSuccessBatch = createBatchAction(
  '[Invoice] Unlink Report Success Batch',
  props<{
    actions: {
      updateReportSuccess: ReturnType<typeof ReportActions.upsertOne>;
      updateInvoiceSuccess: ReturnType<typeof updateSuccess>;
    };
  }>(),
);
const linkMedipassSuccessBatch = createBatchAction(
  '[Invoice] Link Medipass Success Batch',
  props<{
    actions: {
      updateInvoiceSuccess: ReturnType<typeof updateSuccess>;
      updateInvoiceItemSuccess: ReturnType<typeof InvoiceItemActions.findManySuccess>;
    };
  }>(),
);

/**
 * Ngrx Actions for Invoice
 *
 */
export const InvoiceActions = {
  searchSuccess,
  findInReportSuccess,
  findInBookingSuccess,
  findManySuccess,
  createSuccess,
  updateSuccess,

  createReportInvoiceSuccessBatch,
  createBookingInvoiceSuccessBatch,
  findInReportSuccessBatch,
  findInBookingSuccessBatch,
  searchSuccessBatch,
  linkReportSuccessBatch,
  unlinkReportSuccessBatch,
  linkMedipassSuccessBatch,
};
