import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'app/core/services/message.service';
import { InvoiceEffect } from 'app/store/invoice';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: 'rr-link-medipass-invoice',
  templateUrl: './link-medipass-invoice.component.html',
  styleUrls: ['./link-medipass-invoice.component.css'],
})
export class LinkMedipassInvoiceComponent implements OnDestroy {
  invoice_id: number;
  form = new FormGroup({
    transactionId: new FormControl('', { validators: [Validators.required], nonNullable: true }),
  });

  subscription = new Subscription();

  constructor(
    private invoiceEffect: InvoiceEffect,
    public activeModal: NgbActiveModal,
    private messageService: MessageService,
  ) {}

  linkMedipassTransaction() {
    this.subscription.add(
      this.invoiceEffect.linkMedipassTransaction(this.invoice_id, this.form.controls.transactionId.value).subscribe({
        next: () => {
          this.activeModal.close(true);
          this.messageService.add({
            message: 'Update invoice with transaction',
            title: 'Success',
            type: 'success',
            timeout: 5000,
          });
        },
        error: () => {
          this.messageService.add({
            message: 'Failed to update invoice with transaction',
            title: 'Error',
            type: 'danger',
            timeout: 5000,
          });
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modal: NgbModal, invoice_id: number) {
    const modalRef = modal.open(LinkMedipassInvoiceComponent, {
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.invoice_id = invoice_id;
    return modalRef;
  }
}
