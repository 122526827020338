import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface TodoGet {
  todo: RR.Todo;
  audit_events: RR.AuditEvent[];
  users: RR.User[];
}

@Injectable()
export class TodoHttpService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<RR.Todo[]> {
    return this.http.get<RR.Todo[]>('/api/todos');
  }

  create(todo: Partial<RR.Todo>): Observable<TodoGet> {
    return this.http.post<TodoGet>('/api/todos', todo);
  }

  update(todoId: number, changes: Partial<RR.Todo>): Observable<TodoGet> {
    return this.http.put<TodoGet>(`/api/todos/${todoId}`, changes);
  }

  delete(todoId: number) {
    return this.http.delete(`/api/todos/${todoId}`);
  }
}
