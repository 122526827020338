<div class="modal-header">
  <h5 class="modal-title">Check Feedback</h5>
  <button role="button" class="btn-close" (click)="closeModal()"></button>
</div>
<div class="modal-body scroll-hidden d-flex">
  <div *ngIf="!isAuthorised && kioskState">
    <div *ngIf="noNewFeedbacks > 0">
      You got <strong>{{ noNewFeedbacks }}</strong> new feedback or {{ noNewFeedbacks > 1 ? 'comments' : 'comment' }}.
      Please enter your password to check.
    </div>
    <div *ngIf="noNewFeedbacks === 0">
      You have no new feedback. Please enter your password to check previous feedback.
    </div>
    <rr-modal-auth (onAuthenticated)="onAuthenticated()"></rr-modal-auth>
  </div>

  <ng-container *ngIf="isAuthorised || !kioskState">
    <rr-user-feedback-list [reportId]="reportId" class="d-flex flex-grow-1"></rr-user-feedback-list>
  </ng-container>
</div>
<div class="modal-footer d-flex">
  <button role="button" class="btn btn-secondary ms-auto align-self-end" (click)="closeModal()">
    <i class="fa fa-times"></i> Close
  </button>
</div>
