<div class="container px-0 mb-2">
  <div class="d-flex align-items-center">
    <form [formGroup]="paymentFormGroup" class="input-group bootstrap-validate">
      <div class="input-group d-flex">
        <input type="number" [formControl]="paymentFormGroup.controls.amount_paid" class="form-control" />
        <select class="form-select" [formControl]="paymentFormGroup.controls.payment_type">
          <option [ngValue]="null">None</option>
          <option *ngFor="let paymentType of paymentTypes" [value]="paymentType.id">{{ paymentType.name }}</option>
        </select>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          (click)="removePayment()"
          [disabled]="paymentFormGroup.disabled"
        >
          Remove
        </button>
        <span class="invalid-feedback" *ngIf="paymentFormGroup.controls.amount_paid.errors?.required">
          Value is required
        </span>
        <span class="invalid-feedback" *ngIf="paymentFormGroup.controls.payment_type.errors?.required">
          Payment type is required
        </span>
      </div>
    </form>
  </div>
</div>
