import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ScanCodeActions } from './scan-code.action';

export interface ScanCodeState extends EntityState<RR.ScanCode> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.ScanCode>();

const initialState = adapter.getInitialState({ loaded: false });

export const scanCodeReducer = createReducer(
  initialState,
  on(ScanCodeActions.findAllSuccess, (state: ScanCodeState, { scanCodes }) => ({
    ...adapter.setAll(scanCodes, state),
    loaded: true,
  })),
  on(ScanCodeActions.findManySuccess, (state: ScanCodeState, { scanCodes }) => adapter.upsertMany(scanCodes, state)),
  on(ScanCodeActions.findSuccess, (state: ScanCodeState, { scanCode }) => adapter.upsertOne(scanCode, state)),
  on(ScanCodeActions.createSuccess, (state: ScanCodeState, { scanCode }) => adapter.addOne(scanCode, state)),
  on(ScanCodeActions.updateSuccess, (state: ScanCodeState, { scanCode }) => adapter.upsertOne(scanCode, state)),
  on(ScanCodeActions.deleteSuccess, (state: ScanCodeState, { scanCodeId }) => adapter.removeOne(scanCodeId, state)),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
