import React from 'react';
import { useFocused, useSelected } from 'slate-react';

export function useSelectedStyle() {
  const selected = useSelected();
  const focused = useFocused();

  const style: React.CSSProperties = {
    boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : 'none',
  };
  return style;
}
