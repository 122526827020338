import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  NgbModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbAlert,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PREVIEW_SCROLL_ID } from 'app/app.constants';
import { BindObservable, filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { PrefillTagSearchModalComponent } from 'app/modules/editor/prefill/prefill-tag/prefill-tag-search-modal.component';
import { PrefillService } from 'app/modules/editor/prefill/prefill.service';
import { ReportStatusBadgeComponent } from 'app/modules/worklist/components/report-status-label/report-status-badge.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { AppState } from 'app/store';
import { EditorState } from 'app/store/editor';
import { fromPatient } from 'app/store/patient';
import { ReportEffect } from 'app/store/report/report';
import { fromReportStatus } from 'app/store/report/report-status/report-status.selector';
import { fromStatementChoice, StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromSubsectionChoice } from 'app/store/report/subsection-choice';
import { fromTopic } from 'app/store/report/topic';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, map, switchMap, take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../../shared/directives/tooltip.directive';
import { TrackDirective } from '../../../../../shared/directives/track.directive';
import { PatientShortInfoPipe } from '../../../../../shared/pipes/patient-short-info-pipe';
import { CheckReportComponent } from '../../check-report/check-report.component';
import { CheckReportModalComponent } from '../check-report-modal/check-report-modal.component';
import { ChoiceConcatenation } from '../choice-concatenation.service';
import { ChoicePreviewComponent } from '../choice-preview/choice-preview.component';
import { ChoicePreviewService } from '../choice-preview/choice-preview.service';
import { HeadingPreviewDeleteModalComponent } from '../heading-preview/heading-preview-delete-modal.component';
import { SectionHeadingPreviewComponent } from '../heading-preview/heading-preview.component';
import { SectionPreviewComponent } from '../section-preview/section-preview.component';
import { SignatureComponent } from '../signature/signature.component';
import { TitleEditDropdownComponent } from '../title-edit-dropdown/title-edit-dropdown.component';

@Component({
  selector: 'rr-report-preview',
  templateUrl: './report-preview.component.html',
  styleUrls: ['./report-preview.component.css'],
  providers: [ChoicePreviewService, ChoiceConcatenation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TitleEditDropdownComponent,
    TooltipDirective,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
    TrackDirective,
    NgbAlert,
    ChoicePreviewComponent,
    SectionHeadingPreviewComponent,
    SectionPreviewComponent,
    SignatureComponent,
    CheckReportComponent,
    PatientShortInfoPipe,
    ReportAccessionNumberComponent,
    ReportStatusBadgeComponent,
  ],
})
@LifecycleLogger
export class ReportPreviewComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  report$: Observable<RR.Report>;
  report: RR.Report;
  patient$: Observable<RR.Patient>;
  /**
   * This component is used in 3 different contexts (normal editor view, prefill preview, and final report preview)
   */
  @Input() mode: PreviewMode = 'editor'; // displayed inside the editor so should have edit functions

  keyfindings$: Observable<RR.Ctx[]>;
  underlinePrefilled: Observable<EditorState['underlinePrefilled']>;
  PREVIEW_SCROLL_ID = PREVIEW_SCROLL_ID;
  subscription = new Subscription();
  processingDefaults = false;
  processingMessage = '';
  topicLoaded: boolean;
  reportStatus: RR.ReportStatus | undefined;
  reportStatusReviewed: RR.ReportStatus | undefined;

  constructor(
    private reportService: ReportService,
    private editorService: EditorService,
    private el: ElementRef,
    private renderer: Renderer2,
    private store: Store<AppState>,
    private modal: NgbModal,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private prefillService: PrefillService,
    private statementChoiceEffect: StatementChoiceEffect,
    private reportEffect: ReportEffect,
  ) {}

  ngOnInit() {
    this.underlinePrefilled = this.editorService.getUnderlinePrefilled();
    if (this.mode === 'final') {
      this.renderer.addClass(this.el.nativeElement, `mode-final`);
    } // other modes don't have any specific css styles

    this.subscription.add(
      combineLatest([
        this.store.select(fromStatementChoice.selectEntities),
        this.store.select(fromSubsectionChoice.selectEntities),
      ])
        .pipe(debounceTime(100))
        .subscribe(() => {
          this.editorService.greyOutEmptySections(this.el.nativeElement);
        }),
    );

    this.subscription.add(
      this.topic$
        .pipe(
          filterDefined(),
          switchMap((topic) => this.store.select(fromTopic.selectLoaded(topic.id))),
        )
        .subscribe((topicLoaded) => {
          this.topicLoaded = topicLoaded;
          this.cd.markForCheck();
        }),
    );

    this.keyfindings$ = this.topic$.pipe(switchMap((topic) => this.reportService.getKeyFindings(topic.id)));
    this.report$ = this.topic$.pipe(switchMap((topic) => this.reportService.getReport(topic.report_id)));
    this.patient$ = this.report$.pipe(
      map((report) => report.patient_id),
      filterDefined(),
      switchMap((patientId) => this.store.select(fromPatient.selectPatient(patientId))),
      filterDefined(),
    );

    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );

    this.subscription.add(
      this.report$
        .pipe(switchMap((report) => this.store.select(fromReportStatus.selectReportStatus(report.status_id))))
        .subscribe((reportStatus) => {
          this.reportStatus = reportStatus;
        }),
    );

    this.subscription.add(
      this.store.select(fromReportStatus.selectByType('reported_by_registrar')).subscribe((reportStatusReviewed) => {
        this.reportStatusReviewed = reportStatusReviewed;
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  jumpToTitle(topic: RR.Topic) {
    if (this.mode === 'editor' || this.mode === 'final') {
      this.editorService.jumpToTitle(topic.id);
    }
  }

  stopUnderline() {
    return this.editorService.toggleUnderlinePrefilled({ underline: false, choice_ids: new Set() });
  }

  async deleteUnderlined() {
    const modalRef = HeadingPreviewDeleteModalComponent.open(this.modal);
    modalRef.componentInstance.headingName = 'Prefilled Statements';
    try {
      await modalRef.result;
      // eslint-disable-next-line rxjs-angular/prefer-composition
      this.underlinePrefilled.pipe(take(1)).subscribe((underlines) => {
        this.reportService.deleteUnderlined(this.topic.id, underlines.choice_ids);
      });
    } catch {
      // do nothing on dismiss
    }
  }

  prefillSentences(type: 'TITLE' | 'PREDICTION') {
    this.processingDefaults = true;
    this.processingMessage =
      type === 'TITLE' ? 'Adding common statements from title...' : 'Adding common statements from relevant reports...';

    this.statementChoiceEffect
      .createPredictionChoices(this.topic.id, type)
      .pipe(
        take(1),
        finalize(() => {
          this.processingDefaults = false;
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: (action) => {
          // If there are prefilled choices, toggle underline prefill to be able to delete them
          const newChoices = action.actions.statementChoiceAddMany.statementChoices;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          if (newChoices && newChoices.length > 0) {
            // Toggle underline prefill with new created choices
            const choice_ids = newChoices.map((c) => c.id);
            this.editorService.toggleUnderlinePrefilled({ underline: true, choice_ids: new Set(choice_ids) });
          } else {
            // If no sentences found, show error message
            this.processingMessage = 'No statement is prefilled from previous reports';
          }

          // Open prefill tag search modal
          PrefillTagSearchModalComponent.open(this.modal, this.topic.id);
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          this.editorService.prefill.pipe(take(1)).subscribe((p) => {
            if (!p) {
              this.editorService.togglePrefill(true);
            }
          });
        },
        error: () => {
          this.processingMessage = '';
        },
      });
  }

  importTitleTopicsToPrefill() {
    this.processingDefaults = true;
    this.processingMessage = 'Finding same title topics...';
    this.http
      .get<number[]>(`/api/topic/${this.topic.id}/title_related_topics`)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((ids) => {
        this.processingDefaults = false;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!ids || ids.length === 0) {
          this.processingMessage = 'No related topics found!';
        } else {
          this.processingMessage = '';
          // Set prefill preview first before check to toggle prefill so that the prefill doesn't automatically select the
          // first row from the search result
          this.prefillService.setPrefillPreviewTopics({
            openTopicId: this.topic.id,
            topicIds: ids.map((id) => id.toString()),
          });
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          this.editorService.prefill.pipe(take(1)).subscribe((p) => {
            if (!p) {
              this.editorService.togglePrefill(true);
            }
          });
        }

        this.cd.markForCheck();
      });
  }

  proceedToReportStatusReviewed(report: RR.Report, reportStatusReviewed: RR.ReportStatus) {
    this.subscription.add(
      this.reportEffect
        .update(report.id, {
          status_id: reportStatusReviewed.id,
        })
        .subscribe(),
    );

    // Also open Check Report for fun
    CheckReportModalComponent.open(this.modal, this.report.id, this.topic.id);
  }
}
