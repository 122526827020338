<div class="w-100 px-1 newline" [linkify]="text"></div>

<div class="item-btns-right btn-group" *ngIf="hover">
  <button
    role="button"
    (click)="approveChoice()"
    class="btn btn-success btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="APPROVE"
  >
    <span class="fas fa-check"></span>
  </button>
  <button
    role="button"
    (click)="removeChoice()"
    class="btn btn-sm btn-danger"
    permission
    [permission-only]="['report_manage']"
    rrTooltip="REMOVE_FROM_REPORT"
  >
    <span class="fa fa-minus"></span>
  </button>
  <button
    role="button"
    (click)="openEditor('editBlueStatement')"
    class="btn btn-sm btn-info"
    data-cy="edit"
    rrTooltip="EDIT"
    permission
    [permission-only]="['report_manage']"
  >
    <span class="fa fa-edit"></span>
  </button>
</div>
