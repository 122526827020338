import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CompanyRoleActions } from './company-role.action';

export interface CompanyRoleState extends EntityState<RR.CompanyRole> {
  loaded: boolean;
}

const adapter = createEntityAdapter<RR.CompanyRole>();

const initialState = adapter.getInitialState({
  loaded: false,
});

export const companyRoleReducer = createReducer(
  initialState,
  // Company roles findAll action might have `where` condition, So using upsertMany to keep some of the roles that already fetched
  on(CompanyRoleActions.findAllSuccess, (state: CompanyRoleState, { companyRoles }) => ({
    ...adapter.upsertMany(companyRoles, state),
    loaded: true,
  })),
  on(CompanyRoleActions.createSuccess, (state: CompanyRoleState, { companyRole }) =>
    adapter.addOne(companyRole, state),
  ),
  on(CompanyRoleActions.updateSuccess, (state: CompanyRoleState, { companyRole }) =>
    adapter.upsertOne(companyRole, state),
  ),
  on(CompanyRoleActions.deleteSuccess, (state: CompanyRoleState, { companyRoleId }) =>
    adapter.removeOne(companyRoleId, state),
  ),
  // Don't clear store data on open/close editor because permissions are loaded from the CompanyRoles
);
