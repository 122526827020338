import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TitleSetHttpService {
  constructor(private http: HttpClient) {}

  find(titleSetId: number) {
    return this.http.get<{
      title_set: RR.TitleSet;
      title_options: RR.TitleOption[];
    }>(`/api/title_set/${titleSetId}`);
  }

  findInTemplate(templateId: number) {
    return this.http.get<{ title_set: RR.TitleSet; title_options: RR.TitleOption[] }>(
      `/api/template/${templateId}/title_set`,
    );
  }

  update(titleSetId: number, changes: Partial<ServerTitleSet>) {
    return this.http.put<{
      title_set: RR.TitleSet;
      title_options: RR.TitleOption[];
    }>(`/api/title_set/${titleSetId}`, changes);
  }
}
