<div class="mb-2">
  <div class="badge bg-danger me-2" *ngIf="expiredMedicare">Expired Medicare</div>
  <div class="badge bg-warning" *ngIf="!formValid">Missing Details</div>
</div>
<div
  *ngIf="parent === 'REGISTRATION' && !selfRegistration && viewMode === 'edit' && patient"
  class="d-flex rounded p-2 my-2 align-items-center bg-info"
>
  <h3 class="text-white mb-0">Click "Edit" to modify this patient's record across all reports</h3>
  <a
    class="btn btn-light ms-auto"
    rrTooltip="REGISTRAION_EDIT_PATIENT"
    target="_blank"
    [routerLink]="['/entities/patients', patient.id]"
    >Edit</a
  >
</div>
<div class="bg-light p-2 rounded">
  <rr-patient-suggestions
    *ngIf="!patient && similarPatients && similarPatients.patients.length > 0"
    [similarPatients]="similarPatients"
    [similarReferralPatients]="similarReferralPatients"
    (patientSelected)="emitPatient($event.patient)"
  ></rr-patient-suggestions>
  <form [formGroup]="form" class="mt-2 bootstrap-validate" (ngSubmit)="submitPatient()">
    <div *ngIf="patient && !selfRegistration" class="row">
      <div class="form-group mb-2 col">
        <label class="control-label"
          >Patient Number: <strong>{{ patient.patient_number ?? '' }}</strong></label
        >
        <button type="button" class="btn btn-outline-primary btn-sm ms-2" (click)="remove()">
          Search for other patient
        </button>
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-2 col">
        <label class="control-label">First Name <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
        <rr-title-case-input
          [formControl]="form.controls.patient_first_name"
          data-cy="patient_first_name"
          [disableInput]="
            disablePatientInputFields.patient_first_name && parent === 'REGISTRATION' && viewMode === 'edit'
          "
        ></rr-title-case-input>

        <span class="invalid-feedback" *ngIf="form.controls.patient_first_name.errors?.required">
          First name is required
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.patient_first_name.errors?.serverError">
          {{ form.controls.patient_first_name.errors?.serverError }}
        </span>
      </div>
      <div class="form-group mb-2 col">
        <label class="control-label">Last Name <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
        <rr-title-case-input
          [formControl]="form.controls.patient_last_name"
          data-cy="patient_last_name"
          [disableInput]="
            disablePatientInputFields.patient_last_name && parent === 'REGISTRATION' && viewMode === 'edit'
          "
        ></rr-title-case-input>

        <span class="invalid-feedback" *ngIf="form.controls.patient_last_name.errors?.required">
          Last name is required
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.patient_last_name.errors?.serverError">
          {{ form.controls.patient_last_name.errors?.serverError }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="form-group mb-2 col">
        <label class="control-label">Date Of Birth <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
        <input [formControl]="form.controls.patient_dob" data-cy="patient_dob" type="date" class="form-control" />
        <span class="invalid-feedback" *ngIf="form.controls.patient_dob.errors?.required"> DOB is required </span>
        <span class="invalid-feedback" *ngIf="form.controls.patient_dob.errors?.forbiddenDOB"> DOB is invalid </span>
      </div>
      <div class="form-group mb-2 col-sm-4">
        <label class="control-label">Sex <span class="text-danger fa fa-asterisk fa-xs"> </span></label>
        <div class="d-flex flex-wrap">
          <div class="form-check ms-0">
            <input
              id="male"
              class="form-check-input"
              type="radio"
              [formControl]="form.controls.patient_sex"
              data-cy="patient_sex"
              value="male"
            />
            <label for="male" class="form-check-label"> Male </label>
          </div>

          <div class="form-check ms-2">
            <input
              id="female"
              class="form-check-input"
              type="radio"
              [formControl]="form.controls.patient_sex"
              value="female"
            />
            <label for="female" class="form-check-label"> Female </label>
          </div>

          <div class="form-check ms-2">
            <input
              id="other"
              class="form-check-input"
              type="radio"
              [formControl]="form.controls.patient_sex"
              value="other"
            />
            <label for="other" class="form-check-label"> Other </label>
          </div>
        </div>
        <span class="invalid-feedback" *ngIf="form.controls.patient_sex.errors?.required"> Sex is required </span>
      </div>
      <div class="form-group mb-2 col-sm-2">
        <label class="control-label">Pronouns</label>
        <input [formControl]="form.controls.pronouns" class="form-control" />
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-2 col-sm-6">
        <label class="control-label">Medicare Number</label>
        <input
          type="text"
          pattern="\d*"
          maxlength="10"
          [formControl]="form.controls.medicare_number"
          data-cy="medicare_number"
          class="form-control"
        />
        <span
          class="invalid-feedback"
          *ngIf="!form.controls.medicare_number.errors?.serverError && form.controls.medicare_number.invalid"
        >
          Medicare number requires 10 digits
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.medicare_number.errors?.serverError">
          {{ form.controls.medicare_number.errors?.serverError }}
        </span>
      </div>
      <div class="form-group mb-2 col-sm-2">
        <label class="control-label">Ref Number</label>
        <input
          type="text"
          pattern="\d*"
          maxlength="1"
          [formControl]="form.controls.medicare_reference_number"
          class="form-control"
        />
        <span class="invalid-feedback" *ngIf="form.controls.medicare_reference_number.invalid">Invalid</span>
      </div>
      <div class="form-group mb-2 col-sm-2">
        <label class="control-label">Exp Month</label>
        <input
          placeholder="m"
          type="text"
          pattern="\d*"
          maxlength="2"
          [formControl]="form.controls.medicare_expiry_month"
          class="form-control"
          [class.is-invalid]="expiredMedicare"
        />
        <span class="text-danger small mt-1" *ngIf="expiredMedicare">Expired Medicare</span>
        <span class="invalid-feedback" *ngIf="form.controls.medicare_expiry_month.invalid">Invalid</span>
      </div>
      <div class="form-group mb-2 col-sm-2">
        <label class="control-label">Exp Year</label>
        <input
          placeholder="yy"
          type="text"
          pattern="\d*"
          minLength="2"
          maxlength="2"
          [formControl]="form.controls.medicare_expiry_year"
          class="form-control"
          [class.is-invalid]="expiredMedicare"
        />
        <span class="invalid-feedback" *ngIf="form.controls.medicare_expiry_year.invalid"
          >Invalid, use last two digits</span
        >
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-2 col">
        <button
          type="button"
          class="btn"
          (click)="validateWithMedicare()"
          [disabled]="
            !(
              !form.controls.patient_first_name.errors?.required &&
              !form.controls.patient_last_name.errors?.required &&
              !form.controls.patient_dob.errors?.required &&
              !form.controls.medicare_number.invalid &&
              !form.controls.medicare_reference_number.invalid
            )
          "
          [class.btn-outline-success]="form.controls.medipass_last_validated.value"
          [class.btn-outline-primary]="!form.controls.medipass_last_validated.value"
          rrTooltip="REGISTRATION_VALIDATE_MEDICARE"
        >
          <span class="fa fa-user" *ngIf="!form.controls.medipass_last_validated.value"></span>
          <span class="fa fa-user-check" *ngIf="form.controls.medipass_last_validated.value"></span>
          Validate info with Medicare
          <span *ngIf="form.controls.medipass_last_validated.value" class="badge bg-success ms-1">
            Last validated: {{ form.controls.medipass_last_validated.value | date: 'dd/MM/yyyy' }}
          </span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="form-group mb-2 col pe-0 m-0">
        <label class="control-label">Mobile Phone</label>
        <rr-phone-fax-input
          [invalid]="form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.phone_mobile"
          data-cy="phone_mobile"
        ></rr-phone-fax-input>
        <span class="invalid-feedback" *ngIf="form.controls.phone_mobile.errors?.pattern">
          Please enter 10 digit phone number
        </span>
      </div>

      <div class="form-group mb-2 col ps-2 pe-0 m-0">
        <label class="control-label">Home Phone</label>
        <rr-phone-fax-input
          [invalid]="form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.phone_home"
        ></rr-phone-fax-input>
        <span class="invalid-feedback" *ngIf="form.controls.phone_home.errors?.pattern">
          Please enter 10 digit phone number
        </span>
      </div>

      <div class="form-group mb-2 col ps-2 pe-0 m-0">
        <label class="control-label">Work Phone</label>
        <rr-phone-fax-input [invalid]="form.errors?.phoneOrEmailRequired" [formControl]="form.controls.phone_work">
        </rr-phone-fax-input>

        <span class="invalid-feedback" *ngIf="form.controls.phone_work.errors?.pattern">
          Please enter 10 digit phone number
        </span>
      </div>
      <div class="form-group mb-2 col-sm-4 ps-2 m-0">
        <label class="control-label">
          Email
          <div class="text-info ms-2 align-items-center d-inline-flex">
            <i class="text-info fa fa-asterisk fa-xs me-1"></i>
            <small>One contact required</small>
          </div>
        </label>
        <input
          [class.ng-valid]="!form.controls.email.errors?.pattern && !form.errors?.phoneOrEmailRequired"
          [class.ng-invalid]="form.controls.email.errors?.pattern || form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.email"
          class="form-control"
        />
        <span class="invalid-feedback" *ngIf="form.controls.email.errors?.pattern"> Email is invalid </span>
      </div>
    </div>
    <span
      class="text-danger small mt-1"
      *ngIf="
        (form.controls.email.touched ||
          form.controls.phone_home.touched ||
          form.controls.phone_work.touched ||
          form.controls.phone_mobile.touched) &&
        form.errors?.phoneOrEmailRequired
      "
    >
      At least one phone or email has to be provided
    </span>

    <div class="form-group mb-2 mt-3">
      <label class="control-label">Address</label>
      <input [formControl]="form.controls.address" class="form-control" />
    </div>

    <div class="row">
      <div class="form-group mb-2 col-sm-6">
        <label class="control-label">City</label>
        <input [formControl]="form.controls.city" class="form-control" />
      </div>
      <div class="form-group mb-2 col-sm-4">
        <label class="control-label">State</label>
        <input [formControl]="form.controls.state" class="form-control" />
      </div>
      <div class="form-group mb-2 col-sm-2">
        <label class="control-label">Postcode</label>
        <input [formControl]="form.controls.zip" class="form-control" />

        <span class="invalid-feedback" *ngIf="form.controls.zip.errors?.pattern"> Postcode is invalid </span>
      </div>
    </div>

    <div class="form-group mb-2">
      <label class="control-label">Veteran File Number</label>
      <input [formControl]="form.controls.veteran_file_number" class="form-control" />
      <span class="invalid-feedback" *ngIf="form.controls.veteran_file_number.errors?.pattern">
        Veteran file number is invalid
      </span>
      <span class="invalid-feedback" *ngIf="form.controls.veteran_file_number.errors?.serverError">
        {{ form.controls.veteran_file_number.errors?.serverError }}
      </span>
    </div>

    <div class="form-group mb-2" *ngIf="!selfRegistration">
      <label class="control-label">Patient Note</label>
      <textarea [formControl]="form.controls.note" class="form-control"> </textarea>
    </div>

    <div *ngIf="similarPatients && similarPatients.patients.length > 0" class="row my-2">
      <div class="alert alert-danger" role="alert">
        <h4 *ngIf="similarPatients.reason === 'IDENTICAL_NAME'" class="text-danger">
          Patients with identical names found
        </h4>
        <h4 *ngIf="similarPatients.reason === 'IDENTICAL_MEDICARE'" class="text-danger">
          Patients with identical Medicare number found
        </h4>
        <div>Please review then tick each patient before proceeding:</div>
        <div *ngFor="let patient of similarPatients.patients">
          <input
            type="checkbox"
            class="form-check-input"
            id="patient-{{ patient.id }}"
            [checked]="similarPatientsChecked[patient.id]"
            (change)="similarPatientsChecked[patient.id] = !similarPatientsChecked[patient.id] || true"
          />

          <label
            for="patient-{{ patient.id }}"
            class="form-check-label"
            [ngbPopover]="popoverContent"
            popoverTitle="Patient Details"
            triggers="mouseenter:mouseleave"
          >
            {{ patient.patient_first_name }} {{ patient.patient_last_name }} ({{ patient.patient_number }})
          </label>
          <a class="ms-1" target="_blank" [routerLink]="['/entities/patients', patient.id]">
            <span class="small fa fa-external-link-alt"></span>
          </a>
          <ng-template #popoverContent>
            <rr-patient-popover [patient]="patient"></rr-patient-popover>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary"
        type="submit"
        [disabled]="!form.dirty"
        ngbPopover="You have changed your details. Please click here to save your changes."
        placement="left"
        [autoClose]="false"
        triggers="manual"
        #savePopover="ngbPopover"
        popoverClass="kiosk-warning-popover"
      >
        <ng-container *ngIf="viewMode === 'create'; else updatePatient">Create New Patient</ng-container>
        <ng-template #updatePatient>Update Patient</ng-template>
      </button>
      <button type="button" class="btn btn-light ms-2" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
