import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocketService } from 'app/core/services/socket.service';
import { BookingEnquiryHttpService } from 'app/store/booking-enquiry/booking-enquiry.service';
import { Subscription, take } from 'rxjs';

@Component({
  selector: 'rr-referral-enquiry-badge',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './referral-enquiry-badge.component.html',
  styleUrls: ['./referral-enquiry-badge.component.css'],
})
export class ReferralEnquiryBadgeComponent implements OnInit, OnDestroy {
  pendingReferralEnquiryCount = 0;

  subscription: Subscription = new Subscription();

  constructor(
    private bookingEnquiryHttpService: BookingEnquiryHttpService,
    private socket: SocketService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.bookingEnquiryHttpService
        .getPendingReferralEnquiryCount()
        .pipe(take(1))
        .subscribe((res: { count: number }) => {
          this.pendingReferralEnquiryCount = res.count;
        }),
    );

    this.subscription.add(
      this.socket.messageReceivedEvent$.subscribe((data) => {
        if (data.type === 'REFERRAL_ENQUIRY_UPDATE') {
          this.pendingReferralEnquiryCount = data.count ?? 0;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
