import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import { AppState } from '../../app.state';
import { fromCategory } from '../../category/category.selector';
import { fromStatement } from '../statement/statement.selector';

const adapter = createEntityAdapter<RR.StatementSet>();
const selectFeature = (state: AppState) => state.statement_set;
// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

const selectLoadedInStatementSet = (statementSetId: number) =>
  createSelector(
    selectFeature,
    ({ statementSetLoadingState: loadingState }) => loadingState[statementSetId] === 'loaded',
  );

const selectLoadingOrLoadedInStatementSet = (statementSetId: number) =>
  createSelector(selectFeature, ({ statementSetLoadingState: loadingState }) => !!loadingState[statementSetId]);

/**
 * Select a single statement set given the Id
 *
 * This converts the statementSetId into the corresponding region.
 *
 * @params: statementSetId: The identifier of the statement set which is set
 * within the database.
 */
const selectStatementSet = (statementSetId: number) =>
  createSelector(selectEntities, (statementSets: Dictionary<RR.StatementSet>) => statementSets[statementSetId]);

const selectStatementSetName = (statementSetId: number) =>
  createSelector(selectStatementSet(statementSetId), (statementSet) => statementSet?.name ?? 'NO NAME');

/**
 * Select the statements contained within the statementSet
 *
 * @params: statementSetId: The id of the statement set
 */
const selectStatements = (statementSetId: number) =>
  createSelector(
    selectStatementSet(statementSetId),
    fromStatement.selectEntities,
    (statementSet: RR.StatementSet | undefined, statements: Dictionary<RR.Statement>) =>
      statementSet?.statements.map((statementId) => statements[statementId]).filter((s): s is RR.Statement => !!s) ??
      [],
  );

const selectStatementNeighbourId = (statementSetId: number, statementId: number) =>
  createSelector(selectStatementSet(statementSetId), (statementSet: RR.StatementSet | undefined) => {
    if (!statementSet) return undefined;
    const idx = statementSet.statements.indexOf(statementId);
    // If statement is not in statement set or statementSet only has 1 statement
    if (idx === -1 || statementSet.statements.length <= 1) return undefined;
    if (idx > 0) return statementSet.statements[idx - 1];
    return statementSet.statements[idx + 1];
  });

const selectCategories = (statementSetId: number) =>
  createSelector(
    selectStatementSet(statementSetId),
    fromCategory.selectEntities,
    (statementSet: RR.StatementSet | undefined, statements: Dictionary<RR.Category>) =>
      statementSet?.categories.map((statementId) => statements[statementId]).filter((c) => !!c) ?? [],
  );

/**
 * Select the dividers (tags) contained within the statementSet
 *
 * @params: statementSetId: The id of the statement set
 */
const selectDividers = (statementSetId: number) =>
  createSelector(selectStatements(statementSetId), (statements: RR.Statement[]) =>
    statements.filter((statement) => statement.is_divider),
  );

export const fromStatementSet = {
  selectLoadedInStatementSet,
  selectLoadingOrLoadedInStatementSet,
  selectIds,
  selectEntities,
  selectAll,
  selectStatementSet,
  selectStatementSetName,
  selectStatements,
  selectCategories,
  selectDividers,
  selectStatementNeighbourId,
};
