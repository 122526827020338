import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { EditorActions } from '../editor/editor.action';
import { ReferrerNoteCategoryActions } from './referrer-note-category.action';

export type ReferrerNoteCategoryState = EntityState<RR.NoteCategory>;

const adapter = createEntityAdapter<RR.NoteCategory>();
const initialState = adapter.getInitialState();

export const referrerNoteCategoryReducer = createReducer(
  initialState,
  on(ReferrerNoteCategoryActions.findManySuccess, (state: ReferrerNoteCategoryState, { referrer_note_categories }) =>
    adapter.upsertMany(referrer_note_categories, state),
  ),
  on(ReferrerNoteCategoryActions.createSuccess, (state: ReferrerNoteCategoryState, { referrer_note_category }) =>
    adapter.addOne(referrer_note_category, state),
  ),
  // Clear store data on open/close editor
  on(EditorActions.open, EditorActions.close, () => initialState),
);
