import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { PatientNoteEffect } from 'app/store/patient-note';
import { fromReferrer } from 'app/store/referrer';
import { ReferrerNoteEffect } from 'app/store/referrer-note';
import { UserEffect } from 'app/store/user/user';
import { Observable, Subscription, of, switchMap, take } from 'rxjs';

import { NoteCategoryComponent } from './note-category/note-category.component';
import { NoteComponent } from './note/note.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NoteComponent, NoteCategoryComponent, SharedModule],
  selector: 'rr-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit, OnDestroy {
  @ViewChild(NoteCategoryComponent) noteCategoryComponent: NoteCategoryComponent;
  @Input() minimalView = false;
  @Input() type: 'referrer' | 'patient' = 'referrer';
  @Input() @BindObservable() referrerId: number | undefined;
  referrerId$: Observable<number | undefined>;
  @Input() @BindObservable() patientId: number | undefined;
  patientId$: Observable<number | undefined>;
  @Input() createMode = true;
  @Input() reportId?: number | undefined = undefined;

  referrerNotes: RR.ReferrerNote[] = [];
  patientNotes: RR.PatientNote[] = [];

  note = new FormControl<string>('', { nonNullable: true });

  subscription = new Subscription();
  category: RR.NoteCategory | undefined = undefined;

  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
    private referrerNoteEffect: ReferrerNoteEffect,
    private patientNoteEffect: PatientNoteEffect,
    private userEffect: UserEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(this.userEffect.findAll().subscribe());
    if (this.type === 'referrer') {
      this.subscription.add(
        this.referrerId$
          .pipe(
            switchMap((id) => (id ? this.store.select(fromReferrer.selectReferrer(id)) : of(undefined))),
            switchMap((r) => (r ? this.store.select(fromReferrer.selectReferrerNotes(r.id)) : of(undefined))),
          )
          .subscribe((notes) => {
            this.referrerNotes = notes
              ? notes.sort((a, b) => {
                  if (a.resolved !== b.resolved) {
                    return a.resolved ? 1 : -1; // if one note is resolved and the other isn't, the resolved one goes to the bottom
                  }
                  return b.created_at.localeCompare(a.created_at); // otherwise, sort by creation date
                })
              : [];
          }),
      );
    } else {
      this.subscription.add(
        this.patientId$
          .pipe(
            switchMap((id) => (id ? this.store.select(fromPatient.selectPatient(id)) : of(undefined))),
            switchMap((r) => (r ? this.store.select(fromPatient.selectPatientNotes(r.id)) : of(undefined))),
          )
          .subscribe((notes) => {
            this.patientNotes = notes
              ? notes.sort((a, b) => {
                  if (a.resolved !== b.resolved) {
                    return a.resolved ? 1 : -1; // if one note is resolved and the other isn't, the resolved one goes to the bottom
                  }
                  return b.created_at.localeCompare(a.created_at); // otherwise, sort by creation date
                })
              : [];
          }),
      );
    }
  }

  setCategory(category: RR.NoteCategory) {
    this.category = category;
  }

  createNote() {
    const note: Partial<RR.ReferrerNote | RR.PatientNote> = {
      referrer_id: this.referrerId,
      patient_id: this.patientId,
      note: this.note.getRawValue(),
      category_id: this.category?.id,
      report_id: this.reportId,
    };

    if (this.type === 'referrer') {
      this.subscription.add(
        this.referrerNoteEffect
          .create(note)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.messageService.add({
                title: 'Success',
                message: 'Create Referrer Note',
                type: 'success',
              });
              this.note.reset();
              this.noteCategoryComponent.clearCategory();
            },
          }),
      );
    } else {
      this.subscription.add(
        this.patientNoteEffect
          .create(note)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.messageService.add({
                title: 'Success',
                message: 'Create Patient Note',
                type: 'success',
              });
              this.note.reset();
              this.noteCategoryComponent.clearCategory();
            },
          }),
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
